import { PersonService, ConfigService, HelperService } from './../../_services/index';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-person-document-form',
  templateUrl: './person-document-form.component.html',
  styleUrls: ['./person-document-form.component.css']
})
export class PersonDocumentFormComponent implements OnInit {

  @Input() model: any;
  @Input() multiSave: boolean;

  @Output() formDelete = new EventEmitter();
  @Output() formSaved = new EventEmitter();

  editMode: boolean = false;

  @ViewChild('f', { static: true }) currentForm: NgForm;

  private localData: IMyDateModel;

  private expired: boolean = false;

  //  file uploaders
  public documentUploader: FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf'],
      url: 'serverUrl'
    }
  );

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(private personService: PersonService, private helper: HelperService,
    private configService: ConfigService) {

    //  Inizializzo le info sulle date e resetto il blocco
    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    if (this.model.id) {
      //  Setto l'url per il caricamento delle foto e gli header
      this.documentUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
      this.documentUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        this.imageUploaded(item, response, status);
      };
      this.documentUploader.options.url = this.personService.getPersonDocumentFileUploadURL(this.model);

      if (this.model.expiration_date != null)
        this.localData = this.helper.initMyDataInputSingle(this.model.expiration_date);

      //  Se esiste il path per l'immagine costruisco l'url completo
      if (this.model.file_path)
        this.model.documentUrl = this.configService.blobBasePath + this.model.file_path;
    }

    if (!this.model || !this.model.id)
      this.editMode = true;

  }


  //  Calcolo la data di scadenza di un documento
  get calculateExpiration(): boolean {
    return this.helper.calculateExpiration(this.model.expiration_date);
  }


  submit() {
    if (!this.helper.isMyDateValid(this.localData, false)) return;

    if (!this.currentForm.form.valid)
      return;

    if (!this.currentForm.form.dirty) {
      this.editMode = false;
      return;
    }

    if (this.model.id)
      this.personService.updatePersonDocument(this.model, () => { this.editMode = false; });
    else
      this.personService.savePersonDocument(this.model, () => { this.editMode = false; });

    this.formSaved.emit(this.model);

  }

  destroy() {
    this.personService.deletePersonDocument(this.model);
  }

  delete() {
    this.formDelete.emit(this);
  }

  edit() {
    this.editMode = true;
  }


  //  Date picker
  onDateChanged(event: IMyDateModel) {
    this.localData = event;

    if (event.singleDate.jsDate != null)
      this.model.expiration_date = this.helper.formatISODate(event);
    else
      this.model.expiration_date = null;

    this.currentForm.form.markAsDirty();
  }



  //  Image Uploader
  public imageUploaded(item: any, response: any, status: any) {
    if (item != null) {
      if (status === 200) {
        this.model.documentUrl = this.configService.blobBasePath + response;
        this.model.file_path = response;
      }
      this.personService.personDocumentFileResponse(item, response, status, this.model.id);
    }
  }

  //  Remove image
  destroyFile() {
    this.personService.deletePersonDocumentFile(this.model);
  }

  getConfigService() {
    return this.configService
  }

  getLocalData() {
    return this.localData
  }

  getHelper() {
    return this.helper
  }
  getPersonService() {
    return this.personService
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }
}
