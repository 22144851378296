import { ConfigService, CampService, HelperService } from './../../_services/index';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-camp-staff-datatable',
  templateUrl: './camp-staff-datatable.component.html',
  styleUrls: ['./camp-staff-datatable.component.css']
})
export class CampStaffDatatableComponent implements OnInit {

  @Output() personSelected: EventEmitter<any> = new EventEmitter();

  campStaffList: any[];

  //  property del datatable
  selected = [];

  subscribe: any;

  constructor(private campService: CampService, private configService: ConfigService, private helper: HelperService) { }

  ngOnInit() {

    //  inizializzo la lista dello staff
    this.campStaffList = [];

    this.subscribe = this.campService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.getStaffInfo();
    });

    if (this.campService.currentCampStaffList != null) {
        this.getStaffInfo();
    }

  }

  getStaffInfo ()
  {
    if (this.campService.currentCampStaffList != null) {

      this.campStaffList = JSON.parse(JSON.stringify(this.campService.currentCampStaffList));
    }

    if (this.campStaffList == null)
      this.campStaffList = [];

  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }



  update(data: any) {

  }

  onSelect({ selected }) {

   this.selectRow(selected[0]);
  }

  downloadPdfTemplate(model, name: string) {
    this.campService.createPdfStaffDocument(model, name, () => {
      // console.log("ok");
    })
  }

  selectRow(row){

    //  controllo se è una persona cancellata prima di inviare l'evento di apertura della modale
    if (row.person_info != null && typeof row.person_info != "undefined")
      if (row.person_info.deleted_at != null) return;

    if (row.enterprise_info != null && typeof row.enterprise_info != "undefined")
      if (row.enterprise_info.deleted_at != null) return;

    this.personSelected.emit(row);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  getResults()
  {

  }

  getRowClass (row) {

    if (row.person_info){
      return { 'camp-staff-deleted' : row.person_info.deleted_at !== null};
    }
    else if (row.enterprise_info) {
      return { 'camp-staff-deleted' : row.enterprise_info.deleted_at !== null};
    }

    return "";
  }

  getCampService() {
    return this.campService
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }
}
