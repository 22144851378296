import { Component, OnInit } from '@angular/core';

import { User } from '../_models/index';
import { UserService } from '../_services/index';

import { AuthenticationService } from '../_services/index';

@Component({
  moduleId: module.id,
  selector: 'sidebar-app',
  templateUrl: 'sidebar.component.html'
})

export class SidebarComponent implements OnInit {
  currentUser: User;

  constructor(private userService: UserService,
    private authenticationService: AuthenticationService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    this.authenticationService.getLoggeginObservable().subscribe((loggedin: boolean) => {

      if (loggedin)
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      else
        this.currentUser = null;
    });
  }

  getUserService() {
    return this.userService;
  }
}
