<h3 class="">Course Schedules search</h3>
<hr>

<form action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
  <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label class="control-label">Anno</label>
        <select class="form-control" name="year" [(ngModel)]="model.year" (change)="fieldChange($event);">
          <option value=""></option>
          <option *ngFor="let y of getConfigService().yearSelect(-10)" [value]="y">{{y}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label class="control-label">Mese</label>
        <select class="form-control" name="month" [(ngModel)]="model.month" (change)="fieldChange($event);">
          <option value=""></option>
          <option *ngFor="let m of getConfigService().monthSelect()" [value]="m">{{m}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDateFrom, false)) }">
        <label class="control-label">From Date</label>
          <div class="input-group">
            <!-- input box -->
            <input angular-mydatepicker #dp_from_date="angular-mydatepicker" name="from_date" class="form-control"
              [locale]="getHelper().locale"
              [options]="datePickerOptionsBegin"
              [ngModel]="localDateFrom"
              (dateChanged)="onDateFromChanged($event)"
            />
            <!-- clear date button -->
            <span class="input-group-btn">
              <button type="button" *ngIf="model['from_date']" class="btn default" (click)="dp_from_date.clearDate()"><i class="fa fa-close"></i></button>
            </span>
            <!-- toggle calendar button -->
            <span class="input-group-btn">
                <button type="button" class="btn default" (click)="dp_from_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
            </span>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDateTo, false)) }">
        <label class="control-label">To Date</label>
          <div class="input-group">
            <!-- input box -->
            <input angular-mydatepicker #dp_to_date="angular-mydatepicker" name="to_date" class="form-control"
              [locale]="getHelper().locale"
              [options]="datePickerOptionsEnd"
              [ngModel]="localDateTo"
              (dateChanged)="onDateToChanged($event)"
            />
            <!-- clear date button -->
            <span class="input-group-btn">
              <button type="button" *ngIf="model['to_date']" class="btn default" (click)="dp_to_date.clearDate()"><i class="fa fa-close"></i></button>
            </span>
            <!-- toggle calendar button -->
            <span class="input-group-btn">
                <button type="button" class="btn default" (click)="dp_to_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
            </span>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label class="control-label">Edizione</label>
        <input type="text" class="form-control" placeholder="" name="numero_edizione" [(ngModel)]="model.numero_edizione"
          (change)="fieldChange($event);">
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label class="control-label">Sofia Code</label>
        <input type="text" class="form-control" placeholder="" name="sofia_code" [(ngModel)]="model.sofia_code"
          (change)="fieldChange($event);">
      </div>
    </div>
    <div class="col-md-1">
      <div class="form-group">
        <label class="control-label">Online</label>
        <input type="text" name="course_external_id" [(ngModel)]="model.course_external_id" #course_external_id="ngModel"
          class="form-control" placeholder="" (keydown)="fieldChange($event);">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Località</label>
        <input type="text" class="form-control" placeholder="" name="locality" [(ngModel)]="model.locality" (change)="fieldChange($event);">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Province</label>
        <input type="text" class="form-control" placeholder="" name="province" [(ngModel)]="model.province" (change)="fieldChange($event);">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Region</label>
        <input type="text" class="form-control" placeholder="" name="region" [(ngModel)]="model.region" (change)="fieldChange($event);">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label class="control-label">Course Type</label>
        <select class="form-control" name="course_type" [(ngModel)]="model.course_type" (change)="fieldChange($event);">
          <option *ngFor="let c of getConfigService().courseTypeSelect" [value]="c.key">{{c.value}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label class="control-label">Company</label>
        <select class="form-control" name="company" [(ngModel)]="model.company" (change)="fieldChange($event);">
          <option value=""></option>
          <option *ngFor="let c of getConfigService().groupCompaniesSelect" [value]="c.key">{{c.value}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label class="control-label">Area</label>
        <select class="form-control" name="area" [(ngModel)]="model.area" (change)="fieldChange($event);">
          <option value=""></option>
          <option *ngFor="let z of getConfigService().areaExpertiseSelect" [value]="z.key">{{z.value}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="">
        <label class="control-label">Course Status (multiple selection)</label>
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox" *ngFor="let status of multipleSelect">
              <input type="checkbox" [(ngModel)]="status.value" [ngModelOptions]="{standalone: true}"> {{getConfigService().getCourseScheduleStatusValueByKey(status.key)}}
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">Relatore</label>
        <input type="text" class="form-control" placeholder="" name="relatore" [(ngModel)]="model.supervisor" (change)="fieldChange($event);">
      </div>
    </div>
  </div>


  <div class="form-actions right" *ngIf="!advancedSearchShow">
    <!-- <button type="button" [disabled]="ongoingRequest" (click)="showAdvancedSearch()" class="btn default btn-lg">More
      Filter
      <i class="fa fa-search"></i>
    </button> -->
    <button type="button" class="btn default btn-lg" (click)="clearSearch()">Clear
      <i class="fa fa-eraser" aria-hidden="true"></i>
    </button>
    <button class="btn default btn-lg green" [disabled]="ongoingRequest" type="submit">Search</button>
  </div>

</form>


<form [hidden]="!advancedSearchShow" action="#" name="form2" (ngSubmit)="f2.form.valid && submitSearch()" #f2="ngForm"
  novalidate>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Code corsi.educoitalia</label>
        <input type="text" name="course_external_id" [(ngModel)]="model.course_external_id" #course_external_id="ngModel"
          class="form-control" placeholder="" (keydown)="fieldChange($event);">
      </div>
    </div>
  </div>

  <div class="form-actions right" *ngIf="advancedSearchShow">
    <button type="button" class="btn" (click)="hideAdvancedSearch()">Close</button>
    <button type="button" class="btn default" (click)="clearAdvancedSearch()">Clear
      <i class="fa fa-eraser" aria-hidden="true"></i>
    </button>
    <button type="submit" [disabled]="ongoingRequest" class="btn default green">Search
      <i class="fa fa-search"></i>
    </button>
  </div>
</form>
