import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { PersonDatatableComponent } from './../../person/index';
import { ConfigService, TheatrinoService, ShowService } from './../../_services/index';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-theatrino-booking-show-info-form',
  templateUrl: './theatrino-booking-show-info-form.component.html',
  styleUrls: ['./theatrino-booking-show-info-form.component.css']
})
export class TheatrinoBookingShowInfoFormComponent implements OnInit {

  @ViewChild('modalPerson', {static: false}) public modalPerson: ModalDirective;
  @ViewChild('personDatatable', {static: false}) public personDatatable: PersonDatatableComponent;

  @ViewChild('f', {static: true}) currentForm: NgForm;

  editMode: boolean;
  @Input() lockedMode: boolean;
  @Input() model: any = {};
  @Output() showDestroyed = new EventEmitter;

  public asyncSelected: string;
  public typeaheadLoading: boolean;
  public typeaheadNoResults: boolean;

  public showsComplex: any = [];


  constructor(private configService: ConfigService, private theatrinoService: TheatrinoService,
              private showService: ShowService, private notify: NotificationsService) {

    this.showService.getAllShows( ()=> {

      this.showsComplex = this.showService.currentAllShows;

      //  Faccio il sort di tutti gli elementi degli show
      this.showsComplex.sort(function(a, b){
        return a.denomination < b.denomination ? -1 : a.denomination > b.denomination ? 1 : 0 }
      );

      this.checkShow();
    });
   }



  ngOnInit() {

    if (!this.model.id) {
      this.editMode = true;
    }
  }

  checkShow()
  {
    if (this.showsComplex.length > 0)
      if (this.model.show_id)
      {
        this.showsComplex.filter((show:any) => {
          if (this.model.show_id == show.id)
          {
            this.asyncSelected = show.denomination;
            return show.denomination;
          }
        });
      }
  }



  accountableClicked () {
    this.showModal();
  }



  timevalid: boolean = true;
  onTimeInput (event)
  {
    this.timevalid = false;

    //  controllo se è valido il time
    this.configService.timeTourSelect.forEach(element => {
      if (element.value == this.currentForm.form.controls.time.value)
          return this.timevalid = true;
    });
  }
  typeTimeOnSelect (event) {
    //  Se arriva questo evento al 100% è una data valida
    this.timevalid = true;
  }

  public get valid()
  {

    if (this.model.accountable_id && this.currentForm.form.valid && this.timevalid) {
      return true;
    }

    return false;
  }

  edit() {
    this.editMode = true;
  }

  submit() {

    //  Se sono in edit mode vuol dire che non è stato modificato nulla
    if (!this.editMode)
      return;

    if (this.model == null || this.model == {})
      return;

    if (!this.valid)
    {
      if (!this.model.accountable_id)
        this.notify.error("You need to set an accountable on Show");
      return;
    }

    if (this.model.id)
      this.theatrinoService.updateBookingShow(this.model, ()=>{this.editMode = false;});
    else
      this.theatrinoService.saveBookingShow(this.model, ()=>{this.editMode = false;});

  }

  destroy() {

    if (this.model.id)
      this.theatrinoService.destroyBookingShow(this.model);
    else
      this.showDestroyed.emit(this);
  }


  searchKeywordsSubmit(params) {
    this.personDatatable.query = params;
    this.personDatatable.getResults();
  }

  resultSelected(selected) {
    this.model.accountable_id = selected.person_id;
    this.hideModal();
  }


  getSum()
  {
    return (parseInt(this.model.total_subscribers) || 0) +
            (parseInt(this.model.number_gratuities) || 0);
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;

    //   Se non ci sono risultato tolgo l'id dello show
    if (!e)
      this.model.show_id = null;
  }

  public typeaheadOnSelect(e: TypeaheadMatch): void {
    this.model.show_id = e.item.id;
  }


  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.modalPerson.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  getTheatrinoService() {
    return this.theatrinoService
  }

  getConfigService() {
    return this.configService
  }

}
