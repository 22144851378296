import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { VehicleService, HelperService, ConfigService } from 'app/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-vehicle-material-assignment-add-form',
  templateUrl: './vehicle-material-assignment-add-form.component.html',
  styleUrls: ['./vehicle-material-assignment-add-form.component.css']
})
export class VehicleMaterialAssignmentAddFormComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('masterModal', {static: false}) public masterModal: ModalDirective;

  @ViewChild('f', {static: true}) currentForm: NgForm;

  @Input() model: any = {};
  vehicleInfo: any;

  vehicleName: string;

  @Output() modelUpdated = new EventEmitter();
  @Output() modalClosed = new EventEmitter();

  private datePickerOptions: IAngularMyDpOptions = {};
  private localStartData: IMyDateModel;
  private localEndData: IMyDateModel;

  constructor(private vehicleService: VehicleService, private helper: HelperService, private configService: ConfigService) {

    //  Inizializzo le info sulle date e resetto il blocco
    this.datePickerOptions = this.helper.datePickerOptions;
  }

  ngOnInit() {
  }


  public update (model) {

    this.model = {};
    this.vehicleInfo = null;
    this.currentForm.form.markAsDirty();

    this.model = model;

    if (this.model == null)
      this.model = {};

    if (this.model.vehicle_data != null && typeof this.model.vehicle_data != "undefined")
    {
      this.vehicleInfo = JSON.parse(JSON.stringify(this.model.vehicle_data));
    }

    if (typeof this.model.start_date != "undefined")
      this.localStartData = this.helper.initMyDataInputSingle(this.model.start_date);
    else
      this.localStartData = null;

    if (typeof this.model.end_date != "undefined")
      this.localEndData = this.helper.initMyDataInputSingle(this.model.end_date);
    else
      this.localEndData = null;

    if (typeof this.vehicleInfo != "undefined")
    {
      this.model.vehicle_id = this.vehicleInfo.id;
      this.vehicleName = this.vehicleInfo.vehicle_name + " (" + this.vehicleInfo.plate_number + ")";
    }
    else
    {
      this.vehicleName = null;
    }

    this.currentForm.form.markAsDirty();

  }

  submit() {

    if (!this.currentForm.form.valid)
      return;

    if (!this.currentForm.form.dirty)
      return;

    if (!this.helper.isMyDateValid(this.localStartData) || !this.helper.isMyDateValid(this.localEndData))
      return;

    if (this.vehicleInfo == null)
      return;

    this.model.vehicle_id = this.vehicleInfo.id;

    if (this.model.id) {
      this.vehicleService.updateVehicleMaterialAssignment(this.model.id, this.model, () => {
        this.masterModal.hide();
        this.modelUpdated.emit();
      });
    }
    else {
      this.vehicleService.saveVehicleMaterialAssignment(this.model, ()=> {
        this.masterModal.hide();
        this.modelUpdated.emit();
      });
    }
  }

  destroy() {

    this.vehicleService.deleteVehicleMaterialAssignment(this.model.id, () => {
      this.modelUpdated.emit();
      this.masterModal.hide();
    })
  }

  //  Search modal
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }


  //  Master modal
  showMaster(): void {

    this.masterModal.show();
  }

  onHiddenMaster(): void {

    this.modalClosed.emit();
  }

  onDateStartChanged(event: IMyDateModel) {

    this.localStartData = event;

    if (event.singleDate.jsDate != null)
      this.model.start_date = this.helper.formatISODate(event);
    else
      this.model.start_date = null;

    this.currentForm.form.markAsDirty();
  }




  onDateEndChanged(event: IMyDateModel) {

    this.localEndData = event;

    if (event.singleDate.jsDate != null)
      this.model.end_date = this.helper.formatISODate(event);
    else
      this.model.end_date = null;

    this.currentForm.form.markAsDirty();
  }




  getVehicleService () {
    return this.vehicleService;
  }

  getConfigService() {
    return this.configService;
  }


  getLocalDataStart() {
    return this.localStartData
  }
  getLocalDataEnd() {
    return this.localEndData
  }

  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

}
