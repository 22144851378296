<h3 class="">Search</h3>
<hr>
<form *ngIf="!advancedSearchShow" action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
  <div class="row" >
    <div class="col-md-6">
      <div class="input-group input-group-lg" [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }">
        <input type="text" name="simpleSearchKeywordText" #simpleSearchKeywordText="ngModel" [(ngModel)]="model.simpleSearchKeyword" (keydown)="fieldChange($event);" required minlength="3"
          class="form-control" placeholder="Search for show name...">
        <span class="input-group-btn"><button class="btn green" [disabled]="ongoingRequest" type="submit">Search</button></span>
      </div>
      <small [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }"><span class="help-block">Type a word to search in show name, min length 3 chars</span></small>
    </div>
    <div class="col-md-2">
      <button type="button" [disabled]="ongoingRequest" (click)="getAllResources(f);" class="btn btn-lg default">Get All <i class="fa fa-search"></i></button>
    </div>
  </div>
</form>