import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class LoggedService {

  private logged: boolean;
  private subject: Subject<boolean> = new Subject<boolean>();

  setLogged(logged: boolean): void {
    this.logged = logged;
    this.subject.next(logged);
  }
  
  getLogged(): Observable<boolean> {
    return this.subject.asObservable();
  }
}