<div class="portlet light bordered">

  <div class="datatable-loader text-center" *ngIf="getWarehouseService().isOngoingRequest()">
    <span></span><span></span><span></span><span></span><span></span>
    <strong class="text-center">Loading</strong>
  </div>

  <div class="portlet-body">

    <ngx-datatable *ngIf="!getWarehouseService().isOngoingRequest()"
      class="striped"
      [rows]="rows"
      [columnMode]="'flex'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [selectionType]="'single'"
      [selected]="selected"
      (select)='onSelect($event)'
    >

      <ngx-datatable-column name="Type" [flexGrow]="2" prop="type" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
      <ngx-datatable-column name="Name" [flexGrow]="10" prop="name" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
      <ngx-datatable-column name="Description" [flexGrow]="10" prop="description" [sortable]="true" [resizeable]="false"></ngx-datatable-column>

    </ngx-datatable>

  </div>
</div>
