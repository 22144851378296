<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title">Camps Documents</h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">

            <!--end: Portlet-Body -->
            <div class="portlet-body">

              <alert type="warning" *ngIf="loadingData">
                <strong>Loading</strong> ...
              </alert>

              <div *ngIf="!loadingData" class="panel-group accordion scrollable" id="accordion2">

                <div *ngFor="let year of yearAccordions; let i = index" class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion2" href="#collapse_2_{{i}}">
                      {{year?.title}} </a>
                    </h4>
                  </div>
                  <div id="collapse_2_{{i}}" [ngClass]="{'in': i===0, 'collapse': i!==0 }" class="panel-collapse">
                    <div class="panel-body">
                      <div class="row">
                        <div class="col-md-12">
                          <button class="btn green btn-outline btn-block"  [disabled]="documentUploader.isUploading" (click)="addNewDocument(year)"><i class="fa fa-plus"></i>&nbsp;Document</button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12" *ngIf="currentUpload.data">
                          <div [hidden]="!documentUploader.isUploading">
                            <div *ngIf="documentUploader.isUploading">
                              <img src="./assets/global/img/input-spinner.gif" />please wait...
                            </div>
                          </div>
                          <input *ngIf="!documentUploader.isUploading" type="file" id="documentUploader"
                            ng2FileSelect [uploader]="documentUploader" />
                          <label *ngIf="!documentUploader.isUploading" for="documentUploader"
                            class="btn btn-lg btn-block green-jungle text-uppercase label-file"><i class="fa fa-upload"></i>&nbsp; Upload document!</label>
                        </div>
                      </div>

                      <hr>

                      <ngx-datatable
                        class="material"
                        [rows]="year?.documents"
                        [columnMode]="'flex'"
                        [headerHeight]="0"
                        [footerHeight]="0"
                        [rowHeight]="'auto'"
                        [rowClass]="getRowClass"
                      >
                        <ngx-datatable-column prop="date" [flexGrow]="2">
                          <ng-template let-value="value" ngx-datatable-cell-template>
                            {{ value | date:'d MMMM, y':'':'it' }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="file_name" [flexGrow]="6">
                          <ng-template let-value="value" ngx-datatable-cell-template>
                            {{value}}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="" [flexGrow]="2">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <a  class="btn btn-primary" href="{{getConfigService().blobBasePath+row.file_path}}"><i class="fa fa-save"></i>&nbsp;Download</a>
                            <button type="button" class="btn btn-outline red" [disabled]="documentUploader.isUploading" (click)="showModalConfirm(row)" ><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
                          </ng-template>
                        </ngx-datatable-column>
                      </ngx-datatable>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->



<!-- BEGIN: MODAL CONFIRM DELETE -->
<div class="modal fade" bsModal #confirmModal="bs-modal" [config]="{backdrop: 'static'}"
  tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-body text-center">
          <p>Do you want to confirm delete?</p>
          <button type="button" class="btn btn-default" (click)="destroyDocument()" >Yes</button>
          <button type="button" class="btn btn-primary" (click)="confirmModal.hide()" >No</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL CONFIRM DELETE -->
