import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CampService, ConfigService, UserService, PrimanotaService } from 'app/_services';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-camp',
  templateUrl: './camp.component.html',
  styleUrls: ['./camp.component.css']
})
export class CampComponent implements OnInit {

  @ViewChild('campTabs', {static: true}) staticTabs: TabsetComponent;

  private subject: any;
  private campSubject: any;
  private campDirectorError = false;

  loadingData: boolean = true;
  subscribePrimanota: any;

  constructor(private titleService: Title, private route: ActivatedRoute,
    private campService: CampService, private configService: ConfigService, private userService: UserService, private primanotaService: PrimanotaService) { }

  ngOnInit() {

    this.titleService.setTitle("Camp");
    this.disableAllTab();

    //   mi registro ai cambiamenti delle info del campo per controllare se ci sono errori con la CM
    this.campSubject = this.campService.getsubjectToUpdateObservable().subscribe(() => {

      this.loadingData = false;
      this.campDirectorError = this.campService.errorCampDirector;
    });

    this.subject = this.route.params.subscribe(params => {

      this.campService.resetService();

      if (typeof params['idCurrentCamp'] != "undefined") {

        this.campService.currentCampId = +params['idCurrentCamp'];

        this.getCampInfo();
      }
      else {
        this.loadingData = false;
      }
    });



    this.subscribePrimanota = this.primanotaService.getsubjectToUpdatePrimaNotaObservable().subscribe((res: any) => {

      this.campService.getCampSubscriber(() => {

        this.loadingData = false;

      }, () => {

        this.loadingData = false;
        this.disableAllTab();
      });

    });

  }

  ngOnDestroy() {
    this.subscribePrimanota.unsubscribe();
    this.campSubject.unsubscribe();
    this.subject.unsubscribe();
  }

  getCampInfo() {

    this.loadingData = true;

    this.campService.getCampInfoAll(() => {

      this.titleService.setTitle("Camp > " + "Edit");

      this.loadingData = false;
      this.activeAllTab();
      this.selectTab(0);
    }, () => {

      this.loadingData = false;
      this.disableAllTab();
    });
  }

  selectTab(num) {

    if (this.staticTabs.tabs[num])
      this.staticTabs.tabs[num].active = true;
  }

  activeAllTab() {

    this.staticTabs.tabs.forEach(element => {
      element.disabled = false;
    });
  }

  disableAllTab() {

    this.staticTabs.tabs.forEach(element => {
      element.disabled = true;
    });
  }

  getUserService() {
    return this.userService
  }

  getCampService() {
    return this.campService
  }

  getConfigService() {
    return this.configService
  }
}
