import { ConfigService } from './../../_services/config.service';
import { HelperService, UserService, BackendService } from './../../_services/index';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { Component, OnInit } from '@angular/core';
import { addDays, format } from 'date-fns';

@Component({
  selector: 'app-theatrino-group-scheduling',
  templateUrl: './theatrino-group-scheduling.component.html',
  styleUrls: ['./theatrino-group-scheduling.component.css']
})
export class TheatrinoGroupSchedulingComponent implements OnInit {

  //  loading
  loadingData: boolean = false;

  //  modello del component corrente
  model: any;

  //  dati grezzi cacciati dalla request
  rawData: any;

  public localData: IMyDateModel = null;

  //  Impostazione della data che vengono passate dall'helper
  public datePickerOptions: IAngularMyDpOptions = {};

  constructor(private helper: HelperService, private configService: ConfigService, private userService: UserService,
              private backend: BackendService) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    if (this.model == null)
    {
      //  Inizializzo il model
      this.model = {};

      //   inizializzo con la data odierna
      this.model.date = format(new Date(), 'yyyy-MM-dd');
    }

    if (this.model.date)
      this.localData = this.helper.initMyDataInputSingle(this.model.date);

  }

  onDateChanged(event: IMyDateModel) {

    this.localData = event;

    if (event.singleDate.jsDate != null)
      this.model.date = event.singleDate.date.year + '-' + (event.singleDate.date.month) + '-' + event.singleDate.date.day;
    else
      this.model.date = null;

    this.localData = event;
  }



  submit() {

    if (!this.helper.isMyDateValid(this.localData)) return;

    var currentUserID = this.userService.getCurrentUser().person_info.id;

    if (currentUserID == null) return;

    this.loadingData = true;

    //  Cancello quello che c'è nei dati attuali
    this.rawData = null;

    let urlString = "theatrino/person/"+currentUserID+"/groupscheduling";

    this.backend.post(urlString, this.model).subscribe(
      (response) => {
        let res : any = response;

        this.loadingData = false;

        this.rawData = res;
      },
      (error) => {
        console.log("error");
        this.backend.showErrors(error);

        this.loadingData = false;
      }
    );

  }


  goBack()
  {
    //  Rimuovo un giorno, formatto la data e la setto al datepicker
    this.localData = this.helper.initMyDataInputSingle(addDays(this.localData.singleDate.jsDate, -1));
    this.model.date = this.helper.formatISODate(this.localData);

    this.submit();
  }

  forward()
  {
    //  Aggiungo un giorno, formatto la data e la setto al datepicker
    this.localData = this.helper.initMyDataInputSingle(addDays(this.localData.singleDate.jsDate, 1));
    this.model.date = this.helper.formatISODate(this.localData);

    this.submit();
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }
}
