<div class="portlet light bordered">

    <div class="portlet-title">
      <button *ngIf="!lockMode" [disabled]="getShipmentService().isOngoingRequest()" type="button" class="btn green btn-block btn-outline" (click)="addNewMaterial();"><i class="fa fa-plus"></i>&nbsp; Add Material</button>
      <div *ngIf="lockMode">
        <form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submitDate()" #f="ngForm" novalidate>
          <div class="row">
            <div class="col-md-6">
              <div class="col-md-6 read-only form-group editMode">
                <label class="control-label">Date of send shipment</label>
                <p> {{currentShipment?.shipment_start | date:'d MMMM, y':'':'it'}} </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalDataEnd())) }">
                <label class="control-label">Date of arrival shipment <span class="required">*</span></label>
                  <div class="input-group">
                    <!-- input box -->
                    <input angular-mydatepicker #dp_shipment_end="angular-mydatepicker" name="shipment_end" class="form-control"
                      [locale]="getHelper().locale"
                      [options]="getDatePickerOptions()"
                      [ngModel]="getLocalDataEnd()"
                      (dateChanged)="onDateEndChanged($event)"
                    />
                    <!-- clear date button -->
                    <span class="input-group-btn">
                      <button type="button" *ngIf="currentShipment?.shipment_end" class="btn default" (click)="dp_shipment_end.clearDate()"><i class="fa fa-close"></i></button>
                    </span>
                    <!-- toggle calendar button -->
                    <span class="input-group-btn">
                        <button type="button" class="btn default" (click)="dp_shipment_end.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                    </span>
                </div>
                <span class="help-block">Field required</span>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="currentShipment.shipment_code">
            <div class="col-md-12">
              <div class="col-md-6 read-only form-group editMode">
                <label class="control-label">Tracking Code: &nbsp;</label>
                <a href="{{currentShipment?.shipment_code}}">{{currentShipment?.shipment_code}}</a>
              </div>
            </div>
          </div>

          <div class="form-actions right">
            <button type="submit" class="btn blue" [disabled]="getShipmentService().isOngoingRequest()" *ngIf="lockMode">Update Date</button>
          </div>
        </form>
      </div>
    </div>

  <div class="datatable-loader text-center" *ngIf="getShipmentService().isOngoingRequest()">
    <span></span><span></span><span></span><span></span><span></span>
    <strong class="text-center">Loading</strong>
  </div>

  <div class="portlet-body">

    <ngx-datatable *ngIf="!getShipmentService().isOngoingRequest()"
    class="striped"
        [rows]="rows"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="0"
        [rowHeight]="'auto'"
        [selectionType]="'single'"
        [selected]="selected"
        (select)='onSelect($event)'
    >
      <ngx-datatable-column name="Type" [flexGrow]="2" prop="product_info.type" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
      <ngx-datatable-column name="Name" [flexGrow]="10" prop="product_info.name" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
      <ngx-datatable-column name="Description" [flexGrow]="10" prop="product_info.description" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
      <ngx-datatable-column name="Quantity" [flexGrow]="2" prop="quantity" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
    </ngx-datatable>

  </div>
</div>


