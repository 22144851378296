import {PersonService, TutorService} from "../../_services/index";
import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-person-tutor-form',
  templateUrl: './person-tutor-form.component.html',
  styleUrls: ['./person-tutor-form.component.css']
})
export class PersonTutorFormComponent implements OnInit {

  @Input() model: any;

  subscribe: any;

  constructor(private personService: PersonService, private tutorService: TutorService) {}

  ngOnInit() {

    this.fillModel();

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      this.fillModel();
    });
  }

  private fillModel() {

    this.model = this.personService.currentPersonTutor;

    if (this.model == null) {
      this.model = {};
    }
  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }

  isOngoingRequest() {
    return this.personService.isOngoingRequest()
  }

  submit() {

    if (!this.model.object_to_catholic_environment) {
      this.model.object_to_catholic_environment = false;
    }

    if (!this.model.happy_to_stay_with_host_family) {
      this.model.happy_to_stay_with_host_family = false;
    }

    if (!this.model.confident_swimmer) {
      this.model.confident_swimmer = false;
    }

    if (this.model.id) {
      this.personService.updateTutor(this.model);
    }
    else {
      this.personService.saveTutor(this.model);
    }
  }

  getTutorService() {
    return this.tutorService
  }
}
