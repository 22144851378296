import { Router, ActivatedRoute } from '@angular/router';
import { ActorGroupDatatableComponent } from './../actor-group-datatable/actor-group-datatable.component';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-actor-group-search-result',
  templateUrl: './actor-group-search-result.component.html',
  styleUrls: ['./actor-group-search-result.component.css']
})
export class ActorGroupSearchResultComponent implements OnInit {

  @ViewChild(ActorGroupDatatableComponent, {static: true}) datatable: ActorGroupDatatableComponent;

  private subject: any;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.getAllResult();
  }

  groupSelected (selected) {
    this.router.navigate(['actorgroup/edit/'+selected.actor_group_id]);
  }

  getAllResult() {
    this.datatable.query = "all";
    this.datatable.getResult();
  }

  updateResults(params) {
    this.datatable.query = params;
    this.datatable.getResult();
  }

  searchKeywordsSubmit(params) {

    if (typeof params == "undefined")
      this.getAllResult();
    else
      this.updateResults(params);
  }

}
