import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ReminderService, UserService, HelperService } from 'app/_services';
import { HttpParams } from '@angular/common/http';
import { addDays, isToday, isTomorrow, isPast } from 'date-fns'
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reminder-widget-agenda',
  templateUrl: './reminder-widget-agenda.component.html',
  styleUrls: ['./reminder-widget-agenda.component.css']
})
export class ReminderWidgetAgendaComponent implements OnInit {


  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;

  @Input() readOnlyMode: boolean = false;

  @Input() showFutureReminder: boolean = false;
  @Input() showCompletedReminder: boolean = false;

  selectedRowModel: any;
  subscribe: any;
  subscribeCompleted: any;
  loadingData: boolean = false;
  loadingReminderCompleted: boolean = false;

  pageTotalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotalPages: number = 0;
  pageSort: any = null;
  rows: any[] = [];
  rowsPast: any[] = [];
  rowsToday: any[] = [];
  rowsTomorrow: any[] = [];
  rowsFuture: any[] = [];
  rowsCompleted: any[] = [];
  selected = [];
  selectedRow: any;
  currentQuery: any = {};

  constructor(private reminderService: ReminderService, private userService: UserService) { }

  ngOnInit() {

    this.loadingData = true;

    this.subscribe = this.reminderService.getsubjectToUpdateReminderObservable().subscribe((res: any) => {

      this.rows = this.reminderService.getTableData(res);

      this.organizeAgenda();

      this.loadingData = false;
    });

    this.searchReminder();

    this.loadingReminderCompleted = true;

    this.subscribeCompleted = this.reminderService.getsubjectToUpdateReminderCompletedObservable().subscribe((res: any) => {

      this.pageNumber = res.current_page - 1;
      this.pageSize = res.per_page;
      this.pageTotalElements = res.total;
      this.pageTotalPages = res.last_page;

      this.rowsCompleted = this.reminderService.getTableData(res.data);

      this.loadingReminderCompleted = false;
    });

    this.searchReminderCompleted();

  }

  ngOnDestroy(){
    this.subscribe.unsubscribe();
    this.subscribeCompleted.unsubscribe();
  }

  searchReminder(){

    this.rows = [];
    this.pageTotalElements = 0;
    this.pageTotalPages = 0;

    let params = new HttpParams();

    this.currentQuery.from_date = new Date();

    if (!this.showFutureReminder)
      this.currentQuery.to_date = addDays(HelperService.parseDate(this.currentQuery.from_date), 1);

    this.currentQuery.registered_by_user_id = this.userService.getCurrentUser().id;
    this.currentQuery.includeOlderRemindersNotCompleted = true;

    this.reminderService.searchReminder(params, this.currentQuery);
  }

  searchReminderCompleted(){

    this.rowsCompleted = [];
    this.pageTotalElements = 0;
    this.pageTotalPages = 0;

    let params = new HttpParams();
    params = params.set('page', (this.pageNumber + 1).toString());

    if (this.pageSort != null)
    {
      params = params.set('sortDir', this.pageSort.dir);
      params = params.set('sortBy', this.pageSort.prop);
    }

    this.currentQuery.registered_by_user_id = this.userService.getCurrentUser().id;

    this.reminderService.searchReminderCompleted(params, this.currentQuery);
  }

  organizeAgenda(){

    this.rowsPast = [];
    this.rowsToday = [];
    this.rowsTomorrow = [];
    this.rowsFuture = [];

    for (let key in this.rows){

      let r = this.rows[key];

      let start_date_reminder = HelperService.parseDate(r.start_date_reminder);

      if (isToday(start_date_reminder)){
        this.rowsToday.push(r);
      }
      else if (isTomorrow(start_date_reminder)){
        this.rowsTomorrow.push(r);
      }
      else if (isPast(start_date_reminder)){
        this.rowsPast.push(r);
      }
      else {
        this.rowsFuture.push(r);
      }
    }
  }

  markAsCompleted(reminderId){

    this.reminderService.completeReminder(reminderId, null, () => {
      this.searchReminder();
      this.searchReminderCompleted();
    });
  }

  viewReminder(row) {

    if (this.readOnlyMode)
      return;

    if (!this.userService.checkPermission('reminder_edit'))
      return;

    this.selectedRow = Object.assign({}, row);
    this.showModal();
  }

  isPastNotification(date){
    return isPast(HelperService.parseDate(date));
  }

  setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.searchReminderCompleted();
  }

  onSort(sortInfo) {
    this.pageSort = sortInfo.sorts[0];
    this.searchReminderCompleted();
  }

  onSelect({ selected }) {

    if (this.readOnlyMode)
      return;

    if (!this.userService.checkPermission('reminder_edit'))
      return;

    this.selectedRow = Object.assign({}, selected[0]);
    this.showModal();
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }

  // MODAL

  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  modelUpdatedInModal(event) {
    this.hideModal();
    this.loadingData = true;
    this.searchReminder();
    this.searchReminderCompleted();
  }

}
