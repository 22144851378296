import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/index';
import { LoginComponent } from './login/index';
import { PersonComponent, PersonHomeComponent, PersonSearchResultsComponent } from './person/index';
import { EnterpriseComponent, EnterpriseHomeComponent, EnterpriseSearchResultsComponent } from './enterprise/index';
import { ActorGroupHomeComponent, ActorGroupComponent, ActorGroupSearchResultComponent, ActorGroupCashflowPageComponent } from './actor-group/index';
import { TheatrinoComponent, TheatrinoHomeComponent, TheatrinoReportsShowComponent, TheatrinoReportsComponent, TheatrinoReportsReferentiComponent } from './theatrino/index';
import { ShowHomeComponent, ShowComponent } from './show/index';
import { TourComponent } from './tour/index';
import { CampHomeComponent, CampComponent, CampCalendarComponent, CampDocumentsComponent, CampReportsComponent, CampReportsEtaSubscribersComponent, CampReportsCampmembersComponent, CampPersonTransferDetailsComponent } from './camp/index';
import { AgreementComponent, AgreementCampComponent } from './agreement/index';
import { AuthGuard } from './_guards/index';
import { TutorsApplicationsHomeComponent, TutorsApplicationsSearchResultsComponent, TutorsApplicationEditComponent } from './tutors-applications/index';
import { ActorsApplicationsHomeComponent, ActorsApplicationEditComponent } from './actors-applications/index';
import { FacilitatorsApplicationsHomeComponent, FacilitatorsApplicationEditComponent } from './facilitators-applications/index';
import { PrimanotaSearchComponent } from './primanota';
import { WarehouseHomeComponent, WarehouseShipmentComponent } from './warehouse/index';
import { CourseHomeComponent, CourseComponent } from './course/index';
import { CourseScheduleHomeComponent, CourseScheduleComponent } from './course-schedule/index';
import { VehicleHomeComponent, VehicleComponent, VehicleSearchResultsComponent } from './vehicle';
import { AgendaComponent } from './agenda';
import { PaymentsSearchComponent } from './payments';
import { AdminReportsComponent, AdminReportsDiaryComponent, AdminReportsRevisionComponent, AdminReportsCourseSignupRequestComponent, AdminReportsCampSignupRequestComponent } from './admin-reports/index';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard], data: { title: 'Gaston APP' }},
    { path: 'login', component: LoginComponent, data: { title: 'Gaston APP' } },

    { path: 'person/home', component: PersonHomeComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Person', permissions:['person_read', 'person_edit'] } },
    { path: 'person/new', component: PersonComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Person', permissions:['person_edit'] } },
    { path: 'person/new/:idDefaultRole', component: PersonComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Person', permissions:['person_edit'] } },
    { path: 'person/edit/:idCurrentPerson', component: PersonComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Person', permissions:['person_edit'] } },
    { path: 'person/search', component: PersonSearchResultsComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Person', permissions:['person_read', 'person_edit'] } },

    { path: 'enterprise/home', component: EnterpriseHomeComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Enterprise', permissions:['enterprise_read', 'enterprise_edit'] } },
    { path: 'enterprise/new', component: EnterpriseComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Enterprise', permissions:['enterprise_edit'] } },
    { path: 'enterprise/new/:idDefaultType', component: EnterpriseComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Enterprise', permissions:['enterprise_edit'] } },
    { path: 'enterprise/edit/:idCurrentEnterprise', component: EnterpriseComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Enterprise', permissions:['enterprise_edit'] } },
    { path: 'enterprise/search', component: EnterpriseSearchResultsComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Enterprise', permissions:['enterprise_read', 'enterprise_edit'] } },
    { path: 'enterprise/:idCurrentEnterprise/agreement/new', component: AgreementComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Enterprise', permissions:['enterprise_edit'] } },
    { path: 'enterprise/:idCurrentEnterprise/agreement/edit/:idCurrentAgreement', component: AgreementComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Enterprise', permissions:['enterprise_edit'] } },
    { path: 'enterprise/:idCurrentEnterprise/agreementcamp/new', component: AgreementCampComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Enterprise', permissions:['enterprise_edit'] } },
    { path: 'enterprise/:idCurrentEnterprise/agreementcamp/edit/:idCurrentAgreement', component: AgreementCampComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Enterprise', permissions:['enterprise_edit'] } },

    { path: 'actorgroup/home', component: ActorGroupHomeComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Actor', permissions:['actorgroup_read', 'actorgroup_edit'] } },
    { path: 'actorgroup/new', component: ActorGroupComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Actor', permissions:['actorgroup_edit'] } },
    { path: 'actorgroup/edit/:idCurrentGroup', component: ActorGroupComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Actor', permissions:['actorgroup_edit'] } },
    { path: 'actorgroup/search', component: ActorGroupSearchResultComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Actor', permissions:['actorgroup_read', 'actorgroup_edit'] } },
    { path: 'actorgroup/cashflow', component: ActorGroupCashflowPageComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Actor', permissions:['actorgroup_cashflow_edit', 'actorgroup_edit'] } },

    { path: 'show/home', component: ShowHomeComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Show', permissions:['show_read', 'show_edit'] } },
    { path: 'show/new', component: ShowComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Show', permissions:['show_edit'] } },
    { path: 'show/edit/:idCurrentShow', component: ShowComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Show', permissions:['show_edit'] } },

    { path: 'theatrino/home', component: TheatrinoHomeComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_read', 'theatrino_edit'] } },
    { path: 'theatrino/reports', component: TheatrinoReportsComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Reports', permissions:['theatrino_reports_read'] } },
    { path: 'theatrino/reports/show', component: TheatrinoReportsShowComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Reports > Show', permissions:['theatrino_reports_read'] } },
    { path: 'theatrino/reports/referenti', component: TheatrinoReportsReferentiComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Reports > Referenti', permissions:['theatrino_reports_read'] } },
    { path: 'theatrino/new', component: TheatrinoComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },
    { path: 'theatrino/edit/:idCurrentTheatrino', component: TheatrinoComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_read', 'theatrino_edit'] } },

    { path: 'theatrino/:idCurrentTheatrino/tour/new/booking/:booking', component: TourComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },
    { path: 'theatrino/:idCurrentTheatrino/tour/new/dayoff/:dayoff', component: TourComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },
    { path: 'theatrino/:idCurrentTheatrino/tour/new/swapday/:swapday', component: TourComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },
    { path: 'theatrino/:idCurrentTheatrino/tour/new/general/:general', component: TourComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },
    { path: 'theatrino/:idCurrentTheatrino/tour/new/accomodation/:accomodation', component: TourComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },
    { path: 'theatrino/:idCurrentTheatrino/tour/newDate/:date/booking/:booking', component: TourComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },
    { path: 'theatrino/:idCurrentTheatrino/tour/newDate/:date/dayoff/:dayoff', component: TourComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },
    { path: 'theatrino/:idCurrentTheatrino/tour/newDate/:date/swapday/:swapday', component: TourComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },
    { path: 'theatrino/:idCurrentTheatrino/tour/newDate/:date/general/:general', component: TourComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },
    { path: 'theatrino/:idCurrentTheatrino/tour/newDate/:date/accomodation/:accomodation', component: TourComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },
    { path: 'theatrino/:idCurrentTheatrino/tour/edit/:idCurrentTour', component: TourComponent, canActivate: [AuthGuard], data: { title: 'Theatrino > Tour', permissions:['theatrino_edit'] } },

    { path: 'camp/home', component: CampHomeComponent, canActivate: [AuthGuard], data: { title: 'Camp > All', permissions:['camp_read', 'camp_edit'] } },
    { path: 'camp/new', component: CampComponent, canActivate: [AuthGuard], data: { title: 'Camp > New', permissions:['camp_read', 'camp_edit', 'camp_create'] } },
    { path: 'camp/edit/:idCurrentCamp', component: CampComponent, canActivate: [AuthGuard], data: { title: 'Camp > Edit', permissions:['camp_read', 'camp_edit'] } },
    { path: 'camp/calendar', component: CampCalendarComponent, canActivate: [AuthGuard], data: { title: 'Camps Calendar', permissions:['camp_calendar_read', 'camp_calendar_edit'] } },
    { path: 'camp/transfer-details', component: CampPersonTransferDetailsComponent, canActivate: [AuthGuard], data: { title: 'Camps Transfer Details', permissions:['camp_transfer_details_read', 'camp_transfer_details_edit'] } },
    { path: 'camp/documents', component: CampDocumentsComponent, canActivate: [AuthGuard], data: { title: 'Camps Documents', permissions:['camp_documents_read', 'camp_documents_edit'] } },
    { path: 'camp/reports', component: CampReportsComponent, canActivate: [AuthGuard], data: { title: 'Camp > Reports', permissions:['camp_reports_read'] } },
    { path: 'camp/reports/etasubscribers', component: CampReportsEtaSubscribersComponent, canActivate: [AuthGuard], data: { title: 'Camp > Reports > Eta Subscribers', permissions:['camp_reports_read'] } },
    { path: 'camp/reports/campmembers', component: CampReportsCampmembersComponent, canActivate: [AuthGuard], data: { title: 'Camp > Reports > Camp Members', permissions:['camp_reports_read'] } },

    { path: 'tutorsapplications/home', component: TutorsApplicationsHomeComponent, canActivate: [AuthGuard], data: { title: 'Applications > Tutors Applications', permissions:['application_tutors_read', 'application_tutors_edit'] } },
    { path: 'tutorsapplications/search', component: TutorsApplicationsSearchResultsComponent, canActivate: [AuthGuard], data: { title: 'Applications > Tutors Applications', permissions:['application_tutors_read', 'application_tutors_edit'] } },
    { path: 'tutorsapplications/edit/:idCurrentTutor', component: TutorsApplicationEditComponent, canActivate: [AuthGuard], data: { title: 'Applications > Tutors Applications Edit', permissions:['application_tutors_edit'] } },

    { path: 'actorsapplications/home', component: ActorsApplicationsHomeComponent, canActivate: [AuthGuard], data: { title: 'Applications > Actors Applications', permissions:['application_actors_read', 'application_actors_edit'] } },
    { path: 'actorsapplications/edit/:idCurrentActor', component: ActorsApplicationEditComponent, canActivate: [AuthGuard], data: { title: 'Applications > Actors Applications Edit', permissions:['application_actors_edit'] } },
    { path: 'facilitatorsapplications/home', component: FacilitatorsApplicationsHomeComponent, canActivate: [AuthGuard], data: { title: 'Applications > Facilitators Applications', permissions:['application_facilitators_read', 'application_facilitators_edit'] } },
    { path: 'facilitatorsapplications/edit/:idCurrentFacilitator', component: FacilitatorsApplicationEditComponent, canActivate: [AuthGuard], data: { title: 'Applications > Facilitators Applications Edit', permissions:['application_facilitators_edit'] } },

    { path: 'cashflow/search', component: PrimanotaSearchComponent, canActivate: [AuthGuard], data: { title: 'Financial Staff > Cash Flow', permissions:['admin_reports_read'] } },

    { path: 'payments/search', component: PaymentsSearchComponent, canActivate: [AuthGuard], data: { title: 'Financial Staff > Payments Requests', permissions:['admin_reports_read'] } },

    { path: 'warehouse/home', component: WarehouseHomeComponent, canActivate: [AuthGuard], data: { title: 'WareHouse > Product', permissions:['warehouse_read', 'warehouse_edit']} },
    { path: 'warehouse/shipments', component: WarehouseShipmentComponent, canActivate: [AuthGuard], data: { title: 'WareHouse > Shipments', permissions:['warehouse_shipments_read', 'warehouse_shipments_edit']} },

    { path: 'course/home', component: CourseHomeComponent, canActivate: [AuthGuard], data: { title: 'Course > Database', permissions:['course_read', 'course_edit'] } },
    { path: 'course/new', component: CourseComponent, canActivate: [AuthGuard], data: { title: 'Course > Database', permissions:['course_read', 'course_edit'] } },
    { path: 'course/edit/:idCurrentCourse', component: CourseComponent, canActivate: [AuthGuard], data: { title: 'Course > Database', permissions:['course_edit'] } },

    { path: 'courseschedule/home', component: CourseScheduleHomeComponent, canActivate: [AuthGuard], data: { title: 'Course Schedule > All', permissions:['course_schedule_read', 'course_schedule_edit'] } },
    { path: 'courseschedule/new', component: CourseScheduleComponent, canActivate: [AuthGuard], data: { title: 'Course Schedule > New', permissions:['course_schedule_read', 'course_schedule_edit'] } },
    { path: 'courseschedule/edit/:idCurrentCourseSchedule', component: CourseScheduleComponent, canActivate: [AuthGuard], data: { title: 'Course Schedule > Edit', permissions:['course_schedule_read', 'course_schedule_edit'] } },
    // { path: 'courseschedule/calendar', component: CourseScheduleCalendarComponent, canActivate: [AuthGuard], data: { title: 'CourseSchedules Calendar' } },
    // { path: 'courseschedule/documents', component: CourseScheduleDocumentsComponent, canActivate: [AuthGuard], data: { title: 'CourseSchedules Documents' } },

    { path: 'vehicle/home', component: VehicleHomeComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Vehicle', permissions:['vehicle_read', 'vehicle_edit'] } },
    { path: 'vehicle/new', component: VehicleComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Vehicle', permissions:['vehicle_edit'] } },
    { path: 'vehicle/new/:idDefaultType', component: VehicleComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Vehicle', permissions:['vehicle_edit'] } },
    { path: 'vehicle/edit/:idCurrentVehicle', component: VehicleComponent, canActivate: [AuthGuard], data: { title: 'Anagrafica > Vehicle', permissions:['vehicle_edit'] } },

    { path: 'agenda', component: AgendaComponent, canActivate: [AuthGuard], data: { title: 'Agenda' } },

    { path: 'admin/reports', component: AdminReportsComponent, canActivate: [AuthGuard], data: { title: 'Admin > Reports', permissions:['admin_reports_read'] } },
    { path: 'admin/reports/diary', component: AdminReportsDiaryComponent, canActivate: [AuthGuard], data: { title: 'Admin > Reports > Diary', permissions:['admin_reports_read'] } },
    { path: 'admin/reports/revision', component: AdminReportsRevisionComponent, canActivate: [AuthGuard], data: { title: 'Admin > Reports > Revision', permissions:['admin_reports_read'] } },
    { path: 'admin/reports/coursesignuprequest', component: AdminReportsCourseSignupRequestComponent, canActivate: [AuthGuard], data: { title: 'Admin > Reports > Course Signup Request', permissions:['admin_reports_read'] } },
    { path: 'admin/reports/campsignuprequest', component: AdminReportsCampSignupRequestComponent, canActivate: [AuthGuard], data: { title: 'Admin > Reports > Camp Signup Request', permissions:['admin_reports_read'] } },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, {useHash: true});
