import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckPermissionComponent } from './check-permission.component';
import { CheckPermissionDirective } from './check-permission.directive'

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    CheckPermissionDirective
  ],
  declarations: [
    CheckPermissionComponent,
    CheckPermissionDirective
  ]
})
export class CheckPermissionModule { }
