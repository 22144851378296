import { Input, Output, EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { HelperService, WarehouseService, ShipmentService } from 'app/_services';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-shipment-materials',
  templateUrl: './shipment-materials.component.html',
  styleUrls: ['./shipment-materials.component.css']
})
export class ShipmentMaterialsComponent implements OnInit {

  @Input() currentShipment: any;
  @Input('appCheckPermission') appCheckPermission: string;

  @Output() newMaterialAdded = new EventEmitter;
  @Output() materialSelected = new EventEmitter;

  lockMode: boolean = false;

  rows = [];
  selected = [];
  subscribe: any;

  private localDataStart: IMyDateModel;
  private localDataEnd: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(private shipmentService: ShipmentService, private helper: HelperService,
            private warehouseService: WarehouseService) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    if (this.currentShipment == null) return;

    if (this.currentShipment.shipment_end != null)
      this.localDataEnd = this.helper.initMyDataInputSingle(this.currentShipment.shipment_end);

    this.subscribe = this.shipmentService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.loadMaterials();
    });

     setTimeout(() => { this.loadMaterials(); }, 50);
  }


  loadMaterials ()
  {
    this.shipmentService.getAllMaterialFromShipment(this.currentShipment, (result) => {
      this.rows = result;

      this.currentShipment.material_list = result;

      if (this.currentShipment.shipment_end != null)
        this.localDataEnd = this.helper.initMyDataInputSingle(this.currentShipment.shipment_end);

      this.lockMode = this.currentShipment.closed_order;
    });
  }



  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }


  addNewMaterial () {

    if (this.currentShipment == null)
      return;

    if (this.lockMode)
      return;

    this.newMaterialAdded.emit();
  }


  onSelect({ selected }) {

    if (this.lockMode)
      return;

    this.materialSelected.emit(JSON.parse(JSON.stringify(selected[0])));
  }



  onDateStartChanged(event: IMyDateModel) {

    this.localDataStart = event;

    if (event.singleDate.jsDate != null)
      this.currentShipment.shipment_start = this.helper.formatISODate(event);
    else
      this.currentShipment.shipment_start = null;
  }



  onDateEndChanged(event: IMyDateModel) {

    this.localDataEnd = event;

    if (event.singleDate.jsDate != null)
      this.currentShipment.shipment_end = this.helper.formatISODate(event);
    else
      this.currentShipment.shipment_end = null;
  }



  submitDate ()
  {
    if (!this.helper.isMyDateValid(this.localDataEnd)) return;

    this.shipmentService.updateShipment(this.currentShipment);
  }


  getLocalDataStart() {
    return this.localDataStart
  }

  getLocalDataEnd() {
    return this.localDataEnd
  }

  getHelper() {
    return this.helper
  }

  getShipmentService():ShipmentService {
    return this.shipmentService
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

}
