import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-actor-group-home',
  templateUrl: './actor-group-home.component.html',
  styleUrls: ['./actor-group-home.component.css']
})
export class ActorGroupHomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

}