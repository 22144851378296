import { Router } from '@angular/router';
import { EnterpriseService, HelperService, ConfigService } from './../../_services/index';
import { Component, OnInit } from '@angular/core';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-enterprise-agreement-list',
  templateUrl: './enterprise-agreement-list.component.html',
  styleUrls: ['./enterprise-agreement-list.component.css']
})
export class EnterpriseAgreementListComponent {}
