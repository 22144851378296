import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable()
export class RevisionService {

  ongoingRequest: boolean = false;

  private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

  constructor(private backend: BackendService, private notify: NotificationsService) { }

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  isOngoingRequest() {
    return this.ongoingRequest;
  }

  resetService(){

  }

  getPersonRevision(currentPersonId, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('person/' + currentPersonId + '/revision', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  getEnterpriseRevision(currentEnterpriseId, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('enterprise/' + currentEnterpriseId + '/revision', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  getTheatrinoRevision(currentTheatrinoId, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('theatrino/' + currentTheatrinoId + '/revision', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  getCampRevision(currentCampId, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('camp/' + currentCampId + '/revision', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  getCourseScheduleRevision(currentCourseScheduleId, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('courseschedule/' + currentCourseScheduleId + '/revision', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  getCashFlowRevision(currentPrimaNotaRowId, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('primanota/' + currentPrimaNotaRowId + '/revision', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  getVehicleRevision(currentVehicleId, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('vehicle/' + currentVehicleId + '/revision', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  getReminderRevision(rowId, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('reminder/' + rowId + '/revision', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

}
