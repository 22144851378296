import {environment} from './../environment';
import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { BackendService } from './backend.service';
import { ShipmentService } from './shipment.service';

@Injectable()
export class WarehouseService {

  ongoingRequest: boolean = false;

  currentProducts: any[];
  currentShipments: any[];

  private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

  constructor(private backend: BackendService, private notify: NotificationsService, private http: HttpClient, private shipmentService:ShipmentService) {}

  getsubjectToUpdateObservable(): Observable<boolean> {
    return this.subjectToUpdate.asObservable();
  }

  resetService() {

    this.currentProducts = null;
    this.currentShipments = null;
  }

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  isOngoingRequest() {
    return this.ongoingRequest;
  }







  getAllProducts(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('warehouse/allproducts', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.currentProducts = resJson;

        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  getAllProductsByType(type: string, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('warehouse/type/'+type, params).subscribe(
      (response) => {

        let resJson : any = response;

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }







  searchProduct(query: any, tags:any = null, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    //  setto il parametro di ricerca per i tag
    if (tags != null)
      params = params.set('search_tags', tags);

    this.backend.get('warehouse/search/' + query, params).subscribe (
      (response) => {
        let resJson : any = response;

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );

  }




  saveProductInfo(model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.post('warehouse/product', model).subscribe(
      (response) => {

        this.currentProducts.push(response);

        this.subjectToUpdate.next(true);

        this.notify.success("Product Info", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  updateProductInfo(model: any) {

    this.startRequest();

    this.backend.put('warehouse/product/' + model.id, model).subscribe(
      (response) => {

        let resJson : any = response;

				var found = false;
				for (var i=0; i < this.currentProducts.length && !found; i++)
				{
					if (this.currentProducts[i].id == model.id) {
						this.currentProducts[i] = resJson;
						found = true;
					}
        }

        this.notify.success("Product Info", "successfully updated");

        this.subjectToUpdate.next(true);

        this.finishRequest();
      },
      (error) => {
        console.log("Errore");
        console.log(error);

        this.finishRequest();
      });
  }

  deleteProductInfo(model: any, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('warehouse/product/' + model.id).subscribe(
      (response) => {

        var found = false;
				for (var i=0; i < this.currentProducts.length && !found; i++)
				{
					if (this.currentProducts[i].id == model.id) {
						found = true;
						this.currentProducts.splice(i, 1);
					}
				}

        this.notify.success("Product Info", "successfully deleted");

        this.finishRequest();

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }




  //  Recupera la lista dei materiali per una spedizione in particolare
  getShipmentMaterials(model: any, callbackSuccess: any = null, callbackError: any = null)
  {
    this.shipmentService.getAllMaterialFromShipment(model, (shipmentMaterial) => {

      this.subjectToUpdate.next(true);

      if (callbackSuccess != null)
        callbackSuccess(shipmentMaterial);
    }, (error) => {

      if (callbackError != null)
        callbackError();
    })
  }


  updateShipment(model: any, callbackSuccess: any = null, callbackError: any = null)
  {
    this.startRequest();

    this.shipmentService.updateShipment(model, (shipment) => {

      for (var key in this.currentShipments)
        if (this.currentShipments[key].id == shipment.id)
           this.currentShipments[key] = shipment;

      this.subjectToUpdate.next(true);

      this.finishRequest();

      if (callbackSuccess)
        callbackSuccess(shipment);

    }, (error) => {

      this.finishRequest();

      if (callbackError)
        callbackError();
    });
  }



  getAllShipmentRequests(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('shipment/shipmentrequests', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.currentShipments = resJson;

        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }



  closeShipmentOrder(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('shipment/closeshipment', model).subscribe(
      (response) => {

        let resJson : any = response;

        this.currentShipments = resJson;

        this.finishRequest();

        this.subjectToUpdate.next(true);

        if (callbackSuccess != null)
        callbackSuccess();
      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });

  }


  reopenShipmentOrder(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('shipment/reopenshipment', model).subscribe(
      (response) =>{

        let resJson : any = response;

        this.currentShipments = resJson;

        this.finishRequest();

        this.subjectToUpdate.next(true);

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if(callbackError != null)
          callbackError();
      });
  }

}
