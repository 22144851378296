<alert type="warning" *ngIf="loadingData">
  <strong>Loading</strong> ...
</alert>

<!-- <span class="dashboard-stat dashboard-stat-v2 green-jungle">
    <div class="visual">
      <i class="fa fa-plus"></i>
    </div>
    <div class="details">
      <div class="number">
        <span>{{total_addin}}</span>
      </div>
      <div class="desc"> Total </div>
    </div>
  </span> -->

<div class="row">
  <div class="col-md-6">
      <div class="dashboard-stat2 ">
          <div class="display">
              <div class="number">
                  <h3 class="font-green-jungle">
                      <span>{{ (total_addin | currency:'EUR':'symbol':'1.2-2':'it') || "0,00" }}</span>
                      <!-- <small class="font-green-jungle">€</small> -->
                  </h3>
                  <small>TOTAL ADD IN</small>
              </div>
              <div class="icon">
                  <i class="icon-plus"></i>
              </div>
          </div>
          <div class="progress-info">
              <div class="progress">
                  <span [style.width]="total_addin_perc" class="progress-bar progress-bar-success green-jungle">
                      <span class="sr-only">{{total_addin_perc}} progress</span>
                  </span>
              </div>
              <div class="status">
                  <div class="status-title"> % OF THE TOTAL </div>
                  <div class="status-number"> {{total_addin_perc}} </div>
              </div>
          </div>
      </div>
  </div>
  <div class="col-md-6">
      <div class="dashboard-stat2 ">
          <div class="display">
              <div class="number">
                  <h3 class="font-red-haze">
                      <span>{{ (total_addout | currency:'EUR':'symbol':'1.2-2':'it') || "0,00" }}</span>
                      <!-- <small class="font-red-haze">€</small> -->
                  </h3>
                  <small>TOTAL ADD OUT</small>
              </div>
              <div class="icon">
                  <i class="icon-minus"></i>
              </div>
          </div>
          <div class="progress-info">
              <div class="progress">
                  <span [style.width]="total_addout_perc" class="progress-bar progress-bar-success red-haze">
                      <span class="sr-only">{{total_addout_perc}} progress</span>
                  </span>
              </div>
              <div class="status">
                  <div class="status-title"> % OF THE TOTAL </div>
                  <div class="status-number"> {{total_addout_perc}} </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="row">
  <div class="col-md-6">

      <alert type="warning" *ngIf="loadingData">
        <strong>Loading</strong> ...
      </alert>

      <ngx-datatable *ngIf="!loadingData"
      class="striped"
      [rows]="total_by_registered_via"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [scrollbarH]="true"
      [sorts]="[{prop: 'amount', dir: 'desc'}]"
      >
        <ngx-datatable-column name="Registered via" [flexGrow]="8" prop="name" [resizeable]="false"></ngx-datatable-column>
        <ngx-datatable-column name="Amount" [flexGrow]="2" prop="amount" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span [ngClass]="{'font-red-haze': value < 0, 'font-green-jungle': value > 0}" *ngIf="value">{{ (value | currency:'EUR':'symbol':'1.2-2':'it') || "-" }}</span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>

  </div>

  <div class="col-md-6">

      <alert type="warning" *ngIf="loadingData">
        <strong>Loading</strong> ...
      </alert>

      <ngx-datatable *ngIf="!loadingData"
      class="striped"
      [rows]="total_by_causal"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [scrollbarH]="true"
      [sorts]="[{prop: 'amount', dir: 'desc'}]"
      >
        <ngx-datatable-column name="Causal" [flexGrow]="8" prop="name" [resizeable]="false"></ngx-datatable-column>
        <ngx-datatable-column name="Amount" [flexGrow]="2" prop="amount" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span [ngClass]="{'font-red-haze': value < 0, 'font-green-jungle': value > 0}" *ngIf="value">{{ (value | currency:'EUR':'symbol':'1.2-2':'it') || "-" }}</span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>

  </div>
</div>



<!-- <div class="row">
    <div class="col-md-6">

        <alert type="warning" *ngIf="loadingData">
          <strong>Loading</strong> ...
        </alert>

        <ngx-datatable *ngIf="!loadingData"
        class="striped"
        [rows]="total_by_holder"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [sorts]="[{prop: 'amount', dir: 'desc'}]"
        >
          <ngx-datatable-column name="Holder" [flexGrow]="8" prop="name" [resizeable]="false"></ngx-datatable-column>
          <ngx-datatable-column name="Amount" [flexGrow]="2" prop="amount" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span class="red" *ngIf="value">{{ (value | currency:'EUR':'symbol':'1.2-2':'it') || "-" }}</span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

    </div>

    <div class="col-md-6">

        <alert type="warning" *ngIf="loadingData">
          <strong>Loading</strong> ...
        </alert>

        <ngx-datatable *ngIf="!loadingData"
        class="striped"
        [rows]="total_by_holder_type"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [sorts]="[{prop: 'amount', dir: 'desc'}]"
        >
          <ngx-datatable-column name="Holder type" [flexGrow]="8" prop="name" [resizeable]="false"></ngx-datatable-column>
          <ngx-datatable-column name="Amount" [flexGrow]="2" prop="amount" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span class="red" *ngIf="value">{{ (value | currency:'EUR':'symbol':'1.2-2':'it') || "-" }}</span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

    </div>
  </div> -->

