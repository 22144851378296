<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 class="form-section">Bank Account</h3>

    <div class="row">
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || payment_type.dirty || payment_type.touched) && !payment_type.valid }">
          <label class="control-label">Type of Payment <span class="required">*</span></label>
          <select class="form-control" name="payment_type" [(ngModel)]="model.type" #payment_type="ngModel" (change)="onTypeChange($event)"
            required>
            <option *ngFor="let p of getConfigService().paymentEnterpriseSelect" [value]="p.key">{{p.value}}</option>
          </select>
          <span class="help-block">Select payment type, required</span>
        </div>
        <div *ngIf="!editMode && model.type" class="form-group editMode">
          <label class="control-label">Type</label>
          <p>{{getConfigService().getPaymentEnterpriseValueByKey(model.type)}}</p>
        </div>
      </div>
      <div class="col-md-6" *ngIf="model.type">
        <div *ngIf="editMode" class="form-group">
          <label>Primary</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="primary" [(ngModel)]="model.primary" value="1" (ngModelChange)="valueChange($event)"> Yes, show this data in the search results and in the summary screen of this entity
              <span></span>
            </label>
          </div>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Primary</label>
          <p *ngIf="model.primary">Yes, show this data in the search results and in the summary screen of this person</p>
          <p *ngIf="!model.primary">No, hide this data in the search results and in the summary screen of this person</p>
        </div>
      </div>
    </div>

    <div *ngIf="model.type == 1">
      <div class="row">
        <div class="col-md-6">
          <div *ngIf="editMode" class="form-group">
            <label class="control-label">Bank Name</label>
            <input type="text" class="form-control" name="bank_name" #bank_name="ngModel" placeholder="" [(ngModel)]="model.bank_name">
            <span class="help-block">Write the bank name</span>
          </div>
          <div *ngIf="!editMode" class="form-group editMode">
              <label class="control-label">Bank Name</label>
              <p>{{model.bank_name}}</p>
            </div>
        </div>
        <div class="col-md-6">
          <div *ngIf="editMode" class="form-group">
            <label class="control-label">Holder</label>
            <input type="text" class="form-control" name="accountholder" #accountholder="ngModel" placeholder="" [(ngModel)]="model.accountholder">
          </div>
          <div *ngIf="!editMode" class="form-group editMode">
              <label class="control-label">Holder</label>
              <p>{{model.accountholder}}</p>
            </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || iban.dirty || iban.touched) && !iban.valid }">
            <label class="control-label">IBAN <span class="required">*</span></label>
            <input type="text" class="form-control" name="iban" #iban="ngModel" required placeholder="" [(ngModel)]="model.iban">
            <span class="help-block">This field is required</span>
          </div>
          <div *ngIf="!editMode" class="form-group editMode">
              <label class="control-label">IBAN</label>
              <p>{{model.iban}}</p>
            </div>
        </div>
        <div class="col-md-6">
          <div *ngIf="editMode" class="form-group">
            <label class="control-label">SWIFT</label>
            <input type="text" class="form-control" name="swift" placeholder="" [(ngModel)]="model.swift">
          </div>
          <div *ngIf="!editMode" class="form-group editMode">
              <label class="control-label">SWIFT</label>
              <p>{{model.swift}}</p>
            </div>
        </div>
      </div>

      <div class="row"  *ngIf="model.note || editMode">
        <div class="col-md-12">
          <div *ngIf="editMode" class="form-group">
            <label class="control-label">Notes</label>
            <textarea rows="3" class="form-control" name="note" placeholder="Some notes..." [(ngModel)]="model.note"></textarea>
          </div>
          <div *ngIf="!editMode" class="form-group editMode">
              <label class="control-label">Notes</label>
              <p class="line-breaker">{{model.note}}</p>
            </div>
        </div>
      </div>
    </div>



    <div *ngIf="model.type == 2">

      <div class="row"  *ngIf="model.accountholder || editMode">
        <div class="col-md-6">
          <div *ngIf="editMode" class="form-group">
            <label class="control-label">Holder</label>
            <input type="text" class="form-control" name="accountholder" #accountholder="ngModel" placeholder="" [(ngModel)]="model.accountholder">
          </div>
          <div *ngIf="!editMode" class="form-group editMode">
              <label class="control-label">Holder</label>
              <p>{{model.accountholder}}</p>
            </div>
        </div>
      </div>

      <div class="row" hidden>
        <div class="col-md-6">
          <div *ngIf="editMode" class="form-group">
            <label class="control-label">Bank Name</label>
            <input type="text" value="Paypal" class="form-control" name="bank_name" #bank_name="ngModel" placeholder="" [(ngModel)]="model.bank_name">
          </div>
          <div *ngIf="!editMode" class="form-group editMode">
              <label class="control-label">Bank Name</label>
              <p>{{model.bank_name}}</p>
            </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || email.dirty || email.touched) && !validateValue }">
            <label class="control-label">Email <span class="required">*</span></label>
            <input type="text" class="form-control" name="email" #email="ngModel" required placeholder="" [(ngModel)]="model.iban">
            <span class="help-block">Write the email used for PayPal, field required</span>
          </div>
          <div *ngIf="!editMode" class="form-group editMode">
              <label class="control-label">Email</label>
              <p>{{model.iban}}</p>
            </div>
        </div>
      </div>

      <div class="row"  *ngIf="model.note || editMode">
        <div class="col-md-12">
          <div *ngIf="editMode" class="form-group">
            <label class="control-label">Notes</label>
            <textarea rows="3" class="form-control" name="note" placeholder="Some notes..." [(ngModel)]="model.note"></textarea>
          </div>
          <div *ngIf="!editMode" class="form-group editMode">
              <label class="control-label">Notes</label>
              <p class="line-breaker">{{model.note}}</p>
            </div>
        </div>
      </div>
    </div>


  </div>


  <div [appCheckPermission]="'enterprise_edit'" class="form-actions right">
    <img *ngIf="getEnterpriseService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="this.model.id"
       [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline red" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="!this.model.id"
      (click)="delete()"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline blue" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="this.model.id && !editMode"
      (click)="edit()"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

    <button *ngIf="multiSave == false || editMode" type="submit" class="btn blue" [disabled]="getEnterpriseService().isOngoingRequest()"
      [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>
