<h3 class="">Search</h3>
<hr>
<form *ngIf="!isAdvancedSearchShow()" action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="input-group input-group-lg input-icon right" [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }">
        <i class="fa fa-times-circle" aria-hidden="true" (click)="model.simpleSearchKeyword = '';"></i>
        <input type="text" name="simpleSearchKeywordText" #simpleSearchKeywordText="ngModel" [(ngModel)]="model.simpleSearchKeyword"
          (keydown)="fieldChange($event);" class="form-control" placeholder="Search for tutor name...">
        <span class="input-group-btn">
          <button class="btn green" [disabled]="ongoingRequest" type="submit">Search</button>
        </span>
      </div>
      <small [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }">
        <span class="help-block">Type a word to search in surname and name, empty for search all</span>
      </small>
    </div>
   <!-- <div class="col-md-6">
     <div class="input-group input-group-lg input-icon right">
       <span class="input-group-btn">
   	     <a class="btn green" target="_blank" href={{tutorApplicationFormUrl}}>Application form</a> -->
<!--       <button type="button" [disabled]="ongoingRequest" (click)="showAdvancedSearch()" class="btn default btn-lg">Advanced Search
        <i class="fa fa-search"></i>
      </button> -->
       <!-- </span>
     </div>
    </div> -->
  </div>
</form>
