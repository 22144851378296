import { Component, OnInit, ViewChild, Input, ViewChildren, QueryList } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PersonDatatableComponent} from "../person-datatable/person-datatable.component";
import { PersonSearchFormComponent } from "../person-search-form/person-search-form.component";
import { PersonInfoShowComponent } from "../person-info-show/person-info-show.component";
import { PersonService } from './../../_services/index';

@Component({
  selector: 'app-person-family-form',
  templateUrl: './person-family-form.component.html',
  styleUrls: ['./person-family-form.component.css']
})
export class PersonFamilyFormComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('personDatatable', {static: false}) public personDatatable: PersonDatatableComponent;
  @ViewChild('personSearchForm', {static: false}) public personSearchForm: PersonSearchFormComponent;
  @ViewChildren('personInfoShow') public personInfoShow: QueryList<PersonInfoShowComponent>;

  @Input() model: any;
  familyInfo: any[];

  currentTypeMemeber: string;

  subscribe: any;

  constructor(private personService: PersonService) { }

  ngOnInit() {
    this.model = this.personService.currentPersonFamily;

    if (this.model == null)
      this.model = {};

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      this.model = this.personService.currentPersonFamily;

      if (this.model == null)
        this.model = {};

    });
  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }

  addFamilyMember(type) {

    // 0 mother, 1 father, 2 children
    this.currentTypeMemeber = type;

    this.showModal();
  }

  removeFamilyMember(type){

    this.model[type + "_id"] = null;
    this.submit();
  }

  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  searchKeywordsSubmit(params) {
    this.personDatatable.query = params;
    this.personDatatable.getResults();
  }

  personSelected(selected) {

    this.model[this.currentTypeMemeber + "_id"] = selected.person_id;
    this.submit();
    this.hideModal();
  }

  submit() {
    if (this.model.id)
      this.personService.updatePersonFamily(this.model);
    else
      this.personService.savePersonFamily(this.model);
  }

  getPersonService() {
    return this.personService
  }
}
