
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';



@Injectable()
export class CountryPickerService {

  //	questo servizio carica un JSON esterno dalla seguente cartella
  //	questo JSON fa riferimento a quello standard usato qui https://mledoze.github.io/countries/
  private dataUrl = '../assets/countries.json';
  private data: any = [];

  constructor(private http: HttpClient) {
    this.getCountries(() => {});
  }

  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      if (error.status === 404) {
        errMsg = 'Error loading countries.json file. Please configure WebPack and load countries.json assets to your root folder';
      }
    }
    else {
      errMsg = error.message ? error.message : error.toString();
    }

    console.error(errMsg);

    return observableThrowError(errMsg);
  }

  public getCountries(callback: any) {

    // return this.data.pipe(countries => countries.pipe(country => {
    // 	return country;
    // }))

    if (this.data.length > 0) {
      callback(this.data);
      return;
    }

    this.http.get(this.dataUrl).subscribe(
      (response) => {
        this.data = response;
        callback(this.data);
      },
      (error) => {
        this.handleError(error);
      });
  }

}
