
<h3>Group scheduling</h3>
<hr>
<!-- begin: prima riga -->
<div class="row">
  <div class="col-md-12">

        <alert type="warning" *ngIf="loadingData">
          <strong>Loading</strong> ...
        </alert>

        <!-- BEGIN FORM -->
        <form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

          <div class="form-body">

            <!-- BEGIN: inserimento dati -->
            <div class="row">
              <div class="col-md-3">
                <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localData)) }">
                  <label class="control-label">Date <span class="required">*</span></label>
                    <div class="input-group">
                      <!-- input box -->
                      <input angular-mydatepicker #dp="angular-mydatepicker" name="date" class="form-control" [locale]="getHelper().locale" [options]="getDatePickerOptions()"
                      [ngModel]="localData" (dateChanged)="onDateChanged($event)" required />
                      <!-- clear date button -->
                      <span class="input-group-btn">
                        <button type="button" *ngIf="model?.date" class="btn default" (click)="dp.clearDate()"><i class="fa fa-close"></i></button>
                      </span>
                      <!-- toggle calendar button -->
                      <span class="input-group-btn">
                          <button type="button" class="btn default" (click)="dp.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                      </span>
                  </div>
                  <span class="help-block">Date is required</span>
                </div>
              </div>
              <div class="col-md-4">
                <label class="control-label">&nbsp;</label>
                <p>
                  <button type="submit" class="btn blue" [disabled]="loadingData">
                    Search &nbsp;
                    <i class="fa fa-search"></i>
                  </button>
                  <button type="button" class="btn green" [disabled]="loadingData" (click)="goBack()">
                    <i class="fa fa-arrow-left"></i>
                  </button>
                  <button type="button" class="btn green" [disabled]="loadingData" (click)="forward()">
                    <i class="fa fa-arrow-right"></i>
                  </button>
                </p>
              </div>
            </div>
            <!-- END: inserimento dati -->

          </div>

        </form>
        <!-- END FORM -->

        <!-- BEGIN: risultati della ricerca -->
        <app-theatrino-group-scheduling-review *ngIf="!loadingData && rawData" [rawData]="this.rawData"></app-theatrino-group-scheduling-review>
        <!-- END: risultati della ricerca -->

  </div>
</div>
<!--end: prima riga -->
