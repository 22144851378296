<!-- <alert type="warning" *ngIf="ongoingRequest">
  <strong>Loading</strong> ...
</alert> -->
<div class="datatable-loader text-center" *ngIf="ongoingRequest">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>
  
<ngx-datatable *ngIf="!ongoingRequest"
  class="striped"
  [rows]="rows"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="true"
  [externalSorting]="true"
  [count]="pageTotalElements"
  [offset]="pageNumber"
  [limit]="pageSize"
  [selected]="selected"
  [selectionType]="'single'"
  (activate)="onActivate($event)"
  (select)='onSelect($event)'
  (page)='setPage($event)'
  (sort)='onSort($event)'
>  
  <ngx-datatable-column name="Name" [flexGrow]="1" prop="firstname" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Surname" [flexGrow]="1" prop="surname" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Email" [flexGrow]="2" prop="email" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Telephones" [flexGrow]="3" prop="mobile_telephone" [resizeable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>      
      	<span>{{value}} (mobile)</span>
      	<span>{{row['home_telephone']}} (home)</span>
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Imported" [flexGrow]="1" [resizeable]="false" [draggable]="false" prop="imported">
  	<ng-template ngx-datatable-cell-template let-row="row" let-value="value">
    	<i *ngIf="value == true" class="fa fa-check-circle" style="color:green; padding-left: 25%;"></i>
        <i *ngIf="value == false" class="fa fa-close" style="color:red; padding-left: 25%;"></i>
    </ng-template>
  </ngx-datatable-column>
  
</ngx-datatable>
