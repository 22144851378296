<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/person/home']">Person</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span>Search</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Ricerca Persone
        <small></small>
      </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->
      <div class="row">
        <div class="col-md-12">
          <app-person-search-form (searchKeywordsSubmit)="searchKeywordsSubmit($event)" [ongoingRequest]="datatable.ongoingRequest"></app-person-search-form>
        </div>
      </div>

      <br><br>

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">
            <!--end: Portlet-Body -->
            <div class="portlet-body">
              <app-person-datatable (personSelected)="personSelected($event)"></app-person-datatable>
              <div class="row">
                <div class="pull-right">
                  <button [appCheckPermission]="'person_export'" *ngIf="!datatable.ongoingRequest && datatable.rows.length > 0" type="button" class="btn green" (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button>
                </div>
              </div>
            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->


<!-- BEGIN: MODAL CONTENT PERSON INFO -->
<div [hidden]="!isModalShown" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Person Info Summary</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body form">
        <div class="row">
          <div class="col-md-12">
            <app-person-info-summary #personInfoSummaryComponent [idPerson]="currentPersonId" (closeEvent)="hideModal()" ></app-person-info-summary>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL CONTENT PERSON INFO -->

<!-- BEGIN: MODAL LOADING EXPORT-->
<div *ngIf="isModalExport" [config]="{ show: true, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal #staticModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
