<!-- BEGIN FORM -->
<form (keydown.enter)="checkSubmitKeyEnter($event)" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">
    <h3 class="form-section">Vehicle Info</h3>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || vehicle_type.dirty || vehicle_type.touched) && !vehicle_type.valid && !vehicle_type.disabled }">
          <label class="control-label">Vehicle Type <span class="required">*</span></label>
          <select [disabled]="getVehicleService().isOngoingRequest()" class="form-control" name="vehicle_type" [(ngModel)]="model.vehicle_type" #vehicle_type="ngModel" required>
            <option *ngFor="let v of getConfigService().vehicleTypeSelect" [value]="v.key">{{v.value}}</option>
          </select>
          <span class="help-block"> Select vehicle type is required</span>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || vehicle_name.dirty || vehicle_name.touched) && !vehicle_name.valid && !vehicle_name.disabled }">
          <label class="control-label">Name <span class="required">*</span></label>
          <input [disabled]="getVehicleService().isOngoingRequest()" type="text" name="vehicle_name" [(ngModel)]="model.vehicle_name" #vehicle_name="ngModel" required class="form-control">
          <span class="help-block">Name is required </span>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || plate_number.dirty || plate_number.touched) && !plate_number.valid  && !plate_number.disabled}">
          <label class="control-label">Plate Number <span class="required">*</span></label>
          <input [disabled]="getVehicleService().isOngoingRequest()" type="text" name="plate_number" [(ngModel)]="model.plate_number" #plate_number="ngModel" required class="form-control" placeholder="AB123AA">
          <span class="help-block">Plate Number is required</span>
        </div>
        <div class="form-group">
          <label class="control-label">Active</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input [disabled]="getVehicleService().isOngoingRequest()" type="checkbox" #active="ngModel" name="active" [(ngModel)]="model.active" value="1"> Yes
              <span></span>
            </label>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-md-offset-3 mt-element-card">
        <alert type="warning" *ngIf="model.id == undefined">
          <i class="fa fa-warning"></i> First save the form
        </alert>
        <div class="mt-card-item">
          <div *ngIf="model.photoUrl" class="mt-overlay-1" aria-hidden="true">
            <img src="{{model.photoUrl}}?{{this.model.updated_at}}" style="width: 100%;">
          </div>
          <div *ngIf="!model.photoUrl" class="mt-overlay-1" aria-hidden="true">
            <img src="../assets/placeholder/car-placeholder.jpg" width="100%">
          </div>
          <div [appCheckPermission]="'vehicle_edit'" *ngIf="model.id != undefined">
            <div *ngIf="model.photoUrl == undefined || model.photoUrl == ''">
              <input *ngIf="!photoUploader.isUploading" type="file" id="photoUploader"
                ng2FileSelect [uploader]="photoUploader" />
              <label *ngIf="!photoUploader.isUploading" for="photoUploader"
                class="btn btn-lg btn-block green-jungle text-uppercase label-file"><i class="fa fa-upload"></i>&nbsp; Upload picture!</label>
            </div>
            <div *ngIf="photoUploader.isUploading || getVehicleService().isOngoingRequest()">
              <img src="./assets/global/img/input-spinner.gif" />
              please wait...
            </div>
            <div *ngIf="model.photoUrl != undefined && model.photoUrl != ''">
              <button *ngIf="!photoUploader.isUploading" type="button"
                class="btn btn-danger btn-block text-uppercase label-file" (click)="destroyFile();"><i
                  class="fa fa-trash"></i>&nbsp; Remove Picture</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 'brand_name'
    'brand_model'
    'color'
    'shift_type' -->

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Brand name</label>
          <input class="form-control" [disabled]="getVehicleService().isOngoingRequest()" type="text" name="brand_name" [(ngModel)]="model.brand_name" placeholder="Fiat">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Brand Model</label>
          <input class="form-control" [disabled]="getVehicleService().isOngoingRequest()" type="text" name="brand_model" [(ngModel)]="model.brand_model" placeholder="Punto">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Color</label>
          <input class="form-control" [disabled]="getVehicleService().isOngoingRequest()" type="text" name="color" [(ngModel)]="model.color" placeholder="Rossa">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Shift type </label>
          <select [disabled]="getVehicleService().isOngoingRequest()" class="form-control" name="shift_type" [(ngModel)]="model.shift_type" #shift_type="ngModel">
            <option *ngFor="let c of getConfigService().vehicleShiftTypeSelect" [value]="c.key">{{c.value}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Contract Type</label>
          <select [disabled]="getVehicleService().isOngoingRequest()" class="form-control" name="contract_type" [(ngModel)]="model.contract_type" #contract_type="ngModel">
            <option *ngFor="let c of getConfigService().vehicleContractTypeSelect" [value]="c.key">{{c.value}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4" [hidden]="!model.contract_type">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localContractStartData, false)) }">
          <label class="control-label">Contract Start Date</label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_contract_start_date="angular-mydatepicker" name="contract_start_date" class="form-control"
                [locale]="getHelper().locale"
                [disabled]="getVehicleService().isOngoingRequest()"
                [options]="getDatePickerOptions()"
                [ngModel]="getLocalContractStartData()"
                (dateChanged)="onDateContractStartChanged($event)"
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.contract_start_date" class="btn default" (click)="dp_contract_start_date.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_contract_start_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block">Field required</span>
        </div>
      </div>
      <div class="col-md-4" [hidden]="!model.contract_type">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localContractEndData, false)) }">
          <label class="control-label">Contract End Date</label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_contract_end_date="angular-mydatepicker" name="contract_end_date" class="form-control"
                [locale]="getHelper().locale"
                [disabled]="getVehicleService().isOngoingRequest()"
                [options]="getDatePickerOptions()"
                [ngModel]="getLocalContractEndData()"
                (dateChanged)="onDateContractEndChanged($event)"
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.contract_end_date" class="btn default" (click)="dp_contract_end_date.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_contract_end_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block">Field required</span>
        </div>
      </div>
    </div>

  </div>

  <div [appCheckPermission]="'vehicle_edit'" class="form-actions right">
    <img *ngIf="getVehicleService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getVehicleService().isOngoingRequest()" *ngIf="getVehicleService().currentVehicleId"
       [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="submit" class="btn blue" [disabled]="getVehicleService().isOngoingRequest()" *ngIf="getVehicleService().currentVehicleId">Update</button>
    <button type="submit" class="btn green-jungle" [disabled]="getVehicleService().isOngoingRequest()" *ngIf="!getVehicleService().currentVehicleId">Continue</button>
  </div>

</form>
