<h3 class="">Search</h3>
<hr>
<form *ngIf="!advancedSearchShow" action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
  <div class="row" >
    <div class="col-md-6">
      <div class="input-group input-group-lg" [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }">
        <input type="text" name="simpleSearchKeywordText" #simpleSearchKeywordText="ngModel" [(ngModel)]="model.simpleSearchKeyword" (keydown)="fieldChange($event);" required minlength="3"
          class="form-control" placeholder="Search for Theatrino name...">
        <span class="input-group-btn"><button class="btn green" [disabled]="ongoingRequest" type="submit">Search</button></span>
      </div>
      <small [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }"><span class="help-block">Type a word to search in Theatrino name, min length 3 chars</span></small>
    </div>
    <div class="col-md-6">
      <button type="button" [disabled]="ongoingRequest" (click)="showAdvancedSearch()" class="btn default btn-lg">Advanced Search
        <i class="fa fa-search"></i>
      </button>
      <button type="button" [disabled]="ongoingRequest" (click)="getAllResources(f);" class="btn btn-lg default">Get All <i class="fa fa-search"></i></button>
    </div>
  </div>
</form>


<form *ngIf="advancedSearchShow" action="#" name="form2" (ngSubmit)="f2.form.valid && submitSearch()" #f2="ngForm" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group" [ngClass]="{ 'has-error': f2.submitted && !theatrino_denomination.valid }">
        <label class="control-label">Theatrino Name</label>
        <input type="text" name="theatrino_denomination" #theatrino_denomination="ngModel" [(ngModel)]="model.theatrino_denomination" (keydown)="fieldChange($event);" minlength="3"
          class="form-control" placeholder="Search for Theatrino name...">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">Theatrino Active</label>
        <div class="mt-checkbox-inline">
          <label class="mt-checkbox mt-checkbox-outline">
            <input type="checkbox" name="theatrino_active" [(ngModel)]="model.theatrino_active" [checked]="1" (ngModelChange)="fieldChange($event)"> &nbsp;
            <span></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="form-actions right">
    <button type="button" class="btn" (click)="hideAdvancedSearch()">Close</button>
    <button type="button" class="btn default" (click)="clearAdvancedSearch()">Clear
        <i class="fa fa-eraser" aria-hidden="true"></i>
    </button>
    <button type="submit" [disabled]="ongoingRequest" class="btn green">Search
      <i class="fa fa-search"></i>
    </button>
  </div>
</form>
  