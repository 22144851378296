<alert type="warning" *ngIf="loading">
  <strong>Loading</strong> ...
</alert>

<div [hidden]="loading">

  <form action="#" class="horizontal-form" novalidate>
    <div class="form-body">
      <div class="row">
        <div class="col-md-5">
          <div class="form-group read-only">
            <label class="control-label">Name</label>
            <p>{{personInfo.surname}} {{personInfo.first_name}}</p>
          </div>
          <div *ngIf="personInfo.tax_id" class="form-group read-only">
            <label class="control-label">Fiscal Code/Tax ID &nbsp;
              <check-person-taxid [personInfo]="personInfo"></check-person-taxid>
            </label>
            <p>{{personInfo.tax_id}}</p>
          </div>
          <div *ngIf="personInfo.nationality" class="form-group read-only">
            <label class="control-label">Nationality</label>
            <p>{{personInfo.nationality}}</p>
          </div>
        </div>

        <div class="col-md-4">
          <div *ngIf="dataFormatted" class="form-group read-only">
            <label class="control-label">Birthday</label>
            <p>{{dataFormatted}}</p>
          </div>
          <div *ngIf="personInfo.birthplace" class="form-group read-only">
            <label class="control-label">Birthplace</label>
            <p>{{personInfo.birthplace}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Info</label>
            <p>
              <i class="fa fa-car"></i>&nbsp;
              <span *ngIf="personInfo.driver_license">SI</span>
              <span *ngIf="!personInfo.driver_license">NO</span>&nbsp;&nbsp;
              <i class="fa fa-globe" aria-hidden="true"></i>&nbsp;
              <span *ngIf="personInfo.native_english_speaker">SI</span>
              <span *ngIf="!personInfo.native_english_speaker">NO</span>
            </p>
          </div>
        </div>

        <div class="col-md-3 mt-element-card">
          <div class="mt-card-item">
            <div *ngIf="personInfo.avatarUrl" class="mt-overlay-1" aria-hidden="true">
              <img *ngIf="personInfo.avatarUrl" src="{{personInfo.avatarUrl}}?{{personInfo.updated_at}}" class="img-responsive pic-bordered"
                alt="">
            </div>
            <div *ngIf="!personInfo.avatarUrl" class="mt-overlay-1" aria-hidden="true">
              <img src="../assets/placeholder/avatar-placeholder.jpg" width="100%">
            </div>
          </div>
        </div>

        <div *ngIf="personInfo.annotation" class="col-md-9">
          <div class="form-group read-only">
            <label class="control-label">Notes</label>
            <div class="line-breaker">{{personInfo.annotation}}</div>
          </div>
        </div>
      </div>


      <h4 class="form-section">Roles</h4>

      <div class="row">
        <alert *ngIf="noRoleAlert" type="warning">
          <strong>Alert!</strong> no roles found
        </alert>
        <div *ngFor="let role of personInfo.role | mapFromKeyValue" class="col-md-6">
          <div class="form-group read-only">
            <p>{{getConfigService().getRolePersonValueByKey(role.type)}}</p>
            <p>
              <app-enterprise-info-show [idEnterprise]="role.enterprise_id"></app-enterprise-info-show>
            </p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Contacts</h4>

      <div class="row">
        <div *ngFor="let cont of personInfo.contact | mapFromKeyValue" class="col-md-6">
          <div class="form-group read-only">
            <label class="control-label">{{ getConfigService().getContactPersonValueByKey(cont.type)}}</label>
            <p>{{cont.value}}</p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Address</h4>

      <div class="row">
        <div *ngFor="let add of personInfo.address | mapFromKeyValue" class="col-md-6">
            <alert *ngIf="!add.country_long_version" type="warning">
                <strong>Alert!</strong> address is not googlized
              </alert>
          <div class="form-group read-only">
            <label class="control-label">{{getConfigService().getAddressPersonValueByKey(add.type)}}</label>
            <p>
              {{ getConfigService().convertAddressAsStringForTable(add) }}
            </p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Bank Accounts</h4>

      <div class="row">
        <div *ngFor="let bank of personInfo.bankaccount | mapFromKeyValue" class="col-md-6">
          <div class="form-group read-only">
            <label class="control-label">{{ getConfigService().getPaymentPersonValueByKey(bank.type)}}</label>
            <p>{{bank.iban}}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="form-actions right">
      <button type="button" class="btn btn-outline red" (click)="close()">
        <i class="fa fa-times" aria-hidden="true"></i>&nbsp;Close</button>&nbsp;
      <a class="btn btn-outline blue" [appCheckPermission]="'person_edit'" routerLink="/person/edit/{{ currentId }}">
        <i class="fa fa-edit"></i>&nbsp;Edit
      </a>&nbsp;
      <button [appCheckPermission]="'person_summary_export'" type="button" class="btn btn-outline blue" (click)="getPersonService().downloadTutorFormPerson(currentId, personInfo)">
        <i class="fa fa-print" aria-hidden="true"></i>&nbsp;PDF</button>&nbsp;
    </div>

  </form>
</div>
