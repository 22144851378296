<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/theatrino/edit/'+getTheatrinoService().currentTheatrinoId]">Theatrino {{getTheatrinoService().currentTheatrinoInfo?.denomination}}</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span *ngIf="getTheatrinoService().currentTheatrinoInfo && !loadingData && !tourInfo">New Tour Date</span>
            <span *ngIf="getTheatrinoService().currentTheatrinoInfo && !loadingData && tourInfo">Edit Tour Date</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>

      <app-reminder-widget-alertbox [holderType]="'App\\TheatrinoTourDate'" [holderId]="getTheatrinoService().currentTourId"
      [holderName]="tourInfo?.date_formatted"></app-reminder-widget-alertbox>

      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title" *ngIf="getTheatrinoService().currentTheatrinoInfo && !loadingData && !tourInfo">Theatrino <b>{{theatrinoInfo?.denomination}}</b>: new date</h1>
      <h1 class="page-title" *ngIf="getTheatrinoService().currentTheatrinoInfo && !loadingData && tourInfo">Theatrino <b>{{theatrinoInfo?.denomination}}</b>: {{tourInfo?.date_formatted}}</h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">

            <!--end: Portlet-Body -->
            <div class="portlet-body form">

            <alert type="warning" *ngIf="loadingData">
              <strong>Loading</strong> ...
            </alert>

            <tabset #tourTabs class="align-right portlet-title tabbable-line" [hidden]="loadingData">
              <tab heading="Info"><app-theatrino-booking-info-form #theatrinoBookingInfoForm (enableTab)="enableTab($event)" (disableTab)="disableTab($event)"></app-theatrino-booking-info-form></tab>
              <tab heading="Shows List">
                <app-theatrino-booking-show-list  [theatrinoInfo]="theatrinoBookingInfoForm"
                                                  [lockedMode]="theatrinoBookingInfoForm.lockedMode">
                </app-theatrino-booking-show-list>
              </tab>
              <tab heading="Reminder"><app-theatrino-booking-reminder></app-theatrino-booking-reminder></tab>
            </tabset>

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
