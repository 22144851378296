import { HttpClient } from '@angular/common/http';
import { IAngularMyDpOptions, IMyDateModel, IMyInputFieldChanged } from 'angular-mydatepicker';
import { NgForm } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfigService, HelperService, CampService, BackendService, UserService } from './../../_services/index';
import { CampComponent } from './../camp.component';
import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList, ElementRef, DoCheck } from '@angular/core';
import { EnterpriseInfoShowComponent, EnterpriseSearchFormComponent, EnterpriseDatatableComponent, EnterpriseHistoryComponent } from './../../enterprise/index';
import { Router } from '@angular/router';
import { differenceInWeeks } from 'date-fns';
import { parseISO } from 'date-fns';
import { NotificationsService } from 'angular2-notifications';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-camp-info-form',
  templateUrl: './camp-info-form.component.html',
  styleUrls: ['./camp-info-form.component.css']
})
export class CampInfoFormComponent implements OnInit, DoCheck {

  //  componenti per la ricerca della stazione ferroviaria
  //  dati scaricati dal sito datiopen.it
  private dataUrl = '../assets/railway-stations.json';
  asyncSelected: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  stationComplex: any = [];

  onlineErrorArr: any[] = [];
  onlineWarnArr: any[] = [];

  provisionalWeekValid: boolean = true;

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('enterpriseDatatable', {static: false}) public enterpriseDatatable: EnterpriseDatatableComponent;
  @ViewChild('enterpriseSearchForm', {static: false}) public enterpriseSearchForm: EnterpriseSearchFormComponent;
  @ViewChild('confirmModal', {static: false}) confirmModal: ModalDirective;
  @ViewChildren('enterpriseInfoShowVenue') public enterpriseInfoShow: QueryList<EnterpriseInfoShowComponent>;
  @ViewChildren('provisionalInput') public provisionalInputs: QueryList<ElementRef>;

  @ViewChild('f', {static: true}) currentForm: NgForm;

  //  file uploaders
  public leafletUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf', 'application/msword'],
      url: 'serverUrl'
    }
  );
  public conditionUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf', 'application/msword'],
      url: 'serverUrl'
    }
  );
  public venueUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf', 'application/msword'],
      url: 'serverUrl'
    }
  );

  //  numero di settimane del camp che si sta utilizzando
  campWeeks: number = 0;

  public localDataStart: IMyDateModel;
  public localDataEnd: IMyDateModel;

  public accounting_folder: any;

  //  Impostazione della data che vengono passate dall'helper
  public datePickerOptionsBegin: IAngularMyDpOptions = {};
  public datePickerOptionsEnd: IAngularMyDpOptions = {};

  lockedMode = false;
  editMode = false;
  editModeCampDirector = false;

  model: any = null;

  //  Array con le info dell'odience da selezionare
  audienceModel:any = null;

  @Input() campComponent: CampComponent;

  //  metodo e parametri per il caricamento dei file
  public inUpload: boolean = false;

  //  url di caricamento dei files
  private headers: any;

  constructor(private configService: ConfigService, private helper: HelperService,
              private router: Router, private campService: CampService,
              private notify: NotificationsService, private http: HttpClient, private userService: UserService) {

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;

    //  carico l'array dalle risorse
    this.loadStations();
   }

  subscribe: any;

  ngDoCheck ()
  {
    this.onlineWarnArr = new Array;

    if (this.model.buyable == false)
      this.onlineWarnArr.push("non è stato spuntato 'acquistabile'");

    this.campWeeks = this.calculateCampWeek();

    if (this.model.venue_id)
      this.campBuyableOnlineCheck;
  }

  ngOnInit() {

    //  Inizializzo le audience
    this.audienceModel = {};
    this.configService.showAudienceSelect.forEach(element => {
      this.audienceModel[element.key] = false;
    });

    //  La prima volta che entro controllo che non sia nullo il model
    if (this.model == null)
    {
        this.model = {};
        this.model.lock_week = [];
        this.model.not_available_week = [];
        this.editMode = true;
    }

    this.subscribe = this.campService.getsubjectToUpdateObservable().subscribe((update:boolean) => {
        this.getCampInfo();
    });

    if (this.campService.currentCampInfo != null){
      this.getCampInfo();
    }

  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  getCampInfo() {

    this.model =  JSON.parse(JSON.stringify(this.campService.currentCampInfo));

      if (this.model == null)
      {
        this.model = {};
        this.model.lock_week = [];
        this.model.not_available_week = [];
        this.editMode = true;
      }
      else
      {
        this.editMode = false;
        this.editModeCampDirector = false;

        //  Setto l'url per il caricamento delle foto e gli header
        // this.headers = this.campService.getFileUploadHeader();
        this.leafletUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
        this.leafletUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
          this.leafletUploaded(item, response, status);
        };
        this.leafletUploader.options.url = this.campService.getLeafletFileUploadURL(this.model);

        this.conditionUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
        this.conditionUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
          this.conditionUploaded(item, response, status);
        };
        this.conditionUploader.options.url = this.campService.getConditionFileUploadURL(this.model);

        this.venueUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
        this.venueUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
          this.vrequestUploaded(item, response, status);
        };
        this.venueUploader.options.url = this.campService.getVrequestFileUploadURL(this.model);

        //   Se esiste nel modello la data di start
        if (this.model.start != null)
          this.localDataStart = this.helper.initMyDataInputSingle(this.model.start);

        //   Se esiste nel modello la data di end
        if (this.model.end != null)
          this.localDataEnd = this.helper.initMyDataInputSingle(this.model.end);

        // calcola il numero di settimane per questo campo, ! fondamentale
        this.campWeeks = this.calculateCampWeek();

        //  controllo le settimane di iscrizione
        this.checkLockWeeks();

        //  controllo le settimane bloccate
        this.checkNotAvailableWeeks();

        //   Se esiste nel modello la data della cartellina contabile
        if (this.model.accounting_folder != null)
          this.accounting_folder = this.helper.initMyDataInputSingle(this.model.accounting_folder);

        //  Se il model ha informazioni da mostrare faccio il parser della audience
        if (this.model.audience != null)
          this.audienceModel = JSON.parse(this.model.audience);

        //  Se esiste una convenzione generata non devo permettere la modifica dei campi
        if (this.model.agreement_id != null && typeof this.model.agreement_id != "undefined")
          this.lockedMode = true;
      }
  }

  edit() {

    //controllo se non ho i permessi di edit verifico se sono una camp director ed abilito una modalità ridotta di edit
    if (this.userService.checkPermission('camp_edit')){
      this.editModeCampDirector = true;

      //  faccio eccezione nel caso esiste una convenzione generata
      if (!this.lockedMode)
        this.editMode = true;

      return;
    }

    let p = this.userService.getCurrentUser().person_info;

    if (this.campService.isPersonCampDirector(p.id)){
      this.editModeCampDirector = true;
      return;
    }
  }

  submit() {

    if (!this.helper.isMyDateValid(this.localDataStart) || !this.helper.isMyDateValid(this.localDataEnd)) return;

    if (this.audienceModel != null)
      this.model.audience = JSON.stringify(this.audienceModel);

    // controllo solo se sto effettuando l'edit global e non quello limitato alle informazioni travel che può modificare la Camp Director
    if (this.editMode){
      //  Se non sono state impostate in giusto numero di settimane in base alla data del camp non posso andare avanti
      if (!this.isProvisionalWeeksValid) return;
    }

    //  controllo se nelle settimane di camp impostate non ci siano iscritti
    if (!this.canSubscribersForWeeks){
      this.notify.error("Camp Date Error", "Unable to change the camp duration. Please remove all subscribers and ritirati (restore, edit and than delete again) that are part of the week you want to delete");
      return;
    }

    //  conteollo se nelle settimane di camp impostate non ci siano membri dello staff
    if (!this.canStaffforWeeks) {
      this.notify.error("Camp Date Error", "Unable to change the camp duration. Please remove all Staff Members that are part of the week you want to delete.")
      return;
    }

    //  Invio un messaggio di warning tramite modale se non è acquistabile online
    if(!this.campBuyableOnlineCheck || !this.checkBuyable)
      this.confirmModal.show();
    else
      this.confirmSubmit();

  }

  confirmSubmit()
  {
    this.confirmModal.hide();

    if(!this.campBuyableOnlineCheck || !this.checkBuyable)
      this.model.buyable = false;

    if (this.model.id != null)
      this.campService.updateCampInfo(this.model, () => { this.editMode = false; this.editModeCampDirector = false; });
    else
    {
      //  provo a cambiare il routing al salvataggio che mi sembra più giusto
      this.campService.saveCampInfo(this.model, () => {

        this.router.navigate(["camp/edit/"+ this.campService.currentCampId]);
      });
    }
  }

  destroy()
  {
    this.campService.deleteCampInfo(this.model, () => {
      this.router.navigate(['camp/home/']);
    });
  }

  calculateCampWeek()
  {
    if (this.model.start != null && typeof this.model.start != "undefined" &&
          this.model.end != null && typeof this.model.end != "undefined")
        return differenceInWeeks(parseISO(this.model.end), parseISO(this.model.start)) + 1;

    return 0;
  }


  //  controllo se sia possibile cambiare la data di un camp in base a se ci sono
  //    iscritti nelle settimane che vengono eliminate
  get canSubscribersForWeeks ()
  {
    if (this.campService.currentCampSubscriberList != null && typeof this.campService.currentCampSubscriberList != "undefined")
    {
      for (let subscriber of this.campService.currentCampSubscriberList) {
        if (subscriber.camp_week.length > this.campWeeks)
          return false;
      }
    }

    return true;
  }


  //  controllo se sia possibile cambiare la data di un camp in base a se ci sono
  //    membri dello staff nelle settimane che vengono eliminate
  get canStaffforWeeks ()
  {
    if (this.campService.currentCampStaffList != null && typeof this.campService.currentCampStaffList != "undefined")
    {
      for (let staffM of this.campService.currentCampStaffList) {
        switch (this.campWeeks) {
          case 1:
            if (staffM.week_02 != null)
            return false;
          case 2:
            if (staffM.week_03 != null)
            return false;
          case 3:
            if (staffM.week_04 != null)
            return false;
        }
      }
    }

    return true;
  }

  //  evento di cambiamento delle settimane di blocco
  lockWeekChanged(numOfWeek, value)
  {
    //   se lo sto aggiungendo
    if (value == true)
      this.model.lock_week.push(numOfWeek);
    //  se lo sto rimuovendo
    else
    {
      var index = this.model.lock_week.indexOf(numOfWeek);
      if (index > -1)
        this.model.lock_week.splice(index, 1);
    }

  }

  //  Controlla se le settimane bloccate per l'iscrizione siano correttamente inserite
  checkLockWeeks ()
  {
    if (this.model.lock_week == null || typeof this.model.lock_week == "undefined")
      this.model.lock_week = [];

    for (var index in this.model.lock_week)
    {
      switch (this.model.lock_week[index]) {
        case 1:
          this.model.lock_week_01 = true;
          break;
        case 2:
          this.model.lock_week_02 = true;
          break;
        case 3:
          this.model.lock_week_03 = true;
          break;
      }
    }
  }


  //  evento di cambiamento delle settimane non disponibili
  notAvailabeWeekChanged(numOfWeek, value)
  {
    //   se lo sto aggiungendo
    if (value == true)
      this.model.not_available_week.push(numOfWeek);
    //  se lo sto rimuovendo
    else
    {
      var index = this.model.not_available_week.indexOf(numOfWeek);
      if (index > -1)
        this.model.not_available_week.splice(index, 1);
    }

  }

  //  Controlla se le settimane bloccate per l'iscrizione siano correttamente inserite
  checkNotAvailableWeeks ()
  {
    if (this.model.not_available_week == null || typeof this.model.not_available_week == "undefined")
      this.model.not_available_week = [];

    for (var index in this.model.not_available_week)
    {
      switch (this.model.not_available_week[index]) {
        case 1:
          this.model.not_available_week_01 = true;
          break;
        case 2:
          this.model.not_available_week_02 = true;
          break;
        case 3:
          this.model.not_available_week_03 = true;
          break;
      }
    }
  }

  //  Controllo che il camp sia disponibile sulla piattaforma delle iscrizioni
  get campBuyableOnlineCheck ()
  {
    this.onlineErrorArr = new Array;

    if (this.model != null && typeof this.model != "undefined")
    {
      if (this.campWeeks > this.campService.maxNumOfWeeksforCamp)
        this.onlineErrorArr.push("non è possibile andare online con campi più grandi di 4 settimane");
      if (this.model.status != 1 && this.model.status != 3 && this.model.status != 5 && this.model.status != 6)
        this.onlineErrorArr.push("lo status del camp non è corretto");
      if (!this.checkCampCode())
        this.onlineErrorArr.push('Manca il Camp Code');
      if (!this.checkAddressVenueType())
        this.onlineErrorArr.push("L\'indirizzo della Venue non è di tipo Office Address");
      if (!this.checkAddressVenue())
        this.onlineErrorArr.push('L\'indirizzo della Venue non è Googlizzato o è mancante');
      if (!this.campService.currentCampHaveCampDirector)
        this.onlineErrorArr.push('Manca la Camp Director');
      if (!this.checkAudience())
        this.onlineErrorArr.push('L\'audience non è stata scelta');

      if (this.onlineErrorArr.length == 0)
        return true;
    }

    return false;
  }

  checkNumOfWeeks()
  {
    if (this.campWeeks > this.campService.maxNumOfWeeksforCamp)
      return false;

    return true;
  }

  checkCampCode()
  {
    if (this.model.camp_code != null && typeof this.model.camp_code != "undefined" && this.model.camp_code.length > 0)
      return true;

    return false;
  }

  get checkBuyable()
  {
    if (this.model.buyable != null && typeof this.model.buyable != "undefined")
      return this.model.buyable;

    return false;
  }

  checkAddressVenueType ()
  {
    var found = false;
    if (this.enterpriseInfoShow != null && typeof this.enterpriseInfoShow != "undefined" && this.enterpriseInfoShow.length > 0)
    {
      if (this.enterpriseInfoShow.first.enterpriseInfo != null && typeof this.enterpriseInfoShow.first.enterpriseInfo != "undefined")
      {
        if (this.enterpriseInfoShow.first.enterpriseInfo.address != null && typeof this.enterpriseInfoShow.first.enterpriseInfo.address != "undefined")
        {
          Object.keys(this.enterpriseInfoShow.first.enterpriseInfo.address).forEach(key => {
            var element = this.enterpriseInfoShow.first.enterpriseInfo.address[key];
            if (element.type == 1)
              return found = true;
          });
        }
      }
    }

    return found;
  }

  checkAddressVenue ()
  {
    var found = false;
    if (this.enterpriseInfoShow != null && typeof this.enterpriseInfoShow != "undefined" && this.enterpriseInfoShow.length > 0)
    {
      if (this.enterpriseInfoShow.first.enterpriseInfo != null && typeof this.enterpriseInfoShow.first.enterpriseInfo != "undefined")
        if (this.enterpriseInfoShow.first.enterpriseInfo.address != null && typeof this.enterpriseInfoShow.first.enterpriseInfo.address != "undefined")
        Object.keys(this.enterpriseInfoShow.first.enterpriseInfo.address).forEach(key => {
          var element = this.enterpriseInfoShow.first.enterpriseInfo.address[key];
          if (element.type == 1)
            // if (typeof element.route != "undefined" && element.route != null && element.route.length > 0) // La Route non deve essere obligatoria, a volte gli indirizzi non hanno una route
              if(element.administrative_area_level_3_long_version != null && typeof element.administrative_area_level_3_long_version != "undefined" && element.administrative_area_level_3_long_version.length > 0)
                if (typeof element.administrative_area_level_2_short_version != "undefined" && element.administrative_area_level_2_short_version != null && element.administrative_area_level_2_short_version.length > 0)
                  if (typeof element.postal_code != "undefined" && element.postal_code != null && element.postal_code.length > 0)
                    return found = true;
        });
    }

    return found;
  }

  checkAudience ()
  {
    var found = false;
    if (this.audienceModel != null && typeof this.audienceModel != "undefined")
    {
      // let audience = JSON.parse(this.audienceModel);
      for (var k in this.audienceModel)
          if (this.audienceModel[k] == true)
            return found = true;
    }
    return found;
  }

  toggleClicked ()
  {
    // se il camp ha errori e non è acquistabile
    if (!this.campBuyableOnlineCheck)
    {
      this.model.buyable = false;
      return false;
    }

    //   se il camp è acquistabile ma è spuntato no
    if (this.model.buyable == false)
    {
      this.model.buyable = true;
      return true;
    }
    //   se il camp è acquistabile ma è spuntato si
    else
    {
      this.model.buyable = false;
      return false;
    }
  }

  campCodeClicked ()
  {
    if (!this.campBuyableOnlineCheck)
      this.model.buyable = false;
  }



  get isProvisionalWeeksValid()
  {
    //  controllo tutte le settimane fino a 4
    for (var i = 0; i < this.campWeeks; i++)
    {
      //  controllo elemento in base alla settinana
      if(!((this.provisionalInputs.toArray()[i]).nativeElement.value))
      {
        this.notify.error("Form Error", "One of provisional weeks is not set")
        return this.provisionalWeekValid = false;
      }

      if (i >= 3)
        return this.provisionalWeekValid = true;
    }

    return this.provisionalWeekValid = true;
  }

  //  Quando viene cambiata la audience
  onChangeAudience(event) {
  }



  //  Operazioni sulla data di start
  onDateStartChanged(event: IMyDateModel)
  {
    this.localDataStart = event;

    if (event.singleDate.jsDate != null)
    {
      this.model.start = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;
      this.datePickerOptionsEnd = datePickerOpt;
    }
    else
    {
      this.model.start = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }
  }



  //  Operazioni sulla data di end
  onDateEndChanged(event: IMyDateModel)
  {
    this.localDataEnd = event;

    if (event.singleDate.jsDate != null)
    {
      this.model.end = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableSince = event.singleDate.date;
      this.datePickerOptionsBegin = datePickerOpt;
    }
    else
    {
      this.model.end = null;
      this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }


  onDateAccountingFolder(event: IMyDateModel)
  {
    this.accounting_folder = event;

    if (event.singleDate.jsDate != null)
      this.model.accounting_folder = this.helper.formatISODate(event);
    else
      this.model.accounting_folder = null;
  }


  //  Valido l'orario di start
  timeStartvalid: boolean = true;
  onTimeStartInput (event)
  {
    this.timeStartvalid = false;

    //  controllo se è valido il time
    this.configService.timeCampPlanSelect.forEach(element => {
      if (element.value == this.currentForm.form.controls.start_lesson.value)
          return this.timeStartvalid = true;
    });
  }
  typeTimeStartOnSelect (event) {
    //  Se arriva questo evento al 100% è una data valida
    this.timeStartvalid = true;
  }



  //  Valido l'orario di end
  timeEndvalid: boolean = true;
  onTimeEndInput (event)
  {
    this.timeEndvalid = false;

    //  controllo se è valido il time
    this.configService.timeCampPlanSelect.forEach(element => {
      if (element.value == this.currentForm.form.controls.end_lesson.value)
          return this.timeEndvalid = true;
    });
  }
  typeTimeEndOnSelect (event) {
    //  Se arriva questo evento al 100% è una data valida
    this.timeEndvalid = true;
  }




  // Modifica della modal

  isModalShown: boolean = false;
  currentModalProp: string = null;

    showModal(typeEvent:string = null): void {

      //  se non ci sono parametri per i quali posso far aprire la modal non facico nulla
      if (typeEvent == null)
        return;

      //  memorizzo da dove viene la richiesta di apertura della modale
      this.currentModalProp = typeEvent;

      this.isModalShown = true;
    }

    hideModal(): void {
      this.autoShownModal.hide();
    }

    onHidden(): void {
      this.isModalShown = false;
    }

    searchKeywordsSubmit(params) {
      this.enterpriseDatatable.query = params;
      this.enterpriseDatatable.getResults();
    }

    resultSelected(selected) {

      switch (this.currentModalProp)
      {
        case 'venue':
        {
          this.model.venue_id = selected.enterprise_id;
          this.toggleClicked();
          break;
        }
        case 'accomodation_venue':
        {
          this.model.accomodation_venue_id = selected.enterprise_id;
          break;
        }
        case 'promoted':
        {
          this.model.promoted_id = selected.enterprise_id;
          break;
        }
        case 'organized':
        {
          this.model.organizer_id = selected.enterprise_id;
          break;
        }
        case 'contribution':
        {
          this.model.contribution_id = selected.enterprise_id;
          break;
        }
      }

      this.currentForm.form.markAsDirty();

      this.hideModal();
    }

    removeAutority (typeEvent:string = null): void {

      switch (typeEvent)
      {
        case 'venue':
        {
          this.model.venue_id = null;
          this.model.buyable = false;
          break;
        }
        case 'accomodation_venue':
        {
          this.model.accomodation_venue_id = null;
          break;
        }
        case 'promoted':
        {
          this.model.promoted_id = null;
          break;
        }
        case 'organized':
        {
          this.model.organizer_id = null;
          break;
        }
        case 'contribution':
        {
          this.model.contribution_id = null;
          break;
        }
      }

    }



    //   metodi necessari alla ricerca della stazione ferroviaria
  private loadStations() {

    this.http.get(this.dataUrl).subscribe(
    (response) => {
      this.stationComplex = response;
    },
    (error) => {
      console.log(error);
    });
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.model.railway = e.value;
  }





  deleteVrequest(){
    this.inUpload = true;
    this.campService.deleteVrequest(this.model, ()=>{
      this.model.venue_request_file_path = null;
      this.inUpload = false}, ()=>{this.inUpload = false});
  }
  deleteLeaflet ()
  {
    this.inUpload = true;
    this.campService.deleteLeaflet(this.model, ()=>{
      this.model.leaflet_file_path = null;
      this.inUpload = false}, ()=>{this.inUpload = false});
  }
  deleteCondition () {
    this.inUpload = true;
    this.campService.deleteCondition(this.model, ()=>{
      this.model.conditions_file_path = null;
      this.inUpload = false}, ()=>{this.inUpload = false});
  }


  //  metodi per il caricamento dei documenti
  //  Documento Caricato
  public leafletUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.model.leaflet_file_path = response;
      }
      this.campService.uploadedFileResponse(item, response, status,  this.model.id);
    }
  }

  public vrequestUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.model.venue_request_file_path = response;
      }
      this.campService.uploadedFileResponse(item, response, status,  this.model.id);
    }
  }

  public conditionUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.model.conditions_file_path = response;
      }
      this.campService.uploadedFileResponse(item, response, status,  this.model.id);
    }
  }

  GoToAgreement() {

    this.router.navigate([
      "/enterprise/" +
        this.model.organizer_id +
        "/agreementcamp/edit/" +
        this.model.agreement_id
    ]);
  }

  getCampService() {
    return this.campService
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }
}
