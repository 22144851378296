import { Component, OnInit, ViewChild } from '@angular/core';
import { CourseScheduleService, ConfigService, HelperService } from '../../_services';
import { parseISO, differenceInYears } from 'date-fns';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-course-schedule-signup-list',
  templateUrl: './course-schedule-signup-list.component.html',
  styleUrls: ['./course-schedule-signup-list.component.css']
})
export class CourseScheduleSignupListComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;

  rows: any[] = [];
  selected = [];
  ongoingRequest: boolean = false;
  selectedSignupInfo = null;

  constructor(private courseScheduleService: CourseScheduleService, private configService: ConfigService, private helper: HelperService) { }

  ngOnInit() {

    if (this.courseScheduleService.currentCourseScheduleId)
      this.getSignups();
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  onSelect({ selected }) {

    this.selectedSignupInfo = JSON.parse(JSON.stringify(this.selected[0]));
    this.selectedSignupInfo.registration_date_parsed = parseISO(this.selectedSignupInfo.registration_date);

    this.showModal();
  }

  onActivate(event) {
    //  console.log('Activate Event', event);
  }

  calculateYear (birthday: any)
  {
    return differenceInYears(new Date(), parseISO(birthday));
  }

  getSignups() {

    this.ongoingRequest = true;
    this.rows = [];

    this.courseScheduleService.getSignupFormsInfo( (list) => {

      this.rows = list;

      this.ongoingRequest = false;

    });

  }

  getRowClass(row) {
    return {
      'ignore-request': row.ignore_request === 1
    };
  }


  formNewSubscriberSaved(event) {
    this.getSignups();
    this.hideModal();
  }

  formNewSubscriberDelete(event) {
    this.getSignups();
    this.hideModal();
  }

  //  Modale con l'iscritto
  isModalShown: boolean = false;
  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }



  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }


}
