<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 class="form-section">Diary note</h3>

    <div class="row">
      <div class="col-md-4">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
          <label class="control-label">Type
            <span class="required">*</span>
          </label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
            <option *ngFor="let t of getConfigService().diaryCourseScheduleSelect" [value]="t.key">{{t.value}}</option>
          </select>
          <span class="help-block">Select the type of the note, is required</span>
        </div>
        <div *ngIf="!editMode && model.type" class="form-group editMode">
          <label class="control-label">Type/Date</label>
          <p><b>{{getConfigService().getDiaryCourseScheduleValueByKey(model.type)}}</b> on {{model.updated_at_formatted}}</p>
        </div>
      </div>

      <div *ngIf="!editMode" class="col-md-6">
        <div  class="form-group editMode">
          <label class="control-label">Notes</label>
          <p class="line-breaker">{{model.note}}</p>
        </div>
      </div>

      <div *ngIf="editMode" class="col-md-4">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalData(), false)) }">
          <label class="control-label">Date</label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_reminder_date="angular-mydatepicker" name="reminder_date" class="form-control"
                [locale]="getHelper().locale"
                [options]="getDatePickerOptions()"
                [ngModel]="getLocalData()"
                (dateChanged)="onDateChanged($event)"
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.reminder_data" class="btn default" (click)="dp_reminder_date.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_reminder_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
        </div>
      </div>

      <div *ngIf="!editMode" class="col-md-2">
        <div class="form-group editMode">
          <label class="control-label">Date and time</label>
          <p>{{getLocalData()?.singleDate?.jsDate | date}} {{model.time}}</p>
        </div>
      </div>

      <div *ngIf="editMode" class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || time.dirty || time.touched) && (!time.valid || !timevalid) }">
          <label class="control-label">Time</label>
          <input type="text" name="time" [(ngModel)]="model.time" #time="ngModel" class="form-control" (input)="onTimeInput($event)"
            [typeahead]="getConfigService().timeReminderSelect" (typeaheadOnSelect)="typeTimeOnSelect($event)" typeaheadOptionField="value"
            placeholder="10:30" autocomplete="off">
        </div>
      </div>

    </div>

    <div *ngIf="editMode" class="row">
      <div class="col-md-12">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || note.dirty || note.touched) && !note.valid }">
          <label class="control-label">Notes
            <span class="required">*</span>
          </label>
          <textarea rows="3" class="form-control" name="note" placeholder="Some notes..." [(ngModel)]="model.note" #note="ngModel"
            required></textarea>
          <span class="help-block">is required</span>
        </div>
      </div>
    </div>

  </div>



  <div class="form-actions right">
    <img *ngIf="getCourseScheduleService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <!-- <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn red btn-outline" [disabled]="getCourseScheduleService().isOngoingRequest()" *ngIf="this.model.id"
      [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
      <span class="ladda-label">
        <i class="fa fa-trash-o"></i> Delete</span>
    </button>&nbsp;
    <button type="button" class="btn btn-outline red" [disabled]="getCourseScheduleService().isOngoingRequest()" *ngIf="!this.model.id"
      (click)="delete()">
      <i class="fa fa-trash-o"></i> Delete</button>&nbsp; -->
    <button type="button" class="btn btn-outline blue" [disabled]="getCourseScheduleService().isOngoingRequest()" *ngIf="this.model.id && !editMode"
      (click)="edit()">
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

    <button *ngIf="multiSave == false || editMode" type="submit" class="btn blue" [disabled]="getCourseScheduleService().isOngoingRequest()"
      [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id">
        <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>



</form>
