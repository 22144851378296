
<div (onHidden)="onHiddenMaster()" bsModal #masterModal="bs-modal" class="modal fade"
tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">

            <!-- BEGIN FORM -->
            <form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm">

                <input type="hidden" name="id" [(ngModel)]="model.id">

                <div class="form-body">
                  <div *ngIf="isAddIn" class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="control-label">&nbsp;</label>
                        <div class="row">
                          <div class="col-md-12" *ngIf="vehicleHolderName">
                              <h2>{{ vehicleHolderName }}</h2>
                              <button type="button" class="btn btn-block btn-outline red" [disabled]="getVehicleService().isOngoingRequest()" (click)="removeOtherHolder()"><i class="fa fa-trash-o"></i> Remove</button>
                          </div>
                          <div class="col-md-12" *ngIf="!vehicleHolderName">
                              <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('person')"><i class="fa fa-user"></i>  Person</button>
                              <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('group')"><i class="fa fa-group"></i> Group</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 text-center">
                      <label class="control-label">&nbsp;</label>
                      <h2 class="font-green-jungle"></h2>
                    </div>
                    <div class="col-md-4 text-center">
                      <div class="form-group">
                        <label class="control-label">&nbsp;</label>
                        <div class="row">
                          <div class="col-md-12">
                              <h2>{{ holderName }}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!isAddIn" class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="control-label">&nbsp;</label>
                        <div class="row">
                          <div class="col-md-12" *ngIf="vehicleInfo">
                            <h2>{{vehicleName}}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 text-center">
                      <label class="control-label">&nbsp;</label>
                      <h2 class="font-red-haze"></h2>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="control-label">&nbsp;</label>
                        <div class="row">
                          <div class="col-md-12" *ngIf="vehicleHolderName">
                            <h2>{{ vehicleHolderName }}</h2>
                            <button type="button" class="btn btn-block btn-outline red" (click)="removeOtherHolder()"><i class="fa fa-trash-o"></i> Remove</button>
                          </div>
                          <div class="col-md-12" *ngIf="!vehicleHolderName">
                            <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('person')"><i class="fa fa-user"></i>  Person</button>
                            <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('group')"><i class="fa fa-group"></i> Group</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalDataStart())) }">
                        <label class="control-label">Start Date <span class="required">*</span></label>
                          <div class="input-group">
                            <!-- input box -->
                            <input angular-mydatepicker #dp_start_date="angular-mydatepicker" name="start_date" class="form-control"
                              [locale]="getHelper().locale"
                              [disabled]="getVehicleService().isOngoingRequest()"
                              [options]="getDatePickerOptions()"
                              [ngModel]="getLocalDataStart()"
                              (dateChanged)="onDateStartChanged($event)"
                              required
                            />
                            <!-- clear date button -->
                            <span class="input-group-btn">
                              <button type="button" *ngIf="model?.start_date" class="btn default" (click)="dp_start_date.clearDate()"><i class="fa fa-close"></i></button>
                            </span>
                            <!-- toggle calendar button -->
                            <span class="input-group-btn">
                                <button type="button" class="btn default" (click)="dp_start_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                            </span>
                        </div>
                        <span class="help-block">Field required</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalDataEnd())) }">
                        <label class="control-label">End Date <span class="required">*</span></label>
                          <div class="input-group">
                            <!-- input box -->
                            <input angular-mydatepicker #dp_end_date="angular-mydatepicker" name="end_date" class="form-control"
                              [locale]="getHelper().locale"
                              [disabled]="getVehicleService().isOngoingRequest()"
                              [options]="getDatePickerOptions()"
                              [ngModel]="getLocalDataEnd()"
                              (dateChanged)="onDateEndChanged($event)"
                              required
                            />
                            <!-- clear date button -->
                            <span class="input-group-btn">
                              <button type="button" *ngIf="model?.end_date" class="btn default" (click)="dp_end_date.clearDate()"><i class="fa fa-close"></i></button>
                            </span>
                            <!-- toggle calendar button -->
                            <span class="input-group-btn">
                                <button type="button" class="btn default" (click)="dp_end_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                            </span>
                        </div>
                        <span class="help-block">Field required</span>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="form-actions right">
                  <img *ngIf="getVehicleService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
                  <ng-template #confirmDelete>
                    <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
                  </ng-template>
                  <button type="button" class="btn btn-outline red" [disabled]="getVehicleService().isOngoingRequest()" *ngIf="this.model.id"  [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;

                  <button type="submit" class="btn blue" [disabled]="getVehicleService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
                    <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
                    <span class="ladda-label" *ngIf="this.model.id">Update</span>
                  </button>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>





<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="modal-title pull-left">Add reference</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Person -->
      <div class="modal-body" *ngIf="modalEntityType=='person'">
        <div class="row">
          <div class="col-md-12">
            <app-person-search-form #personSearchForm (searchKeywordsSubmit)="searchPersonKeywordsSubmit($event)" [ongoingRequest]="personDatatable.ongoingRequest"></app-person-search-form>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <div class="portlet light bordered">
              <div class="portlet-body">
                <app-person-datatable #personDatatable [query]="personSearchForm.searchKeyword" (personSelected)="personSelected($event)"></app-person-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Group -->
      <div class="modal-body" *ngIf="modalEntityType=='group'">
        <div class="row">
          <div class="col-md-12">
              <app-actor-group-search-form #groupSearchForm (searchKeywordsSubmit)="searchGroupKeywordsSubmit($event)" [ongoingRequest]="groupDatatable.ongoingRequest"></app-actor-group-search-form>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <div class="portlet light bordered">
              <div class="portlet-body">
                  <app-actor-group-datatable #groupDatatable (groupSelected)="groupSelected($event)"></app-actor-group-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
