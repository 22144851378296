<div class="datatable-loader text-center" *ngIf="ongoingRequest">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>
<!-- <ngx-datatable *ngIf="!ongoingRequest"
    class="striped"
    [rows]="rows"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [externalPaging]="true"
    [count]="pageTotalElements"
    [offset]="pageNumber"
    [limit]="pageSize"
    [selected]="selected"
    [selectionType]="'single'"
    (activate)="onActivate($event)"
    (select)='onSelect($event)'
    (page)='setPage($event)'> -->
<ngx-datatable *ngIf="!ongoingRequest" class="striped" [rows]="rows" [columnMode]="'force'" [headerHeight]="50"
  [footerHeight]="50" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'" (activate)="onActivate($event)"
  (select)='onSelect($event)'>

  <ngx-datatable-column name="Status" prop="status_flag" [resizeable]="false" [cellClass]="getCellClass" *ngIf="!smallTableVersion"></ngx-datatable-column>
  <ngx-datatable-column name="Course" prop="title" [resizeable]="false" [draggable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Sofia Code" prop="sofia_code" [resizeable]="false" [draggable]="false" *ngIf="!smallTableVersion"></ngx-datatable-column>
  <ngx-datatable-column name="Locality" [flexGrow]="3" prop="administrative_area_level_3_long_version" [resizeable]="false"
    [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <span *ngIf="row['administrative_area_level_3_long_version'] == null" tooltip="The address is not googlized or not primary address"
        placement="auto"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
      <span *ngIf="row['administrative_area_level_3_long_version'] != null">
        {{row?.administrative_area_level_3_long_version}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="PR" prop="administrative_area_level_2_short_version" [flexGrow]="1" [resizeable]="false"
    [draggable]="false" *ngIf="!smallTableVersion">
    <ng-template>
      <span *ngIf="row['administrative_area_level_2_short_version'] != null">
        {{row?.administrative_area_level_2_short_version}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Region" prop="administrative_area_level_1_long_version" [flexGrow]="2" [resizeable]="false"
    [draggable]="false" *ngIf="!smallTableVersion">
    <ng-template>
      <span *ngIf="row['administrative_area_level_1_long_version'] != null">
        {{row?.administrative_area_level_1_long_version}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Date Start" prop="start" [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row">{{row?.start_formatted}}</ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Date End" prop="end" [resizeable]="false" [draggable]="false" *ngIf="!smallTableVersion">
    <ng-template ngx-datatable-cell-template let-row="row">{{row?.end_formatted}}</ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Time" prop="start_time" [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row">{{row?.start_time}}-{{row?.end_time}}</ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Req/Sub" [flexGrow]="1" [resizeable]="false" [draggable]="false" *ngIf="!smallTableVersion">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <i *ngIf="row.requests_count > 0" class="fa fa-exclamation" style="color:red"></i>
      <span tooltip="Signup request:{{row.requests_count}} Subscribers:{{row.subscription_count}}">{{row.requests_count}}/{{row.subscription_count}}</span>
      <i *ngIf="row.buyable_online == true" class="fa fa-toggle-on" style="color:green"></i>
      <i *ngIf="row.buyable_online == false" class="fa fa-toggle-off" style="color:red"></i>
      <span *ngIf="row.max_num_subscribers" tooltip="numero massimo di subscriber raggiunto">
          <i *ngIf="row.subscription_count >= row.max_num_subscribers" class="fa fa-exclamation-triangle" style="color: orange"></i>
      </span>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
