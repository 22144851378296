import { ConfigService, CourseScheduleService, HelperService } from '../../_services';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-course-schedule-staff-datatable',
  templateUrl: './course-schedule-staff-datatable.component.html',
  styleUrls: ['./course-schedule-staff-datatable.component.css']
})
export class CourseScheduleStaffDatatableComponent implements OnInit {

  @Output() personSelected = new EventEmitter();

  courseScheduleStaffList: any[];

  //  property del datatable
  selected = [];

  subscribe: any;

  constructor(private courseScheduleService: CourseScheduleService, private configService: ConfigService, private helper: HelperService) { }

  ngOnInit() {

    //  inizializzo la lista dello staff
    this.courseScheduleStaffList = [];

    this.subscribe = this.courseScheduleService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.getStaffInfo();
    });

    if (this.courseScheduleService.currentCourseStaffList != null) {
        this.getStaffInfo();
    }

  }

  getStaffInfo ()
  {
    if (this.courseScheduleService.currentCourseStaffList != null) {

      this.courseScheduleStaffList = JSON.parse(JSON.stringify(this.courseScheduleService.currentCourseStaffList));
    }

    if (this.courseScheduleStaffList == null)
      this.courseScheduleStaffList = [];

  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }



  update(data: any) {
  }

  onSelect({ selected }) {

    //  controllo se è una persona cancellata prima di inviare l'evento di apertura della modale
    if (selected[0].person_info != null && typeof selected[0].person_info != "undefined")
      if (selected[0].person_info.deleted_at != null) return;

    if (selected[0].enterprise_info != null && typeof selected[0].enterprise_info != "undefined")
      if (selected[0].enterprise_info.deleted_at != null) return;

    this.personSelected.emit(selected[0]);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  getResults()
  {

  }

  getRowClass (row) {

    if (row.person_info){
      return { 'course-schedule-staff-deleted' : row.person_info.deleted_at !== null};
    }
    else if (row.enterprise_info) {
      return { 'course-schedule-staff-deleted' : row.enterprise_info.deleted_at !== null};
    }

    return "";
  }

  getCourseScheduleService() {
    return this.courseScheduleService
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }
}
