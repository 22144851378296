<h3>Actors</h3>
<span *ngIf="!loading && actorApplications && actorApplications.length > 0">
  <ngx-datatable class="striped" [rows]="actorApplications" [columnMode]="'flex'" [headerHeight]="50"
    [footerHeight]="50" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'"
    (activate)="onActivate($event)" (select)='onSelectActor($event)'>
    <ngx-datatable-column name="Date" [flexGrow]="1" [sortable]="true" [resizeable]="false" prop="created_at">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{value | date: 'shortDate'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Status" [flexGrow]="3" [sortable]="true" [resizeable]="false" prop="status">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{value}} - Phase {{row.phase}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Tour type" [flexGrow]="3" [sortable]="true" [resizeable]="false" prop="tour_type">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{value}}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</span>
<br>
<h3>Tutor</h3>
<span *ngIf="!loading && tutorApplications && tutorApplications.length > 0">
  <ngx-datatable class="striped" [rows]="tutorApplications" [columnMode]="'flex'" [headerHeight]="50"
    [footerHeight]="50" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'"
    (activate)="onActivate($event)" (select)='onSelectTutor($event)'>
    <ngx-datatable-column name="Date" [flexGrow]="1" [sortable]="true" [resizeable]="false" prop="created_at">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{value | date: 'shortDate'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Status" [flexGrow]="3" [sortable]="true" [resizeable]="false" prop="status">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{value}} - Phase {{row.phase}}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</span>
<br>
<h3>Facilitator</h3>
<span *ngIf="!loading && facilitatorApplications && facilitatorApplications.length > 0">
  <ngx-datatable class="striped" [rows]="facilitatorApplications" [columnMode]="'flex'" [headerHeight]="50"
    [footerHeight]="50" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'"
    (activate)="onActivate($event)" (select)='onSelectFacilitator($event)'>
    <ngx-datatable-column name="Date" [flexGrow]="1" [sortable]="true" [resizeable]="false" prop="created_at">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{value | date: 'shortDate'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Status" [flexGrow]="3" [sortable]="true" [resizeable]="false" prop="status">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{value}} - Phase {{row.phase}}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</span>
