<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/camp/reports']">Camp Reports</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span>Età subscribers</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Età subscribers
        <small></small>
      </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <!-- begin: prima colonna -->
        <div class="col-md-12">
          <div class="portlet light bordered">
            <div class="portlet-body">
              <div class="row" *ngIf="getCampService().isOngoingRequest()">
                <div class="col-md-12">
                  <div class="datatable-loader text-center">
                    <span></span><span></span><span></span><span></span><span></span>
                    <strong class="text-center">Loading</strong>
                  </div>
                </div>
              </div>
              <div class="portlet" *ngIf="!getCampService().isOngoingRequest()">
                <div class="portlet-body">
                  <ngx-datatable
                    class="striped"
                    [rows]="rows"
                    [columnMode]="'force'"
                    [headerHeight]="50"
                    [footerHeight]="50"
                    [rowHeight]="'auto'"
                  >
                    <ngx-datatable-column name="Camp code" prop="camp_code" [width]="250"></ngx-datatable-column>
                    <ngx-datatable-column name="Start" prop="camp_start" [width]="210"></ngx-datatable-column>
                    <ngx-datatable-column name="End" prop="camp_end" [width]="210"></ngx-datatable-column>
                    <ngx-datatable-column name="4" prop="age4"></ngx-datatable-column>
                    <ngx-datatable-column name="5" prop="age5"></ngx-datatable-column>
                    <ngx-datatable-column name="6" prop="age6"></ngx-datatable-column>
                    <ngx-datatable-column name="7" prop="age7"></ngx-datatable-column>
                    <ngx-datatable-column name="8" prop="age8"></ngx-datatable-column>
                    <ngx-datatable-column name="9" prop="age9"></ngx-datatable-column>
                    <ngx-datatable-column name="10" prop="age10"></ngx-datatable-column>
                    <ngx-datatable-column name="11" prop="age11"></ngx-datatable-column>
                    <ngx-datatable-column name="12" prop="age12"></ngx-datatable-column>
                    <ngx-datatable-column name="13" prop="age13"></ngx-datatable-column>
                    <ngx-datatable-column name="14" prop="age14"></ngx-datatable-column>
                    <ngx-datatable-column name="15" prop="age15"></ngx-datatable-column>
                    <ngx-datatable-column name="16" prop="age16"></ngx-datatable-column>
                    <ngx-datatable-column name="17" prop="age17"></ngx-datatable-column>
                    <ngx-datatable-column name="18" prop="age18"></ngx-datatable-column>
                  </ngx-datatable>
                </div>
              </div>
              <div class="row" [appCheckPermission]="'camp_reports_export'">
                <div class="pull-right">
                  <button [disabled]="getCampService().isOngoingRequest() || rows?.length <= 0" type="button" class="btn green fix-button-margin" (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end: prima colonna -->
      </div>
      <!-- end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->

<!-- BEGIN: MODAL LOADING EXPORT-->
<div *ngIf="isModalExport" [config]="{ show: true, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal #staticModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
