<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/enterprise/home']">Enterprise</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span *ngIf="!getEnterpriseService().currentEnterpriseId">Nuova</span>
            <span *ngIf="getEnterpriseService().currentEnterpriseId">Modifica</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>

      <app-reminder-widget-alertbox [holderType]="'App\\Enterprise'" [holderId]="getEnterpriseService().currentEnterpriseId"
      [holderName]="getEnterpriseService().currentEnterpriseInfo?.denomination"></app-reminder-widget-alertbox>

      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title" *ngIf="!getEnterpriseService().currentEnterpriseId">New enterprise</h1>
      <h1 class="page-title" *ngIf="getEnterpriseService().currentEnterpriseId">Edit enterprise {{getEnterpriseService().currentEnterpriseInfo?.denomination}} </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">

            <!-- <div class="portlet-title tabbable-line">
              <div class="caption caption-md">
                <i class="icon-globe theme-font hide"></i>
                <span class="caption-subject font-blue-madison bold uppercase">Enterprise</span>
              </div>
            </div> -->

              <div class="portlet-body form">

              <alert type="warning" *ngIf="loadingData">
                <strong>Loading</strong> ...
              </alert>

              <tabset #enterpriseTabs class="align-right portlet-title tabbable-line" [hidden]="loadingData">
                <tab heading="Info"><app-enterprise-info-form [enterpriseComponent]="this"></app-enterprise-info-form></tab>
                <tab>
                  <ng-template tabHeading>
                    <span><i *ngIf="!getEnterpriseService().checkEnterpriseAddress()" tooltip="Indirizzo primario non presente" class="text-warning fa fa-exclamation-triangle" aria-hidden="true"></i> Address</span>
                  </ng-template>
                  <app-enterprise-address-list></app-enterprise-address-list>
                </tab>
                <tab heading="Contact"><app-enterprise-contact-list></app-enterprise-contact-list></tab>
                <tab heading="Payment"><app-enterprise-bank-account-list></app-enterprise-bank-account-list></tab>
                <tab heading="Document"><app-enterprise-document-list></app-enterprise-document-list></tab>
                <tab heading="Accomodation"><app-enterprise-accomodation-form></app-enterprise-accomodation-form></tab>
                <tab heading="Agreements"><app-enterprise-agreement-list  *ngIf="getUserService().checkPermission('enterprise_edit')"></app-enterprise-agreement-list></tab>
                <tab heading="Cash flow"><app-enterprise-cashflow></app-enterprise-cashflow></tab>
                <tab heading="Reminder"><app-enterprise-reminder *ngIf="getUserService().checkPermission('enterprise_edit')"></app-enterprise-reminder></tab>
                <tab heading="History"><app-enterprise-history *ngIf="getUserService().checkPermission('enterprise_edit')"></app-enterprise-history></tab>
                <tab heading="Revision"><app-enterprise-revision *ngIf="getUserService().checkPermission('enterprise_edit')"></app-enterprise-revision></tab>
              </tabset>

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
