import { Component, OnInit, ViewChild, Input, Output, ViewChildren, QueryList, EventEmitter } from '@angular/core';
import { PersonService, ConfigService, UserService } from '../../_services/index';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { EnterpriseDatatableComponent, EnterpriseSearchFormComponent, EnterpriseInfoShowComponent } from '../../enterprise/index';

@Component({
  selector: 'app-person-role-form',
  templateUrl: './person-role-form.component.html',
  styleUrls: ['./person-role-form.component.css']
})
export class PersonRoleFormComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('enterpriseDatatable', {static: false}) public enterpriseDatatable: EnterpriseDatatableComponent;
  @ViewChild('enterpriseSearchForm', {static: false}) public enterpriseSearchForm: EnterpriseSearchFormComponent;
  @ViewChildren('enterpriseInfoShow') public enterpriseInfoShow: EnterpriseInfoShowComponent;

  @Input() model: any;
  @Input() multiSave: boolean;

  @Output() primaryUpdate = new EventEmitter();
  @Output() formDelete = new EventEmitter();
  @Output() formSaved = new EventEmitter();

  editMode: boolean = false;

  permissions: any = [];

  @ViewChild('f', {static: true}) currentForm: NgForm;

  constructor(private personService: PersonService, private configService: ConfigService, private notify: NotificationsService, private userService: UserService) { }

  ngOnInit() {

    if (!this.model || !this.model.id)
      this.edit();

    this.parsePermission();
  }

  submit() {

    if (!this.currentForm.form.valid)
      return;

    if (!this.currentForm.form.dirty) {
      this.editMode = false;
      return;
    }

    if (!this.personService.checkPrimary(this.model, this.personService.currentPersonRoleList)) {
      this.notify.error("You need to set this or other role as primary");
      return;
    }

    if (!this.userService.checkPermission("role_edit_"+this.model.type)){
      this.notify.error("You do not have permission to edit this role");
      return;
    }

    let new_perm_excluded = [];

    // vedo quali permessi sono stati esclusi e li salvo nel ruolo
    this.permissions.forEach((el) => {
      if (el.value == false)
        new_perm_excluded.push(el.index);
    });

    this.model.permissions = JSON.stringify(new_perm_excluded);

    if (this.model.id) {
      this.personService.updatePersonRole(this.model, ()=> {this.editMode = false;});
    }
    else {
      this.personService.savePersonRole(this.model, ()=> {this.editMode = false;});
    }

    this.formSaved.emit(this.model);
  }

  destroy() {
    this.personService.deletePersonRole(this.model);
  }

  delete() {
    this.formDelete.emit(this);
  }

  edit() {

    if (!this.model || !this.model.id){
      this.editMode = true;
    }

    if (this.userService.checkPermission("role_edit_"+this.model.type)){
      this.editMode = true;
    }
  }

  parsePermission() {

    let perm = this.configService.getDefaultRolePermissionByKey(this.model.type);
    let perm_user = [];

    if (this.model.permissions)
      perm_user = JSON.parse(this.model.permissions);

    perm.forEach((el, index) => {

      if (el.startsWith("role_"))
        return;

      let value = true;

      if (perm_user.includes(el))
        value = false;

      this.permissions.push({
        index: el,
        label: el.replace(/\_/gi, " "),
        value: value
      });
    });
  }

  getFilteredPermissionBy(filter)
  {
    return this.permissions.filter((p) => p.index.startsWith(filter));
  }

  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  searchKeywordsSubmit(params) {
    this.enterpriseDatatable.query = params;
    this.enterpriseDatatable.getResults();
  }

  resultSelected(selected) {

    this.model.enterprise_id = selected.enterprise_id;

    this.currentForm.form.markAsDirty();

    //  Solo se già presente l'id faccio il submit automatico
    // if (this.model.id)
    //   this.submit();

    this.hideModal();
  }

  removeAutority() {
    this.model.enterprise_id = null;

    this.currentForm.form.markAsDirty();

    //  Solo se già presente l'id faccio il submit automatico
    // if (this.model.id)
    //   this.submit();
  }

  //  E' cambiato il valore di un primary
  valueChange(value) {
    this.primaryUpdate.emit(this.model);
  }

  valuePermissionChange(value) {

  }

  getConfigService() {
    return this.configService
  }

  getPersonService() {
    return this.personService
  }

}
