<!-- BEGIN: sezione dedicata alle info sui risultati delle ricerche -->
<div class="row">
  <div class="col-md-12">
    <div class="portlet light bordered">
      <div class="portlet-title">
        <button [appCheckPermission]="'course_schedule_edit_group'" class="btn btn-block btn-primary" (click)="newGroup()"><i class="fa fa-plus"></i> New Group</button>
      </div>

      <!-- <div class="datatable-loader text-center" *ngIf="isOngoingRequest()">
        <span></span><span></span><span></span><span></span><span></span>
        <strong class="text-center">Loading</strong>
      </div> -->

      <div class="portlet-body">
        <ngx-datatable class="striped" [columnMode]="'flex'" [rows]="courseScheduleGroupList"
          [rowHeight]="'auto'" [headerHeight]="'auto'" [footerHeight]="0" [selectionType]="'single'"
          (select)='onGroupSelect($event)'>
          <ngx-datatable-column name="Group Name" [flexGrow]="4" prop="group_name" [resizeable]="false"
            [draggable]="false"></ngx-datatable-column>
          <ngx-datatable-column name="Description" [flexGrow]="8" prop="description" [resizeable]="false"
            [draggable]="false"></ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>

<hr>

<tabset #courseScheduleGroupOrganizerTab class="align-right portlet-title tabbable-line">
  <tab heading="Subscribers">
    <!-- BEGIN: sezione per settimana -->
    <div class="row" *ngIf="courseScheduleGroupList.length > 0 && courseScheduleGroupOrganizerTab?.tabs[0]?.active">
      <app-course-schedule-subscriber-groups></app-course-schedule-subscriber-groups>
    </div>
  </tab>
  <tab heading="Staff">
    <!-- BEGIN: sezione per settimana -->
    <div class="row" *ngIf="courseScheduleGroupList.length > 0 && courseScheduleGroupOrganizerTab?.tabs[1]?.active">
      <app-course-schedule-staff-groups></app-course-schedule-staff-groups>
    </div>
  </tab>
</tabset>




<!-- BEGIN: MODAL LOADING EXPORT-->
<div *ngIf="isModalGroup" [config]="{ show: true }" (onHidden)="onHiddenGroup()" bsModal #groupModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Nuovo Gruppo</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalGroup()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="#" role="form" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()"
          #f="ngForm" novalidate>
          <div class="form-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group"
                  [ngClass]="{ 'has-error': (f.submitted || group_name.dirty || group_name.touched) && !group_name.valid }">
                  <label class="control-label">Nome del Gruppo <span class="required">*</span></label>
                  <input class="form-control" type="text" name="group_name" [(ngModel)]="model_group.group_name"
                    #group_name="ngModel" nospace placeholder="Group Name">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">Descrizione del Gruppo</label>
                  <textarea class="form-control" rows="3" name="description" [(ngModel)]="model_group.description"
                    placeholder="Group Description"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="form-actions right"  [appCheckPermission]="'camp_edit_group'">
            <img *ngIf="isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
            <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroyGroup();">Yes
                Delete!</button></ng-template>
            <button type="button" class="btn btn-outline red" *ngIf="model_group.id" [disabled]="isOngoingRequest()"
              [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i>
              Delete</button>&nbsp;
            <button type="submit" [disabled]="isOngoingRequest()" class="btn blue"
              *ngIf="model_group.id">Update</button>
            <button type="submit" [disabled]="isOngoingRequest()" class="btn green-jungle"
              *ngIf="!model_group.id">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
