<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span>Camp Transfer Details</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Camp Transfer Details</h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- BEGIN: Filters -->
      <form action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Area</label>
              <select class="form-control" name="area" #area="ngModel" [(ngModel)]="model.area">
                <option *ngFor="let z of getConfigService().areaExpertiseSelect" [value]="z.key">{{z.value}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-1">
            <div class="form-group">
              <label class="control-label">Anno</label>
              <select class="form-control" name="year" [(ngModel)]="model.year" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let y of getConfigService().yearSelect(-10)" [value]="y">{{y}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="control-label">Mese</label>
              <select class="form-control" name="month" [(ngModel)]="model.month" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let m of getConfigService().monthSelect()" [value]="m">{{m}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group"
              [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDateFrom, false)) }">
              <label class="control-label"> From Date </label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_from_date="angular-mydatepicker" name="from_date" class="form-control"
                  [locale]="getHelper().locale" [options]="datePickerOptionsBegin" [ngModel]="localDateFrom"
                  (dateChanged)="onDateFromChanged($event)" />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model['from_date']" class="btn default"
                    (click)="dp_from_date.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_from_date.toggleCalendar()"><i
                      class="fa fa-calendar-o"></i></button>
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group"
              [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDateTo, false)) }">
              <label class="control-label">To Date</label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_to_date="angular-mydatepicker" name="to_date" class="form-control"
                  [locale]="getHelper().locale" [options]="datePickerOptionsEnd" [ngModel]="localDateTo"
                  (dateChanged)="onDateToChanged($event)" />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model['to_date']" class="btn default" (click)="dp_to_date.clearDate()"><i
                      class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_to_date.toggleCalendar()"><i
                      class="fa fa-calendar-o"></i></button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Camp code</label>
              <input type="text" class="form-control" placeholder="" name="camp_code" [(ngModel)]="model.camp_code"
                (change)="fieldChange($event);">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Località</label>
              <input type="text" class="form-control" placeholder="" name="locality" [(ngModel)]="model.locality"
                (change)="fieldChange($event);">
            </div>
          </div>
          <div class="col-md-1">
            <div class="form-group">
              <label class="control-label">Province</label>
              <input type="text" class="form-control" placeholder="" name="province" [(ngModel)]="model.province"
                (change)="fieldChange($event);">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="control-label">Region</label>
              <input type="text" class="form-control" placeholder="" name="region" [(ngModel)]="model.region"
                (change)="fieldChange($event);">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Tutor</label>
              <input type="text" class="form-control" placeholder="" name="tutor" [(ngModel)]="model.tutor"
                (change)="fieldChange($event);">
            </div>
          </div>
        </div>

        <div class="form-actions right">
          <button type="button" class="btn default btn-lg" (click)="clearSearch()">Clear
            <i class="fa fa-eraser" aria-hidden="true"></i>
          </button>
          <button class="btn default btn-lg green" [disabled]="getCampService().isOngoingRequest()"
            type="submit">Search</button>
        </div>

      </form>
      <!-- END: Filters -->

      <br>

      <!-- begin: prima riga -->
      <div class="row">
        <!-- begin: prima colonna -->
        <div class="col-md-12">
          <div class="portlet light bordered">
            <div class="portlet-body">
              <div class="row" *ngIf="getCampService().isOngoingRequest()">
                <div class="col-md-12">
                  <div class="datatable-loader text-center">
                    <span></span><span></span><span></span><span></span><span></span>
                    <strong class="text-center">Loading</strong>
                  </div>
                </div>
              </div>
              <div class="portlet" *ngIf="!getCampService().isOngoingRequest()">
                <div class="portlet-body">
                  <ngx-datatable #myTable class="striped expandable" [rows]="rows" [columnMode]="'force'"
                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarH]="true"
                    [externalPaging]="true" [externalSorting]="true" [count]="pageTotalElements" [offset]="pageNumber"
                    [limit]="pageSize" (sort)='onSort($event)' (page)='setPage($event)'>


                    <!-- Row Detail Template -->
                    <ngx-datatable-row-detail [rowHeight]="'auto'" (toggle)="onDetailToggle($event)">
                      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                        <br>
                        <button type="button" class="btn green btn-block btn-outline"
                          (click)="createTransferDetails(row);"><i class="fa fa-plus"></i>&nbsp; New Transfer
                          detail</button>
                        <br>
                        <span *ngFor="let d of row.camptransferdetail">
                          <alert *ngIf="d.annotation || d.file_path" type="warning">
                            <small *ngIf="d.annotation" class="line-breaker"><b>Note {{d.transport}}:</b>
                              {{d.annotation}}</small><br *ngIf="d.annotation">
                            <i *ngIf="d.file_path" class="fa fa-ticket" aria-hidden="true"></i> <a *ngIf="d.file_path"
                              href="{{getConfigService().blobBasePath + d.file_path}}" target="_blank"> Download
                              ticket</a>
                          </alert>
                        </span>
                        <alert *ngFor="let director of row.camp_directors" type="info">
                          <strong>Director</strong><br>
                          <app-person-info-show [idPerson]="director.person_id" [showContact]="true"
                            [hideSummaryCardModal]="true"></app-person-info-show>
                        </alert>
                        <alert type="info">
                          <strong>Tutor:</strong><br>
                          <app-person-info-show [idPerson]="row.person_id" [showContact]="true"
                            [hideSummaryCardModal]="true"></app-person-info-show>
                        </alert>

                      </ng-template>
                    </ngx-datatable-row-detail>

                    <ngx-datatable-column [resizeable]="false" [flexGrow]="1" name="Camp partenza"
                      prop="from_camp?.camp_code" [sortable]="false">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <span
                          *ngIf="row.from_camp && row.from_camp['administrative_area_level_3_long_version'] != null">{{row?.from_camp?.administrative_area_level_3_long_version}}</span>
                        <span
                          *ngIf="row.from_camp && row.from_camp['administrative_area_level_2_short_version'] != null">({{row?.from_camp?.administrative_area_level_2_short_version}})</span><br>
                        <a href="javascript:;"
                          [routerLink]="['/camp/edit/'+row.from_camp?.camp_id]">{{ row.from_camp?.camp_code }}</a><br>
                        <small *ngIf="row.from_camp?.area">Area:
                          <b>{{getConfigService().getAreaExpertiseValueByKey(row.from_camp?.area)}}</b></small><br>
                        <small>
                          <span
                            *ngIf="row.from_camp?.week_01">{{ row.from_camp?.week_01_parsed | date:'d MMMM, y':'':'it'}}</span>
                          <br *ngIf="row.from_camp?.week_01" />
                          <span
                            *ngIf="row.from_camp?.week_02">{{ row.from_camp?.week_02_parsed | date:'d MMMM, y':'':'it'}}</span>
                          <br *ngIf="row.from_camp?.week_02" />
                          <span
                            *ngIf="row.from_camp?.week_03">{{ row.from_camp?.week_03_parsed | date:'d MMMM, y':'':'it'}}</span>
                          <br *ngIf="row.from_camp?.week_03" />
                          <span
                            *ngIf="row.from_camp?.week_04">{{ row.from_camp?.week_04_parsed | date:'d MMMM, y':'':'it'}}</span>
                          <span *ngIf="row.from_camp_diff_days > 7" class="badge badge-warning"><strong>ON
                              HOLD</strong>: {{row.from_camp_diff_days}} giorni dal camp di arrivo </span>
                        </small>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [resizeable]="false" [flexGrow]="1" name="Camp arrivo" prop="camp_code"
                      [sortable]="true">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <span
                          *ngIf="row['administrative_area_level_3_long_version'] != null">{{row?.administrative_area_level_3_long_version}}</span>
                        <span
                          *ngIf="row['administrative_area_level_2_short_version'] != null">({{row?.administrative_area_level_2_short_version}})</span><br>
                        <a href="javascript:;" [routerLink]="['/camp/edit/'+row.camp_id]">{{ row.camp_code }}</a><br>
                        <small *ngIf="row.area">Area:
                          <b>{{getConfigService().getAreaExpertiseValueByKey(row.area)}}</b></small><br>
                        <small>
                          <span *ngIf="row.week_01">{{ row.week_01_parsed | date:'d MMMM, y':'':'it'}}</span> <br
                            *ngIf="row.week_01" />
                          <span *ngIf="row.week_02">{{ row.week_02_parsed | date:'d MMMM, y':'':'it'}}</span> <br
                            *ngIf="row.week_02" />
                          <span *ngIf="row.week_03">{{ row.week_03_parsed | date:'d MMMM, y':'':'it'}}</span> <br
                            *ngIf="row.week_03" />
                          <span *ngIf="row.week_04">{{ row.week_04_parsed | date:'d MMMM, y':'':'it'}}</span>
                        </small>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [resizeable]="false" [flexGrow]="3" name="Tutor" prop="surname"
                      [sortable]="true">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <a href="javascript:;" [routerLink]="['/person/edit/' + row.person_id]">{{ row.surname }}
                          {{ row.first_name }}</a>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [resizeable]="false" [flexGrow]="3" name="From" prop="from_date_order_by"
                      [sortable]="true">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <p *ngFor="let d of row.camptransferdetail">
                          <b class="uppercase">{{d.from_place}}</b> with {{ d.transport }}<br>
                          <small><b><i class="fa fa-calendar" aria-hidden="true"></i>
                              {{d.from_date | date:'d MMMM, y':'':'it'}} at {{d.from_time}}</b></small>
                              <br><small><i class="fa fa-refresh" aria-hidden="true"></i> {{d.updated_at | date:'d MMMM, y':'':'it'}} at {{d.updated_at | date:'HH:mm':'':'it'}}</small>
                        </p>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [resizeable]="false" [flexGrow]="3" name="To" prop="to_date_order_by"
                      [sortable]="true">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <p *ngFor="let d of row.camptransferdetail">
                          <b class="uppercase">{{d.to_place}}</b> with {{ d.transport }}<br>
                          <small><b><i class="fa fa-calendar" aria-hidden="true"></i>
                              {{d.to_date | date:'d MMMM, y':'':'it'}} at {{d.to_time}}</b></small>
                              <br><small><i class="fa fa-refresh" aria-hidden="true"></i> {{d.updated_at | date:'d MMMM, y':'':'it'}} at {{d.updated_at | date:'HH:mm':'':'it'}}</small>
                        </p>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false"
                      [canAutoResize]="false">
                      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                        <button class="btn green btn-outline" type="button" (click)="toggleExpandRow(row)"><i
                            class="fa fa-info-circle" aria-hidden="true"></i></button>
                      </ng-template>
                    </ngx-datatable-column>
                  </ngx-datatable>
                </div>
              </div>
              <div class="row">
                <div class="pull-right">
                  <!-- <button [appCheckPermission]="'admin_reports_export'"
                    [disabled]="getCampService().isOngoingRequest() || rows?.length <= 0" type="button"
                    class="btn green fix-button-margin" (click)="exportToExcel()"><i class="fa fa-share"></i>
                    Export to Excel</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END CONTENT BODY -->
      </div>
    </div>
  </div>
  <!-- end: prima colonna -->
</div>
<!-- end: prima riga -->

<!-- BEGIN: MODAL LOADING FormTransferDetail-->
<!-- <div *ngIf="isModalFormTransferDetail" [config]="{ show: true, backdrop: 'static'}" (onHidden)="onHiddenFormTransferDetail()" bsModal
  #staticModalFormTransferDetail="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Transfer Detail</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalFormTranferDetail()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-person-camp-transfer-detail-form #personCampTransferDetailForm (formDelete)="formDelete($event)"  (formSaved)="formSaved($event)"></app-person-camp-transfer-detail-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- END: MODAL LOADING FormTransferDetail-->

<!-- BEGIN: MODAL LOADING EXPORT-->
<div *ngIf="isModalExport" [config]="{ show: true, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal
  #staticModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
