import { ConfigService } from './../../_services/config.service';
import { UserService, HelperService } from 'app/_services';
import { TheatrinoService } from './../../_services/theatrino.service';
import { Component, OnInit } from '@angular/core';
import { parseISO } from 'date-fns';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-theatrino-reports-referenti',
  templateUrl: './theatrino-reports-referenti.component.html',
  styleUrls: ['./theatrino-reports-referenti.component.css']
})
export class TheatrinoReportsReferentiComponent implements OnInit {

  constructor(private theatrinoService: TheatrinoService, private userService: UserService,
    private configService: ConfigService, private helper: HelperService) {

    this.configureArrayOfDates();

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;
  }

  //  model della ricerca
  model: any = {};

  // generic rows
  rows = [];

  // DATE PICKER
  localDateFrom: IMyDateModel;
  localDateTo: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  datePickerOptionsBegin: IAngularMyDpOptions = {};
  datePickerOptionsEnd: IAngularMyDpOptions = {};

  //  array of dates picker
  datesArray: any[];

  ngOnInit() {

    this.submitSearch();
  }


  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    this.exportParse(this.rows, () => {
      this.hideModalExport();
    });

  }

  submitSearch(){

    this.theatrinoService.getReportReferenti(this.model, (results) => {

      results.forEach(element => {

        //  setto la data formattata
        element.tour_date_prenotation_formatted = parseISO(element.tour_date_prenotation).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

        let primary_address = {
          "locality": element.primary_address_locality,
          "administrative_area_level_3_short_version": element.primary_address_administrative_area_level_3_short_version,
          "administrative_area_level_2_short_version": element.primary_address_administrative_area_level_2_short_version,
          "administrative_area_level_1_long_version": element.primary_address_administrative_area_level_1_long_version,
          "route": element.primary_address_route,
          "street_number": element.primary_address_street_number,
          "postal_code": element.primary_address_postal_code,
          "country_long_version": element.primary_address_country_long_version,
        }
        element.address = this.configService.convertAddressAsStringForTable(primary_address);

      });

      this.rows = JSON.parse(JSON.stringify(results));
    })
  }



  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse(data: any, callback: any) {

    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach((element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      let obj = {
        'Nome Tour': element.theatrino_denomination,
        'Data Tour': parseISO(element.tour_date_prenotation).toLocaleDateString(this.helper.locale),
        'Nome': element.first_name,
        'Cognome': element.surname,
        'Contact': element.primary_contact,
        'Address': element.address,
      };

      //  preparo
      exportedArray.push(obj)

    })

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{ width: 30 }, { width: 20 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 100 }];

    // //  personalizzo l'header
    worksheet['!rows'] = [{ hpx: 30 }];

    // //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'Referenti': worksheet }, SheetNames: ['Referenti'] };

    // //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });

    // //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], { type: EXCEL_TYPE }), "theatrino-referenti.xlsx");

    callback();

  }

  clearSearch() {
    this.model = {};
  }




  configureArrayOfDates() {
    this.datesArray = [];
    this.datesArray.push((new Date()).getFullYear());
  }

  //  Operazioni sulla data di start
  onDateFromChanged(event: IMyDateModel) {

    this.localDateFrom = event;

    if (event.singleDate.jsDate != null) {
      this.model['from_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;

      this.datePickerOptionsEnd = datePickerOpt;
    }
    else {
      this.model['from_date'] = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }




  //  Operazioni sulla data di end
  onDateToChanged(event: IMyDateModel) {

    this.localDateTo = event;

    if (event.singleDate.jsDate != null) {
      this.model['to_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableSince = event.singleDate.date;

      this.datePickerOptionsBegin = datePickerOpt;
    }
    else {
      this.model['to_date'];
      this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }



  fieldChange(event) {

    // this.searchKeywordsSubmit.emit(this.model);
  }





  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.isModalExport = false;
    // this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }






  getTheatrinoService() {
    return this.theatrinoService
  }

  getUserService() {
    return this.userService
  }

  getConfigService() {
    return this.configService;
  }

  getHelper(){
    return this.helper;
  }

}
