import { HttpParams } from '@angular/common/http';
import { BackendService, HelperService } from './../../_services/index';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { parseISO } from 'date-fns';


@Component({
  selector: 'app-theatrino-datatable',
  templateUrl: './theatrino-datatable.component.html',
  styleUrls: ['./theatrino-datatable.component.css']
})
export class TheatrinoDatatableComponent implements OnInit {

  @Input() query: any;

  @Output() theatrinoSelected = new EventEmitter();

  pageTotalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotalPages: number = 0;
  rows: any[] = [];

  selected = [];

  ongoingRequest: boolean = false;

  currentQuery: any;

  constructor(private backend: BackendService, private helper: HelperService) { }

  ngOnInit() {
    // this.setPage({ offset: 0});
  }

  update(date:any){}

  setPage(pageInfo)
  {
    this.pageNumber = pageInfo.offset;
    this.getResult();
  }

  onSelect({ selected }) {
    this.theatrinoSelected.emit(selected[0]);
  }

  onActivate(event) {}

  getResult()
  {
    if (typeof this.query == "undefined")
      return;

    if (JSON.stringify(this.currentQuery) != JSON.stringify(this.query))
    {
      //  Salvo il valore della query attuale e resetto le variabili
      this.currentQuery = Object.assign(this.query);
      this.pageTotalElements = 0;
      this.pageNumber = 0;
      this.pageSize = 0;
    }

    this.rows = [];
    this.pageTotalElements = 0;
    this.pageTotalPages = 0;

    if (typeof this.currentQuery == "undefined" || Object.keys(this.currentQuery).length <= 0)
      return;

    let params = new HttpParams();
    params = params.set('page', (this.pageNumber + 1).toString());

    //   Se non ci sono query faccio una query per recuperare tutti i dati
    if (this.currentQuery == "all")
    {
      this.ongoingRequest = true;

      this.backend.post('theatrino/search/advanced', this.currentQuery, params).subscribe(
        (response) => {

          let res : any = response;

          res.data.forEach(element => {

              if (element.start)
                element.start = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

            if (element.end)
              element.end = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
           });

          this.pageNumber = res.current_page - 1;
          this.pageSize = res.per_page;
          this.pageTotalElements = res.total;
          this.pageTotalPages = res.last_page;
          this.rows = res.data;

          this.ongoingRequest = false;
        },
        (error) => {
          this.backend.showErrors(error);

          this.ongoingRequest = false;
        }
      );

      return;
    }
    if (typeof this.currentQuery.advancedSearchOn == "undefined")
      return;

    let simpleSearchKeyword = this.helper.transformSearchString(this.currentQuery.simpleSearchKeyword);

    this.ongoingRequest = true;

    if (this.currentQuery.advancedSearchOn == "false" || !this.currentQuery.advancedSearchOn) {

      this.backend.get('theatrino/search/' + simpleSearchKeyword, params).subscribe(
        (response) => {
          let res : any = response;

          this.pageNumber = res.current_page - 1;
          this.pageSize = res.per_page;
          this.pageTotalElements = res.total;
          this.pageTotalPages = res.last_page;
          this.rows = res.data;
          this.ongoingRequest = false;

          res.data.forEach(element => {

            if (element.start)
              element.start = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

            if (element.end)
              element.end = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
          });
        },
        (error) => {
          this.backend.showErrors(error);

          this.ongoingRequest = false;
        }
      );

    }
    else {

      this.backend.post('theatrino/search/advanced', this.currentQuery, params).subscribe(
        (response) => {
          let res : any = response;
          this.pageNumber = res.current_page - 1;
          this.pageSize = res.per_page;
          this.pageTotalElements = res.total;
          this.pageTotalPages = res.last_page;
          this.rows = res.data;

          this.ongoingRequest = false;
        },
        (error) => {
          this.backend.showErrors(error);

          this.ongoingRequest = false;
        }
      );
    }

  }

}
