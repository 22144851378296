import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mapFromKeyValue'
})

export class MapFromKeyValuePipe implements PipeTransform {
  
  transform(objArr) 
  {
	let mappingObject = [];

	if (typeof objArr != "undefined" && objArr != null)
	{
		Object.keys(objArr).forEach(key=> {
			mappingObject.push(objArr[key]);
		});
	}
	
    return mappingObject;
  }
}