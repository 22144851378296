import { ModalDirective } from 'ngx-bootstrap/modal';
import { CampSearchFormComponent } from './../camp-search-form/camp-search-form.component';
import { CampDatatableComponent } from './../camp-datatable/camp-datatable.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService, CampService, ConfigService, HelperService } from '../../_services';

//  salvataggio per excel
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { format, parseISO } from 'date-fns';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-camp-search-result',
  templateUrl: './camp-search-result.component.html',
  styleUrls: ['./camp-search-result.component.css']
})
export class CampSearchResultComponent implements OnInit {

  @ViewChild('CampDatatableComponent', {static: true}) datatable: CampDatatableComponent;
  @ViewChild('CampSearchFormComponent', {static: true}) searchForm: CampSearchFormComponent;
  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;

  private subject: any;

  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService,
            private campService: CampService, private configService: ConfigService, private helper: HelperService) { }

  ngOnInit() {

    let params = {};

    if (this.campService.lastSearchQueries != null){
      params = this.campService.lastSearchQueries;
    }
    else {
      this.searchForm.model.year = new Date().getFullYear();
      this.searchForm.model.status = ["1", "3", "4", "5", "6"];
      params = { year: new Date().getFullYear(), status: this.searchForm.model.status };
    }

    this.searchKeywordsSubmit(params);
  }

  campSelected (selected) {
    this.router.navigate(['camp/edit/'+selected.camp_id]);
  }

  updateResults(params) {
    this.datatable.query = params;
    this.datatable.getResult();
  }

  searchKeywordsSubmit(params) {

    // controllo se filtrare i risultati solo per persone presenti nello staff del camp quando non hanno il ruolo Staff
    if (!this.userService.isOfficeStaff()){
      params.current_user_staff_member = true;
    }

    if (typeof params != "undefined" && Object.keys(params).length > 0)
      this.updateResults(params);
  }




  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }


  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    this.campService.exportAll(this.searchForm.model, (res) => {
      this.exportParse(res, () => {
        this.hideModalExport();
      });
    }, () => {
      this.hideModalExport();
    });

  }


  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse (data: any, callback: any) {

    //  se non ci sono dati da salvare
    if (data.length == 0) {
      callback();
      return;
    }

    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach( (element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      //  preparo
      exportedArray.push( {
        'Codice': element.camp_code,
        'Tipo': this.configService.getCampTypeValueByKey(element.type),
        'Seniority': this.configService.getCampSeniorityValueByKey(element.seniority),
        'Nome': element.nome,
        'Data inizio': parseISO(element.start).toLocaleDateString(this.helper.locale),
        'Data fine': parseISO(element.end).toLocaleDateString(this.helper.locale),
        'Stato': this.configService.getCampStatusValueByKey(element.status),
        'Numero settimane': element.num_of_weeks,
        'Sede': element.sede,
        'PV': element.administrative_area_level_2_short_version,
        'Regione': element.administrative_area_level_1_long_version,
        'Numero iscritti': element.subscription_count,
        'Numero CD': element.staff.filter(x => x.type == 3).length,
        'Numero organizzazioni': element.staff.filter(x => x.type == 6).length,
        'Numero helpers': element.staff.filter(x => x.type == 4).length,
        'Numero helpers volontari': element.staff.filter(x => x.type == 5).length,
        'Numero assitenti': element.staff.filter(x => x.type == 1).length,
        'Numero assistenti volontari': element.staff.filter(x => x.type == 2).length,
        'Numero Tutors': element.staff.filter(x => x.type == 8).length,
        'Zona': this.configService.getAreaExpertiseValueByKey(element.area),
        'Company': this.configService.getGroupCompanyValueByKey(element.company)
      })
    })

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{width: 18}, {width:20}, {width:10}, {width:40}, {width:12}, {width:12}, {width:16}, {width:6},
                        {width:40}, {width:4}, {width:14}, {width:4}, {width:4}, {width:4}, {width:4}, {width:4}, {width:4},
                        {width:4}, {width:4}, {width:20}, {width:20}];

    //  personalizzo l'header
    worksheet['!rows'] = [{hpx: 30}];
    worksheet['A1'].s = { alignment: "horizontal" }

    //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'Camps': worksheet}, SheetNames: ['Camps'] };

    //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

    //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "camps.xlsx");

    callback();

  }

  getUserService() {
    return this.userService
  }

  getCampService() {
    return this.campService;
  }


}
