import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-show-search-form',
  templateUrl: './show-search-form.component.html',
  styleUrls: ['./show-search-form.component.css']
})
export class ShowSearchFormComponent implements OnInit {

  model: any = { advancedSearchOn: false, simpleSearchKeyword: "" };

  private firstSubmit: boolean = false;

  public advancedSearchShow = false;

  @Output() searchKeywordsSubmit = new EventEmitter();

  @Input() ongoingRequest: boolean;

  ngOnInit() {
  }


  fieldChange(event) {

    if (!this.firstSubmit)
      return;

     this.searchKeywordsSubmit.emit({});
    }

  submitSearch() {
    this.firstSubmit = true;

    if (this.advancedSearchShow)
      this.model.advancedSearchOn = true;
    else {
      this.model = { advancedSearchOn: false, simpleSearchKeyword: this.model.simpleSearchKeyword };
    }

    this.advancedSearchShow = false;
    this.searchKeywordsSubmit.emit(this.model);
  }

  getAllResources(form: any) {

    this.model = {};

    if (form != null)
      form._submitted = false;

    this.searchKeywordsSubmit.emit();
  }

  hideAdvancedSearch() {
    this.advancedSearchShow = false;
  }

  showAdvancedSearch() {
    this.advancedSearchShow = true;
    this.model.simpleSearchKeyword = "";
  }

}
