import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { PersonRoleFormComponent } from '../index';
import { PersonService } from '../../_services/index';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-person-role-list',
  templateUrl: './person-role-list.component.html',
  styleUrls: ['./person-role-list.component.css']
})
export class PersonRoleListComponent implements OnInit {

  @ViewChildren('personRoleFormList') public personRoleFormList: QueryList<PersonRoleFormComponent>;

  personRoleList: any;
  personRoleListKeys: any[];

  allFormValid = true;
  multiSaveForm = true;

  subscribe: any;

  constructor(private personService: PersonService, private notify: NotificationsService) { }

  ngOnInit() {

    this.personRoleList = this.personService.currentPersonRoleList;

    if (this.personRoleList == null)
      this.addNew();

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      if (this.personService.currentPersonRoleList != null) {

        let newFormModel = null;
        if (typeof this.personRoleFormList.first.model.id == "undefined"
          && this.personRoleFormList.first.currentForm.form.dirty
          && this.personRoleFormList.first.currentForm.form.pending) {
          newFormModel = this.personRoleFormList.first.model;
        }

        this.personRoleListKeys = Object.keys(this.personService.currentPersonRoleList).reverse();

        let personRoleListOld = this.personRoleList;
        this.personRoleList = this.personService.currentPersonRoleList;

        this.personRoleListKeys.forEach(k => {

          if (typeof personRoleListOld[k] != "undefined" && this.personRoleList[k]["updated_at"] == personRoleListOld[k]["updated_at"])
            this.personRoleList[k] = personRoleListOld[k];
        });

        if (newFormModel != null) {
          this.personRoleList[0] = newFormModel;
          this.personRoleListKeys.unshift(0);
        }
      }

      if (this.personService.currentPersonRoleList != null && this.personService.currentPersonRoleList.length == 0) {
        this.addNew();
      }
    });
  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }

  addNew() {

    if (this.personRoleList == null) {
      this.personRoleList = {};
      this.personRoleListKeys = new Array();
    }

    this.personRoleList[0] = {
      "type": this.personService.idDefaultRoleType
    };
    this.personRoleListKeys.unshift(0);

    //  Assegno il ruolo come primary perchè è il primo della lista
    if (this.personRoleListKeys.length == 1)
      this.personRoleList[0].primary = true;
  }



  saveAll() {

    //  controllo che ci sia almeno un elemento nella lista

    this.allFormValid = true;

    // Controllo se tutti i form sono validi
    this.personRoleFormList.forEach(element => {

      if (!element.currentForm.form.valid) {

        //  Controllo se non è valido
        for (var i in element.currentForm.controls) {
          element.currentForm.controls[i].markAsTouched();
        }

        this.allFormValid = false;

        if (this.personRoleList.length == 1 && element.model.type == null)
          this.notify.error("A user must have at least one role set");
        else
          this.notify.error("There is a form with error");
      }

    });

    if (!this.allFormValid)
      return;

    if (!this.checkPrimary())
      return;

    this.personRoleFormList.forEach(element => {
      element.submit();
    });
  }

  checkPrimary() {

    // controllo se esiste almeno un contatto primary
    let primaryFound = false;

    this.personRoleFormList.forEach(element => {
      if (element.model.primary)
        primaryFound = true;
    });

    if (!primaryFound) {
      this.notify.error("You need to set a primary role");
      return false;
    }

    return true;
  }

  primaryUpdate(event) {
    //  Cambio il valore di tutti i campi primary
    if (event.primary == true) {
      this.personRoleListKeys.forEach(element => {
        if (this.personRoleList[element].id != event.id) {
          this.personRoleList[element].primary_backup = this.personRoleList[element].primary;
          this.personRoleList[element].primary = 0;
        }
      });
    }
    else {
      this.personRoleListKeys.forEach(element => {
        if (this.personRoleList[element].primary_backup && this.personRoleList[element].id != event.id) {
          this.personRoleList[element].primary = this.personRoleList[element].primary_backup;
        }
      });
    }

  }

  formDelete(event) {
    if (!event.model.id && this.personRoleListKeys.length > 1) {
      this.personRoleListKeys.splice(0, 1);
      delete this.personRoleList[0];
    }

  }

  formSaved(event) {

    // controllo se questo form ha il primary impostato, perché potrebbe aver cambiato anche gli altri
    if (event.primary == true) {

      this.personRoleFormList.forEach(element => {

        if (event.id != element.model.id) {

          if (typeof element.model.primary_backup != "undefined" && element.model.primary_backup != element.model.primary) {
            element.currentForm.form.markAsDirty();
            element.submit();
          }
        }
      });
    }
  }

  getPersonService() {
    return this.personService
  }
}
