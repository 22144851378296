<img *ngIf="loading" src="./assets/global/img/input-spinner.gif" />
<a *ngIf="currentEnterpriseId" class="pointer black" (click)="clickEnterprise()">{{enterpriseInfo.denomination}}&nbsp;</a>
<a *ngIf="currentEnterpriseId" class="pointer blue" target="_blank" routerLink="/enterprise/edit/{{ currentEnterpriseId }}"><i class="fa fa-external-link" aria-hidden="true"></i></a>
<!-- <a *ngIf="currentEnterpriseId" class="pointer blue" (click)="clickEnterprise()"><i class="fa fa-id-card-o" aria-hidden="true"></i></a> -->

<div *ngIf="showContact || showAddress || showRole">&nbsp;</div>

<div *ngIf="showContact" class="row">
  <div *ngFor="let cont of enterpriseInfo.contact | mapFromKeyValue" class="col-md-6">
    <div class="form-group read-only">
      <label class="control-label">{{ getConfigService().getContactEnterpriseValueByKey(cont.type)}}</label>
      <p>{{cont.value}}</p>
    </div>
  </div>
</div>

<div *ngIf="showAddress" class="row">
  <div *ngFor="let add of enterpriseInfo.address | mapFromKeyValue" class="col-md-6">
      <alert *ngIf="!add.country_long_version" type="warning">
          <strong>Alert!</strong> address is not googlized
        </alert>
    <div class="form-group read-only">
      <label class="control-label">{{getConfigService().getAddressEnterpriseValueByKey(add.type)}}</label>
      <p>
        {{ getConfigService().convertAddressAsStringForTable(add) }}
      </p>
    </div>
  </div>
</div>

<!-- BEGIN: MODAL CONTENT ENTERPRISE INFO -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
tabindex="-1" role="dialog" aria-hidden="true">
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Enterprise Info Summary</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body form">
      <div class="row">
        <div class="col-md-12">
          <app-enterprise-info-summary #enterpriseInfoSummaryComponent [idEnterprise]="currentEnterpriseId" (closeEvent)="hideModal()" ></app-enterprise-info-summary>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!-- END: MODAL CONTENT ENTERPRISE INFO -->
