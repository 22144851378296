import { NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule} from '@angular/common';
import { CountryPickerComponent } from './country-picker.component';
import { CountryPickerService } from './country-picker.service';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [CountryPickerComponent],
  exports: [CountryPickerComponent],
  providers: [CountryPickerService]
})
export class CountryPickerModule {}