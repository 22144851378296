import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BackendService, EnterpriseService, ConfigService, HelperService } from '../../_services/index';


@Component({
  selector: 'app-enterprise-datatable',
  templateUrl: './enterprise-datatable.component.html',
  styleUrls: ['./enterprise-datatable.component.css']
})
export class EnterpriseDatatableComponent implements OnInit {

  @Input() query: any;

  @Output() enterpriseSelected = new EventEmitter();

  pageTotalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotalPages: number = 0;
  pageSort: any = null;
  rows: any[] = [];

  selected = [];

  ongoingRequest: boolean = false;

  currentQuery: any;

  constructor(private backend: BackendService, private enterpriseService: EnterpriseService, private configService: ConfigService, private helperService: HelperService) { }

  ngOnInit() {
    // this.setPage({ offset: 0});
  }

  update (data: any) {
  }

  setPage(pageInfo){
    this.pageNumber = pageInfo.offset;
    this.getResults();
  }

  onSelect({ selected }) {
    this.enterpriseSelected.emit(selected[0]);
  }

  onSort(sortInfo) {

    this.pageSort = sortInfo.sorts[0];
    this.getResults();
  }

  onActivate(event){

  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  getResults() {

      if (typeof this.query == "undefined")
        return;

      if (JSON.stringify(this.currentQuery) != JSON.stringify(this.query))
      {
        //  Salvo il valore della query attuale e resetto le variabili
        this.currentQuery = Object.assign(this.query);
        this.pageTotalElements = 0;
        this.pageNumber = 0;
        this.pageSize = 0;
        this.pageSort = null;
      }

      this.rows = [];
      this.pageTotalElements = 0;
      this.pageTotalPages = 0;

      if (typeof this.currentQuery.advancedSearchOn == "undefined")
        return;

      let simpleSearchKeyword = this.helperService.transformSearchString(this.currentQuery.simpleSearchKeyword);

      let params = new HttpParams();
      params = params.set('page', (this.pageNumber + 1).toString());

      if (this.pageSort != null)
      {
        params = params.set('sortDir', this.pageSort.dir);
        params = params.set('sortBy', this.pageSort.prop);
      }

      this.ongoingRequest = true;

      if (this.currentQuery.advancedSearchOn == "false" || !this.currentQuery.advancedSearchOn) {

        this.backend.get('enterprise/search/' + simpleSearchKeyword, params).subscribe (
          (response) => {
            let res : any = response;
            this.pageNumber = res.current_page - 1;
            this.pageSize = res.per_page;
            this.pageTotalElements = res.total;
            this.pageTotalPages = res.last_page;
            this.rows = this.getTableData(res.data);
            this.ongoingRequest = false;
          },
          (error) => {
            this.backend.showErrors(error);

            this.ongoingRequest = false;
          }
        );

      }
      else {
        this.backend.post('enterprise/search/advanced', this.currentQuery, params).subscribe(
        (response) => {
          let res : any = response;
          this.pageNumber = res.current_page - 1;
          this.pageSize = res.per_page;
          this.pageTotalElements = res.total;
          this.pageTotalPages = res.last_page;
          this.rows = this.getTableData(res.data);
          this.ongoingRequest = false;
        },
        (error) => {
          this.backend.showErrors(error);

          this.ongoingRequest = false;
        }
      );

    }
  }

  getTableData(data){

    for (let key in data){
      data[key].type_name = this.configService.getEnterpriseTypeValueByKey(data[key].type);

      data[key].type_sub_name = "";
      if (typeof data[key].type_sub != "undefined" && data[key].type_sub > 0){
        data[key].type_sub_name = this.configService.getEnterpriseTypeSubSelectValueByKey(data[key].type, data[key].type_sub);
      }

      // controllo se ci sono informazioni di rating da mostrare le concateno al subtype
      if (typeof data[key].rating != "undefined" && data[key].rating > 0){
        data[key].rating_star = "";
        for (let i = 0; i < data[key].rating; i++)
          data[key].rating_star += "<i class='fa fa-star' aria-hidden='true'></i>";
        data[key].rating_star += " ("+data[key].rating+")";
      }

      data[key].address = this.configService.convertAddressAsStringForTable(data[key]);
    }

    return data;
  }

  getEnterpriseService() {
    return this.enterpriseService
  }
}
