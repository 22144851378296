<!-- BEGIN FORM -->
<form (keydown.enter)="checkSubmitKeyEnter($event)" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">
    <h3 class="form-section">Personal Info</h3>

    <div class="row">
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || surname.dirty || surname.touched) && !surname.valid && !surname.disabled }">
          <label class="control-label">Surname <span class="required">*</span></label>
          <input [disabled]="loading" type="text" name="surname" [(ngModel)]="model.surname" #surname="ngModel" nospace class="form-control" placeholder="Chee Kin" autocomplete="nope">
          <span class="help-block">Surname is required </span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || first_name.dirty || first_name.touched) && !first_name.valid  && !first_name.disabled}">
          <label class="control-label">First Name <span class="required">*</span></label>
          <input [disabled]="loading" type="text" name="first_name" [(ngModel)]="model.first_name" #first_name="ngModel" nospace class="form-control" placeholder="Lim" autocomplete="nope">
          <span class="help-block">First name is required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || tax_id.dirty || tax_id.touched) && !tax_id.valid && !tax_id.disabled }">
          <label class="control-label">Codice fiscale/Tax ID &nbsp;
              <check-person-taxid [personInfo]="model"></check-person-taxid>
            <span class="required">*</span>
          </label>
          <input [disabled]="loading" type="text" name="tax_id" [(ngModel)]="model.tax_id" #tax_id="ngModel" minlength="10" maxlength="16" nospace class="form-control" placeholder="CF/Tax ID" autocomplete="nope">
          <span class="help-block">Tax ID or Italian CF (max length 16 chars)</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || iban.dirty || iban.touched) && !iban.valid && !iban.disabled }">
          <label class="control-label">IBAN </label>
          <input [disabled]="loading" type="text" name="iban" [(ngModel)]="model.iban" #iban="ngModel" minlength="16" maxlength="27" class="form-control" placeholder="IBAN" autocomplete="nope">
          <span class="help-block">IBAN (max length 27 chars)</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || gender.dirty || gender.touched) && !gender.valid && !gender.disabled }">
          <label class="control-label">Gender <span class="required">*</span></label>
          <select [disabled]="loading" class="form-control" name="gender" [(ngModel)]="model.gender" #gender="ngModel" required>
            <option *ngFor="let g of getConfigService().genderPersonSelect" [value]="g.key">{{g.value}}</option>
          </select>
          <span class="help-block">Gender is required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': !getHelper().isMyDateValid(localData) }">
          <label class="control-label">Date of Birth <span class="required">*</span></label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_birthday="angular-mydatepicker" name="birthday" class="form-control"
                [locale]="getHelper().locale"
                [disabled]="loading"
                [options]="datePickerOptions"
                [ngModel]="localData"
                (dateChanged)="onDateChanged($event)"
                required
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.birthday" class="btn default" (click)="dp_birthday.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_birthday.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block" *ngIf="model.birthday else noAge">Età calcolata: <b>{{calculateAge}} anni</b> </span>
          <ng-template #noAge>
            <span class="help-block"></span>
          </ng-template>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || birthplace.dirty || birthplace.touched) && !birthplace.valid && !birthplace.disabled }">
          <label class="control-label">Place of Birth <span class="required">*</span></label>
          <input [disabled]="loading" type="text" class="form-control" class="form-control" placeholder="City" name="birthplace" [(ngModel)]="model.birthplace" #birthplace="ngModel" nospace autocomplete="nope"/>
        </div>
        <span class="help-block">Place of Birth required</span>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || tshirt_size.dirty || tshirt_size.touched) && !tshirt_size.valid && !tshirt_size.disabled }">
          <label class="control-label">T-Shirt size </label>
          <select [disabled]="loading" class="form-control" name="tshirt_size" [(ngModel)]="model.tshirt_size" #tshirt_size="ngModel">
            <option *ngFor="let sS of getConfigService().shirtSizePersonSelect" [value]="sS.key">{{sS.value}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || email.dirty || email.touched) && (!validateEmail)}">
          <label class="control-label">Email </label>
          <input [disabled]="loading" type="text" class="form-control" placeholder="" name="email" [(ngModel)]="model.email" #email="ngModel" autocomplete="nope">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || mobile.dirty || mobile.touched) && (!validateMobile)}">
          <label class="control-label">Mobile Phone </label>
          <input [disabled]="loading" type="text" class="form-control" placeholder="" name="mobile" [(ngModel)]="model.mobile" #mobile="ngModel" autocomplete="nope">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-error': ((f.submitted || addressInput.dirty || addressInput.touched) && !addressInput.valid && !addressGooglized) }">
          <label class="control-label">Address <span class="required">*</span></label>
          <input [disabled]="loading" type="text" class="form-control" placeholder="" name="address"
            [(ngModel)]="address" (focus)="setupGooglePlace()" #addressInput nospace autocomplete="nope" (input)="addressChange($event.target.value)">
          <input type="hidden" class="form-control" placeholder="" name="addressCheck" [(ngModel)]="addressCheck" #addressCheckInput="ngModel" autocomplete="nope" />
          <span class="help-block">Start to write the address and select it from the list, powered by Google Place</span>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="addressGooglized">
      <div class="col-md-12">
        <h3 *ngIf="model.administrative_area_level_1_long_version">
          <b>{{addressGooglized.administrative_area_level_3_long_version}} <span *ngIf="addressGooglized.locality != addressGooglized.administrative_area_level_3_long_version">loc. {{addressGooglized.locality}}</span> ({{addressGooglized.administrative_area_level_2_long_version}}, {{addressGooglized.administrative_area_level_1_long_version}})</b>
        </h3>
        <h4 *ngIf="addressGooglized.route">
          {{addressGooglized.route}}, {{addressGooglized.street_number}}
        </h4>
        <h4>
          {{addressGooglized.postal_code}} {{addressGooglized.administrative_area_level_3_short_version}} {{addressGooglized.administrative_area_level_2_short_version}}
        </h4>
        <h4>
          {{addressGooglized.country_long_version}} ({{addressGooglized.country_short_version}})
        </h4>
      </div>
    </div>

  </div>

  <!-- <div  [appCheckPermission]="'person_edit'" class="form-actions right"> -->
  <div class="form-actions right">
    <img *ngIf="loading" src="./assets/global/img/input-spinner.gif" />
    <button type="submit" class="btn green-jungle" [disabled]="loading">Save</button>
  </div>

</form>
