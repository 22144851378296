<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/theatrino/home']">Tours</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span *ngIf="!getTheatrinoService().currentTheatrinoId">New</span>
            <span *ngIf="getTheatrinoService().currentTheatrinoId">Edit</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title" *ngIf="!getTheatrinoService().currentTheatrinoId">New Theatrino Tour</h1>
      <h1 class="page-title" *ngIf="getTheatrinoService().currentTheatrinoInfo">Theatrino
        <b>{{getTheatrinoService().currentTheatrinoInfo.denomination}}</b></h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <div class="row"
        *ngIf="getTheatrinoService().currentTheatrinoInfo && getUserService().checkPermission('theatrino_edit')">
        <div class="col-md-12">
          <app-theatrino-widget-convenzioni [idTheatrino]="getTheatrinoService().currentTheatrinoId">
          </app-theatrino-widget-convenzioni>
        </div>
      </div>

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">

            <div *ngIf="getTheatrinoService().currentTheatrinoInfo" class="portlet-title tabbable-line">
              <div class="caption caption-md">
                <i class="icon-globe theme-font hide"></i>
                <span class="caption-subject font-blue-madison bold uppercase"><i class="fa fa-calendar"
                    aria-hidden="true"></i>&nbsp;{{this.theatrinoTourInfoForm.localDataStart.singleDate.jsDate | date }}
                  - {{this.theatrinoTourInfoForm.localDataEnd.singleDate.jsDate | date }}</span>
              </div>
              <div class="actions">
                <div class="btn-group btn-group-devided" data-toggle="buttons" [appCheckPermission]="'theatrino_edit'">
                  <button *ngIf="!this.theatrinoTourInfoForm.editMode" type="button"
                    class="btn btn-circle btn-outline green" [disabled]="getTheatrinoService().isOngoingRequest()"
                    (click)="editForm()"><i class="fa fa-edit"></i>&nbsp;Edit</button>
                  <button *ngIf="this.theatrinoTourInfoForm.editMode" type="button"
                    class="btn btn-circle btn-outline red" [disabled]="getTheatrinoService().isOngoingRequest()"
                    (click)="editForm()"><i class="fa fa-times"></i>&nbsp;Close</button>
                </div>
              </div>
            </div>

            <!--end: Portlet-Body -->
            <div class="portlet-body form">

              <alert type="warning" *ngIf="loadingData">
                <strong>Loading</strong> ...
              </alert>

              <div [hidden]="loadingData">
                <app-theatrino-tour-info-form #theatrinoTourInfoForm></app-theatrino-tour-info-form>
                <div *ngIf="getTheatrinoService().currentTheatrinoId">
                  <tabset [justified]="true">
                    <tab heading="Calendar">
                      <app-theatrino-tour-booking-calendar [accomodationModal]="theatrinoAccomodationMultiFormModal"
                        [theatrinoDayModal]="theatrinoDayModal"></app-theatrino-tour-booking-calendar>
                    </tab>
                    <tab heading="List">
                      <app-theatrino-tour-booking-list (modalLoading)="ModalLoading($event)">
                      </app-theatrino-tour-booking-list>
                    </tab>
                    <tab heading="Accomodations">
                      <app-theatrino-tour-accomodation-list [accomodationModal]="theatrinoAccomodationMultiFormModal">
                      </app-theatrino-tour-accomodation-list>
                    </tab>
                    <tab heading="Revision">
                      <app-theatrino-revision></app-theatrino-revision>
                    </tab>
                  </tabset>
                </div>
              </div>

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->

      <app-theatrino-accomodation-multi-form-modal #theatrinoAccomodationModal>
      </app-theatrino-accomodation-multi-form-modal>
      <app-theatrino-tour-booking-calendar-day [accomodationModal]="theatrinoAccomodationMultiFormModal"
        #theatrinoDayModal></app-theatrino-tour-booking-calendar-day>

    </div>
  </div>
</div>
<!-- END CONTENT BODY -->


<!-- BEGIN: MODAL LOADING EXPORT-->
<div [hidden]="!isModalExport" [config]="{ backdrop: 'static'}" bsModal #exportLoading="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
