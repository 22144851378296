import { ActivatedRoute } from '@angular/router';
import { CourseService } from './../_services/index';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {

  private subject: any;

  loadingData: boolean = true;

  constructor(private courseService: CourseService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {

      this.courseService.resetService();

      if (typeof params['idCurrentCourse'] != "undefined") {

        this.courseService.currentId = +params['idCurrentCourse'];
        this.courseService.getCourseInfoAll(() => {

          this.loadingData = false;
        }, () => {
          this.loadingData = false;
      });

    }
    else {

      this.loadingData = false;
    }

    });

  }

  getCourseService() {
    return this.courseService
  }

}
