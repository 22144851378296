import { HelperService } from '../../_services';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ConfigService, CourseScheduleService } from '../../_services';

@Component({
  selector: 'app-course-schedule-staff-form',
  templateUrl: './course-schedule-staff-form.component.html',
  styleUrls: ['./course-schedule-staff-form.component.css']
})
export class CourseScheduleStaffFormComponent implements OnInit {


  @Input() modelInput: any;
  @Output() memberDeleted = new EventEmitter;
  @Output() memberUpdated = new EventEmitter;

  model: any = {};
  editMode = false;
  loadingDocuments = false;

  constructor(private configService: ConfigService, private courseScheduleService: CourseScheduleService,
              private helper: HelperService) {
  }

  ngOnInit()
  {
    this.model = this.modelInput;
  }


  destroy ()
  {
    this.courseScheduleService.deleteCourseStaffMember(this.model, () => {

      this.memberDeleted.emit(true);
    });
  }

  edit()
  {
    this.editMode = true;
  }


  submit ()
  {
    if (this.model.id)
    {
      this.updateStaffMember();
    }
  }

  confirmSubmit()
  {
    //  Prima di aggiornare il membro dello staff corrente aspetto di togliere
    //  il ruolo di CM a chi lo possiede in questo momento

    //	cerco la CM con il ruolo di amministratore del campo
    this.courseScheduleService.currentCourseStaffList.forEach(member => {
      if (member.staff_email != null && typeof member.staff_email != "undefined"
          && member.person_id != this.model.person_id)
        //   se lo trovo aggiorno il vecchio CM e aspetto la callback
        {
          member.staff_email = null;
          this.courseScheduleService.updateCourseStaffMember(member, () => {
            this.updateStaffMember();
          })
        }
    })
  }


  updateStaffMember () {
    this.courseScheduleService.updateCourseStaffMember(this.model, () => {
      this.memberUpdated.emit(true);
    });
  }


  getCourseScheduleService() {
    return this.courseScheduleService
  }

  checkSubmitKeyEnter(event){

    if (event.target.name == "birthplace")
      return false;

    return true;
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

}
