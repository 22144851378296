<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">
      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/facilitatorsapplications/home']">Facilitators Applications</a>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Facilitators Applications Edit
      </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <div>
        <tabset>
          <!-- Info TAB -->
          <tab heading="Facilitator Info" id="info">

            <!-- begin: prima riga -->
            <div class="row">
              <div class="col-md-12">

                <!--begin: Portlet -->
                <div class="portlet light bordered">
                  <!--begin: Portlet-Body -->
                  <div class="portlet-body form">

                    <alert type="warning" *ngIf="loading">
                      <strong>Loading</strong> ...
                    </alert>

                    <div *ngIf="!loading">

                      <form action="#" name="form" class="horizontal-form" #f="ngForm" (ngSubmit)="submit()" novalidate>
                        <div class="form-body">
                          <div>
                            <alert *ngIf="facilitatorApplicationInfo.imported" type="warning">
                              <strong>Alert!</strong> Facilitator already imported: this facilitator is present in Person Database.
                            </alert>

                            <alert *ngIf="facilitatorApplicationInfo.person_id" type="info">
                              <button class="btn btn-block btn-outline btn-success" routerLink="/person/edit/{{ facilitatorApplicationInfo.person_id }}">Vai alla scheda della persona creata</button>
                            </alert>
                          </div>

                          <br>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group read-only">
                                <label class="control-label">Created At</label>
                                <p>{{ facilitatorApplicationInfo.created_at | date:'d MMMM, y - HH:mm':'':'it' }}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group read-only">
                                <label class="control-label">Last Update</label>
                                <p>{{ facilitatorApplicationInfo.updated_at | date:'d MMMM, y - HH:mm':'':'it' }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Phase</label>
                                <select class="form-control" name="phase" [(ngModel)]="facilitatorApplicationInfo.phase"
                                  #phase="ngModel">
                                  <option value="1">Phase 1</option>
                                  <option value="2">Phase 2 (SelfTape)</option>
                                  <option value="3">Phase 3 (Skype)</option>
                                  <option value="4">Proposal (Phase 4)</option>
                                  <option value="5">Hire (Phase 5)</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Status</label>
                                <select class="form-control" name="status" [(ngModel)]="facilitatorApplicationInfo.status"
                                  #status="ngModel">
                                  <option value="CREATED">CREATED</option>
                                  <option value="SUBMITTED">SUBMITTED</option>
                                  <option value="EDITING">EDITING</option>
                                  <option value="COMPLETED">COMPLETED</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section">Preferences and Annotation</h4>

                          <div class="row" *ngIf="facilitatorApplicationInfo.phase >= 1">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="control-label">Preference Phase 1</label>
                                <select class="form-control" name="status_01_promote_preference"
                                  [(ngModel)]="facilitatorApplicationInfo.status_01_promote_preference" #phase="ngModel">
                                  <option *ngFor="let t of getConfig().applicationPhasePreferenceSelect"
                                    [value]="t.key">{{t.value}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-7">
                              <div class="form-group">
                                <label class="control-label">Annotation</label>
                                <textarea name="status_01_promote_note"
                                  [(ngModel)]="facilitatorApplicationInfo.status_01_promote_note" rows="3"
                                  class="form-control" placeholder="Some notes..."></textarea>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-group">
                                <label class="control-label">&nbsp;</label>
                                <ng-template #phase1Reminder>
                                  <button type="button" class="btn btn-outline green-jungle"
                                    (click)="inviaMailTo($event, 'phase1_reminder')">Yes, send it!</button>
                                </ng-template>
                                <button type="button" [outsideClick]="true" [popover]="phase1Reminder"
                                  popoverTitle="Are you sure?" class="btn btn-outline blue form-control"><i
                                    class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Reminder</button>
                              </div>
                            </div>
                          </div>

                          <div class="row" *ngIf="facilitatorApplicationInfo.phase >= 2">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="control-label">Preference Phase 2</label>
                                <select class="form-control" name="status_02_promote_preference"
                                  [(ngModel)]="facilitatorApplicationInfo.status_02_promote_preference" #phase="ngModel">
                                  <option *ngFor="let t of getConfig().applicationPhasePreferenceSelect"
                                    [value]="t.key">{{t.value}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-7">
                              <div class="form-group">
                                <label class="control-label">Annotation</label>
                                <textarea name="status_02_promote_note"
                                  [(ngModel)]="facilitatorApplicationInfo.status_02_promote_note" rows="3"
                                  class="form-control" placeholder="Some notes..."></textarea>
                              </div>
                            </div>
                          </div>

                          <div class="row" *ngIf="facilitatorApplicationInfo.phase >= 3">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="control-label">Preference Phase 3</label>
                                <select class="form-control" name="status_03_promote_preference"
                                  [(ngModel)]="facilitatorApplicationInfo.status_03_promote_preference" #phase="ngModel">
                                  <option *ngFor="let t of getConfig().applicationPhasePreferenceSelect"
                                    [value]="t.key">{{t.value}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-7">
                              <div class="form-group">
                                <label class="control-label">Annotation</label>
                                <textarea name="status_03_promote_note"
                                  [(ngModel)]="facilitatorApplicationInfo.status_03_promote_note" rows="3"
                                  class="form-control" placeholder="Some notes..."></textarea>
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section">Personal info</h4>

                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || firstname.dirty || firstname.touched) && !firstname.valid && !firstname.disabled }">
                                <label class="control-label">Name</label>
                                <input type="text" name="firstname" [(ngModel)]="facilitatorApplicationInfo.firstname"
                                  #firstname="ngModel" class="form-control" placeholder="Lim">
                              </div>
                              <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getBirthdayLocalData())) }">
                                <label class="control-label"> Date of Birth</label>
                                  <div class="input-group">
                                    <!-- input box -->
                                    <input angular-mydatepicker #dp_birthday="angular-mydatepicker" name="birthday" class="form-control" [locale]="getHelper().locale" [options]="getDatePickerOptions()"
                                    [ngModel]="getBirthdayLocalData()" (dateChanged)="onDateBirthdayChanged($event)" />
                                    <!-- clear date button -->
                                    <span class="input-group-btn">
                                      <button type="button" *ngIf="facilitatorApplicationInfo?.date_of_birth" class="btn default" (click)="dp_birthday.clearDate()"><i class="fa fa-close"></i></button>
                                    </span>
                                    <!-- toggle calendar button -->
                                    <span class="input-group-btn">
                                        <button type="button" class="btn default" (click)="dp_birthday.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                                    </span>
                                </div>
                              </div>
                              <div class="form-group read-only">
                                <label class="control-label">Nationalities</label>
                                <p>{{getfacilitatorService().getNationalitiesLabel(facilitatorApplicationInfo.nationality)}}
                                </p>
                              </div>
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || criminal_record.dirty || criminal_record.touched) && !criminal_record.valid && !criminal_record.disabled }">
                                <label class="control-label">Criminal Record</label>
                                <select class="form-control" name="criminal_record"
                                  [(ngModel)]="facilitatorApplicationInfo.criminal_record" #criminal_record="ngModel">
                                  <option [value]="'yes'">Yes</option>
                                  <option [value]="'no'">No</option>
                                </select>
                              </div>
                              <div class="form-group">
                                <label class="control-label">Passport Type</label>
                                <select class="form-control" name="passport_type"
                                  [(ngModel)]="facilitatorApplicationInfo.passport_type" #passport_type="ngModel">
                                  <option value="EU_PASSPORT">EU/UK Passport</option>
                                  <option value="WORKING_HOLIDAY_VISA">Working Holiday Visa for Italy</option>
                                  <option value="OTHER_WORKING_VISA">Other Working Visa for Italy</option>
                                  <option value="INTENT_WORKING_VISA">Intend to obtain a working holiday visa for Italy</option>
                                  <option value="NONE">None of these</option>
                                </select>
                              </div>
                              <div class="form-group">
                                <label class="control-label">T-Shirt Size</label>
                                <select class="form-control" name="tshirt_size"
                                  [(ngModel)]="facilitatorApplicationInfo.tshirt_size" #tshirt_size="ngModel">
                                  <option value="xs">XS</option>
                                  <option value="s">S</option>
                                  <option value="m">M</option>
                                  <option value="l">L</option>
                                  <option value="xl">XL</option>
                                  <option value="xxl">XXL</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || surname.dirty || surname.touched) && !surname.valid && !surname.disabled }">
                                <label class="control-label">Surname</label>
                                <input type="text" name="surname" [(ngModel)]="facilitatorApplicationInfo.surname"
                                  #surname="ngModel" class="form-control" placeholder="Lim">
                              </div>
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || gender.dirty || gender.touched) && !gender.valid && !gender.disabled }">
                                <label class="control-label">Gender</label>
                                <select class="form-control" name="gender" [(ngModel)]="facilitatorApplicationInfo.gender"
                                  #gender="ngModel">
                                  <option [value]="'FEMALE'">Female</option>
                                  <option [value]="'MALE'">Male</option>
                                  <option [value]="'CUSTOM'">Custom</option>
                                </select>
                              </div>
                              <div class="form-group" *ngIf="facilitatorApplicationInfo.gender == 'CUSTOM'"
                                [ngClass]="{ 'has-error': (f.submitted || custom_gender.dirty || custom_gender.touched) && !custom_gender.valid && !custom_gender.disabled }">
                                <label class="control-label">Custom Gender</label>
                                <input class="form-control" name="custom_gender" [(ngModel)]="facilitatorApplicationInfo.custom_gender"
                                  #custom_gender="ngModel">
                              </div>
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || native_english_speaker.dirty || native_english_speaker.touched) && !native_english_speaker.valid && !native_english_speaker.disabled }">
                                <label class="control-label">Native English Speaker</label>
                                <select class="form-control" name="native_english_speaker"
                                  [(ngModel)]="facilitatorApplicationInfo.native_english_speaker" #native_english_speaker="ngModel">
                                  <option [value]="'yes'">Yes</option>
                                  <option [value]="'no'">No</option>
                                </select>
                              </div>
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || proficient_english_speaker.dirty || proficient_english_speaker.touched) && !proficient_english_speaker.valid && !proficient_english_speaker.disabled }">
                                <label class="control-label">Proficient English Speaker</label>
                                <select class="form-control" name="proficient_english_speaker"
                                  [(ngModel)]="facilitatorApplicationInfo.proficient_english_speaker" #proficient_english_speaker="ngModel">
                                  <option [value]="'yes'">Yes</option>
                                  <option [value]="'no'">No</option>
                                </select>
                              </div>
                              <div class="form-group" *ngIf="facilitatorApplicationInfo.working_visa_details">
                                <label class="control-label">Other Working visa for Italy</label>
                                <textarea name="working_visa_details"
                                  [(ngModel)]="facilitatorApplicationInfo.working_visa_details" rows="3"
                                  class="form-control"></textarea>
                              </div>
                              <div class="form-group">
                                <label class="control-label">Criminal record detail</label>
                                <textarea name="criminal_record_detail"
                                  [(ngModel)]="facilitatorApplicationInfo.criminal_record_detail" rows="3"
                                  class="form-control"></textarea>
                              </div>

                            </div>

                            <div class="col-md-4 mt-element-card">
                              <div class="mt-card-item">
                                <div *ngIf="facilitatorApplicationInfo.photoUrl" class="mt-overlay-1" aria-hidden="true">
                                  <img *ngIf="facilitatorApplicationInfo.photoUrl"
                                    src="{{facilitatorApplicationInfo.photoUrl}}?{{facilitatorApplicationInfo.updated_at}}"
                                    class="img-responsive pic-bordered" alt="">
                                </div>
                                <div *ngIf="!facilitatorApplicationInfo.photoUrl" class="mt-overlay-1" aria-hidden="true">
                                  <img src="../assets/placeholder/avatar-placeholder.jpg" width="100%">
                                </div>
                              </div>
                              <div class="form-group" *ngIf="facilitatorApplicationInfo.cvUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="facilitatorApplicationInfo.cvUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download CV
                                </a>
                              </div>
                              <div class="form-group" *ngIf="facilitatorApplicationInfo.coverUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="facilitatorApplicationInfo.coverUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download Cover Letter
                                </a>
                              </div>
                              <div class="form-group" *ngIf="facilitatorApplicationInfo.drivingLicenceUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="facilitatorApplicationInfo.drivingLicenceUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download Driver Licence
                                </a>
                              </div>
                              <div class="form-group" *ngIf="facilitatorApplicationInfo.passportUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="facilitatorApplicationInfo.passportUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download Passport
                                </a>
                              </div>
                              <div class="form-group" *ngIf="facilitatorApplicationInfo.intChildCertificateUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="facilitatorApplicationInfo.intChildCertificateUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download International Child
                                  Protection Certificate
                                </a>
                              </div>
                              <div class="form-group" *ngIf="facilitatorApplicationInfo.intDriverLicenceUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="facilitatorApplicationInfo.intDriverLicenceUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download International Driver
                                  Licence
                                </a>
                              </div>
                              <div class="form-group" *ngIf="facilitatorApplicationInfo.healthInsuranceUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="facilitatorApplicationInfo.healthInsuranceUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download Health Insurance
                                </a>
                              </div>
                              <div class="form-group" *ngIf="facilitatorApplicationInfo.flightReceiptUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="facilitatorApplicationInfo.flightReceiptUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download Flight Receipt
                                </a>
                              </div>
                              <div class="form-group" *ngIf="facilitatorApplicationInfo.youtube_selftape_link">
                                <!-- <iframe width="100%" [src]="facilitatorApplicationInfo.youtube_selftape_link | SafeUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <!-- {{ getEmbedService().embed(facilitatorApplicationInfo.youtube_selftape_link) }} -->
                                <a class="btn btn-outline btn-block green-jungle" style="white-space:normal;"
                                  [href]="facilitatorApplicationInfo.youtube_selftape_link" target="_blank">
                                  <i class="fa fa-youtube-play" aria-hidden="true"></i>&nbsp;Self-Tape video
                                </a>
                              </div>

                            </div>

                          </div>

                          <h4 class="form-section">Address &amp; Contacts</h4>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || address.dirty || address.touched) && !address.valid && !address.disabled }">
                                <label class="control-label">Address</label>
                                <input type="text" name="address" [(ngModel)]="facilitatorApplicationInfo.address"
                                  #address="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || postal_code.dirty || postal_code.touched) && !postal_code.valid && !postal_code.disabled }">
                                <label class="control-label">Postal Code</label>
                                <input type="text" name="postal_code" [(ngModel)]="facilitatorApplicationInfo.postal_code"
                                  #postal_code="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || city.dirty || city.touched) && !city.valid && !city.disabled }">
                                <label class="control-label">City</label>
                                <input type="text" name="city" [(ngModel)]="facilitatorApplicationInfo.city" #city="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || province.dirty || province.touched) && !province.valid && !province.disabled }">
                                <label class="control-label">Province</label>
                                <input type="text" name="province" [(ngModel)]="facilitatorApplicationInfo.province"
                                  #province="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Country</label>
                                <p>{{facilitatorApplicationInfo.country}}</p>
                              </div>
                              <!-- <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || country.dirty || country.touched) && !country.valid && !country.disabled }">
                                    <label class="control-label">Country</label>
                                      <input type="text" name="country" [(ngModel)]="facilitatorApplicationInfo.country" #country="ngModel" class="form-control">
                                  </div> -->
                            </div>
                          </div>

                          <div class="row">

                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || email.dirty || email.touched) && !email.valid && !email.disabled }">
                                <label class="control-label">Email</label>
                                <input type="text" name="email" [(ngModel)]="facilitatorApplicationInfo.email"
                                  #email="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || mobile_telephone.dirty || mobile_telephone.touched) && !mobile_telephone.valid && !mobile_telephone.disabled }">
                                <label class="control-label">Mobile telephone</label>
                                <input type="text" name="mobile_telephone"
                                  [(ngModel)]="facilitatorApplicationInfo.mobile_telephone" #mobile_telephone="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section">Driving Information</h4>
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || driver_licence.dirty || driver_licence.touched) && !driver_licence.valid && !driver_licence.disabled }">
                                <label class="control-label">Full driving licence</label>
                                <select class="form-control" name="driver_licence"
                                  [(ngModel)]="facilitatorApplicationInfo.driver_licence" #driver_licence="ngModel">
                                  <option [value]="'yes'">Yes</option>
                                  <option [value]="'no'">No</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || clean_driver_licence.dirty || clean_driver_licence.touched) && !clean_driver_licence.valid && !clean_driver_licence.disabled }">
                                <label class="control-label">Clean driving licence</label>
                                <select class="form-control" name="clean_driver_licence"
                                  [(ngModel)]="facilitatorApplicationInfo.clean_driver_licence" #clean_driver_licence="ngModel">
                                  <option [value]="'yes'">Yes</option>
                                  <option [value]="'no'">No</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || like_drive_italy.dirty || like_drive_italy.touched) && !like_drive_italy.valid && !like_drive_italy.disabled }">
                                <label class="control-label">Happy to drive in Italy</label>
                                <select class="form-control" name="like_drive_italy"
                                  [(ngModel)]="facilitatorApplicationInfo.like_drive_italy" #like_drive_italy="ngModel">
                                  <option [value]="'yes'">Yes</option>
                                  <option [value]="'no'">No</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || shift_type.dirty || shift_type.touched) && !shift_type.valid && !shift_type.disabled }">
                                <label class="control-label">Shift Type</label>
                                <select class="form-control" name="shift_type"
                                  [(ngModel)]="facilitatorApplicationInfo.shift_type" #shift_type="ngModel">
                                  <option value="MANUAL">Manual</option>
                                  <option value="AUTO">Automatic</option>
                                  <option value="BOTH">Both</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section">Tour Preference</h4>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || tour_type.dirty || tour_type.touched) && !tour_type.valid && !tour_type.disabled }">
                                <label class="control-label">Tour</label>
                                <select class="form-control" name="tour_type"
                                  [(ngModel)]="facilitatorApplicationInfo.tour_type" #tour_type="ngModel">
                                  <option value="STATIC">static (based in one place)</option>
                                  <option value="TOURING">Touring</option>
                                  <option value="NOSORRY">I don't mind</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || workshop_agree.dirty || workshop_agree.touched) && !workshop_agree.valid && !workshop_agree.disabled }">
                                <label class="control-label">Workshop Date Accepted</label>
                                <select class="form-control" name="workshop_agree"
                                  [(ngModel)]="facilitatorApplicationInfo.workshop_agree" #workshop_agree="ngModel">
                                  <option [value]="'yes'">Yes</option>
                                  <option [value]="'no'">No</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section">CV & Photo</h4>

                          <div class="row">
                            <div class="col-md-4">
                              <div
                                *ngIf="facilitatorApplicationInfo.photoUrl == undefined || facilitatorApplicationInfo.photoUrl == ''">
                                <input *ngIf="!photoUploader.isUploading" type="file" id="photoUploader" ng2FileSelect
                                  [uploader]="photoUploader" />
                                <label *ngIf="!photoUploader.isUploading" for="photoUploader"
                                  class="btn btn-primary text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload
                                  Picture</label>
                              </div>
                              <div *ngIf="photoUploader.isUploading">
                                <img *ngIf="photoUploader.isUploading" src="./assets/global/img/input-spinner.gif" />
                                please wait...
                              </div>
                              <div
                                *ngIf="facilitatorApplicationInfo.photoUrl != undefined && facilitatorApplicationInfo.photoUrl != ''"
                                style="padding: 10px;">
                                <button *ngIf="!photoUploader.isUploading" type="button"
                                  class="btn btn-danger text-uppercase" (click)="photoRemoved($event);"><i
                                    class="fa fa-trash"></i>&nbsp; Remove Photo</button>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div *ngIf="facilitatorApplicationInfo.cvUrl == undefined || facilitatorApplicationInfo.cvUrl == ''">
                                <input *ngIf="!cvUploader.isUploading" type="file" id="cvUploader" ng2FileSelect
                                  [uploader]="cvUploader" />
                                <label *ngIf="!cvUploader.isUploading" for="cvUploader"
                                  class="btn btn-primary text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload
                                  CV</label>
                              </div>
                              <div *ngIf="cvUploader.isUploading">
                                <img *ngIf="cvUploader.isUploading" src="./assets/global/img/input-spinner.gif" />
                                please wait...
                              </div>
                              <div *ngIf="facilitatorApplicationInfo.cvUrl != undefined && facilitatorApplicationInfo.cvUrl != ''"
                                style="padding: 10px;">
                                <button *ngIf="!cvUploader.isUploading" type="button"
                                  class="btn btn-danger text-uppercase" (click)="cvRemoved($event);"><i
                                    class="fa fa-trash"></i>&nbsp; Remove CV</button>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div
                                *ngIf="facilitatorApplicationInfo.coverUrl == undefined || facilitatorApplicationInfo.coverUrl == ''">
                                <input *ngIf="!coverUploader.isUploading" type="file" id="coverUploader" ng2FileSelect
                                  [uploader]="coverUploader" />
                                <label *ngIf="!coverUploader.isUploading" for="coverUploader"
                                  class="btn btn-primary text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload
                                  Cover</label>
                              </div>
                              <div *ngIf="coverUploader.isUploading">
                                <img *ngIf="coverUploader.isUploading" src="./assets/global/img/input-spinner.gif" />
                                please wait...
                              </div>
                              <div
                                *ngIf="facilitatorApplicationInfo.coverUrl != undefined && facilitatorApplicationInfo.coverUrl != ''"
                                style="padding: 10px;">
                                <button *ngIf="!coverUploader.isUploading" type="button"
                                  class="btn btn-danger text-uppercase" (click)="coverRemoved($event);"><i
                                    class="fa fa-trash"></i>&nbsp; Remove Cover</button>
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section" *ngIf="facilitatorApplicationInfo.youtube_selftape_link">Self-Tape</h4>

                          <div class="row" *ngIf="facilitatorApplicationInfo.youtube_selftape_link">
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || youtube_selftape_link.dirty || youtube_selftape_link.touched) && !youtube_selftape_link.valid && !youtube_selftape_link.disabled }">
                                <label class="control-label">Selftape link</label>
                                <input type="text" name="youtube_selftape_link"
                                  [(ngModel)]="facilitatorApplicationInfo.youtube_selftape_link"
                                  #youtube_selftape_link="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || timezone.dirty || timezone.touched) && !timezone.valid && !timezone.disabled }">
                                <label class="control-label">Timezone</label>
                                <select class="form-control" name="timezone" [(ngModel)]="facilitatorApplicationInfo.timezone"
                                  #timezone="ngModel">
                                  <option *ngFor="let zone of timezones" [value]="zone.name">{{zone.text}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="row" *ngIf="facilitatorApplicationInfo.phase >= 3">
                            <div class="form-group" [ngClass]="{ 'has-error': (f.submitted) && !timeValid }">
                              <div class="col-md-4" *ngFor="let time of timePreferences; let i = index">
                                <input type="checkbox" [checked]="time.checked" (change)="checkboxChanged(i)" />
                                {{timePreferences[i].name}}
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section" *ngIf="facilitatorApplicationInfo.audition">Interview & Audition</h4>
                          <div class="row" *ngIf="facilitatorApplicationInfo.audition">
                            <div class="col-md-6">
                              <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalData(), false)) }">
                                <label class="control-label">Interview Date </label>
                                  <div class="input-group">
                                    <!-- input box -->
                                    <input angular-mydatepicker #dp_interview="angular-mydatepicker"name="interview_date" class="form-control" [locale]="getHelper().locale" [options]="getDatePickerOptions()"
                                    [ngModel]="getLocalData()" (dateChanged)="onDateInterviewChanged($event)" />
                                    <!-- clear date button -->
                                    <span class="input-group-btn">
                                      <button type="button" *ngIf="facilitatorApplicationInfo?.interview_date" class="btn default" (click)="dp_interview.clearDate()"><i class="fa fa-close"></i></button>
                                    </span>
                                    <!-- toggle calendar button -->
                                    <span class="input-group-btn">
                                        <button type="button" class="btn default" (click)="dp_interview.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                                    </span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || interview_time.dirty || interview_time.touched) && !interview_time.valid && !interview_time.disabled }">
                                <label class="control-label">Interview time</label>
                                <input type="text" name="interview_time"
                                  [(ngModel)]="facilitatorApplicationInfo.interview_time" #interview_time="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-4">
                              <div
                                *ngIf="facilitatorApplicationInfo.passportUrl == undefined || facilitatorApplicationInfo.passportUrl == ''">
                                <input *ngIf="!passportUploader.isUploading" type="file" id="passportUploader"
                                  ng2FileSelect [uploader]="passportUploader" />
                                <label *ngIf="!passportUploader.isUploading" for="passportUploader"
                                  class="btn btn-primary text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload
                                  Passport</label>
                              </div>
                              <div *ngIf="passportUploader.isUploading">
                                <img *ngIf="passportUploader.isUploading" src="./assets/global/img/input-spinner.gif" />
                                please wait...
                              </div>
                              <div
                                *ngIf="facilitatorApplicationInfo.passportUrl != undefined && facilitatorApplicationInfo.passportUrl != ''"
                                style="padding: 10px;">
                                <button *ngIf="!passportUploader.isUploading" type="button"
                                  class="btn btn-danger text-uppercase" (click)="passportRemoved($event);"><i
                                    class="fa fa-trash"></i>&nbsp; Remove Passport</button>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div
                                *ngIf="facilitatorApplicationInfo.drivingLicenceUrl == undefined || facilitatorApplicationInfo.drivingLicenceUrl == ''">
                                <input *ngIf="!driverLicenceUploader.isUploading" type="file" id="driverLicenceUploader"
                                  ng2FileSelect [uploader]="driverLicenceUploader" />
                                <label *ngIf="!driverLicenceUploader.isUploading" for="driverLicenceUploader"
                                  class="btn btn-primary text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload
                                  Driver Licence</label>
                              </div>
                              <div *ngIf="driverLicenceUploader.isUploading">
                                <img *ngIf="driverLicenceUploader.isUploading"
                                  src="./assets/global/img/input-spinner.gif" /> please wait...
                              </div>
                              <div
                                *ngIf="facilitatorApplicationInfo.drivingLicenceUrl != undefined && facilitatorApplicationInfo.drivingLicenceUrl != ''"
                                style="padding: 10px;">
                                <button *ngIf="!driverLicenceUploader.isUploading" type="button"
                                  class="btn btn-danger text-uppercase" (click)="drivingLicenceRemoved($event);"><i
                                    class="fa fa-trash"></i>&nbsp; Remove Driver Licence</button>
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Skype interview accepted/rejected</label>
                                <p><b>Accepted:</b>
                                  {{facilitatorApplicationInfo.interview_accepted | date:'d MMMM, y HH:mm':'':'it'}}<br>
                                  <b>Rejected:</b>
                                  {{facilitatorApplicationInfo.interview_rejected | date:'d MMMM, y HH:mm':'':'it'}}<br></p>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <div class="col-md-4">
                                <button type="button" style="white-space:normal;"
                                  class="btn red btn-outline btn-block text-uppercase"
                                  [disabled]="!facilitatorApplicationInfo.interview_accepted && !facilitatorApplicationInfo.interview_rejected"
                                  (click)="deleteInterviewPreferences()">Delete Interview accepted/rejected</button>
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || payment_number.dirty || skype_address.touched) && !skype_address.valid && !skype_address.disabled }">
                                <label class="control-label">Skype address</label>
                                <input type="text" name="skype_address" [(ngModel)]="facilitatorApplicationInfo.skype_address"
                                  #skype_address="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || medical_conditions.dirty || medical_conditions.touched) && !medical_conditions.valid && !medical_conditions.disabled }">
                                <label class="control-label">Medical conditions?</label>
                                <select class="control-label" name="medical_conditions"
                                  [(ngModel)]="facilitatorApplicationInfo.medical_conditions"
                                  #medical_conditions="ngModel" class="form-control">
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" *ngIf="facilitatorApplicationInfo.medical_conditions == 'yes'">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || medical_condition_details.dirty || medical_condition_details.touched) && !medical_condition_details.valid && !medical_condition_details.disabled }">
                                <label class="control-label">Medical conditions Info</label>
                                <textarea type="text" name="medical_condition_details"
                                  [(ngModel)]="facilitatorApplicationInfo.medical_condition_details" #medical_condition_details="ngModel"
                                  class="form-control" row=3></textarea>
                              </div>
                            </div>
                          </div>
                          <br>

                          <h4 class="form-section" *ngIf="facilitatorApplicationInfo.phase >= 3">Proposal Information</h4>

                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || payment_number.dirty || payment_number.touched) && !payment_number.valid && !payment_number.disabled }">
                                <label class="control-label">IBAN / Paypal Address</label>
                                <input type="text" name="payment_number"
                                  [(ngModel)]="facilitatorApplicationInfo.payment_number" #payment_number="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                            <!-- <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || insurance_number.dirty || insurance_number.touched) && !insurance_number.valid && !insurance_number.disabled }">
                                <label class="control-label">Insurance number</label>
                                <input type="text" name="insurance_number"
                                  [(ngModel)]="facilitatorApplicationInfo.insurance_number" #insurance_number="ngModel"
                                  class="form-control">
                              </div>
                            </div> -->
                          </div>
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || emergency_contact_name.dirty || emergency_contact_name.touched) && !emergency_contact_name.valid && !emergency_contact_name.disabled }">
                                <label class="control-label">Emergency Contact Name</label>
                                <input type="text" name="emergency_contact_name"
                                  [(ngModel)]="facilitatorApplicationInfo.emergency_contact_name"
                                  #emergency_contact_name="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || emergency_contact_relationship.dirty || emergency_contact_relationship.touched) && !emergency_contact_relationship.valid && !emergency_contact_relationship.disabled }">
                                <label class="control-label">Emergency Contact Relationship</label>
                                <input type="text" name="emergency_contact_relationship"
                                  [(ngModel)]="facilitatorApplicationInfo.emergency_contact_relationship"
                                  #emergency_contact_relationship="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || emergency_contact_phone.dirty || emergency_contact_phone.touched) && !emergency_contact_phone.valid && !emergency_contact_phone.disabled }">
                                <label class="control-label">Emergency Contact Phone</label>
                                <input type="text" name="emergency_contact_phone"
                                  [(ngModel)]="facilitatorApplicationInfo.emergency_contact_phone"
                                  #emergency_contact_phone="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || emergency_contact_email.dirty || emergency_contact_email.touched) && !emergency_contact_email.valid && !emergency_contact_email.disabled }">
                                <label class="control-label">Emergency Contact Email</label>
                                <input type="text" name="emergency_contact_email"
                                  [(ngModel)]="facilitatorApplicationInfo.emergency_contact_email"
                                  #emergency_contact_email="ngModel" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || dietary_requirements_yes_or_not.dirty || dietary_requirements_yes_or_not.touched) && !dietary_requirements_yes_or_not.valid && !dietary_requirements_yes_or_not.disabled }">
                                <label class="control-label">Dietary Requirements</label>
                                <select class="form-control" name="dietary_requirements_yes_or_not"
                                  [(ngModel)]="facilitatorApplicationInfo.dietary_requirements_yes_or_not"
                                  #dietary_requirements_yes_or_not="ngModel">
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                              <div *ngIf="facilitatorApplicationInfo?.dietary_requirements_yes_or_not == 'yes'"
                                class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || dietary_requirements.dirty || dietary_requirements.touched) && !dietary_requirements.valid && !dietary_requirements.disabled }">
                                <textarea class="form-control" name="dietary_requirements"
                                  [(ngModel)]="facilitatorApplicationInfo.dietary_requirements"
                                  #dietary_requirements="ngModel" row="3"></textarea>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || allergic_yes_or_not.dirty || allergic_yes_or_not.touched) && !allergic_yes_or_not.valid && !allergic_yes_or_not.disabled }">
                                <label class="control-label">Allergies</label>
                                <select class="form-control" name="allergic_yes_or_not"
                                  [(ngModel)]="facilitatorApplicationInfo.allergic_yes_or_not" #allergic_yes_or_not="ngModel">
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                              <div *ngIf="facilitatorApplicationInfo?.allergic_yes_or_not == 'yes'" class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || allergic_to.dirty || allergic_to.touched) && !allergic_to.valid && !allergic_to.disabled }">
                                <textarea class="form-control" name="allergic_to"
                                  [(ngModel)]="facilitatorApplicationInfo.allergic_to" #allergic_to="ngModel"
                                  row=3></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || availability.dirty || availability.touched) && !availability.valid && !availability.disabled }">
                                <label class="control-label">Availability</label>
                                <textarea class="form-control" name="availability"
                                  [(ngModel)]="facilitatorApplicationInfo.availability" #availability="ngModel">
                                </textarea>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || flight_details.dirty || flight_details.touched) && !flight_details.valid && !flight_details.disabled }">
                                <label class="control-label">Flight Details</label>
                                <textarea class="form-control" name="flight_details"
                                  [(ngModel)]="facilitatorApplicationInfo.flight_details" #flight_details="ngModel">
                                </textarea>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="form-group">
                              <div class="col-md-3">
                                <div
                                  *ngIf="facilitatorApplicationInfo.intChildCertificateUrl == undefined || facilitatorApplicationInfo.intChildCertificateUrl == ''">
                                  <input *ngIf="!intChildProtectionLicenceUploader.isUploading" type="file"
                                    id="intChildProtectionLicenceUploader" ng2FileSelect
                                    [uploader]="intChildProtectionLicenceUploader" />
                                  <label *ngIf="!intChildProtectionLicenceUploader.isUploading"
                                    style="white-space:normal; min-height:54px" for="intChildProtectionLicenceUploader"
                                    class="btn btn-primary text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload
                                    Internation Child Protection Certificate</label>
                                </div>
                                <div *ngIf="intChildProtectionLicenceUploader.isUploading">
                                  <img *ngIf="intChildProtectionLicenceUploader.isUploading"
                                    src="./assets/global/img/input-spinner.gif" /> please wait...
                                </div>
                                <div
                                  *ngIf="facilitatorApplicationInfo.intChildCertificateUrl != undefined && facilitatorApplicationInfo.intChildCertificateUrl != ''"
                                  style="padding: 10px;">
                                  <button *ngIf="!intChildProtectionLicenceUploader.isUploading"
                                    style="white-space:normal; min-height:54px" type="button" class="btn btn-danger text-uppercase"
                                    (click)="intChildProtectCertificateRemove();"><i class="fa fa-trash"></i>&nbsp;
                                    Remove Internation Child Protection Certificate</button>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div
                                  *ngIf="facilitatorApplicationInfo.intDriverLicenceUrl == undefined || facilitatorApplicationInfo.intDriverLicenceUrl == ''">
                                  <input *ngIf="!intDriverLicenceUploader.isUploading" type="file"
                                    id="intDriverLicenceUploader" ng2FileSelect [uploader]="intDriverLicenceUploader" />
                                  <label *ngIf="!intDriverLicenceUploader.isUploading" style="white-space:normal; min-height:54px"
                                    for="intDriverLicenceUploader" class="btn btn-primary text-uppercase"><i
                                      class="fa fa-upload"></i>&nbsp; Upload International Driver Licence</label>
                                </div>
                                <div *ngIf="intDriverLicenceUploader.isUploading">
                                  <img *ngIf="intDriverLicenceUploader.isUploading"
                                    src="./assets/global/img/input-spinner.gif" /> please wait...
                                </div>
                                <div
                                  *ngIf="facilitatorApplicationInfo.intDriverLicenceUrl != undefined && facilitatorApplicationInfo.intDriverLicenceUrl != ''"
                                  style="padding: 10px;">
                                  <button *ngIf="!intDriverLicenceUploader.isUploading" style="white-space:normal; min-height:54px"
                                    type="button" class="btn btn-danger text-uppercase"
                                    (click)="intDriverLicenceRemove();"><i class="fa fa-trash"></i>&nbsp; Remove
                                    International Driver Licence</button>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div
                                  *ngIf="facilitatorApplicationInfo.healthInsuranceUrl == undefined || facilitatorApplicationInfo.healthInsuranceUrl == ''">
                                  <input *ngIf="!healthInsuranceUploader.isUploading" type="file"
                                    id="healthInsuranceUploader" ng2FileSelect [uploader]="healthInsuranceUploader" />
                                  <label *ngIf="!healthInsuranceUploader.isUploading" style="white-space:normal; min-height:54px"
                                    for="healthInsuranceUploader" class="btn btn-primary text-uppercase"><i
                                      class="fa fa-upload"></i>&nbsp; Upload Health Insurance</label>
                                </div>
                                <div *ngIf="healthInsuranceUploader.isUploading">
                                  <img *ngIf="healthInsuranceUploader.isUploading"
                                    src="./assets/global/img/input-spinner.gif" /> please wait...
                                </div>
                                <div
                                  *ngIf="facilitatorApplicationInfo.healthInsuranceUrl != undefined && facilitatorApplicationInfo.healthInsuranceUrl != ''"
                                  style="padding: 10px;">
                                  <button *ngIf="!healthInsuranceUploader.isUploading" style="white-space:normal; min-height:54px"
                                    type="button" class="btn btn-danger text-uppercase"
                                    (click)="healthInsuranceRemove();"><i class="fa fa-trash"></i>&nbsp; Remove Health
                                    Insurance</button>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div
                                  *ngIf="facilitatorApplicationInfo.flightReceiptUrl == undefined || facilitatorApplicationInfo.flightReceiptUrl == ''">
                                  <input *ngIf="!flightReceiptUploader.isUploading" type="file"
                                    id="flightReceiptUploader" ng2FileSelect [uploader]="flightReceiptUploader" />
                                  <label *ngIf="!flightReceiptUploader.isUploading" style="white-space:normal; min-height:54px"
                                    for="flightReceiptUploader" class="btn btn-primary text-uppercase"><i
                                      class="fa fa-upload"></i>&nbsp; Upload Flight Receipt</label>
                                </div>
                                <div *ngIf="flightReceiptUploader.isUploading">
                                  <img *ngIf="flightReceiptUploader.isUploading"
                                    src="./assets/global/img/input-spinner.gif" /> please wait...
                                </div>
                                <div
                                  *ngIf="facilitatorApplicationInfo.flightReceiptUrl != undefined && facilitatorApplicationInfo.flightReceiptUrl != ''"
                                  style="padding: 10px;">
                                  <button *ngIf="!flightReceiptUploader.isUploading" style="white-space:normal; min-height:54px"
                                    type="button" class="btn btn-danger text-uppercase"
                                    (click)="flightReceiptRemove();"><i class="fa fa-trash"></i>&nbsp; Remove Flight
                                    Receipt</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br>


                          <h4 class="form-section" *ngIf="facilitatorApplicationInfo.phase >= 4">Additional Proposal Information</h4>

                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Professional reference 1</label>
                                <input type="text" name="professional_reference1"
                                  [(ngModel)]="facilitatorApplicationInfo.professional_reference1" #professional_reference1="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Professional reference 2</label>
                                <input type="text" name="professional_reference2"
                                  [(ngModel)]="facilitatorApplicationInfo.professional_reference2" #professional_reference2="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">University</label>
                                <input type="text" name="university"
                                  [(ngModel)]="facilitatorApplicationInfo.university" #university="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Relevant qualifications</label>
                                <textarea type="text" name="qualifications"
                                  [(ngModel)]="facilitatorApplicationInfo.qualifications" #qualifications="ngModel"
                                  class="form-control" row="3"></textarea>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Working Experience with children</label>
                                <textarea type="text" name="children_working_experience"
                                  [(ngModel)]="facilitatorApplicationInfo.children_working_experience" #children_working_experience="ngModel"
                                  class="form-control" rows="3"></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Musical Instruments</label>
                                <input type="text" name="musical_instruments"
                                  [(ngModel)]="facilitatorApplicationInfo.musical_instruments" #musical_instruments="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Teach any Sports</label>
                                <input type="text" name="sports"
                                  [(ngModel)]="facilitatorApplicationInfo.sports" #sports="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Can you speak Italian</label>
                                <select class="form-control" name="italian_speak_rate" [(ngModel)]="facilitatorApplicationInfo.italian_speak_rate"
                                #italian_speak_rate="ngModel">
                                  <option value="0">0</option><option value="1">1</option><option value="2">2</option><option value="3">3</option>
                                  <option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option>
                                  <option value="8">8</option><option value="9">9</option>
                              </select>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Do you smoke</label>
                                <select class="form-control" name="smoker" [(ngModel)]="facilitatorApplicationInfo.smoker"
                                #smoker="ngModel">
                                  <option value="yes">Yes</option>
                                  <option value="socially">Socially</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">First Aid Certificate</label>
                                <input type="text" name="first_aid_year_certificate"
                                [(ngModel)]="facilitatorApplicationInfo.first_aid_year_certificate" #first_aid_year_certificate="ngModel"
                                class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">TEFL Certificate</label>
                                <input type="text" name="tefl_certificate"
                                  [(ngModel)]="facilitatorApplicationInfo.tefl_certificate" #tefl_certificate="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Confident swimmer</label>
                                <input type="checkbox" #condident_swimmer="ngModel" name="condident_swimmer"
                                [(ngModel)]="facilitatorApplicationInfo.confident_swimmer" class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Previous Teaching Experience in Drama</label>
                                <textarea type="text" name="drama_experience"
                                  [(ngModel)]="facilitatorApplicationInfo.drama_experience" #drama_experience="ngModel"
                                  class="form-control" rows="3"></textarea>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Different Skills or experience</label>
                                <textarea type="text" name="skills"
                                  [(ngModel)]="facilitatorApplicationInfo.skills" #skills="ngModel"
                                  class="form-control" rows="3"></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Stay in a Catholic environment</label>
                                <input type="checkbox" #catholic="ngModel" name="catholic"
                                [(ngModel)]="facilitatorApplicationInfo.catholic" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Shall be living with host families</label>
                                <input type="checkbox" #host_families="ngModel" name="host_families"
                                [(ngModel)]="facilitatorApplicationInfo.host_families" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Hear About</label>
                                <input type="text" name="how_hear_about"
                                  [(ngModel)]="facilitatorApplicationInfo.how_hear_about" #how_hear_about="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                          </div>

                        </div>


                        <div class="form-actions right">
                          <button type="submit" class="btn blue">Update</button>
                        </div>

                      </form>
                    </div>


                  </div>
                  <!--end: Portlet-Body -->
                </div>
                <!--end: Portlet -->
              </div>
            </div>
            <!--end: prima riga -->

          </tab>

          <!-- Revision TAB -->
          <tab heading="Facilitator Revision">

            <a class="btn btn-primary btn-block" (click)="this.getFacilitatorApplicationRevision()">Get Revisions</a>

            <alert type="warning" *ngIf="loading">
              <strong>Loading</strong> ...
            </alert>
            <br>
            <ngx-datatable *ngIf="!loading && getfacilitatorService().currentFacilitatorApplicationRevision.length > 0"
              class="striped" [rows]="getfacilitatorService().currentFacilitatorApplicationRevision"
              [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'">
              <ngx-datatable-column name="Date" [flexGrow]="1" [sortable]="true" [resizeable]="false"
                prop="revision.created_at">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{value}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="User" [flexGrow]="1" [sortable]="true" [resizeable]="false"
                prop="person.name">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a routerLink="/person/edit/{{row.person.id}}">{{value}}</a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Action" [flexGrow]="1" [sortable]="false" [resizeable]="false"
                prop="revision.key">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span *ngIf="row.revision.key == 'created_at'"><i class="fa fa-plus-square"></i> Created</span>
                  <span *ngIf="row.revision.key == 'deleted_at'"><i class="fa fa-trash-o"></i> Deleted</span>
                  <span *ngIf="row.revision.key != 'created_at' && row.revision.key != 'deleted_at'"><i
                      class="fa fa-edit"></i> Edit</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Log" [flexGrow]="4" [sortable]="false" [resizeable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  field <b>'{{row.revision.key}}'</b> in {{row.revision.revisionable_type}} (id:
                  {{row.revision.revisionable_id}}) <b>from</b> <i>'{{row.revision.old_value}}'</i> <b>to</b>
                  <i>'{{row.revision.new_value}}'</i>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </tab>
        </tabset>
      </div>

    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
