import { Injectable } from '@angular/core';
import { PersonService } from './person.service';
import { EnterpriseService } from './enterprise.service';
import { GroupService } from './group.service';
import { ShowService } from './show.service';
import { TheatrinoService } from './theatrino.service';
import { CampService } from './camp.service';
import { RevisionService } from './revision.service';
import { PrimanotaService } from './primanota.service';
import { AddressService } from './address.service';
import { TutorApplicationService } from './tutor-application.service';
import { TutorService } from './tutor.service';
import { WarehouseService } from './warehouse.service';
import { CourseService } from './course.service';
import { CourseScheduleService } from './course-schedule.service';
import { ShipmentService } from './shipment.service';
import { VehicleService } from './vehicle.service';
import { AdminReportsService } from './admin-reports.service';

@Injectable()
export class ResetServicesService {

  constructor(private personService: PersonService,
    private enterpriseService: EnterpriseService,
    private groupService: GroupService,
    private showService: ShowService,
    private theatrinoService: TheatrinoService,
    private campService: CampService,
    private revisionService: RevisionService,
    private primanotaService: PrimanotaService,
    private tutorApplicationService: TutorApplicationService,
    private tutorService: TutorService,
    private warehouseService: WarehouseService,
    private courseService: CourseService,
    private courseScheduleService: CourseScheduleService,
    private shipmentService: ShipmentService,
    private vehicleService: VehicleService,
    private adminReportsService: AdminReportsService) { }

    public resetAllServices(){

      this.personService.resetService();
      this.enterpriseService.resetService();
      this.groupService.resetService();
      this.showService.resetService();
      this.theatrinoService.resetService();
      this.campService.resetService();
      this.revisionService.resetService();
      this.primanotaService.resetService();
      this.tutorApplicationService.resetService();
      this.tutorService.resetService();
      this.warehouseService.resetService();
      this.courseService.resetService();
      this.courseScheduleService.resetService();
      this.shipmentService.resetService();
      this.vehicleService.resetService();
      this.adminReportsService.resetService();

      console.log("Reset all services done");
    }

}
