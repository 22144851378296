import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { EnterpriseAddressFormComponent } from '../index';
import { EnterpriseService } from '../../_services/index';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-enterprise-address-list',
  templateUrl: './enterprise-address-list.component.html',
  styleUrls: ['./enterprise-address-list.component.css']
})
export class EnterpriseAddressListComponent implements OnInit {

  @ViewChildren('enterpriseAddressFormList') public enterpriseAddressFormList: QueryList<EnterpriseAddressFormComponent>;

  enterpriseAddressList: any;
  enterpriseAddressListKeys: any[];

  allFormValid = true;
  multiSaveForm = true;

  subscribe: any;

  constructor(private enterpriseService: EnterpriseService, private notify: NotificationsService) { }

  ngOnInit() {

    this.enterpriseAddressList = this.enterpriseService.currentEnterpriseAddressList;

    if (this.enterpriseAddressList == null)
      this.addNew();

    this.subscribe = this.enterpriseService.getsubjectToUpdateObservable().subscribe((update:boolean) => {

      if (this.enterpriseService.currentEnterpriseAddressList != null) {

        let newFormModel = null;
        if (typeof this.enterpriseAddressFormList.first.model.id == "undefined"
          && this.enterpriseAddressFormList.first.currentForm.form.dirty
          && this.enterpriseAddressFormList.first.currentForm.form.pending) {
          newFormModel = this.enterpriseAddressFormList.first.model;
        }

        this.enterpriseAddressListKeys = Object.keys(this.enterpriseService.currentEnterpriseAddressList).reverse();

        let enterpriseAddressListOld = this.enterpriseAddressList;
        this.enterpriseAddressList = this.enterpriseService.currentEnterpriseAddressList;

        this.enterpriseAddressListKeys.forEach(k => {

          if (typeof enterpriseAddressListOld[k] != "undefined" && this.enterpriseAddressList[k]["updated_at"] == enterpriseAddressListOld[k]["updated_at"])
            this.enterpriseAddressList[k] = enterpriseAddressListOld[k];

        });

        if (newFormModel != null) {
          this.enterpriseAddressList[0] = newFormModel;
          this.enterpriseAddressListKeys.unshift(0);
        }
      }

      if (this.enterpriseService.currentEnterpriseAddressList != null && this.enterpriseService.currentEnterpriseAddressList.length == 0)
        this.addNew();

    });
  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }


  addNew() {
    if (this.enterpriseAddressList == null) {
      this.enterpriseAddressList = {};
      this.enterpriseAddressListKeys = new Array();
    }

    this.enterpriseAddressList[0] = {};
    this.enterpriseAddressListKeys.unshift(0);

    //  Assegno l'indirizzo come primary perchè è il primo della lista
    if (this.enterpriseAddressListKeys.length == 1)
      this.enterpriseAddressList[0].primary = true;
  }



  saveAll() {

    this.allFormValid = true;

    // Controllo se tutti i form sono validi
    this.enterpriseAddressFormList.forEach(element => {

      if (!element.currentForm.form.valid) {

        //  Controllo se non è valido

        for (var i in element.currentForm.controls) {
          element.currentForm.controls[i].markAsTouched();
        }

        this.allFormValid = false;
        this.notify.error("There is a form with error");
      }

    });

    if (!this.allFormValid)
      return;

    if (!this.checkPrimary())
      return;

    this.enterpriseAddressFormList.forEach(element => {
      element.submit();
    });
  }

  checkPrimary() {

    // controllo se esiste almeno un contatto primary
    let primaryFound = false;

    if (this.enterpriseAddressFormList != null && typeof this.enterpriseAddressFormList != "undefined")
    {
      this.enterpriseAddressFormList.forEach(element => {
        if (element.model.primary)
          primaryFound = true;
      });
  
      if (!primaryFound) {
        this.notify.error("You need to set a primary address");
        return false;
      }

      return true;
    }

    return true;
  }


  primaryUpdate(event) {
    //  Cambio il valore di tutti i campi primary
    if (event.primary == true) {
      this.enterpriseAddressListKeys.forEach(element => {
        if (this.enterpriseAddressList[element].id != event.id) {
          this.enterpriseAddressList[element].primary_backup = this.enterpriseAddressList[element].primary;
          this.enterpriseAddressList[element].primary = 0;
        }
      });
    }
    else {
      this.enterpriseAddressListKeys.forEach(element => {
        if (this.enterpriseAddressList[element].primary_backup && this.enterpriseAddressList[element].id != event.id) {
          this.enterpriseAddressList[element].primary = this.enterpriseAddressList[element].primary_backup;
        }
      });
    }
  }



  formDelete(event) {
    if (!event.model.id && this.enterpriseAddressListKeys.length > 1) {
      this.enterpriseAddressListKeys.splice(0, 1);
      delete this.enterpriseAddressList[0];
    }
  }

  formSaved(event) {

    // controllo se questo form ha il primary impostato, perché potrebbe aver cambiato anche gli altri
    if (event.primary == true) {

      this.enterpriseAddressFormList.forEach(element => {

        if (event.id != element.model.id) {

          if (typeof element.model.primary_backup != "undefined" && element.model.primary_backup != element.model.primary) {
            element.currentForm.form.markAsDirty();
            element.submit();
          }
        }
      });
    }
  }

  getEnterpriseService() {
    return this.enterpriseService
  }
}
