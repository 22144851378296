<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">

    <h3 *ngIf="editMode" class="form-section">Address</h3>
    <h3 *ngIf="!editMode" class="form-section">
        <i *ngIf="model.primary" class="fa fa-address-card" aria-hidden="true"></i> {{getConfigService().getAddressPersonValueByKey(model.type)}}
    </h3>

    <div class="row">
      <div class="col-md-12">
          <alert *ngIf="model.id && !model.country_long_version" type="warning">
              <strong>Alert!</strong> address is not googlized
            </alert>
      </div>

      <div [hidden]="!editMode" class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
          <label class="control-label">Type <span class="required">*</span></label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
            <option *ngFor="let a of getConfigService().addressPersonSelect" [value]="a.key">{{a.value}}</option>
          </select>
          <span class="help-block">Select the address type, is required</span>
        </div>
      </div>

      <div *ngIf="editMode" class="col-md-6">
        <div *ngIf="editMode" class="form-group">
          <label><i class="fa fa-address-card" aria-hidden="true"></i> Primary</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="primary" [(ngModel)]="model.primary" value="1" (ngModelChange)="valueChange($event)"> Yes, show this data in the search results and in the summary screen of this person
              <span></span>
            </label>
          </div>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Primary</label>
          <p *ngIf="model.primary">Yes, show this data in the search results and in the summary screen of this person</p>
          <p *ngIf="!model.primary">No, hide this data in the search results and in the summary screen of this person</p>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="type.valid && editMode">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-error': ((f.submitted || addressInput.dirty || addressInput.touched) && (!addressCheckInput.valid)) }">
          <label class="control-label">Address <span class="required">*</span></label>
          <input [disabled]="getPersonService().isOngoingRequest()" type="text" class="form-control" placeholder="" name="address"
            [(ngModel)]="address" (focus)="setupGooglePlace()" #addressInput>
          <input type="hidden" class="form-control" placeholder="" name="addressCheck" [(ngModel)]="addressCheck" #addressCheckInput="ngModel"
            required/>
          <span class="help-block">Start to write the address and select it from the list, powered by Google Place</span>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="type.valid">
      <div class="col-md-12">
        <h3 *ngIf="model.administrative_area_level_1_long_version">
          <b>{{model.administrative_area_level_3_long_version}} <span *ngIf="model.locality != model.administrative_area_level_3_long_version">loc. {{model.locality}}</span> ({{model.administrative_area_level_2_long_version}}, {{model.administrative_area_level_1_long_version}})</b>
        </h3>
        <h4 *ngIf="model.route">
          {{model.route}}, {{model.street_number}}
        </h4>
        <h4>
          {{model.postal_code}} {{model.administrative_area_level_3_short_version}} {{model.administrative_area_level_2_short_version}}
        </h4>
        <h4>
          {{model.country_long_version}} ({{model.country_short_version}})
        </h4>
      </div>
    </div>

    <div class="row"  *ngIf="model.note || editMode">
      <div class="col-md-12">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" placeholder="Some notes..." name="note" [(ngModel)]="model.note"></textarea>
        </div>
        <div *ngIf="!editMode"  class="form-group editMode">
          <label class="control-label">Notes</label>
          <p class="line-breaker">{{model.note}}</p>
        </div>
      </div>
    </div>



  </div>

  <div [appCheckPermission]="'person_edit'" class="form-actions right">
    <img *ngIf="getPersonService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getPersonService().isOngoingRequest()" *ngIf="this.model.id" [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline red" [disabled]="getPersonService().isOngoingRequest()" *ngIf="!this.model.id" (click)="delete()"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline blue" [disabled]="getPersonService().isOngoingRequest()" *ngIf="this.model.id && !editMode" (click)="edit()"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

    <button *ngIf="multiSave == false || editMode" type="button" (click)="submit()" class="btn blue" [disabled]="getPersonService().isOngoingRequest()"
      [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>
