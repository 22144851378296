import { Router, ActivatedRoute } from '@angular/router';
import { ShowDatatableComponent } from './../show-datatable/show-datatable.component';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-show-search-result',
  templateUrl: './show-search-result.component.html',
  styleUrls: ['./show-search-result.component.css']
})
export class ShowSearchResultComponent implements OnInit {

  @ViewChild(ShowDatatableComponent, {static: true}) datatable: ShowDatatableComponent;

  private subject: any;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.getAllResult();
  }

  showSelected (selected) {

    this.router.navigate(['show/edit/'+selected.show_id]);
  }

  getAllResult() {
    this.datatable.query = "all";
    this.datatable.getResult();
  }

  updateResults(params) {
    this.datatable.query = params;
    this.datatable.getResult();
  }

  searchKeywordsSubmit(params) {

    if (typeof params == "undefined")
      this.getAllResult();
    else
      this.updateResults(params);
  }

}
