
    <form role="form" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
        <div class="form-body">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label class="control-label">Product Type </label>
                <p>
                  {{ getConfigService().getWarehouseProductTypeValueByKey(model?.product_info?.type) }}
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Product Name </label>
                <p>
                  {{ model?.product_info?.name }}
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || quantity.dirty || quantity.touched) && !quantity.valid && !quantity.disabled }">
                <label class="control-label">Quantity <span class="required">*</span></label>
                <input [disabled]="getShipmentService().isOngoingRequest()" type="number" min="0" name="quantity" required [(ngModel)]="model.quantity" #quantity="ngModel" class="form-control" placeholder="10">
              </div>
            </div>
          </div>
        </div>
        <div class="form-actions right">
          <img *ngIf="getShipmentService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
          <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroyMaterial();">Yes Delete!</button></ng-template>
          <button type="button" class="btn btn-outline red" *ngIf="model.id" [disabled]="getShipmentService().isOngoingRequest()"
              [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
          <button type="submit" [disabled]="getShipmentService().isOngoingRequest()" class="btn blue" *ngIf="model.id">Update</button>
          <button type="submit" [disabled]="getShipmentService().isOngoingRequest()" class="btn green-jungle" *ngIf="!model.id">Save</button>
          <button type="button" class="btn default" (click)="cancel()" *ngIf="!model.id">Cancel</button>
        </div>
    </form>