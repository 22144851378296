import { ConfigService } from './../../_services/config.service';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { BackendService, HelperService } from './../../_services/index';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-camp-datatable',
  templateUrl: './camp-datatable.component.html',
  styleUrls: ['./camp-datatable.component.css']
})

export class CampDatatableComponent implements OnInit {

  @Input() query: any;

  @Output() campSelected = new EventEmitter();

  pageTotalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotalPages: number = 0;
  rows: any[] = [];

  selected = [];

  ongoingRequest: boolean = false;

  currentQuery: any;

  constructor(private backend: BackendService, private helper: HelperService,
    private configService: ConfigService) { }

  ngOnInit() {
  }

  update(date: any) { }

  setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.getResult();
  }

  onSelect({ selected }) {
    // this.campSelected.emit(selected[0]);
  }

  openSelected(row) {
    this.campSelected.emit(row);
  }

  onActivate(event) { }

  getResult() {
    
    if (typeof this.query == "undefined")
      return;

    if (JSON.stringify(this.currentQuery) != JSON.stringify(this.query)) {
      //  Salvo il valore della query attuale e resetto le variabili
      this.currentQuery = Object.assign(this.query);
      this.pageTotalElements = 0;
      this.pageNumber = 0;
      this.pageSize = 0;
    }

    this.rows = [];
    this.pageTotalElements = 0;
    this.pageTotalPages = 0;

    if (typeof this.currentQuery == "undefined" || Object.keys(this.currentQuery).length <= 0)
      return;

    let params = new HttpParams();
    // params = params.set('page', (this.pageNumber + 1).toString());

    //  Faccio sempre una search advanced per i camps
    this.ongoingRequest = true;

    this.backend.post('camp/search', this.currentQuery, params).subscribe(
      (response) => {

        let res: any = response;

        // faccio il parse delle info del camp
        res.forEach(element => {
          if (element.type)
            element.type_name = this.configService.getCampTypeValueByKey(element.type);

          if (element.start)
            element.start_formatted = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.end)
            element.end_formatted = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        });
        // res.data.forEach(element => {
        //   if (element.type)
        //     element.type_name = this.configService.getCampTypeValueByKey(element.type);

        //   if (element.start)
        //     element.start = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

        //   if (element.end)
        //     element.end = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        // });

        //  setto le info delle pagine
        // this.pageNumber = res.current_page - 1;
        // this.pageSize = res.per_page;
        // this.pageTotalElements = res.total;
        // this.pageTotalPages = res.last_page;
        // this.rows = res.data;
        this.rows = res;

        this.ongoingRequest = false;
      },
      (error) => {

        this.backend.showErrors(error);
        this.ongoingRequest = false;
      }
    );

  }


}
