<div [appCheckPermission]="'person_edit'" *ngIf="!this.personBankAccountList[0]">
  <button type="button" class="btn green btn-block btn-outline" (click)="addNew();"><i class="fa fa-plus"></i>&nbsp; New Payment</button>
</div>

<div [appCheckPermission]="'person_edit'" class="form-actions right">
  <img *ngIf="getPersonService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
  <button type="button" (click)="saveAll();" class="btn blue" [disabled]="getPersonService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.personBankAccountList[0]}">
      <span class="ladda-label"> <i class="fa fa-save"></i> Save All</span>
    </button>
</div>

<div *ngFor="let key of personBankAccountListKeys">
   <app-person-bank-account-form #personBankAccountFormList [model]="this.personBankAccountList[key]" [multiSave]="multiSaveForm" (primaryUpdate)="primaryUpdate($event)" (formDelete)="formDelete($event)"  (formSaved)="formSaved($event)"></app-person-bank-account-form>
</div>

<div [appCheckPermission]="'person_edit'" class="form-actions right">
  <img *ngIf="getPersonService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
  <button type="button" (click)="saveAll();" class="btn blue" [disabled]="getPersonService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.personBankAccountList[0]}">
      <span class="ladda-label"> <i class="fa fa-save"></i> Save All</span>
    </button>
</div>
