<!-- BEGIN CONTENT BODY -->
<div class="page-content">
   <div class="row">
  	<div class="col-md-12">
	  <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span>Tutors Applications</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Tutors Applications Database
        <a class="btn green" target="_blank" href={{tutorApplicationFormUrl}}>Application form</a>
        <small></small>
        <small class="pull-right" *ngIf="lastActive?.title">Last Active Edition: <b>{{lastActive?.title}}</b></small>
      </h1>
      <div class="row">
        <div class="col-md-6">
          <!-- Ricerca avanzata -->
          <div class="row">
            <div class="col-xs-8">
              <div class="form-group">
                <label>Filters</label>
                <div class="mt-checkbox-inline">
                    <label class="mt-checkbox">
                        <input type="checkbox" id="inlineCheckbox1" [(ngModel)]="searchModel.completed"> Completed
                        <span></span>
                    </label>
                    <label class="mt-checkbox">
                        <input type="checkbox" id="inlineCheckbox2" [(ngModel)]="searchModel.not_completed"> Not Completed
                        <span></span>
                    </label>
                    <label class="mt-checkbox">
                        <input type="checkbox" id="inlineCheckbox3" [(ngModel)]="searchModel.rejected"> Rejected
                        <span></span>
                    </label>
                </div>
              </div>
            </div>
            <div class="col-xs-4">
              <div class="form-body">
                <div class="form-group">
                  <label class="form-label">&nbsp;</label>
                  <a class="btn blue form-control btn-outline" (click)="search()">Apply</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-xs-8">
              <div class="form-body">
                <div class="form-group">
                  <label class="form-label">Select edition</label>
                  <select class="form-control" [ngModel]="selectedValue" #editionInput (change)="selectedChanged(editionInput.value)">
                      <option *ngFor="let e of editionArray">{{e}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-xs-4">
              <div class="form-body">
                <div class="form-group">
                  <label class="form-label">&nbsp;</label>
                  <a class="btn blue form-control btn-outline" (click)="pressed()" >Add/Edit Editions</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">
            <!--begin: Portlet-Body -->
            <div class="portlet-body">
            <tabset #tutorTabs class="align-right portlet-title tabbable-line">
                <tab heading="Phase 1"><app-tutors-applications-phase [phase]="1" [searchParams]="searchModel" *ngIf="staticTab?.tabs[0]?.active"></app-tutors-applications-phase></tab>
                <tab heading="Phase 2"><app-tutors-applications-phase [phase]="2" [searchParams]="searchModel" *ngIf="staticTab?.tabs[1]?.active"></app-tutors-applications-phase></tab>
                <tab heading="Proposal"><app-tutors-applications-phase [phase]="3" [searchParams]="searchModel" *ngIf="staticTab?.tabs[2]?.active"></app-tutors-applications-phase></tab>
                <tab heading="Hired"><app-tutors-applications-phase [phase]="4" [searchParams]="searchModel" *ngIf="staticTab?.tabs[3]?.active"></app-tutors-applications-phase></tab>
              </tabset>
            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->

      <!-- BEGIN: MODAL CONTENT ACTORS EDITONS -->
      <div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
        tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Editions Edit</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body form">
              <div class="row">
                <div class="col-md-12">
                  <app-tutors-applications-editions [editionList]="listEditions" (editionUpdated)="editionChanged()"></app-tutors-applications-editions>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: MODAL CONTENT ACTORS EDITONS -->

	  </div>
  </div>
</div>
<!-- END CONTENT BODY -->
