<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

    <div class="form-body">

      <div class="row">
        <div class="col-md-4">
          <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || identification_number.dirty || identification_number.touched) && !identification_number.valid }">
            <label class="control-label">N° Penalty Identification Number</label>
            <input type="text" name="identification_number" [(ngModel)]="model.identification_number" #identification_number="ngModel" class="form-control" placeholder="13170864710">
            <span class="help-block">Numero di Verbale</span>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || locality.dirty || locality.touched) && !locality.valid }">
            <label class="control-label">Locality</label>
            <input type="text" class="form-control" name="locality" [(ngModel)]="model.locality" #locality="ngModel" placeholder="Roma">
            <span class="help-block">&nbsp;</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalPenaltyData(), false)) }">
            <label class="control-label">Date of Penalty </label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_date_penalty="angular-mydatepicker" name="date_penalty" class="form-control"
                  [locale]="getHelper().locale"
                  [options]="getDatePickerOptions()"
                  [disabled]="getVehicleService().isOngoingRequest()"
                  [ngModel]="getLocalPenaltyData()"
                  (dateChanged)="onDatePenaltyChanged($event)"
                />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model?.date_penalty" class="btn default" (click)="dp_date_penalty.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                    <button type="button" class="btn default" (click)="dp_date_penalty.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                </span>
            </div>
            <span class="help-block">Questa data fa riferimento al giorno della multa</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalNotificationData(), false)) }">
            <label class="control-label">Date of Notification</label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_date_notification="angular-mydatepicker" name="date_notification" class="form-control"
                  [locale]="getHelper().locale"
                  [options]="getDatePickerOptions()"
                  [disabled]="getVehicleService().isOngoingRequest()"
                  [ngModel]="getLocalNotificationData()"
                  (dateChanged)="onDateNotificationChanged($event)"
                />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model?.date_notification" class="btn default" (click)="dp_date_notification.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                    <button type="button" class="btn default" (click)="dp_date_notification.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                </span>
            </div>
            <span class="help-block">Questa data fa riferimento al giorno in cui è stata notificata la multa</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || violated_code.dirty || violated_code.touched) && !violated_code.valid }">
            <label class="control-label">Violated code</label>
            <input type="text" name="violated_code" [(ngModel)]="model.violated_code" #violated_code="ngModel" class="form-control" placeholder="Divieto di sosta">
            <span class="help-block">Nota sull'articolo del codice della strada che è stato violato</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Note</label>
            <textarea rows="3" class="form-control" name="annotation" placeholder="Some notes..." [(ngModel)]="model.annotation" #note="ngModel"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || amount.dirty || amount.touched) && !amount.valid }">
            <label class="control-label">€ Cost (es. 105.47)</label>
            <input type="text" name="amount" [(ngModel)]="model.amount" #amount="ngModel" class="form-control" placeholder="es: 153.47" required>
            <span class="help-block">&nbsp;</span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group"></div>
            <div class="mt-checkbox-inline">
              <label class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="paid" [(ngModel)]="model.paid" value="1"> Paid?
                <span></span>
              </label>
            </div>
          </div>
          <div class="col-md-4" *ngIf="model.paid == true">
            <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalPaymentData(), false)) }">
              <label class="control-label">Date of payment </label>
                <div class="input-group">
                  <!-- input box -->
                  <input angular-mydatepicker #dp_date_penalty_payed="angular-mydatepicker" name="date_penalty_payed" class="form-control"
                    [locale]="getHelper().locale"
                    [options]="getDatePickerOptions()"
                    [disabled]="getVehicleService().isOngoingRequest()"
                    [ngModel]="getLocalPaymentData()"
                    (dateChanged)="onDatePaymentChanged($event)"
                  />
                  <!-- clear date button -->
                  <span class="input-group-btn">
                    <button type="button" *ngIf="model?.date_payment" class="btn default" (click)="dp_date_penalty_payed.clearDate()"><i class="fa fa-close"></i></button>
                  </span>
                  <!-- toggle calendar button -->
                  <span class="input-group-btn">
                      <button type="button" class="btn default" (click)="dp_date_penalty_payed.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                  </span>
              </div>
              <span class="help-block">Questa data fa riferimento al giorno in cui la multa è stata pagata</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div [appCheckPermission]="'person_edit'" *ngIf="model.id && !model.penaltyUrl">
                <input *ngIf="!penaltyUploader.isUploading" type="file" id="penaltyUploader"
                  ng2FileSelect [uploader]="penaltyUploader" />
                <label *ngIf="!penaltyUploader.isUploading" for="penaltyUploader"
                  class="btn btn-block label-file green-jungle text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload Penalty!</label>
                <div *ngIf="penaltyUploader.isUploading || getVehicleService().isOngoingRequest()">
                  <img src="./assets/global/img/input-spinner.gif" />
                  please wait...
                </div>
              </div>
              <div *ngIf="model.penaltyUrl != undefined && model.penaltyUrl != ''">
                <a type="button" [href]="model.penaltyUrl" target="_blank" class="btn btn-block btn-outline green text-uppercase label-file" ><i class="fa fa-save"></i>&nbsp; Download Penalty File</a>
                <button *ngIf="!penaltyUploader.isUploading" type="button"
                  class="btn btn-danger btn-block text-uppercase label-file" (click)="destroyPenaltyFile();"><i
                  class="fa fa-trash"></i>&nbsp; Remove Penalty</button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div [appCheckPermission]="'person_edit'" *ngIf="model.id && !model.receiptUrl">
                <input *ngIf="!receiptUploader.isUploading" type="file" id="receiptUploader"
                  ng2FileSelect [uploader]="receiptUploader" />
                <label *ngIf="!receiptUploader.isUploading" for="receiptUploader"
                  class="btn btn-block label-file green-jungle text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload Receipt!</label>
                <div *ngIf="receiptUploader.isUploading || getVehicleService().isOngoingRequest()">
                  <img src="./assets/global/img/input-spinner.gif" />
                  please wait...
                </div>
              </div>
              <div *ngIf="model.receiptUrl != undefined && model.receiptUrl != ''">
                <a type="button" [href]="model.receiptUrl" target="_blank" class="btn btn-block btn-outline green text-uppercase label-file"><i class="fa fa-save"></i>&nbsp; Download Receipt File</a>
                <button *ngIf="!receiptUploader.isUploading" type="button"
                  class="btn btn-danger btn-block text-uppercase label-file" (click)="destroyReceiptFile();"><i
                  class="fa fa-trash"></i>&nbsp; Remove Receipt</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div class="form-actions right">
      <img *ngIf="getVehicleService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

      <ng-template #confirmDelete>
        <button type="button" class="btn btn-outline red" (click)="deletePenalty();">Yes Delete!</button>
      </ng-template>
      <button type="button" class="btn btn-outline red" [disabled]="getVehicleService().isOngoingRequest()" *ngIf="model.id"
        [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
        <i class="fa fa-trash-o"></i> Delete</button>&nbsp;
      <button type="submit" class="btn blue" [disabled]="getVehicleService().isOngoingRequest()" *ngIf="model.id"><i class="fa fa-save"></i>&nbsp;Update</button>
      <button type="submit" class="btn green-jungle" [disabled]="getVehicleService().isOngoingRequest()" *ngIf="!model.id"><i class="fa fa-save"></i>&nbsp;Save</button>
    </div>

  </form>
