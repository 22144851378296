<!-- BEGIN FORM -->
<form [appCheckPermission]="'person_edit'" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 class="form-section">User Account<small style="float:right;">ID: {{ model.id }}</small></h3>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || email.dirty || email.touched) && !email.valid}">
          <label class="control-label">Email <span class="required">*</span></label>
          <!-- Sembra che questo pattern di seguito commentato dava problemi, si bloccava il browser -->
          <!-- <input type="text" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" id="email" name="email" class="form-control" #email="ngModel" required placeholder="email" [(ngModel)]="model.email"> -->
          <input type="text" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$" id="email" name="email" class="form-control" #email="ngModel" required placeholder="email" [(ngModel)]="model.email">
          <span class="help-block">Write the email user for reset the password and for the login, field required</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || username.dirty || username.touched) && !username.valid}">
            <label class="control-label">Username <span class="required">*</span></label>
            <input type="text" id="username" name="name" class="form-control" #username="ngModel" required placeholder="username" [(ngModel)]="model.name">
            <span class="help-block">Write the username used for the login, field required</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Password</label>
          <button *ngIf="passwordFieldHidden" (click)="passwordFieldHidden=false;" class="btn btn-block btn-outline blue"><i class="fa fa-unlock" aria-hidden="true"></i> Change password</button>
          <input *ngIf="!passwordFieldHidden" type="password" id="password" name="password" class="form-control" placeholder="password" [(ngModel)]="model.password">
          <span *ngIf="!passwordFieldHidden" class="help-block">Write the new password and save</span>
        </div>
      </div>
    </div>

     <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Active</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" id="inlineCheckbox1" name="active" value="option1" [(ngModel)]="model.active"> Yes
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-6">

      </div>
    </div>

  </div>


  <div class="form-actions right">
    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getPersonService().isOngoingRequest()" *ngIf="this.model.id"  [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete Login data</button>&nbsp;
    <button type="submit" class="btn blue" [disabled]="getPersonService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>
