import { Component, OnInit } from '@angular/core';
import { CampService, UserService, ConfigService, HelperService } from '../../_services';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { IMyInputFieldChanged } from 'angular-mydatepicker';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-camp-reports-campmembers',
  templateUrl: './camp-reports-campmembers.component.html',
  styleUrls: ['./camp-reports-campmembers.component.css']
})
export class CampReportsCampmembersComponent implements OnInit {

  // generic rows
  rows = [];

  //  model della ricerca
  model: any = { };

  public multipleSelect = [];

  // DATE PICKER
  localDateFrom: IMyDateModel;
  localDateTo: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  datePickerOptionsBegin: IAngularMyDpOptions = {};
  datePickerOptionsEnd: IAngularMyDpOptions = {};

  //  array of dates picker
  datesArray: any[];

  constructor(private campService: CampService, private userService: UserService,
              private configService: ConfigService, private helper: HelperService) {

    this.configureArrayOfDates();

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;
  }

  ngOnInit() {

    this.model = {year: new Date().getFullYear(), status: ["1", "3", "4"]};

    this.createSelector();
  }






  fieldChange(event) {

    // this.searchKeywordsSubmit.emit(this.model);
  }


  submitSearch() {

    //  Elimino le chiavi che non hanno valore prima di fare le ricerche
    Object.keys(this.model).forEach(element => {
      if (!this.model[element])
        delete this.model[element];
    });

    if (this.model.status != null && typeof this.model.status != "undefined")
    {
      this.model.status = [];
      this.multipleSelect.forEach(elementStatus => {
        if (elementStatus.value == true)
          this.model.status.push(elementStatus.key);
      })
    }

    this.campService.getReportCampMembers(this.model, (results) => {

      results.forEach(element => {

        element.primary_address = {};
        element.contact_email = "";
        element.contact_mobile = "";

        if (element.addresses.length > 0)
          element.primary_address = element.addresses[0];

        element.addresses.forEach(address => {

          if (address.primary == true)
            element.primary_address = address;
        });

        if (typeof element.staff_email != "undefined" && element.staff_email != null && element.staff_email.length > 0)
          element.contact_email = element.staff_email;
        else
        if (element.contacts.length > 0)
        {
          element.contacts.forEach(contact => {

            if (this.model.camp_staff_type != 10 &&
                this.model.camp_staff_type != 11)
            {
              if (contact.person_contact_type == 1)
              element.contact_email = contact.person_contact_value;

              if (contact.person_contact_type == 5)
                element.contact_mobile = contact.person_contact_value;
            }
            else
            {
              if (contact.enterprise_contact_type == 1)
              element.contact_email = contact.enterprise_contact_value;

              if (contact.enterprise_contact_type == 5)
                element.contact_mobile = contact.enterprise_contact_value;
            }
          });
        }

      });

      this.rows = results;
    });

  }

  clearSearch() {
    this.model = {year: new Date().getFullYear(), status: ["1", "3", "4"]};
    this.createSelector();
  }




  configureArrayOfDates()
  {
    this.datesArray = [];
    this.datesArray.push((new Date()).getFullYear());
  }


  //  crea il multiselettore in base al model
  public createSelector () {

    this.multipleSelect = [
      {key:"1", value:false}, {key:"2", value:false}, {key:"3", value:false}, {key:"4", value:false}, {key:"5", value:false}, {key:"6", value:false}
    ];

    this.model.status.forEach(elementStatus => {
      (this.multipleSelect.find( x => x.key == elementStatus)).value = true;
    });
  }

  //  selezionato il valore attuale dai filtri della select
  public selectedItem(value:any):void {

    if (typeof this.model.status == "undefined")
      this.model.status = [];

    this.model.status.push(value.id);
  }

  //  rimuovo il valore attuale dai filtri della select
  public removedItem(value:any):void {

    var index = this.model.status.findIndex(x => x == value.id);

    if (index >= 0)
      this.model.status.splice(index, 1);
  }






  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    this.exportParse(this.rows, () => {
      this.hideModalExport();
    });

  }





  /*
* Questo metodo prepara l'esportazione dei dati
* da inviare poi ad un file excel o csv
*/
exportParse (data: any, callback: any) {

  var exportedArray = [];

  //  preparo i dati per il csv bonificandoli
  data.forEach( (element) => {

    //  bonifico
    Object.keys(element).forEach(key => {
      if (element[key] == null) element[key] = '';
    })

    let obj = {};

    if (element.camp_staff_type == 10 ||
        element.camp_staff_type == 11)
    {
      obj = {
        'Camp': "" + element.administrative_area_level_3_long_version + " (" + element.administrative_area_level_2_short_version + ") - " + element.administrative_area_level_1_long_version,
        'Camp Code': element.camp_code,
        'Ruolo':this.configService.getCampStaffTypeValueByKey(element.camp_staff_type),
        'Denomination': element.denomination,
        'Indirizzo Enterprise': element.primary_address.address,
        'Citta Enterprise': element.primary_address.administrative_area_level_3_long_version,
        'Prov Enterprise': element.primary_address.administrative_area_level_2_short_version,
        'Regione Enterprise': element.primary_address.administrative_area_level_1_long_version,
        'Cellulare': element.contact_mobile,
        'Email': element.contact_email
      };
    }
    else
    {
      obj = {
        'Camp': "" + element.administrative_area_level_3_long_version + " (" + element.administrative_area_level_2_short_version + ") - " + element.administrative_area_level_1_long_version,
        'Camp Code': element.camp_code,
        'Ruolo':this.configService.getCampStaffTypeValueByKey(element.camp_staff_type),
        'Cognome':  element.surname,
        'Nome': element.first_name,
        'Indirizzo Persona': element.primary_address.address,
        'Citta Persona': element.primary_address.administrative_area_level_3_long_version,
        'Prov Persona': element.primary_address.administrative_area_level_2_short_version,
        'Regione Persona': element.primary_address.administrative_area_level_1_long_version,
        'Cellulare': element.contact_mobile,
        'Email': element.contact_email
      };
    }

    //  preparo
    exportedArray.push(obj)

  })

  //  creo io worksheet con i dati
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

  //  personalizzo le colonne
  if (this.model.camp_staff_type == 10 ||
    this.model.camp_staff_type == 11)
    worksheet['!cols'] = [{width: 42}, {width:18}, {width:16}, {width:40}, {width:42},{width:24}, {width:10}, {width:12}, {width:16}, {width:32}];
  else
    worksheet['!cols'] = [{width: 42}, {width:18}, {width:16}, {width:16}, {width:16}, {width:42},{width:24}, {width:10}, {width:16}, {width:16}, {width:32}];

  //  personalizzo l'header
  worksheet['!rows'] = [{hpx: 30}];

  //  creo il workbook con lo sheet attuale
  const workbook: XLSX.WorkBook = { Sheets: { 'Camp Staff Contacts': worksheet}, SheetNames: ['Camp Staff Contacts'] };

  //  scrivo il file
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

  //  Salvo il file tramite il browser
  FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "report_contacts_camp_staff.xlsx");

  callback();

}



  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.isModalExport = false;
    // this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }



  //  Operazioni sulla data di start
  onDateFromChanged(event: IMyDateModel)
  {
    this.localDateFrom = event;

    if (event.singleDate.jsDate != null)
    {
      this.model['from_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;

      this.datePickerOptionsEnd = datePickerOpt;
    }
    else
    {
      this.model['from_date'] = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }



  //  Operazioni sulla data di end
  onDateToChanged(event: IMyDateModel)
  {
    this.localDateTo = event;

    if (event.singleDate.jsDate != null)
    {
      this.model['to_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
        let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
        datePickerOpt.disableSince = event.singleDate.date;

        this.datePickerOptionsBegin = datePickerOpt;
    }
    else
    {
      this.model['to_date'] = null;
      this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions)) ;
    }

  }



  getCampService() {
    return this.campService
  }

  getUserService() {
    return this.userService
  }

  getConfigService() {
    return this.configService;
  }

  getHelper(){
    return this.helper;
  }

}
