import { Component, OnInit, Input, Output, ChangeDetectorRef, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PersonService, ConfigService } from '../../_services/index';
import { NotificationsService } from 'angular2-notifications';
declare var google:any;

@Component({
  selector: 'app-person-address-form',
  templateUrl: './person-address-form.component.html',
  styleUrls: ['./person-address-form.component.css']
})
export class PersonAddressFormComponent implements OnInit {

  @Input() model: any = {};
  @Input() multiSave: boolean;

  @Output() primaryUpdate = new EventEmitter();
  @Output() formDelete = new EventEmitter();
  @Output() formSaved = new EventEmitter();

  editMode: boolean = false;

  @ViewChild('f', {static: false}) currentForm: NgForm;

  address: any;
  addressCheck: string;


  constructor(private personService: PersonService, private ref: ChangeDetectorRef,
    private configService: ConfigService, private notify: NotificationsService) { }

  ngOnInit() {
    //  Quando viene inizializzato setto sempre l'id sull'address check per validare il form
    this.addressCheck = this.model.id;

    if (!this.model || !this.model.id){
      this.editMode = true;
    }
  }

  @ViewChild('addressInput', {static: false}) addressInput: ElementRef;
  autocomplete:any;
  trigger:any;
  place:any;

  setupGooglePlace() {

    if (!this.addressInput || !this.addressInput.nativeElement)
      return;

    this.autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement, { type: ['address'] });

    this.trigger = this.autocomplete.addListener('place_changed', () => {
      this.place = this.autocomplete.getPlace();
      this.getAddress(this.place);
    });
  }

  submit() {

    console.log(this.currentForm.form);

    if (!this.currentForm.form.valid)
      return;

    if (!this.currentForm.form.dirty) {
      this.editMode = false;
      return;
    }

    if (!this.personService.checkPrimary(this.model, this.personService.currentPersonAddressList)) {
      this.notify.error("You need to set this or other contact as primary");
      return;
    }

    //  pulisco il campo address
    this.address = "";

    if (this.model.id)
      this.personService.updatePersonAddress(this.model, () => { this.editMode = false; });
    else
      this.personService.savePersonAddress(this.model, () => { this.editMode = false; });

    this.formSaved.emit(this.model);
  }

  destroy() {
    this.personService.deletePersonAddress(this.model);
  }

  delete() {
    this.formDelete.emit(this);
  }

  edit() {
    this.editMode = true;
  }

  getAddress(place: Object) {

    if (place == null || typeof place['formatted_address'] == "undefined")
      return;

    this.address = place['formatted_address'];

    this.resetAddress();

    if (typeof place['address_components'] == "undefined")
      return;

    place['address_components'].forEach(element => {

      switch (element['types'][0]) {
        case "street_number": this.model['street_number'] = element['long_name']; break;
        case "route": this.model['route'] = element['long_name']; break;
        case "locality": this.model['locality'] = element['long_name']; break;
        case "administrative_area_level_3": this.model['administrative_area_level_3_long_version'] = element['long_name']; this.model['administrative_area_level_3_short_version'] = element['short_name']; break;
        case "administrative_area_level_2": this.model['administrative_area_level_2_long_version'] = element['long_name']; this.model['administrative_area_level_2_short_version'] = element['short_name']; break;
        case "administrative_area_level_1": this.model['administrative_area_level_1_long_version'] = element['long_name']; this.model['administrative_area_level_1_short_version'] = element['short_name']; break;
        case "country": this.model['country_long_version'] = element['long_name']; this.model['country_short_version'] = element['short_name']; break;
        case "postal_code": this.model['postal_code'] = element['long_name']; break;
      }
    });

    this.addressCheck = place["place_id"];

    //this.submit();
    this.ref.detectChanges();
  }

  //  E' cambiato il valore di un primary
  valueChange(value) {
    this.primaryUpdate.emit(this.model);
  }

  resetAddress() {
    this.model['street_number'] = null;
    this.model['route'] = null;
    this.model['locality'] = null;
    this.model['administrative_area_level_3_long_version'] = null;
    this.model['administrative_area_level_3_short_version'] = null;
    this.model['administrative_area_level_2_long_version'] = null;
    this.model['administrative_area_level_2_short_version'] = null;
    this.model['administrative_area_level_1_long_version'] = null;
    this.model['administrative_area_level_1_short_version'] = null;
    this.model['country_long_version'] = null;
    this.model['country_short_version'] = null;
    this.model['postal_code'] = null;
  }

  getConfigService() {
    return this.configService
  }

  getPersonService() {
    return this.personService
  }
}
