<!-- BEGIN FORM -->

<form action="#" class="horizontal-form">

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 class="form-section">Family</h3>

    <div class="row">
      <div class="col-md-6">
        <div class="portlet light bordered">
          <div class="portlet-title">
            <div class="caption">
              <span class="caption-subject font-dark bold uppercase">Mother</span>
              <span class="caption-helper"></span>
            </div>
            <div  [appCheckPermission]="'person_edit'" class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" [disabled]="getPersonService().isOngoingRequest()" (click)="addFamilyMember('mother')"><i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDelete01><button type="button" class="btn btn-outline red" (click)="removeFamilyMember('mother')">Yes Remove!</button></ng-template>
                <button *ngIf="this.model.mother_id" type="button" [disabled]="getPersonService().isOngoingRequest()" class="btn btn-circle red btn-outline"  [outsideClick]="true" [popover]="confirmDelete01" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-person-info-show #personInfoShow [idPerson]="this.model.mother_id"></app-person-info-show>
          </div>
        </div>
        </div>
      <div class="col-md-6">
        <div class="portlet light bordered">
          <div class="portlet-title">
            <div class="caption">
              <span class="caption-subject font-dark bold uppercase">Father</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div  [appCheckPermission]="'person_edit'" class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" [disabled]="getPersonService().isOngoingRequest()" (click)="addFamilyMember('father')"><i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDelete02><button type="button" class="btn btn-outline red" (click)="removeFamilyMember('father')">Yes Remove!</button></ng-template>
                <button *ngIf="this.model.father_id" type="button" [disabled]="getPersonService().isOngoingRequest()" class="btn btn-circle red btn-outline"  [outsideClick]="true" [popover]="confirmDelete02" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-person-info-show #personInfoShow [idPerson]="this.model.father_id"></app-person-info-show>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-md-6">
          <div class="portlet light bordered">
            <div class="portlet-title">
              <div class="caption">

                <span class="caption-subject font-dark bold uppercase">Children</span>
                <span class="caption-helper"></span>
              </div>
              <div class="actions">
                <div  [appCheckPermission]="'person_edit'" class="btn-group btn-group-devided" data-toggle="buttons">
                  <button type="button" class="btn btn-circle btn-outline green" [disabled]="getPersonService().isOngoingRequest()" (click)="addFamilyMember('child_01')"><i class="fa fa-edit"></i>&nbsp;Edit</button>
                  <ng-template #confirmDelete03><button type="button" class="btn btn-outline red" (click)="removeFamilyMember('child_01')">Yes Remove!</button></ng-template>
                  <button *ngIf="this.model.child_01_id" type="button" [disabled]="getPersonService().isOngoingRequest()" class="btn btn-circle red btn-outline"  [outsideClick]="true" [popover]="confirmDelete03" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i>&nbsp;Remove</button>
                </div>
              </div>
            </div>
            <div class="portlet-body">
              <app-person-info-show #personInfoShow [idPerson]="this.model.child_01_id"></app-person-info-show>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="portlet light bordered">
            <div class="portlet-title">
              <div class="caption">

                <span class="caption-subject font-dark bold uppercase">Children</span>
                <span class="caption-helper"></span>
              </div>
              <div class="actions">
                <div [appCheckPermission]="'person_edit'" class="btn-group btn-group-devided" data-toggle="buttons">
                  <button type="button" class="btn btn-circle btn-outline green" [disabled]="getPersonService().isOngoingRequest()" (click)="addFamilyMember('child_02')"><i class="fa fa-edit"></i>&nbsp;Edit</button>
                  <ng-template #confirmDelete04><button type="button" class="btn btn-outline red" (click)="removeFamilyMember('child_02')">Yes Remove!</button></ng-template>
                  <button *ngIf="this.model.child_02_id" type="button" [disabled]="getPersonService().isOngoingRequest()" class="btn btn-circle red btn-outline"  [outsideClick]="true" [popover]="confirmDelete04" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i>&nbsp;Remove</button>
                </div>
              </div>
            </div>
            <div class="portlet-body">
              <app-person-info-show #personInfoShow [idPerson]="this.model.child_02_id"></app-person-info-show>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="portlet light bordered">
            <div class="portlet-title">
              <div class="caption">

                <span class="caption-subject font-dark bold uppercase">Children</span>
                <span class="caption-helper"></span>
              </div>
              <div class="actions">
                <div [appCheckPermission]="'person_edit'" class="btn-group btn-group-devided" data-toggle="buttons">
                  <button type="button" class="btn btn-circle btn-outline green" [disabled]="getPersonService().isOngoingRequest()" (click)="addFamilyMember('child_03')"><i class="fa fa-edit"></i>&nbsp;Edit</button>
                  <ng-template #confirmDelete05><button type="button" class="btn btn-outline red" (click)="removeFamilyMember('child_03')">Yes Remove!</button></ng-template>
                  <button *ngIf="this.model.child_03_id" type="button" [disabled]="getPersonService().isOngoingRequest()" class="btn btn-circle red btn-outline"  [outsideClick]="true" [popover]="confirmDelete05" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i>&nbsp;Remove</button>
                </div>
              </div>
            </div>
            <div class="portlet-body">
              <app-person-info-show #personInfoShow [idPerson]="this.model.child_03_id"></app-person-info-show>
            </div>
          </div>
        </div>
      </div>
  </div>
</form>


<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add family member</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-person-search-form #personSearchForm (searchKeywordsSubmit)="searchKeywordsSubmit($event)" [ongoingRequest]="personDatatable.ongoingRequest"></app-person-search-form>
          </div>
        </div>

        <br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-person-datatable #personDatatable [query]="personSearchForm.searchKeyword" (personSelected)="personSelected($event)"></app-person-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
