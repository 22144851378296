<div class="datatable-loader text-center" *ngIf="ongoingRequest">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>

<div *ngIf="!ongoingRequest">
<ngx-datatable
  class="striped"

  [rows]="rowsSubscribers"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [selected]="selected"
  [selectionType]="'single'"
  (activate)="onActivate($event)"
  (select)='onSelect($event)'
  [rowClass]="getRowClass"
>
  <ngx-datatable-column name="Subscriber" [flexGrow]="4" prop="out_holder.figlio_cognome" [sortable]="true" [resizeable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngFor="let prima_nota of row?.prima_nota_rows; let first = first; let last = last">{{prima_nota.out_holder_data?.first_name}} {{prima_nota.out_holder_data?.surname}}<span *ngIf="!last">,&nbsp;</span></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Causal" [flexGrow]="2" prop="causal" [sortable]="true" [resizeable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{getConfigService().getPrimanotaCausalInSelect(row?.causal)}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Amount" [flexGrow]="2" prop="amount" [sortable]="true" [resizeable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      {{ (row?.amount | currency:'EUR':'symbol':'1.2-2':'it') || "€ 0,00" }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Attempt" prop="stripe_sessions_count" [flexGrow]="2" [resizeable]="false">
  </ngx-datatable-column>
  <ngx-datatable-column name="Status" prop="status" [flexGrow]="2" [resizeable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span><i class="fa fa-money" [ngClass]="(row.status == 'success' || row.payed_in_cashflow == true) ? 'payed' : 'waiting'"></i></span>
      <span *ngIf="!row.option_disable_link_in_email"><i class="fa fa-cc-stripe" aria-hidden="true" [ngClass]="(row.status == 'success' || row.payed_in_cashflow == true) ? 'payed' : 'waiting'"></i></span>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
</div>

<hr>

<div *ngIf="!ongoingRequest">
  <ngx-datatable
    class="striped"

    [rows]="rowsSignups"
    [columnMode]="'flex'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [selected]="selected"
    [selectionType]="'single'"
    (activate)="onActivate($event)"
    (select)='onSelect($event)'
    [rowClass]="getRowClass"
  >
    <ngx-datatable-column name="Signup Request" [flexGrow]="4" prop="out_holder.figlio_cognome" [sortable]="true" [resizeable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span *ngFor="let prima_nota of row?.prima_nota_rows; let first = first; let last = last">{{prima_nota.out_holder_data?.figlio_cognome}} {{prima_nota.out_holder_data?.figlio_nome}}<span *ngIf="!last">,&nbsp;</span></span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Causal" [flexGrow]="2" prop="causal" [sortable]="true" [resizeable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{getConfigService().getPrimanotaCausalInSelect(row?.causal)}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Amount" [flexGrow]="2" prop="amount" [sortable]="true" [resizeable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{ (row?.amount | currency:'EUR':'symbol':'1.2-2':'it') || "€ 0,00" }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Attempt" prop="stripe_sessions_count" [flexGrow]="2" [resizeable]="false">
    </ngx-datatable-column>
    <ngx-datatable-column name="Status" prop="status" [flexGrow]="2" [resizeable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <span><i class="fa fa-money" [ngClass]="(row.status == 'success' || row.payed_in_cashflow == true) ? 'payed' : 'waiting'"></i></span>
        <span *ngIf="!row.option_disable_link_in_email"><i class="fa fa-cc-stripe" aria-hidden="true" [ngClass]="(row.status == 'success' || row.payed_in_cashflow == true) ? 'payed' : 'waiting'"></i></span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>


<!-- Modale con una richiesta di pagamento -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Payment Request</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
              <app-payment-request-form [model]="selectedPaymentRequestInfo" [readonly]="true" (paymentRequestDeleted)="paymentRequestDeleted()"></app-payment-request-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
