import { ConfigService } from './../../_services/config.service';
import { UserService, HelperService } from 'app/_services';
import { TheatrinoService } from './../../_services/theatrino.service';
import { Component, OnInit } from '@angular/core';
import { parseISO } from 'date-fns';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-theatrino-reports',
  templateUrl: './theatrino-reports.component.html',
  styleUrls: ['./theatrino-reports.component.css']
})
export class TheatrinoReportsComponent implements OnInit {

  constructor(private theatrinoService: TheatrinoService, private userService: UserService,
              private configService: ConfigService, private helper: HelperService) { }

  // generic rows
  rows = [];

  ngOnInit() {
  }


  createTheatrinoShowsReport()
  {
    this.showModalShows();
  }

  isModalShows: boolean = false;
  showModalShows()
  {
    this.isModalShows = true;

    this.theatrinoService.getReportShows( (results) => {

      results.forEach(element => {

        //  sommo i partecipanti
        element.tour_total_students = (parseInt(element.tour_subscribers) || 0) + (parseInt(element.tour_gratuities) || 0);

        //  setto la data formattata
        element.tour_date_prenotation_formatted = parseISO(element.tour_date_prenotation).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

      });

      this.rows = JSON.parse(JSON.stringify(results));
    })

  }

  hideModalShows()
  {
    this.isModalShows = false;
  }
  onHiddenShows()
  {
    this.isModalShows = false;
    this.rows = [];
  }


  /*  Salvo i dati nel formato Excel
  */
 exportShowToExcel() {

  this.showModalExport();

  this.exportParseShows(this.rows, () => {
    this.hideModalExport();
  });

}



/*
* Questo metodo prepara l'esportazione dei dati
* da inviare poi ad un file excel o csv
*/
exportParseShows (data: any, callback: any) {

  var exportedArray = [];

  //  preparo i dati per il csv bonificandoli
  data.forEach( (element) => {

    //  bonifico
    Object.keys(element).forEach(key => {
      if (element[key] == null) element[key] = '';
    })

    let obj = {
      'Nome Show':element.show_denomination,
      'Tipo Show':  this.configService.getShowTypeValueByKey(element.show_type),
      'Nome Tour':element.theatrino_denomination,
      'Data Tour': parseISO(element.tour_date_prenotation).toLocaleDateString(this.helper.locale),
      'Class level':element.tour_class_level,
      'Iscritti':parseInt(element.tour_subscribers) || 0,
      'Gratuità':parseInt(element.tour_gratuities) || 0,
      'Totale Studenti':parseInt(element.tour_total_students) || 0
    };

    //  preparo
    exportedArray.push(obj)

  })

  //  creo io worksheet con i dati
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

  //  personalizzo le colonne
  worksheet['!cols'] = [{width: 50}, {width:20}, {width:40}, {width:12}, {width:80}, {width:10},{width:10}, {width:14}];

  // //  personalizzo l'header
  worksheet['!rows'] = [{hpx: 30}];

  // //  creo il workbook con lo sheet attuale
  const workbook: XLSX.WorkBook = { Sheets: { 'All Shows': worksheet}, SheetNames: ['All Shows'] };

  // //  scrivo il file
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

  // //  Salvo il file tramite il browser
  FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "allshows.xlsx");

  callback();

}







  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.isModalExport = false;
    // this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }






  getTheatrinoService() {
    return this.theatrinoService
  }

  getUserService() {
    return this.userService
  }

  getConfigService() {
    return this.configService;
  }

}
