import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { PersonService, HelperService, ConfigService } from '../../_services/index';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { PersonComponent } from '../person.component';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-person-info-form',
  templateUrl: './person-info-form.component.html',
  styleUrls: ['./person-info-form.component.css']
})
export class PersonInfoFormComponent implements OnInit {

  model: any = {};

  @Input() personComponent: PersonComponent;

  public localData: IMyDateModel;

  //  file uploaders
  public photoUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png'],
      url: 'serverUrl'
    }
  );
  public signutureUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png'],
      url: 'serverUrlSignature'
    }
  );

  //  Controllo se la nazionalità inserita è appartenente all'europa o meno
  public european: boolean;

  addressValues: any;
  address: any;
  addressCheck: string;

  subscribe: any;


  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(private personService: PersonService, private helper: HelperService,
              private router: Router, private configService: ConfigService, private ref: ChangeDetectorRef) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
    this.datePickerOptions.disableSince = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }
  }

  ngOnInit() {

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.model = this.personService.currentPersonInfo;

      if (this.model == null) this.model = {};
      else
      {
        //  Setto l'url per il caricamento delle foto e gli header
        // this.headers = this.personService.getPersonAvatarFileUlopadHeader();
        this.photoUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
        this.photoUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
          this.imageUploaded(item, response, status);
        };
        this.photoUploader.options.url = this.personService.getPersonAvatarFileUploadURL(this.model);

        this.signutureUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
        this.signutureUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
          this.signatureUploaded(item, response, status);
        };
        this.signutureUploader.options.url = this.personService.getPersonSignatureFileUploadURL(this.model);

        if (this.model.birthday != null)
          this.localData = this.helper.initMyDataInputSingle(this.model.birthday);

        //  Se esiste il path per l'immagine costruisco l'url completo
        if (this.model.file_path)
          this.model.avatarUrl = this.configService.blobBasePath+this.model.file_path;

        if (this.model.signature_path)
          this.model.signaturePath = this.configService.blobBasePath+this.model.signature_path;

        if (this.model.gender){
          this.model.gender = this.model.gender.toLowerCase();
        }
      }

    });

  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }


  //  Calcolo l'età a partire dalla data della nascita dall'helper
  get calculateAge(): string {
    return this.helper.calculateAge(this.model.birthday);
  }

  submit() {

    if (!this.helper.isMyDateValid(this.localData, false)) return;

    if (this.model.id != null)
      this.personService.updatePersonInfo(this.model);
    else
      this.personService.savePersonInfo(this.model, () => {
        this.personComponent.activeAllTab();
        this.personComponent.selectTab(2);
      });
  }

  destroy() {

    this.personService.deletePersonInfo(this.model, ()=> {
      this.router.navigate(["person/home"]);
    });
  }

  onDateChanged(event: IMyDateModel) {

    this.localData = event;

    if (event.singleDate.jsDate != null)
      this.model.birthday = this.helper.formatISODate(event);
    else
      this.model.birthday = null;
  }



  checkSubmitKeyEnter(event){

    if (event.target.name == "birthplace")
      return false;

    return true;
  }

  //  Image Uploader
  public imageUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.model.avatarUrl = this.configService.blobBasePath + response;
        this.model.file_path = response;
      }
      this.personService.personAvatarFileResponse(item, response, status,  this.model.id);
    }
  }

  public signatureUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.model.signaturePath = this.configService.blobBasePath + response;
        this.model.signature_path = response;
      }
      this.personService.personSignatureFileResponse(item, response, status,  this.model.id);
    }
  }


  //  Remove image
  destroyFile() {
    this.personService.deletePersonAvatarFile(this.model);
  }

  //  Remove
  destroySignature() {
    this.personService.deletePersonSignatureFile(this.model);
  }

  getConfigService() {
    return this.configService
  }

  getLocalData() {
    return this.localData
  }

  getHelper() {
    return this.helper
  }

  getPersonService() {
    return this.personService
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

  getAddress(event:any) {}
}
