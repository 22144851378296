import { Component, OnInit, Input, OnChanges, ViewChild, ViewChildren } from '@angular/core';
import { PersonService, BackendService, ConfigService } from "../../_services/index";
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PersonInfoSummaryComponent } from "../person-info-summary/person-info-summary.component";

@Component({
  selector: 'app-person-info-show',
  templateUrl: './person-info-show.component.html',
  styleUrls: ['./person-info-show.component.css']
})
export class PersonInfoShowComponent implements OnInit, OnChanges {

  @Input() idPerson: number;
  personInfo: any;
  currentPersonId: number;

  @Input() hideSummaryCardModal: boolean = false;
  @Input() showAddress: boolean = false;
  @Input() showContact: boolean = false;
  @Input() showWhatsapp: boolean = false;
  @Input() whatsappMessage: string = "";
  @Input() showRole: boolean = false;

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChildren('personInfoSummaryComponent') personShow: PersonInfoSummaryComponent;

  constructor(private personService: PersonService, private backend: BackendService, private router: Router, private configService: ConfigService) { }

  ngOnInit() {

    this.personInfo = {};

    this.getPersonInfo();

  }

  ngOnChanges() {

    this.personInfo = {};

    if (this.idPerson != this.currentPersonId)
      this.getPersonInfo();
  }

  loading: boolean = false;

  getPersonInfo() {

    if (typeof this.idPerson == "undefined" || this.idPerson <= 0) {
      this.personInfo = {};
      this.currentPersonId = null;
      return;
    }

    this.currentPersonId = this.idPerson;

    let params = new HttpParams();
    if (this.showContact)
      params = params.set("contact", this.showContact.toString());

    if (this.showAddress)
      params = params.set("address", this.showAddress.toString());

    if (this.showRole)
      params = params.set("role", this.showRole.toString());

    //  aggiungo alla chiamata anche le persone cancellate
    params = params.set('withdeleted', 'true');

    this.loading = true;

    this.backend.get('person/' + this.currentPersonId, params).subscribe(
      (response) => {

        var res : any = response;

        this.personInfo = res;

        this.loading = false;
      },
      (error) => {
        this.loading = false;

        this.backend.showErrors(error);
      });
  }

  clickPerson() {
    //this.router.navigate(['person/edit/' + this.idPerson]);
    this.showModal();
  }

  // MODAL
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  getConfigService() {
    return this.configService
  }

}
