<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 class="form-section">Accomodation</h3>


    <div class="form-group">
      <label>Features</label>
      <div class="mt-checkbox-inline">
          <label class="mt-checkbox mt-checkbox-outline"> Single rooms
              <input type="checkbox" value="1" name="single_rooms" [(ngModel)]="model.single_rooms">
              <span></span>
          </label>
          <label class="mt-checkbox mt-checkbox-outline"> Double rooms
              <input type="checkbox" value="1" name="double_rooms" [(ngModel)]="model.double_rooms">
              <span></span>
          </label>
          <label class="mt-checkbox mt-checkbox-outline"> Triple rooms
              <input type="checkbox" value="1" name="triple_rooms" [(ngModel)]="model.triple_rooms">
              <span></span>
          </label>
          <label class="mt-checkbox mt-checkbox-outline"> Quadruple rooms
              <input type="checkbox" value="1" name="quadruple_rooms" [(ngModel)]="model.quadruple_rooms">
              <span></span>
          </label>
      </div>
    </div>
    <div class="form-group">
      <label>Facility</label>
      <div class="mt-checkbox-inline">
          <label class="mt-checkbox mt-checkbox-outline"> Breakfast
              <input type="checkbox" value="1" name="breakfast" [(ngModel)]="model.breakfast">
              <span></span>
          </label>
          <label class="mt-checkbox mt-checkbox-outline"> Internet
              <input type="checkbox" value="1" name="internet" [(ngModel)]="model.internet">
              <span></span>
          </label>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Rating <b>{{model.rating}}</b></label>
          <p><rating [(ngModel)]="model.rating" [ngModelOptions]="{standalone: true}" [max]="5" [readonly]="false" style="font-size: 200%;"></rating></p>
          <span class="help-block">Select how many stars has the accomodation</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Parking</label>
          <select class="form-control" name="parking" [(ngModel)]="model.parking">
            <option *ngFor="let p of getConfigService().accomodationParkingSelect" [value]="p.key">{{p.value}}</option>
          </select>
          <span class="help-block">Select type of parking</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" placeholder="Some notes..." name="annotation" [(ngModel)]="model.annotation"></textarea>
        </div>
      </div>
    </div>

  </div>

  <div [appCheckPermission]="'enterprise_edit'" class="form-actions right">
    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="this.model.id"  [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="submit" class="btn blue" [disabled]="getEnterpriseService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>
