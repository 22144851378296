import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ConfigService, VehicleService, HelperService } from 'app/_services';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { NgForm } from '@angular/forms';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-vehicle-expiration-add-form',
  templateUrl: './vehicle-expiration-add-form.component.html',
  styleUrls: ['./vehicle-expiration-add-form.component.css']
})
export class VehicleExpirationAddFormComponent implements OnInit {

  @Input() model: any = {};

  @Output() modelUpdated = new EventEmitter();
  @Output() modelDeleted = new EventEmitter();

  @ViewChild('f', {static: true}) currentForm: NgForm;

  public localData: IMyDateModel;

  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(private vehicleService:VehicleService, private configService: ConfigService,
              private helper: HelperService) {

    //  Inizializzo le info sulle date e resetto il blocco
    this.datePickerOptions = this.helper.datePickerOptions;

  }

  ngOnInit() {

    if (this.model == null)
      this.model = {};

    if (typeof this.model.id != "undefined")
      this.localData = this.helper.initMyDataInputSingle(this.model.expiration_date);
  }


  submit() {

    if (!this.helper.isMyDateValid(this.localData)) return;

    //  controllo che la data sia valida
    if (typeof this.model.id != "undefined")
    {
      this.vehicleService.updateVehicleExpiration(this.model.id, this.model, () => {
        this.modelUpdated.emit(this);
      })
    }
    else
    {
      this.vehicleService.saveVehicleExpiration(this.model, () => {
        this.modelUpdated.emit(this);
      })
    }

  }

  deleteExpirations ()
  {
    this.vehicleService.deleteVehicleExpiration(this.model.id, this.model, () => {
      this.modelDeleted.emit(this);
    })
  }



  //  Date picker
  onDateChanged(event: IMyDateModel)
  {
    this.localData = event;

    if (event.singleDate.jsDate != null)
      this.model.expiration_date = this.helper.formatISODate(event);
    else
      this.model.expiration_date = null;

    this.currentForm.form.markAsDirty();
}







  getLocalData (){
    return this.localData;
  }

  getDatePickerOptions (){
    return this.datePickerOptions;
  }

  getHelper() {
    return this.helper;
  }

  getConfigService() {
    return this.configService;
  }

  getVehicleService() {
    return this.vehicleService;
  }

}
