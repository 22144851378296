<div class="row" *ngIf="!showForm">
  <div class="col-md-12">
    <button type="button" class="btn green-jungle btn-block" (click)="showFormReminder();"><i
        class="fa fa-plus"></i>&nbsp;
      Add reminder</button>
  </div>
</div>

<alert type="warning" *ngIf="loadingData">
  <strong>Loading</strong> ...
</alert>


<!-- BEGIN FORM -->
<form *ngIf="showForm" action="#" class="horizontal-form form" name="form" (ngSubmit)="f.form.valid && submit()"
  #f="ngForm">

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 class="form-section">Reminder</h3>

    <alert type="warning" *ngIf="model.tags_reminder">
      <strong><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Reminder speciale</strong> alcuni campi
      non possono essere modificati<br>
    </alert>

    <div class="row">
      <div *ngIf="holderId" class="col-md-4">
        <div class="form-group">
          <label class="control-label">Reminder for</label>
          <div class="row">
            <div class="col-md-12">
              <h4>{{ holderName }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalData())) }">
          <label class="control-label">Date <span class="required">*</span></label>
          <div class="input-group">
            <!-- input box -->
            <input angular-mydatepicker #dp_start_date_reminder="angular-mydatepicker" name="start_date_reminder"
              class="form-control" [locale]="getHelper().locale"
              [disabled]="getReminderService().isOngoingRequest() || model.tags_reminder"
              [options]="getDatePickerOptions()" [ngModel]="getLocalData()" (dateChanged)="onDateChanged($event)" />
            <!-- clear date button -->
            <span class="input-group-btn">
              <button type="button" *ngIf="model?.start_date_reminder" class="btn default"
                (click)="dp_start_date_reminder.clearDate()"><i class="fa fa-close"></i></button>
            </span>
            <!-- toggle calendar button -->
            <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_start_date_reminder.toggleCalendar()"><i
                  class="fa fa-calendar-o"></i></button>
            </span>
          </div>
          <span class="help-block">Field required</span>
        </div>
      </div>
      <div class="col-md-2" [hidden]="model.all_the_day">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || start_time_reminder.dirty || start_time_reminder.touched) && (!start_time_reminder.valid || !timevalid) && !model.all_the_day }">
          <label class="control-label">Time</label>
          <input type="text" [disabled]="model.all_the_day" name="start_time_reminder"
            [(ngModel)]="model.start_time_reminder" #start_time_reminder="ngModel" class="form-control"
            (input)="onTimeInput($event)" [typeahead]="getConfigService().timeReminderSelect"
            (typeaheadOnSelect)="typeTimeOnSelect($event)" typeaheadOptionField="value" placeholder="08:30"
            autocomplete="off">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>&nbsp;</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="all_the_day" [(ngModel)]="model.all_the_day" value="1"> All day
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || title_reminder.dirty || title_reminder.touched) && !title_reminder.valid }">
          <label class="control-label">Title <span class="required">*</span></label>
          <textarea [readonly]="model.tags_reminder" #title_reminder="ngModel" rows="1" class="form-control"
            placeholder="Title..." name="title_reminder" [(ngModel)]="model.title_reminder" required></textarea>
          <span class="help-block">Field required</span>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" placeholder="Some notes..." name="annotation_reminder"
            [(ngModel)]="model.annotation_reminder"></textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalNotificationData())) }">
          <label class="control-label">Notification date <span class="required">*</span></label>
          <div class="input-group">
            <!-- input box -->
            <input angular-mydatepicker #dp_notification_date_time="angular-mydatepicker" name="notification_date_time"
              class="form-control" [locale]="getHelper().locale" [disabled]="getReminderService().isOngoingRequest()"
              [options]="getDatePickerOptions()" [ngModel]="getLocalNotificationData()"
              (dateChanged)="onNotificationDateChanged($event)" />
            <!-- clear date button -->
            <span class="input-group-btn">
              <button type="button" *ngIf="model?.notification_date_time" class="btn default"
                (click)="dp_notification_date_time.clearDate()"><i class="fa fa-close"></i></button>
            </span>
            <!-- toggle calendar button -->
            <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_notification_date_time.toggleCalendar()"><i
                  class="fa fa-calendar-o"></i></button>
            </span>
          </div>
          <span class="help-block">Field required</span>
        </div>
      </div>
      <div class="col-md-2" [hidden]="true">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || notification_time.dirty || notification_time.touched) && (!notification_time.valid || !notificationtimevalid) && !model.all_the_day }">
          <label class="control-label">Time</label>
          <input type="text" [hidden]="model.all_the_day" [disabled]="model.all_the_day" name="notification_time"
            [(ngModel)]="model.notification_time" #notification_time="ngModel" class="form-control"
            (input)="onNotificationTimeInput($event)" [typeahead]="getConfigService().timeReminderSelect"
            (typeaheadOnSelect)="typeNotificationTimeOnSelect($event)" typeaheadOptionField="value" placeholder="08:30"
            autocomplete="off">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="control-label">Notify also by</label>
          <select class="form-control" name="notification_type" [(ngModel)]="model.notification_type"
            #notificationtype="ngModel">
            <option *ngFor="let c of getConfigService().reminderNotificationTypeSelect" [value]="c.key">{{c.value}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6" *ngIf="model.notification_type && model.notification_type == 1">
        <div class="form-group">
          <label class="control-label">destination</label>
          <input type="email" class="form-control" placeholder="" name="notification_destination"
            [(ngModel)]="model.notification_destination">
          <span *ngIf="model.notification_type == 1" class="help-block">le email vengono inviate la mattina della data
            specificata</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-actions right">
    <button *ngIf="getUserService().checkPermission('prima_nota_revisions') && model.id" type="button"
      class="btn btn-outline green" (click)="showRevision = !showRevision">Revisions</button>
    <button *ngIf="showCloseButton" type="button" class="btn btn-outline green" (click)="closeForm();">&times;
      Close</button>
    <img *ngIf="getReminderService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getReminderService().isOngoingRequest()"
      *ngIf="this.model.id" [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i
        class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button *ngIf="model.id && !completed" class="btn default green-jungle"
      [disabled]="getReminderService().isOngoingRequest()" type="button" (click)="markAsCompleted()"><i
        class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Mark
      Complete</button>
    <button *ngIf="model.id && completed" class="btn default green" [disabled]="getReminderService().isOngoingRequest()"
      type="button" (click)="undoMarkAsCompleted(completed.id)"><i class="fa fa-square-o"
        aria-hidden="true"></i>&nbsp;Undo Mark
      Complete</button>

    <button type="submit" class="btn blue" [disabled]="getReminderService().isOngoingRequest()"
      [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

  <div class="row" *ngIf="getUserService().checkPermission('prima_nota_revisions') && showRevision && model.id">
    <div class="col-md-12">
      <ngx-datatable class="striped" [rows]="rowsRevision" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0"
        [rowHeight]="'auto'">
        <ngx-datatable-column name="Date" [flexGrow]="1" [sortable]="true" [resizeable]="false"
          prop="revision.created_at">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="User" [flexGrow]="1" [sortable]="true" [resizeable]="false" prop="person.name">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <a routerLink="/person/edit/{{row.person.id}}">{{value}}</a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Action" [flexGrow]="1" [sortable]="false" [resizeable]="false" prop="revision.key">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span *ngIf="row.revision.key == 'created_at'"><i class="fa fa-plus-square"></i> Created</span>
            <span *ngIf="row.revision.key == 'deleted_at'"><i class="fa fa-trash-o"></i> Deleted</span>
            <span *ngIf="row.revision.key != 'created_at' && row.revision.key != 'deleted_at'"><i
                class="fa fa-edit"></i>
              Edit</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Log" [flexGrow]="4" [sortable]="false" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            field <b>'{{row.revision.key}}'</b> in {{row.revision.revisionable_type}} (ID:
            {{row.revision.revisionable_id}}) <b>from</b> <i>'{{row.revision.old_value}}'</i> <b>to</b>
            <i>'{{row.revision.new_value}}'</i>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</form>
