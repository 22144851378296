import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ShowService {

	currentShowId;
	currentShowInfo: any = [];

	currentAllShows: any[];

	ongoingRequest: boolean = false;


	private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

	constructor(private backend: BackendService, private notify: NotificationsService) { }

	getsubjectToUpdateObservable(): Observable<boolean> {
		return this.subjectToUpdate.asObservable();
	}

	resetService() {
		this.currentShowId = null;
		this.currentShowInfo = null;
	}

	startRequest() {
		this.ongoingRequest = true;
	}

	finishRequest() {
		this.ongoingRequest = false;
	}

	isOngoingRequest() {
		return this.ongoingRequest;
	}

	getAllShows(callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.get('show/all', params).subscribe(
		(response) => {

			let resJson : any = response;
			this.currentAllShows = resJson;

			this.subjectToUpdate.next(true);

			this.finishRequest();

			if (callbackSuccess != null)
				callbackSuccess();
		},
		(error) => {
			this.backend.showErrors(error);

			this.finishRequest();

			if (callbackError != null)
				callbackError();
		});

	}


	getShowInfoAll(callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.get('show/' + this.currentShowId + '/all', params).subscribe(
			(response) => {

				let resJson : any = response;
				this.currentShowInfo = resJson;
				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			});
	}

	saveShowInfo(model: any[], callbackSuccess: any = null) {

		this.startRequest();

		this.backend.post('show', model).subscribe(
			(response) => {

				let resJson : any = response;
				this.currentShowInfo = resJson;
				this.currentShowId = resJson.id;
				this.subjectToUpdate.next(true);

				this.notify.success("Show Info", "successfully saved");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}

	updateShowInfo(model: any[]) {

		this.startRequest();

		this.backend.put('show/' + this.currentShowId, model).subscribe(
			(response) => {

				this.currentShowInfo = response;
				this.currentShowId = this.currentShowInfo.id;
				this.subjectToUpdate.next(true);

				this.notify.success("Show Info", "successfully updated");

				this.finishRequest();
			},
			(error) => {
				console.log("Errore");
				console.log(error);

				this.finishRequest();
			});
	}

	deleteShowInfo(model: any, callbackSuccess: any = null) {

		this.startRequest();

		this.backend.destroy('show/' + this.currentShowId).subscribe(
			(response) => {

				this.resetService();
				this.subjectToUpdate.next(true);

				this.notify.success("Show Info", "successfully deleted");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}




}
