<!-- BEGIN: SEZIONE DEDICATA AGLI SPETTACOLI -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">
    <h3 class="form-section">Show</h3>



    <div class="row">
      <div class="col-md-2">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || time.dirty || time.touched) && (!time.valid || !timevalid) }">
          <label class="control-label">Start Time <span class="required">*</span></label>
          <input type="text" name="time" [(ngModel)]="model.time" #time="ngModel" class="form-control" (input)="onTimeInput($event)"
            [typeahead]="getConfigService().timeTourSelect" (typeaheadOnSelect)="typeTimeOnSelect($event)" typeaheadOptionField="value"
            placeholder="10:30" required autocomplete="off">
          <span class="help-block"> Time is required</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Start Time</label>
          <p>{{model.time}}</p>
        </div>
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || pause.dirty || pause.touched) && (!pause.valid) }">
          <label class="control-label">Pause</label>
          <input type="text" name="pause" [(ngModel)]="model.pause" #pause="ngModel" class="form-control" placeholder="10 min" digits>
          <span *ngIf="pause.errors?.digits" class="help-block">Only positive</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Pause</label>
          <p>{{model.pause}}</p>
        </div>
      </div>
      <div class="col-md-4">
        <input hidden name="show_id" [(ngModel)]="model.show_id" #show_id="ngModel" required>
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || show_name.dirty || show_name.touched) && !show_id.valid }">
          <label class="control-label">Show type<span class="required">*</span></label>
          <input name="show_name" #show_name="ngModel" [(ngModel)]="asyncSelected" [typeahead]="showsComplex" (typeaheadLoading)="changeTypeaheadLoading($event)"
            (typeaheadNoResults)="changeTypeaheadNoResults($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
            [typeaheadWaitMs]="0" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="8"
            [typeaheadMinLength]="0" [typeaheadOptionsLimit]="this.showsComplex.length"
            typeaheadOptionField="denomination" placeholder="Type name of show" class="form-control" autocomplete="off"
            required>
          <span class="help-block"> Show is required</span>
          <div *ngIf="typeaheadLoading===true">
            <i class="glyphicon glyphicon-refresh ng-hide"></i>
          </div>
          <div *ngIf="typeaheadNoResults===true" class="">
            <i class="glyphicon glyphicon-remove"></i> No Results Found
          </div>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Show type</label>
          <p>{{asyncSelected}}</p>
        </div>

        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || workshop.dirty || workshop.touched) && (!workshop.valid) }">
          <label class="control-label">Workshop duration</label>
          <input type="text" name="workshop" [(ngModel)]="model.workshop" #workshop="ngModel" class="form-control" placeholder="10 min" autocomplete="off" digits>
          <span *ngIf="workshop.errors?.digits" class="help-block">Only positive</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Workshop duration</label>
          <p>{{model.workshop}}</p>
        </div>
      </div>

      <div *ngIf="!editMode" class="row read-only">
        <div class="col-md-6">
          <div class="form-group editMode">
            <label class="control-label">Accountable</label>
            <p>
              <app-person-info-show #personInfoShow [idPerson]="this.model.accountable_id"></app-person-info-show>
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="editMode" class="row">
        <div class="col-md-6">
          <label class="control-label"></label>
          <div class="portlet light bordered">
            <div class="portlet-title">
              <div class="caption">
                <i class="icon-bar-chart font-dark hide"></i>
                <span class="caption-subject font-dark bold uppercase">Accountable <span class="required">*</span></span>
                <span class="caption-helper">is required</span>
              </div>
              <div class="actions">
                <div class="btn-group btn-group-devided" data-toggle="buttons">
                  <button type="button" class="btn btn-circle btn-outline green" [disabled]="getTheatrinoService().isOngoingRequest()" (click)="accountableClicked()"><i class="fa fa-edit"></i>&nbsp;Edit</button>
                </div>
              </div>
            </div>
            <div class="portlet-body">
              <app-person-info-show #personInfoShow [idPerson]="this.model.accountable_id"></app-person-info-show>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-2">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || total_subscribers.dirty || total_subscribers.touched) && (!total_subscribers.valid) }">
          <label class="control-label">Students</label>
          <input type="text" name="total_subscribers" [(ngModel)]="model.total_subscribers" #total_subscribers="ngModel"
            class="form-control" digits>
          <span *ngIf="total_subscribers.errors?.digits" class="help-block">Only positive</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Students</label>
          <p>{{model.total_subscribers}}</p>
        </div>
      </div>
      <div class="col-md-2">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || number_gratuities.dirty || number_gratuities.touched) && (!number_gratuities.valid) }">
          <label class="control-label">Gratuities</label>
          <input type="text" name="number_gratuities" [(ngModel)]="model.number_gratuities" #number_gratuities="ngModel"
            class="form-control" digits>
          <span *ngIf="number_gratuities.errors?.digits" class="help-block">Only positive</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Gratuities</label>
          <p>{{model.number_gratuities}}</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group editMode">
          <label class="control-label">Totals</label>
          <p *ngIf="model.total_subscribers || model.number_gratuities">{{ getSum() }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Class level</label>
          <input type="text" name="class_level" [(ngModel)]="model.class_level" #class_level="ngModel" class="form-control">
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Class level</label>
          <p>{{model.class_level}}</p>
        </div>
      </div>
    </div>

  </div>
  <!-- END: SEZIONE DEDICATA AGLI SPETTACOLI -->


  <div *ngIf="!lockedMode" class="form-actions right">
    <img *ngIf="getTheatrinoService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
    <button *ngIf="model.id && editMode" type="button" class="btn btn-outline red" [disabled]="getTheatrinoService().isOngoingRequest()"
       [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete Show</button>&nbsp;
    <button *ngIf="!model.id && editMode" type="button" class="btn btn-outline red" [disabled]="getTheatrinoService().isOngoingRequest()"
      (click)="destroy()"><i class="fa fa-trash-o"></i> Delete Show</button>&nbsp;
    <button type="button" class="btn btn-outline blue" [disabled]="getTheatrinoService().isOngoingRequest()" *ngIf="this.model.id && !editMode"
      (click)="edit()"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>
    <button *ngIf="editMode" type="submit" class="btn blue" [disabled]="getTheatrinoService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>





<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #modalPerson="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add Accountable</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-person-search-form #personSearchForm (searchKeywordsSubmit)="searchKeywordsSubmit($event)" [ongoingRequest]="personDatatable.ongoingRequest"></app-person-search-form>
          </div>
        </div>

        <br><br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-person-datatable #personDatatable [query]="personSearchForm.searchKeyword" (personSelected)="resultSelected($event)"></app-person-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
