import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CourseScheduleService, PrimanotaService } from '../../_services/index';
import { PrimanotaShowComponent } from '../../primanota/index';

@Component({
  selector: 'app-course-schedule-cashflow',
  templateUrl: './course-schedule-cashflow.component.html',
  styleUrls: ['./course-schedule-cashflow.component.css']
})
export class CourseScheduleCashflowComponent implements OnInit {

  @ViewChild('primaNotaShowComponent', {static: true}) public primaNotaShowComponent: PrimanotaShowComponent;

  subscribe: any;
  subscribePrimaNota: any;
  model: any = {};
  holderName: string;
  rows: any[] = [];

  constructor(private courseScheduleService: CourseScheduleService, private primanotaService: PrimanotaService) { }

  ngOnInit() {

    this.subscribe = this.courseScheduleService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.getCourseScheduleInfo();
    });

    this.subscribePrimaNota = this.primanotaService.getsubjectToUpdatePrimaNotaObservable().subscribe((res: any) => {

      this.rows = this.primanotaService.getTableData(res, this.courseScheduleService.currentCourseScheduleId, "App\\CourseSchedule");

    });

    if (this.courseScheduleService.currentCourseScheduleInfo != null)
      this.getCourseScheduleInfo();

  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();

    if (this.subscribePrimaNota)
      this.subscribePrimaNota.unsubscribe();
  }

  getCourseScheduleInfo() {

    this.model = this.courseScheduleService.currentCourseScheduleInfo;

      if (this.model == null) this.model = {};
      else
      {
        this.holderName = this.courseScheduleService.currentCourseScheduleInfo["title"];
      }

  }

  modelUpdated(event){
    this.primaNotaShowComponent.modelUpdated(event);
  }

  getCourseScheduleService() {
    return this.courseScheduleService
  }

}
