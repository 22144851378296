<div class="row" *ngIf="ongoingRequest">
  <div class="col-md-12">
    <div class="datatable-loader text-center">
      <span></span><span></span><span></span><span></span><span></span>
      <strong class="text-center">Loading</strong>
    </div>
  </div>
</div>

<div class="row" *ngIf="!ongoingRequest && rows && rows.length == 0">
  <div class="col-md-12">
    <accordion>
      <accordion-group #accordionGroup [isOpen]="true">
        <div accordion-heading>
          <i class="fa fa-free-code-camp" aria-hidden="true"></i>&nbsp;No camps available
          <i class="pull-right float-xs-right glyphicon"
            [ngClass]="{'glyphicon-chevron-down': accordionGroup?.isOpen, 'glyphicon-chevron-right': !accordionGroup?.isOpen}"></i>
        </div>

        <h4 class="control-label" style="margin-bottom:0;">
          <i class="fa fa-phone-square" aria-hidden="true"></i> Educo contacts when
          transferring
        </h4>

        <div class="row">
          <div class="col-md-6">
            <p><a href="tel:+393295460198" target="_blank"><i class="fa fa-phone-square" aria-hidden="true"></i>
                Sophia (+39)3295460198</a></p>
          </div>
          <div class="col-md-6">
            <p><a href="tel:+393491160325" target="_blank"><i class="fa fa-phone-square" aria-hidden="true"></i> Kuba
                (+39)3491160325</a></p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button type="button" class="btn btn-block btn-sm btn-outline blue" [disabled]="ongoingRequest"
              (click)="update()"><i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;Reload</button>
          </div>
        </div>
      </accordion-group>
    </accordion>
  </div>
</div>

<div class="row" *ngIf="!ongoingRequest">
  <div class="col-md-12">
    <ng-container *ngFor="let model of rows; first as isFirst">
      <accordion>
        <accordion-group #accordionGroup [isOpen]="isFirst">
          <div accordion-heading>
            <i class="fa fa-free-code-camp" aria-hidden="true"></i>&nbsp;{{model.type_name}}: {{
            model.administrative_area_level_3_long_version}}<br><small>({{model.camp_code}})</small>
            <i class="pull-right float-xs-right glyphicon"
              [ngClass]="{'glyphicon-chevron-down': accordionGroup?.isOpen, 'glyphicon-chevron-right': !accordionGroup?.isOpen}"></i>
          </div>

          <!-- Info Camp -->
          <div class="row">
            <div class="col-xs-6 col-md-3">
              <label class="control-label"><i class="fa fa-calendar" aria-hidden="true"></i> Your weeks</label>
              <p> <span *ngIf="model.camp_staff_member_details.week_01">{{ model.camp_staff_member_details.week_01 |
                  date:'d MMMM, y':'':'it'}}<br></span>
                <span *ngIf="model.camp_staff_member_details.week_02">{{ model.camp_staff_member_details.week_02 |
                  date:'d MMMM, y':'':'it'}}<br></span>
                <span *ngIf="model.camp_staff_member_details.week_03">{{ model.camp_staff_member_details.week_03 |
                  date:'d MMMM, y':'':'it'}}<br></span>
                <span *ngIf="model.camp_staff_member_details.week_04">{{ model.camp_staff_member_details.week_04 |
                  date:'d MMMM, y':'':'it'}}<br></span>
              </p>
            </div>
            <div class="col-xs-6 col-md-3">
              <label class="control-label"><i class="fa fa-calendar" aria-hidden="true"></i> Camp Dates</label>
              <p>from {{ model.start| date:'d MMMM, y':'':'it'}}<br>to {{ model.end | date:'d MMMM, y':'':'it' }}</p>
            </div>
            <div class="col-xs-6 col-md-3">
              <label class="control-label"><i class="fa fa-clock-o" aria-hidden="true"></i> Lesson Plan</label>
              <p *ngIf="model.start_lesson && model.end_lesson"> {{ model.start_lesson }} - {{ model.end_lesson }} </p>
              <p *ngIf="!model.start_lesson || !model.end_lesson">-</p>
            </div>
            <div class="col-xs-6 col-md-3">
              <label class="control-label"><i class="fa fa-map-marker" aria-hidden="true"></i> Venue</label>
              <p>
                {{ model.administrative_area_level_3_long_version}} <br>
                {{ model.administrative_area_level_1_long_version}} ({{
                model.administrative_area_level_2_short_version}})
              </p>
            </div>
          </div>

          <!-- PDF Camp -->
          <div class="row">
            <div class="col-md-12">
              <button type="button" class="btn blue" [disabled]="ongoingRequest"
                (click)="downloadCampPDF(model.camp_id, model.camp_code)"><i class="fa fa-print"
                  aria-hidden="true"></i>&nbsp;PDF with details</button>
            </div>
          </div>

          <!-- Transfer details -->
          <div class="row">
            <div class="col-md-6" style="margin-top:30px;">
              <h4 class="control-label"><i class="fa fa-plane" aria-hidden="true"></i> Departures</h4>
              <p *ngFor="let d of model.camp_staff_member_details.transfer_detail_from">
                Departs from <b class="uppercase">{{d.from_place}}</b>&nbsp;<small>({{d.from_camp_code}})</small> with
                {{
                d.transport }} on <b><i class="fa fa-calendar" aria-hidden="true"></i> {{d.from_date | date:'d MMMM,
                  y':'':'it'}} at {{d.from_time}}</b>, to <span class="uppercase">{{d.to_place}}</span>
                <small>({{d.to_camp_code}}) on {{d.to_date | date:'d MMMM, y':'':'it'}} at {{d.to_time}}</small><br>
                <br>
                <small class="line-breaker"><b>Note:</b> {{d.annotation}}</small><br><br>
                <small class="line-breaker"><i class="fa fa-refresh" aria-hidden="true"></i> <b>Last update:</b>
                  {{d.updated_at | date:'d MMMM, y
                  HH:mm':'':'it'}}</small><br>
                <a class="btn blue" style="margin-top: 10px;" *ngIf="d.file_path"
                  href="{{getConfigService().blobBasePath + d.file_path}}" target="_blank"><i class="fa fa-ticket"
                    aria-hidden="true"></i> Download ticket</a>
              </p>
            </div>
            <div class="col-md-6" style="margin-top:30px;">
              <h4 class="control-label"><i class="fa fa-plane" style="transform: scaleY(-1);" aria-hidden="true"></i>
                Arrivals</h4>
              <p *ngFor="let d of model.camp_staff_member_details.transfer_detail_to">
                Arrives in <b class="uppercase">{{d.to_place}}</b>&nbsp;<small>({{d.to_camp_code}})</small> with {{
                d.transport }} on <b><i class="fa fa-calendar" aria-hidden="true"></i> {{d.to_date | date:'d MMMM,
                  y':'':'it'}} at {{d.to_time}}</b>, from <span class="uppercase">{{d.from_place}}</span>
                <small>({{d.from_camp_code}}) on {{d.from_date | date:'d MMMM, y':'':'it'}} at
                  {{d.from_time}}</small><br>
                <br>
                <small class="line-breaker"><b>Note:</b> {{d.annotation}}</small><br><br>
                <small class="line-breaker"><i class="fa fa-refresh" aria-hidden="true"></i> <b>Last update:</b>
                  {{d.updated_at | date:'d MMMM, y
                  HH:mm':'':'it'}}</small><br>
                <a class="btn blue" style="margin-top: 10px;" *ngIf="d.file_path"
                  href="{{getConfigService().blobBasePath + d.file_path}}" target="_blank"><i class="fa fa-ticket"
                    aria-hidden="true"></i> Download ticket</a>
              </p>
            </div>
          </div>

          <h4 class="control-label">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> If you are late please contact your Camp
            Director
          </h4>

          <p *ngFor="let director of model.camp_directors">
            {{director.person_info.surname}} {{director.person_info.first_name}}<br>
            <ng-container *ngFor="let contact of director.person_info.contact">
              <p *ngIf="contact.type == 5" href="tel:{{contact.value}}" target="_blank"><i class="fa fa-phone-square"
                  aria-hidden="true"></i> {{contact.value}}</p>
            </ng-container>
          </p>


          <h4 class="control-label" style="margin-bottom:0;">
            <i class="fa fa-phone-square" aria-hidden="true"></i> Educo contacts when
            transferring
          </h4>

          <div class="row">
            <div class="col-md-6">
              <p><a href="tel:+393295460198" target="_blank"><i class="fa fa-phone-square" aria-hidden="true"></i>
                  Sophia (+39)3295460198</a></p>
            </div>
            <div class="col-md-6">
              <p><a href="tel:+393491160325" target="_blank"><i class="fa fa-phone-square" aria-hidden="true"></i> Kuba
                  (+39)3491160325</a></p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <button type="button" class="btn btn-block btn-sm btn-outline blue" [disabled]="ongoingRequest"
                (click)="update()"><i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;Reload</button>
            </div>
          </div>

        </accordion-group>
      </accordion>
    </ng-container>
  </div>
</div>
