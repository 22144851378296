<h3>History from Gaston</h3>
<button class="btn btn-primary btn-block" [disabled]="loading" (click)="getHistory()">Get History</button>


<span *ngIf="!loading && gaston_history?.theatrino_tour_dates?.length > 0">
  <h4>Theatrino Booking</h4>
  <ngx-datatable *ngIf="!loading" class="striped" [rows]="gaston_history.theatrino_tour_dates" [columnMode]="'flex'"
    [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
    <ngx-datatable-column name="Role" [flexGrow]="3" prop="role" [resizeable]="false" [draggable]="false" [sortable]="false"></ngx-datatable-column>
    <ngx-datatable-column name="Booking" [flexGrow]="4" prop="date" [resizeable]="false" [draggable]="false" [sortable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{row.date_formatted}} <br> Status: {{getConfigService().getTheatrinoStatusValueByKey(row.status)}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Theatrino" [flexGrow]="6" prop="theatrino_denomination" [resizeable]="false"
      [draggable]="false" [sortable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <span><a target="_blank" routerLink="/theatrino/edit/{{row.theatrino_id}}">{{value}}</a></span>
        <br>
        <span>{{row.start_formatted}} - {{row.end_formatted}}</span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</span>

<span *ngIf="!loading && gaston_history?.camps?.length > 0">
  <h4>Camps</h4>
  <ngx-datatable *ngIf="!loading" class="striped" [rows]="gaston_history.camps" [columnMode]="'flex'"
    [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
    <ngx-datatable-column name="Camp" [flexGrow]="3" prop="type" [resizeable]="false" [draggable]="false" [sortable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <a target="_blank" routerLink="/camp/edit/{{row.camp_id}}">{{getConfigService().getCampTypeValueByKey(row.type)}} <br> {{row.camp_code}}</a>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Data" [flexGrow]="3" prop="start_formatted" [resizeable]="false" [draggable]="false"
      [sortable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{row.start_formatted}} <br> {{row.end_formatted}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Status" [flexGrow]="3" prop="status" [resizeable]="false" [draggable]="false"
      [sortable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{getConfigService().getCampStatusValueByKey(row.status)}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Place" [flexGrow]="5" prop="denomination" [resizeable]="false" [draggable]="false"
      [sortable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{getConfigService().getEnterpriseTypeValueByKey(row.enterprise_type)}}
        <span *ngIf="row.enterprise_type_sub"> /
          {{getConfigService().getEnterpriseTypeSubSelectValueByKey(row.enterprise_type, row.enterprise_type_sub)}}</span>
        <br> {{row.denomination}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Address" [flexGrow]="5" prop="administrative_area_level_3_long_version" [resizeable]="false"
      [draggable]="false" [sortable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="row['administrative_area_level_3_long_version'] == null" tooltip="The address is not googlized or not primary address"
          placement="auto"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{{value}}</span>
        <span *ngIf="row['administrative_area_level_3_long_version'] != null">
          {{row.administrative_area_level_3_long_version}} ({{row.administrative_area_level_2_short_version}}) -
          {{row.administrative_area_level_1_long_version}}
        </span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</span>
<br>

<!-- <h3>History from Gabriel</h3>
<span [innerHTML]="history_html"></span> -->
