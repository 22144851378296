import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { differenceInYears } from 'date-fns';
import { CourseScheduleService, PrimanotaService } from 'app/_services';
import { ConfigService, HelperService, UserService } from './../../_services/index';
import { parseISO, format } from 'date-fns';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { NotificationsService } from 'angular2-notifications';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-course-schedule-subscriber-list',
  templateUrl: './course-schedule-subscriber-list.component.html',
  styleUrls: ['./course-schedule-subscriber-list.component.css']
})
export class CourseScheduleSubscriberListComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('autoShownNewModal', {static: false}) public autoShownNewModal: ModalDirective;
  @ViewChild('modalShowPaymentRequest', {static: false}) public autoShowModalRequestPayment: ModalDirective;
  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;
  @ViewChild('autoShownModalCopy', {static: false}) public autoShownModalCopy: ModalDirective;
  @ViewChild('confirmModal', {static: false}) public confirmModal: ModalDirective;

  rows: any[] = [];
  rowsNotActive: any[] = [];
  selected = [];
  ongoingRequest: boolean = false;
  selectedSignupInfo = null;
  selectedSubscribers: any[] = null;

  selectionType:string = 'single';
  multiSelection:boolean = false;

  subscribe: any;
  viewSubscriber: boolean = false;

  modelFiltering: any = {nome: '', gender: '', week: '', school: '', tshirt: ''};

  constructor(private courseSchedule: CourseScheduleService, private configService: ConfigService,
  private helper: HelperService, private userService: UserService, private notify: NotificationsService) { }

  ngOnInit() {

    this.subscribe = this.courseSchedule.getsubjectToUpdateObservable().subscribe((updated: boolean) => {
      this.getSubscribersInfo();
    });

    if (this.courseSchedule.currentCourseSubscriberList != null && this.courseSchedule.currentCourseScheduleInfo != null)
      this.getSubscribersInfo();
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  getSubscribersInfo () {
    if (this.courseSchedule.currentCourseSubscriberList != null && this.courseSchedule.currentCourseScheduleInfo != null) {
      this.parseTable(JSON.parse(JSON.stringify(this.courseSchedule.currentCourseSubscriberList)));
    }
  }



  addNew() {
    this.viewSubscriber = false;
    this.showNewModal();
  }

  formNewSubscriberDelete() {
    this.hideModal();
  }

  formNewSubscriberSaved(event) {
    this.hideNewModal();
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  onSelect({ selected }) {

    if (!this.userService.checkPermission("course_schedule_edit"))
      return;

    if (this.multiSelection)
    {
      this.selected.splice(0, this.selected.length);
      this.selected.push(...selected);
      return;
    }

    if (typeof selected == "undefined") return;
    if (selected.length == 0) return;
    if (selected.length > 1) return;

    this.viewSubscriber = true;
    this.selectedSignupInfo = JSON.parse(JSON.stringify(selected[0]));


    this.showModal();
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  filteringChange (event) {

    var tempArray = this.courseSchedule.currentCourseSubscriberList;

    //  filtro in base al nome
    if (this.modelFiltering.nome.length > 0) {
      tempArray = tempArray.filter(
        x => (
          (x.person_info.surname.toLowerCase()+x.person_info.first_name.toLowerCase() )
          .indexOf(this.modelFiltering.nome.toLowerCase()) !== -1 || !this.modelFiltering.nome) );
    }

    // filtro in base al genere
    if (this.modelFiltering.gender.length > 0) {
      tempArray = tempArray.filter(
        x => x.person_info.gender.toLowerCase().indexOf(this.modelFiltering.gender.toLowerCase()) !== -1 || !this.modelFiltering.gender);
    }

    //  filtro in base alle taglie delle magliette
    // if (this.modelFiltering.tshirt.length > 0) {
    //   tempArray = tempArray.filter(
    //     x => x.person_info.tshirt_size.toLowerCase().indexOf(this.modelFiltering.tshirt.toLowerCase()) !== -1 || this.modelFiltering.tshirt);
    // }

    //  filtro in base alle settimane
    if (this.modelFiltering.week.length > 0) {
      var checkString = this.modelFiltering.week.replace(/ /g, "").split(',');

      checkString.forEach(element => {
        tempArray = tempArray.filter(
          x => x.camp_week.indexOf(parseInt(element)) !== -1);
      });
    }

    //  filtro in base al tipo della scuola
    if (this.modelFiltering.school.length > 0) {

      tempArray = tempArray.filter (
        x => this.configService.getDiaryCampSubscriberSchoolTypeValueByKey(x.school_type).toLowerCase().indexOf(this.modelFiltering.school.split(',')[0].toLowerCase()) !== -1);

      if (this.modelFiltering.school.split(',').length > 1) {
        var splitted_class = this.modelFiltering.school.split(',')[1];

        if (splitted_class.length > 0) {
          tempArray = tempArray.filter (
            x => x.class_type == parseInt(splitted_class));
        }
      }
    }

    // aggiorno la tabella
    this.parseTable(JSON.parse(JSON.stringify(tempArray)));
  }

  parseTable(list){

    let rowActive = [];
    let rowTrashed = [];
    let quote = JSON.parse(JSON.stringify(this.courseSchedule.currentCourseScheduleInfo));

    //  parte dedicata agli sconti che sono da fare

    for (let key in list){

      let active_discount = [];
      let r = list[key];

      if (r.quote_extra_1){
        active_discount.push({label: "Pernottamento (+)", value: quote.quote_extra_1});
      }

      if (r.quote_extra_custom != 0){
        active_discount.push({label: "Personalized extra fee (+)", value: r.quote_extra_custom});
      }

      if (r.quote_discount_1){
        active_discount.push({label: "Teatrino e Camps", value: quote.quote_discount_1});
      }

      if (r.quote_discount_2){
        active_discount.push({label: "Gruppi", value: (quote.quote_discount_2)});
      }

      if (r.quote_discount_early_bird){
        active_discount.push({label: "Early bird discount", value: quote.quote_discount_early_bird});
      }

      if (r.quote_discount_custom != 0){
        active_discount.push({label: "Personalized discount", value: r.quote_discount_custom});
      }

      if (r.quote_discount_free == true){
        active_discount.push({label: "FREE", value: null});
      }

      r['active_discount'] = active_discount;
      r['total_fee_amount'] = quote["quote_course_fee"];

      // row.course_fee_total - row.course_fee_payed
      // row.course_fee_payed && (row.course_fee_payed - row.enrollment_fee_amount) > row.course_fee_total
      if (r.course_fee_payed){
        // se la quota versata è maggiore del totale segnalo il rimborso necessario
        if (r.quote_discount_free == false && r.course_fee_payed > r.course_fee_total){
          r.course_fee_overfee = r.course_fee_total - r.course_fee_payed;
        }
        else if (r.quote_discount_free == true && r.course_fee_payed - r.enrollment_fee_amount > r.course_fee_total){
          r.course_fee_overfee = r.course_fee_total - r.enrollment_fee_amount - r.course_fee_payed;
        }
      }

      r.remaning_to_pay = r.course_fee_total - r.course_fee_payed;

      r.link_certificato = this.courseSchedule.getLinkCertificato(r.id);

      if (r.deleted_at != null){
        rowTrashed.push(r);
      }
      else {
        rowActive.push(r);
      }
    }

    this.rows = rowActive;
    this.rowsNotActive = rowTrashed;

  }




  getRowClass (row) {

    if (row.person_info){
      return { 'camp-staff-deleted' : row.person_info.deleted_at !== null};
    }

    return "";
  }

  changeMultiSelection(multi: boolean)
  {
    if (multi)
    {
      this.multiSelection = true;
      this.selectionType = 'checkbox';
      this.selectedSubscribers = null;
      this.selected = [];
    }
    else
    {
      this.multiSelection = false;
      this.selectionType = 'single';
      this.selectedSubscribers = null;
      this.selected = [];
    }
  }


  inviaMailTo(event: any, email, id_subscriber){

    event.target.disabled = true;

    this.courseSchedule.sendEmailCertificatoTo(id_subscriber, email, () => {
      event.target.innerHTML = '<i class="fa fa-check-square-o aria-hidden="true"></i>&nbsp;Email inviata correttamente';
      this.notify.success("Email", "The email has been sent correctly");
    }, () => {
      event.target.innerHTML = '<i class="fa fa-exclamation-triangle aria-hidden="true"></i>&nbsp;Errore'
    });
  }



  //  MODAL SUBSCRIBER
  isModalShown: boolean = false;
  showModal(): void {
    this.isModalShown = true;
  }
  hideModal(): void {
    this.autoShownModal.hide();
  }
  onHidden(): void {
    this.isModalShown = false;
    this.selectedSignupInfo = null;
  }

  //  MODAL SUBSCRIBER NEW
  isNewModalShown: boolean = false;
  showNewModal (): void {
    this.isNewModalShown = true;
    this.autoShownNewModal.show();
  }
  hideNewModal(): void {
    this.autoShownNewModal.hide();
  }
  onHiddenNew(): void {
    this.isNewModalShown = false;
  }


  currentPaymentRequest: any = null;

  //  MODAL REQUEST PAYMENT
  isModalShownPaymentRequest: boolean = false;
  showModalRequestPayment(modelPrecompiled: any = null) : void {
    this.isModalShownPaymentRequest = true;

    setTimeout( () => {

      if (modelPrecompiled)
        this.currentPaymentRequest = JSON.parse(JSON.stringify(modelPrecompiled));

        this.autoShowModalRequestPayment.show();
     }, 100 );

  }
  hideModalRequestPayment() : void {
    this.autoShowModalRequestPayment.hide();
  }
  onHiddenRequestPayment() : void {
    this.isModalShownPaymentRequest = false;
    this.currentPaymentRequest = null;
  }


  paymentRequestSucceeded ()
  {
    if (this.isModalShownPaymentRequest && this.autoShowModalRequestPayment != null)
      this.autoShowModalRequestPayment.hide();
  }

  paymentRequestError (message)
  {
    this.notify.error("Payment Request", "The following data are missing: " + message.toString());

    if (this.isModalShownPaymentRequest && this.autoShowModalRequestPayment != null)
      this.autoShowModalRequestPayment.hide();
  }


  //  MODAL COPY
  //  modale per la copia degli iscritti
  isModalCopyShown: boolean = false;
  showModalCopy(): void {
    this.isModalCopyShown = true;
  }

  hideModalCopy(): void {
    this.autoShownModalCopy.hide();
  }

  onHiddenCopy(): void {
    this.isModalCopyShown = false;
    this.changeMultiSelection(false);
  }




  //  copia dei subscribers selezionati
  copySubscribersForm (event) {

    if (Array.isArray(event))
    {
      this.selectedSubscribers = event;
      this.showModalCopy();
    }
  }

  //  aggiorno i subscriber
  formSubscriberSaved() {
    this.hideModal();
  }


  deleteSubscribersForm (event) {

    if (Array.isArray(event))
    {
      this.selectedSubscribers = event;
      this.confirmModal.show();
      return;
    }
  }

  confirmDelete () {

    let deleteModel: any = {};
    deleteModel.subscribers = this.selectedSubscribers;
    deleteModel.course_schedule_id = this.courseSchedule.currentCourseScheduleId;

    if (this.checkSubscribersInGroup)
    {
      this.notify.error("Delete Subscribers", "Some subscribers are present in room groups");
      this.confirmModal.hide();
      return;
    }

    this.courseSchedule.deleteMassiveSubscriber(deleteModel, () => {

      //  ricarico i subscribers per questo corso
      this.changeMultiSelection(false);

      this.courseSchedule.getCourseSubscriber();
    })

    this.confirmModal.hide();
  }


  get checkSubscribersInGroup ()
  {
    var found = false;

    if (this.selectedSubscribers!= null)
      this.selectedSubscribers.forEach((subscriber: any) => {
        if (typeof subscriber.rooms != "undefined")
          if (subscriber.rooms.length > 0)
            return found = true;
      });

    return found;
  }


  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }


  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    this.courseSchedule.exportSubscribers(null, (res) => {
      this.exportParse(res, () => {
        this.hideModalExport();
      });
    }, () => {
      this.hideModalExport();
    });

  }



  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse (data: any, callback: any) {

    var exportedArray = [];
    var exportedContactsArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach( (element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      //   se sono presenti i contatti allora li preparo
      if (element.contact_info){

        var contacts = [];
        Object.keys(element.contact_info).forEach(key => {
          contacts.push(element.contact_info[key]);
        });
        element.contact_info = contacts;

        //  rendo univoci tutti i tipi di contatti
        let uniqueContacts = this.removeContactsDuplicated (element.contact_info);

        //  separo le tipologie di contatto e le aggiungo all'array da esportare
        let objContact = {Email: ''};
        uniqueContacts.forEach( (el_contact) => {

          if (el_contact.type == 1) { // email
            objContact['Email'] = el_contact.value;
          }
        });

        exportedContactsArray.push(objContact);
      }

      // seleziono se presente un Home Address (type 1)
      let home_address = {
        route: "",
        street_number: "",
        postal_code: "",
        locality: "",
        administrative_area_level_2_short_version: "",
        administrative_area_level_3_long_version: ""
      }

      if (typeof element.person_info.address != "undefined"){
        for (let k in element.person_info.address){
          let add = element.person_info.address[k];
          if (add.type == 1){
            home_address = add;
            break;
          }
        }
      }

      let obj = {
        'Data di registrazione': parseISO(element.registration_date).toLocaleDateString(this.helper.locale),
        'Nome': element.person_info.first_name,
        'Cognome': element.person_info.surname,
        'Eta': this.helper.calculateAge(element.person_info.birthday),
        'Data di nascita': parseISO(element.person_info.birthday).toLocaleDateString(this.helper.locale),
        'Luogo di nascita': element.person_info.birthplace,
        'Sesso': this.configService.getGenderPersonValueByKey(element.person_info.gender),
        'CF': element.person_info.tax_id,
        'Indirizzo': home_address.route + " " + (home_address.street_number ? home_address.street_number : ""),
        'CAP': home_address.postal_code,
        'Località': home_address.locality + " (" + home_address.administrative_area_level_3_long_version + ")",
        'Provincia': home_address.administrative_area_level_2_short_version,
        'Lavoro': this.configService.getPersonWorkValueByKey(element.person_info.work_type),
        'Insegnante di': this.configService.getpersonWorkSchoolTypeValueByKey(element.person_info.class_type),
        'Scuola': element.school_name,
        'Provincia Scuola': element.school_prov,
        // 'Cash Flow': this.calculateCashFlow(element),
        // 'Quota versata': element.course_fee_payed,
        // 'Quota mancante': (element.course_fee_total - element.course_fee_payed),
        // 'Quota totale': element.course_fee_total,
      };

      if (typeof element.contact_info != "undefined" && element.contact_info != null && Object.keys(element.contact_info).length > 0)
      {
        var mobileToAdd = "";
        var emailToAdd = "";

        element.contact_info.forEach(contact => {

          //  controllo solo se è un contatto cellullare
          if (contact.type == 5)
          {
            //  aggiungo un numero se ancora non è stato fatto
            if (mobileToAdd.length == 0)
              mobileToAdd = contact.value;

            //  se ho già aggiunto un numero controllo che sia quello primary
            if (contact.primary == true)
              mobileToAdd = contact.value;
          }

          //  controllo solo se è un contatto email
          if (contact.type == 1)
          {
            //  aggiungo una mail se ancora non è stato fatto
            if (emailToAdd.length == 0)
              emailToAdd = contact.value;

            //  se ho già aggiunto un numero controllo che sia quello primary
            if (contact.primary == true)
              emailToAdd = contact.value;
          }

        });
      }

      //  aggiungo il numero di telefono al foglio
      obj['Mobile'] = mobileToAdd;

      //  aggiungo la mail al foglio
      obj['Email'] = emailToAdd;

      //  aggiungo le mote
      obj['Note'] = element.annotation;

      //  preparo
      exportedArray.push(obj)

    })

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{width: 18}, {width:20}, {width:30}, {width:8}, {width:14}, {width:30}, {width:10}, {width:30}, {width:30}, {width:8}, {width:30}, {width:12},
      {width:14}, {width:20}, {width:28}, {width:12}, {width:12}, {width:30}, {width:80}];

    // //  personalizzo l'header
    worksheet['!rows'] = [{hpx: 30}];

    const worksheetEmail: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedContactsArray);
    worksheetEmail['!cols'] = [{width: 50}];
    worksheetEmail['!rows'] = [{hpx: 30}];

    // //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'Subscribers': worksheet, 'Emails': worksheetEmail}, SheetNames: ['Subscribers', 'Emails'] };

    // //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

    // //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "subscribers.xlsx");

    callback();

  }



  //  rimuove i duplicati dalla lista dei contatti
  removeContactsDuplicated (contacts: any) {

    let uniqueContacts = [];
    for ( var i = 0; i < contacts.length; i++ )
    {
      let found = false;
      for (var j = 0; j < uniqueContacts.length; j++)
      {
        if (uniqueContacts[j].value == contacts[i].value)
          found = true;
      }

      if (found == false)
        uniqueContacts.push(contacts[i]);
    }

    return uniqueContacts
  }



  calculateCashFlow(subscriber) {

    var value = "";
    if (subscriber.course_fee_payed && subscriber.course_fee_total > 0)
      value = subscriber.course_fee_payed+'/'+subscriber.course_fee_total;

    if (!subscriber.course_fee_payed && subscriber.course_fee_total > 0)
      value = '0/'+subscriber.course_fee_total;

    if (subscriber.course_fee_total == 0)
      value = 'FREE';

    return value;
  }


  getCourseScheduleService(): CourseScheduleService {
    return this.courseSchedule
  }

  getUserService() {
    return this.userService
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

}
