import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CourseScheduleService, ConfigService, PaymentsService } from 'app/_services';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-payment-request-form',
  templateUrl: './payment-request-form.component.html',
  styleUrls: ['./payment-request-form.component.css']
})
export class PaymentRequestFormComponent implements OnInit {

  @Input() model: any = {};
  @Input() readonly: boolean = false;

  @Output() paymentRequestSucceeded = new EventEmitter();
  @Output() paymentRequestError = new EventEmitter();
  @Output() paymentRequestDeleted = new EventEmitter();

  casualSelect: any = [];
  errorMessages: any = [];

  payed: boolean = false;

  constructor(private courseScheduleService: CourseScheduleService, private configService: ConfigService,
    private paymentService: PaymentsService, private notify: NotificationsService) { }

  ngOnInit() {
  }

  ngOnChanges() {

    this.init();
  }

  init() {
    if (this.model != null) {

      //  setto il default a true se non viene passato
      if (this.model.primanota_auto_gen == null || typeof this.model.primanota_auto_gen == "undefined")
        this.model.primanota_auto_gen = true;

      if (!this.validateInputModel) {
        this.model = {};
        this.paymentRequestError.emit(this.errorMessages);
        return;
      }

      // Abilito l'opzione "Disabilita link Stripe" per la causali Course Fee
      if (this.model.causal == 6) {
        this.model.option_disable_link_in_email = true;
      }

      //  controllo se devo bloccare i pulsanti perchè è già avvenuto un pagamento
      this.payed = this.model.payed_in_cashflow || this.model.status == 'success';

      this.casualSelect = this.configService.getPrimanotaCausalSelect("in", this.model.in_holder_type);
    }
    else {
      this.model = {};
      this.payed = false;
    }
  }



  get validateInputModel() {
    this.errorMessages = [];

    if (!this.model.item_id) {
      this.errorMessages.push("item_id");
      return false
    }
    if (!this.model.item_type) {
      this.errorMessages.push("item_type");
      return false
    }
    if (!this.model.item_quantity) {
      this.errorMessages.push("item_quantity");
      return false
    }
    if (!this.model.item_name) {
      this.errorMessages.push("item_name");
      return false
    }
    // if (!this.model.item_image)
    // {
    // this.errorMessages.push("item_image");
    //   return false
    // }
    if (!this.model.item_currency) {
      this.errorMessages.push("currency");
      return false;
    }
    // if (!this.model.item_description)
    // {
    //   this.errorMessages.push("item_description");
    //   return false;
    // }
    //  data della prima nota
    // if (!this.model.date_payment)
    // {
    //   this.errorMessages.push("date_payment");
    //   return false
    // }
    //  id dell'utente che genera la richiesta
    // if (!this.model.registered_by_user_id)
    // {
    //   this.errorMessages.push("registered_by_user_id");
    //   return false
    // }
    //  valore predefinito per il pagamento con stripe
    if (!this.model.registered_via) {
      this.errorMessages.push("registered_via");
      return false
    }
    if (!this.model.in_holder_type) {
      this.errorMessages.push("in_holder_type");
      return false
    }
    if (!this.model.in_holder_id) {
      this.errorMessages.push("in_holder_id");
      return false
    }
    if (!this.model.out_holder_type) {
      this.errorMessages.push("out_holder_type");
      return false
    }
    if (!this.model.out_holder_id) {
      this.errorMessages.push("out_holder_id");
      return false
    }

    return true;
  }


  onInputAmount(event: any) {

  }


  //   Invia una richiesta di pagamento
  sendRequest() {

    if (this.model.amount == 0) {
      this.notify.error("Amount", "Must be greater than 0");
      return;
    }

    //  faccio il parse dell'amount
    this.model.amount = parseFloat(this.model.amount).toFixed(2);

    //  faccio il parse dall'amount al value per stripe che è *100
    this.model.item_amount = this.model.amount * 100;

    this.paymentService.savePaymentRequest(this.model, () => {
      this.paymentRequestSucceeded.emit();
    })

  }

  onChangeCasual(event) {

    // Course fee
    if (this.model.causal == 6) {
      this.model.option_disable_link_in_email = true;
    }
  }


  //  Invia un reminder con la mail di pagamento
  sendReminder() {
    this.paymentService.sendReminder(this.model);
  }

  //  cancella una richiesta di pagamento
  deleteRequest() {
    this.paymentService.deleteRequest(this.model, () => {

      this.paymentRequestDeleted.emit();
    });
  }



  getPaymentService(): PaymentsService {
    return this.paymentService;
  }

  getConfigService(): ConfigService {
    return this.configService;
  }

}
