<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span>Warehouse</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Products
        <small></small>
      </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">
          <app-warehouse-product-datatable></app-warehouse-product-datatable>
        </div>
      </div>
    </div>
    <!-- END CONTENT BODY -->
