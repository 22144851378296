import { Component, OnInit, ViewChild } from '@angular/core';
import { CourseScheduleService, ConfigService } from 'app/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-course-schedule-payment-requests-list',
  templateUrl: './course-schedule-payment-requests-list.component.html',
  styleUrls: ['./course-schedule-payment-requests-list.component.css']
})
export class CourseSchedulePaymentRequestsListComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;

  rowsSubscribers: any[] = [];
  rowsSignups: any[] = [];
  selected = [];
  ongoingRequest: boolean = false;
  selectedPaymentRequestInfo = null;

  constructor(private courseScheduleService: CourseScheduleService, private configService: ConfigService) { }

  ngOnInit() {

    if (this.courseScheduleService.currentCourseScheduleId)
      this.getPaymentRequests();
  }


  getPaymentRequests() {

    this.ongoingRequest = true;
    this.rowsSubscribers = [];
    this.rowsSignups = [];

    this.courseScheduleService.getPaymentRequests( (list) => {

      this.parseRows(list);

      this.ongoingRequest = false;
    }, () =>  {

      this.rowsSubscribers = [];
      this.rowsSignups = [];
      this.ongoingRequest = false;
    })

  }


  parseRows(list: any)
  {
    //  filtro la lista eliminando le richiste che fanno parte delle signup
    this.rowsSubscribers = list.filter(x => x.out_holder_type != "App\\CourseSignupForm");
    this.rowsSignups = list.filter(x => x.out_holder_type == "App\\CourseSignupForm");
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  onSelect({ selected }) {

    this.selectedPaymentRequestInfo = this.selected[0];
    this.showModal();
  }

  onActivate(event) {
    //  console.log('Activate Event', event);
  }

  //  Eventi sulla payment request
  paymentRequestDeleted ()
  {
    this.getPaymentRequests();
    this.hideModal();
  }


  //  MODAL PAYMENT REQUEST
  isModalShown: boolean = false;
  showModal(): void {
    this.isModalShown = true;
  }
  hideModal(): void {
    this.autoShownModal.hide();
  }
  onHidden(): void {
    this.isModalShown = false;
    this.selectedPaymentRequestInfo = null;
  }


  getRowClass(row) {
    return {
      'ignore-request': row.ignore_request === 1
    };
  }

  getConfigService() : ConfigService {
    return this.configService;
  }

  getCourseScheduleService () : CourseScheduleService {
    return this.courseScheduleService;
  }

}
