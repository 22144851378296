<button [appCheckPermission]="'course_schedule_edit'" type="button" class="btn green btn-block btn-outline" (click)="addNew();"><i class="fa fa-plus"></i>&nbsp; New Subscriber</button>

<div class="datatable-loader text-center" *ngIf="getCourseScheduleService().isOngoingRequest()">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>

<br>

<tabset [justified]="true">

  <!-- TAB INFO -->

  <tab heading="Info">
    <div class="portlet">

      <div class="portlet-title" [appCheckPermission]="'course_schedule_edit'">
        <div class="actions">
          <div class="caption">
            <div class="row">
              <div class="col-md-12">
                <button *ngIf="multiSelection == false" class="btn btn-primary" (click)="changeMultiSelection(true);"><i class="fa fa-edit"></i>&nbsp;Multiple Selection</button>
                <button *ngIf="multiSelection == true" class="btn btn-danger" (click)="changeMultiSelection(false);"><i class="fa fa-times"></i>&nbsp;Clear Multiple Selection</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="portlet-body">
        <div *ngIf="!getCourseScheduleService().isOngoingRequest()">

          <ngx-datatable
            class="striped"
            [rows]="rows"
            [columnMode]="'flex'"
            [headerHeight]="50"
            [footerHeight]="80"
            [rowHeight]="'auto'"
            [selected]="selected"
            [selectionType]="selectionType"
            [rowClass]="getRowClass"
            (activate)="onActivate($event)"
            (select)='onSelect($event)'
            [sorts]="[{prop: 'person_info.surname', dir: 'asc'}]"
          >
            <ngx-datatable-column *ngIf="multiSelection"
              [width]="30"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
              [headerCheckboxable]="true"
              [checkboxable]="true">
            </ngx-datatable-column>
            <ngx-datatable-column name="Nome" [flexGrow]="3" prop="person_info.surname" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}} {{row.person_info.first_name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Età" [flexGrow]="1" prop="person_info.birthday" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getHelper().calculateAge(row.person_info.birthday)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Sesso" [flexGrow]="1" prop="person_info.gender" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getConfigService().getGenderPersonValueByKey(row.person_info.gender)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Lavoro" [flexGrow]="1" prop="person_info.work_type" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getConfigService().getPersonWorkValueByKey(row.person_info.work_type)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Classe" [flexGrow]="2" prop="person_info.class_type" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getConfigService().getpersonWorkSchoolTypeValueByKey(row.person_info.class_type)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Cash Flow" prop="" [flexGrow]="2" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <button *ngIf="value" type="button" class="no-button" [popover]="quoteSubscriberTemplate" popoverTitle="Quote {{ row.course_fee_payed }}/{{ row.course_fee_total }} ({{row.total_fee_amount}})" container="body" triggers="mouseenter:mouseleave" placement="left">
                  <span *ngIf="row.enrollment_fee" ><i [ngClass]="{'font-red': (row.enrollment_fee_verified == 0)}" class="fa fa-user-plus"></i></span>
                  <span *ngIf="row.course_fee"><i [ngClass]="{'font-red': (row.course_fee_verified == 0)}" class="fa fa-graduation-cap"></i></span>
                  <span *ngIf="row.course_fee_payed && row.course_fee_total > 0" [ngClass]="{'font-red': (row.course_fee_payed < row.course_fee_total)}">{{ row.course_fee_payed }}/{{ row.course_fee_total }}</span>
                  <span *ngIf="!row.course_fee_payed && row.course_fee_total > 0" class="font-red">0/{{ row.course_fee_total }}</span>
                  <span *ngIf="row.course_fee_overfee" class="font-red"> <i class="fa fa-exclamation-circle" aria-hidden="true"></i> ({{ row.course_fee_overfee }})</span>
                  <span *ngIf="row.course_fee_total == 0" class="font-green">FREE</span>
                  <span *ngIf="row.payment_requests.length > 0"><i class="fa fa-money"></i></span>
                </button>
                <ng-template #quoteSubscriberTemplate>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group read-only">
                          <label class="control-label">Enrollment</label>
                          <p><i *ngIf="row.enrollment_fee_verified" class="fa fa-check-circle" aria-hidden="true" style="color:green"></i> {{ (row.enrollment_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group read-only">
                          <label class="control-label">Course</label>
                          <p><i *ngIf="row.course_fee_verified" class="fa fa-check-circle" aria-hidden="true" style="color:green"></i> {{ (row.course_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group read-only">
                          <label class="control-label">Refound</label>
                          <p>{{ (row.refound_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                        </div>
                      </div>
                    </div>
                    <div *ngFor="let discount of row.active_discount" class="row">
                      <div class="col-md-12">
                        <div class="form-group read-only">
                          <label class="control-label">{{ discount.label }}</label>
                          <p>{{ (discount.value | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                        </div>
                      </div>
                    </div>
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Debt" prop="remaning_to_pay" [flexGrow]="1" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ (row.remaning_to_pay | currency:'EUR':'symbol':'1.2-2':'it') || "€0.00"}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                >
                <div class="row" style="margin:5px">
                  <hr>
                  Total Subscribers: <b> {{rowCount}} </b> <br>
                </div>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="pull-right">
        <button [appCheckPermission]="'course_schedule_export'" [disabled]="getCourseScheduleService().isOngoingRequest() || rows?.length <= 0" type="button" class="btn green" (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button>
        <button [appCheckPermission]="'course_schedule_create'" [disabled]="getCourseScheduleService().isOngoingRequest() || selected?.length <= 0" type="button" class="btn btn-outline blue" (click)="copySubscribersForm(selected)"><i class="fa fa-clone"></i> Copy</button>
        <button [appCheckPermission]="'course_schedule_create'" [disabled]="getCourseScheduleService().isOngoingRequest() || selected?.length <= 0" type="button" class="btn btn-outline red" (click)="deleteSubscribersForm(selected)"><i class="fa fa-trash"></i> Delete</button>
      </div>
    </div>

    <div class="portlet" *ngIf="rowsNotActive.length > 0">
      <div class="portlet-body">
        <div *ngIf="!getCourseScheduleService().isOngoingRequest()">

          <h3>Ritirati (Deleted)</h3>

          <ngx-datatable
            class="striped"
            [rows]="rowsNotActive"
            [columnMode]="'flex'"
            [headerHeight]="50"
            [footerHeight]="80"
            [rowHeight]="'auto'"
            [selected]="selected"
            [selectionType]="'single'"
            [rowClass]="getRowClass"
            (activate)="onActivate($event)"
            (select)='onSelect($event)'
            [sorts]="[{prop: 'person_info.surname', dir: 'asc'}]"
          >
            <ngx-datatable-column name="Nome" [flexGrow]="3" prop="person_info.surname" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}} {{row.person_info.first_name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Età" [flexGrow]="1" prop="person_info.birthday" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getHelper().calculateAge(row.person_info.birthday)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Sesso" [flexGrow]="1" prop="person_info.gender" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getConfigService().getGenderPersonValueByKey(row.person_info.gender)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Lavoro" [flexGrow]="1" prop="person_info.work_type" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getConfigService().getPersonWorkValueByKey(row.person_info.work_type)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Classe" [flexGrow]="2" prop="person_info.class_type" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getConfigService().getpersonWorkSchoolTypeValueByKey(row.person_info.class_type)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Cash Flow" prop="" [flexGrow]="2" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <button *ngIf="value" type="button" class="no-button" [popover]="quoteSubscriberTemplate" popoverTitle="Quote {{ row.course_fee_payed }}/{{ row.course_fee_total }} ({{row.total_fee_amount}})" container="body" triggers="mouseenter:mouseleave" placement="left">
                  <span *ngIf="row.enrollment_fee" ><i [ngClass]="{'font-red': (row.enrollment_fee_verified == 0)}" class="fa fa-user-plus"></i></span>
                  <span *ngIf="row.course_fee"><i [ngClass]="{'font-red': (row.course_fee_verified == 0)}" class="fa fa-graduation-cap"></i></span>
                  <span *ngIf="row.course_fee_payed && row.course_fee_total > 0" [ngClass]="{'font-red': (row.course_fee_payed < row.course_fee_total)}">{{ row.course_fee_payed }}/{{ row.course_fee_total }}</span>
                  <span *ngIf="!row.course_fee_payed && row.course_fee_total > 0" class="font-red">0/{{ row.course_fee_total }}</span>
                  <span *ngIf="row.course_fee_overfee" class="font-red"> <i class="fa fa-exclamation-circle" aria-hidden="true"></i> ({{ row.course_fee_overfee }})</span>
                  <span *ngIf="row.course_fee_total == 0" class="font-green">FREE</span>
                  <span *ngIf="row.payment_requests.length > 0"><i class="fa fa-money"></i></span>
                </button>
                <ng-template #quoteSubscriberTemplate>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group read-only">
                          <label class="control-label">Enrollment Fee</label>
                          <p><i *ngIf="row.enrollment_fee_verified" class="fa fa-check-circle" aria-hidden="true" style="color:green"></i> {{ (row.enrollment_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group read-only">
                          <label class="control-label">Course Fee</label>
                          <p><i *ngIf="row.course_fee_verified" class="fa fa-check-circle" aria-hidden="true" style="color:green"></i> {{ (row.course_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group read-only">
                          <label class="control-label">Refound</label>
                          <p>{{ (row.refound_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                        </div>
                      </div>
                    </div>
                    <div *ngFor="let discount of row.active_discount" class="row">
                      <div class="col-md-12">
                        <div class="form-group read-only">
                          <label class="control-label">{{ discount.label }}</label>
                          <p>-{{ (discount.value | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                        </div>
                      </div>
                    </div>
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Debt" prop="remaning_to_pay" [flexGrow]="1" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ (row.remaning_to_pay | currency:'EUR':'symbol':'1.2-2':'it') || "€0.00"}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                >
                <div class="row" style="margin:5px">
                  <hr>
                  Total Subscribers Retired: <b> {{rowCount}} </b>
                </div>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </tab>


  <!-- TAB NOTE -->


  <tab heading="Annotations">

    <div *ngIf="!getCourseScheduleService().isOngoingRequest()">
      <ngx-datatable
        class="striped"

        [rows]="rows"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [selected]="selected"
        [selectionType]="'single'"
        [rowClass]="getRowClass"
        (activate)="onActivate($event)"
        (select)='onSelect($event)'
        [sorts]="[{prop: 'person_info.surname', dir: 'asc'}]"
      >
        <ngx-datatable-column name="Nome" [flexGrow]="3" prop="person_info.surname" [sortable]="true" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{value}} {{row.person_info.first_name}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Note" [flexGrow]="8" prop="annotation" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
        <ngx-datatable-column name="Discount Note" [flexGrow]="8" prop="quote_discount_annotation" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
      </ngx-datatable>
    </div>

    <div class="portlet" *ngIf="rowsNotActive.length > 0">
      <div class="portlet-body">
        <div *ngIf="!getCourseScheduleService().isOngoingRequest()">

          <h3>Ritirati (Deleted)</h3>

          <ngx-datatable
            class="striped"
            [rows]="rowsNotActive"
            [columnMode]="'flex'"
            [headerHeight]="50"
            [footerHeight]="80"
            [rowHeight]="'auto'"
            [selected]="selected"
            [selectionType]="'single'"
            [rowClass]="getRowClass"
            (activate)="onActivate($event)"
            (select)='onSelect($event)'
            [sorts]="[{prop: 'person_info.surname', dir: 'asc'}]"
          >
            <ngx-datatable-column name="Nome" [flexGrow]="3" prop="person_info.surname" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}} {{row.person_info.first_name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Note" [flexGrow]="8" prop="annotation" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
            <ngx-datatable-column name="Discount Note" [flexGrow]="8" prop="quote_discount_annotation" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>

  </tab>

  <tab heading="Certificato">
    <div class="portlet">

        <div *ngIf="!getCourseScheduleService().isOngoingRequest()">
            <ngx-datatable
              class="striped"

              [rows]="rows"
              [columnMode]="'flex'"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="'auto'"
              [selected]="selected"
              [selectionType]="'single'"
              [rowClass]="getRowClass"
              [sorts]="[{prop: 'person_info.surname', dir: 'asc'}]"
            >
              <ngx-datatable-column name="Nome" [flexGrow]="3" prop="person_info.surname" [sortable]="true" [resizeable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{value}} {{row.person_info.first_name}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Link al certificato" [flexGrow]="6" prop="link_certificato" [sortable]="false" [resizeable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <a href="{{value}}" target="_blank">{{value}}</a>
                    </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Invia mail a" [flexGrow]="4" prop="" [sortable]="false" [resizeable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                    <span *ngFor="let contact of row.contact_info | mapFromKeyValue">
                        <span *ngIf="contact.type == 1">
                            <ng-template #confirmMail>
                              <button type="button" class="btn btn-outline green-jungle" (click)="inviaMailTo($event, contact.value, row.id)">Si, invia la mail!</button>
                            </ng-template>
                            <button type="button" [outsideClick]="true" [popover]="confirmMail" popoverTitle="Sei sicuro?" class="btn btn-outline btn-sm blue"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;{{contact.value}}</button>
                        </span>
                    </span>


                  </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>

    </div>
  </tab>

</tabset>


<!-- BEGIN: Modal subscriber -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{selectedSignupInfo?.person_info.first_name}} {{selectedSignupInfo?.person_info.surname}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-course-schedule-subscriber-form [model]="selectedSignupInfo" [viewSubscriber]="viewSubscriber" [parentComponent]="this" (formSaved)="formSubscriberSaved($event)" (formCopySubscriber)="copySubscribersForm($event)"></app-course-schedule-subscriber-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Mdal subscriber -->

<!-- BEGIN: Modal new subscriber -->
<div [hidden]="!isNewModalShown" [config]="{ show: false }" (onHidden)="onHiddenNew()" bsModal #autoShownNewModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">New Subscriber</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideNewModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-course-schedule-subscriber-form-new *ngIf="isNewModalShown" (formSaved)="formNewSubscriberSaved($event)"></app-course-schedule-subscriber-form-new>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modal new subscriber -->


<!-- BEGIN: Modal request payment -->
<div *ngIf="isModalShownPaymentRequest" [config]="{ show: false }" (onHidden)="onHiddenRequestPayment()" bsModal #modalShowPaymentRequest="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Payment Request for: <b>{{selectedSignupInfo?.person_info.first_name}} {{selectedSignupInfo?.person_info.surname}}</b></h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalRequestPayment()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-payment-request-form [model]="currentPaymentRequest" (paymentRequestSucceeded)="paymentRequestSucceeded()" (paymentRequestError)="paymentRequestError($event)" ></app-payment-request-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Mdal request payment -->


<!-- BEGIN : modale con la copia dei subscribers -->
<div *ngIf="isModalCopyShown" [config]="{ show: true }" (onHidden)="onHiddenCopy()" bsModal #autoShownModalCopy="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          Copia di {{selectedSubscribers.length}} Subscribers
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalCopy()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-course-schedule-subscriber-copy [subscribers]="selectedSubscribers" (subscriberCopied)="hideModalCopy()" ></app-course-schedule-subscriber-copy>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- BEGIN : modale con la copia dei subscribers -->


<!-- BEGIN: MODAL CONFIRM DELETE -->
<div class="modal fade" bsModal #confirmModal="bs-modal" [config]="{backdrop: 'static'}"
  tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" *ngIf="selectedSubscribers">
        <h4 class="modal-title pull-left">
          Cancellazione di {{selectedSubscribers.length}} Subscribers
        </h4>
      </div>
      <div class="modal-body">
        <div class="modal-body text-center">
          <p>Do you want to confirm delete?</p>
          <button type="button" class="btn btn-default" (click)="confirmDelete()" >Yes</button>
          <button type="button" class="btn btn-primary" (click)="confirmModal.hide()" >No</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL CONFIRM DELETE -->


<!-- BEGIN: MODAL LOADING EXPORT-->
<div *ngIf="isModalExport" [config]="{ show: true, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal #staticModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->

