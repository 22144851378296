<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <!-- BEGIN: form -->
  <div class="form-body">

    <!-- <h3 class="form-section">Course Info</h3> -->

    <div class="row" *ngIf="editMode">
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDataStart)) }">
          <label class="control-label"><i class="fa fa-calendar" aria-hidden="true"></i> Start Date
            <span class="required">*</span>
          </label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_start_date="angular-mydatepicker" name="start_date" class="form-control"
                [locale]="getHelper().locale"
                [options]="datePickerOptionsBegin"
                [ngModel]="localDataStart"
                (dateChanged)="onDateStartChanged($event)"
                required
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.start" class="btn default" (click)="dp_start_date.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_start_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block">Start Date is required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDataEnd)) }">
          <label class="control-label"><i class="fa fa-calendar" aria-hidden="true"></i> End Date
            <span class="required">*</span>
          </label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_end_date="angular-mydatepicker" name="end_date" class="form-control"
                [locale]="getHelper().locale"
                [options]="datePickerOptionsEnd"
                [ngModel]="localDataEnd"
                (dateChanged)="onDateEndChanged($event)"
                required
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.end" class="btn default" (click)="dp_end_date.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_end_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block">End Date is required</span>
        </div>
      </div>
      <div class="col-md-3">
        <label class="control-label"><i class="fa fa-clock-o" aria-hidden="true"></i> Lessons Plan
          <span class="required">*</span></label>
        <div class="form-group">
          <input type="text" name="start_time" [(ngModel)]="model.start_time" #start_time="ngModel" class="form-control lesson-time-start"
            (input)="onTimeStartInput($event)" [typeahead]="getConfigService().timeCourseSchedulePlanSelect"
            [typeaheadScrollable]="true" (typeaheadOnSelect)="typeTimeStartOnSelect($event)" typeaheadOptionField="value"
            placeholder="08:30" autocomplete="off" />
          <input type="text" name="end_time" [(ngModel)]="model.end_time" #end_time="ngModel" class="form-control lesson-time-end"
            (input)="onTimeEndInput($event)" [typeahead]="getConfigService().timeCourseSchedulePlanSelect"
            [typeaheadScrollable]="true" (typeaheadOnSelect)="typeTimeEndOnSelect($event)" typeaheadOptionField="value"
            placeholder="11:00" autocomplete="off" />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="control-label">S.O.F.I.A. Code</label>
          <!-- <input type="text" name="sofia_code" [(ngModel)]="model.sofia_code" #sofia_code="ngModel" class="form-control" placeholder="" (keyup)="courseCodeClicked()"> -->
          <input type="text" name="sofia_code" [(ngModel)]="model.sofia_code" #sofia_code="ngModel" class="form-control"
            placeholder="">
          <span class="help-block">unique code for S.O.F.I.A.</span>
        </div>
      </div>
      <div class="col-md-1">
        <div class="form-group">
          <label class="control-label">Edizione</label>
          <input type="text" name="numero_edizione" [(ngModel)]="model.numero_edizione" #numero_edizione="ngModel" class="form-control"
            placeholder="">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="editMode">
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || status.dirty || status.touched) && !status.valid && !status.disabled }">
          <label class="control-label">Course Status
            <span class="required">*</span>
          </label>
          <select class="form-control" name="status" #status="ngModel" [(ngModel)]="model.status" required>
            <option *ngFor="let s of getConfigService().courseScheduleStatusSelect" [value]="s.key">{{s.value}}</option>
          </select>
          <span class="help-block"> Course Status is required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || area.dirty || area.touched) && !area.valid && !area.disabled }">
          <label class="control-label">Area
            <span class="required">*</span>
          </label>
          <select class="form-control" name="area" #area="ngModel" [(ngModel)]="model.area" required>
            <option *ngFor="let z of getConfigService().areaExpertiseSelect" [value]="z.key">{{z.value}}</option>
          </select>
          <span class="help-block"> Area is required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || company.dirty || company.touched) && !company.valid && !company.disabled }">
          <label class="control-label">Company
            <span class="required">*</span>
          </label>
          <select class="form-control" name="company" #company="ngModel" [(ngModel)]="model.company" required>
            <option *ngFor="let c of getConfigService().groupCompaniesSelect" [value]="c.key">{{c.value}}</option>
          </select>
          <span class="help-block"> Company is required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Certificate Hours</label>
          <input type="text" name="certificate_hours" [(ngModel)]="model.certificate_hours" #sofia_code="ngModel" class="form-control"
            placeholder="">
          <span class="help-block">PDF Certificate</span>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="editMode">
      <div class="col-md-12">
        <div class="portlet box blue">
          <div class="portlet-title">
            <div class="caption">
              <i class="fa fa-globe"></i>Buyable Online Info
            </div>
          </div>
          <div class="portlet-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group" *ngIf="onlineErrorArr.length == 0">
                  <label class="control-label">Buyable Online</label>
                  <p style="margin-top:15px;">
                    <a *ngIf="onlineErrorArr.length == 0" name="buyable" (click)="toggleClicked();">
                      <i class="fa fa-toggle-on fa-4x" *ngIf="model.buyable_online== true" style="color:green"></i>
                      <i class="fa fa-toggle-off fa-4x" *ngIf="model.buyable_online== false" style="color:red"></i>
                    </a>
                  </p>
                </div>
                <div *ngIf="onlineErrorArr.length > 0">
                  <div class="form-group">
                    <label class="control-label">&nbsp;</label>
                    <p><i class="fa fa-exclamation-triangle"></i>
                      Attenzione!!! Il corso non sarà acquistabile Online a causa dei seguenti motivi:
                    </p>
                    <ul>
                      <li *ngFor="let error of onlineErrorArr">{{error}}</li>
                      <li *ngFor="let warn of onlineWarnArr">{{warn}}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div clas="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="control-label">Ruolo default per iscrizioni</label>
                      <select class="form-control" name="default_role" #defaultRole="ngModel" [(ngModel)]="model.default_subscribers_role">
                        <option *ngFor="let s of getConfigService().courseScheduleRoleForSubscribersSelect" [value]="s.key">{{s.value}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || max_num_subscribers.dirty || max_num_subscribers.touched) && !max_num_subscribers.valid && !max_num_subscribers.disabled }">
                      <label class="control-label">Max num of subscribers</label>
                      <input type="number" name="max_num_subscribers" min=0 [(ngModel)]="model.max_num_subscribers" #max_num_subscribers="ngModel" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">Riferimento alla pagina di prenotazione educoitalia.it del corso</label>
                      <input type="text" name="external_info_page_url" [(ngModel)]="model.external_info_page_url" #external_info_page_url="ngModel" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="editMode">
      <div class="col-md-12">
        <div class="portlet box blue">
          <div class="portlet-title">
            <div class="caption">
              <i class="fa fa-globe"></i>Course Online
            </div>
          </div>
          <div class="portlet-body">
            <div class="row">
              <div class="col-md-3">
                <label class="control-label">Is an online course?</label>
                <p style="margin-top:15px;">
                  <a name="online" (click)="onlineClicked()">
                    <i *ngIf="model.online == true" class="fa fa-toggle-on fa-4x" style="color:green"></i>
                    <i *ngIf="model.online == false" class="fa fa-toggle-off fa-4x" style="color:red"></i>
                  </a>
                </p>
              </div>
              <div class="col-md-9">
                <div class="form-group">
                  <div class="form-group">
                    <label class="control-label">Link Meeting</label>
                    <input type="text" name="online_description_url" [(ngModel)]="model.online_description_url" #online_description_url="ngModel" class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="editMode">
      <div class="col-md-6">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase">Course</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" (click)="showCourseModal('coursename');">
                  <i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDeleteCourse>
                  <button type="button" class="btn btn-outline red" (click)="removeAutority('coursename')">Yes Remove!</button>
                </ng-template>
                <button *ngIf="this.model.course_id" type="button" class="btn btn-circle red btn-outline"
                  [outsideClick]="true" [popover]="confirmDeleteCourse" popoverTitle="Are you sure?">
                  <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-course-info-show [idCourse]="model.course_id"></app-course-info-show>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase">Relatore</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" (click)="showPersonModal('supervisor');">
                  <i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDeleteSupervisor>
                  <button type="button" class="btn btn-outline red" (click)="removeAutority('supervisor')">Yes Remove!</button>
                </ng-template>
                <button *ngIf="this.model.supervisor_id" type="button" class="btn btn-circle red btn-outline"
                  [outsideClick]="true" [popover]="confirmDeleteSupervisor" popoverTitle="Are you sure?">
                  <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-person-info-show [idPerson]="model.supervisor_id"></app-person-info-show>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="editMode">
        <div class="col-md-6">
          <div class="portlet light">
            <div class="portlet-title">
              <div class="caption">
                <i class="icon-bar-chart font-dark hide"></i>
                <span class="caption-subject font-dark bold uppercase">Co Relatore</span>
                <span class="caption-helper"></span>
              </div>
              <div class="actions">
                <div class="btn-group btn-group-devided" data-toggle="buttons">
                  <button type="button" class="btn btn-circle btn-outline green" (click)="showPersonModal('co_supervisor_01');">
                    <i class="fa fa-edit"></i>&nbsp;Edit</button>
                  <ng-template #confirmDeleteSupervisor_01>
                    <button type="button" class="btn btn-outline red" (click)="removeAutority('co_supervisor_01')">Yes Remove!</button>
                  </ng-template>
                  <button *ngIf="this.model.co_supervisor_01_id" type="button" class="btn btn-circle red btn-outline"
                    [outsideClick]="true" [popover]="confirmDeleteSupervisor_01" popoverTitle="Are you sure?">
                    <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
                </div>
              </div>
            </div>
            <div class="portlet-body">
              <app-person-info-show [idPerson]="model.co_supervisor_01_id"></app-person-info-show>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="portlet light">
            <div class="portlet-title">
              <div class="caption">
                <i class="icon-bar-chart font-dark hide"></i>
                <span class="caption-subject font-dark bold uppercase">Co Relatore</span>
                <span class="caption-helper"></span>
              </div>
              <div class="actions">
                <div class="btn-group btn-group-devided" data-toggle="buttons">
                  <button type="button" class="btn btn-circle btn-outline green" (click)="showPersonModal('co_supervisor_02');">
                    <i class="fa fa-edit"></i>&nbsp;Edit</button>
                  <ng-template #confirmDeleteSupervisor_02>
                    <button type="button" class="btn btn-outline red" (click)="removeAutority('co_supervisor_02')">Yes Remove!</button>
                  </ng-template>
                  <button *ngIf="this.model.co_supervisor_02_id" type="button" class="btn btn-circle red btn-outline"
                    [outsideClick]="true" [popover]="confirmDeleteSupervisor_02" popoverTitle="Are you sure?">
                    <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
                </div>
              </div>
            </div>
            <div class="portlet-body">
              <app-person-info-show [idPerson]="model.co_supervisor_02_id"></app-person-info-show>
            </div>
          </div>
        </div>
      </div>

    <div class="row" *ngIf="editMode">
      <div class="col-md-6">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase"><i class="fa fa-map-marker" aria-hidden="true"></i>
                Venue</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" (click)="showEnterpriseModal('venue');">
                  <i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDeleteVenue>
                  <button type="button" class="btn btn-outline red" (click)="removeAutority('venue')">Yes Remove!</button>
                </ng-template>
                <button *ngIf="this.model.venue_id" type="button" class="btn btn-circle red btn-outline" [outsideClick]="true"
                  [popover]="confirmDeleteVenue" popoverTitle="Are you sure?">
                  <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-enterprise-info-show #enterpriseInfoShowVenue [showAddress]="true" [idEnterprise]="model.venue_id"></app-enterprise-info-show>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase"><i class="fa fa-map-marker" aria-hidden="true"></i>
                Accomodation Venue</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" (click)="showEnterpriseModal('accomodation_venue');">
                  <i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDeleteAccomodationVenue>
                  <button type="button" class="btn btn-outline red" (click)="removeAutority('accomodation_venue')">Yes
                    Remove!</button>
                </ng-template>
                <button *ngIf="this.model.accomodation_venue_id" type="button" class="btn btn-circle red btn-outline"
                  [outsideClick]="true" [popover]="confirmDeleteAccomodationVenue" popoverTitle="Are you sure?">
                  <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-enterprise-info-show #enterpriseInfoShowVenue [idEnterprise]="model.accomodation_venue_id"></app-enterprise-info-show>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="editMode">
      <div *ngIf="model.id" class="col-md-6 form-group">
        <label class="control-label">Leaflet</label>
        <div *ngIf="model.leaflet_file_path == undefined || model.leaflet_file_path == ''">
          <input *ngIf="!leafletUploader.isUploading" type="file" id="leafletUploader"
            ng2FileSelect [uploader]="leafletUploader" />
          <label *ngIf="!leafletUploader.isUploading" for="leafletUploader"
            class="btn green-jungle text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload Leaflet!</label>
        </div>
        <div *ngIf="leafletUploader.isUploading || inUpload || getCourseScheduleService().isOngoingRequest()">
          <img src="./assets/global/img/input-spinner.gif" />
          please wait...
        </div>
        <div *ngIf="model.leaflet_file_path != undefined && model.leaflet_file_path != ''">
          <a class="btn btn-primary text-uppercase" href="{{getConfigService().blobBasePath}}{{model.leaflet_file_path}}" target="_self">Download</a>
          <button *ngIf="!leafletUploader.isUploading && !inUpload" type="button"
            class="btn btn-danger text-uppercase" (click)="deleteLeaflet();"><i
              class="fa fa-trash"></i>&nbsp; Delete Leaflet</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="editMode">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Online Description corsi.educoitalia.it</label>
          <textarea name="online_description" [(ngModel)]="model.online_description" rows="3" class="form-control"
            placeholder=""></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="editMode">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Payment Agreements</label>
          <textarea name="payment_agreements" [(ngModel)]="model.payment_agreements" rows="3" class="form-control"
            placeholder=""></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="audienceModel != null">
      <div class="col-md-12" *ngIf="editMode">
        <div class="form-group">
          <label class="control-label"> Audience</label>
          <br>
          <div class="btn-group">
            <label *ngFor="let c of getConfigService().courseScheduleAudienceSelect; let i=index" name="{{c.key}} | i"
              [(ngModel)]="audienceModel[i]" class="btn btn-primary" (ngModelChange)="onChangeAudience($event)"
              btnCheckbox>{{c.value}}</label>
          </div>
          <span class="help-block">Select one or more boxes</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" *ngIf="editMode">
        <div class="form-group">
          <label class="control-label">Enrollment</label>
          <textarea name="enrollment" [(ngModel)]="model.enrollment" rows="3" class="form-control" placeholder=""></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="editModeTravelInfo">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label"><i class="fa fa-train" aria-hidden="true"></i> Railway Station</label>
          <input [(ngModel)]="model.railway" name="railway" [typeahead]="stationComplex" [typeaheadScrollable]="true"
            (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadNoResults)="changeTypeaheadNoResults($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
            [typeaheadOptionsLimit]="7" typeaheadOptionField="sname" placeholder="Scrivi il nome di una stazione" class="form-control"
            autocomplete="nope">
          <div *ngIf="typeaheadLoading===true">Loading</div>
          <div *ngIf="typeaheadNoResults===true">&#10060; No Results Found</div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="editModeTravelInfo">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label"><i class="fa fa-road" aria-hidden="true"></i> Transfer details</label>
          <textarea name="transfer_details" [(ngModel)]="model.transfer_details" rows="3" class="form-control"
            placeholder=""></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="editMode">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label"><i class="fa fa-files-o" aria-hidden="true"></i> Additional agreements for
            contract</label>
          <textarea name="additional_agreements" [(ngModel)]="model.additional_agreements" rows="3" class="form-control"
            placeholder=""></textarea>
        </div>
      </div>
    </div>






    <div class="row" *ngIf="!editMode">
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label"><i class="fa fa-calendar" aria-hidden="true"></i> Start Date</label>
          <p> {{ model.start_parsed| date:'d MMMM, y':'':'it' }} </p>
        </div>
      </div>
      <div class="col-md-3">
        <label class="control-label"><i class="fa fa-calendar" aria-hidden="true"></i> End Date</label>
        <p>{{ model.end_parsed | date:'d MMMM, y':'':'it' }}</p>
      </div>
      <div class="col-md-3" *ngIf="model.start_time && model.end_time">
        <label class="control-label"><i class="fa fa-clock-o" aria-hidden="true"></i> Lesson Plan</label>
        <p> {{ model.start_time }} - {{ model.end_time }} </p>
      </div>
      <div class="col-md-2 read-only form-group editMode" *ngIf="model.sofia_code">
        <label class="control-label">Sofia Code</label>
        <p> {{model.sofia_code}} </p>
      </div>
      <div class="col-md-1 read-only form-group editMode" *ngIf="model.numero_edizione">
        <label class="control-label">Edizione</label>
        <p> {{model.numero_edizione}} </p>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-3">
        <label class="control-label">Course Status</label>
        <p> {{ getConfigService().getCourseScheduleStatusValueByKey(model.status) }} </p>
      </div>
      <div class="col-md-3">
        <label class="control-label">Area</label>
        <p> {{ getConfigService().getAreaExpertiseValueByKey(model.area) }} </p>
      </div>
      <div class="col-md-3">
        <label class="control-label">Company</label>
        <p> {{ getConfigService().getGroupCompanyValueByKey(model.company) }} </p>
      </div>
    </div>

    <div class="row" *ngIf="!editMode">
      <div class="col-md-12">
        <div class="portlet">
          <div class="portlet-title">
            <div class="caption">
              <i class="fa fa-globe"></i>Buyable Online Info
            </div>
          </div>
          <div class="portlet-body">
            <div class="row">
              <div class="col-md-3">
                <label class="control-label">Course Buyable online</label>
                <p style="margin-top:15px;">
                  <i *ngIf="model.buyable_online == true" class="fa fa-toggle-on fa-2x" style="color:green"></i>
                  <i *ngIf="model.buyable_online == false" class="fa fa-toggle-off " style="color:red"></i>
                </p>
              </div>
              <div class="col-md-3">
                <div class="form-group" *ngIf="model.max_num_subscribers">
                  <label class="control-label">Max num of subscribers</label>
                  <p>
                    {{model.max_num_subscribers}}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" *ngIf="model.external_info_page_url">
                  <label class="control-label">Pagina web di prenotazione</label>
                  <p>
                    {{model.external_info_page_url}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!editMode">
      <div class="col-md-12">
        <div class="portlet">
          <div class="portlet-title">
            <div class="caption">
              <i class="fa fa-clock-o"></i>Course Online
            </div>
          </div>
          <div class="portlet-body">
            <div class="row">
              <div class="col-md-3">
                <label class="control-label">Is an online course?</label>
                <p style="margin-top:15px;">
                  <i *ngIf="model.online == true" class="fa fa-toggle-on fa-2x" style="color:green"></i>
                  <i *ngIf="model.online == false" class="fa fa-toggle-off " style="color:red"></i>
                </p>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">Ruolo default per iscrizioni</label>
                  <p>
                    {{getConfigService().getCourseScheduleRoleForSubscribersValueByKey( model.default_subscribers_role)}}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Link Meeting</label>
                  <p>
                    {{model.online_description_url}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!editMode">
      <div class="col-md-6 read-only form-group editMode" *ngIf="model.course_id">
        <label class="control-label"><i class="fa fa-map-marker" aria-hidden="true"></i> Course</label>
        <p>
          <app-course-info-show [idCourse]="model.course_id"></app-course-info-show>
        </p>
      </div>
      <div class="col-md-6 read-only form-group editMode" *ngIf="model.supervisor_id">
        <label class="control-label"><i class="fa fa-map-marker" aria-hidden="true"></i> Relatore</label>
        <p>
          <app-person-info-show [idPerson]="model.supervisor_id"></app-person-info-show>
        </p>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-6 read-only form-group editMode" *ngIf="model.co_supervisor_01_id">
        <label class="control-label"><i class="fa fa-map-marker" aria-hidden="true"></i> Co Relatore</label>
        <p>
          <app-person-info-show [idPerson]="model.co_supervisor_01_id"></app-person-info-show>
        </p>
      </div>
      <div class="col-md-6 read-only form-group editMode" *ngIf="model.co_supervisor_02_id">
        <label class="control-label"><i class="fa fa-map-marker" aria-hidden="true"></i> Co Relatore</label>
        <p>
          <app-person-info-show [idPerson]="model.co_supervisor_02_id"></app-person-info-show>
        </p>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-6 read-only form-group editMode" *ngIf="model.venue_id">
        <label class="control-label"><i class="fa fa-map-marker" aria-hidden="true"></i> Venue</label>
        <p>
          <app-enterprise-info-show [showAddress]="true" [idEnterprise]="model.venue_id"></app-enterprise-info-show>
        </p>
      </div>
      <div class="col-md-6 read-only form-group editMode" *ngIf="model.accomodation_venue_id">
        <label class="control-label"><i class="fa fa-map-marker" aria-hidden="true"></i> Accomodation Venue</label>
        <p>
          <app-enterprise-info-show [idEnterprise]="model.accomodation_venue_id"></app-enterprise-info-show>
        </p>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-6">
        <label class="control-label">Audience</label>
        <ul class="list-inline">
          <li class="list-inline-item" *ngFor="let audie of audienceModel | mapFromKeyValue; let i = index">
            <span *ngIf="audie == true"> {{ getConfigService().courseScheduleAudienceSelect[i].value }} </span>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <label class="control-label">Leaflet</label>
        <p *ngIf="model.leaflet_file_path">
          <a class="btn btn-primary" href="{{getConfigService().blobBasePath}}{{model.leaflet_file_path}}" target="_self">Download</a>
        </p>
        <p *ngIf="!model.leaflet_file_path">Nessun documento caricato</p>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-12" *ngIf="model.online_description">
        <div class="form-group">
          <label class="control-label">Online Description corsi.educoitalia.it</label>
          <p> {{ model.online_description }} </p>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-12" *ngIf="model.payment_agreements">
        <div class="form-group">
          <label class="control-label">Payment Agreements</label>
          <p> {{ model.payment_agreements }} </p>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-12" *ngIf="model.enrollment">
        <label class="control-label">Enrollment</label>
        <p> {{ model.enrollment }} </p>
      </div>
    </div>
    <div class="row" *ngIf="!editModeTravelInfo">
      <div class="col-md-6" *ngIf="model.railway">
        <label class="control-label"><i class="fa fa-train" aria-hidden="true"></i> Railway Station</label>
        <p> {{ model.railway }} </p>
      </div>
    </div>
    <div class="row" *ngIf="!editModeTravelInfo">
      <div class="col-md-12" *ngIf="model.transfer_details">
        <label class="control-label"><i class="fa fa-road" aria-hidden="true"></i> Transfer details</label>
        <p> {{ model.transfer_details }} </p>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-12" *ngIf="model.additional_agreements">
        <label class="control-label"><i class="fa fa-files-o" aria-hidden="true"></i> Additional agreements for
          contract</label>
        <p> {{ model.additional_agreements }} </p>
      </div>
    </div>
    <!-- END: edit mode -->





    <!-- END: form -->
  </div>


  <div class="form-actions right" [appCheckPermission]="'course_schedule_edit'">
    <img *ngIf="getCourseScheduleService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button [appCheckPermission]="'course_schedule_edit'" type="button" class="btn btn-outline red" [disabled]="getCourseScheduleService().isOngoingRequest()"
      *ngIf="getCourseScheduleService().currentCourseScheduleId && (editMode || editModeTravelInfo)" [outsideClick]="true"
      [popover]="confirmDelete" popoverTitle="Are you sure?">
      <i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button *ngIf="this.model.id && !(editMode || editModeTravelInfo)" type="button" class="btn btn-outline blue"
      [disabled]="getCourseScheduleService().isOngoingRequest()" (click)="edit()">
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>
    <button type="submit" class="btn blue" [disabled]="getCourseScheduleService().isOngoingRequest()" *ngIf="getCourseScheduleService().currentCourseScheduleId && (editMode || editModeTravelInfo)">Update</button>
    <button type="submit" class="btn green-jungle" [disabled]="getCourseScheduleService().isOngoingRequest()" *ngIf="!getCourseScheduleService().currentCourseScheduleId && (editMode || editModeTravelInfo)">Continue</button>

    <button *ngIf="this.model.id" type="button" class="btn btn-outline blue" [disabled]="getCourseScheduleService().isOngoingRequest()"
      (click)="copy()">
      <i class="fa fa-clone" aria-hidden="true"></i> Copy</button>
  </div>

</form>




<!-- BEGIN: Modal di copia dei course schedule -->
<div *ngIf="isModalCopyCourse" [config]="{ show: true }" (onHidden)="onHiddenCopy()" bsModal #modalCopy="bs-modal" class="modal fade"
  tabindex="-1" tole="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"> Copy Course Schedule</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalCopy()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-course-schedule-copy (closeModal)="closeModalEvent($event)" [model]="this.model"></app-course-schedule-copy>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modal di copia dei course schedule -->


<!-- BEGIN: Modal di ricerca delle enterprise da associare -->
<div *ngIf="isEnterpriseModalShown" [config]="{ show: true }" (onHidden)="onEnterpriseHidden()" bsModal
  #enterpriseModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Enterprise</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideEnterpriseModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-enterprise-search-form #enterpriseSearchForm (searchKeywordsSubmit)="searchEnterpriseKeywordsSubmit($event)"
              [ongoingRequest]="enterpriseDatatable.ongoingRequest"></app-enterprise-search-form>
          </div>
        </div>

        <br>
        <br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-enterprise-datatable #enterpriseDatatable [query]="enterpriseSearchForm.searchKeyword"
                  (enterpriseSelected)="resultSelected($event)"></app-enterprise-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modal di ricerca delle enterprise da associare -->


<!-- BEGIN: Modal di ricerca delle persone da associare -->
<div *ngIf="isPersonModalShown" [config]="{ show: true }" (onHidden)="onPersonHidden()" bsModal #personModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Person</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hidePersonModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-person-search-form #personSearchForm (searchKeywordsSubmit)="searchPersonKeywordsSubmit($event)"
              [ongoingRequest]="personDatatable.ongoingRequest"></app-person-search-form>
          </div>
        </div>

        <br>
        <br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-person-datatable #personDatatable [query]="personSearchForm.searchKeyword" (personSelected)="resultSelected($event)"></app-person-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modal di ricerca delle persone da associare -->


<!-- BEGIN: Modal di ricerca delle corsi da associare -->
<div *ngIf="isCourseModalShown" [config]="{ show: true }" (onHidden)="onCourseHidden()" bsModal #courseModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Course</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideCourseModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-course-search-form #courseSearchForm (searchKeywordsSubmit)="searchCourseKeywordsSubmit($event)"
              [ongoingRequest]="courseDatatable.ongoingRequest"></app-course-search-form>
          </div>
        </div>

        <br>
        <br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-course-datatable #courseDatatable [query]="courseSearchForm.searchKeyword" (courseSelected)="resultSelected($event)"></app-course-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modal di ricerca delle corsi da associare -->


<div class="modal fade" bsModal #confirmModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
  aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Attenzione!</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="confirmModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Il corso non sarà acquistabile Online a causa dei seguenti motivi: <br>
        <ul *ngIf="onlineErrorArr.length > 0 || onlineWarnArr.length > 0">
          <li *ngFor="let error of onlineErrorArr">{{error}}</li>
          <li *ngFor="let warn of onlineWarnArr">{{warn}}</li>
        </ul>
        <button type="button" class="btn btn-primary" (click)="confirmSubmit()">Ok</button>
      </div>
    </div>
  </div>
</div>
