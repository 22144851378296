
<div class="row">
  <div class="col-md-12">
    <app-vehicle-search-form (searchKeywordsSubmit)="searchKeywordsSubmit($event)" [ongoingRequest]="datatable.ongoingRequest"></app-vehicle-search-form>
  </div>
</div>

<br><br>

<!-- begin: prima riga -->
<div class="row">
  <div class="col-md-12">

    <!--begin: Portlet -->
    <div class="portlet light bordered">
      <!--end: Portlet-Body -->
      <div class="portlet-body">
        <app-vehicle-datatable (vehicleSelected)="vehicleSelected($event)"></app-vehicle-datatable>
        <div class="row">
          <div class="pull-right">
            <!-- <button [appCheckPermission]="'vehicle_export'"  *ngIf="!datatable.ongoingRequest && datatable.rows.length > 0" type="button" class="btn green" (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button> -->
          </div>
        </div>
      </div>
      <!--end: Portlet-Body -->
    </div>
    <!--end: Portlet -->
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <app-vehicle-calendar (assignementSelected)="assignementSelected($event)" [ongoingRequest]="datatable.ongoingRequest"></app-vehicle-calendar>
  </div>
</div>


<!-- BEGIN: MODAL CONTENT VEHICLE INFO -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Vehicle Info Summary</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body form">
        <div class="row">
          <div class="col-md-12">
            <app-vehicle-info-summary #vehicleInfoSummaryComponent [idVehicle]="currentVehicleId" (closeEvent)="hideModal()" ></app-vehicle-info-summary>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL CONTENT VEHICLE INFO -->

<!-- BEGIN: MODAL LOADING EXPORT-->
<div *ngIf="isModalExport" [config]="{ show: true, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal #staticModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
