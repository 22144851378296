import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class CourseService {

	currentId;
	currentInfo: any;

	currentAll: any[];

	ongoingRequest: boolean = false;


	private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

	constructor(private backend: BackendService, private notify: NotificationsService) { }

	getsubjectToUpdateObservable(): Observable<boolean> {
		return this.subjectToUpdate.asObservable();
	}

	resetService() {
		this.currentId = null;
		this.currentInfo = null;
	}

	startRequest() {
		this.ongoingRequest = true;
	}

	finishRequest() {
		this.ongoingRequest = false;
	}

	isOngoingRequest() {
		return this.ongoingRequest;
	}

	getAllCourses(callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.get('course/all', params).subscribe(
		(response) => {

			let resJson : any = response;
			this.currentAll = resJson;

			this.subjectToUpdate.next(true);

			this.finishRequest();

			if (callbackSuccess != null)
				callbackSuccess();
		},
		(error) => {
			this.backend.showErrors(error);

			this.finishRequest();

			if (callbackError != null)
				callbackError();
		});

	}


	getCourseInfoAll(callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.get('course/' + this.currentId + '/all', params).subscribe(
			(response) => {

				let resJson : any = response;
				this.currentInfo = resJson;
				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			});
	}

	saveCourseInfo(model: any[], callbackSuccess: any = null) {

		this.startRequest();

		this.backend.post('course', model).subscribe(
			(response) => {

				let resJson : any = response;
				this.currentInfo = resJson;
				this.currentId = resJson.id;
				this.subjectToUpdate.next(true);

				this.notify.success("Course Info", "successfully saved");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}

	updateCourseInfo(model: any[]) {

		this.startRequest();

		this.backend.put('course/' + this.currentId, model).subscribe(
			(response) => {

				this.currentInfo = response;
				this.currentId = this.currentInfo.id;
				this.subjectToUpdate.next(true);

				this.notify.success("Course Info", "successfully updated");

				this.finishRequest();
			},
			(error) => {
				console.log("Errore");
				console.log(error);

				this.finishRequest();
			});
	}

	deleteCourseInfo(model: any, callbackSuccess: any = null) {

		this.startRequest();

		this.backend.destroy('course/' + this.currentId).subscribe(
			(response) => {

				this.resetService();
				this.subjectToUpdate.next(true);

				this.notify.success("Course Info", "successfully deleted");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}


	getCourseCoverFileUploadURL(model: any) {
		return (environment.apiUrl + 'course/' + this.currentId + '/uploadcover');
	  }


  public courseCoverFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
      this.notify.success("Cover File", 'Successfully Uploaded');
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Cover File', JSON.parse(response)['message']);
      else
        this.notify.error('Cover File', response);

      this.backend.showErrors(response);
    }
  }



	deleteCourseCoverFile(model: any) {

		this.startRequest();

		this.backend.destroy('course/' + this.currentId + '/destroycover').subscribe(
		  (response) => {

			this.currentInfo = response;
      this.currentId = this.currentInfo.id;
			this.subjectToUpdate.next(true);

			this.notify.success("Cover File", "Successfully Deleted");

			this.finishRequest();
		  },
		  (error) => {
			console.log("Errore");
			console.log(error);

			this.finishRequest();
		  }
		);
	  }


}
