import { UserService, HelperService, ConfigService } from 'app/_services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.css']
})
export class AdminReportsComponent implements OnInit {

  constructor(private userService: UserService, private configService: ConfigService, private helper: HelperService) { }


  ngOnInit() {
  }

  getUserService() {
    return this.userService
  }

  getConfigService() {
    return this.configService;
  }

}
