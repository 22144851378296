import { ConfigService, CourseScheduleService, HelperService } from './../../_services/index';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

@Component({
  selector: 'app-course-schedule-search-form',
  templateUrl: './course-schedule-search-form.component.html',
  styleUrls: ['./course-schedule-search-form.component.css']
})
export class CourseScheduleSearchFormComponent implements OnInit {

  model: any = { };

  private firstSubmit: boolean = false;
  private defaultSearchModel = null;

  private subject: any;

  public advancedSearchShow = false;

  public multipleSelect = [];

  @Output() searchKeywordsSubmit = new EventEmitter();

  @Input() ongoingRequest: boolean;

  constructor(private route: ActivatedRoute, private configService: ConfigService, private courseScheduleService: CourseScheduleService, private helper: HelperService) {

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;
  }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {});

    this.defaultSearchModel = this.model;

    if (this.courseScheduleService.lastSearchQueries != null){
      this.model = this.courseScheduleService.lastSearchQueries;
    }
    else {
      this.clearSearch();
    }

    //  aggiungo all'array della multi selezione le info contenute nel model della ricerca
    this.createSelector();

  }

  ngOnDestroy ()
  {
    this.subject.unsubscribe();
  }


  fieldChange(event) {

    if (!this.firstSubmit)
      return;

    this.searchKeywordsSubmit.emit(this.model);
  }

  submitSearch() {

    //  memorizzo i parametri della ricerca attuale
    this.courseScheduleService.lastSearchQueries = this.model;

    //  Elimino le chiavi che non hanno valore prima di fare le ricerche
    Object.keys(this.model).forEach(element => {
      if (!this.model[element])
        delete this.model[element];
    });

    if (this.model.status != null && typeof this.model.status != "undefined")
    {
      this.model.status = [];
      this.multipleSelect.forEach(elementStatus => {
        if (elementStatus.value == true)
          this.model.status.push(elementStatus.key);
      })
    }

    this.searchKeywordsSubmit.emit(this.model);
  }

  clearSearch() {
    this.model = {year: new Date().getFullYear(), status: ["1", "2", "3", "4"]};
    this.createSelector();

    this.courseScheduleService.lastSearchQueries = this.model;
    this.hideAdvancedSearch();
  }


  //  crea il multiselettore in base al model
  public createSelector () {

    this.multipleSelect = [
      {key:"1", value:false}, {key:"2", value:false}, {key:"3", value:false}, {key:"4", value:false}
    ];

    this.model.status.forEach(elementStatus => {
      (this.multipleSelect.find( x => x.key == elementStatus)).value = true;
    });
  }

  //  selezionato il valore attuale dai filtri della select
  public selectedItem(value:any):void {

    if (typeof this.model.status == "undefined")
      this.model.status = [];

    this.model.status.push(value.id);
  }

  //  rimuovo il valore attuale dai filtri della select
  public removedItem(value:any):void {

    var index = this.model.status.findIndex(x => x == value.id);

    if (index >= 0)
      this.model.status.splice(index, 1);
  }


  // DATE PICKER
  localDateFrom: IMyDateModel;
  localDateTo: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  datePickerOptionsBegin: IAngularMyDpOptions = {};
  datePickerOptionsEnd: IAngularMyDpOptions = {};

  //  Operazioni sulla data di start
  onDateFromChanged(event: IMyDateModel)
  {
    this.localDateFrom = event;

    if (event.singleDate.jsDate != null)
    {
      this.model['from_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;

      this.datePickerOptionsEnd = datePickerOpt;
    }
    else
    {
      this.model['from_date'] = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }
  }



  //  Operazioni sulla data di end
  onDateToChanged(event: IMyDateModel)
  {
    this.localDateTo = event;

    if (event.singleDate.jsDate != null)
    {
      this.model['to_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableSince = event.singleDate.date;

      this.datePickerOptionsBegin = datePickerOpt;
    }
    else
    {
      this.model['to_date'] = null;
      this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }




  hideAdvancedSearch() {
    this.advancedSearchShow = false;
  }

  clearAdvancedSearch() {

    //  resetto il modello e tengo aperto il form di ricerca
    this.model = { };
    this.showAdvancedSearch();
  }

  showAdvancedSearch() {
    this.advancedSearchShow = true;
  }

  getConfigService() {
    return this.configService
  }

  getHelper(){
    return this.helper;
  }

}
