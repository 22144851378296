import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-check-platform-compatibility',
  templateUrl: './check-platform-compatibility.component.html',
  styleUrls: ['./check-platform-compatibility.component.css']
})
export class CheckPlatformCompatibilityComponent implements OnInit {

  deviceInfo = null;
  show: boolean = false;

  constructor() { }

  ngOnInit() {

    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
      var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
      var version = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)];

      if (version[0] < 10)
        this.show = true;
    }
  }

}
