<ngx-datatable class="striped" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
  [rowClass]="getRowClass" [selected]="selected" (activate)="onActivate($event)"
  [sorts]="[{prop: 'date', dir: 'desc'}]"
  [selectionType]="'checkbox'" >

  <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
      <span *ngIf="!row['agreement_id'] && row['status'] == 1">
        <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)"/>
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Status" prop="status_value"></ngx-datatable-column>
  <ngx-datatable-column name="Date" prop="date_formatted">
    <ng-template let-column="column" ngx-datatable-header-template>
      <i class="fa fa-calendar"> </i>
      <label class="control-label bold">
        {{column.name}} </label>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Theatrino" prop="theatrino_name"></ngx-datatable-column>
  <ngx-datatable-column name=" " prop="id">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <button *ngIf="row['agreement_id']" type="button" class="btn blue" (click)="onViewAgreement(value)"><i class="fa fa-eye"></i>&nbsp; Agreement</button>
      <button *ngIf="!row['agreement_id'] && row['status'] == 1 && selected.length == 0" type="button" class="btn green" (click)="newAgreement([row['id']]);">
        <i class="fa fa-plus"></i>&nbsp; Agreement</button>
        <button type="button" class="btn blue" (click)="editReservation(row['theatrino_id'], row['id']);">
            <i class="fa fa-edit"></i>&nbsp; Reservation</button>
    </ng-template>
  </ngx-datatable-column>

</ngx-datatable>

<div class="row">
  <div class="pull-right">
    <button *ngIf="selected.length > 0" type="button" class="btn green" (click)="newMultiAgreement();"><i class="fa fa-plus"></i>&nbsp; New Agreement</button>
  </div>
</div>
