import { Router, ActivatedRoute } from '@angular/router';
import { CourseDatatableComponent } from './../course-datatable/course-datatable.component';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-course-search-result',
  templateUrl: './course-search-result.component.html',
  styleUrls: ['./course-search-result.component.css']
})
export class CourseSearchResultComponent implements OnInit {

  @ViewChild(CourseDatatableComponent, {static: true}) datatable: CourseDatatableComponent;

  private subject: any;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.getAllResult();
  }

  courseSelected (selected) {

    this.router.navigate(['course/edit/'+selected.course_id]);
  }

  getAllResult() {
    this.datatable.query = "all";
    this.datatable.getResult();
  }

  updateResults(params) {
    this.datatable.query = params;
    this.datatable.getResult();
  }

  searchKeywordsSubmit(params) {

    if (typeof params == "undefined")
      this.getAllResult();
    else
      this.updateResults(params);
  }

}
