<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/actorgroup/home']">Actor Groups</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span *ngIf="!getGroupService().currentGroupId">New</span>
            <span *ngIf="getGroupService().currentGroupId">Edit</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>

      <app-reminder-widget-alertbox *ngIf="holderId" [holderType]="holderType" [holderId]="holderId"
        [holderName]="holderName"></app-reminder-widget-alertbox>

      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title" *ngIf="!getGroupService().currentGroupId">New group</h1>
      <h1 class="page-title" *ngIf="getGroupService().currentGroupId">Edit group</h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">

            <div class="portlet-title tabbable-line">
              <div class="caption caption-md">
                <i class="icon-globe theme-font hide"></i>
                <span class="caption-subject font-blue-madison bold uppercase">Actor Group</span>
              </div>
            </div>

            <!--end: Portlet-Body -->
            <div class="portlet-body form">

              <alert type="warning" *ngIf="loadingData">
                <strong>Loading</strong> ...
              </alert>

              <tabset #actorTabs class="align-right portlet-title tabbable-line" [hidden]="loadingData">
                <!--[personComponent]="this"-->
                <tab heading="Info">
                  <app-actor-group-info-form></app-actor-group-info-form>
                </tab>
                <tab heading="Shipments">
                  <app-actor-group-shipments-list></app-actor-group-shipments-list>
                </tab>
                <tab heading="Vehicles">
                  <app-actor-group-vehicle *ngIf="staticTabs?.tabs[2]?.active"></app-actor-group-vehicle>
                </tab>
                <tab heading="Cash flow">
                  <app-actor-group-cashflow></app-actor-group-cashflow>
                </tab>
                <tab heading="History">
                  <app-actor-group-history></app-actor-group-history>
                </tab>
                <tab heading="Reminder">
                  <app-actor-group-reminder></app-actor-group-reminder>
                </tab>
              </tabset>

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
