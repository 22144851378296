import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { CampService, HelperService, ConfigService, PrimanotaService, UserService, TutorApplicationService } from 'app/_services';
import { parseISO, isBefore, differenceInCalendarWeeks } from 'date-fns';
import { NgForm } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-camp-subscriber-form-fee-discount',
  templateUrl: './camp-subscriber-form-fee-discount.component.html',
  styleUrls: ['./camp-subscriber-form-fee-discount.component.css']
})
export class CampSubscriberFormFeeDiscountComponent implements OnInit {

  @ViewChild('f', {static: true}) currentForm: NgForm;

  @Output() formSaved = new EventEmitter();

  @Input() selectedSubscribers: any = [];

  //  numero di settimane del camp che si sta utilizzando
  campWeeks: number = 0;

  model: any = null;


  ongoingRequestParent = false;
  ongoingRequestPerson = false;

  addInAlertType: string = "warning";
  canEditDiscount: boolean = false;
  quote_discount_early_bird_date_parsed: any;

  constructor(private campService: CampService, private helper: HelperService,
    private configService: ConfigService, private notify: NotificationsService,
    private primanotaService: PrimanotaService, private userService: UserService) {
  }

  ngOnInit() {

    this.canEditDiscount = this.campService.canEditDiscount();

    this.campWeeks = differenceInCalendarWeeks(this.campService.currentCampInfo.end, this.campService.currentCampInfo.start, { weekStartsOn: 1 }) + 1;

    if (!this.model || !this.model.id)
      this.configureModel();

    if (this.model) {
      if (this.campService.currentCampInfo && this.campService.currentCampInfo.quote_discount_early_bird_date) {
        this.quote_discount_early_bird_date_parsed = parseISO(this.campService.currentCampInfo.quote_discount_early_bird_date);
      }

      // aggiungo l'id del camp corrente
      this.model.camp_id = this.campService.currentCampId;

      // aggiungo le informazioni per il CashFlow
      this.model.addin_enrollment_fee = false;
      this.model.addin_course_fee = false;

      if (this.model.price_quota_associativa > 0) {
        this.model.addin_enrollment_fee_value = this.model.price_quota_associativa;
        this.model.addin_enrollment_fee_registered_via = 7
      }
      else {
        if (this.model.quote_enrollment_fee > 0)
          this.model.addin_enrollment_fee_value = this.model.quote_enrollment_fee;
        else
          this.model.addin_enrollment_fee_value = 50;
      }

      this.updateCourseFeeWithDiscount();
    }
  }


  configureModel() {
    this.model = {};
  }




  /*
   _____ ____  __  __ __  __  ____  _   _   _____        _____ _______ _____
  / ____/ __ \|  \/  |  \/  |/ __ \| \ | | |  __ \ /\   |  __ \__   __/ ____|
 | |   | |  | | \  / | \  / | |  | |  \| | | |__) /  \  | |__) | | | | (___
 | |   | |  | | |\/| | |\/| | |  | | . ` | |  ___/ /\ \ |  _  /  | |  \___ \
 | |___| |__| | |  | | |  | | |__| | |\  | | |  / ____ \| | \ \  | |  ____) |
  \_____\____/|_|  |_|_|  |_|\____/|_| \_| |_| /_/    \_\_|  \_\ |_| |_____/

*/





  submit() {

    if (!this.currentForm.form.valid) return;

    this.model.ids = [];

    this.selectedSubscribers.forEach(element => {
      this.model.ids.push(element.id);
    });

    this.campService.updateMultiCampSubscriber(this.model, () => {
      this.formSaved.emit(this.model);
      this.primanotaService.getPrimaNota('App\\Camp', this.campService.currentCampId);
    });
  }

  suggestEarlyBirdDiscount: boolean = false;

  updateCourseFeeWithDiscount() {

    if (this.campService.currentCampInfo == null)
      return;

    let num_weeks = 1;
    let camp = this.campService.currentCampInfo;

    let course_fee = camp['quote_course_fee_w' + num_weeks];

    if (this.model.enrollment_fee_amount)
      course_fee -= this.model.enrollment_fee_amount;

    if (this.model.quote_discount_1) {
      course_fee -= camp.quote_discount_1
    }

    if (this.model.quote_discount_2) {
      course_fee -= camp.quote_discount_2
    }

    if (this.model.quote_discount_3) {
      course_fee -= camp.quote_discount_3
    }

    if (this.model.quote_discount_4) {
      course_fee -= camp.quote_discount_4
    }

    if (this.model.quote_discount_early_bird) {
      course_fee -= camp.quote_discount_early_bird
      // this.suggestEarlyBirdDiscount = false;
    }
    else {
      if (isBefore(this.model.registration_date, this.campService.currentCampInfo.quote_discount_early_bird_date)) {
        this.suggestEarlyBirdDiscount = true;
      }
      else {
        this.suggestEarlyBirdDiscount = false;
      }
    }

    if (this.model.quote_discount_free == true) {
      course_fee = 0
    }

    if (this.model.quote_discount_custom > 0) {

      if (this.model.quote_discount_custom > course_fee)
        this.model.quote_discount_custom = course_fee;

      course_fee -= parseFloat(this.model.quote_discount_custom);
    }

    // sottraggo un eventuale valore già pagato
    if (this.model.course_fee_amount > 0) {
      course_fee -= this.model.course_fee_amount;
    }

    // aggiorno il prezzo del corso
    this.model.addin_course_fee_value = course_fee;

  }

  getUserService(): UserService {
    return this.userService;
  }

  getCampService(): CampService {
    return this.campService
  }

  getHelper() {
    return this.helper
  }

  getConfigService() {
    return this.configService
  }


}
