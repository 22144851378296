<ngx-datatable class="striped" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
  [rowClass]="getRowClass" [selected]="selected" (activate)="onActivate($event)"
  [sorts]="[{prop: 'date', dir: 'desc'}]">

  <ngx-datatable-column name="Status" prop="status_value"></ngx-datatable-column>
  <ngx-datatable-column name="Date" prop="date_formatted">
    <ng-template let-column="column" ngx-datatable-header-template>
      <i class="fa fa-calendar"> </i>
      <label class="control-label bold">
        {{column.name}} </label>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Camp" prop="camp_description"></ngx-datatable-column>
  <ngx-datatable-column name=" " prop="id">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <button *ngIf="row['agreement_id']" type="button" class="btn blue" (click)="onViewAgreement(value)"><i class="fa fa-eye"></i>&nbsp; Agreement</button>
      <button *ngIf="!row['agreement_id'] && row['status'] == 1 && selected.length == 0" type="button" class="btn green" (click)="newAgreement([row['id']]);">
        <i class="fa fa-plus"></i>&nbsp; Agreement</button>
        <button type="button" class="btn blue" (click)="editCamp(row['id']);">
            <i class="fa fa-edit"></i>&nbsp; View Camp</button>
    </ng-template>
  </ngx-datatable-column>

</ngx-datatable>
