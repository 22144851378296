import { ConfigService } from './../../_services/config.service';
import { UserService, HelperService } from 'app/_services';
import { CampService } from './../../_services/camp.service';
import { Component, OnInit } from '@angular/core';
import { parseISO } from 'date-fns';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-camp-reports',
  templateUrl: './camp-reports.component.html',
  styleUrls: ['./camp-reports.component.css']
})
export class CampReportsComponent implements OnInit {

  constructor(private campService: CampService, private userService: UserService,
              private configService: ConfigService, private helper: HelperService) { }



  ngOnInit() {
  }

  getCampService() {
    return this.campService
  }

  getUserService() {
    return this.userService
  }

  getConfigService() {
    return this.configService;
  }

}
