import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';

declare var saveAs: any;

@Injectable()
export class PersonService {

  currentPersonId;
  currentPersonInfo: any;

  currentPersonContactList: any;
  currentPersonRoleList: any;
  currentPersonAddressList: any;
  currentPersonBankAccountList: any;
  currentPersonAccount: any;
  currentPersonFamily: any;
  currentPersonDocumentList: any;
  currentPersonDiaryList: any;
  currentPersonTutor: any;
  currentPersonAvailability: any;
  currentPersonAssignments: any[] = [];
  currentPersonCampTransferDetailList: any;

  idDefaultRoleType: any;

  ongoingRequest: boolean = false;

  //	Memorizza l'ultima query di ricerca fatta
  lastSearchQueries: any = null;


  private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

  constructor(private backend: BackendService, private notify: NotificationsService, private http: HttpClient) { }

  getsubjectToUpdateObservable(): Observable<boolean> {
    return this.subjectToUpdate.asObservable();
  }

  resetService() {
    this.currentPersonId = null;
    this.currentPersonInfo = null;
    this.currentPersonContactList = null;
    this.currentPersonRoleList = null;
    this.currentPersonAddressList = null;
    this.currentPersonBankAccountList = null;
    this.currentPersonDocumentList = null;
    this.currentPersonDiaryList = null;
    this.currentPersonAccount = null;
    this.currentPersonFamily = null;
    this.idDefaultRoleType = null;
    this.currentPersonTutor = null;
    this.currentPersonAvailability = null
    this.currentPersonAssignments = null;
    this.currentPersonCampTransferDetailList = null;
  }

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  isOngoingRequest() {
    return this.ongoingRequest;
  }

  public MAX_NUM_OF_EXPORT = 150000;

  checkPrimary(model: any, modelList: any[]) {

    if (!model.primary || model.primary == 0) {

      let primaryFound = false;

      for (let k in modelList) {
        let entry = modelList[k];

        if (entry.primary == true || entry.primary == 1) {
          primaryFound = true;
        }
      }

      if (!primaryFound)
        return false;
    }

    return true;
  }



  /* API di esportazione di tutti i campi */
  exportAll(model: any, callbackSuccess: any = null, callbackError: any = null) {

    let params = new HttpParams();
    params = params.set('page_size', '-1');

    this.backend.get('person/export/' + model.simpleSearchKeyword, params).subscribe(
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      }
    );

  }

  exportAllAdvanced(model: any, callbackSuccess: any = null, callbackError: any = null) {

    let params = new HttpParams();
    params = params.set('page_size', '-1');

    this.backend.post('person/export/advanced', model, params).subscribe(
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      }
    );
  }



  getPersonInfoAll(callbackSuccess: any = null, callbackError: any = null) {

    // this.startRequest();

    let params = new HttpParams();

    this.backend.get('person/' + this.currentPersonId + '/all', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.currentPersonInfo = resJson;
        this.currentPersonId = resJson.id;
        this.currentPersonContactList = resJson.contact;
        this.currentPersonRoleList = resJson.role;
        this.currentPersonAddressList = resJson.address;
        this.currentPersonBankAccountList = resJson.bankaccount;
        this.currentPersonDocumentList = resJson.document;
        this.currentPersonDiaryList = resJson.diary;
        this.currentPersonAccount = resJson.account;
        this.currentPersonFamily = resJson.family;
        this.currentPersonTutor = resJson.tutor;
        this.currentPersonAvailability = resJson.availability;
        this.currentPersonAssignments = resJson.assignments;
        this.currentPersonCampTransferDetailList = resJson.camptransferdetail;
        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }


  //  Carica tutte le informazioni su una persona senza passare dal service
  getPersonInfoAllSummary(personId, callbackSuccess: any = null, callbackError: any = null) {

    let params = new HttpParams();

    this.backend.get('person/' + personId + '/all', params).subscribe(
      (response) => {

        let resJson : any = response;

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      });
  }



  savePersonInfo(model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.post('person', model).subscribe(
      (response) => {

        let resJson : any = response;
        this.currentPersonInfo = resJson;
        this.currentPersonId = resJson.id;
        // this.currentPersonContactList = resJson.contact;
        // this.currentPersonRoleList = resJson.role;
        // this.currentPersonAddressList = resJson.address;
        // this.currentPersonBankAccountList = resJson.bankaccount;
        // this.currentPersonDocumentList = resJson.document;
        // this.currentPersonAccount = resJson.account;
        // this.currentPersonFamily = resJson.family;
        // this.currentPersonCampTransferDetailList = resJson.camptransferdetail;
        this.subjectToUpdate.next(true);

        this.notify.success("Personal Info", "successfully saved");

        this.finishRequest();

        this.savePersonRole({ "type": this.idDefaultRoleType, "primary": true });

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  updatePersonInfo(model: any[]) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId, model).subscribe(
      (response: any) => {

        this.currentPersonId = response.id;

        //	Faccio partire una richiesta con tutte le info della persona che altrimenti non mi arriverebbero
        this.getPersonInfoAll(() => {
          this.notify.success("Personal Info", "successfully updated");
        });

        // this.subjectToUpdate.next(true);

        // this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      });
  }

  deletePersonInfo(model: any, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId).subscribe(
      (response) => {

        this.resetService();

        this.notify.success("Person Info", "successfully deleted");

        this.finishRequest();

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }




  savePersonRole(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/role', model).subscribe(
      (response) => {

        var roleList : any = response;

        if (this.currentPersonRoleList == null)
          this.currentPersonRoleList = {};

        this.currentPersonRoleList = roleList;
        this.subjectToUpdate.next(true);

        this.notify.success("Role", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updatePersonRole(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId + '/role/' + model.id, model).subscribe(
      (response) => {

        var roleList : any = response;

        if (this.currentPersonRoleList == null)
          this.currentPersonRoleList = {};

        this.currentPersonRoleList = roleList;
        this.subjectToUpdate.next(true);

        this.notify.success("Role", "successfully updated");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deletePersonRole(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/role/' + model.id).subscribe(
      (response) => {

        var roleList : any = response;

        if (this.currentPersonRoleList == null)
          this.currentPersonRoleList = {};

        this.currentPersonRoleList = roleList;
        this.subjectToUpdate.next(true);

        this.notify.success("Role", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }








  savePersonContactAll(model: any) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/contact/all', model).subscribe(
      (response) => {

        var contactList : any = response;

        if (this.currentPersonContactList == null)
          this.currentPersonContactList = {};

        this.currentPersonContactList = contactList;
        this.subjectToUpdate.next(true);

        this.notify.success("Contact", "successfully saved");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }


  savePersonContact(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/contact', model).subscribe(
      (response) => {

        var contactList : any = response;

        if (this.currentPersonContactList == null)
          this.currentPersonContactList = {};

        this.currentPersonContactList = contactList;
        this.subjectToUpdate.next(true);

        this.notify.success("Contact", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updatePersonContact(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId + '/contact/' + model.id, model).subscribe(
      (response) => {

        var contactList : any = response;

        if (this.currentPersonContactList == null)
          this.currentPersonContactList = {};

        this.currentPersonContactList = contactList;
        this.subjectToUpdate.next(true);

        this.notify.success("Contact", "successfully updated");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deletePersonContact(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/contact/' + model.id).subscribe(
      (response) => {

        var contactList : any = response;

        if (this.currentPersonContactList == null)
          this.currentPersonContactList = {};

        this.currentPersonContactList = contactList;
        this.subjectToUpdate.next(true);

        this.notify.success("Contact", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }








  savePersonBankAccount(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/bankaccount', model).subscribe(
      (response) => {

        var bankAccountList : any = response;

        if (this.currentPersonBankAccountList == null)
          this.currentPersonBankAccountList = {};

        this.currentPersonBankAccountList = bankAccountList;
        this.subjectToUpdate.next(true);

        this.notify.success("Bank Account", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updatePersonBankAccount(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId + '/bankaccount/' + model.id, model).subscribe(
      (response) => {

        var bankAccountList : any = response;

        if (this.currentPersonBankAccountList == null)
          this.currentPersonBankAccountList = {};

        this.currentPersonBankAccountList = bankAccountList;
        this.subjectToUpdate.next(true);

        this.notify.success("Bank Account", "successfully updated");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deletePersonBankAccount(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/bankaccount/' + model.id).subscribe(
      (response) => {

        var bankAccountList : any = response;

        if (this.currentPersonBankAccountList == null)
          this.currentPersonBankAccountList = {};

        this.currentPersonBankAccountList = bankAccountList;
        this.subjectToUpdate.next(true);

        this.notify.success("Bank Account", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }







  savePersonAddress(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/address', model).subscribe(
      (response) => {

        var addressList : any = response;

        if (this.currentPersonAddressList == null)
          this.currentPersonAddressList = {};

        this.currentPersonAddressList = addressList;
        this.subjectToUpdate.next(true);

        this.notify.success("Address", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updatePersonAddress(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId + '/address/' + model.id, model).subscribe(
      (response) => {

        var addressList : any = response;

        if (this.currentPersonAddressList == null)
          this.currentPersonAddressList = {};

        this.currentPersonAddressList = addressList;
        this.subjectToUpdate.next(true);

        this.notify.success("Address", "successfully updated");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deletePersonAddress(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/address/' + model.id).subscribe(
      (response) => {

        var addressList : any = response;

        if (this.currentPersonAddressList == null)
          this.currentPersonAddressList = {};

        this.currentPersonAddressList = addressList;
        this.subjectToUpdate.next(true);

        this.notify.success("Address", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }






  savePersonAccount(model: any) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/account', model).subscribe(
      (response) => {

        var account : any = response;

        if (this.currentPersonAccount == null)
          this.currentPersonAccount = {};

        this.currentPersonAccount = account;
        this.subjectToUpdate.next(true);

        this.notify.success("User Account", "successfully saved");

        //	Faccio partire una richiesta con tutte le info della persona che altrimenti lo user_id rimane vuoto
        this.getPersonInfoAll(() => {
          this.notify.success("Personal Info", "successfully updated");
        });
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  updatePersonAccount(model: any) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId + '/account/' + model.id, model).subscribe(
      (response) => {

        var account : any = response;

        if (this.currentPersonAccount == null)
          this.currentPersonAccount = {};

        this.currentPersonAccount = account;
        this.subjectToUpdate.next(true);

        this.notify.success("User Account", "successfully updated");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  deletePersonAccount(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/account/' + model.id).subscribe(
      (response) => {

        if (this.currentPersonAccount == null)
          this.currentPersonAccount = {};

        this.currentPersonAccount = {};
        this.subjectToUpdate.next(true);

        this.notify.success("User Account", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }



  savePersonDocument(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/document', model).subscribe(
      (response) => {

        var documents : any = response;

        if (this.currentPersonDocumentList == null)
          this.currentPersonDocumentList = {};

        this.currentPersonDocumentList = documents;
        this.subjectToUpdate.next(true);

        this.notify.success("Document", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updatePersonDocument(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId + '/document/' + model.id, model).subscribe(
      (response) => {

        var documents : any = response;

        if (this.currentPersonDocumentList == null)
          this.currentPersonDocumentList = {};

        this.currentPersonDocumentList = documents;
        this.subjectToUpdate.next(true);

        this.notify.success("Document", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deletePersonDocument(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/document/' + model.id).subscribe(
      (response) => {

        var document : any = response;

        if (this.currentPersonDocumentList == null)
          this.currentPersonDocumentList = {};

        this.currentPersonDocumentList = document;
        this.subjectToUpdate.next(true);

        this.notify.success("Document", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  getPersonAvatarFileUploadURL(model: any) {
    return (environment.apiUrl + 'person/' + this.currentPersonId + '/upload');
  }
  getPersonSignatureFileUploadURL(model: any) {
    return (environment.apiUrl + 'person/' + this.currentPersonId + '/uploadsignature');
  }

  getPersonAvatarFileUlopadHeader() {
    return this.backend.setupAuthHttpHeaders().headers;
  }

  private avatarToUpdate: Subject<string> = new Subject<string>();
  getUpdateUserObservable(): Observable<string> {
    return this.avatarToUpdate.asObservable();
  }



  public personAvatarFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Avatar File', JSON.parse(response)['message']);
      else
        this.notify.error('Avatar File', response);

      this.backend.showErrors(response);
    }
  }


  public personSignatureFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Signature File', JSON.parse(response)['message']);
      else
        this.notify.error('Signature File', response);

      this.backend.showErrors(response);
    }
  }

  deletePersonAvatarFile(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/destroyFile').subscribe(
      (response) => {

        this.currentPersonInfo = response;
        this.currentPersonId = this.currentPersonInfo.id;
        this.subjectToUpdate.next(true);

        //	aggiorno anche l'immagine dell'utente se è quello corrente
        var jsonUserInfo = JSON.stringify({ userID: model.user_id, avatarURL: "" });
        this.avatarToUpdate.next(jsonUserInfo);

        this.notify.success("Avatar File", "Successfully Deleted");

        this.finishRequest();
      },
      (error) => {
        console.log("Errore");
        console.log(error);

        this.finishRequest();
      }
    );
  }


  deletePersonSignatureFile(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/destroysignature').subscribe(
      (response) => {

        this.currentPersonInfo = response;
        this.currentPersonId = this.currentPersonInfo.id;
        this.subjectToUpdate.next(true);

        this.notify.success("Signature File", "Successfully Deleted");

        this.finishRequest();
      },
      (error) => {
        console.log("Errore");
        console.log(error);

        this.finishRequest();
      }
    );
  }



  getPersonDocumentFileUploadURL(model: any) {
    return (environment.apiUrl + 'person/' + this.currentPersonId + '/document/' + model.id + '/upload');
  }

  public personDocumentFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Document File', JSON.parse(response)['message']);
      else
        this.notify.error('Document File', response);

      this.backend.showErrors(response);
    }
  }

  deletePersonDocumentFile(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/document/' + model.id + '/destroyFile').subscribe(
      (response) => {

        var document : any = response;

        if (this.currentPersonDocumentList == null)
          this.currentPersonDocumentList = {};

        this.currentPersonDocumentList = document;
        this.subjectToUpdate.next(true);

        this.notify.success("Document", "successfully deleted image");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }





  savePersonDiary(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/diary', model).subscribe(
      (response) => {

        var diary : any = response;

        if (this.currentPersonDiaryList == null)
          this.currentPersonDiaryList = {};

        this.currentPersonDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updatePersonDiary(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId + '/diary/' + model.id, model).subscribe(
      (response) => {

        var diary : any = response;

        if (this.currentPersonDiaryList == null)
          this.currentPersonDiaryList = {};

        this.currentPersonDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deletePersonDiary(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/diary/' + model.id).subscribe(
      (response) => {

        var diary : any = response;

        if (this.currentPersonDiaryList == null)
          this.currentPersonDiaryList = {};

        this.currentPersonDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }






  savePersonFamily(model: any) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/family', model).subscribe(
      (response) => {

        var family : any = response;

        if (this.currentPersonFamily == null)
          this.currentPersonFamily = {};

        this.currentPersonFamily = family;
        this.subjectToUpdate.next(true);

        this.notify.success("Person Family", "successfully saved");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  updatePersonFamily(model: any) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId + '/family/' + model.id, model).subscribe(
      (response) => {

        var family : any = response;

        if (this.currentPersonFamily == null)
          this.currentPersonFamily = {};

        this.currentPersonFamily = family;
        this.subjectToUpdate.next(true);

        this.notify.success("Person Family", "successfully updated");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }


  //BEGIN Tutor

  private TUTOR_RESOURCES: String = 'tutor'

  saveTutor(model: any) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/' + this.TUTOR_RESOURCES, model).subscribe(
      (response) => {

        var tutor : any = response;

        if (this.currentPersonTutor == null)
          this.currentPersonTutor = {};

        this.currentPersonTutor = tutor;
        this.subjectToUpdate.next(true);

        this.notify.success("Tutor", "successfully saved");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  updateTutor(model: any) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId + '/' + this.TUTOR_RESOURCES + '/' + model.id, model).subscribe(
      (response) => {

        var tutor : any = response;

        if (this.currentPersonTutor == null)
          this.currentPersonTutor = {};

        this.currentPersonTutor = tutor;
        this.subjectToUpdate.next(true);

        this.notify.success("Tutor", "successfully updated");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  public downloadTutorForm() {
    this.backend.download('person/' + this.currentPersonId + '/tutor/form', this.currentPersonInfo['first_name'] + '_' + this.currentPersonInfo['surname'] + '.pdf');
  }

  public downloadTutorFormPerson(currentId, personInfo) {
    this.backend.download('person/' + currentId + '/tutor/form', personInfo['first_name'] + '_' + personInfo['surname'] + '.pdf');
  }

  //END Tutor

  currentPersonHasRole(typeId: any) {

    var hasRole = false;

    if (this.currentPersonRoleList) {

      var personRoleListKeys = Object.keys(this.currentPersonRoleList).reverse();

      personRoleListKeys.forEach(element => {

        if (this.currentPersonRoleList[element].type == typeId) {
          hasRole = true;
        }

      });
    }
    return hasRole;
  }

  //BEGIN Availability

  private AVAILABILITY_RESOURCES: String = 'availability'

  saveAvailability(model: any) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/' + this.AVAILABILITY_RESOURCES, model).subscribe(
      (response) => {

        var availability : any = response;

        if (this.currentPersonAvailability == null)
          this.currentPersonAvailability = {};

        this.currentPersonAvailability = availability;
        this.subjectToUpdate.next(true);

        this.notify.success("Availability", "successfully saved");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  updateAvailability(model: any) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId + '/' + this.AVAILABILITY_RESOURCES + '/' + model.id, model).subscribe(
      (response) => {

        var availability : any = response;

        if (this.currentPersonAvailability == null)
          this.currentPersonAvailability = {};

        this.currentPersonAvailability = availability;
        this.subjectToUpdate.next(true);

        this.notify.success("Availability", "successfully updated");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }
  //END Availability







  savePersonCampTransferDetail(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('person/' + this.currentPersonId + '/camptransferdetail', model).subscribe(
      (response) => {

        var CampTransferDetailList : any = response;

        if (this.currentPersonCampTransferDetailList == null)
          this.currentPersonCampTransferDetailList = {};

        this.currentPersonCampTransferDetailList = CampTransferDetailList;
        this.subjectToUpdate.next(true);

        this.notify.success("Transfer detail", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updatePersonCampTransferDetail(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('person/' + this.currentPersonId + '/camptransferdetail/' + model.id, model).subscribe(
      (response) => {

        var CampTransferDetailList : any = response;

        if (this.currentPersonCampTransferDetailList == null)
          this.currentPersonCampTransferDetailList = {};

        this.currentPersonCampTransferDetailList = CampTransferDetailList;
        this.subjectToUpdate.next(true);

        this.notify.success("Transfer detail", "successfully updated");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deletePersonCampTransferDetail(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/camptransferdetail/' + model.id).subscribe(
      (response) => {

        var CampTransferDetailList : any = response;

        if (this.currentPersonCampTransferDetailList == null)
          this.currentPersonCampTransferDetailList = {};

        this.currentPersonCampTransferDetailList = CampTransferDetailList;
        this.subjectToUpdate.next(true);

        this.notify.success("Transfer detail", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  getPersonCampStaffMember(callbackSuccess: any = null, callbackError: any = null) {

    let params = new HttpParams();

    this.backend.get('person/' + this.currentPersonId + '/campsstaffmember', params).subscribe(
      (response) => {

        let resJson : any = response;

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }


  getPersonCampTransferDetailFileUploadURL(model: any) {
    return (environment.apiUrl + 'person/' + this.currentPersonId + '/camptransferdetail/' + model.id + '/upload');
  }

  public personCampTransferDetailFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
      this.notify.success("Camp Transfer Detail File", 'successfully uploaded');
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Camp Transfer Detail File', JSON.parse(response)['message']);
      else
        this.notify.error('Camp Transfer Detail File', response);

      this.backend.showErrors(response);
    }
  }

  deletePersonCampTransferDetailFile(model: any) {

    this.startRequest();

    this.backend.destroy('person/' + this.currentPersonId + '/camptransferdetail/' + model.id + '/destroyFile').subscribe(
      (response) => {

        var document : any = response;

        if (this.currentPersonCampTransferDetailList == null)
          this.currentPersonCampTransferDetailList = {};

        this.currentPersonCampTransferDetailList = document;
        this.subjectToUpdate.next(true);

        this.notify.success("Camp Transfer Detail File", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

}
