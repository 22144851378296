import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../environment';

import { NotificationsService } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';

import { saveAs } from 'file-saver';

@Injectable()
export class BackendService {

  constructor(
    private http: HttpClient,
    private notify: NotificationsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  setupAuthHttpHeaders(searchParams: HttpParams = new HttpParams()) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.set("Authorization", "Bearer " + localStorage.getItem("Api-Token"));

    let options: any = { headers: headers, responseType: 'json', observe: 'body', params: searchParams};

    return options;
  }

  get(resource: string, searchParams: HttpParams) {

    let options = this.setupAuthHttpHeaders(searchParams);

    return this.http.get(environment.apiUrl + resource, options);
  }


  post(resource: string, body: any[], searchParams: HttpParams = null) {

    let options = this.setupAuthHttpHeaders(searchParams);

    return this.http.post(environment.apiUrl + resource, JSON.stringify(body), options);
  }

  destroy(resource: string) {

    let options = this.setupAuthHttpHeaders();

    return this.http.delete(environment.apiUrl + resource, options);
  }

  put(resource: string, body: any[]) {

    let options = this.setupAuthHttpHeaders();

    return this.http.put(environment.apiUrl + resource, JSON.stringify(body), options);
  }

  download(resource: string, filename: string) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.set("Authorization", "Bearer " + localStorage.getItem("Api-Token"));

    let options: any = { headers: headers, responseType: 'blob'};

    this.http.get(environment.apiUrl + resource, options).subscribe(
      (response: any) => {
        var blob = new Blob([response], {type: response.type});
        saveAs(blob, filename);
      },
      (error) => {
        this.showErrors(error);
      }
    );
  }

  showErrors(error: any) {

    if (error == null || !error.status) {
      this.notify.error("Backend ERROR", "No error message, please contact the support.");
      return;
    }

    // controllo se è un errore di validazione
    if (error.status == 422) {

      var errorJson = error;

      this.notify.error("ERROR", errorJson.message);

      if (errorJson.error && errorJson.error.message)
      {
        this.notify.error("ERROR", errorJson.error.message);
      }

      for (var key in errorJson.errors) {
        var value = errorJson.errors[key];
        this.notify.error(key.toUpperCase(), value);
      }
    }
    else if (error.status == 404) {
      this.notify.warn("Resource not found", "what you were looking for was not found");
    }
    else if (error.status == 401 || (error.message && error.message.indexOf("Unauthenticated")) > 0) {
      this.notify.warn("Unauthorized", "Session expired or there is a new update available, try to login again.");
      this.router.navigate(["/login"]);
    }
    else {
      console.error("Backend error", error);
      this.notify.error("Error " + error.status + " " + error.statusText, "backend error, check the console for debug information");
    }

  }
}
