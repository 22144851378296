<!-- BEGIN CONTENT BODY -->
<div class="page-content">
    <div class="row">
      <div class="col-md-12">

        <!-- BEGIN PAGE HEADER-->
        <!-- BEGIN PAGE BAR -->
        <div class="page-bar">
          <ul class="page-breadcrumb">
            <li>
              <a href="javascript:;" [routerLink]="['/home']">Home</a>
              <i class="fa fa-circle"></i>
            </li>
            <li>
               <span>Agenda</span>
            </li>
          </ul>
          <div class="page-toolbar">

          </div>
        </div>
        <!-- BEGIN PAGE TITLE-->
        <!-- <h1 class="page-title">Agenda</h1> -->
        <!-- END PAGE TITLE-->
        <!-- END PAGE HEADER-->

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">

              <div class="portlet-title tabbable-line">
                <div class="caption caption-md">
                  <i class="icon-globe theme-font hide"></i>
                  <span class="caption-subject font-blue-madison bold uppercase">Agenda</span>
                </div>
              </div>

              <!--end: Portlet-Body -->
              <div class="portlet-body form">

                  <app-reminder-widget-agenda [showFutureReminder]="true" [showCompletedReminder]="true"></app-reminder-widget-agenda>

              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
        <!--end: prima riga -->
      </div>
    </div>
  </div>
  <!-- END CONTENT BODY -->
