<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">

    <div class="row">
      <div class="col-md-4">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || denomination.dirty || denomination.touched) && !denomination.valid }">
          <label class="control-label">Show Name
            <span class="required">*</span>
          </label>
          <input type="text" name="denomination" [(ngModel)]="model.denomination" #denomination="ngModel" required class="form-control"
            placeholder="">
          <span class="help-block">Show name is required </span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
          <label class="control-label">Type
            <span class="required">*</span>
          </label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
            <option *ngFor="let s of getConfigService().showTypeSelect" [value]="s.key">{{s.value}}</option>
          </select>
          <span class="help-block">Select show type, type is required</span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || duration.dirty || duration.touched) && !duration.valid }">
          <label class="control-label">Default Duration
          </label>
          <input type="text" name="duration" [(ngModel)]="model.duration" #duration="ngModel" class="form-control" placeholder=""
            digits autocomplete="off">
          <span class="help-block"> Duration in minutes</span>
          <span *ngIf="duration.errors?.digits" class="help-block">Only positive</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Attivo</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="active" [(ngModel)]="model.active" value="1"> Yes
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-md-12" *ngIf="audienceModel != null">
        <div class="form-group">
          <label class="control-label"> Audience</label>
          <br>
          <div class="btn-group">
            <label *ngFor="let c of getConfigService().showAudienceSelect; let i=index" name="{{c.key}} | i" [(ngModel)]="audienceModel[i]"
              class="btn btn-primary" (ngModelChange)="onChangeAudience($event)" btnCheckbox>{{c.value}}</label>
          </div>
          <span class="help-block"> Select Audience type</span>
        </div>
      </div>

    </div>

    <div class="row hide">
      <div class="col-md-4">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || workshop_type.dirty || workshop_type.touched) && !workshop_type.valid }">
          <label class="control-label">Default Workshop type
          </label>
          <select class="form-control" name="workshop_type" [(ngModel)]="model.workshop_type" #workshop_type="ngModel">
            <option *ngFor="let w of getConfigService().workshopTypeSelect" [value]="w.key">{{w.value}}</option>
          </select>
          <span class="help-block"> Select workshop type, optional</span>
        </div>
      </div>
    </div>

  </div>

  <div class="form-actions right">
    <img *ngIf="getShowService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getShowService().isOngoingRequest()" *ngIf="getShowService().currentShowId"
      [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
      <i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="submit" class="btn blue" [disabled]="getShowService().isOngoingRequest()" *ngIf="getShowService().currentShowId">Update</button>
    <button type="submit" class="btn green-jungle" [disabled]="getShowService().isOngoingRequest()" *ngIf="!getShowService().currentShowId">Continue</button>
  </div>

</form>
