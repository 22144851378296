import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import { getTranslationProviders } from './app/i18n-providers';

// import { TRANSLATIONS, TRANSLATIONS_FORMAT, LOCALE_ID  } from '@angular/core';
// import { TRANSLATION_SV } from './locale/messages.it';

if (environment.production) {
    enableProdMode();
}
// platformBrowserDynamic().bootstrapModule(AppModule, {
//     providers: [
//       {provide: TRANSLATIONS, useValue: TRANSLATION_SV},
//       {provide: TRANSLATIONS_FORMAT, useValue: "xlf"},
//       {provide: LOCALE_ID, useValue:'it'}
//     ]
// });

// getTranslationProviders().then(providers => {
//   const options = { providers };
//   platformBrowserDynamic().bootstrapModule(AppModule, options);
// });

platformBrowserDynamic().bootstrapModule(AppModule);
