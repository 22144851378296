<button type="button" class="btn green btn-block btn-outline" (click)="addNew();" [appCheckPermission]="'camp_edit'"><i class="fa fa-plus"></i>&nbsp; New
  Subscriber</button>

<div class="datatable-loader text-center" *ngIf="getCampService().isOngoingRequest()">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>

<br>

<tabset [justified]="true">

  <!-- TAB INFO -->

  <tab heading="Info">
    <div class="portlet">

      <div class="portlet-body">
        <div *ngIf="!getCampService().isOngoingRequest()">

          <ngx-datatable class="hidden-empty-datatable" [rows]="rows" [columnMode]="'flex'" [headerHeight]="40"
            [footerHeight]="0" [rowHeight]="0">
            <ngx-datatable-column name="" [flexGrow]="3" prop="person_info.surname">
              <ng-template ngx-datatable-header-template>
                <input type="text" class="form-control metro-design" [(ngModel)]="modelFiltering.nome"
                  (input)="filteringChange($event.target)" placeholder="Type to filter names">
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="" [flexGrow]="1" prop="person_info.birthday">
            </ngx-datatable-column>
            <ngx-datatable-column name="" [flexGrow]="1" prop="person_info.gender">
              <ng-template ngx-datatable-header-template>
                <select class="form-control" [(ngModel)]="modelFiltering.gender"
                  (change)="filteringChange($event.target)">
                  <option [value]=""></option>
                  <option *ngFor="let g of getConfigService().genderPersonSelect" [value]="g.key">{{g.value}}</option>
                </select>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="" [flexGrow]="2" prop="person_info.tshirt_size">
              <ng-template ngx-datatable-header-template>
                <select class="form-control" [(ngModel)]="modelFiltering.tshirt"
                  (change)="filteringChange($event.target)">
                  <option *ngFor="let sS of getConfigService().shirtSizePersonSelect" [value]="sS.key">{{sS.value}}
                  </option>
                </select>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="" [flexGrow]="4" prop="school_type">
              <ng-template ngx-datatable-header-template>
                <input type="text" class="form-control metro-design" [(ngModel)]="modelFiltering.school"
                  (input)="filteringChange($event.target)" placeholder="Type comma separated">
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="" prop="camp_week" [flexGrow]="3">
              <ng-template ngx-datatable-header-template>
                <input type="text" class="form-control metro-design" [(ngModel)]="modelFiltering.week"
                  (input)="filteringChange($event.target)" placeholder="Type comma separated">
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="" prop="" [flexGrow]="2">
            </ngx-datatable-column>
          </ngx-datatable>


          <ngx-datatable class="striped" [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="120"
            [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'" [rowClass]="getRowClass"
            (activate)="onActivate($event)" (select)='onSelect($event)'
            [sorts]="[{prop: 'person_info.surname', dir: 'asc'}]">
            <ngx-datatable-column name="Nome" [flexGrow]="3" prop="person_info.surname" [sortable]="true"
              [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}} {{row.person_info.first_name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Età" [flexGrow]="1" prop="person_info.birthday" [sortable]="true"
              [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getHelper().calculateAge(row.person_info.birthday)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Sesso" [flexGrow]="1" prop="person_info.gender" [sortable]="true"
              [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getConfigService().getGenderPersonValueByKey(row.person_info.gender)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Taglia" [flexGrow]="2" prop="person_info.tshirt_size" [sortable]="true"
              [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ getConfigService().getShirtSizePersonValueByKey(value) }}
              </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column name="Classe" [comparator]="classSort.bind()" [flexGrow]="4" prop="school_type" -->
            <ngx-datatable-column name="Classe" [flexGrow]="4" prop="school_type"
              [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getConfigService().getDiaryCampSubscriberSchoolTypeValueByKey(row.school_type)}} - classe:
                {{getConfigService().getDiaryCampSubscriberSchoolTypeSubValueByKey(row.school_type, row.class_type)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Settimane" prop="camp_week" [flexGrow]="3" [sortable]="true"
              [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngFor="let index of value">
                  {{index}}&ordf;&nbsp;
                </ng-container>
                <i tooltip="Host week" *ngIf="row.host_week != null && row.host_week.length > 0" class="fa fa-home"></i>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Cash Flow" prop="" [flexGrow]="2" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <button *ngIf="value" type="button" class="no-button" [popover]="quoteSubscriberTemplate"
                  popoverTitle="Quote {{ row.course_fee_payed }}/{{ row.course_fee_total }} ({{row.total_fee_amount}})"
                  container="body" triggers="mouseenter:mouseleave" placement="left">
                  <span *ngIf="row.enrollment_fee"><i [ngClass]="{'font-red': (row.enrollment_fee_verified == 0)}"
                      class="fa fa-user-plus"></i></span>
                  <span *ngIf="row.course_fee"><i [ngClass]="{'font-red': (row.course_fee_verified == 0)}"
                      class="fa fa-graduation-cap"></i></span>
                  <span *ngIf="row.course_fee_payed && row.course_fee_total > 0"
                    [ngClass]="{'font-red': (row.course_fee_payed < row.course_fee_total)}">{{ row.course_fee_payed }}/{{ row.course_fee_total }}</span>
                  <span *ngIf="!row.course_fee_payed && row.course_fee_total > 0"
                    class="font-red">0/{{ row.course_fee_total }}</span>
                  <span *ngIf="row.course_fee_overfee" class="font-red"> <i class="fa fa-exclamation-circle"
                      aria-hidden="true"></i> ({{ row.course_fee_overfee }})</span>
                  <span *ngIf="row.course_fee_total == 0" class="font-green">FREE</span>
                </button>
                <ng-template #quoteSubscriberTemplate>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group read-only">
                        <label class="control-label">Enrollment Fee</label>
                        <p><i *ngIf="row.enrollment_fee_verified" class="fa fa-check-circle" aria-hidden="true"
                            style="color:green"></i>
                          {{ (row.enrollment_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group read-only">
                        <label class="control-label">Course Fee</label>
                        <p><i *ngIf="row.course_fee_verified" class="fa fa-check-circle" aria-hidden="true"
                            style="color:green"></i>
                          {{ (row.course_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group read-only">
                        <label class="control-label">Refound</label>
                        <p>{{ (row.refound_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let discount of row.active_discount" class="row">
                    <div class="col-md-12">
                      <div class="form-group read-only">
                        <label class="control-label">{{ discount.label }}</label>
                        <p>-{{ (discount.value | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Remaning" prop="remaning_to_pay" [flexGrow]="2" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ (row.remaning_to_pay | currency:'EUR':'symbol':'1.2-2':'it') || "€0.00"}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
                <div class="row" style="margin:5px">
                  <hr>
                  Total Subscribers: <b> {{rowCount}} </b> <br>
                  <span *ngIf="getCampService().numOfCampWeeks > 0"> week 1 : <b> {{getSubs(1)}} </b> </span> <br>
                  <span *ngIf="getCampService().numOfCampWeeks > 1"> week 2 : <b> {{getSubs(2)}} </b> </span> <br>
                  <span *ngIf="getCampService().numOfCampWeeks > 2"> week 3 : <b> {{getSubs(3)}} </b> </span>
                </div>
              </ng-template>
            </ngx-datatable-footer>

          </ngx-datatable>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="pull-right">
        <button *ngIf="getUserService().checkPermission('camp_subscribers_export') || getCampService().isCurrentUserPersonCampDirector()"
          [disabled]="getCampService().isOngoingRequest() || rows?.length <= 0" type="button" class="btn green"
          (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button>
      </div>
    </div>

    <div class="portlet">
      <div class="portlet-body">
        <div *ngIf="!getCampService().isOngoingRequest()">

          <h3>Ritirati (Deleted)</h3>

          <ngx-datatable class="striped" [rows]="rowsNotActive" [columnMode]="'flex'" [headerHeight]="50"
            [footerHeight]="120" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'"
            [rowClass]="getRowClass" (activate)="onActivate($event)" (select)='onSelect($event)'
            [sorts]="[{prop: 'person_info.surname', dir: 'asc'}]">
            <ngx-datatable-column name="Nome" [flexGrow]="3" prop="person_info.surname" [sortable]="true"
              [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}} {{row.person_info.first_name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Età" [flexGrow]="1" prop="person_info.birthday" [sortable]="true"
              [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getHelper().calculateAge(row.person_info.birthday)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Sesso" [flexGrow]="1" prop="person_info.gender" [sortable]="true"
              [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getConfigService().getGenderPersonValueByKey(row.person_info.gender)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Taglia" [flexGrow]="2" prop="person_info.tshirt_size" [sortable]="true"
              [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ getConfigService().getShirtSizePersonValueByKey(value) }}
              </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column name="Classe" [comparator]="classSort.bind(this)" [flexGrow]="4" prop="school_type" -->
            <ngx-datatable-column name="Classe" [flexGrow]="4" prop="school_type"
              [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getConfigService().getDiaryCampSubscriberSchoolTypeValueByKey(row.school_type)}} - classe:
                {{getConfigService().getDiaryCampSubscriberSchoolTypeSubValueByKey(row.school_type, row.class_type)}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Settimane" prop="camp_week" [flexGrow]="3" [sortable]="true"
              [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngFor="let index of value">
                  {{index}}&ordf;&nbsp;
                </ng-container>
                <i tooltip="Host week" *ngIf="row.host_week != null && row.host_week.length > 0" class="fa fa-home"></i>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Cash Flow" prop="" [flexGrow]="2" [sortable]="false" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <button *ngIf="value" type="button" class="no-button" [popover]="quoteSubscriberTemplate"
                  popoverTitle="Quote {{ row.course_fee_payed }}/{{ row.course_fee_total }} ({{row.total_fee_amount}})"
                  container="body" triggers="mouseenter:mouseleave" placement="left">
                  <span *ngIf="row.enrollment_fee"><i [ngClass]="{'font-red': (row.enrollment_fee_verified == 0)}"
                      class="fa fa-user-plus"></i></span>
                  <span *ngIf="row.course_fee"><i [ngClass]="{'font-red': (row.course_fee_verified == 0)}"
                      class="fa fa-graduation-cap"></i></span>
                  <span *ngIf="row.course_fee_payed && row.course_fee_total > 0"
                    [ngClass]="{'font-red': (row.course_fee_payed < row.course_fee_total)}">{{ row.course_fee_payed }}/{{ row.course_fee_total }}</span>
                  <span *ngIf="!row.course_fee_payed && row.course_fee_total > 0"
                    class="font-red">0/{{ row.course_fee_total }}</span>
                  <span *ngIf="row.course_fee_overfee" class="font-red"> <i class="fa fa-exclamation-circle"
                      aria-hidden="true"></i> ({{ row.course_fee_overfee }})</span>
                  <span *ngIf="row.course_fee_total == 0" class="font-green">FREE</span>
                </button>
                <ng-template #quoteSubscriberTemplate>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group read-only">
                        <label class="control-label">Enrollment Fee</label>
                        <p><i *ngIf="row.enrollment_fee_verified" class="fa fa-check-circle" aria-hidden="true"
                            style="color:green"></i>
                          {{ (row.enrollment_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group read-only">
                        <label class="control-label">Course Fee</label>
                        <p><i *ngIf="row.course_fee_verified" class="fa fa-check-circle" aria-hidden="true"
                            style="color:green"></i>
                          {{ (row.course_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group read-only">
                        <label class="control-label">Refound</label>
                        <p>{{ (row.refound_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let discount of row.active_discount" class="row">
                    <div class="col-md-12">
                      <div class="form-group read-only">
                        <label class="control-label">{{ discount.label }}</label>
                        <p>-{{ (discount.value | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Remaning" prop="remaning_to_pay" [flexGrow]="2" [sortable]="true" [resizeable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ (row.remaning_to_pay | currency:'EUR':'symbol':'1.2-2':'it') || "€0.00"}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
                <div class="row" style="margin:5px">
                  <hr>
                  Total Subscribers: <b> {{rowCount}} </b>
                </div>
              </ng-template>
            </ngx-datatable-footer>

          </ngx-datatable>
        </div>
      </div>
    </div>
  </tab>




  <!-- TAB QUOTE -->


  <tab heading="Fee and Discount">

    <div *ngIf="!getCampService().isOngoingRequest()">

      <div *ngIf="selectedFeeDiscount && selectedFeeDiscount.length > 0" class="row">
        <div class="col-md-12">
          <button *ngIf="selectedFeeDiscount.length > 0" [appCheckPermission]="'camp_edit'" class="blue btn default btn-block"
            (click)="multipleEditSelected()"><i class="fa fa-edit" aria-hidden="true"></i> Edit {{selectedFeeDiscount.length}}
            subscribers</button>
          <br>
          <alert type="warning" *ngIf="selectedFeeDiscount.length > 0">
            <strong>Attenzione:</strong> tutti gli sconti per i subscribers selezionati verranno sovrascritti,
            quindi se prima un subscriber aveva uno sconto e nella finestra di modifica degli sconti non viene
            selezionato, lo sconto verrà
            rimosso
          </alert>
        </div>
      </div>

      <ngx-datatable class="striped" [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="160"
        [rowHeight]="'auto'" [selected]="selectedFeeDiscount" [selectionType]="'checkbox'" [rowClass]="getRowClass"
        (activate)="onActivate($event)" (select)='onSelectFeeDiscount($event)'
        [sorts]="[{prop: 'person_info.surname', dir: 'asc'}]">
        <ngx-datatable-column [sortable]="false" [draggable]="false" [resizeable]="false" [headerCheckboxable]="false"
          [checkboxable]="true">
        </ngx-datatable-column>
        <ngx-datatable-column name="Nome" [flexGrow]="3" prop="person_info.surname" [sortable]="true"
          [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{value}} {{row.person_info.first_name}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Settimane" prop="camp_week" [flexGrow]="2" [sortable]="true" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <ng-container *ngFor="let index of value">
              {{index}}&ordf;&nbsp;
            </ng-container>
            <i tooltip="Host week" *ngIf="row.host_week != null && row.host_week.length > 0" class="fa fa-home"></i>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Enrollment" [flexGrow]="2" prop="enrollment_fee_amount" [sortable]="true"
          [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <i *ngIf="row.enrollment_fee_verified" class="fa fa-check-circle" aria-hidden="true"
              style="color:green"></i>
            <span
              *ngIf="row.enrollment_fee_amount > 0">{{ (row.enrollment_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Course" [flexGrow]="2" prop="course_fee_amount" [sortable]="true"
          [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <i *ngIf="row.course_fee_verified" class="fa fa-check-circle" aria-hidden="true" style="color:green"></i>
            <span
              *ngIf="row.course_fee_amount > 0">{{ (row.course_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Discount" [flexGrow]="5" prop="" [sortable]="false" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span *ngFor="let discount of row.active_discount">
              {{ discount.label }} <b>-{{ (discount.value | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</b><br>
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Invoice" [flexGrow]="2" prop="invoice" [sortable]="true" [resizeable]="true"></ngx-datatable-column>

        <ngx-datatable-column name="Cash Flow" prop="" [flexGrow]="2" [sortable]="false" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <button *ngIf="value" type="button" class="no-button" [popover]="quoteSubscriberTemplate"
              popoverTitle="Quote {{ row.course_fee_payed }}/{{ row.course_fee_total }} ({{row.total_fee_amount}})"
              container="body" triggers="mouseenter:mouseleave" placement="left">
              <span *ngIf="row.enrollment_fee"><i [ngClass]="{'font-red': (row.enrollment_fee_verified == 0)}"
                  class="fa fa-user-plus"></i></span>
              <span *ngIf="row.course_fee"><i [ngClass]="{'font-red': (row.course_fee_verified == 0)}"
                  class="fa fa-graduation-cap"></i></span>
              <span *ngIf="row.course_fee_payed && row.course_fee_total > 0"
                [ngClass]="{'font-red': (row.course_fee_payed < row.course_fee_total)}">{{ row.course_fee_payed }}/{{ row.course_fee_total }}</span>
              <span *ngIf="!row.course_fee_payed && row.course_fee_total > 0"
                class="font-red">0/{{ row.course_fee_total }}</span>
              <span *ngIf="row.course_fee_overfee" class="font-red"> <i class="fa fa-exclamation-circle"
                  aria-hidden="true"></i> ({{ row.course_fee_overfee }})</span>
              <span *ngIf="row.course_fee_total == 0" class="font-green">FREE</span>
            </button>
            <ng-template #quoteSubscriberTemplate>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group read-only">
                    <label class="control-label">Enrollment Fee</label>
                    <p><i *ngIf="row.enrollment_fee_verified" class="fa fa-check-circle" aria-hidden="true"
                        style="color:green"></i>
                      {{ (row.enrollment_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group read-only">
                    <label class="control-label">Course Fee</label>
                    <p><i *ngIf="row.course_fee_verified" class="fa fa-check-circle" aria-hidden="true"
                        style="color:green"></i>
                      {{ (row.course_fee_amount | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                  </div>
                </div>
              </div>
              <div *ngFor="let discount of row.active_discount" class="row">
                <div class="col-md-12">
                  <div class="form-group read-only">
                    <label class="control-label">{{ discount.label }}</label>
                    <p>-{{ (discount.value | currency:'EUR':'symbol':'1.2-2':'it') || '-' }}</p>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [flexGrow]="1" name="" [resizeable]="false" [draggable]="false" prop="">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <button class="blue btn default btn-sm" (click)="openSelected(row)"><i class="fa fa-edit"
                aria-hidden="true"></i></button>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-selectedCount="selectedCount">
            <div style="padding: 5px 10px">
              <div>
                Total: {{rowCount}} |
                <strong>Selected: {{selectedCount}}</strong>&nbsp;&nbsp;&nbsp;
                <button *ngIf="selectedCount > 0"  [appCheckPermission]="'camp_edit'" class="blue btn default btn-sm" style="float: right;"
                  (click)="multipleEditSelected()"><i class="fa fa-edit" aria-hidden="true"></i> Edit {{selectedCount}}
                  subscribers</button>
              </div>
              <br>
              <alert type="warning" *ngIf="selectedCount > 0">
                <strong>Attenzione:</strong> tutti gli sconti per i subscribers selezionati verranno sovrascritti,
                quindi se prima un subscriber aveva uno sconto e nella finestra di modifica degli sconti non viene
                selezionato, lo sconto verrà
                rimosso
              </alert>
            </div>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </tab>
</tabset>


<!-- BEGIN: Modal subscriber -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{selectedSignupInfo?.person_info.first_name}}
          {{selectedSignupInfo?.person_info.surname}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-camp-subscriber-form [model]="selectedSignupInfo" [viewSubscriber]="viewSubscriber" [parentComponent]="this"
              (formSaved)="hideModal($event)"></app-camp-subscriber-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modal subscriber -->


<!-- BEGIN: Modal new subscriber -->
<div [hidden]="!isNewModalShown" [config]="{ show: false }" (onHidden)="onHiddenNew()" bsModal #autoShownNewModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">New Subscriber</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideNewModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-camp-subscriber-form-new (formSaved)="formNewSubscriberSaved($event)"></app-camp-subscriber-form-new>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modal new subscriber -->



<!-- BEGIN: Modal request payment -->
<div *ngIf="isModalShownPaymentRequest" [config]="{ show: false }" (onHidden)="onHiddenRequestPayment()" bsModal #modalShowPaymentRequest="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Payment Request for: <b>{{selectedSignupInfo?.person_info.first_name}} {{selectedSignupInfo?.person_info.surname}}</b></h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalRequestPayment()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-payment-request-form [model]="currentPaymentRequest" (paymentRequestSucceeded)="paymentRequestSucceeded()" (paymentRequestError)="paymentRequestError($event)" ></app-payment-request-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Mdal request payment -->


<!-- BEGIN: Modal Course Fee e Discount -->
<div *ngIf="isFeeDiscountModalShown" [config]="{ show: true }" (onHidden)="onHiddenFeeDiscount()" bsModal
  #autoShownFeeDiscountModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Course Fee e Discount</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideFeeDiscountModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-camp-subscriber-form-fee-discount (formSaved)="formFeeDiscountSubscriberSaved($event)"
              [selectedSubscribers]="selectedFeeDiscount"></app-camp-subscriber-form-fee-discount>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modal course fee e discount -->


<!-- BEGIN: MODAL LOADING EXPORT-->
<div *ngIf="isModalExport" [config]="{ show: true, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal
  #staticModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
