<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/theatrino/reports']">Theatrino Reports</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span>Show</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Tutti gli show presentati
        <small></small>
      </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- BEGIN: Filters -->
      <form action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
        <div class="row">
          <div class="col-md-1">
            <div class="form-group">
              <label class="control-label">Anno</label>
              <select class="form-control" name="year" [(ngModel)]="model.year" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let y of getConfigService().yearSelect(-10)" [value]="y">{{y}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="control-label">Mese</label>
              <select class="form-control" name="month" [(ngModel)]="model.month" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let m of getConfigService().monthSelect()" [value]="m">{{m}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDateFrom, false)) }">
              <label class="control-label">From Date</label>
                <div class="input-group">
                  <!-- input box -->
                  <input angular-mydatepicker #dp_from_date="angular-mydatepicker" name="from_date" class="form-control"
                    [locale]="getHelper().locale"
                    [options]="datePickerOptionsBegin"
                    [ngModel]="localDateFrom"
                    (dateChanged)="onDateFromChanged($event)"
                  />
                  <!-- clear date button -->
                  <span class="input-group-btn">
                    <button type="button" *ngIf="model['from_date']" class="btn default" (click)="dp_from_date.clearDate()"><i class="fa fa-close"></i></button>
                  </span>
                  <!-- toggle calendar button -->
                  <span class="input-group-btn">
                      <button type="button" class="btn default" (click)="dp_from_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                  </span>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDateTo, false)) }">
              <label class="control-label">To Date</label>
                <div class="input-group">
                  <!-- input box -->
                  <input angular-mydatepicker #dp_to_date="angular-mydatepicker" name="to_date" class="form-control"
                    [locale]="getHelper().locale"
                    [options]="datePickerOptionsEnd"
                    [ngModel]="localDateTo"
                    (dateChanged)="onDateToChanged($event)"
                  />
                  <!-- clear date button -->
                  <span class="input-group-btn">
                    <button type="button" *ngIf="model['to_date']" class="btn default" (click)="dp_to_date.clearDate()"><i class="fa fa-close"></i></button>
                  </span>
                  <!-- toggle calendar button -->
                  <span class="input-group-btn">
                      <button type="button" class="btn default" (click)="dp_to_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                  </span>
              </div>
            </div>
          </div>

        </div>

        <div class="form-actions right">
          <button type="button" class="btn default btn-lg" (click)="clearSearch()">Clear
            <i class="fa fa-eraser" aria-hidden="true"></i>
          </button>
          <button class="btn default btn-lg green" [disabled]="getTheatrinoService().isOngoingRequest()" type="submit">Search</button>
        </div>

      </form>
      <!-- END: Filters -->

      <br>

      <!-- begin: prima riga -->
      <div class="row">
        <!-- begin: prima colonna -->
        <div class="col-md-12">
          <div class="portlet light bordered">
            <div class="portlet-body">
              <div class="row" *ngIf="getTheatrinoService().isOngoingRequest()">
                <div class="col-md-12">
                  <div class="datatable-loader text-center">
                    <span></span><span></span><span></span><span></span><span></span>
                    <strong class="text-center">Loading</strong>
                  </div>
                </div>
              </div>
              <div class="portlet" *ngIf="!getTheatrinoService().isOngoingRequest()">
                <div class="portlet-body">
                  <ngx-datatable class="striped" [rows]="rows" [columnMode]="'force'" [headerHeight]="50"
                    [footerHeight]="50" [rowHeight]="'auto'" [limit]="50" [scrollbarH]="true">
                    <ngx-datatable-column name="Nome Show" prop="show_denomination"></ngx-datatable-column>
                    <ngx-datatable-column name="Show Type" prop="show_type">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{getConfigService().getShowTypeValueByKey(value)}}
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Nome Tour" prop="theatrino_denomination"></ngx-datatable-column>
                    <ngx-datatable-column name="Data Tour" prop="tour_date_prenotation">
                      <ng-template ngx-datatable-cell-template let-row="row">{{row?.tour_date_prenotation_formatted}}</ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Class level" prop="tour_class_level"></ngx-datatable-column>
                    <ngx-datatable-column name="Students" prop="tour_subscribers"></ngx-datatable-column>
                    <ngx-datatable-column name="Gratuities" prop="tour_gratuities"></ngx-datatable-column>
                    <ngx-datatable-column name="Total" prop="tour_total_students"></ngx-datatable-column>
                    <ngx-datatable-column name="Costo" prop="tour_date_cost">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{ (value | currency:'EUR':'symbol':'1.2-2':'it') || "0,00" }}
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Costo per show" prop="tour_date_cost_per_show">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{ (value | currency:'EUR':'symbol':'1.2-2':'it') || "0,00" }}
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Payment type" prop="tour_date_payment_type">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{getConfigService().getPaymentTourValueByKey(value)}}
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Area" prop="tour_date_area">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{getConfigService().getAreaExpertiseValueByKey(value)}}
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Accountable" prop="show_accountable_first_name">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{row.show_accountable_first_name}} {{row.show_accountable_surname}}
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Enterprise" prop="enterprise_main_denomination"></ngx-datatable-column>
                    <ngx-datatable-column name="Organizer" prop="enterprise_organizer_denomination"></ngx-datatable-column>
                  </ngx-datatable>
                </div>
              </div>
              <div class="row">
                <div class="pull-right">
                  <button [appCheckPermission]="'theatrino_reports_export'" [disabled]="getTheatrinoService().isOngoingRequest() || rows?.length <= 0"
                    type="button" class="btn green fix-button-margin" (click)="exportToExcel()"><i class="fa fa-share"></i>
                    Export to Excel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END CONTENT BODY -->
      </div>
    </div>
  </div>
  <!-- end: prima colonna -->
</div>
<!-- end: prima riga -->

<!-- BEGIN: MODAL LOADING EXPORT-->
<div *ngIf="isModalExport" [config]="{ show: true, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal
  #staticModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
