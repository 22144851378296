<div class="datatable-loader text-center" *ngIf="getCampService().isOngoingRequest()">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>

<tabset [justified]="true">

  <!-- TAB INFO -->

  <tab heading="Info">

    <div *ngIf="!getCampService().isOngoingRequest()">

      <ngx-datatable class="striped" [rows]="campStaffList" [columnMode]="'flex'" [headerHeight]="50"
        [footerHeight]="50" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'"
        [rowClass]="getRowClass" (activate)="onActivate($event)">

        <ngx-datatable-column name="Type" [flexGrow]="2" prop="type" [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <button *ngIf="row.staff_email && getCampService().getNumOfCampDirector > 1" type="button" class="no-button"
              popover="Attenzione non è possibile avere più di un responsabile alla volta attivo"
              triggers="mouseenter:mouseleave" placement="right">
              <i class="fa fa-warning fa-exclamation-triangle"></i></button>
            <button *ngIf="row.staff_email" type="button" class="no-button"
              popover="Attuale responsabile delle iscrizioni" triggers="mouseenter:mouseleave" placement="right">
              <i class="fa fa-child"></i></button>
            {{getConfigService().getCampStaffTypeValueByKey(value)}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Name" [flexGrow]="4" [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            {{row.person_info?.surname}} {{row.person_info?.first_name}}
            {{row.enterprise_info?.denomination}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Contacts" [flexGrow]="4" [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <small>
              <span *ngFor="let contact of row.person_info?.contact | mapFromKeyValue">
                <span *ngIf="contact.type != 1"><i class="fa fa-phone-square" aria-hidden="true"></i> {{contact.value}}
                  <br /></span>
              </span>
              <span *ngFor="let contact of row.enterprise_info?.contact | mapFromKeyValue">
                <span *ngIf="contact.type != 1"><i class="fa fa-phone-square" aria-hidden="true"></i> {{contact.value}}
                  <br /></span>
              </span>
              <span *ngFor="let contact of row.person_info?.contact | mapFromKeyValue">
                <span *ngIf="contact.type == 1"><i class="fa fa-envelope" aria-hidden="true"></i> {{contact.value}}
                  <br /></span>
              </span>
              <span *ngFor="let contact of row.enterprise_info?.contact | mapFromKeyValue">
                <span *ngIf="contact.type == 1"><i class="fa fa-envelope" aria-hidden="true"></i> {{contact.value}}
                  <br /></span>
              </span>
            </small>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Weeks" [flexGrow]="2" [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <small>
              <span *ngIf="row.week_01">{{ row.week_01 | date:'d MMMM, y':'':'it'}}</span> <br />
              <span *ngIf="row.week_02">{{ row.week_02 | date:'d MMMM, y':'':'it'}}</span> <br />
              <span *ngIf="row.week_03">{{ row.week_03 | date:'d MMMM, y':'':'it'}}</span> <br />
              <span *ngIf="row.week_04">{{ row.week_04 | date:'d MMMM, y':'':'it'}}</span>
            </small>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Documents" [flexGrow]="3" [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <small>
              <span *ngFor="let doc of row.documents | mapFromKeyValue">
                <a *ngIf="doc.file_path" class="btn btn-xs blue btn-outline"
                  href="{{getConfigService().blobBasePath + doc.file_path}}" target="_blank">
                  <i class="fa fa-file-text"
                    aria-hidden="true"></i>&nbsp;{{getConfigService().getCampStaffDocumentTypeValueByKey(doc.type)}}</a><br>
              </span>
              <button *ngIf="row.type <= 3" type="button" class="btn btn-xs green btn-outline"
                (click)="downloadPdfTemplate(row, 'lettera-incarico');"><i class="fa fa-file-pdf-o"></i>&nbsp;Lettera
                d'Incarico</button>
              <button *ngIf="row.type <= 4" type="button" class="btn btn-xs green btn-outline"
                (click)="downloadPdfTemplate(row, 'certificato');"><i
                  class="fa fa-file-pdf-o"></i>&nbsp;Certificato</button>
              <button *ngIf="row.transfer_detail_from && row.transfer_detail_from.length > 0" type="button" class="btn btn-xs green btn-outline"
                (click)="downloadPdfTemplate(row, 'transfer-details-departures');"><i
                  class="fa fa-file-pdf-o"></i>&nbsp;Transfer Details Partenze <i class="fa fa-plane" aria-hidden="true"></i></button>
              <button *ngIf="row.transfer_detail_to && row.transfer_detail_to.length > 0" type="button" class="btn btn-xs green btn-outline"
                (click)="downloadPdfTemplate(row, 'transfer-details-arrivals');"><i
                  class="fa fa-file-pdf-o"></i>&nbsp;Transfer Details Arrivi <i class="fa fa-plane" style="transform: scaleY(-1);" aria-hidden="true"></i></button>
            </small>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="" [flexGrow]="1" [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <button class="btn default btn-sm blue" type="button" (click)="selectRow(row)"><i class="fa fa-edit"
                aria-hidden="true"></i></button>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>

    </div>
  </tab>



  <!-- TAB Transfer Detail -->

  <tab heading="Transfer details">

    <div *ngIf="!getCampService().isOngoingRequest()">

      <ngx-datatable class="striped" [rows]="campStaffList" [columnMode]="'flex'" [headerHeight]="50"
        [footerHeight]="50" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'"
        [rowClass]="getRowClass" (activate)="onActivate($event)">

        <ngx-datatable-column name="Type" [flexGrow]="2" prop="type" [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <button *ngIf="row.staff_email && getCampService().getNumOfCampDirector > 1" type="button" class="no-button"
              popover="Attenzione non è possibile avere più di un responsabile alla volta attivo"
              triggers="mouseenter:mouseleave" placement="right">
              <i class="fa fa-warning fa-exclamation-triangle"></i></button>
            <button *ngIf="row.staff_email" type="button" class="no-button"
              popover="Attuale responsabile delle iscrizioni" triggers="mouseenter:mouseleave" placement="right">
              <i class="fa fa-child"></i></button>
            {{getConfigService().getCampStaffTypeValueByKey(value)}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Name" [flexGrow]="4" [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            {{row.person_info?.surname}} {{row.person_info?.first_name}}
            {{row.enterprise_info?.denomination}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Departures" [flexGrow]="4" [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
              <p *ngFor="let d of row.transfer_detail_from">
                Departs from <b class="uppercase">{{d.from_place}}</b> with {{ d.transport }} <small>({{d.from_camp_code}})</small> on <b><i class="fa fa-calendar" aria-hidden="true"></i> {{d.from_date | date:'d MMMM, y':'':'it'}} at {{d.from_time}}</b>, to <span class="uppercase">{{d.to_place}}</span> <small>({{d.to_camp_code}}) on {{d.to_date | date:'d MMMM, y':'':'it'}} at {{d.to_time}}</small><br>
                <small class="line-breaker"><b>Note:</b> {{d.annotation}}</small><br>
                <i *ngIf="d.file_path" class="fa fa-ticket" aria-hidden="true"></i> <a *ngIf="d.file_path"
                  href="{{getConfigService().blobBasePath + d.file_path}}" target="_blank"> Download ticket</a>
              </p>
              <button *ngIf="row.transfer_detail_from && row.transfer_detail_from.length > 0" type="button" class="btn btn-xs green btn-outline"
                (click)="downloadPdfTemplate(row, 'transfer-details-departures');"><i
                  class="fa fa-file-pdf-o"></i>&nbsp;Transfer Details Partenze <i class="fa fa-plane" aria-hidden="true"></i></button>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Arrivals" [flexGrow]="4" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                <p *ngFor="let d of row.transfer_detail_to">
                  Arrives in <b class="uppercase">{{d.to_place}}</b> with {{ d.transport }} <small>({{d.to_camp_code}})</small> on <b><i class="fa fa-calendar" aria-hidden="true"></i> {{d.to_date | date:'d MMMM, y':'':'it'}} at {{d.to_time}}</b>, from <span class="uppercase">{{d.from_place}}</span> <small>({{d.from_camp_code}}) on {{d.from_date | date:'d MMMM, y':'':'it'}} at {{d.from_time}}</small><br>
                  <small class="line-breaker"><b>Note:</b> {{d.annotation}}</small><br>
                  <i *ngIf="d.file_path" class="fa fa-ticket" aria-hidden="true"></i> <a *ngIf="d.file_path"
                    href="{{getConfigService().blobBasePath + d.file_path}}" target="_blank"> Download ticket</a>
                </p>
                <button *ngIf="row.transfer_detail_to && row.transfer_detail_to.length > 0" type="button" class="btn btn-xs green btn-outline"
                  (click)="downloadPdfTemplate(row, 'transfer-details-arrivals');"><i
                    class="fa fa-file-pdf-o"></i>&nbsp;Transfer Details Arrivi <i class="fa fa-plane" style="transform: scaleY(-1);" aria-hidden="true"></i></button>
            </ng-template>
          </ngx-datatable-column>

        <ngx-datatable-column name="" [flexGrow]="1" [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <button class="btn default btn-sm blue" type="button" (click)="selectRow(row)"><i class="fa fa-edit"
                aria-hidden="true"></i></button>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>

    </div>
  </tab>
