import { Component, OnInit, ViewChild } from '@angular/core';
import { VehicleService, ConfigService, HelperService } from 'app/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { isPast } from 'date-fns';

@Component({
  selector: 'app-vehicle-expiration-list',
  templateUrl: './vehicle-expiration-list.component.html',
  styleUrls: ['./vehicle-expiration-list.component.css']
})
export class VehicleExpirationListComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;

  subject: any;

  expirations: any[] = [];
  selected: any = [];
  currentSelected: any;
  rows: any[] = [];

  constructor(private vehicleService: VehicleService, private configService: ConfigService) { }

  ngOnInit() {

    this.prepareView();

    this.subject = this.vehicleService.getsubjectToUpdateObservable().subscribe(() => {

      this.prepareView();
    });

  }

  ngOnDestroy() {

    this.subject.unsubscribe();
  }


  prepareView()
  {
    if (this.vehicleService.currentVehicleExpirations == null || typeof this.vehicleService.currentVehicleExpirations == "undefined")
      this.updateData([]);
    else
      this.updateData(this.vehicleService.currentVehicleExpirations);
  }

  updateData (expirations: any[])
  {
    this.expirations = JSON.parse(JSON.stringify(expirations));
    this.expirations.forEach(expiration => {

      if (isPast(HelperService.parseDate(expiration.expiration_date)))
        expiration.expired = true;
    })
    this.rows = this.expirations;
  }


  showAddForm() {

    this.selected = [];
    this.currentSelected = null;
    this.showModal();
  }



  onSelect(event)
  {
    this.currentSelected = this.selected[0];
    this.showModal();
  }

  edit(row)
  {
    this.currentSelected = row;
    this.showModal();
  }



  //  Search modal
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.selected = {};
    this.isModalShown = false;
  }



  public getVehicleService(){
    return this.vehicleService;
  }

  public getConfigService(){
    return this.configService;
  }

}
