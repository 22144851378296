<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div *ngIf="!editMode" class="form-body">

    <h3 class="form-section">Fee and Discount</h3>

    <div class="row">
      <div class="col-md-2">
        <b>Enrollment fee: </b>{{model.quote_enrollment_fee | currency:'EUR':'symbol':'1.2-2':'it'}}
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-md-4">
        <b>Contribution fee: </b>{{model.quote_contribution_fee | currency:'EUR':'symbol':'1.2-2':'it'}}
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-md-12">
        <b>Course fee: </b><br>
        <ul>
          <li>1 week {{model.quote_course_fee_w1 | currency:'EUR':'symbol':'1.2-2':'it'}}</li>
          <li>2 weeks {{model.quote_course_fee_w2| currency:'EUR':'symbol':'1.2-2':'it'}}</li>
          <li>3 weeks {{model.quote_course_fee_w3| currency:'EUR':'symbol':'1.2-2':'it'}}</li>
          <li>4 weeks {{model.quote_course_fee_w4| currency:'EUR':'symbol':'1.2-2':'it'}}</li>
          <li>5 weeks {{model.quote_course_fee_w5| currency:'EUR':'symbol':'1.2-2':'it'}}</li>
        </ul>
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-md-12">
        <b>Max expense (add out) per subscriber: </b><br>
        <ul>
          <li>1 week {{model.quote_max_out_per_subscriber_w1 | currency:'EUR':'symbol':'1.2-2':'it'}}</li>
          <li>2 weeks {{model.quote_max_out_per_subscriber_w2| currency:'EUR':'symbol':'1.2-2':'it'}}</li>
          <li>3 weeks {{model.quote_max_out_per_subscriber_w3| currency:'EUR':'symbol':'1.2-2':'it'}}</li>
          <li>4 weeks {{model.quote_max_out_per_subscriber_w4| currency:'EUR':'symbol':'1.2-2':'it'}}</li>
          <li>5 weeks {{model.quote_max_out_per_subscriber_w5| currency:'EUR':'symbol':'1.2-2':'it'}}</li>
        </ul>
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-md-12">
        <b>Early bird discount: </b>{{model.quote_discount_early_bird | currency:'EUR':'symbol':'1.2-2':'it'}} from {{ model.quote_discount_early_bird_date | date }}
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-md-12">
        <b>Iscrizione durante l'incontro genitori: </b>{{model.quote_discount_1 | currency:'EUR':'symbol':'1.2-2':'it'}}<br>
        <b>Host Families (per host week): </b>{{model.quote_discount_2 | currency:'EUR':'symbol':'1.2-2':'it'}}<br>
        <b>Sconto a fratello (per settimana): </b>{{model.quote_discount_3 | currency:'EUR':'symbol':'1.2-2':'it'}}, dal 3° fratello in poi {{model.quote_discount_4 | currency:'EUR':'symbol':'1.2-2':'it'}}
      </div>
    </div>

    <br><br>

  </div>

  <div *ngIf="editMode" class="form-body">

    <h3 class="form-section">Enrollment Fee</h3>

    <div class="row">
      <div class="col-md-4">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_enrollment_fee.dirty || quote_enrollment_fee.touched) && !quote_enrollment_fee.valid}">
          <!-- <label class="control-label">Enrollment Fee</label> -->
          <input type="number" name="quote_enrollment_fee" [(ngModel)]="model.quote_enrollment_fee" #quote_enrollment_fee="ngModel" class="form-control" required>
          <span class="help-block">included in the course fee</span>
        </div>
      </div>
    </div>

    <h3 class="form-section">Contribution Fee</h3>

    <div class="row">
      <div class="col-md-4">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_contribution_fee.dirty || quote_contribution_fee.touched) && !quote_contribution_fee.valid}">
          <input type="number" name="quote_contribution_fee" [(ngModel)]="model.quote_contribution_fee" #quote_contribution_fee="ngModel" class="form-control" required>
          <span class="help-block">&nbsp;</span>
        </div>
      </div>
    </div>

    <h3 class="form-section">Course Fee</h3>

    <div class="row">
      <div class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_course_fee_w1.dirty || quote_course_fee_w1.touched) && !quote_course_fee_w1.valid}">
          <label class="control-label">1 week</label>
          <input type="number" name="quote_course_fee_w1" [(ngModel)]="model.quote_course_fee_w1" #quote_course_fee_w1="ngModel" class="form-control" required>
        </div>
      </div>
      <div class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_course_fee_w2.dirty || quote_course_fee_w2.touched) && !quote_course_fee_w2.valid}">
          <label class="control-label">2 weeks</label>
          <input type="number" name="quote_course_fee_w2" [(ngModel)]="model.quote_course_fee_w2" #quote_course_fee_w2="ngModel" class="form-control" required>
        </div>
      </div>
      <div class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_course_fee_w3.dirty || quote_course_fee_w3.touched) && !quote_course_fee_w3.valid}">
          <label class="control-label">3 weeks</label>
          <input type="number" name="quote_course_fee_w3" [(ngModel)]="model.quote_course_fee_w3" #quote_course_fee_w3="ngModel" class="form-control" required>
        </div>
      </div>
      <div class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_course_fee_w4.dirty || quote_course_fee_w4.touched) && !quote_course_fee_w4.valid}">
          <label class="control-label">4 weeks</label>
          <input type="number" name="quote_course_fee_w4" [(ngModel)]="model.quote_course_fee_w4" #quote_course_fee_w4="ngModel" class="form-control" required>
        </div>
      </div>
      <div class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_course_fee_w5.dirty || quote_course_fee_w5.touched) && !quote_course_fee_w5.valid}">
          <label class="control-label">5 weeks</label>
          <input type="number" name="quote_course_fee_w5" [(ngModel)]="model.quote_course_fee_w5" #quote_course_fee_w5="ngModel" class="form-control" required>
        </div>
      </div>
    </div>

    <h3 class="form-section">Max expense (add out) per subscriber</h3>

    <div class="row">
      <div class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_max_out_per_subscriber_w1.dirty || quote_max_out_per_subscriber_w1.touched) && !quote_max_out_per_subscriber_w1.valid}">
          <label class="control-label">1 week</label>
          <input type="number" name="quote_max_out_per_subscriber_w1" [(ngModel)]="model.quote_max_out_per_subscriber_w1" #quote_max_out_per_subscriber_w1="ngModel" class="form-control" required>
        </div>
      </div>
      <div class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_max_out_per_subscriber_w2.dirty || quote_max_out_per_subscriber_w2.touched) && !quote_max_out_per_subscriber_w2.valid}">
          <label class="control-label">2 weeks</label>
          <input type="number" name="quote_max_out_per_subscriber_w2" [(ngModel)]="model.quote_max_out_per_subscriber_w2" #quote_max_out_per_subscriber_w2="ngModel" class="form-control" required>
        </div>
      </div>
      <div class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_max_out_per_subscriber_w3.dirty || quote_max_out_per_subscriber_w3.touched) && !quote_max_out_per_subscriber_w3.valid}">
          <label class="control-label">3 weeks</label>
          <input type="number" name="quote_max_out_per_subscriber_w3" [(ngModel)]="model.quote_max_out_per_subscriber_w3" #quote_max_out_per_subscriber_w3="ngModel" class="form-control" required>
        </div>
      </div>
      <div class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_max_out_per_subscriber_w4.dirty || quote_max_out_per_subscriber_w4.touched) && !quote_max_out_per_subscriber_w4.valid}">
          <label class="control-label">4 weeks</label>
          <input type="number" name="quote_max_out_per_subscriber_w4" [(ngModel)]="model.quote_max_out_per_subscriber_w4" #quote_max_out_per_subscriber_w4="ngModel" class="form-control" required>
        </div>
      </div>
      <div class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_max_out_per_subscriber_w5.dirty || quote_max_out_per_subscriber_w5.touched) && !quote_max_out_per_subscriber_w5.valid}">
          <label class="control-label">5 weeks</label>
          <input type="number" name="quote_max_out_per_subscriber_w5" [(ngModel)]="model.quote_max_out_per_subscriber_w5" #quote_max_out_per_subscriber_w5="ngModel" class="form-control" required>
        </div>
      </div>
    </div>

    <h3 class="form-section">Discount</h3>

    <div class="row">

      <div class="col-md-4">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_discount_early_bird.dirty || quote_discount_early_bird.touched) && !quote_discount_early_bird.valid}">
          <label class="control-label">Early Bird Discount</label>
          <input type="number" name="quote_discount_early_bird" [(ngModel)]="model.quote_discount_early_bird" #quote_discount_early_bird="ngModel" class="form-control" required>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper.isMyDateValid(getLocalData(), false)) }">
          <label class="control-label">From Date</label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_quote_discount_early_bird_date="angular-mydatepicker" name="quote_discount_early_bird_date" class="form-control"
                [locale]="getHelper.locale"
                [options]="getDatePickerOptions()"
                [ngModel]="getLocalData()"
                (dateChanged)="onDateChanged($event)"
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.quote_discount_early_bird_date" class="btn default" (click)="dp_quote_discount_early_bird_date.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_quote_discount_early_bird_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
        <div class="col-md-4">
          <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_discount_1.dirty || quote_discount_1.touched) && !quote_discount_1.valid}">
            <label class="control-label">Iscrizione durante l'incontro genitori</label>
            <input type="number" name="quote_discount_1" [(ngModel)]="model.quote_discount_1" #quote_discount_1="ngModel" class="form-control" required>
          </div>
        </div>

        <div class="col-md-4">
          <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_discount_2.dirty || quote_discount_2.touched) && !quote_discount_2.valid}">
            <label class="control-label">Host Families</label>
            <input type="number" name="quote_discount_2" [(ngModel)]="model.quote_discount_2" #quote_discount_2="ngModel" class="form-control" required>
          </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
          <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_discount_3.dirty || quote_discount_3.touched) && !quote_discount_3.valid}">
            <label class="control-label">Sconto a fratello (per settimana)</label>
            <input type="number" name="quote_discount_3" [(ngModel)]="model.quote_discount_3" #quote_discount_3="ngModel" class="form-control" required>
          </div>
        </div>

        <div class="col-md-4">
          <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_discount_4.dirty || quote_discount_4.touched) && !quote_discount_4.valid}">
            <label class="control-label">dal 3° fratello in poi (per settimana)</label>
            <input type="number" name="quote_discount_4" [(ngModel)]="model.quote_discount_4" #quote_discount_4="ngModel" class="form-control" required>
          </div>
        </div>
    </div>

  </div>



  <div [appCheckPermission]="'camp_edit_quote'" class="form-actions right">
    <img *ngIf="isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <button [appCheckPermission]="'camp_edit_quote'" type="button" class="btn btn-outline blue" [disabled]="isOngoingRequest()" *ngIf="this.model.id && !editMode"
      (click)="edit()">
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

    <button *ngIf="editMode" type="submit" class="btn blue" [disabled]="isOngoingRequest()"
      [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id">
        <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>



</form>
