<form action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="input-group input-group-lg input-icon right" [ngClass]="{ 'has-error': f.submitted && !searchNameText.valid}">
        <i class="fa fa-times-circle" aria-hidden="true" (click)="model.searchName = '';"></i>
        <input type="text" name="searchNameText" #searchNameText="ngModel" [(ngModel)]="model.searchName"
          nospace minlength="4" class="form-control" placeholder="Search for product name...">
        <span class="input-group-btn">
          <button class="btn green" [disabled]="getWarehouseService().isOngoingRequest()" type="submit">Search</button>
        </span>
      </div>
      <small [ngClass]="{ 'has-error': f.submitted && !searchNameText.valid }">
        <span class="help-block">Type a word to search product name, min length 4 chars</span>
      </small>
    </div>
  </div>

  <hr>
  
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>Product Tags</label>
        <div class="mt-checkbox-inline">
          <label class="mt-checkbox" *ngFor="let tag of model.tags_formatted">
            <input type="checkbox" name="{{tag.key}}" [(ngModel)]="tag.enabled" (ngModelChange)="tagChanged();"> {{tag.value}}
            <span></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</form>