import { ModalDirective } from 'ngx-bootstrap/modal';
import { WarehouseService } from './../../_services/warehouse.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { HelperService, UserService } from '../../_services';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-warehouse-shipment-datatable',
  templateUrl: './warehouse-shipment-datatable.component.html',
  styleUrls: ['./warehouse-shipment-datatable.component.css']
})
export class WarehouseShipmentDatatableComponent implements OnInit {

  @ViewChild('shipmentModal', {static: false}) public shipmentModal: ModalDirective;

  rows = [];
  selected = [];

  hideCloseShipments: boolean = true;

  subscribe: any;

  private localDataStart: IMyDateModel;
  private localDataEnd: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(private warehouseService: WarehouseService, private notify: NotificationsService, private helper: HelperService, private userService: UserService) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    this.subscribe = this.warehouseService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      this.rows =  JSON.parse(JSON.stringify(this.warehouseService.currentShipments));
      this.valueChange(this.hideCloseShipments);

    });
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }


  submit()
  {
    if (this.currentShipmentSelected.id)
    {
      if (!this.helper.isMyDateValid(this.localDataStart) || !this.helper.isMyDateValid(this.localDataEnd))
        return;

      this.warehouseService.updateShipment(this.currentShipmentSelected);
    }
  }

  valueChange(event)
  {

    if (!this.warehouseService.currentShipments)
      return;

    if (event == true)
    {
      this.rows = JSON.parse(JSON.stringify( this.warehouseService.currentShipments.filter( x => x.closed_shipment != true) ))
    }
    else
    {
      this.rows =  JSON.parse(JSON.stringify(this.warehouseService.currentShipments));
    }

  }


  reopenOrder ()
  {
    if (this.currentShipmentSelected.closed_shipment == true)
      this.notify.error("Shipment alrady closed");

    this.warehouseService.reopenShipmentOrder (this.currentShipmentSelected, () => {

      this.hideModalShipment();
    });
  }

  closeOrder ()
  {
    //  controllo se ci sono prodotti
    if (!this.checkNumProducts)
    {
      return;
    }

    //  controllo se ci sono le quantità necessarie
    if (!this.checkOrder)
    {
      this.notify.error("Shipment Order", "There are not enough quantities");
      return;
    }

    if (this.currentShipmentSelected != null)
    {
      if (this.currentShipmentSelected.cloded_order == true) return;

      this.warehouseService.closeShipmentOrder(this.currentShipmentSelected, () => {

        this.hideModalShipment();
      });
    }

  }


  //  controllo se le quantità per ogni prodotto sono presenti in magazzino
  get checkOrder ()
  {
    var check:boolean = true;

    //  controllo se ci sono le quantità necessarie
    if (typeof this.currentShipmentSelected.material_list == "undefined")
      return check = false;

    this.currentShipmentSelected.material_list.forEach(element => {

      if (element.quantity > element.product_info.quantity)
        return check = false;
    });

    return check;
  }

  //  controllo che ci sia almeno un prodotto
  get checkNumProducts ()
  {
    var check:boolean = true;

     //  controllo se ci sono le quantità necessarie
     if (typeof this.currentShipmentSelected.material_list == "undefined")
      return check = false;

    if (this.currentShipmentSelected.material_list.length == 0)
      return check = false;

    return check;
  }


  currentShipmentSelected: any = null;
  onSelect({ selected }) {

    if (!this.userService.checkPermission("warehouse_shipments_edit"))
      return;

    this.currentShipmentSelected = JSON.parse(JSON.stringify(selected[0]));

    if (this.currentShipmentSelected.shipment_start != null)
      this.localDataStart = this.helper.initMyDataInputSingle(this.currentShipmentSelected.shipment_start);

    if (this.currentShipmentSelected.shipment_end != null)
      this.localDataEnd = this.helper.initMyDataInputSingle(this.currentShipmentSelected.shipment_end);

    //  scarico le info aggiuntive per la shipment richiesta

    this.showModalShipment();
  }





  onDateStartChanged(event: IMyDateModel) {

    this.localDataStart = event;

    if (event.singleDate.jsDate != null)
      this.currentShipmentSelected.shipment_start = this.helper.formatISODate(event);
    else
      this.currentShipmentSelected.shipment_start = null;
  }




  onDateEndChanged(event: IMyDateModel) {

    this.localDataEnd = event;

    if (event.singleDate.jsDate != null)
      this.currentShipmentSelected.shipment_end = this.helper.formatISODate(event);
    else
      this.currentShipmentSelected.shipment_end = null;
  }





  //  modale della spedizione
  isModalShipmentShow: boolean = false;
  showModalShipment()
  {
    this.isModalShipmentShow = true;
    this.shipmentModal.show();
  }

  hideModalShipment()
  {
    this.shipmentModal.hide();
  }

  onHiddenShipmentModal ()
  {
    this.currentShipmentSelected = null;

    //  reset delle date
    this.localDataStart = null;
    this.localDataEnd = null;

    this.isModalShipmentShow = false;
  }





  getLocalDataStart() {
    return this.localDataStart
  }

  getLocalDataEnd() {
    return this.localDataEnd
  }

  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

  getWarehouseService () {
    return this.warehouseService;
  }
}
