<div class="row" *ngIf="requestCount == 0">

  <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="!currentAgreement?.id && errorMessages.length <= 0">
    <button type="button" class="btn green btn-block btn-outline" (click)="createAgreement();" ><i
        class="fa fa-check"></i>&nbsp; Confirm Agreement Creation</button>
  </div>

  <div class="col-xs-6 col-sm-6 col-md-6" *ngIf="currentAgreement?.id && errorMessages.length <= 0">
    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="deleteAgreement();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn btn-outline red btn-block" [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
      <i class="fa fa-trash-o"></i> Delete</button>&nbsp;
  </div>
  <div class="col-xs-6 col-sm-6 col-md-6" *ngIf="currentAgreement?.id && errorMessages.length <= 0 && currentAgreement.status == 1">
    <button type="button" class="btn green btn-block btn-outline" (click)="downloadPdfAgreement();" ><i
        class="fa fa-file-pdf-o"></i>&nbsp; Download PDF Agreement</button>
  </div>

</div>

<hr>

<div class="row" *ngIf="currentAgreement?.id">
  <div *ngIf="!currentAgreement.url_document" class=" col-xs-12 col-sm-12 col-md-12">
    <div *ngIf="currentAgreement.url_document == undefined || currentAgreement.url_document == ''">
      <input *ngIf="!imageUploader.isUploading" type="file" id="imageUploader"
        ng2FileSelect [uploader]="imageUploader" />
      <label *ngIf="!imageUploader.isUploading" for="imageUploader"
        class="btn btn-block green-jungle text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload signed agreement!</label>
    </div>
    <div *ngIf="imageUploader.isUploading || getEnterpriseService().isOngoingRequest()">
      <img src="./assets/global/img/input-spinner.gif" />
      please wait...
    </div>
    <div *ngIf="currentAgreement.url_document != undefined && currentAgreement.url_document != ''" style="padding: 10px;">
      <button *ngIf="!imageUploader.isUploading" type="button"
        class="btn btn-danger btn-block text-uppercase" (click)="destroyFile();"><i
          class="fa fa-trash"></i>&nbsp; Remove signed agreement</button>
    </div>
  </div>
  <div class="row" *ngIf="currentAgreement.url_document">
    <div class="col-xs-6 col-sm-6 col-md-6">
      <a class="btn-block btn blue" *ngIf="currentAgreement.url_document" href="{{getConfigService().blobBasePath+currentAgreement.url_document}}"
        target="_blank"> <i class="fa fa-save"></i>&nbsp; Download Signed Agreement </a>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-6">
      <button class="btn-block btn red" (click)="destroyFile();"><i class="fa fa-trash-o"></i>&nbsp; Remove Signed
        Agreement</button>
    </div>
  </div>
</div>

<hr>

<!-- BEGIN: Sezione dedicata alla gestione degli errori che impediscono la generazione della convenzione -->
<alert type="danger" *ngFor="let message of errorMessages">
  <strong>Error!</strong> {{message}}
</alert>
<alert type="warning" *ngFor="let message of warningMessages">
  <strong>Warning!</strong> {{message}}
</alert>
<!-- END: Sezione dedicata alla gestione degli errori che impediscono la generazione della convenzione -->

<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">
    <h3 class="form-section">{{ currentProjectName }} Agreement preview ({{ currentAgreement?.number }})</h3>

    <!-- BEGIN: parte dedicata alle informazioni del Camp -->
    <h4> Camp Detail </h4>
    <div class="row" *ngIf="currentCamp">
      <div class="col-xs-3 col-sm-3 col-md-3">
        <label class="control-label">Camp Code</label>
        <p> <a [routerLink]="['/camp/edit/',currentCamp.id]"> {{currentCamp.camp_code}}</a> </p>
      </div>
      <div class="col-xs-3 col-sm-3 col-md-3">
        <label class="control-label">Start Date</label>
        <p> {{currentCamp.start_date_parsed}} </p>
      </div>
      <div class="col-xs-3 col-sm-3 col-md-3">
        <label class="control-label">End Date</label>
        <p> {{currentCamp.end_date_parsed}} </p>
      </div>
      <div class="col-xs-3 col-sm-3 col-md-3">
        <label class="control-label">Lesson Plan</label>
        <p> {{currentCamp.lesson}} </p>
      </div>
    </div>
    <!-- END: parte dedicata alle informazioni di Camp -->

    <hr>

    <!-- BEGIN: parte dedicata alle informazioni di pagamento -->
    <h4> Payment info </h4>
    <div class="row">
      <!-- <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Totale Pagamento</label>
        <p> {{ (totalCost | currency:'EUR':'symbol':'1.2-2':'it') || "€ 0,00" }} </p>
      </div> -->
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Contribution fee</label>
        <p> {{ (currentCamp.quote_contribution_fee | currency:'EUR':'symbol':'1.2-2':'it') || "€ 0,00" }} </p>
      </div>
    </div>
    <!-- END: parte dedicata alle informazioni di pagamento -->

    <hr>

    <!-- BEGIN: parte dedicata alle informazioni della enterprise -->
    <h4> Organizer </h4>
    <div class="row" *ngIf="currentEnterprise">
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Denomination</label>
        <p> <a [routerLink]="['/enterprise/edit/',currentEnterprise.id]"> {{currentEnterprise.denomination}}</a> </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Address</label>
        <p> {{currentEnterprise.primaryAddress?.route}} {{currentEnterprise.primaryAddress?.street_number}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">CAP</label>
        <p> {{currentEnterprise.primaryAddress?.postal_code}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Locality</label>
        <p> {{currentEnterprise.primaryAddress?.locality}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Province</label>
        <p> {{currentEnterprise.primaryAddress?.administrative_area_level_2_long_version}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">CF/P.Iva</label>
        <p> C.F. {{ currentEnterprise.fiscal_code }} - Tax.Id {{ currentEnterprise.tax_id }} </p>
      </div>
    </div>

    <div class="row">
      <div *ngIf="currentEnterprise.primaryBankaccount">
        <div *ngIf="currentEnterprise.primaryBankaccount.type == 1" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">BankAccount:</label>
          <p> {{ currentEnterprise.primaryBankaccount.bank_name }} {{ currentEnterprise.primaryBankaccount.iban }}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div *ngFor="let contact of currentEnterprise.contact | mapFromKeyValue">
        <div *ngIf="contact.type == 1" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Email:</label>
          <p> {{ contact.value }} </p>
          <ng-template #confirmMail>
            <button type="button" class="btn btn-outline green-jungle" (click)="inviaMailTo($event, contact.value)">Si, invia la mail!</button>
          </ng-template>
          <button *ngIf="currentAgreement?.id && errorMessages.length <= 0" type="button" [outsideClick]="true" [popover]="confirmMail" popoverTitle="Sei sicuro?" class="btn btn-outline btn-block blue"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Invia link alla convenzione</button>
        </div>
        <div *ngIf="contact.type == 2" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Fax:</label>
          <p> {{ contact.value }} </p>
        </div>
        <div *ngIf="contact.type == 8" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Web:</label>
          <p> {{ contact.value }} </p>
        </div>
        <div *ngIf="contact.type != 1 && contact.type != 2 && contact.type != 8" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Tel/Altro:</label>
          <p> {{ contact.value }} </p>
        </div>
      </div>
    </div>
    <!-- END: parte dedicata alle informazioni della enterprise -->

    <hr>

    <!-- BEGIN: parte dedicata alle informazioni della enterprise -->
    <h4> Venue </h4>
    <div class="row" *ngIf="currentVenue">
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Denomination</label>
        <p> <a [routerLink]="['/enterprise/edit/',currentVenue.id]"> {{currentVenue.denomination}}</a> </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Address</label>
        <p> {{currentVenue.primaryAddress?.route}} {{currentVenue.primaryAddress?.street_number}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">CAP</label>
        <p> {{currentVenue.primaryAddress?.postal_code}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Locality</label>
        <p> {{currentVenue.primaryAddress?.locality}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Province</label>
        <p> {{currentVenue.primaryAddress?.administrative_area_level_2_long_version}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Audience</label>
        <ul class="list-inline">
          <div *ngFor="let audie of audienceModel | mapFromKeyValue; let i = index">
            <li *ngIf="audie == true"> {{ getConfigService().showAudienceSelect[i].value }} </li>
          </div>
        </ul>
      </div>
    </div>
    <!-- END: parte dedicata alle informazioni della enterprise -->

    <hr>

    <!-- BEGIN: parte dedicata alle informazioni del legale rappresentante della enterprise -->
    <h4> Legally represented by </h4>
    <div class="row" *ngIf="currentLegal">
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Surname and Name</label>
        <p> <a [routerLink]="['/person/edit/',currentLegal.id]"> {{currentLegal?.surname}} {{currentLegal?.first_name}}
          </a> </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Birthday</label>
        <p> {{currentLegal?.birthday}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Bithplace</label>
        <p> {{currentLegal?.birthplace}} </p>
      </div>
    </div>
    <div class="row" *ngIf="currentLegal">
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Fiscal Code</label>
        <p> {{currentLegal?.tax_id}} </p>
      </div>
    </div>
    <div class="row" *ngIf="currentLegal">
      <div *ngFor="let contact of currentLegal.contact | mapFromKeyValue">
        <div *ngIf="contact.type == 1" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Email:</label>
          <p> {{ contact.value }} </p>
          <ng-template #confirmMail>
            <button type="button" class="btn btn-outline green-jungle" (click)="inviaMailTo($event, contact.value)">Si, invia la mail!</button>
          </ng-template>
          <button *ngIf="currentAgreement?.id && errorMessages.length <= 0" type="button" [outsideClick]="true" [popover]="confirmMail" popoverTitle="Sei sicuro?" class="btn btn-outline btn-block blue"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Invia link alla convenzione</button>
        </div>
        <div *ngIf="contact.type == 2" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Fax:</label>
          <p> {{ contact.value }} </p>
        </div>
        <div *ngIf="contact.type == 8" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Web:</label>
          <p> {{ contact.value }} </p>
        </div>
        <div *ngIf="contact.type != 1 && contact.type != 2 && contact.type != 8" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Tel/Altro:</label>
          <p> {{ contact.value }} </p>
        </div>
      </div>
    </div>
    <!-- END: parte dedicata alle informazioni del legale rappresentante della enterprise -->

    <hr>

    <!-- BEGIN: parte dedicata alle informazioni del camp director -->
    <h4> Camp Director </h4>
    <div class="row" *ngIf="currentCD">
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Surname and Name</label>
        <p> <a [routerLink]="['/person/edit/',currentCD.id]"> {{currentCD?.surname}} {{currentCD?.first_name}}
          </a> </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Birthday</label>
        <p> {{currentCD?.birthday}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Bithplace</label>
        <p> {{currentCD?.birthplace}} </p>
      </div>
    </div>
    <div class="row" *ngIf="currentCD">
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Fiscal Code</label>
        <p> {{currentCD?.tax_id}} </p>
      </div>
    </div>
    <!-- END: parte dedicata alle informazioni del camp director -->

  </div>
</form>
