import { NotificationsService } from 'angular2-notifications';
import { IAngularMyDpOptions, IMyDateModel, AngularMyDatePickerModule } from 'angular-mydatepicker';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { ConfigService, HelperService, CourseScheduleService, PrimanotaService, UserService } from './../../_services/index';
import { parseISO, format, isBefore } from 'date-fns';
import CodiceFiscale from 'codice-fiscale-js'

@Component({
  selector: 'app-course-schedule-subscriber-form',
  templateUrl: './course-schedule-subscriber-form.component.html',
  styleUrls: ['./course-schedule-subscriber-form.component.css']
})
export class CourseScheduleSubscriberFormComponent implements OnInit {

  @ViewChild('f', {static: true}) currentForm: NgForm;
  @ViewChild('datePicker', {static: true}) datePicker: AngularMyDatePickerModule;

  @Input() parentComponent: any = null;

  @Input() model: any = null;
  @Input() modelFromSignup: any = null;
  @Input() viewSubscriber: boolean = false;

  @Output() formDelete = new EventEmitter();
  @Output() formSaved = new EventEmitter();
  @Output() formCopySubscriber = new EventEmitter();

  editMode: boolean = false;

  //  indica che una persona è stata cancellata dal DB
  personDeleted: boolean = false;

  public localPersonData: IMyDateModel;

  public localRegistrationDate: any;

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  ongoingRequestPerson = false;

  firstSearchPerson = false;

  personFound: any[] = [];
  personSelected: any;

  addNewPerson: boolean = false;

  addInAlertType: string = "warning";
  standardCourseFee: number = 0;
  totalDiscount: number = 0;

  canEditDiscount: boolean = false;
  quote_discount_early_bird_date_parsed: any;

  constructor(private courseScheduleService: CourseScheduleService, private helper: HelperService, private userService: UserService,
    private configService: ConfigService, private notify: NotificationsService, private primanotaService: PrimanotaService) {

      //  Inizializzo le info sulle date
      this.datePickerOptions = helper.datePickerOptions;
    }


  ngOnInit() {

    this.canEditDiscount = this.courseScheduleService.canEditDiscount();

    //  arriva un model esterno che contiene l'iscrizione
    if (this.modelFromSignup != null)
    {
      //  faccio una copia esatta in modo da non aggiornare il vecchio model
      this.model = this.modelFromSignup;
    }
    else if (!this.model || !this.model.id)
    {
      this.configureModel();
      this.editMode = true;
    }

    if (this.model)
    {

      if (this.courseScheduleService.currentCourseScheduleInfo && this.courseScheduleService.currentCourseScheduleInfo.quote_discount_early_bird_date){
        this.quote_discount_early_bird_date_parsed = parseISO(this.courseScheduleService.currentCourseScheduleInfo.quote_discount_early_bird_date);
      }

      if (this.model.person_info != null && typeof this.model.person_info != "undefined")
      {
        //  setto che qualcosa è stato cancellato da questo subscriber
        if (this.model.person_info.deleted_at != null)
          this.personDeleted = true;

        if (this.model.person_info.birthday != null)
        {
          this.localPersonData = this.helper.initMyDataInputSingle(this.model.person_info.birthday);
          this.model.person_info.birthday_parsed = parseISO(this.model.person_info.birthday);
        }else
          this.localPersonData = null;

        //  faccio partire automaticamente le chiamate per ricercare il figlio
        if (this.modelFromSignup != null)
          setTimeout(() => { this.findPerson(); }, 1000);
      }

      //  se non ho un ruolo di default preimpostato nel corso allora prendo quello di sisitema
      this.model.person_default_course_role = this.courseScheduleService.currentCourseScheduleInfo.default_subscribers_role;
      if (!this.model.person_default_course_role)
        this.model.person_default_course_role = this.configService.courseScheduleRoleDefaultForSubscribers();

      //  setto a not valid quando la data di iscrizione al campo non esiste
      if (this.model.registration_date)
        this.model.registration_date_parsed = parseISO(this.model.registration_date);
      else
        this.localRegistrationDate = null;

      //  forzo l'aggiornamento della validazione di model autorizzation
      if (this.model.authorization_personal_data == true)
        this.model.authorization_personal_data = true;

      // aggiungo l'id del corso schedule corrente
      this.model.course_schedule_id = this.courseScheduleService.currentCourseScheduleId;

      // aggiungo le informazioni per il CashFlow
      this.model.addin_enrollment_fee = false;
      this.model.addin_course_fee = false;

      //  controllo se esiste un pagamento già effettuato
      if (typeof this.model.payment_requests != "undefined" && this.model.payment_requests != null && this.modelFromSignup != null)
      {
        //  se la causale è quella dell'enrollment fee allora lo inserisco
        if (this.model.payment_requests.causal == 5 && this.model.payment_requests.primanota_auto_gen == false && this.model.payment_requests.status == "success")
        {
          this.model.addin_enrollment_fee = true;
          this.model.addin_enrollment_fee_value = this.model.payment_requests.amount;
          this.model.addin_enrollment_fee_registered_via = this.model.payment_requests.registered_via;
          this.model.payment_request = this.model.payment_requests;
        }
      }

      if (this.model.price_quota_associativa > 0){
        this.model.addin_enrollment_fee_value = this.model.price_quota_associativa;
        this.model.addin_enrollment_fee_registered_via = 7
      }
      else {
        if (this.model.quote_enrollment_fee > 0)
          this.model.addin_enrollment_fee_value = this.model.quote_enrollment_fee;
        else
          this.model.addin_enrollment_fee_value = 0;
      }

      this.updateCourseFeeWithDiscount();

      //  se non è un utente precedentemente registrato allora abilito l'edit mode
      if (!this.model.registered)
        this.editMode = true;

      // se è un utente cancellato, disabilito l'edit mode
      if (this.model.deleted_at)
        this.editMode = false;

    }
  }

  configureModel ()
  {
    this.model = {};
    this.model.person_info = {};
    this.model.person_info.contacts = [
      {type: 5, value: ""}, {type: 1, value: ""}, {type: 4, value: ""}
    ];
    this.model.person_info.addresses = [
      {type: 1}
    ]
  }




  submit()
  {
    if (!this.currentForm.form.valid) return;

    if (this.model.addin_course_fee_value > 0) {
      if (this.model.addin_course_fee_value + this.model.course_fee_amount > this.model.addin_course_fee_value_total){
        this.notify.error("Course fee", "La quota che si sta versando è più elevata di quella prevista, controllare i dati inseriti");
        return;
      }
    }

    //  registration form
    if (this.modelFromSignup)
    {
      if (this.checkOnSubmit){

        this.courseScheduleService.saveCourseSubscriber(this.model, () => {

          this.formSaved.emit(this.model);
          this.primanotaService.getPrimaNota('App\\CourseSchedule', this.courseScheduleService.currentCourseScheduleId);
        });
      }
      return;
    }

    //  manual registration
    if (this.model.id)
    {
      this.courseScheduleService.updateCourseSubscriber(this.model, () => {
        this.formSaved.emit(this.model);
        this.primanotaService.getPrimaNota('App\\CourseSchedule', this.courseScheduleService.currentCourseScheduleId);
      });
    }
    else
    {
      if (this.checkOnSubmit)
      {
        this.courseScheduleService.saveCourseSubscriber(this.model, () => {
          this.formSaved.emit(this.model);
          this.primanotaService.getPrimaNota('App\\CourseSchedule', this.courseScheduleService.currentCourseScheduleId);
        });
      }
    }


  }

  get checkOnSubmit()
  {
    if (!this.helper.isMyDateValid(this.localPersonData) && !this.personSelected) return false;

    //   se non è stata mai settata una data di registrazione valida
    if (this.model.registration_date == null || this.model.registration_date == "undefined") return;

    if (this.personSelected == null && !this.addNewPerson)
    {
      this.notify.error("Subscriber Info", "Ricercare e selezionare una persona dalla tabella, oppure spuntare 'aggiungi nuova persona'");
      return false;
    }

    if (this.personSelected)
      this.preparePersonModel();

    if(this.addNewPerson)
      this.prepareNewPersonModel();

    return true;
  }


  onTaxidChange (valueField)
  {
    if (typeof valueField != "undefined")
      this.model.person_info.tax_id = valueField.toUpperCase();

    try {
      var cf = new CodiceFiscale(this.model.person_info.tax_id).toJSON();

      this.model.person_info.gender = cf.gender.toLowerCase();

      if (typeof cf.birthplace != "undefined")
      {
        if (this.model.person_info.birthplace != cf.birthplace)
          this.model.person_info.birthplace = cf.birthplace;

        if (this.model.person_info.birthplace_province != cf.birthplaceProvincia)
          this.model.person_info.birthplace_province = cf.birthplaceProvincia;
        }

      //  assegno e formatto la data nel formato del datepicker corrente
      var localData = format(
        new Date(parseISO(cf.year + "-" + cf.month + "-" + cf.day)),
        this.getDateFormat().toUpperCase().replace('GG', "dd"));

      if (this.helper.formatISODate(this.localPersonData) != localData)
      {
        this.model.person_info.birthday = cf.year + '-' + cf.month + '_' + cf.day;
        this.localPersonData = this.helper.initMyDataInputSingle(localData);
      }
    }
    catch(err) {
        return;
    }

  }

  getDateFormat ()
  {
    var localeFormat = "";

    if (typeof this.datePicker['localeService'].locales[this.datePicker['locale']] != "undefined")
      localeFormat = this.datePicker['localeService'].locales[this.datePicker['locale']].localeFormat;

    if (typeof localeFormat == "undefined" || localeFormat.length == 0)
      localeFormat = this.datePickerOptions.dateFormat;

    if (typeof localeFormat == "undefined" || localeFormat.length == 0)
      localeFormat = "gg/mm/yyyy";

    return localeFormat
  }

  destroy ()
  {
    this.courseScheduleService.deleteCourseSubscriber(this.model, () => {this.formSaved.emit(this.model);});
  }

  restore ()
  {
    this.courseScheduleService.restoreCourseSubscriber(this.model, () => {this.formSaved.emit(this.model);});
  }

  ignore(){
    this.courseScheduleService.ignoreCourseSignupForm(this.model, () => {this.formSaved.emit(this.model);});
  }


  findPerson()
  {
    this.ongoingRequestPerson = true;
    this.personSelected = null;

    var searchModel: any = {};
    searchModel.first_name = this.model.person_info.first_name;
    searchModel.surname = this.model.person_info.surname;
    searchModel.tax_id = this.model.person_info.tax_id;

    this.courseScheduleService.findPerson(searchModel, (personFound) => {
      this.personFound = personFound;

      //  setto l'indirizzo da mostrare nel datatable
      this.personFound.forEach(person => {
        if (Object.keys(person.address).length > 0) {
          person.address_primary = person.address[Object.keys(person.address)[0]]
          Object.keys(person.address).forEach(addressKey => {
            if (person.address[addressKey].primary == true)
              person.address_primary = person.address[addressKey];
          });
        }
    })
      this.personFound = personFound;
      this.ongoingRequestPerson = false;
      this.firstSearchPerson = true;
    }, () => {this.ongoingRequestPerson = false;});
  }


  preparePersonModel ()
  {
    //  aggiungo dei valori che aiutino la memorizzazione della persona
    this.model.person_info.id = this.personSelected.id;

    //  controllo che ci siano nuovi contatti
    this.model.person_new_contact = new Array;

    this.model.person_info.contacts.forEach(contact => {
      var found = false;

      if (contact.value.trim().length == 0)
        found = true;

      Object.keys(this.personSelected.contact).forEach(key => {
        if ((this.personSelected.contact[key].type == contact.type
          && this.personSelected.contact[key].value == contact.value))
            found = true;
      });

      if (!found)
        this.model.person_new_contact.push(contact);
    });

    //  controllo che ci siano nuovi indirizzi
    this.model.person_new_address = new Array;

    this.model.person_info.addresses.forEach(address => {
      var found = false;
      Object.keys(this.personSelected.address).forEach(key => {
        if (this.personSelected.address[key].type == address.type &&
          address.route.indexOf(this.personSelected.address[key].route) >= 0)
            found = true;
      });

      if (!found) {
        this.model.person_new_address.push(address);
      }
    });

    //  controllo che ci siano i ruoli corretti
    this.model.person_new_role = new Array;

    //  cerco sia il ruolo di default del corso che quello basato sul lavoro dell'iscritto
    var foundCourseRole = false;
    var foundWorkRole = false;

    //  il ruolo da trovare è quello di default del corso
    var rolesToFind = [this.model.person_default_course_role];

    //  se però ho selezionato docente come lavoro allora aggiungo docente come ruolo
    if (this.model.person_info.work_type == 1)
      rolesToFind.push(1);

    Object.keys(this.personSelected.role).forEach(key => {
      //  cerco prima il ruolo di default del corso
      if (this.personSelected.role[key].type == rolesToFind[0])
        foundCourseRole = true;

      //  e dopo solo se presente anche il ruolo basato sul lavoro
      if (rolesToFind.length > 1)
        if (this.personSelected.role[key].type == rolesToFind[1])
          foundWorkRole = true;
    });

    if (!foundCourseRole) {
      var role: any = {type: rolesToFind[0]};
      this.model.person_new_role.push(role);
    }

    if (!foundWorkRole && rolesToFind.length > 1) {
      var role: any = {type: rolesToFind[1]};
      this.model.person_new_role.push(role);
    }

  }


  prepareNewPersonModel ()
  {
    //  aggiungo i contatti
    this.model.person_new_contact = new Array;
    this.model.person_info.contacts.forEach(element => {
      if (element.value.trim().length > 0)
        this.model.person_new_contact.push(element);
    });

    //  aggiungo gli indirizzi
    this.model.person_new_address = new Array;
    this.model.person_info.addresses.forEach(element => {
      if (element.route.trim().length > 0)
        this.model.person_new_address.push(element);
    });

    //  il ruolo da trovare è quello di default del corso
    var rolesToAdd = [this.model.person_default_course_role];

    //  se però ho selezionato docente come lavoro allora aggiungo docente come ruolo
    if (this.model.person_info.work_type == 1)
      rolesToAdd.push(1);

    rolesToAdd.forEach(roleToAdd => {
      var role: any = {type: roleToAdd};
      this.model.person_new_role = new Array (role);
    });

  }

  //  quando viene cambiato il lavoro devono essere azzerate le altre variabili
  changedWorkType (event) {

    //  se tiverso da docente azzero le altre scelte
    if (event != 1)
    {
      this.model.person_info.class_type = null;
      this.model.school_name = null;
      this.model.school_prov = null;
    }

  }

  onSelectPerson({ selected }) {
    this.personSelected = selected[0];
  }

  onDatePersonChanged(event: IMyDateModel) {
    this.localPersonData = event;

    if (event.singleDate.jsDate != null)
      this.model.person_info.birthday = this.helper.formatISODate(event);
    else
      this.model.person_info.birthday = null;
  }



  onDateRegistrationChanged(event: IMyDateModel) {
    this.localRegistrationDate = event;
    if (event.singleDate.jsDate != null)
      this.model.registration_date = this.helper.formatISODate(event);
    else
      this.model.registration_date = null;
  }


  suggestEarlyBirdDiscount: boolean = false;

  updateCourseFeeWithDiscount() {

    if (this.courseScheduleService.currentCourseScheduleInfo == null)
      return;

    if (this.model == null)
      return;

    let courseSchedule = this.courseScheduleService.currentCourseScheduleInfo;

    this.standardCourseFee = courseSchedule['quote_course_fee'];

    let course_fee = courseSchedule['quote_course_fee'];

    if (this.model.enrollment_fee_amount)
      course_fee -= this.model.enrollment_fee_amount;

    if (this.model.quote_extra_1){
      course_fee += courseSchedule.quote_extra_1
    }

    if (this.model.quote_extra_custom > 0){
      course_fee += parseFloat(this.model.quote_extra_custom);
    }

    if (this.model.quote_discount_1){
      course_fee -= courseSchedule.quote_discount_1
    }

    if (this.model.quote_discount_2){
      course_fee -= courseSchedule.quote_discount_2
    }

    if (this.model.quote_discount_early_bird){
      course_fee -= courseSchedule.quote_discount_early_bird
      // this.suggestEarlyBirdDiscount = false;
    }
    else {
      if (isBefore(parseISO(this.model.registration_date), parseISO(this.courseScheduleService.currentCourseScheduleInfo.quote_discount_early_bird_date))){
        this.suggestEarlyBirdDiscount = true;
      }
      else {
        this.suggestEarlyBirdDiscount = false;
      }
    }

    if (this.model.quote_discount_custom > 0){

      if (this.model.quote_discount_custom > course_fee)
        this.model.quote_discount_custom = course_fee;

      course_fee -= parseFloat(this.model.quote_discount_custom);
    }

    if (this.model.quote_discount_free == true){
      course_fee = 0
    }

    this.model.addin_course_fee_value_total = course_fee;

    // sottraggo un eventuale valore già pagato
    if (this.model.course_fee_amount > 0){
      course_fee -= this.model.course_fee_amount;
    }

    // aggiorno il prezzo del corso
    this.model.addin_course_fee_value = course_fee;

    // calcolo lo sconto totale dalla quota standard
    this.totalDiscount = this.standardCourseFee - course_fee - this.model.enrollment_fee_amount - this.model.course_fee_amount;
  }



  //  mostra la modale con la richiesta di pagamento
  createPaymentRequest (typeOfPayment: any = null)
  {

    //  creo il modulo da inviare e mostro la modale
    if (this.parentComponent != null)
    {
      let modelRequest: any = {};

      modelRequest.item_id = this.courseScheduleService.currentCourseScheduleId;
      modelRequest.item_type = 'App\\CourseSchedule';
      modelRequest.item_quantity = 1;
      modelRequest.item_name = this.courseScheduleService.currentCourseScheduleInfo.course_info.title;
      modelRequest.item_image = this.courseScheduleService.currentCourseScheduleInfo.course_info.image_url;
      modelRequest.item_currency = 'eur';
      modelRequest.item_description = this.courseScheduleService.currentCourseScheduleInfo.course_info.online_description;
      Object.keys(this.model.person_info.contact).forEach(contactKey => {
        if (this.model.person_info.contact[contactKey].type == 1)
          modelRequest.sendto_mail_address = this.model.person_info.contact[contactKey].value;
      });

      /*
      * CAMPI PER LA PRIMA NOTA
      */

      //  data della prima nota
      // modelRequest.date_payment = null;
      //  id dell'utente che genera la richiesta
      // modelRequest.registered_by_user_id = 1;
      //  valore predefinito per il pagamento con stripe
      modelRequest.registered_via = 8;
      if (typeOfPayment == 'coursefee')
      {
        modelRequest.amount = this.model.addin_course_fee_value;
        modelRequest.causal = 6;
      } else
      if (typeOfPayment == 'enrollmentfee')
      {
        modelRequest.amount = this.model.addin_enrollment_fee_value;
        modelRequest.causal = 5
      }
      modelRequest.in_holder_type = 'App\\CourseSchedule';
      modelRequest.in_holder_id = this.courseScheduleService.currentCourseScheduleId;
      modelRequest.out_holder_type = 'App\\Person';
      modelRequest.out_holder_id = this.model.person_info.id;

      this.parentComponent.showModalRequestPayment(modelRequest);
    }
  }

  //  copio i subscriber in un array e inoltto l'evento al padre che gestirà la copia
  copySubscriberCall () {
    var subscribersArray: any = [];
    subscribersArray.push(JSON.parse(JSON.stringify(this.model)));

    this.formCopySubscriber.emit(subscribersArray);
  }

  getCourseScheduleService():CourseScheduleService {
    return this.courseScheduleService
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

  getUserService() {
    return this.userService
  }

}
