import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject, Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpParams } from '@angular/common/http';
import { differenceInWeeks, parseISO } from 'date-fns';
import { UserService } from './user.service';
import { ShipmentService } from './shipment.service';

declare var saveAs: any;

@Injectable()
export class CampService {

  currentCampId;
  currentCampInfo: any;
  allCamps: any;
  currentCampStaffList: any[];
  currentCampDiaryList: any;
  currentCampSubscriberList: any[];
  currentCampGroups: any = [];
  currentDocumentList: any[];
  currentShipmentList: any[];

  ongoingRequest: boolean = false;

  //	Memorizza l'ultima query di ricerca fatta
  lastSearchQueries: any = null;
  lastTransferDetailsSearchQueries: any = null;


  private subjectToUpdate: Subject<boolean> = new Subject<boolean>();
  private subjectToUpdateCampSubscriberList: Subject<boolean> = new Subject<boolean>();

  constructor(private backend: BackendService, private notify: NotificationsService,
    private userService: UserService, private shipmentService: ShipmentService) { }

  getsubjectToUpdateObservable(): Observable<boolean> {
    return this.subjectToUpdate.asObservable();
  }

  getsubjectToUpdateCampSubscriberListObservable(): Observable<boolean> {
    return this.subjectToUpdateCampSubscriberList.asObservable();
  }

  resetService() {
    this.allCamps = null;
    this.currentCampId = null;
    this.currentCampInfo = null;
    this.currentCampStaffList = null;
    this.currentCampDiaryList = null;
    this.currentCampSubscriberList = null;
    this.currentCampGroups = null;
    this.currentDocumentList = null;
    this.currentShipmentList = null;
  }

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  isOngoingRequest() {
    return this.ongoingRequest;
  }


  public maxNumOfWeeksforCamp = 4;

  //	restituisce il numero di camp director per questo campo,
  //		che hanno anche la mail settata
  get getNumOfCampDirector() {

    var counter = 0;
    this.currentCampStaffList.forEach(element => {
      if (element.type == 3 && element.staff_email != null)
        counter++;
    });

    return counter;
  }

  //	restituisce un errore nel caso in cui una camp director sia stata cancellata
  get errorCampDirector() {

    var error = false;
    if (this.currentCampStaffList != null)
      this.currentCampStaffList.forEach(element => {
        if (element.type == 3 && element.staff_email != null)
          if (typeof element.person_info != "undefined")
            if (element.person_info.deleted_at != null)
              return error = true;
      });

    return error;
  }

  get numOfCampWeeks() {

    if (this.currentCampInfo != null && typeof this.currentCampInfo.start != "undefined" &&
      this.currentCampInfo != null && typeof this.currentCampInfo.end != "undefined")
      return differenceInWeeks(parseISO(this.currentCampInfo.end), parseISO(this.currentCampInfo.start)) + 1;

    return 0;
  }

  /**
   * Restituisce se il camp corrente ha o meno una camp directore assegnata
   */
  public get currentCampHaveCampDirector() {

    let error = false;

    if (this.currentCampStaffList != null) {
      this.currentCampStaffList.forEach(staff => {

        if (this.isPersonCampDirector(staff.person_id))
          if (staff.staff_email != null && typeof staff.staff_email != "undefined")
            return error = true;
      });
    }

    return error;
  }

  public isPersonCampDirector(person_id: any) {

    let res = false;

    if (this.currentCampStaffList != null) {
      this.currentCampStaffList.forEach(element => {
        if (element.type == 3 && element.person_id == person_id) {
          res = true;
        }
      });
    }

    return res;
  }

  public isCurrentUserPersonCampDirector() {

    let user = this.userService.getCurrentUser();

    if (!user)
      return false;

    let p = user.person_info;

    return this.isPersonCampDirector(p.id);
  }

  /**
   * restituisce una persona dello staff che è camp director
   */
  public getCampDirector() {

    let campDirector = null;

    if (this.currentCampStaffList != null) {
      this.currentCampStaffList.forEach(staff => {

        if (this.isPersonCampDirector(staff.person_id))
          if (staff.staff_email != null && typeof staff.staff_email != "undefined")
            return campDirector = staff;
      });
    }

    return campDirector;
  }


  public canEditStaff() {

    if (this.userService.checkPermission('camp_edit_staff'))
      return true;

    let u = this.userService.getCurrentUser();

    if (!u)
      return false;

    let p = u.person_info;

    if (this.isPersonCampDirector(p.id)) {
      return true;
    }

    return false;
  }

  public canEditDiscount() {

    if (this.userService.checkPermission('camp_add_discount'))
      return true;

    let user = this.userService.getCurrentUser();

    if (!user)
      return false;

    let p = user.person_info;

    if (this.isPersonCampDirector(p.id)) {
      return true;
    }

    return false;
  }

  public canEditCampTravelInfo() {

    if (this.userService.checkPermission('camp_edit'))
      return true;

    let user = this.userService.getCurrentUser();

    if (!user)
      return false;

    let p = user.person_info;

    if (this.isPersonCampDirector(p.id)) {
      return true;
    }

    return false;
  }

  /* API di esportazione di tutti i campi */
  exportAll(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.post('camp/export', model).subscribe(
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      });
  }




  getAllCampsInfo(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let query: any;
    query = "all";

    this.backend.post('camp/search', query).subscribe(
      (response) => {

        let resJson: any = response;
        this.allCamps = resJson.data;

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );

  }

  getAllCampsCalendar(searchModel: any, callbackSuccess: any = null, callbackError: any = null) {
    this.startRequest();

    this.backend.post('camp/calendar/search', searchModel).subscribe(
      (response) => {

        let resJson: any = response;

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(resJson);

      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }


  getCampInfoAll(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('camp/' + this.currentCampId + '/all', params).subscribe(
      (response) => {

        let resJson: any = response;

        this.currentCampInfo = resJson;
        this.currentCampId = resJson.id;
        this.currentCampStaffList = resJson.staff;
        this.currentCampDiaryList = resJson.diary;
        this.currentCampSubscriberList = resJson.subscribers;
        this.currentCampGroups = resJson.groups;
        this.currentDocumentList = resJson.documents;
        this.currentShipmentList = resJson.shipments;

        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  saveCampInfo(model: any[], callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('camp', model).subscribe(
      (response) => {

        let resJson: any = response;
        this.currentCampInfo = resJson;
        this.currentCampId = resJson.id;
        this.subjectToUpdate.next(true);

        this.notify.success("Camp Info", "successfully saved");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );
  }

  updateCampInfo(model: any[], callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('camp/' + this.currentCampId, model).subscribe(
      (response) => {

        this.currentCampInfo = response;
        this.currentCampId = this.currentCampInfo.id;
        this.subjectToUpdate.next(true);

        this.notify.success("Camp Info", "successfully updated");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        console.log("Errore");
        console.log(error);

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );
  }

  deleteCampInfo(model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('camp/' + this.currentCampId).subscribe(
      (response) => {

        this.resetService();
        this.subjectToUpdate.next(true);

        this.notify.success("Camp Info", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }




  saveCampDiary(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('camp/' + this.currentCampId + '/diary', model).subscribe(
      (response) => {

        var diary: any = response;

        if (this.currentCampDiaryList == null)
          this.currentCampDiaryList = {};

        this.currentCampDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updateCampDiary(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('camp/' + this.currentCampId + '/diary/' + model.id, model).subscribe(
      (response) => {

        var diary: any = response;

        if (this.currentCampDiaryList == null)
          this.currentCampDiaryList = {};

        this.currentCampDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteCampDiary(model: any) {

    this.startRequest();

    this.backend.destroy('camp/' + this.currentCampId + '/diary/' + model.id).subscribe(
      (response) => {

        var diary: any = response;

        if (this.currentCampDiaryList == null)
          this.currentCampDiaryList = {};

        this.currentCampDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }







  saveCampStaffMember(model: any, callbackSuccess: any = null, callbackError: any = null) {
    this.startRequest();

    this.backend.post('camp/' + this.currentCampId + '/staff', model).subscribe(
      (response) => {

        let resJson: any = response;

        this.currentCampStaffList.push(resJson);

        this.subjectToUpdate.next(true);

        this.notify.success("Camp Staff Member", "successfully saved");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );

  }



  updateCampStaffMember(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('camp/' + this.currentCampId + '/staff/' + model.id, model).subscribe(
      (response) => {

        let resJson: any = response;

        var found = false;
        for (var i = 0; i < this.currentCampStaffList.length && !found; i++) {
          if (this.currentCampStaffList[i].id == model.id) {
            this.currentCampStaffList[i] = resJson;
            found = true;
          }
        }

        this.subjectToUpdate.next(true);

        this.notify.success("Camp Staff Member", "successfully updated");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        console.log("Errore");
        console.log(error);

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );
  }



  deleteCampStaffMember(model: any, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('camp/' + this.currentCampId + '/staff/' + model.id).subscribe(
      (response) => {

        var found = false;
        for (var i = 0; i < this.currentCampStaffList.length && !found; i++) {
          if (this.currentCampStaffList[i].id == model.id) {
            found = true;
            this.currentCampStaffList.splice(i, 1);
          }
        }

        this.subjectToUpdate.next(true);

        this.notify.success("Camp Staff Member", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }



  getFileUploadHeader() {
    return this.backend.setupAuthHttpHeaders().headers;
  }

  uploadedFileResponse(item: any, response: any, status: any, id: String) {

    if (status === 200) {
      this.notify.success("Document File", 'successfully uploaded');
    }
    else {
      if (status === 422 || status === 400) {
        this.notify.error('Document File', JSON.parse(response)['message']);
      }
      else
        this.notify.error('Avatar File', response);

      this.backend.showErrors(response);
    }

  }


  createPdfStaffDocument(model: any, name: string, callbackSuccess: any = null, callbackError: any = null) {
    this.backend.download('camp/' + this.currentCampId + '/staff/' + model.id + '/document/template/' + name, name + '-' + model.person_info.surname + '-' + model.person_info.first_name + '.pdf');
  }


  getStaffDocumentFileUploadURL(model: any, type: any) {
    return (environment.apiUrl + 'camp/' + this.currentCampId + '/staff/' + model.id + '/document/upload/' + type);
  }


  getAllDocumentForStaffMember(model: any, callbackSuccess: any = null, callbackError: any = null) {
    let params = new HttpParams();

    this.backend.get('camp/' + this.currentCampId + '/staff/' + model.id + '/document/all', params).subscribe(
      (response) => {

        let res: any = response;

        if (callbackSuccess != null)
          callbackSuccess(res);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      }
    );
  }


  sendEmailStaffMemberInfoTo(model: any, email: string, callbackSuccess: any = null, callbackError: any = null) {
    this.startRequest();

    let params = new HttpParams();

    this.backend.get('camp/' + this.currentCampId + '/staff/' + model.id + '/transferdetail/mailto/' + email, params).subscribe(
      (response) => {

        // var resJson : any = response;

        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  sendWhatsappStaffMemberInfoTo(model: any, phone: string, message: string, callbackSuccess: any = null) {

    let url = "https://api.whatsapp.com/send?phone=" + phone + "&text=" + message;

    window.open(url, "_blank");

    if (callbackSuccess)
      callbackSuccess();
  }



  deleteDocumentForStaffMember(model: any, idDocument: any, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('camp/' + this.currentCampId + '/staff/' + model.id + '/document/' + idDocument).subscribe(
      (response) => {

        this.finishRequest();

        this.notify.success("Staff Document", "successfully deleted");

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {

        this.finishRequest();
      }
    );

  }

  sendEmailSubscriberInfo(model: any, email: string, callbackSuccess: any = null, callbackError: any = null) {
    this.startRequest();

    let params = new HttpParams();

    this.backend.get('camp/' + this.currentCampId + '/subscriber/' + model.id + '/mailto/' + email, params).subscribe(
      (response) => {

        // var resJson : any = response;

        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }




  //	recupero le informazioni che arrivano dal form esterno degli iscritti al campo
  getSignupFormsInfo(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('camp/' + this.currentCampId + '/signupforms', params).subscribe(
      (response) => {

        let resJson: any = response;

        //	Ordino l'array in base a se le richieste sono stati già inserite o meno come utenti
        resJson.sort(function (a, b) {
          return a.registered > b.registered ? 1 : a.registered < b.registered ? -1 : 0
        }
        );

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });

  }



  //  recupero le informazioni delle richieste di pagamento
  getPaymentRequests(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('camp/' + this.currentCampId + '/paymentrequests', params).subscribe(
      (response) => {

        let resJson: any = response;

        //  Ordino l'array in base alle richieste se sono attive o meno

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );

  }





  getLeafletFileUploadURL(model: any) {
    return (environment.apiUrl + 'camp/' + this.currentCampId + '/document/leaflet/upload');
  }
  getConditionFileUploadURL(model: any) {
    return (environment.apiUrl + 'camp/' + this.currentCampId + '/document/condition/upload');
  }
  getVrequestFileUploadURL(model: any) {
    return (environment.apiUrl + 'camp/' + this.currentCampId + '/document/vrequest/upload');
  }
  deleteLeaflet(model: any, callbackSuccess = null, callbackError = null) {

    this.startRequest();

    this.backend.destroy('camp/' + this.currentCampId + '/leaflet').subscribe(
      (response) => {

        this.notify.success("Leaflet", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );

  }
  deleteVrequest(model: any, callbackSuccess = null, callbackError = null) {
    this.startRequest();

    this.backend.destroy('camp/' + this.currentCampId + '/vrequest').subscribe(
      (response) => {

        this.notify.success("Venue Request", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );
  }
  deleteCondition(model: any, callbackSuccess = null, callbackError = null) {
    this.startRequest();

    this.backend.destroy('camp/' + this.currentCampId + '/condition').subscribe(
      (response) => {

        this.notify.success("Conditions & Agreements", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );
  }




  /* API di esportazione di tutti i campi */
  exportSubscribers(model: any, callbackSuccess: any = null, callbackError: any = null) {

    let params = new HttpParams();

    this.backend.get('camp/' + this.currentCampId + '/subscriber/export', params).subscribe(
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      });
  }



  getCampSubscriber(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('camp/' + this.currentCampId + '/subscriber', params).subscribe(
      (response) => {

        var subscriber: any = response;

        this.currentCampSubscriberList = subscriber;

        this.subjectToUpdateCampSubscriberList.next(true);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }




  saveCampSubscriber(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('camp/' + this.currentCampId + '/subscriber', model).subscribe(
      (response) => {

        var subscriber: any = response;

        if (this.currentCampSubscriberList == null)
          this.currentCampSubscriberList = [];

        this.currentCampSubscriberList = subscriber;

        this.subjectToUpdate.next(true);

        this.notify.success("Subscriber", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updateCampSubscriber(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('camp/' + this.currentCampId + '/subscriber/' + model.id, model).subscribe(
      (response) => {

        var subscriber: any = response;

        if (this.currentCampSubscriberList == null)
          this.currentCampSubscriberList = [];

        this.currentCampSubscriberList = subscriber;
        this.subjectToUpdate.next(true);

        this.notify.success("Subscriber", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updateMultiCampSubscriber(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('camp/' + this.currentCampId + '/subscribers', model).subscribe(
      (response) => {

        var subscriber: any = response;

        if (this.currentCampSubscriberList == null)
          this.currentCampSubscriberList = [];

        this.currentCampSubscriberList = subscriber;
        this.subjectToUpdate.next(true);

        this.notify.success("Subscribers", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteCampSubscriber(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.destroy('camp/' + this.currentCampId + '/subscriber/' + model.id).subscribe(
      (response) => {

        var subscriber: any = response;

        if (this.currentCampSubscriberList == null)
          this.currentCampSubscriberList = [];

        this.currentCampSubscriberList = subscriber;
        this.subjectToUpdate.next(true);

        this.notify.success("Subscriber", "successfully deleted");

        if (callbackSuccess)
          callbackSuccess();

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  restoreCampSubscriber(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.destroy('camp/' + this.currentCampId + '/subscriber/' + model.id + "/restore").subscribe(
      (response) => {

        var subscriber: any = response;

        if (this.currentCampSubscriberList == null)
          this.currentCampSubscriberList = [];

        this.currentCampSubscriberList = subscriber;
        this.subjectToUpdate.next(true);

        this.notify.success("Subscriber", "successfully restored");

        if (callbackSuccess)
          callbackSuccess();

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }




  findPerson(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.post('camp/' + this.currentCampId + '/signupforms/find', model).subscribe(
      (response) => {

        var results: any = response;

        if (callbackSuccess)
          callbackSuccess(results);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }


  ignoreCampSignupForm(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('camp/' + this.currentCampId + '/signupforms/' + model.signup_form_id + '/ignore', model).subscribe(
      (response) => {

        var results: any = response;

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(results);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }


  saveCampGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('camp/' + this.currentCampId + '/group', model).subscribe(
      (response) => {

        this.currentCampGroups = response;

        this.subjectToUpdate.next(true);

        this.notify.success("Group", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }


  updateCampGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('camp/' + this.currentCampId + '/group/' + model.id, model).subscribe(
      (response) => {

        this.currentCampGroups = response;

        this.getCampSubscriber(() => {

          this.subjectToUpdate.next(true);

          this.notify.success("Group", "successfully updated");

          this.finishRequest();

          if (callbackSuccess)
            callbackSuccess();
        })

      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteCampGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.destroy('camp/' + this.currentCampId + '/group/' + model.id).subscribe(
      (response) => {

        this.currentCampGroups = response;

        this.getCampSubscriber(() => {

          this.subjectToUpdate.next(true);

          this.notify.success("Group", "successfully deleted");

          if (callbackSuccess)
            callbackSuccess();

          this.finishRequest();
        })

      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }



  saveCampSubscriberGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.post('camp/' + this.currentCampId + '/subscriber/group', model).subscribe(
      (response) => {

        if (callbackSuccess)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }


  updateCampSubscriberGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.put('camp/' + this.currentCampId + '/subscriber/group/' + model.id, model).subscribe(
      (response) => {

        if (callbackSuccess)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteCampSubscriberGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.destroy('camp/' + this.currentCampId + '/subscriber/group/' + model.id).subscribe(
      (response) => {

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }

  public downloadCampStaff(camp_id: any = null, camp_code: any = null) {

    if (!camp_id) {
      camp_id = this.currentCampId;
    }

    if (!camp_code) {
      camp_code = this.currentCampInfo['camp_code']
    }

    this.backend.download('camp/' + camp_id + '/campstaff/pdf', camp_code + '.pdf');
  }





  getCampShipment(callbackSuccess: any = null, callbackError: any = null) {
    let params = new HttpParams();

    this.backend.get('camp/' + this.currentCampId + '/shipment', params).subscribe(
      (response) => {

        var resJson: any = response;

        this.finishRequest();

        this.currentShipmentList = resJson;

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        console.log("Errore");

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );

  }


  saveCampShipment(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    model.holder_type = "App\\Camp";
    model.holder_id = this.currentCampId;

    this.shipmentService.saveShipment(model, (shipment) => {

      if (this.currentShipmentList == null)
        this.currentShipmentList = [];

      this.currentShipmentList.push(shipment);
      this.subjectToUpdate.next(true);

      this.finishRequest();

      if (callbackSuccess)
        callbackSuccess(shipment);
    }, (error) => {
      this.finishRequest();

      if (callbackError)
        callbackError();
    });

  }

  updateCampShipment(model: any, callbackSuccess: any = null, callbackError: any = null) {
    this.startRequest();

    this.shipmentService.updateShipment(model, (shipment) => {

      for (var key in this.currentShipmentList)
        if (this.currentShipmentList[key].id == shipment.id)
          this.currentShipmentList[key] = shipment;

      this.subjectToUpdate.next(true);

      this.finishRequest();

      if (callbackSuccess)
        callbackSuccess(shipment);
    }, (error) => {

      this.finishRequest();

      if (callbackError)
        callbackError();
    })
  }

  deleteCampShipment(model: any, callbackSuccess: any = null, callbackError: any = null) {
    this.startRequest();

    this.shipmentService.deleteShipment(model, (shipment) => {

      for (var key in this.currentShipmentList)
        if (this.currentShipmentList[key].id == model.id) {
          this.currentShipmentList.splice(parseInt(key), 1);
          break;
        }

      this.subjectToUpdate.next(true);

      this.finishRequest();

      if (callbackSuccess)
        callbackSuccess();
    }, (error) => {

      this.finishRequest();

      if (callbackError)
        callbackError();
    })
  }


  getReportEtaSubscribers(callbackSuccess = null, callbackError = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('camp/report/etasubscribers', params).subscribe(
      (response) => {

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }



  getReportCampMembers(searchModel: any, callbackSuccess = null, callbackError = null) {

    this.startRequest();

    this.backend.post('camp/report/campmembers', searchModel).subscribe(
      (response) => {

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }


  getPersonCampTransferDetails(model: any, params: HttpParams, callbackSuccess = null, callbackError = null) {

    this.startRequest();

    this.backend.post('camp/transferdetails', model, params).subscribe(
      (response) => {

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  getLastCampTransferDetails(callbackSuccess = null, callbackError = null) {

    this.startRequest();

    this.backend.post('camp/transferdetails/last', []).subscribe(
      (response) => {

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  getAllCampsIamTutor(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let query: any;

    this.backend.post('camp/iamtutor', query).subscribe(
      (response) => {

        let resJson: any = response;

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(resJson.data);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );

  }


}
