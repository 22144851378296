import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HelperService, ConfigService, WarehouseService, UserService, CourseScheduleService } from './../../_services/index';
import { NotificationsService } from 'angular2-notifications';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-course-schedule-groups',
  templateUrl: './course-schedule-groups.component.html',
  styleUrls: ['./course-schedule-groups.component.css']
})
export class CourseScheduleGroupsComponent implements OnInit {

  @ViewChild('groupModal', {static: false}) public groupModal: ModalDirective;
  @ViewChild('courseScheduleGroupOrganizerTab', {static: true}) courseScheduleGroupOrganizerTab: TabsetComponent;

  courseScheduleGroupList: any[] = [];
  subscriberGroupList: any[] = [];
  staffGroupList: any[] = [];

  subscribe: any;

  selected = [] = [];

  courseScheduleWeeks: number = 0;

  libri: any;

  constructor(private courseScheduleService: CourseScheduleService, private helper: HelperService, private userService: UserService,
              private configService: ConfigService, private notify: NotificationsService, private warehouseService: WarehouseService) { }

  ngOnInit() {

    this.subscribe = this.courseScheduleService.getsubjectToUpdateObservable().subscribe((update: boolean) =>  {
      this.getGroupsInfo();
    });

    if (this.courseScheduleService.currentCourseGroups != null && this.courseScheduleService.currentCourseSubscriberList != null)
      this.getGroupsInfo();
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  getGroupsInfo () {

    if (this.courseScheduleService.currentCourseGroups != null &&
      this.courseScheduleService.currentCourseSubscriberList != null &&
      this.courseScheduleService.currentCourseStaffList != null) {

      this.courseScheduleGroupList = this.courseScheduleService.currentCourseGroups;
      this.prepareWeekSubscribers();
      this.prepareWeekStaff();
    }
  }

  prepareWeekSubscribers ()
  {
    //  per ogni settimana del campo setto i0 subscriber che ne sono iscritti
    var subs = JSON.parse(JSON.stringify(this.courseScheduleService.currentCourseSubscriberList.filter(x => !x.deleted_at)));

    //  per ogni iscritto della settimana prendo solo la settimana corrispondente
    subs.forEach (subscriber => {

      let group = undefined;

      //  controllo che ci sia un gruppo per questo subscriber
      if (typeof subscriber.groups != "undefined" && subscriber.groups != null)
        group = JSON.parse(JSON.stringify(this.courseScheduleGroupList.find(x => x.id == subscriber.groups.course_schedule_group_id)))

      //  se trovo il gruppo lo assegno
      if (typeof group != "undefined" && group != null) {
        subscriber.group = group;
        subscriber.group.group_id = subscriber.groups.id;
        subscriber.group.subscriber_id = subscriber.id;
        subscriber.group.name = group.group_name;
        subscriber.group.course_schedule_group_id = subscriber.groups.course_schedule_group_id;
      }

      //  se il gruppo non c'è lo inizializzo comunque
      if (typeof subscriber.group == "undefined") { subscriber.group = {}; subscriber.group.name = "";}

    });

    //  inserisco la settimana nell'array
    this.subscriberGroupList = subs;
  }

  prepareWeekStaff ()
  {
    //  per ogni settimana del campo setto i membri dello staff che ne sono iscritti
    var subs = JSON.parse(JSON.stringify(this.courseScheduleService.currentCourseStaffList.filter(x => !x.deleted_at)));

    //  per ogni iscritto della settimana prendo solo la settimana corrispondente
    subs.forEach (staff => {

      let group = undefined;

      //  controllo che ci sia un gruppo per questo membro dello staff
      if (typeof staff.groups != "undefined" && staff.groups != null) {
        let groupCheck = JSON.parse(JSON.stringify(this.courseScheduleGroupList.find(x => x.id == staff.groups.course_schedule_group_id)));
        if (typeof groupCheck != "undefined")
          group = JSON.parse( JSON.stringify(groupCheck));
      }

      //  se trovo il gruppo lo assegno
      if (typeof group != "undefined" && group != null) {
        staff.group = group;
        staff.group.group_id = staff.groups.id;
        staff.group.staff_member_id = staff.id;
        staff.group.name = group.group_name;
        staff.group.course_schedule_group_id = staff.groups.course_schedule_group_id;
      }

      //  se il gruppo non c'è lo inizializzo comunque
      if (typeof staff.group == "undefined") { staff.group = {}; staff.group.name = "";}

    });

    //  inserisco la settimana nell'array
    this.staffGroupList = subs;
  }


  // GROUPS & MODAL

  newGroup () {
    this.showModalGroup();
  }

  onGroupSelect({ selected }) {

    if (!this.userService.checkPermission("course_schedule_edit_group"))
      return;

    Object.assign(this.model_group, selected[0]);
    this.showModalGroup();
  }

  model_group: any = {};

  isModalGroup: boolean = false;

  showModalGroup(): void {
    this.isModalGroup = true;
  }

  hideModalGroup(): void {
    this.groupModal.hide();
  }

  onHiddenGroup(): void {
    this.isModalGroup = false;
    this.model_group = {};
  }

  submit() {

    if (this.model_group.id) {

      //  aggiorno il gruppo
      this.courseScheduleService.updateCourseGroup(this.model_group, () => {
        this.hideModalGroup();
      })
    } else {
      this.courseScheduleService.saveCourseGroup(this.model_group, () => {
        this.hideModalGroup();
      })
    }
  }

  destroyGroup() {

    if (this.subscriberGroupList.filter(x => x.group.course_schedule_group_id == this.model_group.id).length > 0) {
      this.notify.error("Group Info", "There are one or more children in this group");
      return;
    }
    else
    if (this.staffGroupList.filter(x => x.group.course_schedule_group_id == this.model_group.id).length > 0) {
      this.notify.error("Group Info", "There are one or more member of staff in this group");
      return;
    }
    else {
      // cancello il gruppo
      // this.courseScheduleService.deleteCourseGroup(this.model_group, () => {
      //   this.hideModalGroup();
      // })
        this.hideModalGroup();
      return;
    }

  }

  isOngoingRequest() {
    return this.courseScheduleService.isOngoingRequest()
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

  getWarehouseService() {
    return this.warehouseService
  }

}
