import { NotificationsService } from 'angular2-notifications';
import { PersonService } from './../../_services/index';
import { PersonDiaryFormComponent } from './../person-diary-form/person-diary-form.component';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-person-diary-list',
  templateUrl: './person-diary-list.component.html',
  styleUrls: ['./person-diary-list.component.css']
})
export class PersonDiaryListComponent implements OnInit {

  @ViewChildren('personDiaryFormList') public personDiaryFormList: QueryList<PersonDiaryFormComponent>;

  personDiaryList: any;
  personDiaryListKey: any[];

  allFormValid = true;
  multisave = true;

  subscribe: any;

  constructor(private personService: PersonService, private notify: NotificationsService) { }

  ngOnInit() {

    this.personDiaryList = this.personService.currentPersonDiaryList;

    if(this.personDiaryList == null)
      this.addNew();

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      if (this.personService.currentPersonDiaryList != null) {

        let newFormModel = null;
        if (typeof this.personDiaryFormList.first.model.id == "undefined"
          && this.personDiaryFormList.first.currentForm.form.dirty
          && this.personDiaryFormList.first.currentForm.form.pending){
            newFormModel = this.personDiaryFormList.first.model;
        }

        this.personDiaryList = this.personService.currentPersonDiaryList;
        this.personDiaryListKey = Object.keys(this.personService.currentPersonDiaryList).reverse();

        //  Ordino le chiavi dell'array in base al valore di aggiornamento del model
        this.personDiaryListKey = this.OrderByUpdate(this.personDiaryListKey, this.personDiaryList);
        
        //  Controllo che non ci sia un elemento attualmente in modifica
        let personDiaryListOld = this.personDiaryList;
        this.personDiaryListKey.forEach(k => {

          if (typeof personDiaryListOld[k] != "undefined" && this.personDiaryList[k]["updated_at"] == personDiaryListOld[k]["updated_at"])
            this.personDiaryList[k] = personDiaryListOld[k];

        });

        if (newFormModel != null) {
          this.personDiaryList[0] = newFormModel;
          this.personDiaryListKey.unshift(0);
        }

      }

      if (this.personService.currentPersonDiaryList != null && this.personService.currentPersonDiaryList.length == 0) {
        this.addNew();
      }

    });
    
  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }


  addNew() {

    if (this.personDiaryList == null) {
      this.personDiaryList = {};
      this.personDiaryListKey = new Array();
    }
    this.personDiaryList[0] = {};
    this.personDiaryListKey.unshift(0);
  }


  saveAll () {

    this.allFormValid = true;

    //  Controllo se tutti i form sono validi
    this.personDiaryFormList.forEach(element => {

      if (!element.currentForm.form.valid) {

        //  Controllo se non è valido
        for (var i in element.currentForm.form.controls) {
          element.currentForm.controls[i].markAsTouched();
        }

        this.allFormValid = false;
        this.notify.error("There is a form with error");
      }

    });

    if (!this.allFormValid)
      return;

      this.personDiaryFormList.forEach(element => {
        element.submit();
      });
  }

  formDelete (event) {
    if(!event.model.id && this.personDiaryListKey.length > 1) {
      this.personDiaryListKey.splice(0, 1);
      delete this.personDiaryList[0];
    }
  }

  formSaved (event) {
    
  }


  OrderByUpdate( listKey: any[], listValue: any) : any[]
  {
    
    let arrayTmp = new Array();

    listKey.forEach(key => { arrayTmp.push(listValue[key]) });

    arrayTmp.sort(function(a, b){
      return a.updated_at > b.updated_at ? -1 : a.updated_at < b.updated_at ? 1 : 0 }
    );

    let array = new Array();

    arrayTmp.forEach(element => {
      array.push(element.id);
    });

    return array;
  }

}
