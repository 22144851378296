import { Component, OnInit, Input } from '@angular/core';
import { TheatrinoService } from './../../_services/theatrino.service';
import { UserService, HelperService } from 'app/_services';
import { ConfigService } from './../../_services/config.service';
import { parseISO } from 'date-fns';
import { Router } from '@angular/router';

declare var jQuery:any;

@Component({
  selector: 'app-theatrino-widget-convenzioni',
  templateUrl: './theatrino-widget-convenzioni.component.html',
  styleUrls: ['./theatrino-widget-convenzioni.component.css']
})
export class TheatrinoWidgetConvenzioniComponent implements OnInit {

  //  model della ricerca
  model: any = {};

  @Input() idTheatrino;

   // generic rows
  rows: any  = null;

  dateTimeline = null;

  constructor(private theatrinoService: TheatrinoService, private router: Router, private userService: UserService, private configService: ConfigService, private helper: HelperService) { }

  ngOnInit() {

    this.updateWidget();
  }

  updateWidget(){

    this.theatrinoService.getReportWidgetConvenzioni(this.idTheatrino, this.model, (results) => {

      this.rows = JSON.parse(JSON.stringify(results));

      this.rows.start_date_formatted = parseISO(this.rows.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
      this.rows.end_date_formatted = parseISO(this.rows.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

      this.rows.tour_date.forEach(element => {

        // if (typeof this.dateTimeline[element.date] == "undefined"){
        //   this.dateTimeline[element.date] = [];
        // }

        element.date_formatted =  parseISO(element.date).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

        if (element.type == 2 && element.status != 4){

          if (!this.dateTimeline)
            this.dateTimeline = [];

          this.dateTimeline.push(element);
        }
      });

      // console.log(this.rows);
      // console.log(this.dateTimeline);
    })
  }

  getUserService(){
    return this.userService;
  }

  editTourDate(id) {
    //   Vado al component per editare la corrente data dal tour
    this.router.navigate(["theatrino/" + this.theatrinoService.currentTheatrinoId + "/tour/edit/" + id]);
  }

  getConfigService(){
    return this.configService;
  }
}
