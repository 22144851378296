import { EnterpriseDatatableComponent } from './../../enterprise/enterprise-datatable/enterprise-datatable.component';
import { ViewChild, ViewChildren, QueryList, Input } from '@angular/core';
import { TheatrinoService, HelperService } from './../../_services/index';
import { Component, OnInit } from '@angular/core';
import { parseISO } from "date-fns"
import { TheatrinoAccomodationMultiFormModalComponent } from './../../theatrino/theatrino-accomodation-multi-form-modal/theatrino-accomodation-multi-form-modal.component';

@Component({
  selector: 'app-theatrino-tour-accomodation-list',
  templateUrl: './theatrino-tour-accomodation-list.component.html',
  styleUrls: ['./theatrino-tour-accomodation-list.component.css']
})
export class TheatrinoTourAccomodationListComponent implements OnInit {

  @Input() accomodationModal: TheatrinoAccomodationMultiFormModalComponent;

  tourList: any;
  tourListKeys: any[];
  accomodationList: any = [];

  rows: any[] = [];
  selected = [];


  constructor(private theatrinoService: TheatrinoService, private helper: HelperService) { }

  subscribe: any;

  ngOnInit() {

    this.tourList = this.theatrinoService.currentTourInfoList;

    this.subscribe = this.theatrinoService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      if (this.theatrinoService.currentTourInfoList == null)
        this.tourList = {};
      else
        this.tourList = this.theatrinoService.currentTourInfoList;

      //  Elimino i duplicati dalla lista delle date
      // this.tourList = this.RemoveDuplicateDate(this.tourList);

      //  Creo la lista in base alle chiavi
      this.tourListKeys = Object.keys(this.tourList);

      //  Setto le date formattate correttamente
      this.tourListKeys.forEach(key => {

        if (this.tourList[key].date != null)
          this.tourList[key].date_formatted = parseISO(this.tourList[key].date).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
      });

      //  Setto le righe
      this.rows = this.getTableData(this.tourList);

    });
  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }

  RemoveDuplicateDate(array: any[])
  {
    var check = [];
    var filtered = [];
    array.filter(function(elem) {
      if (check.indexOf(elem.date) == -1)
      {
        check.push(elem.date);
        filtered.push(elem);
        return true;
      }
      return false;
    });

    return filtered;
  }

  getTableData(list) {

    var data = new Array();

      for (let key in list) {

        if (list[key].type == 5)
        {
          //  Clono l'oggeto perchè è un elemento immutabile
          var d = Object.assign({}, list[key]);

          //  Assegno una chiave di navigazione dell'elemento
          d.key = key;

          data.push(d);
        }

      }

      return data;
  }


  onSelect({ selected }) {
    this.accomodationModal.showAccomodationModal(selected[0]);
  }

  onActivate(event){
  }

  getSelectedIx() {
    //return this.selected[0]['$$index'];
  }

  getTheatrinoService() {
    return this.theatrinoService
  }

}
