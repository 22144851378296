<!-- BEGIN: Gestione di una scadenza-->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">

    <div class="row">
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid && !type.disabled }">
          <label class="control-label">Type of expiration <span class="required">*</span></label>
          <select [disabled]="getVehicleService().isOngoingRequest()" class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
            <option *ngFor="let p of getConfigService().vehicleExpirationTypeSelect" [value]="p.key">{{p.value}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalData())) }">
          <label class="control-label">Date of expiration <span class="required">*</span></label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_expiration_date="angular-mydatepicker" name="expiration_date" class="form-control"
                [locale]="getHelper().locale"
                [disabled]="getVehicleService().isOngoingRequest()"
                [options]="getDatePickerOptions()"
                [ngModel]="getLocalData()"
                (dateChanged)="onDateChanged($event)"
                required
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.expiration_date" class="btn default" (click)="dp_expiration_date.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_expiration_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block">Field required</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Annotation</label>
          <textarea [disabled]="getVehicleService().isOngoingRequest()" name="annotation" [(ngModel)]="model.annotation" #annotation="ngModel" rows="3" class="form-control"></textarea>
        </div>
      </div>
    </div>

  </div>

  <div class="form-actions right">
    <img *ngIf="getVehicleService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="deleteExpirations();">Yes Delete!</button></ng-template>
    <button type="button" class="btn btn-outline red" *ngIf="model.id" [disabled]="getVehicleService().isOngoingRequest()"
        [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="submit" [disabled]="getVehicleService().isOngoingRequest()" class="btn blue" *ngIf="model.id">Update</button>
    <button type="submit" [disabled]="getVehicleService().isOngoingRequest()" class="btn green-jungle" *ngIf="!model.id">Save</button>
  </div>

</form>
<!-- END: Gestione di una scadenza -->
