import { ConfigService, PersonService, AddressService, HelperService } from '../../_services/index';
import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-person-search-form',
  templateUrl: './person-search-form.component.html',
  styleUrls: ['./person-search-form.component.css']
})
export class PersonSearchFormComponent implements OnInit {

  model: any = { advancedSearchOn: false, simpleSearchKeyword: "" };

  private firstSubmit: boolean = false;

  private subject: any;

  public advancedSearchShow = false;

  regioniArray: any;
  provinceArray: any;

  @Output() searchKeywordsSubmit = new EventEmitter();

  @Input() ongoingRequest: boolean;

  @ViewChild('simpleSearchKeywordText', {static: true, read: ElementRef}) private searchInput: ElementRef;

  constructor(private route: ActivatedRoute, private router: Router, private configService: ConfigService,
              private personService: PersonService, private addressService: AddressService,
              private helperService: HelperService) {

    this.regioniArray = this.addressService.getRegioni();
    this.provinceArray = this.addressService.getProvince();
  }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {

      if (typeof params.advancedSearchOn != "undefined"){

          if (this.personService.lastSearchQueries != null)
            this.model = this.personService.lastSearchQueries;
      }

    });

  }

  //   quando la vista si è inizializzata setto il focus sul campo di ricerca
  ngAfterViewInit() {

    setTimeout(() => {
      if (typeof this.searchInput != "undefined")
        this.searchInput.nativeElement.focus();
    }, 600);

  }

  ngOnDestroy ()
  {
    this.subject.unsubscribe();
  }


  fieldChange(event) {

    if (!this.firstSubmit)
      return;

    this.searchKeywordsSubmit.emit({});
  }

  submitSearch() {

    //  Elimino le chiavi che non hanno valore prima di fare le ricerche
    Object.keys(this.model).forEach(element => {
      if (!this.model[element])
        delete this.model[element];
    });

    //  memorizzo i parametri della ricerca attuale
    this.personService.lastSearchQueries = JSON.parse(JSON.stringify(this.model));

    this.firstSubmit = true;

    if (this.advancedSearchShow)
      this.model.advancedSearchOn = true;
    else {

      // se sono nella ricerca semplice e scrivo un'email simulo la ricerca avanzata tramite account
      if (this.helperService.validateEmail(this.model.simpleSearchKeyword)){
        this.model.advancedSearchOn = true;
        this.model.account = this.model.simpleSearchKeyword;
      }
      else {
        this.model = { advancedSearchOn: false, simpleSearchKeyword: this.model.simpleSearchKeyword };
      }
    }

    this.advancedSearchShow = false;
    this.searchKeywordsSubmit.emit(this.model);
  }

  hideAdvancedSearch() {
    this.advancedSearchShow = false;
  }

  clearAdvancedSearch() {

    //  resetto il modello e tengo aperto il form di ricerca
    this.model = { advancedSearchOn: false, simpleSearchKeyword: "" };
    this.showAdvancedSearch();
  }

  showAdvancedSearch() {
    this.advancedSearchShow = true;
    this.model.simpleSearchKeyword = "";
  }

  getConfigService() {
    return this.configService
  }

}
