import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class AdminReportsService {

  //	Memorizza l'ultima query di ricerca fatta
	lastSearchQueries: any = null;


	ongoingRequest: boolean = false;


	private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

	constructor(private backend: BackendService, private notify: NotificationsService) { }

	getsubjectToUpdateObservable(): Observable<boolean> {
		return this.subjectToUpdate.asObservable();
  }

  resetService() {

  }

  startRequest() {
		this.ongoingRequest = true;
	}

	finishRequest() {
		this.ongoingRequest = false;
	}

	isOngoingRequest() {
		return this.ongoingRequest;
  }

  getReport(name: string, model: any, callbackSuccess = null, callbackError = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.post('admin/report/'+name, model, params).subscribe(
			(response) => {

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess(response);
			},
			(error) => {
				console.log("Errore");

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
  }

}
