<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

	<div class="form-body">
    	<h3 class="form-section">Availability</h3>

    	<fieldset>
    		<legend>Tutor</legend>

    		<div class="row">

    			<div class="col-md-6">
					<div class="form-group">
						<label class="control-label">From</label>
						  <div class="input-group">
							<!-- input box -->
							<input angular-mydatepicker #dp_tutorFromInput="angular-mydatepicker" name="tutorFromInput" class="form-control" 
							  [locale]="getHelper().locale" 
							  [disabled]="getPersonService().isOngoingRequest()"
							  [options]="getDatePickerOptions()"
							  [ngModel]="tutorFrom" 
							  (dateChanged)="onTutorFromDateChanged($event)" 
							/>
							<!-- toggle calendar button -->
							<span class="input-group-btn">
								<button type="button" class="btn default" (click)="dp_tutorFromInput.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
							</span>
						</div>
					</div>
		      	</div>

		      	<div class="col-md-6">
					<div class="form-group">
						<label class="control-label">To</label>
						  <div class="input-group">
							<!-- input box -->
							<input angular-mydatepicker #dp_tutorToInput="angular-mydatepicker" name="tutorToInput" class="form-control" 
							  [locale]="getHelper().locale" 
							  [disabled]="getPersonService().isOngoingRequest()"
							  [options]="getDatePickerOptions()"
							  [ngModel]="tutorTo" 
							  (dateChanged)="onTutorToDateChanged($event)" 
							/>
							<!-- toggle calendar button -->
							<span class="input-group-btn">
								<button type="button" class="btn default" (click)="dp_tutorToInput.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
							</span>
						</div>
					</div>
		      	</div>

	  		</div>

	  		<div class="row">

	  			<div class="col-md-12">
			   		<div class="form-group">
			        	<label class="control-label">Notes</label>
			          	<textarea [disabled]="getPersonService().isOngoingRequest()" rows="4" class="form-control" id="tutorNotesInput" name="tutorNotesInput"
			          	[(ngModel)]="model.tutor_notes" ></textarea>
		          	</div>
		      	</div>

	  		</div>

    	</fieldset>

    	<fieldset>
    		<legend>Actor</legend>

    		<div class="row">

    			<div class="col-md-6">
					<div class="form-group">
						<label class="control-label">From</label>
						  <div class="input-group">
							<!-- input box -->
							<input angular-mydatepicker #dp_actorFromInput="angular-mydatepicker" name="actorFromInput" class="form-control" 
							  [locale]="getHelper().locale" 
							  [disabled]="getPersonService().isOngoingRequest()"
							  [options]="getDatePickerOptions()"
							  [ngModel]="actorFrom" 
							  (dateChanged)="onActorFromDateChanged($event)" 
							/>
							<!-- toggle calendar button -->
							<span class="input-group-btn">
								<button type="button" class="btn default" (click)="dp_actorFromInput.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
							</span>
						</div>
					</div>
		      	</div>

		      	<div class="col-md-6">
					<div class="form-group">
						<label class="control-label">To</label>
						  <div class="input-group">
							<!-- input box -->
							<input angular-mydatepicker #dp_actorToInput="angular-mydatepicker" name="actorToInput" class="form-control" 
							  [locale]="getHelper().locale" 
							  [disabled]="getPersonService().isOngoingRequest()"
							  [options]="getDatePickerOptions()"
							  [ngModel]="actorTo" 
							  (dateChanged)="onActorToDateChanged($event)" 
							/>
							<!-- toggle calendar button -->
							<span class="input-group-btn">
								<button type="button" class="btn default" (click)="dp_actorToInput.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
							</span>
						</div>
					</div>
		      	</div>

	  		</div>

	  		<div class="row">

	  			<div class="col-md-12">
			   		<div class="form-group">
			        	<label class="control-label">Notes</label>
			          	<textarea [disabled]="getPersonService().isOngoingRequest()" rows="4" class="form-control" id="actorNotesInput" name="actorNotesInput"
			          	[(ngModel)]="model.actor_notes" ></textarea>
		          	</div>
		      	</div>

	  		</div>

    	</fieldset>

    	<br>

    	<fieldset>
    		<!-- <legend>Arriving to Italy</legend> -->
    		<h3 class="form-section">Arriving to Italy</h3>

    		<fieldset>
	    		<legend>Fligth Departure</legend>

	    		<div class="row">

	    			<div class="col-md-6">
						<div class="form-group">
							<label class="control-label">Date of Departure</label>
							  <div class="input-group">
								<!-- input box -->
								<input angular-mydatepicker #dp_arrivingFligthDepartureDateInput="angular-mydatepicker" name="arrivingFligthDepartureDateInput" class="form-control" 
								  [locale]="getHelper().locale" 
								  [disabled]="getPersonService().isOngoingRequest()"
								  [options]="getDatePickerOptions()"
								  [ngModel]="arrivingFligthDeparture" 
								  (dateChanged)="onArrivingFligthDepartureDateChanged($event)" 
								/>
								<!-- toggle calendar button -->
								<span class="input-group-btn">
									<button type="button" class="btn default" (click)="dp_arrivingFligthDepartureDateInput.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
								</span>
							</div>
						</div>
	    			</div>

	    			<div class="col-md-6">
		    			<div class="form-group">
				        	<label class="control-label">Departing from</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="arrivingFligthDepartureFromInput"
				          	name="arrivingFligthDepartureFromInput" [(ngModel)]="model.arriving_fligth_departure_from" />
				    	</div>
	    			</div>

	    		</div>

	    		<div class="row">

	    			<div class="col-md-6">
	    				<div class="form-group">
				        	<label class="control-label">Time of Departure</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="arrivingFligthDepartureTimeInput"
				          	name="arrivingFligthDepartureTimeInput" [(ngModel)]="model.arriving_fligth_departure_time" />
				    	</div>
	    			</div>

	    			<div class="col-md-6">
	    				<div class="form-group">
				        	<label class="control-label">Fligth number</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="arrivingFligthDepartureNumberInput"
				          	name="arrivingFligthDepartureNumberInput" [(ngModel)]="model.arriving_fligth_departure_number" />
				    	</div>
	    			</div>

	    		</div>

	    	</fieldset>

	    	<fieldset>
	    		<legend>Fligth Arrival</legend>

	    		<div class="row">

	    			<div class="col-md-6">
						<div class="form-group">
							<label class="control-label">Date of Arrival</label>
							  <div class="input-group">
								<!-- input box -->
								<input angular-mydatepicker #dp_arrivingFligthArrivalDateInput="angular-mydatepicker" name="arrivingFligthArrivalDateInput" class="form-control" 
								  [locale]="getHelper().locale" 
								  [disabled]="getPersonService().isOngoingRequest()"
								  [options]="getDatePickerOptions()"
								  [ngModel]="arrivingFligthArrival" 
								  (dateChanged)="onArrivingFligthArrivalDateChanged($event)" 
								/>
								<!-- toggle calendar button -->
								<span class="input-group-btn">
									<button type="button" class="btn default" (click)="dp_arrivingFligthArrivalDateInput.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
								</span>
							</div>
						</div>
	    			</div>

	    			<div class="col-md-6">
		    			<div class="form-group">
				        	<label class="control-label">Arriving in</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="arrivingFligthArrivalFromInput"
				          	name="arrivingFligthArrivalFromInput" [(ngModel)]="model.arriving_fligth_arrival_from" />
				    	</div>
	    			</div>

	    		</div>

	    		<div class="row">

	    			<div class="col-md-6">
	    				<div class="form-group">
				        	<label class="control-label">Time of Arrival</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="arrivingFligthArrivalTimeInput"
				          	name="arrivingFligthArrivalTimeInput" [(ngModel)]="model.arriving_fligth_arrival_time" />
				    	</div>
	    			</div>

	    			<div class="col-md-6">
	    				<div class="form-group">
				        	<label class="control-label">Fligth number</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="arrivingFligthArrivalNumberInput"
				          	name="arrivingFligthArrivalNumberInput" [(ngModel)]="model.arriving_fligth_arrival_number" />
				    	</div>
	    			</div>

	    		</div>

	    	</fieldset>

    		<fieldset>
	    		<legend>Train Details</legend>

	    		<div class="row">

	    			<div class="col-md-6">
						<div class="form-group">
							<label class="control-label">Date of Departure</label>
							  <div class="input-group">
								<!-- input box -->
								<input angular-mydatepicker #dp_arrivingTrainDepartureDateInput="angular-mydatepicker" name="arrivingTrainDepartureDateInput" class="form-control" 
								  [locale]="getHelper().locale" 
								  [disabled]="getPersonService().isOngoingRequest()"
								  [options]="getDatePickerOptions()"
								  [ngModel]="arrivingTrainDeparture" 
								  (dateChanged)="onArrivingTrainDepartureDateChanged($event)" 
								/>
								<!-- toggle calendar button -->
								<span class="input-group-btn">
									<button type="button" class="btn default" (click)="dp_arrivingTrainDepartureDateInput.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
								</span>
							</div>
						</div>
	    			</div>

	    			<div class="col-md-6">
		    			<div class="form-group">
				        	<label class="control-label">Departing from</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="arrivingTrainDepartureFromInput"
				          	name="arrivingTrainDepartureFromInput" [(ngModel)]="model.arriving_train_departure_from" />
				    	</div>
	    			</div>

	    		</div>

	    		<div class="row">

	    			<div class="col-md-6">
	    				<div class="form-group">
				        	<label class="control-label">Time of Departure</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="arrivingTrainDepartureTimeInput"
				          	name="arrivingTrainDepartureTimeInput" [(ngModel)]="model.arriving_train_departure_time" />
				    	</div>
	    			</div>

	    			<div class="col-md-6">
	    				<div class="form-group">
				        	<label class="control-label">Time of Arrival into Sanremo</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="arrivingTrainSanremoArrivalTimeInput"
				          	name="arrivingTrainSanremoArrivalTimeInput" [(ngModel)]="model.arriving_train_sanremo_arrival_time" />
				    	</div>
	    			</div>

	    		</div>

	    	</fieldset>

	    	<fieldset>
	    		<legend>Travel plans</legend>

	    		<div class="row">

	    			<div class="col-md-12">

	    				<div class="form-group">
				        	<label class="control-label">Travel plans if arriving before orientation date</label>
				          	<textarea [disabled]="getPersonService().isOngoingRequest()" rows="4" class="form-control" id="arrivingTravelPlansInput"
				          	name="arrivingTravelPlansInput" [(ngModel)]="model.arriving_travel_plans" ></textarea>
			          	</div>

	    			</div>

    			</div>

    		</fieldset>

    	</fieldset>

    	<br>

    	<fieldset>
    		<!-- <legend>Leaving Italy</legend> -->
    		<h3 class="form-section">Leaving Italy</h3>

    		<fieldset>
	    		<legend>Fligth Departure</legend>

	    		<div class="row">

	    			<div class="col-md-6">
						<div class="form-group">
							<label class="control-label">Date of Departure</label>
							  <div class="input-group">
								<!-- input box -->
								<input angular-mydatepicker #dp_leavingFligthDepartureDateInput="angular-mydatepicker" name="leavingFligthDepartureDateInput" class="form-control" 
								  [locale]="getHelper().locale" 
								  [disabled]="getPersonService().isOngoingRequest()"
								  [options]="getDatePickerOptions()"
								  [ngModel]="leavingFligthDeparture" 
								  (dateChanged)="onLeavingFligthDepartureDateChanged($event)" 
								/>
								<!-- toggle calendar button -->
								<span class="input-group-btn">
									<button type="button" class="btn default" (click)="dp_leavingFligthDepartureDateInput.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
								</span>
							</div>
						</div>
	    			</div>

	    			<div class="col-md-6">
		    			<div class="form-group">
				        	<label class="control-label">Departing from</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="leavingFligthDepartureFromInput"
				          	name="leavingFligthDepartureFromInput" [(ngModel)]="model.leaving_fligth_departure_from" />
				    	</div>
	    			</div>

	    		</div>

	    		<div class="row">

	    			<div class="col-md-6">
	    				<div class="form-group">
				        	<label class="control-label">Time of Departure</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="leavingFligthDepartureTimeInput"
				          	name="leavingFligthDepartureTimeInput" [(ngModel)]="model.leaving_fligth_departure_time" />
				    	</div>
	    			</div>

	    			<div class="col-md-6">
	    				<div class="form-group">
				        	<label class="control-label">Fligth number</label>
				          	<input [disabled]="getPersonService().isOngoingRequest()" class="form-control" id="leavingFligthDepartureNumberInput"
				          	name="leavingFligthDepartureNumberInput" [(ngModel)]="model.leaving_fligth_departure_number" />
				    	</div>
	    			</div>

	    		</div>

	    	</fieldset>

    	</fieldset>

    </div>

    <div class="form-actions right">
	  	<button type="submit" class="btn blue" [disabled]="getPersonService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
	    	<span class="ladda-label" *ngIf="!this.model.currentPersonAvailability"> <i class="fa fa-save"></i> Save</span>
	      	<span class="ladda-label" *ngIf="this.model.currentPersonAvailability">Update</span>
	    </button>
  	</div>

</form>

