import { Component, OnInit } from '@angular/core';
import { GroupService, BackendService, ConfigService, HelperService } from 'app/_services';
import { HttpParams } from '@angular/common/http';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-actor-group-history',
  templateUrl: './actor-group-history.component.html',
  styleUrls: ['./actor-group-history.component.css']
})
export class ActorGroupHistoryComponent implements OnInit {

  subscribe: any;
  model: any = {};

  gaston_history: any = [];

  loading: boolean = false;

  constructor(private actorGroupService: GroupService, private backend:BackendService,
            private configService: ConfigService, private helper: HelperService) { }

  ngOnInit() {

    this.subscribe = this.actorGroupService.getsubjectToUpdateObservable().subscribe( () => {

      this.model = this.actorGroupService.currentGroupInfo;

      if (this.model == null) this.model = {};
      else {

        this.getHistory();
      }
    });

  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }



  getHistory ()
  {
    if (this.model.id == null || typeof this.model.id == "undefined") return;

    this.loading = true;

    let params = new HttpParams();

    this.backend.get('actorgroup/'+this.model.id+'/history', params).subscribe (
      (response) => {

        var res : any = response;

        //  faccio il sort in base alle date
        res.sort(function(a, b) {
          return a.theatrino_start < b.theatrino_start ? -1 : a.theatrino_start > b.theatrino_start ? 1 : 0;
        });

        //   setto le date
        res.forEach(element => {
          if (element.theatrino_start)
            element.start_formatted = parseISO(element.theatrino_start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.theatrino_end)
            element.end_formatted = parseISO(element.theatrino_end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        });

        this.gaston_history = res;
        this.loading = false;
      },
      (error) => {
        this.backend.showErrors(error);
        this.loading = false;
      });
  }


  getConfigService() {
    return this.configService
  }

}
