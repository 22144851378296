import { Component, OnInit, Input } from '@angular/core';
import { ReminderService, HelperService } from 'app/_services';
import { addDays, isPast } from 'date-fns'

@Component({
  selector: 'app-reminder-widget-alertbox',
  templateUrl: './reminder-widget-alertbox.component.html',
  styleUrls: ['./reminder-widget-alertbox.component.css']
})
export class ReminderWidgetAlertboxComponent implements OnInit {

  @Input() holderType: string;
  @Input() holderName: string;
  @Input() holderId: number;

  subscribe: any;
  reminders: any[] = [];
  rows: any[] = [];

  constructor(private reminderService: ReminderService) { }

  ngOnInit() {

    this.subscribe = this.reminderService.getsubjectToUpdateReminderObservable().subscribe((res: any) => {

      this.rows = this.reminderService.getTableData(res, this.holderId, this.holderType);

      this.organizeReminders();
    });
  }

  ngOnDestroy(){
    this.subscribe.unsubscribe();
  }

  organizeReminders(){

    this.reminders = [];

    for (let key in this.rows){

      let r = this.rows[key];

      if (r.holder_id != this.holderId)
        continue;

      // non completato
      if (typeof r.completed[0] == "undefined"){
        // scaduto
        if (isPast(HelperService.parseDate(r.start_date_reminder)) || isPast(HelperService.parseDate(r.notification_date_time))){
          this.reminders.push(r);
        }
      }
    }
  }

}
