<div class="form-body">

  <div class="form-section" *ngIf="getCourseScheduleService().canEditStaff()">
    <div class="btn-group" dropdown>
      <button dropdownToggle type="button" class="btn green btn-outline dropdown-toggle">
        <i class="fa fa-plus"></i>&nbsp; Add Staff Member &nbsp;
        <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem" *ngFor="let st of getConfigService().courseScheduleStaffTypeSelect">
          <a class="dropdown-item" (click)="AddNewStaff(st.key)">{{st.value}}</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-course-schedule-staff-datatable (personSelected)="editPerson($event)"></app-course-schedule-staff-datatable>
    </div>
  </div>


  <!-- Modale per la visualizzazione o inserimento di un nuovo membro -->
  <div *ngIf="isPersonModalShow" [config]="{ show: true }" (onHidden)="onHiddenPerson()" bsModal #personShownModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Course Schedule Staff Member</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hidePersonModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <app-course-schedule-staff-form [modelInput]="currentSelectedPerson" (memberDeleted)="hidePersonModal()" (memberUpdated)="hidePersonModal()"></app-course-schedule-staff-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal di ricerca di una persona -->
<div *ngIf="isPersonModalSearch" [config]="{ show: true }" (onHidden)="onHiddenPersonSearch()" bsModal #personSearchModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add Staff Member:
          <b>{{getConfigService().getCourseScheduleStaffTypeValueByKey(currentStaffType)}}</b>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hidePersonSearchModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-person-search-form #personSearchForm (searchKeywordsSubmit)="searchPersonKeywordsSubmit($event)" [ongoingRequest]="personDatatable.ongoingRequest"></app-person-search-form>
          </div>
        </div>

        <br>
        <br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-person-datatable #personDatatable [query]="personSearchForm.searchKeyword" (personSelected)="personSearchResultSelected($event)"></app-person-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>

        <hr>

        <div  *ngIf="getCourseScheduleService().canEditStaff()" class="row">
          <div class="col-md-12">
            <label class="control-label">* Se la persona che cerchi non compare in questa ricerca, allora puoi inserirla da qui</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn green" (click)="addNewPerson()" [disabled]="!searched"><i class="fa fa-plus"></i>&nbsp;new Person</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- Modal di aggiunta di una nuova persona -->
<div  *ngIf="newPersonModalShow" [config]="{ show: true }" (onHidden)="onHiddenPersonNew()" bsModal #personAddModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add New Person as Staff Member:
          <b>{{getConfigService().getCourseScheduleStaffTypeValueByKey(currentStaffType)}}</b>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hidePersonNewModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-person-add-new (personAdded)="newPersonAdded($event)"></app-person-add-new>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- BEGIN: Modal di ricerca delle enterprise da associare -->
<div *ngIf="isEnterpriseSearchModalShown" [config]="{ show: true }" (onHidden)="onHiddenEnterpriseSearch()" bsModal #enterpriseSearchModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Enterprise</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideEnterpriseSearchModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-enterprise-search-form #enterpriseSearchForm (searchKeywordsSubmit)="searchEnterpriseKeywordsSubmit($event)" [ongoingRequest]="enterpriseDatatable.ongoingRequest"></app-enterprise-search-form>
          </div>
        </div>

        <br>
        <br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-enterprise-datatable #enterpriseDatatable [query]="enterpriseSearchForm.searchKeyword" (enterpriseSelected)="enterpriseSearchResultSelected($event)"></app-enterprise-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modal di ricerca delle enterprise da associare -->
