<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
    <div class="form-body">

      <div class="row">
        <div class="col-md-9">
          <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalData())) }">
            <label class="control-label">Course Schedule Data
              <span class="required">*</span>
            </label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_date="angular-mydatepicker" name="date" class="form-control"
                  [locale]="getHelper().locale"
                  [disabled]="loadingData || getCourseScheduleService().isOngoingRequest()"
                  [options]="getDatePickerOptions()"
                  [ngModel]="getLocalData()"
                  (dateChanged)="onDateChanged($event)"
                  required
                />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="modelSearch?.to_date" class="btn default" (click)="dp_date.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                    <button type="button" class="btn default" (click)="dp_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                </span>
            </div>
            <span class="help-block">Date is required</span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">&nbsp;</label>
            <button type="button" (click)="searchCourse()" class="form-control btn blue" [disabled]=" loadingData || getCourseScheduleService().isOngoingRequest()">
              <span class="ladda-label">
                <i class="fa fa-search" aria-hidden="true"></i> Search</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="courseScheduleList">
        <div class="col-md-12">
          <div class="form-group">
              <label class="control-label">Course Schedule</label>
              <span *ngIf="courseScheduleList.length == 0">No results</span>
              <select *ngIf="courseScheduleList.length > 0" class="form-control" name="courseschedule" [(ngModel)]="model.course_schedule_id" #courseschedule="ngModel" (ngModelChange)="onSelectChange($event)"
              [disabled]="loadingData || getCourseScheduleService().isOngoingRequest()" required>
              <option *ngFor="let c of courseScheduleList" [value]="c.course_schedule_id">{{c.description}}</option>
            </select>
          </div>
        </div>
      </div>

    </div>

    <div class="form-actions right">
      <img *ngIf="getCourseScheduleService().isOngoingRequest() || this.loadingData" src="./assets/global/img/input-spinner.gif" />
      <button type="submit" class="btn blue" [disabled]=" loadingData || !courseScheduleList || !model.course_schedule_id || getCourseScheduleService().isOngoingRequest()">
        <span class="ladda-label">
          <i class="fa fa-clone" aria-hidden="true"></i> Copy</span>
      </button>
    </div>

  </form>


  <!-- <div class="bootbox modal fade bootbox-confirm in" bsModal #confirmModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body alert-warning">
          <div class="bootbox-body">
            In this day there is already a booking. <br>
            Do you want to save this too?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="confirmSubmit()" >Yes</button>
            <button type="button" class="btn btn-primary" (click)="confirmModal.hide()" >No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
   -->
