<!-- BEGIN: modale multi form per l'inserimento delle accomoation e dei gruppi -->
<div *ngIf="isModalShown" [config]="{show:true, ignoreBackdropClick: getTheatrinoService().isOngoingRequest(), keyboard:!getTheatrinoService().isOngoingRequest()}"
  (onHidden)="onHidden()" bsModal #modalAccomodation="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="this.data?.calendarDay">Set New Group or Accomodation</h4>
        <h4 class="modal-title pull-left" *ngIf="!this.data?.calendarDay">Update Group or Accomodation</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <!-- BEGIN FORM -->
        <form action="#" class="horizontal-form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

          <div class="form-body">

            <!-- BEGIN: parte delle date -->
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDataStart)) }">
                  <label class="control-label">Date <span class="required">*</span></label>
                    <div class="input-group">
                      <!-- input box -->
                      <input angular-mydatepicker #dp_start="angular-mydatepicker" name="start" class="form-control"
                        [locale]="getHelper().locale"
                        [options]="datePickerOptionsBegin"
                        [ngModel]="localDataStart"
                        (dateChanged)="onDateStartChanged($event)"
                      />
                      <!-- clear date button -->
                      <span class="input-group-btn">
                        <button type="button" *ngIf="model?.end" class="btn default" (click)="dp_start.clearDate()"><i class="fa fa-close"></i></button>
                      </span>
                      <!-- toggle calendar button -->
                      <span class="input-group-btn">
                          <button type="button" class="btn default" (click)="dp_start.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                      </span>
                  </div>
                  <span class="help-block">Date is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDataEnd)) }">
                  <label class="control-label">To Date <span class="required">*</span></label>
                    <div class="input-group">
                      <!-- input box -->
                      <input angular-mydatepicker #dp_end="angular-mydatepicker" name="end" class="form-control"
                        [locale]="getHelper().locale"
                        [options]="datePickerOptionsEnd"
                        [ngModel]="localDataEnd"
                        (dateChanged)="onDateEndChanged($event)"
                      />
                      <!-- clear date button -->
                      <span class="input-group-btn">
                        <button type="button" *ngIf="model?.end" class="btn default" (click)="dp_end.clearDate()"><i class="fa fa-close"></i></button>
                      </span>
                      <!-- toggle calendar button -->
                      <span class="input-group-btn">
                          <button type="button" class="btn default" (click)="dp_end.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                      </span>
                  </div>
                  <span class="help-block">Date is required</span>
                </div>
              </div>
            </div>
            <!-- END: parte delle date -->

            <div class="row">
              <!-- BEGIN: Gruppi Accomodation -->
              <div class="col-md-6">
                <label class="control-label"></label>
                <div class="portlet light bordered">
                  <div class="portlet-title">
                    <div class="caption">
                      <i class="icon-bar-chart font-dark hide"></i>
                      <span class="caption-subject font-dark bold uppercase">Group</span>
                    </div>
                    <div class="actions">
                      <div class="btn-group btn-group-devided" data-toggle="buttons">
                        <ng-template #confirmGroup>
                          <button type="button" class="btn btn-outline red" (click)="removeGroupMember()">Yes Remove!</button>
                        </ng-template>
                        <button *ngIf="this.model.actor_group_id" type="button" [disabled]="getTheatrinoService().isOngoingRequest()" class="btn btn-circle red btn-outline"
                          [outsideClick]="true" [popover]="confirmGroup" popoverTitle="Are you sure?">
                          <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
                        <button type="button" class="btn btn-circle btn-outline green" [disabled]="getTheatrinoService().isOngoingRequest()" (click)="showModalGroups()">
                          <i class="fa fa-edit"></i>&nbsp;Edit</button>
                      </div>
                    </div>
                  </div>
                  <div class="portlet-body">
                    <app-actor-group-info-show #groupInfoShow [idGroup]="model.actor_group_id"></app-actor-group-info-show>
                  </div>
                </div>
              </div>
              <!-- END: Gruppi Accomodation -->

              <!-- BEGIN: Azienda Accomodation -->
              <div class="col-md-6">
                <label class="control-label"></label>
                <div class="portlet light bordered">
                  <div class="portlet-title">
                    <div class="caption">
                      <i class="icon-bar-chart font-dark hide"></i>
                      <span class="caption-subject font-dark bold uppercase">Enterprise</span>
                    </div>
                    <div class="actions">
                      <div class="btn-group btn-group-devided" data-toggle="buttons">
                        <ng-template #confirmEnterprise>
                          <button type="button" class="btn btn-outline red" (click)="removeEnterprise()">Yes Remove!</button>
                        </ng-template>
                        <button *ngIf="this.model.enterprise_id" type="button" [disabled]="getTheatrinoService().isOngoingRequest()" class="btn btn-circle red btn-outline"
                          [outsideClick]="true" [popover]="confirmEnterprise" popoverTitle="Are you sure?">
                          <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
                        <button type="button" class="btn btn-circle btn-outline green" [disabled]="getTheatrinoService().isOngoingRequest()" (click)="showModalEnterprise()">
                          <i class="fa fa-edit"></i>&nbsp;Edit</button>
                      </div>
                    </div>
                  </div>
                  <div class="portlet-body">
                    <app-enterprise-info-show #enterpriseInfoShow [idEnterprise]="model.enterprise_id"></app-enterprise-info-show>
                  </div>
                </div>
              </div>
              <!-- END: Azienda Accomodation -->

            </div>

            <!-- BEGIN: Note Accomodation -->
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">Notes</label>
                  <textarea rows="3" class="form-control" placeholder="Some notes..." name="annotation" [(ngModel)]="model.annotation"></textarea>
                </div>
              </div>
            </div>
            <!-- END: Note Accomodation -->

          </div>

          <!-- BEGIN: Buttons forms -->
          <div class="form-actions right">
            <img *ngIf="getTheatrinoService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
            <ng-template #confirmDelete>
              <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
            </ng-template>
            <button *ngIf="this.model.tour_id" type="button" class="btn btn-outline red" [disabled]="getTheatrinoService().isOngoingRequest()"
              [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
              <i class="fa fa-trash-o"></i> Delete</button>&nbsp;

            <button type="submit" class="btn blue" [disabled]="getTheatrinoService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
              <span class="ladda-label" *ngIf="!this.model.tour_id">
                <i class="fa fa-save"></i> Save</span>
              <span class="ladda-label" *ngIf="this.model.tour_id">Update</span>
            </button>

          </div>
          <!-- END: Buttons forms -->

        </form>

      </div>
    </div>
  </div>
</div>
<!-- END: modale multi form per l'inserimento delle accomoation e dei gruppi -->


<!-- BEGIN: Modale enterprise -->
<div *ngIf="isModalEnterpriseShown" [config]="{ show: true }" (onHidden)="onHiddenEnterprise()" bsModal #modalEnterprise="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Select Enterprise</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalEnterprise()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-enterprise-search-form #enterpriseSearchForm (searchKeywordsSubmit)="searchKeywordsSubmitEnterprise($event)" [ongoingRequest]="enterpriseDatatable.ongoingRequest"></app-enterprise-search-form>
          </div>
        </div>

        <br>
        <br>

        <div class="row">
          <div class="col-md-12">
            <div class="portlet light bordered">
              <div class="portlet-body">
                <app-enterprise-datatable #enterpriseDatatable (enterpriseSelected)="resultEnterpriseSelected($event)"></app-enterprise-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modale enterprise -->

<!-- BEGIN: Modale gruppi -->
<div *ngIf="isModalGroupShown" [config]="{ show: true }" (onHidden)="onHiddenGroup()" bsModal #modalGroup="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add Group</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalGroup()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-actor-group-search-form #groupSearchForm (searchKeywordsSubmit)="searchKeyworkdsSubmitGroup($event)" [ongoingRequest]="groupDatatable.ongoingRequest"></app-actor-group-search-form>
          </div>
        </div>

        <br>
        <br>

        <div class="row">
          <div class="col-md-12">
            <div class="portlet light bordered">
              <div class="portlet-body">
                <app-actor-group-datatable #groupDatatable (groupSelected)="resultGroupSelected($event)"></app-actor-group-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modale gruppi -->
