import { TheatrinoAccomodationMultiFormModalComponent } from './../theatrino-accomodation-multi-form-modal/theatrino-accomodation-multi-form-modal.component';
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TheatrinoService, ConfigService, HelperService, UserService } from './../../_services/index';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay, CalendarMonthViewComponent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { parseISO, endOfDay, subDays, isSameDay } from 'date-fns';
import { TheatrinoTourBookingCalendarDayComponent } from 'app/theatrino/theatrino-tour-booking-calendar-day/theatrino-tour-booking-calendar-day.component';

type CalendarPeriod = 'day' | 'week' | 'month';

@Component({
  selector: 'app-theatrino-tour-booking-calendar',
  templateUrl: './theatrino-tour-booking-calendar.component.html',
  styleUrls: ['./theatrino-tour-booking-calendar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TheatrinoTourBookingCalendarComponent implements OnInit {

  openDayDate: any;
  @Input() theatrinoDayModal: TheatrinoTourBookingCalendarDayComponent;
  @Input() accomodationModal: TheatrinoAccomodationMultiFormModalComponent;

  tourList: any;
  tourListKeys: any[];

  view: CalendarPeriod = 'month';
  viewDate: Date = new Date();
  subTitle: string = "";

  refresh: Subject<any> = new Subject();

  activeDayIsOpen: boolean = false;

  actions: CalendarEventAction[] = [
    // {
    //   label: '<i class="fa fa-pencil"></i>',
    //   onClick: ({ event }: { event: CalendarEvent }): void => {
    //     this.handleEvent('Edited', event);
    //   }
    // }
  ];

  minDate: Date = new Date();

  maxDate: Date = new Date();

  prevBtnDisabled: boolean = false;

  nextBtnDisabled: boolean = false;

  events: CalendarEvent[] = [];

  @ViewChild('calendarMonthView', { static: true }) public calendarMonthView: CalendarMonthViewComponent;

  constructor(private theatrinoService: TheatrinoService, private configService: ConfigService, private userService: UserService,
    private router: Router, private helper: HelperService) { }

  subscribe: any;

  ngOnInit() {

    this.tourList = JSON.parse(JSON.stringify(this.theatrinoService.currentTourInfoList));

    this.subscribe = this.theatrinoService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      if (this.theatrinoService.currentTourInfoList == null)
        this.tourList = {};
      else
        this.tourList = JSON.parse(JSON.stringify(this.theatrinoService.currentTourInfoList));

      //  Creo la lista in base alle chiavi
      this.tourListKeys = Object.keys(this.tourList);

      this.updateEventArray();

    });

    if (this.userService.getUserPreferences("theatrino_tour_booking_calendar_" + this.theatrinoService.currentTheatrinoId + "_view") != null)
      this.view = this.userService.getUserPreferences("theatrino_tour_booking_calendar_" + this.theatrinoService.currentTheatrinoId + "_view");

    if (this.userService.getUserPreferences("theatrino_tour_booking_calendar_" + this.theatrinoService.currentTheatrinoId + "_viewDate") != null)
      this.viewDate = new Date(this.userService.getUserPreferences("theatrino_tour_booking_calendar_" + this.theatrinoService.currentTheatrinoId + "_viewDate"));

  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  addNewTourDate() {
    this.router.navigate(["theatrino/" + this.theatrinoService.currentTheatrinoId + "/tour/new/booking/", true]);
  }

  addNewDayOff() {
    this.router.navigate(["theatrino/" + this.theatrinoService.currentTheatrinoId + "/tour/new/dayoff/", true]);
  }

  addNewSwapDay() {
    this.router.navigate(["theatrino/" + this.theatrinoService.currentTheatrinoId + "/tour/new/swapday/", true]);
  }

  addNewGeneral() {
    this.router.navigate(["theatrino/" + this.theatrinoService.currentTheatrinoId + "/tour/new/general/", true]);
  }

  addNewAccomodation() {
    // this.router.navigate(["theatrino/"+this.theatrinoService.currentTheatrinoId +"/tour/new/accomodation/", true]);
    this.addNewAccomodationFromOpenDay();
  }

  addNewTourDateFromOpenDay() {

    let day = this.openDayDate;
    this.router.navigate(["theatrino/" + this.theatrinoService.currentTheatrinoId + "/tour/newDate/" + day.toDateString() + "/booking/", true]);
  }

  addNewDayOffFromOpenDay() {

    let day = this.openDayDate;
    this.router.navigate(["theatrino/" + this.theatrinoService.currentTheatrinoId + "/tour/newDate/" + day.toDateString() + "/dayoff/", true]);
  }

  addNewSwapDayFromOpenDay() {

    let day = this.openDayDate;
    this.router.navigate(["theatrino/" + this.theatrinoService.currentTheatrinoId + "/tour/newDate/" + day.toDateString() + "/swapday/", true]);
  }

  addNewGeneralFromOpenDay() {

    let day = this.openDayDate;
    this.router.navigate(["theatrino/" + this.theatrinoService.currentTheatrinoId + "/tour/newDate/" + day.toDateString() + "/general/", true]);
  }

  addNewAccomodationFromOpenDay() {

    var model: any = {};
    model.calendarDay = this.openDayDate;

    // this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/newDate/"+day.toDateString()+"/accomodation/", true]);
    this.theatrinoDayModal.hideModal();
    //  Mostro la modale con le informazioni da passare per la creazione/modifica di una accomodation
    this.accomodationModal.showAccomodationModal(model)

  }

  updateEventArray() {

    let currentTheatrino = this.theatrinoService.currentTheatrinoInfo;

    this.minDate = subDays(parseISO(currentTheatrino["start"]), 1);
    this.maxDate = parseISO(currentTheatrino["end"]);

    this.events = [];

    this.tourListKeys.forEach(key => {

      // Day off
      if (this.tourList[key].type == 1) {

        let startDate = parseISO(this.tourList[key].date);

        this.events.push({
          start: startDate,
          end: endOfDay(startDate),
          title: '<i class="fa fa-power-off" aria-hidden="true"></i>&nbsp; Day Off',
          color: {
            primary: "rgba(0,0,0,0)",
            secondary: "#ff8e8e",
          },
          meta: {
            incrementsBadgeTotal: false,
            listKey: key,
            smallTitle: '<i class="fa fa-power-off" aria-hidden="true"></i>&nbsp; Day Off',
          },
          cssClass: "cal-dayoff",
          allDay: true
        });
      }
      // prenotazione normale
      else if (this.tourList[key].type == 2) {

        // let startDate = parseISO(this.tourList[key].date+" "+this.tourList[key].time);
        let startDate = parseISO(this.tourList[key].date + " " + this.tourList[key].time);

        this.events.push({
          start: startDate,
          end: endOfDay(startDate),
          title: this.getEventTitle(this.tourList[key]),
          color: this.configService.getTheatrinoStatusColorByKey(this.tourList[key].status),
          actions: this.actions,
          meta: {
            incrementsBadgeTotal: true,
            listKey: key,
            smallTitle: this.getEventSmallTitle(this.tourList[key])
          },
          cssClass: "cal-booking",
          allDay: false
        });
      }
      // Swap Day
      else if (this.tourList[key].type == 3) {

        let startDate = parseISO(this.tourList[key].date);

        this.events.push({
          start: startDate,
          end: endOfDay(startDate),
          title: '<i class="fa fa-retweet" aria-hidden="true"></i>&nbsp; Swap Day',
          color: {
            primary: "rgba(0,0,0,0)",
            secondary: "#ff8e8e",
          },
          actions: this.actions,
          meta: {
            incrementsBadgeTotal: false,
            listKey: key,
            smallTitle: '<i class="fa fa-retweet" aria-hidden="true"></i>&nbsp; Swap Day',
          },
          cssClass: "cal-swapday",
          allDay: true
        });
      }
      // general event
      else if (this.tourList[key].type == 4) {

        let startDate = parseISO(this.tourList[key].date);

        this.events.push({
          start: startDate,
          end: endOfDay(startDate),
          title: '<i class="fa fa-sticky-note" aria-hidden="true"></i>&nbsp; Admin annotation: ' + this.tourList[key].admin_annotation,
          color: {
            primary: "white",
            secondary: "white",
          },
          actions: this.actions,
          meta: {
            incrementsBadgeTotal: false,
            listKey: key,
            smallTitle: '<i class="fa fa-sticky-note" aria-hidden="true"></i>&nbsp;Admin annotation',
          },
          cssClass: "cal-generic",
          allDay: true
        });
      }
      // Accomodation
      else if (this.tourList[key].type == 5) {

        let startDate = parseISO(this.tourList[key].date);

        var groupName = "-";
        var accomodationName = "-";

        var groupIcon = "";
        var accomodationIcon = "";
        var accomodationIconSmall = "";

        if (this.tourList[key].accomodation && this.tourList[key].accomodation.group_name != null) {
          groupIcon = '<i class="fa fa-users" aria-hidden="true"></i>';
          groupName = this.tourList[key].accomodation.group_name;
        }

        if (this.tourList[key].accomodation && this.tourList[key].accomodation.denomination) {
          accomodationIconSmall = '<i class="fa fa-bed icon-accomodation" aria-hidden="true"></i>';
          accomodationIcon = '<i class="fa fa-bed" aria-hidden="true"></i>';
          accomodationName = this.tourList[key].accomodation.denomination;
        }

        this.events.push({
          start: startDate,
          end: endOfDay(startDate),
          title: groupIcon + "&nbsp;" + groupName + "&nbsp;" + accomodationIcon + "&nbsp;" + accomodationName,
          color: {
            primary: "white",
            secondary: "white",
          },
          actions: this.actions,
          meta: {
            incrementsBadgeTotal: false,
            listKey: key,
            smallTitle: groupIcon + "&nbsp;" + groupName + "&nbsp;" + accomodationIconSmall
          },
          cssClass: "cal-accomodation",
          allDay: true
        });
      }



    });

    this.dateOrViewChanged();
    this.refresh.next();
  }

  getEventTitle(tourDate) {

    let t = "" + '<i class="fa fa-clock-o"></i>&nbsp;';

    t += '<small>' + tourDate.time + '</small>';
    t += "&nbsp;&nbsp;<b>Enterprise:</b> " + tourDate.enterprise_denomination;

    if (tourDate.city != null)
      t += "&nbsp;&nbsp;<b>City:</b> " + tourDate.city

    if (tourDate.prov != null)
      t += "&nbsp;(" + tourDate.prov + ")";

    if (tourDate.enterprise_organizer_denomination != null)
      t += "&nbsp;&nbsp;<b>Organizer:</b> " + tourDate.enterprise_organizer_denomination

    //  Se sono già stati impostati degli show ed esiste un utente responsabile per ogni show lo mostro
    if (tourDate.shows && Object.keys(tourDate.shows).length > 0) {
      var accountables = [];

      Object.keys(tourDate.shows).forEach(key => {
        if (tourDate.shows[key].accountable != null && typeof tourDate.shows[key].accountable != "undefined") {
          if (!accountables.find(x => x.id == tourDate.shows[key].accountable.id))
            accountables.push(tourDate.shows[key].accountable);
        }

      });

      accountables.forEach(element => {
        t += "&nbsp;&nbsp;<b>Accountable:</b> " + element.surname + " " + element.first_name;
      });
    }

    if (tourDate.accountable) {
      t += "&nbsp;&nbsp;<b>Accountable:</b> " + tourDate.accountable.surname + " " + tourDate.accountable.first_name;
    }

    return t;
  }

  getEventSmallTitle(tourDate) {

    let t = "";

    t += '<i class="fa fa-clock-o"></i>&nbsp;<small>' + tourDate.time + '</small>';

    if (tourDate.status == 4 || tourDate.status == 5) {
      t += '&nbsp;<i class="fa fa-file-pdf-o"></i>';
    }

    if (tourDate.city != null)
      t += "&nbsp;<b>" + tourDate.city + "</b>";

    if (tourDate.prov != null)
      t += "&nbsp;(" + tourDate.prov + ")";

    t += "&nbsp;-&nbsp;<b>" + tourDate.enterprise_denomination + "</b>";

    return t;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

    this.openDayDate = date;

    // if (isSameMonth(date, this.viewDate)) {
    if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
      this.viewDate = date;
    }

    if (events && events.length === 0) {
      this.activeDayIsOpen = true;
      //this.addNewTourDateFromOpenDay(date);
    }
    // }
    // else {
    //   this.activeDayIsOpen = false;
    // }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  increment(): void {
    this.changeDate(this.helper.addPeriod(this.view, this.viewDate, 1));
  }

  decrement(): void {
    this.changeDate(this.helper.subPeriod(this.view, this.viewDate, 1));
  }

  today(): void {
    this.changeDate(new Date());
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.activeDayIsOpen = false;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarPeriod): void {
    this.view = view;
    this.activeDayIsOpen = false;
    this.dateOrViewChanged();
  }

  dateIsValid(date: Date): boolean {
    return date >= this.minDate && date <= this.maxDate;
  }

  handleEvent(action: string, event: CalendarEvent): void {

    if (action == "Edited" || action == "Clicked") {

      this.theatrinoDayModal.openModalFullDay(parseISO(this.tourList[event.meta.listKey].date))

      //  Se è un evento accomodation devo mostrare la modale
      // if (this.tourList[event.meta.listKey].type == 5)
      //   this.accomodationModal.showAccomodationModal(this.tourList[event.meta.listKey]);
      // //  Altrimenti vado nella sezione di modifica normale di un evento
      // else{
      //   this.openModalFullDay(parseISO(this.tourList[event.meta.listKey].date))
      //   //this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/edit/" + this.tourList[event.meta.listKey].id]);
      // }
    }
  }


  dateOrViewChanged(): void {

    this.prevBtnDisabled = !this.dateIsValid(
      this.helper.endOfPeriod(this.view, this.helper.subPeriod(this.view, this.viewDate, 1))
    );

    this.nextBtnDisabled = !this.dateIsValid(
      this.helper.startOfPeriod(this.view, this.helper.addPeriod(this.view, this.viewDate, 1))
    );

    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }

    this.subTitle = this.helper.startOfPeriod(this.view, this.viewDate).toDateString() + " - " + this.helper.endOfPeriod(this.view, this.viewDate).toDateString();

    this.userService.saveUserPreferences("theatrino_tour_booking_calendar_" + this.theatrinoService.currentTheatrinoId + "_view", this.view);
    this.userService.saveUserPreferences("theatrino_tour_booking_calendar_" + this.theatrinoService.currentTheatrinoId + "_viewDate", this.viewDate.toDateString());
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      if (!this.dateIsValid(day.date)) {
        day.cssClass = 'cal-disabled';
      }

      if (day.events.length > 0) {

        for (let i = 0; i < day.events.length; i++) {
          if (typeof this.tourList[day.events[i].meta.listKey] != "undefined" && typeof this.tourList[day.events[i].meta.listKey].type != "undefined") {
            if (this.tourList[day.events[i].meta.listKey].type == 1) {
              day.cssClass = 'cal-dayoff';
            }

            if (this.tourList[day.events[i].meta.listKey].type == 3) {
              day.cssClass = 'cal-swapday';
            }
          }
        }
      }
    });
  }

  getConfigService() {
    return this.configService
  }

}
