import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { VehicleService, HelperService } from 'app/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PersonDatatableComponent, PersonSearchFormComponent } from 'app/person';
import { ActorGroupDatatableComponent, ActorGroupSearchFormComponent } from 'app/actor-group';
import { NgForm } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { IMyInputFieldChanged } from 'angular-mydatepicker';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-vehicle-assignment-add-form',
  templateUrl: './vehicle-assignment-add-form.component.html',
  styleUrls: ['./vehicle-assignment-add-form.component.css']
})
export class VehicleAssignmentAddFormComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('masterModal', {static: false}) public masterModal: ModalDirective;
  @ViewChild('personDatatable', {static: false}) public personDatatable: PersonDatatableComponent;
  @ViewChild('personSearchForm', {static: false}) public personSearchForm: PersonSearchFormComponent;
  @ViewChild('groupDatatable', {static: false}) public groupDatatable: ActorGroupDatatableComponent;
  @ViewChild('groupSearchForm', {static: false}) public groupSearchForm: ActorGroupSearchFormComponent;

  @ViewChild('f', {static: true}) currentForm: NgForm;

  @Input() model: any = {};
  isAddIn: boolean;
  vehicleInfo: any;

  vehicleHolderType: string;
  vehicleHolderName: string;
  vehicleHolderId: number;

  vehicleName: string;
  modalEntityType: string;

  @Output() modelUpdated = new EventEmitter();
  @Output() modalClosed = new EventEmitter();

  private datePickerOptions: IAngularMyDpOptions = {};
  private localStartData: IMyDateModel;
  private localEndData: IMyDateModel;

  constructor(private vehicleService: VehicleService, private helper: HelperService) {

    //  Inizializzo le info sulle date e resetto il blocco
    this.datePickerOptions = this.helper.datePickerOptions;
  }

  ngOnInit() {
  }


  public update (model) {

    this.model = {};
    this.vehicleInfo = null;
    this.currentForm.form.markAsDirty();

    this.model = model;

    if (this.model == null)
      this.model = {};

    if (this.model.vehicle_data == null || typeof this.model.vehicle_data == "undefined")
      this.isAddIn = true;
    else
    {
      this.vehicleInfo = JSON.parse(JSON.stringify(this.model.vehicle_data));
      this.isAddIn = false;
    }

    if (typeof this.model.start_date != "undefined")
      this.localStartData = this.helper.initMyDataInputSingle(this.model.start_date);
    else
      this.localStartData = null;

    if (typeof this.model.end_date != "undefined")
      this.localEndData = this.helper.initMyDataInputSingle(this.model.end_date);
    else
      this.localEndData = null;

    if (typeof this.vehicleInfo != "undefined")
    {
      this.model.vehicle_id = this.vehicleInfo.id;
      this.vehicleName = this.vehicleInfo.vehicle_name + " (" + this.vehicleInfo.plate_number + ")";
    }
    else
    {
      this.vehicleName = null;
    }

    if (typeof this.model.in_holder_data != "undefined")
    {
      this.vehicleHolderType = this.model.in_holder_type;
      this.vehicleHolderId = this.model.in_holder_id;
      this.vehicleHolderName = this.helper.getHolderNameFromType(this.model.in_holder_type, this.model.in_holder_data);
    }
    else
    {
      this.vehicleHolderType = null;
      this.vehicleHolderId = null;
      this.vehicleHolderName = null;
    }

    this.currentForm.form.markAsDirty();

  }

  submit() {

    if (!this.currentForm.form.valid)
      return;

    if (!this.currentForm.form.dirty)
      return;

    if (this.vehicleHolderType == null || this.vehicleHolderId == null)
      return;

    if (!this.helper.isMyDateValid(this.localStartData) || !this.helper.isMyDateValid(this.localEndData))
      return;

    if (this.vehicleInfo == null)
      return;

    // console.log(this.model);

    //  TODO: aggiungere anche quello dei veicoli una volta che verrà passato all'assegnazione in altre entità come le persone
    this.model.out_holder_type = 'App\\Vehicle';
    this.model.vehicle_id = this.vehicleInfo.id;
    this.model.in_holder_type = this.vehicleHolderType;
    this.model.in_holder_id = this.vehicleHolderId;

    if (this.model.id) {
      this.vehicleService.updateVehicleAssignment(this.model.id, this.model, () => {
        this.masterModal.hide();
        this.modelUpdated.emit();
      });
    }
    else {
      this.vehicleService.saveVehicleAssignment(this.model, ()=> {
        this.masterModal.hide();
        this.modelUpdated.emit();
      });
    }
  }

  destroy() {

    this.vehicleService.deleteVehicleAssignment(this.model.id, () => {
      this.modelUpdated.emit();
      this.masterModal.hide();
    })
  }


  removeOtherHolder(){

    this.vehicleHolderName = null;
    this.vehicleHolderType = null;
    this.vehicleHolderId = null;
    this.currentForm.form.markAsDirty();
  }



  addEntityFromModal(type: string) {

    this.modalEntityType = type;
    this.showModal();
  }


  //  Search modal
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }


  //  Master modal
  showMaster(): void {

    this.masterModal.show();
  }

  onHiddenMaster(): void {

    this.modalClosed.emit();
  }

  //  Person
  searchPersonKeywordsSubmit(params) {
    this.personDatatable.query = params;
    this.personDatatable.getResults();
  }
  personSelected(selected) {

    this.vehicleHolderId = selected.person_id;
    this.vehicleHolderName = selected.first_name + " " + selected.surname;
    this.vehicleHolderType = 'App\\Person';
    this.currentForm.form.markAsDirty();
    this.hideModal();
  }



  //  Group
  searchGroupKeywordsSubmit(params) {

    if (typeof params == "undefined" || params == null)
      this.groupDatatable.query = "all";
    else
      this.groupDatatable.query = params;

    //  Prendo i risultati dalla tabella
    this.groupDatatable.getResult();
  }

  groupSelected (selected)
  {
    //this.model.actor_group_id = selected.actor_group_id;
    this.vehicleHolderId = selected.actor_group_id;
    this.vehicleHolderName = selected.group_name;
    this.vehicleHolderType = 'App\\ActorGroup';
    this.currentForm.form.markAsDirty();
    this.hideModal();
  }



  onDateStartChanged(event: IMyDateModel) {

    this.localStartData = event;

    if (event.singleDate.jsDate != null)
      this.model.start_date = this.helper.formatISODate(event);
    else
      this.model.start_date = null;

    this.currentForm.form.markAsDirty();
  }



  onDateEndChanged(event: IMyDateModel) {

    this.localEndData = event;

    if (event.singleDate.jsDate != null)
      this.model.end_date = this.helper.formatISODate(event);
    else
      this.model.end_date = null;

    this.currentForm.form.markAsDirty();
  }



  getVehicleService () {
    return this.vehicleService;
  }


  getLocalDataStart() {
    return this.localStartData
  }
  getLocalDataEnd() {
    return this.localEndData
  }

  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

}
