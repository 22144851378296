<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/course/home']">Courses</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
             <span *ngIf="!getCourseService().currentId">New</span>
            <span *ngIf="getCourseService().currentId">Edit</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title" *ngIf="!getCourseService().currentId">New course</h1>
      <h1 class="page-title" *ngIf="getCourseService().currentId">Edit course: {{getCourseService().currentInfo?.title}}</h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">

            <!--end: Portlet-Body -->
            <div class="portlet-body form">

            <alert type="warning" *ngIf="loadingData">
              <strong>Loading</strong> ...
            </alert>

            <app-course-info-form [hidden]="loadingData"></app-course-info-form>

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
