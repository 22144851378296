<ngx-datatable *ngIf="!getActorService().ongoingRequest" class="striped hidden-empty-datatable"
  [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="10" [rowHeight]="'auto'"
  [selected]="selected" [selectionType]="'single'">

  <ngx-datatable-column name="Name" prop="surname" [flexGrow]="5" [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <i *ngIf="row.gender == 'MALE'" class="fa fa-male" aria-hidden="true"></i><i *ngIf="row.gender == 'FEMALE'"
        class="fa fa-female" aria-hidden="true"></i>&nbsp;&nbsp;{{row.surname}} {{row.firstname}}<br>
      <a href="mailto:{{row.email}}"><small style="white-space: nowrap;"><i class="fa fa-envelope"
            aria-hidden="true"></i>&nbsp;{{row.email}}</small></a><br>
      <small *ngIf="row.skype_address"><i class="fa fa-skype" aria-hidden="true"></i> {{ row.skype_address }}</small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="filter.length == 0" name="Date info" [flexGrow]="4" prop="created_at"
    [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <small><i class="fa fa-plus-square-o"
          aria-hidden="true"></i>&nbsp;{{ value | date:'d MMMM, y HH:mm':'':'it' }}</small><br>
      <small><i class="fa fa-pencil-square-o"
          aria-hidden="true"></i>&nbsp;{{ row.updated_at | date:'d MMMM, y HH:mm':'':'it' }}</small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Nat." [flexGrow]="1" prop="nationality" [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <small>{{ value }}</small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Tour type" [flexGrow]="4" prop="tour_type" [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <small>{{ value }}</small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Status" [flexGrow]="2" prop="status" [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <i *ngIf="value=='SUBMITTED'" class="fa fa-check-square-o" aria-hidden="true"></i>
      <i *ngIf="value=='EDITING'" class="fa fa-pencil-square-o" aria-hidden="true"></i>
      <i *ngIf="value=='CREATED'" class="fa fa-plus-square-o" aria-hidden="true"></i>
      <i *ngIf="row.criminal_record == 'yes'" class="fa fa-user-secret" aria-hidden="true"></i>
      <i *ngIf="row.phase == 1 && row.audition_agree == 'no'" class="fa fa-youtube-play" aria-hidden="true"></i>
      <i *ngIf="row.phase == 1 && row.audition_agree == 'yes'" class="fa fa-microphone" aria-hidden="true"></i>
      <i *ngIf="row.phase >= 2 && row.selftape"
        [ngClass]="{'font-green-jungle': row.youtube_selftape_link, 'font-default': !row.youtube_selftape_link}"
        class="fa fa-youtube-play" aria-hidden="true"></i>
      <i *ngIf="row.phase >= 2" [ngClass]="{'font-green-jungle': row.passport_url, 'font-default': !row.passport_url}"
        class="fa fa-book" aria-hidden="true"></i>
      <i *ngIf="row.phase >= 2 && row.audition"
        [ngClass]="{'font-green-jungle': row.passport_url, 'font-default': !row.passport_url}" class="fa fa-microphone"
        aria-hidden="true"></i>
      <i *ngIf="row.phase >= 2 && row.audition_agree == 'yes' && !row.audition" class="fa fa-microphone"
        aria-hidden="true"></i>
      <i *ngIf="row.driver_licence == 'yes'"
        [ngClass]="{'font-green-jungle': row.like_drive_italy=='yes', 'font-red': row.like_drive_italy=='no'}"
        class="fa fa-car" aria-hidden="true"></i>
      <i *ngIf="row.phase >= 3"
        [ngClass]="{'font-green-jungle': row.proposal_accepted != null, 'font-red': row.proposal_rejected != null}"
        class="fa fa-bullhorn" aria-hidden="true"></i>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="filter.length > 0" name="Info" [flexGrow]="4" prop="" [resizeable]="false"
    [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <span *ngIf="filter == 'selftape'">
        <small *ngIf="!row.youtube_selftape_link">
          <i class="fa fa-youtube-play" aria-hidden="true"></i> waiting for selftape video
        </small>
        <small *ngIf="row.youtube_selftape_link && !row.interview_date">
          <i class="fa font-green-jungle fa-youtube-play" aria-hidden="true"></i> selftape video received on
          {{row.self_tape_accepted | date:'d MMMM, y HH:mm':'':'it'}}<br>
          <b>{{row.timezone_formatted}}</b> {{row.preferred_interview_time_formatted}}
        </small>
        <small
          *ngIf="row.youtube_selftape_link && row.interview_date && !row.interview_accepted && !row.interview_rejected">
          waiting for interview response
        </small>
        <small *ngIf="row.youtube_selftape_link && row.interview_date && row.interview_accepted">
          <b class="font-green-jungle">Interview accepted</b> on
          {{row.interview_accepted | date:'d MMMM, y HH:mm':'':'it'}}<br>
          <b>Interview on </b>{{row.interview_date | date}} {{row.interview_time}} {{row.timezone_formatted}}<br>
          <b>Italian time </b>{{row.italian_interview_time_formatted}}<br>
        </small>
        <small *ngIf="row.youtube_selftape_link && row.interview_date && row.interview_rejected">
          <b class="font-red">Interview rejected</b> on {{row.interview_rejected | date:'d MMMM, y HH:mm':'':'it'}}<br>
          <b>Interview on </b>{{row.interview_date | date}} {{row.interview_time}}<br>
          <b>Preference: </b>{{row.timezone_formatted}} {{row.preferred_interview_time_formatted}}
        </small>
      </span>
      <span *ngIf="filter == 'audition'">
        <small *ngIf="!row.audition_accepted && !row.audition_rejected">
          waiting for a response
        </small>
        <small *ngIf="row.audition_accepted">
          <b class="font-green-jungle">Audition accepted</b> on
          {{row.audition_accepted | date:'d MMMM, y HH:mm':'':'it'}}<br>
        </small>
        <small *ngIf="row.audition_rejected">
          <b class="font-red">Audition rejected</b> on {{row.audition_rejected | date:'d MMMM, y HH:mm':'':'it'}}<br>
        </small>
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="phase == 1" name="Pref" [flexGrow]="3" prop="status_01_promote_preference"
    [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      {{ getConfigService().getapplicationPhasePreferenceSelectValueByKey(value) }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="phase == 2" name="Pref" [flexGrow]="3" prop="status_02_promote_preference"
    [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      {{ getConfigService().getapplicationPhasePreferenceSelectValueByKey(value) }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="phase == 3" name="Pref" [flexGrow]="3" prop="status_03_promote_preference"
    [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      {{ getConfigService().getapplicationPhasePreferenceSelectValueByKey(value) }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="" [flexGrow]="1" [resizeable]="false" [draggable]="false" prop="">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <button class="blue btn btn-block default btn-sm" (click)="openSelected(row)"><i class="fa fa-edit"
          aria-hidden="true"></i></button>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
      <b>Total</b> : {{rowCount}} Actors
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>


<!-- BEGIN: MODAL CONTENT ACTORS INFO -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
          <h4 class="modal-title pull-left">{{currentActorInfo.firstname}} {{currentActorInfo.surname}} Info Summary</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body form">
        <div class="row">
          <div class="col-md-12">
              <app-actors-applications-info-summary #actorsApplicationsInfoSummaryComponent [actorApplicationId]="currentActorInfo.id" (closeEvent)="hideModal()" ></app-actors-applications-info-summary>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL CONTENT ACTORS INFO -->
