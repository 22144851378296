import { NotificationsService } from 'angular2-notifications';
import { CourseScheduleService } from './../../_services/index';
import { CourseScheduleDiaryFormComponent } from './../course-schedule-diary-form/course-schedule-diary-form.component';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-course-schedule-diary-list',
  templateUrl: './course-schedule-diary-list.component.html',
  styleUrls: ['./course-schedule-diary-list.component.css']
})
export class CourseScheduleDiaryListComponent implements OnInit {

  courseScheduleDiaryList: any;
  courseScheduleDiaryListKey: any[];

  allFormValid = true;
  multisave = true;

  subscribe: any;

  constructor(private courseScheduleService: CourseScheduleService, private notify: NotificationsService) { }

  ngOnInit() {

    if(this.courseScheduleDiaryList == null)
      this.addNew();

    this.subscribe = this.courseScheduleService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.getDiaryInfo();
    });

    if (this.courseScheduleService.currentCourseDiaryList != null)
      this.getDiaryInfo();

  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  getDiaryInfo() {

    if (this.courseScheduleService.currentCourseDiaryList != null) {

      this.courseScheduleDiaryList = JSON.parse(JSON.stringify(this.courseScheduleService.currentCourseDiaryList));
      this.courseScheduleDiaryListKey = Object.keys(this.courseScheduleService.currentCourseDiaryList).reverse();

      //  Ordino le chiavi dell'array in base al valore di aggiornamento del model
      this.courseScheduleDiaryListKey = this.OrderByUpdate(this.courseScheduleDiaryListKey, this.courseScheduleDiaryList);

      //  Controllo che non ci sia un elemento attualmente in modifica
      let courseScheduleDiaryListOld = this.courseScheduleDiaryList;
      this.courseScheduleDiaryListKey.forEach(k => {

        if (typeof courseScheduleDiaryListOld[k] != "undefined" && this.courseScheduleDiaryList[k]["updated_at"] == courseScheduleDiaryListOld[k]["updated_at"])
          this.courseScheduleDiaryList[k] = courseScheduleDiaryListOld[k];

      });
    }

    if (this.courseScheduleService.currentCourseDiaryList != null && this.courseScheduleService.currentCourseDiaryList.length == 0) {
      this.addNew();
    }

  }


  addNew() {

    if (this.courseScheduleDiaryList == null) {
      this.courseScheduleDiaryList = {};
      this.courseScheduleDiaryListKey = new Array();
    }
    this.courseScheduleDiaryList[0] = {};
    this.courseScheduleDiaryListKey.unshift(0);
  }

  formDelete (event) {
    if(!event.model.id && this.courseScheduleDiaryListKey.length > 1) {
      this.courseScheduleDiaryListKey.splice(0, 1);
      delete this.courseScheduleDiaryList[0];
    }
  }

  formSaved (event) {

  }


  OrderByUpdate( listKey: any[], listValue: any) : any[]
  {

    let arrayTmp = new Array();

    listKey.forEach(key => { arrayTmp.push(listValue[key]) });

    arrayTmp.sort(function(a, b){
      return a.updated_at > b.updated_at ? -1 : a.updated_at < b.updated_at ? 1 : 0 }
    );

    let array = new Array();

    arrayTmp.forEach(element => {
      array.push(element.id);
    });

    return array;
  }

}
