import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";

import { User } from "../_models/index";
import { ConfigService } from "./config.service";

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private configService: ConfigService) { }

  public getCurrentUser() {
    return JSON.parse(localStorage.getItem("currentUser"));
  }

  public getCurrentSession() {
    return JSON.parse(localStorage.getItem("currentSession"));
  }

  public saveUserPreferences(key, value) {
    let currentUser = this.getCurrentUser();
    let currentUserPreferences = JSON.parse(localStorage.getItem("currentUserPreferences"));

    if (currentUserPreferences != null)
      currentUserPreferences[key] = value;
    else
      currentUserPreferences = { 'created': new Date() };

    currentUserPreferences['updated'] = new Date();

    localStorage.setItem("currentUserPreferences", JSON.stringify(currentUserPreferences));
  }

  public getUserPreferences(key) {
    let currentUser = this.getCurrentUser();
    let currentUserPreferences = JSON.parse(localStorage.getItem("currentUserPreferences"));

    if (currentUserPreferences == null) {
      this.saveUserPreferences("created", new Date());
      return null;
    }

    return currentUserPreferences[key];
  }

  // RUOLI

  public isActor() {
    return this.checkRole(6);
  }

  public isDocente() {
    return this.checkRole(1);
  }

  public isCampStaff() {
    return this.checkRole(4);
  }

  public isTutor() {
    return this.checkRole(9);
  }

  public isFacilitator() {
    return this.checkRole(12);
  }

  public isOfficeStaff() {
    return this.checkRole(10) || this.checkRole(11) || this.checkRole(13) || this.checkRole(14) || this.checkRole(15);
  }

  public isOfficeSalesStaff() {
    return this.checkRole(10);
  }

  public isOfficeFinancialStaff() {
    return this.checkRole(11);
  }

  public isOfficeAssistantFinancialStaff() {
    return this.checkRole(13);
  }

  public isOfficeShipmentStaff() {
    return this.checkRole(14);
  }

  public isOfficeEnglishStaff() {
    return this.checkRole(15);
  }

  public checkRole(roleType) {

    let currentUser = this.getCurrentUser();

    if (!currentUser || !currentUser.roles)
      return false;

    let roles = currentUser.roles;

    for (var k in roles) {
      if (roles[k].type == roleType) return true;
    }

    return false;
  }

  public checkPermission(permission_string) {

    let currentUser = this.getCurrentUser();

    if (!currentUser || !currentUser.roles)
      return false;

    let roles = currentUser.roles;

    for (var k in roles) {

      // controllo se il ruolo gli abilita il permesso
      let perm = this.configService.getDefaultRolePermissionByKey(roles[k].type);

      for (var p in perm) {

        if (perm[p] == permission_string) {

          if (roles[k].permissions) {
            // controllo se il permesso è stato escluso per quel ruolo solo per la sua persona
            let perm_user = JSON.parse(roles[k].permissions);

            for (var p_user in perm_user) {

              if (perm_user[p_user] == permission_string) {
                return false;
              }
            }
          }

          return true;
        }
      }
    }

    return false;

  }
}
