<div class="page-content" *ngIf="show">
    <div class="row">
      <div class="col-md-12">
        <div class="note note-warning" (click)="show = false">
          <h3>Your browser is not fully supported</h3>
          <p>Something may not work properly because you are using an old version of iOS, all the versions before 10.0 are not fully supported.</p>
          <p>Update the device to the latest available version and report any issue to the technical support.</p>
          <p class="left">Click to close {{deviceInfo | json}}</p>
        </div>
      </div>
    </div>
  </div>
