import {Injectable} from '@angular/core';
import {environment} from './../environment';
import {BackendService} from './backend.service';
import {Subject, Observable} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {HttpParams, HttpHeaders} from '@angular/common/http';
import {Nationalities} from './nationalities';

@Injectable()
export class FacilitatorApplicationService {

  resourceUrl = 'facilitatorsapplication';

  ongoingRequest: boolean = false;

  //  Memorizza l'ultima query di ricerca fatta
  lastSearchQueries: any = {};

  currentFacilitatorApplicationId;
  currentFacilitatorApplicationInfo: any[];
  currentFacilitatorApplicationRevision: any[] = [];

  private subjectToUpdate: Subject<boolean> = new Subject<boolean>();
  getsubjectToUpdateObservable(): Observable<boolean> {
    return this.subjectToUpdate.asObservable();
  }

  public sendUpdateEvent() {
    this.subjectToUpdate.next();
  }

  constructor(private backend: BackendService, private notify: NotificationsService) {}

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  isOngoingRequest() {
    return this.ongoingRequest;
  }

  resetService(){

  }


  public getLastActive(callbackSuccess: any = null, callbackError: any = null)
  {
    let params = new HttpParams();

    this.backend.get('facilitatorsapplicationedition/lastactive', params).subscribe (
      (response) => {

        let resJson : any = response;

        if (callbackSuccess != null)
          callbackSuccess(resJson);

      }, (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      }
    );
  }


  public getAllEditions(callbackSuccess: any = null, callbackError: any = null)
  {
    this.startRequest();

      let params = new HttpParams();

      this.backend.get('facilitatorsapplicationedition/all', params).subscribe (
        (response) => {

          let resJson : any = response;

          this.finishRequest();

          if (callbackSuccess != null)
            callbackSuccess(resJson);

        }, (error) => {
          this.backend.showErrors(error);

          this.finishRequest();

          if (callbackError != null)
            callbackError();
        }
      );
  }

  public saveEdition(model, callbackSuccess: any = null, callbackError: any = null)
  {
    this.startRequest();

    this.backend.post('facilitatorsapplicationedition', model).subscribe(
      (response) => {

        let resJson : any = response;

          this.finishRequest();

          if (callbackSuccess != null)
            callbackSuccess(resJson);

        }, (error) => {
          this.backend.showErrors(error);

          this.finishRequest();

          if (callbackError != null)
            callbackError();
        }
    );
  }


  public updateEdition(model, callbackSuccess: any = null, callbackError: any = null)
  {
    this.startRequest();

    this.backend.put('facilitatorsapplicationedition/'+model.id, model).subscribe(
      (response) => {

        let resJson : any = response;

          this.finishRequest();

          if (callbackSuccess != null)
            callbackSuccess(resJson);

        }, (error) => {
          this.backend.showErrors(error);

          this.finishRequest();

          if (callbackError != null)
            callbackError();
        }
    );
  }



  public getFileUploadURL(uid: String) {
    return (environment.apiUrl + 'facilitatorsapplicationforms/' + uid + '/upload');
  }
  public photoFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Upload Photo File', JSON.parse(response)['message']);
      else
        this.notify.error('Upload Photo File', response);

      this.backend.showErrors(response);
    }
  }



  public getCVUploadURL(uid: String) {
    return (environment.apiUrl + 'facilitatorsapplicationforms/' + uid + '/uploadcv');
  }

  public cvFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Upload CV File', JSON.parse(response)['message']);
      else
        this.notify.error('Upload CV File', response);

      this.backend.showErrors(response);
    }
  }


  public getCoverUploadURL(uid: String) {
    return (environment.apiUrl + 'facilitatorsapplicationforms/' + uid + '/uploadcover')
  }

  public coverFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Upload Cover File', JSON.parse(response)['message']);
      else
        this.notify.error('Upload Cover File', response);

      this.backend.showErrors(response);
    }
  }


  public getPassportUploadURL(uid: String) {
    return (environment.apiUrl + 'facilitatorsapplicationforms/' + uid + '/uploadpassport')
  }


  public passportFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Upload Passport File', JSON.parse(response)['message']);
      else
        this.notify.error('Upload Passport File', response);

      this.backend.showErrors(response);
    }
  }


  public getDrivingLicenceUploadURL(uid: String) {
    return (environment.apiUrl + 'facilitatorsapplicationforms/' + uid + '/uploaddriverlicence')
  }

  public drivingLicenceFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Upload Driving Licence File', JSON.parse(response)['message']);
      else
        this.notify.error('Upload Driving Licence File', response);

      this.backend.showErrors(response);
    }
  }


  public getIntChildProtectCertificateURL(uid: String) {
    return (environment.apiUrl + 'facilitatorsapplicationforms/' + uid + '/uploadchildprotectcertificate')
  }

  public intChildProtectCertificateFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Upload International Child Protection Certificate File', JSON.parse(response)['message']);
      else
        this.notify.error('Upload International Child Protection Certificate File', response);

      this.backend.showErrors(response);
    }
  }


  public getIntDriverLicenceURL(uid: String) {
    return (environment.apiUrl + 'facilitatorsapplicationforms/' + uid + '/uploadintdriverlicence')
  }

  public intDriverLicenceFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Upload International Driver Licence File', JSON.parse(response)['message']);
      else
        this.notify.error('Upload International Driver Licence File', response);

      this.backend.showErrors(response);
    }
  }



  public getHealthInsuranceURL(uid: String) {
    return (environment.apiUrl + 'facilitatorsapplicationforms/' + uid + '/uploadhealthinsurance')
  }

  public healthInsuranceFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Upload Health Insurance File', JSON.parse(response)['message']);
      else
        this.notify.error('Upload Health Insurance File', response);

      this.backend.showErrors(response);
    }
  }


  public getFlightReceiptURL(uid: String) {
    return (environment.apiUrl + 'facilitatorsapplicationforms/' + uid + '/uploadflightreceipt')
  }

  public flightReceiptFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Upload Flight Receipt File', JSON.parse(response)['message']);
      else
        this.notify.error('Upload Flight Receipt File', response);

      this.backend.showErrors(response);
    }
  }



  public getFileUlopadHeader() {
    return this.backend.setupAuthHttpHeaders().headers;
  }


  public deleteFile(uid: string, callbackSuccess = null, callbackError = null) {

    this.backend.destroy('facilitatorsapplicationforms/' + uid + '/destroyupload').subscribe (
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess();
      }, (error) => {

        if (callbackError != null)
          callbackError();
      }
    );
  }


  public deleteCv(uid: string, callbackSuccess = null, callbackError = null) {

    this.backend.destroy('facilitatorsapplicationforms/' + uid + '/destroycv').subscribe (
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess();
      }, (error) => {

        if (callbackError != null)
          callbackError();
      }
    );
  }


  public deleteCover(uid: string, callbackSuccess = null, callbackError = null) {

    this.backend.destroy('facilitatorsapplicationforms/' + uid + '/destroycover').subscribe (
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess();
      }, (error) => {

        if (callbackError != null)
          callbackError();
      }
    );
  }

  public deletePassport(uid: string, callbackSuccess = null, callbackError = null) {

    this.backend.destroy('facilitatorsapplicationforms/' + uid + '/destroypassport').subscribe (
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess();
      }, (error) => {

        if (callbackError != null)
          callbackError();
      }
    );
  }

  public deleteDrivingLicence(uid: string, callbackSuccess = null, callbackError = null) {

    this.backend.destroy('facilitatorsapplicationforms/' + uid + '/destroydriverlicence').subscribe (
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess();
      }, (error) => {

        if (callbackError != null)
          callbackError();
      }
    );
  }

  public deleteChildProtectCertificate(uid: string, callbackSuccess = null, callbackError = null) {

    this.backend.destroy('facilitatorsapplicationforms/' + uid + '/destroychildprotectcertificate').subscribe (
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess();
      }, (error) => {

        if (callbackError != null)
          callbackError();
      }
    );
  }

  public deleteIntDriverLicence(uid: string, callbackSuccess = null, callbackError = null) {

    this.backend.destroy('facilitatorsapplicationforms/' + uid + '/destroyintdriverlicence').subscribe (
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess();
      }, (error) => {

        if (callbackError != null)
          callbackError();
      }
    );
  }

  public deleteHealthInsurance(uid: string, callbackSuccess = null, callbackError = null) {

    this.backend.destroy('facilitatorsapplicationforms/' + uid + '/destroyhealthinsurance').subscribe (
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess();
      }, (error) => {

        if (callbackError != null)
          callbackError();
      }
    );
  }

  public deleteFlightReceipt(uid: string, callbackSuccess = null, callbackError = null) {

    this.backend.destroy('facilitatorsapplicationforms/' + uid + '/destroyflightreceipt').subscribe (
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess();
      }, (error) => {

        if (callbackError != null)
          callbackError();
      }
    );
  }


  getFacilitatorApplicationPhase(idPhase, searchParams, callbackSuccess: any = null, callbackError: any = null)
  {
      this.startRequest();

        this.backend.post(this.resourceUrl + '/phase/' + idPhase,  searchParams).subscribe (
        (response) => {

          let resJson : any = response;

          this.finishRequest();

          if (callbackSuccess != null)
            callbackSuccess(resJson);

        }, (error) => {
          this.backend.showErrors(error);

          this.finishRequest();

          if (callbackError != null)
            callbackError();
        }
      );
  }

  getFacilitatorApplicationInfo(callbackSuccess: any = null, callbackError: any = null) {

    let params = new HttpParams();

    this.backend.get(this.resourceUrl + '/' + this.currentFacilitatorApplicationId, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.currentFacilitatorApplicationInfo = resJson;
        this.currentFacilitatorApplicationId = resJson.id;

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }


  getFacilitatorApplicationRevision(callbackSuccess: any = null, callbackError: any = null) {

    let params = new HttpParams();

    this.backend.get(this.resourceUrl + '/' + this.currentFacilitatorApplicationId + '/revision', params).subscribe(
      (response) => {

        let resJson : any = response;
        this.currentFacilitatorApplicationRevision = resJson;

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  public getNationalityLabel(code: string) {

    if (!code)
      return;

    for (let i = 0; i < Nationalities.length; i++) {
      let country = Nationalities[i];
      if (country.value == code.trim()) {
        return country.label;
      }
    }

    return code;
  }

  public getNationalitiesLabel(input: string) {

    if (input == null)
      input = "";

    let nationalitiesLabel = '';

    let codes = input.split(",");

    for (let i = 0; i < codes.length; i++) {

      let code = codes[i];

      let nationalityLabel = this.getNationalityLabel(code.trim());

      if (i === 0) {
        nationalitiesLabel = nationalityLabel;
      }
      else {
        nationalitiesLabel = nationalitiesLabel + ', ' + nationalityLabel;
      }
    }

    return nationalitiesLabel;
  }


  public updateAllFacilitatorApplicationInfo (model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put(this.resourceUrl + '/' + model.id + '/updateall', model).subscribe(
      (response) => {

        this.notify.success("Facilitator", "successfully updated");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );
  }


  public promoteFacilitator(id: String, model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post(this.resourceUrl + '/' + id + '/promote', model).subscribe(
      (response) => {

        let resJson : any = response;

        this.notify.success("Facilitator", "successfully promote");

        this.finishRequest();

        this.subjectToUpdate.next();

        if (callbackSuccess != null)
          callbackSuccess(resJson)
      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      })
  }

  public hireFacilitator(id: String, model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post(this.resourceUrl + '/' + id + '/hire', model).subscribe(
      (response) => {

        let resJson : any = response;

        this.notify.success("Facilitator", "successfully hired!");

        this.finishRequest();

        this.subjectToUpdate.next();

        if (callbackSuccess != null)
          callbackSuccess(resJson)
      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      })
  }


  public rejectFacilitator(id: String, model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post(this.resourceUrl + '/' + id + '/reject', model).subscribe(
      (response) => {

        let resJson : any = response;

        this.notify.success("Facilitator", "successfully rejected");

        this.finishRequest();

        this.subjectToUpdate.next();

        if (callbackSuccess != null)
          callbackSuccess(resJson)
      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      })
  }

  public cancelRejectFacilitator(id: String, model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post(this.resourceUrl + '/' + id + '/cancelreject', model).subscribe(
      (response) => {

        let resJson : any = response;

        this.notify.success("Facilitator", "successfully cancelled rejected status");

        this.finishRequest();

        this.subjectToUpdate.next();

        if (callbackSuccess != null)
          callbackSuccess(resJson)
      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      })
  }


  saveInterviewInfo (id: String, model: any, callbackSuccess = null, callbackError = null)
  {
    this.backend.put(this.resourceUrl + '/' + id + '/interviewinfo', model).subscribe(
      (response) => {

        let resJson : any = response;

        this.notify.success("Facitator", "successfully set Interview Info");

        this.finishRequest();

        this.subjectToUpdate.next();

        if (callbackSuccess != null)
          callbackSuccess(resJson)
      },
      (error) => {

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
    })
  }

  sendEmailType (id: string, type: string, callbackSuccess: any = null, callbackError: any = null)
	{
    this.startRequest();

		let params = new HttpParams();

		this.backend.get(this.resourceUrl + "/" + id + '/sendmail/' + type, params).subscribe (
			(response) => {

				// var resJson : any = response;

				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}

}
