import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/_services';

@Component({
  selector: 'app-person-home',
  templateUrl: './person-home.component.html',
  styleUrls: ['./person-home.component.css']
})
export class PersonHomeComponent implements OnInit {

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit() {}

  searchKeywordsSubmit(data){
    this.router.navigate(["person/search", data], { skipLocationChange: true });
  }

  getUserService() {
    return this.userService;
  }

}
