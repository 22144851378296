<ngx-datatable
class="striped"
[rows]="rows"
[columnMode]="'flex'"
[headerHeight]="50"
[footerHeight]="0"
[rowHeight]="'auto'"
[selected]="selected"
[selectionType]="'single'"
(select)='onSelect($event)'
[sorts]="[{prop: 'start_date', dir: 'asc'}]"
>
<ngx-datatable-column name="Vehicle"  prop="vehicle_data" [flexGrow]="2" 
[resizeable]="false"
[draggable]="false"
>
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value"> {{value.brand_model}} {{value.brand_name}} {{value.plate_number}}</span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Date Start"  prop="start_date" [flexGrow]="2" 
[resizeable]="false"
[draggable]="false"
>
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value">{{ row.start_date_parsed | date }}</span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Date End"  prop="end_date" [flexGrow]="2" 
[resizeable]="false"
[draggable]="false"
>
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value">{{ row.end_date_parsed | date }}</span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Name"  prop="holder_name" [flexGrow]="6" 
[resizeable]="false"
[draggable]="false"
>
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="row.in_holder_data"><i class="fa fa-{{row.holder_icon}}"></i> {{ value }} 
      <a class="blue pointer" target="_blank" routerLink="/{{row.holder_link}}/edit/{{ row.in_holder_id }}">
        <i class="fa fa-external-link" aria-hidden="true"></i>
      </a>
    </span>
    <span *ngIf="!row.in_holder_data" class="text-danger"> <i class="fa fa-{{row.holder_icon}}"></i> Deleted
      <i class="fa fa-trash font-red-haze" aria-hidden="true"></i>
    </span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Reference" prop="theatrino_data" [flexGrow]="4" 
[resizeable]="false"
[draggable]="false"
>
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngFor="let tour of row.theatrino_data">
      <a class="btn blue btn-xs btn-outline" routerLink="/theatrino/edit/{{ tour.theatrino_id }}">{{tour.theatrino_info?.denomination}}</a>
    </span>
  </ng-template>
</ngx-datatable-column>
</ngx-datatable>
