import { Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import { HelperService, ConfigService, VehicleService } from '../../_services/index';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { parseISO } from 'date-fns';
import { VehicleComponent } from '../vehicle.component';
import { FileUploader } from 'ng2-file-upload';


@Component({
  selector: 'app-vehicle-info-form',
  templateUrl: './vehicle-info-form.component.html',
  styleUrls: ['./vehicle-info-form.component.css']
})
export class VehicleInfoFormComponent implements OnInit {

  model: any = {};

  @Input() vehicleComponent: VehicleComponent;

  public localContractStartData: IMyDateModel;
  public localContractEndData: IMyDateModel;

  //  Url per l'upload di file
  private headers: any;

  subscribe: any;

  //  file uploaders
  public photoUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp'],
      url: 'serverUrl'
    }
  );

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(private vehicleService: VehicleService, private helper: HelperService,
              private router: Router, private configService: ConfigService, private ref: ChangeDetectorRef) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    this.subscribe = this.vehicleService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.model = this.vehicleService.currentVehicleInfo;

      if (this.model == null) this.model = {};
      else
      {
        //  Setto l'url per il caricamento delle foto e gli header
        this.headers = this.vehicleService.getVehicleFileUlopadHeader();

        this.photoUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
        this.photoUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
          this.imageUploaded(item, response, status);
        };
        this.photoUploader.options.url = this.vehicleService.getVehicleFileUploadURL(this.model);

        //  contract start data
        if (this.model.contract_start_date != null)
          this.localContractStartData = this.helper.initMyDataInputSingle(this.model.contract_start_date);
        //  contract end data
        if (this.model.contract_end_date != null)
          this.localContractEndData = this.helper.initMyDataInputSingle(this.model.contract_end_date);

        //  validate active
        if (this.model.active)
          this.model.active = this.model.active === 1;

        //  Se esiste il path per l'immagine costruisco l'url completo
        if (this.model.photo_url)
          this.model.photoUrl = this.configService.blobBasePath+this.model.photo_url;
      }

    });

  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }


  submit() {

    if (!this.helper.isMyDateValid(this.localContractStartData, false)) return;
    if (!this.helper.isMyDateValid(this.localContractEndData, false)) return;

    if (this.model.id != null)
      this.vehicleService.updateVehicleInfo(this.model);
    else
      this.vehicleService.saveVehicleInfo(this.model, () => {
        this.vehicleComponent.activeAllTab();
        this.vehicleComponent.selectTab(0);

        this.router.navigate(["vehicle/edit/"+this.vehicleService.currentVehicleId]);
      });
  }

  destroy() {

    this.vehicleService.deleteVehicleInfo(this.model, ()=> {
      this.router.navigate(["vehicle/home"]);
    });

  }


  //  contract start Data
  onDateContractStartChanged(event: IMyDateModel) {

    this.localContractStartData = event;

    if (event.singleDate.jsDate != null)
      this.model.contract_start_date = this.helper.formatISODate(event);
    else
      this.model.contract_start_date = null;
  }



  //  contract end Data
  onDateContractEndChanged(event: IMyDateModel) {

    this.localContractEndData = event;

    if (event.singleDate.jsDate != null)
      this.model.contract_end_date = this.helper.formatISODate(event);
    else
      this.model.contract_end_date = null;
  }


  checkSubmitKeyEnter(event){

    // if (event.target.name == "birthplace")
    //   return false;

    return true;
  }

  //  Image Uploader
  public imageUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.model.photoUrl = this.configService.blobBasePath + response;
        this.model.photo_url = response;
      }
      this.vehicleService.vehiclePhotoFileResponse(item, response, status,  this.model.id);
    }
  }


  //  Remove image
  destroyFile() {
    this.vehicleService.deleteVehiclePhotoFile(this.model);
  }


  getConfigService() {
    return this.configService
  }

  getLocalContractStartData() {
    return this.localContractStartData
  }

  getLocalContractEndData() {
    return this.localContractEndData
  }

  getHelper() {
    return this.helper
  }

  getVehicleService() {
    return this.vehicleService
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

}
