<div>
	
	<!-- BEGIN Assigned Tutors -->
	<div>

		<div class="form-body">
	    	<h3 class="form-section">Assigned Tutors ({{this.camp.table_weeks[this.calendarWeekIndex].tutors}} provisional tutors needed)</h3>
	
			<alert type="warning" *ngIf="ongoingAssignedRequest">
		  		<strong>Loading</strong> ...
			</alert>
		
			<ngx-datatable *ngIf="!ongoingAssignedRequest"
			  class="material striped"
			  [rows]="assignedTutors"
			  [columnMode]="'flex'"
			  [headerHeight]="50"
			  [footerHeight]="50"
			  [rowHeight]="'auto'"
			  [externalPaging]="true"
			  [externalSorting]="true"
			  [count]="assignedPageTotalElements"
			  [offset]="assignedPageNumber"
			  [limit]="assignedPageSize"
			  [selected]="assignedSelected"
			  [selectionType]="'single'"
			  (select)='onAssignedSelect($event)'
			  (page)='setAssignedPage($event)'
			  (sort)='onAssignedSort($event)'
			>  
			  <ngx-datatable-column name="Name" [flexGrow]="1" prop="first_name" [resizeable]="false"></ngx-datatable-column>
			  <ngx-datatable-column name="Surname" [flexGrow]="1" prop="surname" [resizeable]="false"></ngx-datatable-column>
			  
			</ngx-datatable>	
		</div>
		
		<div class="form-actions right">
		
			<img *ngIf="ongoingAssignedRequest" src="./assets/global/img/input-spinner.gif" />
		    
		    <ng-template #confirmDeleteTemplate >
		      <button type="button" class="btn btn-outline red" (click)="confirmDelete.hide();removeAssignedTutors();">Yes Remove!</button>
		    </ng-template>		    
		    <button type="button" class="btn btn-outline red" [disabled]="ongoingAssignedRequest || assignedSelected.length === 0" 
		      [outsideClick]="true" [popover]="confirmDeleteTemplate" popoverTitle="Are you sure?" #confirmDelete="bs-popover" >
		    	<i class="fa fa-trash-o"></i> Remove
		    </button>&nbsp;
		</div>
		
	</div>
	<!-- END Assigned Tutors -->	
		
	<!-- BEGIN Available Tutors -->	
	<div>
		<div class="form-body">
	    	<h3 class="form-section">Available Tutors</h3>
	    	
			<alert type="warning" *ngIf="ongoingAvailableRequest">
		  		<strong>Loading</strong> ...
			</alert>
						
			<form action="#" name="form" (ngSubmit)="f1.form.valid && submitSearchAvailable()" #f1="ngForm" novalidate>			
				<div class="row">
				    <div class="col-md-6">
				      <div class="input-group input-group-lg input-icon right" [ngClass]="{ 'has-error': f1.submitted && !simpleSearchKeywordAvailableText.valid }">
				        <i class="fa fa-times-circle" aria-hidden="true" (click)="simpleSearchKeywordAvailable = '';"></i>
				        <input type="text" name="simpleSearchKeywordAvailableText" #simpleSearchKeywordAvailableText="ngModel" [(ngModel)]="simpleSearchKeywordAvailable"
				          (keydown)="fieldChangeAvailable($event);" class="form-control" placeholder="Search for tutor name...">
				        <span class="input-group-btn">
				          <button class="btn green" [disabled]="ongoingAvailableRequest" type="submit">Search</button><i class="fa fa-search"></i>
				        </span>
				      </div>
				      <small [ngClass]="{ 'has-error': f1.submitted && !simpleSearchKeywordAvailableText.valid }">
				        <span class="help-block">Type a word to search in surname and name</span>
				      </small>
				    </div>
	  			</div>
			</form>	
		
			<ngx-datatable *ngIf="!ongoingAvailableRequest"
			  class="material striped"
			  [rows]="availableTutors"
			  [columnMode]="'flex'"
			  [headerHeight]="50"
			  [footerHeight]="50"
			  [rowHeight]="'auto'"
			  [externalPaging]="true"
			  [externalSorting]="true"
			  [count]="availablePageTotalElements"
			  [offset]="availablePageNumber"
			  [limit]="availablePageSize"
			  [selected]="availableSelected"
			  [selectionType]="'single'"
			  (select)='onAvailableSelect($event)'
			  (page)='setAvailablePage($event)'
			  (sort)='onAvailableSort($event)'
			>  
			  <ngx-datatable-column name="Name" [flexGrow]="1" prop="first_name" [resizeable]="false"></ngx-datatable-column>
			  <ngx-datatable-column name="Surname" [flexGrow]="1" prop="surname" [resizeable]="false"></ngx-datatable-column>
			  
			</ngx-datatable>
		</div>
		<div class="form-actions right">
		
			<img *ngIf="ongoingAvailableRequest" src="./assets/global/img/input-spinner.gif" />
		    	    
		    <button type="button" class="btn btn-outline blue" (click)="addAvailableTutors();" [disabled]="ongoingAssignedRequest || availableSelected.length === 0">
		    	<i class="fa fa-edit"></i> Assign
		    </button>&nbsp;
		</div>
	</div>
	<!-- END Available Tutors -->

	<!-- BEGIN Assigned Tutors to Other Camps -->
	<div>

		<div class="form-body">
	    	<h3 class="form-section">Assigned Tutors of Other Camps</h3>
	
			<alert type="warning" *ngIf="ongoingAssignedToOthersRequest">
		  		<strong>Loading</strong> ...
			</alert>
		
			<form action="#" name="form" (ngSubmit)="f2.form.valid && submitSearchAssignedToOthers()" #f2="ngForm" novalidate>			
				<div class="row">
				    <div class="col-md-6">
				      <div class="input-group input-group-lg input-icon right" [ngClass]="{ 'has-error': f2.submitted && !simpleSearchKeywordAssignedToOthersText.valid }">
				        <i class="fa fa-times-circle" aria-hidden="true" (click)="simpleSearchKeywordAssignedToOthers = '';"></i>
				        <input type="text" name="simpleSearchKeywordAssignedToOthersText" #simpleSearchKeywordAssignedToOthersText="ngModel" [(ngModel)]="simpleSearchKeywordAssignedToOthers"
				          (keydown)="fieldChangeAssignedToOthers($event);" class="form-control" placeholder="Search for tutor name...">
				        <span class="input-group-btn">
				          <button class="btn green" [disabled]="ongoingAssignedToOthersRequest" type="submit">Search</button><i class="fa fa-search"></i>
				        </span>
				      </div>
				      <small [ngClass]="{ 'has-error': f2.submitted && !simpleSearchKeywordAssignedToOthersText.valid }">
				        <span class="help-block">Type a word to search in surname and name</span>
				      </small>
				    </div>
	  			</div>
			</form>	
		
			<ngx-datatable *ngIf="!ongoingAssignedToOthersRequest"
			  class="material striped"
			  [rows]="assignedToOthersTutors"
			  [columnMode]="'flex'"
			  [headerHeight]="50"
			  [footerHeight]="50"
			  [rowHeight]="'auto'"
			  [externalPaging]="true"
			  [externalSorting]="true"
			  [count]="assignedToOthersPageTotalElements"
			  [offset]="assignedToOthersPageNumber"
			  [limit]="assignedToOthersPageSize"
			  [selected]="assignedToOthersSelected"
			  [selectionType]="'single'"
			  (select)='onAssignedToOthersSelect($event)'
			  (page)='setAssignedToOthersPage($event)'
			  (sort)='onAssignedToOthersSort($event)'
			>  
			  <ngx-datatable-column name="Name" [flexGrow]="1" prop="first_name" [resizeable]="false"></ngx-datatable-column>
			  <ngx-datatable-column name="Surname" [flexGrow]="1" prop="surname" [resizeable]="false"></ngx-datatable-column>
			  <ngx-datatable-column name="Camp" [flexGrow]="1" prop="locality" [resizeable]="false"></ngx-datatable-column>
			  <ngx-datatable-column name="Code" [flexGrow]="1" prop="camp_code" [resizeable]="false"></ngx-datatable-column>
			  
			</ngx-datatable>	
		</div>
		
		<div class="form-actions right">
		
			<img *ngIf="ongoingAssignedToOthersRequest" src="./assets/global/img/input-spinner.gif" />
		    
		    <ng-template #confirmOthersDeleteTemplate >
		      <button type="button" class="btn btn-outline red" (click)="confirmOthersDelete.hide();removeAssignedToOthersTutors();">Yes Remove!</button>
		    </ng-template>		    
		    <button type="button" class="btn btn-outline red" [disabled]="ongoingAssignedToOthersRequest || assignedToOthersSelected.length === 0" 
		      [outsideClick]="true" [popover]="confirmOthersDeleteTemplate" popoverTitle="Are you sure?" #confirmOthersDelete="bs-popover">
		    	<i class="fa fa-trash-o"></i> Remove
		    </button>&nbsp;
		    
		    <ng-template #confirmOthersDeleteAndAssingTemplate >
		      <button type="button" class="btn btn-outline red" (click)="confirmOthersDeleteAndAssing.hide();removeAndAssingAssignedToOthersTutors();">Yes Remove!</button>
		    </ng-template>
		    <button type="button" class="btn btn-outline blue" [disabled]="ongoingAssignedToOthersRequest || assignedToOthersSelected.length === 0" 
		      [outsideClick]="true" [popover]="confirmOthersDeleteAndAssingTemplate" popoverTitle="Are you sure?" #confirmOthersDeleteAndAssing="bs-popover">
		    	<i class="fa fa-trash-o fa-edit"></i> Remove and Assign
		    </button>&nbsp;
		</div>
				
	</div>
	<!-- END Assigned Tutors to Other Camps -->	

</div>