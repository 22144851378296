<!-- BEGIN FORM -->
<form (keydown.enter)="checkSubmitKeyEnter($event)" action="#" class="horizontal-form" name="form"
  (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body" *ngIf="model">

    <!-- Left -->
    <div class="row">
      <div class="col-md-6">

        <div class="row" *ngIf="editMode">
          <div class="form-group">
            <div class="col-md-12" *ngIf="model.type"
              [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
              <label class="control-label">Staff Type
                <span class="required">*</span>
              </label>
              <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
                <option *ngFor="let st of getConfigService().campStaffTypeSelect" [value]="st.key">{{st.value}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!editMode">
          <div class="form-group">
            <div class="col-md-12 read-only" *ngIf="model.type">
              <label class="control-label">Staff Type</label>
              <p>{{getConfigService().getCampStaffTypeValueByKey(model.type)}}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group">
            <div class="col-md-12 read-only">
              <label class="control-label">Name</label>
              <p>
                <a *ngIf="model.person_info?.id" class="blue pointer"
                  routerLink="/person/edit/{{ model.person_info?.id }}">{{model.person_info?.surname}}
                  {{model.person_info?.first_name}}</a>
                <a *ngIf="model.person_info?.id" class="blue pointer" target="_blank"
                  routerLink="/person/edit/{{ model.person_info?.id }}"><i class="fa fa-external-link"
                    aria-hidden="true"></i></a>

                <a *ngIf="model.enterprise_info?.id" class="blue pointer"
                  routerLink="/enterprise/edit/{{ model.enterprise_info?.id }}">{{model.enterprise_info?.denomination}}</a>
                <a *ngIf="model.enterprise_info?.id" class="blue pointer" target="_blank"
                  routerLink="/enterprise/edit/{{ model.enterprise_info?.id }}"><i class="fa fa-external-link"
                    aria-hidden="true"></i></a>
              </p>
              <!-- <app-person-info-show [idPerson]="model.person_info?.id"></app-person-info-show> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <div class="col-md-12 read-only">
              <label class="control-label">Contacts</label>
              <ul>
                <li *ngFor="let contact of model.person_info?.contact | mapFromKeyValue">
                  {{getConfigService().getContactPersonValueByKey(contact.type)}}: {{contact.value}}
                  <span *ngIf="contact.type == 1 && ((model.transfer_detail_from && model.transfer_detail_from.length > 0) || (model.transfer_detail_to && model.transfer_detail_to.length > 0)) ">
                      <ng-template #confirmMail>
                        <button type="button" class="btn btn-outline green-jungle" (click)="inviaMailTo($event, contact.value)">Si, invia la mail!</button>
                      </ng-template>
                      <button type="button" [outsideClick]="true" [popover]="confirmMail" popoverTitle="Sei sicuro?" class="btn btn-outline btn-block btn-xs blue"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Invia Transfer details arrivals/departures</button>
                  </span>
                  <span *ngIf="contact.type != 1 && contact.type != 8 && ((model.transfer_detail_from && model.transfer_detail_from.length > 0) || (model.transfer_detail_to && model.transfer_detail_to.length > 0)) ">
                    <ng-template #confirmMail>
                      <button type="button" class="btn btn-outline green-jungle" (click)="inviaWhatsappTo($event, contact.value)">Si, apri whatsapp!</button>
                    </ng-template>
                    <button type="button" [outsideClick]="true" [popover]="confirmMail" popoverTitle="Sei sicuro?" class="btn btn-outline btn-block btn-xs green"><i class="fa fa-whatsapp" aria-hidden="true"></i>&nbsp;Invia Whatsapp</button>
                </span>
                </li>
              </ul>
              <ul>
                <li *ngFor="let contact of model.enterprise_info?.contact | mapFromKeyValue">{{getConfigService().getContactEnterpriseValueByKey(contact.type)}}: {{contact.value}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="editMode && model.type == 3">
          <div class="col-md-12">
            <div class="mt-checkbox-inline" style="margin:8px 0 -10px">
              <label class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" placeholder="" name="setdirector" [(ngModel)]="model.asdirector" (change)="setAsDirector()" > Set as Camp Director
                <span></span>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" *ngIf="model.asdirector">
            <div class="form-group">
              <select class="form-control" name="email" [(ngModel)]="model.staff_email">
                <option [value]=""></option>
                <ng-container *ngFor="let c of model.person_info?.contact | mapFromKeyValue">
                  <option *ngIf="c.type == 1" [value]="c.id">{{c.value}}</option>
                </ng-container>
                <ng-container *ngFor="let c of model.enterprise_info?.contact | mapFromKeyValue">
                  <option *ngIf="c.type == 1" [value]="c.id">{{c.value}}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!editMode && model.staff_email">
          <div class="form-group">
            <div class="col-md-12 read-only">
              <label class="control-label">Contact for Camps</label>
              <ng-container *ngFor="let c of model.person_info?.contact | mapFromKeyValue">
                <p *ngIf="c.id == model.staff_email">{{c.value}}</p>
              </ng-container>
              <ng-container *ngFor="let c of model.enterprise_info?.contact | mapFromKeyValue">
                <p *ngIf="c.id == model.staff_email">{{c.value}}</p>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="editMode">
          <div class="col-md-12">
            <div class="form-group">
              <label>Weeks</label>
              <div class="mt-checkbox-inline">
                <label class="mt-checkbox" *ngIf="campWeeks > 0">
                  <input type="checkbox" name="week_01" (change)="dateChange(1)" [ngModel]="model.week_01"> {{
                  arrayOfWeeks[0] | date:'d MMMM, y':'':'it'}}
                  <span></span>
                </label>
                <label class="mt-checkbox" *ngIf="campWeeks > 1">
                  <input type="checkbox" name="week_02" (change)="dateChange(2)" [ngModel]="model.week_02"> {{
                  arrayOfWeeks[1] | date:'d MMMM, y':'':'it'}}
                  <span></span>
                </label>
                <label class="mt-checkbox" *ngIf="campWeeks > 2">
                  <input type="checkbox" name="week_03" (change)="dateChange(3)" [ngModel]="model.week_03"> {{
                  arrayOfWeeks[2] | date:'d MMMM, y':'':'it'}}
                  <span></span>
                </label>
                <label class="mt-checkbox" *ngIf="campWeeks > 3">
                  <input type="checkbox" name="week_04" (change)="dateChange(4)" [ngModel]="model.week_04"> {{
                  arrayOfWeeks[3] | date:'d MMMM, y':'':'it'}}
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!editMode && (model.week_01 || model.week_02 || model.week_03 || model.week_04)">
          <div class="form-group">
            <div class="col-md-12 read-only">
              <label class="control-label">Weeks</label>
              <ul>
                <li *ngIf="model.week_01">{{ model.week_01 | date:'d MMMM, y':'':'it'}}</li>
                <li *ngIf="model.week_02">{{ model.week_02 | date:'d MMMM, y':'':'it'}}</li>
                <li *ngIf="model.week_03">{{ model.week_03 | date:'d MMMM, y':'':'it'}}</li>
                <li *ngIf="model.week_04">{{ model.week_04 | date:'d MMMM, y':'':'it'}}</li>
              </ul>
            </div>
          </div>
        </div>

      </div>

      <!-- Right -->
      <div class="col-md-6">

        <!-- BEGIN: portlet con la lista dei documenti -->
        <div class="portlet light bordered">
          <div class="portlet-title">
            <div class="caption">
              <span class="caption-subject bold uppercase" [hidden]="documentUploader.isUploading"> Documenti Inseriti</span>
            </div>
            <div class="actions">
              <div class="btn-group">
                <div class="row text-right">
                  <div *ngIf="!uploadDocument && editMode" class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn green btn-outline dropdown-toggle"
                      [disabled]="loadingDocuments || getCampService().isOngoingRequest()">
                      <i class="fa fa-plus"></i>&nbsp; Add Document &nbsp;
                      <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                      <li role="menuitem" *ngFor="let st of getConfigService().campStaffDocumentTypeSelect">
                        <a class="dropdown-item" *ngIf="!docLocked(st.key)"
                          (click)="addNewDocument(st.key)">{{st.value}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div *ngIf="model.id && uploadDocument" class="row">
              <div *ngIf="model.avatarUrl == undefined || model.avatarUrl == ''">
                <input *ngIf="!documentUploader.isUploading" type="file" id="documentUploader"
                  ng2FileSelect [uploader]="documentUploader" />
                <label *ngIf="!documentUploader.isUploading" for="documentUploader" style="white-space: normal;"
                  class="btn btn-lg btn-block green-jungle text-uppercase label-file"><i class="fa fa-upload"></i>&nbsp; UPLOAD {{getConfigService().getCampStaffDocumentTypeValueByKey(currentDcType)}}</label>
              </div>
              <div *ngIf="documentUploader.isUploading || getCampService().isOngoingRequest()">
                <img src="./assets/global/img/input-spinner.gif" />
                please wait...
              </div>
              <div *ngIf="model.avatarUrl != undefined && model.avatarUrl != ''" style="padding: 10px;">
                <button *ngIf="!documentUploader.isUploading" type="button"
                  class="btn btn-danger text-uppercase" (click)="clearNewDocument();"><i
                    class="fa fa-trash"></i>&nbsp; Annulla Upload</button>
              </div>
            </div>
          </div>
          <div class="portlet-body form">
            <form role="form">
              <div class="form-body">
                <div class="form-group">
                  <div class="mt-checkbox-list">
                    <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                      *ngFor="let value of personDocuments">
                      <div class="col-xs-8" style="margin-bottom: 3px;">
                        <div class="align-to-button">
                          <i class="fa fa-file-o" style="color:gray" aria-hidden="true"></i>
                          <a *ngIf="value.file_path" href="{{getConfigService().blobBasePath+value.file_path}}"
                            target="_blank">
                            {{getConfigService().getCampStaffDocumentTypeValueByKey(value.type)}}</a>
                          <br />
                        </div>
                      </div>
                      <div class="col-xs-4">
                        <button class="btn red" *ngIf="value.id && !uploadDocument && editMode"
                          [disabled]="loadingDocuments || getCampService().isOngoingRequest()" type="button"
                          (click)="destroyDocument(value.id);">Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- END: portlet -->
        </div>

        <div class="portlet light bordered">
          <div class="portlet-title">
            <div class="caption">
              <span class="caption-subject bold uppercase" [hidden]="documentUploader.isUploading"> Documenti autogenerati</span>
            </div>
          </div>
          <div class="portlet-body form">
            <form role="form">
              <div class="form-body">
                <div class="form-group">
                  <div class="mt-checkbox-list">
                    <button *ngIf="model.type <= 3" type="button" class="btn btn-sm green btn-block btn-outline"
                      (click)="downloadPdfTemplate('lettera-incarico');"><i class="fa fa-file-pdf-o"></i>&nbsp; Lettera
                      d'Incarico</button>
                    <button *ngIf="model.type <= 4" type="button" class="btn btn-sm green btn-block btn-outline"
                      (click)="downloadPdfTemplate('certificato');"><i class="fa fa-file-pdf-o"></i>&nbsp;
                      Certificato</button>
                      <button *ngIf="model.transfer_detail_from && model.transfer_detail_from.length > 0" type="button" class="btn btn-sm green btn-block btn-outline"
                        (click)="downloadPdfTemplate('transfer-details-departures');"><i
                          class="fa fa-file-pdf-o"></i>&nbsp;Transfer Details Partenze <i class="fa fa-plane" aria-hidden="true"></i></button>
                      <button *ngIf="model.transfer_detail_to && model.transfer_detail_to.length > 0" type="button" class="btn btn-sm green btn-block btn-outline"
                        (click)="downloadPdfTemplate('transfer-details-arrivals');"><i
                          class="fa fa-file-pdf-o"></i>&nbsp;Transfer Details Arrivi <i class="fa fa-plane" style="transform: scaleY(-1);" aria-hidden="true"></i></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- END: portlet -->
        </div>
      </div>

    </div>

    <!-- SOTTO alle colonne di destra e sinistra con i dettagli dello staff e documenti inseriti -->
    <div class="row">
      <div class="col-md-6">
        <div class="portlet light bordered">
          <div class="portlet-title">
            <div class="caption">
              <span class="caption-subject bold"><i class="fa fa-plane" aria-hidden="true"></i> Departures</span>
            </div>
          </div>
          <div class="portlet-body">
            <p *ngFor="let d of model.transfer_detail_from">
              Departs from <b class="uppercase">{{d.from_place}}</b> <small>({{d.from_camp_code}})</small> with {{ d.transport }} on <b><i class="fa fa-calendar" aria-hidden="true"></i> {{d.from_date | date:'d MMMM, y':'':'it'}} at {{d.from_time}}</b>, to <span class="uppercase">{{d.to_place}}</span> <small>({{d.to_camp_code}}) on {{d.to_date | date:'d MMMM, y':'':'it'}} at {{d.to_time}}</small><br>
              <br>
              <small class="line-breaker"><b>Note:</b> {{d.annotation}}</small><br><br>
              <small class="line-breaker"><i class="fa fa-refresh" aria-hidden="true"></i> <b>Ultimo aggiornamento:</b> {{d.updated_at | date:'d MMMM, y - HH:mm':'':'it'}}</small><br>
              <i *ngIf="d.file_path" class="fa fa-ticket" aria-hidden="true"></i> <a *ngIf="d.file_path"
                href="{{getConfigService().blobBasePath + d.file_path}}" target="_blank"> Download ticket</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
          <div class="portlet light bordered">
            <div class="portlet-title">
              <div class="caption">
                <span class="caption-subject bold"><i class="fa fa-plane" style="transform: scaleY(-1);" aria-hidden="true"></i> Arrivals</span>
              </div>
            </div>
            <div class="portlet-body">
              <p *ngFor="let d of model.transfer_detail_to">
                Arrives in <b class="uppercase">{{d.to_place}}</b> <small>({{d.to_camp_code}})</small> with {{ d.transport }} on <b><i class="fa fa-calendar" aria-hidden="true"></i> {{d.to_date | date:'d MMMM, y':'':'it'}} at {{d.to_time}}</b>, from <span class="uppercase">{{d.from_place}}</span> <small>({{d.from_camp_code}}) on {{d.from_date | date:'d MMMM, y':'':'it'}} at {{d.from_time}}</small><br>
                <br>
                <small class="line-breaker"><b>Note:</b> {{d.annotation}}</small><br><br>
                <small class="line-breaker"><i class="fa fa-refresh" aria-hidden="true"></i> <b>Ultimo aggiornamento:</b> {{d.updated_at | date:'d MMMM, y - HH:mm':'':'it'}}</small><br>
                <i *ngIf="d.file_path" class="fa fa-ticket" aria-hidden="true"></i> <a *ngIf="d.file_path"
                  href="{{getConfigService().blobBasePath + d.file_path}}" target="_blank"> Download ticket</a>
              </p>
            </div>
          </div>
        </div>
    </div>


    <!-- END: if form body -->
  </div>

  <div class="form-actions right" *ngIf="getCampService().canEditStaff()">
    <img *ngIf="loadingDocuments || getCampService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn btn-outline red"
      [disabled]="loadingDocuments || getCampService().isOngoingRequest()" *ngIf="this.model.id && this.model.type!='8'"
      [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
      <i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline red"
      [disabled]="loadingDocuments || getCampService().isOngoingRequest()" *ngIf="!this.model.id">
      <i class="fa fa-trash-o"></i> Delete</button>
    <button type="button" class="btn btn-outline blue"
      [disabled]="loadingDocuments || getCampService().isOngoingRequest()" *ngIf="this.model.id && !editMode"
      (click)="edit()">
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

    <button *ngIf="editMode" type="submit" class="btn blue"
      [disabled]="loadingDocuments || getCampService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>


<!-- Modale per la conferma di sovrascrittura di un Camp Director come responsabile di un campo -->
<div class="bootbox modal fade bootbox-confirm in" bsModal #confirmModal="bs-modal" [config]="{backdrop: 'static'}"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body alert-warning">
        <div class="bootbox-body">
          E' già presente un Camp Director come responsabile di questo campo.<br>
          Lo vuoi sostituire con questo?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="confirmSubmit()">Si</button>
          <button type="button" class="btn btn-primary" (click)="confirmModal.hide()">No</button>
        </div>
      </div>
    </div>
  </div>
</div>
