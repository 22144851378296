<alert type="warning" *ngIf="loading">
  <strong>Loading</strong> ...
</alert>

<div [hidden]="loading">
  <form action="#" class="horizontal-form" novalidate>
    <div class="form-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group read-only">
            <label class="control-label">Denomination</label>
            <p>{{enterpriseInfo.denomination}}</p>
          </div>
          <div *ngIf="enterpriseInfo.tax_id" class="form-group read-only">
            <label class="control-label">Tax ID</label>
            <p>{{enterpriseInfo.tax_id}}</p>
          </div>
          <div *ngIf="enterpriseInfo.fiscal_code" class="form-group read-only">
            <label class="control-label">Fiscal Code</label>
            <p>{{enterpriseInfo.fiscal_code}}</p>
          </div>
        </div>

        <div class="col-md-6">
          <div *ngIf="enterpriseInfo.type_sub && getConfigService().getEnterpriseTypeSubSelectValueByKey(enterpriseInfo.type, enterpriseInfo.type_sub).length > 0" class="form-group read-only">
            <label class="control-label">{{getConfigService().getEnterpriseTypeValueByKey(enterpriseInfo.type)}}</label>
            <p>{{getConfigService().getEnterpriseTypeSubSelectValueByKey(enterpriseInfo.type, enterpriseInfo.type_sub)}}</p>
          </div>

          <div *ngIf="!enterpriseInfo.type_sub || getConfigService().getEnterpriseTypeSubSelectValueByKey(enterpriseInfo.type, enterpriseInfo.type_sub).length <= 0" class="form-group read-only">
            <label class="control-label">Type</label>
            <p>{{getConfigService().getEnterpriseTypeValueByKey(enterpriseInfo.type)}}</p>
          </div>

          <div class="form-group read-only">
            <label class="control-label">Info</label>
            <p *ngIf="enterpriseInfo.accomodation">
              <i class="fa fa-star" aria-hidden="true"></i>&nbsp;{{enterpriseInfo.accomodation.rating}}&nbsp;
              <i class="fa fa-coffee" aria-hidden="true"></i>&nbsp;<span *ngIf="enterpriseInfo.accomodation.breakfast">SI</span><span *ngIf="!enterpriseInfo.accomodation.breakfast">NO</span>&nbsp;
              <i class="fa fa-wifi" aria-hidden="true"></i>&nbsp;<span *ngIf="enterpriseInfo.accomodation.internet">SI</span><span *ngIf="!enterpriseInfo.accomodation.internet">NO</span>&nbsp;&nbsp;&nbsp;
              <i class="fa fa-bed" aria-hidden="true"></i>&nbsp;<span *ngIf="enterpriseInfo.accomodation.single_rooms">1</span><span *ngIf="enterpriseInfo.accomodation.double_rooms">/2</span><span *ngIf="enterpriseInfo.accomodation.triple_rooms">/3</span><span *ngIf="enterpriseInfo.accomodation.quadruple_rooms">/4</span>
            </p>
          </div>

          <div *ngIf="enterpriseInfo.accomodation" class="form-group read-only">
            <label class="control-label">Parking Type</label>
            <p>{{getConfigService().getAccomodationParkingValueByKey(enterpriseInfo.accomodation.parking)}}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group read-only">
            <label class="control-label">Notes</label>
            <p class="line-breaker">{{enterpriseInfo.annotation}}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div *ngIf="enterpriseInfo.superior_id" class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Parent Authority</label>
            <p>
              <app-enterprise-info-show [idEnterprise]="enterpriseInfo.superior_id"></app-enterprise-info-show>
            </p>
          </div>
        </div>
        <div *ngFor="let enter of enterpriseInfo.subordinates | mapFromKeyValue" class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Subordinate</label>
            <p>
              <app-enterprise-info-show [idEnterprise]="enter.id"></app-enterprise-info-show>
            </p>
          </div>
        </div>
        <div *ngFor="let rr of enterpriseInfo.representative | mapFromKeyValue" class="col-md-4">
            <div class="form-group read-only">
              <label class="control-label">Representative</label>
              <p>
                <app-person-info-show [idPerson]="rr.person_id"></app-person-info-show>
              </p>
            </div>
          </div>
      </div>




      <h4 class="form-section">Contacts</h4>

      <div class="row">
        <div *ngFor="let cont of enterpriseInfo.contact | mapFromKeyValue" class="col-md-6">
          <div class="form-group read-only">
            <label class="control-label">{{ getConfigService().getContactEnterpriseValueByKey(cont.type)}}</label>
            <p>{{cont.value}}</p>
          </div>
        </div>
      </div>



      <h4 class="form-section">Address</h4>

      <div class="row">
        <div *ngFor="let add of enterpriseInfo.address | mapFromKeyValue" class="col-md-6">
            <alert *ngIf="!add.country_long_version" type="warning">
                <strong>Alert!</strong> address is not googlized
              </alert>
          <div class="form-group read-only">
            <label class="control-label">{{getConfigService().getAddressEnterpriseValueByKey(add.type)}}</label>
            <p>
              <span *ngIf="add.administrative_area_level_1_long_version">
                {{add.administrative_area_level_3_long_version}}
                <span *ngIf="add.locality != add.administrative_area_level_3_long_version">loc. {{add.locality}}</span>({{add.administrative_area_level_2_long_version}}, {{add.administrative_area_level_1_long_version}})
              <br>
              </span>
              <span *ngIf="add.route">
                {{add.route}}, {{add.street_number}}<br>
              </span>
              <span>
                {{add.postal_code}} {{add.administrative_area_level_3_short_version}} {{add.administrative_area_level_2_short_version}}<br>
              </span>
              <span>
                {{add.country_long_version}} ({{add.country_short_version}})
              </span>
            </p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Bank Accounts</h4>

      <div class="row">
        <div *ngFor="let bank of enterpriseInfo.bankaccount | mapFromKeyValue" class="col-md-6">
          <div class="form-group read-only">
            <label class="control-label">{{ getConfigService().getPaymentPersonValueByKey(bank.type)}}</label>
            <p>{{bank.iban}}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="form-actions right">
      <button type="button" class="btn btn-outline red" (click)="close()"><i class="fa fa-times" aria-hidden="true"></i>&nbsp;Close</button>&nbsp;
      <a [appCheckPermission]="'enterprise_edit'" class="btn btn-outline blue"  routerLink="/enterprise/edit/{{ currentId }}"><i class="fa fa-edit"></i>&nbsp;Edit</a>
    </div>

  </form>
</div>
