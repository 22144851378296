import { BackendService } from './../../_services/backend.service';
import { Component, OnInit } from '@angular/core';
import { PersonService, HelperService, ConfigService } from '../../_services/index';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-person-applications',
  templateUrl: './person-applications.component.html',
  styleUrls: ['./person-applications.component.css']
})
export class PersonApplicationsComponent implements OnInit {

  subscribe: any;
  model: any = {};
  actorApplications: any = [];
  tutorApplications: any = [];
  facilitatorApplications: any = [];
  loading: boolean = false;
  selected = [];

  constructor(private personService: PersonService, private helper: HelperService,
    private router: Router, private configService: ConfigService, private http: HttpClient,
    private backend: BackendService) { }

  ngOnInit() {

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.model = this.personService.currentPersonInfo;

      if (this.model){

        this.actorApplications = this.model.actor_applications;
        this.tutorApplications = this.model.tutor_applications;
        this.facilitatorApplications = this.model.facilitator_applications;
      }
    });

  }

  onSelectActor({ selected }) {

    this.router.navigate(['actorsapplications/edit/' + this.selected[0].id]);
  }

  onSelectTutor({ selected }) {

    this.router.navigate(['tutorsapplications/edit/' + this.selected[0].id]);
  }

  onSelectFacilitator({ selected }) {

    this.router.navigate(['facilitatorsapplications/edit/' + this.selected[0].id]);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  getRowClass(row) {

  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }

  getConfigService() {
    return this.configService
  }
}
