import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TheatrinoService } from '../../_services/index';
import { ReminderShowComponent } from '../../reminder/index';

@Component({
  selector: 'app-theatrino-booking-reminder',
  templateUrl: './theatrino-booking-reminder.component.html',
  styleUrls: ['./theatrino-booking-reminder.component.css']
})
export class TheatrinoBookingReminderComponent implements OnInit {

  @ViewChild('reminderShowComponent', {static: false}) public reminderShowComponent: ReminderShowComponent;

  subscribe: any;
  model: any = {};
  holderName: string;

  constructor(private theatrinoService: TheatrinoService) { }

  ngOnInit() {

    this.subscribe = this.theatrinoService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.model = this.theatrinoService.currentTourInfo;

      if (this.model == null) this.model = {};
      else
      {
        this.holderName = "Booking "+this.model["date"];
      }

    });
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  modelUpdated(event){
    if (this.reminderShowComponent)
      this.reminderShowComponent.modelUpdated(event);
  }

  getTheatrinoService() {
    return this.theatrinoService
  }

}
