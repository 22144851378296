<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">
    <h3 class="form-section">Info Subscription</h3>

    <div class="row">
      <div class="col-md-3 read-only"
        *ngIf="(editMode && viewSubscriber) || (!editMode && !viewSubscriber) || modelFromSignup != null">
        <div class="form-group">
          <label class="control-label">Data di Registrazione</label>
          <p> {{ model.registration_date_parsed | date:'d MMMM, y':'':'it'}} </p>
        </div>
      </div>
      <div class="col-md-3" *ngIf="editMode && !viewSubscriber && modelFromSignup == null">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localRegistrationDate)) }">
          <label class="control-label">Data di Registrazione
            <span class="required">*</span>
          </label>
          <div class="input-group">
            <!-- input box -->
            <input angular-mydatepicker #dp_registration_date="angular-mydatepicker" name="registration_date"
              class="form-control" [locale]="getHelper().locale" [options]="getDatePickerOptions()"
              [ngModel]="localRegistrationDate" (dateChanged)="onDateRegistrationChanged($event)" required />
            <!-- clear date button -->
            <span class="input-group-btn">
              <button type="button" *ngIf="model?.registration_date" class="btn default"
                (click)="dp_registration_date.clearDate()"><i class="fa fa-close"></i></button>
            </span>
            <!-- toggle calendar button -->
            <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_registration_date.toggleCalendar()"><i
                  class="fa fa-calendar-o"></i></button>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 read-only">
        <label class="control-label">Modalità di pagamento (da aggiungere)</label>
        <p>
          <!-- <i *ngIf="model.paid_online" class="fa fa-check" aria-hidden="true"></i>
          <i *ngIf="!model.paid_online" class="fa fa-times" aria-hidden="true"></i> -->
        </p>
      </div>
    </div>



    <div class="row">

      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || authorization_personal_data.dirty || authorization_personal_data.touched) && !authorization_personal_data.valid }">
          <label class="control-label">Il sottoscritto dichiara di accettare quanto previsto dal D.Lgs196/03 Codice in
            materia di protezione dei dati
            personali. <span class="required">*</span>
          </label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="authorization_personal_data" #authorization_personal_data="ngModel"
                [(ngModel)]="model.authorization_personal_data" value="1" required [disabled]="personDeleted"> Yes
              <span></span>
            </label>
          </div>
        </div>
        <div *ngIf="!editMode && model.authorization_personal_data" class="form-group edit-mode">
          <label>Il sottoscritto dichiara di accettare quanto previsto dal D.Lgs196/03 Codice in materia di protezione
            dei dati
            personali.
          </label>
          <p>
            <i *ngIf="model.authorization_personal_data" class="fa fa-check" aria-hidden="true"></i>
            <i *ngIf="!model.authorization_personal_data" class="fa fa-times" aria-hidden="true"></i>
          </p>
        </div>
      </div>

      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group">
          <label>Il sottoscritto autorizza EDUCO a pubblicare e realizzare, ai sensi dell'art. 96 legge n.
            633/1941</label>
          <span>&nbsp;</span>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="authorization_educo" [(ngModel)]="model.authorization_educo" value="1"
                [disabled]="personDeleted"> Yes
              <span></span>
            </label>
          </div>
        </div>
        <div *ngIf="!editMode && model.authorization_educo" class="form-group edit-mode">
          <label>Il sottoscritto autorizza EDUCO a pubblicare e realizzare, ai sensi dell'art. 96 legge n.
            633/1941</label>
          <p>
            <i *ngIf="model.authorization_educo" class="fa fa-check" aria-hidden="true"></i>
            <i *ngIf="!model.authorization_educo" class="fa fa-times" aria-hidden="true"></i>
          </p>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div *ngIf="!editMode && model.annotation" class="form-group editMode">
          <label class="control-label">Notes</label>
          <p class="line-breaker">{{model.annotation}}</p>
        </div>
        <div *ngIf="editMode" class="form-group edit-mode">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" name="annotation" placeholder="Some notes..."
            [(ngModel)]="model.annotation" #note="ngModel" [disabled]="personDeleted"></textarea>
        </div>
      </div>
    </div>



    <h3 class="form-section">Subscriber Info</h3>

    <!-- Sezione dedicata alla selezione del ruolo -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Ruolo dell'iscritto</label>
          <select class="form-control" name="default_role" #defaultRole="ngModel" [(ngModel)]="model.person_default_course_role">
            <option *ngFor="let s of getConfigService().courseScheduleRoleForSubscribersSelect" [value]="s.key">{{s.value}}</option>
          </select>
        </div>
      </div>
    </div>

    <!-- Sezione dedicata ad un utente già registrato -->
    <div class="row" *ngIf="viewSubscriber">
      <div class="col-md-12">
        <app-person-info-show [idPerson]="model.person_info.id" [showContact]="true" [showAddress]="true">
        </app-person-info-show>
      </div>
    </div>

    <!-- Sezione dedicata all'iscrizione di un utente non precedentemente registrato -->
    <div class="row">
      <div class="col-md-3">
        <div *ngIf="editMode && !viewSubscriber && !personSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || person_fist_name.dirty || person_fist_name.touched) && (!person_fist_name.valid && !personSelected) }">
          <label class="control-label">Nome
            <span class="required">*</span>
          </label>
          <input name="person_fist_name" [(ngModel)]="model.person_info.first_name" #person_fist_name="ngModel"
            class="form-control" nospace>
        </div>
        <div *ngIf="editMode && !viewSubscriber && personSelected" class="form-group">
          <label class="control-label">Nome
            <span class="required">*</span>
          </label>
          <input name="person_fist_name" [(ngModel)]="model.person_info.first_name" #person_fist_name="ngModel"
            class="form-control">
        </div>
        <div *ngIf="!editMode && !viewSubscriber" class="form-group edit-mode">
          <label class="control-label">Nome</label>
          <p>{{model.person_info.first_name}}</p>
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="editMode && !viewSubscriber && !personSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || person_surname.dirty || person_surname.touched) && (!person_surname.valid && !personSelected) }">
          <label class="control-label">Cognome
            <span class="required">*</span>
          </label>
          <input name="person_surname" [(ngModel)]="model.person_info.surname" #person_surname="ngModel"
            class="form-control" nospace>
        </div>
        <div *ngIf="editMode && !viewSubscriber && personSelected" class="form-group">
          <label class="control-label">Cognome
            <span class="required">*</span>
          </label>
          <input name="person_surname" [(ngModel)]="model.person_info.surname" #person_surname="ngModel"
            class="form-control">
        </div>
        <div *ngIf="!editMode && !viewSubscriber" class="form-group edit-mode">
          <label class="control-label">Cognome</label>
          <p>{{model.person_info.surname}}</p>
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="editMode && !viewSubscriber && !personSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || person_tax_id.dirty || person_tax_id.touched) && (!person_tax_id.valid && !personSelected) }">
          <label class="control-label">Codice fiscale &nbsp;
            <check-person-taxid [personInfo]="model.person_info"></check-person-taxid>
            <span class="required">*</span>
          </label>
          <input name="person_tax_id" [(ngModel)]="model.person_info.tax_id" #person_tax_id="ngModel"
            class="form-control" (change)="onTaxidChange($event.target.value)" nospace>
        </div>
        <div *ngIf="editMode && !viewSubscriber && personSelected" class="form-group">
          <label class="control-label">Codice fiscale &nbsp;
            <check-person-taxid [personInfo]="model.person_info"></check-person-taxid>
            <span class="required">*</span>
          </label>
          <input name="person_tax_id" [(ngModel)]="model.person_info.tax_id" #person_tax_id="ngModel"
            class="form-control">
        </div>
        <div *ngIf="!editMode && !viewSubscriber" class="form-group edit-mode">
          <label class="control-label">Codice fiscale &nbsp;
            <check-person-taxid [personInfo]="model.person_info"></check-person-taxid>
          </label>
          <p>{{model.person_info.tax_id}}</p>
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="editMode && !viewSubscriber && !personSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || person_gender.dirty || person_gender.touched) && (!person_gender.valid && !personSelected) }">
          <label class="control-label">Sesso
            <span class="required">*</span>
          </label>
          <select class="form-control" name="person_gender" [(ngModel)]="model.person_info.gender"
            #person_gender="ngModel" required>
            <option *ngFor="let g of getConfigService().genderPersonSelect" [value]="g.key">{{g.value}}</option>
          </select>
        </div>
        <div *ngIf="editMode && !viewSubscriber && personSelected" class="form-group">
          <label class="control-label">Sesso
          </label>
          <select class="form-control" name="person_gender" [(ngModel)]="model.person_info.gender"
            #person_gender="ngModel">
            <option *ngFor="let g of getConfigService().genderPersonSelect" [value]="g.key">{{g.value}}</option>
          </select>
        </div>
        <div *ngIf="!editMode && !viewSubscriber" class="form-group edit-mode">
          <label class="control-label">Sesso</label>
          <p>{{getConfigService().getGenderPersonValueByKey(model.person_info.gender)}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div *ngIf="editMode && !viewSubscriber" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted && (!getHelper().isMyDateValid(localPersonData) && !personSelected)) }">
          <label class="control-label">Data di Nascita
            <span class="required">*</span>
          </label>
          <div class="input-group">
            <!-- input box -->
            <input angular-mydatepicker #dp_person_birthday="angular-mydatepicker" name="person_birthday"
              class="form-control" [locale]="getHelper().locale" [options]="getDatePickerOptions()"
              [ngModel]="localPersonData" (dateChanged)="onDatePersonChanged($event)" required />
            <!-- clear date button -->
            <span class="input-group-btn">
              <button type="button" *ngIf="model?.person_info?.birthday" class="btn default"
                (click)="dp_person_birthday.clearDate()"><i class="fa fa-close"></i></button>
            </span>
            <!-- toggle calendar button -->
            <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_person_birthday.toggleCalendar()"><i
                  class="fa fa-calendar-o"></i></button>
            </span>
          </div>
        </div>
        <div *ngIf="!editMode && !viewSubscriber" class="form-group edit-mode">
          <label class="control-label">Data di Nascita</label>
          <p> {{ model.person_info.birthday_parsed | date:'d MMMM, y':'':'it'}} </p>
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="editMode && !viewSubscriber && !personSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || person_birthplace.dirty || person_birthplace.touched) && (!person_birthplace.valid && !personSelected) }">
          <label class="control-label">Città di Nascita
            <span class="required">*</span>
          </label>
          <input name="person_birthplace" [(ngModel)]="model.person_info.birthplace" #person_birthplace="ngModel"
            class="form-control" nospace>
        </div>
        <div *ngIf="editMode && !viewSubscriber && personSelected">
          <label class="control-label">Città di Nascita
          </label>
          <input name="person_birthplace" [(ngModel)]="model.person_info.birthplace" #person_birthplace="ngModel"
            class="form-control">
        </div>
        <div *ngIf="!editMode && !viewSubscriber" class="form-group edit-mode">
          <label class="control-label">Città di Nascita</label>
          <p> {{model.person_info.birthplace}} </p>
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="editMode && !viewSubscriber && !personSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || person_birthplace_province.dirty || person_birthplace_province.touched) && (!person_birthplace_province.valid && !personSelected) }">
          <label class="control-label">Provincia di Nascita
            <span class="required">*</span>
          </label>
          <input name="person_birthplace_province" [(ngModel)]="model.person_info.birthplace_province"
            #person_birthplace_province="ngModel" class="form-control" nospace>
        </div>
        <div *ngIf="editMode && !viewSubscriber && personSelected" class="form-group">
          <label class="control-label">Provincia di Nascita
          </label>
          <input name="person_birthplace_province" [(ngModel)]="model.person_info.birthplace_province"
            #person_birthplace_province="ngModel" class="form-control">
        </div>
        <div *ngIf="!editMode && !viewSubscriber" class="form-group edit-mode">
          <label class="control-label">Provincia di Nascita</label>
          <p>{{model.person_info.birthplace_province}}</p>
        </div>
      </div>
    </div>

    <h4 class="form-section" *ngIf="editMode && !viewSubscriber">Indirizzi</h4>

    <div class="row" *ngIf="editMode && !viewSubscriber">
      <div *ngFor="let address of model.person_info.addresses">
        <div class="col-md-3 form-group" *ngIf="address.type == 1"
          [ngClass]="{ 'has-error': (f.submitted || person_address.dirty || person_address.touched) && !person_address.valid }">
          <label class="control-label">Indirizzo
            <span class="required">*</span>
          </label>
          <input name="person_address" [(ngModel)]="address.route" #person_address="ngModel" class="form-control"
            nospace>
        </div>
        <div class="col-md-3 form-group" *ngIf="address.type == 1"
          [ngClass]="{ 'has-error': (f.submitted || person_city.dirty || person_city.touched) && !person_city.valid }">
          <label class="control-label">Città
            <span class="required">*</span>
          </label>
          <input name="person_city" [(ngModel)]="address.administrative_area_level_3_long_version"
            #person_city="ngModel" class="form-control" nospace>
        </div>
        <div class="col-md-3 form-group" *ngIf="address.type == 1"
          [ngClass]="{ 'has-error': (f.submitted || person_province.dirty || person_province.touched) && !person_province.valid }">
          <label class="control-label">Provincia
            <span class="required">*</span>
          </label>
          <input name="person_province" [(ngModel)]="address.administrative_area_level_2_short_version"
            #person_province="ngModel" class="form-control" nospace>
        </div>
        <div class="col-md-3 form-group" *ngIf="address.type == 1"
          [ngClass]="{ 'has-error': (f.submitted || person_cap.dirty || person_cap.touched) && !person_cap.valid }">
          <label class="control-label">CAP
            <span class="required">*</span>
          </label>
          <input name="person_cap" [(ngModel)]="address.postal_code" #person_cap="ngModel" class="form-control" nospace>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode && !viewSubscriber">
      <div class="form-group edit-mode" *ngFor="let address of model.person_info.addresses">
        <div *ngIf="address.type == 1" class="col-md-3">
          <label class="control-label">Indirizzo</label>
          <p>{{address.route}}</p>
        </div>
        <div *ngIf="address.type == 1" class="col-md-3">
          <label class="control-label">Città</label>
          <p>{{address.administrative_area_level_3_long_version}}</p>
        </div>
        <div *ngIf="address.type == 1" class="col-md-3">
          <label class="control-label">Provincia</label>
          <p>{{address.administrative_area_level_2_short_version}}</p>
        </div>
        <div *ngIf="address.type == 1" class="col-md-3">
          <label class="control-label">CAP</label>
          <p>{{address.postal_code}}</p>
        </div>
      </div>
    </div>

    <h4 class="form-section" *ngIf="editMode && !viewSubscriber">Contatti</h4>

    <div *ngIf="editMode && !viewSubscriber" class="row">
      <div class="col-md-3" *ngFor="let contact of model.person_info.contacts">
        <div class="form-group" *ngIf="contact.type == 4">
          <label class="control-label">Telefono Casa</label>
          <input name="person_contact_home" [(ngModel)]="contact.value" #person_contact_home="ngModel"
            class="form-control">
        </div>
        <div class="form-group" *ngIf="contact.type == 5"
          [ngClass]="{ 'has-error': (f.submitted || person_contact_phone.dirty || person_contact_phone.touched) && !person_contact_phone.valid}">
          <label class="control-label">Cellulare
            <span class="required">*</span>
          </label>
          <input name="person_contact_phone" [(ngModel)]="contact.value" #person_contact_phone="ngModel"
            class="form-control" nospace>
        </div>
        <div class="form-group" *ngIf="contact.type == 1"
          [ngClass]="{ 'has-error': (f.submitted || person_contact_email.dirty || person_contact_email.touched) && !person_contact_email.valid}">
          <label class="control-label">Email
            <span class="required">*</span>
          </label>
          <input name="person_contact_email" [(ngModel)]="contact.value" #person_contact_email="ngModel"
            class="form-control" nospace>
        </div>
      </div>
    </div>
    <div *ngIf="!editMode && !viewSubscriber" class="row">
      <div class="col-md-3" *ngFor="let contact of model.person_info.contacts">
        <div *ngIf="contact.type == 4" class="form-group edit-mode">
          <label class="control-label">Telefono Casa</label>
          <p>{{contact.value}}</p>
        </div>
        <div *ngIf="contact.type == 5" class="form-group edit-mode">
          <label class="control-label">Cellulare</label>
          <p>{{contact.value}}</p>
        </div>
        <div *ngIf="contact.type == 1" class="form-group edit-mode">
          <label class="control-label">Email</label>
          <p>{{contact.value}}</p>
        </div>
      </div>
    </div>

    <h4 class="form-section" *ngIf="editMode">Professione</h4>

    <!-- Sezione dedicata alle info aggiuntive per i docenti e non obligatoria per l'iscritto -->
    <div *ngIf="editMode" class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Professione</label>
          <select class="form-control" name="person_work_type" [(ngModel)]="model.person_info.work_type"
            #person_work_type="ngModel" (change)="changedWorkType($event.target.value)">
            <option *ngFor="let w of getConfigService().personWorkSelect" [value]="w.key">{{w.value}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" *ngIf="model.person_info?.work_type == 1">
          <label class="control-label">Docente di</label>
          <select class="form-control" name="person_class_type" [(ngModel)]="model.person_info.class_type"
            #person_class_type="ngModel">
            <option *ngFor="let s of getConfigService().personWorkSchoolTypeSelect" [value]="s.key">{{s.value}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" *ngIf="model.person_info?.work_type == 1">
          <label class="control-label">Nome Scuola</label>
          <input class="form-control" name="person_school_name" [(ngModel)]="model.school_name"
            #person_school_name="ngModel">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" *ngIf="model.person_info?.work_type == 1">
          <label class="control-label">Provincia Scuola</label>
          <input class="form-control" name="person_school_prov" [(ngModel)]="model.school_prov"
            #person_school_prov="ngModel">
        </div>
      </div>
    </div>

    <h4 class="form-section" *ngIf="editMode">Marketing</h4>

    <!-- Sezione dedicata al marcheting e non obligatoria per l'iscritto -->
    <div *ngIf="editMode" class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Come ci avete conosciuti</label>
          <select class="form-control" name="person_how_meet_us" [(ngModel)]="model.how_meet_us"
            #person_how_meet_us="ngModel">
            <option *ngFor="let m of getConfigService().courseSubscriberMeetUsSelect" [value]="m.key">{{m.value}}
            </option>
          </select>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="editMode && !viewSubscriber">
      <div class="col-md-12">
        <button type="button" class="btn btn-outline blue btn-block" (click)="findPerson()"
          [disabled]="getCourseScheduleService().isOngoingRequest()">
          Search Person</button>
      </div>
    </div>
    <div class="row" *ngIf="editMode && !viewSubscriber">
      <div class="col-md-12">
        <div class="datatable-loader text-center" *ngIf="ongoingRequestPerson">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <strong class="text-center">Loading</strong>
        </div>
        <ngx-datatable *ngIf="!ongoingRequestPerson" class="material" [rows]="personFound" [columnMode]="'flex'"
          [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [selectionType]="'single'"
          (select)='onSelectPerson($event)'>
          <ngx-datatable-column name="Surname" [flexGrow]="2" prop="surname" [resizeable]="false">
          </ngx-datatable-column>
          <ngx-datatable-column name="Name" [flexGrow]="2" prop="first_name" [resizeable]="false">
          </ngx-datatable-column>
          <ngx-datatable-column name="Tax ID" [flexGrow]="2" prop="tax_id" [resizeable]="false"></ngx-datatable-column>
          <ngx-datatable-column name="Locality" [flexGrow]="2" prop="address_primary" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span
                *ngIf="value?.administrative_area_level_3_long_version == null">{{value?.administrative_area_level_2_short_version}}</span>
              <span
                *ngIf="value?.administrative_area_level_3_long_version != null">{{value?.administrative_area_level_3_long_version}}
                ({{value?.administrative_area_level_2_short_version}})</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Roles" [flexGrow]="1" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <div class="row" *ngFor="let role of row?.role | mapFromKeyValue">
                <div class="col-md-12">
                  {{this.getConfigService().getRolePersonValueByKey(role.type)}}
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
    <div class="row" *ngIf="!personSelected && editMode && firstSearchPerson">
      <div class="col-md-12">
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="new_person" [(ngModel)]="addNewPerson" />Nessuna persona trovato nel sistema.
              Aggiungilo come nuova!
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <h3 class="form-section">Add In Enrollment Fee</h3>

    <div class="row"
      *ngIf="editMode && viewSubscriber && !model.enrollment_fee && getUserService().checkPermission('camp_edit_subscriber')">
      <div class="col-12">
        <button type="button" class="btn btn-block btn-outline blue" (click)="createPaymentRequest('enrollmentfee')"><i
            class="fa fa-eur"></i> Create a payment Request</button>
      </div>
    </div>
    <br>

    <div *ngIf="model.enrollment_fee" class="row">
      <div class="col-md-12">
        <alert type="success">
          <strong>Pagata:</strong> {{ model.enrollment_fee_amount | currency:'EUR':'symbol':'1.2-2':'it' }}<br>
        </alert>
      </div>
    </div>

    <!-- Informazione che indica che c'è una richiesta di pagamento -->
    <div class="row" *ngIf="editMode && !model.enrollment_fee">
      <div class="col-md-12">
        <alert type="warning" *ngIf="model.payment_requests && modelFromSignup">
          <i class="fa fa-money"></i> &nbsp; È stata generata in automatico una richiesta di pagamento!
        </alert>
        <alert type="success"
          *ngIf="model.payment_requests && modelFromSignup && model.payment_requests.status == 'success'">
          <i class="fa fa-money"></i> &nbsp; La richiesta di pagamento è stata saldata.
        </alert>
      </div>
    </div>
    <div class="row" *ngIf="editMode && !model.enrollment_fee">
      <div class="col-md-6">
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="add_in_check" [(ngModel)]="model.addin_enrollment_fee"
                [disabled]="personDeleted"
                (change)="model.addin_enrollment_fee ? addInAlertType = 'success' : addInAlertType = 'warning'" />Confermo
              che l'iscrizione è stata pagata
              <span></span>
            </label>
            <p>Verrà aggiunta una voce Add In nel CashFlow del corso</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group" *ngIf="model.addin_enrollment_fee">
          <label class="control-label">Quota</label>
          <input type="number" name="add_in_value" [(ngModel)]="model.addin_enrollment_fee_value"
            #add_in_value="ngModel" class="form-control">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" *ngIf="model.addin_enrollment_fee"
          [ngClass]="{ 'has-error': (f.submitted || registeredvia.dirty || registeredvia.touched) && !registeredvia.valid }">
          <label class="control-label">Registered via
            <span class="required">*</span>
          </label>
          <select class="form-control" name="registered_via" [(ngModel)]="model.addin_enrollment_fee_registered_via"
            #registeredvia="ngModel" required>
            <option *ngFor="let c of getConfigService().primanotaRegisteredViaSelect" [value]="c.key">{{c.value}}
            </option>
          </select>
          <span class="help-block">Field required</span>
        </div>
      </div>
      <div class="col-md-12" *ngIf="model.addin_enrollment_fee">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="2" class="form-control" placeholder="Some notes..." name="addin_enrollment_fee_annotation"
            [(ngModel)]="model.addin_enrollment_fee_annotation"></textarea>
        </div>
      </div>
    </div>

    <h3 class="form-section">Discount e Extra<span *ngIf="this.totalDiscount"
        class="pull-right">{{this.totalDiscount | currency:'EUR':'symbol':'1.2-2':'it' }}</span><span
        *ngIf="model.quote_discount_free" class="pull-right">&nbsp;FREE</span></h3>

    <div class="row" *ngIf="editMode && canEditDiscount">
      <div class="col-md-4">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_1" #quote_discount_1 [(ngModel)]="model.quote_discount_1"
            (change)="updateCourseFeeWithDiscount()">Teatrino o Camps:
          -{{ getCourseScheduleService().currentCourseScheduleInfo.quote_discount_1 | currency:'EUR':'symbol':'1.2-2':'it' }}
          <span></span>
        </label>
      </div>
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_2" #quote_discount_2 [(ngModel)]="model.quote_discount_2"
            (change)="updateCourseFeeWithDiscount()">
          Gruppi:
          -{{ getCourseScheduleService().currentCourseScheduleInfo.quote_discount_2 | currency:'EUR':'symbol':'1.2-2':'it' }}
          <span></span>
        </label>
      </div>
    </div>

    <div class="row" *ngIf="editMode && canEditDiscount">
      <div class="col-md-4">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_early_bird" #quote_discount_early_bird
            [(ngModel)]="model.quote_discount_early_bird" (change)="updateCourseFeeWithDiscount()">
          <i *ngIf="suggestEarlyBirdDiscount" class="fa fa-check-circle" aria-hidden="true" style="color:green"></i>
          Early bird discount:
          -{{ getCourseScheduleService().currentCourseScheduleInfo.quote_discount_early_bird | currency:'EUR':'symbol':'1.2-2':'it' }}
          from {{ quote_discount_early_bird_date_parsed | date:'d MMMM, y':'':'it'}}
          <span></span>
        </label>
      </div>
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_free" #quote_discount_free [(ngModel)]="model.quote_discount_free"
            (change)="updateCourseFeeWithDiscount()"> Free
          <span></span>
        </label>
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <label class="control-label">Personalized discount</label>
          <input type="number" name="quote_discount_custom" [(ngModel)]="model.quote_discount_custom"
            #quote_discount_custom="ngModel" (change)="updateCourseFeeWithDiscount()" class="form-control">
        </div>
      </div>
    </div>

    <div class="row" *ngIf="editMode && canEditDiscount">
      <div class="col-md-4">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_extra_1" #quote_extra_1 [(ngModel)]="model.quote_extra_1"
            (change)="updateCourseFeeWithDiscount()">Pernottamento:
          +{{ getCourseScheduleService().currentCourseScheduleInfo.quote_extra_1 | currency:'EUR':'symbol':'1.2-2':'it' }}
          <span></span>
        </label>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Personalized extra fee</label>
          <input type="number" name="quote_extra_custom" [(ngModel)]="model.quote_extra_custom"
            #quote_extra_custom="ngModel" (change)="updateCourseFeeWithDiscount()" class="form-control">
        </div>
      </div>
    </div>

    <div class="row" *ngIf="editMode && canEditDiscount">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Discount and Extra fee notes</label>
          <textarea rows="2" class="form-control" placeholder="Some notes..." name="quote_discount_annotation"
            [(ngModel)]="model.quote_discount_annotation"></textarea>
        </div>
      </div>
    </div>

    <h3 class="form-section">Add In Course Fee <small style="padding-top: 6px;" *ngIf="this.standardCourseFee"
        class="pull-right">&nbsp;({{this.standardCourseFee | currency:'EUR':'symbol':'1.2-2':'it' }})</small><span
        [ngClass]="{'font-red': model.addin_course_fee_value_total < 0}"
        *ngIf="model.addin_course_fee_value_total && !model.quote_discount_free"
        class="pull-right">{{model.addin_course_fee_value_total | currency:'EUR':'symbol':'1.2-2':'it' }}</span><span
        *ngIf="model.quote_discount_free" class="pull-right">FREE</span></h3>

    <div class="row"
      *ngIf="editMode && viewSubscriber && model.course_fee_amount < model.addin_course_fee_value_total && getUserService().checkPermission('course_schedule_edit')">
      <div class="col-12">
        <button type="button" class="btn btn-block btn-outline blue" (click)="createPaymentRequest('coursefee')"><i
            class="fa fa-eur"></i> Create a payment Request</button>
      </div>
    </div>
    <br>

    <div class="row" *ngIf="editMode && model.course_fee_amount < model.addin_course_fee_value_total">
      <div class="col-md-6">
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="add_in_check" [(ngModel)]="model.addin_course_fee" />Confermo che la quota
              del corso è stata pagata
              <span></span>
            </label>
            <p>Verrà aggiunta una voce Add In nel CashFlow del corso</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group" *ngIf="model.addin_course_fee">
          <label class="control-label">Quota</label>
          <input type="number" name="add_in_value" [(ngModel)]="model.addin_course_fee_value" #add_in_value="ngModel"
            class="form-control">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" *ngIf="model.addin_course_fee"
          [ngClass]="{ 'has-error': (f.submitted || registeredvia.dirty || registeredvia.touched) && !registeredvia.valid }">
          <label class="control-label">Registered via
            <span class="required">*</span>
          </label>
          <select class="form-control" name="registered_via" [(ngModel)]="model.addin_course_fee_registered_via"
            #registeredvia="ngModel" required>
            <option *ngFor="let c of getConfigService().primanotaRegisteredViaSelect" [value]="c.key">{{c.value}}
            </option>
          </select>
          <span class="help-block">Field required</span>
        </div>
      </div>
      <div class="col-md-12" *ngIf="model.addin_course_fee">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="2" class="form-control" placeholder="Some notes..." name="addin_course_fee_annotation"
            [(ngModel)]="model.addin_course_fee_annotation"></textarea>
        </div>
      </div>
    </div>

    <div *ngIf="model.course_fee_amount >= model.addin_course_fee_value_total" class="row">
      <div class="col-md-12">
        <alert type="success">
          <span *ngIf="!model.quote_discount_free"><strong>Pagata:</strong>
            {{ model.course_fee_amount | currency:'EUR':'symbol':'1.2-2':'it' }}<br></span>
          <span *ngIf="model.quote_discount_free"><strong>FREE</strong>
            <label class="mt-checkbox mt-checkbox-outline pull-right">
              <input type="checkbox" name="quote_discount_free" #quote_discount_free
                [(ngModel)]="model.quote_discount_free" (change)="updateCourseFeeWithDiscount()"> <small>Uncheck and
                Update to remove FREE discount</small>
              <span></span>
            </label>
          </span><br>
          <span
            *ngIf="(model.addin_course_fee_value_total + model.enrollment_fee_amount)  != standardCourseFee && !model.quote_discount_free">
            <strong>Discount:</strong>
            <ul>
              <li *ngIf="model.quote_discount_1">Teatrino o Camps:
                {{ getCourseScheduleService().currentCourseScheduleInfo.quote_discount_1 | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_discount_2">Gruppi:
                {{ (getCourseScheduleService().currentCourseScheduleInfo.quote_discount_2) | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_discount_early_bird">Early bird discount:
                {{ getCourseScheduleService().currentCourseScheduleInfo.quote_discount_early_bird | currency:'EUR':'symbol':'1.2-2':'it' }}
                before {{ quote_discount_early_bird_date_parsed | date:'d MMMM, y':'':'it'}}</li>
              <li *ngIf="model.quote_discount_custom > 0">Personalized discount:
                {{ model.quote_discount_custom | currency:'EUR':'symbol':'1.2-2':'it' }}</li>
            </ul>
          </span>
          <span *ngIf="model.quote_extra_1 || model.quote_extra_custom > 0">
            <strong>Extra Fee:</strong>
            <ul>
              <li *ngIf="model.quote_extra_1">Pernottamento:
                {{ getCourseScheduleService().currentCourseScheduleInfo.quote_extra_1 | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_extra_custom > 0">Personalized extra fee:
                {{ model.quote_extra_custom | currency:'EUR':'symbol':'1.2-2':'it' }}</li>
            </ul>
          </span>
        </alert>
      </div>
    </div>

    <div *ngIf="model.course_fee_amount > 0 && model.course_fee_amount < model.addin_course_fee_value_total"
      class="row">
      <div class="col-md-12">
        <alert type="warning">
          <span *ngIf="!model.quote_discount_free"><strong>Pagata parzialmente:</strong>
            {{ model.course_fee_amount | currency:'EUR':'symbol':'1.2-2':'it' }}<br></span>
          <br>
          <span
            *ngIf="(model.addin_course_fee_value_total + model.enrollment_fee_amount)  != standardCourseFee && !model.quote_discount_free">
            <strong>Discount:</strong>
            <ul>
              <li *ngIf="model.quote_discount_1">Teatrino o Camps:
                {{ getCourseScheduleService().currentCourseScheduleInfo.quote_discount_1 | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_discount_2">Gruppi:
                {{ (getCourseScheduleService().currentCourseScheduleInfo.quote_discount_2) | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_discount_early_bird">Early bird discount:
                {{ getCourseScheduleService().currentCourseScheduleInfo.quote_discount_early_bird | currency:'EUR':'symbol':'1.2-2':'it' }}
                before {{ quote_discount_early_bird_date_parsed | date:'d MMMM, y':'':'it'}}</li>
              <li *ngIf="model.quote_discount_custom > 0">Personalized discount:
                {{ model.quote_discount_custom | currency:'EUR':'symbol':'1.2-2':'it' }}</li>
            </ul>
          </span>
          <span *ngIf="model.quote_extra_1 || model.quote_extra_custom > 0">
            <strong>Extra Fee:</strong>
            <ul>
              <li *ngIf="model.quote_extra_1">Pernottamento:
                {{ getCourseScheduleService().currentCourseScheduleInfo.quote_extra_1 | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_extra_custom > 0">Personalized extra fee:
                {{ model.quote_extra_custom | currency:'EUR':'symbol':'1.2-2':'it' }}</li>
            </ul>
          </span>
        </alert>
      </div>
    </div>

    <div class="row" *ngIf="editMode && !model.enrollment_fee">
      <div class="col-md-12">
        <alert type="warning">
          <strong>Attenzione:</strong> prima di aggiungere la quota del corso, registrare la quota di iscrizione
        </alert>
      </div>
    </div>

  </div>

  <div class="form-actions right" *ngIf="editMode && !viewSubscriber">
    <img *ngIf="getCourseScheduleService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <ng-template #confirmIgnore>
      <button type="button" class="btn btn-outline red" (click)="ignore();">Yes change it!</button>
    </ng-template>
    <button type="button" *ngIf="modelFromSignup != null" class="btn btn-outline red"
      [disabled]="getCourseScheduleService().isOngoingRequest()" [outsideClick]="true" [popover]="confirmIgnore"
      popoverTitle="Are you sure?">
      <i class="fa fa-eye-slash"></i> Toggle Ignore</button>

    <button type="submit" class="btn blue"
      [disabled]="getCourseScheduleService().isOngoingRequest() || model.ignore_request">
      <span class="ladda-label">
        <i class="fa fa-save"></i> Save</span>
    </button>
  </div>


  <div class="form-actions right" *ngIf="editMode && viewSubscriber && !model.deleted_at">
    <img *ngIf="getCourseScheduleService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getCourseScheduleService().isOngoingRequest()"
      *ngIf="model.id" [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
      <i class="fa fa-trash-o"></i> Delete</button>
    <button type="submit" class="btn blue" [disabled]="getCourseScheduleService().isOngoingRequest()"
      *ngIf="this.model.id">
      <span class="ladda-label">
        <i class="fa fa-edit"></i> Update</span>
    </button>
    <button type="button" class="btn btn-outline blue" [disabled]="getCourseScheduleService().isOngoingRequest()"
      *ngIf="this.model.id" (click)="copySubscriberCall()">
      <span class="ladda-label">
        <i class="fa fa-clone" aria-hidden="true"></i> Copy</span>
    </button>
    <button type="submit" class="btn green-jungle" [disabled]="getCourseScheduleService().isOngoingRequest()"
      *ngIf="!this.model.id">
      <span class="ladda-label">
        <i class="fa fa-save"></i> Save</span>
    </button>
  </div>

  <div class="form-actions right" *ngIf="model.deleted_at">
    <img *ngIf="getCourseScheduleService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <button type="button" class="btn green-jungle" (click)="restore();"
      [disabled]="getCourseScheduleService().isOngoingRequest()">
      <span class="ladda-label">
        <i class="fa fa-save"></i> Restore</span>
    </button>
  </div>

</form>
