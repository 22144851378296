import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService, HelperService, UserService, TutorApplicationService } from 'app/_services';
import { ActivatedRoute } from '@angular/router';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import * as moment from 'moment-timezone';
import { Nationalities } from 'app/_services/nationalities';
import { NotificationsService } from 'angular2-notifications';
import { NgForm } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'app/environment';

@Component({
  selector: 'app-tutors-application-edit',
  templateUrl: './tutors-application-edit.component.html',
  styleUrls: ['./tutors-application-edit.component.css']
})
export class TutorsApplicationEditComponent implements OnInit {

  private subject: any;

  @ViewChild('f', { static: false }) public form: NgForm;

  //  file uploaders
  public photoUploader: FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png'],
      url: 'serverUrl'
    }
  );
  public cvUploader: FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlCV'
    }
  );
  public coverUploader: FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlCover'
    }
  );
  public passportUploader: FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlPassport'
    }
  );
  public healthInsuranceUploader: FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlHealthInsurance'
    }
  );

  //  impostazione delle data
  private datePickerOptions: IAngularMyDpOptions = {};
  localBirthdayData: any;

  headers: any;

  timezones: any[];
  timePreferences: any[];
  public timeValid: boolean = false;

  public loading = false;
  tutorApplicationInfo: any = {};
  tutorApplicationEdition: any = [];

  constructor(public tutorService: TutorApplicationService, private route: ActivatedRoute,
    private configService: ConfigService, private helper: HelperService, private userService: UserService, private notify: NotificationsService, private tutorApplicationService: TutorApplicationService) {
    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
    this.datePickerOptions.disableSince = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }
  }

  ngOnInit() {

    // prima configurazione per l'upload delle foto
    this.photoUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.photoUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.photoUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload del CV
    this.cvUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.cvUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.cvUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload del cover letter
    this.coverUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.coverUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.coverUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload del passaporto
    this.passportUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.passportUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.passportUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload dell'assicurazione sanitaria
    this.healthInsuranceUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.healthInsuranceUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.healthInsuranceUploadFinished(item, response, status);
    };

    this.addTimeZones();
    this.addCheckbox();

    this.subject = this.route.params.subscribe(params => {

      this.tutorApplicationInfo = {};

      if (typeof params['idCurrentTutor'] != "undefined") {
        this.getTutorApplicationInfo(params['idCurrentTutor']);
      }
    });
  }


  ngOnDestroy() {
    this.subject.unsubscribe();
  }

  addTimeZones() {
    this.timezones = [];
    moment.tz.names().forEach(zonename => {
      this.timezones.push(
        {
          name: zonename,
          gmt: moment.tz(zonename).format('Z'),
          text: zonename + ' - (GMT:' + moment.tz(zonename).format('Z') + ')',
        }
      );
    });
  }

  addCheckbox() {
    this.timePreferences = [
      { id: 100, name: '09:00 - 12:00', checked: false },
      { id: 200, name: '12:00 - 15:00', checked: false },
      { id: 300, name: '15:00 - 18:00', checked: false }
    ];
  }
  checkboxChanged(index) {
    this.timePreferences[index].checked = !this.timePreferences[index].checked;

    if (this.timePreferences.filter(x => x.checked == true).length >= 1)
      this.timeValid = true;
    else
      this.timeValid = false;
  }

  submit() {

    if (!this.form.form.valid)
      return;

    if (this.tutorApplicationInfo.dietary_requirements_yes_or_not == 'yes' && !this.tutorApplicationInfo.dietary_requirements) {
      this.notify.error("Dietary Requirements", "is Required");
      return;
    }
    // if (this.tutorApplicationInfo.allergic_yes_or_not == 'yes' && !this.tutorApplicationInfo.allergic_to)
    // {
    //   this.notify.error("Allergies", "is Required");
    //   return;
    // }

    var modelUpdate = JSON.parse(JSON.stringify(this.tutorApplicationInfo));

    modelUpdate.nationality = this.createNationalities(this.tutorApplicationInfo.nationality);

    delete modelUpdate.photoUrl;
    delete modelUpdate.cvUrl;
    delete modelUpdate.coverUrl;
    delete modelUpdate.passportUrl;
    delete modelUpdate.intChildCertificateUrl;
    delete modelUpdate.healthInsuranceUrl;

    if (this.tutorApplicationInfo.dietary_requirements_yes_or_not == 'no')
      modelUpdate.dietary_requirements = null;

    if (this.tutorApplicationInfo.allergic_yes_or_not == 'no')
      modelUpdate.allergic_to = null;

    this.tutorService.updateAllTutorApplicationInfo(modelUpdate, () => {

      this.getTutorApplicationInfo(modelUpdate.id);
    });
  }

  getTutorApplicationInfo(tutorApplicationId) {

    this.loading = true;

    this.tutorService.currentTutorApplicationId = tutorApplicationId;

    this.tutorService.getTutorApplicationInfo(() => {

      this.tutorApplicationInfo = this.tutorService.currentTutorApplicationInfo;

      //Setto l'url per il caricamento delle foto e gli header
      this.photoUploader.options.url = this.tutorService.getFileUploadURL(this.tutorApplicationInfo.uid);
      this.cvUploader.options.url = this.tutorService.getCVUploadURL(this.tutorApplicationInfo.uid);
      this.coverUploader.options.url = this.tutorService.getCoverUploadURL(this.tutorApplicationInfo.uid);
      this.passportUploader.options.url = this.tutorService.getPassportUploadURL(this.tutorApplicationInfo.uid);
      this.healthInsuranceUploader.options.url = this.tutorService.getHealthInsuranceURL(this.tutorApplicationInfo.uid);

      this.headers = this.tutorService.getFileUlopadHeader();

      if (this.tutorApplicationInfo.date_of_birth != null)
        this.localBirthdayData = this.helper.initMyDataInputSingle(this.tutorApplicationInfo.date_of_birth);

      //  Se esiste il path per i file aggiuntivi costruisco gli url
      if (this.tutorApplicationInfo.photo_url)
        this.tutorApplicationInfo.photoUrl = this.configService.blobBasePath + this.tutorApplicationInfo.photo_url;

      if (this.tutorApplicationInfo.cv_url)
        this.tutorApplicationInfo.cvUrl = this.configService.blobBasePath + this.tutorApplicationInfo.cv_url;

      if (this.tutorApplicationInfo.cover_url)
        this.tutorApplicationInfo.coverUrl = this.configService.blobBasePath + this.tutorApplicationInfo.cover_url;

      if (this.tutorApplicationInfo.passport_url)
        this.tutorApplicationInfo.passportUrl = this.configService.blobBasePath + this.tutorApplicationInfo.passport_url;

      if (this.tutorApplicationInfo.int_child_protection_certificate_url)
        this.tutorApplicationInfo.intChildCertificateUrl = this.configService.blobBasePath + this.tutorApplicationInfo.int_child_protection_certificate_url;

      if (this.tutorApplicationInfo.health_insurance_url)
        this.tutorApplicationInfo.healthInsuranceUrl = this.configService.blobBasePath + this.tutorApplicationInfo.health_insurance_url;

      //  recupero anche tutte le edizioni delle application dei tutor
      this.tutorApplicationService.getAllEditions((editionRes) => {

        this.tutorApplicationEdition = editionRes;
        this.loading = false;
      }, () => {
        this.loading = false;
      });

    }, () => { this.loading = false; });

  }


  getTutorApplicationRevision() {
    this.loading = true;

    //  recupera le info della review
    this.tutorService.getTutorApplicationRevision(() => {
      this.loading = false;
    }, () => { this.loading = false; });

  }


  private createNationalities(nationality) {
    if (!nationality)
      return undefined;

    if (Array.isArray(nationality))
      return nationality;

    var nationalArray = [];

    let values = nationality.split(',');
    values.forEach(n => {

      var value = Nationalities.find(x => x.value == n);
      if (typeof value != "undefined")
        nationalArray.push(value);
    });

    return nationalArray
  }


  onDateBirthdayChanged(event: IMyDateModel) {

    this.localBirthdayData = event;

    if (event.singleDate.jsDate != null)
      this.tutorApplicationInfo.date_of_birth = this.helper.formatISODate(event);
    else
      this.tutorApplicationInfo.date_of_birth = null;
  }



  public photoUploadFinished(item: any, response: any, status: any) {

    if (item != null) {
      if (status === 200) {
        this.tutorApplicationInfo.photoUrl = this.configService.blobBasePath + response;
        this.tutorApplicationInfo.photo_url = response;
      }
      this.tutorService.photoFileResponse(item, response, status, this.tutorApplicationInfo.uid);
    }

  }

  public photoRemoved() {

    this.photoUploader.isUploading = true;
    this.tutorService.deleteFile(this.tutorApplicationInfo.uid, () => {

      this.tutorApplicationInfo.photoUrl = undefined;
      this.tutorApplicationInfo.photo_url = null;

      this.photoUploader.isUploading = false;
    }, () => { this.photoUploader.isUploading = false; })

  }

  public cvUploadFinished(item: any, response: any, status: any) {

    if (item != null) {
      if (status === 200) {
        this.tutorApplicationInfo.cvUrl = this.configService.blobBasePath + response;
        this.tutorApplicationInfo.cv_url = response;
      }
      this.tutorService.cvFileResponse(item, response, status, this.tutorApplicationInfo.uid);
    }

  }

  public cvRemoved() {

    this.cvUploader.isUploading = true;
    this.tutorService.deleteCv(this.tutorApplicationInfo.uid, () => {

      this.tutorApplicationInfo.cvUrl = undefined;
      this.tutorApplicationInfo.cv_url = null;

      this.cvUploader.isUploading = false;
    }, () => { this.cvUploader.isUploading = false; })
  }


  public coverUploadFinished(item: any, response: any, status: any) {

    if (item != null) {
      if (status === 200) {
        this.tutorApplicationInfo.coverUrl = this.configService.blobBasePath + response;
        this.tutorApplicationInfo.cover_url = response;
      }
      this.tutorService.coverFileResponse(item, response, status, this.tutorApplicationInfo.uid);
    }

  }

  public coverRemoved() {

    this.coverUploader.isUploading = true;

    this.tutorService.deleteCover(this.tutorApplicationInfo.uid, () => {

      this.tutorApplicationInfo.coverUrl = undefined;
      this.tutorApplicationInfo.cover_url = null;
      this.coverUploader.isUploading = false;

    }, () => { this.coverUploader.isUploading = false; })
  }

  public passportUploadFinished(item: any, response: any, status: any) {

    if (item != null) {
      if (status === 200) {
        this.tutorApplicationInfo.passportUrl = this.configService.blobBasePath + response;
        this.tutorApplicationInfo.passport_url = response;
      }
      this.tutorService.passportFileResponse(item, response, status, this.tutorApplicationInfo.uid);
    }

  }

  public passportRemoved() {

    this.passportUploader.isUploading = true;

    this.tutorService.deletePassport(this.tutorApplicationInfo.uid, () => {

      this.tutorApplicationInfo.passportUrl = undefined;
      this.tutorApplicationInfo.passport_url = null;
      this.passportUploader.isUploading = false;

    }, () => { this.passportUploader.isUploading = false; })
  }



  public healthInsuranceUploadFinished(item: any, response: any, status: any) {

    if (item != null) {
      if (status === 200) {
        this.tutorApplicationInfo.healthInsuranceUrl = this.configService.blobBasePath + response;
        this.tutorApplicationInfo.health_insurance_url = response;
      }
      this.tutorService.healthInsuranceFileResponse(item, response, status, this.tutorApplicationInfo.uid);
    }

  }

  healthInsuranceRemove() {

    this.healthInsuranceUploader.isUploading = true;

    this.tutorService.deleteHealthInsurance(this.tutorApplicationInfo.uid, () => {

      this.tutorApplicationInfo.healthInsuranceUrl = undefined;
      this.tutorApplicationInfo.health_insurance_url = null;
      this.healthInsuranceUploader.isUploading = false;

    }, () => { this.healthInsuranceUploader.isUploading = false; })
  }




  inviaMailTo(event: any, type) {

    event.target.disabled = true;

    this.tutorService.sendEmailType(this.tutorService.currentTutorApplicationId.toString(), type, () => {
      event.target.innerHTML = '<i class="fa fa-check-square-o aria-hidden="true"></i>&nbsp;Email inviata correttamente';
      this.notify.success("Email", "The email has been sent correctly");
    }, () => {
      event.target.innerHTML = '<i class="fa fa-exclamation-triangle aria-hidden="true"></i>&nbsp;Errore'
    });
  }

  gettutorService() {
    return this.tutorService;
  }

  getUserService() {
    return this.userService;
  }

  getConfig() {
    return this.configService;
  }

  getHelper() {
    return this.helper;
  }

  getBirthdayLocalData() {
    return this.localBirthdayData;
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

  // getEmbedService(){
  //   return this.embedService;
  // }

  get tutorApplicationFormUrl() {
    return environment.tutorApplicationFormUrl;
  }

}
