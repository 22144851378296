import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FacilitatorApplicationService, ConfigService } from 'app/_services';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-facilitators-applications-phase-table',
  templateUrl: './facilitators-applications-phase-table.component.html',
  styleUrls: ['./facilitators-applications-phase-table.component.css']
})
export class FacilitatorsApplicationsPhaseTableComponent implements OnInit {

  @Input("rows") rows: any[] = [];
  @Input('filter') filter: string = "";
  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;

  selected = [];

  constructor(private facilitatorService: FacilitatorApplicationService, private configService: ConfigService) { }

  ngOnInit() {
  }

  currentFacilitatorId: number;
  currentFacilitatorInfo: any;
  openSelected(selected) {

    this.currentFacilitatorId = selected.id;
    this.currentFacilitatorInfo = selected;
    this.showModal();
  }


  // MODAL
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }



  public getFacilitatorService() {
    return this.facilitatorService;
  }

  public getConfigService(){
    return this.configService
  }

}
