import { Router } from '@angular/router';
import { FacilitatorApplicationService, ConfigService, HelperService, UserService } from '../../_services/index';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { parseISO } from 'date-fns';
import * as moment from 'moment-timezone';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { IMyInputFieldChanged } from 'angular-mydatepicker';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-facilitators-applications-info-summary',
  templateUrl: './facilitators-applications-info-summary.component.html',
  styleUrls: ['./facilitators-applications-info-summary.component.css']
})
export class FacilitatorsApplicationsInfoSummaryComponent implements OnInit {

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(public facilitatorApplicationService: FacilitatorApplicationService, private router: Router,
    private configService: ConfigService, private helper: HelperService, private userService: UserService, private notify: NotificationsService) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
    this.datePickerOptions.disableUntil = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() - 1 }
  }

  @Input() facilitatorApplicationId: number;
  @Output() closeEvent = new EventEmitter();

  public loading: boolean = false;

  facilitatorApplicationInfo: any = {};
  currentId: number;

  dataFormatted: any;
  private localData: IMyDateModel;
  public interviewReady: boolean = false;

  noRoleAlert: boolean = false;

  ngOnInit() {

    this.facilitatorApplicationInfo = {};
  }

  close() {
    this.closeEvent.emit();
  }

  editPerson() {
    this.router.navigate(['person/edit/' + this.facilitatorApplicationInfo.person_id]);
  }

  ngOnChanges() {

    if (this.facilitatorApplicationId != this.currentId)
      this.facilitatororApplicationInfo();
  }

  facilitatororApplicationInfo() {

    if (typeof this.facilitatorApplicationId == "undefined" || this.facilitatorApplicationId <= 0) {
      this.facilitatorApplicationInfo = {};
      this.currentId = null;
      return;
    }

    this.loading = true;

    this.currentId = this.facilitatorApplicationId;
    this.facilitatorApplicationService.currentFacilitatorApplicationId = this.currentId;

    this.facilitatorApplicationService.getFacilitatorApplicationInfo(() => {

      this.facilitatorApplicationInfo = this.facilitatorApplicationService.currentFacilitatorApplicationInfo;

      if (this.facilitatorApplicationInfo.birthday != null)
        this.dataFormatted = this.helper.initMyDataInputSingle(this.facilitatorApplicationInfo.birthday);

      //  Se esiste il path per i file aggiuntivi costruisco gli url
      if (this.facilitatorApplicationInfo.photo_url)
        this.facilitatorApplicationInfo.avatarUrl = this.configService.blobBasePath + this.facilitatorApplicationInfo.photo_url;

      if (this.facilitatorApplicationInfo.cv_url)
        this.facilitatorApplicationInfo.cvUrl = this.configService.blobBasePath + this.facilitatorApplicationInfo.cv_url;

      if (this.facilitatorApplicationInfo.cover_url)
        this.facilitatorApplicationInfo.coverUrl = this.configService.blobBasePath + this.facilitatorApplicationInfo.cover_url;

      if (this.facilitatorApplicationInfo.driving_licence_url)
        this.facilitatorApplicationInfo.driverLicenceUrl = this.configService.blobBasePath + this.facilitatorApplicationInfo.driving_licence_url;

      if (this.facilitatorApplicationInfo.passport_url)
        this.facilitatorApplicationInfo.passportUrl = this.configService.blobBasePath + this.facilitatorApplicationInfo.passport_url;

      if (this.facilitatorApplicationInfo.int_child_protection_certificate_url)
        this.facilitatorApplicationInfo.intChildCertificateUrl = this.configService.blobBasePath + this.facilitatorApplicationInfo.int_child_protection_certificate_url;

      if (this.facilitatorApplicationInfo.int_driver_licence_url)
        this.facilitatorApplicationInfo.intDriverLicenceUrl = this.configService.blobBasePath + this.facilitatorApplicationInfo.int_driver_licence_url;

      if (this.facilitatorApplicationInfo.health_insurance_url)
        this.facilitatorApplicationInfo.healthInsuranceUrl = this.configService.blobBasePath + this.facilitatorApplicationInfo.health_insurance_url;

      if (this.facilitatorApplicationInfo.flight_receipt_url)
        this.facilitatorApplicationInfo.flightReceiptUrl = this.configService.blobBasePath + this.facilitatorApplicationInfo.flight_receipt_url;

      if (this.facilitatorApplicationInfo.timezone) {
        this.facilitatorApplicationInfo.timezone_formatted = this.facilitatorApplicationInfo.timezone + ' (GMT:' + moment.tz(this.facilitatorApplicationInfo.timezone).format('Z') + ')';

        if (this.facilitatorApplicationInfo.interview_time && this.facilitatorApplicationInfo.interview_date) {
          let m = moment.tz(this.facilitatorApplicationInfo.interview_date + " " + this.facilitatorApplicationInfo.interview_time, this.facilitatorApplicationInfo.timezone);
          this.localData = this.helper.initMyDataInputSingle(m);
          this.facilitatorApplicationInfo.italian_interview_time_formatted = m.tz("Europe/Rome").format('hh:mm');;
        }
      }

      if (this.facilitatorApplicationInfo.preferred_interview_time) {
        this.facilitatorApplicationInfo.preferred_interview_time_formatted = [];
        JSON.parse(this.facilitatorApplicationInfo.preferred_interview_time).forEach(time => {
          if (time.checked == true)
            this.facilitatorApplicationInfo.preferred_interview_time_formatted.push(
              " • " + time.name
            );
        });
      }

      this.setInterviewInfo();

      this.loading = false;

    }, () => { this.loading = false; });

  }

  PromoteFacilitator() {

    this.facilitatorApplicationService.promoteFacilitator('' + this.currentId, this.facilitatorApplicationInfo, () => {
      this.close();
    })
  }

  HireFacilitator() {

    this.facilitatorApplicationService.hireFacilitator('' + this.currentId, this.facilitatorApplicationInfo, (jsonResponse) => {

      this.facilitatorApplicationInfo = jsonResponse;
      this.close();
      this.editPerson();
    })
  }

  RejectFacilitator() {

    this.facilitatorApplicationService.rejectFacilitator('' + this.currentId, this.facilitatorApplicationInfo, () => {
      this.close();
    })
  }

  CancelRejectFacilitator() {

    this.facilitatorApplicationService.cancelRejectFacilitator('' + this.currentId, this.facilitatorApplicationInfo, () => {
      this.close();
    })
  }

  EditFacilitator() {

    this.router.navigate(['facilitatorsapplications/edit/' + this.currentId]);
  }


  saveInterviewInfo() {

    this.facilitatorApplicationService.saveInterviewInfo(this.currentId.toString(), this.facilitatorApplicationInfo, () => {

      this.setInterviewInfo();
    });
  }

  setInterviewInfo() {
    if (this.facilitatorApplicationInfo.interview_date != null) {

      if (this.facilitatorApplicationInfo.interview_time != null)
        this.interviewReady = true;

      if (this.facilitatorApplicationInfo.timezone) {
        if (this.facilitatorApplicationInfo.interview_time && this.facilitatorApplicationInfo.interview_date) {
          let m = moment.tz(this.facilitatorApplicationInfo.interview_date + " " + this.facilitatorApplicationInfo.interview_time, this.facilitatorApplicationInfo.timezone);
          this.localData = this.helper.initMyDataInputSingle(m);
          this.facilitatorApplicationInfo.italian_interview_time_formatted = m.tz("Europe/Rome").format('hh:mm');
        }
      }
    }
  }

  // changeSelftape(event) {

  //   if (this.facilitatorApplicationInfo.selftape == true)
  //     this.facilitatorApplicationService.enableSelfTape(this.currentId.toString());
  //   else
  //     this.facilitatorApplicationService.disableSelfTape(this.currentId.toString());
  // }

  // changeAudition(event) {

  //   if (this.facilitatorApplicationInfo.audition == true)
  //     this.facilitatorApplicationService.enableAudition(this.currentId.toString());
  //   else
  //     this.facilitatorApplicationService.disableAudition(this.currentId.toString());
  // }



  onDateChanged(event: IMyDateModel) {

    this.localData = event;

    if (event.singleDate.jsDate) {
      this.facilitatorApplicationInfo.interview_date = this.helper.formatISODate(event);
    } else
    this.facilitatorApplicationInfo.interview_date = null;
  }


  inviaMailTo(event: any, type) {

    event.target.disabled = true;

    this.facilitatorApplicationService.sendEmailType(this.currentId.toString(), type, () => {
      event.target.innerHTML = '<i class="fa fa-check-square-o aria-hidden="true"></i>&nbsp;Email inviata correttamente'
      this.notify.success("Email", "The email has been sent correctly");
    }, () => {
      event.target.innerHTML = '<i class="fa fa-exclamation-triangle aria-hidden="true"></i>&nbsp;Errore'
    });
  }

  public getConfig() {
    return this.configService;
  }

  public getHelper() {
    return this.helper;
  }

  public getDatePickerOptions() {
    return this.datePickerOptions
  }

  public getLocalData() {
    return this.localData
  }

  public getUserService() {
    return this.userService
  }

  public getFacilitatorService() {
    return this.facilitatorApplicationService;
  }

}
