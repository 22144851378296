import { Component, OnInit, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ActivatedRoute } from '@angular/router';
import { VehicleService, UserService } from 'app/_services';
import { VehicleReminderComponent } from './vehicle-reminder/vehicle-reminder.component';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {

  @ViewChild('vehicleTabs', {static: true}) staticTabs: TabsetComponent;
  @ViewChild('reminderTabComponent', {static: true}) public reminderTabComponent: VehicleReminderComponent;

  private subject: any;

  loadingData:boolean = true;
  holderType: string;
  holderId: any;
  holderName: string;


  constructor(private vehicleService:VehicleService, private route: ActivatedRoute,
            private userService: UserService) { }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {

      this.vehicleService.resetService();

      if (typeof params['idDefaultType'] != "undefined") {

        this.vehicleService.idDefaultType = + params['idDefaultType'];

        this.loadingData = false;
        this.disableAllTab();
      }
      else if (typeof params['idCurrentVehicle'] != "undefined") {

        this.vehicleService.currentVehicleId = + params['idCurrentVehicle'];
        this.vehicleService.getVehicleInfoAll(() => {

          this.holderType = "App\\Vehicle";
          this.holderName = this.vehicleService.currentVehicleInfo["vehicle_name"]+" "+this.vehicleService.currentVehicleInfo["plate_number"];
          this.holderId = this.vehicleService.currentVehicleId;

          this.loadingData = false;
          this.activeAllTab();
          this.selectTab(0);
        }, ()=> {

          this.loadingData = false;
          this.disableAllTab();
        });
      }

    });
  }


  ngOnDestroy ()
  {
    this.subject.unsubscribe();
  }


  selectTab(num) {

    if (this.staticTabs.tabs[num])
      this.staticTabs.tabs[num].active = true;
  }

  activeAllTab() {

    this.staticTabs.tabs.forEach(element => {
      element.disabled = false;
    });
  }

  disableAllTab() {

    this.staticTabs.tabs.forEach(element => {
      element.disabled = true;
    });
  }


  getVehicleService() {
    return this.vehicleService
  }

  getUserService() {
    return this.userService
  }

  tabSelectedReminder(event){
    this.reminderTabComponent.modelUpdated(event);
  }

}
