import { ModalDirective } from 'ngx-bootstrap/modal';
import { CampService } from './../../_services/camp.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { ShipmentMaterialSearchFormComponent, ShipmentMaterialDatatableComponent } from 'app/shipments';
import { ShipmentService, ConfigService } from 'app/_services';

@Component({
  selector: 'app-camp-shipments-list',
  templateUrl: './camp-shipments-list.component.html',
  styleUrls: ['./camp-shipments-list.component.css']
})
export class CampShipmentsListComponent implements OnInit {

  @ViewChild('shipmentModal', { static: false }) public shipmentModal: ModalDirective;

  @ViewChild('materialModal', { static: false }) public materialModal: ModalDirective;
  @ViewChild('materialSearchDatatable', { static: true }) public materialSearchDatatable: ShipmentMaterialDatatableComponent;
  @ViewChild('materialSearchForm', { static: true }) public materialSearchForm: ShipmentMaterialSearchFormComponent;

  campGroupList: any[] = [];
  tshirtSizeList: any[] = [];
  staffTshirtSizeList: any[] = [];
  rows = [];
  subscribe: any;
  subscribeShipment: any;

  model: any = {};
  currentMaterialSelected: any = null;

  constructor(private campService: CampService, private notify: NotificationsService,
    private shipmentService: ShipmentService, private configService: ConfigService) { }

  ngOnInit() {

    this.subscribe = this.campService.getsubjectToUpdateObservable().subscribe((update: boolean) => {
      this.loadShipmentList();
      this.getGroupsInfo();
      this.getStaffTshirtInfo();
    });

    this.subscribeShipment = this.shipmentService.getsubjectToUpdateObservable().subscribe((update: boolean) => {
      this.campService.getCampShipment(() => {
        this.loadShipmentList();
      });
    });

    this.loadShipmentList();
    this.getGroupsInfo();
    this.getStaffTshirtInfo();
  }

  getGroupsInfo() {

    if (this.campService.currentCampGroups != null && this.campService.currentCampSubscriberList != null) {

      this.campGroupList = this.campService.currentCampGroups;

      var subs = this.campService.currentCampSubscriberList.filter(x => !x.deleted_at);

      subs.forEach((subscriber: any) => {

        if (typeof subscriber.groups != "undefined" && typeof subscriber.groups[0] != "undefined") {

          let indexGroup = this.campGroupList.findIndex(x => x.id == subscriber.groups[0].camp_group_id)

          if (indexGroup >= 0) {
            this.campGroupList[indexGroup]["count_subscribers_for_book"] = 0;
          }
        }

      });

      subs.forEach((subscriber: any) => {

        if (typeof subscriber.groups != "undefined" && typeof subscriber.groups[0] != "undefined") {

          var found_group_and_counted = false;

          subscriber.groups.forEach(group => {

            let indexGroup = this.campGroupList.findIndex(x => x.id == group.camp_group_id)

            if (indexGroup >= 0 && !found_group_and_counted) {

              found_group_and_counted = true;

              if (typeof this.campGroupList[indexGroup]["count_subscribers_for_book"] == "undefined")
                this.campGroupList[indexGroup]["count_subscribers_for_book"] = 0;

              this.campGroupList[indexGroup]["count_subscribers_for_book"]++;
            }
          });
        }

        if (typeof subscriber.person_info != "undefined") {

          let indexTshirt = this.tshirtSizeList.findIndex(x => x.index == subscriber.person_info.tshirt_size)

          if (indexTshirt < 0) {
            this.tshirtSizeList.push({
              "index": subscriber.person_info.tshirt_size,
              "name": this.configService.getShirtSizePersonValueByKey(subscriber.person_info.tshirt_size),
              "count": 1
            });
          }
          else {
            this.tshirtSizeList[indexTshirt].count++;
          }
        }

      })

    }
  }

  getStaffTshirtInfo() {

    if (this.campService.currentCampStaffList != null) {

      let campStaff = this.campService.currentCampStaffList;

      campStaff.forEach(staff => {
        if (typeof staff.person_info != "undefined") {

          let indexTshirt = this.staffTshirtSizeList.findIndex(x => x.index == staff.person_info.tshirt_size && x.type == staff.type)

          if (indexTshirt < 0) {
            this.staffTshirtSizeList.push({
              "index": staff.person_info.tshirt_size,
              "type": staff.type,
              "type_name": this.configService.getCampStaffTypeValueByKey(staff.type),
              "name": this.configService.getShirtSizePersonValueByKey(staff.person_info.tshirt_size),
              "count": 1
            });
          }
          else {
            this.staffTshirtSizeList[indexTshirt].count++;
          }
        }

      })
    }
  }


  loadShipmentList() {
    if (this.campService.currentShipmentList != null && typeof this.campService.currentShipmentList != "undefined") {
      this.rows = JSON.parse(JSON.stringify(this.campService.currentShipmentList));
    }
  }

  ngOnDestroy() {

    if (this.subscribe)
      this.subscribe.unsubscribe();

    if (this.subscribeShipment)
      this.subscribeShipment.unsubscribe();
  }


  submit() {
    if (this.currentShipmentSelected.id != null) {
      this.campService.updateCampShipment(this.currentShipmentSelected, () => {
      });
    }
    else {
      var model: any = {};
      this.currentShipmentSelected = model;
      this.campService.saveCampShipment(this.currentShipmentSelected, () => {
        this.hideModalShipment();
      })
    }
  }

  finalizeOrder() {
    if (this.currentShipmentSelected != null)
      if (this.currentShipmentSelected.id != null) {
        if (this.currentShipmentSelected.material_list.length <= 0) return;

        this.currentShipmentSelected.closed_order = true;
        this.campService.updateCampShipment(this.currentShipmentSelected, () => {
          this.hideModalShipment();
        });
      }

  }

  destroyShipment() {
    this.campService.deleteCampShipment(this.currentShipmentSelected, () => {
      this.hideModalShipment();
    })
  }


  addNewShipment() {
    if (this.currentShipmentSelected == null) {
      var model: any = {};
      this.currentShipmentSelected = model;
      this.campService.saveCampShipment(this.currentShipmentSelected, () => {
        this.hideModalShipment();
      })
      return;
    }
  }


  currentShipmentSelected: any = null;
  onSelect({ selected }) {
    this.currentShipmentSelected = JSON.parse(JSON.stringify(selected[0]));
    this.showModalShipment();
  }






  //  modale della spedizione
  isModalShipmentShow: boolean = false;
  showModalShipment() {
    this.isModalShipmentShow = true;
    this.shipmentModal.show();
  }

  hideModalShipment() {
    this.shipmentModal.hide();
  }

  onHiddenShipmentModal() {
    this.currentShipmentSelected = null;
    this.isModalShipmentShow = false;
  }






  //  modale del prodotto
  isModalMaterialShow: boolean = false;
  showModalMaterial() {
    this.isModalMaterialShow = true;
    this.materialModal.show();
    this.materialSearchForm.setFocus();
  }
  showMaterial(material) {
    this.currentMaterialSelected = material;
    this.showModalMaterial();
  }

  hideModalMaterial() {
    this.materialModal.hide();
  }

  onHiddenMaterialModal() {
    this.currentMaterialSelected = null;
    this.isModalMaterialShow = false;
  }

  //  notifica di ricezione dei risultati dalla ricerca
  searchResult(results) {

    //  assegno al datatable i risultati della ricerca
    this.materialSearchDatatable.rows = results;
  }

  //  notifica di selezione di un prodotto
  productSelected(product) {

    //  cerco se il prodotto è già presente nella lista, così da non aggiungerlo
    if (typeof this.currentShipmentSelected.material_list != "undefined") {
      var found = false;
      for (var i = 0; i < this.currentShipmentSelected.material_list.length; i++) {
        if (this.currentShipmentSelected.material_list[i].product_id == product.id) {
          this.notify.error("This material is already present");
          return found = true;
        }
      }

      if (found) return;
    }

    //  assegno il prodotto alla modale
    var material = JSON.parse(JSON.stringify(product));
    this.currentMaterialSelected = {
      product_id: material.id,
      product_info:
      {
        type: material.type,
        name: material.name
      },
      shipment_id: this.currentShipmentSelected.id
    };

  }


  //  notifica di elemento salvato / aggiornato
  materialSaved() {

    this.hideModalMaterial();
  }


  //  notifica di cancellazione dell'inserimento di un prodotto
  cancelEvent() {

    this.currentMaterialSelected = null;
  }





  getCampService(): CampService {
    return this.campService
  }

}
