import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject, Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { isPast } from 'date-fns';
import { HelperService } from './helper.service';

declare var saveAs: any;

@Injectable()
export class VehicleService {

  currentVehicleId;
  currentVehicleInfo: any;
  idDefaultType;
  currentVehicleAssignments: any[];
  currentVehicleMaterialAssignments: any[];
  currentVehiclePenalties: any[];
  currentVehicleExpirations: any[];
  currentVehicleDiaryList: any;

  ongoingRequest: boolean = false;

  //	Memorizza l'ultima query di ricerca fatta
  lastSearchQueries: any = null;


  private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

  constructor(private backend: BackendService, private notify: NotificationsService, private http: HttpClient) { }

  getsubjectToUpdateObservable(): Observable<boolean> {
    return this.subjectToUpdate.asObservable();
  }

  resetService() {
    this.currentVehicleId = null;
    this.idDefaultType = null;
    this.currentVehicleInfo = null;
    this.currentVehicleAssignments = null;
    this.currentVehicleMaterialAssignments = null;
    this.currentVehiclePenalties = null;
    this.currentVehicleExpirations = null;
    this.currentVehicleDiaryList = null;
  }

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  isOngoingRequest() {
    return this.ongoingRequest;
  }

  public MAX_NUM_OF_EXPORT = 150000;


  getPenaltyFileUploadURL(model: any) {
    return (environment.apiUrl + 'vehicle/' + this.currentVehicleId + '/penalties/' + model.id + '/uploadpenalty');
  }

  getReceiptFileUploadURL(model: any) {
    return (environment.apiUrl + 'vehicle/' + this.currentVehicleId + '/penalties/' + model.id + '/uploadreceipt');
  }

  getFileUlopadHeader() {
    return this.backend.setupAuthHttpHeaders().headers;
  }

  public penaltyFileResponse(item: any, response: any, status: any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Penalty File', JSON.parse(response)['message']);
      else
        this.notify.error('Penalty File', response);

      this.backend.showErrors(response);
    }
  }

  public receiptFileResponse(item: any, response: any, status: any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Penalty Recipt File', JSON.parse(response)['message']);
      else
        this.notify.error('Penalty Recipt File', response);

      this.backend.showErrors(response);
    }
  }


  get penaltyToPay() {
    var found = false;
    if (this.currentVehiclePenalties != null && typeof this.currentVehiclePenalties != "undefined") {
      for (var i = 0; i < this.currentVehiclePenalties.length && found == false; i++)
        this.currentVehiclePenalties.forEach(penalty => {

          if (!penalty.paid)
            return found = true;
        });
    }

    return found
  }

  get expirationDeadlineExpired() {
    var found = false;
    if (this.currentVehicleExpirations != null && typeof this.currentVehicleExpirations != "undefined") {
      for (var i = 0; i < this.currentVehicleExpirations.length && found == false; i++)
        this.currentVehicleExpirations.forEach(expiration => {

          if (isPast(HelperService.parseDate(expiration.expiration_date)))
            return found = true;
        });
    }

    return found
  }


  deletePenaltyFile(model: any, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('vehicle/' + this.currentVehicleId + '/penalties/' + model.id + '/penalty').subscribe(
      (response) => {

        var resJson: any = response;

        var found = false;
        for (var i = 0; i < this.currentVehiclePenalties.length && !found; i++) {
          if (this.currentVehiclePenalties[i].id == resJson.id) {
            this.currentVehiclePenalties[i] = resJson;
            found = true;
          }
        }

        this.subjectToUpdate.next(true);

        this.notify.success("Penalty File", "Successfully Deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        console.log("Errore");
        console.log(error);

        this.finishRequest();
      }
    );
  }


  deleteReceiptFile(model: any, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('vehicle/' + this.currentVehicleId + '/penalties/' + model.id + '/receipt').subscribe(
      (response) => {

        var resJson: any = response;

        var found = false;
        for (var i = 0; i < this.currentVehiclePenalties.length && !found; i++) {
          if (this.currentVehiclePenalties[i].id == resJson.id) {
            this.currentVehiclePenalties[i] = resJson;
            found = true;
          }
        }

        this.subjectToUpdate.next(true);

        this.notify.success("Receipt File", "Successfully Deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        console.log("Errore");
        console.log(error);

        this.finishRequest();
      }
    );
  }

  /* API di esportazione di tutti i campi */
  // exportAll(model: any, callbackSuccess: any = null, callbackError: any = null) {

  //   let params = new HttpParams();
  //   params = params.set('page_size', '-1');

  //   this.backend.get('person/export/' + model.simpleSearchKeyword, params).subscribe(
  //     (response) => {

  //       if (callbackSuccess != null)
  //         callbackSuccess(response);
  //     },
  //     (error) => {
  //       this.backend.showErrors(error);

  //       if (callbackError != null)
  //         callbackError();
  //     }
  //   );

  // }

  // exportAllAdvanced(model: any, callbackSuccess: any = null, callbackError: any = null) {

  //   let params = new HttpParams();
  //   params = params.set('page_size', '-1');

  // 	this.backend.post('person/export/advanced', model, params).subscribe(
  //     (response) => {

  //       if (callbackSuccess != null)
  //         callbackSuccess(response);
  //     },
  //     (error) => {
  //       this.backend.showErrors(error);

  //       if (callbackError != null)
  //         callbackError();
  //     }
  //   );
  // }




  getVehicleInfoAll(callbackSuccess: any = null, callbackError: any = null) {

    // this.startRequest();

    let params = new HttpParams();

    this.backend.get('vehicle/' + this.currentVehicleId + '/all', params).subscribe(
      (response) => {

        let resJson: any = response;

        this.currentVehicleInfo = resJson;
        this.currentVehicleId = resJson.id;
        this.currentVehicleAssignments = resJson.assignments;
        this.currentVehicleMaterialAssignments = resJson.material_assignments;
        this.currentVehiclePenalties = resJson.penalties;
        this.currentVehicleExpirations = resJson.expirations;
        this.currentVehicleDiaryList = resJson.diary;

        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }


  //  Carica tutte le informazioni su un veicolo senza passare dal service
  getVehicleInfoAllSummary(vehicleId, callbackSuccess: any = null, callbackError: any = null) {

    let params = new HttpParams();

    this.backend.get('vehicle/' + vehicleId + '/all', params).subscribe(
      (response) => {

        let resJson: any = response;

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      });
  }



  saveVehicleInfo(model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.post('vehicle', model).subscribe(
      (response) => {

        let resJson: any = response;
        this.currentVehicleInfo = resJson;
        this.currentVehicleId = resJson.id;
        this.subjectToUpdate.next(true);

        this.notify.success("Vehicle Info", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  updateVehicleInfo(model: any[]) {

    this.startRequest();

    this.backend.put('vehicle/' + this.currentVehicleId, model).subscribe(
      (response: any) => {

        this.currentVehicleId = response.id;

        //	Faccio partire una richiesta con tutte le info del veicolo che altrimenti non mi arriverebbero
        this.getVehicleInfoAll(() => {
          this.notify.success("Vehicle Info", "successfully updated");
        });

      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      });
  }

  deleteVehicleInfo(model: any, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('vehicle/' + this.currentVehicleId).subscribe(
      (response) => {

        this.resetService();

        this.notify.success("Vehicle Info", "successfully deleted");

        this.finishRequest();

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }


  getVehicleFileUploadURL(model: any) {
    return (environment.apiUrl + 'vehicle/' + this.currentVehicleId + '/upload');
  }
  getVehicleFileUlopadHeader() {
    return this.backend.setupAuthHttpHeaders().headers;
  }

  public vehiclePhotoFileResponse(item: any, response: any, status: any, id: String) {

    if (status === 200) {
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Photo File', JSON.parse(response)['message']);
      else
        this.notify.error('Photo File', response);

      this.backend.showErrors(response);
    }
  }


  deleteVehiclePhotoFile(model: any) {

    this.startRequest();

    this.backend.destroy('vehicle/' + this.currentVehicleId + '/destroyFile').subscribe(
      (response) => {

        this.currentVehicleInfo = response;
        this.currentVehicleId = this.currentVehicleInfo.id;
        this.subjectToUpdate.next(true);

        this.notify.success("Photo File", "Successfully Deleted");

        this.finishRequest();
      },
      (error) => {
        console.log("Errore");
        console.log(error);

        this.finishRequest();
      }
    );
  }



  getAllAssignment(vehicleId: number, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('vehicle/' + vehicleId + '/assignment/all', params).subscribe((response) => {

      let resJson: any = response;
      this.currentVehicleAssignments = resJson;

      this.finishRequest();

      if (callbackSuccess != null)
        callbackSuccess();
    },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });

  }


  saveVehicleAssignment(model: any[], callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('vehicle/assignment', model).subscribe(
      (response) => {

        let resJson: any = response;

        this.notify.success("Vehicle Assignment", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updateVehicleAssignment(assignmentId: number, model: any[], callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('vehicle/assignment/' + assignmentId, model).subscribe(
      (response) => {

        let resJson: any = response;

        this.notify.success("Vehicle Assignment", "successfully updated");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();

      });
  }


  deleteVehicleAssignment(assignmentId: number, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('vehicle/assignment/' + assignmentId).subscribe(
      (response) => {

        this.notify.success("Vehicle Assignment", "successfully deleted");

        this.finishRequest();

        this.subjectToUpdate.next(null);

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }











  getAllMaterialAssignment(vehicleId: number, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('vehicle/' + vehicleId + '/material/all', params).subscribe((response) => {

      let resJson: any = response;
      this.currentVehicleMaterialAssignments = resJson;

      this.finishRequest();

      if (callbackSuccess != null)
        callbackSuccess();
    },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });

  }


  saveVehicleMaterialAssignment(model: any[], callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('vehicle/material', model).subscribe(
      (response) => {

        let resJson: any = response;

        this.notify.success("Material Assignment", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updateVehicleMaterialAssignment(assignmentId: number, model: any[], callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('vehicle/material/' + assignmentId, model).subscribe(
      (response) => {

        let resJson: any = response;

        this.notify.success("Material Assignment", "successfully updated");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();

      });
  }


  deleteVehicleMaterialAssignment(assignmentId: number, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('vehicle/material/' + assignmentId).subscribe(
      (response) => {

        this.notify.success("Material Assignment", "successfully deleted");

        this.finishRequest();

        this.subjectToUpdate.next(null);

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }








  saveVehiclePenalty(model: any[], callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('vehicle/' + this.currentVehicleId + '/penalties', model).subscribe(
      (response) => {

        let resJson: any = response;

        if (this.currentVehiclePenalties == null)
          this.currentVehiclePenalties = [];

        this.currentVehiclePenalties.push(resJson);
        this.subjectToUpdate.next(true);

        this.notify.success("Vehicle Penalty", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updateVehiclePenalty(penaltyId: number, model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('vehicle/' + this.currentVehicleId + '/penalties/' + penaltyId, model).subscribe(
      (response) => {

        let resJson: any = response;

        var found = false;
        for (var i = 0; i < this.currentVehiclePenalties.length && !found; i++) {
          if (this.currentVehiclePenalties[i].id == model.id) {
            this.currentVehiclePenalties[i] = resJson;
            found = true;
          }
        }

        this.subjectToUpdate.next(true);

        this.notify.success("Vehicle Penalty", "successfully updated");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();

      });
  }


  deleteVehiclePenalty(penaltyId: number, model: any, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('vehicle/' + this.currentVehicleId + '/penalties/' + penaltyId).subscribe(
      (response) => {

        var found = false;
        for (var i = 0; i < this.currentVehiclePenalties.length && !found; i++) {
          if (this.currentVehiclePenalties[i].id == model.id) {
            found = true;
            this.currentVehiclePenalties.splice(i, 1);
          }
        }

        this.subjectToUpdate.next(true);

        this.notify.success("Vehicle Penalty", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }






  saveVehicleExpiration(model: any[], callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('vehicle/' + this.currentVehicleId + '/expirations', model).subscribe(
      (response) => {

        let resJson: any = response;

        if (this.currentVehicleExpirations == null)
          this.currentVehicleExpirations = [];

        this.currentVehicleExpirations.push(resJson);
        this.subjectToUpdate.next(true);

        this.notify.success("Vehicle Expiration", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updateVehicleExpiration(expirationId: number, model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('vehicle/' + this.currentVehicleId + '/expirations/' + expirationId, model).subscribe(
      (response) => {

        let resJson: any = response;

        var found = false;
        for (var i = 0; i < this.currentVehicleExpirations.length && !found; i++) {
          if (this.currentVehicleExpirations[i].id == model.id) {
            this.currentVehicleExpirations[i] = resJson;
            found = true;
          }
        }

        this.subjectToUpdate.next(true);

        this.notify.success("Vehicle Expiration", "successfully updated");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();

      });
  }


  deleteVehicleExpiration(expirationId: number, model: any, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('vehicle/' + this.currentVehicleId + '/expirations/' + expirationId).subscribe(
      (response) => {

        var found = false;
        for (var i = 0; i < this.currentVehicleExpirations.length && !found; i++) {
          if (this.currentVehicleExpirations[i].id == model.id) {
            found = true;
            this.currentVehicleExpirations.splice(i, 1);
          }
        }

        this.subjectToUpdate.next(true);

        this.notify.success("Vehicle Expiration", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }



  saveVehicleDiary(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('vehicle/' + this.currentVehicleId + '/diary', model).subscribe(
      (response) => {

        var diary: any = response;

        if (this.currentVehicleDiaryList == null)
          this.currentVehicleDiaryList = {};

        this.currentVehicleDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updateVehicleDiary(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('vehicle/' + this.currentVehicleId + '/diary/' + model.id, model).subscribe(
      (response) => {

        var diary: any = response;

        if (this.currentVehicleDiaryList == null)
          this.currentVehicleDiaryList = {};

        this.currentVehicleDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteVehicleDiary(model: any) {

    this.startRequest();

    this.backend.destroy('vehicle/' + this.currentVehicleId + '/diary/' + model.id).subscribe(
      (response) => {

        var diary: any = response;

        if (this.currentVehicleDiaryList == null)
          this.currentVehicleDiaryList = {};

        this.currentVehicleDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }


}
