<!-- BEGIN CONTENT BODY -->
<div class="page-content">
    <div class="row">
      <div class="col-md-12">

        <!-- BEGIN PAGE HEADER-->
        <!-- BEGIN PAGE BAR -->
        <div class="page-bar">
          <ul class="page-breadcrumb">
            <li>
              <a href="javascript:;" [routerLink]="['/home']">Home</a>
              <i class="fa fa-circle"></i>
            </li>
            <li>
              <a href="javascript:;" [routerLink]="['/camp/reports']">Camp Reports</a>
              <i class="fa fa-circle"></i>
            </li>
            <li>
              <span>Camp Members</span>
            </li>
          </ul>
          <div class="page-toolbar">

          </div>
        </div>
        <!-- BEGIN PAGE TITLE-->
        <h1 class="page-title"> Camp Members
          <small></small>
        </h1>
        <!-- END PAGE TITLE-->
        <!-- END PAGE HEADER-->

        <!-- BEGIN: Filters -->
        <form action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Tipo</label>
              <select class="form-control" name="type" [(ngModel)]="model.type" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let t of getConfigService().campTypeSelect" [value]="t.key">{{t.value}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-1">
            <div class="form-group">
              <label class="control-label">Anno</label>
              <select class="form-control" name="year" [(ngModel)]="model.year" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let y of getConfigService().yearSelect(-10)" [value]="y">{{y}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="control-label">Mese</label>
              <select class="form-control" name="month" [(ngModel)]="model.month" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let m of getConfigService().monthSelect()" [value]="m">{{m}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDateFrom, false)) }">
              <label class="control-label">From Date</label>
                <div class="input-group">
                  <!-- input box -->
                  <input angular-mydatepicker #dp_from_date="angular-mydatepicker" name="from_date" class="form-control"
                    [locale]="getHelper().locale"
                    [options]="datePickerOptionsBegin"
                    [ngModel]="localDateFrom"
                    (dateChanged)="onDateFromChanged($event)"
                  />
                  <!-- clear date button -->
                  <span class="input-group-btn">
                    <button type="button" *ngIf="model['from_date']" class="btn default" (click)="dp_from_date.clearDate()"><i class="fa fa-close"></i></button>
                  </span>
                  <!-- toggle calendar button -->
                  <span class="input-group-btn">
                      <button type="button" class="btn default" (click)="dp_from_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                  </span>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !dp_to_date.isDateValid) }">
              <label class="control-label">To Date</label>
                <div class="input-group">
                  <!-- input box -->
                  <input angular-mydatepicker #dp_to_date="angular-mydatepicker" name="to_date" class="form-control"
                    [locale]="getHelper().locale"
                    [options]="datePickerOptionsEnd"
                    [ngModel]="localDateTo"
                    (dateChanged)="onDateToChanged($event)"
                  />
                  <!-- clear date button -->
                  <span class="input-group-btn">
                    <button type="button" *ngIf="model['to_date']" class="btn default" (click)="dp_to_date.clearDate()"><i class="fa fa-close"></i></button>
                  </span>
                  <!-- toggle calendar button -->
                  <span class="input-group-btn">
                      <button type="button" class="btn default" (click)="dp_to_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                  </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
              <div class="form-group">
                <label class="control-label">Camp code</label>
                <input type="text" class="form-control" placeholder="" name="camp_code" [(ngModel)]="model.camp_code" (change)="fieldChange($event);">
              </div>
            </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Località</label>
              <input type="text" class="form-control" placeholder="" name="locality" [(ngModel)]="model.locality" (change)="fieldChange($event);">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Province</label>
              <input type="text" class="form-control" placeholder="" name="province" [(ngModel)]="model.province" (change)="fieldChange($event);">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Region</label>
              <input type="text" class="form-control" placeholder="" name="region" [(ngModel)]="model.region" (change)="fieldChange($event);">
            </div>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">Status</label>
              <select class="form-control" name="status" [(ngModel)]="model.status" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let s of getConfigService().campStatusSelect" [value]="s.key">{{s.value}}</option>
              </select>
            </div>
          </div> -->
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Company</label>
              <select class="form-control" name="company" [(ngModel)]="model.company" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let c of getConfigService().groupCompaniesSelect" [value]="c.key">{{c.value}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Area</label>
              <select class="form-control" name="area" [(ngModel)]="model.area" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let z of getConfigService().areaExpertiseSelect" [value]="z.key">{{z.value}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <label class="control-label">Camp Status (multiple selection)</label>
              <div class="form-group">
                <div class="mt-checkbox-inline">
                  <label class="mt-checkbox" *ngFor="let status of multipleSelect">
                    <input type="checkbox" [(ngModel)]="status.value" [ngModelOptions]="{standalone: true}"> {{getConfigService().getCampStatusValueByKey(status.key)}}
                    <span></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group"  [ngClass]="{ 'has-error': (f.submitted || camp_staff_type.dirty || camp_staff_type.touched) && !camp_staff_type.valid }">
              <label class="control-label">Person Role in Camp <span class="required">*</span></label>
              <select class="form-control" #camp_staff_type="ngModel" name="camp_staff_type" [(ngModel)]="model.camp_staff_type" required (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let st of getConfigService().campStaffTypeSelect" [value]="st.key">{{st.value}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-actions right">
          <button type="button" class="btn default btn-lg" (click)="clearSearch()">Clear
            <i class="fa fa-eraser" aria-hidden="true"></i>
          </button>
          <button class="btn default btn-lg green" [disabled]="getCampService().isOngoingRequest()" type="submit">Search</button>
        </div>

      </form>
        <!-- END: Filters -->

        <!-- begin: prima riga -->
        <div class="row">
          <!-- begin: prima colonna -->
          <div class="col-md-12">
            <div class="portlet light bordered">
              <div class="portlet-body">
                <div class="row" *ngIf="getCampService().isOngoingRequest()">
                  <div class="col-md-12">
                    <div class="datatable-loader text-center">
                      <span></span><span></span><span></span><span></span><span></span>
                      <strong class="text-center">Loading</strong>
                    </div>
                  </div>
                </div>
                <div class="portlet" *ngIf="!getCampService().isOngoingRequest()">
                  <div class="portlet-body">
                    <ngx-datatable
                      class="striped"
                      [rows]="rows"
                      [columnMode]="'force'"
                      [headerHeight]="50"
                      [footerHeight]="50"
                      [rowHeight]="'auto'"
                    >

                    <ngx-datatable-column name="Name">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <span *ngIf="row.denomination">
                          {{row.denomination}}
                        </span>
                        <span *ngIf="row.first_name">
                          {{row.surname}} {{row.first_name}}
                        </span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Code" prop="camp_code"></ngx-datatable-column>

                    <ngx-datatable-column name="Camp">
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{row.administrative_area_level_3_long_version}} -
                        ({{row.administrative_area_level_2_short_version}}) -
                        {{row.administrative_area_level_1_long_version}}
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Mobile" prop="contact_mobile"></ngx-datatable-column>
                    <ngx-datatable-column name="Email" prop="contact_email"></ngx-datatable-column>

                    </ngx-datatable>
                  </div>
                </div>
                <div class="row" [appCheckPermission]="'camp_reports_export'">
                  <div class="pull-right">
                    <button [disabled]="getCampService().isOngoingRequest() || rows?.length <= 0" type="button" class="btn green fix-button-margin" (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end: prima colonna -->
        </div>
        <!-- end: prima riga -->
      </div>
    </div>
  </div>
  <!-- END CONTENT BODY -->

  <!-- BEGIN: MODAL LOADING EXPORT-->
  <div *ngIf="isModalExport" [config]="{ show: true, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal #staticModal="bs-modal" class="modal fade"
    tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-center">
            Attendere il caricamento senza chiudere o cambiare pagina
          </h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="datatable-loader text-center">
                <span></span><span></span><span></span><span></span><span></span>
                <strong class="text-center">Loading</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: MODAL LOADING EXPORT-->
