<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/admin/reports']">Admin Reports</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span>Reports Camp Signup Request</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->
      <br>

      <!-- BEGIN: Filters -->
      <form action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label class="control-label">Anno</label>
              <select class="form-control" name="year" [(ngModel)]="model.year" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let y of getConfigService().yearSelect(-10)" [value]="y">{{y}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="control-label">Mese</label>
              <select class="form-control" name="month" [(ngModel)]="model.month" (change)="fieldChange($event);">
                <option value=""></option>
                <option *ngFor="let m of getConfigService().monthSelect()" [value]="m">{{m}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group"
              [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDateFrom, false)) }">
              <label class="control-label">From Date</label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_from_date="angular-mydatepicker" name="from_date" class="form-control"
                  [locale]="getHelper().locale" [options]="datePickerOptionsBegin" [ngModel]="localDateFrom"
                  (dateChanged)="onDateFromChanged($event)" />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model['from_date']" class="btn default"
                    (click)="dp_from_date.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_from_date.toggleCalendar()"><i
                      class="fa fa-calendar-o"></i></button>
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group"
              [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDateTo, false)) }">
              <label class="control-label">To Date</label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_to_date="angular-mydatepicker" name="to_date" class="form-control"
                  [locale]="getHelper().locale" [options]="datePickerOptionsEnd" [ngModel]="localDateTo"
                  (dateChanged)="onDateToChanged($event)" />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model['to_date']" class="btn default" (click)="dp_to_date.clearDate()"><i
                      class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_to_date.toggleCalendar()"><i
                      class="fa fa-calendar-o"></i></button>
                </span>
              </div>
            </div>
          </div>

        </div>

        <div class="form-actions right">
          <button type="button" class="btn default btn-lg" (click)="clearSearch()">Clear
            <i class="fa fa-eraser" aria-hidden="true"></i>
          </button>
          <button class="btn default btn-lg green" [disabled]="getAdminReportsService().isOngoingRequest()"
            type="submit">Search</button>
        </div>

      </form>
      <!-- END: Filters -->

      <br>

      <!-- begin: prima riga -->
      <div class="row">
        <!-- begin: prima colonna -->
        <div class="col-md-12">
          <div class="portlet light bordered">
            <div class="portlet-body">
              <div class="row" *ngIf="getAdminReportsService().isOngoingRequest()">
                <div class="col-md-12">
                  <div class="datatable-loader text-center">
                    <span></span><span></span><span></span><span></span><span></span>
                    <strong class="text-center">Loading</strong>
                  </div>
                </div>
              </div>
              <div class="portlet" *ngIf="!getAdminReportsService().isOngoingRequest()">
                <div class="portlet-body">
                  <ngx-datatable class="striped" [rows]="rows" [columns]="columns" [trackByProp]="'id'"
                    [columnMode]="'standard'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="50"
                    [scrollbarH]="true">
                  </ngx-datatable>
                </div>
              </div>
              <div class="row">
                <div class="pull-right">
                  <button [appCheckPermission]="'admin_reports_export'"
                    [disabled]="getAdminReportsService().isOngoingRequest() || rows?.length <= 0" type="button"
                    class="btn green fix-button-margin" (click)="exportToExcel()"><i class="fa fa-share"></i>
                    Export to Excel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END CONTENT BODY -->
      </div>
    </div>
  </div>
  <!-- end: prima colonna -->
</div>
<!-- end: prima riga -->

<!-- BEGIN: MODAL LOADING EXPORT-->
<div *ngIf="isModalExport" [config]="{ show: true, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal
  #staticModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
