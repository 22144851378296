<!-- <alert type="warning" *ngIf="ongoingRequest">
  <strong>Loading</strong> ...
</alert> -->
<div class="datatable-loader text-center" *ngIf="ongoingRequest">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>

<ngx-datatable *ngIf="!ongoingRequest"
  class="striped"
  [rows]="rows"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="true"
  [externalSorting]="true"
  [count]="pageTotalElements"
  [offset]="pageNumber"
  [limit]="pageSize"
  [selected]="selected"
  [selectionType]="'single'"
  (activate)="onActivate($event)"
  (select)='onSelect($event)'
  (page)='setPage($event)'
  (sort)='onSort($event)'
>
  <ngx-datatable-column name="Name" prop="vehicle_name" [flexGrow]="1" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Brand" prop="brand_name" [flexGrow]="1" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Model" prop="brand_model" [flexGrow]="1" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Plate Numer" prop="plate_number" [flexGrow]="1" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Active" prop="active" [flexGrow]="1" [resizeable]="false">
    <ng-template ngx-datatable-cell-template let-value="value">
      <i *ngIf="value" class="fa fa-check" aria-hidden="true"></i>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Alerts" prop="penalties_count" [flexGrow]="1" [resizeable]="false">
    <ng-template ngx-datatable-cell-template let-value="value">
      <span class="danger">
        <i *ngIf="value" class="fa fa-exclamation-triangle" tooltip="{{value}} penalties not paid" aria-hidden="true"></i>
      </span>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
