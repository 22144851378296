import { Component, OnInit, Input } from '@angular/core';
import { EnterpriseService, ConfigService } from '../../_services/index';

@Component({
  selector: 'app-enterprise-accomodation-form',
  templateUrl: './enterprise-accomodation-form.component.html',
  styleUrls: ['./enterprise-accomodation-form.component.css']
})
export class EnterpriseAccomodationFormComponent implements OnInit {

@Input() model: any;

subscribe: any;

  constructor(private enterpriseService: EnterpriseService, private configService: ConfigService) { }

  ngOnInit() {

    this.model = this.enterpriseService.currentEnterpriseAccomodation;

    if (this.model == null)
      this.model = {};

    this.subscribe = this.enterpriseService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

        this.model = this.enterpriseService.currentEnterpriseAccomodation;

        if (this.model == null)
          this.model = {};
      });
  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }

  submit() {
    if (this.model.id)
      this.enterpriseService.updateEnterpriseAccomodation(this.model);
    else
      this.enterpriseService.saveEnterpriseAccomodation(this.model);
  }

  getConfigService() {
    return this.configService
  }

  getEnterpriseService() {
    return this.enterpriseService
  }

}
