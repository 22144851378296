<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 class="form-section">Documents</h3>

    <div class="row">
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
          <label class="control-label">Type<span class="required">*</span></label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
            <option *ngFor="let d of getConfigService().documentEnterpriseSelect" [value]="d.key">{{d.value}}</option>
          </select>
          <span class="help-block">Select the type of the document, is required</span>
        </div>
        <div *ngIf="!editMode && model.type" class="form-group editMode">
          <label class="control-label">Type</label>
          <p>{{getConfigService().getDocumentEnterpriseValueByKey(model.type)}}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" name="note" placeholder="Some notes..." [(ngModel)]="model.note"></textarea>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
            <label class="control-label">Notes</label>
            <p class="line-breaker">{{model.note}}</p>
          </div>
      </div>
    </div>


    <div class="row" *ngIf="!model.id && editMode">
      <div class="col-md-6">
        <label class="control-label">Attachment file</label>
        <alert type="warning" *ngIf="!model.documentUrl">
          <i class="fa fa-warning"></i> First save the form and after upload the document
        </alert>
      </div>
    </div>

    <div class="row" *ngIf="model.id">
      <div class="col-md-12">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Attachment file</label>
          <div *ngIf="model.file_path && !getEnterpriseService().isOngoingRequest()">
            <a type="button" [href]="getConfigService().blobBasePath + model.file_path" target="_blank" class="btn btn-block btn-outline green text-uppercase label-file" ><i class="fa fa-save"></i>&nbsp; Download {{getConfigService().getDocumentEnterpriseValueByKey(model.type)}}</a>
          </div>
            <div *ngIf="(model.file_path == undefined || model.file_path == '') && !getEnterpriseService().isOngoingRequest()">
              <input *ngIf="!documentUploader.isUploading" type="file" id="documentUploader"
                ng2FileSelect [uploader]="documentUploader" />
              <label *ngIf="!documentUploader.isUploading" for="documentUploader"
                class="btn btn-lg btn-block label-file green-jungle text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload {{getConfigService().getDocumentEnterpriseValueByKey(model.type)}}!</label>
            </div>
            <div *ngIf="documentUploader.isUploading || getEnterpriseService().isOngoingRequest()">
              <img src="./assets/global/img/input-spinner.gif" />
              please wait...
            </div>
            <div *ngIf="model.file_path != undefined && model.file_path != ''">
              <button *ngIf="!documentUploader.isUploading && !getEnterpriseService().isOngoingRequest()" type="button"
                class="btn btn-danger btn-block text-uppercase label-file" (click)="destroyFile();"><i
                  class="fa fa-trash"></i>&nbsp; Remove {{getConfigService().getDocumentEnterpriseValueByKey(model.type)}}</button>
            </div>
          </div>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Attachment</label>
            <div *ngIf="model.file_path">
            <a type="button" [href]="getConfigService().blobBasePath + model.file_path" target="_blank" class="btn btn-block btn-outline green text-uppercase label-file" ><i class="fa fa-save"></i>&nbsp; Download {{getConfigService().getDocumentEnterpriseValueByKey(model.type)}}!</a>
          </div>
          <p *ngIf="!model.file_path">none</p>
      </div>
    </div>

  </div>


  <div [appCheckPermission]="'enterprise_edit'" class="form-actions right">
    <img *ngIf="getEnterpriseService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
    <button type="button" class="btn red btn-outline" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="this.model.id"
       [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
      <span class="ladda-label"> <i class="fa fa-trash-o"></i> Delete</span>
    </button>
    <button type="button" class="btn btn-outline blue" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="this.model.id && !editMode"
    (click)="edit()"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

    <button *ngIf="editMode" type="submit" class="btn blue" [disabled]="getEnterpriseService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>



</form>
