import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { EnterpriseService, ConfigService, HelperService } from '../../_services/index';
import { NgForm } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-enterprise-contact-form',
  templateUrl: './enterprise-contact-form.component.html',
  styleUrls: ['./enterprise-contact-form.component.css']
})
export class EnterpriseContactFormComponent implements OnInit {

  @Input() model: any = {};
  @Input() multiSave: boolean;

  @Output() primaryUpdate = new EventEmitter();
  @Output() formDelete = new EventEmitter();
  @Output() formSaved = new EventEmitter();

    editMode: boolean = false;

  @ViewChild('f', {static: true}) currentForm: NgForm;

  constructor(private enterpriseService: EnterpriseService, private configService: ConfigService,
              private helper: HelperService, private notify: NotificationsService) { }

  ngOnInit() {

    if (!this.model || !this.model.id)
      this.editMode = true;
  }

  submit()
  {
    if (!this.currentForm.form.valid)
      return;

    if (!this.currentForm.form.dirty) {
      this.editMode = false;
      return;
    }

    if (!this.enterpriseService.checkPrimary(this.model, this.enterpriseService.currentEnterpriseContactList)) {
      this.notify.error("You need to set this or other contact as primary");
      return;
    }

    //  Se non pho passato la validazione mi fermo
    if (!this.validateValue)
      return;

    if (this.model.id)
      this.enterpriseService.updateEnterpriseContact(this.model, ()=> {this.editMode = false;});
    else
      this.enterpriseService.saveEnterpriseContact(this.model, ()=> {this.editMode = false;});

    this.formSaved.emit(this.model);
  }

  destroy() {
    this.enterpriseService.deleteEnterpriseContact(this.model);
  }

  delete() {
    this.formDelete.emit(this);
  }

  edit() {
    this.editMode = true;
  }

  //  E' cambiato il valore di un primary
  valueChange(value) {
    this.primaryUpdate.emit(this.model);
  }


  get validateValue (): boolean
  {
       //  Controllo la validità dei campi prima di inviarli
    if (this.model.type != null && this.model.value != null)
    {
      switch (parseInt(this.model.type)) {
        case 1:
          return this.helper.validateEmail(this.model.value);
        case 2:
          return this.helper.validatePhone(this.model.value);
        case 3:
          return this.helper.validatePhone(this.model.value);
        case 4:
          return this.helper.validatePhone(this.model.value);
        case 5:
          return this.helper.validatePhone(this.model.value);
        case 6:
          return this.helper.validateUrl(this.model.value);
      }
    }

    return true;
  }


  getConfigService() {
    return this.configService
  }


  getEnterpriseService() {
    return this.enterpriseService
  }

}
