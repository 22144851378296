import {Component, OnInit, Input} from '@angular/core';
import {PersonService, HelperService} from "../../_services/index";
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-person-availability-form',
  templateUrl: './person-availability-form.component.html',
  styleUrls: ['./person-availability-form.component.css']
})
export class PersonAvailabilityFormComponent implements OnInit {

  @Input() model: any;

  subscribe: any;

  private datePickerOptions: IAngularMyDpOptions = {};

  tutorFrom: IMyDateModel;
  tutorTo: IMyDateModel;
  actorFrom: IMyDateModel;
  actorTo: IMyDateModel;
  arrivingFligthDeparture: IMyDateModel;
  arrivingFligthArrival: IMyDateModel;
  arrivingTrainDeparture: IMyDateModel;
  leavingFligthDeparture: IMyDateModel;

  constructor(private personService: PersonService, private helper: HelperService) {

    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    this.fillModel();

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      this.fillModel();
    });
  }

  private fillModel() {

    this.model = this.personService.currentPersonAvailability;

    if (this.model == null) {
      this.model = {};
    }

    //tutor
    if (this.model.tutor_from) {

      this.tutorFrom = this.helper.initMyDataInputSingle(this.model.tutor_from);
    }

    if (this.model.tutor_to) {

      this.tutorTo = this.helper.initMyDataInputSingle(this.model.tutor_to);
    }

    //actor
    if (this.model.actor_from) {

      this.actorFrom = this.helper.initMyDataInputSingle(this.model.actor_from);
    }

    if (this.model.actor_to) {

      this.actorTo = this.helper.initMyDataInputSingle(this.model.actor_to);
    }

    //
    if (this.model.arriving_fligth_departure_date) {

      this.arrivingFligthDeparture = this.helper.initMyDataInputSingle(this.model.arriving_fligth_departure_date);
    }

    //
    if (this.model.arriving_fligth_arrival_date) {

      this.arrivingFligthArrival = this.helper.initMyDataInputSingle(this.model.arriving_fligth_arrival_date);
    }

    //
    if (this.model.arriving_train_departure_time) {

      this.arrivingTrainDeparture = this.helper.initMyDataInputSingle(this.model.arriving_train_departure_date);
    }

    //
    if (this.model.leaving_fligth_departure_date) {

      this.leavingFligthDeparture = this.helper.initMyDataInputSingle(this.model.leaving_fligth_departure_date);
    }
  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }

  submit() {

    if (this.model.id) {
      this.personService.updateAvailability(this.model);
    }
    else {
      this.personService.saveAvailability(this.model);
    }
  }

  onTutorFromDateChanged(event: IMyDateModel) {

    this.tutorFrom = event;

    this.model.tutor_from = null;

    if (event.singleDate.jsDate)
      this.model.tutor_from = this.helper.formatISODate(event);
    else
      this.model.tutor_from = null;
  }

  onTutorToDateChanged(event: IMyDateModel) {

    this.tutorTo = event;

    this.model.tutor_to = null;

    if (event.singleDate.jsDate)
      this.model.tutor_to = this.helper.formatISODate(event);
    else
      this.model.tutor_to = null;
  }

  onActorFromDateChanged(event: IMyDateModel) {

    this.actorFrom = event;

    this.model.actor_from = null;

    if (event.singleDate.jsDate)
      this.model.actor_from = this.helper.formatISODate(event);
    else
      this.model.actor_from = null;
  }

  onActorToDateChanged(event: IMyDateModel) {

    this.actorTo = event;

    this.model.actor_to = null;

    if (event.singleDate.jsDate)
      this.model.actor_to = this.helper.formatISODate(event);
    else
      this.model.actor_to = null;
  }

  onArrivingFligthDepartureDateChanged(event: IMyDateModel) {

    this.arrivingFligthDeparture = event;

    this.model.arriving_fligth_departure_date = null;

    if (event.singleDate.jsDate)
      this.model.arriving_fligth_departure_date = this.helper.formatISODate(event);
    else
      this.model.arriving_fligth_departure_date = null;
  }

  onArrivingFligthArrivalDateChanged(event: IMyDateModel) {

    this.arrivingFligthArrival = event;

    this.model.arriving_fligth_arrival_date = null;

    if (event.singleDate.jsDate)
      this.model.arriving_fligth_arrival_date = this.helper.formatISODate(event);
    else
      this.model.arriving_fligth_arrival_date = null;
  }

  onArrivingTrainDepartureDateChanged(event: IMyDateModel) {

    this.arrivingTrainDeparture = event;

    this.model.arriving_train_departure_date = null;

    if (event.singleDate.jsDate)
      this.model.arriving_train_departure_date = this.helper.formatISODate(event);
    else
      this.model.arriving_train_departure_date = null;
  }

  onLeavingFligthDepartureDateChanged(event: IMyDateModel) {

    this.arrivingFligthDeparture = event;

    this.model.leaving_fligth_departure_date = null;

    if (event.singleDate.jsDate)
      this.model.leaving_fligth_departure_date = this.helper.formatISODate(event);
    else
      this.model.leaving_fligth_departure_date = null;
  }

  getHelper() {
    return this.helper
  }

  getPersonService() {
    return this.personService
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

}
