import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TheatrinoDatatableComponent } from './../theatrino-datatable/theatrino-datatable.component';
import { TheatrinoService } from '../../_services';

@Component({
  selector: 'app-theatrino-search-result',
  templateUrl: './theatrino-search-result.component.html',
  styleUrls: ['./theatrino-search-result.component.css']
})
export class TheatrinoSearchResultComponent implements OnInit {

  @ViewChild(TheatrinoDatatableComponent, {static: true}) datatable: TheatrinoDatatableComponent;

  private subject: any;

  constructor(private route: ActivatedRoute, private router: Router,
              private theatrinoService: TheatrinoService) { }

  ngOnInit() {

    let params = {};

    if (this.theatrinoService.lastSearchQueries != null)
    {
      params = this.theatrinoService.lastSearchQueries;
    }
    //  inizializzo i paramteri di ricerca
    else
    {
      params = {theatrino_active: true};
      this.getAllResult();
      return;
    }

    this.searchKeywordsSubmit(params);
  }

  theatrinoSelected (selected) {
    this.router.navigate(['theatrino/edit/'+selected.theatrino_id]);
  }

  getAllResult() {
    this.datatable.query = "all";
    this.datatable.getResult();
  }

  updateResults(params) {
    this.datatable.query = params;
    this.datatable.getResult();
  }

  searchKeywordsSubmit(params) {

    if (typeof params == "undefined")
      this.getAllResult();
    else
      this.updateResults(params);
  }

}
