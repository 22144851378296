<div>
  <div>
    <h3 class="form-section">Theatrino Booking Agreements</h3>
  </div>

  <app-enterprise-theatrino-agreement-list></app-enterprise-theatrino-agreement-list>

  <div>
    <h3 class="form-section">Camp Agreements</h3>
  </div>

  <app-enterprise-camp-agreement-list></app-enterprise-camp-agreement-list>

</div>
