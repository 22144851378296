import { HelperService, ConfigService, CampService } from 'app/_services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-camp-document-list',
  templateUrl: './camp-document-list.component.html',
  styleUrls: ['./camp-document-list.component.css']
})
export class CampDocumentListComponent implements OnInit {

  documentsRows: any [] = [];

  subscribe: any;

  constructor(private helper: HelperService, private configService: ConfigService,
            private campService: CampService) { }

  ngOnInit() {

    this.subscribe = this.campService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.getDocumentsInfo();
    });

    if (this.campService.currentDocumentList != null && this.campService.currentDocumentList != null)
    this.getDocumentsInfo();

  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  isOngoingRequest() {
    return this.campService.isOngoingRequest()
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }
  getDocumentsInfo () {

    if (this.campService.currentDocumentList != null && this.campService.currentDocumentList != null) {
      this.documentsRows = this.campService.currentDocumentList;

      this.documentsRows.forEach((el) => {
        el.date_parsed = HelperService.parseDate(el.date);
      })
    }
  }

}
