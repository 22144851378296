import { Component, Input, OnInit, Output, ViewChild, EventEmitter, ViewChildren, QueryList, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { ConfigService, CourseScheduleService, HelperService } from 'app/_services';

@Component({
  selector: 'app-course-schedule-table-date-row',
  templateUrl: './course-schedule-table-date-row.component.html',
  styleUrls: ['./course-schedule-table-date-row.component.css']
})
export class CourseScheduleTableDateRowComponent implements OnInit, OnChanges {

  @Input('row') rowSchedule;
  @Output('deleteRowEvent') deleteRowEvent = new EventEmitter();
  @Output('updatedRowEvent') updatedRowEvent = new EventEmitter();

  @ViewChild('myForm') currentForm: NgForm;

  public localDataStart: IMyDateModel;
  //  Impostazione della data che vengono passate dall'helper
  private _datePickerOptionsBegin: IAngularMyDpOptions = {};
  public get datePickerOptionsBegin () {
    return this._datePickerOptionsBegin;
  }

  _rowSchedule: any = {};

  constructor(private helper: HelperService, private configService: ConfigService, private courseScheduleService: CourseScheduleService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {

    if (this.rowSchedule) {
      this._rowSchedule = JSON.parse(JSON.stringify(this.rowSchedule));
      this.localDataStart = this.helper.initMyDataInputSingle(this._rowSchedule.date);
    }
  }

  onSubmit() {

    this.onTimeStartInput();
    this.onTimeEndInput();

    if (this.currentForm.form.valid && this.timeStartvalid && this.timeEndvalid) {
      if (this._rowSchedule.id)
        this.courseScheduleService.updateTimesheet(this._rowSchedule, (response) => {
          this.updatedRowEvent.emit(response);
        });
      else
        this.courseScheduleService.saveTimesheet(this._rowSchedule, (response) => {
          this.updatedRowEvent.emit(response);
        });
    }
  }

  deleteRow() {
    this.courseScheduleService.deleteTimesheet(this._rowSchedule.id, (response) => {
      this.deleteRowEvent.emit(response);
    });
  }


  //  Operazioni sulla data di start
  onDateStartChanged(event: IMyDateModel)
  {
    this.localDataStart = event;

    if (event.singleDate.jsDate != null)
    {
      this._rowSchedule.date = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil =  {year: event.singleDate.date.year, month: event.singleDate.date.month, day: event.singleDate.date.day -1};
    }
    else
      this._rowSchedule.date = null;

  }



  //  Valido l'orario di start
  timeStartvalid: boolean = false;
  onTimeStartInput (event = null)
  {
    this.timeStartvalid = false;

    //  controllo se è valido il time
    this.configService.timeCourseSchedulePlanSelect.forEach(element => {

      if (element.value == this.currentForm.form.controls.start_time.value)
          return this.timeStartvalid = true;
    });
  }
  typeTimeStartOnSelect (event) {
    //  Se arriva questo evento al 100% è una data valida
    this.timeStartvalid = true;
  }

      //  Valido l'orario di end
  timeEndvalid: boolean = false;
  onTimeEndInput (event = null)
  {
    this.timeEndvalid = false;

    //  controllo se è valido il time
    this.configService.timeCourseSchedulePlanSelect.forEach(element => {
      if (element.value == this.currentForm.form.controls.end_time.value)
        return this.timeEndvalid = true;
    });
  }
  typeTimeEndOnSelect (event) {
    //  Se arriva questo evento al 100% è una data valida
    this.timeEndvalid = true;
  }


  getCourseScheduleService() {
    return this.courseScheduleService;
  }

  getHelper() {
    return this.helper
  }

  getConfigService() {
    return this.configService
  }
}
