import { Router } from '@angular/router';
import { VehicleService, ConfigService, HelperService } from './../../_services/index';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-vehicle-info-summary',
  templateUrl: './vehicle-info-summary.component.html',
  styleUrls: ['./vehicle-info-summary.component.css']
})
export class VehicleInfoSummaryComponent implements OnInit, OnChanges {

  constructor(private vehicleService: VehicleService, private router: Router,
              private configService: ConfigService, private helper: HelperService) { }

  @Input() idVehicle: number;
  @Output() closeEvent = new EventEmitter();
  vehicleInfo: any;
  currentId: number;

  dataFormatted: any;

  loading: boolean = false;

  ngOnInit() {

    this.vehicleInfo = {};
  }

  close(){
    this.closeEvent.emit();
  }

  editVehicle() {
    // console.log(this.currentId);

    this.router.navigate(['vehicle/edit/'+this.currentId]);
  }

  ngOnChanges() {

    if (this.idVehicle != this.currentId)
      this.getVehicleInfo();
  }

  getVehicleInfo(){

    if (typeof this.idVehicle == "undefined" ||  this.idVehicle <= 0){
      this.vehicleInfo = {};
      this.currentId = null;
      return;
    }

    this.currentId = this.idVehicle;

    this.loading = true;

    this.vehicleService.getVehicleInfoAllSummary(this.currentId, (vehicleInfo) => {

      this.vehicleInfo = vehicleInfo;

      //  Se esiste il path per l'immagine costruisco l'url completo
      if (this.vehicleInfo.photo_url)
        this.vehicleInfo.photoUrl = this.configService.blobBasePath+this.vehicleInfo.photo_url;

        this.loading = false;
    }, () => {

        this.loading = false;
    });

  }

  getConfigService() {
    return this.configService
  }

  getVehicleService() {
    return this.vehicleService
  }
}
