<alert type="warning" *ngIf="loading">
  <strong>Loading</strong> ...
</alert>

<div *ngIf="!loading">

  <form action="#" class="horizontal-form" novalidate>
    <div class="form-body">
      <div>
        <alert *ngIf="facilitatorApplicationInfo.imported" type="warning">
          <strong>Alert!</strong> Facilitator already imported: this facilitator is present in Person Database.
        </alert>

        <alert *ngIf="facilitatorApplicationInfo.person_id" type="info">
          <button class="btn btn-block btn-outline btn-success"
            routerLink="/person/edit/{{ facilitatorApplicationInfo.person_id }}">Vai alla scheda della persona
            creata</button>
        </alert>
      </div>

      <br>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Created At</label>
            <p>{{ facilitatorApplicationInfo.created_at | date:'d MMMM, y - HH:mm':'':'it' }}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Last Update</label>
            <p>{{ facilitatorApplicationInfo.updated_at | date:'d MMMM, y - HH:mm':'':'it' }}</p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Annotation and Preferences</h4>
      <div class="row" *ngIf="facilitatorApplicationInfo.phase >= 1">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Preference Phase 1</label>
            <p>
              {{ getConfig().getapplicationPhasePreferenceSelectValueByKey(facilitatorApplicationInfo.status_01_promote_preference) }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group read-only">
            <label class="control-label">Annotation</label>
            <p class="line-breaker">{{facilitatorApplicationInfo.status_01_promote_note}}</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="facilitatorApplicationInfo.phase >= 2">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Preference Phase 2</label>
            <p>
              {{ getConfig().getapplicationPhasePreferenceSelectValueByKey(facilitatorApplicationInfo.status_02_promote_preference) }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group read-only">
            <label class="control-label">Annotation</label>
            <p class="line-breaker">{{facilitatorApplicationInfo.status_02_promote_note}}</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="facilitatorApplicationInfo.phase >= 3">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Preference Phase 3</label>
            <p>
              {{ getConfig().getapplicationPhasePreferenceSelectValueByKey(facilitatorApplicationInfo.status_03_promote_preference) }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group read-only">
            <label class="control-label">Annotation</label>
            <p class="line-breaker">{{facilitatorApplicationInfo.status_03_promote_note}}</p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Personal info</h4>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Name</label>
            <p>{{facilitatorApplicationInfo.surname}} {{facilitatorApplicationInfo.firstname}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Date of birth</label>
            <p>{{facilitatorApplicationInfo.date_of_birth}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Nationalities</label>
            <p>{{getFacilitatorService().getNationalitiesLabel(facilitatorApplicationInfo.nationality)}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Criminal Record</label>
            <p *ngIf="facilitatorApplicationInfo.criminal_record == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="facilitatorApplicationInfo.criminal_record == 'no'"><i class="fa fa-remove"></i></p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Current Occupation</label>
            <p>{{facilitatorApplicationInfo.current_occupation}}&nbsp;</p>
          </div>
          <div class="form-group read-only" *ngIf="facilitatorApplicationInfo.tshirt_size">
            <label class="control-label">T-Shirt Size</label>
            <p>{{facilitatorApplicationInfo.tshirt_size}}&nbsp;</p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Gender</label>
            <!-- <p>{{facilitatorApplicationInfo.gender}}</p> -->
            <p>{{facilitatorApplicationInfo.custom_gender}}</p>
          </div>
          <div class="form-group read-only" *ngIf="facilitatorApplicationInfo.native_english_speaker">
            <label class="control-label">Native English speaker</label>
            <p *ngIf="facilitatorApplicationInfo.native_english_speaker == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="facilitatorApplicationInfo.native_english_speaker == 'no'"><i class="fa fa-remove"></i></p>
          </div>
          <div class="form-group read-only" *ngIf="facilitatorApplicationInfo.proficient_english_speaker">
            <label class="control-label">Proficient English speaker</label>
            <p *ngIf="facilitatorApplicationInfo.proficient_english_speaker == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="facilitatorApplicationInfo.proficient_english_speaker == 'no'"><i class="fa fa-remove"></i></p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Passport Type</label>
            <p>{{facilitatorApplicationInfo.passport_type}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Criminal record detail</label>
            <p>{{facilitatorApplicationInfo.criminal_record_detail}}&nbsp;</p>
          </div>
        </div>

        <div class="col-md-4 mt-element-card">
          <div class="mt-card-item">
            <div *ngIf="facilitatorApplicationInfo.avatarUrl" class="mt-overlay-1" aria-hidden="true">
              <img *ngIf="facilitatorApplicationInfo.avatarUrl"
                src="{{facilitatorApplicationInfo.avatarUrl}}?{{facilitatorApplicationInfo.updated_at}}"
                class="img-responsive pic-bordered" alt="">
            </div>
            <div *ngIf="!facilitatorApplicationInfo.avatarUrl" class="mt-overlay-1" aria-hidden="true">
              <img src="../assets/placeholder/avatar-placeholder.jpg" width="100%">
            </div>
          </div>
          <div class="form-group" *ngIf="facilitatorApplicationInfo.cvUrl">
            <a class="btn btn-outline btn-block blue" [href]="facilitatorApplicationInfo.cvUrl" target="_blank">
              <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Download CV
            </a>
          </div>
          <div class="form-group" *ngIf="facilitatorApplicationInfo.coverUrl">
            <a class="btn btn-outline btn-block blue" [href]="facilitatorApplicationInfo.coverUrl" target="_blank">
              <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Download Cover Letter
            </a>
          </div>
          <div class="form-group" *ngIf="facilitatorApplicationInfo.youtube_selftape_link">
            <a class="btn btn-outline btn-block green-jungle" [href]="facilitatorApplicationInfo.youtube_selftape_link"
              target="_blank">
              <i class="fa fa-youtube-play" aria-hidden="true"></i>&nbsp;Self-Tape video
            </a>
          </div>
        </div>
      </div>

      <h4 class="form-section">Additional Info</h4>
      <div class="row">
        <div class="col-md-6" *ngIf="facilitatorApplicationInfo.timezone">
          <label class="control-label">Preferred interview Time</label>
          <p>
            {{facilitatorApplicationInfo.timezone_formatted}}
            {{facilitatorApplicationInfo.preferred_interview_time_formatted}}
          </p>
        </div>
        <div class="col-md-6"></div>
      </div>

      <h4 class="form-section">Address &amp; Contacts</h4>
      <div class="row">
        <div class="col-md-4" *ngIf="facilitatorApplicationInfo.address">
          <div class="form-group read-only">
            <label class="control-label">Address</label>
            <p>{{facilitatorApplicationInfo.address}}, {{facilitatorApplicationInfo.postal_code}} -
              {{facilitatorApplicationInfo.city}}
              ({{facilitatorApplicationInfo.province}}) -
              {{getFacilitatorService().getNationalityLabel(facilitatorApplicationInfo.country)}}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Email</label>
            <p>{{facilitatorApplicationInfo.email}}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Mobile telephone</label>
            <p>{{facilitatorApplicationInfo.mobile_telephone}}</p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Driving Information</h4>
      <div class="row">

        <div class="col-md-3">
          <div class="form-group read-only">
            <label class="control-label">Full driving licence</label>
            <p *ngIf="facilitatorApplicationInfo.driver_licence == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="facilitatorApplicationInfo.driver_licence == 'no'"><i class="fa fa-remove"></i></p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group read-only">
            <label class="control-label">Clean driver licence</label>
            <p *ngIf="facilitatorApplicationInfo.clean_driver_licence  == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="facilitatorApplicationInfo.clean_driver_licence  == 'no'"><i class="fa fa-remove"></i></p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group read-only">
            <label class="control-label">Happy to drive in Italy</label>
            <p *ngIf="facilitatorApplicationInfo.like_drive_italy  == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="facilitatorApplicationInfo.like_drive_italy  == 'no'"><i class="fa fa-remove"></i></p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group read-only">
            <label class="control-label">Shift Type</label>
            <p>{{facilitatorApplicationInfo.shift_type}}&nbsp;</p>
          </div>
        </div>

      </div>

      <h4 class="form-section">Tour Preference</h4>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Tour</label>
            <p>{{getConfig().getFacilitatorApplicationTourType(facilitatorApplicationInfo.tour_type)}}&nbsp;</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Workshop Date Accepted</label>
            <p *ngIf="facilitatorApplicationInfo.workshop_agree == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="facilitatorApplicationInfo.workshop_agree == 'no'"><i class="fa fa-remove"></i></p>
          </div>
        </div>
      </div>

      <br>

      <span *ngIf="facilitatorApplicationInfo.phase >= 3">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">Date of interview <span class="required">*</span></label>
                    <div class="input-group">
                      <input angular-mydatepicker #dp_interview_date="angular-mydatepicker" name="interview_date"
                        class="form-control" [locale]="getHelper().locale" [options]="getDatePickerOptions()"
                        [ngModel]="getLocalData()" (dateChanged)="onDateChanged($event)" />
                      <span class="input-group-btn">
                        <button type="button" *ngIf="facilitatorApplicationInfo?.interview_date" class="btn default"
                          (click)="dp_interview_date.clearDate()"><i class="fa fa-close"></i></button>
                      </span>
                      <span class="input-group-btn">
                        <button type="button" class="btn default" (click)="dp_interview_date.toggleCalendar()"><i
                            class="fa fa-calendar-o"></i></button>
                      </span>
                    </div>
                    <span class="help-block">Set the date and time in {{facilitatorApplicationInfo.timezone_formatted}}
                      timezone</span>
                  </div>

                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">Time of interview <span class="required">*</span></label>
                    <input type="text" name="interview_time" [(ngModel)]="facilitatorApplicationInfo.interview_time"
                      #interview_time="ngModel" class="form-control" required>
                    <span *ngIf="facilitatorApplicationInfo.italian_interview_time_formatted" class="help-block">Italian
                      time:
                      {{facilitatorApplicationInfo.italian_interview_time_formatted}}</span>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label class="control-label">&nbsp;</label>
                    <button
                      *ngIf="facilitatorApplicationInfo.interview_date && facilitatorApplicationInfo.interview_time"
                      type="button" class="btn blue form-control" (click)="saveInterviewInfo()"><i class="fa fa-save"
                        aria-hidden="true"></i>&nbsp;Save</button>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label class="control-label">&nbsp;</label>
                    <ng-template #confirmMailInterview>
                      <button type="button" class="btn btn-outline green-jungle"
                        (click)="inviaMailTo($event, 'phase3_interview')">Yes, send it!</button>
                    </ng-template>
                    <button type="button" *ngIf="interviewReady" [outsideClick]="true" [popover]="confirmMailInterview"
                      popoverTitle="Are you sure?" class="btn btn-outline blue form-control"><i class="fa fa-envelope-o"
                        aria-hidden="true"></i>&nbsp;Interview</button>
                    <small>{{facilitatorApplicationInfo.interview_mail_send}}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Skype interview accepted/rejected</label>
              <p><b>Accepted:</b> {{facilitatorApplicationInfo.interview_accepted | date:'d MMMM, y HH:mm':'':'it'}}<br>
                <b>Rejected:</b> {{facilitatorApplicationInfo.interview_rejected | date:'d MMMM, y HH:mm':'':'it'}}<br>
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Skype Address</label>
              <p>{{facilitatorApplicationInfo.skype_address}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" *ngIf="facilitatorApplicationInfo.passportUrl">
              <a class="btn btn-outline btn-block blue" [href]="facilitatorApplicationInfo.passportUrl" target="_self">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Passport
              </a>
            </div>
          </div>
          <div class="col-md-4" *ngIf="facilitatorApplicationInfo.skype_address">
            <div class="form-group" *ngIf="facilitatorApplicationInfo.driverLicenceUrl">
              <a class="btn btn-outline btn-block blue" [href]="facilitatorApplicationInfo.driverLicenceUrl"
                target="_self">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Driving Licence
              </a>
            </div>
          </div>
        </div>
      </span>

      <div *ngIf="facilitatorApplicationInfo.phase >= 4">
        <h4 class="form-section">Proposal</h4>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">IBAN / Paypal Address</label>
              <p>{{facilitatorApplicationInfo.payment_number}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group" *ngIf="facilitatorApplicationInfo.intChildCertificateUrl">
              <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                [href]="facilitatorApplicationInfo.intChildCertificateUrl" target="_blank">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp; International Child Protection Certificate
              </a>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" *ngIf="facilitatorApplicationInfo.intDriverLicenceUrl">
              <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                [href]="facilitatorApplicationInfo.intDriverLicenceUrl" target="_blank">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp; International Driver Licence
              </a>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" *ngIf="facilitatorApplicationInfo.healthInsuranceUrl">
              <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                [href]="facilitatorApplicationInfo.healthInsuranceUrl" target="_blank">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Health Insurance
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Emergency Contact Name</label>
              <p>{{facilitatorApplicationInfo.emergency_contact_name}}</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Emergency Contact Relationship</label>
              <p>{{facilitatorApplicationInfo.emergency_contact_relationship}}</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Emergency Contact Phone</label>
              <p>{{facilitatorApplicationInfo.emergency_contact_phone}}</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Emergency Contact Email</label>
              <p>{{facilitatorApplicationInfo.emergency_contact_email}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Dietary Requirements
                <i class="fa fa-check-circle text-success"
                  *ngIf="facilitatorApplicationInfo?.dietary_requirements_yes_or_not == 'yes'"></i>
                <i class="fa fa-close text-danger"
                  *ngIf="facilitatorApplicationInfo?.dietary_requirements_yes_or_not == 'no'"></i>
              </label>
              <p>{{facilitatorApplicationInfo.dietary_requirements}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Allergies
                <i class="fa fa-check-circle text-success"
                  *ngIf="facilitatorApplicationInfo?.allergic_yes_or_not == 'yes'"></i>
                <i class="fa fa-close text-danger" *ngIf="facilitatorApplicationInfo?.allergic_yes_or_not == 'no'"></i>
              </label>
              <p>{{facilitatorApplicationInfo.allergic_to}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Medical Conditions
                <i class="fa fa-check-circle text-success"
                  *ngIf="facilitatorApplicationInfo?.medical_conditions === 'yes'"></i>
                <i class="fa fa-close text-danger" *ngIf="facilitatorApplicationInfo?.medical_conditions === 'no'"></i>
              </label>
              <p>{{facilitatorApplicationInfo.medical_condition_details}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Availability</label>
              <p>{{facilitatorApplicationInfo.availability}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Flight Details</label>
              <p>{{facilitatorApplicationInfo.flight_details}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" *ngIf="facilitatorApplicationInfo.flightReceiptUrl">
              <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                [href]="facilitatorApplicationInfo.flightReceiptUrl" target="_blank">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Flight Receipt
              </a>
            </div>
          </div>
        </div>
      </div>

      <span *ngIf="facilitatorApplicationInfo.phase >= 5">
        <h4 class="form-section">Additional Proposal Info</h4>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Professional reference 1</label>
              <p>{{facilitatorApplicationInfo.professional_reference1}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Professional reference 2</label>
              <p>{{facilitatorApplicationInfo.professional_reference2}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">University</label>
              <p>{{facilitatorApplicationInfo.university}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Relevant qualifications</label>
              <p>{{facilitatorApplicationInfo.qualifications}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Working Experience with children</label>
              <p>{{facilitatorApplicationInfo.children_working_experience	}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Musical Instruments</label>
              <p>{{facilitatorApplicationInfo.musical_instruments}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Teach any Sports</label>
              <p>{{facilitatorApplicationInfo.sports}}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Can you speak Italian</label>
              <p>{{facilitatorApplicationInfo.italian_speak_rate}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Do you smoke</label>
              <p>{{facilitatorApplicationInfo.smoker}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">First Aid Certificate</label>
              <p>{{facilitatorApplicationInfo.first_aid_year_certificate}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">TEFL Certificate</label>
              <p>{{facilitatorApplicationInfo.tefl_certificate}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Confident swimmer</label>
              <p *ngIf="facilitatorApplicationInfo.confident_swimmer == true"><i class="fa fa-check"></i></p>
              <p *ngIf="facilitatorApplicationInfo.confident_swimmer == false"><i class="fa fa-remove"></i></p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Previous Teaching Experience in Drama</label>
              <p>{{facilitatorApplicationInfo.drama_experience}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Different Skills or experience</label>
              <p>{{facilitatorApplicationInfo.skills}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Stay in a Catholic environment</label>
              <p *ngIf="facilitatorApplicationInfo.catholic == true"><i class="fa fa-check"></i></p>
              <p *ngIf="facilitatorApplicationInfo.catholic == false"><i class="fa fa-remove"></i></p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Shall be living with host families</label>
              <p *ngIf="facilitatorApplicationInfo.host_families == true"><i class="fa fa-check"></i></p>
              <p *ngIf="facilitatorApplicationInfo.host_families == false"><i class="fa fa-remove"></i></p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Hear About</label>
              <p>{{facilitatorApplicationInfo.how_hear_about}}</p>
            </div>
          </div>
        </div>
      </span>
    </div>
    <br>

    <div class="form-actions right">
      <button type="button" class="btn blue btn-outline" (click)="EditFacilitator()"
        [appCheckPermission]="'application_facilitators_edit'">Edit</button>
      <button type="button" class="btn btn-outline red" (click)="RejectFacilitator()"
        *ngIf="!facilitatorApplicationInfo.rejected">
        <i class="fa fa-times" aria-hidden="true"></i>&nbsp;Reject
      </button>

      <button type="button" class="btn btn-outline red" (click)="CancelRejectFacilitator()"
        *ngIf="facilitatorApplicationInfo.rejected">
        <i class="fa fa-times" aria-hidden="true"></i>&nbsp;Cancel rejected status
      </button>

      <ng-template #confirmPromote>
        <button type="button" class="btn btn-outline green-jungle" (click)="PromoteFacilitator()">Yes, I'm
          sure!</button>
      </ng-template>
      <button type="button"
        *ngIf="!facilitatorApplicationInfo.rejected && !facilitatorApplicationInfo.imported && facilitatorApplicationInfo.status == 'SUBMITTED' && facilitatorApplicationInfo.phase < 5"
        [outsideClick]="true" [popover]="confirmPromote" popoverTitle="Are you sure?" class="btn btn-outline blue"><i
          class="fa fa-check" aria-hidden="true"></i>&nbsp;Promote</button>

      <ng-template #confirmPromoteToHire>
        <button type="button" class="btn btn-outline green-jungle" (click)="HireFacilitator()">Yes, I'm sure!</button>
      </ng-template>
      <button type="button"
        *ngIf="!facilitatorApplicationInfo.rejected && !facilitatorApplicationInfo.imported && facilitatorApplicationInfo.status == 'EDITING' && facilitatorApplicationInfo.phase >= 5"
        [outsideClick]="true" [popover]="confirmPromoteToHire" popoverTitle="Are you sure?"
        class="btn btn-outline blue"><i class="fa fa-check" aria-hidden="true"></i>&nbsp;Hire!</button>
    </div>

  </form>
</div>
