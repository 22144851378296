import { HttpParams } from '@angular/common/http';
import { BackendService, HelperService } from './../../_services/index';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-actor-group-datatable',
  templateUrl: './actor-group-datatable.component.html',
  styleUrls: ['./actor-group-datatable.component.css']
})
export class ActorGroupDatatableComponent implements OnInit {

  @Input() query: any;

  @Output() groupSelected = new EventEmitter();

  pageTotalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotalPages: number = 0;
  rows: any[] = [];

  selected = [];

  ongoingRequest: boolean = false;

  currentQuery: any;

  constructor(private backend: BackendService, private helperService: HelperService) { }

  ngOnInit() {
    // this.setPage({ offset: 0});
  }

  update (data: any) {
  }

  setPage(pageInfo)
  {
    this.pageNumber = pageInfo.offset;
    this.getResult();
  }

  onSelect({ selected }) {
    this.groupSelected.emit(selected[0]);
  }

  onActivate(event){
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  getResult()
  {
    if (typeof this.query == "undefined")
      return;

    if (JSON.stringify(this.currentQuery) != JSON.stringify(this.query))
    {
      //  Salvo il valore della query attuale e resetto le variabili
      this.currentQuery = Object.assign(this.query);
      this.pageTotalElements = 0;
      this.pageNumber = 0;
      this.pageSize = 0;
    }

    this.rows = [];
    this.pageTotalElements = 0;
    this.pageTotalPages = 0;

    if (typeof this.currentQuery == "undefined" || Object.keys(this.currentQuery).length <= 0)
      return;

    let params = new HttpParams();
    params = params.set('page', (this.pageNumber + 1).toString());

    //   Se non ci sono query faccio una query per recuperare tutti i dati
    if (this.currentQuery == "all")
    {
      this.ongoingRequest = true;

      this.backend.post('actorgroup/search/advanced', this.currentQuery, params).subscribe(
        (response) => {
          let res : any = response;

          this.pageNumber = res.current_page - 1;
          this.pageSize = res.per_page;
          this.pageTotalElements = res.total;
          this.pageTotalPages = res.last_page;
          this.rows = res.data;

          this.ongoingRequest = false;
        },
        (error) => {
          this.backend.showErrors(error);

          this.ongoingRequest = false;
        }
      );

      return;
    }

    if (typeof this.currentQuery.advancedSearchOn == "undefined")
      return;

    let simpleSearchKeyword = this.helperService.transformSearchString(this.currentQuery.simpleSearchKeyword);

    this.ongoingRequest = true;

    if (this.currentQuery.advancedSearchOn == "false" || !this.currentQuery.advancedSearchOn) {

      this.backend.get('actorgroup/search/' + simpleSearchKeyword, params).subscribe(
        (response) => {
          let res : any = response;
          this.pageNumber = res.current_page - 1;
          this.pageSize = res.per_page;
          this.pageTotalElements = res.total;
          this.pageTotalPages = res.last_page;
          this.rows = res.data;
          this.ongoingRequest = false;
        },
        (error) => {
          this.backend.showErrors(error);

          this.ongoingRequest = false;
        }
      );

    }
    else {

      this.backend.post('actorgroup/search/advanced', this.currentQuery, params).subscribe(
        (response) => {
          let res : any = response;
          this.pageNumber = res.current_page - 1;
          this.pageSize = res.per_page;
          this.pageTotalElements = res.total;
          this.pageTotalPages = res.last_page;
          this.rows = res.data;

          this.ongoingRequest = false;
        },
        (error) => {
          this.backend.showErrors(error);

          this.ongoingRequest = false;
        }
      );
    }

  }


}
