<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>

        <!-- BEGIN PAGE TITLE-->
        <h1 class="page-title">Camps Calendar</h1>
        <!-- END PAGE TITLE-->
        <!-- END PAGE HEADER-->

        <div [hidden]="!isSmartPortrait" class="overlay-portrait"></div>

        <!-- BEGIN: filter component -->
        <app-camp-calendar-filter (search)="submitSearch($event)"></app-camp-calendar-filter>
        <!-- END: filters -->

        <!-- BEGIN: sezione dedicata alle info sui risultati delle ricerche -->
        <div class="row" *ngIf="rows?.length > 0">
          <div class="col-md-12">
            <div class="portlet light bordered">
              <div class="portlet-body">
                <div class="row">
                  <div class="col-md-6">
                    Numero di Camps Totali : <b> {{allCamps?.length}} </b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    Numero totale delle presenze : <b> {{totalSubscribersWeek}} </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- BEGIN: sezione datatable -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">

              <!--end: Portlet-Body -->
              <div class="portlet-body form">

              <div class="datatable-loader text-center" *ngIf="getCampService().isOngoingRequest()">
                <span></span><span></span><span></span><span></span><span></span>
                <strong class="text-center">Loading</strong>
              </div>

                <!-- calendar camps: datatable -->
                <div class="fluid-header-datatable">

                  <!-- calendar camps: header-->
                  <div #affixheader>
                    <ngx-datatable
                      class="material hidden-empty-datatable"
                      [columnMode]="'flex'"
                      [rows]="[]"
                      [headerHeight]="'auto'"
                      [footerHeight]="0"
                      >
                      <ngx-datatable-column name="Camps" [flexGrow]="6" [resizeable]="false" [sortable]="false" [draggable]="false">
                      </ngx-datatable-column>
                      <ngx-datatable-column *ngFor="let col of columns;let i = index"
                        [flexGrow]="2" [name]="col.name"
                        [resizeable]="false" [sortable]="false" [draggable]="false"
                        >
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>



                  <ngx-datatable
                    class="material"
                    [columnMode]="'flex'"
                    [rows]="rows"
                    [selectionType]="'single'"
                    [headerHeight]="0"
                    [footerHeight]="0"
                    [selected]="selected"
                    [scrollbarH]="false"
                    [scrollbarV]="false"
                    [rowClass]="getRowClass"
                    (select)="onSelect($event)"
                    >

                    <ngx-datatable-column name="Camps" [flexGrow]="6" [resizeable]="false" [sortable]="false" [draggable]="false" prop="administrative_area_level_3_long_version" [cellClass]="getCellClass">
                      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <button *ngIf="value" type="button" class="no-button" [popover]="campTemplate" popoverTitle="Camp {{value}}" container="body" triggers="mouseenter:mouseleave" placement="right">
                          {{value}}</button>
                        <ng-template #campTemplate>
                          <div class="row camp-popover">
                            <div class="col-md-6">
                              <div class="form-group read-only">
                                <label class="control-label">Codice campo</label>
                                <p>{{row?.camp_code}}</p>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group read-only">
                                <label class="control-label">Status</label>
                                <p class="font-{{row?.status_color.name}}">{{row?.status_name}}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group read-only">
                                <label class="control-label">Data Inizio</label>
                                <p>{{row?.start | date:'d MMMM, y':'':'it'}}</p>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group read-only">
                                <label class="control-label">Data Fine</label>
                                <p>{{row?.end | date:'d MMMM, y':'':'it'}}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group read-only">
                                <label class="control-label">Indirizzo</label>
                                <p>{{row?.route}} {{row?.street_number}} - {{row?.postal_code}} - {{row?.locality}} </p>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </ng-template>
                    </ngx-datatable-column>

                    <!-- [headerClass]="'datatable-vertical-text'" -->
                    <ngx-datatable-column *ngFor="let col of columns;let i = index"
                      [flexGrow]="2" [name]="col.name"
                      [resizeable]="false" [sortable]="false" [draggable]="false">
                      <ng-template let-row="row" let-column="col" ngx-datatable-cell-template>
                        <ng-container *ngIf="row?.table_weeks[i]?.in_this_date == true">
                        	<button type="button" class="btn btn-outline {{getTutorsClass(row, i)}} no-button" style="padding: 0px; padding-left: 5px; padding-right: 5px; aborder: none;"
                          		(click)="showTutorsModal(row, i)">{{getSubsForDate(row, i)}} / {{getProvsForDate(row, i)}}</button>
                        </ng-container>
                      </ng-template>
                    </ngx-datatable-column>

                  </ngx-datatable>

                  <hr>

                  <!-- tutor camps: datatable -->
                  <ngx-datatable
                    class="material fullscreen"
                    [columnMode]="'flex'"
                    [rows]="fakeRows"
                    [headerHeight]="0"
                    [footerHeight]="0"
                    [selectionType]="'cell'"
                    [scrollbarH]="false"
                    >
                    <ngx-datatable-column name="" [flexGrow]="6" [resizeable]="false" [sortable]="false">
                      <ng-template let-row="row" let-column="col" ngx-datatable-cell-template>
                        <b>{{row?.title}}</b>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column *ngFor="let col of columns;let i = index" [flexGrow]="2" name="" [resizeable]="false" [sortable]="false">
                      <ng-template let-row="row" let-column="col" ngx-datatable-cell-template>
                        <ng-container *ngIf="row?.title == 'Iscritti'">
                          {{rowChildrenWeeks[i]?.entry | zeroCurrency}}
                        </ng-container>
                        <ng-container *ngIf="row?.title == 'Tutor Previsti'">
                          {{rowTutorPrevisisonal[i]?.entry | zeroCurrency}}
                        </ng-container>
                        <ng-container *ngIf="row?.title == 'Tutor Disponibili'">
                          {{rowTutorAvailable[i]?.entry | zeroCurrency}}
                        </ng-container>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-footer>
                      <ng-template ngx-datatable-footer-template >
                      </ng-template>
                    </ngx-datatable-footer>
                  </ngx-datatable>

                </div>

                <!-- export button -->
                <div class="row" [appCheckPermission]="'camp_calendar_export'">
                  <div class="pull-right">
                    <button [disabled]="getCampService().isOngoingRequest() || rows?.length <= 0" type="button" class="btn green" (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button>
                  </div>
                </div>

              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
        <!--end: prima riga -->
      </div>
    </div>
  </div>
  <!-- END CONTENT BODY -->

<!-- BEGIN: MODAL LOADING EXPORT-->
<div [config]="{ backdrop: 'static'}" bsModal #staticModal="bs-modal" class="modal fade"
tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->

<!-- BEGIN: MODAL CONTENT TUTORS -->
<div *ngIf="isTutorsModalShown" [config]="{ show: true }" (onHidden)="onTutorsHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" style="text-align: center;">Camp {{currentCamp.administrative_area_level_3_long_version}} {{currentCamp.camp_code}}</h1>
        <h2 class="modal-title" style="text-align: center;">Week {{currentWeekNum}} ({{currentWeekStart}} - {{currentWeekEnd}})</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideTutorsModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body form">
        <div class="row">
          <div class="col-md-12">
          	<app-camp-week-tutors [camp]="currentCamp" [weekIndex]="currentWeekIndex" [calendarWeekIndex]="currentCalendarWeekIndex" [allCamps]="allCamps" (closeEvent)="hideTutorsModal()"></app-camp-week-tutors>
		  </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL CONTENT TUTORS -->
