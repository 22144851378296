import { NotificationsService } from 'angular2-notifications';
import { HttpParams } from '@angular/common/http';
import { BackendService, VehicleService, HelperService } from './../../_services/';
import { VehicleSearchFormComponent } from '../vehicle-search-form/vehicle-search-form.component';
import { VehicleDatatableComponent } from '../vehicle-datatable/vehicle-datatable.component';
import { VehicleInfoSummaryComponent } from '../vehicle-info-summary/vehicle-info-summary.component';
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { parseISO } from 'date-fns';

//  salvataggio per excel
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { } from '../../_services';
import { VehicleCalendarComponent } from '../vehicle-calendar/vehicle-calendar.component';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-vehicle-search-results',
  templateUrl: './vehicle-search-results.component.html',
  styleUrls: ['./vehicle-search-results.component.css']
})
export class VehicleSearchResultsComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;

  @ViewChild(VehicleDatatableComponent, {static: true}) datatable: VehicleDatatableComponent;
  @ViewChild(VehicleCalendarComponent, {static: true}) calendar: VehicleCalendarComponent;
  @ViewChild(VehicleSearchFormComponent, {static: true}) searchForm: VehicleSearchFormComponent;
  @ViewChildren('vehicleInfoSummaryComponent') vehicleShow: VehicleInfoSummaryComponent;

  private subject: any;

  constructor(private route: ActivatedRoute, private router: Router, private backend: BackendService,
    private vehicleService: VehicleService, private notify: NotificationsService, private helper: HelperService) { }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {
      this.updateResults(params);
    });
  }

  ngOnDestroy() {
    this.subject.unsubscribe();
  }

  currentVehicleId: number;
  vehicleSelected(selected) {

    this.currentVehicleId = selected.vehicle_id;
    this.showModal();
  }

  assignementSelected(selected){
    // console.log(selected);
  }

  getAllResult() {
    this.datatable.query = "all";
    this.datatable.getResults();
    // this.calendar.query = "all";
    // this.calendar.getResults();
  }

  updateResults(params) {
    this.datatable.query = params;
    this.datatable.getResults();
    // this.calendar.query = params;
    // this.calendar.getResults();
  }

  searchKeywordsSubmit(params) {
    if (typeof params == "undefined" || Object.keys(params).length <= 0)
      this.getAllResult();
    else
      this.updateResults(params);
  }


  // MODAL
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }



  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }

  exportToExcel() {
    // console.log("aheeee già vuoi l'esportazione?");
  }

  /*  Salvo i dati nel formato Excel
  */
  //  exportToExcel() {

  //     if (this.datatable.pageTotalElements != null && typeof this.datatable.pageTotalElements != "undefined")
  //       if (this.datatable.pageTotalElements > this.vehicleService.MAX_NUM_OF_EXPORT) {
  //         this.notify.warn("Export", "Too many results, improve your search");
  //         return;
  //       }

  //     this.showModalExport();

  //     if (this.searchForm.model.advancedSearchOn == "false" || !this.searchForm.model.advancedSearchOn) {

  //       this.vehicleService.exportAll(this.searchForm.model, (res) => {
  //         this.exportParse(res, () => {
  //           this.hideModalExport();
  //         });
  //       }, () => {
  //         this.hideModalExport();
  //       });

  //     }
  //     else {

  //       this.personService.exportAllAdvanced(this.searchForm.model, (res) => {
  //         this.exportParse(res, () => {
  //           this.hideModalExport();
  //         })
  //       }, () => {
  //         this.hideModalExport();
  //       });
  //     }

  //   }

  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  // exportParse (data: any, callback: any) {

  //   var exportedArray = [];

  //   //  preparo i dati per il csv bonificandoli
  //   data.forEach( (element) => {

  //     //  bonifico
  //     Object.keys(element).forEach(key => {
  //       if (element[key] == null) element[key] = '';
  //     })

  //     if (typeof element.birthday != "undefined" && element.birthday != null && element.birthday.length > 0)
  //       element.data_parsed = parseISO(element.birthday).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
  //     else
  //       element.data_parsed = "";

  //     //  preparo
  //     exportedArray.push( {
  //       'Nome': element.first_name,
  //       'Cognome': element.surname,
  //       'Cellulare': element.mobile_contact,
  //       'Email': element.email_contact,
  //       'Indirizzo': element.route +" "+element.street_number,
  //       "Città": element.administrative_area_level_3_short_version,
  //       "Provincia": element.administrative_area_level_2_short_version,
  //       "Regione": element.administrative_area_level_1_long_version,
  //       "Codice Postale": element.postal_code,
  //       "Codice Fiscale": element.tax_id,
  //       "Data di Nascita": element.data_parsed,
  //       "Luogo di Nascita": element.birthplace,
  //       "Dati bancari": element.bank_account_iban
  //     })
  //   });

  //   //  creo io worksheet con i dati
  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

  //   //  personalizzo le colonne
  //   worksheet['!cols'] = [{width: 20}, {width:20}, {width:20}, {width:30}, {width:40}, {width:20}, {width:12}, {width:20}, {width:14}, {width:20}, {width:20}, {width:36}, {width:30}];

  //   //  creo il workbook con lo sheet attuale
  //   const workbook: XLSX.WorkBook = { Sheets: { 'People': worksheet}, SheetNames: ['People'] };

  //   //  scrivo il file
  //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

  //   //  Salvo il file tramite il browser
  //   FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "people.xlsx");

  //   callback();

  // }

}
