import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-theatrino-home',
  templateUrl: './theatrino-home.component.html',
  styleUrls: ['./theatrino-home.component.css']
})
export class TheatrinoHomeComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit() {

    this.titleService.setTitle("Theatrino > Tours");
  }

}
