import {Component, OnInit, ViewChild} from '@angular/core';
import {PersonService, UserService} from "../_services/index";
import {ActivatedRoute} from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.css']
})
export class PersonComponent implements OnInit {

  @ViewChild('personTabs', {static: true}) staticTabs: TabsetComponent;

  private subject: any;

  loadingData: boolean = true;

  holderName: string;

  showTutorTab: boolean = false;
  showAavailabilityTab: boolean = false;

  tutorRoleId: any = '9';

  constructor(private personService: PersonService, private route: ActivatedRoute, private userService: UserService) {}

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {

      this.personService.resetService();

      if (typeof params['idDefaultRole'] != "undefined") {

        this.personService.idDefaultRoleType = + params['idDefaultRole']; // (+) converts string 'id' to a number

        if (this.personService.idDefaultRoleType == this.tutorRoleId) {
          this.showTutorTab = true;
          this.showAavailabilityTab = this.showTutorTab;
        }

        this.loadingData = false;
        this.disableAllTab();

      } else if (typeof params['idCurrentPerson'] != "undefined") {

        this.personService.currentPersonId = + params['idCurrentPerson']; // (+) converts string 'id' to a number
        this.personService.getPersonInfoAll(() => {
          this.holderName = this.personService.currentPersonInfo["surname"] + " " + this.personService.currentPersonInfo["first_name"];

          this.showTutorTab = this.personService.currentPersonHasRole(this.tutorRoleId);
          this.showAavailabilityTab = this.showTutorTab;

          this.loadingData = false;
          this.activeAllTab();
          this.selectTab(0);
        }, () => {
          this.loadingData = false;
          this.disableAllTab();
        });
      }

    });
  }

  selectTab(num) {

    if (this.staticTabs.tabs[num])
      this.staticTabs.tabs[num].active = true;
  }

  activeAllTab() {

    this.staticTabs.tabs.forEach(element => {
      element.disabled = false;
    });
  }

  disableAllTab() {

    this.staticTabs.tabs.forEach(element => {
      element.disabled = true;
    });
  }


  getPersonService() {
    return this.personService
  }

  getUserService() {
    return this.userService
  }

}
