<button type="button" class="btn green btn-block btn-outline" (click)="getSignups();">Update List</button>

<div class="datatable-loader text-center" *ngIf="ongoingRequest">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>

<div *ngIf="!ongoingRequest">
<ngx-datatable
  class="striped"

  [rows]="rows"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [selected]="selected"
  [selectionType]="'single'"
  (activate)="onActivate($event)"
  (select)='onSelect($event)'
  [rowClass]="getRowClass"
>
  <ngx-datatable-column name="Nome" [flexGrow]="4" prop="person_info.surname" [sortable]="true" [resizeable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{row?.person_info?.surname}} {{row?.person_info?.first_name}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Età" [flexGrow]="1" prop="person_info.birthday" [sortable]="true" [resizeable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{calculateYear(row?.person_info?.birthday)}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Sesso" [flexGrow]="2" prop="person_info.gender" [sortable]="true" [resizeable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      {{getConfigService().getGenderPersonValueByKey(row?.person_info?.gender)}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="1" [resizeable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="row.registered == false"><i class="fa fa-exclamation-circle"></i></span>
      <span *ngIf="row.registered == true"><i class="fa fa-check-circle"></i></span>
      <span *ngIf="row.ignore_request == true"><i class="fa fa-eye-slash"></i></span>
      <span *ngIf="row?.payment_requests"><i class="fa fa-money" [ngClass]="(row.payment_requests.status == 'success') ? 'payed' : 'waiting'"></i></span>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
</div>

<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{selectedSignupInfo.person_info.first_name}} {{selectedSignupInfo.person_info.surname}} - iscritto {{ selectedSignupInfo.registration_date_parsed | date:'d MMMM, y':'':'it'}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-course-schedule-subscriber-form [modelFromSignup]="selectedSignupInfo" (formDelete)="formNewSubscriberDelete($event)" (formSaved)="formNewSubscriberSaved($event)"></app-course-schedule-subscriber-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>