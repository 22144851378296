<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span>Theatrino</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Theatrino Database
        <small></small>
      </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row" [appCheckPermission]="'theatrino_edit'">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">
            <!--begin: Portlet-Body -->
            <div class="portlet-body">

              <div class="grid grid-flex grid-responsive-md">
                <div class="row">
                  <div class="col col-md-2">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;"
                      [routerLink]="['/theatrino/new']">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Theatrino</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <!--end: inserimento show -->
                </div>
              </div>

              <!--end: Portlet-Body -->
            </div>

            <!--end: Portlet -->
          </div>

        </div>
      </div>



      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">
            <!--begin: Portlet-Body -->
            <div class="portlet-body">

              <div class="grid grid-flex grid-responsive-md">
                <!--begin: Form di ricerca gruppo-->
                <div class="row">
                  <div class="col col-md-12">
                    <app-theatrino-search-result></app-theatrino-search-result>
                  </div>
                  <!--end: Form di ricerca gruppo-->
                </div>
                <!--end: seconda riga-->
              </div>

              <!--end: Portlet-Body -->
            </div>

            <!--end: Portlet -->
          </div>

        </div>
      </div>
    </div>
    <!-- END CONTENT BODY -->
