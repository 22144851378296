<alert type="warning" *ngIf="loading">
  <strong>Loading</strong> ...
</alert>

<div [hidden]="loading">

  <form action="#" class="horizontal-form" novalidate>
    <div class="form-body">
      <div class="row">
        <div class="col-md-5">
          <div class="form-group read-only">
            <label class="control-label">Name</label>
            <p>{{vehicleInfo.vehicle_name}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Brand name</label>
            <p>{{vehicleInfo.brand_name}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Vehicle type</label>
            <p>{{getConfigService().getVehicleTypeValueByKey(vehicleInfo.vehicle_type)}}</p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Plate Number</label>
            <p>{{vehicleInfo.plate_number}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Model</label>
            <p>{{vehicleInfo.brand_model}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Color</label>
            <p>{{vehicleInfo.color}}</p>
          </div>
        </div>

        <div class="col-md-3 mt-element-card">
          <div class="mt-card-item">
            <div *ngIf="vehicleInfo.photoUrl" class="mt-overlay-1" aria-hidden="true">
              <img *ngIf="vehicleInfo.photoUrl" src="{{vehicleInfo.photoUrl}}?{{vehicleInfo.updated_at}}" class="img-responsive pic-bordered"
                alt="">
            </div>
            <div *ngIf="!vehicleInfo.photoUrl" class="mt-overlay-1" aria-hidden="true">
              <img src="../assets/placeholder/car-placeholder.jpg" width="100%">
            </div>
          </div>
        </div>

        <!-- <div *ngIf="vehicleInfo.annotation" class="col-md-9">
          <div class="form-group read-only">
            <label class="control-label">Notes</label>
            <div class="line-breaker">{{vehicleInfo.annotation}}</div>
          </div>
        </div> -->
      </div>

      <div class="row">
        <div class="col-md-4" *ngIf="vehicleInfo.contract_type">
          <div class="form-group read-only">
            <label class="control-label">Contract Type</label>
            <p>{{getConfigService().getVehicleContractTypeValueByKey(vehicleInfo.contract_type)}}</p>
          </div>
        </div>
      </div>

      <!-- <h4 class="form-section">Contacts</h4> -->

    </div>

    <div class="form-actions right">
      <button type="button" class="btn btn-outline red" (click)="close()">
        <i class="fa fa-times" aria-hidden="true"></i>&nbsp;Close</button>&nbsp;
      <a class="btn btn-outline blue" [appCheckPermission]="'vehicle_edit'" routerLink="/vehicle/edit/{{ currentId }}">
        <i class="fa fa-edit"></i>&nbsp;Edit
      </a>
    </div>

  </form>
</div>
