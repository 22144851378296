import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { parseISO } from 'date-fns';
import { HelperService } from './helper.service';

@Injectable()
export class PrimanotaService {

  ongoingRequest: boolean = false;

  private subjectToUpdate: Subject<any> = new Subject<any>();
  private subjectToUpdatePrimaNota: Subject<any> = new Subject<any>();

  constructor(private backend: BackendService, private notify: NotificationsService, private configService: ConfigService, private helperService: HelperService) { }

  getsubjectToUpdateObservable(): Observable<any> {
		return this.subjectToUpdate.asObservable();
  }

  getsubjectToUpdatePrimaNotaObservable(): Observable<any> {
		return this.subjectToUpdatePrimaNota.asObservable();
	}

  resetService() {

  }

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  isOngoingRequest() {
    return this.ongoingRequest;
  }

  searchPrimaNotaRow(params: HttpParams, model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.post('primanota/search', model, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdatePrimaNota.next(resJson);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  exportPrimaNotaRow(params: HttpParams, model: any[], callbackSuccess: any = null) {

    this.backend.post('primanota/search', model, params).subscribe(
      (response) => {

        let resJson : any = response;

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);
      }
    );
  }

  getPrimaNotaRow(primaNotaRowId: number, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('primanota/' + primaNotaRowId, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(resJson);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  savePrimaNotaRow(model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.post('primanota', model).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(resJson);

        this.notify.success("Add in/out", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  updatePrimaNotaRow(primaNotaRowId: number, model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.put('primanota/' + primaNotaRowId, model).subscribe(
      (response) => {

        this.notify.success("Add in/out", "successfully updated");

        this.subjectToUpdate.next(response);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      });
  }

  deletePrimaNotaRow(primaNotaRowId: number, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('primanota/' + primaNotaRowId).subscribe(
      (response) => {

        this.resetService();

        this.notify.success("Add in/out", "successfully deleted");

        this.subjectToUpdate.next(null);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  getPrimaNota(holderType: string, holderId: number, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    let prefixType = "";

    if (holderType.indexOf("Person") > 0)
      prefixType = "person/";
    else if (holderType.indexOf("Enterprise") > 0)
      prefixType = "enterprise/";
    else if (holderType.indexOf("ActorGroup") > 0)
      prefixType = "actorgroup/";
    else if (holderType.indexOf("Camp") > 0)
      prefixType = "camp/";
    else if (holderType.indexOf("CourseSchedule") > 0)
      prefixType = "courseschedule/";

    this.backend.get('primanota/' + prefixType + holderId, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdatePrimaNota.next(resJson);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }



  getTableData(table, holderId = null, holderType = null){

    for (let key in table){

      if (table[key].in_holder_type){
        table[key].in_holder_icon = this.helperService.getHolderIconFromType(table[key].in_holder_type);

        if (table[key].in_holder_data){
          table[key].in_holder_name = this.helperService.getHolderNameFromType(table[key].in_holder_type, table[key].in_holder_data);
          table[key].in_holder_firstname = this.helperService.getHolderFirstNameFromType(table[key].in_holder_type, table[key].in_holder_data);
          table[key].in_holder_lastname = this.helperService.getHolderLastNameFromType(table[key].in_holder_type, table[key].in_holder_data);
          table[key].in_holder_deleted = (table[key].in_holder_data.deleted_at != null);
        }
        else {
          table[key].in_holder_name = "- MISSING -";
          table[key].in_holder_deleted = true;
        }

      }

      if (table[key].out_holder_type){
        table[key].out_holder_icon = this.helperService.getHolderIconFromType(table[key].out_holder_type);

        if (table[key].out_holder_data){
          table[key].out_holder_name = this.helperService.getHolderNameFromType(table[key].out_holder_type, table[key].out_holder_data);
          table[key].out_holder_firstname = this.helperService.getHolderFirstNameFromType(table[key].out_holder_type, table[key].out_holder_data);
          table[key].out_holder_lastname = this.helperService.getHolderLastNameFromType(table[key].out_holder_type, table[key].out_holder_data);
          table[key].out_holder_deleted = (table[key].out_holder_data.deleted_at != null);
        }
        else {
          table[key].out_holder_name = "- MISSING -";
          table[key].out_holder_deleted = true;
        }
      }

      if (table[key].registered_via){
        table[key].registered_via_description = this.configService.getPrimanotaRegisteredViaSelect(table[key].registered_via);
      }

      if (table[key].causal){

        let typeCasual = "";

        // Se mi trovo all'interno di un'entità
        if (holderId != null){
          if (table[key].in_holder_id == holderId && table[key].in_holder_type == holderType){
            typeCasual = this.configService.switchCasualeTypeBasedOnHolderType(table[key].in_holder_type, "in");
          }
          else if (table[key].out_holder_id == holderId && table[key].out_holder_type == holderType){
            typeCasual = this.configService.switchCasualeTypeBasedOnHolderType(table[key].out_holder_type, "out");
          }
        }
        // altrimenti mi trovo nella pagina generale di ricerca cashflow e quindi non sono associato a nessuna entità
        else {
          if (table[key].in_holder_id != null){
            typeCasual = this.configService.switchCasualeTypeBasedOnHolderType(table[key].in_holder_type, "in");
          }
          else {
            typeCasual = this.configService.switchCasualeTypeBasedOnHolderType(table[key].out_holder_type, "out");
          }
        }

        if (typeCasual == "in"){
          table[key].causal_description = this.configService.getPrimanotaCausalInSelect(table[key].causal);
        }
        else if (typeCasual == "out"){
          table[key].causal_description = this.configService.getPrimanotaCausalOutSelect(table[key].causal);
        }

      }


      if (table[key].created_at){
        //TODO: @enrico -> controlla se ha senso o meno cambiare questa parte
        // table[key].created_at = table[key].created_at.replace(/-/g, '/');
        table[key].created_at_parsed = parseISO(table[key].created_at);
      }

      if (holderId != null){
        if (table[key].in_holder_id == holderId && table[key].in_holder_type == holderType){
          table[key].in_amount = parseFloat(table[key].amount);
        }
        else {
          table[key].out_amount = parseFloat(table[key].amount);
        }
      }
      else {
        if (table[key].in_holder_id != null){
          table[key].in_amount = parseFloat(table[key].amount);
        }
        else {
          table[key].out_amount = parseFloat(table[key].amount);
        }
      }
    }

    return table;
  }
}
