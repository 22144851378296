import { AbstractControl, ValidatorFn} from '@angular/forms';

export function NoSpacesValidator() : ValidatorFn {

  return (control: AbstractControl): { [key:string]: any} => {

    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : {'whitespace': 'there is a whitespace'}

  };
}
