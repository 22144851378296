import { NotificationsService } from 'angular2-notifications';
import { ActorGroupSearchFormComponent } from './../../actor-group/actor-group-search-form/actor-group-search-form.component';
import { ActorGroupDatatableComponent } from './../../actor-group/actor-group-datatable/actor-group-datatable.component';
import { EnterpriseSearchFormComponent } from './../../enterprise/enterprise-search-form/enterprise-search-form.component';
import { EnterpriseDatatableComponent } from './../../enterprise/enterprise-datatable/enterprise-datatable.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { IAngularMyDpOptions, IMyDateModel, IMyInputFieldChanged } from 'angular-mydatepicker';
import { HelperService, TheatrinoService } from './../../_services/index';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { format } from "date-fns";
import { parseISO } from 'date-fns';


@Component({
  selector: 'app-theatrino-accomodation-multi-form-modal',
  templateUrl: './theatrino-accomodation-multi-form-modal.component.html',
  styleUrls: ['./theatrino-accomodation-multi-form-modal.component.css'],
})
export class TheatrinoAccomodationMultiFormModalComponent implements OnInit {

  @ViewChild('modalEnterprise', {static: false}) public modalEnterprise: ModalDirective;
  @ViewChild('enterpriseDatatable', {static: false}) public enterpriseDatatable: EnterpriseDatatableComponent;
  @ViewChild('enterpriseSearchForm', {static: false}) public enterpriseSearchForm: EnterpriseSearchFormComponent;

  @ViewChild('modalGroup', {static: false}) public modalGroup: ModalDirective;
  @ViewChild('groupDatatable', {static: false}) public groupDatatable: ActorGroupDatatableComponent;
  @ViewChild('groupSearchForm', {static: false}) public groupSearchForm: ActorGroupSearchFormComponent;

  @ViewChild('modalAccomodation', {static: false}) public modalAccomodation: ModalDirective;

  @Input() data: any = null;
  model: any = {};

  public localDataStart: IMyDateModel;
  public localDataEnd: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  public datePickerOptionsBegin: IAngularMyDpOptions = {};
  public datePickerOptionsEnd: IAngularMyDpOptions = {};

  constructor(private helper: HelperService, private theatrinoService:TheatrinoService,
              private notify: NotificationsService) {

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;
  }


  ngOnInit() {
  }

  loadData() {

    // Se arriva una data dal calendario devo settare solo quella
    //    e preparare il component per salvare anche la nuova accomodation
    if (this.data.calendarDay)
    {
      var data = HelperService.parseDate(this.data.calendarDay);

      this.localDataStart = this.helper.initMyDataInputSingle(data);
      this.localDataEnd = this.helper.initMyDataInputSingle(data);

      //  Aggiungo la data anche al model
      this.model.date = format(data, "yyyy-MM-dd");

      //  Setto il tipo data
      this.model.type = 5;

      //  Setto le info del theatrino
      this.model.theatrino_id = this.theatrinoService.currentTheatrinoId;

      //  preparo le informazioni del model come da data normale
      this.model.time = "0";
      this.model.enterprise_id = 0;
      this.model.enterprise_organizer_id = 0;
      this.model.area = 0;
      this.model.status = 0;
      this.model.company = 0;
      this.model.admin_annotation = null;

      return;
    }

    //  Controllo se sono arrivati tutti i dati
    if (this.data)
    {

      //  Setto le info del theatrino
      this.model.theatrino_id = this.data.theatrino_id;

      //  Setto le info sulla data
      this.model.date = this.data.date;

      //  Setto anche le info del tour per la cancellazione
      this.model.tour_id = this.data.id;

      //  Trasformo le date in date date leggibili
      this.localDataStart = this.helper.initMyDataInputSingle(this.model.date);
      this.localDataEnd = this.helper.initMyDataInputSingle(this.model.date);

      //  Se ho una accomodation settata allora posso modificare direttamente questa
      if (this.data.accomodation)
      {
        this.model.id = this.data.accomodation.id;

        this.model.annotation = this.data.accomodation.annotation;
        this.model.actor_group_id = this.data.accomodation.actor_group_id;
        this.model.enterprise_id = this.data.accomodation.enterprise_id;
      }

    }

  }


  submit()
  {

    //  Salvo una nuova accomodation
    if (this.data.calendarDay)
    {
      this.submitNewAccomodation();
      return;
    }
    else
    {
      this.submitUpdateAccomodation();
      return;
    }

  }


  //  Salvo prima la data e poi l'accomodation
  submitNewAccomodation()
  {

    if (!this.helper.isMyDateValid(this.localDataStart)) return;

    if (!this.helper.isMyDateValid(this.localDataEnd)) return;

    if (!this.model.theatrino_id) return;

    if (!this.model.actor_group_id && !this.model.enterprise_id)
    {
      this.notify.error("You need to set an enterprise or an actor group");
      return;
    }

    //  Salvo prima la data normale e l'accomodation tutte insieme
    this.theatrinoService.saveAccomodation(this.model, () => {
      this.hideModal();
    })

  }

  //  Aggiorno o salvo l'accomodation
  submitUpdateAccomodation()
  {

    if (!this.helper.isMyDateValid(this.localDataStart)) return;

    if (!this.helper.isMyDateValid(this.localDataEnd)) return;

    if (!this.model.theatrino_id) return;

    if (!this.model.actor_group_id && !this.model.enterprise_id)
    {
      this.notify.error("You need to set an enterprise or an actor group");
      return;
    }

    if (this.model.id)
      this.theatrinoService.updateAccomodation(this.model, ()=> {
        this.hideModal();
      });
  }


  destroy()
  {
    this.theatrinoService.deleteAccomodation(this.model, ()=>{
      this.hideModal();
    });
  }


//  Mostra il component con le informazioni sulla data
  showAccomodationModal(currentDate: any)
  {

    //  Setto i dati che arrivano
    this.data = currentDate;

    this.isModalShown = true;
    this.showModal();

    //  Elaboro i dati e li mostro
    this.loadData();
  }


  isModalShown: boolean = false;
  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.modalAccomodation.hide();
  }

  onHidden(): void {
    this.isModalShown = false;

    this.data = null;
    this.model = {};
  }


// Info Date

  //  Start
  onDateStartChanged(event: IMyDateModel)
  {
    this.localDataStart = event;

    if (event.singleDate.jsDate != null)
    {
      this.model.start = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;

      this.datePickerOptionsEnd = datePickerOpt;
    }
    else
    {
      this.model.start = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }



  validateStartDate()
  {
    if (this.model.start)
    {
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      let dateS = parseISO(this.model.start);
      datePickerOpt.disableUntil = { year: dateS.getFullYear(), month: dateS.getMonth() + 1, day: dateS.getDate() + 1 }

      this.datePickerOptionsEnd = datePickerOpt;
    }

  }

  //  Fine
  onDateEndChanged(event: IMyDateModel)
  {
    this.localDataEnd = event;

    if (event.singleDate.jsDate != null)
    {
      this.model.end = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableSince = event.singleDate.date;

      this.datePickerOptionsBegin = datePickerOpt;
    }
    else
    {
      this.model.end = null;
      this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }
  }



  validateEndDate()
  {
    let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    let dateE = parseISO(this.model.end);
    datePickerOpt.disableSince = { year: dateE.getFullYear(), month: dateE.getMonth() + 1, day: dateE.getDate() + 1 }

    this.datePickerOptionsBegin = datePickerOpt;
  }




//  Modale Enterprise
  isModalEnterpriseShown: boolean = false;

  showModalEnterprise(): void {
    this.isModalEnterpriseShown = true;
  }

  hideModalEnterprise(): void {
    this.modalEnterprise.hide();
  }

  onHiddenEnterprise(): void {
    this.isModalEnterpriseShown = false;
  }

  searchKeywordsSubmitEnterprise(params) {
    this.enterpriseDatatable.query = params;
    this.enterpriseDatatable.getResults();
  }

  resultEnterpriseSelected (selected)
  {
    this.model.enterprise_id = selected.enterprise_id;
    this.hideModalEnterprise();
  }

  removeEnterprise()
  {
    this.model.enterprise_id = null;
  }



//  Modale gruppi
  isModalGroupShown: boolean = false;

    showModalGroups(): void {
      this.isModalGroupShown = true;
    }
    hideModalGroup(): void {
      this.modalGroup.hide();
    }
    onHiddenGroup(): void {
      this.isModalGroupShown = false;
    }
    searchKeyworkdsSubmitGroup(params) {

      if (typeof params == "undefined" || params == null)
        this.groupDatatable.query = "all";
      else
        this.groupDatatable.query = params;

      //  Prendo i risultati dalla tabella
      this.groupDatatable.getResult();
    }

    resultGroupSelected (selected)
    {
      this.model.actor_group_id = selected.actor_group_id;
      this.hideModalGroup();
    }

    removeGroupMember()
    {
      this.model.actor_group_id = null;
    }

    getTheatrinoService() {
      return this.theatrinoService
    }
    getHelper() {
      return this.helper
    }

}
