import { TheatrinoBookingInfoFormComponent } from './../theatrino/theatrino-booking-info-form/theatrino-booking-info-form.component';
import { Component, OnInit, ViewChild } from "@angular/core";
import { TheatrinoService, HelperService } from "../_services/index";
import { ActivatedRoute } from "@angular/router";
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Title }     from '@angular/platform-browser';
import { parseISO, parse } from 'date-fns';


@Component({
  selector: "app-tour",
  templateUrl: "./tour.component.html",
  styleUrls: ["./tour.component.css"]
})
export class TourComponent implements OnInit {

  private subject: any;

  loadingData: boolean = true;

  theatrinoInfo: any;
  tourInfo: any;

  @ViewChild('theatrinoBookingInfoForm', {static: true}) public theatrinoBookingInfoForm: TheatrinoBookingInfoFormComponent;
  @ViewChild('tourTabs', {static: true}) staticTabs: TabsetComponent;

  constructor(
    private theatrinoService: TheatrinoService,
    private route: ActivatedRoute,
    private helper: HelperService,
    private titleService: Title
  ) {}

  ngOnInit() {

    this.titleService.setTitle("Tour");

    this.subject = this.route.params.subscribe(params => {

      if (typeof params["date"] != "undefined")
        this.theatrinoBookingInfoForm.defaultDate = HelperService.parseDate(new Date(params["date"]));

      if (typeof params["booking"] != "undefined")
        this.theatrinoBookingInfoForm.defaultType = 2;

      if (typeof params['dayoff'] != "undefined")
        this.theatrinoBookingInfoForm.defaultType = 1;

      if (typeof params["swapday"] != "undefined")
        this.theatrinoBookingInfoForm.defaultType = 3;

      if (typeof params["general"] != "undefined")
        this.theatrinoBookingInfoForm.defaultType = 4;

      if (typeof params["idCurrentTheatrino"] != "undefined") {

        this.loadingData = true;

        this.disableAllTab();

        //  Se non ci sono i dati del theatrino devo scaricarli
        if (this.theatrinoService.currentTheatrinoInfo == null && typeof this.theatrinoService.currentTheatrinoInfo == "undefined")
        {
          this.downloadTheatrinoInfo(params);
          return;
        }

        //  Se sto modificando dati per un theatrino che già è stato scaricato allora non lo scarico nuovamente
        if (this.theatrinoService.currentTheatrinoInfo)
        if (this.theatrinoService.currentTheatrinoInfo.id == this.theatrinoService.currentTheatrinoId)
        {
          this.downloadTourInfo(params);
          return;
        }

        //  Altrimenti lo scarico per mantenere coerenza tra i dati
        this.downloadTheatrinoInfo(params);

      }
      else {
        this.loadingData = false;
      }
    });
  }



  //  Scarica le informazioni del theatrino
  downloadTheatrinoInfo(params)
  {
    this.theatrinoService.currentTheatrinoId = +params[
      "idCurrentTheatrino"
    ];

    this.theatrinoService.getTheatrinoTourInfoAll(() => {

      this.theatrinoInfo = this.theatrinoService.currentTheatrinoInfo;

      this.downloadTourInfo(params);
    });
  }

  //  Scarica le informazioni del tour
  downloadTourInfo (params)
  {

    if (typeof params["idCurrentTour"] != "undefined") {

      this.theatrinoService.currentTourId = +params["idCurrentTour"];
      this.theatrinoService.getTheatrinoTourDateInfo(
        () => {
          this.tourInfo = this.theatrinoService.currentTourInfo;
          this.tourInfo.date_formatted = parseISO(this.tourInfo.date).toLocaleDateString(this.helper.locale, { weekday:"short", year: "numeric", month: "short", day: "2-digit" });

          //  Abilito sempre il tab principale
          this.enableTab(0);
          this.enableTab(2);

          //  attivo i tab solo in base alla tipologia di data
          if (this.helper.isADateTourStandard(this.tourInfo.type))
            this.enableTab(1);

          //seleziono sempre il primo tab
          this.selectTab(0);

          this.loadingData = false;
        },
        () => {
          this.loadingData = false;
        }
      );
    }
    else
    {
      this.disableAllTab();
      this.loadingData = false;
    }
  }



  selectTab(num) {

    if (this.staticTabs.tabs[num])
      this.staticTabs.tabs[num].active = true;
  }

  activeAllTab(){

    this.staticTabs.tabs.forEach(element => {
      element.disabled = false;
    });
  }

  disableAllTab(){

    this.staticTabs.tabs.forEach(element => {
      element.disabled = true;
    });
  }

  enableTab(num: number)
  {
    if (this.staticTabs.tabs[num])
      this.staticTabs.tabs[num].disabled = false;
  }

  disableTab(num: number)
  {
    if (this.staticTabs.tabs[num])
      this.staticTabs.tabs[num].disabled = true;
  }

  getTheatrinoService() {
    return this.theatrinoService
  }

}
