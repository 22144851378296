<div class="row text-center calendar-header">
  <div class="col-md-4">
    <div class="btn-group">
      <button class="btn blue" (click)="decrement()" [disabled]="prevBtnDisabled">
        <i class="fa fa-arrow-left"></i>&nbsp;
      </button>
      <button class="btn blue" (click)="increment()" [disabled]="nextBtnDisabled">
        &nbsp;
        <i class="fa fa-arrow-right"></i>
      </button>
    </div>
    <button class="btn green-jungle" (click)="today()">
      <i class="fa fa-window-close-o"></i>&nbsp;Today
    </button>
  </div>
  <div class="col-md-4">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    <h4>{{ subTitle }}</h4>
  </div>
  <div class="col-md-4">
    <div class="btn-group">
      <div class="btn blue" (click)="changeView('month')" [class.active]="view === 'month'">
        <i class="fa fa-th"></i>&nbsp;&nbsp;Month</div>
      <div class="btn blue" (click)="changeView('week')" [class.active]="view === 'week'">
        <i class="fa fa-th-large"></i>&nbsp;&nbsp;Week</div>
      <div class="btn blue" (click)="changeView('day')" [class.active]="view === 'day'">
        <i class="fa fa-th-list"></i>&nbsp;&nbsp;Day</div>
    </div>
  </div>
</div>
<br>

<ng-template #customCellTemplate let-day="day" let-locale="locale" let-highlightDay="highlightDay"
  let-unhighlightDay="unhighlightDay" let-eventClicked="eventClicked">
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
  </div>
  <small *ngFor="let event of day.events" style="margin: 2px; padding: 1px; cursor: pointer;"
    [style.backgroundColor]="event.color.primary" [innerHTML]="event.meta.smallTitle" [ngClass]="event?.cssClass"
    (mouseenter)="highlightDay.emit({event: event})" (mouseleave)="unhighlightDay.emit({event: event})"
    (click)="$event.stopPropagation(); eventClicked.emit({event: event})"></small>
</ng-template>

<ng-template #customOpenDayEventsTemplate let-events="events" let-openDay="openDay" l et-eventClicked="eventClicked"
  let-isOpen="isOpen">
  <div class="cal-open-day-events" *ngIf="isOpen">
    <div *ngFor="let event of events" [ngClass]="event?.cssClass">
      <span class="cal-event" [style.backgroundColor]="event.color.primary"></span>
      <mwl-calendar-event-title [event]="event" view="month" (mwlClick)="handleEvent('Clicked', event)">
      </mwl-calendar-event-title>
      <mwl-calendar-event-actions [event]="event"></mwl-calendar-event-actions>
    </div>
  </div>
</ng-template>


<div [ngSwitch]="view">
  <mwl-calendar-month-view #calendarMothView *ngSwitchCase="'month'" [@.disabled]="true" [viewDate]="viewDate"
    [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" [cellTemplate]="customCellTemplate"
    [openDayEventsTemplate]="customOpenDayEventsTemplate" (dayClicked)="dayClicked($event.day)" [weekStartsOn]="1"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
    (beforeViewRender)="beforeMonthViewRender($event)">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
    (dayHeaderClicked)="dayClicked($event.day)">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
    [dayStartHour]="8" [dayEndHour]="19">
  </mwl-calendar-day-view>
</div>

<div class="row">
  <div class="col-md-12">
    <h4>Legend color:</h4>
  </div>
  <div *ngFor="let s of getConfigService().courseScheduleStatusSelect" class="col-md-3">
    <i class="fa fa-square" [style.color]="s.color.primary" aria-hidden="true"></i> {{s.value}}
  </div>
</div>
