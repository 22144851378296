// import { TheatrinoAccomodationMultiFormModalComponent } from './../theatrino-accomodation-multi-form-modal/theatrino-accomodation-multi-form-modal.component';
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService, HelperService, CourseScheduleService, BackendService, UserService } from './../../_services/index';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpParams } from '@angular/common/http';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay, CalendarMonthViewComponent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { parseISO, addDays, isSameDay, differenceInDays } from 'date-fns';

type CalendarPeriod = 'day' | 'week' | 'month';

@Component({
  selector: 'app-course-schedule-search-result-calendar',
  templateUrl: './course-schedule-search-result-calendar.component.html',
  styleUrls: ['./course-schedule-search-result-calendar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseScheduleSearchResultCalendarComponent implements OnInit {

  @Input() query: any;
  @Output() courseScheduleSelected = new EventEmitter();

  currentQuery: any;
  openDayDate: any;
  // @Input() theatrinoDayModal: TheatrinoTourBookingCalendarDayComponent;
  // @Input() accomodationModal: TheatrinoAccomodationMultiFormModalComponent;
  eventList: any;
  eventListKeys: any[];

  view: CalendarPeriod = 'month';
  viewDate: Date = new Date();
  subTitle: string = "";

  ongoingRequest: boolean = false;

  refresh: Subject<any> = new Subject();

  activeDayIsOpen: boolean = false;

  actions: CalendarEventAction[] = [
    // {
    //   label: '<i class="fa fa-pencil"></i>',
    //   onClick: ({ event }: { event: CalendarEvent }): void => {
    //     this.handleEvent('Edited', event);
    //   }
    // }
  ];

  // minDate: Date = new Date();

  // maxDate: Date = new Date();

  prevBtnDisabled: boolean = false;

  nextBtnDisabled: boolean = false;

  events: CalendarEvent[] = [];

  @ViewChild('calendarMothView', {static: true}) public calendarMonthView: CalendarMonthViewComponent;

  constructor(private courseScheduleService: CourseScheduleService, private backend: BackendService, private configService: ConfigService, private userService: UserService,
    private router: Router, private helper: HelperService) { }

  subscribe: any;

  ngOnInit() {

    if (this.userService.getUserPreferences("course_schedule_search_result_calendar_view") != null)
      this.view = this.userService.getUserPreferences("course_schedule_search_result_calendar_view");

    if (this.userService.getUserPreferences("course_schedule_search_result_calendar_viewDate") != null)
      this.viewDate = new Date(this.userService.getUserPreferences("course_schedule_search_result_calendar_viewDate"));

  }

  getResult() {
    if (typeof this.query == "undefined")
      return;

    if (this.currentQuery != this.query) {
      //  Salvo il valore della query attuale e resetto le variabili
      this.currentQuery = Object.assign(this.query);
    }

    this.eventList = {};
    this.eventListKeys = [];

    if (typeof this.currentQuery == "undefined" || Object.keys(this.currentQuery).length <= 0)
      return;

    let params = new HttpParams();
    // params = params.set('page', (this.pageNumber + 1).toString());

    //  Faccio sempre una search advanced per i corsi
    this.ongoingRequest = true;

    this.backend.post('courseschedule/search', this.currentQuery, params).subscribe(
      (response) => {

        let res : any = response;

        // faccio il parse delle info dei corsi
        res.forEach(element => {

          if (element.start)
            element.start_formatted = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.end)
            element.end_formatted = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        });

        this.updateEventArray(res);

        this.ongoingRequest = false;
      },
      (error) => {

        this.backend.showErrors(error);
        this.ongoingRequest = false;
      }
    );

  }

  ngOnDestroy() {
    // this.subscribe.unsubscribe();
  }

  updateEventArray(results) {

    // this.minDate = subDays(parseISO("2018/01/01"), 1);
    // this.maxDate = parseISO("2018/12/31");

    this.events = [];
    this.eventList = results;
    this.eventListKeys = Object.keys(results);

    this.eventListKeys.forEach(key => {

      let event = results[key];

      let startDate = parseISO(event.start + " " + event.start_time);
      let endDate = parseISO(event.start + " " + event.end_time);

      let howManyDays = differenceInDays(parseISO(event.end), parseISO(event.start)) + 1;

      for (let i = 0; i < howManyDays; i++) {

        let sDate = addDays(startDate, i);
        let eDate = addDays(endDate, i);

        this.events.push({
          start: sDate,
          end: eDate,
          title: this.getEventTitle(event),
          color: this.configService.getCourseScheduleStatusColorByKey(event.status),
          actions: this.actions,
          meta: {
            incrementsBadgeTotal: true,
            listKey: key,
            smallTitle: this.getEventSmallTitle(event, event.start_time, event.end_time)
          },
          cssClass: "cal-booking",
          allDay: false
        });
      }

      if (event.timesheet) {
        for (let j = 0; j < event.timesheet.length; j++) {
          let eventTimesheet = event.timesheet[j];

          let eventTimesheetStartDate = parseISO(eventTimesheet.date + " " + eventTimesheet.start_time);
          let eventTimesheetEndDate = parseISO(eventTimesheet.date + " " + eventTimesheet.end_time);

          this.events.push({
            start: eventTimesheetStartDate,
            end: eventTimesheetEndDate,
            title: this.getEventTitle(event),
            color: this.configService.getCourseScheduleStatusColorByKey(event.status),
            actions: this.actions,
            meta: {
              incrementsBadgeTotal: true,
              listKey: key,
              smallTitle: this.getEventSmallTitle(event, eventTimesheet.start_time, eventTimesheet.end_time)
            },
            cssClass: "cal-booking",
            allDay: false
          });
        }
      }

    });

    this.dateOrViewChanged();
    this.refresh.next();
  }

  getEventTitle(eventDate) {

    let t = "";

    t += eventDate.title;

    if (eventDate.administrative_area_level_3_long_version != null) {
      t += '&nbsp;&nbsp;<i class="fa fa-map-marker"></i> ' + eventDate.administrative_area_level_3_long_version
      if (eventDate.administrative_area_level_2_short_version != null) {
        t += '&nbsp;(' + eventDate.administrative_area_level_2_short_version + ')'
      }
    }

    if (eventDate.supervisor_surname != null) {
      t += '&nbsp;&nbsp;<i class="fa fa-user"></i> ' + eventDate.supervisor_first_name + ' ' + eventDate.supervisor_surname
    }

    if (eventDate.buyable_online) {
      t += '<br><i class="fa fa-toggle-on" style="color:green"></i>&nbsp;Acquistabile On-line&nbsp;';
    }
    else {
      t += '<br><i class="fa fa-toggle-on" style="color:red"></i>&nbsp;Acquistabile Off-line&nbsp;';
    }

    t += '<br><i class="fa fa-clock-o"></i>&nbsp;Corso dal&nbsp;';

    t += eventDate.start_formatted + '&nbsp;al&nbsp;'+eventDate.end_formatted + '&nbsp;&nbsp;';

    t += '<small>' + eventDate.start_time + '-' + eventDate.end_time + '</small>';


    if (eventDate.online) {
      t += '<br>Corso online';

      if (eventDate.online_description_url) {
        t += ':&nbsp;'+eventDate.online_description_url
      }
    }

    if (eventDate.timesheet && eventDate.timesheet.length > 0) {
      t += '<br>Date aggiuntive (Timesheet):&nbsp;';

      for (let j = 0; j < eventDate.timesheet.length; j++) {
        let eventTimesheet = eventDate.timesheet[j];
        let eventTimesheetStartDateFormatted = parseISO(eventTimesheet.date).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });;
        t += '&nbsp;'+eventTimesheetStartDateFormatted+'&nbsp;<i class="fa fa-clock-o"></i>&nbsp;<small>'+eventTimesheet.start_time+'-'+eventTimesheet.end_time+'</small>&nbsp;&nbsp;&nbsp;';
      }
    }

    t += '<br><br>';

    return t;
  }

  getEventSmallTitle(eventDate, start_time, end_time) {

    let t = "";

    if (eventDate.buyable_online) {
      t += '<i class="fa fa-toggle-on" style="color:green"></i> On&nbsp;';
    }
    else {
      t += '<i class="fa fa-toggle-on" style="color:red"></i> Off&nbsp;';
    }

    t += '<i class="fa fa-clock-o"></i>&nbsp;';

    t += '<b>' + start_time + '-' + end_time  + '</b>';
    t += '<br><i class="fa fa-university"></i>&nbsp;' + eventDate.title;

    if (eventDate.administrative_area_level_3_long_version != null) {
      t += '<br><i class="fa fa-map-marker"></i> ' + eventDate.administrative_area_level_3_long_version
      if (eventDate.administrative_area_level_2_short_version != null) {
        t += '&nbsp;(' + eventDate.administrative_area_level_2_short_version + ')'
      }
    }

    if (eventDate.supervisor_surname != null) {
      t += '<br><i class="fa fa-user"></i> ' + eventDate.supervisor_first_name + ' ' + eventDate.supervisor_surname
    }

    return t;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

    this.openDayDate = date;

    // if (isSameMonth(date, this.viewDate)) {
    if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
      this.viewDate = date;
    }

    if (events && events.length === 0) {
      this.activeDayIsOpen = true;
      //this.addNewTourDateFromOpenDay(date);
    }
    // }
    // else {
    //   this.activeDayIsOpen = false;
    // }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  increment(): void {
    this.changeDate(this.helper.addPeriod(this.view, this.viewDate, 1));
  }

  decrement(): void {
    this.changeDate(this.helper.subPeriod(this.view, this.viewDate, 1));
  }

  today(): void {
    this.changeDate(new Date());
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.activeDayIsOpen = false;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarPeriod): void {
    this.view = view;
    this.activeDayIsOpen = false;
    this.dateOrViewChanged();
  }

  dateIsValid(date: Date): boolean {
    return true;
    // return date >= this.minDate && date <= this.maxDate;
  }

  handleEvent(action: string, event: CalendarEvent): void {

    if (action == "Edited" || action == "Clicked") {

      this.courseScheduleSelected.emit(this.eventList[event.meta.listKey]);

      // this.theatrinoDayModal.openModalFullDay(parseISO(this.tourList[event.meta.listKey].date))

      //  Se è un evento accomodation devo mostrare la modale
      // if (this.tourList[event.meta.listKey].type == 5)
      //   this.accomodationModal.showAccomodationModal(this.tourList[event.meta.listKey]);
      // //  Altrimenti vado nella sezione di modifica normale di un evento
      // else{
      //   this.openModalFullDay(parseISO(this.tourList[event.meta.listKey].date))
      //   //this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/edit/" + this.tourList[event.meta.listKey].id]);
      // }
    }
  }


  dateOrViewChanged(): void {

    // this.prevBtnDisabled = !this.dateIsValid(
    //   this.helper.endOfPeriod(this.view, this.helper.subPeriod(this.view, this.viewDate, 1))
    // );

    // this.nextBtnDisabled = !this.dateIsValid(
    //   this.helper.startOfPeriod(this.view, this.helper.addPeriod(this.view, this.viewDate, 1))
    // );

    // if (this.viewDate < this.minDate) {
    //   this.changeDate(this.minDate);
    // } else if (this.viewDate > this.maxDate) {
    //   this.changeDate(this.maxDate);
    // }

    this.subTitle = this.helper.startOfPeriod(this.view, this.viewDate).toDateString() + " - " + this.helper.endOfPeriod(this.view, this.viewDate).toDateString();

    this.userService.saveUserPreferences("course_schedule_search_result_calendar_view", this.view);
    this.userService.saveUserPreferences("course_schedule_search_result_calendar_viewDate", this.viewDate.toDateString());
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      if (!this.dateIsValid(day.date)) {
        day.cssClass = 'cal-disabled';
      }

      if (day.events.length > 0) {

        // for (let i = 0; i < day.events.length; i++){
        //   if (typeof this.tourList[day.events[i].meta.listKey].type != "undefined")
        //   {
        //     if (this.tourList[day.events[i].meta.listKey].type == 1){
        //       day.cssClass = 'cal-dayoff';
        //     }

        //     if (this.tourList[day.events[i].meta.listKey].type == 3){
        //       day.cssClass = 'cal-swapday';
        //     }
        //   }
        // }
      }
    });
  }


  getConfigService() {
    return this.configService
  }


}
