import { ConfigService, WarehouseService, HelperService } from 'app/_services';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-warehouse-product-form',
  templateUrl: './warehouse-product-form.component.html',
  styleUrls: ['./warehouse-product-form.component.css']
})
export class WarehouseProductFormComponent implements OnInit {

  @Input() model: any = {};
  @Input() in: boolean = false;
  @Input() out: boolean = false;

  handling: boolean = false;

  constructor(private configService: ConfigService, private warehouseService: WarehouseService, private helper: HelperService){};
    
  ngOnInit() {    
    
    if (this.model == null) this.model = {
      tags : "",
      newquantity : 0
    };
    
    if (typeof this.model.tags == "undefined" || this.model.tags == null)
      this.model.tags = "";

    if (typeof this.model.newquantity == "undefined")
      this.model.newquantity = 0;

    //  provo sempre a convertire i tag nel formato corretto
    this.model.tags_formatted = this.helper.stringToArrayOfProductTags(this.model.tags, this.configService.warehouseProductTagsSelect);

    this.handling = true;

    //  assegno la tipologia di vista da far gestire al form
    // this.handling = (typeof this.model.id != "undefined" && (this.in || this.out));

    //  setto la diff se sto gestendo un inserimento
    // if (this.handling) this.model.diff = 0;
  }

  submit() {

    // console.log(this.model);
    // console.log(this.in);
    // console.log(this.out);

    if (typeof this.model.id != "undefined") {
      
      if (!this.quantityValid)
        return;

      //  carico e scarico dei prodotti
      if (this.in == true)
        this.model.quantity += this.model.newquantity;
      else if (this.out == true)
        this.model.quantity -= this.model.newquantity;

      this.warehouseService.updateProductInfo(this.model);
    }
    else {
      this.warehouseService.saveProductInfo(this.model);
    }
  }

  get quantityValid ()
  {
    if (this.out == true && this.model.newquantity > this.model.quantity)
      return false;
    
    return true;
  }
  tagChanged () {
    //  trasform tag products into string
    this.model.tags = this.helper.arrayOfProductTagsToString(this.model.tags_formatted);
  }

  destroyProduct () {
    
    this.warehouseService.deleteProductInfo(this.model);
  }

  getConfigService() {
    return this.configService;
  }

  getWarehouseService() {
    return this.warehouseService;
  }

}
