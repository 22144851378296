import { ConfigService } from './../../_services/config.service';
import { UserService, HelperService, BackendService } from 'app/_services';
import { CampService } from './../../_services/camp.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { parseISO, differenceInDays, max, min, endOfWeek, startOfWeek, format } from 'date-fns';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { IMyInputFieldChanged } from 'angular-mydatepicker';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpParams } from '@angular/common/http';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Route, Router } from '@angular/router';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-camp-person-transfer-details',
  templateUrl: './camp-person-transfer-details.component.html',
  styleUrls: ['./camp-person-transfer-details.component.css']
})
export class CampPersonTransferDetailsComponent implements OnInit {

  constructor(private campService: CampService, private userService: UserService, private router: Router,
    private configService: ConfigService, private helper: HelperService, private backend: BackendService) {

    this.configureArrayOfDates();

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;
  }

  @ViewChild('myTable', {static: false}) table: any;
  @ViewChild('staticModalFormTransferDetail', {static: false}) public staticModalFormTransferDetail: ModalDirective;

  //  model della ricerca
  model: any = {};

  // generic rows
  rows = [];

  // DATE PICKER

  localDateFrom: IMyDateModel;
  localDateTo: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  datePickerOptionsBegin: IAngularMyDpOptions = {};
  datePickerOptionsEnd: IAngularMyDpOptions = {};

  //  array of dates picker
  datesArray: any[];

  pageTotalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotalPages: number = 0;
  pageSort: any = null;

  ngOnInit() {

    if (this.campService.lastTransferDetailsSearchQueries != null)
      this.model = this.campService.lastTransferDetailsSearchQueries;
    else {
      this.clearSearch();
    }

    this.submitSearch();
  }

  submitSearch() {

    this.campService.lastTransferDetailsSearchQueries = this.model;
    this.userService.saveUserPreferences("camp_person_transfer_details_last_search_query", this.model);

    let params = new HttpParams();
    params = params.set('page', (this.pageNumber + 1).toString());

    if (this.pageSort != null)
    {
      params = params.set('sortDir', this.pageSort.dir);
      params = params.set('sortBy', this.pageSort.prop);
    }

    this.campService.getPersonCampTransferDetails(this.model, params, (results) => {

      this.pageNumber = results.current_page - 1;
      this.pageSize = results.per_page;
      this.pageTotalElements = results.total;
      this.pageTotalPages = results.last_page;
      let data = results.data;

      data.forEach(element => {

        // console.log(element);

        //  setto la data formattata
        // element.tour_date_prenotation_formatted = parseISO(element.tour_date_prenotation).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

        element.week_01_parsed = parseISO(element.week_01);
        element.week_02_parsed = parseISO(element.week_02);
        element.week_03_parsed = parseISO(element.week_03);
        element.week_04_parsed = parseISO(element.week_04);

        // if (element.camptransferdetail){

        //   element.camptransferdetail.forEach(t => {
        //     element.from_date_order_by = t.from_date;
        //     element.to_date_order_by = t.to_date;
        //   });

        // }
      });

      data.forEach(el1 => {

        let closestCampDiffDays = null;

        let current_min = null;
        if (el1.week_01 != null){
          if (current_min == null) current_min = el1.week_01_parsed;
          current_min = min([current_min, el1.week_01_parsed]);
        }

        if (el1.week_02 != null){
          if (current_min == null) current_min = el1.week_02_parsed;
          current_min = min([current_min, el1.week_02_parsed]);
        }

        if (el1.week_03 != null){
          if (current_min == null) current_min = el1.week_03_parsed;
          current_min = min([current_min, el1.week_03_parsed]);
        }

        if (el1.week_04 != null){
          if (current_min == null) current_min = el1.week_04_parsed;
          current_min = min([current_min, el1.week_04_parsed]);
        }

        if (el1.prev_camps){

          el1.prev_camps.forEach(el2 => {

            el2.week_01_parsed = parseISO(el2.week_01);
            el2.week_02_parsed = parseISO(el2.week_02);
            el2.week_03_parsed = parseISO(el2.week_03);
            el2.week_04_parsed = parseISO(el2.week_04);

            let prev_max = max([el2.week_01_parsed, el2.week_02_parsed, el2.week_03_parsed, el2.week_04_parsed]);

            let diff_days = differenceInDays(current_min, prev_max);

            if (diff_days > 0 && (diff_days < closestCampDiffDays || closestCampDiffDays == null)){
              closestCampDiffDays = diff_days;
              el1.from_camp_diff_days = diff_days;
              el1.from_camp = el2;
            }
          });
        }

      });

      this.rows = JSON.parse(JSON.stringify(data));
    })
  }


  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    this.exportParse(this.rows, () => {
      this.hideModalExport();
    });

  }

  setPage(pageInfo){
    this.pageNumber = pageInfo.offset;
    this.submitSearch();
  }

  onSort(sortInfo) {

    if (this.pageSort != null && sortInfo.sorts[0].prop == this.pageSort.prop){
      if (this.pageSort.dir == 'asc')
        sortInfo.sorts[0].dir = 'desc';
      else if (this.pageSort.dir == 'desc')
        sortInfo.sorts[0].dir = 'asc';
    }

    this.pageSort = sortInfo.sorts[0];

    this.submitSearch();
  }





  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse(data: any, callback: any) {

    // var exportedArray = [];

    // //  preparo i dati per il csv bonificandoli
    // data.forEach((element) => {

    //   //  bonifico
    //   Object.keys(element).forEach(key => {
    //     if (element[key] == null) element[key] = '';
    //   })

    //   let obj = {
    //     'Nome Show': element.show_denomination,
    //     'Tipo Show': this.configService.getShowTypeValueByKey(element.show_type),
    //     'Nome Tour': element.theatrino_denomination,
    //     'Data Tour': parseISO(element.tour_date_prenotation).toLocaleDateString(this.helper.locale),
    //     'Class level': element.tour_class_level,
    //     'Iscritti': parseInt(element.tour_subscribers) || 0,
    //     'Gratuità': parseInt(element.tour_gratuities) || 0,
    //     'Totale Studenti': parseInt(element.tour_total_students) || 0,
    //     'Total Cost': parseFloat(element.tour_date_cost) || 0,
    //     'Cost per show': parseFloat(element.tour_date_cost_per_show) || 0,
    //     'Payment type': this.configService.getPaymentTourValueByKey(element.tour_date_payment_type),
    //     'Area': this.configService.getAreaExpertiseValueByKey(element.tour_date_area),
    //     'Accountable': element.show_accountable_first_name + " " + element.show_accountable_surname,
    //     'Enterprise organizer': element.enterprise_organizer_denomination,
    //     'Enterprise organizer locality': element.enterprise_organizer_primary_address_locality,
    //     'Enterprise organizer region': element.enterprise_organizer_primary_address_administrative_area_level_1_long_version,
    //     'Enterprise organizer provincia': element.enterprise_organizer_primary_address_administrative_area_level_2_short_version,
    //   };

    //   //  preparo
    //   exportedArray.push(obj)

    // })

    // //  creo io worksheet con i dati
    // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    // //  personalizzo le colonne
    // worksheet['!cols'] = [{ width: 50 }, { width: 30 }, { width: 40 }, { width: 12 }, { width: 80 }, { width: 10 }, { width: 10 }, { width: 14 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }];

    // // //  personalizzo l'header
    // worksheet['!rows'] = [{ hpx: 30 }];

    // // //  creo il workbook con lo sheet attuale
    // const workbook: XLSX.WorkBook = { Sheets: { 'All Shows': worksheet }, SheetNames: ['All Shows'] };

    // // //  scrivo il file
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });

    // // //  Salvo il file tramite il browser
    // FileSaver.saveAs(new Blob([excelBuffer], { type: EXCEL_TYPE }), "allshows.xlsx");

    callback();

  }


  clearSearch() {
    this.model = {
      year: (new Date()).getFullYear(),
      // month: this.getConfigService().monthSelect()[(new Date()).getMonth()],
      from_date: format(startOfWeek(new Date()), 'yyyy/M/d'),
      to_date: format(endOfWeek(new Date()), 'yyyy/M/d'),
    };

    this.localDateFrom = this.helper.initMyDataInputSingle(startOfWeek(new Date()));
    this.localDateTo = this.helper.initMyDataInputSingle(endOfWeek(new Date()));
  }



  downloadPdfTemplate(model, name: string) {
    let params = new HttpParams();
    // this.backend.download('camp/' + camp_id + '/staff/' + staff_id + '/document/template/' + name, params, name + '-'+person_info.surname+'-'+person_info.first_name+'.pdf');
  }

  createTransferDetails(row){
    // console.log(row);
    this.router.navigate(['person/edit/' + row.person_id]);
    // this.showModalFormTransferDetail();
  }


  configureArrayOfDates() {
    this.datesArray = [];
    this.datesArray.push((new Date()).getFullYear());
  }

  //  Operazioni sulla data di start
  onDateFromChanged(event: IMyDateModel) {

    this.localDateFrom = event;

    if (event.singleDate.jsDate != null)
    {
      this.model['from_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;

      this.datePickerOptionsEnd = datePickerOpt;
    }
    else
    {
      this.model['from_date'] = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }
  }



  //  Operazioni sulla data di end
  onDateToChanged(event: IMyDateModel) {

    this.localDateTo = event;

    if (event.singleDate.jsDate != null)
    {
      this.model['to_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
        let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
        datePickerOpt.disableSince = event.singleDate.date;

        this.datePickerOptionsBegin = datePickerOpt;
      }
      else
      {
        this.model['to_date'] = null;
        this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      }

  }


  fieldChange(event) {

    // this.searchKeywordsSubmit.emit(this.model);
  }



  toggleExpandRow(row) {
    // console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    // console.log('Detail Toggled', event);
  }



  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.isModalExport = false;
    // this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }

  // MODAL FormTransferDetail
  isModalFormTransferDetail: boolean = false;

  showModalFormTransferDetail(): void {
    this.isModalFormTransferDetail = true;
  }

  hideModalFormTransferDetail(): void {
    this.staticModalFormTransferDetail.hide();
  }

  onHiddenFormTransferDetail(): void {
    this.isModalFormTransferDetail = false;
  }






  getCampService() {
    return this.campService
  }

  getUserService() {
    return this.userService
  }

  getConfigService() {
    return this.configService;
  }

  getHelper() {
    return this.helper;
  }

}
