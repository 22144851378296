import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { parseISO, isPast, isToday } from 'date-fns';
import { HelperService } from './helper.service';

@Injectable()
export class ReminderService {

  ongoingRequest: boolean = false;

  private subjectToUpdate: Subject<any> = new Subject<any>();
  private subjectToUpdateReminder: Subject<any> = new Subject<any>();
  private subjectToUpdateReminderCompleted: Subject<any> = new Subject<any>();

  constructor(private backend: BackendService, private notify: NotificationsService, private configService: ConfigService, private helperService: HelperService) { }

  getsubjectToUpdateObservable(): Observable<any> {
    return this.subjectToUpdate.asObservable();
  }

  getsubjectToUpdateReminderObservable(): Observable<any> {
    return this.subjectToUpdateReminder.asObservable();
  }

  getsubjectToUpdateReminderCompletedObservable(): Observable<any> {
    return this.subjectToUpdateReminderCompleted.asObservable();
  }

  resetService() {

  }

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  isOngoingRequest() {
    return this.ongoingRequest;
  }

  searchReminder(params: HttpParams, model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.post('reminder/search', model, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdateReminder.next(resJson);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  searchReminderCompleted(params: HttpParams, model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.post('reminder/search/completed', model, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdateReminderCompleted.next(resJson);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  exportReminder(params: HttpParams, model: any[], callbackSuccess: any = null) {

    this.backend.post('reminder/search', model, params).subscribe(
      (response) => {

        let resJson : any = response;

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);
      }
    );
  }

  getReminder(rowId: number, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('reminder/' + rowId, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(resJson);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  getReminderByTag(tag: string, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('reminder/search/tag/' + tag, params).subscribe(
      (response) => {

        let resJson : any = response;
        // this.subjectToUpdate.next(resJson);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  getRemindersForHolder(holderType: string, holderId: number, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    let prefixType = "";

    if (holderType.indexOf("Person") > 0)
      prefixType = "person/";
    else if (holderType.indexOf("Enterprise") > 0)
      prefixType = "enterprise/";
    else if (holderType.indexOf("ActorGroup") > 0)
      prefixType = "actorgroup/";
    else if (holderType.indexOf("TheatrinoTourDate") > 0)
      prefixType = "tourdate/";
    else if (holderType.indexOf("Camp") > 0)
      prefixType = "camp/";
    else if (holderType.indexOf("CourseSchedule") > 0)
      prefixType = "courseschedule/";
    else if (holderType.indexOf("Vehicle") > 0)
      prefixType = "vehicle/";

    this.backend.get('reminder/' + prefixType + holderId, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdateReminder.next(resJson);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  saveReminder(model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.post('reminder', model).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(resJson);

        this.notify.success("Reminder", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  updateReminder(rowId: number, model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.put('reminder/' + rowId, model).subscribe(
      (response) => {

        this.notify.success("Reminder", "successfully updated");

        this.subjectToUpdate.next(response);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      });
  }

  completeReminder(rowId: number, model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.put('reminder/' + rowId + "/complete", model).subscribe(
      (response) => {

        this.notify.success("Reminder", "marked as completed");

        this.subjectToUpdate.next(response);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      });
  }

  deleteReminder(rowId: number, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('reminder/' + rowId).subscribe(
      (response) => {

        this.resetService();

        this.notify.success("Reminder", "successfully deleted");

        this.finishRequest();

        this.subjectToUpdate.next(null);

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }

  undoReminderComplete(rowId: number, rowCompleteId: number, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('reminder/' + rowId + '/completed/' + rowCompleteId).subscribe(
      (response) => {

        this.resetService();

        this.notify.success("Reminder", "mark as not completed");

        this.finishRequest();

        this.subjectToUpdate.next(null);

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }






  getTableData(table, holderId = null, holderType = null) {

    for (let key in table) {

      if (table[key].holder_type) {
        table[key].holder_icon = this.helperService.getHolderIconFromType(table[key].holder_type);
        table[key].holder_url_path = this.helperService.getHolderUrlPathFromType(table[key].holder_type, table[key].holder_data);

        if (table[key].holder_data) {
          table[key].holder_name = this.helperService.getHolderNameFromType(table[key].holder_type, table[key].holder_data);
          table[key].holder_deleted = (table[key].holder_data.deleted_at != null);
        }
        else {
          table[key].holder_name = "- MISSING -";
          table[key].holder_deleted = true;
        }

      }

      if (table[key].created_at) {
        table[key].created_at_parsed = HelperService.parseDate(table[key].created_at);
      }

      if (table[key].start_date_reminder) {
        table[key].start_date_reminder_parsed = HelperService.parseDate(table[key].start_date_reminder);
      }

      if (table[key].notification_date_time) {
        table[key].notification_date_time_parsed = HelperService.parseDate(table[key].notification_date_time);
      }

      if (typeof table[key].completed[0] != "undefined") {
        table[key].status_icon_class = "fa-check-square-o font-green-jungle";
      }
      else {
        if (isPast(HelperService.parseDate(table[key].start_date_reminder)) && !isToday(HelperService.parseDate(table[key].start_date_reminder))) {
          table[key].status_icon_class = "fa-exclamation-triangle text-warning";
        }
        else {
          table[key].status_icon_class = "fa-square-o";
        }
      }



    }

    return table;
  }
}
