import { Component, OnInit, Input} from '@angular/core';
import { ConfigService, FacilitatorApplicationService } from 'app/_services';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-facilitators-applications-phase',
  templateUrl: './facilitators-applications-phase.component.html',
  styleUrls: ['./facilitators-applications-phase.component.css']
})
export class FacilitatorsApplicationsPhaseComponent implements OnInit {

  @Input('phase') phase: string;
  @Input('filter') filter: string = "";
  @Input('searchParams') search: any;

  ongoingRequest: boolean = false;
  subscribe: any;

  selected = [];

  facilitatorsCompleted: any[] = [];
  facilitators: any[] = [];
  facilitatorsRejected: any[] = [];
  facilitatorsImported: any[] = [];

  constructor(private facilitatorService: FacilitatorApplicationService, private configService: ConfigService) { }

  ngOnInit() {

    this.init();
    this.subscribe = this.facilitatorService.getsubjectToUpdateObservable().subscribe(() => {
      this.init();
    })
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  init()
  {
    if (typeof this.phase != "undefined" && this.phase != null)
    {
      this.facilitatorService.getFacilitatorApplicationPhase(this.phase, this.search, (response) => {

        this.updateTable(response);
      });
    }
  }

  updateTable (data)
  {
    data.forEach(facilitatorApplicationInfo => {

      if (facilitatorApplicationInfo.timezone){
        facilitatorApplicationInfo.timezone_formatted = facilitatorApplicationInfo.timezone + ' (GMT:' + moment.tz(facilitatorApplicationInfo.timezone).format('Z') + ')';

        if (facilitatorApplicationInfo.interview_time && facilitatorApplicationInfo.interview_date){
          let m = moment.tz(facilitatorApplicationInfo.interview_date + " "+facilitatorApplicationInfo.interview_time, facilitatorApplicationInfo.timezone);
          facilitatorApplicationInfo.italian_interview_time_formatted = m.tz("Europe/Rome");
        }
      }

      if (facilitatorApplicationInfo.preferred_interview_time) {
        facilitatorApplicationInfo.preferred_interview_time_formatted = [];
        JSON.parse(facilitatorApplicationInfo.preferred_interview_time).forEach(time => {
          if (time.checked == true)
            facilitatorApplicationInfo.preferred_interview_time_formatted.push(
              " • " + time.name
            );
        });
      }
    });

    this.facilitators = data.filter(x => x.rejected == false && x.status != "SUBMITTED" && x.status != "COMPLETED" && !x.imported == true);
    this.facilitatorsCompleted = data.filter(x => x.rejected == false && (x.status == "SUBMITTED" || x.status == "COMPLETED" ));
    this.facilitatorsRejected = data.filter(x => x.rejected == true);
    this.facilitatorsImported = data.filter(x => x.imported == true);

    // applico eventuali filtri
    if (this.filter == "selftape"){
      this.facilitators = this.facilitators.filter(x => x.selftape == true);
      this.facilitatorsCompleted = this.facilitatorsCompleted.filter(x => x.selftape == true);
      this.facilitatorsRejected = this.facilitatorsRejected.filter(x => x.selftape == true);
    }

    if (this.filter == "audition"){
      this.facilitators = this.facilitators.filter(x => x.audition == true);
      this.facilitatorsCompleted = this.facilitatorsCompleted.filter(x => x.audition == true);
      this.facilitatorsRejected = this.facilitatorsRejected.filter(x => x.audition == true);
    }
  }


  public getFacilitatorService() {
    return this.facilitatorService;
  }

  public getConfigService(){
    return this.configService
  }

}
