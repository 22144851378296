import { ConfigService, CampService, HelperService } from './../../_services/index';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IAngularMyDpOptions, IMyDateModel, IMyInputFieldChanged } from 'angular-mydatepicker';

@Component({
  selector: 'app-camp-search-form',
  templateUrl: './camp-search-form.component.html',
  styleUrls: ['./camp-search-form.component.css']
})
export class CampSearchFormComponent implements OnInit {

  model: any = {};

  private firstSubmit: boolean = false;
  private defaultSearchModel = null;
  public multipleSelect = [];

  private subject: any;

  public advancedSearchShow = false;

  @Output() searchKeywordsSubmit = new EventEmitter();

  @Input() ongoingRequest: boolean;

  constructor(private route: ActivatedRoute, private configService: ConfigService, private campService: CampService, private helper: HelperService) {

    this.configureArrayOfDates();

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;
  }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => { });

    this.defaultSearchModel = this.model;

    if (this.campService.lastSearchQueries != null) {
      this.model = this.campService.lastSearchQueries;
    }

    //  aggiungo all'array della multi selezione le info contenute nel model della ricerca
    this.createSelector();

  }

  ngOnDestroy() {
    this.subject.unsubscribe();
  }

  datesArray: any[];

  configureArrayOfDates() {
    this.datesArray = [];
    this.datesArray.push((new Date()).getFullYear());
  }


  fieldChange(event) {

    if (!this.firstSubmit)
      return;

    this.searchKeywordsSubmit.emit(this.model);
  }

  submitSearch() {

    //  memorizzo i parametri della ricerca attuale
    this.campService.lastSearchQueries = JSON.parse(JSON.stringify(this.model));

    //  Elimino le chiavi che non hanno valore prima di fare le ricerche
    Object.keys(this.model).forEach(element => {
      if (!this.model[element])
        delete this.model[element];
    });

    if (this.model.status != null && typeof this.model.status != "undefined") {
      this.model.status = [];
      this.multipleSelect.forEach(elementStatus => {
        if (elementStatus.value == true)
          this.model.status.push(elementStatus.key);
      })
    }

    this.searchKeywordsSubmit.emit(this.model);
  }

  //  crea il multiselettore in base al model
  public createSelector() {

    this.multipleSelect = [
      { key: "1", value: false }, { key: "2", value: false }, { key: "3", value: false }, { key: "4", value: false }, { key: "5", value: false }, { key: "6", value: false }
    ];

    if (this.model.status != null && typeof this.model.status != "undefined") {
      this.model.status.forEach(elementStatus => {
        (this.multipleSelect.find(x => x.key == elementStatus)).value = true;
      });
    }
  }

  //  selezionato il valore attuale dai filtri della select
  public selectedItem(value: any): void {

    if (typeof this.model.status == "undefined")
      this.model.status = [];

    this.model.status.push(value.id);
  }

  //  rimuovo il valore attuale dai filtri della select
  public removedItem(value: any): void {

    var index = this.model.status.findIndex(x => x == value.id);

    if (index >= 0)
      this.model.status.splice(index, 1);
  }


  // DATE PICKER
  localDateFrom: IMyDateModel;
  localDateTo: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  datePickerOptionsBegin: IAngularMyDpOptions = {};
  datePickerOptionsEnd: IAngularMyDpOptions = {};

  //  Operazioni sulla data di start
  onDateFromChanged(event: IMyDateModel) {
    this.localDateFrom = event;

    if (event.singleDate.jsDate != null) {
      this.model['from_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;

      this.datePickerOptionsEnd = datePickerOpt;
    }
    else {
      this.model['from_date'] = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }
  }

  //  Operazioni sulla data di end
  onDateToChanged(event: IMyDateModel) {
    this.localDateTo = event;

    if (event.singleDate.jsDate != null) {
      this.model['to_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableSince = event.singleDate.date;

      this.datePickerOptionsBegin = datePickerOpt;
    }
    else {
      this.model['to_date'] = null;
      this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }



  hideAdvancedSearch() {
    this.advancedSearchShow = false;
  }

  clearSearch() {
    this.model = { year: new Date().getFullYear(), status: ["1", "3", "4", "5", "6"] };
    this.createSelector();

    this.campService.lastSearchQueries = this.model;
    this.hideAdvancedSearch();
  }

  clearAdvancedSearch() {

    this.clearSearch();
  }

  showAdvancedSearch() {
    this.advancedSearchShow = true;
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper;
  }

}
