import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ConfigService } from './../../_services/config.service';
import { HelperService } from './../../_services/helper.service';
import { CourseScheduleService } from './../../_services/course-schedule.service';
import { UserService } from './../../_services/user.service';
import { IAngularMyDpOptions, IMyDateModel, IMyInputFieldChanged } from 'angular-mydatepicker';
import { NgForm } from '@angular/forms';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-course-schedule-quote',
  templateUrl: './course-schedule-quote.component.html',
  styleUrls: ['./course-schedule-quote.component.css']
})
export class CourseScheduleQuoteComponent implements OnInit {

  model: any = null;

  @ViewChild('f', {static: true}) currentForm: NgForm;

  private localData: IMyDateModel;
  private datePickerOptions: IAngularMyDpOptions = {};


  editMode: boolean = false;
  subscribe: any;


  constructor(private configService: ConfigService, private helper: HelperService,
              private courseScheduleService: CourseScheduleService, private userService: UserService) {


      //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    //  La prima volta che entro controllo che non sia nullo il model
    if (this.model == null)
    {
        this.model = {};
        this.edit();
    }

    this.subscribe = this.courseScheduleService.getsubjectToUpdateObservable().subscribe((update:boolean) => {

      this.getQuoteInfo();
    });

    if (this.courseScheduleService.currentCourseScheduleInfo != null)
      this.getQuoteInfo();
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  ngOnChanges() {

    if (!this.model || !this.model.id)
    {
      this.model = {};
      this.edit();
    }

    if (typeof this.model.id != "undefined")
    {

      //  trasformo la data del reminder in una data leggibile
      if (this.model.quote_discount_early_bird_date)
        this.localData = this.helper.initMyDataInputSingle(this.model.quote_discount_early_bird_date);

    }

  }

  getQuoteInfo () {

    this.model = JSON.parse(JSON.stringify(this.courseScheduleService.currentCourseScheduleInfo));

      if (this.model == null)
      {
        this.model = {};
        this.edit();
      }
      else
      {

        if (this.model.quote_discount_early_bird_date)
          this.localData = this.helper.initMyDataInputSingle(this.model.quote_discount_early_bird_date);

        this.editMode = false;
      }

  }

  submit()
  {

    if (!this.helper.isMyDateValid(this.localData,false)) return;

    if (!this.currentForm.form.valid) return;

    if (!this.currentForm.form.dirty) {
      this.editMode = false;
      return;
    }

    if (this.model.id)
      this.courseScheduleService.updateCourseScheduleInfo(this.model, () => {this.editMode = false; this.courseScheduleService.getCourseScheduleInfoAll();});
    else
      this.courseScheduleService.saveCourseScheduleInfo(this.model, () => {this.editMode = false;});
  }

  edit() {

    if (!this.userService.checkPermission('course_schedule_edit_quote'))
      return;

    this.editMode = true;
  }

  //  Date picker
  onDateChanged(event: IMyDateModel)
  {
    this.localData = event;

    if (event.singleDate.jsDate != null)
      this.model.quote_discount_early_bird_date = this.helper.formatISODate(event);
    else
      this.model.quote_discount_early_bird_date = null;

    this.currentForm.form.markAsDirty();
}



  isOngoingRequest() {
    return this.courseScheduleService.isOngoingRequest()
  }

  getLocalData() {
    return this.localData
  }

  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }
}
