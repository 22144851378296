import { ConfigService } from './../../_services/config.service';
import { UserService, HelperService } from 'app/_services';
import { TheatrinoService } from './../../_services/theatrino.service';
import { Component, OnInit } from '@angular/core';
import { parseISO } from 'date-fns';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-theatrino-reports-show',
  templateUrl: './theatrino-reports-show.component.html',
  styleUrls: ['./theatrino-reports-show.component.css']
})
export class TheatrinoReportsShowComponent implements OnInit {

  constructor(private theatrinoService: TheatrinoService, private userService: UserService,
    private configService: ConfigService, private helper: HelperService) {

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;
  }

  //  model della ricerca
  model: any = {};

  // generic rows
  rows = [];

  // DATE PICKER
  localDateFrom: IMyDateModel;
  localDateTo: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  datePickerOptionsBegin: IAngularMyDpOptions = {};
  datePickerOptionsEnd: IAngularMyDpOptions = {};

  ngOnInit() {

    this.submitSearch();
  }

  submitSearch() {

    this.theatrinoService.getReportShows(this.model, (results) => {

      results.forEach(element => {

        //  sommo i partecipanti
        element.tour_total_students = (parseInt(element.tour_subscribers) || 0) + (parseInt(element.tour_gratuities) || 0);

        //  setto la data formattata
        element.tour_date_prenotation_formatted = parseISO(element.tour_date_prenotation).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

        let count_shows = 0;

        results.forEach(element2 => {
          if (element.tour_date_id == element2.tour_date_id) {
            count_shows++;
          }
        });

        element.count_shows_per_date = count_shows;

        if (!element.tour_date_cost)
          element.tour_date_cost = 0;

        if (parseFloat(element.tour_date_cost) > 0 && count_shows > 0)
          element.tour_date_cost_per_show = (parseFloat(element.tour_date_cost) / count_shows).toFixed(2);
        else
          element.tour_date_cost_per_show = 0;

      });

      this.rows = JSON.parse(JSON.stringify(results));

    })
  }


  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    this.exportParse(this.rows, () => {
      this.hideModalExport();
    });

  }



  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse(data: any, callback: any) {

    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach((element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      let obj = {
        'Nome Show': element.show_denomination,
        'Tipo Show': this.configService.getShowTypeValueByKey(element.show_type),
        'Nome Tour': element.theatrino_denomination,
        'Data Tour': parseISO(element.tour_date_prenotation).toLocaleDateString(this.helper.locale),
        'Class level': element.tour_class_level,
        'Iscritti': parseInt(element.tour_subscribers) || 0,
        'Gratuità': parseInt(element.tour_gratuities) || 0,
        'Totale Studenti': parseInt(element.tour_total_students) || 0,
        'Total Cost': parseFloat(element.tour_date_cost) || 0,
        'Cost per show': parseFloat(element.tour_date_cost_per_show) || 0,
        'Payment type': this.configService.getPaymentTourValueByKey(element.tour_date_payment_type),
        'Area': this.configService.getAreaExpertiseValueByKey(element.tour_date_area),
        'Accountable': element.show_accountable_first_name + " " + element.show_accountable_surname,
        'Enterprise organizer': element.enterprise_organizer_denomination,
        'Enterprise organizer locality': element.enterprise_organizer_primary_address_locality,
        'Enterprise organizer region': element.enterprise_organizer_primary_address_administrative_area_level_1_long_version,
        'Enterprise organizer provincia': element.enterprise_organizer_primary_address_administrative_area_level_2_short_version,
        'Enterprise organizer address': element.enterprise_organizer_primary_address_route + ", "+element.enterprise_organizer_primary_address_street_number,
        'Enterprise organizer CAP': element.enterprise_organizer_primary_address_postal_code,
        'Enterprise organizer country': element.enterprise_organizer_primary_address_country_long_version,
        'Enterprise': element.enterprise_main_denomination,
        'Enterprise locality': element.enterprise_main_primary_address_locality,
        'Enterprise region': element.enterprise_main_primary_address_administrative_area_level_1_long_version,
        'Enterprise provincia': element.enterprise_main_primary_address_administrative_area_level_2_short_version,
        'Enterprise address': element.enterprise_main_primary_address_route + ", "+element.enterprise_main_primary_address_street_number,
        'Enterprise CAP': element.enterprise_main_primary_address_postal_code,
        'Enterprise country': element.enterprise_main_primary_address_country_long_version,
      };

      //  preparo
      exportedArray.push(obj)

    })

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{ width: 50 }, { width: 30 }, { width: 40 }, { width: 12 }, { width: 30 }, { width: 10 }, { width: 10 }, { width: 14 }, { width: 20 }, { width: 20 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 50 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }];

    // //  personalizzo l'header
    worksheet['!rows'] = [{ hpx: 30 }];

    // //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'All Shows': worksheet }, SheetNames: ['All Shows'] };

    // //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });

    // //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], { type: EXCEL_TYPE }), "allshows.xlsx");

    callback();

  }


  clearSearch() {
    this.model = {};
  }

  //  Operazioni sulla data di start
  onDateFromChanged(event: IMyDateModel) {

    this.localDateFrom = event;

    if (event.singleDate.jsDate != null) {
      this.model['from_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;

      this.datePickerOptionsEnd = datePickerOpt;
    }
    else {
      this.model['from_date'] = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }




  //  Operazioni sulla data di end
  onDateToChanged(event: IMyDateModel) {

    this.localDateTo = event;

    if (event.singleDate.jsDate != null) {
      this.model['to_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableSince = event.singleDate.date;

      this.datePickerOptionsBegin = datePickerOpt;
    }
    else {
      this.model['to_date'] = null;
      this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }



  fieldChange(event) {

    // this.searchKeywordsSubmit.emit(this.model);
  }







  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.isModalExport = false;
    // this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }






  getTheatrinoService() {
    return this.theatrinoService
  }

  getUserService() {
    return this.userService
  }

  getConfigService() {
    return this.configService;
  }

  getHelper() {
    return this.helper;
  }

}
