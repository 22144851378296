
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #modalDay="bs-modal" class="modal fade"
tabindex="-1" role="dialog" aria-hidden="true">
<div class="modal-dialog modal-lg" #modalDayRef>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ modal.selectedDate | date:'fullDate':'':'it'}}
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body form">
      <div class="row" *ngIf="modal.loading">
        <div class="col-md-12">
          <alert type="warning">
            <strong>Loading</strong> ...
          </alert>
        </div>
      </div>

      <!-- begin: prima riga -->
      <span *ngIf="!modal.loading">
        <div class="row">
          <div class="col-md-12">
            <span *ngFor="let event of modal.events">
              <div class="portlet light bordered" [ngStyle]="getBackgroundColorByEvent(event)">
                <div class="portlet-body form" *ngIf="event.type == 2">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="control-label">
                          <i class="fa fa-square" [style.color]="getConfigService().getTheatrinoStatusColorByKey(event.status).primary"
                            aria-hidden="true"></i> {{getConfigService().getTheatrinoStatusValueByKey(event.status)}}</label>
                        <p>
                          <b>{{event.city}}</b> ({{event.prov}})</p>
                      </div>
                      <div class="form-group">
                        <label class="control-label">Enterprise</label>
                        <p><app-enterprise-info-show [idEnterprise]="event.enterprise_id"></app-enterprise-info-show></p>
                      </div>
                      <div class="form-group">
                        <label class="control-label">Organizer</label>
                        <p><app-enterprise-info-show [idEnterprise]="event.enterprise_organizer_id"></app-enterprise-info-show></p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">
                          <label class="control-label">Project/Show Type</label>
                          <p>{{ getConfigService().getShowTypeValueByKey(event.project_type) }}</p>
                      </div>
                      <div class="form-group">
                        <label class="control-label">
                          <i class="fa fa-clock-o"></i>&nbsp;{{event.time}}
                        </label>
                        <p><b>Annotation: </b> {{event.annotation}}</p>
                        <p><b>Admin Annotation: </b> {{event.admin_annotation}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                      <div class="col-md-4" *ngFor="let show of event.shows">
                        <div class="form-group">
                          <label class="control-label">Accountable</label>
                          <p><app-person-info-show [idPerson]="show.accountable_id"></app-person-info-show></p>
                        </div>
                      </div>
                    </div>

                  <div class="form-actions right" [appCheckPermission]="'theatrino_edit'">
                    <a class="btn btn-outline blue" routerLink="/theatrino/{{getTheatrinoService().currentTheatrinoId}}/tour/edit/{{ event.id }}" [queryParams]="{ editMode:'true'}">
                      <i class="fa fa-edit"></i>&nbsp;Edit
                    </a>
                  </div>

                </div>


                <div class="portlet-body form" *ngIf="event.type == 5">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="control-label">
                          <i class="fa fa-users" aria-hidden="true"></i> Group</label>
                        <p>{{event.accomodation?.group_name}}</p>
                      </div>
                      <div class="form-group">
                        <label class="control-label">
                          <i class="fa fa-bed" aria-hidden="true"></i> Accomodation</label>
                        <p>{{event.accomodation?.denomination}}</p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">
                        <label class="control-label">Annotation</label>
                        <p>{{event.accomodation?.annotation}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="form-actions right" [appCheckPermission]="'theatrino_edit'">
                    <a class="btn btn-outline blue" (click)="hideModal(); accomodationModal.showAccomodationModal(event);">
                      <i class="fa fa-edit"></i>&nbsp;Edit
                    </a>
                  </div>

                </div>



                <div class="portlet-body form" *ngIf="event.type == 1">
                  <div class="row">
                    <div class="col-md-12">
                      <h3>
                        <i class="fa fa-power-off" aria-hidden="true"></i> Day Off</h3>
                    </div>
                  </div>

                  <div class="form-actions right" [appCheckPermission]="'theatrino_edit'">
                    <a class="btn btn-outline blue" routerLink="/theatrino/{{getTheatrinoService().currentTheatrinoId}}/tour/edit/{{ event.id }}">
                      <i class="fa fa-edit"></i>&nbsp;Edit
                    </a>
                  </div>
                </div>



                <div class="portlet-body form" *ngIf="event.type == 3">
                  <div class="row">
                    <div class="col-md-12">
                      <h3>
                        <i class="fa fa-retweet" aria-hidden="true"></i> Swap Day</h3>
                    </div>
                  </div>

                  <div class="form-actions right" [appCheckPermission]="'theatrino_edit'">
                    <a class="btn btn-outline blue" routerLink="/theatrino/{{getTheatrinoService().currentTheatrinoId}}/tour/edit/{{ event.id }}">
                      <i class="fa fa-edit"></i>&nbsp;Edit
                    </a>
                  </div>
                </div>


                <div class="portlet-body form" *ngIf="event.type == 4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label"> Admin Note</label>
                        <p>{{event.admin_annotation}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="form-actions right" [appCheckPermission]="'theatrino_edit'">
                    <a class="btn btn-outline blue" routerLink="/theatrino/{{getTheatrinoService().currentTheatrinoId}}/tour/edit/{{ event.id }}">
                      <i class="fa fa-edit"></i>&nbsp;Edit
                    </a>
                  </div>
                </div>

              </div>
            </span>
          </div>
        </div>
      </span>


      <div [appCheckPermission]="'theatrino_edit'" *ngIf="!modal.loading" class="form-actions right">
        <button type="button" *ngIf="!hideModalBookingButton" class="btn blue btn-outline" (click)="addNewTourDateFromOpenDay();">
          <i class="fa fa-plus"></i>&nbsp; booking
        </button>
        <button style="margin-left: 15px;" type="button" class="btn green btn-outline" (click)="addNewGeneralFromOpenDay();">
          <i class="fa fa-plus"></i>&nbsp; Admin note
        </button>
        <button  style="margin-left: 15px;" type="button" *ngIf="!hideModalAccomodationButton" class="btn blue btn-outline" (click)="addNewAccomodationFromOpenDay();">
          <i class="fa fa-plus"></i>&nbsp; Group or Accomodation
        </button>
        <button  style="margin-left: 15px;" type="button" *ngIf="!hideModalDayOffButton" class="btn red btn-outline" (click)="addNewDayOffFromOpenDay();">
          <i class="fa fa-plus"></i>&nbsp; Day Off
        </button>
        <button  style="margin-left: 15px;" type="button" *ngIf="!hideModalSwapDayButton" class="btn red btn-outline" (click)="addNewSwapDayFromOpenDay();">
          <i class="fa fa-plus"></i>&nbsp; Swap Day
        </button>
        <button style="margin-left: 30px;" type="button" class="btn btn-outline red" (click)="hideModal()">
          <i class="fa fa-times" aria-hidden="true"></i>&nbsp;Close popup
        </button>
      </div>

    </div>
  </div>
</div>
</div>
