<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 class="form-section">Address</h3>

    <div class="row">
      <div class="col-md-6">
        <div [hidden]="!editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
          <label class="control-label">Type <span class="required">*</span></label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
            <option *ngFor="let a of getConfigService().addressEnterpriseSelect" [value]="a.key">{{a.value}}</option>
          </select>
          <span class="help-block">Select address type, is required</span>
        </div>
        <div *ngIf="!editMode && model.type" class="form-group editMode">
          <label class="control-label">Type</label>
          <p>{{getConfigService().getAddressEnterpriseValueByKey(model.type)}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group">
          <label>Primary</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="primary" [(ngModel)]="model.primary" value="1" (ngModelChange)="valueChange($event)"> Yes, show this data in the search results and in the summary screen of this entity
              <span></span>
            </label>
          </div>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Primary</label>
          <p *ngIf="model.primary">Yes, show this data in the search results and in the summary screen of this person</p>
          <p *ngIf="!model.primary">No, hide this data in the search results and in the summary screen of this person</p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="type.valid && editMode">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-error': ((f.submitted || addressInput.dirty || addressInput.touched) && (!addressCheckInput.valid)) }">
          <label class="control-label">Address <span class="required">*</span></label>
          <input [disabled]="getEnterpriseService().isOngoingRequest()" type="text" class="form-control" placeholder="" name="address"
            [(ngModel)]="address" #addressInput (focus)="setupGooglePlace()"/>
          <input type="hidden" class="form-control" placeholder="" name="addressCheck" [(ngModel)]="addressCheck" #addressCheckInput="ngModel"
            required/>
          <span class="help-block">Start to write the address and select it from the list, powered by Google Place</span>
        </div>
      </div>
    </div>

    <!-- BEGIN: Sezione per inserimento manuale di un indirizzo -->
    <div *ngIf="!searchOk && editMode">
      <alert type="warning">
        <strong>La ricerca con Google Place non ha restituito tutti i dati richiesti, è necessario inserirli tutti</strong> ...
      </alert>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group" [ngClass]="{ 'has-error': ((f.submitted || admin_level_3_long.dirty || admin_level_3_long.touched) && !admin_level_3_long.valid) }">
            <label class="control-label">Comune <span class="required">*</span></label>
            <input class="form-control" placeholder="" name="admin_level_3_long" [(ngModel)]="model.administrative_area_level_3_long_version" #admin_level_3_long="ngModel" required/>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group" [ngClass]="{ 'has-error': ((f.submitted || admin_level_2_long.dirty || admin_level_2_long.touched) && !admin_level_2_long.valid) }">
            <label class="control-label">Provincia/Contea/Distretto <span class="required">*</span></label>
            <input class="form-control" placeholder="" name="admin_level_2_long" [(ngModel)]="model.administrative_area_level_2_long_version" #admin_level_2_long="ngModel" required/>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group" [ngClass]="{ 'has-error': ((f.submitted || admin_level_1_long.dirty || admin_level_1_long.touched) && !admin_level_1_long.valid) }">
            <label class="control-label">Regione/Stato <span class="required">*</span></label>
            <input class="form-control" placeholder="" name="admin_level_1_long" [(ngModel)]="model.administrative_area_level_1_long_version" #admin_level_1_long="ngModel" required/>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group" [ngClass]="{ 'has-error': ((f.submitted || locality.dirty || locality.touched) && !locality.valid) }">
            <label class="control-label">Locality <span class="required">*</span></label>
            <input class="form-control" placeholder="" name="locality" [(ngModel)]="model.locality" #locality="ngModel" required/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group" [ngClass]="{ 'has-error': ((f.submitted || route.dirty || route.touched) && !route.valid) }">
            <label class="control-label">Route <span class="required">*</span></label>
            <input class="form-control" placeholder="" name="route" [(ngModel)]="model.route" #route="ngModel" required/>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group" [ngClass]="{ 'has-error': ((f.submitted || street_number.dirty || street_number.touched) && !street_number.valid) }">
            <label class="control-label">Street number <span class="required">*</span></label>
            <input class="form-control" placeholder="" name="street_number" [(ngModel)]="model.street_number" #street_number="ngModel" required/>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group" [ngClass]="{ 'has-error': ((f.submitted || postal_code.dirty || postal_code.touched) && !postal_code.valid) }">
            <label class="control-label">Postal code <span class="required">*</span></label>
            <input class="form-control" placeholder="" name="postal_code" [(ngModel)]="model.postal_code" #postal_code="ngModel" required/>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group" [ngClass]="{ 'has-error': ((f.submitted || country.dirty || country.touched) && !country.valid) }">
            <label class="control-label">Country <span class="required">*</span></label>
            <input class="form-control" placeholder="" name="country" [(ngModel)]="model.country_long_version" #country="ngModel" required/>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Sezione per inserimento manuale di un indirizzo -->

    <div class="row" *ngIf="model.note || editMode">
      <div class="col-md-12">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" placeholder="Some notes..." name="note" [(ngModel)]="model.note"></textarea>
        </div>
        <div *ngIf="!editMode"  class="form-group editMode">
          <label class="control-label">Notes</label>
          <p class="line-breaker">{{model.note}}</p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="type.valid && searchOk">
      <div class="col-md-12">
        <h3 *ngIf="model.administrative_area_level_1_long_version">
          <b>{{model.administrative_area_level_3_long_version}} <span *ngIf="model.locality != model.administrative_area_level_3_long_version">loc. {{model.locality}}</span> ({{model.administrative_area_level_2_long_version}}, {{model.administrative_area_level_1_long_version}})</b>
        </h3>
        <h4 *ngIf="model.route">
          {{model.route}}, {{model.street_number}}
        </h4>
        <h4>
          {{model.postal_code}} {{model.administrative_area_level_3_short_version}} {{model.administrative_area_level_2_short_version}}
        </h4>
        <h4>
          {{model.country_long_version}} ({{model.country_short_version}})
        </h4>
      </div>
    </div>

  </div>

  <div [appCheckPermission]="'enterprise_edit'" class="form-actions right">
    <img *ngIf="getEnterpriseService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="this.model.id"
       [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline red" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="!this.model.id"
      (click)="delete();"><i class="fa fa-trash-o"></i> Delete</button>
    <button type="button" class="btn btn-outline blue" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="this.model.id && !editMode"
      (click)="edit()"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>
    <button *ngIf="multiSave == false || editMode" type="button" (click)="submit()" class="btn blue" [disabled]="getEnterpriseService().isOngoingRequest()"
      [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>
