import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PersonService } from '../../_services/index';
import { PrimanotaShowComponent } from '../../primanota/index';

@Component({
  selector: 'app-person-cashflow',
  templateUrl: './person-cashflow.component.html',
  styleUrls: ['./person-cashflow.component.css']
})
export class PersonCashflowComponent implements OnInit {

  @ViewChild('primaNotaShowComponent', {static: true}) public primaNotaShowComponent: PrimanotaShowComponent;

  subscribe: any;
  model: any = {};
  holderName: string;

  constructor(private personService: PersonService) { }

  ngOnInit() {

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.model = this.personService.currentPersonInfo;

      if (this.model == null) this.model = {};
      else
      {
        this.holderName = this.personService.currentPersonInfo["surname"]+" "+this.personService.currentPersonInfo["first_name"];
      }

    });
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  modelUpdated(event){
    this.primaNotaShowComponent.modelUpdated(event);
  }

  getPersonService() {
    return this.personService
  }

}
