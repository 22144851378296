import { EnterpriseBankAccountFormComponent } from '../index';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { EnterpriseService } from '../../_services/index';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-enterprise-bank-account-list',
  templateUrl: './enterprise-bank-account-list.component.html',
  styleUrls: ['./enterprise-bank-account-list.component.css']
})
export class EnterpriseBankAccountListComponent implements OnInit {

  @ViewChildren('enterpriseBankAccountFormList') public enterpriseBankAccountFormList: QueryList<EnterpriseBankAccountFormComponent>;

  enterpriseBankAccountList: any;
  enterpriseBankAccountListKeys: any[];

  allFormValid = true;
  multiSaveForm = true;

  subscribe: any;

  constructor(private enterpriseService: EnterpriseService, private notify: NotificationsService) { }

  ngOnInit() {

    this.enterpriseBankAccountList = this.enterpriseService.currentEnterpriseBankAccountList;

    if (this.enterpriseBankAccountList == null)
      this.addNew();

    this.subscribe = this.enterpriseService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      if (this.enterpriseService.currentEnterpriseBankAccountList != null) {

        let newFormModel = null;
        if (typeof this.enterpriseBankAccountFormList.first.model.id == "undefined"
          && this.enterpriseBankAccountFormList.first.currentForm.form.dirty
          && this.enterpriseBankAccountFormList.first.currentForm.form.pending) {
          newFormModel = this.enterpriseBankAccountFormList.first.model;
        }

        this.enterpriseBankAccountListKeys = Object.keys(this.enterpriseService.currentEnterpriseBankAccountList)

        let enterpriseBankAccountListOld = this.enterpriseBankAccountList;
        this.enterpriseBankAccountList = this.enterpriseService.currentEnterpriseBankAccountList;

        this.enterpriseBankAccountListKeys.forEach(k => {

          if (typeof enterpriseBankAccountListOld[k] != "undefined" && this.enterpriseBankAccountList[k]["updated_at"] == enterpriseBankAccountListOld[k]["updated_at"])
            this.enterpriseBankAccountList[k] = enterpriseBankAccountListOld[k];
        });

        if (newFormModel != null) {
          this.enterpriseBankAccountList[0] = newFormModel;
          this.enterpriseBankAccountListKeys.unshift(0);
        }
      }

      if (this.enterpriseService.currentEnterpriseBankAccountList != null && this.enterpriseService.currentEnterpriseBankAccountList.length == 0) {
        this.addNew();
      }
    });
  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }


  addNew() {

    //  Cancello sempre la lista
    if (this.enterpriseBankAccountList == null) {
      this.enterpriseBankAccountList = {};
      this.enterpriseBankAccountListKeys = new Array();
    }

    this.enterpriseBankAccountList[0] = {};
    this.enterpriseBankAccountListKeys.unshift(0);

    //  Assegno il bank account come primary perchè è il primo della lista
    if (this.enterpriseBankAccountListKeys.length == 1)
      this.enterpriseBankAccountList[0].primary = true;
  }



  saveAll() {

    this.allFormValid = true;

    // Controllo se tutti i form sono validi
    this.enterpriseBankAccountFormList.forEach(element => {

      if (!element.currentForm.form.valid) {

        //  Controllo se non è valido

        for (var i in element.currentForm.controls) {
          element.currentForm.controls[i].markAsTouched();
        }

        this.allFormValid = false;
        this.notify.error("There is a form with error");
      }

    });

    if (!this.allFormValid)
      return;

    if (!this.checkPrimary())
      return;

    this.enterpriseBankAccountFormList.forEach(element => {
      element.submit();
    });
  }

  checkPrimary() {

    // controllo se esiste almeno un contatto primary
    let primaryFound = false;

    this.enterpriseBankAccountFormList.forEach(element => {
      if (element.model.primary)
        primaryFound = true;
    });

    if (!primaryFound) {
      this.notify.error("You need to set a primary address");
      return false;
    }

    return true;
  }



  primaryUpdate(event) {
    //  Cambio il valore di tutti i campi primary
    if (event.primary == true) {
      this.enterpriseBankAccountListKeys.forEach(element => {
        if (this.enterpriseBankAccountList[element].id != event.id) {
          this.enterpriseBankAccountList[element].primary_backup = this.enterpriseBankAccountList[element].primary;
          this.enterpriseBankAccountList[element].primary = 0;
        }
      });
    }
    else {
      this.enterpriseBankAccountListKeys.forEach(element => {
        if (this.enterpriseBankAccountList[element].primary_backup && this.enterpriseBankAccountList[element].id != event.id) {
          this.enterpriseBankAccountList[element].primary = this.enterpriseBankAccountList[element].primary_backup;
        }
      });
    }
  }



  formDelete(event) {
    if (!event.model.id && this.enterpriseBankAccountListKeys.length > 1) {
      this.enterpriseBankAccountListKeys.splice(0, 1);
      delete this.enterpriseBankAccountList[0];
    }

  }

  formSaved(event) {

    // controllo se questo form ha il primary impostato, perché potrebbe aver cambiato anche gli altri
    if (event.primary == true) {

      this.enterpriseBankAccountFormList.forEach(element => {

        if (event.id != element.model.id) {

          if (typeof element.model.primary_backup != "undefined" && element.model.primary_backup != element.model.primary) {
            element.currentForm.form.markAsDirty();
            element.submit();
          }
        }
      });
    }
  }

  getEnterpriseService() {
    return this.enterpriseService
  }

}
