<div class="row">
  <div class="col-md-12">
     <app-theatrino-search-form (searchKeywordsSubmit)="searchKeywordsSubmit($event)" [ongoingRequest]="datatable.ongoingRequest"></app-theatrino-search-form> 
  </div>
</div>

<br><br>

<!-- begin: prima riga -->
<div class="row">
  <div class="col-md-12">

    <!--begin: Portlet -->
    <div class="portlet light bordered">
      <!--end: Portlet-Body -->
      <div class="portlet-body">
         <app-theatrino-datatable (theatrinoSelected)="theatrinoSelected($event)"></app-theatrino-datatable> 
      </div>
      <!--end: Portlet-Body -->
    </div>
    <!--end: Portlet -->
  </div>
</div>
<!--end: prima riga -->