<form #myForm="ngForm" novalidate>
  <div class="row">
    <div class="col-xs-4">
      <div class="form-group" [ngClass]="{ 'has-error': (myForm.submitted && !getHelper().isMyDateValid(localDataStart)) }">
        <label class="control-label"><i class="fa fa-calendar" aria-hidden="true"></i> Date
          <span class="required">*</span>
        </label>
        <div class="input-group">
          <input angular-mydatepicker #dp_start_date="angular-mydatepicker" name="start_date" class="form-control"
            [locale]="getHelper().locale"
            [options]="datePickerOptionsBegin"
            [ngModel]="localDataStart"
            [disabled]="getCourseScheduleService().isOngoingRequest()"
            (dateChanged)="onDateStartChanged($event)"
            required
          />
          <!-- clear date button -->
          <span class="input-group-btn">
            <button type="button" *ngIf="_rowSchedule?.start" class="btn default" (click)="dp_start_date.clearDate()"><i class="fa fa-close"></i></button>
          </span>
          <!-- toggle calendar button -->
          <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_start_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
          </span>
        </div>
      </div>
    </div>
    <div class="col-xs-6">
        <div class="row">
          <div class="col-xs-6">
            <div class="form-group" [ngClass]="{ 'has-error': (myForm.submitted && !timeStartvalid) }">
              <label class="control-label"><i class="fa fa-clock-o" aria-hidden="true"></i> Start Time
                <span class="required">*</span>
              </label>
              <input type="text" name="start_time" [(ngModel)]="_rowSchedule.start_time" #start_time="ngModel" class="form-control lesson-time-start"
                (input)="onTimeStartInput($event)" [typeahead]="getConfigService().timeCourseSchedulePlanSelect"
                [typeaheadScrollable]="true" (typeaheadOnSelect)="typeTimeStartOnSelect($event)" typeaheadOptionField="value"
                [disabled]="getCourseScheduleService().isOngoingRequest()"
                placeholder="08:30" autocomplete="off" />
            </div>
          </div>
          <div class="col-xs-6">
            <div class="form-group" [ngClass]="{ 'has-error': (myForm.submitted && !timeEndvalid) }">
              <label class="control-label"><i class="fa fa-clock-o" aria-hidden="true"></i> End Time
                <span class="required">*</span>
              </label>
              <input type="text" name="end_time" [(ngModel)]="_rowSchedule.end_time" #end_time="ngModel" class="form-control lesson-time-end"
                (input)="onTimeEndInput($event)" [typeahead]="getConfigService().timeCourseSchedulePlanSelect"
                [typeaheadScrollable]="true" (typeaheadOnSelect)="typeTimeEndOnSelect($event)" typeaheadOptionField="value"
                [disabled]="getCourseScheduleService().isOngoingRequest()"
                placeholder="11:00" autocomplete="off" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-2">
      <div style="margin-top: 25px;">
        <button *ngIf="!_rowSchedule.id" type="submit" class="btn green-jungle" [disabled]="getCourseScheduleService().isOngoingRequest()" (click)="onSubmit()"><i class="fa fa-save"></i></button>
        <button *ngIf="_rowSchedule.id" type="submit" class="btn blue" [disabled]="getCourseScheduleService().isOngoingRequest()" (click)="onSubmit()"><i class="fa fa-save"></i></button>

        <ng-template #confirmDelete><button type="button" class="btn btn-outline red"
          (click)="deleteRow();">Yes Delete!</button></ng-template>
        <button *ngIf="_rowSchedule.id" type="button" class="btn btn-danger"
          [outsideClick]="true" [disabled]="getCourseScheduleService().isOngoingRequest()" [popover]="confirmDelete" popoverTitle="Are you sure?"
          ><i class="fa fa-trash"></i></button>
      </div>
    </div>
  </div>
</form>
