import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TutorApplicationService } from 'app/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';

// import { PersonInfoSummaryComponent } from "../person-info-summary/person-info-summary.component";

@Component({
  selector: 'app-tutors-applications-phase',
  templateUrl: './tutors-applications-phase.component.html',
  styleUrls: ['./tutors-applications-phase.component.css']
})
export class TutorsApplicationsPhaseComponent implements OnInit {

  @Input('phase') phase: string;
  @ViewChild('autoShownModal', { static: false }) public autoShownModal: ModalDirective;
  @Input('searchParams') search: any;

  ongoingRequest: boolean = false;
  subscribe: any;

  selected = [];

  tutorsCompleted: any[] = [];
  tutors: any[] = [];
  tutorsRejected: any[] = [];
  tutorsImported: any[] = [];

  constructor(private tutorService: TutorApplicationService) { }

  ngOnInit() {

    this.init();
    this.subscribe = this.tutorService.getsubjectToUpdateObservable().subscribe(() => {
      this.init();
    })
  }

  ngOnDestroy() {

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  init() {
    if (typeof this.phase != "undefined" && this.phase != null) {
      this.tutorService.getTutorApplicationPhase(this.phase, this.search, (response) => {

        this.updateTable(response);
      });
    }
  }

  updateTable(data) {
    this.tutors = data.filter(x => x.rejected == false && x.status != "SUBMITTED" && !x.imported == true);
    this.tutorsCompleted = data.filter(x => x.rejected == false && x.status == "SUBMITTED");
    this.tutorsRejected = data.filter(x => x.rejected == true);
    this.tutorsImported = data.filter(x => x.imported == true);
  }


  currentTutorId: number;
  currentTutorInfo: any;
  openSelected(selected) {

    this.currentTutorId = selected.id;
    this.currentTutorInfo = selected;
    this.showModal();
  }



  // MODAL
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }



  public getTutorService() {
    return this.tutorService;
  }

}
