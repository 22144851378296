import { Component, OnInit, ViewChild } from '@angular/core';
import { VehicleService } from 'app/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-vehicle-penalties-list',
  templateUrl: './vehicle-penalties-list.component.html',
  styleUrls: ['./vehicle-penalties-list.component.css']
})
export class VehiclePenaltiesListComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;

  subject: any;

  penalties: any[] = [];

  rows: any[] = [];
  selected: any;
  selectedRow: any = [];

  loading: boolean = false;

  constructor(private vehicleService: VehicleService) { }

  ngOnInit() {

    this.prepareView();

    this.subject = this.vehicleService.getsubjectToUpdateObservable().subscribe(() => {

      this.prepareView();
    });
  }

  ngOnDestroy() {

    this.subject.unsubscribe();
  }


  prepareView() {

    if (this.vehicleService.currentVehiclePenalties == null || typeof this.vehicleService.currentVehiclePenalties == "undefined")
      this.penalties = [];
    else
      this.penalties = JSON.parse(JSON.stringify(this.vehicleService.currentVehiclePenalties));

      this.rows = this.penalties;
  }


  addPenalty() {
    this.showModal();
  }

  //  datatable
  onActivate(event){
  }
  onSelect({ selected }) {
    this.selected = JSON.parse(JSON.stringify(selected[0]));
    this.showModal();
  }

  //  modal
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
    this.selected = null;
    this.selectedRow = [];
  }



  getVehicleService (){
    return this.vehicleService;
  }

}
