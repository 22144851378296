import { ConfigService } from './../../_services/config.service';
import { Component, OnInit, Input } from '@angular/core';
import { addMinutes, format } from 'date-fns';

@Component({
  selector: 'app-theatrino-group-scheduling-review',
  templateUrl: './theatrino-group-scheduling-review.component.html',
  styleUrls: ['./theatrino-group-scheduling-review.component.css']
})
export class TheatrinoGroupSchedulingReviewComponent implements OnInit {

  @Input() rawData: any;

  public tableData: any;
  public tableDataKeys: any[];

  constructor(private configService: ConfigService) { }

  ngOnInit() {
  }

  ngOnChanges ()
  {
    this.getData(this.rawData);
  }


  getData(list)
  {
    //  Inizializzo la lista
    var data = [];

    //Faccio il sort degli eventi in base alla loro data d'inizio
    list.sort(function(a, b) {
      return a.time < b.time ? -1 : a.time > b.time ? 1 : 0;
    });

    list.forEach(element => {

      //  Se arriva una prenotazione regolare che però non è stata firmata la convenzione o non è confermata non la mostro
      if (element.type == 5 || (element.type == 2 && (element.status == 4 || element.status == 1 || element.status == 5)))
      {
          if (element.type == 2 && element.shows)
          {
            //  per ogni show controllo che abbia un workshow attivo
            element.shows.forEach(show => {

              //  Aggiungo l'orario di fine dello spettacolo
              show.endTime = this.calculateEndTimer(show.time, show.show_info.duration);

          //     //   Se è stato settato un workshop allora deve inserirlo nell'array
              if (show.workshop && show.workshop != 0)
              {
                var workshop_info: any = {};

                //  Se c'è una pausa allora la devo considerare
                if (show.pause && show.pause != 0)
                  workshop_info.time = this.calculateEndTimer(show.endTime, show.pause);
                else
                  workshop_info.time = show.endTime;

                workshop_info.endTime = this.calculateEndTimer(workshop_info.time, show.workshop);
                workshop_info.denomination = "Workshop";

                show.workshop_info = workshop_info;
              }

            });

            //  Faccio il sort dell'array basandomi sull'orario di inizio degli show
            element.shows.sort(function(a, b){
              return a.time < b.time ? -1 : a.time > b.time ? 1 : 0
            });

          }

        //  Inserisco l'elemento nell'array che verrà poi visualizzato
        data.push(element);

      }
    });

    //  Faccio il sort dell'array preferendo prima le prenotazioni e poi le accomodation
    data.sort(function(a, b){
      return a.type < b.type ? -1 : a.type > b.type ? 1 : 0 }
    );

    //  colleziono i dati in un dizionario
    var dataTheatrinoParsed = {};
    data.forEach(element => {
      if (dataTheatrinoParsed[element.theatrino_id] == null || typeof dataTheatrinoParsed[element.theatrino_id] == "undefined")
        dataTheatrinoParsed[element.theatrino_id] = [];

      dataTheatrinoParsed[element.theatrino_id].push(element);
    });

    // this.tableData = data;
    this.tableData = dataTheatrinoParsed;
    this.tableDataKeys = Object.keys(dataTheatrinoParsed);
    
  }


  //  Calcola il tempo di fine tra uno show ed un'altro
  calculateEndTimer(start: any, duration: any) : string
  {
    //  Aggiungo l'orario di fine dello spettacolo
    var dateTime = new Date();
    let hours = start.split(":");
    dateTime.setHours(hours[0], hours[1]);
    dateTime = addMinutes(dateTime, duration);
    return format(dateTime, "HH:mm");
  }


  //  Calcola il numero di alunni totali
  totalPulips(show)
  {
    return (parseInt(show.total_subscribers) || 0) +
          (parseInt(show.number_gratuities) || 0);
  }

  getConfigService() {
    return this.configService;
  }

}
