<div (onHidden)="onHiddenMaster()" bsModal #masterModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">

            <!-- BEGIN FORM -->
            <form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm">

              <input type="hidden" name="id" [(ngModel)]="model.id">

              <div class="form-body">

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">&nbsp;</label>
                      <div class="row">
                        <div class="col-md-12" *ngIf="vehicleInfo">
                          <h2>{{vehicleName}}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">Material</label>
                      <select class="form-control" name="material_type" [(ngModel)]="model.material_type"
                        #material_type="ngModel">
                        <option *ngFor="let c of getConfigService().vehicleMaterialAssignmentTypeSelect"
                          [value]="c.key">{{c.value}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalDataStart())) }">
                      <label class="control-label">Start Date <span class="required">*</span></label>
                        <div class="input-group">
                          <!-- input box -->
                          <input angular-mydatepicker #dp_start_date="angular-mydatepicker" name="start_date" class="form-control"
                            [locale]="getHelper().locale"
                            [disabled]="getVehicleService().isOngoingRequest()"
                            [options]="getDatePickerOptions()"
                            [ngModel]="getLocalDataStart()"
                            (dateChanged)="onDateStartChanged($event)"
                            required
                          />
                          <!-- clear date button -->
                          <span class="input-group-btn">
                            <button type="button" *ngIf="model?.start_date" class="btn default" (click)="dp_start_date.clearDate()"><i class="fa fa-close"></i></button>
                          </span>
                          <!-- toggle calendar button -->
                          <span class="input-group-btn">
                              <button type="button" class="btn default" (click)="dp_start_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                          </span>
                      </div>
                      <span class="help-block">Field required</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalDataEnd())) }">
                      <label class="control-label">End Date <span class="required">*</span></label>
                        <div class="input-group">
                          <!-- input box -->
                          <input angular-mydatepicker #dp_end_date="angular-mydatepicker" name="end_date" class="form-control"
                            [locale]="getHelper().locale"
                            [disabled]="getVehicleService().isOngoingRequest()"
                            [options]="getDatePickerOptions()"
                            [ngModel]="getLocalDataEnd()"
                            (dateChanged)="onDateEndChanged($event)"
                            required
                          />
                          <!-- clear date button -->
                          <span class="input-group-btn">
                            <button type="button" *ngIf="model?.end_date" class="btn default" (click)="dp_end_date.clearDate()"><i class="fa fa-close"></i></button>
                          </span>
                          <!-- toggle calendar button -->
                          <span class="input-group-btn">
                              <button type="button" class="btn default" (click)="dp_end_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                          </span>
                      </div>
                      <span class="help-block">Field required</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">Notes</label>
                      <textarea name="annotation" [(ngModel)]="model.annotation" rows="5" class="form-control"
                        placeholder="Some notes..."></textarea>
                    </div>
                  </div>
                </div>


              </div>

              <div class="form-actions right">
                <img *ngIf="getVehicleService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
                <ng-template #confirmDelete>
                  <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
                </ng-template>
                <button type="button" class="btn btn-outline red" [disabled]="getVehicleService().isOngoingRequest()"
                  *ngIf="this.model.id" [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i
                    class="fa fa-trash-o"></i> Delete</button>&nbsp;

                <button type="submit" class="btn blue" [disabled]="getVehicleService().isOngoingRequest()"
                  [ngClass]="{'green-jungle': !this.model.id}">
                  <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
                  <span class="ladda-label" *ngIf="this.model.id">Update</span>
                </button>
              </div>

            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
