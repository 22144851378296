import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { parseISO } from 'date-fns';
import { HelperService, ConfigService } from 'app/_services';

@Component({
  selector: 'app-vehicle-material-assignment-datatable',
  templateUrl: './vehicle-material-assignment-datatable.component.html',
  styleUrls: ['./vehicle-material-assignment-datatable.component.css']
})
export class VehicleMaterialAssignmentDatatableComponent implements OnInit {

  assignments: any[];

  @Output() select = new EventEmitter();

  rows: any[] = [];
  selected = [];
  selectedRow: any;

  loading: boolean = true;

  constructor(private helper: HelperService, private configService: ConfigService) { }

  ngOnInit() {
  }


  update(assignments:any[])
  {
    this.rows = [];
    this.loading = true;

    this.rows.splice(0, this.rows.length);

    if (assignments != null && typeof assignments != "undefined")
    {
      assignments.forEach(assignment => {

        if (assignment.start_date)
          assignment.start_date_parsed = parseISO(assignment.start_date);

        if (assignment.end_date)
          assignment.end_date_parsed = parseISO(assignment.end_date);
      });

      this.rows = assignments;
    }

    this.loading = false;
  }

  onSelect({ selected }) {

    if (this.external) return;

    this.selectedRow = Object.assign({}, selected[0]);
    this.select.emit(this.selectedRow);

    this.external = false;
  }

  external: boolean = false;
  clickExternal() {
    this.external = true;
  }

  getConfigService() {
    return this.configService;
  }


}
