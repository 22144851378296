import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { PersonDatatableComponent, PersonSearchFormComponent, PersonInfoShowComponent } from '../../person/index';
import { EnterpriseInfoShowComponent, EnterpriseSearchFormComponent, EnterpriseDatatableComponent } from './../../enterprise/index';
import { ConfigService, CampService } from '../../_services/index';

@Component({
  selector: 'app-camp-staff',
  templateUrl: './camp-staff.component.html',
  styleUrls: ['./camp-staff.component.css']
})
export class CampStaffComponent implements OnInit {

  @ViewChild('personShownModal', {static: false}) public personShownModal: ModalDirective;

  @ViewChild('personAddModal', {static: false}) public personAddModal: ModalDirective;

  @ViewChild('personSearchModal', {static: false}) public personSearchModal: ModalDirective;
  @ViewChild('personSearchForm', {static: false}) public personSearchForm: PersonSearchFormComponent;
  @ViewChild('personDatatable', {static: false}) public personDatatable: PersonDatatableComponent;

  @ViewChild('enterpriseSearchModal', {static: false}) public enterpriseSearchModal: ModalDirective;
  @ViewChild('enterpriseDatatable', {static: false}) public enterpriseDatatable: EnterpriseDatatableComponent;
  @ViewChild('enterpriseSearchForm', {static: false}) public enterpriseSearchForm: EnterpriseSearchFormComponent;

  currentSelectedPerson: any;
  searched: boolean = false;

  constructor(private configService: ConfigService, private campService: CampService) { }

  ngOnInit() {
  }


  currentStaffType: number;
  //  Apre la modale per aggiungere una persona
  AddNewStaff(event) {

    //  memorizzo lo staff type da assegnare per il salvataggio
    this.currentStaffType = event;

    let staffType = this.configService.getCampStaffTypeByKey(this.currentStaffType);

    if (staffType){

      if (staffType.type == 'people'){
        this.showPersonSearchModal();
      }
      else if (staffType.type == 'enterprise') {
        this.showEnterpriseSearchModal();
      }

    }
  }


  //  l'utente vuole aggiungere una nuova persona
  addNewPerson () {
    this.showPersonNewModal();
  }

  //  una nuova persona è stata aggiunta
  newPersonAdded (person) {

    //  chiudo la modale
    this.hidePersonNewModal();

    //  aggiungo il person_id per renderlo usabile con il vecchio metodo
    person.person_id = person.id;

    //  salvo la persona appena aggiunta come membro dello staff
    this.personSearchResultSelected(person);
  }

  //  Eventi legati al datatable
  editPerson(event)
  {
    this.currentSelectedPerson = JSON.parse(JSON.stringify(event));
    this.showPersonModal();
  }

  //  Modale per la visualizzazione di una persona
  isPersonModalShow: boolean = false;

  showPersonModal(): void {
    this.isPersonModalShow = true;
  }

  hidePersonModal(): void {
    this.personShownModal.hide();
  }

  onHiddenPerson(): void {
    this.isPersonModalShow = false;
    this.currentSelectedPerson = null;
  }


  newPersonModalShow: boolean = false;

  showPersonNewModal(): void {
    this.newPersonModalShow = true;
  }

  //  Modale per l'inserimento di una nuova persona
  onHiddenPersonNew(): void {
    this.newPersonModalShow = false;
  }

  hidePersonNewModal(): void {
    this.personAddModal.hide();
  }


  //  Modale per la ricerca di una persona
  isPersonModalSearch: boolean = false;

  showPersonSearchModal(): void {
    this.isPersonModalSearch = true;
  }

  hidePersonSearchModal(): void {
    if (this.isPersonModalSearch)
      this.personSearchModal.hide();
  }

  onHiddenPersonSearch(): void {

    //  Rimuovo il current staff type
    this.currentStaffType = null;

    //  resetto il controllo di ricerca
    this.searched = false;

    this.isPersonModalSearch = false;
  }

  searchPersonKeywordsSubmit(params) {
    this.personDatatable.query = params;
    this.personDatatable.getResults();

    this.searched = true;
  }

  //  Modale per la ricerca di una persona
  isEnterpriseSearchModalShown: boolean = false;

  showEnterpriseSearchModal(): void {
    this.isEnterpriseSearchModalShown = true;
  }

  hideEnterpriseSearchModal(): void {
    if (this.isEnterpriseSearchModalShown)
      this.enterpriseSearchModal.hide();
  }

  onHiddenEnterpriseSearch(): void {
    this.isEnterpriseSearchModalShown = false;

    //  Rimuovo il current staff type
    this.currentStaffType = null;
  }

  searchEnterpriseKeywordsSubmit(params) {
    this.enterpriseDatatable.query = params;
    this.enterpriseDatatable.getResults();
  }

  //  setto il risultato della ricerca
    //  ma controllo che non ci sia una richiesta già in borso
  private inAction: boolean = false;
  personSearchResultSelected(selected) {

    //  se sto ancora elaborando una richiesta non faccio nulla
    if (this.inAction) return;

    //  preparo il model
    var model = selected;
    selected.type = this.currentStaffType;

    //  faccio partire una richiesta
    this.inAction = true;

    this.saveSelection(model);
  }

  enterpriseSearchResultSelected(selected) {

    //  se sto ancora elaborando una richiesta non faccio nulla
    if (this.inAction) return;

    //  preparo il model
    var model = selected;
    selected.type = this.currentStaffType;

    //  faccio partire una richiesta
    this.inAction = true;

    this.saveSelection(model);
  }

  saveSelection(model){

    //  Salvo il nuovo membro
    this.campService.saveCampStaffMember(model, () => {

      //  terminata la richiesta
      this.inAction = false;

      //  Tolgo la modale
      this.hidePersonSearchModal();
      this.hideEnterpriseSearchModal();

    }, () => {

      this.inAction = false;
    });
  }

  getConfigService() {
    return this.configService
  }

  getCampService() {
    return this.campService
  }

}
