import { IAngularMyDpOptions, IMyDateModel, IMyInputFieldChanged} from 'angular-mydatepicker';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { EnterpriseService, ConfigService, HelperService } from '../../_services/index';
import { parseISO } from 'date-fns';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-enterprise-document-form',
  templateUrl: './enterprise-document-form.component.html',
  styleUrls: ['./enterprise-document-form.component.css']
})
export class EnterpriseDocumentFormComponent implements OnInit {

  @Input () model: any = {};

  @Output() formSaved = new EventEmitter();

  editMode: boolean = false;

  //  Url per l'upload dei file
  public documentUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf'],
      url: 'serverUrl'
    }
  );

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(private enterpriseService: EnterpriseService, private configService: ConfigService,
              private helper: HelperService) {

      //  Inizializzo le info sulle date
      this.datePickerOptions = helper.datePickerOptions;
    }

  ngOnInit() {

    if (this.model.id)
    {
      //  Setto l'url per il caricamento delle foto e gli header
      this.documentUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
      this.documentUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
        this.imageUploaded(item, response, status);
      };
      this.documentUploader.options.url = this.enterpriseService.getEnterpriseDocumentFileUploadURL(this.model);
    }

    if (!this.model || !this.model.id)
      this.editMode = true;
  }


  get calculateExpiration (): boolean {
    return this.helper.calculateExpiration(this.model.expiration_date)
  }


  submit()
  {
    if (this.model.id)
      this.enterpriseService.updateEnterpriseDocument(this.model, ()=> {this.editMode = false;});
    else
      this.enterpriseService.saveEnterpriseDocument(this.model, ()=> {this.editMode = false;});

    this.formSaved.emit(this.model);
  }

  destroy() {
    this.enterpriseService.deleteEnterpriseDocument(this.model);
  }

  edit() {
    this.editMode = true;
  }


//  Image Uploader
public imageUploaded(item:any, response:any, status:any)
{
  if (item != null) {
    if (status === 200) {
      this.model.file_path = response;
    }
    this.enterpriseService.enterpriseDocumentFileResponse(item, response, status, this.model.id);
  }
}

//  Remove image
  destroyFile()
  {
    this.enterpriseService.deleteEnterpriseDocumentFile(this.model);
  }

  getConfigService() {
    return this.configService
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

  getEnterpriseService() {
    return this.enterpriseService
  }
}
