import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { parseISO, isSameDay } from 'date-fns';
import { ConfigService, VehicleService, UserService, HelperService, BackendService } from '../../_services';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay, CalendarMonthViewComponent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

type CalendarPeriod = 'day' | 'week' | 'month';

@Component({
  selector: 'app-vehicle-calendar',
  templateUrl: './vehicle-calendar.component.html',
  styleUrls: ['./vehicle-calendar.component.css']
})
export class VehicleCalendarComponent implements OnInit {

  @Input() ongoingRequest: boolean;
  @Input() query: any;
  @Output() assignementSelected = new EventEmitter();

  currentQuery: any;

  view: CalendarPeriod = 'month';
  viewDate: Date = new Date();
  subTitle: string = "";


  refresh: Subject<any> = new Subject();

  activeDayIsOpen: boolean = false;

  actions: CalendarEventAction[] = [
    // {
    //   label: '<i class="fa fa-pencil"></i>',
    //   onClick: ({ event }: { event: CalendarEvent }): void => {
    //     this.handleEvent('Edited', event);
    //   }
    // }
  ];

  openDayDate: any;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  prevBtnDisabled: boolean = false;
  nextBtnDisabled: boolean = false;
  events: CalendarEvent[] = [];
  dataAssignement: any;

  @ViewChild('calendarMonthView', {static: true}) public calendarMonthView: CalendarMonthViewComponent;

  constructor(private vehicleService: VehicleService, private configService: ConfigService, private userService: UserService,
    private router: Router, private helper: HelperService, private backend: BackendService) { }

  ngOnInit() {

    if (this.userService.getUserPreferences("vehicle_calendar_view") != null)
      this.view = this.userService.getUserPreferences("vehicle_calendar_view");

    if (this.userService.getUserPreferences("vehicle_calendar_viewDate") != null)
      this.viewDate = new Date(this.userService.getUserPreferences("vehicle_calendar_viewDate"));

    this.getResults();
  }

  ngOnDestroy() {
  }

  getResults() {

    this.ongoingRequest = true;

    let params = new HttpParams();

    if (!this.currentQuery)
      this.currentQuery = {};

    this.currentQuery.viewDate = this.viewDate;

    this.backend.post('vehicle/assignment/search', this.currentQuery, params).subscribe(
      (response) => {
        let res : any = response;

        this.dataAssignement = res;

        this.updateEventArray();

        this.ongoingRequest = false;
      },
      (error) => {
        this.backend.showErrors(error);

        this.ongoingRequest = false;
      }
    );
  }

  updateEventArray() {

    this.events = [];

    this.dataAssignement.forEach(element => {

      this.events.push({
        start: parseISO(element.start_date),
        end: parseISO(element.end_date),
        title: this.getEventTitle(element),
        color: this.helper.getRandomColorForCalendarEvent("vechicle_reservetion_"+element.id),
        actions: this.actions,
        meta: {
          incrementsBadgeTotal: true,
          listElement: element,
          smallTitle: this.getEventSmallTitle(element)
        },
        cssClass: "cal-booking",
        allDay: true
      });

    });

  }

  getEventTitle(element){

    let title = element.vehicle_data.vehicle_name+" ["+element.vehicle_data.plate_number+"] ";

    if (element.in_holder_data){
      if (element.in_holder_type.indexOf("Person") >= 0){
        title += element.in_holder_data.first_name+" "+element.in_holder_data.surname;
      }
      else if (element.in_holder_type.indexOf("ActorGroup") >= 0){
        title += element.in_holder_data.group_name;
      }
    }

    return title;
  }

  getEventSmallTitle(element){
    let title = element.vehicle_data.vehicle_name;

    if (element.in_holder_data){
      if (element.in_holder_type.indexOf("Person") >= 0){
        title += " - "+element.in_holder_data.first_name+" "+element.in_holder_data.surname;
      }
      else if (element.in_holder_type.indexOf("ActorGroup") >= 0){
        title += " - "+element.in_holder_data.group_name;
      }
    }

    return title;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

    this.openDayDate = date;

    // if (isSameMonth(date, this.viewDate)) {
    if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
      this.viewDate = date;
    }

    if (events && events.length === 0) {
      this.activeDayIsOpen = true;
      //this.addNewTourDateFromOpenDay(date);
    }
    // }
    // else {
    //   this.activeDayIsOpen = false;
    // }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  increment(): void {
    this.changeDate(this.helper.addPeriod(this.view, this.viewDate, 1));
  }

  decrement(): void {
    this.changeDate(this.helper.subPeriod(this.view, this.viewDate, 1));
  }

  today(): void {
    this.changeDate(new Date());
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.activeDayIsOpen = false;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarPeriod): void {
    this.view = view;
    this.activeDayIsOpen = false;
    this.dateOrViewChanged();
  }

  dateIsValid(date: Date): boolean {
    // return date >= this.minDate && date <= this.maxDate;
    return true;
  }

  handleEvent(action: string, event: CalendarEvent): void {

    if (action == "Edited" || action == "Clicked") {
      this.router.navigate(["vehicle/edit/"+event.meta.listElement.vehicle_id]);
    }
  }


  dateOrViewChanged(): void {

    this.prevBtnDisabled = !this.dateIsValid(
      this.helper.endOfPeriod(this.view, this.helper.subPeriod(this.view, this.viewDate, 1))
    );

    this.nextBtnDisabled = !this.dateIsValid(
      this.helper.startOfPeriod(this.view, this.helper.addPeriod(this.view, this.viewDate, 1))
    );

    // if (this.viewDate < this.minDate) {
    //   this.changeDate(this.minDate);
    // } else if (this.viewDate > this.maxDate) {
    //   this.changeDate(this.maxDate);
    // }

    this.subTitle = this.helper.startOfPeriod(this.view, this.viewDate).toDateString() + " - " + this.helper.endOfPeriod(this.view, this.viewDate).toDateString();

    this.userService.saveUserPreferences("vehicle_calendar_view", this.view);
    this.userService.saveUserPreferences("vehicle_calendar_viewDate", this.viewDate.toDateString());

    this.getResults();
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      if (!this.dateIsValid(day.date)) {
        day.cssClass = 'cal-disabled';
      }

      if (day.events.length > 0) {

        // for (let i = 0; i < day.events.length; i++){
        //   if (typeof this.tourList[day.events[i].meta.listKey] != "undefined" && typeof this.tourList[day.events[i].meta.listKey].type != "undefined")
        //   {
        //     if (this.tourList[day.events[i].meta.listKey].type == 1){
        //       day.cssClass = 'cal-dayoff';
        //     }

        //     if (this.tourList[day.events[i].meta.listKey].type == 3){
        //       day.cssClass = 'cal-swapday';
        //     }
        //   }
        // }
      }
    });
  }

  getConfigService() {
    return this.configService
  }

}
