export const environment = {
  production: true,
  apiUrl: "https://gastonapi.educoitalia.it/api/",
  resetPasswordLink: "https://gastonapi.educoitalia.it/password/reset",
  version: "2024.10.28",
  tutorApplicationFormUrl: 'http://application.educoitalia.it/#/tutorsapplicationforms',
  actorApplicationFormUrl: 'http://application.educoitalia.it/#/actorsapplicationforms',
  facilitatorApplicationFormUrl: 'http://application.educoitalia.it/#/facilitatorsapplicationforms',
  sentry: 'https://0fc76b1a0eb8426e99311ac5f70f253d@sentry.io/281173',
  hotjarTrackingId: '898415',
  checkVersionUrl: 'version.json',
};
