import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GroupService } from '../../_services/index';
import { PrimanotaShowComponent } from '../../primanota/index';

@Component({
  selector: 'app-actor-group-cashflow',
  templateUrl: './actor-group-cashflow.component.html',
  styleUrls: ['./actor-group-cashflow.component.css']
})
export class ActorGroupCashflowComponent implements OnInit {

  @ViewChild('primaNotaShowComponent', {static: true}) public primaNotaShowComponent: PrimanotaShowComponent;

  subscribe: any;
  model: any = {};
  holderName: string;

  constructor(private groupService: GroupService) { }

  ngOnInit() {

    this.subscribe = this.groupService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.model = this.groupService.currentGroupInfo;

      if (this.model == null) this.model = {};
      else
      {
        this.holderName = this.groupService.currentGroupInfo["group_name"];
      }

    });
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  modelUpdated(event){
    this.primaNotaShowComponent.modelUpdated(event);
  }

  getGroupService() {
    return this.groupService
  }

}
