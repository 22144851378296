import { Router } from '@angular/router';
import { PersonService, ConfigService, HelperService, UserService } from './../../_services/index';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-person-info-summary',
  templateUrl: './person-info-summary.component.html',
  styleUrls: ['./person-info-summary.component.css']
})
export class PersonInfoSummaryComponent implements OnInit, OnChanges {

  constructor(private personService: PersonService, private router: Router,
              private configService: ConfigService, private helper: HelperService, private userService: UserService) { }

  @Input() idPerson: number;
  @Output() closeEvent = new EventEmitter();
  personInfo: any;
  currentId: number;

  dataFormatted: any;

  loading: boolean = false;

  noRoleAlert: boolean = false;

  ngOnInit() {

    this.personInfo = {};
  }

  close(){
    this.closeEvent.emit();
  }

  editPerson() {
    this.router.navigate(['person/edit/'+this.currentId]);
  }

  ngOnChanges() {

    if (this.idPerson != this.currentId)
      this.getPersonInfo();
  }

  getPersonInfo(){

    if (typeof this.idPerson == "undefined" ||  this.idPerson <= 0){
      this.personInfo = {};
      this.currentId = null;
      return;
    }

    this.currentId = this.idPerson;

    this.loading = true;

    this.personService.getPersonInfoAllSummary(this.currentId, (personInfo) => {

      this.personInfo = personInfo;

      if (this.personInfo.role.length == 0)
        this.noRoleAlert = true;

      if (this.personInfo.birthday != null)
        this.dataFormatted = parseISO(this.personInfo.birthday).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" })

      //  Se esiste il path per l'immagine costruisco l'url completo
      if (this.personInfo.file_path)
        this.personInfo.avatarUrl = this.configService.blobBasePath+this.personInfo.file_path;

        this.loading = false;
    }, () => {

        this.loading = false;
    });

  }

  getConfigService() {
    return this.configService
  }

  getPersonService() {
    return this.personService
  }

  getHelper() {
    return this.helper
  }

  getUserService() {
    return this.userService;
  }
}
