import { Router } from '@angular/router';
import { ActorApplicationService, ConfigService, HelperService, UserService } from './../../_services/index';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { parseISO } from 'date-fns';
import * as moment from 'moment-timezone';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { IMyInputFieldChanged } from 'angular-mydatepicker';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-actors-applications-info-summary',
  templateUrl: './actors-applications-info-summary.component.html',
  styleUrls: ['./actors-applications-info-summary.component.css']
})
export class ActorsApplicationsInfoSummaryComponent implements OnInit {

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(public actorApplicationService: ActorApplicationService, private router: Router,
    private configService: ConfigService, private helper: HelperService, private userService: UserService, private notify: NotificationsService) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
    this.datePickerOptions.disableUntil = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() - 1 }
  }

  @Input() actorApplicationId: number;
  @Output() closeEvent = new EventEmitter();

  public loading: boolean = false;

  actorApplicationInfo: any = {};
  currentId: number;

  dataFormatted: any;
  private localData: any;
  public interviewReady: boolean = false;

  noRoleAlert: boolean = false;

  ngOnInit() {

    this.actorApplicationInfo = {};
  }

  close() {
    this.closeEvent.emit();
  }

  editPerson() {
    this.router.navigate(['person/edit/' + this.actorApplicationInfo.person_id]);
  }

  ngOnChanges() {

    if (this.actorApplicationId != this.currentId)
      this.actortorApplicationInfo();
  }

  actortorApplicationInfo() {

    if (typeof this.actorApplicationId == "undefined" || this.actorApplicationId <= 0) {
      this.actorApplicationInfo = {};
      this.currentId = null;
      return;
    }

    this.loading = true;

    this.currentId = this.actorApplicationId;
    this.actorApplicationService.currentActorApplicationId = this.currentId;

    this.actorApplicationService.getActorApplicationInfo(() => {

      this.actorApplicationInfo = this.actorApplicationService.currentActorApplicationInfo;

      if (this.actorApplicationInfo.birthday != null)
        this.dataFormatted = this.helper.initMyDataInputSingle(this.actorApplicationInfo.birthday);

      //  Se esiste il path per i file aggiuntivi costruisco gli url
      if (this.actorApplicationInfo.photo_url)
        this.actorApplicationInfo.avatarUrl = this.configService.blobBasePath + this.actorApplicationInfo.photo_url;

      if (this.actorApplicationInfo.cv_url)
        this.actorApplicationInfo.cvUrl = this.configService.blobBasePath + this.actorApplicationInfo.cv_url;

      if (this.actorApplicationInfo.cover_url)
        this.actorApplicationInfo.coverUrl = this.configService.blobBasePath + this.actorApplicationInfo.cover_url;

      if (this.actorApplicationInfo.driving_licence_url)
        this.actorApplicationInfo.driverLicenceUrl = this.configService.blobBasePath + this.actorApplicationInfo.driving_licence_url;

      if (this.actorApplicationInfo.passport_url)
        this.actorApplicationInfo.passportUrl = this.configService.blobBasePath + this.actorApplicationInfo.passport_url;

      if (this.actorApplicationInfo.int_child_protection_certificate_url)
        this.actorApplicationInfo.intChildCertificateUrl = this.configService.blobBasePath + this.actorApplicationInfo.int_child_protection_certificate_url;

      if (this.actorApplicationInfo.int_driver_licence_url)
        this.actorApplicationInfo.intDriverLicenceUrl = this.configService.blobBasePath + this.actorApplicationInfo.int_driver_licence_url;

      if (this.actorApplicationInfo.health_insurance_url)
        this.actorApplicationInfo.healthInsuranceUrl = this.configService.blobBasePath + this.actorApplicationInfo.health_insurance_url;

      if (this.actorApplicationInfo.flight_receipt_url)
        this.actorApplicationInfo.flightReceiptUrl = this.configService.blobBasePath + this.actorApplicationInfo.flight_receipt_url;

      if (this.actorApplicationInfo.timezone) {
        this.actorApplicationInfo.timezone_formatted = this.actorApplicationInfo.timezone + ' (GMT:' + moment.tz(this.actorApplicationInfo.timezone).format('Z') + ')';

        if (this.actorApplicationInfo.interview_time && this.actorApplicationInfo.interview_date) {
          let m = moment.tz(this.actorApplicationInfo.interview_date + " " + this.actorApplicationInfo.interview_time, this.actorApplicationInfo.timezone);
          this.actorApplicationInfo.italian_interview_time_formatted = m.tz("Europe/Rome");
        }
      }

      if (this.actorApplicationInfo.preferred_interview_time) {
        this.actorApplicationInfo.preferred_interview_time_formatted = [];
        JSON.parse(this.actorApplicationInfo.preferred_interview_time).forEach(time => {
          if (time.checked == true)
            this.actorApplicationInfo.preferred_interview_time_formatted.push(
              " • " + time.name
            );
        });
      }

      this.setInterviewInfo();

      this.loading = false;

    }, () => { this.loading = false; });

  }

  PromoteActor() {

    this.actorApplicationService.promoteActor('' + this.currentId, this.actorApplicationInfo, () => {
      this.close();
    })
  }

  HireActor() {

    this.actorApplicationService.hireActor('' + this.currentId, this.actorApplicationInfo, (jsonResponse) => {

      this.actorApplicationInfo = jsonResponse;
      this.close();
      this.editPerson();
    })
  }

  RejectActor() {

    this.actorApplicationService.rejectActor('' + this.currentId, this.actorApplicationInfo, () => {
      this.close();
    })
  }

  CancelRejectActor() {

    this.actorApplicationService.cancelRejectActor('' + this.currentId, this.actorApplicationInfo, () => {
      this.close();
    })
  }

  EditActor() {

    this.router.navigate(['actorsapplications/edit/' + this.currentId]);
  }


  saveInterviewInfo() {

    this.actorApplicationService.saveInterviewInfo(this.currentId.toString(), this.actorApplicationInfo, () => {

      this.setInterviewInfo();
    });
  }

  setInterviewInfo() {
    if (this.actorApplicationInfo.interview_date != null) {
      this.localData = this.helper.initMyDataInputSingle(this.actorApplicationInfo.interview_date);

      if (this.actorApplicationInfo.interview_time != null)
        this.interviewReady = true;

      if (this.actorApplicationInfo.timezone) {
        if (this.actorApplicationInfo.interview_time && this.actorApplicationInfo.interview_date) {
          let m = moment.tz(this.actorApplicationInfo.interview_date + " " + this.actorApplicationInfo.interview_time, this.actorApplicationInfo.timezone);
          this.actorApplicationInfo.italian_interview_time_formatted = m.tz("Europe/Rome");
        }
      }
    }
  }

  changeSelftape(event) {

    if (this.actorApplicationInfo.selftape == true)
      this.actorApplicationService.enableSelfTape(this.currentId.toString());
    else
      this.actorApplicationService.disableSelfTape(this.currentId.toString());
  }

  changeAudition(event) {

    if (this.actorApplicationInfo.audition == true)
      this.actorApplicationService.enableAudition(this.currentId.toString());
    else
      this.actorApplicationService.disableAudition(this.currentId.toString());
  }



  onDateChanged(event: IMyDateModel) {

    this.localData = event;

    if (event.singleDate.jsDate) {
      this.actorApplicationInfo.interview_date = this.helper.formatISODate(event);
    }
  }


  inviaMailTo(event: any, type) {

    event.target.disabled = true;

    this.actorApplicationService.sendEmailType(this.currentId.toString(), type, () => {
      event.target.innerHTML = '<i class="fa fa-check-square-o aria-hidden="true"></i>&nbsp;Email inviata correttamente'
      this.notify.success("Email", "The email has been sent correctly");
    }, () => {
      event.target.innerHTML = '<i class="fa fa-exclamation-triangle aria-hidden="true"></i>&nbsp;Errore'
    });
  }

  public getConfig() {
    return this.configService;
  }

  public getHelper() {
    return this.helper;
  }

  public getDatePickerOptions() {
    return this.datePickerOptions
  }

  public getLocalData() {
    return this.localData
  }

  public getUserService() {
    return this.userService
  }

  public getActorService() {
    return this.actorApplicationService;
  }

}
