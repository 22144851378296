import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthenticationService, LoggedService, UserService, CheckVersionService, ResetServicesService } from './_services/index';
import { environment } from './environment';
import { enableProdMode } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { setTheme } from 'ngx-bootstrap/utils';

@Component({
  moduleId: module.id,
  selector: 'app',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  notificationOptions = {
    position: ["top", "right"],
    timeOut: 1500,
    theClass: "simpleNotifications"
  }

  hotjarTrackingId = environment.hotjarTrackingId;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private auth: AuthenticationService,
    private userService: UserService,
    private ls: LoggedService,
    private checkVersionService: CheckVersionService,
    private resetServicesService: ResetServicesService
  ) {
    setTheme('bs3');
  }

  ngOnInit() {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        var rt = this.getChild(this.activatedRoute)
        rt.data.subscribe(data => {
          this.titleService.setTitle(data.title)
        })
      })

    this.checkVersionService.initVersionCheck();

    this.auth.checkAndUpdateCurrentUserData();

  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

}
