import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HelperService, ConfigService, CourseScheduleService, UserService } from './../../_services/index';
import { NotificationsService } from 'angular2-notifications';

//  salvataggio per excel
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {  } from '../../_services';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-course-schedule-rooms',
  templateUrl: './course-schedule-rooms.component.html',
  styleUrls: ['./course-schedule-rooms.component.css']
})
export class CourseScheduleRoomsComponent implements OnInit {

  @ViewChild('roomModal', {static: false}) public roomModal: ModalDirective;
  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;

  courseScheduleRoomList: any[] = [];
  courseSubscriberRoomList: any[] = [];
  courseStaffRoomList: any[] = [];

  subscribe: any;

  selected = [] = [];

  constructor(private  courseScheduleService: CourseScheduleService, private helper: HelperService,
              private configService: ConfigService, private notify: NotificationsService, private userService: UserService) { }

  ngOnInit() {

    this.subscribe = this.courseScheduleService.getsubjectToUpdateObservable().subscribe((update: boolean) =>  {
      this.getRoomsInfo();
    });

    if (this.courseScheduleService.currentCourseRooms != null && this.courseScheduleService.currentCourseSubscriberList != null)
      this.getRoomsInfo();
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  getRoomsInfo () {

    if (this.courseScheduleService.currentCourseScheduleInfo != null && this.courseScheduleService.currentCourseSubscriberList != null) {

      this.courseScheduleRoomList = JSON.parse(JSON.stringify(this.courseScheduleService.currentCourseRooms));
      this.prepareSubscriber();
      this.prepareStaff();
      this.numOfGuestForRoom();
    }

  }


  numOfGuestForRoom() {

    this.courseScheduleRoomList.forEach(element => {

      var numOfGuest = 0;

      this.courseSubscriberRoomList.forEach((subscriber: any) => {

        if (typeof subscriber.room.course_room_id != "undefined")
          if (subscriber.room.course_room_id == element.id)
            numOfGuest++;
      })

      this.courseStaffRoomList.forEach(staff => {

        if (typeof staff.room.course_room_id != "undefined")
          if (staff.room.course_room_id == element.id)
            numOfGuest++;
      })

      element.num_of_guests = numOfGuest;
    })
  }

  prepareSubscriber ()
  {
    //  setto la lista
    this.courseSubscriberRoomList = JSON.parse(JSON.stringify(this.courseScheduleService.currentCourseSubscriberList.filter(x => !x.deleted_at)));

      //  aggiorno il nome del gruppo
      this.courseSubscriberRoomList.forEach((subscriber: any) => {

      //  controllo tra tutte le room di questo iscritto se compare
      subscriber.room = {};
      if (subscriber.rooms.length > 0)
      {
        subscriber.rooms.forEach(room => {
          var roomsFound = this.courseScheduleRoomList.find(x => x.id == room.course_room_id);
          if (typeof roomsFound != "undefined")
          {
            subscriber.room = room;
            subscriber.room.name = roomsFound.room_name;
            subscriber.room.room_type = roomsFound.type;
            subscriber.room.room_description = roomsFound.description;
          }
        });
      }
    });
  }

  prepareStaff ()
  {
    //  setto la lista
    this.courseStaffRoomList = JSON.parse(JSON.stringify(this.courseScheduleService.currentCourseStaffList.filter(x => !x.deleted_at)));

    //  aggiorno il nome del gruppo
    this.courseStaffRoomList.forEach(staffMember => {

      //  controllo tra tutte le room di questo membro dello staff se compare
      staffMember.room = {};
      if (staffMember.rooms.length > 0)
      {
        staffMember.rooms.forEach(room => {
            var roomFound = this.courseScheduleRoomList.find(x => x.id == room.course_room_id);
            if (typeof roomFound != "undefined")
            {
              staffMember.room = room;
              staffMember.room.name = roomFound.room_name;
            }
        });
      }
    });
  }


  currentModify: any = {};
  modifyRoom (row) {

    this.currentModify.subscriber = JSON.parse(JSON.stringify(row));
  }

  closeRoom () {

    this.currentModify = {};
  }

  loading:boolean = false;
  changeRoom (row) {


    //  inserisco una nuova room per questo iscritto
    if (typeof this.currentModify.subscriber.room.course_room_id == "undefined" && typeof this.currentModify.subscriber.room.id == "undefined")
    {
      //  controllo se non è stato selezionato una room valida
      if (row.room.course_room_id < 0) return;

      let model = { subscriber_id: row.id, course_room_id: row.room.course_room_id };

      this.saveSubscriberRoom(row, model, () => {
        this.notify.success("Subscriber Room", "successfully saved");
      })

      return;
    }

    //  questo iscritto è già in una room
    if (typeof this.currentModify.subscriber.room.course_room_id != "undefined" && typeof this.currentModify.subscriber.room.id != "undefined")
    {
      if (row.room.course_room_id > 0)
      {
        this.updateSubscriberRoom(row, () => {
          this.notify.success("Subscriber Room", "successfully changed");
        });
        return;
      }

      if (row.room.course_room_id < 0)
      {
        this.deleteSubscriberRoom(row, () => {
          this.notify.success("Subscriber Room", "successfully deleted");
        });
        return;
      }
    }
  }


  /*
   * Sezione dedicata agli iscritti
   */
  saveSubscriberRoom (row, model, callbackSuccess: any = null) {

    this.loading = true;

    this.courseScheduleService.saveCourseSubscriberRoom(model, (newRoom) => {

      row.room = newRoom;

      //  controllo che non arrivino informazioni errate prima di salvarle
      var gr = this.courseScheduleRoomList.find(x => x.id == row.room.course_room_id);

      row.room.name = gr.room_name;

      this.prepareSubscriber();

      this.currentModify = {};

      this.loading = false;

      if (callbackSuccess != null)
        callbackSuccess();
    },
    () => {

      this.loading = false;
    });

  }
  updateSubscriberRoom (row, callbackSuccess: any = null) {

    this.loading = true;

    this.courseScheduleService.updateCourseSubscriberRoom(row.room, (updateRoom) => {

      row.room = updateRoom;
      row.room.name = this.courseScheduleRoomList.find(x => x.id == row.room.course_room_id).room_name;

      this.currentModify = {};

      this.prepareSubscriber();

      this.loading = false;

      if (callbackSuccess != null)
        callbackSuccess();
    }, () => {
      this.loading = false;

    });
  }
  deleteSubscriberRoom (row, callbackSuccess: any = null) {

    this.loading = true;

    this.courseScheduleService.deleteCourseSubscriberRoom(row.room, () => {

      row.room = {}; row.room.name = "";
      this.currentModify = {};
      this.loading = false;

      this.prepareSubscriber();

      if (callbackSuccess != null)
        callbackSuccess();

    },
    ()=> {
      this.loading = false;
    });

  }



  /*
   *  Sezione dedicata agli iscritti
   */
  multiSelection: any = {course_room_id: -1, enabled: false};

  enableMultipleSelection () {
    this.multiSelection.enabled = true;
    this.closeRoom();
  }
  cancelMultipleSelection() {
    this.selected = [];
    this.multiSelection.course_room_id = -1;
    this.multiSelection.enabled = false;
  }
  saveMultipleSelection () {

    this.currentMultisave = 0;

    if (this.selected.length == 0)
      this.notify.warn("Subscriber Room", "No subscribers selected");

    this.selected.forEach((subscriber: any) => {

      if (typeof subscriber.room.course_room_id == "undefined" && typeof subscriber.room.id == "undefined")
      {
        //  controllo se non è stato selezionato una room valida
        if (this.multiSelection.course_room_id < 0) {this.currentMultisave++; this.checkMultipleSave(); return;}

        let model = { subscriber_id: subscriber.id, course_room_id: this.multiSelection.course_room_id };
        this.saveSubscriberRoom(subscriber, model, () => { this.currentMultisave++; this.checkMultipleSave() });
        return;
      }

      //  questo iscritto ha già una room
      if (typeof subscriber.room.course_room_id != "undefined" && typeof subscriber.room.id != "undefined")
      {
        subscriber.room.course_room_id = this.multiSelection.course_room_id;
        if (subscriber.room.course_room_id > 0) {
          this.updateSubscriberRoom(subscriber, () => { this.currentMultisave++; this.checkMultipleSave() });
          return;
        }

        if (subscriber.room.course_room_id < 0)
        {
          this.deleteSubscriberRoom(subscriber, () => { this.currentMultisave++; this.checkMultipleSave() });
          return;
        }
      }

    })
  }
  currentMultisave = 0;
  checkMultipleSave () {
    if (this.currentMultisave >= this.selected.length) {
      this.notify.success("Subscriber Rooms", this.currentMultisave + " successfully changed");
      this.cancelMultipleSelection();
    }
  }




  currentStaffModify: any = {};
  modifyRoomStaff (row) {

    this.currentStaffModify.staff_member = JSON.parse(JSON.stringify(row));
  }

  closeRoomStaff () {

    this.currentStaffModify = {};
  }

  changeRoomStaff (row) {


    //  inserisco una nuova room per questo iscritto
    if (typeof this.currentStaffModify.staff_member.room.course_room_id == "undefined" && typeof this.currentStaffModify.staff_member.room.id == "undefined")
    {
      //  controllo se non è stato selezionato una room valida
      if (row.room.course_room_id < 0) return;

      let model = { staff_member_id: row.id, course_room_id: row.room.course_room_id };

      this.saveStaffMemberRoom(row, model, () => {
        this.notify.success("Staff Member Room", "successfully saved");
      })

      return;
    }

    //  questo iscritto è già in una room
    if (typeof this.currentStaffModify.staff_member.room.course_room_id != "undefined" && typeof this.currentStaffModify.staff_member.room.id != "undefined")
    {
      if (row.room.course_room_id > 0)
      {
        this.updateStaffMemberRoom(row, () => {
          this.notify.success("Staff Member Room", "successfully changed");
        });
        return;
      }

      if (row.room.course_room_id < 0)
      {
        this.deleteStaffMemberRoom(row, () => {
          this.notify.success("Staff Member Room", "successfully deleted");
        });
        return;
      }
    }
  }




  /*
   * Sezione dedicata allo staff
   */
  saveStaffMemberRoom (row, model, callbackSuccess: any = null) {

    this.loading = true;

    this.courseScheduleService.saveCourseStaffMemberRoom(model, (newRoom) => {

      row.room = newRoom;

      //  controllo che non arrivino informazioni errate prima di salvarle
      var gr = this.courseScheduleRoomList.find(x => x.id == row.room.course_room_id);

      row.room.name = gr.room_name;

      this.prepareStaff();

      this.currentStaffModify = {};

      this.loading = false;

      if (callbackSuccess != null)
        callbackSuccess();
    },
    () => {

      this.loading = false;
    });

  }
  updateStaffMemberRoom (row, callbackSuccess: any = null) {

    this.loading = true;

    this.courseScheduleService.updateCourseStaffMemberRoom(row.room, (updateRoom) => {

      row.room = updateRoom;
      row.room.name = this.courseScheduleRoomList.find(x => x.id == row.room.course_room_id).room_name;

      this.currentStaffModify = {};

      this.prepareStaff();

      this.loading = false;

      if (callbackSuccess != null)
        callbackSuccess();
    }, () => {
      this.loading = false;

    });
  }
  deleteStaffMemberRoom (row, callbackSuccess: any = null) {

    this.loading = true;

    this.courseScheduleService.deleteCourseStaffMemberRoom(row.room, () => {

      row.room = {}; row.room.name = "";
      this.currentStaffModify = {};
      this.loading = false;

      this.prepareStaff();

      if (callbackSuccess != null)
        callbackSuccess();

    },
    ()=> {
      this.loading = false;
    });

  }



  /*
   *  Sezione dedicata allo staff
   */
  multiSelectionStaff: any = {course_room_id: -1, enabled: false};

  enableMultipleStaffSelection () {
    this.multiSelectionStaff.enabled = true;
    this.closeRoomStaff();
  }
  cancelMultipleStaffSelection() {
    this.selected = [];
    this.multiSelectionStaff.course_room_id = -1;
    this.multiSelectionStaff.enabled = false;
  }
  saveMultipleStaffSelection () {

    this.currentMultiStaffsave = 0;

    if (this.selected.length == 0)
      this.notify.warn("Subscriber Room", "No Staff Member selected");

    this.selected.forEach((staff_member: any) => {

      if (typeof staff_member.room.course_room_id == "undefined" && typeof staff_member.room.id == "undefined")
      {
        //  controllo se non è stato selezionato una room valida
        if (this.multiSelectionStaff.course_room_id < 0) {this.currentMultiStaffsave++; this.checkMultipleStaffSave(); return;}

        let model = { staff_member_id: staff_member.id, course_room_id: this.multiSelectionStaff.course_room_id };
        this.saveStaffMemberRoom(staff_member, model, () => { this.currentMultiStaffsave++; this.checkMultipleStaffSave() });
        return;
      }

      //  questo iscritto ha già una room
      if (typeof staff_member.room.course_room_id != "undefined" && typeof staff_member.room.id != "undefined")
      {
        staff_member.room.course_room_id = this.multiSelectionStaff.course_room_id;
        if (staff_member.room.course_room_id > 0) {
          this.updateStaffMemberRoom(staff_member, () => { this.currentMultiStaffsave++; this.checkMultipleStaffSave() });
          return;
        }

        if (staff_member.room.course_room_id < 0)
        {
          this.deleteStaffMemberRoom(staff_member, () => { this.currentMultiStaffsave++; this.checkMultipleStaffSave() });
          return;
        }
      }

    })
  }
  currentMultiStaffsave = 0;
  checkMultipleStaffSave () {
    if (this.currentMultiStaffsave >= this.selected.length) {
      this.notify.success("Staff Member Rooms", this.currentMultiStaffsave + " successfully changed");
      this.cancelMultipleStaffSelection();
    }
  }


  onSelect({ selected }) {
    // console.log(this.selected);
  }



  getRowClass (row) {

    if (row.person_info){
      return { 'camp-staff-deleted' : row.person_info.deleted_at !== null};
    }

    return "";
  }



  // ROOM & MODAL

  newRoom () {
    this.showModalRoom();
  }

  onRoomSelect({ selected }) {

    if (!this.userService.checkPermission("course_schedule_edit"))
      return;

    this.model_room = selected[0];
    this.showModalRoom();
  }

  model_room: any = {};

  isModalRoom: boolean = false;

  showModalRoom(): void {
    this.isModalRoom = true;
  }

  hideModalRoom(): void {
    this.roomModal.hide();
  }

  onHiddenRoom(): void {
    this.isModalRoom = false;
    this.model_room = {};
  }

  submit() {

    if (this.model_room.id) {
      this.courseScheduleService.updateCourseRoom(this.model_room, () => {
        this.hideModalRoom();
      });
    } else {
      this.courseScheduleService.saveCourseRoom(this.model_room, () => {
        this.hideModalRoom();
      });
    }
  }


  destroyRoom() {

    if (typeof this.model_room.num_of_guests != "undefined")
      if (this.model_room.num_of_guests > 0)
      {
        this.notify.error("Room Info", "There are one or more subscriber/staff in this room");
        return;
      }

    this.courseScheduleService.deleteCourseRoom
    (this.model_room, () => {
      this.hideModalRoom();
    })

  }


  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
    this.staticModal.show();
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }


  /*  Salvo i dati nel formato Excel
  */
 exportToExcel() {

  this.showModalExport();

  this.exportParse(this.courseSubscriberRoomList, this.courseStaffRoomList, () => {

    this.hideModalExport();
  })

}


/*
* Metodo per il parse degli oggetti prima di esportatli in un excel
*/
  exportParse (dataSubscribers:any, dataStaff:any, callback: any) {

    var exportedArray = [];

    //preparo i dati per il csv bonificandoli
    dataSubscribers.forEach(element => {
      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })
      //  preparo
      exportedArray.push( {
        'Type': 'Subscriber',
        'Nome': element.person_info.first_name,
        'Cognome': element.person_info.surname,
        'Eta': this.helper.calculateAge(element.person_info.birthday),
        'Sesso': this.configService.getGenderPersonValueByKey(element.person_info.gender),
        'Room': element.room.name,
        'Room Type': this.configService.getCourseScheduleTyoeValueByKey(element.room.room_type),
        'Room Description': element.room.room_description
      });

    });

    //preparo i dati per il csv bonificandoli
    dataStaff.forEach(element => {
      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })
      //  preparo
      exportedArray.push( {
        'Type': 'Staff',
        'Nome': element.person_info.first_name,
        'Cognome': element.person_info.surname,
        'Eta': this.helper.calculateAge(element.person_info.birthday),
        'Sesso': this.configService.getGenderPersonValueByKey(element.person_info.gender),
        'Room': element.room.name,
        'Room Type': this.configService.getCourseScheduleTyoeValueByKey(element.room.room_type),
        'Room Description': element.room.room_description
      })
    });

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{width: 12}, {width:20}, {width:20}, {width:6}, {width:8}, {width:18}, {width: 18}, {width: 50}];

    //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'CourseScheduleRooms': worksheet}, SheetNames: ['CourseScheduleRooms'] };

    //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

    //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "course_schedule_rooms.xlsx");

    callback();
  }



  isOngoingRequest() {
    return this.courseScheduleService.isOngoingRequest()
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

}
