<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">
    <h3 class="form-section">Enterprise Info</h3>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
          <label class="control-label">Type <span class="required">*</span></label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" (change)="onChangeType($event);" required>
            <option *ngFor="let t of getConfigService().enterpriseTypeSelect" [value]="t.key">{{t.value}}</option>
          </select>
          <span class="help-block"> Select type of enterprise, is required</span>
        </div>
      </div>
      <div class="col-md-6">
        <label class="control-label">Sub Type</label>
        <select class="form-control" name="type_sub" [(ngModel)]="model.type_sub" #type_sub="ngModel" [disabled]="!model.type || getConfigService().enterpriseTypeSubSelect[model.type].length <= 0">
            <option *ngFor="let st of getConfigService().enterpriseTypeSubSelect[this.model.type]" [value]="st.key">{{st.value}}</option>
        </select>
        <span class="help-block"> Select sub type of enterprise</span>
      </div>
    </div>

    <div class="row" *ngIf="model.type == 1">
      <div class="col-md-6">
        <label class="control-label">Legal Personality</label>
        <select class="form-control" name="legal_personality" [(ngModel)]="model.legal_personality" #legal_personality="ngModel">
            <option *ngFor="let lp of getConfigService().enterpriseTypeLegalSelect" [value]="lp.key">{{lp.value}}</option>
        </select>
        <span class="help-block"> Select legal personality of enterprise</span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || denomination.dirty || denomination.touched) && !denomination.valid }">
          <label class="control-label">Denomination <span class="required">*</span></label>
          <input type="text" name="denomination" [(ngModel)]="model.denomination" #denomination="ngModel" required class="form-control">
          <span class="help-block"> Denomination is required </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Tax ID</label>
          <input type="text" name="tax_id" [(ngModel)]="model.tax_id" #tax_id="ngModel" class="form-control">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Fiscal Code</label>
          <input type="text" name="fiscal_code" [(ngModel)]="model.fiscal_code" #fiscal_code="ngModel" class="form-control">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Unique Office Code</label>
          <input type="text" name="office_code" [(ngModel)]="model.office_code" #office_code="ngModel" class="form-control">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label class="control-label"></label>
        <div class="portlet light bordered" >
          <!--*ngIf="getEnterpriseService().currentEnterpriseId"-->
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase">Parent Autority</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div [appCheckPermission]="'enterprise_edit'" class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" [disabled]="getEnterpriseService().isOngoingRequest()" (click)="showModal();"><i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDelete01><button type="button" class="btn btn-outline red" (click)="removeAutority()">Yes Remove!</button></ng-template>
                <button *ngIf="this.model.superior_id" type="button" [disabled]="getEnterpriseService().isOngoingRequest()" class="btn btn-circle red btn-outline"
                   [outsideClick]="true" [popover]="confirmDelete01" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-enterprise-info-show #enterpriseInfoShow [idEnterprise]="model.superior_id"></app-enterprise-info-show>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="portlet light" *ngIf="(model.subordinates | mapFromKeyValue ).length > 0">
          <div class="portlet-body">
            <div class="mt-element-list">
              <div class="mt-list-head list-simple font-white bg-green">
                <div class="list-head-title-container">
                  <h3 class="list-title">Subordinates</h3>
                </div>
              </div>
              <div class="mt-list-container list-simple">
                <ul>
                  <li *ngFor="let s of model.subordinates | mapFromKeyValue" class="mt-list-item">
                    <!--<li class="mt-list-item">-->
                    <!--<div class="list-icon-container done">
                                </div>-->
                    <!--<div class="list-datetime"> 8 Nov </div>-->
                    <div class="list-item-content">
                      <h3>
                        <app-enterprise-info-show [idEnterprise]="s.id"></app-enterprise-info-show>
                      </h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
            <div class="alert alert-warning" *ngIf="checkLegalRappresentativeError">
              <strong>Warning!</strong> More than one legal representative is present
            </div>
            <div class="portlet light" *ngIf="(model.representative | mapFromKeyValue ).length > 0">
              <div class="portlet-body">
                <div class="mt-element-list">
                  <div class="mt-list-head list-simple font-white bg-green">
                    <div class="list-head-title-container">
                      <h3 class="list-title">Representative</h3>
                    </div>
                  </div>
                  <div class="mt-list-container list-simple">
                    <ul>
                      <li *ngFor="let s of model.representative | mapFromKeyValue" class="mt-list-item">
                        <div class="list-item-content">
                            {{ getConfigService().getRolePersonValueByKey(s.type) }} <br>
                            <app-person-info-show [idPerson]="s.person_id"></app-person-info-show>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea name="annotation" [(ngModel)]="model.annotation" rows="3" class="form-control" placeholder="Some notes..."></textarea>
        </div>
      </div>
    </div>

  </div>

  <div [appCheckPermission]="'enterprise_edit'" class="form-actions right">
    <img *ngIf="getEnterpriseService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif"
    />
    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="getEnterpriseService().currentEnterpriseId"
       [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="submit" class="btn blue" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="getEnterpriseService().currentEnterpriseId">Update</button>
    <button type="submit" class="btn green-jungle" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="!getEnterpriseService().currentEnterpriseId">Continue</button>
  </div>

</form>

<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add parent autority</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-enterprise-search-form #enterpriseSearchForm (searchKeywordsSubmit)="searchKeywordsSubmit($event)" [ongoingRequest]="enterpriseDatatable.ongoingRequest"></app-enterprise-search-form>
          </div>
        </div>

        <br><br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-enterprise-datatable #enterpriseDatatable [query]="enterpriseSearchForm.searchKeyword" (enterpriseSelected)="resultSelected($event)"></app-enterprise-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
