import { ConfigService } from './../../_services/config.service';
import { HelperService } from './../../_services/helper.service';
import { CourseScheduleService } from './../../_services/course-schedule.service';
import { IAngularMyDpOptions, IMyDateModel, IMyInputFieldChanged } from 'angular-mydatepicker';
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-course-schedule-diary-form',
  templateUrl: './course-schedule-diary-form.component.html',
  styleUrls: ['./course-schedule-diary-form.component.css']
})
export class CourseScheduleDiaryFormComponent implements OnInit {

  @Input() model: any;
  @Input() multiSave: boolean;

  @Output() formDelete = new EventEmitter();
  @Output() formSaved = new EventEmitter();

  editMode: boolean = false;

  @ViewChild('f', {static: true}) currentForm: NgForm;

  private localData: IMyDateModel;

  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(private courseScheduleService: CourseScheduleService, private helper: HelperService,
              private configService: ConfigService) {

      //  Inizializzo le info sulle date e resetto il blocco
      this.datePickerOptions = this.helper.datePickerOptions;

      this.datePickerOptions.disableUntil = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate() - 1
      };
    }

  ngOnInit() {

  }

  ngOnChanges() {

    if (!this.model || !this.model.id)
    {
      this.model = {};
      this.editMode = true;
    }

    if (typeof this.model.id != "undefined")
    {
      //  trasformo la data dell'ultima modifica in una data leggibile
      if (this.model.updated_at)
        this.model.updated_at_formatted = parseISO(this.model.updated_at).toLocaleDateString(this.helper.locale,
                                          {weekday:"short", year:"numeric", month:"short", day:"2-digit",
                                          hour:"2-digit", minute:"2-digit", second:"2-digit"});

      //  trasformo la data del reminder in una data leggibile
      if (this.model.reminder_data)
        this.localData = this.helper.initMyDataInputSingle(this.model.reminder_data);

    }

  }

  submit()
  {

    if (!this.helper.isMyDateValid(this.localData, false)) return;

    if (!this.timevalid) return;

    if (!this.currentForm.form.valid) return;

    if (!this.currentForm.form.dirty) {
      this.editMode = false;
      return;
    }

    if (this.model.id)
      this.courseScheduleService.updateCourseDiary(this.model, () => {this.editMode = false;});
    else
      this.courseScheduleService.saveCourseDiary(this.model, () => {this.editMode = false;});

    this.formSaved.emit(this.model);
  }

  destroy()
  {
    this.courseScheduleService.deleteCourseDiary(this.model);
  }

  delete() {
    this.formDelete.emit(this);
  }

  edit() {
    this.editMode = true;
  }

  //  Date picker
  onDateChanged(event: IMyDateModel)
  {
    this.localData = event;

    if (event.singleDate.jsDate != null)
      this.model.reminder_data = this.helper.formatISODate(event);
    else
      this.model.reminder_data = null;

    this.currentForm.form.markAsDirty();
}


  //  Time picher
  timevalid: boolean = true;
  onTimeInput (event)
  {
    this.timevalid = false;

    //  controllo se è valido il time
    this.configService.timeReminderSelect.forEach(element => {
      if (element.value == this.currentForm.form.controls.time.value)
          return this.timevalid = true;
    });
  }

  typeTimeOnSelect (event) {
    //  Se arriva questo evento al 100% è una data valida
    this.timevalid = true;
  }

  getCourseScheduleService() {
    return this.courseScheduleService
  }

  getConfigService() {
    return this.configService
  }

  getLocalData() {
    return this.localData
  }

  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

}
