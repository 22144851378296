import { element } from 'protractor';
import { EnterpriseService, UserService } from './../_services/index';
import { Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.css']
})
export class EnterpriseComponent implements OnInit {

  @ViewChild('enterpriseTabs', {static: true}) staticTabs: TabsetComponent;

  private subject: any;

  loadingData: boolean = true;

  constructor(private enterpriseService: EnterpriseService, private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {

      this.enterpriseService.resetService();

      if (typeof params['idDefaultType'] != "undefined")
      {
        this.enterpriseService.idDefaultType = + params['idDefaultType']; // (+) converts string 'id' to a number
        this.loadingData = false;
        this.disableAllTab();
      }
      else if (typeof params['idCurrentEnterprise'] != "undefined"){

        this.enterpriseService.currentEnterpriseId = + params['idCurrentEnterprise']; // (+) converts string 'id' to a number
        this.enterpriseService.getEnterpriseInfoAll(() => {
          this.loadingData = false;
          this.activeAllTab();
          this.selectTab(0);

          //recupero le info di una azienda e abilito il tab se serve
          if(parseInt(this.enterpriseService.currentEnterpriseInfo['type']) != 5)
            this.disableTab(5);
          else
            this.disableTab(6);

        }, () => {
          this.loadingData = false;
          this.disableAllTab();
        });
      }
    });
  }


  selectTab(num) {

    if (this.staticTabs.tabs[num])
      this.staticTabs.tabs[num].active = true;
  }

  activeAllTab(){

    this.staticTabs.tabs.forEach(element => {
      element.disabled = false;
    });
  }

  disableAllTab(){

    this.staticTabs.tabs.forEach(element => {
      element.disabled = true;
    });
  }

  enableTab(num) {
    if (this.staticTabs.tabs[num])
      this.staticTabs.tabs[num].disabled = false;
  }

  disableTab(num) {
    if (this.staticTabs.tabs[num])
      this.staticTabs.tabs[num].disabled = true;
   }

   getEnterpriseService() {
    return this.enterpriseService
  }

  getUserService() {
    return this.userService
  }

}
