import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { VehicleService } from '../../_services/index';
import { ReminderShowComponent } from '../../reminder/index';

@Component({
  selector: 'app-vehicle-reminder',
  templateUrl: './vehicle-reminder.component.html',
  styleUrls: ['./vehicle-reminder.component.css']
})
export class VehicleReminderComponent implements OnInit {

  @ViewChild('reminderShowComponent', {static: false}) public reminderShowComponent: ReminderShowComponent;

  subscribe: any;
  model: any = {};
  holderName: string;
  holderId: any;
  holderType: string;

  constructor(private vehicleService: VehicleService) { }

  ngOnInit() {

    this.subscribe = this.vehicleService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.holderName = this.vehicleService.currentVehicleInfo["vehicle_name"]+" "+this.vehicleService.currentVehicleInfo["plate_number"];
      this.holderId = this.vehicleService.currentVehicleId;
      this.holderType = 'App\\Vehicle';
    });
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  modelUpdated(event){
    this.reminderShowComponent.modelUpdated(event);
  }

}
