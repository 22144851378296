<!-- BEGIN CONTENT BODY -->
<div class="page-content">

  <div class="row" *ngIf="isTutor()">
    <div class="col-md-12">
      <app-camp-tutor-recap></app-camp-tutor-recap>
    </div>
  </div>

  <div class="row" *ngIf="isActor()">
    <div class="col-md-12">
      <div class="portlet light bordered">
        <div class="portlet-body">
          <app-theatrino-group-scheduling></app-theatrino-group-scheduling>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isDocente()">
    <div class="col-md-12">
      <div class="portlet light bordered">
        <div class="portlet-body">
          <app-camp-search-result></app-camp-search-result>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isOfficeStaff()">

    <div class="col-md-12">

      <div class="row" hidden>
        <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <h1 i18n="Welcome title">Welcome!</h1>
          <p i18n="welcome message|Welcome message in the dashboard">You're logged in with Angular 2!</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <accordion>
            <accordion-group #agendaGroup [isOpen]="true">
              <div accordion-heading>
                <i class="fa fa-calendar-check-o" aria-hidden="true"></i>&nbsp;Agenda
                <i class="pull-right float-xs-right glyphicon"
                  [ngClass]="{'glyphicon-chevron-down': agendaGroup?.isOpen, 'glyphicon-chevron-right': !agendaGroup?.isOpen}"></i>
              </div>
              <app-reminder-widget-agenda></app-reminder-widget-agenda>
            </accordion-group>
          </accordion>
        </div>
      </div>

      <br>

      <div class="row">
        <!-- <div class="col-md-6">
          <accordion>
            <accordion-group #changelogGroup [isOpen]="false">
              <div accordion-heading>
                <i class="fa fa-code" aria-hidden="true"></i>&nbsp;Changelog&nbsp;> Ultima versione {{ lastVersion }}
                <i class="pull-right float-xs-right glyphicon"
                  [ngClass]="{'glyphicon-chevron-down': changelogGroup?.isOpen, 'glyphicon-chevron-right': !changelogGroup?.isOpen}"></i>
              </div>
              <div Markdown path="changelog.md"></div>
            </accordion-group>
          </accordion>
        </div> -->
        <div class="col-md-12">
          <accordion>
            <accordion-group #messageGroup [isOpen]="true">
              <div accordion-heading>
                <i class="fa fa-comments-o" aria-hidden="true"></i>&nbsp;Feedback
                <i class="pull-right float-xs-right glyphicon"
                  [ngClass]="{'glyphicon-chevron-down': messageGroup?.isOpen, 'glyphicon-chevron-right': !messageGroup?.isOpen}"></i>
              </div>
              <div>
                E' stato introdotto un pulsante in basso "Feedback" per poter segnalare problemi o miglioramenti al
                team
                di sviluppo. Cercare di essere molto descrittivi, spiegare tutti i passaggi per consentire al team
                tecnico
                di riprodurre il problema.
              </div>
            </accordion-group>
          </accordion>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
