<!-- BEGIN FORM -->
<form *ngIf="editMode" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">
    <!-- <h3 class="form-section">Theatrino Tour Info</h3> -->

    <div class="row">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || denomination.dirty || denomination.touched) && !denomination.valid }">
          <label class="control-label">Denomination <span class="required">*</span></label>
          <input type="text" name="denomination" [(ngModel)]="model.denomination" #denomination="ngModel" required class="form-control"
            placeholder="Peter Pan">
          <span class="help-block"> Denomination is required </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
          <label class="control-label">Type <span class="required">*</span></label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
            <option *ngFor="let t of getConfigService().tourTheatrinoTypeSelect" [value]="t.key">{{t.value}}</option>
          </select>
          <span class="help-block"> Select Type, type is required</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Active</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="active" [(ngModel)]="model.active" value="1"> Yes
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDataStart)) }">
          <label class="control-label">Begin Date <span class="required">*</span></label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_start="angular-mydatepicker" name="start" class="form-control"
                [locale]="getHelper().locale"
                [options]="datePickerOptionsBegin"
                [ngModel]="localDataStart"
                (dateChanged)="onDateStartChanged($event)"
                required
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.start" class="btn default" (click)="dp_start.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_start.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block"> Start Date is required</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDataEnd)) }">
          <label class="control-label">End Date <span class="required">*</span></label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_end="angular-mydatepicker" name="end" class="form-control"
                [locale]="getHelper().locale"
                [options]="datePickerOptionsEnd"
                [ngModel]="localDataEnd"
                (dateChanged)="onDateEndChanged($event)"
                required
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.end" class="btn default" (click)="dp_end.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_end.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block"> End Date is required</span>
        </div>
      </div>
    </div>


    <div class="form-actions right">
      <img *ngIf="getTheatrinoService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />


      <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
      <button type="button" class="btn btn-outline red" [disabled]="getTheatrinoService().isOngoingRequest()" *ngIf="this.model.id"
         [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>
      <button type="button" class="btn btn-outline blue" [disabled]="getTheatrinoService().isOngoingRequest()" *ngIf="this.model.id && !editMode" (click)="edit()"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

      <button *ngIf="editMode" type="submit" class="btn blue" [disabled]="getTheatrinoService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
        <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
        <span class="ladda-label" *ngIf="this.model.id">Update</span>
      </button>

    </div>

  </div>

</form>
