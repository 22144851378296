import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { PersonCampTransferDetailFormComponent } from '../index';
import { CampService, PersonService } from '../../_services/index';
import { NotificationsService } from 'angular2-notifications';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-person-camp-transfer-detail-list',
  templateUrl: './person-camp-transfer-detail-list.component.html',
  styleUrls: ['./person-camp-transfer-detail-list.component.css']
})
export class PersonCampTransferDetailListComponent implements OnInit {

  @ViewChildren('personCampTransferDetailFormList') public personCampTransferDetailFormList: QueryList<PersonCampTransferDetailFormComponent>;
  @ViewChild('copyPasteModal', { static: false }) public copyPasteModal: ModalDirective;

  personCampTransferDetailList: any;
  personCampTransferDetailListKeys: any[];

  campTransferDetailsCopyPaste: any;

  allFormValid = true;
  multiSaveForm = true;
  camp_staff_member = [];

  subscribe: any;

  constructor(private personService: PersonService, private notify: NotificationsService, private campService: CampService) { }

  ngOnInit() {

    this.personCampTransferDetailList = this.personService.currentPersonCampTransferDetailList;

    this.personService.getPersonCampStaffMember((res) => {
      this.camp_staff_member = res;
      this.personCampTransferDetailFormList.forEach(element => {
        element.updateCampInfo();
      });
    })

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      if (this.personService.currentPersonCampTransferDetailList != null) {

        let newFormModel = null;
        if (typeof this.personCampTransferDetailFormList.first.model.id == "undefined"
          && this.personCampTransferDetailFormList.first.currentForm.form.dirty
          && this.personCampTransferDetailFormList.first.currentForm.form.pending) {
          newFormModel = this.personCampTransferDetailFormList.first.model;
        }

        this.personCampTransferDetailListKeys = Object.keys(this.personService.currentPersonCampTransferDetailList)

        this.personCampTransferDetailListKeys = this.personCampTransferDetailListKeys.reverse();

        let personCampTransferDetailListOld = this.personCampTransferDetailList;
        this.personCampTransferDetailList = this.personService.currentPersonCampTransferDetailList;

        this.personCampTransferDetailListKeys.forEach(k => {

          if (typeof personCampTransferDetailListOld[k] != "undefined" && this.personCampTransferDetailList[k]["updated_at"] == personCampTransferDetailListOld[k]["updated_at"])
            this.personCampTransferDetailList[k] = personCampTransferDetailListOld[k];
        });

        if (newFormModel != null) {
          this.personCampTransferDetailList[0] = newFormModel;
          this.personCampTransferDetailListKeys.unshift(0);
        }

      }

      if (this.personService.currentPersonCampTransferDetailList != null && this.personService.currentPersonCampTransferDetailList.length == 0) {
        this.addNew();
      }

      this.hideModalCopyPaste();
    });

    if (this.personCampTransferDetailList == null || typeof this.personCampTransferDetailList == "undefined") {
      this.addNew();
    }
    else {
      this.personCampTransferDetailListKeys = Object.keys(this.personCampTransferDetailList)
      this.personCampTransferDetailListKeys = this.personCampTransferDetailListKeys.reverse();
    }
  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }


  addNew() {

    //	cancello sempre la lista
    if (this.personCampTransferDetailList == null || typeof this.personCampTransferDetailList == "undefined") {
      this.personCampTransferDetailList = [];
    }

    if (this.personCampTransferDetailListKeys == null || typeof this.personCampTransferDetailListKeys == "undefined") {
      this.personCampTransferDetailListKeys = [];
    }

    this.personCampTransferDetailList[0] = {};
    this.personCampTransferDetailListKeys.unshift(0);
  }

  saveAll() {

    this.allFormValid = true;

    // Controllo se tutti i form sono validi
    this.personCampTransferDetailFormList.forEach(element => {

      if (!element.currentForm.form.valid) {

        //  Controllo se non è valido

        for (var i in element.currentForm.controls) {
          element.currentForm.controls[i].markAsTouched();
        }

        this.allFormValid = false;
        this.notify.error("There is a form with error");
      }

    });

    if (!this.allFormValid)
      return;

    this.personCampTransferDetailFormList.forEach(element => {
      element.submit();
    });
  }



  formDelete(event) {
    if (!event.model.id && this.personCampTransferDetailListKeys.length > 1) {
      this.personCampTransferDetailListKeys.splice(0, 1);
      delete this.personCampTransferDetailList[0];
    }
  }

  formSaved(event) {

  }

  getPersonService() {
    return this.personService
  }

  openModalCopyAndPaste() {
    this.campService.getLastCampTransferDetails((results) => {
      this.campTransferDetailsCopyPaste = results;
      this.showModalCopyPaste();
    }, () => {

    });
  }

  isModalCopyPasteShow: boolean = false;
  showModalCopyPaste() {
    this.isModalCopyPasteShow = true;
    this.copyPasteModal.show();
  }

  hideModalCopyPaste() {
    this.copyPasteModal.hide();
  }

  onHiddenCopyPasteModal() {
    this.isModalCopyPasteShow = false;
  }

  copyAndPaste(details) {
    console.log(details);

    details.id = null;
    details.file_path = null;
    details.updated_at = null;
    details.created_at = null;
    details.from_camp = null;
    details.to_camp = null;
    details.person = null;

    // siccome nel form solo se è presente l'id questo campo viene settato, in questo caso lo forzo da qui perché ho annullato l'id
    if (details.from_camp_id == null)
      details.orientation_returns = 1;
    else
      details.orientation_returns = 0;

    // aggiungo un nuovo form vuoto
    this.addNew();

    this.hideModalCopyPaste();

    // sovrascrivo il model di quel trasfer details
    setTimeout(() => {
      this.personCampTransferDetailFormList.first.overwriteModel(details);
    }, 300);
  }

}
