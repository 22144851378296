<div *ngIf="!getTheatrinoService().ongoingRequest">
  <div>
    <h3 class="form-section">Theatrino Accomodation List</h3>
  </div>

  <ngx-datatable
  class="striped"
  [rows]="rows"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [selected]="selected"
  [selectionType]="'single'"
  (activate)="onActivate($event)"
  (select)='onSelect($event)'
  >

  <ngx-datatable-column name="Date" prop="date_formatted" [flexGrow]="1" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Accomodation" prop="accomodation.denomination" [flexGrow]="3" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Actor Group" prop="accomodation.group_name" [flexGrow]="3" [resizeable]="false"></ngx-datatable-column>

  </ngx-datatable>

</div>
