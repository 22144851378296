import { Component, OnInit, ViewChild } from "@angular/core";
import {
  HelperService,
  ConfigService,
  UserService,
  CourseScheduleService,
} from "app/_services";
import { NotificationsService } from "angular2-notifications";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ModalDirective } from 'ngx-bootstrap/modal';
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

@Component({
  selector: "app-course-schedule-subscriber-groups",
  templateUrl: "./course-schedule-subscriber-groups.component.html",
  styleUrls: ["./course-schedule-subscriber-groups.component.css"],
})
export class CourseScheduleSubscriberGroupsComponent implements OnInit {
  @ViewChild("staticModal", { static: false })
  public staticModal: ModalDirective;

  courseScheduleGroupList: any[] = [];
  subscriberGroupList: any[] = [];

  subscribeGroup: any;
  subscribeSubscriberList: any;

  selected: any[] = [];

  libri: any;

  constructor(
    private courseScheduleService: CourseScheduleService,
    private helper: HelperService,
    private configService: ConfigService,
    private notify: NotificationsService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.subscribeGroup = this.courseScheduleService
      .getsubjectToUpdateObservable()
      .subscribe((update: boolean) => {
        this.getGroupsInfo();
      });

    this.subscribeSubscriberList = this.courseScheduleService
      .getsubjectToUpdateObservable()
      .subscribe((update: boolean) => {
        this.getSubscriberInfo();
      });

    this.getGroupsInfo();
    this.courseScheduleService.getCourseSubscriber();
  }

  ngOnDestroy() {
    this.subscribeGroup.unsubscribe();
    this.subscribeSubscriberList.unsubscribe();
  }

  getGroupsInfo() {
    if (this.courseScheduleService.currentCourseGroups != null) {
      this.courseScheduleGroupList = this.courseScheduleService.currentCourseGroups;
    }
  }

  getSubscriberInfo() {
    if (this.courseScheduleService.currentCourseSubscriberList != null) {
      this.prepareWeekSubscribers();
    }
  }

  prepareWeekSubscribers() {
    //  per ogni settimana del campo setto i0 subscriber che ne sono iscritti
    var subs = JSON.parse(
      JSON.stringify(
        this.courseScheduleService.currentCourseSubscriberList.filter(
          (x) => !x.deleted_at
        )
      )
    );

    //  per ogni iscritto della settimana prendo solo la settimana corrispondente
    subs.forEach((subscriber) => {
      let group = undefined;

      //  controllo che ci sia un gruppo per questo subscriber
      if (
        typeof subscriber.groups != "undefined" &&
        subscriber.groups != null
      ) {
        group = JSON.parse(
          JSON.stringify(
            this.courseScheduleGroupList.find(
              (x) => x.id == subscriber.groups.course_schedule_group_id
            )
          )
        );
      }

      //  se trovo il gruppo lo assegno
      if (typeof group != "undefined" && group != null) {
        subscriber.group = group;
        subscriber.group.group_id = subscriber.groups.id;
        subscriber.group.subscriber_id = subscriber.id;
        subscriber.group.name = group.group_name;
        subscriber.group.course_schedule_group_id =
          subscriber.groups.course_schedule_group_id;
      }

      //  se il gruppo non c'è lo inizializzo comunque
      if (typeof subscriber.group == "undefined") {
        subscriber.group = {};
        subscriber.group.name = "";
      }
    });

    //  inserisco la settimana nell'array
    this.subscriberGroupList = subs;
  }

  currentModify: any = {};
  modifyGroup(row) {
    this.currentModify.subscriber = JSON.parse(JSON.stringify(row));
  }

  closeGroup() {
    this.currentModify = {};
  }

  loading: boolean = false;
  changeGroup(row) {
    //  inserisco un nuovo gruppo per questo iscritto
    if (
      typeof this.currentModify.subscriber.group.course_schedule_group_id ==
        "undefined" &&
      typeof this.currentModify.subscriber.group.id == "undefined"
    ) {
      //  controllo se non è stato selezionato un gruppo valido
      if (row.group.course_schedule_group_id < 0) return;

      let model = {
        subscriber_id: row.id,
        course_schedule_group_id: row.group.course_schedule_group_id,
      };
      this.saveSubscriberGroup(row, model, () => {
        this.notify.success("Subscriber Group", "successfully saved");
      });
      return;
    }

    //  questo iscritto ha già un gruppo
    if (
      typeof this.currentModify.subscriber.group.course_schedule_group_id !=
        "undefined" &&
      typeof this.currentModify.subscriber.group.id != "undefined"
    ) {
      if (row.group.course_schedule_group_id > 0) {
        this.updateSubscriberGroup(row, () => {
          this.notify.success("Subscriber Group", "successfully changed");
        });
        return;
      }

      if (row.group.course_schedule_group_id < 0) {
        this.deleteSubscriberGroup(row, () => {
          this.notify.success("Subscriber Group", "successfully deleted");
        });
        return;
      }
    }
  }

  saveSubscriberGroup(row, model, callbackSuccess: any = null) {
    this.loading = true;
    this.courseScheduleService.saveCourseSubscriberGroup(
      model,
      (newGroup) => {
        var subIndex = this.subscriberGroupList.findIndex(
          (x) => x.id == newGroup.subscriber_id
        );
        if (this.subscribeSubscriberList[subIndex]) {
          this.subscriberGroupList[subIndex].groups = newGroup;
          this.subscriberGroupList[subIndex].group = newGroup;
          this.subscriberGroupList[subIndex].group.group_id = this.subscriberGroupList[subIndex].groups.id;
          this.subscriberGroupList[subIndex].group.subscriber_id = this.subscriberGroupList[subIndex].id;
          this.subscriberGroupList[subIndex].group.name = this.courseScheduleGroupList.find((x) => x.id == newGroup.course_schedule_group_id).group_name;
          this.subscriberGroupList[subIndex].group.course_schedule_group_id = this.subscriberGroupList[subIndex].groups.course_schedule_group_id;
        }

        this.currentModify = {};
        this.loading = false;
        if (callbackSuccess != null) callbackSuccess();
      },
      () => {
        this.loading = false;
      }
    );
  }
  updateSubscriberGroup(row, callbackSuccess: any = null) {
    this.loading = true;
    this.courseScheduleService.updateCourseSubscriberGroup(
      row.group,
      (updateGroup) => {
        var subIndex = this.subscriberGroupList.findIndex(
          (x) => x.id == updateGroup.subscriber_id
        );
        if (this.subscriberGroupList[subIndex]) {
          this.subscriberGroupList[subIndex].groups = updateGroup;
          this.subscriberGroupList[subIndex].group = updateGroup;
          this.subscriberGroupList[subIndex].group.group_id = this.subscriberGroupList[subIndex].groups.id;
          this.subscriberGroupList[subIndex].group.subscriber_id = this.subscriberGroupList[subIndex].id;
          this.subscriberGroupList[subIndex].group.name = this.courseScheduleGroupList.find((x) => x.id == updateGroup.course_schedule_group_id).group_name;
          this.subscriberGroupList[subIndex].group.course_schedule_group_id = this.subscriberGroupList[subIndex].groups.course_schedule_group_id;
        }

        this.currentModify = {};
        this.loading = false;
        if (callbackSuccess != null) callbackSuccess();
      },
      () => {
        this.loading = false;
      }
    );
  }
  deleteSubscriberGroup(row, callbackSuccess: any = null) {
    this.loading = true;
    this.courseScheduleService.deleteCourseSubscriberGroup(
      row.group,
      () => {
        row.groups = null;
        row.group = {};
        row.group.name = "";
        this.currentModify = {};
        this.loading = false;
        if (callbackSuccess != null) callbackSuccess();
      },
      () => {
        this.loading = false;
      }
    );
  }

  multiSelection: boolean = false;
  multiSelectionCourseScheduleGroupId = undefined;

  enableMultipleSelection() {
    this.multiSelection = true;
  }
  cancelMultipleSelection() {
    this.selected = [];
    this.multiSelection = false;
  }
  saveMultipleSelection() {
    this.currentMultisave = 0;

    if (this.selected.length == 0)
      this.notify.warn("Subscriber Group", "No subscribers selected");

    //  controllo se non è stato selezionato un gruppo valido
    if (typeof this.multiSelectionCourseScheduleGroupId == "undefined") return;

    this.selected.forEach((subscriber) => {
      if (typeof subscriber.group.id == "undefined") {
        let model = {
          subscriber_id: subscriber.id,
          course_schedule_group_id: this.multiSelectionCourseScheduleGroupId,
        };
        this.saveSubscriberGroup(subscriber, model, () => {
          this.currentMultisave++;
          this.checkMultipleSave();
        });
        return;
      }

      //  questo iscritto ha già un gruppo
      if (typeof subscriber.group.id != "undefined") {
        subscriber.group.course_schedule_group_id = this.multiSelectionCourseScheduleGroupId;

        if (subscriber.group.course_schedule_group_id > 0) {
          this.updateSubscriberGroup(subscriber, () => {
            this.currentMultisave++;
            this.checkMultipleSave();
          });
          return;
        }

        if (subscriber.group.course_schedule_group_id < 0) {
          this.deleteSubscriberGroup(subscriber, () => {
            this.currentMultisave++;
            this.checkMultipleSave();
          });
          return;
        }
      }
    });
  }
  currentMultisave = 0;
  checkMultipleSave() {
    if (this.currentMultisave >= this.selected.length) {
      this.notify.success(
        "Subscriber Group",
        this.currentMultisave + " successfully changed"
      );
      this.cancelMultipleSelection();
    }
  }

  onSelect({ selected }) {
    // console.log(this.selected);
  }

  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
    this.staticModal.show();
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }

  /*  Salvo i dati nel formato Excel
   */
  exportToExcel() {
    this.showModalExport();

    this.exportParse(this.subscriberGroupList, () => {
      this.hideModalExport();
    });
  }

  /*
   * Questo metodo prepara l'esportazione dei dati
   * da inviare poi ad un file excel o csv
   */
  exportParse(data: any, callback: any) {
    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach((element) => {
      //  bonifico
      Object.keys(element).forEach((key) => {
        if (element[key] == null) element[key] = "";
      });

      let obj = {
        Nome: element.person_info.first_name,
        Cognome: element.person_info.surname,
        Eta: this.helper.calculateAge(element.person_info.birthday),
        Sesso: this.configService.getGenderPersonValueByKey(
          element.person_info.gender
        ),
        Gruppo: typeof element.group != "undefined" ? element.group.name : "",
      };

      //  preparo
      exportedArray.push(obj);
    });

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet["!cols"] = [
      { width: 18 },
      { width: 20 },
      { width: 10 },
      { width: 12 },
      { width: 30 },
    ];

    //  personalizzo l'header
    worksheet["!rows"] = [{ hpx: 30 }];

    //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = {
      Sheets: { Subscribers: worksheet },
      SheetNames: ["Subscribers"],
    };

    //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      bookSST: false,
      type: "array",
    });

    //  Salvo il file tramite il browser
    FileSaver.saveAs(
      new Blob([excelBuffer], { type: EXCEL_TYPE }),
      "subscribers course groups.xlsx"
    );

    callback();
  }

  getRowClass(row) {
    if (row.person_info) {
      return {
        "course-schedule-staff-deleted": row.person_info.deleted_at !== null,
      };
    }

    return "";
  }

  isOngoingRequest() {
    return this.courseScheduleService.isOngoingRequest();
  }

  getCourseScheduleService() {
    return this.courseScheduleService;
  }

  getConfigService() {
    return this.configService;
  }

  getHelper() {
    return this.helper;
  }

  getUserService() {
    return this.userService;
  }
}
