import { ModalDirective } from 'ngx-bootstrap/modal';
import { BackendService } from './../../_services/backend.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ConfigService, HelperService, TheatrinoService  } from "../../_services/index";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { parseISO, isEqual } from "date-fns";
import {
  IAngularMyDpOptions,
  IMyDateModel } from 'angular-mydatepicker';

@Component({
  selector: 'app-theatrino-booking-copy',
  templateUrl: './theatrino-booking-copy.component.html',
  styleUrls: ['./theatrino-booking-copy.component.css']
})
export class TheatrinoBookingCopyComponent implements OnInit {

  @Input() model: any;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('confirmModal', {static: false}) confirmModal: ModalDirective;

  private localData: IMyDateModel;

  timevalid: boolean = true;
  showcopy: boolean = true;

  theatrinoList: any = [];
  theatrinoInfo: any;

  loadingData: boolean = false;

  //  Impostazione della data che vengono passate dall'helper
  public datePickerOptions: IAngularMyDpOptions = {};

  constructor(private backend: BackendService, private theatrinoService: TheatrinoService, private helper: HelperService,
              private configService: ConfigService, private notify: NotificationsService,
              private router: Router)
            {
              //  Inizializzo le info sulle date
              this.datePickerOptions = helper.datePickerOptions;
            }


  ngOnInit() {

    //  Devo copiare il model passato ricreando un nuovo oggetto
    this.model =  JSON.parse(JSON.stringify(this.model));

    this.theatrinoList = [];

    this.loadingData = true;

    this.backend.post('theatrino/alltheatrinos', null).subscribe(
      (response) => {

        this.theatrinoList = response;

        //  ordino alfabeticamente in base al nome del theatrino
        this.theatrinoList.sort(function(a, b){
            if (a.denomination.toLowerCase() < b.denomination.toLowerCase())
                return -1
            if (a.denomination.toLowerCase() > b.denomination.toLowerCase())
                return 1
            return 0;
      })

        this.getAllTheatrinoInfoLight();
      },
      (error) => {

        this.backend.showErrors(error);
        console.log(error);

        this.loadingData = false;
      }
    );

  }

  getAllTheatrinoInfoLight ()
  {
    this.loadingData = true;

    let params = new HttpParams();

    this.backend.get('theatrino/' + this.model.theatrino_id + '/light', params).subscribe(
      (response) => {

        this.theatrinoInfo = response;

        //  Setto le informazioni aggiuntive sul theatrino
        this.checkTheatrino();

        this.loadingData = false;
      },
      (error) => {

        this.backend.showErrors(error);
        console.log(error);

        this.loadingData = false;
      }
    );
  }


  checkTheatrino ()
  {
    // this.datePickerOptions.disableSince = {
    //   year: 2020,
    //   month: 3,
    //   day: 31
    // }
    // this.datePickerOptions.disableUntil = {
    //   year: 2020,
    //   month: 1,
    //   day: 5
    // };

    if (this.model.date != null) {
      this.localData = this.helper.initMyDataInputSingle(this.model.date);
    }

    //  Elimino date di inizio, fine e dayoff dal theatrino che sto copiando in questo momento
    var dateStart = parseISO(
      this.theatrinoInfo.start
    );
    this.datePickerOptions.disableUntil = {
      year: dateStart.getFullYear(),
      month: dateStart.getMonth() + 1,
      day: dateStart.getDate() - 1
    };

    var dateEnd = parseISO(this.theatrinoInfo.end);
    this.datePickerOptions.disableSince = {
      year: dateEnd.getFullYear(),
      month: dateEnd.getMonth() + 1,
      day: dateEnd.getDate() + 1
    };

    //  Disabilito anche tutte le date che non sono selezionabili in quanto day off
    var dateOff = [];
    this.theatrinoInfo.tour_date.forEach(
      element => {
        if (this.helper.isADateTourDayOff(element.type)){
          var day = parseISO(element.date);
          dateOff.push({
            year: day.getFullYear(),
            month: day.getMonth() + 1,
            day: day.getDate()
          });
        }
      }
    );

    this.datePickerOptions.disableDates = dateOff;
  }


  submit()
  {
    var found = false;

    for (var i = 0, len = this.theatrinoInfo.tour_date.length; i < len ; i++) {

      if (isEqual(HelperService.parseDate(this.model.date), HelperService.parseDate(this.theatrinoInfo.tour_date[i].date)))
        if (this.helper.isADateTourStandard(this.theatrinoInfo.tour_date[i].type)) {
          found = true;
          this.confirmModal.show();
          break;
        }
    }

    if (!found)
      this.confirmSubmit();

  }


  confirmSubmit()
  {

    //  nascondo la modale
    this.confirmModal.hide();

    //  Controllo se la data non è valida
    if (!this.helper.isMyDateValid(this.localData)) return;

    //  Controllo se il time non è valido
    this.onTimeInput("");
    if (!this.timevalid) {
      this.model.time = "";
      this.notify.error("You need to set a valid Time for Tour");
      return;
    }

    //  Preparo il model prima di inviarlo
    this.model.id = null;
    this.model.agreement_id = null;

    //  Cancello i dati non necessari al salvataggio degli show
    Object.keys(this.model.shows).forEach(key => {

      this.model.shows[key].id = null;
      this.model.shows[key].id = null;
      this.model.shows[key].tour_date_id = null;
    });

    //  Salvo tutto e mando l'utente alla modifica della data
    this.theatrinoService.cloneTheatrinoTourDateInfo(this.model, this.showcopy, () => {

        // this.router.navigate([ "theatrino/" +
        //   this.model.theatrino_id + "/tour/edit/" +
        //   this.theatrinoService.currentTourId
        // ]);

        this.closeModal.emit(true);
    })

  }



  onSelectChange(event)
  {
    this.model.theatrino_id = event;

    //  Recupero le informazioni di questo theatrino
    this.getAllTheatrinoInfoLight();
  }


  //  Metodi per la data
  onDateChanged(event: IMyDateModel) {

    this.localData = event;

    if (event.singleDate.jsDate != null)
      this.model.date = this.helper.formatISODate(event);
    else
      this.model.date = null;
  }


  //  Metodi per il timer
  onTimeInput(event) {
    this.timevalid = false;

    //  controllo se è valido il time
    this.configService.timeTourSelect.forEach(element => {
      if (element.value == this.model.time) return (this.timevalid = true);
    });
  }
  typeTimeOnSelect(event) {
    //  Se arriva questo evento al 100% è una data valida
    this.timevalid = true;
  }

  getTheatrinoService() {
    return this.theatrinoService
  }
  getConfigService() {
    return this.configService
  }

  getLocalData() {
    return this.localData
  }
  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }
}
