<div class="row" *ngIf="showAddInOutButton">
  <div class="col-md-6" style="margin-bottom: 20px">
    <button type="button" class="btn green-jungle btn-block"
      [ngClass]="{'btn-outline': isAddIn === false || isAddIn == null}" (click)="showFormAddIn();"><i
        class="fa fa-plus"></i>&nbsp; Add In</button>
  </div>
  <div class="col-md-6" style="margin-bottom: 20px">
    <button type="button" class="btn red btn-block" [ngClass]="{'btn-outline': isAddIn === true || isAddIn == null}"
      (click)="showFormAddOut();"><i class="fa fa-minus"></i>&nbsp; Add Out</button>
  </div>
</div>

<alert type="warning" *ngIf="loadingData">
  <strong>Loading</strong> ...
</alert>


<!-- BEGIN FORM -->
<form *ngIf="showForm" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm">

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 *ngIf="isAddIn == true" class="form-section">Add In</h3>
    <h3 *ngIf="isAddIn == false" class="form-section">Add Out</h3>

    <div *ngIf="isAddIn" class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">From</label>
          <div class="row">
            <div class="col-md-12" *ngIf="otherHolderName">
              <h2>{{ otherHolderName }}</h2>
              <button type="button" class="btn btn-block btn-outline red" (click)="removeOtherHolder()"><i
                  class="fa fa-trash-o"></i> Remove</button>
            </div>
            <div class="col-md-12" *ngIf="!otherHolderName">
              <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('person')"><i
                  class="fa fa-user"></i> Person</button>
              <button type="button" class="btn btn-block btn-outline green"
                (click)="addEntityFromModal('enterprise')"><i class="fa fa-industry"></i> Enterprise</button>
              <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('group')"><i
                  class="fa fa-group"></i> Group</button>
              <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('camp')"><i
                  class="fa fa-free-code-camp" aria-hidden="true"></i> Camp</button>
              <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('course')"><i
                  class="fa fa-university" aria-hidden="true"></i> Course</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <label class="control-label"><i class="fa fa-arrow-right"></i></label>
        <h2 class="font-green-jungle">{{ (model.amount | currency:'EUR':'symbol':'1.2-2':'it') || "€ 0,00" }}</h2>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">To</label>
          <div class="row">
            <div class="col-md-12">
              <h2>{{ holderName }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isAddIn" class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">From</label>
          <div class="row">
            <div class="col-md-12">
              <h2>{{ holderName }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <label class="control-label"><i class="fa fa-arrow-right"></i></label>
        <h2 class="font-red-haze">{{ (model.amount | currency:'EUR':'symbol':'1.2-2':'it') || "€ 0,00" }}</h2>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">To</label>
          <div class="row">
            <div class="col-md-12" *ngIf="otherHolderName">
              <h2>{{ otherHolderName }}</h2>
              <button type="button" class="btn btn-block btn-outline red" (click)="removeOtherHolder()"><i
                  class="fa fa-trash-o"></i> Remove</button>
            </div>
            <div class="col-md-12" *ngIf="!otherHolderName">
              <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('person')"><i
                  class="fa fa-user"></i> Person</button>
              <button type="button" class="btn btn-block btn-outline green"
                (click)="addEntityFromModal('enterprise')"><i class="fa fa-industry"></i> Enterprise</button>
              <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('group')"><i
                  class="fa fa-group"></i> Group</button>
              <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('camp')"><i
                  class="fa fa-free-code-camp" aria-hidden="true"></i> Camp</button>
              <button type="button" class="btn btn-block btn-outline green" (click)="addEntityFromModal('course')"><i
                  class="fa fa-university" aria-hidden="true"></i> Course</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalData())) }">
          <label class="control-label">Date <span class="required">*</span></label>
          <div class="input-group">
            <!-- input box -->
            <input angular-mydatepicker #dp_date_payment="angular-mydatepicker" name="date_payment" class="form-control"
              [locale]="getHelper().locale" [options]="getDatePickerOptions()" [ngModel]="getLocalData()"
              (dateChanged)="onDateChanged($event)" />
            <!-- clear date button -->
            <span class="input-group-btn">
              <button type="button" *ngIf="model?.date_payment" class="btn default"
                (click)="dp_date_payment.clearDate()"><i class="fa fa-close"></i></button>
            </span>
            <!-- toggle calendar button -->
            <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_date_payment.toggleCalendar()"><i
                  class="fa fa-calendar-o"></i></button>
            </span>
          </div>
          <span class="help-block">Field required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || registeredvia.dirty || registeredvia.touched) && !registeredvia.valid }">
          <label class="control-label">Registered via <span class="required">*</span></label>
          <select class="form-control" name="registered_via" [(ngModel)]="model.registered_via" #registeredvia="ngModel"
            required>
            <option *ngFor="let c of getConfigService().primanotaRegisteredViaSelect" [value]="c.key">{{c.value}}
            </option>
          </select>
          <span class="help-block">Field required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || amount.dirty || amount.touched) && !amount.valid }">
          <label class="control-label">Amount <span class="required">*</span></label>
          <input type="number" class="form-control" (input)="onInputAmount($event)" placeholder="0,00" step="0.01"
            name="amount" [(ngModel)]="model.amount" #amount="ngModel" required>
          <span class="help-block">Field required, use "," for decimal value</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Causal</label>
          <select class="form-control" name="causal" [(ngModel)]="model.causal">
            <option value="">-</option>
            <option *ngFor="let c of casualSelect" [value]="c.key">{{c.value}}</option>
          </select>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" placeholder="Some notes..." name="annotation"
            [(ngModel)]="model.annotation"></textarea>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><i class="fa fa-file-text" aria-hidden="true"></i> Fiscal Document</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="fiscal_document" [(ngModel)]="model.fiscal_document" value="1"> Yes, there is
              a fiscal document for this payment
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-3" [appCheckPermission]="'primanota_edit'">
        <div class="form-group">
          <label><i class="fa fa-check-circle" aria-hidden="true"></i> Verified</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="verified_transaction" [(ngModel)]="model.verified_transaction" value="1">
              Transaction verified
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="col-xs-6">
        <div class="form-group">
          <label><i class="fa fa-file-text" aria-hidden="true"></i> Dati di fatturazione</label>
          <p *ngIf="!model.invoice_data">-</p>
          <p *ngIf="model.invoice_data">
            {{model.invoice_data.nome}}<br><br>
            <b>Indirizzo</b><br>
            {{model.invoice_data.indirizzo_via}}<br>
            {{model.invoice_data.indirizzo_citta}} ({{model.invoice_data.indirizzo_provincia}})
            {{model.invoice_data.indirizzo_cap}}<br>
          </p>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="form-group">
          <label class="control-label">&nbsp;</label>
          <p *ngIf="model.invoice_data">
            <b *ngIf="model.invoice_data.cf">Codice Fiscale: </b> {{model.invoice_data.cf}}<br><br>
            <b *ngIf="model.invoice_data.piva">Partita IVA: </b> {{model.invoice_data.piva}}<br>
          </p>
        </div>
      </div>
    </div>

  </div>

  <div class="form-actions right">
    <button *ngIf="getUserService().checkPermission('prima_nota_revisions') && model.id" type="button"
      class="btn btn-outline green" (click)="showRevision = !showRevision">Revisions</button>
    <button *ngIf="showCloseButton" type="button" class="btn btn-outline green" (click)="closeForm();">&times;
      Close</button>
    <img *ngIf="getPrimaNotaService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" [appCheckPermission]="'prima_nota_delete'" class="btn btn-outline red"
      [disabled]="getPrimaNotaService().isOngoingRequest()" *ngIf="this.model.id" [outsideClick]="true"
      [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <!-- <button type="button" class="btn btn-outline red" [disabled]="getPrimaNotaService().isOngoingRequest()" *ngIf="!this.model.id" (click)="delete()"><i class="fa fa-trash-o"></i> Delete</button> -->

    <button type="submit" class="btn blue" [disabled]="getPrimaNotaService().isOngoingRequest()"
      [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>

<ngx-datatable class="striped" [rows]="rowsRevision" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0"
  [rowHeight]="'auto'" *ngIf="getUserService().checkPermission('prima_nota_revisions') && showRevision && model.id">
  <ngx-datatable-column name="Date" [flexGrow]="1" [sortable]="true" [resizeable]="false" prop="revision.created_at">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      {{value}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="User" [flexGrow]="1" [sortable]="true" [resizeable]="false" prop="person.name">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <a routerLink="/person/edit/{{row.person.id}}">{{value}}</a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Action" [flexGrow]="1" [sortable]="false" [resizeable]="false" prop="revision.key">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="row.revision.key == 'created_at'"><i class="fa fa-plus-square"></i> Created</span>
      <span *ngIf="row.revision.key == 'deleted_at'"><i class="fa fa-trash-o"></i> Deleted</span>
      <span *ngIf="row.revision.key != 'created_at' && row.revision.key != 'deleted_at'"><i class="fa fa-edit"></i>
        Edit</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Log" [flexGrow]="4" [sortable]="false" [resizeable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      field <b>'{{row.revision.key}}'</b> in {{row.revision.revisionable_type}} (ID: {{row.revision.revisionable_id}})
      <b>from</b> <i>'{{row.revision.old_value}}'</i> <b>to</b> <i>'{{row.revision.new_value}}'</i>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>






<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="modal-title pull-left">Add reference</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Person -->
      <div class="modal-body" *ngIf="modalEntityType=='person'">
        <div class="row">
          <div class="col-md-12">
            <app-person-search-form #personSearchForm (searchKeywordsSubmit)="searchPersonKeywordsSubmit($event)"
              [ongoingRequest]="personDatatable.ongoingRequest"></app-person-search-form>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <div class="portlet light bordered">
              <div class="portlet-body">
                <app-person-datatable #personDatatable [query]="personSearchForm.searchKeyword"
                  (personSelected)="personSelected($event)"></app-person-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Enterprise -->
      <div class="modal-body" *ngIf="modalEntityType=='enterprise'">
        <div class="row">
          <div class="col-md-12">
            <app-enterprise-search-form #enterpriseSearchForm
              (searchKeywordsSubmit)="searchEnterpriseKeywordsSubmit($event)"
              [ongoingRequest]="enterpriseDatatable.ongoingRequest"></app-enterprise-search-form>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <div class="portlet light bordered">
              <div class="portlet-body">
                <app-enterprise-datatable #enterpriseDatatable [query]="enterpriseSearchForm.searchKeyword"
                  (enterpriseSelected)="enterpriseSelected($event)"></app-enterprise-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Group -->
      <div class="modal-body" *ngIf="modalEntityType=='group'">
        <div class="row">
          <div class="col-md-12">
            <app-actor-group-search-form #groupSearchForm (searchKeywordsSubmit)="searchGroupKeywordsSubmit($event)"
              [ongoingRequest]="groupDatatable.ongoingRequest"></app-actor-group-search-form>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <div class="portlet light bordered">
              <div class="portlet-body">
                <app-actor-group-datatable #groupDatatable (groupSelected)="groupSelected($event)">
                </app-actor-group-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Camp -->
      <div class="modal-body" *ngIf="modalEntityType=='camp'">
        <div class="row">
          <div class="col-md-12">
            <app-camp-search-form #campSearchForm (searchKeywordsSubmit)="searchCampKeywordsSubmit($event)"
              [ongoingRequest]="campDatatable.ongoingRequest"></app-camp-search-form>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <div class="portlet light bordered">
              <div class="portlet-body">
                <app-camp-datatable #campDatatable (campSelected)="campSelected($event)"></app-camp-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- Course -->
      <div class="modal-body" *ngIf="modalEntityType=='course'">
        <div class="row">
          <div class="col-md-12">
            <app-course-schedule-search-form #courseSearchForm
              (searchKeywordsSubmit)="searchCourseKeywordsSubmit($event)"
              [ongoingRequest]="courseDatatable.ongoingRequest"></app-course-schedule-search-form>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <div class="portlet light bordered">
              <div class="portlet-body">
                <app-course-schedule-datatable #courseDatatable (courseScheduleSelected)="courseSelected($event)"
                  [smallTableVersion]="true"></app-course-schedule-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
