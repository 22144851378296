import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { environment } from '../environment';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class CheckVersionService {

  constructor(private http: HttpClient, private notify: NotificationsService) { }

  public initVersionCheck(frequency = 1000 * 60 * 60 * 2) {
    setInterval(() => {
      this.checkVersion(environment.checkVersionUrl);
    }, frequency);
  }

  private checkVersion(url) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime())
        .subscribe(
            (response: any) => {
                const ver : any = response.version;
                const hashChanged = environment.version != ver;

                // If new version, do something
                if (hashChanged) {
                  this.notify.info("Aggiornamento "+ver, "una nuova versione di Gaston è disponibile, ricaricare la pagina per aggiornare", {
                    timeOut: 0
                  })
                }
            },
            (err) => {
                console.error(err, 'Could not get version');
            }
        );
}

}
