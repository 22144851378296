import { Router } from '@angular/router';
import { EnterpriseService, HelperService, ConfigService } from './../../_services/index';
import { Component, OnInit } from '@angular/core';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-enterprise-theatrino-agreement-list',
  templateUrl: './enterprise-theatrino-agreement-list.component.html',
  styleUrls: ['./enterprise-theatrino-agreement-list.component.css']
})
export class EnterpriseTheatrinoAgreementListComponent implements OnInit {

  enterpriseBookingList: any;
  enterpriseBookingListKeys: any[];
  agreementSuitable = false;

  rows: any[] = [];
  selected = [];

  constructor(private enterpriseService: EnterpriseService, private helper: HelperService,
              private configService: ConfigService, private router: Router) { }

  subscribe: any;
  ngOnInit()
  {
    this.rows = [];

    this.enterpriseBookingList = this.enterpriseService.currentEnterpriseBookingList;

    if (this.enterpriseBookingList == null)
      this.enterpriseBookingList = {};

    this.subscribe = this.enterpriseService.getsubjectToUpdateObservable().subscribe( (update) => {

      //  Controllo se arrivano date prenotate per generare una convenzione
      this.agreementSuitable = false;

      if (this.enterpriseService.currentEnterpriseBookingList != null)
      {
        this.enterpriseBookingList = this.enterpriseService.currentEnterpriseBookingList;
        this.enterpriseBookingListKeys = Object.keys(this.enterpriseBookingList);

        this.enterpriseBookingListKeys.forEach(key => {

            if (this.enterpriseBookingList[key].date != null)
              this.enterpriseBookingList[key].date_formatted = parseISO(this.enterpriseBookingList[key].date).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

            //  Se la prenotazione corrente non ha una convenzione assegnata e si trova nello stato di confermata allora posso permettere la generazione della convenzione
            if (this.enterpriseBookingList[key].agreement_id == null && this.enterpriseBookingList[key].status == 1)
              this.agreementSuitable = true;

          });
      }

      if (this.enterpriseService.currentEnterpriseBookingList != null && this.enterpriseService.currentEnterpriseBookingList.length == 0)
      {
        this.enterpriseBookingList = {};
      }

      //  Trasformo le informazioni sotto forma di array per la rappresenzazione in tabella
      this.rows = this.getTableData(this.enterpriseBookingList);

      // console.log(this.rows)
    });

  }

  getTableData(list) {

    if (typeof list == "undefined")
      return new Array();

      let data = new Array();

      for (let key in list) {
        let d = list[key];
        // valore testuale dello status
        d.status_value = this.configService.getTheatrinoStatusValueByKey(list[key].status);
        // colore dello status
        d.status_color = this.configService.getTheatrinoStatusColorByKey(list[key].status);
        //  nome del theatrino
        d.theatrino_name = list[key].theatrino.denomination;

        data.push(d);
      }

      return data;
  }

  onViewAgreement ( event ) {

    if (event)
      this.viewAgreement(event)
  }

  onSelect({ selected }) {

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);

  }

  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  onActivate(event){
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  getRowClass(row) {

    //   I day Off sono rappresentati con il colore rosso
    let obj = {};
    obj['font-'+row.status_color.name] = true;
    obj['border-left-before-'+row.status_color.name] = true;
    obj['border-left-2'] = true;
    return obj;

  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }


  newAgreement (tourDates: any[])
  {
    //  Vado alla pagina per la creazione dell'agreement
    this.router.navigate(['/enterprise/'+this.enterpriseService.currentEnterpriseId+'/agreement/new', { tourIds: JSON.stringify(tourDates) }]);
  }

  newMultiAgreement ()
  {
    var tourDates: any [] = [];

    this.selected.forEach(element => {
      tourDates.push(element.id);
    });

    //  Vado alla pagina per la creazione dell'agreement, in questo caso con più date prenotate
    this.router.navigate(['/enterprise/'+this.enterpriseService.currentEnterpriseId+'/agreement/new', { tourIds: JSON.stringify(tourDates) }]);
  }

  viewAgreement (key: any)
  {
    //  Vado alla pagina della visualizzazione di una agreement
    this.router.navigate(['/enterprise/'+this.enterpriseService.currentEnterpriseId+'/agreement/edit/' + this.enterpriseBookingList[key].agreement_id]);
  }

  editReservation(theatrino_id, id){
    this.router.navigate(["theatrino/" + theatrino_id + "/tour/edit/" + id]);
  }

  getEnterpriseService() {
    return this.enterpriseService
  }

}
