import { BackendService } from './../../_services/backend.service';
import { Component, OnInit } from '@angular/core';
import { EnterpriseService, HelperService, ConfigService } from '../../_services/index';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-enterprise-history',
  templateUrl: './enterprise-history.component.html',
  styleUrls: ['./enterprise-history.component.css']
})
export class EnterpriseHistoryComponent implements OnInit {

  subscribe: any;
  model: any = {};
  history_url_old_id: any = "";
  history_html: any;

  gaston_history: any = {
    // staff: []
  };

  loading: boolean = false;

  constructor(private enterpriseService: EnterpriseService, private helper: HelperService,
    private router: Router, private configService: ConfigService, private http: HttpClient,
    private backend: BackendService) { }

  ngOnInit() {

    this.subscribe = this.enterpriseService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.model = this.enterpriseService.currentEnterpriseInfo;

      if (this.model == null) this.model = {};
      else {

        // if (this.model.old_id) {
        //   //this.history_url_old_id = this.configService.blobBasePath + "history_old_id/"+ this.model.old_id + ".html";
        //   this.history_url_old_id = "https://gastonapp.blob.core.windows.net/history/" + this.model.old_id + ".html";
        //   this.http.get(this.history_url_old_id).subscribe(
        //     (response: Response) => {
        //       var text = response.text().replace(/<a\b[^>]*>/gi, "").replace(/<\/a>/gi, "")
        //       this.history_html = text;
        //     },
        //     (error) => {
        //       this.history_html = "There is no history from Gabriel for this enterprise...";
        //     });
        // }
      }

    });

  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }


  getHistory ()
  {
    if (this.model.id == null || typeof this.model.id == "undefined") return;

    this.loading = true;

    let params = new HttpParams();

    this.backend.get('enterprise/'+this.model.id+'/history', params).subscribe (
      (response) => {

        var res : any = response;

        /** TOUR BOOKING */
        if (typeof res.theatrino_tour_dates == "undefined")
          res.theatrino_tour_dates = [];

        //  faccio il sort in base alle date
        res.theatrino_tour_dates.sort(function(a, b) {
          return a.start < b.start ? -1 : a.start > b.start ? 1 : 0;
        });

        //   setto le date
        res.theatrino_tour_dates.forEach(element => {
          if (element.start)
            element.start_formatted = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.end)
            element.end_formatted = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.date)
            element.date_formatted = parseISO(element.date).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        });

        /** CAMPS */
        //  faccio il sort in base alle date
        res.camps.sort(function(a, b) {
          return a.start < b.start ? -1 : a.start > b.start ? 1 : 0;
        });

        //   setto le date
        res.camps.forEach(element => {
          if (element.start)
            element.start_formatted = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.end)
            element.end_formatted = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        });



        this.gaston_history = res;
        this.loading = false;
      },
      (error) => {
        this.backend.showErrors(error);
        this.loading = false;
      });
  }

  getConfigService() {
    return this.configService
  }
}
