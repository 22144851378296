import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { ConfigService, EnterpriseService, AddressService } from '../../_services/index';

@Component({
  selector: 'app-enterprise-search-form',
  templateUrl: './enterprise-search-form.component.html',
  styleUrls: ['./enterprise-search-form.component.css']
})
export class EnterpriseSearchFormComponent implements OnInit {

  model:any = { advancedSearchOn: false, simpleSearchKeyword: ""};

  private firstSubmit: boolean = false;

  private subject: any;

  public advancedSearchShow = false;

  regioniArray: any;
  provinceArray: any;

  @Output() searchKeywordsSubmit = new EventEmitter();

  @Input() ongoingRequest: boolean;

  @ViewChild('simpleSearchKeywordText', {static: true, read: ElementRef}) private searchInput: ElementRef;

  constructor(private route: ActivatedRoute, private router: Router, private configService: ConfigService,
              private enterpriseService: EnterpriseService, private addressService: AddressService) {

    this.regioniArray = this.addressService.getRegioni();
    this.provinceArray = this.addressService.getProvince();

  }

  ngOnInit() {

    this.subject = this.route.params.subscribe( params => {

      if (typeof params.advancedSearchOn != "undefined") {

        if (this.enterpriseService.lastSearchQueries != null)
          this.model = this.enterpriseService.lastSearchQueries;
      }

    })
  }

  //   quando la vista si è inizializzata setto il focus sul campo di ricerca
  ngAfterViewInit() {

    setTimeout(() => {
      if (typeof this.searchInput != "undefined")
        this.searchInput.nativeElement.focus();
    }, 600);

  }

  ngOnDestroy () {
    this.subject.unsubscribe();
  }

  fieldChange(event) {

    if (!this.firstSubmit)
      return;

    this.searchKeywordsSubmit.emit({});
  }

  submitSearch() {

    //  Elimino le chiavi che non hanno valore prima di fare le ricerche
    Object.keys(this.model).forEach(element => {
      if (!this.model[element])
        delete this.model[element];
    });

    //  memorizzo i parametri della ricerca attuale
    this.enterpriseService.lastSearchQueries = JSON.parse(JSON.stringify(this.model));

    this.firstSubmit = true;

    if (this.advancedSearchShow) {
      this.model.advancedSearchOn = true;
    }
    else {
      this.model = { advancedSearchOn: false, simpleSearchKeyword: this.model.simpleSearchKeyword };
    }

    this.advancedSearchShow = false;
    this.searchKeywordsSubmit.emit(this.model);
  }

  hideAdvancedSearch() {
    this.advancedSearchShow = false;
  }

  clearAdvancedSearch() {

    //  resetto il modello e tengo aperto il form di ricerca
    this.model = { advancedSearchOn: false, simpleSearchKeyword: "" };
    this.showAdvancedSearch();
  }

  showAdvancedSearch() {
    this.advancedSearchShow = true;
    this.model.simpleSearchKeyword = "";
  }

  getConfigService() {
    return this.configService
  }

  getEnterpriseService() {
    return this.enterpriseService
  }
}
