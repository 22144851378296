<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">
      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/tutorsapplications/home']">Tutors Applications</a>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Tutors Applications Edit
      </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <div>
        <tabset>
          <!-- Info TAB -->
          <tab heading="Tutor Info" id="info">

            <!-- begin: prima riga -->
            <div class="row">
              <div class="col-md-12">

                <!--begin: Portlet -->
                <div class="portlet light bordered">
                  <!--begin: Portlet-Body -->
                  <div class="portlet-body form">

                    <alert type="warning" *ngIf="loading">
                      <strong>Loading</strong> ...
                    </alert>

                    <div *ngIf="!loading">

                      <form action="#" name="form" class="horizontal-form" #f="ngForm" (ngSubmit)="submit()" novalidate>
                        <div class="form-body">
                          <div>
                            <alert *ngIf="tutorApplicationInfo.imported" type="warning">
                              <strong>Alert!</strong> Tutor already imported: this tutor is present in Person Database.
                            </alert>

                            <alert *ngIf="tutorApplicationInfo.person_id" type="info">
                              <button class="btn btn-block btn-outline btn-success"
                                routerLink="/person/edit/{{ tutorApplicationInfo.person_id }}">Vai alla scheda della
                                persona creata</button>
                            </alert>
                          </div>

                          <br>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group read-only">
                                <label class="control-label">Created At</label>
                                <p>{{ tutorApplicationInfo.created_at | date:'d MMMM, y - HH:mm':'':'it' }}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group read-only">
                                <label class="control-label">Last Update</label>
                                <p>{{ tutorApplicationInfo.updated_at | date:'d MMMM, y - HH:mm':'':'it' }}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group read-only">
                                <label class="control-label">Uid</label>
                                <p>{{ tutorApplicationInfo.uid }}<a class="btn btn-link btn-xs green" target="_blank" href="{{tutorApplicationFormUrl}}/{{ tutorApplicationInfo.uid }}/phase/{{ tutorApplicationInfo.phase }}/step1">Application form</a></p>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Phase</label>
                                <select class="form-control" name="phase" [(ngModel)]="tutorApplicationInfo.phase"
                                  #phase="ngModel">
                                  <option value="1">Phase 1</option>
                                  <option value="2">Phase 2 (PreTeaching)</option>
                                  <option value="3">Proposal (Phase 3)</option>
                                  <option value="4">Hire (Phase 4)</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Status</label>
                                <select class="form-control" name="status" [(ngModel)]="tutorApplicationInfo.status"
                                  #status="ngModel">
                                  <option value="CREATED">CREATED</option>
                                  <option value="SUBMITTED">SUBMITTED</option>
                                  <option value="EDITING">EDITING</option>
                                  <option value="COMPLETED">COMPLETED</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Edition</label>
                                <select class="form-control" name="edition"
                                  [(ngModel)]="tutorApplicationInfo.edition_id" #edition="ngModel">
                                  <option *ngFor="let opt of tutorApplicationEdition" [value]="opt.id">{{opt.title}}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section">Preferences and Annotation</h4>

                          <div class="row" *ngIf="tutorApplicationInfo.phase >= 1">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="control-label">Preference Phase 1</label>
                                <select class="form-control" name="status_01_promote_preference"
                                  [(ngModel)]="tutorApplicationInfo.status_01_promote_preference" #phase="ngModel">
                                  <option *ngFor="let t of getConfig().applicationPhasePreferenceSelect"
                                    [value]="t.key">{{t.value}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-7">
                              <div class="form-group">
                                <label class="control-label">Annotation</label>
                                <textarea name="status_01_promote_note"
                                  [(ngModel)]="tutorApplicationInfo.status_01_promote_note" rows="3"
                                  class="form-control" placeholder="Some notes..."></textarea>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-group">
                                <label class="control-label">&nbsp;</label>
                                <ng-template #phase1Reminder>
                                  <button type="button" class="btn btn-outline green-jungle"
                                    (click)="inviaMailTo($event, 'phase1_reminder')">Yes, send it!</button>
                                </ng-template>
                                <button type="button" [outsideClick]="true" [popover]="phase1Reminder"
                                  popoverTitle="Are you sure?" class="btn btn-outline blue form-control"><i
                                    class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Reminder</button>
                              </div>
                            </div>
                          </div>

                          <div class="row" *ngIf="tutorApplicationInfo.phase >= 2">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="control-label">Preference Phase 2</label>
                                <select class="form-control" name="status_02_promote_preference"
                                  [(ngModel)]="tutorApplicationInfo.status_02_promote_preference" #phase="ngModel">
                                  <option *ngFor="let t of getConfig().applicationPhasePreferenceSelect"
                                    [value]="t.key">{{t.value}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-7">
                              <div class="form-group">
                                <label class="control-label">Annotation</label>
                                <textarea name="status_02_promote_note"
                                  [(ngModel)]="tutorApplicationInfo.status_02_promote_note" rows="3"
                                  class="form-control" placeholder="Some notes..."></textarea>
                              </div>
                            </div>
                          </div>

                          <div class="row" *ngIf="tutorApplicationInfo.phase >= 3">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="control-label">Preference Phase 3</label>
                                <select class="form-control" name="status_03_promote_preference"
                                  [(ngModel)]="tutorApplicationInfo.status_03_promote_preference" #phase="ngModel">
                                  <option *ngFor="let t of getConfig().applicationPhasePreferenceSelect"
                                    [value]="t.key">{{t.value}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-7">
                              <div class="form-group">
                                <label class="control-label">Annotation</label>
                                <textarea name="status_03_promote_note"
                                  [(ngModel)]="tutorApplicationInfo.status_03_promote_note" rows="3"
                                  class="form-control" placeholder="Some notes..."></textarea>
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section">Personal info</h4>

                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || firstname.dirty || firstname.touched) && !firstname.valid && !firstname.disabled }">
                                <label class="control-label">Name</label>
                                <input type="text" name="firstname" [(ngModel)]="tutorApplicationInfo.firstname"
                                  #firstname="ngModel" class="form-control" placeholder="Lim">
                              </div>
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getBirthdayLocalData())) }">
                                <label class="control-label"> Date of Birth</label>
                                <div class="input-group">
                                  <!-- input box -->
                                  <input angular-mydatepicker #dp_birthday="angular-mydatepicker" name="birthday"
                                    class="form-control" [locale]="getHelper().locale"
                                    [options]="getDatePickerOptions()" [ngModel]="getBirthdayLocalData()"
                                    (dateChanged)="onDateBirthdayChanged($event)" />
                                  <!-- clear date button -->
                                  <span class="input-group-btn">
                                    <button type="button" *ngIf="tutorApplicationInfo?.date_of_birth"
                                      class="btn default" (click)="dp_birthday.clearDate()"><i
                                        class="fa fa-close"></i></button>
                                  </span>
                                  <!-- toggle calendar button -->
                                  <span class="input-group-btn">
                                    <button type="button" class="btn default" (click)="dp_birthday.toggleCalendar()"><i
                                        class="fa fa-calendar-o"></i></button>
                                  </span>
                                </div>
                              </div>
                              <div class="form-group read-only">
                                <label class="control-label">Nationalities</label>
                                <p>{{gettutorService().getNationalitiesLabel(tutorApplicationInfo.nationality)}}
                                </p>
                              </div>
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || criminal_record.dirty || criminal_record.touched) && !criminal_record.valid && !criminal_record.disabled }">
                                <label class="control-label">Criminal Record</label>
                                <select class="form-control" name="criminal_record"
                                  [(ngModel)]="tutorApplicationInfo.criminal_record" #criminal_record="ngModel">
                                  <option [value]="'yes'">Yes</option>
                                  <option [value]="'no'">No</option>
                                </select>
                              </div>
                              <div class="form-group">
                                <label class="control-label">Passport Type</label>
                                <select class="form-control" name="passport_type"
                                  [(ngModel)]="tutorApplicationInfo.passport_type" #passport_type="ngModel">
                                  <option value="EU_PASSPORT">EU/UK Passport</option>
                                  <option value="WORKING_HOLIDAY_VISA">Working Holiday Visa for Italy</option>
                                  <option value="OTHER_WORKING_VISA">Other Working Visa for Italy</option>
                                  <option value="INTENT_WORKING_VISA">Intend to obtain a working holiday visa for Italy
                                  </option>
                                  <option value="NONE">None of these</option>
                                </select>
                              </div>
                              <div class="form-group">
                                <label class="control-label">T-Shirt Size</label>
                                <select class="form-control" name="tshirt_size"
                                  [(ngModel)]="tutorApplicationInfo.tshirt_size" #tshirt_size="ngModel">
                                  <option value="xs">XS</option>
                                  <option value="s">S</option>
                                  <option value="m">M</option>
                                  <option value="l">L</option>
                                  <option value="xl">XL</option>
                                  <option value="xxl">XXL</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || surname.dirty || surname.touched) && !surname.valid && !surname.disabled }">
                                <label class="control-label">Surname</label>
                                <input type="text" name="surname" [(ngModel)]="tutorApplicationInfo.surname"
                                  #surname="ngModel" class="form-control" placeholder="Lim">
                              </div>
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || gender.dirty || gender.touched) && !gender.valid && !gender.disabled }">
                                <label class="control-label">Gender</label>
                                <select class="form-control" name="gender" [(ngModel)]="tutorApplicationInfo.gender"
                                  #gender="ngModel">
                                  <option [value]="'FEMALE'">Female</option>
                                  <option [value]="'MALE'">Male</option>
                                  <option [value]="'CUSTOM'">Custom</option>
                                </select>
                              </div>
                              <div class="form-group" *ngIf="tutorApplicationInfo.gender == 'CUSTOM'"
                                [ngClass]="{ 'has-error': (f.submitted || custom_gender.dirty || custom_gender.touched) && !custom_gender.valid && !custom_gender.disabled }">
                                <label class="control-label">Custom Gender</label>
                                <input class="form-control" name="custom_gender"
                                  [(ngModel)]="tutorApplicationInfo.custom_gender" #custom_gender="ngModel">
                              </div>
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || native_english_speaker.dirty || native_english_speaker.touched) && !native_english_speaker.valid && !native_english_speaker.disabled }">
                                <label class="control-label">Native English Speaker</label>
                                <select class="form-control" name="native_english_speaker"
                                  [(ngModel)]="tutorApplicationInfo.native_english_speaker"
                                  #native_english_speaker="ngModel">
                                  <option [value]="'yes'">Yes</option>
                                  <option [value]="'no'">No</option>
                                </select>
                              </div>
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || proficient_english_speaker.dirty || proficient_english_speaker.touched) && !proficient_english_speaker.valid && !proficient_english_speaker.disabled }">
                                <label class="control-label">Proficient English Speaker</label>
                                <select class="form-control" name="proficient_english_speaker"
                                  [(ngModel)]="tutorApplicationInfo.proficient_english_speaker"
                                  #proficient_english_speaker="ngModel">
                                  <option [value]="'yes'">Yes</option>
                                  <option [value]="'no'">No</option>
                                </select>
                              </div>
                              <div class="form-group" *ngIf="tutorApplicationInfo.working_visa_details">
                                <label class="control-label">Other Working visa for Italy</label>
                                <textarea name="working_visa_details"
                                  [(ngModel)]="tutorApplicationInfo.working_visa_details" rows="3"
                                  class="form-control"></textarea>
                              </div>
                              <div class="form-group">
                                <label class="control-label">Criminal record detail</label>
                                <textarea name="criminal_record_detail"
                                  [(ngModel)]="tutorApplicationInfo.criminal_record_detail" rows="3"
                                  class="form-control"></textarea>
                              </div>

                            </div>

                            <div class="col-md-4 mt-element-card">
                              <div class="mt-card-item">
                                <div *ngIf="tutorApplicationInfo.photoUrl" class="mt-overlay-1" aria-hidden="true">
                                  <img *ngIf="tutorApplicationInfo.photoUrl"
                                    src="{{tutorApplicationInfo.photoUrl}}?{{tutorApplicationInfo.updated_at}}"
                                    class="img-responsive pic-bordered" alt="">
                                </div>
                                <div *ngIf="!tutorApplicationInfo.photoUrl" class="mt-overlay-1" aria-hidden="true">
                                  <img src="../assets/placeholder/avatar-placeholder.jpg" width="100%">
                                </div>
                              </div>
                              <div class="form-group" *ngIf="tutorApplicationInfo.cvUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="tutorApplicationInfo.cvUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download CV
                                </a>
                              </div>
                              <div class="form-group" *ngIf="tutorApplicationInfo.coverUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="tutorApplicationInfo.coverUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download Cover Letter
                                </a>
                              </div>
                              <div class="form-group" *ngIf="tutorApplicationInfo.passportUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="tutorApplicationInfo.passportUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download Passport
                                </a>
                              </div>
                              <div class="form-group" *ngIf="tutorApplicationInfo.healthInsuranceUrl">
                                <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                                  [href]="tutorApplicationInfo.healthInsuranceUrl" target="_self">
                                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download Health Insurance
                                </a>
                              </div>
                              <div class="form-group" *ngIf="tutorApplicationInfo.youtube_preteaching_link">
                                <a class="btn btn-outline btn-block green-jungle" style="white-space:normal;"
                                  [href]="tutorApplicationInfo.youtube_preteaching_link" target="_blank">
                                  <i class="fa fa-youtube-play" aria-hidden="true"></i>&nbsp;Self-Tape video
                                </a>
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section">Address &amp; Contacts</h4>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || address.dirty || address.touched) && !address.valid && !address.disabled }">
                                <label class="control-label">Address</label>
                                <input type="text" name="address" [(ngModel)]="tutorApplicationInfo.address"
                                  #address="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || postal_code.dirty || postal_code.touched) && !postal_code.valid && !postal_code.disabled }">
                                <label class="control-label">Postal Code</label>
                                <input type="text" name="postal_code" [(ngModel)]="tutorApplicationInfo.postal_code"
                                  #postal_code="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || city.dirty || city.touched) && !city.valid && !city.disabled }">
                                <label class="control-label">City</label>
                                <input type="text" name="city" [(ngModel)]="tutorApplicationInfo.city" #city="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || province.dirty || province.touched) && !province.valid && !province.disabled }">
                                <label class="control-label">Province</label>
                                <input type="text" name="province" [(ngModel)]="tutorApplicationInfo.province"
                                  #province="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Country</label>
                                <p>{{tutorApplicationInfo.country}}</p>
                              </div>
                              <!-- <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || country.dirty || country.touched) && !country.valid && !country.disabled }">
                                    <label class="control-label">Country</label>
                                      <input type="text" name="country" [(ngModel)]="tutorApplicationInfo.country" #country="ngModel" class="form-control">
                                  </div> -->
                            </div>
                          </div>

                          <div class="row">

                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || email.dirty || email.touched) && !email.valid && !email.disabled }">
                                <label class="control-label">Email</label>
                                <input type="text" name="email" [(ngModel)]="tutorApplicationInfo.email"
                                  #email="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || mobile_telephone.dirty || mobile_telephone.touched) && !mobile_telephone.valid && !mobile_telephone.disabled }">
                                <label class="control-label">Mobile telephone</label>
                                <input type="text" name="mobile_telephone"
                                  [(ngModel)]="tutorApplicationInfo.mobile_telephone" #mobile_telephone="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section">Orientation Preference</h4>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || tour_type.dirty || tour_type.touched) && !tour_type.valid && !tour_type.disabled }">
                                <label class="control-label">Orientation</label>
                                <select class="form-control" name="tour_type"
                                  [(ngModel)]="tutorApplicationInfo.tour_type" #tour_type="ngModel">
                                  <option *ngFor="let t of getConfig().tutorApplicationDateOrientationSelect"
                                    [value]="t.key">{{t.value}}</option>
                                </select>
                              </div>
                            </div>
                            <!-- <div class="col-md-4">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || workshop_agree.dirty || workshop_agree.touched) && !workshop_agree.valid && !workshop_agree.disabled }">
                                <label class="control-label">Workshop Date Accepted</label>
                                <select class="form-control" name="workshop_agree"
                                  [(ngModel)]="tutorApplicationInfo.workshop_agree" #workshop_agree="ngModel">
                                  <option [value]="'yes'">Yes</option>
                                  <option [value]="'no'">No</option>
                                </select>
                              </div>
                            </div> -->
                          </div>

                          <h4 class="form-section">CV & Photo</h4>

                          <div class="row">
                            <div class="col-md-4">
                              <div
                                *ngIf="tutorApplicationInfo.photoUrl == undefined || tutorApplicationInfo.photoUrl == ''">
                                <input *ngIf="!photoUploader.isUploading" type="file" id="photoUploader" ng2FileSelect
                                  [uploader]="photoUploader" />
                                <label *ngIf="!photoUploader.isUploading" for="photoUploader"
                                  class="btn btn-primary text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload
                                  Picture</label>
                              </div>
                              <div *ngIf="photoUploader.isUploading">
                                <img *ngIf="photoUploader.isUploading" src="./assets/global/img/input-spinner.gif" />
                                please wait...
                              </div>
                              <div
                                *ngIf="tutorApplicationInfo.photoUrl != undefined && tutorApplicationInfo.photoUrl != ''"
                                style="padding: 10px;">
                                <button *ngIf="!photoUploader.isUploading" type="button"
                                  class="btn btn-danger text-uppercase" (click)="photoRemoved($event);"><i
                                    class="fa fa-trash"></i>&nbsp; Remove Photo</button>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div *ngIf="tutorApplicationInfo.cvUrl == undefined || tutorApplicationInfo.cvUrl == ''">
                                <input *ngIf="!cvUploader.isUploading" type="file" id="cvUploader" ng2FileSelect
                                  [uploader]="cvUploader" />
                                <label *ngIf="!cvUploader.isUploading" for="cvUploader"
                                  class="btn btn-primary text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload
                                  CV</label>
                              </div>
                              <div *ngIf="cvUploader.isUploading">
                                <img *ngIf="cvUploader.isUploading" src="./assets/global/img/input-spinner.gif" />
                                please wait...
                              </div>
                              <div *ngIf="tutorApplicationInfo.cvUrl != undefined && tutorApplicationInfo.cvUrl != ''"
                                style="padding: 10px;">
                                <button *ngIf="!cvUploader.isUploading" type="button"
                                  class="btn btn-danger text-uppercase" (click)="cvRemoved($event);"><i
                                    class="fa fa-trash"></i>&nbsp; Remove CV</button>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div
                                *ngIf="tutorApplicationInfo.coverUrl == undefined || tutorApplicationInfo.coverUrl == ''">
                                <input *ngIf="!coverUploader.isUploading" type="file" id="coverUploader" ng2FileSelect
                                  [uploader]="coverUploader" />
                                <label *ngIf="!coverUploader.isUploading" for="coverUploader"
                                  class="btn btn-primary text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload
                                  Cover</label>
                              </div>
                              <div *ngIf="coverUploader.isUploading">
                                <img *ngIf="coverUploader.isUploading" src="./assets/global/img/input-spinner.gif" />
                                please wait...
                              </div>
                              <div
                                *ngIf="tutorApplicationInfo.coverUrl != undefined && tutorApplicationInfo.coverUrl != ''"
                                style="padding: 10px;">
                                <button *ngIf="!coverUploader.isUploading" type="button"
                                  class="btn btn-danger text-uppercase" (click)="coverRemoved($event);"><i
                                    class="fa fa-trash"></i>&nbsp; Remove Cover</button>
                              </div>
                            </div>
                          </div>

                          <h4 class="form-section" *ngIf="tutorApplicationInfo.youtube_preteaching_link">Pre-Teaching
                            video</h4>

                          <div class="row" *ngIf="tutorApplicationInfo.youtube_preteaching_link">
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || youtube_preteaching_link.dirty || youtube_preteaching_link.touched) && !youtube_preteaching_link.valid && !youtube_preteaching_link.disabled }">
                                <label class="control-label">Pre Teaching video link</label>
                                <input type="text" name="youtube_preteaching_link"
                                  [(ngModel)]="tutorApplicationInfo.youtube_preteaching_link"
                                  #youtube_preteaching_link="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || timezone.dirty || timezone.touched) && !timezone.valid && !timezone.disabled }">
                                <label class="control-label">Timezone</label>
                                <select class="form-control" name="timezone" [(ngModel)]="tutorApplicationInfo.timezone"
                                  #timezone="ngModel">
                                  <option *ngFor="let zone of timezones" [value]="zone.name">{{zone.text}}</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || medical_conditions.dirty || medical_conditions.touched) && !medical_conditions.valid && !medical_conditions.disabled }">
                                <label class="control-label">Medical conditions?</label>
                                <select class="control-label" name="medical_conditions"
                                  [(ngModel)]="tutorApplicationInfo.medical_conditions" #medical_conditions="ngModel"
                                  class="form-control">
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" *ngIf="tutorApplicationInfo.medical_conditions == 'yes'">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || medical_condition_details.dirty || medical_condition_details.touched) && !medical_condition_details.valid && !medical_condition_details.disabled }">
                                <label class="control-label">Medical conditions Info</label>
                                <textarea type="text" name="medical_condition_details"
                                  [(ngModel)]="tutorApplicationInfo.medical_condition_details"
                                  #medical_condition_details="ngModel" class="form-control" row=3></textarea>
                              </div>
                            </div>
                          </div>
                          <br>

                          <h4 class="form-section" *ngIf="tutorApplicationInfo.phase >= 3">Proposal Information</h4>

                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || payment_number.dirty || payment_number.touched) && !payment_number.valid && !payment_number.disabled }">
                                <label class="control-label">IBAN / Paypal Address</label>
                                <input type="text" name="payment_number"
                                  [(ngModel)]="tutorApplicationInfo.payment_number" #payment_number="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || emergency_contact_name.dirty || emergency_contact_name.touched) && !emergency_contact_name.valid && !emergency_contact_name.disabled }">
                                <label class="control-label">Emergency Contact Name</label>
                                <input type="text" name="emergency_contact_name"
                                  [(ngModel)]="tutorApplicationInfo.emergency_contact_name"
                                  #emergency_contact_name="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || emergency_contact_relationship.dirty || emergency_contact_relationship.touched) && !emergency_contact_relationship.valid && !emergency_contact_relationship.disabled }">
                                <label class="control-label">Emergency Contact Relationship</label>
                                <input type="text" name="emergency_contact_relationship"
                                  [(ngModel)]="tutorApplicationInfo.emergency_contact_relationship"
                                  #emergency_contact_relationship="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || emergency_contact_phone.dirty || emergency_contact_phone.touched) && !emergency_contact_phone.valid && !emergency_contact_phone.disabled }">
                                <label class="control-label">Emergency Contact Phone</label>
                                <input type="text" name="emergency_contact_phone"
                                  [(ngModel)]="tutorApplicationInfo.emergency_contact_phone"
                                  #emergency_contact_phone="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || emergency_contact_email.dirty || emergency_contact_email.touched) && !emergency_contact_email.valid && !emergency_contact_email.disabled }">
                                <label class="control-label">Emergency Contact Email</label>
                                <input type="text" name="emergency_contact_email"
                                  [(ngModel)]="tutorApplicationInfo.emergency_contact_email"
                                  #emergency_contact_email="ngModel" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || dietary_requirements_yes_or_not.dirty || dietary_requirements_yes_or_not.touched) && !dietary_requirements_yes_or_not.valid && !dietary_requirements_yes_or_not.disabled }">
                                <label class="control-label">Dietary Requirements</label>
                                <select class="form-control" name="dietary_requirements_yes_or_not"
                                  [(ngModel)]="tutorApplicationInfo.dietary_requirements_yes_or_not"
                                  #dietary_requirements_yes_or_not="ngModel">
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                              <div *ngIf="tutorApplicationInfo?.dietary_requirements_yes_or_not == 'yes'"
                                class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || dietary_requirements.dirty || dietary_requirements.touched) && !dietary_requirements.valid && !dietary_requirements.disabled }">
                                <textarea class="form-control" name="dietary_requirements"
                                  [(ngModel)]="tutorApplicationInfo.dietary_requirements"
                                  #dietary_requirements="ngModel" row="3"></textarea>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || allergic_yes_or_not.dirty || allergic_yes_or_not.touched) && !allergic_yes_or_not.valid && !allergic_yes_or_not.disabled }">
                                <label class="control-label">Allergies</label>
                                <select class="form-control" name="allergic_yes_or_not"
                                  [(ngModel)]="tutorApplicationInfo.allergic_yes_or_not" #allergic_yes_or_not="ngModel">
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                              <div *ngIf="tutorApplicationInfo?.allergic_yes_or_not == 'yes'" class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || allergic_to.dirty || allergic_to.touched) && !allergic_to.valid && !allergic_to.disabled }">
                                <textarea class="form-control" name="allergic_to"
                                  [(ngModel)]="tutorApplicationInfo.allergic_to" #allergic_to="ngModel"
                                  row=3></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || availability.dirty || availability.touched) && !availability.valid && !availability.disabled }">
                                <label class="control-label">Availability</label>
                                <textarea class="form-control" name="availability"
                                  [(ngModel)]="tutorApplicationInfo.availability" #availability="ngModel">
                                </textarea>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group"
                                [ngClass]="{ 'has-error': (f.submitted || flight_details.dirty || flight_details.touched) && !flight_details.valid && !flight_details.disabled }">
                                <label class="control-label">Flight Details</label>
                                <textarea class="form-control" name="flight_details"
                                  [(ngModel)]="tutorApplicationInfo.flight_details" #flight_details="ngModel">
                                </textarea>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="form-group">
                              <div class="col-md-4">
                                <div
                                  *ngIf="tutorApplicationInfo.healthInsuranceUrl == undefined || tutorApplicationInfo.healthInsuranceUrl == ''">
                                  <input *ngIf="!healthInsuranceUploader.isUploading" type="file"
                                    id="healthInsuranceUploader" ng2FileSelect [uploader]="healthInsuranceUploader" />
                                  <label *ngIf="!healthInsuranceUploader.isUploading"
                                    style="white-space:normal; min-height:54px" for="healthInsuranceUploader"
                                    class="btn btn-primary text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload
                                    Health Insurance</label>
                                </div>
                                <div *ngIf="healthInsuranceUploader.isUploading">
                                  <img *ngIf="healthInsuranceUploader.isUploading"
                                    src="./assets/global/img/input-spinner.gif" /> please wait...
                                </div>
                                <div
                                  *ngIf="tutorApplicationInfo.healthInsuranceUrl != undefined && tutorApplicationInfo.healthInsuranceUrl != ''"
                                  style="padding: 10px;">
                                  <button *ngIf="!healthInsuranceUploader.isUploading"
                                    style="white-space:normal; min-height:54px" type="button"
                                    class="btn btn-danger text-uppercase" (click)="healthInsuranceRemove();"><i
                                      class="fa fa-trash"></i>&nbsp; Remove Health
                                    Insurance</button>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div
                                  *ngIf="tutorApplicationInfo.passportUrl == undefined || tutorApplicationInfo.passportUrl == ''">
                                  <input *ngIf="!passportUploader.isUploading" type="file" id="passportUploader"
                                    ng2FileSelect [uploader]="passportUploader" />
                                  <label *ngIf="!passportUploader.isUploading"
                                    style="white-space:normal; min-height:54px" for="passportUploader"
                                    class="btn btn-primary text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload
                                    Passport</label>
                                </div>
                                <div *ngIf="passportUploader.isUploading">
                                  <img *ngIf="passportUploader.isUploading"
                                    src="./assets/global/img/input-spinner.gif" />
                                  please wait...
                                </div>
                                <div
                                  *ngIf="tutorApplicationInfo.passportUrl != undefined && tutorApplicationInfo.passportUrl != ''"
                                  style="padding: 10px;">
                                  <button *ngIf="!passportUploader.isUploading"
                                    style="white-space:normal; min-height:54px" type="button"
                                    class="btn btn-danger text-uppercase" (click)="passportRemoved($event);"><i
                                      class="fa fa-trash"></i>&nbsp; Remove Passport</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br>


                          <h4 class="form-section" *ngIf="tutorApplicationInfo.phase >= 3">Additional Proposal
                            Information</h4>

                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Professional reference 1</label>
                                <input type="text" name="professional_reference1"
                                  [(ngModel)]="tutorApplicationInfo.professional_reference1"
                                  #professional_reference1="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Professional reference 2</label>
                                <input type="text" name="professional_reference2"
                                  [(ngModel)]="tutorApplicationInfo.professional_reference2"
                                  #professional_reference2="ngModel" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">University</label>
                                <input type="text" name="university" [(ngModel)]="tutorApplicationInfo.university"
                                  #university="ngModel" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Relevant qualifications</label>
                                <textarea type="text" name="qualifications"
                                  [(ngModel)]="tutorApplicationInfo.qualifications" #qualifications="ngModel"
                                  class="form-control" row="3"></textarea>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Working Experience with children</label>
                                <textarea type="text" name="children_working_experience"
                                  [(ngModel)]="tutorApplicationInfo.children_working_experience"
                                  #children_working_experience="ngModel" class="form-control" rows="3"></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Musical Instruments</label>
                                <input type="text" name="musical_instruments"
                                  [(ngModel)]="tutorApplicationInfo.musical_instruments" #musical_instruments="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Teach any Sports</label>
                                <input type="text" name="sports" [(ngModel)]="tutorApplicationInfo.sports"
                                  #sports="ngModel" class="form-control">
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Can you speak Italian</label>
                                <select class="form-control" name="italian_speak_rate"
                                  [(ngModel)]="tutorApplicationInfo.italian_speak_rate" #italian_speak_rate="ngModel">
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Do you smoke</label>
                                <select class="form-control" name="smoker" [(ngModel)]="tutorApplicationInfo.smoker"
                                  #smoker="ngModel">
                                  <option value="yes">Yes</option>
                                  <option value="socially">Socially</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">First Aid Certificate</label>
                                <input type="text" name="first_aid_year_certificate"
                                  [(ngModel)]="tutorApplicationInfo.first_aid_year_certificate"
                                  #first_aid_year_certificate="ngModel" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">TEFL Certificate</label>
                                <input type="text" name="tefl_certificate"
                                  [(ngModel)]="tutorApplicationInfo.tefl_certificate" #tefl_certificate="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Confident swimmer</label>
                                <input type="checkbox" #condident_swimmer="ngModel" name="condident_swimmer"
                                  [(ngModel)]="tutorApplicationInfo.confident_swimmer" class="form-control" />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Previous Teaching Experience in Drama</label>
                                <textarea type="text" name="drama_experience"
                                  [(ngModel)]="tutorApplicationInfo.drama_experience" #drama_experience="ngModel"
                                  class="form-control" rows="3"></textarea>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">Different Skills or experience</label>
                                <textarea type="text" name="skills" [(ngModel)]="tutorApplicationInfo.skills"
                                  #skills="ngModel" class="form-control" rows="3"></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Stay in a Catholic environment</label>
                                <input type="checkbox" #catholic="ngModel" name="catholic"
                                  [(ngModel)]="tutorApplicationInfo.catholic" class="form-control" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Shall be living with host families</label>
                                <input type="checkbox" #host_families="ngModel" name="host_families"
                                  [(ngModel)]="tutorApplicationInfo.host_families" class="form-control" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="control-label">Hear About</label>
                                <input type="text" name="how_hear_about"
                                  [(ngModel)]="tutorApplicationInfo.how_hear_about" #how_hear_about="ngModel"
                                  class="form-control">
                              </div>
                            </div>
                          </div>

                        </div>


                        <div class="form-actions right">
                          <button type="submit" class="btn blue">Update</button>
                        </div>

                      </form>
                    </div>


                  </div>
                  <!--end: Portlet-Body -->
                </div>
                <!--end: Portlet -->
              </div>
            </div>
            <!--end: prima riga -->

          </tab>

          <!-- Revision TAB -->
          <tab heading="Tutor Revision">

            <a class="btn btn-primary btn-block" (click)="this.getTutorApplicationRevision()">Get Revisions</a>

            <alert type="warning" *ngIf="loading">
              <strong>Loading</strong> ...
            </alert>
            <br>
            <ngx-datatable *ngIf="!loading && gettutorService().currentTutorApplicationRevision.length > 0"
              class="striped" [rows]="gettutorService().currentTutorApplicationRevision" [columnMode]="'flex'"
              [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'">
              <ngx-datatable-column name="Date" [flexGrow]="1" [sortable]="true" [resizeable]="false"
                prop="revision.created_at">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{value}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="User" [flexGrow]="1" [sortable]="true" [resizeable]="false"
                prop="person.name">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a routerLink="/person/edit/{{row.person.id}}">{{value}}</a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Action" [flexGrow]="1" [sortable]="false" [resizeable]="false"
                prop="revision.key">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span *ngIf="row.revision.key == 'created_at'"><i class="fa fa-plus-square"></i> Created</span>
                  <span *ngIf="row.revision.key == 'deleted_at'"><i class="fa fa-trash-o"></i> Deleted</span>
                  <span *ngIf="row.revision.key != 'created_at' && row.revision.key != 'deleted_at'"><i
                      class="fa fa-edit"></i> Edit</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Log" [flexGrow]="4" [sortable]="false" [resizeable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  field <b>'{{row.revision.key}}'</b> in {{row.revision.revisionable_type}} (id:
                  {{row.revision.revisionable_id}}) <b>from</b> <i>'{{row.revision.old_value}}'</i> <b>to</b>
                  <i>'{{row.revision.new_value}}'</i>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </tab>
        </tabset>
      </div>

    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
