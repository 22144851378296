import { Component, OnInit, ViewChild, ViewChildren, ChangeDetectionStrategy, Input, Output, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TheatrinoService, ConfigService, HelperService } from './../../_services/index';
import { Router } from '@angular/router';
import { EventEmitter } from 'selenium-webdriver';
import { TheatrinoAccomodationMultiFormModalComponent } from './../theatrino-accomodation-multi-form-modal/theatrino-accomodation-multi-form-modal.component';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-theatrino-tour-booking-calendar-day',
  templateUrl: './theatrino-tour-booking-calendar-day.component.html',
  styleUrls: ['./theatrino-tour-booking-calendar-day.component.css']
})
export class TheatrinoTourBookingCalendarDayComponent implements OnInit {

  openDayDate: any;
  @ViewChild('modalDay', {static: false}) public modalDay: ModalDirective;
  @ViewChild('modalDayRef', {static: true}) public modalDayRef: ElementRef;
  modal: any;

  @Input() accomodationModal: TheatrinoAccomodationMultiFormModalComponent;

  // MODAL
  isModalShown: boolean = false;
  hideModalBookingButton: boolean = false;
  hideModalSwapDayButton: boolean = false;
  hideModalDayOffButton: boolean = false;
  hideModalAccomodationButton: boolean = false;

  constructor(private theatrinoService: TheatrinoService, private configService: ConfigService,
    private router: Router, private helper: HelperService) { }

  ngOnInit() {
  }

  public openModalFullDay(date){

    this.openDayDate = date;

    const yyyy = date.getFullYear();
    const mm = (`0${ date.getMonth() + 1 }`).slice(-2);
    const dd = (`0${ date.getDate() }`).slice(-2);
    let singleDate = yyyy+"-"+mm+"-"+dd;
    this.modal = {};
    this.modal.selectedDate = date;//parseISO(date.toString());
    this.modal.loading = true;

    this.theatrinoService.getTheatrinoTourDateByDate(singleDate, () => {

      this.modal.events = this.theatrinoService.currentTourInfoByDate;
      this.modal.loading = false;

      this.hideModalBookingButton = false;
      this.hideModalSwapDayButton = false;
      this.hideModalDayOffButton = false;
      this.hideModalAccomodationButton = false;

      this.modal.events.forEach(element => {
        if (element.type == 2){
          this.hideModalDayOffButton = true;
        }
        else if (element.type == 5){
          this.hideModalAccomodationButton = true;
        }
        else if (element.type == 1){
          this.hideModalDayOffButton = true;
          this.hideModalSwapDayButton = true;
          this.hideModalBookingButton = true;
        }
        else if (element.type == 3){
          this.hideModalSwapDayButton = true;
        }
      });

      // pezzotto per colpa del calendario che da un problema con il component person-summary
      // il problema che si verifica è che rimane in caricamente nonostante abbia finito di scaricare i dati
      // setTimeout(() => {
      //   this.modalDayRef.nativeElement.click();
      // }, 2000);
    });

    this.showModal();

  }

  showModal(): void {
    this.isModalShown = true;
  }

  public hideModal(): void {
    if (this.modalDay)
      this.modalDay.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  getBackgroundColorByEvent(event) {

    if (event.type == 4)
      return { 'background-color': "white" };

    if (event.type == 1)
      return { 'background-color': "#ff8e8e" };

    if (event.type == 3)
      return { 'background-color': "#bcf6f0z" };

    if (event.type == 5)
      return { 'background-color': "white" };

    if (event.type == 2){
      return { 'background-color': this.configService.getTheatrinoStatusColorByKey(event.status).secondary };
    }

  }

  addNewTourDateFromOpenDay(){

    let day = this.openDayDate;
    this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/newDate/"+day.toDateString()+"/booking/", true]);
  }

  addNewDayOffFromOpenDay(){

    let day = this.openDayDate;
    this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/newDate/"+day.toDateString()+"/dayoff/", true]);
  }

  addNewSwapDayFromOpenDay(){

    let day = this.openDayDate;
    this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/newDate/"+day.toDateString()+"/swapday/", true]);
  }

  addNewGeneralFromOpenDay(){

    let day = this.openDayDate;
    this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/newDate/"+day.toDateString()+"/general/", true]);
  }

  addNewAccomodationFromOpenDay() {

    var model: any = {};
    model.calendarDay = this.openDayDate;

    // this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/newDate/"+day.toDateString()+"/accomodation/", true]);
    this.hideModal();
    //  Mostro la modale con le informazioni da passare per la creazione/modifica di una accomodation
    this.accomodationModal.showAccomodationModal(model)

  }

  getTheatrinoService() {
    return this.theatrinoService
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }
}
