<!-- BEGIN: Gestione di un prodotto-->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">

    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Product Type </label>
          <select [disabled]="getWarehouseService().isOngoingRequest()" class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel">
            <option *ngFor="let p of getConfigService().warehouseProductTypeSelect" [value]="p.key">{{p.value}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || name.dirty || name.touched) && !name.valid && !name.disabled }">
          <label class="control-label">Product Name <span class="required">*</span></label>
          <input [disabled]="getWarehouseService().isOngoingRequest()" type="text" name="name" [(ngModel)]="model.name" #name="ngModel" nospace class="form-control" placeholder="Penna BIC">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || quantity.dirty || quantity.touched) && !quantity.valid && !quantity.disabled }">
          <label class="control-label">Quantity <span class="required">*</span></label>
          <input [disabled]="getWarehouseService().isOngoingRequest() || model.id" type="number" min="0" name="quantity" required [(ngModel)]="model.quantity" #quantity="ngModel" class="form-control" placeholder="10">
        </div>
      </div>
    </div>

    <!-- BEGIN: carico e scarico prodotti -->
    <div class="row" *ngIf="model.id">
      <div class="col-md-6" *ngIf="model.id && out == false">
        <div class="form-group">
          <label class="control-label">&nbsp;</label>
          <button type="button" (click)="in = !in" class="btn green-jungle form-control"><i class="fa fa-plus"></i>&nbsp; Quantity</button>
        </div>
      </div>
      <div class="col-md-6" *ngIf="model.id && out == false && in == true">
        <div class="form-group">
          <label class="control-label">&nbsp;</label>
          <input type="number" min="0" name="newquantity" required [(ngModel)]="model.newquantity" #newquantity="ngModel" class="form-control" placeholder="5">
        </div>
      </div>
      <div class="col-md-6" *ngIf="model.id && in == false && out == true">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || newquantity.dirty || newquantity.touched) && (!newquantity.valid || !quantityValid)}">
          <label class="control-label">&nbsp;</label>
          <input type="number" min="0" name="newquantity" required [(ngModel)]="model.newquantity" #newquantity="ngModel" class="form-control" placeholder="5">
        </div>
      </div>
      <div class="col-md-6" *ngIf="model.id && in == false">
        <div class="form-group">
          <label class="control-label">&nbsp;</label>
          <button type="button" (click)="out = !out" class="btn red form-control"><i class="fa fa-minus"></i>&nbsp; Quantity</button>
        </div>
      </div>
      <!-- END: carico e scarico prodotti -->
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Description</label>
          <textarea [disabled]="getWarehouseService().isOngoingRequest()" name="description" [(ngModel)]="model.description" rows="3" class="form-control" placeholder=""></textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Product Tags</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox" *ngFor="let tag of model.tags_formatted">
              <input type="checkbox" name="{{tag.key}}" [(ngModel)]="tag.enabled" (ngModelChange)="tagChanged();"> {{tag.value}}
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    

  </div>

  <div class="form-actions right">
    <img *ngIf="getWarehouseService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroyProduct();">Yes Delete!</button></ng-template>
    <button type="button" class="btn btn-outline red" *ngIf="model.id" [disabled]="getWarehouseService().isOngoingRequest()"
        [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="submit" [disabled]="getWarehouseService().isOngoingRequest()" class="btn blue" *ngIf="model.id">Update</button>
    <button type="submit" [disabled]="getWarehouseService().isOngoingRequest()" class="btn green-jungle" *ngIf="!model.id">Save</button>
  </div>

</form>
<!-- END: Gestione di un prodotto-->
