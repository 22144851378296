import { NotificationsService } from 'angular2-notifications';
import { BackendService } from './../../_services/backend.service';
import { ConfigService } from './../../_services/config.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { HelperService } from 'app/_services';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
declare var google:any;

@Component({
  selector: 'app-person-add-new',
  templateUrl: './person-add-new.component.html',
  styleUrls: ['./person-add-new.component.css']
})
export class PersonAddNewComponent implements OnInit {

  model: any = {};

  localData: IMyDateModel;

  loading: boolean = false;

  @Output() personAdded = new EventEmitter();

  //  Impostazione della data che vengono passate dall'helper
  datePickerOptions: IAngularMyDpOptions = {};

  @ViewChild('addressInput', {static: false}) addressInput: ElementRef;
  autocomplete:any;
  trigger:any;
  place:any;

  address: any;
  addressCheck: string = "";
  addressGooglized: any = null;

  constructor(private helper:HelperService, private configService:ConfigService,
              private ref: ChangeDetectorRef, private backend: BackendService,
              private notify: NotificationsService) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
    this.datePickerOptions.disableSince = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }
  }

  ngOnInit() {

    if (this.model == null) this.model = {};
  }

  setupGooglePlace() {

    if (!this.addressInput || !this.addressInput.nativeElement)
      return;

    this.autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement, { type: ['address'] });

    this.trigger = this.autocomplete.addListener('place_changed', () => {
      this.place = this.autocomplete.getPlace();
      this.getAddress(this.place);
    });
  }


  //  Calcolo l'età a partire dalla data della nascita dall'helper
  get calculateAge(): string {
    return this.helper.calculateAge(this.model.birthday);
  }


  submit() {

    if (!this.helper.isMyDateValid(this.localData)) return;

    //  aggiungo alcune info importanti per la persona che sto aggiungendo
    this.model.roles = [{type: 4, primary: true}];

    // console.log(this.model.email);
    // console.log(this.model.mobile);

    this.model.contacts = [];

    if (this.model.email != null && typeof this.model.email != "undefined" && this.model.email.trim().length > 0)
      this.model.contacts.push({type:1, value: this.model.email.trim(), primary: true});

    if (this.model.mobile != null && typeof this.model.mobile != "undefined" && this.model.mobile.trim().length > 0)
      this.model.contacts.push({type:5, value:this.model.mobile.trim(), primary: false});

    if (this.model.iban != null && typeof this.model.iban != "undefined")
      if (this.model.iban.trim().length > 0)
      {
        this.model.bankaccounts = [
          {type: 1,
            value: this.model.iban,
            accountholder: this.model.surname + " " + this.model.first_name,
            primary: true
          }];
      }

    if (this.addressGooglized != null)
    {
      this.model.addresses = [
        {type: 2,
          administrative_area_level_1_long_version: this.addressGooglized.administrative_area_level_1_long_version,
          administrative_area_level_1_short_version: this.addressGooglized.administrative_area_level_1_short_version,
          administrative_area_level_2_long_version: this.addressGooglized.administrative_area_level_2_long_version,
          administrative_area_level_2_short_version: this.addressGooglized.administrative_area_level_2_short_version,
          administrative_area_level_3_long_version: this.addressGooglized.administrative_area_level_3_long_version,
          administrative_area_level_3_short_version: this.addressGooglized.administrative_area_level_3_short_version,
          country_long_version: this.addressGooglized.country_long_version,
          country_short_version: this.addressGooglized.country_short_version,
          locality: this.addressGooglized.locality,
          postal_code: this.addressGooglized.postal_code,
          route: this.addressGooglized.route,
          street_number: this.addressGooglized.street_number,
        primary: true}
      ];
    }

    this.loading = true;

    this.backend.post('person/simple', this.model).subscribe(
      (response) => {

        let resJson : any = response;

        //  invio la notifica con l'utente appena registrato
        this.personAdded.emit(resJson);

        this.notify.success("Person Info", "successfully saved");

        //  pulisco il campo address
        this.address = "";
        this.addressGooglized = null;

        this.loading = false;
      },
      (error) => {
        this.backend.showErrors(error);

        this.loading = false;
      }
    );

  }

  //  blocco l'invio se sto inserendo un indirizzo
  checkSubmitKeyEnter(event){

    if (event.target.name == "address")
      return false;

    return true;
  }


  onDateChanged(event: IMyDateModel) {
    this.localData = event;

    if (event.singleDate.jsDate != null)
      this.model.birthday = this.helper.formatISODate(event);
    else
      this.model.birthday = null;
  }




  getAddress(place: Object) {

    if (place == null || typeof place['formatted_address'] == "undefined")
      return;

    this.address = place['formatted_address'];

    this.resetAddress();

    if (typeof place['address_components'] == "undefined")
      return;

    place['address_components'].forEach(element => {

      switch (element['types'][0]) {
        case "street_number": this.addressGooglized['street_number'] = element['long_name']; break;
        case "route": this.addressGooglized['route'] = element['long_name']; break;
        case "locality": this.addressGooglized['locality'] = element['long_name']; break;
        case "administrative_area_level_3": this.addressGooglized['administrative_area_level_3_long_version'] = element['long_name']; this.addressGooglized['administrative_area_level_3_short_version'] = element['short_name']; break;
        case "administrative_area_level_2": this.addressGooglized['administrative_area_level_2_long_version'] = element['long_name']; this.addressGooglized['administrative_area_level_2_short_version'] = element['short_name']; break;
        case "administrative_area_level_1": this.addressGooglized['administrative_area_level_1_long_version'] = element['long_name']; this.addressGooglized['administrative_area_level_1_short_version'] = element['short_name']; break;
        case "country": this.addressGooglized['country_long_version'] = element['long_name']; this.addressGooglized['country_short_version'] = element['short_name']; break;
        case "postal_code": this.addressGooglized['postal_code'] = element['long_name']; break;
      }
    });

    this.addressCheck = place["place_id"];

    this.ref.detectChanges();
  }


  resetAddress() {
    this.addressGooglized = {};
    this.addressGooglized['street_number'] = null;
    this.addressGooglized['route'] = null;
    this.addressGooglized['locality'] = null;
    this.addressGooglized['administrative_area_level_3_long_version'] = null;
    this.addressGooglized['administrative_area_level_3_short_version'] = null;
    this.addressGooglized['administrative_area_level_2_long_version'] = null;
    this.addressGooglized['administrative_area_level_2_short_version'] = null;
    this.addressGooglized['administrative_area_level_1_long_version'] = null;
    this.addressGooglized['administrative_area_level_1_short_version'] = null;
    this.addressGooglized['country_long_version'] = null;
    this.addressGooglized['country_short_version'] = null;
    this.addressGooglized['postal_code'] = null;
  }


  //  evento nell'input dell'indirizzo
  addressChange (value) {

    if (value.length == 0) {
      this.addressGooglized = null;
    }

  }

  get validateEmail(): boolean {
    //  Controllo la validità dei campi prima di inviarli
    if (this.model.email != null && this.model.email.length > 0) {
      return this.helper.validateEmail(this.model.email);
    }

    return true;
  }


  get validateMobile(): boolean {

    //  Controllo la validità dei campi prima di inviarli
    if (this.model.mobile != null && this.model.mobile.length > 0) {
      return this.helper.validatePhone(this.model.mobile);
    }
    return true;
  }


  getConfigService() {
    return this.configService;
  }

  getHelper() {
    return this.helper;
  }

}
