<div class="datatable-loader text-center" *ngIf="ongoingRequest || getActorService().ongoingRequest">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>


<div class="portlet">
    <div class="portlet-body">
      <h3 class="font-green-jungle">Completed <small>(SUBMITTED)</small></h3>
      <app-actors-applications-phase-table [rows]="actorsCompleted" [filter]="filter"></app-actors-applications-phase-table>
    </div>
  </div>


<div class="portlet">
  <div class="portlet-body">

    <h3>Not Completed <small>(EDITING - CREATED)</small></h3>
    <app-actors-applications-phase-table [rows]="actors" [filter]="filter"></app-actors-applications-phase-table>
  </div>
</div>

<div class="portlet">
  <div class="portlet-body">

    <h3 class="font-red">Rejected</h3>
    <app-actors-applications-phase-table [rows]="actorsRejected" [filter]="filter"></app-actors-applications-phase-table>
  </div>
</div>

<div class="portlet" *ngIf="phase == '4'">
  <div class="portlet-body">

    <h3 class="font-blue">Hired (Imported)</h3>
    <app-actors-applications-phase-table [rows]="actorsImported" [filter]="filter"></app-actors-applications-phase-table>
  </div>
</div>

<div class="portlet">
  <div class="portlet-body">

    <h3>Status Legend</h3>
    <br>
    <i class="fa fa-plus-square-o" aria-hidden="true"></i> CREATED: only when the first step with name, surname, email and privacy check is completed.<br>
    <i class="fa fa-pencil-square-o" aria-hidden="true"></i> EDITING: after the first step, and for each step until the end of the current process (phase)<br>
    <i class="fa fa-check-square-o" aria-hidden="true"></i> SUBMITTED: when all the information in the current process (phase) are filled and submitted<br>
    <br>
    <i class="fa fa-car" aria-hidden="true"></i> Driver licence: the icon appear if they have checked the driver licence<br>
    <i class="fa fa-car font-red" aria-hidden="true"></i> Driver licence: not happy to drive in Italy<br>
    <i class="fa fa-car font-green-jungle" aria-hidden="true"></i> Driver licence: happy to drive in Italy<br>
    <br>
    <i class="fa fa-microphone" aria-hidden="true"></i> Audition: if they agree to the audition in UK in phase 1<br>
    <i class="fa fa-youtube-play" aria-hidden="true"></i> Self-Tape: if they do not agree to the audition in UK in phase 1 or if self-tape is checked in phase 2 the youtube icon appear in the status column<br>
    <i class="fa fa-book" aria-hidden="true"></i> Documents: in phase 2 they need to upload documents and passport<br>
    <br>
    <i class="fa fa-microphone font-default" aria-hidden="true"></i> Audition: if the audition flag is checked in phase 2 the microphone icon appear in light grey waiting the approval or rejection by the actor<br>
    <i class="fa fa-microphone font-red" aria-hidden="true"></i> Audition: the icon is red if they refuse the audition proposal<br>
    <i class="fa fa-microphone font-green-jungle" aria-hidden="true"></i> Audition: the icon is green if they accept the audition proposal uploading the passport<br>
    <br>
    <i class="fa fa-youtube-play font-default" aria-hidden="true"></i> Self-Tape: if self-tape video is not submitted the icon is light grey<br>
    <i class="fa fa-youtube-play font-green-jungle" aria-hidden="true"></i> Self-Tape: if self-tape video is submitted the icon is green<br>
    <br>
    <i class="fa fa-book font-default" aria-hidden="true"></i> Documents: if documents and passport are not submitted the icon is light grey<br>
    <i class="fa fa-book font-green-jungle" aria-hidden="true"></i> Documents: if documents and passport are submitted the icon is green<br>

  </div>
</div>



