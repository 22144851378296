import { Component, OnInit, ViewChild, Input, ViewChildren, QueryList} from '@angular/core';
import { PrimanotaService, ConfigService, UserService, HelperService } from './../../_services/index';
import { ModalDirective } from 'ngx-bootstrap/modal';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-primanota-show',
  templateUrl: './primanota-show.component.html',
  styleUrls: ['./primanota-show.component.css']
})
export class PrimanotaShowComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;

  @Input() holderType: string;
  @Input() holderName: string;
  @Input() holderId: number;
  @Input() readOnlyMode: boolean = false;

  selectedRowModel: any;
  subscribe: any;
  loadingData: boolean = false;

  pageTotalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotalPages: number = 0;
  pageSort: any = null;
  rows: any[] = [];
  selected = [];
  selectedRow: any;
  selectedHolderType: string;
  selectedHolderId: number;
  selectedHolderName: string;

  constructor(private primanotaService: PrimanotaService, private configService: ConfigService, private userService: UserService, private helper: HelperService) { }

  ngOnInit() {

    this.loadingData = true;

    this.subscribe = this.primanotaService.getsubjectToUpdatePrimaNotaObservable().subscribe((res: any) => {

      this.rows = this.primanotaService.getTableData(res, this.holderId, this.holderType);

      this.loadingData = false;
    });

    if (this.holderId)
      this.primanotaService.getPrimaNota(this.holderType, this.holderId);
  }

  ngOnDestroy(){
    this.subscribe.unsubscribe();
  }

  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  onSelect({ selected }) {

    if (this.readOnlyMode)
      return;

    if (!this.userService.checkPermission('primanota_edit'))
      return;

    this.selectedRow = Object.assign({}, selected[0]);
    this.showModal();
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  getRowClass(row) {

    // if (row.out_holder_name == null)
    //   return { 'camp-staff-deleted' : row.out_holder_name == null};

    // if (row.in_holder_name == null)
    //   return { 'camp-staff-deleted' : row.in_holder_name == null};

    return "";
  }

  modelUpdatedInModal(event) {
    this.hideModal();
    this.loadingData = true;
    this.primanotaService.getPrimaNota(this.holderType, this.holderId);
  }

  modelUpdated(event) {
    this.loadingData = true;
    this.primanotaService.getPrimaNota(this.holderType, this.holderId);
  }


  // MODAL EXPORT

  showModalExport(): void {
    this.staticModal.show();
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    // call back alla chiusura della modale
  }


  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    this.exportParse(this.rows, () => {
      this.hideModalExport();
    });

  }



  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse (data: any, callback: any) {

    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach( (element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      //  preparo
      exportedArray.push( {
        'Date payment': element.date_payment,
        'Date created': element.created_at,
        'From': element.out_holder_name,
        'From first name': element.out_holder_firstname,
        'From last name': element.out_holder_lastname,
        'In amount': element.in_amount,
        'Out amount': element.out_amount,
        'To': element.in_holder_name,
        'To first name': element.in_holder_firstname,
        'To last name': element.in_holder_lastname,
        'Registered via': element.registered_via_description,
        'Causal': element.causal_description,
        'Fiscal document': element.fiscal_document,
        'Verified': element.verified_transaction,
        'Annotation': element.annotation
      })
    })

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{width: 20}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 15}, {width: 15}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 20}, {width: 10}, {width: 100}];

    // //  personalizzo l'header
    worksheet['!rows'] = [{hpx: 30}];

    // //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'CashFlow': worksheet}, SheetNames: ['CashFlow'] };

    // //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

    // data corrente
    let d = new Date();

    // //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "cashflow-"+d.toString()+".xlsx");

    callback();

  }

  getHelper() {
    return this.helper
  }

  getUserService() {
    return this.userService
  }

  getPrimanotaService() {
    return this.primanotaService;
  }

}
