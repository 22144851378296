<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm">

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 class="form-section">Contact</h3>

    <div class="row">
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
          <label class="control-label">Contact Type <span class="required">*</span></label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
            <option *ngFor="let c of getConfigService().contactEnterpriseSelect" [value]="c.key">{{c.value}}</option>
          </select>
          <span class="help-block">Select contact type, is required</span>
        </div>
        <div *ngIf="!editMode && model.type" class="form-group editMode">
          <label class="control-label">Contact Type</label>
          <p>{{getConfigService().getContactEnterpriseValueByKey(model.type)}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || contact_value.dirty || contact_value.touched) && (!validateValue)}">
          <label class="control-label">Email/Phone/Website <span class="required">*</span></label>
          <input type="text" class="form-control" placeholder="" name="value" [(ngModel)]="model.value" #contact_value="ngModel" required>
          <span class="help-block">Field required</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Email/Phone/Website</label>
          <p>{{model.value}}</p>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="model.note || editMode">
      <div class="col-md-12">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" placeholder="Some notes..." name="note" [(ngModel)]="model.note"></textarea>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Notes</label>
          <p class="line-breaker">{{model.note}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group">
          <label>Primary</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="primary" [(ngModel)]="model.primary" value="1" (ngModelChange)="valueChange($event)"> Yes, show this data in the search results and in the summary screen of this entity
              <span></span>
            </label>
          </div>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Primary</label>
          <p *ngIf="model.primary">Yes, show this data in the search results and in the summary screen of this person</p>
          <p *ngIf="!model.primary">No, hide this data in the search results and in the summary screen of this person</p>
        </div>
      </div>
    </div>

  </div>


  <div [appCheckPermission]="'enterprise_edit'" class="form-actions right">
    <img *ngIf="getEnterpriseService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="this.model.id"
       [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline red" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="!this.model.id"
      (click)="delete();"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline blue" [disabled]="getEnterpriseService().isOngoingRequest()" *ngIf="this.model.id && !editMode"
      (click)="edit()"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

    <button *ngIf="multiSave == false || editMode" type="submit" class="btn blue" [disabled]="getEnterpriseService().isOngoingRequest()"
      [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>
