import { Component, OnInit, Input, ɵConsole } from '@angular/core';
import { ReminderService, ConfigService, UserService, HelperService } from 'app/_services';
import { parseISO, format, isToday, isPast, isTomorrow } from "date-fns";

@Component({
  selector: 'app-reminder-smart-button',
  templateUrl: './reminder-smart-button.component.html',
  styleUrls: ['./reminder-smart-button.component.css']
})
export class ReminderSmartButtonComponent implements OnInit {

  @Input() holderType: string;
  @Input() holderName: string;
  @Input() holderId: number;
  @Input() reminderDate: string;
  @Input() reminderTitle: string;
  @Input() reminderAnnotation: string;
  @Input() reminderTag: string;

  model: any;
  loadingData: boolean = false;

  constructor(private reminderService: ReminderService, private configService: ConfigService, private userService: UserService) { }

  ngOnInit() {

    // avvio la ricerca se esiste o meno il promemoria creato
    if (this.reminderTag){

      this.loadingData = true;
      this.reminderService.getReminderByTag(this.reminderTag, (res) => {

        this.model = res;
        this.loadingData = false;
      });
    }
    else {
      this.model = {}
    }
  }

  getStatusReminderClass(){

    if (this.model && this.model.completed && this.model.completed.id){
      return "fa fa-check-square-o text-success";
    }

    if (this.model){

      if (isToday(HelperService.parseDate(this.model.start_date_reminder))){
        return "fa fa-calendar-check-o";
      }
      else if (isTomorrow(HelperService.parseDate(this.model.start_date_reminder))){
        return "fa fa-calendar-plus-o";
      }
      else if (isPast(HelperService.parseDate(this.model.start_date_reminder))){
        return "fa fa-exclamation-triangle text-warning";
      }
      else {
        return "fa fa-calendar";
      }
    }
    else {
      return "fa fa-calendar-check-o";
    }
  }

  saveReminder(){

    this.model = {};
    this.model.holder_type = this.holderType;
    this.model.holder_id = this.holderId;
    this.model.tags_reminder = this.reminderTag;

    this.model.start_date_reminder = this.reminderDate;
    this.model.start_time_reminder = this.configService.defaultNotificationAllDayReminder_Time;
    this.model.notification_time = this.model.start_time_reminder;
    this.model.notification_date_time = this.reminderDate;
    this.model.all_the_day = 1;
    // this.model.notification_type = 1; // email
    // this.model.notification_destination = this.userService.getCurrentUser().email;

    this.model.title_reminder = this.reminderTitle;
    this.model.annotation_reminder = this.reminderAnnotation;

    let d = HelperService.parseDate(this.model.notification_date_time);
    let t = this.model.notification_time.split(":");
    d.setHours(t[0]);
    d.setMinutes(t[1]);
    this.model.notification_date_time = format(d, "yyyy-MM-dd HH:mm:ss");

    this.loadingData = true;

    this.reminderService.saveReminder(this.model, (res) => {

      this.model = res;
      this.loadingData = false;

      this.reminderService.getRemindersForHolder(this.holderType, this.holderId);
    });
  }

}
