<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 class="form-section">Documents</h3>

    <div class="row">
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
          <label class="control-label">Type
            <span class="required">*</span>
          </label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
            <option *ngFor="let d of getConfigService().documentPersonSelect" [value]="d.key">{{d.value}}</option>
          </select>
          <span class="help-block">Select the type of the document, is required</span>
        </div>
        <div *ngIf="!editMode && model.type" class="form-group editMode">
          <label class="control-label">Type</label>
          <p>{{getConfigService().getDocumentPersonValueByKey(model.type)}}</p>
        </div>
      </div>

      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted || number.dirty || number.touched) && !number.valid }">
          <label class="control-label">Number
            <span class="required">*</span>
          </label>
          <input type="text" class="form-control" name="number" #number="ngModel" required placeholder="" [(ngModel)]="model.number"
            required>
          <span class="help-block">This field is required</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Number</label>
          <p>{{model.number}}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Country</label>
          <app-country-picker name="country" #country="ngModel" [(ngModel)]="model.country"></app-country-picker>
          <span class="help-block"></span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Country</label>
          <p>{{model.country}}</p>
        </div>
      </div>

      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalData(), false)) }">
          <label class="control-label">Expiration Date</label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_expiration_date="angular-mydatepicker" name="expiration_date" class="form-control"
                [locale]="getHelper().locale"
                [options]="getDatePickerOptions()"
                [ngModel]="getLocalData()"
                (dateChanged)="onDateChanged($event)"
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.expiration_date" class="btn default" (click)="dp_expiration_date.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_expiration_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block" *ngIf="calculateExpiration else expired">Document valid</span>
          <ng-template #expired>
            <span *ngIf="getLocalData()" class="error text-danger">Document expired</span>
          </ng-template>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Expiration Date</label>
          <p>{{model.expiration_date}}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Issued By</label>
          <input type="text" class="form-control" name="issued_by" #issued_by="ngModel" placeholder="" [(ngModel)]="model.issued_by">
          <span class="help-block"></span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Issued By</label>
          <p>{{model.issued_by}}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" name="note" placeholder="Some notes..." [(ngModel)]="model.note"></textarea>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Notes</label>
          <p class="line-breaker">{{model.note}}</p>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="!model.id && editMode">
      <div class="col-md-6">
        <label class="control-label">Attachment file</label>
        <alert type="warning" *ngIf="!model.documentUrl">
          <i class="fa fa-warning"></i> First save the form and after upload the document
        </alert>
      </div>
    </div>

    <div class="row" *ngIf="model.id">
      <div class="col-md-12">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Attachment file</label>
          <div *ngIf="model.documentUrl && !getPersonService().isOngoingRequest()">
            <a type="button" [href]="model.documentUrl" target="_blank" class="btn btn-block btn-outline green text-uppercase label-file" ><i class="fa fa-save"></i>&nbsp; Download {{getConfigService().getDocumentPersonValueByKey(model.type)}}</a>
          </div>
            <div *ngIf="(model.documentUrl == undefined || model.documentUrl == '') && !getPersonService().isOngoingRequest()">
              <input *ngIf="!documentUploader.isUploading" type="file" id="documentUploader"
                ng2FileSelect [uploader]="documentUploader" />
              <label *ngIf="!documentUploader.isUploading" for="documentUploader"
                class="btn btn-lg btn-block label-file green-jungle text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload {{getConfigService().getDocumentPersonValueByKey(model.type)}}!</label>
            </div>
            <div *ngIf="documentUploader.isUploading || getPersonService().isOngoingRequest()">
              <img src="./assets/global/img/input-spinner.gif" />
              please wait...
            </div>
            <div *ngIf="model.documentUrl != undefined && model.documentUrl != ''">
              <button *ngIf="!documentUploader.isUploading && !getPersonService().isOngoingRequest()" type="button"
                class="btn btn-danger btn-block text-uppercase label-file" (click)="destroyFile();"><i
                  class="fa fa-trash"></i>&nbsp; Remove {{getConfigService().getDocumentPersonValueByKey(model.type)}}</button>
            </div>
          </div>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Attachment</label>
            <div *ngIf="model.documentUrl">
            <a type="button" [href]="model.documentUrl" target="_blank" class="btn btn-block btn-outline green text-uppercase label-file" ><i class="fa fa-save"></i>&nbsp; Download {{getConfigService().getDocumentPersonValueByKey(model.type)}}!</a>
          </div>
          <p *ngIf="!model.documentUrl">none</p>
        </div>
      </div>
    </div>


  <div [appCheckPermission]="'person_edit'" class="form-actions right">
    <img *ngIf="getPersonService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn red btn-outline" [disabled]="getPersonService().isOngoingRequest()" *ngIf="this.model.id"
      [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
      <span class="ladda-label">
        <i class="fa fa-trash-o"></i> Delete</span>
    </button>&nbsp;
    <button type="button" class="btn btn-outline red" [disabled]="getPersonService().isOngoingRequest()" *ngIf="!this.model.id"
      (click)="delete()">
      <i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline blue" [disabled]="getPersonService().isOngoingRequest()" *ngIf="this.model.id && !editMode"
      (click)="edit()">
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

    <button *ngIf="multiSave == false || editMode" type="submit" class="btn blue" [disabled]="getPersonService().isOngoingRequest()"
      [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id">
        <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>



</form>
