<div class="portlet light bordered">

  <div class="portlet-title">
    <button [appCheckPermission]="'camp_edit'" [disabled]="getCampService().isOngoingRequest()" type="button" class="btn green btn-block btn-outline"
      (click)="addNewShipment();"><i class="fa fa-plus"></i>&nbsp; Add Shipment</button>
  </div>

  <div class="datatable-loader text-center" *ngIf="getCampService().isOngoingRequest()">
    <span></span><span></span><span></span><span></span><span></span>
    <strong class="text-center">Loading</strong>
  </div>

  <div class="note note-info">
    <h5 class="block">Stime materiale in base a gruppi, iscritti e staff</h5>
    <div class="row">
      <div class="col-md-4">
        <h4 class="block">Libri</h4>
        <div *ngFor="let g of campGroupList" style="margin-top: 10px;">
          <div *ngIf="g.count_subscribers_for_book" class="badge badge-info pull-right bold">{{ g.count_subscribers_for_book }}</div>
          <i class="fa fa-book" aria-hidden="true"></i>&nbsp;<span *ngIf="g.warehouse_product">{{ g.warehouse_product.name }}</span><br>
          <small>{{ g.group_name }} - {{ g.description }}</small>
        </div>
      </div>
      <div class="col-md-4">
        <h4 class="block">T-Shirt iscritti</h4>
        <div *ngFor="let t of tshirtSizeList" style="margin-top: 10px;">
          <div class="badge badge-info pull-right bold">{{ t.count }}</div>
          <i class="fa fa-user" aria-hidden="true"></i>&nbsp;{{ t.name }}
        </div>
      </div>
      <div class="col-md-4">
        <h4 class="block">T-Shirt staff</h4>
        <div *ngFor="let t of staffTshirtSizeList" style="margin-top: 10px;">
          <div class="badge badge-info pull-right bold">{{ t.count }}</div>
          <i class="fa fa-user-plus" aria-hidden="true"></i>&nbsp;{{ t.name }}<br>
          <small>{{ t.type_name }}</small>
        </div>
      </div>
    </div>
  </div>

  <div class="portlet-body">

    <ngx-datatable *ngIf="!getCampService().isOngoingRequest()" class="striped" [rows]="rows" [columnMode]="'flex'"
      [headerHeight]="50" [footerHeight]="50" [rowHeight]="40" [selectionType]="'single'" (select)='onSelect($event)'>
      <ngx-datatable-column name="Data di creazione" [flexGrow]="3" prop="created_at" [sortable]="true"
        [resizeable]="false"></ngx-datatable-column>
      <ngx-datatable-column name="Order Closed" [flexGrow]="1" prop="closed_order" [sortable]="false"
        [resizeable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span *ngIf="row.closed_order == true"><i class="fa fa-check-circle"></i></span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Shipped" [flexGrow]="1" prop="shipment_code" [sortable]="false" [resizeable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span *ngIf="row.shipment_code"><i class="fa fa-truck"></i></span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

  </div>
</div>


<!-- BEGIN: MODAL SHIPMENT -->
<div [config]="{ show: false }" bsModal (onHidden)="onHiddenShipmentModal()" #shipmentModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="currentShipmentSelected?.id"> Edit Shipment </h4>
        <h4 class="modal-title pull-left" *ngIf="!currentShipmentSelected?.id"> New Shipment </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalShipment()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- sezione con il form della spedizione attivo -->
        <div class="row">
          <div class="col-md-12">
            <form role="form" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()"
              #f="ngForm" novalidate>
              <div class="form-body">
                <div class="row">
                </div>

                <div class="row" *ngIf="currentShipmentSelected?.id">
                  <div class="col-md-12">
                    <app-shipment-materials [currentShipment]="currentShipmentSelected"
                      (newMaterialAdded)="showModalMaterial()" (materialSelected)="showMaterial($event)">
                    </app-shipment-materials>
                  </div>
                </div>

              </div>
              <div class="form-actions right">
                <img *ngIf="getCampService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
                <ng-template #confirmDelete><button type="button" class="btn btn-outline red"
                    (click)="destroyShipment();">Yes Delete!</button></ng-template>
                <button *ngIf="currentShipmentSelected?.id && !currentShipmentSelected?.closed_order" type="button"
                  class="btn btn-outline red"
                  [disabled]="getCampService().isOngoingRequest() || currentShipmentSelected?.closed_order"
                  [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i
                    class="fa fa-trash-o"></i> Delete</button>&nbsp;
                <!-- <button type="submit" [disabled]="getCampService().isOngoingRequest() || currentShipmentSelected?.closed_order" class="btn blue" *ngIf="currentShipmentSelected?.id">Update</button> -->
                <button *ngIf="currentShipmentSelected?.id && !currentShipmentSelected?.closed_order" type="button"
                  [disabled]="getCampService().isOngoingRequest()" (click)="finalizeOrder()" class="btn green"><i
                    class="fa fa-check green"></i>&nbsp;Send Request</button>
                <button *ngIf="!currentShipmentSelected?.id" type="button" class="btn default"
                  (click)="shipmentModal.hide()">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL SHIPMENT -->




<!-- BEGIN: MODAL MATERIAL -->
<div [config]="{ show: false }" bsModal (onHidden)="onHiddenMaterialModal()" #materialModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="currentMaterialSelected?.product_info?.id"> Edit Material Info </h4>
        <h4 class="modal-title pull-left" *ngIf="!currentMaterialSelected?.product_info?.id"> New Material </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalMaterial()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="currentMaterialSelected">
          <div class="col-md-12">
            <app-shipment-material-form [model]="currentMaterialSelected" (materialSaved)="materialSaved()"
              (cancelEvent)="cancelEvent()"></app-shipment-material-form>
          </div>
        </div>
        <div class="row" [hidden]="currentMaterialSelected">
          <div class="col-md-12">
            <app-shipment-material-search-form #materialSearchForm [defaultTags]="'camp'"
              (searchResult)="searchResult($event)"></app-shipment-material-search-form>
          </div>
        </div>
        <br>
        <div class="row" [hidden]="currentMaterialSelected">
          <div class="col-md-12">
            <app-shipment-material-datatable #materialSearchDatatable (productSelected)="productSelected($event)">
            </app-shipment-material-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL MATERIAL -->
