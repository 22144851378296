import { ConfigService } from './../_services/config.service';
import { Component, OnInit } from '@angular/core';
import { PersonService, AuthenticationService, ReminderService, HelperService } from '../_services/index';
import { environment } from '../environment';
import { addDays, isPast } from 'date-fns'
import { User } from '../_models/index';
import { UserService, LoggedService } from '../_services/index';


@Component({
  moduleId: module.id,
  selector: 'header-app',
  templateUrl: 'header.component.html'
})

export class HeaderComponent implements OnInit {
  currentUser: User;
  currentLanguage: string;
  currentVersion: string;
  reminderNotification: number = 0;

  subscribeReminder: any;

  constructor(private userService: UserService, private ls: LoggedService,
    private personService: PersonService, private auth: AuthenticationService,
    private reminderService: ReminderService,
    private configService: ConfigService) {

    this.currentVersion = environment.version;

    this.personService.getUpdateUserObservable().subscribe((user: string) => {

      var jsonUser = JSON.parse(user);

      if (this.currentUser.id == jsonUser.userID) {
        //  Se esiste il path per l'immagine costruisco l'url completo
        if (jsonUser.avatarURL)
          this.currentUser.avatarURL = this.configService.blobBasePath + jsonUser.avatarURL;

        this.auth.update(this.currentUser);
      }

    });

    let lang = null;

    if (typeof localStorage != "undefined") {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      lang = localStorage.getItem("locale");
    }

    if (!lang) {
      lang = "en";
      if (typeof localStorage != "undefined") {
        localStorage.setItem("locale", lang);
      }
    }

    this.currentLanguage = lang;
  }

  setLanguage(lang) {
    this.currentLanguage = lang;
    localStorage.setItem("locale", lang);
    location.reload();
  }

  updateCurrentUser() {
    this.auth.updateCurrentUserData();
  }

  ngOnInit() {
    this.ls.getLogged().subscribe((logged: boolean) => {
      if (logged)
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      else
        this.currentUser = null;
    });

    this.subscribeReminder = this.reminderService.getsubjectToUpdateReminderObservable().subscribe((res: any) => {

      let rows = this.reminderService.getTableData(res);

      this.reminderNotification = 0;

      for (let key in rows) {

        let r = rows[key];

        // non completato
        if (typeof r.completed[0] == "undefined") {
          // scaduto
          if (isPast(HelperService.parseDate(r.start_date_reminder)) || isPast(HelperService.parseDate(r.notification_date_time))) {
            this.reminderNotification++;
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscribeReminder.unsubscribe();
  }
}
