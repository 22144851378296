import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import {
  EnterpriseComponent,
  EnterpriseDatatableComponent,
  EnterpriseSearchFormComponent
} from "./../../enterprise/index";
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import {
  TheatrinoService,
  HelperService,
  ConfigService,
} from "../../_services/index";
import {
  IAngularMyDpOptions,
  IMyDateModel
} from 'angular-mydatepicker';
import { NotificationsService } from "angular2-notifications";
import { format, isEqual } from "date-fns";
import { parseISO } from 'date-fns';

// *  modale scheduling
import { BackendService } from './../../_services/backend.service';
// *  modale scheduling

@Component({
  selector: "app-theatrino-booking-info-form",
  templateUrl: "./theatrino-booking-info-form.component.html",
  styleUrls: ["./theatrino-booking-info-form.component.css"]
})
export class TheatrinoBookingInfoFormComponent implements OnInit {
  @ViewChild('modalCopy', { static: false }) public modalCopy: ModalDirective;
  @ViewChild('modalEnterprise', { static: false }) public modalEnterprise: ModalDirective;
  @ViewChild('enterpriseDatatable', { static: false }) public enterpriseDatatable: EnterpriseDatatableComponent;
  @ViewChild('enterpriseSearchForm', { static: true }) public enterpriseSearchForm: EnterpriseSearchFormComponent;


  // *  modale scheduling
  @ViewChild('modalScheduling', { static: false }) public modalScheduling: ModalDirective;
  loadingData: boolean;
  rawData: any;
  // *  modale scheduling

  @ViewChild('f', { static: true }) public currentForm: NgForm;

  @Input() model: any = null;
  @Input() defaultDate: Date;
  @Input() defaultType: number;

  @Output() enableTab: EventEmitter<number> = new EventEmitter<number>();
  @Output() disableTab: EventEmitter<number> = new EventEmitter<number>();

  private localData: IMyDateModel;

  private enterpriseType: number = 0;

  subscribe: any;

  editMode: boolean = false;

  lockedMode: boolean = false;

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(
    private theatrinoService: TheatrinoService,
    private helper: HelperService,
    private configService: ConfigService,
    private notify: NotificationsService,
    private router: Router,
    private route: ActivatedRoute,
    private backend: BackendService
  ) {
    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    this.subscribe = this.theatrinoService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {
      if (this.theatrinoService.currentTheatrinoInfo)
        this.prepareView();
    });

    //   se non sono entrato nel component che sto recuperando le informazioni base allora costruisco la scena nuda
    if (!this.theatrinoService.ongoingRequest && this.theatrinoService.currentTheatrinoInfo)
      this.prepareView();

  }

  prepareView() {
    this.model = this.theatrinoService.currentTourInfo;

    if (this.model == null) {
      this.model = {};
      this.editMode = true;
    }
    else {
      this.editMode = false;

      if (typeof this.model.shows == "undefined")
        this.model.shows = [];

      //  Formatto la data
      if (this.model.date != null) {
        this.localData = this.helper.initMyDataInputSingle(this.model.date);
      }
    }

    if (this.route.snapshot.queryParams['editMode'] && this.route.snapshot.queryParams['editMode'] == "true") {
      this.editMode = true;
    }

    //  se esiste una convenzione generata non posso permettere la modifica delle info della prenotazione
    if (this.model.agreement_id != null && typeof this.model.agreement_id != "undefined")
      this.editMode = false;

    //  Comunque ogni volta tento di disabilitare i giorni che non fanno parte del theatrino corrente
    var dateStart = parseISO(
      this.theatrinoService.currentTheatrinoInfo.start);
    var dateEnd = parseISO(
      this.theatrinoService.currentTheatrinoInfo.end);

    //  Elimino le date antecedenti e successive al tour dal picker della data
    this.datePickerOptions.disableUntil = {
      year: dateStart.getFullYear(),
      month: dateStart.getMonth() + 1,
      day: dateStart.getDate() - 1
    };
    this.datePickerOptions.disableSince = {
      year: dateEnd.getFullYear(),
      month: dateEnd.getMonth() + 1,
      day: dateEnd.getDate() + 1
    };

    //  Disabilito anche tutte le date che non sono selezionabili, come ad esempio i day off
    var invalidDates = [];
    this.theatrinoService.currentTheatrinoInfo.tour_date.forEach(
      element => {
        if (element.type == 1 || element.type == 3) {
          var day = parseISO(element.date);
          invalidDates.push({
            year: day.getFullYear(),
            month: day.getMonth() + 1,
            day: day.getDate()
          });
        }
      }
    );

    this.datePickerOptions.disableDates = invalidDates;

    //  Controllo che sia una data del theatrino che è già stata inclusa in una convenzione
    if (this.model.agreement_id) this.lockedMode = true;


    //  Se esiste un default type default sto aprendo una data diversa da quella normale, preparo il model
    if (typeof this.defaultType != "undefined") {
      //  indico il cambiamento del dayoff
      this.typeChange(this.defaultType);
    }

    if (this.defaultDate != null) {
      var data = HelperService.parseDate(this.defaultDate);

      this.localData = this.helper.initMyDataInputSingle(data);

      //  Aggiungo la data anche al model
      this.model.date = format(data, "yyyy-MM-dd");
    }

  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  closeModalEvent(event) {
    this.hideModalCopy();
  }

  edit() {
    this.editMode = true;
  }

  typeChange(event) {

    this.defaultType = event;

    //  Se è una prenotazione normale allora è tutto ok
    if (this.defaultType == 2) {
      this.model.type = 2;

      this.model.time = "";
      this.model.enterprise_id = null;
      this.model.enterprise_organizer_id = null;
      this.model.area = null;
      this.model.status = null;
      this.model.company = null;

      if (this.model.id) {
        this.enableTab.emit(1)
        this.disableTab.emit(2)
      }
    }
    //  Altrimenti non devo poter settare nulla
    else {
      this.model.type = this.defaultType;

      this.model.time = "0";
      this.model.enterprise_id = 0;
      this.model.enterprise_organizer_id = 0;
      this.model.area = 0;
      this.model.status = 0;
      this.model.company = 0;
      this.model.admin_annotation = null;

      if (this.model.id) {
        this.enableTab.emit(2)
        this.disableTab.emit(1)
      }
    }

  }

  SubmitOtherEvent() {

    if (this.isDateTypeSame()) {
      this.notify.error("A similar event already exists");
      return;
    }

    //  Solo al submit controllo che sia tutto in relazione ai giorni di riposo
    if (!this.helper.isADateTourStandard(this.model.type) && this.helper.isMyDateValid(this.localData)) {
      if (this.model.id != null)
        this.theatrinoService.updateTheatrinoTourDateInfo(this.model, () => { this.editMode = false; });
      else
        this.theatrinoService.saveTheatrinoTourDateInfo(this.model, () => {

          this.editMode = false;

          //  alla fine, appena ottenuto l'id mi sposto nella schermata di modifica
          this.router.navigate([
            "theatrino/" +
            this.theatrinoService.currentTheatrinoId +
            "/tour/edit/" +
            this.theatrinoService.currentTourId
          ]);
        });
    }
  }

  //  Controlla se per questa data è possibile inserire la tipoliga di evento
  isDateTypeSame() {
    let found = false;

    //  Se si sta inserendo una booking devo controllare che non ci siano eventi bloccanti come ad esempio gli swap day
    if (this.model.type == 2)
      this.theatrinoService.currentTourInfoList.forEach(element => {
        if (isEqual(parseISO(this.model.date), parseISO(element.date))) {
          if (element.type == 2 && this.model.type == element.type)
            return found = false;
          if (element.type == 1)
            return found = true;
        }
      });
    //  Se inserisco una nota posso sempre inserirla
    else
      if (this.model.type == 4)
        return found = false;
      //  Controllo se inserisco un evento simile a quello già presente
      else {
        this.theatrinoService.currentTourInfoList.forEach(element => {
          if (isEqual(parseISO(this.model.date), parseISO(element.date)))
            if (this.model.type == element.type && this.model.id != element.id) {
              return found = true;
            }
        });
      }

    return found;
  }


  submit() {

    if (this.isDateTypeSame()) {
      this.notify.error("A similar event already exists");
      return;
    }

    //  Se è una prenotazione diversa da quella normale
    if (!this.helper.isADateTourStandard(this.model.type)) {
      this.SubmitOtherEvent();
      return;
    }
    //  Se non è una data di day off, setto il type corretto e marco touched tutti i campi
    else {
      this.model.type = 2;

      for (var i in this.currentForm.controls)
        this.currentForm.controls[i].markAsTouched();
    }

    //  Controllo se la data non è valida
    if (!this.helper.isMyDateValid(this.localData)) return;

    //  Controllo se il time non è valido
    this.onTimeInput("");
    if (!this.timevalid) {
      this.model.time = "";
      this.notify.error("You need to set a valid Time for Tour");
      return;
    }

    //  Controllo che sia stato inserito l'enterprise di riferimento
    if (!this.model.enterprise_id) {
      this.notify.error("You need to set an Enterprise for Tour");
      return;
    }

    //  Controllo che tutti gli alri campi restanti siano validi
    if (!this.currentForm.form.valid) return;

    //  Faccio l'update o il salvataggio in base a cosa sto salvando
    if (this.model.id != null)
      this.theatrinoService.updateTheatrinoTourDateInfo(this.model, () => {
        this.editMode = false;
      });
    else
      this.theatrinoService.saveTheatrinoTourDateInfo(this.model, () => {
        this.editMode = false;
        //  alla fine, appena ottenuto l'id mi sposto nella schermata di modifica
        this.router.navigate([
          "theatrino/" +
          this.theatrinoService.currentTheatrinoId +
          "/tour/edit/" +
          this.theatrinoService.currentTourId
        ]);
      });
  }

  destroy() {
    this.theatrinoService.deleteTheatrinoTourDateInfo(this.model, () => {
      this.router.navigate([
        "theatrino/edit/" + this.theatrinoService.currentTheatrinoId
      ]);
    });
  }



  GoToAgreement() {

    this.router.navigate([
      "/enterprise/" +
      this.model.enterprise_organizer_id +
      "/agreement/edit/" +
      this.model.agreement_id
    ]);
  }



  timevalid: boolean = true;
  onTimeInput(event) {
    this.timevalid = false;

    //  controllo se è valido il time
    this.configService.timeTourSelect.forEach(element => {
      if (element.value == this.model.time) return (this.timevalid = true);
    });
  }
  typeTimeOnSelect(event) {
    //  Se arriva questo evento al 100% è una data valida
    this.timevalid = true;
  }

  onDateChanged(event: IMyDateModel) {

    this.localData = event;

    if (event.singleDate.jsDate != null)
      this.model.date = this.helper.formatISODate(event);
    else
      this.model.date = null;
  }


  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.modalEnterprise.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  //  Evento di click sull'edit dell'enterprise
  enterpriseClicked() {
    this.enterpriseType = 1;
    this.showModal();
  }

  //  Evento di click sull'edit dell'enterprise organizzatrice
  enterpriseOrganizerClicked() {
    this.enterpriseType = 2;
    this.showModal();
  }

  //  Rimuovo l'enterprise principale
  removeEnterprise() {
    this.model.enterprise_id = null;
  }

  //  Rimuovo l'enterprise organizzatrice
  removeEnterpriseOrganizer() {
    this.model.enterprise_organizer_id = null;
  }

  searchKeywordsSubmit(params) {
    this.enterpriseDatatable.query = params;
    this.enterpriseDatatable.getResults();
  }

  resultSelected(selected) {
    if (this.enterpriseType == 1) {
      this.model.enterprise_id = selected.enterprise_id;

      //  se non è ancora presente la enterprise organizzattrice setto la stessa enterprise
      if (
        typeof this.model.enterprise_organizer_id == "undefined" ||
        this.model.enterprise_organizer_id == null
      )
        this.model.enterprise_organizer_id = selected.enterprise_id;
    } else if (this.enterpriseType == 2)
      this.model.enterprise_organizer_id = selected.enterprise_id;

    this.enterpriseType = 0;

    this.hideModal();
  }




  isModalCopyShow: boolean = false;
  copy(): void {
    this.isModalCopyShow = true;
  }

  showModalCopy(): void {
    this.isModalCopyShow = true;
  }

  hideModalCopy(): void {
    this.modalCopy.hide();
  }

  onHiddenCopy(): void {
    this.isModalCopyShow = false;
  }






  // *  modale scheduling

  isModalSchedulingShown: boolean = false;
  showModalScheduling(): void {
    this.isModalSchedulingShown = true;
  }

  hideModalScheduling(): void {
    this.modalScheduling.hide();
  }

  onHiddenScheduling(): void {
    this.isModalSchedulingShown = false;
  }


  SearchScheduling() {

    if (!this.helper.isMyDateValid(this.localData)) return;

    var found = false;

    var currentGroupID = null;

    for (var i = 0, len = this.theatrinoService.currentTourInfoList.length; i < len; i++) {
      if (this.theatrinoService.currentTourInfoList[i].date == this.model.date) {
        if (typeof this.theatrinoService.currentTourInfoList[i].accomodation != "undefined") {
          if (typeof this.theatrinoService.currentTourInfoList[i].accomodation.actor_group_id != "undefined" ||
            this.theatrinoService.currentTourInfoList[i].accomodation.actor_group_id != null) {
            currentGroupID = this.theatrinoService.currentTourInfoList[i].accomodation.actor_group_id
            found = true;
            break;
          }
        }
      }
    }

    //   Se non ho trovato il gruppo allora sicuramente non c'è
    if (!found) {
      this.notify.warn("Alert!", "No group on this date");
      return;
    }

    //  mosteo la modale
    this.showModalScheduling();

    if (currentGroupID == null) return;

    this.loadingData = true;

    //  Cancello quello che c'è nei dati attuali
    this.rawData = null;

    let urlString = "theatrino/group/" + currentGroupID + "/groupscheduling";

    this.backend.post(urlString, this.model).subscribe(
      (response) => {
        let res: any = response;

        this.loadingData = false;

        this.rawData = res;
      },
      (error) => {
        console.log("error");
        this.backend.showErrors(error);

        this.loadingData = false;
      }
    );

  }
  // *  modale scheduling

  getTheatrinoService() {
    return this.theatrinoService
  }

  getConfigService() {
    return this.configService
  }

  getLocalData() {
    return this.localData
  }

  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

}
