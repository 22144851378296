import { ModalDirective } from 'ngx-bootstrap/modal';
import { BackendService, HelperService } from './../../_services/index';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ConfigService, CampService } from 'app/_services';
import { addWeeks, format, differenceInWeeks } from 'date-fns';
import { NotificationsService } from 'angular2-notifications';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-camp-staff-form',
  templateUrl: './camp-staff-form.component.html',
  styleUrls: ['./camp-staff-form.component.css']
})
export class CampStaffFormComponent implements OnInit {


  @Input() model: any;
  @Output() memberDeleted = new EventEmitter;
  @Output() memberUpdated = new EventEmitter;

  @ViewChild('confirmModal', {static: false}) confirmModal: ModalDirective;

  editMode = false;
  loadingDocuments = false;

  //  mostra il form per inserire un nuovo tipo di documento
  public uploadDocument = false;
  private headers: any;

  //  contiene tutti i documenti di una persona
  personDocuments: any[];

  //  settimane presenti nel camp
  public campWeeks: number;
  public arrayOfWeeks: any[];

  //  file uploaders
  public documentUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf'],
      url: 'serverUrl'
    }
  );

  constructor(private configService: ConfigService, private campService: CampService,
              private helper: HelperService, private backend: BackendService,
              private notify: NotificationsService) {
  }

  ngOnInit()
  {
    //  Sono appena entrato e se mi è stato passato un membro dello staff
    //    richiedo i documenti al server
    if (this.model)
    {
      //  recupero le informazioni sui documenti della persona
      this.getAllDocument();

      //  calcolo le settimane da visualizzare
      this.arrayOfWeeks = [];
      this.campWeeks = differenceInWeeks(HelperService.parseDate(this.campService.currentCampInfo.end), HelperService.parseDate(this.campService.currentCampInfo.start)) + 1;
      for (var i=0; i<4; i++){
        this.arrayOfWeeks.push(addWeeks(HelperService.parseDate(this.campService.currentCampInfo.start), i));
      }

      //  controllo se è un camp director
      if (this.model.staff_email != null && typeof this.model.staff_email != "undefined")
        this.model.asdirector = true;
      else
        this.model.asdirector = false;
    }
  }

  setAsDirector()
  {
    if (this.model.asdirector == false)
      this.model.staff_email = null;
  }

  destroy ()
  {
    //  se è una camp directo ed è la direttrice del camp allora faccio dei controlli aggiuntivi
    if (this.model.type == 3 && this.model.staff_email)
    {
      if (this.campService.currentCampInfo.agreement_id)
      {
        this.notify.error("There is a signed convention", "it is not possible to delete the camp director");
        return;
      }
      if (this.campService.currentCampInfo.buyable)
      {
        this.notify.error("This camp is buyable online", "it is not possible to delete the camp director");
        return;
      }
    }

    this.campService.deleteCampStaffMember(this.model, () => {

      this.memberDeleted.emit(true);
    });
  }

  edit()
  {
    //  se è una camp directo ed è la direttrice del camp allora controllo che non ci sia una convenzione generata
    if (this.model.type == 3 && this.model.staff_email &&
        this.campService.currentCampInfo.agreement_id)
    {
      this.editMode = false;
      this.notify.warn("There is a signed convention", "it is not possible to change the camp director");
    }
    else
      this.editMode = true;
  }


  submit ()
  {
    if (this.model.id)
    {
      var counterCD = 0;
      this.campService.currentCampStaffList.forEach(element => {
        if (element.type == 3 && element.staff_email != null && element.id != this.model.id)
            counterCD++;
      });

      //  prima di salvare devo controllare se esiste più di un CD per questo campo
      if (counterCD >= 1 && this.model.staff_email != null && this.model.staff_email.length > 0)
        this.confirmModal.show();
      //   non posso modificare la camp director se il camp è online
      else if (counterCD == 0 && (this.model.staff_email == null || this.model.staff_email.length <= 0) && this.campService.currentCampInfo.buyable)
        this.notify.error("This camp is buyable online", "it is not possible to change the camp director");
      else
        this.updateStaffMember();
    }
  }

  confirmSubmit()
  {
    //  Prima di aggiornare il membro dello staff corrente aspetto di togliere
    //  il ruolo di CM a chi lo possiede in questo momento

    //	cerco la CM con il ruolo di amministratore del campo
    this.campService.currentCampStaffList.forEach(member => {
      if (member.staff_email != null && typeof member.staff_email != "undefined"
          && member.person_id != this.model.person_id)
        //   se lo trovo aggiorno il vecchio CM e aspetto la callback
        {
          member.staff_email = null;
          this.campService.updateCampStaffMember(member, () => {
            this.updateStaffMember();
          })
        }
    })
  }


  updateStaffMember () {
    this.campService.updateCampStaffMember(this.model, () => {
      this.memberUpdated.emit(true);

      //  nascondo la modale
      this.confirmModal.hide();
    });
  }

  //  è cambiato qualcosa nell'array
  dateChange (arrayPos: number)
  {
    switch (arrayPos)
    {
      case 1 :
        if (this.model.week_01)
          this.model.week_01 = null;
        else
          this.model.week_01 = format(this.arrayOfWeeks[0], 'yyyy-MM-dd');
        break;
      case 2 :
        if (this.model.week_02)
          this.model.week_02 = null;
        else
          this.model.week_02 = format(this.arrayOfWeeks[1], 'yyyy-MM-dd');
        break;
      case 3 :
        if (this.model.week_03)
          this.model.week_03 = null;
        else
          this.model.week_03 = format(this.arrayOfWeeks[2], 'yyyy-MM-dd');
        break;
      case 4 :
        if (this.model.week_04)
          this.model.week_04 = null;
        else
          this.model.week_04 = format(this.arrayOfWeeks[3], 'yyyy-MM-dd');
        break;
    }

  }

  //  controlla che il documento è già presente tra quelli dell'utente
  docLocked (key: number)
  {
    if (this.personDocuments.find(x => x.type == key))
      return true;

    return false;
  }

  downloadPdfTemplate(name: string) {
    this.campService.createPdfStaffDocument(this.model, name, () => {
      // console.log("ok");
    })
  }

  inviaMailTo(event: any, email) {

    event.target.disabled = true;

    this.campService.sendEmailStaffMemberInfoTo(this.model, email, () => {
      event.target.innerHTML = '<i class="fa fa-check-square-o aria-hidden="true"></i>&nbsp;Email inviata correttamente';
      this.notify.success("Email", "The email has been sent correctly");
    }, () => {
      event.target.innerHTML = '<i class="fa fa-exclamation-triangle aria-hidden="true"></i>&nbsp;Errore'
    });
  }

  inviaWhatsappTo(event: any, phone) {

    event.target.disabled = true;

    let message = "Hello from Educo! The details for your next camp transfer are ready! Log in to Gaston to see all the details. Check if all the details are ok before you download the tickets. Thank you!"

    this.campService.sendWhatsappStaffMemberInfoTo(this.model, phone, message, () => {
      event.target.innerHTML = '<i class="fa fa-check-square-o aria-hidden="true"></i>&nbsp;Invia il messaggio da whatsapp';
      this.notify.success("Whatsapp", "Invia il messaggio dal tab aperto su whatsapp");
    });
  }


  getAllDocument ()
  {
    this.loadingDocuments = true;
    this.uploadDocument = false;

      this.campService.getAllDocumentForStaffMember(this.model, (res) => {
        this.personDocuments = res;
        this.loadingDocuments = false;
      }, ()=> {
        this.personDocuments = new Array();
        this.loadingDocuments = false;
      });
  }


  //  sto cercando di caricare un nuovo documento
  currentDcType: number;
  addNewDocument (type: number)
  {
    this.currentDcType = type;

    //  Setto l'url per il caricamento delle foto e gli header
    this.headers = this.campService.getFileUploadHeader();
    this.documentUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.documentUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.documentUploaded(item, response, status);
    };
    this.documentUploader.options.url = this.campService.getStaffDocumentFileUploadURL(this.model, this.currentDcType);

    this.uploadDocument = true;
  }

  //  cancella l'inserimento di un nuovo documento
  clearNewDocument ()
  {
    this.currentDcType = null;
    this.uploadDocument = !this.uploadDocument;
  }


  //  fa partire una richiesta per la cancellazione di un documento
  destroyDocument (documentId: number)
  {
    this.loadingDocuments = true;

    this.campService.deleteDocumentForStaffMember(this.model, documentId, () => {

      this.loadingDocuments = false;
      this.getAllDocument();
    });
  }


  //  Documento Caricato
  public documentUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.getAllDocument();
      }
      this.campService.uploadedFileResponse(item, response, status,  this.model.id);
    }
  }

  getCampService() {
    return this.campService
  }

  checkSubmitKeyEnter(event){

    if (event.target.name == "birthplace")
      return false;

    return true;
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

}
