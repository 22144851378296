import { NotificationsService } from 'angular2-notifications';
import { Component, OnInit, Input } from '@angular/core';
import { PersonService, HelperService } from '../../_services/index';

@Component({
  selector: 'app-person-account-form',
  templateUrl: './person-account-form.component.html',
  styleUrls: ['./person-account-form.component.css']
})
export class PersonAccountFormComponent implements OnInit {

  @Input() model: any;

  passwordFieldHidden: boolean = true;

  constructor(private personService: PersonService, private helper: HelperService,
              private notify: NotificationsService) { }

  subscribe: any;

  ngOnInit() {

    this.model = this.personService.currentPersonAccount;

    if (this.model == null)
      this.model = {};

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      this.model = this.personService.currentPersonAccount;

      if (this.model == null)
        this.model = {};
    });

  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }

  submit() {

    this.passwordFieldHidden = true;

    //  Controllo che la username non sia una mail e che non sia uguale alla attuale email
    if (this.helper.validateEmail(this.model.name) || this.model.name == this.model.email)
    {
      this.notify.error("Username can not be an Email");
      return;
    }

    if (this.model.id)
      this.personService.updatePersonAccount(this.model);
    else
      this.personService.savePersonAccount(this.model);
  }

  destroy() {
    if (this.model.id) {
      this.personService.deletePersonAccount(this.model);
    }
  }

  getPersonService() {
    return this.personService
  }

}
