<!-- <alert type="warning" *ngIf="ongoingRequest">
  <strong>Loading</strong> ...
</alert> -->
<div class="datatable-loader text-center" *ngIf="ongoingRequest">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>
        
<ngx-datatable *ngIf="!ongoingRequest"
  class="striped"
  [rows]="rows"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="true"
  [externalSorting]="true"
  [count]="pageTotalElements"
  [offset]="pageNumber"
  [limit]="pageSize"
  [selected]="selected"
  [selectionType]="'single'"
  (activate)="onActivate($event)"
  (select)='onSelect($event)'
  (page)='setPage($event)'
  (sort)='onSort($event)'
  >

  <ngx-datatable-column name="Type" [flexGrow]="1" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
          {{row['type_name']}} 
          <span *ngIf="row['type_sub_name']">({{row['type_sub_name']}})</span>
          <span *ngIf="row['rating_star']">
            <p [innerHTML]="row['rating_star']"></p>
          </span>
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Denomination" [flexGrow]="2" prop="denomination" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Contact" [flexGrow]="1" prop="contact" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Address" [flexGrow]="3" prop="address" [resizeable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span *ngIf="row['country_long_version'] == null" tooltip="The address is not googlized or not primary address" placement="auto"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{{value}}</span>
          <span *ngIf="row['address'] != null">{{value}}</span>
      </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
