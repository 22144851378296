<h3 class="">Search</h3>
<hr>
<form *ngIf="!advancedSearchShow" action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="input-group input-group-lg input-icon right" [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }">
        <i class="fa fa-times-circle" aria-hidden="true" (click)="model.simpleSearchKeyword = '';"></i>
        <input type="text" name="simpleSearchKeywordText" #simpleSearchKeywordText="ngModel" [(ngModel)]="model.simpleSearchKeyword"
          (keydown)="fieldChange($event);" required minlength="4" class="form-control" placeholder="Search for people name...">
        <span class="input-group-btn">
          <button class="btn green" [disabled]="ongoingRequest" type="submit">Search</button>
        </span>
      </div>
      <small [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }">
        <span class="help-block">Type a word to search in surname and name, min length 4 chars</span>
      </small>
    </div>
    <div class="col-md-6">
      <button type="button" [disabled]="ongoingRequest" (click)="showAdvancedSearch()" class="btn default btn-lg">Advanced Search
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
</form>


<form *ngIf="advancedSearchShow" action="#" name="form2" (ngSubmit)="f2.form.valid && submitSearch()" #f2="ngForm" novalidate>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label class="control-label">Role Type</label>
        <select class="form-control" name="role_type" [(ngModel)]="model.role_type" (change)="fieldChange($event);">
          <option value=""></option>
          <option *ngFor="let r of getConfigService().rolePersonSelect" [value]="r.key">{{r.value}}</option>
        </select>
        <span class="help-block">Search by role type</span>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Account</label>
        <input type="text" class="form-control" placeholder="" name="account" [(ngModel)]="model.account" (keydown)="fieldChange($event);">
        <span class="help-block">Search in account email or username</span>
      </div>
    </div>
    <div class="col-md-1">
      <div class="form-group">
        <label class="control-label">Account</label><br>
        <label class="mt-checkbox mt-checkbox-outline"> active
          <input type="checkbox" value="1" name="account_active" [(ngModel)]="model.account_active">
          <span></span>
        </label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="control-label">Bank Account</label>
        <input type="text" class="form-control" name="bank_account" (keydown)="fieldChange($event);" placeholder="" [(ngModel)]="model.bank_account">
        <span class="help-block">Search in Bank Name, Holder and IBAN/Email</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2">
      <div class="form-group" [ngClass]="{ 'has-error': f2.submitted && !surname.valid }">
        <label class="control-label">Surname</label>
        <input type="text" name="surname" #surname="ngModel" [(ngModel)]="model.surname" (keydown)="fieldChange($event);" class="form-control"
          placeholder="Chee Kin" minlength="3">
        <span class="help-block">min 3 chars</span>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group" [ngClass]="{ 'has-error': f2.submitted && !first_name.valid }">
        <label class="control-label">First Name</label>
        <input type="text" name="first_name" #first_name="ngModel" [(ngModel)]="model.first_name" (keydown)="fieldChange($event);"
          class="form-control" placeholder="Chee Kin" minlength="3">
        <span class="help-block">min 3 chars</span>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="control-label">Codice Fiscale/Tax ID</label>
        <input type="text" name="tax_id" [(ngModel)]="model.tax_id" (keydown)="fieldChange($event);" maxlength="16" class="form-control"
          placeholder="CF/Tax ID">
        <span class="help-block">Search in Tax ID or Italian CF</span>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="control-label">Contact</label>
        <input type="text" class="form-control" (keydown)="fieldChange($event);" placeholder="" name="contact" [(ngModel)]="model.contact">
        <span class="help-block">Search in Email/Phone/Website</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label class="control-label">Locality</label>
        <input type="text" class="form-control" placeholder="" name="locality" [(ngModel)]="model.locality" (keydown)="fieldChange($event);">
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="control-label">Country</label>
        <input type="text" class="form-control" placeholder="" name="country" [(ngModel)]="model.country" (keydown)="fieldChange($event);">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label class="control-label">State/Provincia</label>
        <input type="text" class="form-control" placeholder="" name="provincia" [(ngModel)]="model.provincia" (keydown)="fieldChange($event);"
        [typeahead]="provinceArray" [typeaheadMinLength]="0"
        [typeaheadWaitMs]="0" [typeaheadScrollable]="true" autocomplete="off">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label class="control-label">Region</label>
        <input type="text" class="form-control" placeholder="" name="regione" [(ngModel)]="model.regione" (keydown)="fieldChange($event);"
        [typeahead]="regioniArray" [typeaheadMinLength]="0"
        [typeaheadWaitMs]="0" [typeaheadScrollable]="true" autocomplete="off">
      </div>
    </div>
  </div>

  <div class="form-actions right">
    <button type="button" class="btn" (click)="hideAdvancedSearch()">Close</button>
    <button type="button" class="btn default" (click)="clearAdvancedSearch()">Clear
        <i class="fa fa-eraser" aria-hidden="true"></i>
    </button>
    <button type="submit" [disabled]="ongoingRequest" class="btn green">Search
      <i class="fa fa-search"></i>
    </button>
  </div>
</form>
