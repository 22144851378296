import { UserService, HelperService, ConfigService, AdminReportsService } from 'app/_services';
import { Component, OnInit } from '@angular/core';
import { parseISO } from 'date-fns';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { IMyInputFieldChanged } from 'angular-mydatepicker';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-admin-reports-revision',
  templateUrl: './admin-reports-revision.component.html',
  styleUrls: ['./admin-reports-revision.component.css']
})
export class AdminReportsRevisionComponent implements OnInit {

  constructor(private userService: UserService,
    private configService: ConfigService, private helper: HelperService, private adminReportsService: AdminReportsService) {

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;
  }

  //  model della ricerca
  model: any = {};

  // generic rows
  rows = [];

  // DATE PICKER

  localDateFrom: IMyDateModel;
  localDateTo: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  datePickerOptionsBegin: IAngularMyDpOptions = {};
  datePickerOptionsEnd: IAngularMyDpOptions = {};

  ngOnInit() {

    this.clearSearch();
  }

  submitSearch() {

    this.adminReportsService.getReport("revision", this.model, (results) => {

      results.forEach(element => {

        //  setto la data formattata
        element.created_at_formatted = parseISO(element.created_at).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit", hour: "numeric", minute: "numeric" });
        element.updated_at_formatted = parseISO(element.updated_at).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit", hour: "numeric", minute: "numeric" });
      });

      this.rows = JSON.parse(JSON.stringify(results));

    })
  }


  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    this.exportParse(this.rows, () => {
      this.hideModalExport();
    });

  }



  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse(data: any, callback: any) {

    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach((element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      let obj = {
        'Data': element.updated_at,
        'User ID': element.user_id,
        'Type': element.revisionable_type,
        'Type ID': element.revisionable_id,
        'Key': element.key,
        'Old value': element.old_value,
        'New value': element.new_value,
      };

      //  preparo
      exportedArray.push(obj)

    })

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{ width: 20 }, { width: 20 }, { width: 40 }, { width: 20 }, { width: 40 }, { width: 200 }, { width: 200 }];

    // //  personalizzo l'header
    worksheet['!rows'] = [{ hpx: 30 }];

    // //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'Reports revision': worksheet }, SheetNames: ['Reports revision'] };

    // //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });

    // //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], { type: EXCEL_TYPE }), "reports_revision_"+this.model.account_id+".xlsx");

    callback();

  }


  clearSearch() {
    this.model = { year: new Date().getFullYear(), month: this.configService.monthSelect()[new Date().getMonth()] }
  }

  //  Operazioni sulla data di start
  onDateFromChanged(event: IMyDateModel)
  {
    this.localDateFrom = event;

    if (event.singleDate.jsDate != null)
    {
      this.model['from_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;

      this.datePickerOptionsEnd = datePickerOpt;
    }
    else
    {
      this.model['from_date'] = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }



  //  Operazioni sulla data di end
  onDateToChanged(event: IMyDateModel) {

    this.localDateTo = event;

    if (event.singleDate.jsDate != null)
    {
      this.model['to_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableSince = event.singleDate.date;

      this.datePickerOptionsBegin = datePickerOpt;
    }
    else
    {
      this.model['to_date'] = null;
      this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }


  fieldChange(event) {

    // this.searchKeywordsSubmit.emit(this.model);
  }







  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.isModalExport = false;
    // this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }



  getAdminReportsService() {
    return this.adminReportsService;
  }

  getUserService() {
    return this.userService
  }

  getConfigService() {
    return this.configService;
  }

  getHelper() {
    return this.helper;
  }

}
