<div *ngIf="!getTheatrinoService().ongoingRequest">
  <div>
    <h3 class="form-section">Theatrino Tour Booking List</h3>
  </div>
  <div class="btn-group btn-group-justified hidden" role="group" aria-label="...">
    <div class="btn-group" role="group">
      <button type="button" class="btn green btn-block btn-outline" (click)="addNewTourDate();">
        <i class="fa fa-plus"></i>&nbsp; New booking</button>
    </div>
    <div class="btn-group" role="group">
      <button type="button" class="btn green btn-block btn-outline" (click)="addNewGeneral();">
        <i class="fa fa-plus"></i>&nbsp; New General Event</button>
    </div>
    <div class="btn-group" role="group">
      <button type="button" class="btn red btn-block btn-outline" (click)="addNewDayOff();">
        <i class="fa fa-plus"></i>&nbsp; New Day Off</button>
    </div>
    <div class="btn-group" role="group">
      <button type="button" class="btn red btn-block btn-outline" (click)="addNewSwapDay();">
        <i class="fa fa-plus"></i>&nbsp; New Swap Day</button>
    </div>
  </div>

  <ngx-datatable
    class="striped" [rows]="rows"
    [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [selected]="selected"
    [selectionType]="'single'" (activate)="onActivate($event)" (select)='onSelect($event)'>

    <ngx-datatable-column name="Status" prop="status_flag" [resizeable]="false" [cellClass]="getCellClass" ></ngx-datatable-column>
    <ngx-datatable-column name="Date" prop="date_formatted" [resizeable]="false"></ngx-datatable-column>
    <ngx-datatable-column name="Enterprise" prop="enterprise_denomination" [resizeable]="false"></ngx-datatable-column>
    <ngx-datatable-column name="Organizer" prop="enterprise_organizer_denomination" [resizeable]="false"></ngx-datatable-column>

  </ngx-datatable>

</div>

<div class="row">
  <div class="pull-right">
    <button [appCheckPermission]="'theatrino_export'" *ngIf="!getTheatrinoService().ongoingRequest && getTheatrinoService().currentTourInfoList != null && getTheatrinoService().currentTourInfoList.length > 0" type="button" class="btn green" (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button>
  </div>
</div>


