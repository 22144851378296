<div class="row" *ngIf="requestCount == 0">

  <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="!currentAgreement?.id && errorMessages.length <= 0">
    <button type="button" class="btn green btn-block btn-outline" (click)="createAgreement();" ><i
        class="fa fa-check"></i>&nbsp; Confirm Agreement Creation</button>
  </div>

  <div class="col-xs-6 col-sm-6 col-md-6" *ngIf="currentAgreement?.id && errorMessages.length <= 0">
    <button type="button" class="btn red btn-block btn-outline" (click)="deleteAgreement();"><i
        class="fa fa-trash"></i>&nbsp; Delete Agreement</button>
  </div>
  <div class="col-xs-6 col-sm-6 col-md-6" *ngIf="currentAgreement?.id && errorMessages.length <= 0 && currentAgreement.status == 1">
    <button type="button" class="btn green btn-block btn-outline" (click)="downloadPdfAgreement();" ><i
        class="fa fa-file-pdf-o"></i>&nbsp; Download PDF Agreement</button>
  </div>

</div>

<hr>

<div class="row" *ngIf="currentAgreement?.id">
  <div *ngIf="!currentAgreement.url_document" class=" col-xs-12 col-sm-12 col-md-12">
    <div *ngIf="currentAgreement.url_document == undefined || currentAgreement.url_document == ''">
      <input *ngIf="!imageUploader.isUploading" type="file" id="imageUploader"
        ng2FileSelect [uploader]="imageUploader" />
      <label *ngIf="!imageUploader.isUploading" for="imageUploader"
        class="btn btn-block green-jungle text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload signed agreement!</label>
    </div>
    <div *ngIf="imageUploader.isUploading || getEnterpriseService().isOngoingRequest()">
      <img src="./assets/global/img/input-spinner.gif" />
      please wait...
    </div>
  </div>
  <div class="row" *ngIf="currentAgreement.url_document">
    <div class="col-xs-6 col-sm-6 col-md-6">
      <a class="btn-block btn blue" *ngIf="currentAgreement.url_document" href="{{getConfigService().blobBasePath+currentAgreement.url_document}}"
        target="_blank"> <i class="fa fa-save"></i>&nbsp; Download Signed Agreement </a>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-6">
      <button class="btn-block btn red" (click)="destroyFile();"><i class="fa fa-trash-o"></i>&nbsp; Remove Signed Agreement</button>
    </div>
  </div>
</div>

<hr>

<!-- BEGIN: Sezione dedicata alla gestione degli errori che impediscono la generazione della convenzione -->
<alert type="danger" *ngFor="let message of errorMessages">
  <strong>Error!</strong> {{message}}
</alert>
<alert type="warning" *ngFor="let message of warningMessages">
  <strong>Warning!</strong> {{message}}
</alert>
<!-- END: Sezione dedicata alla gestione degli errori che impediscono la generazione della convenzione -->

<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">
    <h3 class="form-section">{{ currentProjectName }} Agreement preview ({{ currentAgreement?.number }})</h3>

    <!-- BEGIN: parte dedicata alle informazioni di pagamento -->

    <h4> Payment info </h4>
    <div class="row">
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Totale Pagamento</label>
        <p> {{ (totalCost | currency:'EUR':'symbol':'1.2-2':'it') || "€ 0,00" }} </p>
      </div>
    </div>
    <!-- END: parte dedicata alle informazioni di pagamento -->

    <hr>

    <!-- BEGIN: parte dedicata alle informazioni della enterprise -->
    <div class="row" *ngIf="currentEnterprise">
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Denomination</label>
        <p> <a [routerLink]="['/enterprise/edit/',currentEnterprise.id]"> {{currentEnterprise.denomination}}</a> </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Address</label>
        <p> {{currentEnterprise.primaryAddress?.route}} {{currentEnterprise.primaryAddress?.street_number}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">CAP</label>
        <p> {{currentEnterprise.primaryAddress?.postal_code}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Locality</label>
        <p> {{currentEnterprise.primaryAddress?.locality}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Province</label>
        <p> {{currentEnterprise.primaryAddress?.administrative_area_level_2_long_version}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">CF/P.Iva</label>
        <p> C.F. {{ currentEnterprise.fiscal_code }} - Tax.Id {{ currentEnterprise.tax_id }} </p>
      </div>
    </div>

    <div class="row">
      <div *ngFor="let contact of currentEnterprise.contact | mapFromKeyValue">
        <div *ngIf="contact.type == 1" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Email:</label>
          <p> {{ contact.value }} </p>
          <ng-template #confirmMail>
            <button type="button" class="btn btn-outline green-jungle" (click)="inviaMailTo($event, contact.value)">Si, invia la mail!</button>
          </ng-template>
          <button *ngIf="currentAgreement?.id && errorMessages.length <= 0" type="button" [outsideClick]="true" [popover]="confirmMail" popoverTitle="Sei sicuro?" class="btn btn-outline btn-block blue"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Invia link alla convenzione</button>
        </div>
        <div *ngIf="contact.type == 2" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Fax:</label>
          <p> {{ contact.value }} </p>
        </div>
        <div *ngIf="contact.type == 8" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Web:</label>
          <p> {{ contact.value }} </p>
        </div>
        <div *ngIf="contact.type != 1 && contact.type != 2 && contact.type != 8" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Tel/Altro:</label>
          <p> {{ contact.value }} </p>
        </div>
      </div>
    </div>
    <!-- END: parte dedicata alle informazioni della enterprise -->

    <hr>
    <p> Legally represented by </p>
    <!-- BEGIN: parte dedicata alle informazioni del legale rappresentante della enterprise -->
    <div class="row" *ngIf="currentLegal">
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Surname and Name</label>
        <p> <a [routerLink]="['/person/edit/',currentLegal.id]"> {{currentLegal?.surname}} {{currentLegal?.first_name}}
          </a> </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Birthday</label>
        <p> {{currentLegal?.birthday}} </p>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Bithplace</label>
        <p> {{currentLegal?.birthplace}} </p>
      </div>
    </div>
    <div class="row" *ngIf="currentLegal">
      <div class="col-xs-4 col-sm-4 col-md-4">
        <label class="control-label">Fiscal Code &nbsp;
          <check-person-taxid [personInfo]="currentLegal"></check-person-taxid>
        </label>
        <p> {{currentLegal?.tax_id}} </p>
      </div>
    </div>
    <div class="row" *ngIf="currentLegal">
      <div *ngFor="let contact of currentLegal.contact | mapFromKeyValue">
        <div *ngIf="contact.type == 1" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Email:</label>
          <p> {{ contact.value }} </p>
          <ng-template #confirmMail>
            <button type="button" class="btn btn-outline green-jungle" (click)="inviaMailTo($event, contact.value)">Si, invia la mail!</button>
          </ng-template>
          <button *ngIf="currentAgreement?.id && errorMessages.length <= 0" type="button" [outsideClick]="true" [popover]="confirmMail" popoverTitle="Sei sicuro?" class="btn btn-outline btn-block blue"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Invia link alla convenzione</button>
        </div>
        <div *ngIf="contact.type == 2" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Fax:</label>
          <p> {{ contact.value }} </p>
        </div>
        <div *ngIf="contact.type == 8" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Web:</label>
          <p> {{ contact.value }} </p>
        </div>
        <div *ngIf="contact.type != 1 && contact.type != 2 && contact.type != 8" class="col-xs-4 col-sm-4 col-md-4">
          <label class="control-label">Tel/Altro:</label>
          <p> {{ contact.value }} </p>
        </div>
      </div>
    </div>
    <!-- END: parte dedicata alle informazioni del legale rappresentante della enterprise -->

    <hr>

    <!-- BEGIN: Theatrino -->

    <!-- BEGIN: Singola data del Tour -->
    <div *ngFor="let dataThea of currentAgreement?.tour_dates">
      <h4> Data: <a [routerLink]="[getTourRouterLink(dataThea)]"> {{getTourData(dataThea)}} </a> </h4>
      <h6> Work details </h6>
      <div class="row" style="font-weight: bold;">
        <div class="col-xs-1 col-sm-1 col-md-1">
          Da
        </div>
        <div class="col-xs-1 col-sm-1 col-md-1">
          A
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6">
          Attività
        </div>
        <div class="col-xs-1 col-sm-1 col-md-1">
          Iscritti
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3">
          Classe
        </div>
      </div>
      <div class="row" *ngFor="let show of dataThea.shows">
        <div class="col-xs-1 col-sm-1 col-md-1">
          {{show.time}}
        </div>
        <div class="col-xs-1 col-sm-1 col-md-1">
          {{getTotalShowDuration(show)}}
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6">
          <a [routerLink]="['/show/edit/',show.activities.id]"> {{show.activities.denomination}} </a>
        </div>
        <div class="col-xs-1 col-sm-1 col-md-1">
          {{getTotalShowSubscribers(show)}}
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3">
          {{show.class_level}}
        </div>
      </div>

      <br>

      <!-- BEGIN: parte dedicata alle informazioni della enterprise -->
      <p> Enterprise that host the show </p>

      <div *ngIf="dataThea.hosting">
        <div class="row">
          <div class="col-xs-4 col-sm-4 col-md-4">
            <label class="control-label">Denomination</label>
            <p> <a [routerLink]="['/enterprise/edit/',dataThea.hosting.id]"> {{dataThea.hosting?.denomination}} </a>
            </p>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4">
            <label class="control-label">Address</label>
            <p> {{dataThea.hosting?.primaryAddress?.route}} {{dataThea.hosting?.primaryAddress?.street_number}} </p>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4">
            <label class="control-label">CAP</label>
            <p> {{dataThea.hosting?.primaryAddress?.postal_code}} </p>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4">
            <label class="control-label">Locality</label>
            <p> {{dataThea.hosting?.primaryAddress?.locality}} </p>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4">
            <label class="control-label">Province</label>
            <p> {{dataThea.hosting?.primaryAddress?.administrative_area_level_2_long_version}} </p>
          </div>
        </div>

        <div class="row">
          <div *ngFor="let contact of dataThea.hosting?.contact | mapFromKeyValue">
            <div *ngIf="contact.type == 1" class="col-xs-4 col-sm-4 col-md-4">
              <label class="control-label">Email:</label>
              <p> {{ contact.value }} </p>
              <ng-template #confirmMail3>
                <button type="button" class="btn btn-outline green-jungle" (click)="inviaMailTo($event, contact.value)">Si, invia la mail!</button>
              </ng-template>
              <button *ngIf="currentAgreement?.id && errorMessages.length <= 0"  type="button" [outsideClick]="true" [popover]="confirmMail3" popoverTitle="Sei sicuro?" class="btn btn-outline btn-block blue"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Invia link alla convenzione</button>
            </div>
            <div *ngIf="contact.type == 2" class="col-xs-4 col-sm-4 col-md-4">
              <label class="control-label">Fax:</label>
              <p> {{ contact.value }} </p>
            </div>
            <div *ngIf="contact.type == 8" class="col-xs-4 col-sm-4 col-md-4">
              <label class="control-label">Web:</label>
              <p> {{ contact.value }} </p>
            </div>
            <div *ngIf="contact.type != 1 && contact.type != 2 && contact.type != 8" class="col-xs-4 col-sm-4 col-md-4">
              <label class="control-label">Tel/Altro:</label>
              <p> {{ contact.value }} </p>
            </div>
          </div>
        </div>

      </div>
      <!-- END: parte dedicata alle informazioni della enterprise -->

      <br><br>
    </div>
    <!-- END: Singola data del Tour -->

  </div>
  <!-- END: Theatrino -->

</form>
