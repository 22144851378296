import { ModalDirective } from 'ngx-bootstrap/modal';
import { CourseScheduleSearchFormComponent } from './../course-schedule-search-form/course-schedule-search-form.component';
import { CourseScheduleDatatableComponent } from './../course-schedule-datatable/course-schedule-datatable.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService, CourseScheduleService, ConfigService, HelperService } from '../../_services';

//  salvataggio per excel
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { format, parseISO } from 'date-fns';
import { CourseScheduleSearchResultCalendarComponent } from '../course-schedule-search-result-calendar/course-schedule-search-result-calendar.component';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-course-schedule-search-result',
  templateUrl: './course-schedule-search-result.component.html',
  styleUrls: ['./course-schedule-search-result.component.css']
})
export class CourseScheduleSearchResultComponent implements OnInit {

  @ViewChild('CourseScheduleDatatableComponent', {static: true}) datatable: CourseScheduleDatatableComponent;
  @ViewChild('CourseScheduleCalendarComponent', {static: true}) calendar: CourseScheduleSearchResultCalendarComponent;
  @ViewChild('CourseScheduleSearchFormComponent', {static: true}) searchForm: CourseScheduleSearchFormComponent;
  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;

  private subject: any;

  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService,
            private courseScheduleService: CourseScheduleService, private configService: ConfigService, private helper: HelperService) { }

  ngOnInit() {

    let params = {};

    if (this.courseScheduleService.lastSearchQueries != null){
      params = this.courseScheduleService.lastSearchQueries;
    }
    else {
      this.searchForm.model.year = new Date().getFullYear();
      this.searchForm.model.status = ["1", "2", "3", "4"];
      params = { year: new Date().getFullYear(), status: this.searchForm.model.status }
    }

    this.searchKeywordsSubmit(params);
  }

  updateResults(params) {
    this.datatable.query = params;
    this.datatable.getResult();

    this.calendar.query = params;
    this.calendar.getResult();
  }

  searchKeywordsSubmit(params) {

    // controllo se filtrare i risultati solo per persone presenti nello staff del corso quando non hanno il ruolo Staff
    if (!this.userService.isOfficeStaff()){
      params.current_user_staff_member = true;
    }

    if (typeof params != "undefined" && Object.keys(params).length > 0)
      this.updateResults(params);
  }


  courseScheduleSelected(selected)
  {
    this.router.navigate(['courseschedule/edit/'+selected.course_schedule_id]);
  }



  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }


  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    this.courseScheduleService.exportAll(this.searchForm.model, (res) => {
      this.exportParse(res, () => {
        this.hideModalExport();
      });
    }, () => {
      this.hideModalExport();
    });

  }


  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse (data: any, callback: any) {

    //  se non ci sono dati da salvare
    if (data.length == 0) {
      callback();
      return;
    }

    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach( (element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      //  preparo
      exportedArray.push( {
        'Tipo': this.configService.getCourseTypeValueByKey(element.course_type),
        'Nome': element.course_title,
        'Sofia code': element.sofia_code,
        'Stato': this.configService.getCourseScheduleStatusValueByKey(element.status),
        'Data inizio': parseISO(element.start).toLocaleDateString(this.helper.locale),
        'Data fine': parseISO(element.end).toLocaleDateString(this.helper.locale),
        'Orario': element.start_time + " - " + element.end_time,
        'Sede': element.sede,
        'Città': element.administrative_area_level_3_long_version,
        'PV': element.administrative_area_level_2_short_version,
        'Regione': element.administrative_area_level_1_long_version,
        'Località': element.locality,
        'Zona': this.configService.getAreaExpertiseValueByKey(element.area),
        'Company': this.configService.getGroupCompanyValueByKey(element.company),
        'Tot Request' : element.requests_count,
        'Tot Subscriber' : element.subscription_count,
        'Online' : element.online,
        'Online description url' : element.online_description_url,
      });

    })

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{width: 25}, {width:90}, {width:8}, {width:14}, {width:12}, {width:12}, {width:12}, {width:60}, {width:30}, {width:18},
                        {width:40}, {width:34}, {width:23}, {width:28}, {width:12}, {width:12}, {width:12}, {width:28}];

    //  personalizzo l'header
    worksheet['!rows'] = [{hpx: 30}];
    worksheet['A1'].s = { alignment: "horizontal" }

    //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'Course Schedule': worksheet}, SheetNames: ['Course Schedule'] };

    //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

    //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "Course Schedule.xlsx");

    callback();

  }

  getUserService() {
    return this.userService
  }

  getCourseScheduleService(){
    return this.courseScheduleService;
  }


}
