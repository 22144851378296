<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">

    <h3 class="form-section">Add In Enrollment Fee</h3>

    <div *ngIf="model.enrollment_fee" class="row">
      <div class="col-md-12">
        <alert type="success">
          <strong>Pagata:</strong> {{ model.enrollment_fee_amount | currency:'EUR':'symbol':'1.2-2':'it' }}<br>
        </alert>
      </div>
    </div>


    <div *ngIf="!model.enrollment_fee" class="row">
      <div class="col-md-12">
        <alert *ngIf="model.price_quota_associativa > 0" type="{{addInAlertType}}">
          <strong>Paypal:</strong> {{model.status}} - Transaction ID {{model.paypal_transaction_id}}<br>
          <strong>Payer:</strong> {{model.payer_first_name}} {{model.payer_last_name}} ({{model.payer_email}})<br>
          <strong>Address:</strong> {{model.payer_address_recipient_name}}, {{model.payer_address_city}}
          ({{model.payer_address_state}}) {{model.payer_address_postal_code}} {{model.payer_address_country_code}},
          {{model.payer_address_line1}} {{model.payer_address_line2}}<br>
          <strong>Enrollment fee:</strong> {{model.price_quota_associativa}} (+{{model.price_spese_gestione}})<br>
        </alert>
        <alert *ngIf="model.price_quota_associativa <= 0" type="warning">
          <strong>Online payment not received</strong>
        </alert>
      </div>
    </div>

    <div class="row" *ngIf="!model.enrollment_fee">
      <div class="col-md-6">
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="add_in_check" [(ngModel)]="model.addin_enrollment_fee"
                (change)="model.addin_enrollment_fee ? addInAlertType = 'success' : addInAlertType = 'warning'" />Confermo
              che l'iscrizione è stata pagata
              <span></span>
            </label>
            <p>Verrà aggiunta una voce Add In nel CashFlow del camp</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group" *ngIf="model.addin_enrollment_fee">
          <label class="control-label">Quota</label>
          <input type="number" min="0" name="add_in_value" [(ngModel)]="model.addin_enrollment_fee_value"
            #add_in_value="ngModel" class="form-control" required>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" *ngIf="model.addin_enrollment_fee"
          [ngClass]="{ 'has-error': (f.submitted || registeredvia.dirty || registeredvia.touched) && !registeredvia.valid }">
          <label class="control-label">Registered via
            <span class="required">*</span>
          </label>
          <select class="form-control" name="registered_via" [(ngModel)]="model.addin_enrollment_fee_registered_via"
            #registeredvia="ngModel" required>
            <option *ngFor="let c of getConfigService().primanotaRegisteredViaSelect" [value]="c.key">{{c.value}}
            </option>
          </select>
          <span class="help-block">Field required</span>
        </div>
      </div>
      <div class="col-md-12" *ngIf="model.addin_enrollment_fee">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="2" class="form-control" placeholder="Some notes..." name="addin_enrollment_fee_annotation"
            [(ngModel)]="model.addin_enrollment_fee_annotation"></textarea>
        </div>
      </div>
    </div>

    <h3 class="form-section">Discount <span *ngIf="model.quote_discount_free" class="pull-right">&nbsp;FREE</span></h3>

    <alert type="warning">
      <strong>Attenzione:</strong> tutti gli sconti per i subscribers selezionati verranno sovrascritti, quindi se prima un subscriber aveva uno sconto e in questa schermata non viene selezionato, lo sconto verrà rimosso
    </alert>

    <div class="row" *ngIf="canEditDiscount">
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_1" #quote_discount_1 [(ngModel)]="model.quote_discount_1"
            (change)="updateCourseFeeWithDiscount()">Iscrizione durante l'incontro genitori:
          {{ getCampService().currentCampInfo.quote_discount_1 | currency:'EUR':'symbol':'1.2-2':'it' }}
          <span></span>
        </label>
      </div>
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_2" #quote_discount_2 [(ngModel)]="model.quote_discount_2"
            (change)="updateCourseFeeWithDiscount()">
          Host Families (per host week):
          {{ getCampService().currentCampInfo.quote_discount_2 | currency:'EUR':'symbol':'1.2-2':'it' }}
          <span></span>
        </label>
      </div>
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_3" #quote_discount_3 [(ngModel)]="model.quote_discount_3"
            (change)="updateCourseFeeWithDiscount()">
          Sconto a fratello (per settimana):
          {{ getCampService().currentCampInfo.quote_discount_3 | currency:'EUR':'symbol':'1.2-2':'it' }}
          <span></span>
        </label>
      </div>
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_4" #quote_discount_4 [(ngModel)]="model.quote_discount_4"
            (change)="updateCourseFeeWithDiscount()">
          dal 3° fratello in poi:
          {{ getCampService().currentCampInfo.quote_discount_4 | currency:'EUR':'symbol':'1.2-2':'it' }}
          <span></span>
        </label>
      </div>
    </div>

    <div class="row" *ngIf="canEditDiscount">
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_early_bird" #quote_discount_early_bird
            [(ngModel)]="model.quote_discount_early_bird" (change)="updateCourseFeeWithDiscount()">
          <i *ngIf="suggestEarlyBirdDiscount" class="fa fa-check-circle" aria-hidden="true" style="color:green"></i>
          Early bird discount:
          {{ getCampService().currentCampInfo.quote_discount_early_bird | currency:'EUR':'symbol':'1.2-2':'it' }} from
          {{ quote_discount_early_bird_date_parsed | date:'d MMMM, y':'':'it'}}
          <span></span>
        </label>
      </div>
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_free" #quote_discount_free [(ngModel)]="model.quote_discount_free"
            (change)="updateCourseFeeWithDiscount()"> Free
          <span></span>
        </label>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Personalized discount</label>
          <input type="number" name="quote_discount_custom" [(ngModel)]="model.quote_discount_custom"
            #quote_discount_custom="ngModel" (change)="updateCourseFeeWithDiscount()" class="form-control">
        </div>
      </div>
    </div>

    <div class="row" *ngIf="canEditDiscount">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Discount notes</label>
          <textarea rows="2" class="form-control" placeholder="Some notes..." name="quote_discount_annotation"
            [(ngModel)]="model.quote_discount_annotation"></textarea>
        </div>
      </div>
    </div>


    <h3 class="form-section">Add In Course Fee <span *ngIf="model.quote_discount_free" class="pull-right">FREE</span>
    </h3>


    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="add_in_check" [(ngModel)]="model.addin_course_fee" />Confermo che la quota
              del corso è stata pagata
              <span></span>
            </label>
            <p>Verrà aggiunta una voce Add In nel CashFlow del camp</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group" *ngIf="model.addin_course_fee">
          <label class="control-label">Quota</label>
          <input type="number" name="add_in_value" [(ngModel)]="model.addin_course_fee_value" #add_in_value="ngModel"
            class="form-control">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" *ngIf="model.addin_course_fee"
          [ngClass]="{ 'has-error': (f.submitted || registeredvia.dirty || registeredvia.touched) && !registeredvia.valid }">
          <label class="control-label">Registered via
            <span class="required">*</span>
          </label>
          <select class="form-control" name="registered_via" [(ngModel)]="model.addin_course_fee_registered_via"
            #registeredvia="ngModel" required>
            <option *ngFor="let c of getConfigService().primanotaRegisteredViaSelect" [value]="c.key">{{c.value}}
            </option>
          </select>
          <span class="help-block">Field required</span>
        </div>
      </div>
      <div class="col-md-12" *ngIf="model.addin_course_fee">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="2" class="form-control" placeholder="Some notes..." name="addin_course_fee_annotation"
            [(ngModel)]="model.addin_course_fee_annotation"></textarea>
        </div>
      </div>
    </div>

  </div>

  <div class="form-actions right" [appCheckPermission]="'camp_edit'">
    <img *ngIf="getCampService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <button type="submit" class="btn blue" [disabled]="getCampService().isOngoingRequest()"
      *ngIf="selectedSubscribers && selectedSubscribers.length > 0">
      <span class="ladda-label">
        <i class="fa fa-save"></i> Apply to {{selectedSubscribers.length}} subscribers</span>
    </button>
  </div>

</form>
