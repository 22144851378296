<ngx-datatable
  #myTable
  [class]="editable == true ? 'striped' : ''"
  [rows]="_scheduleTable"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [messages]="{emptyMessage: 'Timetable empty'}"
  [rowClass]="getRowClass"
  [sorts]="[{ prop: 'date', dir: 'asc' }]"
  >
  <ngx-datatable-column name="Data" prop="date" [resizeable]="false">

    <ng-template ngx-datatable-cell-template let-row="row">
        {{row.date}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Start Time" prop="start_time" [resizeable]="false">
    <ng-template ngx-datatable-cell-template let-row="row">
        {{row.start_time}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="End Time" prop="end_time" [resizeable]="false">
    <ng-template ngx-datatable-cell-template let-row="row">
        {{row.end_time}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="" prop="" [resizeable]="false">
    <ng-template ngx-datatable-cell-template let-row="row">
      <span *ngIf="editable">
        <button type="button" class="btn btn-outline green" [disabled]="getCourseScheduleService().isOngoingRequest()" (click)="showTimesheetModal(row)" ><i class="fa fa-edit"></i>&nbsp;Edit</button>
        <ng-template #confirmDelete><button type="button" class="btn btn-outline red"
          (click)="deleteRow(row);">Yes Delete!</button></ng-template>
        <button type="button" class="btn btn-outline red"
          [outsideClick]="true" [disabled]="getCourseScheduleService().isOngoingRequest()" [popover]="confirmDelete" popoverTitle="Are you sure?"
        ><i class="fa fa-trash"></i></button>
      </span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template *ngIf="editable">
      <button type="button" class="btn btn-block btn-outline green" [disabled]="getCourseScheduleService().isOngoingRequest()" (click)="addSpecificLessonDate()" >Aggiungi una data e un orario</button>
    </ng-template>
  </ngx-datatable-footer>

  </ngx-datatable>



<div [hidden]="!isTimesheetModalShown" class="modal fade" bsModal #timesheetModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" (onHidden)="onTimesheetHidden()" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Date e orari di un corso</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="timesheetModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-course-schedule-table-date-row *ngIf="_rowEditing" [row]="_rowEditing" (updatedRowEvent)="timesheetUpdated($event)" (deleteRowEvent)="timesheetDeleted($event)"></app-course-schedule-table-date-row>
      </div>
    </div>
  </div>
</div>
