import { TutorService } from "../../_services/tutor.service";
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { PopoverDirective } from "ngx-bootstrap/popover";

@Component({
  selector: 'app-camp-week-tutors',
  templateUrl: './camp-week-tutors.component.html',
  styleUrls: ['./camp-week-tutors.component.css']
})
export class CampWeekTutorsComponent implements OnInit {

  @ViewChild('confirmDelete', { static: false }) confirmDelete;

  @Input() camp: any;
  @Input() weekIndex: number;
  @Input() calendarWeekIndex: number;
  @Output() closeEvent = new EventEmitter();
  @Input() allCamps: any;

  ongoingAvailableRequest: boolean = false;
  availableTutors: any[] = [];
  availablePageTotalElements: number = 0;
  availablePageNumber: number = 0;
  availablePageSize: number = 0;
  availablePageTotalPages: number = 0;
  availablePageSort: any = null;
  availableSelected = [];

  ongoingAssignedRequest: boolean = false;
  assignedTutors: any[] = [];
  assignedPageTotalElements: number = 0;
  assignedPageNumber: number = 0;
  assignedPageSize: number = 0;
  assignedPageTotalPages: number = 0;
  assignedPageSort: any = null;
  assignedSelected = [];

  ongoingAssignedToOthersRequest: boolean = false;
  assignedToOthersTutors: any[] = [];
  assignedToOthersPageTotalElements: number = 0;
  assignedToOthersPageNumber: number = 0;
  assignedToOthersPageSize: number = 0;
  assignedToOthersPageTotalPages: number = 0;
  assignedToOthersPageSort: any = null;
  assignedToOthersSelected = [];

  private firstSubmitAvailable: boolean = false;
  simpleSearchKeywordAvailable: string;

  private firstSubmitAssignedToOthers: boolean = false;
  simpleSearchKeywordAssignedToOthers: string;

  constructor(private tutorService: TutorService) { }

  ngOnInit() {

    this.getAvailableResults();
    this.getAssignedResults();
    this.getAssignedToOthersResults();
  }


  fieldChangeAvailable(event) {
    if (!this.firstSubmitAvailable)
      return;
  }

  submitSearchAvailable() {
    this.availablePageNumber = 0;
    this.getAvailableResults();
  }

  getAvailableResults() {

    this.availableSelected = [];
    this.availableTutors = [];
    this.availablePageTotalElements = 0;
    this.availablePageTotalPages = 0;

    let params = new HttpParams();
    params = params.set('page', (this.availablePageNumber + 1).toString());

    if (this.availablePageSort != null) {
      params = params.set('sortDir', this.availablePageSort.dir);
      params = params.set('sortBy', this.availablePageSort.prop);
    }

    params = params.set('start', this.camp.camp_weeks[this.weekIndex].start_date);
    params = params.set('end', this.camp.camp_weeks[this.weekIndex].end_date);

    if (this.simpleSearchKeywordAvailable != null && this.simpleSearchKeywordAvailable != '') {
      params = params.set('search', this.simpleSearchKeywordAvailable);
    }

    this.ongoingAvailableRequest = true;

    this.tutorService.getAvailableTutors(params,
      (jsonResponse) => {

        this.availablePageNumber = jsonResponse.current_page - 1;
        this.availablePageSize = jsonResponse.per_page;
        this.availablePageTotalElements = jsonResponse.total;
        this.availablePageTotalPages = jsonResponse.last_page;
        this.availableTutors = jsonResponse.data;

        this.ongoingAvailableRequest = false;
      }, (error) => {

        this.ongoingAvailableRequest = false;
      });
  }

  getAssignedResults() {

    this.assignedSelected = [];
    this.assignedTutors = [];
    this.assignedPageTotalElements = 0;
    this.assignedPageTotalPages = 0;

    let params = new HttpParams();
    params = params.set('page', (this.assignedPageNumber + 1).toString());

    if (this.assignedPageSort != null) {
      params = params.set('sortDir', this.assignedPageSort.dir);
      params = params.set('sortBy', this.assignedPageSort.prop);
    }

    this.ongoingAssignedRequest = true;

    this.tutorService.getAssignedTutors(
      this.camp.camp_id,
      this.camp.camp_weeks[this.weekIndex].id,
      params,
      (jsonResponse) => {

        this.assignedPageNumber = jsonResponse.current_page - 1;
        this.assignedPageSize = jsonResponse.per_page;
        this.assignedPageTotalElements = jsonResponse.total;
        this.assignedPageTotalPages = jsonResponse.last_page;
        this.assignedTutors = jsonResponse.data;

        this.ongoingAssignedRequest = false;
      }, (error) => {

        this.ongoingAssignedRequest = false;
      });
  }

  fieldChangeAssignedToOthers(event) {
    if (!this.firstSubmitAssignedToOthers)
      return;
  }

  submitSearchAssignedToOthers() {
    this.assignedPageNumber = 0;
    this.getAssignedToOthersResults();
  }

  getAssignedToOthersResults() {

    this.assignedToOthersSelected = [];
    this.assignedToOthersTutors = [];
    this.assignedToOthersPageTotalElements = 0;
    this.assignedToOthersPageTotalPages = 0;

    let params = new HttpParams();
    params = params.set('page', (this.assignedToOthersPageNumber + 1).toString());

    if (this.availablePageSort != null) {
      params = params.set('sortDir', this.assignedToOthersPageSort.dir);
      params = params.set('sortBy', this.assignedToOthersPageSort.prop);
    }

    params = params.set('start', this.camp.camp_weeks[this.weekIndex].start_date);
    params = params.set('end', this.camp.camp_weeks[this.weekIndex].end_date);
    params = params.set('camptoexclude', this.camp.camp_id);

    if (this.simpleSearchKeywordAssignedToOthers != null && this.simpleSearchKeywordAssignedToOthers != '') {
      params = params.set('search', this.simpleSearchKeywordAssignedToOthers);
    }

    this.ongoingAssignedToOthersRequest = true;

    this.tutorService.getAssignedTutorsToOtherCamps(params,
      (jsonResponse) => {

        this.assignedToOthersPageNumber = jsonResponse.current_page - 1;
        this.assignedToOthersPageSize = jsonResponse.per_page;
        this.assignedToOthersPageTotalElements = jsonResponse.total;
        this.assignedToOthersPageTotalPages = jsonResponse.last_page;
        this.assignedToOthersTutors = jsonResponse.data;

        this.ongoingAssignedToOthersRequest = false;
      }, (error) => {

        this.ongoingAssignedToOthersRequest = false;
      });
  }

  removeAssignedTutors() {

    let tempArray = this.assignedSelected;

    for (let i = 0; i < tempArray.length; i++) {

      let item = tempArray[i];

      this.ongoingAssignedRequest = true;

      this.tutorService.removeTutor(
        this.camp.camp_id,
        this.camp.camp_weeks[this.weekIndex].id,
        item.person_id,
        () => {

          let camp_week_tutors = this.camp.camp_weeks[this.weekIndex].camp_week_tutors;

          this.removeFromModel(camp_week_tutors, this.camp.camp_id, this.camp.camp_weeks[this.weekIndex].id, item.person_id);

          this.ongoingAssignedRequest = false;

          this.getAssignedResults();
          this.getAvailableResults();
        }, (error) => {

          this.ongoingAssignedRequest = false;
        });
    }
  }

  addAvailableTutors() {

    let tempArray = this.availableSelected;

    for (let i = 0; i < tempArray.length; i++) {

      let item = tempArray[i];

      this.ongoingAvailableRequest = true;

      this.tutorService.addTutor(
        this.camp.camp_id,
        this.camp.camp_weeks[this.weekIndex].id,
        item.person_id,
        () => {

          let camp_week_tutors = this.camp.camp_weeks[this.weekIndex].camp_week_tutors;

          this.addToModel(camp_week_tutors, this.camp.camp_id, this.camp.camp_weeks[this.weekIndex].id, item.person_id);

          this.ongoingAvailableRequest = false;

          this.getAvailableResults();
          this.getAssignedResults();
        }, (error) => {

          this.ongoingAvailableRequest = false;
        });
    }
  }

  close() {
    this.closeEvent.emit();
  }

  //
  setAvailablePage(pageInfo) {
    this.availablePageNumber = pageInfo.offset;
    this.getAvailableResults();
  }

  onAvailableSort(sortInfo) {

    this.availablePageSort = sortInfo.sorts[0];
    this.getAvailableResults();
  }

  onAvailableSelect({ selected }) {
    //this.tutorApplicationSelected.emit(selected[0]);
  }

  //
  setAssignedPage(pageInfo) {
    this.availablePageNumber = pageInfo.offset;
    this.getAssignedResults();
  }

  onAssignedSort(sortInfo) {

    this.availablePageSort = sortInfo.sorts[0];
    this.getAssignedResults();
  }

  onAssignedSelect({ selected }) {
    //this.tutorApplicationSelected.emit(selected[0]);
  }

  //
  setAssignedToOthersPage(pageInfo) {
    this.availablePageNumber = pageInfo.offset;
    this.getAssignedResults();
  }

  onAssignedToOthersSort(sortInfo) {

    this.availablePageSort = sortInfo.sorts[0];
    this.getAssignedResults();
  }

  onAssignedToOthersSelect({ selected }) {
    //this.tutorApplicationSelected.emit(selected[0]);
  }

  removeAssignedToOthersTutors() {

    let tempArray = this.assignedToOthersSelected;

    for (let i = 0; i < tempArray.length; i++) {

      let item = tempArray[i];

      this.ongoingAssignedToOthersRequest = true;

      this.tutorService.removeTutor(
        item.camp_id,
        item.camp_week_id,
        item.person_id,
        () => {

          let otherCamp = this.allCamps.find(x => x.camp_id == item.camp_id);

          let otherCampWeek = otherCamp.camp_weeks.find(x => x.id == item.camp_week_id);

          let camp_week_tutors = otherCampWeek.camp_week_tutors;

          this.removeFromModel(camp_week_tutors, item.camp_id, item.camp_week_id, item.person_id);

          this.ongoingAssignedToOthersRequest = false;

          this.getAssignedToOthersResults();
          this.getAvailableResults();

        }, (error) => {

          this.ongoingAssignedToOthersRequest = false;
        });
    }
  }

  removeAndAssingAssignedToOthersTutors() {

    let tempArray = this.assignedToOthersSelected;

    for (let i = 0; i < tempArray.length; i++) {

      let item = tempArray[i];

      this.ongoingAssignedToOthersRequest = true;

      this.tutorService.removeTutor(
        item.camp_id,
        item.camp_week_id,
        item.person_id,
        () => {

          let otherCamp = this.allCamps.find(x => x.camp_id == item.camp_id);

          let otherCampWeek = otherCamp.camp_weeks.find(x => x.id == item.camp_week_id);

          let camp_week_tutors = otherCampWeek.camp_week_tutors;

          this.removeFromModel(camp_week_tutors, item.camp_id, item.camp_week_id, item.person_id);

          this.ongoingAssignedToOthersRequest = false;

          this.getAssignedToOthersResults();

          this.tutorService.addTutor(
            this.camp.camp_id,
            this.camp.camp_weeks[this.weekIndex].id,
            item.person_id,
            () => {

              let camp_week_tutors = this.camp.camp_weeks[this.weekIndex].camp_week_tutors;

              this.addToModel(camp_week_tutors, this.camp.camp_id, this.camp.camp_weeks[this.weekIndex].id, item.person_id);

              this.ongoingAvailableRequest = false;

              this.getAssignedResults();
            }, (error) => {

              this.ongoingAvailableRequest = false;
            });


        }, (error) => {

          this.ongoingAssignedToOthersRequest = false;
        });
    }
  }

  addToModel(camp_week_tutors: any, camp_id: any, camp_week_id: any, person_id: any) {

    let camp_week_tutor = {};

    camp_week_tutor['camp_id'] = camp_id;
    camp_week_tutor['camp_week_id'] = camp_week_id;
    camp_week_tutor['person_id'] = person_id;

    camp_week_tutors.push(camp_week_tutor);
  }

  removeFromModel(camp_week_tutors: any, camp_id: any, camp_week_id: any, person_id: any) {

    let i;
    for (i = 0; i < camp_week_tutors.length; i++) {

      let camp_week_tutor = camp_week_tutors[i];

      if (camp_week_tutor['camp_id'] == camp_id && camp_week_tutor['camp_week_id'] == camp_week_id && camp_week_tutor['person_id'] == person_id) {
        break;
      }
    }

    camp_week_tutors.splice(i, 1);
  }

}
