import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { EnterpriseService } from '../../_services/index';
import { PrimanotaShowComponent } from '../../primanota/index';

@Component({
  selector: 'app-enterprise-cashflow',
  templateUrl: './enterprise-cashflow.component.html',
  styleUrls: ['./enterprise-cashflow.component.css']
})
export class EnterpriseCashflowComponent implements OnInit {

  @ViewChild('primaNotaShowComponent', {static: true}) public primaNotaShowComponent: PrimanotaShowComponent;

  subscribe: any;
  model: any = {};
  holderName: string;

  constructor(private enterpriseService: EnterpriseService) { }

  ngOnInit() {

    this.subscribe = this.enterpriseService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.model = this.enterpriseService.currentEnterpriseInfo;

      if (this.model == null) this.model = {};
      else
      {
        this.holderName = this.enterpriseService.currentEnterpriseInfo["denomination"];
      }

    });
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  modelUpdated(event){
    this.primaNotaShowComponent.modelUpdated(event);
  }

  getEnterpriseService() {
    return this.enterpriseService
  }
}
