import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { CourseScheduleService } from 'app/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-course-schedule-table-date',
  templateUrl: './course-schedule-table-date.component.html',
  styleUrls: ['./course-schedule-table-date.component.css']
})
export class CourseScheduleTableDateComponent implements OnInit {

  @Input('scheduleTable') scheduleTable;
  @Input('editable') editable = true;
  @ViewChild('timesheetModal', {static: false}) public timesheetModal: ModalDirective;

  @Output('timesheetUpdatedEvent') timesheetUpdatedEvent = new EventEmitter();

  _scheduleTable: any = [];
  _rowEditing:any;

  subscribe: any;

  constructor(private courseScheduleService: CourseScheduleService) { }

  ngOnInit(): void {

    if (this.scheduleTable)
      this._scheduleTable = this.scheduleTable;

    this.subscribe = this.courseScheduleService.getsubjectToUpdateObservable().subscribe((update:boolean) => {

      this.getTimeTable();
    });

    if (this.courseScheduleService.currentCourseScheduleInfo != null)
      this.getTimeTable();
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }


  getTimeTable () {

    if (this.courseScheduleService.currentCourseTimetable)
      this._scheduleTable = JSON.parse(JSON.stringify(this.courseScheduleService.currentCourseTimetable));
    else
    this._scheduleTable = [];
  }


  /**
   * Aggiunge una data e un orario alle lezioni
   */
  addSpecificLessonDate() {
    this.showTimesheetModal();
  }

  /**
   * Nasconde il box di inserimento di una nuova data
   */
  deleteRow(row: any = null) {
    if (row != null) {
      this.courseScheduleService.deleteTimesheet(row.id, (response) => {
        this._scheduleTable = response;
        this.timesheetHideModal();
        this.timesheetUpdatedEvent.emit(response);
      });
    }
    else
    {
      this.timesheetHideModal();
      this.timesheetUpdatedEvent.emit();
    }
  }


  //  la tabella non avrà selezione all'hover
  getRowClass(row) {
    return {'no-selection-row': true};
}


  //  modal Timesheet
  isTimesheetModalShown: boolean = false;
  showTimesheetModal(row:any = null): void {

    if (row) {
      this._rowEditing = row;
    } else {
      this._rowEditing = { id: undefined }
    }

    this.isTimesheetModalShown = true;
    this.timesheetModal.show();
  }
  timesheetHideModal() {
    this.timesheetModal.hide();
  }
  onTimesheetHidden() {
    this.isTimesheetModalShown = false;
    this._rowEditing = null;
    this.timesheetModal.hide();
  }

  timesheetUpdated(event) {
    this._scheduleTable = event;
    this.onTimesheetHidden();
    this.timesheetUpdatedEvent.emit(event);
  }

  timesheetDeleted(event) {
    this._scheduleTable = event;
    this.onTimesheetHidden();
    this.timesheetUpdatedEvent.emit(event);
  }




  getCourseScheduleService() {
    return this.courseScheduleService;
  }

}
