import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { BackendService } from './../../_services/backend.service';
import { EnterpriseService, HelperService, PersonService, ConfigService } from './../../_services/index';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { addMinutes, format } from 'date-fns';
import { parseISO } from 'date-fns';
import { NotificationsService } from 'angular2-notifications';
import { FileItem, FileLikeObject, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';

@Component({
  selector: 'app-agreement-info-form',
  templateUrl: './agreement-info-form.component.html',
  styleUrls: ['./agreement-info-form.component.css']
})
export class AgreementInfoFormComponent implements OnInit {

  constructor(private enterpriseService: EnterpriseService, private personService: PersonService,
    private helper: HelperService, private configService: ConfigService,
    private backend: BackendService, private router: Router, private notify: NotificationsService) { }

  currentEnterprise: any;
  currentLegal: any;
  currentData: any;
  currentAgreement: any;
  currentTheratrino: any;
  currentCompany: any = null;
  currentProjectName: any = "";

  errorMessages: any[] = [];
  warningMessages: any[] = [];

  requestCount: number;

  totalCost: number = 0;

  //  file uploaders
  public imageUploader:FileUploader = new FileUploader(
    {
      maxFileSize: 7999999,
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf'],
      url: 'serverUrl'
    }
  );


  @Output() onrequest: EventEmitter<boolean> = new EventEmitter<boolean>();


  ngOnInit() {

    //  quando si attiva il component ho già tutte le info di cui necessito
    this.requestCount = 0;

    this.currentEnterprise = this.enterpriseService.currentEnterpriseInfo;

    if (this.currentEnterprise == null) this.currentEnterprise = {};

    this.currentAgreement = this.enterpriseService.currentAgreement;
    if (this.enterpriseService.currentAgreement == null) this.currentAgreement = [];

    //  Potrebbe capitare che si arriva qui senza che la enterprise ha delle date realmente prenotate
    if (this.currentAgreement.tour_dates && this.currentAgreement.tour_dates.length <= 0)
      this.errorMessages.push("Non ci sono date prenotate per questa enterprise");

    //  Se esiste il path per l'immagine costruisco l'url completo
    if (this.currentAgreement.file_path)
      this.currentAgreement.documentUrl = this.configService.blobBasePath + this.currentAgreement.file_path;

    //  Devo recuperare le informazioni sulle aziende che ospitano
    if (this.currentAgreement.tour_dates) {
      this.currentAgreement.tour_dates.forEach(element => {

        this.currentProjectName = this.configService.getShowTypeValueByKey(element.project_type);

        //  calcolo il costo del theatrino
        if (element.cost != null && typeof element.cost != "undefined")
          this.totalCost += parseFloat(element.cost);

        this.getEnterpriseHolding(element);
        if (element.shows.length <= 0)
          this.warningMessages.push("Mancano gli spettacoli per una data");
      });
    }

    //  Setto le info della company che crea il theatrino
    if (this.currentAgreement.tour_dates && this.currentAgreement.tour_dates.length > 0)
      this.currentCompany = this.currentAgreement.tour_dates[0].company;

    this.imageUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.imageUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.imageUploaded(item, response, status);
    };
    this.imageUploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if (filter.name == "fileSize") {
        var maxSize = String(options.maxFileSize).charAt(0) + " Mb";
        this.notify.error("Documento troppo grande", "il documento ha superato le dimensioni massime consentite di " + maxSize)
      }
    }

    //  Se è una convenzione approvata allora setto alcune informazioni
    if (this.currentAgreement.id)
      this.imageUploader.options.url = this.enterpriseService.getAgreementDocumentFileUploadURL(this.currentAgreement);

    //  Setto la data corrente
    this.currentData = new Date().toLocaleDateString(this.helper.locale, { weekday: "long", year: "numeric", month: "long", day: "2-digit" });

    //  Assegno il primo indirizzo della lista se presente
    if (Object.keys(this.enterpriseService.currentEnterpriseAddressList).length > 0)
      this.currentEnterprise.primaryAddress = this.enterpriseService.currentEnterpriseAddressList[Object.keys(this.enterpriseService.currentEnterpriseAddressList)[0]];
    else
      this.errorMessages.push("Mancano l'indirizzo per l'enterprise organizzatrice");
    //  Cerco l'indirizzo primary
    Object.keys(this.enterpriseService.currentEnterpriseAddressList).forEach(key => {
      if (this.enterpriseService.currentEnterpriseAddressList[key].primay)
        this.currentEnterprise.primaryAddress = this.enterpriseService.currentEnterpriseAddressList[key];
    });

    //  Assegno il contatto della lista se presente
    if (Object.keys(this.enterpriseService.currentEnterpriseContactList).length > 0)
      this.currentEnterprise.primaryContact = this.enterpriseService.currentEnterpriseContactList[Object.keys(this.enterpriseService.currentEnterpriseContactList)[0]];
    else
      this.errorMessages.push("Mancano il contatto per l'enterprise organizzatrice");
    //  Cerco l'indirizzo primary
    Object.keys(this.enterpriseService.currentEnterpriseContactList).forEach(key => {
      if (this.enterpriseService.currentEnterpriseContactList[key].primay)
        this.currentEnterprise.primaryContact = this.enterpriseService.currentEnterpriseContactList[key];
    });

    //  Inserisco il legale rappresentante della enterprise
    var legalCount = 0;
    Object.keys(this.enterpriseService.currentEnterpriseInfo.representative).forEach(key => {
      if (this.enterpriseService.currentEnterpriseInfo.representative[key].type == 7) {
        //  Faccio una chiamata per recuperare i dati di questa persona
        legalCount++;
        this.getLegal(this.enterpriseService.currentEnterpriseInfo.representative[key]);
      }
    });

    // Se non ci sono informazioni vado subito in errore
    if (legalCount == 0)
      this.warningMessages.push("Manca il rappresentante legale, aggiungi una persona con questo ruolo collegandolo alla scuola " + this.currentEnterprise.denomination);
    else if (legalCount > 1)
      this.errorMessages.push("Esiste più di un rappresentante legale, bisogna indicarne uno soltanto per la scuola " + this.currentEnterprise.denomination);

  }

  ngOnDestroy() {
  }


  //  Creo la convenzione con le informazioni attuali
  createAgreement() {
    //  creo l'array con le date selezionate per questa convenzione
    var dateIds: any = [];
    this.enterpriseService.currentAgreement.tour_dates.forEach(tourDate => {
      dateIds.push(tourDate.id);
    });

    this.enterpriseService.confirmAgreementCreation(dateIds, () => {

      this.router.navigate(['enterprise/' + this.enterpriseService.currentEnterpriseId + '/agreement/edit/' + this.enterpriseService.currentAgreement.id]);
    });
  }

  //  Cancello la convenzione e mando l'utente alla schermata del enterprise
  deleteAgreement() {
    this.enterpriseService.deleteAgreement(() => {

      this.router.navigate(['enterprise/edit/' + this.enterpriseService.currentEnterpriseId]);
    })
  }

  downloadPdfAgreement() {
    this.enterpriseService.createPdfAgreement(() => {

      // this.router.navigate(['enterprise/edit/' + this.enterpriseService.currentEnterpriseId]);
    })
  }

  inviaMailTo(event: any, email) {

    event.target.disabled = true;

    this.enterpriseService.sendEmailAgreementTo(email, () => {
      event.target.innerHTML = '<i class="fa fa-check-square-o aria-hidden="true"></i>&nbsp;Email inviata correttamente';
      this.notify.success("Email", "The email has been sent correctly");
    }, () => {
      event.target.innerHTML = '<i class="fa fa-exclamation-triangle aria-hidden="true"></i>&nbsp;Errore'
    });
  }


  getLegal(legal: any) {
    this.requestCount++;
    this.onrequest.emit(true);

    if (this.personService.currentPersonInfo == null)
      this.currentLegal = {};

    this.personService.currentPersonId = legal.person_id;
    this.personService.getPersonInfoAll();

    var service = this.personService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      if (this.personService.currentPersonInfo) {
        this.currentLegal = this.personService.currentPersonInfo;
        service.unsubscribe();

        //  Trasformo la data in quella locale
        if (this.currentLegal.birthday != null)
          this.currentLegal.birthday = parseISO(this.currentLegal.birthday);

        //  Resetto le indo delle persone dal service
        this.personService.resetService();

        //  Controllo la possibilità di errori nelle infomazioni del rappresentante legale
        if (this.currentLegal.id) {
          //  Setto i messaggi di warning
          if (!this.currentLegal.birthday)
            this.warningMessages.push("Manca la data di nascita del rappresentante legale");
          if (!this.currentLegal.birthplace)
            this.warningMessages.push("Manca il luogo di nascita del rappresentante legale");
          if (!this.currentLegal.tax_id)
            this.warningMessages.push("Manca il codice fiscale del rappresentante legale");
        }
        else
          //  Setto i messaggi di errore
          this.errorMessages.push("Mancano le informazioni sul rappresentante legale");
      }

      this.requestCount--;
      if (this.requestCount <= 0)
        this.onrequest.emit(false);

    });

  }

  lastEnterprise: any = {};
  getEnterpriseHolding(enterprise: any) {

    this.requestCount++;
    this.onrequest.emit(true);

    let params = new HttpParams();

    this.backend.get('enterprise/' + enterprise.enterprise_id + '/all', params).subscribe(
      (response) => {

        //  Se l'id dell'azienda è lo stesso allora restituisco l'azienda precedente senza fare una nuova chiamata
        if (this.lastEnterprise.enterprise_id != enterprise.enterprise_id) {
          this.lastEnterprise = response;

          //  Setto la primary address
          if (Object.keys(this.lastEnterprise.address).length > 0)
            this.lastEnterprise.primaryAddress = this.lastEnterprise.address[Object.keys(this.lastEnterprise.address)[0]];
          else
            this.errorMessages.push("Manca l\'indirizzo per l\'enterprise " + this.lastEnterprise.denomination);
          Object.keys(this.lastEnterprise.address).forEach(key => {
            if (this.lastEnterprise.address[key].primay)
              this.lastEnterprise.primaryAddress = this.lastEnterprise.address[key];
          });

          //  Setto la primary contact
          if (Object.keys(this.lastEnterprise.contact).length > 0)
            this.lastEnterprise.primaryContact = this.lastEnterprise.contact[Object.keys(this.lastEnterprise.contact)[0]];
          else
            this.errorMessages.push("Manca il contatto per l\'enterprise " + this.lastEnterprise.denomination);
          Object.keys(this.lastEnterprise.contact).forEach(key => {
            if (this.lastEnterprise.contact[key].primay)
              this.lastEnterprise.primaryContact = this.lastEnterprise.contact[key];
          });
        }

        //  Assegno le info dell'enterprise
        enterprise.hosting = this.lastEnterprise;

        this.requestCount--;
        if (this.requestCount <= 0)
          this.onrequest.emit(false);

      },
      (error) => {
        this.backend.showErrors(error);

        this.requestCount--;
        if (this.requestCount <= 0)
          this.onrequest.emit(false);

      });
  }


  getTourData(tour: any) {
    return parseISO(tour.date);
  }

  getTourRouterLink(tour: any) {
    return "/theatrino/" + tour.theatrino_id + "/tour/edit/" + tour.id;
  }

  getTotalShowSubscribers(show: any) {
    return show.number_gratuities + show.total_subscribers;
  }

  getTotalShowDuration(show: any) {
    var datetime = parseISO('1970-01-01T' + show.time);

    var endTime = addMinutes(datetime, show.activities.duration);

    return format(endTime, 'HH:mm');
  }


  //  Document Uploader
  public imageUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.currentAgreement.url_document = response;
        this.currentAgreement.status = this.enterpriseService.currentAgreement.status;
      }
      this.enterpriseService.agreementDocumentFileResponse(item, response, status,  this.currentAgreement.id);
    }

  }


  //  Remove Document
  destroyFile() {
    this.enterpriseService.deleteAgreementDocumentFile(this.currentAgreement, () => {

      this.currentAgreement = this.enterpriseService.currentAgreement;
    });
  }

  getGroupCompanyValueByKey(currentCompany) {
    return this.configService.getGroupCompanyValueByKey(currentCompany)
  }
  submit() {

  }

  getEnterpriseService() {
    return this.enterpriseService
  }

  getConfigService() {
    return this.configService;
  }

}
