import { ConfigService } from './../../_services/config.service';
import { UserService, HelperService } from 'app/_services';
import { CampService } from './../../_services/camp.service';
import { Component, OnInit } from '@angular/core';
import { parseISO } from 'date-fns';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-camp-reports-etasubscribers',
  templateUrl: './camp-reports-etasubscribers.component.html',
  styleUrls: ['./camp-reports-etasubscribers.component.css']
})
export class CampReportsEtaSubscribersComponent implements OnInit {

  constructor(private campService: CampService, private userService: UserService,
              private configService: ConfigService, private helper: HelperService) { }

  // generic rows
  rows = [];



  ngOnInit() {

    this.campService.getReportEtaSubscribers( (results) => {
      this.rows = results;
    })
  }


  /*  Salvo i dati nel formato Excel
  */
 exportToExcel() {

  this.showModalExport();

  this.exportParse(this.rows, () => {
    this.hideModalExport();
  });

}



/*
* Questo metodo prepara l'esportazione dei dati
* da inviare poi ad un file excel o csv
*/
exportParse (data: any, callback: any) {

  var exportedArray = [];

  //  preparo i dati per il csv bonificandoli
  data.forEach( (element) => {

    //  bonifico
    Object.keys(element).forEach(key => {
      if (element[key] == null) element[key] = '';
    })

    let obj = {
      'Camp Code': element.camp_code,
      'Start':  parseISO(element.camp_start),
      'End': parseISO(element.camp_end).toLocaleDateString(this.helper.locale),
      '4 anni': parseInt(element.age4) || 0,
      '5 anni': parseInt(element.age5) || 0,
      '6 anni': parseInt(element.age6) || 0,
      '7 anni': parseInt(element.age7) || 0,
      '8 anni': parseInt(element.age8) || 0,
      '9 anni': parseInt(element.age9) || 0,
      '10 anni': parseInt(element.age10) || 0,
      '11 anni': parseInt(element.age11) || 0,
      '12 anni': parseInt(element.age12) || 0,
      '13 anni': parseInt(element.age13) || 0,
      '14 anni': parseInt(element.age14) || 0,
      '15 anni': parseInt(element.age15) || 0,
      '16 anni': parseInt(element.age16) || 0,
      '17 anni': parseInt(element.age17) || 0,
      '18 anni': parseInt(element.age18) || 0,
    };

    //  preparo
    exportedArray.push(obj)

  })

  //  creo io worksheet con i dati
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

  //  personalizzo le colonne
  worksheet['!cols'] = [{width: 20}, {width:20}, {width:20}, {width:10}, {width:10}, {width:10}, {width:10}, {width:10}, {width:10}, {width:10}, {width:10}, {width:10}, {width:10}, {width:10}, {width:10}, {width:10}, {width:10}, {width:10}];

  // //  personalizzo l'header
  worksheet['!rows'] = [{hpx: 30}];

  // //  creo il workbook con lo sheet attuale
  const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet}, SheetNames: ['Report'] };

  // //  scrivo il file
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

  // //  Salvo il file tramite il browser
  FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "report.xlsx");

  callback();

}







  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.isModalExport = false;
    // this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }






  getCampService() {
    return this.campService
  }

  getUserService() {
    return this.userService
  }

  getConfigService() {
    return this.configService;
  }

}
