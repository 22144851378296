<!-- BEGIN FORM -->
<form (keydown.enter)="checkSubmitKeyEnter($event)" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()"
  #f="ngForm" novalidate>

  <div class="form-body" *ngIf="model">

    <!-- Left -->
    <div class="row">
      <div class="col-md-6">

        <div class="row" *ngIf="editMode">
          <div class="form-group">
            <div class="col-md-12" *ngIf="model.type" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
              <label class="control-label">Staff Type
                <span class="required">*</span>
              </label>
              <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
                <option *ngFor="let st of getConfigService().courseScheduleStaffTypeSelect" [value]="st.key">{{st.value}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!editMode">
          <div class="form-group">
            <div class="col-md-12 read-only" *ngIf="model.type">
              <label class="control-label">Staff Type</label>
              <p>{{getConfigService().getCourseScheduleStaffTypeValueByKey(model.type)}}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group">
            <div class="col-md-12 read-only">
              <label class="control-label">Name</label>
              <p>
                <a *ngIf="model.person_info?.id" class="blue pointer" routerLink="/person/edit/{{ model.person_info?.id }}">{{model.person_info?.surname}} {{model.person_info?.first_name}}</a>
                <a *ngIf="model.person_info?.id" class="blue pointer" target="_blank" routerLink="/person/edit/{{ model.person_info?.id }}"><i class="fa fa-external-link" aria-hidden="true"></i></a>

                <a *ngIf="model.enterprise_info?.id" class="blue pointer" routerLink="/enterprise/edit/{{ model.enterprise_info?.id }}">{{model.enterprise_info?.denomination}}</a>
                <a *ngIf="model.enterprise_info?.id" class="blue pointer" target="_blank" routerLink="/enterprise/edit/{{ model.enterprise_info?.id }}"><i class="fa fa-external-link" aria-hidden="true"></i></a>
              </p>
              <!-- <app-person-info-show [idPerson]="model.person_info?.id"></app-person-info-show> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <div class="col-md-12 read-only">
              <label class="control-label">Contacts</label>
              <ul *ngFor="let contact of model.person_info?.contact | mapFromKeyValue">
                <li>{{getConfigService().getContactPersonValueByKey(contact.type)}}: {{contact.value}}</li>
              </ul>
              <ul *ngFor="let contact of model.enterprise_info?.contact | mapFromKeyValue">
                  <li>{{getConfigService().getContactEnterpriseValueByKey(contact.type)}}: {{contact.value}}</li>
                </ul>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="editMode && model.type == 3">
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">Contact</label>
              <select class="form-control" name="email" [(ngModel)]="model.staff_email" >
                <option [value]=""></option>
                <ng-container *ngFor="let c of model.person_info?.contact | mapFromKeyValue">
                  <option *ngIf="c.type == 1" [value]="c.id">{{c.value}}</option>
                </ng-container>
                <ng-container *ngFor="let c of model.enterprise_info?.contact | mapFromKeyValue">
                  <option *ngIf="c.type == 1" [value]="c.id">{{c.value}}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!editMode && model.staff_email">
          <div class="form-group">
            <div class="col-md-12 read-only">
              <label class="control-label">Contact</label>
              <ng-container *ngFor="let c of model.person_info?.contact | mapFromKeyValue">
                <p *ngIf="c.id == model.staff_email">{{c.value}}</p>
              </ng-container>
              <ng-container *ngFor="let c of model.enterprise_info?.contact | mapFromKeyValue">
                <p *ngIf="c.id == model.staff_email">{{c.value}}</p>
              </ng-container>
            </div>
          </div>
        </div>

      </div>

      <!-- Right -->
      <div class="col-md-6"></div>

    </div>

    <!-- END: if form body -->
  </div>

  <div class="form-actions right" *ngIf="getCourseScheduleService().canEditStaff()">
    <img *ngIf="loadingDocuments || getCourseScheduleService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="loadingDocuments || getCourseScheduleService().isOngoingRequest()" *ngIf="this.model.id && this.model.type!='8'"
      [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
      <i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline red" [disabled]="loadingDocuments || getCourseScheduleService().isOngoingRequest()" *ngIf="!this.model.id"
      (click)="delete()">
      <i class="fa fa-trash-o"></i> Delete</button>
    <button type="button" class="btn btn-outline blue" [disabled]="loadingDocuments || getCourseScheduleService().isOngoingRequest()" *ngIf="this.model.id && !editMode"
      (click)="edit()">
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

    <button *ngIf="editMode" type="submit" class="btn blue" [disabled]="loadingDocuments || getCourseScheduleService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
      <!-- <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span> -->
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>
