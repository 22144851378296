import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { PrimanotaService, ConfigService, HelperService, RevisionService, UserService } from './../../_services/index';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { PersonDatatableComponent } from "../../person/person-datatable/person-datatable.component";
import { PersonSearchFormComponent} from "../../person/person-search-form/person-search-form.component";
import { EnterpriseDatatableComponent } from '../../enterprise/enterprise-datatable/enterprise-datatable.component';
import { EnterpriseSearchFormComponent } from '../../enterprise/enterprise-search-form/enterprise-search-form.component';
import { ActorGroupDatatableComponent } from "../../actor-group/actor-group-datatable/actor-group-datatable.component";
import { ActorGroupSearchFormComponent} from "../../actor-group/actor-group-search-form/actor-group-search-form.component";
import { CampDatatableComponent } from "../../camp/camp-datatable/camp-datatable.component";
import { CampSearchFormComponent} from "../../camp/camp-search-form/camp-search-form.component";
import { CourseScheduleSearchFormComponent } from '../../course-schedule/course-schedule-search-form/course-schedule-search-form.component';
import { CourseScheduleDatatableComponent } from '../../course-schedule/course-schedule-datatable/course-schedule-datatable.component';
import { IAngularMyDpOptions, IMyDateModel, IMyInputFieldChanged, IMyDate } from 'angular-mydatepicker';
import { parseISO } from "date-fns";

@Component({
  selector: 'app-primanota-add-form',
  templateUrl: './primanota-add-form.component.html',
  styleUrls: ['./primanota-add-form.component.css']
})
export class PrimanotaAddFormComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('personDatatable', {static: false}) public personDatatable: PersonDatatableComponent;
  @ViewChild('personSearchForm', {static: false}) public personSearchForm: PersonSearchFormComponent;
  @ViewChild('enterpriseDatatable', {static: false}) public enterpriseDatatable: EnterpriseDatatableComponent;
  @ViewChild('enterpriseSearchForm', {static: false}) public enterpriseSearchForm: EnterpriseSearchFormComponent;
  @ViewChild('groupDatatable', {static: false}) public groupDatatable: ActorGroupDatatableComponent;
  @ViewChild('groupSearchForm', {static: false}) public groupSearchForm: ActorGroupSearchFormComponent;
  @ViewChild('campDatatable', {static: false}) public campDatatable: CampDatatableComponent;
  @ViewChild('campSearchForm', {static: false}) public campSearchForm: CampSearchFormComponent;
  @ViewChild('courseDatatable', {static: false}) public courseDatatable: CourseScheduleDatatableComponent;
  @ViewChild('courseSearchForm', {static: false}) public courseSearchForm: CourseScheduleSearchFormComponent;

  model: any = {};
  showForm: boolean = false;
  isAddIn: boolean;
  typeDetected: string;
  rowsRevision: any[] = [];
  showRevision: boolean = false;
  casualSelect: any;

  private localData: IMyDateModel;

  @Output() modelUpdated = new EventEmitter();

  @Input() showAddInOutButton: boolean = true;
  @Input() showCloseButton: boolean = true;
  @Input() primaNotaModel: any;
  @Input() primaNotaRowId: number;
  @Input() holderType: string;
  @Input() holderName: string;
  @Input() holderId: number;

  @ViewChild('f', {static: false}) currentForm: NgForm;

  subscribe: any;
  otherHolderType: string;
  otherHolderId: number;
  otherHolderName: string;
  modalEntityType: string;

  loadingData: boolean = false;

  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(private primanotaService: PrimanotaService, private configService: ConfigService, private helper: HelperService, private revisionService: RevisionService, private userService: UserService) {

    //  Inizializzo le info sulle date e resetto il blocco
    this.datePickerOptions = this.helper.datePickerOptions;
  }

  ngOnInit() {

    if (this.userService.isOfficeFinancialStaff()){
      this.showRevision = false;
    }

    if (!this.primaNotaModel && this.primaNotaRowId){

      this.loadingData = true;
      this.showForm = false;
      this.showAddInOutButton = false;

      this.subscribeToEventUpdate();
      this.primanotaService.getPrimaNotaRow(this.primaNotaRowId);
    }
    else {

      if (this.primaNotaModel){
        this.model = this.primaNotaModel;
        this.parsePrimaNotaRowModel();
        this.showForm = true;
      }

      this.parseDatePayment();
    }

  }

  addEntityFromModal(type: string) {

    this.modalEntityType = type;
    this.showModal();
  }

  subscribeToEventUpdate(){

    this.subscribe = this.primanotaService.getsubjectToUpdateObservable().subscribe((res: any) => {

      this.model = res;

      this.parsePrimaNotaRowModel();

      if (this.model == null)
        this.model = {};

      this.parseDatePayment();

      this.loadingData = false;
      this.showForm = true;

    });
  }

  parseDatePayment(){
    // console.log(this.model.date_payment)

    if (this.model.date_payment == null){
      let today = new Date();
      this.model.date_payment = today.getFullYear() + '/' + (today.getMonth()+1) + '/' + today.getDate();

      if (this.currentForm)
        this.currentForm.form.markAsDirty();
    }
    else {
      this.localData = this.helper.initMyDataInputSingle(this.model.date_payment);
    }
  }

  parsePrimaNotaRowModel() {

    if (this.holderId && this.model.in_holder_id == this.holderId && this.model.in_holder_type == this.holderType){

      this.parseAddInModel();
    }
    else if (this.holderId && this.model.out_holder_id == this.holderId && this.model.out_holder_type == this.holderType){

      this.parseAddOutModel();
    }
    else {

      if (this.model.in_amount){
        this.parseAddInModel();
      }
      else {
        this.parseAddOutModel();
      }
    }

    if (this.model){

      this.revisionService.getCashFlowRevision(this.model.id, (list) => {

        list.sort(function(a,b){
          if (a.revision.created_at < b.revision.created_at)
            return 1;
          if (a.revision.created_at > b.revision.created_at)
            return -1;
          return 0;
        });

        this.rowsRevision = list;
      });
    }
  }

  parseAddInModel(){

    this.isAddIn = true;

    this.holderName = this.helper.getHolderNameFromType(this.model.in_holder_type, this.model.in_holder_data);

    this.holderType = this.model.in_holder_type;
    this.holderId = this.model.in_holder_id;

    this.otherHolderName = this.helper.getHolderNameFromType(this.model.out_holder_type, this.model.out_holder_data);

    this.otherHolderType = this.model.out_holder_type;
    this.otherHolderId = this.model.out_holder_id;

    this.casualSelect = this.configService.getPrimanotaCausalSelect("in", this.holderType);
  }

  parseAddOutModel(){

    this.isAddIn = false;

    this.holderName = this.helper.getHolderNameFromType(this.model.out_holder_type, this.model.out_holder_data);

    this.holderType = this.model.out_holder_type;
    this.holderId = this.model.out_holder_id;

    this.otherHolderName = this.helper.getHolderNameFromType(this.model.in_holder_type, this.model.in_holder_data);

    this.otherHolderType = this.model.in_holder_type;
    this.otherHolderId = this.model.in_holder_id;

    this.casualSelect = this.configService.getPrimanotaCausalSelect("out", this.holderType);
  }

  onInputAmount(event: any){

  }

  onDateChanged(event: IMyDateModel) {

    this.localData = event;

    if (event.singleDate.jsDate != null)
      this.model.date_payment = this.helper.formatISODate(event);
    else
      this.model.date_payment = null;

    this.currentForm.form.markAsDirty();
  }



  submit() {

    if (!this.helper.isMyDateValid(this.localData))
      return;

    if (!this.currentForm.form.valid)
      return;

    if (!this.currentForm.form.dirty) {
      return;
    }

    if (this.isAddIn){

      this.model.in_holder_type = this.holderType;
      this.model.in_holder_id = this.holderId;
      this.model.out_holder_type = this.otherHolderType;
      this.model.out_holder_id = this.otherHolderId;
    }
    else {

      this.model.out_holder_type = this.holderType;
      this.model.out_holder_id = this.holderId;
      this.model.in_holder_type = this.otherHolderType;
      this.model.in_holder_id = this.otherHolderId;
    }

    this.model.amount = parseFloat(this.model.amount);

    if (this.model.id){
      this.primanotaService.updatePrimaNotaRow(this.model.id, this.model, ()=> {
         this.modelUpdated.emit();
      });
    }
    else {
      this.primanotaService.savePrimaNotaRow(this.model, ()=> {

        this.showForm = false;

        this.modelUpdated.emit();

        if (this.showAddInOutButton){
          this.isAddIn = null;

        }

        this.removeOtherHolder();
        this.model = {};
      });
    }

  }

  destroy() {
    this.primanotaService.deletePrimaNotaRow(this.model.id, () => {
      this.showForm = false;
      this.model = {};
      this.removeOtherHolder();
      this.modelUpdated.emit();
    });
  }

  removeOtherHolder(){

    this.otherHolderName = null;
    this.otherHolderId = null;
    this.otherHolderType = null;
    this.currentForm.form.markAsDirty();
  }

  closeForm(){
    this.showForm = false;
    this.isAddIn = null;
    this.modelUpdated.emit();
  }

  showFormAddIn(){
    this.showForm = true;
    this.isAddIn = true;
    this.casualSelect = this.configService.getPrimanotaCausalSelect("in", this.holderType);
  }

  showFormAddOut(){
    this.showForm = true;
    this.isAddIn = false;
    this.casualSelect = this.configService.getPrimanotaCausalSelect("out", this.holderType);
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  searchPersonKeywordsSubmit(params) {
    this.personDatatable.query = params;
    this.personDatatable.getResults();
  }

  personSelected(selected) {

    this.otherHolderId = selected.person_id;
    this.otherHolderName = selected.first_name + " " + selected.surname;
    this.otherHolderType = 'App\\Person';
    this.currentForm.form.markAsDirty();
    this.hideModal();
  }

  searchEnterpriseKeywordsSubmit(params) {
    this.enterpriseDatatable.query = params;
    this.enterpriseDatatable.getResults();
  }

  enterpriseSelected(selected) {

    //this.model.enterprise_id = selected.enterprise_id;
    this.otherHolderId = selected.enterprise_id;
    this.otherHolderName = selected.denomination;
    this.otherHolderType = 'App\\Enterprise';
    this.currentForm.form.markAsDirty();
    this.hideModal();
  }

  searchGroupKeywordsSubmit(params) {

    if (typeof params == "undefined" || params == null)
      this.groupDatatable.query = "all";
    else
      this.groupDatatable.query = params;

    //  Prendo i risultati dalla tabella
    this.groupDatatable.getResult();
  }

  groupSelected (selected)
  {
    //this.model.actor_group_id = selected.actor_group_id;
    this.otherHolderId = selected.actor_group_id;
    this.otherHolderName = selected.group_name;
    this.otherHolderType = 'App\\ActorGroup';
    this.currentForm.form.markAsDirty();
    this.hideModal();
  }

  searchCampKeywordsSubmit(params) {

    if (typeof params != "undefined" && Object.keys(params).length > 0){
      this.campDatatable.query = params;
      this.campDatatable.getResult();
    }
  }

  campSelected (selected) {
    this.otherHolderId = selected.camp_id;
    this.otherHolderName = selected.type_name+" "+selected.camp_code;
    this.otherHolderType = 'App\\Camp';
    this.currentForm.form.markAsDirty();
    this.hideModal();
  }

  searchCourseKeywordsSubmit(params) {

    if (typeof params != "undefined" && Object.keys(params).length > 0){
      // controllo se filtrare i risultati solo per persone presenti nello staff del corso quando non hanno il ruolo Staff
      if (!this.userService.isOfficeStaff()){
        params.current_user_staff_member = true;
      }
      this.courseDatatable.query = params;
      this.courseDatatable.getResult();
    }
  }

  courseSelected (selected) {
    this.otherHolderId = selected.course_schedule_id;
    this.otherHolderName = selected.title;
    this.otherHolderType = 'App\\CourseSchedule';
    this.currentForm.form.markAsDirty();
    this.hideModal();
  }

  getConfigService() {
    return this.configService
  }

  getLocalData() {
    return this.localData
  }

  getHelper() {
    return this.helper
  }

  getPrimaNotaService(){
    return this.primanotaService;
  }

  getUserService(){
    return this.userService;
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }
}
