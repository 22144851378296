import { Component, OnInit, ViewChild } from "@angular/core";
import {
  HelperService,
  ConfigService,
  UserService,
  CourseScheduleService,
} from "app/_services";
import { NotificationsService } from "angular2-notifications";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ModalDirective } from 'ngx-bootstrap/modal';
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

@Component({
  selector: "app-course-schedule-staff-groups",
  templateUrl: "./course-schedule-staff-groups.component.html",
  styleUrls: ["./course-schedule-staff-groups.component.css"],
})
export class CourseScheduleStaffGroupsComponent implements OnInit {
  @ViewChild("staticModal", { static: false })
  public staticModal: ModalDirective;

  courseScheduleGroupList: any[] = [];
  staffGroupList: any[] = [];

  subscribeGroup: any;
  subscribeStaffList: any;

  selected: any[] = [];

  libri: any;

  constructor(
    private courseScheduleService: CourseScheduleService,
    private helper: HelperService,
    private configService: ConfigService,
    private notify: NotificationsService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.subscribeGroup = this.courseScheduleService
      .getsubjectToUpdateObservable()
      .subscribe((update: boolean) => {
        this.getGroupsInfo();
      });

    this.subscribeStaffList = this.courseScheduleService
      .getsubjectToUpdateObservable()
      .subscribe((update: boolean) => {
        this.getStaffInfo();
      });

    this.getGroupsInfo();
    this.courseScheduleService.getCourseStaff();
  }

  ngOnDestroy() {
    this.subscribeGroup.unsubscribe();
    this.subscribeStaffList.unsubscribe();
  }

  getGroupsInfo() {
    if (this.courseScheduleService.currentCourseGroups != null) {
      this.courseScheduleGroupList = this.courseScheduleService.currentCourseGroups;
    }
  }

  getStaffInfo() {
    if (this.courseScheduleService.currentCourseStaffList != null) {
      this.prepareWeekStaff();
    }
  }

  prepareWeekStaff() {
    //  per ogni settimana del campo setto lo staff che ne sono iscritti
    var subs = JSON.parse( JSON.stringify( this.courseScheduleService.currentCourseStaffList));

    //  per ogni iscritto della settimana prendo solo la settimana corrispondente
    subs.forEach((staff) => {
      let group = undefined;

      //  controllo che ci sia un gruppo per questo staff
      if ( typeof staff.groups != "undefined" && staff.groups != null) {
        let groupCheck = this.courseScheduleGroupList.find( (x) => x.id == staff.groups.course_schedule_group_id);
        if (typeof groupCheck != "undefined")
          group = JSON.parse( JSON.stringify(groupCheck));
      }

      //  se trovo il gruppo lo assegno
      if (typeof group != "undefined" && group != null) {
        staff.group = group;
        staff.group.group_id = staff.groups.id;
        staff.group.staff_member_id = staff.id;
        staff.group.name = group.group_name;
        staff.group.course_schedule_group_id = staff.groups.course_schedule_group_id;
      }

      //  se il gruppo non c'è lo inizializzo comunque
      if (typeof staff.group == "undefined") {
        staff.group = {};
        staff.group.name = "";
      }
    });

    //  inserisco la settimana nell'array
    this.staffGroupList = subs;
  }

  currentModify: any = {};
  modifyGroup(row) {
    this.currentModify.staff = JSON.parse(JSON.stringify(row));
  }

  closeGroup() {
    this.currentModify = {};
  }

  loading: boolean = false;
  changeGroup(row) {

    //  inserisco un nuovo gruppo per questo iscritto
    if (
      typeof this.currentModify.staff.group.course_schedule_group_id == "undefined" &&
      typeof this.currentModify.staff.group.id == "undefined"
    ) {
      //  controllo se non è stato selezionato un gruppo valido
      if (row.group.course_schedule_group_id < 0) return;

      let model = {
        staff_member_id: row.id,
        course_schedule_group_id: row.group.course_schedule_group_id,
      };

      this.saveStaffGroup(row, model, () => {
        this.notify.success("Staff Group", "successfully saved");
      });
      return;
    }

    //  questo iscritto ha già un gruppo
    if (
      typeof this.currentModify.staff.group.course_schedule_group_id !=
        "undefined" &&
      typeof this.currentModify.staff.group.id != "undefined"
    ) {
      if (row.group.course_schedule_group_id > 0) {
        this.updateStaffGroup(row, () => {
          this.notify.success("Staff Group", "successfully changed");
        });
        return;
      }

      if (row.group.course_schedule_group_id < 0) {
        this.deleteStaffGroup(row, () => {
          this.notify.success("Staff Group", "successfully deleted");
        });
        return;
      }
    }
  }

  saveStaffGroup(row, model, callbackSuccess: any = null) {
    this.loading = true;
    this.courseScheduleService.saveCourseStaffGroup(
      model,
      (newGroup) => {
        row.groups = newGroup;
        row.group = newGroup;
        row.group.course_schedule_group_id = newGroup.course_schedule_group_id;

        //  controllo che non arrivino infirmazioni errate prima di salvarle
        var gr = this.courseScheduleGroupList.find(
          (x) => x.id == row.group.course_schedule_group_id
        );
        if (gr == null || typeof gr == "undefined") return;
        if (typeof gr.group_name == "undefined") return;

        row.group.name = gr.group_name;
        row.group.group_id = newGroup.id;
        row.group.staff_member_id = row.id;

        this.currentModify = {};
        this.loading = false;
        if (callbackSuccess != null) callbackSuccess();
      },
      () => {
        this.loading = false;
      }
    );
  }
  updateStaffGroup(row, callbackSuccess: any = null) {
    this.loading = true;
    this.courseScheduleService.updateCourseStaffGroup(
      row.group,
      (updateGroup) => {
        var subIndex = this.staffGroupList.findIndex(
          (x) => x.id == updateGroup.staff_member_id
        );
        if (this.staffGroupList[subIndex]) {
          this.staffGroupList[subIndex].groups = updateGroup;
          this.staffGroupList[subIndex].group = updateGroup;
          this.staffGroupList[
            subIndex
          ].group.group_id = this.staffGroupList[subIndex].groups.id;
          this.staffGroupList[
            subIndex
          ].group.staff_member_id = this.staffGroupList[subIndex].id;
          this.staffGroupList[
            subIndex
          ].group.name = this.courseScheduleGroupList.find(
            (x) => x.id == updateGroup.course_schedule_group_id
          ).group_name;
          this.staffGroupList[
            subIndex
          ].group.course_schedule_group_id = this.staffGroupList[
            subIndex
          ].groups.course_schedule_group_id;
        }

        this.currentModify = {};
        this.loading = false;
        if (callbackSuccess != null) callbackSuccess();
      },
      () => {
        this.loading = false;
      }
    );
  }
  deleteStaffGroup(row, callbackSuccess: any = null) {
    this.loading = true;
    this.courseScheduleService.deleteCourseStaffGroup(
      row.group,
      () => {
        row.groups = null;
        row.group = {};
        row.group.name = "";
        this.currentModify = {};
        this.loading = false;
        if (callbackSuccess != null) callbackSuccess();
      },
      () => {
        this.loading = false;
      }
    );
  }

  multiSelection: boolean = false;
  multiSelectionCourseScheduleGroupId = undefined;

  enableMultipleSelection() {
    this.multiSelection = true;
  }
  cancelMultipleSelection() {
    this.selected = [];
    this.multiSelection = false;
  }
  saveMultipleSelection() {
    this.currentMultisave = 0;

    if (this.selected.length == 0)
      this.notify.warn("Staff Group", "No staff members selected");

    //  controllo se non è stato selezionato un gruppo valido
    if (typeof this.multiSelectionCourseScheduleGroupId == "undefined") return;

    this.selected.forEach((staff) => {
      if (typeof staff.group.id == "undefined") {
        let model = {
          staff_member_id: staff.id,
          course_schedule_group_id: this.multiSelectionCourseScheduleGroupId,
        };
        this.saveStaffGroup(staff, model, () => {
          this.currentMultisave++;
          this.checkMultipleSave();
        });
        return;
      }

      //  questo iscritto ha già un gruppo
      if (typeof staff.group.id != "undefined") {
        staff.group.course_schedule_group_id = this.multiSelectionCourseScheduleGroupId;

        if (staff.group.course_schedule_group_id > 0) {
          this.updateStaffGroup(staff, () => {
            this.currentMultisave++;
            this.checkMultipleSave();
          });
          return;
        }

        if (staff.group.course_schedule_group_id < 0) {
          this.deleteStaffGroup(staff, () => {
            this.currentMultisave++;
            this.checkMultipleSave();
          });
          return;
        }
      }
    });
  }
  currentMultisave = 0;
  checkMultipleSave() {
    if (this.currentMultisave >= this.selected.length) {
      this.notify.success(
        "Staff Group",
        this.currentMultisave + " successfully changed"
      );
      this.cancelMultipleSelection();
    }
  }

  onSelect({ selected }) {
    // console.log(this.selected);
  }

  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
    this.staticModal.show();
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }

  /*  Salvo i dati nel formato Excel
   */
  exportToExcel() {
    this.showModalExport();

    this.exportParse(this.staffGroupList, () => {
      this.hideModalExport();
    });
  }

  /*
   * Questo metodo prepara l'esportazione dei dati
   * da inviare poi ad un file excel o csv
   */
  exportParse(data: any, callback: any) {
    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach((element) => {
      //  bonifico
      Object.keys(element).forEach((key) => {
        if (element[key] == null) element[key] = "";
      });

      let obj = {
        Nome: element.person_info.first_name,
        Cognome: element.person_info.surname,
        Eta: this.helper.calculateAge(element.person_info.birthday),
        Sesso: this.configService.getGenderPersonValueByKey(
          element.person_info.gender
        ),
        Gruppo: typeof element.group != "undefined" ? element.group.name : "",
      };

      //  preparo
      exportedArray.push(obj);
    });

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet["!cols"] = [
      { width: 18 },
      { width: 20 },
      { width: 10 },
      { width: 12 },
      { width: 30 },
    ];

    //  personalizzo l'header
    worksheet["!rows"] = [{ hpx: 30 }];

    //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = {
      Sheets: { Staff: worksheet },
      SheetNames: ["Staff"],
    };

    //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      bookSST: false,
      type: "array",
    });

    //  Salvo il file tramite il browser
    FileSaver.saveAs(
      new Blob([excelBuffer], { type: EXCEL_TYPE }),
      "staff course groups.xlsx"
    );

    callback();
  }

  getRowClass(row) {
    if (row.person_info) {
      return {
        "course-schedule-staff-deleted": row.person_info.deleted_at !== null,
      };
    }

    return "";
  }

  isOngoingRequest() {
    return this.courseScheduleService.isOngoingRequest();
  }

  getCourseScheduleService() {
    return this.courseScheduleService;
  }

  getConfigService() {
    return this.configService;
  }

  getHelper() {
    return this.helper;
  }

  getUserService() {
    return this.userService;
  }
}
