import { ActivatedRoute } from '@angular/router';
import { EnterpriseService } from './../_services/index';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agreement-camp',
  templateUrl: './agreement-camp.component.html',
  styleUrls: ['./agreement-camp.component.css']
})
export class AgreementCampComponent implements OnInit {

  private subject: any;

  loadingData: boolean = true;
  loadingAdditionalData: boolean = false;

  constructor(private enterpriseService: EnterpriseService, private route: ActivatedRoute) { }

  ngOnInit() {

    //  Controllo da quale collegamento sto arrivando
    this.subject = this.route.params.subscribe( params => {

      //   se ho l'id dell'enterprise posso andare avanti altrimenti invio l'errore
      if (typeof params['idCurrentEnterprise'] != "undefined")
      {
        if (this.enterpriseService.currentEnterpriseInfo == null || typeof this.enterpriseService.currentEnterpriseInfo == "undefined" ||
            this.enterpriseService.currentEnterpriseId == null || typeof this.enterpriseService.currentEnterpriseId == "undefined" ||
            this.enterpriseService.currentEnterpriseId != params['idCurrentEnterprise'])
        {
          this.enterpriseService.currentEnterpriseId = params['idCurrentEnterprise'];
          this.enterpriseService.getEnterpriseInfoAll( ()=> {

              //  se ho l'id della convenzione allora devo recuperarla altrimenti
              if (typeof params['idCurrentAgreement'] != "undefined")
              {

                let camp_id = [params['idCurrentAgreement']];

                this.enterpriseService.getCampAgreement( camp_id, ()  => {
                  this.loadingData = false;
                }, () => {
                  this.loadingData = false;
                });
              }
              //  altrimenti devo tentare di crearne una nuova
              else
              {
                if (typeof params['camp_id'] != "undefined")
                {
                  this.enterpriseService.createCampAgreement(params['camp_id'], () => {
                    this.loadingData = false;
                  }, () => {
                    this.loadingData = false;
                  });
                }
              }

            //  Se va in errore lo scaricamento dei dati della enterprise collegata
            }, () => {
              this.loadingData = false;
            })

          }
          else
          {
            //  se ho l'id della convenzione allora devo recuperarla altrimenti
            if (typeof params['idCurrentAgreement'] != "undefined")
            {
              let camp_id = [params['idCurrentAgreement']];

              this.enterpriseService.getCampAgreement( camp_id, ()  => {
                this.loadingData = false;
              }, () => {
                this.loadingData = false;
              });

            }
            //  altrimenti devo tentare di crearne una nuova
            else
            {
              if (typeof params['camp_id'] != "undefined")
              {
                this.enterpriseService.createCampAgreement(params['camp_id'], () => {
                  this.loadingData = false;
                }, () => {
                  this.loadingData = false;
                });
              }
            }
          }
        }

      }, () => {
        this.loadingData = false;
      });
    }


    additionalLoadingChange(event) {
      setTimeout(() => { this.loadingAdditionalData = event }, 1000);
    }

    getCurrentEnterpriseId() {
      return this.enterpriseService.currentEnterpriseId;
    }

    getCurrentAgreement() {
      return this.enterpriseService.currentCampAgreement;
    }


    getEnterpriseService() {
      return this.enterpriseService
    }

}
