import { Component, OnInit, ViewChild } from '@angular/core';
import { PersonService } from 'app/_services';
import { VehicleAssignmentDatatableComponent } from '../../vehicle/vehicle-assignment-datatable/vehicle-assignment-datatable.component';

@Component({
  selector: 'app-person-vehicle',
  templateUrl: './person-vehicle.component.html',
  styleUrls: ['./person-vehicle.component.css']
})
export class PersonVehicleComponent implements OnInit {

  @ViewChild('assignmentDatatable', {static: true}) assignmentDatatable: VehicleAssignmentDatatableComponent;

  subject: any;

  assignments: any[];

  constructor(private personService: PersonService) { }

  ngOnInit() {

  this.prepareView();

    this.subject = this.personService.getsubjectToUpdateObservable().subscribe(() => {

      this.prepareView();
    });

  }

  prepareView()
  {
    if (this.personService.currentPersonAssignments == null || typeof this.personService.currentPersonAssignments == "undefined")
        this.assignments = [];
    else
    {
      this.assignments = JSON.parse(JSON.stringify(this.personService.currentPersonAssignments));
      this.assignmentDatatable.update(this.assignments);
    }
  }

}
