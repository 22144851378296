<div class="datatable-loader text-center" *ngIf="getCourseScheduleService().isOngoingRequest()">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>

  <div *ngIf="!getCourseScheduleService().isOngoingRequest()">
  <ngx-datatable
    class="striped"
    [rows]="courseScheduleStaffList"
    [columnMode]="'flex'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [selected]="selected"
    [selectionType]="'single'"
    [rowClass]="getRowClass"
    (activate)="onActivate($event)"
    (select)='onSelect($event)'
  >
    <ngx-datatable-column name="Type" [flexGrow]="2" prop="type" [resizeable]="false">
      <ng-template ngx-datatable-cell-template let-value="value">
        {{getConfigService().getCourseScheduleStaffTypeValueByKey(value)}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Name" [flexGrow]="4" [resizeable]="false">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        {{row.person_info?.surname}} {{row.person_info?.first_name}}
        {{row.enterprise_info?.denomination}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Phone" [flexGrow]="3" [resizeable]="false">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        <span *ngFor="let contact of row.person_info?.contact | mapFromKeyValue">
          <span *ngIf="contact.type != 1">{{getConfigService().getContactPersonValueByKey(contact.type)}}: {{contact.value}} <br /></span>
        </span>
        <span *ngFor="let contact of row.enterprise_info?.contact | mapFromKeyValue">
          <span *ngIf="contact.type != 1">{{getConfigService().getContactEnterpriseValueByKey(contact.type)}}: {{contact.value}} <br /></span>
        </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Email" [flexGrow]="3" [resizeable]="false">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <span *ngFor="let contact of row.person_info?.contact | mapFromKeyValue">
            <span *ngIf="contact.type == 1">{{contact.value}}</span>
          </span>
          <span *ngFor="let contact of row.enterprise_info?.contact | mapFromKeyValue">
            <span *ngIf="contact.type == 1">{{contact.value}}</span>
          </span>
        </ng-template>
      </ngx-datatable-column>
  </ngx-datatable>
</div>
