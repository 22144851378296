import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ActorApplicationService, ConfigService } from 'app/_services';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-actors-applications-phase-table',
  templateUrl: './actors-applications-phase-table.component.html',
  styleUrls: ['./actors-applications-phase-table.component.css']
})
export class ActorsApplicationsPhaseTableComponent implements OnInit {

  @Input("rows") rows: any[] = [];
  @Input('filter') filter: string = "";
  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;

  selected = [];

  constructor(private actorService: ActorApplicationService, private configService: ConfigService) { }

  ngOnInit() {
  }

  currentActorId: number;
  currentActorInfo: any;
  openSelected(selected) {

    this.currentActorId = selected.id;
    this.currentActorInfo = selected;
    this.showModal();
  }


  // MODAL
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }



  public getActorService() {
    return this.actorService;
  }

  public getConfigService(){
    return this.configService
  }

}
