import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { differenceInYears } from 'date-fns';
import { CampService, PrimanotaService } from 'app/_services';
import { ConfigService, HelperService, UserService } from './../../_services/index';
import { parseISO, format } from 'date-fns';
import { NotificationsService } from 'angular2-notifications';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-camp-subscriber-list',
  templateUrl: './camp-subscriber-list.component.html',
  styleUrls: ['./camp-subscriber-list.component.css']
})
export class CampSubscriberListComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('autoShownNewModal', {static: false}) public autoShownNewModal: ModalDirective;
  @ViewChild('modalShowPaymentRequest', {static: false}) public autoShowModalRequestPayment: ModalDirective;
  @ViewChild('autoShownFeeDiscountModal', {static: false}) public autoShownFeeDiscountModal: ModalDirective;
  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;

  rows: any[] = [];
  rowsNotActive: any[] = [];
  selected = [];
  selectedFeeDiscount = [];
  ongoingRequest: boolean = false;
  selectedSignupInfo = null;

  subscribe: any;
  viewSubscriber: boolean = false;

  modelFiltering: any = {nome: '', gender: '', week: '', school: '', tshirt: ''};

  constructor(private campService: CampService, private configService: ConfigService,
  private helper: HelperService, private userService: UserService, private notify: NotificationsService) { }

  ngOnInit() {

    this.subscribe = this.campService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {
      this.selected = [];
      this.selectedFeeDiscount = [];
      this.getSubscribersInfo();
    });

    if (this.campService.currentCampSubscriberList != null && this.campService.currentCampInfo != null)
      this.getSubscribersInfo();
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  getSubscribersInfo () {
    if (this.campService.currentCampSubscriberList != null && this.campService.currentCampInfo != null) {
      this.parseTable(this.campService.currentCampSubscriberList);
    }
  }

  getSubs (week) {

    var counter = 0;
    this.rows.forEach((subscriber: any) => {
      if (subscriber.camp_week.includes(week))
        counter++;
    });

    return counter;
  }

  // classSort(valueA, valueB, rowA, rowB) {

  //   return -1;

  //   if ((rowA.school_type - rowB.school_type || rowA.class_type - rowB.class_type) <
  //       (rowB.school_type - rowA.school_type || rowB.class_type - rowA.class_type)) return -1;

  //   if ((rowA.school_type - rowB.school_type || rowA.class_type - rowB.class_type) >
  //     (rowB.school_type - rowA.school_type || rowB.class_type - rowA.class_type)) return 1;
  // }


  addNew() {
    this.viewSubscriber = false;
    this.showNewModal();
  }

  formNewSubscriberDelete() {
    this.hideModal();
  }

  formNewSubscriberSaved(event) {
    this.hideNewModal();
  }

  formFeeDiscountSubscriberSaved() {
    this.hideFeeDiscountModal();
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  onSelect({ selected }) {

    if (typeof selected == "undefined") return;
    if (selected.length == 0) return;
    if (selected.length > 1) return;

    this.viewSubscriber = true;
    this.selectedSignupInfo = JSON.parse(JSON.stringify(selected[0]));
    this.showModal();
  }

  onSelectFeeDiscount({ selected }) {

    if (typeof selected == "undefined") return;

  }

  openSelected(row){

    this.viewSubscriber = true;
    this.selectedSignupInfo = JSON.parse(JSON.stringify(row));
    this.showModal();
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  filteringChange (event) {

    var tempArray = this.campService.currentCampSubscriberList;

    //  filtro in base al nome
    if (this.modelFiltering.nome.length > 0) {
      tempArray = tempArray.filter(
        x => (
          (x.person_info.surname.toLowerCase()+x.person_info.first_name.toLowerCase() )
          .indexOf(this.modelFiltering.nome.toLowerCase()) !== -1 || !this.modelFiltering.nome) );
    }

    // filtro in base al genere
    if (this.modelFiltering.gender.length > 0) {
      tempArray = tempArray.filter(
        x => x.person_info.gender.toLowerCase().indexOf(this.modelFiltering.gender.toLowerCase()) !== -1 || !this.modelFiltering.gender);
    }

    //  filtro in base alle taglie delle magliette
    if (this.modelFiltering.tshirt.length > 0) {
      tempArray = tempArray.filter(
        x => (x.person_info.tshirt_size != null && x.person_info.tshirt_size == this.modelFiltering.tshirt) || !this.modelFiltering.tshirt);
    }

    //  filtro in base alle settimane
    if (this.modelFiltering.week.length > 0) {
      var checkString = this.modelFiltering.week.replace(/ /g, "").split(',');

      checkString.forEach(element => {
        tempArray = tempArray.filter(
          x => x.camp_week.indexOf(parseInt(element)) !== -1);
      });
    }

    //  filtro in base al tipo della scuola
    if (this.modelFiltering.school.length > 0) {

      tempArray = tempArray.filter (
        x => this.configService.getDiaryCampSubscriberSchoolTypeValueByKey(x.school_type).toLowerCase().indexOf(this.modelFiltering.school.split(',')[0].toLowerCase()) !== -1);

      if (this.modelFiltering.school.split(',').length > 1) {
        var splitted_class = this.modelFiltering.school.split(',')[1];

        if (splitted_class.length > 0) {
          tempArray = tempArray.filter (
            x => x.class_type == parseInt(splitted_class));
        }
      }
    }

    // aggiorno la tabella
    this.parseTable(tempArray);
  }

  parseTable(list){

    let rowActive = [];
    let rowTrashed = [];
    let quote = this.campService.currentCampInfo;

    for (let key in list){

      let active_discount = [];
      let r = list[key];

      if (r.quote_discount_1){
        active_discount.push({label: "Iscrizione durante l'incontro genitori", value: quote.quote_discount_1});
      }

      if (r.quote_discount_2){
        active_discount.push({label: "Host Families (x"+r.host_week.length+")", value: (quote.quote_discount_2 * r.host_week.length)});
      }

      if (r.quote_discount_3){
        active_discount.push({label: "Sconto a fratello (x"+r.camp_week.length+")", value: (quote.quote_discount_3 * r.camp_week.length)});
      }

      if (r.quote_discount_4){
        active_discount.push({label: "dal 3° fratello in poi (x"+r.camp_week.length+")", value: (quote.quote_discount_4 * r.camp_week.length)});
      }

      if (r.quote_discount_early_bird){
        active_discount.push({label: "Early bird discount", value: quote.quote_discount_early_bird});
      }

      if (r.quote_discount_custom != 0){
        active_discount.push({label: "Personalized discount", value: r.quote_discount_custom});
      }

      if (r.quote_discount_free == true){
        active_discount.push({label: "FREE", value: null});
      }

      r['active_discount'] = active_discount;
      r['total_fee_amount'] = quote["quote_course_fee_w"+r.camp_week.length];

      // row.course_fee_total - row.course_fee_payed
      // row.course_fee_payed && (row.course_fee_payed - row.enrollment_fee_amount) > row.course_fee_total
      if (r.course_fee_payed){
        // se la quota versata è maggiore del totale segnalo il rimborso necessario
        if (r.quote_discount_free == false && r.course_fee_payed > r.course_fee_total){
          r.course_fee_overfee = r.course_fee_total - r.course_fee_payed;
        }
        else if (r.quote_discount_free == true && r.course_fee_payed - r.enrollment_fee_amount > r.course_fee_total){
          r.course_fee_overfee = r.course_fee_total - r.enrollment_fee_amount - r.course_fee_payed;
        }
      }

      r.remaning_to_pay = r.course_fee_total - r.course_fee_payed;

      if (r.deleted_at != null){
        rowTrashed.push(r);
      }
      else {
        rowActive.push(r);
      }
    }

    this.rows = rowActive;
    this.rowsNotActive = rowTrashed;

  }




  getRowClass (row) {

    if (row.person_info){
      return { 'camp-staff-deleted' : row.person_info.deleted_at !== null};
    }

    return "";
  }



  currentPaymentRequest: any = null;

  //  MODAL REQUEST PAYMENT
  isModalShownPaymentRequest: boolean = false;
  showModalRequestPayment(modelPrecompiled: any = null) : void {
    this.isModalShownPaymentRequest = true;

    setTimeout( () => {

      if (modelPrecompiled) {
        this.currentPaymentRequest = JSON.parse(JSON.stringify(modelPrecompiled));
      }

        this.autoShowModalRequestPayment.show();
     }, 100 );

  }
  hideModalRequestPayment() : void {
    this.autoShowModalRequestPayment.hide();
  }
  onHiddenRequestPayment() : void {
    this.isModalShownPaymentRequest = false;
    this.currentPaymentRequest = null;
  }


  paymentRequestSucceeded ()
  {
    if (this.isModalShownPaymentRequest && this.autoShowModalRequestPayment != null)
      this.autoShowModalRequestPayment.hide();
  }

  paymentRequestError (message)
  {
    this.notify.error("Payment Request", "The following data are missing: " + message.toString());

    if (this.isModalShownPaymentRequest && this.autoShowModalRequestPayment != null)
      this.autoShowModalRequestPayment.hide();
  }




  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    if (this.autoShownModal)
      this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;

    this.selectedSignupInfo = null;
  }


  isNewModalShown: boolean = false;

  showNewModal (): void {
    this.isNewModalShown = true;
    if (this.autoShownNewModal)
      this.autoShownNewModal.show();
  }

  hideNewModal(): void {
    if (this.autoShownNewModal)
      this.autoShownNewModal.hide();
  }

  onHiddenNew(): void {
    this.isNewModalShown = false;
  }


  isFeeDiscountModalShown: boolean = false;

  showFeeDiscountModal (): void {
    this.isFeeDiscountModalShown = true;
  }

  hideFeeDiscountModal(): void {
    if (this.autoShownFeeDiscountModal)
      this.autoShownFeeDiscountModal.hide();
  }

  onHiddenFeeDiscount(): void {
    this.isFeeDiscountModalShown = false;
  }

  multipleEditSelected(): void {
    this.showFeeDiscountModal();
  }




  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }


  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    this.campService.exportSubscribers(null, (res) => {
      this.exportParse(res, () => {
        this.hideModalExport();
      });
    }, () => {
      this.hideModalExport();
    });

  }



  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse (data: any, callback: any) {

    var exportedArray = [];
    var exportedContactsArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach( (element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      //   se sono presenti i contatti allora li preparo
      if (element.contact_info){

        //  rendo univoci tutti i tipi di contatti
        let uniqueContacts = this.removeContactsDuplicated (element.contact_info);

        //  separo le tipologie di contatto e le aggiungo all'array da esportare
        let objContact = {Email: ''};
        uniqueContacts.forEach( (el_contact) => {

          if (el_contact.type == 1) { // email
            objContact['Email'] = el_contact.value;
          }
        });

        exportedContactsArray.push(objContact);
      }

      // seleziono se presente un Home Address (type 1)
      let home_address = {
        route: "",
        street_number: "",
        postal_code: "",
        locality: "",
        administrative_area_level_2_short_version: "",
        administrative_area_level_3_long_version: ""
      }

      if (typeof element.person_info.address != "undefined"){
        for (let k in element.person_info.address){
          let add = element.person_info.address[k];
          if (add.type == 1){
            home_address = add;
            break;
          }
        }
      }


      let obj = {
        'Data di registrazione': parseISO(element.registration_date).toLocaleDateString(this.helper.locale),
        'Nome': element.person_info.first_name,
        'Cognome': element.person_info.surname,
        'Eta': this.helper.calculateAge(element.person_info.birthday),
        'Data di nascita': parseISO(element.person_info.birthday).toLocaleDateString(this.helper.locale),
        'Luogo di nascita': element.person_info.birthplace,
        'Sesso': this.configService.getGenderPersonValueByKey(element.person_info.gender),
        'CF': element.person_info.tax_id,
        'Indirizzo': home_address.route + " " + (home_address.street_number ? home_address.street_number : ""),
        'CAP': home_address.postal_code,
        'Località': home_address.locality + " (" + home_address.administrative_area_level_3_long_version + ")",
        'Provincia': home_address.administrative_area_level_2_short_version,
        'Taglia': this.configService.getShirtSizePersonValueByKey(element.person_info.tshirt_size),
        'Scuola': this.configService.getDiaryCampSubscriberSchoolTypeValueByKey(element.school_type),
        'Classe': this.configService.getDiaryCampSubscriberSchoolTypeSubValueByKey(element.school_type, element.class_type),
        'Cash Flow': this.calculateCashFlow(element),
        'Quota versata': element.course_fee_payed,
        'Quota mancante': (element.course_fee_total - element.course_fee_payed),
        'Quota totale': element.course_fee_total,
      };

      if (typeof element.contact_info != "undefined" && element.contact_info != null && element.contact_info.length > 0)
      {

        var contactToAdd = "";
        element.contact_info.forEach(contact => {

          //  controllo solo se è un contatto cellullare
          if (contact.type == 5)
          {
            //  aggiungo un numero se ancora non è stato fatto
            if (contactToAdd.length == 0)
              contactToAdd = contact.value;

            //  se ho già aggiunto un numero controllo che sia quello primary
            if (contact.primary == true)
              contactToAdd = contact.value;
          }

        });
      }

      //  aggiungo il numero di telefono al foglio
      obj['Mobile'] = contactToAdd;


      //  aggiungo le settimane
      if (element.camp_week.length > 0){
        element.camp_week.forEach( (element) => {
          obj['Week '+element] = 1;
        });
      }

      //  aggiungo le mote
      obj['Note'] = element.annotation;

      //  preparo
      exportedArray.push(obj)

    })

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{width: 18}, {width:20}, {width:30}, {width:12}, {width:14}, {width:30}, {width:10}, {width:30}, {width:30}, {width:30}, {width:30}, {width:30},
      {width:20}, {width:20}, {width:12}, {width:12}, {width:10}, {width:10}, {width:10}, {width:16}, {width:10}, {width:10}, {width:50}];

    // //  personalizzo l'header
    worksheet['!rows'] = [{hpx: 30}];

    const worksheetEmail: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedContactsArray);
    worksheetEmail['!cols'] = [{width: 50}];
    worksheetEmail['!rows'] = [{hpx: 30}];

    // //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'Subscribers': worksheet, 'Emails': worksheetEmail}, SheetNames: ['Subscribers', 'Emails'] };

    // //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

    // //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "subscribers.xlsx");

    callback();

  }



  //  rimuove i duplicati dalla lista dei contatti
  removeContactsDuplicated (contacts: any) {

    let uniqueContacts = [];
    for ( var i = 0; i < contacts.length; i++ )
    {
      let found = false;
      for (var j = 0; j < uniqueContacts.length; j++)
      {
        if (uniqueContacts[j].value == contacts[i].value)
          found = true;
      }

      if (found == false)
        uniqueContacts.push(contacts[i]);
    }

    return uniqueContacts
  }



  calculateCashFlow(subscriber) {

    var value = "";
    if (subscriber.course_fee_payed && subscriber.course_fee_total > 0)
      value = subscriber.course_fee_payed+'/'+subscriber.course_fee_total;

    if (!subscriber.course_fee_payed && subscriber.course_fee_total > 0)
      value = '0/'+subscriber.course_fee_total;

    if (subscriber.course_fee_total == 0)
      value = 'FREE';

    return value;
  }


  getCampService():CampService {
    return this.campService
  }

  getUserService() {
    return this.userService
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

}
