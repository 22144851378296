import { ViewChild, ElementRef } from '@angular/core';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-actor-group-search-form',
  templateUrl: './actor-group-search-form.component.html',
  styleUrls: ['./actor-group-search-form.component.css']
})
export class ActorGroupSearchFormComponent implements OnInit {

  model: any = { advancedSearchOn: false, simpleSearchKeyword: "" };

  private firstSubmit: boolean = false;
  public advancedSearchShow = false;

  @Output() searchKeywordsSubmit = new EventEmitter();

  @Input() ongoingRequest: boolean;

  @ViewChild('simpleSearchKeywordText', {static: true, read: ElementRef}) private searchInput: ElementRef;

  ngOnInit() {
  }

  //   quando la vista si è inizializzata setto il focus sul campo di ricerca
  ngAfterViewInit() {

    setTimeout(() => {
      if (typeof this.searchInput != "undefined")
        this.searchInput.nativeElement.focus();
    }, 600);

  }

  fieldChange(event) {

    if (!this.firstSubmit)
      return;

     this.searchKeywordsSubmit.emit({});
    }

  submitSearch() {
    this.firstSubmit = true;

    if (this.advancedSearchShow)
      this.model.advancedSearchOn = true;
    else {
      this.model = { advancedSearchOn: false, simpleSearchKeyword: this.model.simpleSearchKeyword };
    }

    if (!this.advancedSearchShow)
      this.advancedSearchShow = false;

    this.searchKeywordsSubmit.emit(this.model);
  }

  getAllResources(form: any) {

    this.model = {};

    if (form != null)
      form._submitted = false;

    this.searchKeywordsSubmit.emit();
  }

  clearAdvancedSearch() {

    //  resetto il modello e tengo aperto il form di ricerca
    this.model = { advancedSearchOn: false, simpleSearchKeyword: "" };
    this.showAdvancedSearch();
  }

  hideAdvancedSearch() {
    this.advancedSearchShow = false;
  }

  showAdvancedSearch() {
    this.advancedSearchShow = true;
    this.model.simpleSearchKeyword = "";

    if (!this.model.actor_groups_active)
      this.model.actor_groups_active = true;
  }

}
