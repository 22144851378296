import { WarehouseService } from 'app/_services';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-shipment-material-datatable',
  templateUrl: './shipment-material-datatable.component.html',
  styleUrls: ['./shipment-material-datatable.component.css']
})
export class ShipmentMaterialDatatableComponent implements OnInit {

  @Output() productSelected = new EventEmitter<any> ();

  rows: any[] = [];
  isOngoingRequest: boolean = false

  selected = [];

  constructor(private warehouseService: WarehouseService) { }

  ngOnInit() {
  }

  onSelect({ selected }) {
    this.productSelected.emit(selected[0]);
  }

  getWarehouseService():WarehouseService {
    return this.warehouseService;
  }

}
