import { WarehouseService } from 'app/_services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warehouse-home',
  templateUrl: './warehouse-home.component.html',
  styleUrls: ['./warehouse-home.component.css']
})
export class WarehouseHomeComponent implements OnInit {

  constructor(private warehouseService: WarehouseService) { }

  ngOnInit() {

    this.warehouseService.getAllProducts();
  }

}
