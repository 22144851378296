<!-- BEGIN FORM -->

<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">

    <div class="row" *ngIf="model?.agreement_id">
      <div class="col-md-12">
        <div class="form-group">
          <button type="button" class="btn green btn-block btn-outline" (click)="GoToAgreement()"><i
              class="fa fa-external-link"></i>&nbsp; Existing Agreeement</button>
        </div>
      </div>
    </div>

    <alert type="warning" *ngIf="model?.agreement_id">
      <strong>Warning!</strong> La modifica di alcune informazioni è bloccata perché esiste già una convenzione creata,
      se si vuole modificare queste informazioni bisogna cancellare la convenzione
    </alert>

    <div class="row">
      <div class="col-md-2">
        <div *ngIf="editMode" class="form-group" [ngClass]="{ 'has-error': (f.submitted && !type.valid) }">
          <label class="control-label">Event Type
            <span class="required">*</span>
          </label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel"
            (ngModelChange)="typeChange($event)" required>
            <option *ngFor="let e of getConfigService().theatrinoTourEventSelect" [hidden]="e.key == 5" [value]="e.key">
              {{e.value}}</option>
          </select>
          <span class="help-block"> Type is required</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Event Type</label>
          <p>{{getConfigService().getTheatrinoEventValueByKey(model?.type)}}</p>
        </div>
      </div>
      <div class="col-md-4">
        <div [hidden]="!editMode" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalData())) }">
          <label class="control-label">Tour Date <span class="required">*</span></label>
          <div class="input-group">
            <!-- input box -->
            <input angular-mydatepicker #dp_date="angular-mydatepicker" name="date" class="form-control"
              [locale]="getHelper().locale" [options]="getDatePickerOptions()" [ngModel]="getLocalData()"
              (dateChanged)="onDateChanged($event)" required />
            <!-- clear date button -->
            <span class="input-group-btn">
              <button type="button" *ngIf="model?.date" class="btn default" (click)="dp_date.clearDate()"><i
                  class="fa fa-close"></i></button>
            </span>
            <!-- toggle calendar button -->
            <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_date.toggleCalendar()"><i
                  class="fa fa-calendar-o"></i></button>
            </span>
          </div>
          <span class="help-block"> Date is required</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Tour Date</label>
          <p>{{getLocalData()?.singleDate?.jsDate | date}}</p>
        </div>
      </div>
      <div class="col-md-2" *ngIf="model" [hidden]="!getHelper().isADateTourStandard(model.type)">
        <div *ngIf="editMode" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || time.dirty || time.touched) && (!time.valid || !timevalid) }">
          <label class="control-label">Time
            <span class="required">*</span>
          </label>
          <input type="text" name="time" [(ngModel)]="model.time" #time="ngModel" class="form-control"
            (input)="onTimeInput($event)" [typeahead]="getConfigService().timeTourSelect"
            (typeaheadOnSelect)="typeTimeOnSelect($event)" typeaheadOptionField="value" placeholder="10:30" required
            autocomplete="off">
          <span class="help-block"> Time is required</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Time</label>
          <p>{{model?.time}}</p>
        </div>
      </div>
      <div class="col-md-4" *ngIf="model && getHelper().isADateTourStandard(model.type)"
        [hidden]="!getHelper().isADateTourStandard(model.type)">
        <div *ngIf="editMode" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || project_type.dirty || project_type.touched) && !project_type.valid }">
          <label class="control-label">Project/Show Type
            <span class="required">*</span>
          </label>
          <select class="form-control" name="project_type" [(ngModel)]="model.project_type" #project_type="ngModel"
            required>
            <option *ngFor="let s of getConfigService().showTypeSelect" [value]="s.key">{{s.value}}</option>
          </select>
          <span class="help-block">Select show type, is required</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Project/Show Type</label>
          <p>{{ getConfigService().getShowTypeValueByKey(model.project_type) }}</p>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode && model" class="row read-only" [hidden]="!getHelper().isADateTourStandard(model.type)">
      <div class="col-md-6">
        <div class="form-group editMode">
          <label class="control-label">Enterprise</label>
          <p>
            <app-enterprise-info-show #enterpriseInfoShow [idEnterprise]="model.enterprise_id">
            </app-enterprise-info-show>
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group editMode">
          <label class="control-label">Organizer</label>
          <p>
            <app-enterprise-info-show #enterpriseInfoShow [idEnterprise]="model.enterprise_organizer_id">
            </app-enterprise-info-show>
          </p>
        </div>
      </div>
    </div>


    <div *ngIf="editMode && model" class="row" [hidden]="!getHelper().isADateTourStandard(model.type)">
      <div class="col-md-6">
        <label class="control-label"></label>
        <div class="portlet light bordered">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase">Enterprise
                <span class="required">*</span>
              </span>
              <span class="caption-helper">is required</span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green"
                  [disabled]="getTheatrinoService().isOngoingRequest()" (click)="enterpriseClicked()">
                  <i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDelete01>
                  <button type="button" class="btn btn-outline red" (click)="removeEnterprise()">Yes Remove!</button>
                </ng-template>
                <button *ngIf="this.model.enterprise_id" type="button"
                  [disabled]="enterpriseInfoShow.getEnterpriseService().isOngoingRequest()"
                  class="btn btn-circle red btn-outline" [outsideClick]="true" [popover]="confirmDelete01"
                  popoverTitle="Are you sure?">
                  <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-enterprise-info-show #enterpriseInfoShow [idEnterprise]="model.enterprise_id">
            </app-enterprise-info-show>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label class="control-label"></label>
        <div class="portlet light bordered">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase">Enterprise Organizer</span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green"
                  [disabled]="getTheatrinoService().isOngoingRequest()" (click)="enterpriseOrganizerClicked()">
                  <i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDelete02>
                  <button type="button" class="btn btn-outline red" (click)="removeEnterpriseOrganizer()">Yes
                    Remove!</button>
                </ng-template>
                <button *ngIf="this.model.enterprise_organizer_id" type="button"
                  [disabled]="enterpriseInfoShow.getEnterpriseService().isOngoingRequest()"
                  class="btn btn-circle red btn-outline" [outsideClick]="true" [popover]="confirmDelete02"
                  popoverTitle="Are you sure?">
                  <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-enterprise-info-show #enterpriseInfoShow [idEnterprise]="model.enterprise_organizer_id">
            </app-enterprise-info-show>
          </div>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="model" [hidden]="!getHelper().isADateTourStandard(model.type)">

      <div class="col-md-2">
        <div *ngIf="editMode" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || status.dirty || status.touched) && !status.valid }">
          <label class="control-label">Status
            <span class="required">*</span>
          </label>
          <select class="form-control" name="status" [(ngModel)]="model.status" #status="ngModel" required>
            <option *ngFor="let s of getConfigService().theatrinoTourStatusSelect" [value]="s.key">{{s.value}}</option>
          </select>
          <span class="help-block">is required</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Status</label>
          <p>{{getConfigService().getTheatrinoStatusValueByKey(model.status)}}</p>
        </div>
      </div>

      <div class="col-md-2">
        <div *ngIf="editMode" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || area.dirty || area.touched) && !area.valid }">
          <label class="control-label">Area
            <span class="required">*</span>
          </label>
          <select class="form-control" name="area" [(ngModel)]="model.area" #area="ngModel" required>
            <option *ngFor="let a of getConfigService().areaExpertiseSelect" [value]="a.key">{{a.value}}</option>
          </select>
          <span class="help-block">is required</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Area</label>
          <p>{{getConfigService().getAreaExpertiseValueByKey(model.area)}}</p>
        </div>
      </div>

      <div class="col-md-4 col-md-offset-2">
        <div *ngIf="editMode" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || company.dirty || company.touched) && !company.valid }">
          <label class="control-label">Company
            <span class="required">*</span>
          </label>
          <select class="form-control" name="company" [(ngModel)]="model.company" #company="ngModel" required>
            <option *ngFor="let c of getConfigService().groupCompaniesSelect" [value]="c.key">{{c.value}}</option>
          </select>
          <span class="help-block"> Select company, is required</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Company</label>
          <p>{{getConfigService().getGroupCompanyValueByKey(model.company)}}</p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="model" [hidden]="!getHelper().isADateTourStandard(model.type)">
      <div class="col-md-2">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Payment Type</label>
          <select class="form-control" name="payment_type" [(ngModel)]="model.payment_type" #payment_type="ngModel">
            <option *ngFor="let p of getConfigService().paymentTourTypeSelect" [value]="p.key">{{p.value}}</option>
          </select>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Payment Type</label>
          <p>{{getConfigService().getPaymentTourValueByKey(model.payment_type)}}</p>
        </div>
      </div>
      <div class="col-md-2">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Cost</label>
          <input type="number" name="cost" [(ngModel)]="model.cost" #cost="ngModel" class="form-control"
            placeholder="es: 1000,00">
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Cost</label>
          <p>{{model.cost | currency:'EUR':'symbol':'1.2-2':'it'}}</p>
        </div>
      </div>
      <div class="col-md-4 col-md-offset-2">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Alternative Place</label>
          <input type="text" name="alternative_place" [(ngModel)]="model.alternative_place" #alternative_place="ngModel"
            class="form-control" placeholder="es: plesso scuola materna">
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Alternative Place</label>
          <p>{{model.alternative_place}}</p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="model" [hidden]="!getHelper().isADateTourStandard(model.type)">
      <div class="col-md-12">
        <div *ngIf="editMode || model?.agreement_id" class="form-group">
          <label class="control-label">Notes (this notes are visible to actor groups)</label>
          <textarea name="annotation" [(ngModel)]="model.annotation" rows="3" class="form-control"
            placeholder="Some notes..."></textarea>
        </div>
        <div *ngIf="!editMode && !model?.agreement_id" class="form-group editMode">
          <label class="control-label">Notes (this notes are visible to actor groups)</label>
          <p class="line-breaker">{{model.annotation}}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div *ngIf="editMode || model?.agreement_id" class="form-group">
          <label class="control-label">Administrative notes </label>
          <textarea name="admin_annotation" [(ngModel)]="model.admin_annotation" rows="3" class="form-control"
            placeholder="Some notes..."></textarea>
        </div>
        <div *ngIf="!editMode && !model?.agreement_id" class="form-group editMode">
          <label class="control-label">Administrative notes</label>
          <p class="line-breaker">{{model?.admin_annotation}}</p>
        </div>
      </div>
    </div>

    <div class="form-actions right" *ngIf="model">
      <img *ngIf="getTheatrinoService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

      <ng-template #confirmDelete>
        <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
      </ng-template>
      <button *ngIf="editMode && this.model.id &&!lockedMode" type="button" class="btn btn-outline red"
        [disabled]="getTheatrinoService().isOngoingRequest()" [outsideClick]="true" [popover]="confirmDelete"
        popoverTitle="Are you sure?">
        <i class="fa fa-trash-o"></i> Delete</button>&nbsp;
      <button *ngIf="this.model.id && !editMode && !lockedMode" type="button" class="btn btn-outline blue"
        [disabled]="getTheatrinoService().isOngoingRequest()" (click)="edit()">
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

      <button *ngIf="(editMode && !lockedMode) || model?.agreement_id" type="submit" class="btn blue"
        [disabled]="getTheatrinoService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
        <span class="ladda-label" *ngIf="!this.model.id">Continue</span>
        <span class="ladda-label" *ngIf="this.model.id">Update</span>
      </button>

      <button *ngIf="this.model.id && !!getHelper().isADateTourStandard(model.type)" type="button"
        class="btn btn-outline blue" [disabled]="getTheatrinoService().isOngoingRequest()" (click)="copy()">
        <i class="fa fa-clone" aria-hidden="true"></i> Copy</button>

      <button *ngIf="this.model.id && !!getHelper().isADateTourStandard(model.type)" type="button"
        class="btn btn-outline blue" [disabled]="getTheatrinoService().isOngoingRequest()" (click)="SearchScheduling()">
        <i class="fa fa-user-o" aria-hidden="true"></i> Show as Actor</button>
    </div>

  </div>

</form>



<div *ngIf="isModalCopyShow" [config]="{ show: true }" (onHidden)="onHiddenCopy()" bsModal #modalCopy="bs-modal"
  class="modal fade" tabindex="-1" tole="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"> Copy Booking</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalCopy()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-theatrino-booking-copy (closeModal)="closeModalEvent($event)" [model]="this.model">
            </app-theatrino-booking-copy>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #modalEnterprise="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add Enterprise</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-enterprise-search-form #enterpriseSearchForm (searchKeywordsSubmit)="searchKeywordsSubmit($event)"
              [ongoingRequest]="enterpriseDatatable.ongoingRequest"></app-enterprise-search-form>
          </div>
        </div>

        <br>
        <br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-enterprise-datatable #enterpriseDatatable [query]="enterpriseSearchForm.searchKeyword"
                  (enterpriseSelected)="resultSelected($event)"></app-enterprise-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: Modale per la vista dello scheduling -->
<div *ngIf="isModalSchedulingShown" [config]="{ show: true }" (onHidden)="onHiddenScheduling()" bsModal
  #modalScheduling="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Group Scheduling</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalScheduling()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <alert type="warning" *ngIf="loadingData">
          <strong>Loading</strong> ...
        </alert>
        <div class="row">
          <div class="col-md-12">
            <app-theatrino-group-scheduling-review *ngIf="!loadingData && rawData" [rawData]="this.rawData">
            </app-theatrino-group-scheduling-review>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modale per la vista dello scheduling -->
