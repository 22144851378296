import { WarehouseService } from './../../_services/warehouse.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warehouse-shipment',
  templateUrl: './warehouse-shipment.component.html',
  styleUrls: ['./warehouse-shipment.component.css']
})
export class WarehouseShipmentComponent implements OnInit {

  constructor(private warehouseService: WarehouseService) { }

  ngOnInit() {

    this.warehouseService.getAllShipmentRequests();
  }

}
