import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class TheatrinoService {

	currentTheatrinoId;
	currentTheatrinoInfo: any;
	allTheatrinos: any;

	//	Contiene le informazioni del tour sul quale si sta operando in questo momento
	currentTourInfoList: any;
	currentTourId;
  currentTourInfo: any;
	currentTourInfoByDate: any;

	//	Memorizza l'ultima query di ricerca fatta
	lastSearchQueries: any = null;


	ongoingRequest: boolean = false;


	private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

	constructor(private backend: BackendService, private notify: NotificationsService) { }

	getsubjectToUpdateObservable(): Observable<boolean> {
		return this.subjectToUpdate.asObservable();
	}

	resetService() {
		this.allTheatrinos = null;
		this.currentTheatrinoId = null;
		this.currentTheatrinoInfo = null;
		this.currentTourInfoList = null;
		this.currentTourId = null;
    this.currentTourInfo = null;
    this.currentTourInfoByDate = null;
	}

	startRequest() {
		this.ongoingRequest = true;
	}

	finishRequest() {
		this.ongoingRequest = false;
	}

	isOngoingRequest() {
		return this.ongoingRequest;
	}

	getAllTheatrinoInfo(callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		let query: any;
		query = "all";

		this.backend.post('theatrino/search/advanced', query).subscribe(
			(response) => {

				let resJson : any = response;
				this.allTheatrinos = resJson.data;

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);

	}

	orderTourInfoList ()
	{
		this.currentTourInfoList.sort(function(a, b){
			return a.date < b.date ? -1 : a.date > b.date ? 1 : 0 }
		  );
	}

	getTheatrinoTourInfoAll(callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.get('theatrino/' + this.currentTheatrinoId + '/all', params).subscribe(
			(response) => {

				let resJson : any = response;

				this.currentTheatrinoId = resJson.id;
				this.currentTheatrinoInfo = resJson;
				this.currentTourInfoList = resJson.tour_date;

				//	Ordino l'array
				this.orderTourInfoList();

				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			});
	}

	saveTheatrinoTourInfo(model: any[], callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.post('theatrino', model).subscribe(
			(response) => {

				let resJson : any = response;
				this.currentTheatrinoInfo = resJson;

				this.currentTheatrinoId = resJson.id;
				this.subjectToUpdate.next(true);

				this.notify.success("Tour Info", "successfully saved");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			}
		);
	}

	updateTheatrinoTourInfo(model: any[], callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		//	salvo le date del theatrino prima di assegnarle a quello aggiornato
		var tour_date = JSON.parse(JSON.stringify(this.currentTheatrinoInfo.tour_date));

		this.backend.put('theatrino/' + this.currentTheatrinoId, model).subscribe(
			(response) => {

				this.currentTheatrinoInfo = response;

				this.currentTheatrinoInfo.tour_date = JSON.parse(JSON.stringify(tour_date));

				this.currentTheatrinoId = this.currentTheatrinoInfo.id;
				this.subjectToUpdate.next(true);

				this.notify.success("Tour Info", "successfully updated");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				console.log("Errore");
				console.log(error);

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			}
		);
	}

	deleteTheatrinoTourInfo(model: any[], callbackSuccess: any = null) {

		this.startRequest();

		this.backend.destroy('theatrino/' + this.currentTheatrinoId).subscribe(
			(response) => {

				this.resetService();
				this.subjectToUpdate.next(true);

				this.notify.success("Tour Info", "successfully deleted");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}





	//	scarica solo la lista degli spettacoli
	getTheatrinoTourDateList(callbackSuccess = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.get('theatrino/' + this.currentTheatrinoId + '/tour/all', params).subscribe(
			(response) => {

				this.currentTourInfoList = response;

				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				console.log("Errore");
				console.log(error);

				this.finishRequest();
			}
		);
  }

  getTheatrinoTourDateByDate(date, callbackSuccess = null, callbackError = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.get('theatrino/' + this.currentTheatrinoId + '/tour/date/'+ date, params).subscribe(
			(response) => {

				this.currentTourInfoByDate = response;

				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				console.log("Errore");
				console.log(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
			}
		);
	}



	getTheatrinoTourDateInfo(callbackSuccess = null, callbackError = null) {

		let params = new HttpParams();

		this.backend.get('theatrino/' + this.currentTheatrinoId + '/tour/' + this.currentTourId + '/show', params).subscribe(
			(response) => {

				let resJson : any = response;

				this.currentTourId = resJson.id;
				this.currentTourInfo = resJson;

				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				console.log("Errore");
				console.log(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			}
		);
	}



	//	Uso questo metodo per clonare una data di un tour
	cloneTheatrinoTourDateInfo(model: any, showCopy: boolean, callbackSuccess: any = null, callbackError: any = null)
	{
		this.startRequest();

		//	Salvo prima le info del theatrino nuovo
		this.backend.post('theatrino/'+ model.theatrino_id + '/tour', model).subscribe(
			(response) => {

				//	Salvo i dati dei tour che sono arrivati
				let resJson : any = response;

				//	Se ci degli show li salvo
				if (Object.keys(model.shows).length > 0 && showCopy == true)
				{
					//	Controllo quanti salvataggi è necessario fare per gli show
					var showCounter = 0;

					//	Salvo anche tutti gli show se presenti
					Object.keys(model.shows).forEach(key => {

						this.backend.post('theatrino/' + model.theatrino_id + '/tour/' + resJson.id + '/show', model.shows[key]).subscribe(
							(response) => {
								showCounter++;
								if (showCounter >= Object.keys(model.shows).length)
								{
									this.notify.success("Tour Show Clone", "clone successfully");
									this.finishRequest();
									if (callbackSuccess != null)
										callbackSuccess();
								}
							},
							(error) => {
								this.backend.showErrors(error);
								this.finishRequest();
								if (callbackError != null)
									callbackError();
								console.log("Errore");
								console.log(error);
							}
						);
					});
				}
				else
				{
					this.notify.success("Tour Show Clone", "clone successfully");
					this.finishRequest();
					if (callbackSuccess != null)
						callbackSuccess();
				}
			},
			(error) => {
				this.backend.showErrors(error);
				this.finishRequest();
				if (callbackError != null)
					callbackError();
				console.log("Errore");
				console.log(error);
			}
		)
	}



	saveTheatrinoTourDateInfo(model: any, callbackSuccess: any = null) {

		this.startRequest();

		this.backend.post('theatrino/' + this.currentTheatrinoId + '/tour', model).subscribe(
			(response) => {

				let resJson : any = response;

				this.currentTourId = resJson.id;
				this.currentTourInfo = resJson;

				this.notify.success("Tour Date Info", "successfully saved");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();

			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}


	updateTheatrinoTourDateInfo(model: any, callbackSuccess: any = null) {

		this.startRequest();

		this.backend.put('theatrino/' + this.currentTheatrinoId + '/tour/' + model.id, model).subscribe(
			(response) => {

				let resJson : any = response;

				this.currentTourId = resJson.id;
				this.currentTourInfo = resJson;

				this.notify.success("Tour Date Info", "successfully updated");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();

			},
			(error) => {
				console.log("Errore");
				console.log(error);

				this.finishRequest();
			}
		);
	}

	deleteTheatrinoTourDateInfo(model: any, callbackSuccess: any = null) {

		this.startRequest();

		this.backend.destroy('theatrino/' + this.currentTheatrinoId + '/tour/' + model.id).subscribe(
			(response) => {

			//  cancello le informazioni dell'accomodation/tour con l'id che avevo passato in precedenza
			for (var key in this.currentTourInfoList){
        if (this.currentTourInfoList[key].id == model.id)
				{
					this.currentTourInfoList.splice(key, 1);
					break;
				}
      }


      //  Aggiorno per continuità dei dati anche la stessa lista contenuta nelle info del theatrino
      if (this.currentTheatrinoInfo)
        this.currentTheatrinoInfo.tour_date = this.currentTourInfoList;

			this.notify.success("Tour Date Info", "successfully deleted");

			this.finishRequest();

			// this.subjectToUpdate.next(true);

			if (callbackSuccess != null)
				callbackSuccess();

			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}









	saveBookingShow(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.post('theatrino/' + this.currentTheatrinoId + '/tour/' + this.currentTourInfo.id + '/show', model).subscribe(
			(response) => {

				this.currentTourInfo["shows"] = response;

				this.notify.success("Tour Show Info", "successfully saved");

				this.finishRequest();

				this.subjectToUpdate.next(true);

				if (callbackSuccess != null)
					callbackSuccess();

			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			}
		);
	}


	updateBookingShow(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.put('theatrino/' + this.currentTheatrinoId + '/tour/' + this.currentTourInfo.id + '/show/' + model.id, model).subscribe(
			(response) => {

				this.currentTourInfo["shows"] = response;

				this.notify.success("Tour Show Info", "successfully updated");

				this.finishRequest();

				this.subjectToUpdate.next(true);

				if (callbackSuccess != null)
					callbackSuccess();

			},
			(error) => {
				console.log("Errore");
				console.log(error);

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			}
		);
	}

	destroyBookingShow(model: any, callbackSuccess: any = null) {

		this.startRequest();

		this.backend.destroy('theatrino/' + this.currentTheatrinoId + '/tour/' + this.currentTourInfo.id + '/show/' + model.id).subscribe(
			(response) => {

				this.currentTourInfo["shows"] = response;

				this.notify.success("Tour Show Info", "successfully deleted");

				this.finishRequest();

				this.subjectToUpdate.next(true);
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}





	saveAccomodation(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.post('theatrino/' + this.currentTheatrinoId + '/tour', model).subscribe(
			(response) => {

				//	Risposta che contiene la data del tour con tutte le info
        let resJson : any = response;

				this.backend.post('theatrino/' + this.currentTheatrinoId + '/tour/' + resJson.id + '/accomodation',  model).subscribe(
					(response) => {

						//	Risposta che contiene le informazioni sull'accomodation e basta
						let resJson : any = response;

            this.currentTourInfoList.push(resJson);

            //  Ordino l'array
            this.orderTourInfoList();

            //  Aggiorno per continuità dei dati anche la stessa lista contenuta nelle info del theatrino
            this.currentTheatrinoInfo.tour_date = this.currentTourInfoList;

            this.notify.success("Accomodation", "successfully saved");

            this.finishRequest();

            this.subjectToUpdate.next(true);

            if (callbackSuccess != null)
              callbackSuccess();
					},
					(error) => {
						this.backend.showErrors(error);

						this.finishRequest();

						if (callbackError != null)
							callbackError();
					}
				);
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}



	updateAccomodation(model: any, callbackSuccess: any = null, callbackError: any = null)
	{
		this.startRequest();

		this.backend.put('theatrino/' + this.currentTheatrinoId + '/tour/' + model.tour_id + '/accomodation/' + model.id,   model).subscribe(
			(response) => {

				let resJson : any = response;

				//	Setto nell'array con le informazioni delle date del tour l'aggiornamento della accomodation corrente
				for (var key in this.currentTourInfoList)
					if (this.currentTourInfoList[key].id == resJson.id)
						this.currentTourInfoList[key] = resJson;

        //  Aggiorno per continuità dei dati anche la stessa lista contenuta nelle info del theatrino
        this.currentTheatrinoInfo.tour_date = this.currentTourInfoList;

				this.notify.success("Tour Date Accomodation", "successfully updated");

				this.finishRequest();

				this.subjectToUpdate.next(true);

				if (callbackSuccess != null)
					callbackSuccess();

			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			}
		);
	}


	deleteAccomodation(model: any, callbackSuccess: any = null, callbackError: any = null)
	{
		this.startRequest();

		this.backend.destroy('theatrino/' + this.currentTheatrinoId + '/tour/' + model.tour_id + '/accomodation/' + model.id).subscribe(
			(response) => {

			//  cancello le informazioni dell'accomodation/tour con l'id che avevo passato in precedenza
			for (var key in this.currentTourInfoList)
			if (this.currentTourInfoList[key].id == model.tour_id)
			{
				this.currentTourInfoList.splice(key, 1);
				break;
			}

			//  Aggiorno per continuità dei dati anche la stessa lista contenuta nelle info del theatrino
			this.currentTheatrinoInfo.tour_date = this.currentTourInfoList;

			this.notify.success("Accomodation", "successfully deleted");

			this.finishRequest();

			this.subjectToUpdate.next(true);

			if (callbackSuccess != null)
				callbackSuccess();

			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			}
		);
	}


	getReportShows(model: any, callbackSuccess = null, callbackError = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.post('theatrino/report/shows', model, params).subscribe(
			(response) => {

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess(response);
			},
			(error) => {
				console.log("Errore");

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
  }

  getReportReferenti(model: any, callbackSuccess = null, callbackError = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.post('theatrino/report/referenti', model, params).subscribe(
			(response) => {

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess(response);
			},
			(error) => {
				console.log("Errore");

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
  }

  getReportWidgetConvenzioni(id, model: any, callbackSuccess = null, callbackError = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.post('theatrino/report/theatrino_convenzioni_scadenza/'+id, model, params).subscribe(
			(response) => {

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess(response);
			},
			(error) => {
				console.log("Errore");

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
  }

}
