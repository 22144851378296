<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div *ngIf="!editMode" class="form-body">

    <h3 class="form-section">Fee and Discount</h3>

    <div class="row">
      <div class="col-md-2">
        <b>Enrollment fee: </b>{{model.quote_enrollment_fee | currency:'EUR':'symbol':'1.2-2':'it'}}
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-md-12">
        <b>Course fee: </b>{{model.quote_course_fee | currency:'EUR':'symbol':'1.2-2':'it'}}
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-md-12">
        <b>Early bird discount: </b>{{model.quote_discount_early_bird | currency:'EUR':'symbol':'1.2-2':'it'}} from {{ model.quote_discount_early_bird_date | date }}
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-md-12">
        <b>Ha già partecipato ad un Theatrino o Camps: </b>{{model.quote_discount_1 | currency:'EUR':'symbol':'1.2-2':'it'}}<br>
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-md-12">
        <b>Sconto gruppi: </b>{{model.quote_discount_2 | currency:'EUR':'symbol':'1.2-2':'it'}}<br>
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-md-12">
        <b>Pernottamento: </b>{{model.quote_extra_1 | currency:'EUR':'symbol':'1.2-2':'it'}}<br>
      </div>
    </div>

    <br><br>

  </div>

  <div *ngIf="editMode" class="form-body">

    <h3 class="form-section">Enrollment Fee</h3>

    <div class="row">
      <div class="col-md-4">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_enrollment_fee.dirty || quote_enrollment_fee.touched) && !quote_enrollment_fee.valid}">
          <!-- <label class="control-label">Enrollment Fee</label> -->
          <input type="number" name="quote_enrollment_fee" [(ngModel)]="model.quote_enrollment_fee" #quote_enrollment_fee="ngModel" class="form-control" required>
          <span class="help-block">included in the course fee</span>
        </div>
      </div>
    </div>

    <h3 class="form-section">Course Fee</h3>

    <div class="row">
      <div class="col-md-2">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_course_fee.dirty || quote_course_fee.touched) && !quote_course_fee.valid}">
          <input type="number" name="quote_course_fee" [(ngModel)]="model.quote_course_fee" #quote_course_fee="ngModel" class="form-control" required>
        </div>
      </div>
    </div>

    <h3 class="form-section">Discount</h3>

    <div class="row">

      <div class="col-md-4">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_discount_early_bird.dirty || quote_discount_early_bird.touched) && !quote_discount_early_bird.valid}">
          <label class="control-label">Early Bird Discount</label>
          <input type="number" name="quote_discount_early_bird" [(ngModel)]="model.quote_discount_early_bird" #quote_discount_early_bird="ngModel" class="form-control" required>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalData(), false)) }">
          <label class="control-label">From Date</label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_quote_discount_early_bird_date="angular-mydatepicker" name="quote_discount_early_bird_date" class="form-control"
                [locale]="getHelper().locale"
                [options]="getDatePickerOptions()"
                [ngModel]="getLocalData()"
                (dateChanged)="onDateChanged($event)"
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.quote_discount_early_bird_date" class="btn default" (click)="dp_quote_discount_early_bird_date.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_quote_discount_early_bird_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
        <div class="col-md-4">
          <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_discount_1.dirty || quote_discount_1.touched) && !quote_discount_1.valid}">
            <label class="control-label">Ha già partecipato ad un Theatrino o Camps</label>
            <input type="number" name="quote_discount_1" [(ngModel)]="model.quote_discount_1" #quote_discount_1="ngModel" class="form-control" required>
          </div>
        </div>

        <div class="col-md-4">
          <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_discount_2.dirty || quote_discount_2.touched) && !quote_discount_2.valid}">
            <label class="control-label">Sconto gruppi</label>
            <input type="number" name="quote_discount_2" [(ngModel)]="model.quote_discount_2" #quote_discount_2="ngModel" class="form-control" required>
          </div>
        </div>
    </div>

    <h3 class="form-section">Extra Fee</h3>

    <div class="row">
      <div class="col-md-4">
        <div  class="form-group" [ngClass]="{ 'has-error': (f.submitted || quote_extra_1.dirty || quote_extra_1.touched) && !quote_extra_1.valid}">
          <label class="control-label">Pernottamento</label>
          <input type="number" name="quote_extra_1" [(ngModel)]="model.quote_extra_1" #quote_extra_1="ngModel" class="form-control" required>
        </div>
      </div>
  </div>

  </div>



  <div [appCheckPermission]="'course_schedule_edit_quote'" class="form-actions right">
    <img *ngIf="isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <button type="button" class="btn btn-outline blue" [disabled]="isOngoingRequest()" *ngIf="this.model.id && !editMode"
      (click)="edit()">
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>

    <button *ngIf="editMode" type="submit" class="btn blue" [disabled]="isOngoingRequest()"
      [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id">
        <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>



</form>
