import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { ShipmentMaterialSearchFormComponent, ShipmentMaterialDatatableComponent } from 'app/shipments';
import { ShipmentService, GroupService } from 'app/_services';

@Component({
  selector: 'app-actor-group-shipments-list',
  templateUrl: './actor-group-shipments-list.component.html',
  styleUrls: ['./actor-group-shipments-list.component.css']
})
export class ActorGroupShipmentsListComponent implements OnInit {

  @ViewChild('shipmentModal', {static: false}) public shipmentModal: ModalDirective;

  @ViewChild('materialModal', {static: false}) public materialModal: ModalDirective;
  @ViewChild('materialSearchDatatable', {static: true}) public materialSearchDatatable: ShipmentMaterialDatatableComponent;
  @ViewChild('materialSearchForm', {static: true}) public materialSearchForm: ShipmentMaterialSearchFormComponent;

  rows = [];
  subscribe: any;
  subscribeShipment: any;

  model: any = {};
  currentMaterialSelected: any = null;

  constructor(private groupService: GroupService, private notify: NotificationsService,
            private shipmentService: ShipmentService) { }

  ngOnInit() {

    this.subscribe = this.groupService.getsubjectToUpdateObservable().subscribe((update: boolean) => {
      this.loadShipmentList();
    });

    this.subscribeShipment = this.shipmentService.getsubjectToUpdateObservable().subscribe((update: boolean) => {
      this.groupService.getGroupShipment(() => {
        this.loadShipmentList();
      });
    });

    this.loadShipmentList();
  }


  loadShipmentList ()
  {
    if (this.groupService.currentShipmentList != null && typeof this.groupService.currentShipmentList != "undefined")
    {
      this.rows =  JSON.parse(JSON.stringify(this.groupService.currentShipmentList));
    }
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();

    if (this.subscribeShipment)
      this.subscribeShipment.unsubscribe();
  }


  submit ()
  {
    if (this.currentShipmentSelected.id != null)
    {
      this.groupService.updateGroupShipment(this.currentShipmentSelected, () => {
      });
    }
    else
    {
      var model:any = {};
      this.currentShipmentSelected = model;
      this.groupService.saveGroupShipment(this.currentShipmentSelected, () => {
        this.hideModalShipment();
      })
    }
  }

  finalizeOrder ()
  {
    if (this.currentShipmentSelected != null)
      if (this.currentShipmentSelected.id != null)
      {
        if (this.currentShipmentSelected.material_list.length <= 0) return;

        this.currentShipmentSelected.closed_order = true;
        this.groupService.updateGroupShipment(this.currentShipmentSelected, () => {
          this.hideModalShipment();
        });
      }
  }

  destroyShipment ()
  {
    this.groupService.deleteGroupShipment(this.currentShipmentSelected, () => {
      this.hideModalShipment();
    })
  }


  addNewShipment()
  {
    if (this.currentShipmentSelected == null)
    {
      var model:any = {};
      this.currentShipmentSelected = model;
      this.groupService.saveGroupShipment(this.currentShipmentSelected, () => {
        this.hideModalShipment();
      })
      return;
    }
  }


  currentShipmentSelected: any = null;
  onSelect({ selected }) {
    this.currentShipmentSelected = JSON.parse(JSON.stringify(selected[0]));
    this.showModalShipment();
  }






  //  modale della spedizione
  isModalShipmentShow: boolean = false;
  showModalShipment()
  {
    this.isModalShipmentShow = true;
    this.shipmentModal.show();
  }

  hideModalShipment()
  {
    this.shipmentModal.hide();
  }

  onHiddenShipmentModal ()
  {
    this.currentShipmentSelected = null;
    this.isModalShipmentShow = false;
  }






  //  modale del prodotto
  isModalMaterialShow: boolean = false;
  showModalMaterial() {
    this.isModalMaterialShow = true;
    this.materialModal.show();
    this.materialSearchForm.setFocus();
  }
  showMaterial(material){
    this.currentMaterialSelected = material;
    this.showModalMaterial();
  }

  hideModalMaterial() {
    this.materialModal.hide();
  }

  onHiddenMaterialModal () {
    this.currentMaterialSelected = null;
    this.isModalMaterialShow = false;
  }

  //  notifica di ricezione dei risultati dalla ricerca
  searchResult (results) {

    //  assegno al datatable i risultati della ricerca
    this.materialSearchDatatable.rows = results;
  }

  //  notifica di selezione di un prodotto
  productSelected (product) {

    //  cerco se il prodotto è già presente nella lista, così da non aggiungerlo
    if (typeof this.currentShipmentSelected.material_list != "undefined")
    {
      var found = false;
      for (var i = 0; i < this.currentShipmentSelected.material_list.length; i++)
      {
        if (this.currentShipmentSelected.material_list[i].product_id == product.id)
        {
          this.notify.error("This material is already present");
          return found = true;
        }
      }

      if (found) return;
    }

    //  assegno il prodotto alla modale
    var material = JSON.parse(JSON.stringify(product));
    this.currentMaterialSelected = {
      product_id: material.id,
      product_info :
        {
          type: material.type,
          name: material.name
        },
      shipment_id: this.currentShipmentSelected.id
    };

  }


  //  notifica di elemento salvato / aggiornato
  materialSaved () {

    this.hideModalMaterial();
  }


  //  notifica di cancellazione dell'inserimento di un prodotto
  cancelEvent () {

    this.currentMaterialSelected = null;
  }





  getGroupService():GroupService {
    return this.groupService
  }

}
