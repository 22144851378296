import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigService, TutorApplicationService, AddressService } from '../../_services/index';
import { environment } from '../../environment';

@Component({
  selector: 'app-tutors-applications-search-form',
  templateUrl: './tutors-applications-search-form.component.html',
  styleUrls: ['./tutors-applications-search-form.component.css']
})
export class TutorsApplicationsSearchFormComponent implements OnInit {

  model: any = {advancedSearchOn: false, simpleSearchKeyword: ""};

  private firstSubmit: boolean = false;

  private subject: any;

  private advancedSearchShow = false;

  @Output() searchKeywordsSubmit = new EventEmitter();

  @Input() ongoingRequest: boolean;

  constructor(private route: ActivatedRoute, private router: Router, private configService: ConfigService,
    private tutorApplicationService: TutorApplicationService, private addressService: AddressService) {}

  ngOnInit() {
  }

  fieldChange(event) {

    if (!this.firstSubmit)
      return;

    //this.searchKeywordsSubmit.emit({});
  }

  submitSearch() {

    //  memorizzo i parametri della ricerca attuale
    this.tutorApplicationService.lastSearchQueries = this.model;

    this.firstSubmit = true;

    if (this.advancedSearchShow)
      this.model.advancedSearchOn = true;
    else {
      this.model = {advancedSearchOn: false, simpleSearchKeyword: this.model.simpleSearchKeyword};
    }

    this.advancedSearchShow = false;
    this.searchKeywordsSubmit.emit(this.model);
  }

  hideAdvancedSearch() {
    this.advancedSearchShow = false;
  }

  clearAdvancedSearch() {

    //  resetto il modello e tengo aperto il form di ricerca
    this.model = {advancedSearchOn: false, simpleSearchKeyword: ""};
    this.showAdvancedSearch();
  }

  showAdvancedSearch() {
    this.advancedSearchShow = true;
    this.model.simpleSearchKeyword = "";
  }

  get tutorApplicationFormUrl(){
	  return environment.tutorApplicationFormUrl;
  }
  isAdvancedSearchShow() {
    return this.advancedSearchShow
  }
}
