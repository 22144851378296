import { Input, Output, Component, OnInit, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryPickerService } from './country-picker.service';


@Component({
  selector: 'app-country-picker',
  template: `<select *ngIf="sel != null && sel.length == 3" [disabled]="disabled" class="form-control" [(ngModel)]="sel">
              <option [value]=""></option>
              <option *ngFor="let c of countries" [value]="c.cca3">{{c.name.common}}</option>
            </select>
            <select *ngIf="sel == null || sel.length != 3" [disabled]="disabled" class="form-control" [(ngModel)]="sel">
              <option [value]=""></option>
              <option *ngFor="let c of countries" [value]="c.cca2">{{c.name.common}}</option>
            </select>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryPickerComponent),
      multi: true
    }]
})

export class CountryPickerComponent implements ControlValueAccessor {

  //  Controllo che sono nella prima fase di inizializzazione
  private check: boolean = false;

  @Input() disabled: boolean;

  @Input()
  get sel() {
    //  Questa merda serve per un errore che node da in development mode
    //    la versione attuale con la quale ho sviluppato è la 8.10 ma il bug è tracciato ed è da fixare
    if (this.currentCountry != undefined && this.check == false) {
      this.check = true;
      setTimeout(() => { this.euro.emit(this.checkEuro); }, 10);
    }

    return this.currentCountry;
  };
  set sel(val) {
    this.currentCountry = val;
    this.euro.emit(this.checkEuro);
    this.propagateChange(this.currentCountry);
  }

  @Output() euro = new EventEmitter<boolean>();


  //  Contiene le info della localizzazione attuale
  private locale: string = navigator.language.split('-')[0];

  //  Contiene tutti i paesi con le loro info
  public countries: any[];

  //  Contiene la stringa del paese attualmente sotto controllo
  private currentCountry: string;



  constructor(private countryPickerService: CountryPickerService) {

    this.countryPickerService.getCountries(countries => {

      this.countries = countries;

      this.translateCountries();

      this.sortCountries();

    });
  }


  writeValue(value: any) {
    if (value !== undefined)
      this.currentCountry = value;
  }
  propagateChange = (_: any) => { };
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() { }



  // Traduco i paesi in base alla stringa locale
  private translateCountries() {
    for (let c of this.countries) {
      for (let ct of Object.keys(c.translations)) {
        if (ct.indexOf(this.locale) >= 0) {
          c.name.common = c.translations.ita.common;
          break;
        }
      }
    }
  }


  // Ordino i paesi in ordine alfabetico
  private sortCountries() {
    this.countries.sort((a: any, b: any) => {
      let na = a.name.common;
      let nb = b.name.common;
      if (na > nb)
        return 1;
      if (na < nb)
        return -1;
      return 0;
    });
  }


  get checkEuro() {
    if (this.currentCountry != undefined &&
      this.countries != undefined) {
      for (let country of this.countries) {
        if (country.cca3 == this.currentCountry || country.cca2 == this.currentCountry) {
          if (country.region == "Europe")
            return true;
          else
            return false;
        }
      }
    }
  }

}
