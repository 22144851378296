
<!-- BEGIN: sezione dedicata alle info sui risultati delle ricerche -->
<div class="row">
  <div class="col-md-12">
    <div class="portlet light bordered">
      <div class="portlet-title" [appCheckPermission]="'course_schedule_edit'">
        <button class="btn btn-block btn-primary" (click)="newRoom()" ><i class="fa fa-plus"></i> New Room</button>
      </div>

      <div class="datatable-loader text-center" *ngIf="isOngoingRequest()">
        <span></span><span></span><span></span><span></span><span></span>
        <strong class="text-center">Loading</strong>
      </div>

      <div class="portlet-body">
          <ngx-datatable *ngIf="!isOngoingRequest()"
          class="striped"
          [columnMode]="'flex'"
          [rows]="courseScheduleRoomList"
          [rowHeight]="'auto'"
          [headerHeight]="'auto'"
          [footerHeight]="0"
          [selectionType]="'single'"
          (select)='onRoomSelect($event)'
          >
          <ngx-datatable-column name="Room Name" [flexGrow]="4" prop="room_name" [resizeable]="false" [draggable]="false"></ngx-datatable-column>
          <ngx-datatable-column name="Description" [flexGrow]="8" prop="description" [resizeable]="false" [draggable]="false"></ngx-datatable-column>
          <ngx-datatable-column name="Room Type" [flexGrow]="4" prop="type" [resizeable]="false" [draggable]="false">
            <ng-template ngx-datatable-cell-template let-value="value">
              {{getConfigService().getCourseScheduleTyoeValueByKey(value)}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Guests" [flexGrow]="2" prop="num_of_guests" [resizeable]="false" [draggable]="false"></ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>

<hr>

<!-- BEGIN: sezione con i membri dello staff -->
<div class="row" *ngIf="courseScheduleRoomList.length > 0 && courseStaffRoomList.length > 0">
  <div class="col-md-12">
    <div class="portlet light bordered">
      <div class="portlet-title">
        <div class="caption"><span class="caption-subject font-dark sbold uppercase">Staff Members</span></div>
        <div class="actions">
          <select *ngIf="multiSelectionStaff.enabled" class="form-control portlet-inline" [(ngModel)]="multiSelectionStaff.course_room_id">
            <option [value]="-1"></option>
            <option *ngFor="let r of courseScheduleRoomList" [value]="r.id">{{r.room_name}}</option>
          </select>
          <span><img *ngIf="loading" src="./assets/global/img/input-spinner.gif" /></span>
          <button *ngIf="!multiSelectionStaff.enabled" class="btn btn-primary" [disabled]="loading" (click)="enableMultipleStaffSelection()"><i class="fa fa-edit"></i>&nbsp;Multiple Selection</button>
          <button *ngIf="multiSelectionStaff.enabled" class="btn green" [disabled]="loading" (click)="saveMultipleStaffSelection()"><i class="fa fa-save"></i>&nbsp;Save</button>
          <button *ngIf="multiSelectionStaff.enabled" class="btn btn-danger" [disabled]="loading" (click)="cancelMultipleStaffSelection()"><i class="fa fa-times"></i>&nbsp;Clear Multiple Selection</button>
        </div>
      </div>
      <div class="portlet-body">

        <div class="datatable-loader text-center" *ngIf="isOngoingRequest()">
          <span></span><span></span><span></span><span></span><span></span>
          <strong class="text-center">Loading</strong>
        </div>

        <!-- BEGIN: Tabella con la settiman corrente -->
        <ngx-datatable *ngIf="!isOngoingRequest()"
          class="striped"
          [columnMode]="'flex'"
          [rowHeight]="'auto'"
          [rows]="courseStaffRoomList"
          [headerHeight]="'auto'"
          [footerHeight]="0"
          [selected]="selected"
          [selectionType]="'checkbox'"
          [rowClass]="getRowClass"
          (select)='onSelect($event)'
          [sorts]="[{prop: 'person_info.surname', dir: 'asc'}]"
          >

          <ngx-datatable-column *ngIf="multiSelectionStaff.enabled"
            [width]="30"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            [headerCheckboxable]="false"
            [checkboxable]="true">
          </ngx-datatable-column>
          <ngx-datatable-column name="Nome" [flexGrow]="4" prop="person_info.surname" [sortable]="true" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{value}} {{row.person_info.first_name}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Età" [flexGrow]="1" prop="person_info.birthday" [sortable]="true" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{getHelper().calculateAge(row.person_info.birthday)}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Sesso" [flexGrow]="2" prop="person_info.gender" [sortable]="true" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{getConfigService().getGenderPersonValueByKey(row.person_info.gender)}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Room" [flexGrow]="3" prop="room.name" [sortable]="true" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span *ngIf="(currentStaffModify?.staff_member?.person_id == row.person_id); else elsegroupstaff">
                <select class="form-control" [(ngModel)]="row.room.course_room_id" (change)="changeRoomStaff(row)">
                  <option [value]="-1"></option>
                  <option *ngFor="let r of courseScheduleRoomList" [value]="r.id">{{r.room_name}}</option>
                </select>
              </span>
              <ng-template #elsegroupstaff>
                <span *ngIf="value">
                  {{value}}
                </span>
              </ng-template>
            </ng-template>
            </ngx-datatable-column>
          <ngx-datatable-column name="" [flexGrow]="2" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span *ngIf="currentStaffModify?.staff_member?.person_id != row.person_id; else elsebuttonstaff">
                <button [appCheckPermission]="'course_schedule_edit'" class="btn btn-xs btn-outline btn-primary" [disabled]="loading" (click)="modifyRoomStaff(row)"><i class="fa fa-edit" ></i>&nbsp;Edit</button>
              </span>
              <ng-template #elsebuttonstaff>
                <span><img *ngIf="loading" src="./assets/global/img/input-spinner.gif" /></span>
                <button [appCheckPermission]="'course_schedule_edit'" *ngIf="!loading" class="btn btn-xs btn-outline btn-danger" (click)="closeRoomStaff()"><i class="fa fa-times" ></i>&nbsp;Close</button>
              </ng-template>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

        <!-- END: sezione con gli iscritti -->
      </div>
    </div>

  </div>
</div>
<!-- END: sezione con i membri dello staff -->

<!-- BEGIN: sezione con gli iscritti -->
<div class="row" *ngIf="courseScheduleRoomList.length > 0 && courseSubscriberRoomList.length > 0">

  <div class="col-md-12">
    <div class="portlet light bordered">
      <div class="portlet-title">
        <div class="caption"><span class="caption-subject font-dark sbold uppercase">Subscribers</span></div>
        <div class="actions">
          <select *ngIf="multiSelection.enabled" class="form-control portlet-inline" [(ngModel)]="multiSelection.course_room_id">
            <option [value]="-1"></option>
            <option *ngFor="let r of courseScheduleRoomList" [value]="r.id">{{r.room_name}}</option>
          </select>
          <span><img *ngIf="loading" src="./assets/global/img/input-spinner.gif" /></span>
          <button [appCheckPermission]="'course_schedule_edit'" *ngIf="!multiSelection.enabled" class="btn btn-primary" [disabled]="loading" (click)="enableMultipleSelection()"><i class="fa fa-edit"></i>&nbsp;Multiple Selection</button>
          <button [appCheckPermission]="'course_schedule_edit'" *ngIf="multiSelection.enabled" class="btn green" [disabled]="loading" (click)="saveMultipleSelection()"><i class="fa fa-save"></i>&nbsp;Save</button>
          <button [appCheckPermission]="'course_schedule_edit'" *ngIf="multiSelection.enabled" class="btn btn-danger" [disabled]="loading" (click)="cancelMultipleSelection()"><i class="fa fa-times"></i>&nbsp;Clear Multiple Selection</button>
        </div>
      </div>
      <div class="portlet-body">

        <div class="datatable-loader text-center" *ngIf="isOngoingRequest()">
          <span></span><span></span><span></span><span></span><span></span>
          <strong class="text-center">Loading</strong>
        </div>

        <!-- BEGIN: Tabella con la settiman corrente -->
        <ngx-datatable *ngIf="!isOngoingRequest()"
          class="striped"
          [columnMode]="'flex'"
          [rowHeight]="'auto'"
          [rows]="courseSubscriberRoomList"
          [headerHeight]="'auto'"
          [footerHeight]="0"
          [selected]="selected"
          [selectionType]="'checkbox'"
          [rowClass]="getRowClass"
          (select)='onSelect($event)'
          [sorts]="[{prop: 'person_info.surname', dir: 'asc'}]"
          >

          <ngx-datatable-column *ngIf="multiSelection.enabled"
            [width]="30"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            [headerCheckboxable]="false"
            [checkboxable]="true">
          </ngx-datatable-column>
          <ngx-datatable-column name="Nome" [flexGrow]="4" prop="person_info.surname" [sortable]="true" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{value}} {{row.person_info.first_name}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Età" [flexGrow]="1" prop="person_info.birthday" [sortable]="true" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{getHelper().calculateAge(row.person_info.birthday)}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Sesso" [flexGrow]="2" prop="person_info.gender" [sortable]="true" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{getConfigService().getGenderPersonValueByKey(row.person_info.gender)}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Room" [flexGrow]="3" prop="room.name" [sortable]="true" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span *ngIf="(currentModify?.subscriber?.person_id == row.person_id); else elsegroup">
                <select class="form-control" [(ngModel)]="row.room.course_room_id" (change)="changeRoom(row)">
                  <option [value]="-1"></option>
                  <option *ngFor="let r of courseScheduleRoomList" [value]="r.id">{{r.room_name}}</option>
                </select>
              </span>
              <ng-template #elsegroup>
                <span *ngIf="value">
                  {{value}}
                </span>
              </ng-template>
            </ng-template>
            </ngx-datatable-column>
          <ngx-datatable-column name="" [flexGrow]="2" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span *ngIf="currentModify?.subscriber?.person_id != row.person_id; else elsebutton">
                <button [appCheckPermission]="'course_schedule_edit'" class="btn btn-xs btn-outline btn-primary" [disabled]="loading" (click)="modifyRoom(row)"><i class="fa fa-edit" ></i>&nbsp;Edit</button>
              </span>
              <ng-template #elsebutton>
                <span><img *ngIf="loading" src="./assets/global/img/input-spinner.gif" /></span>
                <button [appCheckPermission]="'course_schedule_edit'" *ngIf="!loading" class="btn btn-xs btn-outline btn-danger" (click)="closeRoom()"><i class="fa fa-times" ></i>&nbsp;Close</button>
              </ng-template>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

        <!-- END: sezione con gli iscritti -->
      </div>
    </div>
    <div class="row">
      <div class="pull-right">
        <button [appCheckPermission]="'course_schedule_export'" *ngIf="!loading && courseSubscriberRoomList.length > 0" type="button" class="btn green" (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button>
      </div>
    </div>
  </div>
</div>



<!-- BEGIN: MODAL ROOM -->
<div *ngIf="isModalRoom" [config]="{ show: true }" (onHidden)="onHiddenRoom()" bsModal #roomModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Nuova Camera</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalRoom()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="#" role="form" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
          <div class="form-body">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || room_name.dirty || room_name.touched) && !room_name.valid }">
                  <label class="control-label">Nome della Camera <span class="required">*</span></label>
                  <input class="form-control" type="text" name="room_name" [(ngModel)]="model_room.room_name" #room_name="ngModel" nospace placeholder="Room Name">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || room_type.dirty || room_type.touched) && !room_type.valid }">
                  <label class="control-label">Tipologia di Camera</label>
                  <select class="form-control" name="room_type" [(ngModel)]="model_room.type" #room_type="ngModel">
                    <option *ngFor="let sS of getConfigService().courseScheduleRoomTypeSelect" [value]="sS.key">{{sS.value}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">Descrizione della Camera</label>
                  <textarea class="form-control" rows="3" name="description" [(ngModel)]="model_room.description" placeholder="Room Description"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="form-actions right">
            <img *ngIf="isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
            <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroyRoom();">Yes Delete!</button></ng-template>
            <button type="button" class="btn btn-outline red" *ngIf="model_room.id" [disabled]="isOngoingRequest()"
                [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
            <button type="submit" [disabled]="isOngoingRequest()" class="btn blue" *ngIf="model_room.id">Update</button>
            <button type="submit" [disabled]="isOngoingRequest()" class="btn green-jungle" *ngIf="!model_room.id">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL ROOM -->

<!-- BEGIN: MODAL LOADING EXPORT-->
<div [config]="{ show: false, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal #staticModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
