<div class="portlet light bordered">

  <div class="portlet-title">
    <button [disabled]="getGroupService().isOngoingRequest()" type="button" class="btn green btn-block btn-outline" (click)="addNewShipment();"><i class="fa fa-plus"></i>&nbsp; Add Shipment</button>
  </div>

  <div class="datatable-loader text-center" *ngIf="getGroupService().isOngoingRequest()">
    <span></span><span></span><span></span><span></span><span></span>
    <strong class="text-center">Loading</strong>
  </div>

  <div class="portlet-body">

    <ngx-datatable *ngIf="!getGroupService().isOngoingRequest()"
    class="striped"
        [rows]="rows"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="40"
        [selectionType]="'single'"
        (select)='onSelect($event)'
    >
      <ngx-datatable-column name="Data di creazione" [flexGrow]="3" prop="created_at" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
      <ngx-datatable-column name="Order Closed" [flexGrow]="1" prop="closed_order" [sortable]="false" [resizeable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span *ngIf="row.closed_order == true"><i class="fa fa-check-circle"></i></span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Shipped" [flexGrow]="1" prop="shipment_code" [sortable]="false" [resizeable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span *ngIf="row.shipment_code"><i class="fa fa-truck"></i></span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

  </div>
</div>


<!-- BEGIN: MODAL SHIPMENT -->
<div [config]="{ show: false }" bsModal (onHidden)="onHiddenShipmentModal()"  #shipmentModal="bs-modal" class="modal fade"
tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="currentShipmentSelected?.id"> Edit Shipment </h4>
        <h4 class="modal-title pull-left" *ngIf="!currentShipmentSelected?.id"> New Shipment </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalShipment()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- sezione con il form della spedizione attivo -->
        <div class="row">
          <div class="col-md-12">
            <form role="form" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
              <div class="form-body">
                <div class="row">
                </div>

                <div class="row" *ngIf="currentShipmentSelected?.id">
                  <div class="col-md-12">
                    <app-shipment-materials [currentShipment]="currentShipmentSelected"
                    (newMaterialAdded)="showModalMaterial()" (materialSelected)="showMaterial($event)"></app-shipment-materials>
                  </div>    
                </div>
                
              </div>
              <div class="form-actions right">
                <img *ngIf="getGroupService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
                <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroyShipment();">Yes Delete!</button></ng-template>
                <button *ngIf="currentShipmentSelected?.id && !currentShipmentSelected?.closed_order" type="button" class="btn btn-outline red" 
                [disabled]="getGroupService().isOngoingRequest() || currentShipmentSelected?.closed_order"
                    [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
                <!-- <button type="submit" [disabled]="getGroupService().isOngoingRequest() || currentShipmentSelected?.closed_order" class="btn blue" *ngIf="currentShipmentSelected?.id">Update</button> -->
                <button *ngIf="currentShipmentSelected?.id && !currentShipmentSelected?.closed_order" type="button" [disabled]="getGroupService().isOngoingRequest()" (click)="finalizeOrder()" class="btn green"><i class="fa fa-check green"></i>&nbsp;Send Request</button>
                <button *ngIf="!currentShipmentSelected?.id" type="button" class="btn default" (click)="shipmentModal.hide()">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL SHIPMENT -->




<!-- BEGIN: MODAL MATERIAL -->
<div [config]="{ show: false }" bsModal (onHidden)="onHiddenMaterialModal()"  #materialModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="currentMaterialSelected?.product_info?.id"> Edit Material Info </h4>
        <h4 class="modal-title pull-left" *ngIf="!currentMaterialSelected?.product_info?.id"> New Material </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalMaterial()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="currentMaterialSelected">
          <div class="col-md-12">
            <app-shipment-material-form [model]="currentMaterialSelected" (materialSaved)="materialSaved()" (cancelEvent)="cancelEvent()"></app-shipment-material-form>
          </div>    
        </div>
        <div class="row" [hidden]="currentMaterialSelected">
          <div class="col-md-12">
            <app-shipment-material-search-form #materialSearchForm [defaultTags]="'actor'" (searchResult)="searchResult($event)"></app-shipment-material-search-form>
          </div>
        </div>
        <br>
        <div class="row" [hidden]="currentMaterialSelected">
          <div class="col-md-12">
            <app-shipment-material-datatable #materialSearchDatatable (productSelected)="productSelected($event)"></app-shipment-material-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL MATERIAL -->