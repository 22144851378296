
  <div class="portlet light bordered">
    <div class="portlet-title">
      <button [appCheckPermission]="'warehouse_edit'" [disabled]="getWarehouseService().isOngoingRequest() || getShipmentService().isOngoingRequest()" type="button" class="btn green btn-block btn-outline" (click)="addNew();"><i class="fa fa-plus"></i>&nbsp; New Product</button>
    </div>

    <div class="datatable-loader text-center" *ngIf="getWarehouseService().isOngoingRequest() || getShipmentService().isOngoingRequest()">
      <span></span><span></span><span></span><span></span><span></span>
      <strong class="text-center">Loading</strong>
    </div>

    <div class="portlet-body">
      <ngx-datatable *ngIf="!getWarehouseService().isOngoingRequest() || getShipmentService().isOngoingRequest()"
        class="striped"
        [rows]="rows"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [selectionType]="'single'"
        [selected]="selected"
        (select)='onSelect($event)'
      >
        <ngx-datatable-column name="Type" [flexGrow]="2" prop="type" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
        <ngx-datatable-column name="Name" [flexGrow]="6" prop="name" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
        <ngx-datatable-column name="Description" [flexGrow]="8" prop="description" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
        <ngx-datatable-column name="Tag" prop="tags" [flexGrow]="2" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
        <ngx-datatable-column name="Available" [flexGrow]="2" [sortable]="true" [resizeable]="false">
          <ng-template ngx-datatable-cell-template let-row="row">
            <span [ngClass]="{'text-danger': row?.quantity < row?.quantity_requested}">{{row?.quantity}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Requested" [flexGrow]="2" prop="quantity_requested" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
        <!-- <ngx-datatable-column name="" [flexGrow]="1" [sortable]="false" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <button type="button" class="btn btn-sm green-jungle" (click)="AddForProduct()"><i class="fa fa-plus"></i>&nbsp;</button>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="" [flexGrow]="1" [sortable]="false" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <button type="button" class="btn btn-sm red" (click)="RemoveForProduct()"><i class="fa fa-minus"></i>&nbsp;</button>
          </ng-template>
        </ngx-datatable-column> -->
      </ngx-datatable>
    </div>
  </div>





<!-- BEGIN: MODAL PRODUCT -->
<div [config]="{ show: false }" bsModal (onHidden)="onHiddenProductModal()"  #productModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"> Product Info </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalProduct()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body form">
        <br>
        <app-warehouse-product-form *ngIf="isModalProductionShow" [model]="currentProductSelected" [in]="car" [out]="scar" ></app-warehouse-product-form>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL PRODUCT -->
