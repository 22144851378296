import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TheatrinoService } from '../../_services';

@Component({
  selector: 'app-theatrino-search-form',
  templateUrl: './theatrino-search-form.component.html',
  styleUrls: ['./theatrino-search-form.component.css']
})
export class TheatrinoSearchFormComponent implements OnInit {

  model: any = { advancedSearchOn: false, simpleSearchKeyword: "" };

  private firstSubmit: boolean = false;
  private defaultSearchModel = null;

  private subject: any;

  public advancedSearchShow = false;

  @Output() searchKeywordsSubmit = new EventEmitter();

  @Input() ongoingRequest: boolean;

  constructor(private route: ActivatedRoute, private theatrinoService: TheatrinoService) { }

  ngOnInit() {
    
    this.subject = this.route.params.subscribe(params => {});

    this.defaultSearchModel = this.model;

    if (this.theatrinoService.lastSearchQueries != null)
    {
      this.model = this.theatrinoService.lastSearchQueries;
    }

    this.advancedSearchShow = this.model.advancedSearchOn;

  }

  ngOnDestroy () {
    this.subject.unsubscribe();
  }


  fieldChange(event) {

  if (!this.firstSubmit)
    return;

    this.searchKeywordsSubmit.emit({});
  }


  submitSearch() {

    this.firstSubmit = true;

    if (this.advancedSearchShow) {
      this.model.advancedSearchOn = true;
    }
    else {
      this.model = { advancedSearchOn: false, simpleSearchKeyword: this.model.simpleSearchKeyword };
    }

    if (!this.advancedSearchShow)
      this.advancedSearchShow = false;

      //  memorizzo i parametri della ricerca attuale
    this.theatrinoService.lastSearchQueries = this.model;
    
    this.searchKeywordsSubmit.emit(this.model);
  }

  getAllResources(form: any)
  {
    this.model = {};

    if (form != null)
      form._submitted = false;

    this.theatrinoService.lastSearchQueries = null;

    this.searchKeywordsSubmit.emit();
  }

  clearAdvancedSearch() {

    //  resetto il modello e tengo aperto il form di ricerca
    this.model = { advancedSearchOn: false, simpleSearchKeyword: "" };
    this.showAdvancedSearch();
  }

  hideAdvancedSearch() {
    this.advancedSearchShow = false;
  }

  showAdvancedSearch() {
    this.advancedSearchShow = true;
    this.model.simpleSearchKeyword = "";

    if (!this.model.theatrino_active)
      this.model.theatrino_active = true;
  }

}
