import { Component, OnInit, ViewChild } from '@angular/core';
import { HelperService, CampService, ConfigService, UserService } from 'app/_services';
import { NotificationsService } from 'angular2-notifications';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ModalDirective } from 'ngx-bootstrap/modal';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-camp-groups-all-week',
  templateUrl: './camp-groups-all-week.component.html',
  styleUrls: ['./camp-groups-all-week.component.css']
})
export class CampGroupsAllWeekComponent implements OnInit {

  @ViewChild('staticModal', { static: false }) public staticModal: ModalDirective;

  campGroupList: any[] = [];
  campWeeksList: any[] = [];

  subscribeGroup: any;
  subscribeSubscriberList: any;

  selected = [] = [];

  campWeeks: number = 0;

  libri: any;

  constructor(private campService: CampService, private helper: HelperService,
    private configService: ConfigService, private notify: NotificationsService,
    private userService: UserService) { }

  ngOnInit() {

    this.subscribeGroup = this.campService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      this.getGroupsInfo();
    });

    this.subscribeSubscriberList = this.campService.getsubjectToUpdateCampSubscriberListObservable().subscribe((update: boolean) => {

      this.getSubscriberInfo();
    });

    if (this.campService.currentCampInfo) {
      this.campWeeks = this.campService.numOfCampWeeks;
    }

    this.getGroupsInfo();
    this.campService.getCampSubscriber();
  }

  ngOnDestroy() {
    this.subscribeGroup.unsubscribe();
    this.subscribeSubscriberList.unsubscribe();
  }

  getGroupsInfo() {

    if (this.campService.currentCampGroups != null) {

      this.campGroupList = this.campService.currentCampGroups;
    }
  }

  getSubscriberInfo() {

    if (this.campService.currentCampSubscriberList != null) {

      this.prepareWeekSubscribers();
    }
  }

  prepareWeekSubscribers() {
    this.campWeeksList = [];

    //  per ogni settimana del campo setto i0 subscriber che ne sono iscritti
    for (var i = 0; i < this.campService.numOfCampWeeks; i++) {
      var subs = JSON.parse(JSON.stringify(this.campService.currentCampSubscriberList.filter(x => !x.deleted_at).filter(x => x.camp_week.includes(i + 1))));

      //  per ogni iscritto della settimana prendo solo la settimana corrispondente
      subs.forEach(subscriber => {
        var subIdx = subscriber.groups.findIndex(x => x.num_of_week == (i + 1));

        //  se ho trovato una settimana la assegno a questo iscritto
        if (subIdx >= 0) {
          subscriber.group = subscriber.groups[subIdx];
          var group = this.campGroupList.find(x => x.id == subscriber.groups[subIdx].camp_group_id)
          if (typeof group != "undefined")
            subscriber.group.name = group.group_name;
        }

        //  se il gruppo non c'è lo inizializzo comunque
        if (typeof subscriber.group == "undefined") { subscriber.group = {}; subscriber.group.name = ""; }

      })

      //  inserisco la settimana nell'array
      this.campWeeksList.push(subs);

    }

  }

  currentModify: any = {};
  modifyGroup(tableIndex, row) {

    this.currentModify.index = tableIndex;
    this.currentModify.subscriber = JSON.parse(JSON.stringify(row));
  }

  closeGroup() {

    this.currentModify = {};
  }

  loading: boolean = false;
  changeGroup(row, week) {

    //  inserisco un nuovo gruppo per questo iscritto
    if (typeof this.currentModify.subscriber.group.camp_group_id == "undefined" && typeof this.currentModify.subscriber.group.id == "undefined") {
      //  controllo se non è stato selezionato un gruppo valido
      if (row.group.camp_group_id < 0) return;

      let model = { subscriber_id: row.id, camp_group_id: row.group.camp_group_id, num_of_week: (week + 1) };
      this.saveSubscriberGroup(row, model, () => {
        this.notify.success("Subscriber Group", "successfully saved");
      });
      return;
    }

    //  questo iscritto ha già un gruppo
    if (typeof this.currentModify.subscriber.group.camp_group_id != "undefined" && typeof this.currentModify.subscriber.group.id != "undefined") {
      if (row.group.camp_group_id > 0) {
        this.updateSubscriberGroup(row, () => {
          this.notify.success("Subscriber Group", "successfully changed");
        });
        return;
      }

      if (row.group.camp_group_id < 0) {
        this.deleteSubscriberGroup(row, () => {
          this.notify.success("Subscriber Group", "successfully deleted");
        });
        return;
      }
    }
  }

  saveSubscriberGroup(row, model, callbackSuccess: any = null) {
    this.loading = true;
    this.campService.saveCampSubscriberGroup(model, (newGroup) => {
      row.group = newGroup;

      //  controllo che non arrivino infirmazioni errate prima di salvarle
      var gr = this.campGroupList.find(x => x.id == row.group.camp_group_id);
      if (gr == null || typeof gr == "undefined") return;
      if (typeof gr.group_name == "undefined") return;

      row.group.name = gr.group_name;
      this.currentModify = {};
      this.loading = false;
      if (callbackSuccess != null)
        callbackSuccess();
    }, () => { this.loading = false; })
  }
  updateSubscriberGroup(row, callbackSuccess: any = null) {
    this.loading = true;
    this.campService.updateCampSubscriberGroup(row.group, (updateGroup) => {
      row.group = updateGroup;
      row.group.name = this.campGroupList.find(x => x.id == row.group.camp_group_id).group_name;
      this.currentModify = {};
      this.loading = false;
      if (callbackSuccess != null)
        callbackSuccess();
    }, () => { this.loading = false; });
  }
  deleteSubscriberGroup(row, callbackSuccess: any = null) {
    this.loading = true;
    this.campService.deleteCampSubscriberGroup(row.group, () => {
      row.group = {}; row.group.name = "";
      this.currentModify = {};
      this.loading = false;
      if (callbackSuccess != null)
        callbackSuccess();
    }, () => { this.loading = false; });
  }



  multiSelection: any = { week: 0 };

  enableMultipleSelection(week) {
    this.multiSelection.week = week + 1;
  }
  cancelMultipleSelection() {
    this.selected = [];
    this.multiSelection.week = 0;
    this.multiSelection.camp_group_id = -1;
  }
  saveMultipleSelection() {

    this.currentMultisave = 0;

    if (this.selected.length == 0)
      this.notify.warn("Subscriber Group", "No subscribers selected");

    this.selected.forEach((subscriber: any) => {

      //  controllo se non è stato selezionato un gruppo valido
      if (this.multiSelection.camp_group_id == "undefined") return;

      if (typeof subscriber.group.id == "undefined") {
        let model = { subscriber_id: subscriber.id, camp_group_id: this.multiSelection.camp_group_id, num_of_week: this.multiSelection.week };
        this.saveSubscriberGroup(subscriber, model, () => { this.currentMultisave++; this.checkMultipleSave() });
        return;
      }

      //  questo iscritto ha già un gruppo
      if (typeof subscriber.group.id != "undefined") {
        subscriber.group.camp_group_id = this.multiSelection.camp_group_id;

        if (subscriber.group.camp_group_id > 0) {
          this.updateSubscriberGroup(subscriber, () => { this.currentMultisave++; this.checkMultipleSave() });
          return;
        }

        if (subscriber.group.camp_group_id < 0) {
          this.deleteSubscriberGroup(subscriber, () => { this.currentMultisave++; this.checkMultipleSave() });
          return;
        }
      }

    })
  }
  currentMultisave = 0;
  checkMultipleSave() {
    if (this.currentMultisave >= this.selected.length) {
      this.notify.success("Subscriber Group", this.currentMultisave + " successfully changed");
      this.cancelMultipleSelection();
    }
  }


  classSort(valueA, valueB, rowA, rowB) {

    if ((rowA.school_type - rowB.school_type || rowA.class_type - rowB.class_type) <
      (rowB.school_type - rowA.school_type || rowB.class_type - rowA.class_type)) return -1;

    if ((rowA.school_type - rowB.school_type || rowA.class_type - rowB.class_type) >
      (rowB.school_type - rowA.school_type || rowB.class_type - rowA.class_type)) return 1;
  }

  onSelect({ selected }) {
    // console.log(this.selected);
  }



  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
    this.staticModal.show();
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }

  /*  Salvo i dati nel formato Excel
  */
  exportToExcel(week: any) {
    this.showModalExport();

    this.exportParse(week, () => {
      this.hideModalExport();
    });

  }


  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse(data: any, callback: any) {

    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach((element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      let obj = {
        'Nome': element.person_info.first_name,
        'Cognome': element.person_info.surname,
        'Eta': this.helper.calculateAge(element.person_info.birthday),
        'Sesso': this.configService.getGenderPersonValueByKey(element.person_info.gender),
        'Taglia': this.configService.getShirtSizePersonValueByKey(element.person_info.tshirt_size),
        'Scuola': this.configService.getDiaryCampSubscriberSchoolTypeValueByKey(element.school_type),
        'Classe': this.configService.getDiaryCampSubscriberSchoolTypeSubValueByKey(element.school_type, element.class_type),
        'Gruppo': (typeof element.group != "undefined" ? element.group.name : '')
      };

      //  preparo
      exportedArray.push(obj)
    })

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{ width: 18 }, { width: 20 }, { width: 10 }, { width: 12 }, { width: 14 }, { width: 30 }, { width: 10 }, { width: 30 }];

    //  personalizzo l'header
    worksheet['!rows'] = [{ hpx: 30 }];

    //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'Subscribers': worksheet }, SheetNames: ['Subscribers'] };

    //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });

    //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], { type: EXCEL_TYPE }), "subscribers camp groups.xlsx");

    callback();

  }



  getRowClass(row) {

    if (row.person_info) {
      return { 'camp-staff-deleted': row.person_info.deleted_at !== null };
    }

    return "";
  }

  isOngoingRequest() {
    return this.campService.isOngoingRequest()
  }

  getCampService() {
    return this.campService;
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

  getUserService() {
    return this.userService;
  }


}
