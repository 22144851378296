<h3 class="">Search</h3>
<hr>
<form *ngIf="!advancedSearchShow" action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm"
  novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="input-group input-group-lg input-icon right" [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }">
        <i class="fa fa-times-circle" aria-hidden="true" (click)="model.simpleSearchKeyword = '';"></i>
        <input type="text" name="simpleSearchKeywordText" #simpleSearchKeywordText="ngModel" [(ngModel)]="model.simpleSearchKeyword"
          (keydown)="fieldChange($event);" required minlength="3" class="form-control" placeholder="Search for Enterprise name...">
        <span class="input-group-btn">
          <button class="btn green" [disabled]="ongoingRequest" type="submit">Search</button>
        </span>
      </div>
      <small [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }">
        <span class="help-block">Type a word to search in name, min length 3 chars</span>
      </small>
    </div>
    <div class="col-md-6">
      <button type="button" [disabled]="ongoingRequest" (click)="showAdvancedSearch()" class="btn default btn-lg">Advanced
        Search
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
</form>


<form *ngIf="advancedSearchShow" action="#" name="form2" (ngSubmit)="f2.form.valid && submitSearch()" #f2="ngForm"
  novalidate>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Denomination</label>
        <input type="text" name="denomination" [(ngModel)]="model.denomination" (keydown)="fieldChange($event);" class="form-control">
        <span class="help-block"> Search in Denomination </span>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Tax ID</label>
        <input type="text" name="tax_id" [(ngModel)]="model.tax_id" (keydown)="fieldChange($event);" class="form-control">
        <span class="help-block">Search in Tax ID</span>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Fiscal Code</label>
        <input type="text" name="fiscal_code" [(ngModel)]="model.fiscal_code" (keydown)="fieldChange($event);" class="form-control">
        <span class="help-block">Search in Fiscal Code</span>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Type legal personality</label>
        <select class="form-control" name="legal_personality" [(ngModel)]="model.legal_personality" (change)="fieldChange($event);">
          <option *ngFor="let t of getConfigService().enterpriseTypeLegalSelect" [value]="t.key">{{t.value}}</option>
        </select>
        <span class="help-block"> Search by type legal personality</span>
      </div>
    </div>
    <div class="col-md-3" hidden>
      <div class="form-group">
        <label class="control-label">Person</label>
        <input type="text" name="person_name" [(ngModel)]="model.person_name" (keydown)="fieldChange($event);" class="form-control">
        <span class="help-block">Search for a person connected with</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Bank Account</label>
        <input type="text" class="form-control" name="bank_account" (keydown)="fieldChange($event);" placeholder=""
          [(ngModel)]="model.bank_account">
        <span class="help-block">Bank Name, Holder and IBAN/Email</span>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Contact</label>
        <input type="text" class="form-control" (keydown)="fieldChange($event);" placeholder="" name="contact"
          [(ngModel)]="model.contact">
        <span class="help-block">Email/Phone/Website</span>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Type</label>
        <select class="form-control" name="type" [(ngModel)]="model.type" (change)="fieldChange($event);">
          <option></option>
          <option *ngFor="let t of getConfigService().enterpriseTypeSelect" [value]="t.key">{{t.value}}</option>
        </select>
        <span class="help-block"> Search by type</span>
      </div>
    </div>
    <div class="col-md-3">
      <label class="control-label">Sub Type</label>
      <select class="form-control" name="type_sub" [(ngModel)]="model.type_sub" (change)="fieldChange($event);"
        [disabled]="!model.type || getConfigService().enterpriseTypeSubSelect[model.type].length <= 0">
        <option *ngFor="let st of getConfigService().enterpriseTypeSubSelect[this.model.type]" [value]="st.key">{{st.value}}</option>
      </select>
      <span class="help-block"> Search by sub type</span>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Località</label>
        <input type="text" class="form-control" placeholder="" name="locality" [(ngModel)]="model.locality" (keydown)="fieldChange($event);">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Nazione</label>
        <input type="text" class="form-control" placeholder="" name="country" [(ngModel)]="model.country" (keydown)="fieldChange($event);">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Provincia</label>
        <input type="text" class="form-control" placeholder="" name="provincia" [(ngModel)]="model.provincia" (keydown)="fieldChange($event);"
          [typeahead]="provinceArray" [typeaheadMinLength]="0" [typeaheadWaitMs]="0" [typeaheadScrollable]="true"
          autocomplete="off">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Regione</label>
        <input type="text" class="form-control" placeholder="" name="regione" [(ngModel)]="model.regione" (keydown)="fieldChange($event);"
          [typeahead]="regioniArray" [typeaheadMinLength]="0" [typeaheadWaitMs]="0" [typeaheadScrollable]="true"
          autocomplete="nope">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label class="control-label">Accomodation Features & Facility</label>
        <div class="mt-checkbox-inline">
          <label class="mt-checkbox mt-checkbox-outline"> Single rooms
            <input type="checkbox" value="1" name="single_rooms" [(ngModel)]="model.single_rooms">
            <span></span>
          </label>
          <label class="mt-checkbox mt-checkbox-outline"> Double rooms
            <input type="checkbox" value="1" name="double_rooms" [(ngModel)]="model.double_rooms">
            <span></span>
          </label>
          <label class="mt-checkbox mt-checkbox-outline"> Triple rooms
            <input type="checkbox" value="1" name="triple_rooms" [(ngModel)]="model.triple_rooms">
            <span></span>
          </label>
          <label class="mt-checkbox mt-checkbox-outline"> Quadruple rooms
            <input type="checkbox" value="1" name="quadruple_rooms" [(ngModel)]="model.quadruple_rooms">
            <span></span>
          </label>
          <label class="mt-checkbox mt-checkbox-outline"> Breakfast
            <input type="checkbox" value="1" name="breakfast" [(ngModel)]="model.breakfast">
            <span></span>
          </label>
          <label class="mt-checkbox mt-checkbox-outline"> Internet
            <input type="checkbox" value="1" name="internet" [(ngModel)]="model.internet">
            <span></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Accomodation Rating <b>{{model.rating}}</b></label>
        <p>
          <rating [(ngModel)]="model.rating" [ngModelOptions]="{standalone: true}" max="5" [readonly]="false" style="font-size: 200%;"></rating>
        </p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Accomodation Parking</label>
        <select class="form-control" name="parking" [(ngModel)]="model.parking">
          <option *ngFor="let p of getConfigService().accomodationParkingSelect" [value]="p.key">{{p.value}}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="form-actions right">
    <button type="button" class="btn" (click)="hideAdvancedSearch()">Close</button>
    <button type="button" class="btn default" (click)="clearAdvancedSearch()">Clear
      <i class="fa fa-eraser" aria-hidden="true"></i>
    </button>
    <button type="submit" [disabled]="ongoingRequest" class="btn green">Search
      <i class="fa fa-search"></i>
    </button>
  </div>
</form>
