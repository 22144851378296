import { Component, OnInit } from '@angular/core';
import { environment } from '../environment';
import { User } from '../_models/index';
import { UserService } from '../_services/index';

@Component({
  moduleId: module.id,
  templateUrl: 'home.component.html'
})

export class HomeComponent implements OnInit {
  currentUser: User;
  lastVersion = environment.version;

  constructor(private userService: UserService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {

  }

  isActor() {
    return this.userService.isActor()
  }
  isDocente() {
    return this.userService.isDocente()
  }
  isCampStaff() {
    return this.userService.isCampStaff()
  }
  isOfficeStaff() {
    return this.userService.isOfficeStaff()
  }
  isTutor() {
    return this.userService.isTutor()
  }
}
