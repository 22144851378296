<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()"
  #f="ngForm" novalidate>

  <div class="form-body">
    <!-- <h3 class="form-section">Camp Info</h3> -->

    <!-- Convenzione -->
    <div class="row" *ngIf="model?.agreement_id">
      <div class="col-md-12">
        <div class="form-group">
            <button type="button" class="btn green btn-block btn-outline" (click)="GoToAgreement()"><i class="fa fa-external-link"></i>&nbsp; Existing Agreeement</button>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="editMode">
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid && !type.disabled }">
          <label class="control-label">Camp Type
            <span class="required">*</span>
          </label>
          <select class="form-control" name="type" #type="ngModel" [(ngModel)]="model.type" required>
            <option *ngFor="let t of getConfigService().campTypeSelect" [value]="t.key">{{t.value}}</option>
          </select>
          <span class="help-block"> Camp type is required </span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDataStart)) }">
          <label class="control-label"><i class="fa fa-calendar" aria-hidden="true"></i> Start Date
            <span class="required">*</span></label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_start_date="angular-mydatepicker" name="start_date" class="form-control"
                [locale]="getHelper().locale"
                [options]="datePickerOptionsBegin"
                [ngModel]="localDataStart"
                (dateChanged)="onDateStartChanged($event)"
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.start" class="btn default" (click)="dp_start_date.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_start_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block"> Start Date is required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDataEnd)) }">
          <label class="control-label"><i class="fa fa-calendar" aria-hidden="true"></i> End Date
            <span class="required">*</span></label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_end_date="angular-mydatepicker" name="end_date" class="form-control"
                [locale]="getHelper().locale"
                [options]="datePickerOptionsEnd"
                [ngModel]="localDataEnd"
                (dateChanged)="onDateEndChanged($event)"
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.end" class="btn default" (click)="dp_end_date.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_end_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block"> End Date is required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Camp Code</label>
          <input type="text" name="camp_code" [(ngModel)]="model.camp_code" #camp_code="ngModel" class="form-control" placeholder="" (keyup)="campCodeClicked()">
          <span class="help-block">this is the unique code for camps</span>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label"><i class="fa fa-calendar" aria-hidden="true"></i> Start Date</label>
          <p> {{ model.start| date:'d MMMM, y':'':'it'}} </p>
        </div>
      </div>
      <div class="col-md-3">
        <label class="control-label"><i class="fa fa-calendar" aria-hidden="true"></i> End Date</label>
        <p>{{ model.end | date:'d MMMM, y':'':'it' }}</p>
      </div>
      <div class="col-md-6 read-only form-group editMode" >
        <label class="control-label">Camp Code</label>
        <p *ngIf="model.camp_code"> {{model.camp_code}} </p>
        <p *ngIf="!model.camp_code"> - </p>
      </div>
    </div>

    <div class="row" *ngIf="editMode">
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || status.dirty || status.touched) && !status.valid && !status.disabled }">
          <label class="control-label">Camp Status
            <span class="required">*</span>
          </label>
          <select class="form-control" name="status" #status="ngModel" [(ngModel)]="model.status" required>
            <option *ngFor="let s of getConfigService().campStatusSelect" [value]="s.key">{{s.value}}</option>
          </select>
          <span class="help-block"> Camp Status is required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || company.dirty || company.touched) && !company.valid && !company.disabled }">
          <label class="control-label">Company
            <span class="required">*</span>
          </label>
          <select class="form-control" name="company" #company="ngModel" [(ngModel)]="model.company" required>
            <option *ngFor="let c of getConfigService().groupCompaniesSelect" [value]="c.key">{{c.value}}</option>
          </select>
          <span class="help-block"> Company is required</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || area.dirty || area.touched) && !area.valid && !area.disabled }">
          <label class="control-label">Area
            <span class="required">*</span>
          </label>
          <select class="form-control" name="area" #area="ngModel" [(ngModel)]="model.area" required>
            <option *ngFor="let z of getConfigService().areaExpertiseSelect" [value]="z.key">{{z.value}}</option>
          </select>
          <span class="help-block"> Area is required</span>
        </div>
      </div>
      <div class="col-md-3" *ngIf="editMode">
        <label class="control-label"><i class="fa fa-clock-o" aria-hidden="true"></i> Lessons Plan</label>
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || start_lesson.dirty || start_lesson.touched) && (!start_lesson.valid || !timeStartvalid) }">
          <input type="text" name="start_lesson" [(ngModel)]="model.start_lesson" #start_lesson="ngModel" class="form-control lesson-time-start" (input)="onTimeStartInput($event)"
            [typeahead]="getConfigService().timeCampPlanSelect" [typeaheadScrollable]="true" (typeaheadOnSelect)="typeTimeStartOnSelect($event)"
            typeaheadOptionField="value" placeholder="08:30" autocomplete="no">
            <input type="text" name="end_lesson" [(ngModel)]="model.end_lesson" #end_lesson="ngModel" class="form-control lesson-time-end" (input)="onTimeEndInput($event)"
            [typeahead]="getConfigService().timeCampPlanSelect" [typeaheadScrollable]="true" (typeaheadOnSelect)="typeTimeEndOnSelect($event)"
            typeaheadOptionField="value" placeholder="11:00" autocomplete="no">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-3">
        <label class="control-label">Camp Status</label>
        <p> {{ getConfigService().getCampStatusValueByKey(model.status) }} </p>
      </div>
      <div class="col-md-3">
        <label class="control-label">Company</label>
        <p> {{ getConfigService().getGroupCompanyValueByKey(model.company) }} </p>
      </div>
      <div class="col-md-3">
        <label class="control-label">Area</label>
        <p> {{ getConfigService().getAreaExpertiseValueByKey(model.area) }} </p>
      </div>
      <div class="col-md-3">
        <label class="control-label"><i class="fa fa-clock-o" aria-hidden="true"></i> Lesson Plan</label>
        <p *ngIf="model.start_lesson && model.end_lesson"> {{ model.start_lesson }} - {{ model.end_lesson }} </p>
        <p *ngIf="!model.start_lesson || !model.end_lesson">-</p>
      </div>
    </div>

    <div class="row" *ngIf="!editMode">
      <div class="col-md-12">
        <div class="portlet">
          <div class="portlet-title">
            <div class="caption">
              <i class="fa fa-globe"></i>Online Info
            </div>
          </div>
          <div class="portlet-body">
            <div class="row">
              <div class="col-md-4">
                <label class="control-label">Camp Buyable online</label>
                <p style="margin-top:15px;">
                  <i *ngIf="model.buyable == true" class="fa fa-toggle-on fa-2x" style="color:green"></i>
                  <i *ngIf="model.buyable == false" class="fa fa-toggle-off fa-2x" style="color:red"></i>
                </p>
              </div>
              <div class="col-md-4">
                <div class="form-group" *ngIf="model.lock_week.length > 0">
                  <label class="control-label">Buyable weeks locked</label>
                  <p>
                    {{model.lock_week}}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" *ngIf="model.not_available_week.length > 0">
                  <label class="control-label">Buyable weeks not available</label>
                  <p>
                    {{model.not_available_week}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="editMode">
      <div class="col-md-12">
        <div class="portlet box blue">
          <div class="portlet-title">
            <div class="caption">
              <i class="fa fa-globe"></i>Online Info
            </div>
          </div>
          <div class="portlet-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" *ngIf="onlineErrorArr.length == 0">
                  <label class="control-label">Buyable Online</label>
                  <p style="margin-top:15px;">
                    <a *ngIf="checkCampCode() && checkAddressVenue() && checkNumOfWeeks() && (model.status == 1 || model.status == 3 || model.status == 5 || model.status == 6)" name="buyable" (click)="toggleClicked();">
                      <i class="fa fa-toggle-on fa-4x" *ngIf="model.buyable == true" style="color:green"></i>
                      <i class="fa fa-toggle-off fa-4x" *ngIf="model.buyable == false" style="color:red"></i>
                    </a>
                  </p>
                </div>
                <div *ngIf="onlineErrorArr.length > 0 || onlineWarnArr.length > 0">
                  <div class="form-group">
                    <label class="control-label">&nbsp;</label>
                    <p><i class="fa fa-exclamation-triangle"></i>
                      Attenzione!!! Il camp non sarà acquistabile Online a causa dei seguenti motivi:
                    </p>
                    <ul>
                      <li *ngFor="let error of onlineErrorArr">{{error}}</li>
                      <li *ngFor="let warn of onlineWarnArr">{{warn}}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4 form-group" *ngIf="onlineErrorArr.length == 0 && onlineWarnArr.length == 0">
                <label class="control-label">Buyable Weeks required</label>
                <div class="mt-checkbox-list">
                  <div *ngIf="campWeeks >= 1" class="mt-checkbox-inline">
                    <label class="mt-checkbox mt-checkbox-outline">
                      <input type="checkbox" name="lock_week_01" #lock_week_01 [(ngModel)]="model.lock_week_01" (click)="lockWeekChanged(1, lock_week_01.checked)">Prima settimana
                      <span></span>
                    </label>
                  </div>
                  <div *ngIf="campWeeks >= 2" class="mt-checkbox-inline">
                    <label class="mt-checkbox mt-checkbox-outline">
                      <input type="checkbox" name="lock_week_02" #lock_week_02 [(ngModel)]="model.lock_week_02" (click)="lockWeekChanged(2, lock_week_02.checked)">Seconda settimana
                      <span></span>
                    </label>
                  </div>
                  <div *ngIf="campWeeks >= 3" class="mt-checkbox-inline">
                    <label class="mt-checkbox mt-checkbox-outline">
                      <input type="checkbox" name="lock_week_03" #lock_week_03 [(ngModel)]="model.lock_week_03" (click)="lockWeekChanged(3, lock_week_03.checked)">Terza settimana
                      <span></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-4 form-group" *ngIf="onlineErrorArr.length == 0 && onlineWarnArr.length == 0">
                <label class="control-label">Buyable Weeks not available</label>
                <div class="mt-checkbox-list">
                  <div *ngIf="campWeeks >= 1" class="mt-checkbox-inline">
                    <label class="mt-checkbox mt-checkbox-outline">
                      <input type="checkbox" name="not_available_week_01" #not_available_week_01 [(ngModel)]="model.not_available_week_01" (click)="notAvailabeWeekChanged(1, not_available_week_01.checked)">Prima settimana
                      <span></span>
                    </label>
                  </div>
                  <div *ngIf="campWeeks >= 2" class="mt-checkbox-inline">
                    <label class="mt-checkbox mt-checkbox-outline">
                      <input type="checkbox" name="not_available_week_02" #not_available_week_02 [(ngModel)]="model.not_available_week_02" (click)="notAvailabeWeekChanged(2, not_available_week_02.checked)">Seconda settimana
                      <span></span>
                    </label>
                  </div>
                  <div *ngIf="campWeeks >= 3" class="mt-checkbox-inline">
                    <label class="mt-checkbox mt-checkbox-outline">
                      <input type="checkbox" name="not_available_week_03" #not_available_week_03 [(ngModel)]="model.not_available_week_03" (click)="notAvailabeWeekChanged(3, not_available_week_03.checked)">Terza settimana
                      <span></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" [hidden]="!editMode">
      <div class="col-md-6">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase"><i class="fa fa-map-marker" aria-hidden="true"></i> Venue</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" (click)="showModal('venue');">
                  <i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDeleteVenue>
                  <button type="button" class="btn btn-outline red" (click)="removeAutority('venue')">Yes Remove!</button>
                </ng-template>
                <button *ngIf="this.model.venue_id" type="button" class="btn btn-circle red btn-outline" [outsideClick]="true" [popover]="confirmDeleteVenue"
                  popoverTitle="Are you sure?">
                  <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-enterprise-info-show #enterpriseInfoShowVenue [showAddress]="true" [idEnterprise]="model.venue_id"></app-enterprise-info-show>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-6 read-only form-group editMode">
        <label class="control-label"><i class="fa fa-map-marker" aria-hidden="true"></i> Venue</label>
        <p *ngIf="model.venue_id">
          <app-enterprise-info-show [showAddress]="true" [idEnterprise]="model.venue_id"></app-enterprise-info-show>
        </p>
        <p *ngIf="!model.venue_id">-</p>
      </div>
    </div>

    <div class="row" *ngIf="editMode">
      <div class="col-md-6">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase"><i class="fa fa-map-marker" aria-hidden="true"></i> Accomodation Venue</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" (click)="showModal('accomodation_venue');">
                  <i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDeleteAccomodationVenue>
                  <button type="button" class="btn btn-outline red" (click)="removeAutority('accomodation_venue')">Yes Remove!</button>
                </ng-template>
                <button *ngIf="this.model.accomodation_venue_id" type="button" class="btn btn-circle red btn-outline" [outsideClick]="true"
                  [popover]="confirmDeleteAccomodationVenue" popoverTitle="Are you sure?">
                  <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-enterprise-info-show #enterpriseInfoShowVenue [idEnterprise]="model.accomodation_venue_id"></app-enterprise-info-show>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase">Promoted By</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" (click)="showModal('promoted');">
                  <i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDeletePromoted>
                  <button type="button" class="btn btn-outline red" (click)="removeAutority('promoted')">Yes Remove!</button>
                </ng-template>
                <button *ngIf="this.model.promoted_id" type="button" class="btn btn-circle red btn-outline" [outsideClick]="true" [popover]="confirmDeletePromoted"
                  popoverTitle="Are you sure?">
                  <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-enterprise-info-show [idEnterprise]="model.promoted_id"></app-enterprise-info-show>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-6 read-only form-group editMode">
        <label class="control-label"><i class="fa fa-map-marker" aria-hidden="true"></i> Accomodation Venue</label>
        <p *ngIf="model.accomodation_venue_id">
          <app-enterprise-info-show [idEnterprise]="model.accomodation_venue_id"></app-enterprise-info-show>
        </p>
        <p *ngIf="!model.accomodation_venue_id">-</p>
      </div>
      <div class="col-md-6 read-only form-group editMode">
        <label class="control-label">Promoted By</label>
        <p *ngIf="model.promoted_id">
          <app-enterprise-info-show [idEnterprise]="model.promoted_id"></app-enterprise-info-show>
        </p>
        <p *ngIf="!model.promoted_id">-</p>
      </div>
    </div>

    <div class="row" *ngIf="editMode">
      <div class="col-md-6">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase">Organized By</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" (click)="showModal('organized');">
                  <i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDeleteOrganized>
                  <button type="button" class="btn btn-outline red" (click)="removeAutority('organized')">Yes Remove!</button>
                </ng-template>
                <button *ngIf="this.model.organizer_id" type="button" class="btn btn-circle red btn-outline" [outsideClick]="true" [popover]="confirmDeleteOrganized"
                  popoverTitle="Are you sure?">
                  <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-enterprise-info-show [idEnterprise]="model.organizer_id"></app-enterprise-info-show>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase">Contribution for</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green" (click)="showModal('contribution');">
                  <i class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDeleteContribution>
                  <button type="button" class="btn btn-outline red" (click)="removeAutority('contribution')">Yes Remove!</button>
                </ng-template>
                <button *ngIf="this.model.contribution_id" type="button" class="btn btn-circle red btn-outline" [outsideClick]="true" [popover]="confirmDeleteContribution"
                  popoverTitle="Are you sure?">
                  <i class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-enterprise-info-show [idEnterprise]="model.contribution_id"></app-enterprise-info-show>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-6 read-only form-group editMode">
        <label class="control-label">Organized By</label>
        <p *ngIf="model.organizer_id">
          <app-enterprise-info-show [idEnterprise]="model.organizer_id"></app-enterprise-info-show>
        </p>
        <p *ngIf="!model.organizer_id">-</p>
      </div>
      <div class="col-md-6 read-only form-group editMode">
        <label class="control-label">Contribution for</label>
        <p *ngIf="model.contribution_id">
          <app-enterprise-info-show [idEnterprise]="model.contribution_id"></app-enterprise-info-show>
        </p>
        <p *ngIf="!model.contribution_id">-</p>
      </div>
    </div>

    <div class="row" *ngIf="editModeCampDirector">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Payment Agreements</label>
          <textarea name="payment_agreements" [(ngModel)]="model.payment_agreements" rows="3" class="form-control" placeholder=""></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editModeCampDirector">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Payment Agreements</label>
          <p class="line-breaker textarea-border">{{ model.payment_agreements }}</p>
        </div>
      </div>
    </div>



    <div class="row" *ngIf="editMode">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Cartellina Contabile</label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_accounting_folder="angular-mydatepicker" name="accounting_folder" class="form-control"
                [locale]="getHelper().locale"
                [ngModel]="accounting_folder"
                (dateChanged)="onDateAccountingFolder($event)"
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.accounting_folder" class="btn default" (click)="dp_accounting_folder.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_accounting_folder.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
        </div>
      </div>
      <div *ngIf="model.id" class="col-md-6">
        <label class="control-label">Leaflet</label>
        <div *ngIf="model.leaflet_file_path == undefined || model.leaflet_file_path == ''">
          <input *ngIf="!leafletUploader.isUploading && !inUpload" type="file" id="leafletUploader"
            ng2FileSelect [uploader]="leafletUploader" />
          <label *ngIf="!leafletUploader.isUploading && !inUpload" for="leafletUploader" style="margin: 0px;"
            class="btn green-jungle text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload Leaflet!</label>
        </div>
        <div *ngIf="leafletUploader.isUploading || getCampService().isOngoingRequest() || inUpload">
          <img src="./assets/global/img/input-spinner.gif" />
          please wait...
        </div>
        <div *ngIf="model.leaflet_file_path != undefined && model.leaflet_file_path != ''">
          <button *ngIf="!leafletUploader.isUploading && !inUpload" type="button"
            class="btn btn-danger text-uppercase" (click)="deleteLeaflet();"><i
              class="fa fa-trash"></i>&nbsp; Remove Leaflet</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-6">
        <label class="control-label">Cartellina Contabile</label>
        <p *ngIf="accounting_folder">{{ model.accounting_folder | date:'d MMMM, y':'':'it'}}</p>
        <p *ngIf="!accounting_folder">Nessuna cartellina contabile</p>
      </div>
      <div class="col-md-6">
        <label class="control-label">Leaflet</label>
        <p *ngIf="model.leaflet_file_path">
          <a class="btn btn-primary" href="{{getConfigService().blobBasePath}}{{model.leaflet_file_path}}" target="_self">Download</a>
        </p>
        <p *ngIf="!model.leaflet_file_path">Nessun documento caricato</p>
      </div>
    </div>


    <div class="row" *ngIf="editMode">
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Seniority</label>
          <select class="form-control" name="seniority" [(ngModel)]="model.seniority">
            <option *ngFor="let s of getConfigService().campSenioritySelect" [value]="s.key">{{s.value}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <label class="control-label">Meals</label>
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" placeholder="" name="canteen" [(ngModel)]="model.canteen"> Canteen
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">&nbsp;</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="packed_lunch" [(ngModel)]="model.packed_lunch" value="1"> Packed&nbsp;Lunch
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-3" *ngIf="model.seniority">
        <label class="control-label">Seniority</label>
        <p> {{ getConfigService().getCampSeniorityValueByKey(model.seniority) }} </p>
      </div>
      <div class="col-md-3">
        <label class="control-label">Meals</label>
        <p> Canteen:
          <i *ngIf="model.canteen" class="fa fa-check" aria-hidden="true"></i>
          <i *ngIf="!model.canteen" class="fa fa-times" aria-hidden="true"></i>
        </p>
      </div>
      <div class="col-md-3">
        <label class="control-label">&nbsp;</label>
        <p> Packed lunch:
          <i *ngIf="model.packed_lunch" class="fa fa-check" aria-hidden="true"></i>
          <i *ngIf="!model.packed_lunch" class="fa fa-times" aria-hidden="true"></i>
        </p>
      </div>
    </div>


    <div class="row" *ngIf="audienceModel != null">
      <div class="col-md-12" *ngIf="editMode">
        <div class="form-group">
          <label class="control-label"> Audience</label>
          <br>
          <div class="btn-group">
            <label *ngFor="let c of getConfigService().showAudienceSelect; let i=index" name="{{c.key}} | i" [(ngModel)]="audienceModel[i]"
              class="btn btn-primary" (ngModelChange)="onChangeAudience($event)" btnCheckbox>{{c.value}}</label>
          </div>
          <span class="help-block">Select one or more boxes</span>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-6">
        <label class="control-label">Audience</label>
        <ul class="list-inline">
          <li class="list-inline-item" *ngFor="let audie of audienceModel | mapFromKeyValue; let i = index">
            <span *ngIf="audie == true"> {{ getConfigService().showAudienceSelect[i].value }} </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12" *ngIf="editMode">
        <div class="form-group">
          <label class="control-label">Enrollment</label>
          <textarea name="enrollment" [(ngModel)]="model.enrollment" rows="3" class="form-control" placeholder=""></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-12">
        <label class="control-label">Enrollment</label>
        <p class="line-breaker textarea-border">{{ model.enrollment }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label class="bold">Provisional weekly tutors needed (1/2/3/4)<span class="required">*</span></label>
      </div>
    </div>
    <div class="row" *ngIf="editMode">
      <div class="col-md-3">
        <div *ngIf="campWeeks <= 0" class="form-group">
          <input type="number" #provisionalInput min="0" name="week_01" #week_01="ngModel" [min]="0" [(ngModel)]="model.provisional_weeks_1"
            class="form-control" placeholder="" number>
        </div>
        <div *ngIf="campWeeks > 0" class="form-group" [ngClass]="{ 'has-error': (f.submitted || week_01.dirty || week_01.touched) && !week_01.valid }">
          <input type="number" #provisionalInput min="0" #week_01="ngModel" name="week_01" [min]="0" [(ngModel)]="model.provisional_weeks_1"
            class="form-control" placeholder="" number required>
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="campWeeks <= 1" class="form-group">
          <input type="number" #provisionalInput min="0" name="week_02" [min]="0" [(ngModel)]="model.provisional_weeks_2"
            class="form-control" placeholder="" number>
        </div>
        <div *ngIf="campWeeks > 1" class="form-group" [ngClass]="{ 'has-error': (f.submitted || week_02.dirty || week_02.touched) && !week_02.valid }">
          <input type="number" #provisionalInput min="0" #week_02="ngModel" name="week_02" [min]="0" [(ngModel)]="model.provisional_weeks_2"
            class="form-control" placeholder="" number required>
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="campWeeks <= 2" class="form-group">
          <input type="number" #provisionalInput min="0" name="week_03" [min]="0" [(ngModel)]="model.provisional_weeks_3"
            class="form-control" placeholder="" number>
        </div>
        <div *ngIf="campWeeks > 2" class="form-group" [ngClass]="{ 'has-error': (f.submitted || week_03.dirty || week_03.touched) && !week_03.valid }">
          <input type="number" #provisionalInput min="0" #week_03="ngModel" name="week_03" [min]="0" [(ngModel)]="model.provisional_weeks_3"
            class="form-control" placeholder="" number required>
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="campWeeks <= 3" class="form-group">
          <input type="number" #provisionalInput min="0" name="week_04" [min]="0" [(ngModel)]="model.provisional_weeks_4"
            class="form-control" placeholder="" number>
        </div>
        <div *ngIf="campWeeks > 3" class="form-group" [ngClass]="{ 'has-error': (f.submitted || week_04.dirty || week_04.touched) && !week_04.valid }">
            <input type="number" #provisionalInput min="0" #week_04="ngModel" name="week_04" [min]="0" [(ngModel)]="model.provisional_weeks_4"
              class="form-control" placeholder="" number required>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-3">
        <p *ngIf="model.provisional_weeks_1"> week 1: {{ model.provisional_weeks_1 }} </p>
      </div>
      <div class="col-md-3">
        <p *ngIf="model.provisional_weeks_2"> week 2: {{ model.provisional_weeks_2 }} </p>
      </div>
      <div class="col-md-3">
        <p *ngIf="model.provisional_weeks_3"> week 3: {{ model.provisional_weeks_3 }} </p>
      </div>
      <div class="col-md-3">
        <p *ngIf="model.provisional_weeks_4"> week 4: {{ model.provisional_weeks_4 }} </p>
      </div>
    </div>

    <div class="row" *ngIf="editModeCampDirector">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label"><i class="fa fa-file-text-o" aria-hidden="true"></i> Camp and Methological Description</label>
          <textarea name="camp_description" [(ngModel)]="model.camp_description" rows="3" class="form-control" placeholder=""></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editModeCampDirector">
      <div class="col-md-12">
        <label class="control-label"><i class="fa fa-file-text-o" aria-hidden="true"></i> Camp and Methological Description</label>
        <p class="line-breaker textarea-border">{{ model.camp_description }}</p>
      </div>
    </div>

    <div class="row" *ngIf="editModeCampDirector">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label"><i class="fa fa-train" aria-hidden="true"></i> Railway Station</label>
          <input [(ngModel)]="model.railway" name="railway" [typeahead]="stationComplex" [typeaheadScrollable]="true"
            (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadNoResults)="changeTypeaheadNoResults($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
            [typeaheadOptionsLimit]="7" typeaheadOptionField="sname" placeholder="Scrivi il nome di una stazione" class="form-control"
            autocomplete="no">
          <div *ngIf="typeaheadLoading===true">Loading</div>
          <div *ngIf="typeaheadNoResults===true">&#10060; No Results Found</div>

        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editModeCampDirector">
      <div class="col-md-6">
        <label class="control-label"><i class="fa fa-train" aria-hidden="true"></i> Railway Station</label>
        <p *ngIf="model.railway"> {{ model.railway }} </p>
        <p *ngIf="!model.railway">-</p>
      </div>
    </div>

    <div class="row" *ngIf="editModeCampDirector">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label"><i class="fa fa-road" aria-hidden="true"></i> Transfer details</label>
          <textarea name="transfer_details" [(ngModel)]="model.transfer_details" rows="3" class="form-control" placeholder=""></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editModeCampDirector">
      <div class="col-md-12">
        <label class="control-label"><i class="fa fa-road" aria-hidden="true"></i> Transfer details</label>
        <p class="line-breaker textarea-border">{{ model.transfer_details }}</p>
      </div>
    </div>



    <div class="row" *ngIf="editMode">

      <div *ngIf="model.id" class="col-md-6 form-group">
        <label class="control-label"><i class="fa fa-files-o" aria-hidden="true"></i> Conditions & Agreements</label>
        <div *ngIf="model.conditions_file_path == undefined || model.conditions_file_path == ''">
          <input *ngIf="!conditionUploader.isUploading && !inUpload" type="file" id="conditionUploader"
            ng2FileSelect [uploader]="conditionUploader" />
          <label *ngIf="!conditionUploader.isUploading && !inUpload" for="conditionUploader" style="margin: 0px;"
            class="btn green-jungle text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload Conditions!</label>
        </div>
        <div *ngIf="conditionUploader.isUploading || getCampService().isOngoingRequest() || inUpload">
          <img src="./assets/global/img/input-spinner.gif" />
          please wait...
        </div>
        <div *ngIf="model.conditions_file_path != undefined && model.conditions_file_path != ''">
          <button *ngIf="!conditionUploader.isUploading && !inUpload" type="button"
            class="btn btn-danger text-uppercase" (click)="deleteCondition();"><i
              class="fa fa-trash"></i>&nbsp; Remove Conditions</button>
        </div>
      </div>

      <div *ngIf="model.id" class="col-md-6 form-group">
        <label class="control-label">Venue Request</label>
        <div *ngIf="model.venue_request_file_path == undefined || model.venue_request_file_path == ''">
          <input *ngIf="!venueUploader.isUploading && !inUpload" type="file" id="venueUploader"
            ng2FileSelect [uploader]="venueUploader" />
          <label *ngIf="!venueUploader.isUploading && !inUpload" for="venueUploader" style="margin: 0px;"
            class="btn green-jungle text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload Venue Request!</label>
        </div>
        <div *ngIf="venueUploader.isUploading || getCampService().isOngoingRequest() || inUpload">
          <img src="./assets/global/img/input-spinner.gif" />
          please wait...
        </div>
        <div *ngIf="model.venue_request_file_path != undefined && model.venue_request_file_path != ''">
          <button *ngIf="!venueUploader.isUploading && !inUpload" type="button"
            class="btn btn-danger text-uppercase" (click)="deleteVrequest();"><i
              class="fa fa-trash"></i>&nbsp; Remove Venue Request</button>
        </div>
      </div>

    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-6">
        <label class="control-label"><i class="fa fa-files-o" aria-hidden="true"></i> Conditions & Agreements</label>
        <p *ngIf="model.conditions_file_path">
          <a class="btn btn-primary" href="{{getConfigService().blobBasePath}}{{model.conditions_file_path}}" target="_self">Download</a>
        </p>
        <p *ngIf="!model.conditions_file_path">Nessun documento caricato</p>
      </div>
      <div class="col-md-6">
        <label class="control-label">Venue Request</label>
        <p *ngIf="model.venue_request_file_path">
          <a class="btn btn-primary" href="{{getConfigService().blobBasePath}}{{model.venue_request_file_path}}" target="_self">Download</a>
        </p>
        <p *ngIf="!model.venue_request_file_path">Nessun documento caricato</p>
      </div>
    </div>


    <div class="row" *ngIf="editMode">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label"><i class="fa fa-files-o" aria-hidden="true"></i> Additional agreements for contract</label>
          <textarea name="additional_agreements" [(ngModel)]="model.additional_agreements" rows="3" class="form-control" placeholder=""></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!editMode">
      <div class="col-md-12">
        <label class="control-label"><i class="fa fa-files-o" aria-hidden="true"></i> Additional agreements for contract</label>
        <p class="line-breaker textarea-border">{{ model.additional_agreements }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <!-- <label class="control-label">Download contract</label>
          <button disabled type="button" class="btn btn-primary">Download</button> -->
        </div>
      </div>
    </div>

  </div>

  <div class="form-actions right">
    <img *ngIf="getCampService().canEditCampTravelInfo() && getCampService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button [appCheckPermission]="'camp_edit'" type="button" class="btn btn-outline red" [disabled]="getCampService().isOngoingRequest()" *ngIf="getCampService().canEditCampTravelInfo() && getCampService().currentCampId && (editMode || editModeCampDirector)"
      [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
      <i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button *ngIf="getCampService().canEditCampTravelInfo() && this.model.id && !(editMode || editModeCampDirector)" type="button" class="btn btn-outline blue" [disabled]="getCampService().isOngoingRequest()"
      (click)="edit()">
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</button>
    <button type="submit" class="btn blue" [disabled]="getCampService().isOngoingRequest()" *ngIf="getCampService().canEditCampTravelInfo() && getCampService().currentCampId && (editMode || editModeCampDirector)">Update</button>
    <button type="submit" class="btn green-jungle" [disabled]="getCampService().isOngoingRequest()" *ngIf="getCampService().canEditCampTravelInfo() && !getCampService().currentCampId && (editMode || editModeCampDirector)">Continue</button>
    <button type="button" class="btn btn-outline blue" [disabled]="getCampService().isOngoingRequest()" *ngIf="getCampService().currentCampId" (click)="getCampService().downloadCampStaff()"><i class="fa fa-print" aria-hidden="true"></i>&nbsp;PDF</button>
  </div>

</form>




<!-- BEGIN: Modal di ricerca delle enterprise da associare -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Enterprise</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-enterprise-search-form #enterpriseSearchForm (searchKeywordsSubmit)="searchKeywordsSubmit($event)" [ongoingRequest]="enterpriseDatatable.ongoingRequest"></app-enterprise-search-form>
          </div>
        </div>

        <br>
        <br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-enterprise-datatable #enterpriseDatatable [query]="enterpriseSearchForm?.searchKeyword" (enterpriseSelected)="resultSelected($event)"></app-enterprise-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Modal di ricerca delle enterprise da associare -->


<div class="modal fade" bsModal #confirmModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Attenzione!</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="confirmModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
          Il camp non sarà acquistabile Online a causa dei seguenti motivi:  <br>
          <ul *ngIf="onlineErrorArr.length > 0 || onlineWarnArr.length > 0">
            <li *ngFor="let error of onlineErrorArr">{{error}}</li>
            <li *ngFor="let warn of onlineWarnArr">{{warn}}</li>
          </ul>
          <button type="button" class="btn btn-primary" (click)="confirmSubmit()" >Ok</button>
      </div>
    </div>
  </div>
</div>
