import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EnterpriseDatatableComponent } from '../enterprise-datatable/enterprise-datatable.component';
import { EnterpriseInfoSummaryComponent } from '../enterprise-info-summary/enterprise-info-summary.component';
import { EnterpriseSearchFormComponent } from '../enterprise-search-form/enterprise-search-form.component';
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { EnterpriseService } from '../../_services';

//  salvataggio per excel
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { NotificationsService } from 'angular2-notifications';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-enterprise-search-results',
  templateUrl: './enterprise-search-results.component.html',
  styleUrls: ['./enterprise-search-results.component.css']
})
export class EnterpriseSearchResultsComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;

  @ViewChild(EnterpriseDatatableComponent, {static: true}) datatable:EnterpriseDatatableComponent;
  @ViewChildren('enterpriseInfoSummaryComponent') enterpriseShow: EnterpriseInfoSummaryComponent;

  private subject: any;
  private searchForm: any;

  constructor(private route:ActivatedRoute, private notify: NotificationsService, private enterpriseService: EnterpriseService) { }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {
      this.updateResults(params);
    });
  }

  ngOnDestroy()
  {
    this.subject.unsubscribe();
  }

  currentEnterpriseId: number;
  enterpriseSelected(selected){

    this.currentEnterpriseId = selected.enterprise_id;
    this.showModal();
  }

  updateResults(params){
    this.searchForm = params;

    this.datatable.query = params;
    this.datatable.getResults();
  }

  searchKeywordsSubmit(params){
    this.updateResults(params);
  }


  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }




  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    this.isModalExport = false;
  }


  /*  Salvo i dati nel formato Excel
  */
 exportToExcel() {

    if (this.datatable.pageTotalElements != null && typeof this.datatable.pageTotalElements != "undefined")
      if (this.datatable.pageTotalElements > this.enterpriseService.MAX_NUM_OF_EXPORT) {
        this.notify.warn("Export", "Too many results, improve your search");
        return;
      }

    this.showModalExport();

    if (this.searchForm.advancedSearchOn == "false" || !this.searchForm.advancedSearchOn) {

      this.enterpriseService.exportAll(this.searchForm, (res) => {
        this.exportParse(res, () => {
          this.hideModalExport();
        });
      }, () => {
        this.hideModalExport();
      });

    }
    else {

      this.enterpriseService.exportAllAdvanced(this.searchForm, (res) => {
        this.exportParse(res, () => {
          this.hideModalExport();
        })
      }, () => {
        this.hideModalExport();
      });
    }

  }

  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse (data: any, callback: any) {

    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach( (element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      //  preparo
      exportedArray.push( {
        'Nome': element.denomination,
        'Contatto': element.contact,
    //     'Email': element.email_contact,
        'Indirizzo': element.route +" "+element.street_number,
        "Città": element.administrative_area_level_3_short_version,
        "Provincia": element.administrative_area_level_2_short_version,
        "Regione": element.administrative_area_level_1_long_version,
        "Codice Postale": element.postal_code,
        "TAX ID": element.tax_id,
        "Dati bancari": element.bank_account_iban
      })
    });

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    //  TODO: da aggiungere la mail nella terza posizione = {width:30}
    worksheet['!cols'] = [{width: 50}, {width:40}, {width:50}, {width:20}, {width:12}, {width:20}, {width:14}, {width:20}, {width:30}];

    //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'Enterprises': worksheet}, SheetNames: ['Enterprises'] };

    //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

    //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "enterprises.xlsx");

    callback();

  }

}
