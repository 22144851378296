<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/enterprise/home']">Enterprise</a>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Enterprises Database
        <small></small>
      </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">
            <!--begin: Portlet-Body -->
            <div class="portlet-body">
              <app-enterprise-search-form (searchKeywordsSubmit)="searchKeywordsSubmit($event)"></app-enterprise-search-form>
            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->


      <!-- begin: prima riga -->
      <div class="row" [appCheckPermission]="'enterprise_create'">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">
            <!--begin: Portlet-Body -->
            <div class="portlet-body">

              <!-- begin: Inserimento Ente -->
              <h3 [appCheckPermission]="'enterprise_create'" class="">Add new</h3>
              <hr>
              <div [appCheckPermission]="'enterprise_create'" class="m-grid m-grid-flex m-grid-responsive-md">
                <div class="row">
                  <div class="col col-md-2">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/enterprise/new', 1]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Scuola</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <div class="col col-md-2 col-half-offset">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/enterprise/new', 2]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Comune</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <div class="col col-md-2 col-half-offset">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/enterprise/new', 3]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Associazione</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <div class="col col-md-2 col-half-offset">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/enterprise/new', 4]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Ditta</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <div class="col col-md-2 col-half-offset">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/enterprise/new', 5]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Accomodation</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <!--end: inserimento Ente -->

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->

    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
