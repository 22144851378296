import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VehicleService, HelperService, ConfigService } from 'app/_services';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { parseISO } from 'date-fns';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-vehicle-penalty-info-form',
  templateUrl: './vehicle-penalty-info-form.component.html',
  styleUrls: ['./vehicle-penalty-info-form.component.css']
})
export class VehiclePenaltyInfoFormComponent implements OnInit {

  @Input() model:any;
  @Output() penaltyDeleted = new EventEmitter();
  @Output() penaltyUpdated = new EventEmitter();

  private localPenaltyData: IMyDateModel;
  private localNotificationData: IMyDateModel;
  private localPaymentData: IMyDateModel;

  //  Url per l'upload di file
  private headers: any;

  //  file uploaders
  public penaltyUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf'],
      url: 'serverUrl'
    }
  );
  //  file uploaders
  public receiptUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf'],
      url: 'serverUrl'
    }
  );


  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(private vehicleService: VehicleService, private helper: HelperService, private configService: ConfigService) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    if (this.model == null || typeof this.model == "undefined")
    {
      this.model = {};
      this.model.paid = false;
    }
    else
    {
      this.prepareModel();
    }
  }


  prepareModel()
  {
    //  Setto l'url per il caricamento delle foto e gli header
    this.headers = this.vehicleService.getFileUlopadHeader();

    this.penaltyUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.penaltyUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.penaltyUploaded(item, response, status);
    };
    this.penaltyUploader.options.url = this.vehicleService.getPenaltyFileUploadURL(this.model);

    this.receiptUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.receiptUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.receiptUploaded(item, response, status);
    };
    this.receiptUploader.options.url = this.vehicleService.getReceiptFileUploadURL(this.model);

    //  data della multa
    if (this.model.date_penalty != null)
      this.localPenaltyData = this.helper.initMyDataInputSingle(this.model.date_penalty);
    //  data della notifica della multa
    if (this.model.date_notification != null)
      this.localNotificationData = this.helper.initMyDataInputSingle(this.model.date_notification);
    //  data di pagamento della multa
    if (this.model.date_payment != null)
      this.localPaymentData = this.helper.initMyDataInputSingle(this.model.date_payment);

    //  validate paid
    if (this.model.paid && typeof this.model.paid != 'boolean')
      this.model.paid = this.model.paid === 1;

      //  Se esiste il path per l'immagine costruisco l'url completo
      if (this.model.penalty_url)
        this.model.penaltyUrl = this.configService.blobBasePath+this.model.penalty_url;
      if (this.model.receipt_url)
        this.model.receiptUrl = this.configService.blobBasePath+this.model.receipt_url;

  }

  submit()
  {
    if (!this.helper.isMyDateValid(this.localNotificationData, false) || !this.helper.isMyDateValid(this.localPaymentData, false) || !this.helper.isMyDateValid(this.localPenaltyData, false))
      return;

    if (this.model.id)
      this.updatePenalty();
    else
      this.savePenalty();
  }

  savePenalty ()
  {
    this.vehicleService.saveVehiclePenalty(this.model, () => {
      this.penaltyUpdated.emit();
    });
  }

  updatePenalty()
  {
    this.vehicleService.updateVehiclePenalty(this.model.id, this.model, () => {
      this.penaltyUpdated.emit();
    });
  }

  deletePenalty ()
  {
    this.vehicleService.deleteVehiclePenalty(this.model.id, this.model, () => {
      this.penaltyDeleted.emit();
    });
  }




  //  penalty Data
  onDatePenaltyChanged(event: IMyDateModel) {

    this.localPenaltyData = event;

    if (event.singleDate.jsDate != null)
      this.model.date_penalty = this.helper.formatISODate(event);
    else
      this.model.date_penalty = null;
  }


  //  notification Data
  onDateNotificationChanged(event: IMyDateModel) {

    this.localNotificationData = event;

    if (event.singleDate.jsDate != null)
      this.model.date_notification = this.helper.formatISODate(event);
    else
      this.model.date_notification = null;
  }


  //  penalty Data
  onDatePaymentChanged(event: IMyDateModel) {

    this.localPaymentData = event;

    if (event.singleDate.jsDate != null)
      this.model.date_payment = this.helper.formatISODate(event);
    else
      this.model.date_payment = null;
  }



  public penaltyUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.model.penaltyUrl = this.configService.blobBasePath + response;
        this.model.penalty_url = response;
      }
      this.vehicleService.penaltyFileResponse(item, response, status,  this.model.id);
    }
  }

  receiptUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.model.receiptUrl = this.configService.blobBasePath + response;
        this.model.receipt_url = response;
      }
      this.vehicleService.receiptFileResponse(item, response, status,  this.model.id);
    }
  }

  //  Remove image
  destroyPenaltyFile() {
    this.vehicleService.deletePenaltyFile(this.model, (new_model) => {

      this.model = new_model;
      this.prepareModel();
    });
  }


  //  Remove image
  destroyReceiptFile() {
    this.vehicleService.deleteReceiptFile(this.model, (new_model) => {

      this.model = new_model;
      this.prepareModel();
    });
  }

  getConfigService() {
    return this.configService
  }

  getLocalPenaltyData() {
    return this.localPenaltyData
  }

  getLocalNotificationData() {
    return this.localNotificationData
  }

  getLocalPaymentData() {
    return this.localPaymentData
  }

  getHelper() {
    return this.helper
  }

  getVehicleService() {
    return this.vehicleService
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

}
