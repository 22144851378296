import { ConfigService, VehicleService, HelperService } from '../../_services/index';
import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-vehicle-search-form',
  templateUrl: './vehicle-search-form.component.html',
  styleUrls: ['./vehicle-search-form.component.css']
})
export class VehicleSearchFormComponent implements OnInit {

  model: any = { advancedSearchOn: false, simpleSearchKeyword: "" };

  private firstSubmit: boolean = false;

  private subject: any;

  public advancedSearchShow = false;

  @Output() searchKeywordsSubmit = new EventEmitter();

  @Input() ongoingRequest: boolean;

  @ViewChild('simpleSearchKeywordText', { static: true, read: ElementRef }) private searchInput: ElementRef;

  constructor(private route: ActivatedRoute, private router: Router, private configService: ConfigService,
    private vehicleService: VehicleService, private helperService: HelperService) {
  }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {

      if (typeof params.advancedSearchOn != "undefined") {

        if (this.vehicleService.lastSearchQueries != null)
          this.model = this.vehicleService.lastSearchQueries;
      }

    });

  }

  //   quando la vista si è inizializzata setto il focus sul campo di ricerca
  ngAfterViewInit() {

    setTimeout(() => {
      if (typeof this.searchInput != "undefined")
        this.searchInput.nativeElement.focus();

      // chiamo la pria chiamata dove restituisco tutto
      this.getAllResources(null);

    }, 600);

  }

  ngOnDestroy() {
    this.subject.unsubscribe();
  }


  fieldChange(event) {

    if (!this.firstSubmit)
      return;
  }

  submitSearch() {

    //  memorizzo i parametri della ricerca attuale
    this.vehicleService.lastSearchQueries = JSON.parse(JSON.stringify(this.model));

    //  Elimino le chiavi che non hanno valore prima di fare le ricerche
    Object.keys(this.model).forEach(element => {
      if (!this.model[element])
        delete this.model[element];
    });

    this.firstSubmit = true;

    if (this.advancedSearchShow) {
      this.model.advancedSearchOn = true;

      // Avendo eliminato prima le chiavi senza valore se non era true è stata eliminata e l'aggiungo come false essendo una checkbox
      if (typeof this.model.vehicle_active == 'undefined') {
        this.model.vehicle_active = false;
      }
    }
    else {
      this.model = { advancedSearchOn: false, simpleSearchKeyword: this.model.simpleSearchKeyword };
    }

    if (!this.advancedSearchShow)
      this.advancedSearchShow = false;

    this.searchKeywordsSubmit.emit(this.model);
  }

  getAllResources(form: any) {

    this.model = {};

    if (form != null)
      form._submitted = false;

    this.searchKeywordsSubmit.emit(this.model);
  }

  hideAdvancedSearch() {
    this.advancedSearchShow = false;
  }

  clearAdvancedSearch() {

    //  resetto il modello e tengo aperto il form di ricerca
    this.model = { advancedSearchOn: false, simpleSearchKeyword: "" };
    this.showAdvancedSearch();
  }

  showAdvancedSearch() {
    this.advancedSearchShow = true;
    this.model.simpleSearchKeyword = "";

    if (!this.model.vehicle_active)
      this.model.vehicle_active = true;
  }

  getConfigService() {
    return this.configService
  }

}
