import { Directive } from '@angular/core';
import { NoSpacesValidator } from '../_validators/nospace.validator';
import { NG_VALIDATORS, Validator, AbstractControl, Validators } from '@angular/forms';

/**
 * Questa funzione di validazionefunziona uguale alla direttiva "required" ma non accetta gli spazi vuoti
 *
 * @export
 * @class NospaceDirective
 * @implements {Validator}
 */
@Directive({
  selector: '[nospace]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NospaceDirective, multi: true}]
})
export class NospaceDirective {

  private valFN = NoSpacesValidator();
  validate(control: AbstractControl): { [key: string]:any } {
    return this.valFN(control);
  }

}