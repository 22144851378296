import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupService } from 'app/_services';
import { VehicleAssignmentDatatableComponent } from '../../vehicle/vehicle-assignment-datatable/vehicle-assignment-datatable.component';

@Component({
  selector: 'app-actor-group-vehicle',
  templateUrl: './actor-group-vehicle.component.html',
  styleUrls: ['./actor-group-vehicle.component.css']
})
export class ActorGroupVehicleComponent implements OnInit {

  @ViewChild('assignmentDatatable', {static: true}) assignmentDatatable: VehicleAssignmentDatatableComponent;

  subject: any;

  assignments: any[];

  constructor(private groupService:GroupService) { }

  ngOnInit() {

    this.prepareView();

    this.subject = this.groupService.getsubjectToUpdateObservable().subscribe(() => {

      this.prepareView();
    });
  }

  prepareView()
  {
    if (this.groupService.currentAssignments == null || typeof this.groupService.currentAssignments == "undefined")
        this.assignments = [];
      else
      {
        this.assignments = JSON.parse(JSON.stringify(this.groupService.currentAssignments));
        this.assignmentDatatable.update(this.assignments);
      }
  }

}
