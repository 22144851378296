import { Component, OnInit, ViewChild } from '@angular/core';
import { CampService, ConfigService } from 'app/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-camp-payment-requests-list',
  templateUrl: './camp-payment-requests-list.component.html',
  styleUrls: ['./camp-payment-requests-list.component.css']
})
export class CampPaymentRequestsListComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;

  rowsSubscribers: any[] = [];
  rowsSignups: any[] = [];
  selected = [];
  ongoingRequest: boolean = false;
  selectedPaymentRequestInfo = null;

  constructor(private campService: CampService, private configService: ConfigService) { }

  ngOnInit() {

    if (this.campService.currentCampId)
      this.getPaymentRequests();
  }


  getPaymentRequests() {

    this.ongoingRequest = true;
    this.rowsSubscribers = [];
    this.rowsSignups = [];

    this.campService.getPaymentRequests((list) => {

      this.parseRows(list);

      this.ongoingRequest = false;
    }, () => {

      this.rowsSubscribers = [];
      this.rowsSignups = [];
      this.ongoingRequest = false;
    })

  }


  parseRows(list: any) {
    this.rowsSubscribers = [];
    this.rowsSignups = [];
    //  filtro la lista eliminando le richiste che fanno parte delle signup
    for (let indice in list) {
      let el1 = list[indice];
      let addToSub = false;
      for (let indice2 in el1.prima_nota_rows) {
        let el2 = el1.prima_nota_rows[indice2];

        if (el2.out_holder_type != "App\\CampSignupForm") {
          this.rowsSubscribers.push(el1);
          addToSub = true;
          break;
        }
      }

      if (!addToSub) {
        this.rowsSignups.push(el1);
      }
    }
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  onSelect({ selected }) {

    this.selectedPaymentRequestInfo = this.selected[0];
    this.showModal();
  }

  onActivate(event) {
    //  console.log('Activate Event', event);
  }

  //  Eventi sulla payment request
  paymentRequestDeleted() {
    this.getPaymentRequests();
    this.hideModal();
  }


  //  MODAL PAYMENT REQUEST
  isModalShown: boolean = false;
  showModal(): void {
    this.isModalShown = true;
  }
  hideModal(): void {
    this.autoShownModal.hide();
  }
  onHidden(): void {
    this.isModalShown = false;
    this.selectedPaymentRequestInfo = null;
  }


  getRowClass(row) {
    return {
      'ignore-request': row.ignore_request === 1
    };
  }

  getConfigService(): ConfigService {
    return this.configService;
  }

  getCampService(): CampService {
    return this.campService;
  }

}
