<div class="datatable-loader text-center" *ngIf="ongoingRequest">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>
<ngx-datatable *ngIf="!ongoingRequest"
  class="striped"
  [rows]="rows"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="true"
  [count]="pageTotalElements"
  [offset]="pageNumber"
  [limit]="pageSize"
  [selected]="selected"
  [selectionType]="'single'"
  (activate)="onActivate($event)"
  (select)='onSelect($event)'
  (page)='setPage($event)'>

  <ngx-datatable-column name="Course title" prop="title" [sortable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Notes" prop="annotation" [sortable]="false">
    <ng-template ngx-datatable-cell-template let-value="value">
      <p class="line-breaker">{{value}}</p>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Type" prop="type" [sortable]="true">
    <ng-template ngx-datatable-cell-template let-value="value">
      {{ getConfigService().getCourseTypeValueByKey(value) }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Active" prop="active" [sortable]="false">
    <ng-template ngx-datatable-cell-template let-value="value">
      <i *ngIf="value" class="fa fa-check" aria-hidden="true"></i>
    </ng-template>
  </ngx-datatable-column>

</ngx-datatable>
