<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">

    <div class="row">
      <div class="col-md-4">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || title.dirty || title.touched) && !title.valid }">
          <label class="control-label">Course title
            <span class="required">*</span>
          </label>
          <input type="text" name="title" [(ngModel)]="model.title" #title="ngModel" required class="form-control"
            placeholder="">
          <span class="help-block">Course title is required </span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
          <label class="control-label">Type
            <span class="required">*</span>
          </label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
            <option *ngFor="let s of getConfigService().courseTypeSelect" [value]="s.key">{{s.value}}</option>
          </select>
          <span class="help-block">Select course type, type is required</span>
        </div>
      </div>
      <!-- <div class="col-md-2">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || duration.dirty || duration.touched) && !duration.valid }">
          <label class="control-label">Default Duration
          </label>
          <input type="text" name="duration" [(ngModel)]="model.duration" #duration="ngModel" class="form-control" placeholder=""
            digits autocomplete="off">
          <span class="help-block"> Duration in minutes</span>
          <span *ngIf="duration.errors?.digits" class="help-block">Only positive</span>
        </div>
      </div> -->
      <div class="col-md-2">
        <div class="form-group">
          <label>Attivo</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="active" [(ngModel)]="model.active" value="1"> Yes
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <div [appCheckPermission]="'person_edit'" *ngIf="model.id && !model.coverPath">
            <input *ngIf="!coverUploader.isUploading" type="file" id="coverUploader"
              ng2FileSelect [uploader]="coverUploader" />
            <label *ngIf="!coverUploader.isUploading" for="coverUploader"
              class="btn btn-lg btn-block green-jungle text-uppercase label-file"><i class="fa fa-upload"></i>&nbsp; Upload Cover!</label>
          </div>
          <div *ngIf="coverUploader.isUploading || getCourseService().isOngoingRequest()">
            <img src="./assets/global/img/input-spinner.gif" />
            please wait...
          </div>
          <div *ngIf="model.id && model.coverPath">
            <div *ngIf="model.coverPath" aria-hidden="true">
              <img src="{{model.coverPath}}?{{this.model.updated_at}}" style="width: 100%;">
            </div>
            <button type="button" [disabled]="getCourseService().isOngoingRequest()" class="btn btn-block red" (click)="destroyCover()">Remove Cover</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" placeholder="Some notes..." name="annotation" [(ngModel)]="model.annotation"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Online Description corsi.educoitalia.it</label>
          <textarea rows="3" class="form-control" placeholder="" name="online_description" [(ngModel)]="model.online_description"></textarea>
        </div>
      </div>
    </div>

  </div>

  <div class="form-actions right">
    <img *ngIf="getCourseService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getCourseService().isOngoingRequest()" *ngIf="getCourseService().currentId"
      [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?">
      <i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="submit" class="btn blue" [disabled]="getCourseService().isOngoingRequest()" *ngIf="getCourseService().currentId">Update</button>
    <button type="submit" class="btn green-jungle" [disabled]="getCourseService().isOngoingRequest()" *ngIf="!getCourseService().currentId">Continue</button>
  </div>

</form>
