import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../environment';
import { AuthenticationService, LoggedService } from '../_services/index';

@Component({
  moduleId: module.id,
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;
  resetPasswordLink: string;
  loginError: any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private ls: LoggedService) { }

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();

    this.resetPasswordLink = environment.resetPasswordLink;

    this.ls.setLogged(false);

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.authenticationService.getLoggeginObservable().subscribe(
      data => {
        this.ls.setLogged(true);
        this.router.navigate([this.returnUrl]);
      },
      error => {
        this.loading = false;

        this.loginError = error;
      });
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.model.email, this.model.password,
      (res) => {
        this.loading = false;
    }, ()=> {
      this.loading = false;
    });

  }

  valuechange(event) {
    this.loginError = null;
  }
}
