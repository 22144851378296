import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ConfigService, HelperService, RevisionService, UserService, ReminderService } from './../../_services/index';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { PersonDatatableComponent } from "../../person/person-datatable/person-datatable.component";
import { PersonSearchFormComponent} from "../../person/person-search-form/person-search-form.component";
import { EnterpriseDatatableComponent } from '../../enterprise/enterprise-datatable/enterprise-datatable.component';
import { EnterpriseSearchFormComponent } from '../../enterprise/enterprise-search-form/enterprise-search-form.component';
import { ActorGroupDatatableComponent } from "../../actor-group/actor-group-datatable/actor-group-datatable.component";
import { ActorGroupSearchFormComponent} from "../../actor-group/actor-group-search-form/actor-group-search-form.component";
import { CampDatatableComponent } from "../../camp/camp-datatable/camp-datatable.component";
import { CampSearchFormComponent} from "../../camp/camp-search-form/camp-search-form.component";
import { CourseScheduleSearchFormComponent } from '../../course-schedule/course-schedule-search-form/course-schedule-search-form.component';
import { CourseScheduleDatatableComponent } from '../../course-schedule/course-schedule-datatable/course-schedule-datatable.component';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { parseISO, format } from "date-fns";

@Component({
  selector: 'app-reminder-add-form',
  templateUrl: './reminder-add-form.component.html',
  styleUrls: ['./reminder-add-form.component.css']
})
export class ReminderAddFormComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('personDatatable', {static: true}) public personDatatable: PersonDatatableComponent;
  @ViewChild('personSearchForm', {static: true}) public personSearchForm: PersonSearchFormComponent;
  @ViewChild('enterpriseDatatable', {static: true}) public enterpriseDatatable: EnterpriseDatatableComponent;
  @ViewChild('enterpriseSearchForm', {static: true}) public enterpriseSearchForm: EnterpriseSearchFormComponent;
  @ViewChild('groupDatatable', {static: true}) public groupDatatable: ActorGroupDatatableComponent;
  @ViewChild('groupSearchForm', {static: true}) public groupSearchForm: ActorGroupSearchFormComponent;
  @ViewChild('campDatatable', {static: true}) public campDatatable: CampDatatableComponent;
  @ViewChild('campSearchForm', {static: true}) public campSearchForm: CampSearchFormComponent;
  @ViewChild('courseDatatable', {static: true}) public courseDatatable: CourseScheduleDatatableComponent;
  @ViewChild('courseSearchForm', {static: true}) public courseSearchForm: CourseScheduleSearchFormComponent;

  model: any = {};
  showForm: boolean = false;
  typeDetected: string;
  rowsRevision: any[] = [];
  showRevision: boolean = false;
  completed: any = null;

  private localData: IMyDateModel;
  private localNotificationData: IMyDateModel;

  @Output() modelUpdated = new EventEmitter();

  @Input() showCloseButton: boolean = true;
  @Input() reminderModel: any;
  @Input() reminderId: number;
  @Input() holderType: string;
  @Input() holderName: string;
  @Input() holderId: number;

  @ViewChild('f', {static: false}) currentForm: NgForm;

  subscribe: any;
  modalEntityType: string;

  loadingData: boolean = false;

  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(private reminderService: ReminderService, private configService: ConfigService, private helper: HelperService, private revisionService: RevisionService, private userService: UserService) {

    //  Inizializzo le info sulle date e resetto il blocco
    this.datePickerOptions = this.helper.datePickerOptions;
  }

  ngOnInit() {

    this.model.notification_destination = this.getUserService().getCurrentUser().email;

    if (this.userService.isOfficeFinancialStaff()){
      this.showRevision = false;
    }

    if (!this.reminderModel && this.reminderId){

      this.loadingData = true;
      this.showForm = false;

      this.subscribeToEventUpdate();
      this.reminderService.getReminder(this.reminderId);
    }
    else {

      if (this.reminderModel){
        this.model = this.reminderModel;
        this.parseReminderModel();
        this.showForm = true;
      }

      this.parseDateReminder();
    }

  }

  subscribeToEventUpdate(){

    this.subscribe = this.reminderService.getsubjectToUpdateObservable().subscribe((res: any) => {

      this.model = res;

      this.parseReminderModel();

      if (this.model == null)
        this.model = {};

      this.parseDateReminder();

      this.loadingData = false;
      this.showForm = true;

    });
  }

  parseDateReminder(){

    if (this.model.start_date_reminder == null){
      let today = new Date();
      this.model.start_date_reminder = today.getFullYear() + '/' + (today.getMonth()+1) + '/' + today.getDate();

      if (this.currentForm)
        this.currentForm.form.markAsDirty();
    }

    this.localData = this.helper.initMyDataInputSingle(this.model.start_date_reminder);

    if (this.model.notification_date_time != null){
      this.localNotificationData = this.helper.initMyDataInputSingle(this.model.notification_date_time);
      this.model.notification_time = format(parseISO(this.model.notification_date_time), "HH:mm");

      if (this.model.all_the_day){
        this.model.notification_time = this.configService.defaultNotificationAllDayReminder_Time;
      }
    }

    this.localNotificationData = this.helper.initMyDataInputSingle(this.model.notification_date_time);

    if (this.model.completed){
      if (typeof this.model.completed[0] == "undefined"){
        this.completed = null;
      }
      else {
        this.completed = this.model.completed[0];
      }
    }

  }

  parseReminderModel() {

    this.holderName = this.helper.getHolderNameFromType(this.model.holder_type, this.model.holder_data);

    this.holderType = this.model.holder_type;
    this.holderId = this.model.holder_id;

    if (this.model){

      this.revisionService.getReminderRevision(this.model.id, (list) => {

        list.sort(function(a,b){
          if (a.revision.created_at < b.revision.created_at)
            return 1;
          if (a.revision.created_at > b.revision.created_at)
            return -1;
          return 0;
        });

        this.rowsRevision = list;
      });
    }
  }

  onDateChanged(event: IMyDateModel) {

    this.localData = event;

    if (event.singleDate.jsDate != null) {
      this.model.start_date_reminder = this.helper.formatISODate(event);
      this.localData = event;

      if (!this.model.notification_date_time){
        this.onNotificationDateChanged(event)
      }
    }
    this.currentForm.form.markAsDirty();
  }


  onNotificationDateChanged(event: IMyDateModel) {

    this.localNotificationData = event;

    if (event.singleDate.jsDate != null) {

      let time = this.configService.defaultNotificationAllDayReminder_Time;

      if (!this.model.all_the_day && this.model.start_time_reminder){
        time = this.model.start_time_reminder;
      }

      this.model.notification_time = time;
      this.model.notification_date_time = this.helper.formatISODate(event);
    }
    this.currentForm.form.markAsDirty();
  }


  //  Time picher
  timevalid: boolean = true;
  onTimeInput (event)
  {
    this.timevalid = false;

    //  controllo se è valido il time
    this.configService.timeReminderSelect.forEach(element => {
      if (element.value == this.currentForm.form.controls.start_time_reminder.value)
          return this.timevalid = true;
    });
  }
  typeTimeOnSelect (event) {
    //  Se arriva questo evento al 100% è una data valida
    this.timevalid = true;
  }

  //  Time picher
  notificationtimevalid: boolean = true;
  onNotificationTimeInput (event)
  {
    this.notificationtimevalid = false;

    //  controllo se è valido il time
    this.configService.timeReminderSelect.forEach(element => {
      if (element.value == this.currentForm.form.controls.notification_time.value)
          return this.notificationtimevalid = true;
    });
  }
  typeNotificationTimeOnSelect (event) {
    //  Se arriva questo evento al 100% è una data valida
    this.notificationtimevalid = true;
  }

  submit() {

    if (!this.helper.isMyDateValid(this.localData))
      return;

    if (!this.helper.isMyDateValid(this.localNotificationData))
      return;

    if (!this.currentForm.form.valid)
      return;

    if (!this.currentForm.form.dirty) {
      return;
    }

    let d = parseISO(this.model.notification_date_time);

    if (!this.model.start_time_reminder)
      this.model.start_time_reminder = this.configService.defaultNotificationAllDayReminder_Time;

    if (!this.model.notification_time){
      this.model.notification_time = this.model.start_time_reminder;
    }

    let t = this.model.notification_time.split(":");
    d.setHours(t[0]);
    d.setMinutes(t[1]);
    this.model.notification_date_time = format(d, "yyyy-MM-dd HH:mm:ss");

    this.model.holder_type = this.holderType;
    this.model.holder_id = this.holderId;

    if (this.model.id){
      this.reminderService.updateReminder(this.model.id, this.model, ()=> {
         this.modelUpdated.emit();
      });
    }
    else {
      this.reminderService.saveReminder(this.model, ()=> {

        this.showForm = false;

        this.modelUpdated.emit();
        this.model = {};

        this.parseDateReminder();
      });
    }

  }

  destroy() {
    this.reminderService.deleteReminder(this.model.id, () => {
      this.showForm = false;
      this.model = {};
      this.modelUpdated.emit();
    });
  }

  markAsCompleted() {
    this.reminderService.completeReminder(this.model.id, null, () => {
      this.closeForm();
    });
  }

  undoMarkAsCompleted(completedId) {
    this.reminderService.undoReminderComplete(completedId, null, () => {
      this.closeForm();
    });
  }

  closeForm(){
    this.showForm = false;
    this.modelUpdated.emit();
  }

  showFormReminder(){
    this.showForm = true;
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  getConfigService() {
    return this.configService
  }

  getLocalData() {
    return this.localData
  }

  getLocalNotificationData() {
    return this.localNotificationData
  }

  getHelper() {
    return this.helper
  }

  getReminderService(){
    return this.reminderService;
  }

  getUserService(){
    return this.userService;
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }
}
