import { ConfigService } from './../../_services/config.service';
import { BackendService } from './../../_services/backend.service';
import { CourseService } from './../../_services/course.service';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-course-info-show',
  templateUrl: './course-info-show.component.html',
  styleUrls: ['./course-info-show.component.css']
})
export class CourseInfoShowComponent implements OnInit, OnChanges {

  @Input() idCourse: number;
  courseInfo: any;
  currentCourseId: number;

  @Input() hideSummaryCardModal: boolean = false;

  constructor(private courseService: CourseService, private backend: BackendService, private router: Router, private configService: ConfigService) { }

  ngOnInit() {
    this.courseInfo = {};

    this.getCourseInfo();
  }

  ngOnChanges() {

    this.courseInfo = {};

    if (this.idCourse != this.currentCourseId)
      this.getCourseInfo();
  }


  loading: boolean = false;

  getCourseInfo() {

    if (typeof this.idCourse == "undefined" || this.idCourse <= 0) {
      this.courseInfo = {};
      this.currentCourseId = null;
      return;
    }

    this.currentCourseId = this.idCourse;

    let params = new HttpParams();

    this.loading = true;

    this.backend.get('course/' + this.currentCourseId, params).subscribe(
      (response) => {

        var res : any = response;

        this.courseInfo = res;

        this.loading = false;
      },
      (error) => {
        this.loading = false;

        this.backend.showErrors(error);
      });
  }

  clickCourse() {
    this.router.navigate(['course/edit/' + this.idCourse]);
  }

  getConfigService() {
    return this.configService;
  }

}
