<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span>Admin Reports</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Admin Reports
        <small></small>
      </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <!-- begin: prima colonna -->
        <div class="col-md-12">
          <div class="portlet light bordered">
            <div class="portlet-title">
              <div class="caption">
                <i class=" icon-layers font-green"></i>
                <span class="caption-subject font-green bold uppercase">Reports</span>
                <div class="caption-desc font-grey-cascade">Da quì è possibile richiedere report complessi che hanno un tempo di produzione dei risultati più lungo</div>
              </div>
            </div>
            <div class="portlet-body">
              <div class="row">

                <div class="col-xs-6 col-md-6">
                  <div class="mt-element-list">
                    <div class="mt-list-head list-default green-haze">
                      <div class="row">
                        <div class="col-xs-12 col-md-12">
                          <div class="list-head-title-container">
                            <h3 class="list-title uppercase sbold">Diary</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-list-container list-news">
                      <ul>
                        <li class="mt-list-item">
                          <div class="list-icon-container"><a [routerLink]="['/admin/reports/diary']"><i class="fa fa-angle-right"></i></a></div>
                          <!-- <div class="list-datetime">Dal 2017 ad oggi</div> -->
                          <div class="list-item-content">
                            <h3 class="uppercase bold">
                              <a [routerLink]="['/admin/reports/diary']">Report Diary</a>
                            </h3>
                            <p>Questo report permette di analizzare, per un determinato intervallo di date, tutte le voci di Diary inserite nel sistema</p>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-xs-6 col-md-6">
                  <div class="mt-element-list">
                    <div class="mt-list-head list-default green-haze">
                      <div class="row">
                        <div class="col-xs-12 col-md-12">
                          <div class="list-head-title-container">
                            <h3 class="list-title uppercase sbold">Revision e Attività</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-list-container list-news">
                      <ul>
                        <li class="mt-list-item">
                          <div class="list-icon-container"><a [routerLink]="['/admin/reports/revision']"><i class="fa fa-angle-right"></i></a></div>
                          <!-- <div class="list-datetime">Dal 2017 ad oggi</div> -->
                          <div class="list-item-content">
                            <h3 class="uppercase bold">
                              <a [routerLink]="['/admin/reports/revision']">Resoconto delle modifiche effettuate da un account utente</a>
                            </h3>
                            <p>Questo report genera una lista di tutte le azioni fatte in gaston da una determinata persona, necessita dell'id dell'account utente per poter ricercare</p>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-xs-6 col-md-6">
                  <div class="mt-element-list">
                    <div class="mt-list-head list-default green-haze">
                      <div class="row">
                        <div class="col-xs-12 col-md-12">
                          <div class="list-head-title-container">
                            <h3 class="list-title uppercase sbold">Corsi</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-list-container list-news">
                      <ul>
                        <li class="mt-list-item">
                          <div class="list-icon-container"><a [routerLink]="['/admin/reports/coursesignuprequest']"><i class="fa fa-angle-right"></i></a></div>
                          <!-- <div class="list-datetime">Dal 2017 ad oggi</div> -->
                          <div class="list-item-content">
                            <h3 class="uppercase bold">
                              <a [routerLink]="['/admin/reports/coursesignuprequest']">Report Signup Request</a>
                            </h3>
                            <p>Questo report genera l'elenco di tutte le richieste di iscrizione ai corsi, includendo i dati sulla privacy e GDPR (IP Address, data, ora, ecc..)</p>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-xs-6 col-md-6">
                  <div class="mt-element-list">
                    <div class="mt-list-head list-default green-haze">
                      <div class="row">
                        <div class="col-xs-12 col-md-12">
                          <div class="list-head-title-container">
                            <h3 class="list-title uppercase sbold">Camp</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-list-container list-news">
                      <ul>
                        <li class="mt-list-item">
                          <div class="list-icon-container"><a [routerLink]="['/admin/reports/campsignuprequest']"><i class="fa fa-angle-right"></i></a></div>
                          <!-- <div class="list-datetime">Dal 2017 ad oggi</div> -->
                          <div class="list-item-content">
                            <h3 class="uppercase bold">
                              <a [routerLink]="['/admin/reports/campsignuprequest']">Report Signup Request</a>
                            </h3>
                            <p>Questo report genera l'elenco di tutte le richieste di iscrizione ai camp, includendo i dati sulla privacy e GDPR (IP Address, data, ora, ecc..)</p>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- end: prima colonna -->
      </div>
      <!-- end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
