import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ActorApplicationService } from 'app/_services';

@Component({
  selector: 'app-actors-applications-editions',
  templateUrl: './actors-applications-editions.component.html',
  styleUrls: ['./actors-applications-editions.component.css']
})
export class ActorsApplicationsEditionsComponent implements OnInit {

  @Input() editionList: any[];
  @Output() editionUpdated = new EventEmitter;

  public rows: any[] = null;
  selectedRow: any;

  addNewEdition: boolean = false;
  modelNewEdition: any = {};

  constructor(private actorService: ActorApplicationService) {}

  ngOnInit() {

    this.init()
  }

  init()
  {
    if (this.editionList == null || this.editionList.length == 0)
    {
      this.actorService.getAllEditions( (editions) => {
        this.editionList = editions;

        this.updateTable();
      });
    }
    else
    this.updateTable();
  }


  updateTable()
  {
    for (let edition of this.editionList){
      edition.active = !!+edition.active
    }
    this.rows = JSON.parse(JSON.stringify(this.editionList));
  }

  //  aggiunge una nuova edizione
  addEdition() {
    this.addNewEdition = true;
  }

  cancelEdition() {
    this.addNewEdition = false;
    this.modelNewEdition = {};
  }

  submit() {
    this.actorService.saveEdition(this.modelNewEdition, () => {

      this.cancelEdition();
      this.editionUpdated.emit(true);
    });
  }

  //  seleziona una nuova edizione presente nella tabella
  selectRow(row) {
    this.selectedRow = JSON.parse(JSON.stringify(row));
  }

  //  salva l'edizione corrente
  updateRow(row) {

    if (this.selectedRow.title == row.title && this.selectedRow.active == row.active)
      this.selectedRow = null;
    else
    {
      this.actorService.updateEdition(this.selectedRow, () => {
        this.editionUpdated.emit(true);
      })
    }
  }


  getActorService(){
    return this.actorService;
  }

}
