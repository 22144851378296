import { WarehouseService } from './../../_services/warehouse.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-warehouse-shipment-materials',
  templateUrl: './warehouse-shipment-materials.component.html',
  styleUrls: ['./warehouse-shipment-materials.component.css']
})
export class WarehouseShipmentMaterialsComponent implements OnInit {

  @Input() currentShipment: any;

  rows = [];
  subscribe: any;

  constructor(private warehouseService: WarehouseService) { }

  ngOnInit() {

    this.rows = [];

    this.loadMaterialList();
  }



  loadMaterialList ()
  {
    if (this.currentShipment.material_list != null && typeof this.currentShipment.material_list != "undefined")
      this.rows = this.currentShipment.material_list;
    else
    {
        this.warehouseService.getShipmentMaterials(this.currentShipment, (shipmentMaterials) => {
          this.currentShipment.material_list = shipmentMaterials;
          this.rows = this.currentShipment.material_list;
        })
    }
  }



  getWarehouseService () {
    return this.warehouseService;
  }
}
