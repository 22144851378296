import { Component, OnInit } from '@angular/core';
import { EnterpriseService, ConfigService } from '../../_services/index';

@Component({
  selector: 'app-enterprise-document-list',
  templateUrl: './enterprise-document-list.component.html',
  styleUrls: ['./enterprise-document-list.component.css']
})
export class EnterpriseDocumentListComponent implements OnInit {

  enterpriseDocumentList: any;
  enterpriseDocumentListKey: any[];

  subscribe: any;

  constructor(private enterpriseService: EnterpriseService, private configService: ConfigService) { }

  ngOnInit() {

    this.enterpriseDocumentList = this.enterpriseService.currentEnterpriseDocumentList;

    if (this.enterpriseDocumentList == null)
      this.addNew();

    this.subscribe = this.enterpriseService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      if (this.enterpriseService.currentEnterpriseDocumentList != null) {

        this.enterpriseDocumentList = this.enterpriseService.currentEnterpriseDocumentList;
        this.enterpriseDocumentListKey = Object.keys(this.enterpriseDocumentList).reverse();

        this.enterpriseDocumentListKey.forEach(k => {

          // devo aggiornare manualmente questo campo perché il model non si aggiorna
          //  Se esiste il path per l'immagine costruisco l'url completo
          if (this.enterpriseDocumentList[k].file_path)
            this.enterpriseDocumentList[k].documentUrl = this.configService.blobBasePath + this.enterpriseDocumentList[k].file_path;
        });
      }

      if (this.enterpriseService.currentEnterpriseDocumentList != null && this.enterpriseService.currentEnterpriseDocumentList.length == 0)
        this.addNew();

    });
  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }

  addNew() {

    if (this.enterpriseDocumentList == null) {
      this.enterpriseDocumentList = {};
      this.enterpriseDocumentListKey = new Array();
    }

    this.enterpriseDocumentList[0] = {};
    this.enterpriseDocumentListKey.unshift(0);
  }

  formSaved(event) {

  }

  getEnterpriseService() {
    return this.enterpriseService
  }
}
