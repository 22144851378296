<accordion *ngIf="rows && dateTimeline">
    <accordion-group #groupConvenzioniWidget [isOpen]="false">
      <div accordion-heading>
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;{{dateTimeline.length}} convezioni non firmate in {{ rows.denomination}} per i prossimi 7 giorni
        <i class="pull-right float-xs-right glyphicon" [ngClass]="{'glyphicon-chevron-down': groupConvenzioniWidget?.isOpen, 'glyphicon-chevron-right': !groupConvenzioniWidget?.isOpen}"></i>
      </div>
      <div class="note note-danger" *ngFor="let event of dateTimeline | mapFromKeyValue; let first = first; let i = index;">
          <h4 class="block"><i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;{{event.date_formatted}} <small><i class="fa fa-clock-o"></i>&nbsp;{{event.time}}</small></h4>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">
                  <i class="fa fa-square" [style.color]="getConfigService().getTheatrinoStatusColorByKey(event.status).primary"
                    aria-hidden="true"></i> {{getConfigService().getTheatrinoStatusValueByKey(event.status)}}</label>
                <p><b>{{event.city}}</b> ({{event.prov}})</p>
              </div>
              <div class="form-group">
                <label class="control-label">Enterprise</label>
                <p>
                  <app-enterprise-info-show [idEnterprise]="event.enterprise_id"></app-enterprise-info-show>
                </p>
              </div>
              <div class="form-group">
                <label class="control-label">Organizer</label>
                <p>
                  <app-enterprise-info-show [idEnterprise]="event.enterprise_organizer_id"></app-enterprise-info-show>
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">Project/Show Type</label>
                <p>{{ getConfigService().getShowTypeValueByKey(event.project_type) }}</p>
              </div>
              <div class="form-group">
                <label class="control-label">
                  <i class="fa fa-clock-o"></i>&nbsp;{{event.time}}
                </label>
                <p><b>Annotation: </b> {{event.annotation}}</p>
                <p><b>Admin Annotation: </b> {{event.admin_annotation}}</p>
              </div>
            </div>
            <div class="col-md-4">
              <!-- <div class="form-group">
                <label class="control-label">Email inviate</label>
                <ol>
                  <li>...</li>
                  <li>...</li>
                  <li>...</li>
                </ol>
              </div> -->
            </div>
          </div>

          <button class="btn btn-block btn-outline blue" (click)="editTourDate(event.id)"><i class="fa fa-edit"></i>&nbsp;Vai alla prenotazione</button>
        </div>
    </accordion-group>
  </accordion>
