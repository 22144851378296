import { NgModule, ErrorHandler, Injectable } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
registerLocaleData(localeIt, 'it', localeItExtra);

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { CustomFormsModule } from 'ngx-custom-validators';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as Sentry from "@sentry/browser";
// import { HotjarModule } from 'ng-hotjar';
import { DndModule } from 'ngx-drag-drop'
// import { EmbedVideo } from 'ngx-embed-video';


import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { SimpleNotificationsModule } from 'angular2-notifications';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { FileUploadModule } from 'ng2-file-upload';
import { CountryPickerModule } from './country-picker/country-picker.module';

import { AuthGuard } from './_guards/index';

import { AuthenticationService, UserService, LoggedService, BackendService, HelperService, ConfigService, WarehouseService, ReminderService } from './_services/index';
import { TutorApplicationService, ActorApplicationService, FacilitatorApplicationService, AddressService, PrimanotaService, CourseScheduleService } from './_services/index';
import { PersonService, EnterpriseService, GroupService, ShowService, TheatrinoService, CampService, RevisionService, TutorService, CourseService, ShipmentService } from './_services/index';
import { VehicleService } from './_services/index';
import { PaymentsService } from './_services/index'
import { CheckVersionService } from './_services/index';
import { ResetServicesService } from './_services/index';
import { AdminReportsService } from './_services/index';

import { HomeComponent } from './home/index';
import { HeaderComponent } from './header/index';
import { SidebarComponent } from './sidebar/index';
import { LoginComponent } from './login/index';
import { AdminReportsComponent, AdminReportsDiaryComponent, AdminReportsRevisionComponent, AdminReportsCourseSignupRequestComponent, AdminReportsCampSignupRequestComponent } from './admin-reports/index';
import { PersonComponent, PersonHomeComponent, PersonVehicleComponent } from './person/index';
import {
  PersonInfoFormComponent, PersonAccountFormComponent, PersonFamilyFormComponent, PersonInfoShowComponent,
  PersonAddressFormComponent, PersonAddressListComponent,
  PersonRoleFormComponent, PersonRoleListComponent,
  PersonContactFormComponent, PersonContactListComponent,
  PersonBankAccountFormComponent, PersonBankAccountListComponent,
  PersonDocumentFormComponent, PersonDocumentListComponent,
  PersonDiaryFormComponent, PersonDiaryListComponent,
  PersonSearchFormComponent, PersonDatatableComponent,
  PersonSearchResultsComponent, PersonInfoSummaryComponent,
  PersonAddNewComponent,
  PersonHistoryComponent, PersonRevisionComponent,
  PersonCashflowComponent, PersonReminderComponent,
  PersonTutorFormComponent, PersonCampTransferDetailFormComponent, PersonCampTransferDetailListComponent,
  PersonApplicationsComponent
} from './person/index';
import {
  EnterpriseComponent, EnterpriseHomeComponent, EnterpriseInfoFormComponent, EnterpriseAddressFormComponent,
  EnterpriseContactFormComponent, EnterpriseBankAccountFormComponent,
  EnterpriseDocumentFormComponent, EnterpriseAddressListComponent,
  EnterpriseContactListComponent, EnterpriseBankAccountListComponent,
  EnterpriseDocumentListComponent, EnterpriseSearchFormComponent,
  EnterpriseAccomodationFormComponent, EnterpriseInfoShowComponent,
  EnterpriseSearchResultsComponent, EnterpriseDatatableComponent,
  EnterpriseInfoSummaryComponent, EnterpriseAgreementListComponent,
  EnterpriseRevisionComponent, EnterpriseCashflowComponent, EnterpriseHistoryComponent,
  EnterpriseReminderComponent
} from './enterprise/index';
import {
  TheatrinoComponent, TheatrinoTourInfoFormComponent, TheatrinoHomeComponent, TheatrinoSearchResultComponent,
  TheatrinoDatatableComponent, TheatrinoSearchFormComponent,
  TheatrinoBookingInfoFormComponent, TheatrinoTourBookingListComponent,
  TheatrinoBookingShowInfoFormComponent, TheatrinoBookingShowListComponent,
  TheatrinoTourBookingCalendarComponent, TheatrinoTourBookingCalendarDayComponent, TheatrinoBookingCopyComponent,
  TheatrinoTourAccomodationListComponent, TheatrinoAccomodationMultiFormModalComponent,
  TheatrinoGroupSchedulingComponent, TheatrinoGroupSchedulingReviewComponent,
  TheatrinoRevisionComponent, TheatrinoReportsComponent, TheatrinoReportsShowComponent, TheatrinoReportsReferentiComponent,
  TheatrinoWidgetConvenzioniComponent, TheatrinoBookingReminderComponent
} from './theatrino/index';
import { TourComponent } from './tour/index';
import { ActorGroupComponent } from './actor-group/index';
import {
  ActorGroupHomeComponent, ActorGroupInfoFormComponent, ActorGroupSearchFormComponent,
  ActorGroupDatatableComponent, ActorGroupSearchResultComponent,
  ActorGroupInfoShowComponent, ActorGroupCashflowComponent,
  ActorGroupCashflowPageComponent, ActorGroupShipmentsListComponent, ActorGroupVehicleComponent,
  ActorGroupHistoryComponent, ActorGroupReminderComponent
} from './actor-group/index';
import { GroupMemberInfoShowComponent } from './group/index';
import {
  ShowComponent, ShowHomeComponent, ShowSearchFormComponent, ShowSearchResultComponent,
  ShowDatatableComponent, ShowInfoFormComponent
} from './show/index';
import { AgreementComponent, AgreementCampComponent, AgreementInfoFormComponent, AgreementCampInfoFormComponent, EnterpriseTheatrinoAgreementListComponent, EnterpriseCampAgreementListComponent } from './agreement/index';
import { PrimanotaAddFormComponent, PrimanotaShowComponent, PrimanotaStatsComponent, PrimanotaSearchComponent } from './primanota/index';
import { ReminderAddFormComponent, ReminderShowComponent, ReminderWidgetAgendaComponent, ReminderWidgetAlertboxComponent, ReminderSmartButtonComponent } from './reminder/index';
import {
  CampComponent, CampHomeComponent, CampSearchResultComponent,
  CampDatatableComponent, CampSearchFormComponent, CampInfoFormComponent,
  CampStaffComponent, CampStaffDatatableComponent, CampStaffFormComponent,
  CampDiaryFormComponent, CampDiaryListComponent, CampCashflowComponent,
  CampSignupListComponent, CampSubscriberFormComponent, CampSubscriberFormNewComponent, CampSubscriberFormFeeDiscountComponent, CampSubscriberListComponent,
  CampCalendarComponent, CampCalendarFilterComponent,
  CampDocumentsComponent, CampDocumentListComponent, CampShipmentsListComponent,
  CampRevisionComponent, CampQuoteComponent, CampGroupsComponent, CampGroupsDndComponent, CampGroupsAllWeekComponent,
  CampReportsComponent, CampReportsEtaSubscribersComponent,
  CampReportsCampmembersComponent, CampReminderComponent, CampPersonTransferDetailsComponent,
  CampPaymentRequestsListComponent
} from './camp/index';
import {
  WarehouseHomeComponent, WarehouseProductDatatableComponent, WarehouseProductFormComponent,
  WarehouseShipmentComponent, WarehouseShipmentDatatableComponent, WarehouseShipmentMaterialsComponent
} from './warehouse/index';
import {
  CourseComponent, CourseHomeComponent, CourseSearchFormComponent, CourseSearchResultComponent,
  CourseDatatableComponent, CourseInfoFormComponent, CourseInfoShowComponent
} from './course/index';

import {
  ShipmentMaterialSearchFormComponent, ShipmentMaterialDatatableComponent, ShipmentMaterialsComponent,
  ShipmentMaterialFormComponent
} from './shipments/index';

import {
  CourseScheduleHomeComponent, CourseScheduleDatatableComponent, CourseScheduleSearchFormComponent, CourseScheduleSearchResultComponent,
  CourseScheduleComponent, CourseScheduleInfoFormComponent, CourseScheduleQuoteComponent, CourseScheduleDiaryFormComponent, CourseScheduleDiaryListComponent,
  CourseScheduleCashflowComponent, CourseScheduleRevisionComponent, CourseScheduleStaffComponent, CourseScheduleStaffDatatableComponent, CourseScheduleStaffFormComponent,
  CourseScheduleSubscriberListComponent, CourseScheduleSubscriberFormComponent, CourseScheduleRoomsComponent, CourseScheduleSearchResultCalendarComponent,
  CourseScheduleSignupListComponent, CourseScheduleCopyComponent, CourseScheduleSubscriberCopyComponent, CourseScheduleGroupsComponent, CourseScheduleSubscriberGroupsComponent,
  CourseScheduleStaffGroupsComponent, CourseScheduleReminderComponent, CourseScheduleSubscriberFormNewComponent, CourseSchedulePaymentRequestsListComponent
} from './course-schedule/index';

import {
  VehicleComponent, VehicleHomeComponent, VehicleSearchFormComponent, VehicleDatatableComponent, VehicleSearchResultsComponent,
  VehicleInfoFormComponent, VehicleInfoSummaryComponent, VehicleRevisionComponent,
  VehicleAssignmentComponent, VehicleAssignmentAddFormComponent, VehicleAssignmentDatatableComponent, VehiclePenaltiesListComponent,
  VehicleDiaryFormComponent, VehicleDiaryListComponent,
  VehicleMaterialAssignmentComponent, VehicleMaterialAssignmentAddFormComponent, VehicleMaterialAssignmentDatatableComponent,
  VehiclePenaltyInfoFormComponent, VehicleCalendarComponent, VehicleExpirationListComponent, VehicleExpirationAddFormComponent, VehicleReminderComponent
} from './vehicle/index';

import { PaymentRequestFormComponent, PaymentsSearchComponent } from './payments/index';

// Utility
import { CheckPlatformCompatibilityComponent } from './utility/check-platform-compatibility/check-platform-compatibility.component'
import { CheckPersonTaxidComponent } from './utility/check-person-taxid/check-person-taxid.component';

// Directive
import { CheckPermissionModule } from './_directives/check-permission/check-permission.module';
import { NospaceDirective } from './_directives/nospace.directive';

// Pipes
import { MapFromKeyValuePipe } from './_pipes/MapFromKeyValuePipe';
import { ZeroCurrencyPipe } from './_pipes/ZeroCurrency.pipe';
import { SafeUrlPipe } from './_pipes/SafeUrlPipe';

import { environment } from './environment';

import {
  TutorsApplicationsComponent,
  TutorsApplicationsEditionsComponent,
  TutorsApplicationsSearchFormComponent,
  TutorsApplicationsSearchResultsComponent,
  TutorsApplicationsHomeComponent,
  TutorsApplicationsPhaseComponent,
  TutorsApplicationsDatatableComponent,
  TutorsApplicationsInfoSummaryComponent,
  TutorsApplicationEditComponent
} from './tutors-applications/index';

import {
  ActorsApplicationsComponent,
  ActorsApplicationsEditionsComponent,
  ActorsApplicationsHomeComponent,
  ActorsApplicationsPhaseComponent,
  ActorsApplicationEditComponent,
  ActorsApplicationsInfoSummaryComponent,
  ActorsApplicationsPhaseTableComponent,
} from './actors-applications/index';

import {
  FacilitatorsApplicationsComponent,
  FacilitatorsApplicationsHomeComponent,
  FacilitatorsApplicationsPhaseComponent,
  FacilitatorsApplicationsPhaseTableComponent,
  FacilitatorsApplicationsInfoSummaryComponent,
  FacilitatorsApplicationEditComponent,
  FacilitatorsApplicationsEditionsComponent,
} from './facilitators-applications/index';

import { PersonAvailabilityFormComponent } from './person/person-availability-form/person-availability-form.component';
import { CampWeekTutorsComponent } from './camp/camp-week-tutors/camp-week-tutors.component';
import { AgendaComponent } from './agenda/agenda.component';

import { enableProdMode } from '@angular/core';
import { CourseScheduleTableDateComponent } from './course-schedule/course-schedule-table-date/course-schedule-table-date.component';
import { CourseScheduleTableDateRowComponent } from './course-schedule/course-schedule-table-date-row/course-schedule-table-date-row.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CampTutorRecapComponent } from './camp/camp-tutor-recap/camp-tutor-recap.component';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: environment.sentry,
    release: environment.version
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {

    console.error(error.originalError || error);

    if (environment.production) {
      const eventId = Sentry.captureException(error.originalError || error);
      Sentry.showReportDialog({ eventId });
    }
  }
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    // CustomFormsModule,
    HttpClientModule,
    AngularMyDatePickerModule,
    routing,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    SimpleNotificationsModule.forRoot(),
    FileUploadModule,
    NgxDatatableModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    CountryPickerModule,
    ButtonsModule.forRoot(),
    RatingModule.forRoot(),
    TypeaheadModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    AccordionModule.forRoot(),
    CheckPermissionModule,
    //HotjarModule,
    DndModule,
    // EmbedVideo.forRoot()
  ],
  declarations: [
    AppComponent,
    MapFromKeyValuePipe,
    ZeroCurrencyPipe,
    SafeUrlPipe,
    NospaceDirective,
    HomeComponent,
    HeaderComponent,
    SidebarComponent,
    LoginComponent,
    AdminReportsComponent,
    AdminReportsDiaryComponent,
    AdminReportsRevisionComponent,
    AdminReportsCourseSignupRequestComponent,
    AdminReportsCampSignupRequestComponent,
    PersonComponent,
    PersonHomeComponent,
    PersonInfoFormComponent,
    PersonInfoShowComponent,
    PersonAccountFormComponent,
    PersonFamilyFormComponent,
    PersonRoleFormComponent,
    PersonRoleListComponent,
    PersonContactFormComponent,
    PersonContactListComponent,
    PersonBankAccountFormComponent,
    PersonBankAccountListComponent,
    PersonAddressFormComponent,
    PersonAddressListComponent,
    PersonDiaryFormComponent,
    PersonDiaryListComponent,
    PersonDocumentFormComponent,
    PersonDocumentListComponent,
    PersonDatatableComponent,
    PersonSearchResultsComponent,
    PersonSearchFormComponent,
    PersonAddNewComponent,
    PersonInfoSummaryComponent,
    PersonHistoryComponent,
    PersonRevisionComponent,
    PersonCashflowComponent,
    PersonTutorFormComponent,
    PersonReminderComponent,
    PersonVehicleComponent,
    PersonCampTransferDetailListComponent,
    PersonCampTransferDetailFormComponent,
    PersonApplicationsComponent,
    EnterpriseComponent,
    EnterpriseHomeComponent,
    EnterpriseInfoFormComponent,
    EnterpriseAddressFormComponent,
    EnterpriseContactFormComponent,
    EnterpriseBankAccountFormComponent,
    EnterpriseDocumentFormComponent,
    EnterpriseAddressListComponent,
    EnterpriseContactListComponent,
    EnterpriseBankAccountListComponent,
    EnterpriseDocumentListComponent,
    EnterpriseAccomodationFormComponent,
    EnterpriseSearchFormComponent,
    EnterpriseSearchResultsComponent,
    EnterpriseDatatableComponent,
    EnterpriseInfoShowComponent,
    EnterpriseInfoSummaryComponent,
    EnterpriseAgreementListComponent,
    EnterpriseRevisionComponent,
    EnterpriseCashflowComponent,
    EnterpriseHistoryComponent,
    EnterpriseReminderComponent,
    GroupMemberInfoShowComponent,
    ActorGroupComponent,
    ActorGroupHomeComponent,
    ActorGroupInfoFormComponent,
    ActorGroupSearchFormComponent,
    ActorGroupDatatableComponent,
    ActorGroupSearchResultComponent,
    ActorGroupInfoShowComponent,
    ActorGroupCashflowComponent,
    ActorGroupCashflowPageComponent,
    ActorGroupShipmentsListComponent,
    ActorGroupVehicleComponent,
    ActorGroupHistoryComponent,
    ActorGroupReminderComponent,
    ShowComponent,
    ShowHomeComponent,
    ShowInfoFormComponent,
    ShowSearchFormComponent,
    ShowSearchResultComponent,
    ShowDatatableComponent,
    TheatrinoComponent,
    TheatrinoTourInfoFormComponent,
    TheatrinoHomeComponent,
    TheatrinoSearchResultComponent,
    TheatrinoSearchFormComponent,
    TheatrinoDatatableComponent,
    TheatrinoBookingInfoFormComponent,
    TheatrinoTourBookingListComponent,
    TheatrinoBookingShowListComponent,
    TheatrinoBookingShowInfoFormComponent,
    TheatrinoTourBookingCalendarComponent,
    TheatrinoTourBookingCalendarDayComponent,
    TheatrinoBookingCopyComponent,
    TheatrinoTourAccomodationListComponent,
    TheatrinoGroupSchedulingComponent,
    TheatrinoGroupSchedulingReviewComponent,
    TheatrinoAccomodationMultiFormModalComponent,
    TheatrinoRevisionComponent,
    TheatrinoReportsComponent,
    TheatrinoReportsShowComponent,
    TheatrinoReportsReferentiComponent,
    TheatrinoWidgetConvenzioniComponent,
    TheatrinoBookingReminderComponent,
    TourComponent,
    CampComponent,
    CampHomeComponent,
    CampSearchResultComponent,
    CampDatatableComponent,
    CampSearchFormComponent,
    CampInfoFormComponent,
    CampStaffComponent,
    CampStaffFormComponent,
    CampStaffDatatableComponent,
    CampDiaryFormComponent,
    CampDiaryListComponent,
    CampCashflowComponent,
    CampSignupListComponent,
    CampSubscriberFormComponent,
    CampSubscriberFormNewComponent,
    CampSubscriberFormFeeDiscountComponent,
    CampSubscriberListComponent,
    CampCalendarComponent,
    CampCalendarFilterComponent,
    CampDocumentsComponent,
    CampDocumentListComponent,
    CampShipmentsListComponent,
    CampRevisionComponent,
    CampQuoteComponent,
    CampGroupsComponent,
    CampGroupsDndComponent,
    CampGroupsAllWeekComponent,
    CampReportsComponent,
    CampReportsEtaSubscribersComponent,
    CampReportsCampmembersComponent,
    CampReminderComponent,
    CampPersonTransferDetailsComponent,
    CampPaymentRequestsListComponent,
    PrimanotaAddFormComponent,
    PrimanotaShowComponent,
    PrimanotaStatsComponent,
    PrimanotaSearchComponent,
    ReminderAddFormComponent,
    ReminderShowComponent,
    ReminderWidgetAlertboxComponent,
    ReminderWidgetAgendaComponent,
    ReminderSmartButtonComponent,
    AgreementComponent,
    AgreementCampComponent,
    AgreementInfoFormComponent,
    AgreementCampInfoFormComponent,
    EnterpriseTheatrinoAgreementListComponent,
    EnterpriseCampAgreementListComponent,
    TutorsApplicationsComponent,
    TutorsApplicationsEditionsComponent,
    TutorsApplicationsSearchFormComponent,
    TutorsApplicationsSearchResultsComponent,
    TutorsApplicationsPhaseComponent,
    TutorsApplicationsHomeComponent,
    TutorsApplicationsDatatableComponent,
    TutorsApplicationsInfoSummaryComponent,
    TutorsApplicationEditComponent,
    ActorsApplicationsComponent,
    ActorsApplicationsEditionsComponent,
    ActorsApplicationsHomeComponent,
    ActorsApplicationsPhaseComponent,
    ActorsApplicationEditComponent,
    ActorsApplicationsInfoSummaryComponent,
    ActorsApplicationsPhaseTableComponent,
    FacilitatorsApplicationsComponent,
    FacilitatorsApplicationsHomeComponent,
    FacilitatorsApplicationsPhaseComponent,
    FacilitatorsApplicationsPhaseTableComponent,
    FacilitatorsApplicationsInfoSummaryComponent,
    FacilitatorsApplicationEditComponent,
    FacilitatorsApplicationsEditionsComponent,
    CheckPlatformCompatibilityComponent,
    CheckPersonTaxidComponent,
    PersonAvailabilityFormComponent,
    CampWeekTutorsComponent,
    ShipmentMaterialSearchFormComponent,
    ShipmentMaterialDatatableComponent,
    ShipmentMaterialsComponent,
    ShipmentMaterialFormComponent,
    WarehouseHomeComponent,
    WarehouseProductDatatableComponent,
    WarehouseProductFormComponent,
    WarehouseShipmentComponent,
    WarehouseShipmentDatatableComponent,
    WarehouseShipmentMaterialsComponent,
    CourseComponent,
    CourseHomeComponent,
    CourseInfoFormComponent,
    CourseSearchFormComponent,
    CourseSearchResultComponent,
    CourseDatatableComponent,
    CourseInfoShowComponent,
    CourseScheduleHomeComponent,
    CourseScheduleDatatableComponent,
    CourseScheduleSearchFormComponent,
    CourseScheduleSearchResultComponent,
    CourseScheduleComponent,
    CourseScheduleInfoFormComponent,
    CourseScheduleQuoteComponent,
    CourseScheduleDiaryListComponent,
    CourseScheduleDiaryFormComponent,
    CourseScheduleCashflowComponent,
    CourseScheduleRevisionComponent,
    CourseScheduleStaffComponent,
    CourseScheduleStaffDatatableComponent,
    CourseScheduleStaffFormComponent,
    CourseScheduleSubscriberListComponent,
    CourseScheduleSubscriberFormComponent,
    CourseScheduleSubscriberFormNewComponent,
    CourseScheduleRoomsComponent,
    CourseScheduleSearchResultCalendarComponent,
    CourseScheduleSignupListComponent,
    CourseScheduleCopyComponent,
    CourseScheduleSubscriberCopyComponent,
    CourseScheduleGroupsComponent,
    CourseScheduleSubscriberGroupsComponent,
    CourseScheduleStaffGroupsComponent,
    CourseScheduleReminderComponent,
    CourseSchedulePaymentRequestsListComponent,
    VehicleComponent,
    VehicleHomeComponent,
    VehicleSearchFormComponent,
    VehicleDatatableComponent,
    VehicleSearchResultsComponent,
    VehicleInfoFormComponent,
    VehicleInfoSummaryComponent,
    VehicleRevisionComponent,
    VehicleAssignmentComponent,
    VehicleAssignmentAddFormComponent,
    VehicleAssignmentDatatableComponent,
    VehicleCalendarComponent,
    VehicleReminderComponent,
    VehiclePenaltiesListComponent,
    VehiclePenaltyInfoFormComponent,
    VehicleExpirationListComponent,
    VehicleExpirationAddFormComponent,
    VehicleDiaryFormComponent,
    VehicleDiaryListComponent,
    VehicleMaterialAssignmentComponent,
    VehicleMaterialAssignmentAddFormComponent,
    VehicleMaterialAssignmentDatatableComponent,
    AgendaComponent,
    PaymentRequestFormComponent,
    PaymentsSearchComponent,
    CourseScheduleTableDateComponent,
    CourseScheduleTableDateRowComponent,
    CampTutorRecapComponent,
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    HelperService,
    ConfigService,
    UserService,
    LoggedService,
    BackendService,
    PersonService,
    EnterpriseService,
    GroupService,
    ShowService,
    TheatrinoService,
    CampService,
    RevisionService,
    AddressService,
    PrimanotaService,
    TutorApplicationService,
    ActorApplicationService,
    FacilitatorApplicationService,
    TutorService,
    WarehouseService,
    CourseService,
    CourseScheduleService,
    ShipmentService,
    VehicleService,
    PaymentsService,
    ReminderService,
    CheckVersionService,
    ResetServicesService,
    AdminReportsService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
