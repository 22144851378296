import { Component, OnInit, Input } from '@angular/core';
import { PrimanotaService, ConfigService } from './../../_services/index';

@Component({
  selector: 'app-primanota-stats',
  templateUrl: './primanota-stats.component.html',
  styleUrls: ['./primanota-stats.component.css']
})
export class PrimanotaStatsComponent implements OnInit {

  subscribe: any;
  model: any = {};
  loadingData: boolean = false;

  pageTotalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotalPages: number = 0;
  pageSort: any = null;
  rows: any[] = [];
  total_addin = 0;
  total_addout = 0;
  total_addin_perc = "0%";
  total_addout_perc = "0%";
  total_by_causal = [];
  total_by_registered_via = [];
  total_by_holder = [];
  total_by_holder_type = [];
  selected = [];
  selectedRow: any;
  selectedHolderType: string;
  selectedHolderId: number;
  selectedHolderName: string;


  @Input() holderType: string;
  @Input() holderName: string;
  @Input() holderId: number;

  constructor(private primanotaService: PrimanotaService, private configService: ConfigService) { }

  ngOnInit() {

    this.loadingData = true;

    this.subscribe = this.primanotaService.getsubjectToUpdatePrimaNotaObservable().subscribe((res: any) => {

      this.rows = this.primanotaService.getTableData(res, this.holderId, this.holderType);

      this.updateStatsFromData(this.rows);

      this.loadingData = false;
    });

    //this.primanotaService.getPrimaNota(this.holderType, this.holderId);
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }



  updateStatsFromData(data){

    let _total_addin = 0;
    let _total_addout = 0;
    let _total_addin_perc = 0;
    let _total_addout_perc = 0;
    let _total_by_causal = {};
    let _total_by_registered_via = {};
    let _total_by_holder = {};
    let _total_by_holder_type = {};

    for (let key in data){

      let r = data[key];

      if (r.in_amount > 0){
        _total_addin += r.in_amount;
      }

      if (r.out_amount > 0){
        _total_addout += r.out_amount;
      }

      if (r.causal){
        if (!_total_by_causal[r.causal_description]){
          _total_by_causal[r.causal_description] = {
            "name": r.causal_description,
            "amount": 0
          };
        }

        if (r.in_amount)
          _total_by_causal[r.causal_description].amount += r.in_amount;

        if (r.out_amount)
          _total_by_causal[r.causal_description].amount -= r.out_amount;
      }

      if (r.registered_via){
        if (!_total_by_registered_via[r.registered_via_description]){
          _total_by_registered_via[r.registered_via_description] = {
            "name": r.registered_via_description,
            "amount": 0
          };
        }

        if (r.in_amount)
          _total_by_registered_via[r.registered_via_description].amount += r.in_amount;

        if (r.out_amount)
          _total_by_registered_via[r.registered_via_description].amount -= r.out_amount;
      }

      // NON FUNZIONA, da fixare
      // if (r.in_holder_type){
      //   if (!_total_by_holder[r.in_holder_name]){
      //     _total_by_holder[r.in_holder_name] = {
      //       "name": r.in_holder_name,
      //       "amount": 0
      //     };
      //   }

      //   if (r.in_amount)
      //     _total_by_holder[r.in_holder_name].amount += r.in_amount;
      //   // if (r.out_amount)
      //   //   _total_by_holder[r.in_holder_name].amount -= r.out_amount;

      //   if (!_total_by_holder_type[r.in_holder_type]){
      //     _total_by_holder_type[r.in_holder_type] = {
      //       "name": r.in_holder_type,
      //       "amount": 0
      //     };
      //   }

      //   if (r.in_amount)
      //     _total_by_holder_type[r.in_holder_type].amount += r.in_amount;
      //   // if (r.out_amount)
      //   //   _total_by_holder_type[r.in_holder_type].amount -= r.out_amount;
      // }

      // if (r.out_holder_type){
      //   if (!_total_by_holder[r.out_holder_name]){
      //     _total_by_holder[r.out_holder_name] = {
      //       "name": r.out_holder_name,
      //       "amount": 0
      //     };
      //   }

      //   if (r.out_amount)
      //     _total_by_holder[r.out_holder_name].amount -= r.out_amount;
      //   // if (r.in_amount)
      //   //   _total_by_holder[r.out_holder_name].amount -= r.in_amount;

      //   if (!_total_by_holder_type[r.out_holder_type]){
      //     _total_by_holder_type[r.out_holder_type] = {
      //       "name": r.out_holder_type,
      //       "amount": 0
      //     };
      //   }

      //   if (r.out_amount)
      //     _total_by_holder_type[r.out_holder_type].amount -= r.out_amount;
      //   // if (r.in_amount)
      //   //   _total_by_holder_type[r.out_holder_type].amount -= r.in_amount;
      // }

    }

    _total_addin_perc = _total_addin * 100 / (_total_addin+_total_addout);
    _total_addout_perc = _total_addout * 100 / (_total_addin+_total_addout);

    this.total_addin = _total_addin;
    this.total_addout = _total_addout;
    this.total_addin_perc = ""+(Math.round(_total_addin_perc * 100) / 100)+"%";
    this.total_addout_perc = ""+(Math.round(_total_addout_perc * 100) / 100)+"%";

    this.total_by_causal = Object.keys(_total_by_causal).map(function (key) { return _total_by_causal[key]; });
    this.total_by_registered_via = Object.keys(_total_by_registered_via).map(function (key) { return _total_by_registered_via[key]; });
    this.total_by_holder = Object.keys(_total_by_holder).map(function (key) { return _total_by_holder[key]; });
    this.total_by_holder_type = Object.keys(_total_by_holder_type).map(function (key) { return _total_by_holder_type[key]; });

  }

  onSelect({ selected }) {
    this.selectedRow = Object.assign({}, selected[0]);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  getRowClass(row) {

    // if (!row.role){
    //   return {'no-role-row': true};
    // }
  }

}
