<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/vehicle/home']">Vehicle</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span *ngIf="!getVehicleService().currentVehicleId">New</span>
            <span *ngIf="getVehicleService().currentVehicleId">Edit</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>

      <app-reminder-widget-alertbox *ngIf="holderId" [holderType]="holderType" [holderId]="holderId"
        [holderName]="holderName"></app-reminder-widget-alertbox>

      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title" *ngIf="!getVehicleService().currentVehicleId">New Vehicle</h1>
      <h1 class="page-title" *ngIf="getVehicleService().currentVehicleId">Edit Vehicle
        {{getVehicleService().currentVehicleInfo?.brand_model}}
        {{getVehicleService().currentVehicleInfo?.brand_name}}
      </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">

            <!-- <div class="portlet-title tabbable-line">
                <div class="caption caption-md">
                  <i class="icon-globe theme-font hide"></i>
                  <span class="caption-subject font-blue-madison bold uppercase">Vehicle</span>
                </div>
              </div> -->

            <!--end: Portlet-Body -->
            <div class="portlet-body form">

              <alert type="warning" *ngIf="loadingData">
                <strong>Loading</strong> ...
              </alert>

              <tabset #vehicleTabs class="align-right portlet-title tabbable-line" [hidden]="loadingData">
                <tab heading="Info">
                  <app-vehicle-info-form [vehicleComponent]="this"></app-vehicle-info-form>
                </tab>
                <tab>
                  <ng-template tabHeading>
                    <span class="text-warning">
                      <i *ngIf="getVehicleService().expirationDeadlineExpired" class="fa fa-exclamation-triangle"
                        aria-hidden="true"></i>
                    </span>
                    Expirations
                  </ng-template>
                  <app-vehicle-expiration-list *ngIf="staticTabs?.tabs[1]?.active"></app-vehicle-expiration-list>
                </tab>
                <tab>
                  <ng-template tabHeading>
                    <span class="text-warning">
                      <i *ngIf="getVehicleService().penaltyToPay" class="fa fa-exclamation-triangle"
                        aria-hidden="true"></i>
                    </span>
                    Penalties
                  </ng-template>
                  <app-vehicle-penalties-list *ngIf="staticTabs?.tabs[2]?.active"></app-vehicle-penalties-list>
                </tab>
                <tab heading="Assignments">
                  <app-vehicle-assignment *ngIf="staticTabs?.tabs[3]?.active"></app-vehicle-assignment>
                </tab>
                <tab heading="Materials">
                  <app-vehicle-material-assignment *ngIf="staticTabs?.tabs[4]?.active">
                  </app-vehicle-material-assignment>
                </tab>
                <tab heading="Reminder" (select)="tabSelectedReminder($event)">
                  <app-vehicle-reminder #reminderTabComponent></app-vehicle-reminder>
                </tab>
                <tab heading="Diary">
                  <app-vehicle-diary-list *ngIf="getUserService().checkPermission('vehicle_edit')">
                  </app-vehicle-diary-list>
                </tab>
                <tab heading="Revision">
                  <app-vehicle-revision *ngIf="getUserService().checkPermission('vehicle_revisions')">
                  </app-vehicle-revision>
                </tab>
              </tabset>

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
