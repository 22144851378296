<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
  <div class="form-body">

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Theatrino
            <span class="required">*</span>
          </label>
          <select class="form-control" name="theatrino" [(ngModel)]="model.theatrino_id" #theatrino="ngModel" (ngModelChange)="onSelectChange($event)"
            [disabled]="loadingData || getTheatrinoService().isOngoingRequest()" required>
            <option *ngFor="let t of theatrinoList" [value]="t.theatrino_id">{{t.denomination}}</option>
          </select>
          <span class="help-block">Select Theatrino, is required</span>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!loadingData && !getTheatrinoService().isOngoingRequest()">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalData())) }">
          <label class="control-label">Tour Date <span class="required">*</span></label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_date_copy="angular-mydatepicker" name="date" class="form-control"
                [locale]="getHelper().locale"
                [options]="getDatePickerOptions()"
                [ngModel]="getLocalData()"
                (dateChanged)="onDateChanged($event)"
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.date" class="btn default" (click)="dp_date_copy.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_date_copy.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block">Date required</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !timevalid) }">
          <label class="control-label">Time
            <span class="required">*</span>
          </label>
          <input type="text" name="time" [(ngModel)]="model.time" #time="ngModel" class="form-control" (input)="onTimeInput($event)"
            [typeahead]="getConfigService().timeTourSelect" (typeaheadOnSelect)="typeTimeOnSelect($event)" typeaheadOptionField="value"
            placeholder="10:30" [disabled]="loadingData || getTheatrinoService().isOngoingRequest()" required autocomplete="off">
          <span class="help-block"> Time is required</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Also copy the shows</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="showcopy" [disabled]="loadingData || getTheatrinoService().isOngoingRequest()" [(ngModel)]="showcopy"
                value="1"> Yes
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="form-actions right">
    <img *ngIf="getTheatrinoService().isOngoingRequest() || this.loadingData" src="./assets/global/img/input-spinner.gif" />
    <button type="submit" class="btn blue" [disabled]=" loadingData || getTheatrinoService().isOngoingRequest()">
      <span class="ladda-label">
        <i class="fa fa-clone" aria-hidden="true"></i> Copy</span>
    </button>

  </div>

</form>


<div class="bootbox modal fade bootbox-confirm in" bsModal #confirmModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body alert-warning">
        <div class="bootbox-body">
          In this day there is already a booking. <br>
          Do you want to save this too?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="confirmSubmit()" >Yes</button>
          <button type="button" class="btn btn-primary" (click)="confirmModal.hide()" >No</button>
        </div>
      </div>
    </div>
  </div>
</div>
