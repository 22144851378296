<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a>Cash Flow</a>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title">Cash Flow</h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">

            <!--end: Portlet-Body -->
            <div class="portlet-body form">

              <alert type="warning" *ngIf="loadingData">
                <strong>Loading</strong> ...
              </alert>

              <div class="row" *ngIf="model && model.id">
                <div class="col-md-12">
                  <app-primanota-add-form [holderType]="'App\\ActorGroup'" [holderId]="model.id" [holderName]="holderName" (modelUpdated)="modelUpdated($event)"></app-primanota-add-form>
                </div>
              </div>

              <div class="row" *ngIf="model && model.id">
                <div class="col-md-12">
                  <app-primanota-show #primaNotaShowComponent [holderType]="'App\\ActorGroup'" [holderId]="model.id" [holderName]="holderName"
                    [readOnlyMode]="true"></app-primanota-show>
                </div>
              </div>

              <div class="row" *ngIf="model && model.id">
                <div class="col-md-12">
                  <h4>
                    <i class="fa fa-line-chart" aria-hidden="true"></i> Statistics</h4>
                  <app-primanota-stats [holderType]="'App\\ActorGroup'" [holderId]="model.id" [holderName]="holderName"></app-primanota-stats>
                </div>
              </div>

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
