<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>

  <div class="form-body">

    <!-- BEGIN: sezione di editing -->
    <div *ngIf="!readonly">
      <!-- <h3 class="form-section">Course Type: <b>{{getConfigService().getCourseTypeValueByKey(model.item_type)}}</b></h3> -->

      <div class="row">
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">Item name</label>
            <p>
              {{model.item_name}}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label">Item description</label>
            <p>
              {{model.item_description}}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group"
            [ngClass]="{ 'has-error': (f.submitted || sendto_mail_address.dirty || sendto_mail_address.touched) && !sendto_mail_address.valid }">
            <label class="control-label">Email Address <span class="required">*</span></label>
            <input name="sendto_mail_address" [(ngModel)]="model.sendto_mail_address" #sendto_mail_address="ngModel"
              class="form-control" nospace pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$">
            <span class="help-block small">Questa è la mail alla quale verrà inviata la richiesta di pagamento</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group"
            [ngClass]="{ 'has-error': (f.submitted || sendto_mail_message.dirty || sendto_mail_message.touched) && !sendto_mail_message.valid }">
            <label class="control-label">Email Message</label>
            <textarea name="sendto_mail_message" [(ngModel)]="model.sendto_mail_message" rows="3" class="form-control"
              #sendto_mail_message></textarea>
            <span class="help-block small">Testo che andrà a completare il messaggio precompilato della mail</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-4">
          <div class="form-group"
            [ngClass]="{ 'has-error': (f.submitted || registered_via.dirty || registered_via.touched) && !registered_via.valid }">
            <label class="control-label">Registered via <span class="required">*</span></label>
            <select class="form-control" name="registered_via" [(ngModel)]="model.registered_via"
              #registered_via="ngModel" required [disabled]="true">
              <option *ngFor="let c of getConfigService().primanotaRegisteredViaSelect" [value]="c.key">{{c.value}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-xs-4">
          <div class="form-group">
            <label class="control-label">Causal</label>
            <select class="form-control" name="causal" [(ngModel)]="model.causal" (ngModelChange)="onChangeCasual($event)">
              <option value="">-</option>
              <option *ngFor="let c of casualSelect" [value]="c.key">{{c.value}}</option>
            </select>
          </div>
        </div>
        <div class="col-xs-4">
          <div class="form-group"
            [ngClass]="{ 'has-error': (f.submitted || amount.dirty || amount.touched) && !amount.valid }">
            <label class="control-label">Amount <span class="required">*</span></label>
            <input type="number" class="form-control" (input)="onInputAmount($event)" placeholder="0,00" step="0.01"
              name="amount" [(ngModel)]="model.amount" #amount="ngModel" required>
            <span class="help-block small">Field required, use "," for decimal value</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label">Disabilita il link Stripe di pagamento nell'email</label>
            <div class="mt-checkbox-inline">
              <label class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="option_disable_link_in_email" #option_disable_link_in_email="ngModel"
                  [(ngModel)]="model.option_disable_link_in_email" value="0"> Si, disabilita il pagamento tramite Stripe e la gestione automatica della prima nota
                <span></span>
              </label>
            </div>
            <!-- Course Fee alert -->
            <alert *ngIf="model.causal == 6" type="warning">
              <strong>Attenzione!</strong> Il versamento della quota corso è consentito solo tramite bonifico. Se si toglie la spunta e si consente il pagamento con carta di credito verranno addebitate commissioni bancarie non autorizzate! Parlare con l'ufficio amministrativo prima di autorizzare un pagamento con carta di credito. Grazie!
            </alert>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group"
            [ngClass]="{ 'has-error': (f.submitted || annotation.dirty || annotation.touched) && !annotation.valid }">
            <label class="control-label">Annotation (this note will appear in 'cash flow')</label>
            <textarea name="annotation" [(ngModel)]="model.annotation" rows="3" class="form-control"
              #annotation></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">Generate 'prima nota' automatically</label>
            <div class="mt-checkbox-inline">
              <label class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="primanota_auto_gen" #primanota_auto_gen="ngModel"
                  [(ngModel)]="model.primanota_auto_gen" value="1" required> Yes
                <span></span>
              </label>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- END: sezione di editing -->

    <!-- BEGIN: sezione di readonly -->
    <div *ngIf="readonly">
      <!-- <h3 class="form-section">Course Type: <b>{{getConfigService().getCourseTypeValueByKey(model.item_type)}}</b></h3> -->

      <div class="row">
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">Item name</label>
            <p>{{model.item_name}}</p>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">Item description</label>
            <p>{{model.item_description}}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-4">
          <div class="form-group">
            <label class="control-label">Email Address</label>
            <p>{{model.sendto_mail_address}}</p>
          </div>
        </div>
        <div class="col-xs-8">
          <div class="form-group">
            <label class="control-label">Email Message</label>
            <p>{{model.sendto_mail_message}}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-4">
          <div class="form-group">
            <label class="control-label">Registered via</label>
            <p>{{getConfigService().getPrimanotaRegisteredViaSelect(model.registered_via)}}</p>
          </div>
        </div>
        <div class="col-xs-4">
          <div class="form-group">
            <label class="control-label">Causal</label>
            <p>{{getConfigService().getPrimanotaCausalInSelect(model.causal)}}</p>
          </div>
        </div>
        <div class="col-xs-4">
          <div class="form-group">
            <label class="control-label">Amount</label>
            <p>{{ (model.amount | currency:'EUR':'symbol':'1.2-2':'it') || "€ 0,00" }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">Disabilita il link Stripe di pagamento nell'email</label>
            <p>
              <i *ngIf="model.option_disable_link_in_email" class="fa fa-check"></i>
              <i *ngIf="!model.option_disable_link_in_email" class="fa fa-times"></i>
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label">Annotation (this note will appear in 'cash flow')</label>
            <p>{{model.annotation}}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">Generate 'prima nota' automatically</label>
            <p>
              <i *ngIf="model.primanota_auto_gen" class="fa fa-check"></i>
              <i *ngIf="!model.primanota_auto_gen" class="fa fa-times"></i>
            </p>
          </div>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">Dati di fatturazione</label>
            <p *ngIf="!model.invoice_data">-</p>
            <p *ngIf="model.invoice_data">
              {{model.invoice_data.nome}}<br><br>
              <b>Indirizzo</b><br>
              {{model.invoice_data.indirizzo_via}}<br>
              {{model.invoice_data.indirizzo_citta}} ({{model.invoice_data.indirizzo_provincia}}) {{model.invoice_data.indirizzo_cap}}<br>
            </p>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">&nbsp;</label>
            <p *ngIf="model.invoice_data">
              <b *ngIf="model.invoice_data.cf">Codice Fiscale: </b> {{model.invoice_data.cf}}<br><br>
              <b *ngIf="model.invoice_data.piva">Partita IVA: </b> {{model.invoice_data.piva}}<br>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- END: sezione di readonly -->
  </div>



  <div class="form-actions right">
    <img *ngIf="getPaymentService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <div *ngIf="!model.id">
      <ng-template #confirmSend>
        <button type="button" class="btn btn-outline blue" (click)="sendRequest();">Yes Send!</button>
      </ng-template>
      <button type="button" class="btn btn-outline blue"
        [disabled]="getPaymentService().isOngoingRequest() || getPaymentService().isOngoingRequest()"
        [outsideClick]="true" [popover]="confirmSend" popoverTitle="Are you sure?"> <span class="ladda-label"></span>
        <i class="fa fa-send"></i> Send</button>
    </div>
    <div *ngIf="model.id">
      <ng-template #confirmSendReminder>
        <button type="button" class="btn btn-outline blue" (click)="sendReminder()">Yes Send!</button>
      </ng-template>
      <button type="button" class="btn btn-outline blue" [disabled]="getPaymentService().isOngoingRequest() || payed"
        [outsideClick]="true" [popover]="confirmSendReminder" popoverTitle="Are you sure?"> <span class="ladda-label">
          <i class="fa fa-send"></i> Send Reminder</span></button>

      <ng-template #confirmDelete>
        <button type="button" class="btn btn-outline red" (click)="deleteRequest()">Yes Delete!</button>
      </ng-template>
      <button type="button" class="btn btn-outline red" [disabled]="getPaymentService().isOngoingRequest() || readonly"
        [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"> <span class="ladda-label">
          <i class="fa fa-trash-o"></i> Delete</span></button>
    </div>

  </div>

</form>
