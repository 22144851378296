
import { TutorsApplicationsDatatableComponent } from "../tutors-applications-datatable/tutors-applications-datatable.component";
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-tutors-applications-search-results',
  templateUrl: './tutors-applications-search-results.component.html',
  styleUrls: ['./tutors-applications-search-results.component.css']
})
export class TutorsApplicationsSearchResultsComponent implements OnInit {

  @ViewChild('autoShownModal', { static: false }) public autoShownModal: ModalDirective;

  @ViewChild(TutorsApplicationsDatatableComponent, { static: true }) datatable: TutorsApplicationsDatatableComponent;
  //@ViewChildren('personInfoSummaryComponent') personShow: PersonInfoSummaryComponent;

  private subject: any;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {
      this.updateResults(params);
    });
  }

  ngOnDestroy() {
    this.subject.unsubscribe();
  }

  currentTutorApplication: any;
  tutorApplicationSelected(selected) {

    this.currentTutorApplication = selected;
    this.showModal();
  }

  updateResults(params) {

    this.datatable.query = params;
    this.datatable.getResults();
  }

  searchKeywordsSubmit(params) {
    this.updateResults(params);
  }


  // MODAL
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

}
