<a [appCheckPermission]="'theatrino_edit'" class="btn btn-primary btn-block" (click)="this.getRevision()">Get Revisions</a>

<alert type="warning" *ngIf="ongoingRequest">
  <strong>Loading</strong> ...
</alert>
<br>
<ngx-datatable
  class="striped"

  [rows]="rows"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowClass]="getRowClass"
  [rowHeight]="'auto'"
  [selected]="selected"
  [selectionType]="'single'"
  (activate)="onActivate($event)"
  (select)='onSelect($event)'
>
  <ngx-datatable-column name="Date" [flexGrow]="1" [sortable]="true" [resizeable]="false" prop="revision.created_at">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{value}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="User" [flexGrow]="1" [sortable]="true" [resizeable]="false" prop="person.name">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <a routerLink="/person/edit/{{row.person.id}}">{{value}}</a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Tour Date" [flexGrow]="1" [sortable]="true" [resizeable]="false" prop="tourDate.date">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <a *ngIf="row.tourDate.id != 0" routerLink="/theatrino/{{row.tourDate.theatrino_id}}/tour/edit/{{row.tourDate.id}}">{{value}}</a>
      <span *ngIf="row.tourDate.id == 0">Tour Created</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Action" [flexGrow]="1" [sortable]="false" [resizeable]="false" prop="revision.key">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="row.revision.key == 'created_at'"><i class="fa fa-plus-square"></i> Created</span>
        <span *ngIf="row.revision.key == 'deleted_at'"><i class="fa fa-trash-o"></i> Deleted</span>
        <span *ngIf="row.revision.key != 'created_at' && row.revision.key != 'deleted_at'"><i class="fa fa-edit"></i> Edit</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Log" [flexGrow]="4" [sortable]="false" [resizeable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        field <b>'{{row.revision.key}}'</b> in {{row.revision.revisionable_type}} (id: {{row.revision.revisionable_id}}) <b>from</b> <i>'{{row.revision.old_value}}'</i>  <b>to</b> <i>'{{row.revision.new_value}}'</i>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>


