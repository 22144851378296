<!-- BEGIN: sezione dedicata alle info sui risultati delle ricerche -->
<div class="row">
  <div class="col-md-12">
    <div class="portlet light bordered">
      <div class="portlet-title">
        <button [appCheckPermission]="'camp_edit_group'" class="btn btn-block btn-primary" (click)="newGroup()"><i class="fa fa-plus"></i> New Group</button>
      </div>

      <!-- <div class="datatable-loader text-center" *ngIf="isOngoingRequest()">
        <span></span><span></span><span></span><span></span><span></span>
        <strong class="text-center">Loading</strong>
      </div> -->

      <div class="portlet-body">
        <ngx-datatable class="striped" [columnMode]="'flex'" [rows]="campGroupList"
          [rowHeight]="'auto'" [headerHeight]="'auto'" [footerHeight]="0" [selectionType]="'single'"
          (select)='onGroupSelect($event)'>
          <ngx-datatable-column name="Group Name" [flexGrow]="4" prop="group_name" [resizeable]="false"
            [draggable]="false"></ngx-datatable-column>
          <ngx-datatable-column name="Info" [flexGrow]="4" prop="" [resizeable]="false" [draggable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
              <small>
                <span *ngIf="row.week_01">1&ordf; settimana<br></span>
                <span *ngIf="row.week_02">2&ordf; settimana<br></span>
                <span *ngIf="row.week_03">3&ordf; settimana<br></span>
                <span *ngIf="row.week_04">4&ordf; settimana<br></span>
                <span *ngIf="row.warehouse_product"><i class="fa fa-book"
                    aria-hidden="true"></i>&nbsp;{{row.warehouse_product.name}}</span>
              </small>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Description" [flexGrow]="8" prop="description" [resizeable]="false"
            [draggable]="false"></ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>

<hr>

<tabset #campGroupOrganizerTab class="align-right portlet-title tabbable-line">
  <tab heading="All weeks">
    <!-- BEGIN: sezione per settimana -->
    <div class="row" *ngIf="campGroupList.length > 0 && campGroupOrganizerTab?.tabs[0]?.active">
      <app-camp-groups-all-week></app-camp-groups-all-week>
    </div>
  </tab>
  <tab *ngIf="campWeeks >= 1" heading="Week 1" [appCheckPermission]="'camp_edit_group'">
    <div class="row" *ngIf="campGroupList.length > 0  && campGroupOrganizerTab?.tabs[1]?.active">
      <app-camp-groups-dnd #dragAndDrop [weekName]="'week_01'"></app-camp-groups-dnd>
    </div>
  </tab>
  <tab *ngIf="campWeeks >= 2" heading="Week 2" [appCheckPermission]="'camp_edit_group'" >
    <div class="row" *ngIf="campGroupList.length > 0  && campGroupOrganizerTab?.tabs[2]?.active">
      <app-camp-groups-dnd #dragAndDrop [weekName]="'week_02'"></app-camp-groups-dnd>
    </div>
  </tab>
  <tab *ngIf="campWeeks >= 3" heading="Week 3" [appCheckPermission]="'camp_edit_group'">
    <div class="row" *ngIf="campGroupList.length > 0  && campGroupOrganizerTab?.tabs[3]?.active">
      <app-camp-groups-dnd #dragAndDrop [weekName]="'week_03'"></app-camp-groups-dnd>
    </div>
  </tab>
  <tab *ngIf="campWeeks >= 4" heading="Week 4" [appCheckPermission]="'camp_edit_group'">
    <div class="row" *ngIf="campGroupList.length > 0  && campGroupOrganizerTab?.tabs[4]?.active">
      <app-camp-groups-dnd #dragAndDrop [weekName]="'week_04'"></app-camp-groups-dnd>
    </div>
  </tab>
</tabset>




<!-- BEGIN: MODAL LOADING EXPORT-->
<div *ngIf="isModalGroup" [config]="{ show: true }" (onHidden)="onHiddenGroup()" bsModal #groupModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Nuovo Gruppo</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalGroup()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="#" role="form" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()"
          #f="ngForm" novalidate>
          <div class="form-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group"
                  [ngClass]="{ 'has-error': (f.submitted || group_name.dirty || group_name.touched) && !group_name.valid }">
                  <label class="control-label">Nome del Gruppo <span class="required">*</span></label>
                  <input class="form-control" type="text" name="group_name" [(ngModel)]="model_group.group_name"
                    #group_name="ngModel" nospace placeholder="Group Name">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">Descrizione del Gruppo</label>
                  <textarea class="form-control" rows="3" name="description" [(ngModel)]="model_group.description"
                    placeholder="Group Description"></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Settimana del gruppo</label><br><br>
                  <label *ngIf="campWeeks >= 1" class="mt-checkbox mt-checkbox-outline">
                    <input type="checkbox" name="week_01" #week_01 [(ngModel)]="model_group.week_01">1&ordf; settimana
                    <span></span>
                  </label><br>
                  <label *ngIf="campWeeks >= 2" class="mt-checkbox mt-checkbox-outline">
                    <input type="checkbox" name="week_02" #week_02 [(ngModel)]="model_group.week_02">2&ordf; settimana
                    <span></span>
                  </label><br>
                  <label *ngIf="campWeeks >= 3" class="mt-checkbox mt-checkbox-outline">
                    <input type="checkbox" name="week_03" #week_03 [(ngModel)]="model_group.week_03">3&ordf; settimana
                    <span></span>
                  </label><br>
                  <label *ngIf="campWeeks >= 4" class="mt-checkbox mt-checkbox-outline">
                    <input type="checkbox" name="week_04" #week_04 [(ngModel)]="model_group.week_04">4&ordf; settimana
                    <span></span>
                  </label><br>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Libro</label>
                  <select [disabled]="getWarehouseService().isOngoingRequest()" class="form-control" name="type"
                    [(ngModel)]="model_group.warehouse_product_id" #type="ngModel">
                    <option *ngFor="let p of libri" [value]="p.id">{{p.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-actions right"  [appCheckPermission]="'camp_edit_group'">
            <img *ngIf="isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
            <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroyGroup();">Yes
                Delete!</button></ng-template>
            <button type="button" class="btn btn-outline red" *ngIf="model_group.id" [disabled]="isOngoingRequest()"
              [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i>
              Delete</button>&nbsp;
            <button type="submit" [disabled]="isOngoingRequest()" class="btn blue"
              *ngIf="model_group.id">Update</button>
            <button type="submit" [disabled]="isOngoingRequest()" class="btn green-jungle"
              *ngIf="!model_group.id">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
