
  <div class="portlet light bordered">
      <!-- <div class="portlet-title">
      </div> -->

      <div class="datatable-loader text-center" *ngIf="getWarehouseService().isOngoingRequest()">
        <span></span><span></span><span></span><span></span><span></span>
        <strong class="text-center">Loading</strong>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label class="mt-checkbox mt-checkbox-outline">
            <input type="checkbox" [(ngModel)]="hideCloseShipments" value="1" (ngModelChange)="valueChange($event)"> Hide Closed Shipments
            <span></span>
          </label>
        </div>
      </div>

      <div class="portlet-body">
        <ngx-datatable *ngIf="!getWarehouseService().isOngoingRequest()"
          class="striped"
          [rows]="rows"
          [columnMode]="'flex'"
          [headerHeight]="50"
          [footerHeight]="0"
          [rowHeight]="40"
          [selectionType]="'single'"
          [selected]="selected"
          (select)='onSelect($event)'
        >
          <ngx-datatable-column name="Creation Date" [flexGrow]="2" prop="created_at" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
          <ngx-datatable-column name="Request From" [flexGrow]="2" prop="holder_type" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span *ngIf="row.holder_type == 'App\\Camp'">Camp</span>
              <span *ngIf="row.holder_type == 'App\\ActorGroup'">Actor Group</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Name" [flexGrow]="2" prop="holder_type" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span *ngIf="row.camp_info?.camp_code">{{row.camp_info.camp_code}}</span>
              <span *ngIf="row.actor_group_info?.group_name">{{row.actor_group_info.group_name}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Shipment Closed" [flexGrow]="1" prop="closed_shipment" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span *ngIf="row.closed_shipment == true"><i class="fa fa-check-circle"></i></span>
            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>
      </div>
    </div>

    <!-- BEGIN: MODAL SHIPMENT -->
<div [config]="{ show: false }" bsModal (onHidden)="onHiddenShipmentModal()"  #shipmentModal="bs-modal" class="modal fade"
tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 *ngIf="!currentShipmentSelected?.closed_shipment" class="modal-title pull-left"> Shipment </h4>
        <h4 *ngIf="currentShipmentSelected?.closed_shipment" class="modal-title pull-left">Shipment Closed</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalShipment()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body form">
        <!-- sezione con il form della spedizione attivo -->
        <div class="row">
          <div class="col-md-12">
            <form role="form" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
              <div class="form-body">
                <div class="row" *ngIf="currentShipmentSelected">
                  <div class="col-md-6">
                    <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalDataStart(), false)) }">
                      <label class="control-label">Date of send shipment</label>
                        <div class="input-group">
                          <!-- input box -->
                          <input angular-mydatepicker #dp_shipment_start="angular-mydatepicker" name="shipment_start" class="form-control"
                            [locale]="getHelper().locale"
                            [disabled]="getWarehouseService().isOngoingRequest()"
                            [options]="getDatePickerOptions()"
                            [ngModel]="getLocalDataStart()"
                            (dateChanged)="onDateStartChanged($event)"
                          />
                          <!-- clear date button -->
                          <span class="input-group-btn">
                            <button type="button" *ngIf="currentShipmentSelected?.shipment_start" class="btn default" (click)="dp_shipment_start.clearDate()"><i class="fa fa-close"></i></button>
                          </span>
                          <!-- toggle calendar button -->
                          <span class="input-group-btn">
                              <button type="button" class="btn default" (click)="dp_shipment_start.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                          </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalDataEnd(), false)) }">
                      <label class="control-label">Date of arrival shipment</label>
                        <div class="input-group">
                          <!-- input box -->
                          <input angular-mydatepicker #dp_shipment_end="angular-mydatepicker" name="shipment_end" class="form-control"
                            [locale]="getHelper().locale"
                            [disabled]="getWarehouseService().isOngoingRequest()"
                            [options]="getDatePickerOptions()"
                            [ngModel]="getLocalDataEnd()"
                            (dateChanged)="onDateEndChanged($event)"
                          />
                          <!-- clear date button -->
                          <span class="input-group-btn">
                            <button type="button" *ngIf="currentShipmentSelected?.shipment_end" class="btn default" (click)="dp_shipment_end.clearDate()"><i class="fa fa-close"></i></button>
                          </span>
                          <!-- toggle calendar button -->
                          <span class="input-group-btn">
                              <button type="button" class="btn default" (click)="dp_shipment_end.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="currentShipmentSelected">
                  <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Tracking Code: &nbsp;</label>
                        <input class="form-control" name="tracking_code" type="text" [(ngModel)]="currentShipmentSelected.shipment_code" #tracking_code="ngModel">
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="currentShipmentSelected?.id">
                  <div class="col-md-12">
                    <app-warehouse-shipment-materials [currentShipment]="currentShipmentSelected"></app-warehouse-shipment-materials>
                  </div>
                </div>

              </div>
              <div class="form-actions right form-clear">
                <img *ngIf="getWarehouseService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
                <button type="submit" [disabled]="getWarehouseService().isOngoingRequest()" *ngIf="currentShipmentSelected?.id" class="btn blue" >Update</button>
                <button type="button" [disabled]="getWarehouseService().isOngoingRequest() || currentShipmentSelected?.closed_shipment"
                  (click)="reopenOrder()" class="btn red" *ngIf="currentShipmentSelected?.id"
                  tooltip="Permette ai campi di rivedere la richiesta, aggiungendo o rimuovendo prodotti"><i class="fa fa-refresh"></i>&nbsp;Reopen Order</button>
                <button type="button" [disabled]="getWarehouseService().isOngoingRequest() || currentShipmentSelected?.closed_shipment"
                  (click)="closeOrder()" class="btn green" *ngIf="currentShipmentSelected?.id"
                  tooltip="Chiudendo la spedizione non sarà più possibile tornare indentro"><i class="fa fa-check"></i>&nbsp;Confirm Order</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL SHIPMENT -->
