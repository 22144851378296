import { Router } from '@angular/router';
import { TheatrinoBookingInfoFormComponent } from './../theatrino-booking-info-form/theatrino-booking-info-form.component';
import { TheatrinoService, ConfigService, HelperService } from './../../_services/index';
import { Component, OnInit, EventEmitter, ViewChildren, Output } from '@angular/core';
import { parseISO } from 'date-fns';

//  salvataggio per excel
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {  } from '../../_services';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-theatrino-tour-booking-list',
  templateUrl: './theatrino-tour-booking-list.component.html',
  styleUrls: ['./theatrino-tour-booking-list.component.css']
})
export class TheatrinoTourBookingListComponent implements OnInit {

  @ViewChildren('theatrinoBookingInfo') public theatrinoInfo: TheatrinoBookingInfoFormComponent

  //  status dell'esportazione
  @Output() modalLoading: EventEmitter<any> = new EventEmitter();

  tourList: any;
  tourListKeys: any[];
  rows: any[] = [];
  selected = [];

  constructor(private theatrinoService: TheatrinoService, private configService: ConfigService,
    private router: Router, private helper: HelperService) { }

  subscribe: any;

  ngOnInit() {

    this.tourList = JSON.parse(JSON.stringify(this.theatrinoService.currentTourInfoList));

    this.subscribe = this.theatrinoService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      if (this.theatrinoService.currentTourInfoList == null)
        this.tourList = {};
      else
        this.tourList = JSON.parse(JSON.stringify(this.theatrinoService.currentTourInfoList));

      //  Creo la lista in base alle chiavi
      this.tourListKeys = Object.keys(this.tourList);

      //  Setto le date formattate correttamente
      this.tourListKeys.forEach(key => {

        if (this.tourList[key].date != null)
          this.tourList[key].date_formatted = parseISO(this.tourList[key].date).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
      });

      this.rows = this.getTableData(this.tourList);

    });

  }


  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }



  getTableData(list) {

    var data = new Array();

    for (let key in list) {

      if (list[key].type != 4 &&
          list[key].type != 5)
      {
        //  Clono l'oggeto perchè è un elemento immutabile
        var d = Object.assign({}, list[key]);

        //  Assegno una chiave di navigazione dell'elemento
        d.key = key;

        // day off
        if (d.type == 1){
          d.status_flag = "Day off";
        }
        else if (d.type == 3){
          d.status_flag = "Swap Day";
        }
        else {
          // colore dello status
          d.status_color = this.configService.getTheatrinoStatusColorByKey(d.status);
          // imposto il flag dello status
          d.status_flag = this.configService.getTheatrinoStatusValueByKey(d.status);
        }

        data.push(d);
      }

    }

    return data;
  }

  onSelect({ selected }) {
    //this.enterpriseSelected.emit(selected[0]);
    this.editTourDate(selected[0].key)
  }

  onActivate(event){
  }

  getSelectedIx() {
    //return this.selected[0]['$$index'];
  }

  getCellClass({ row, column, value })
  {
    //   I day Off sono rappresentati con il colore rosso
    if (row.type == 1){
      return {
        'font-red' : true,
        'border-left-2': true,
        'border-left-2-red': true
      };
    }
    else if (row.type == 3){
      return {
        'font-red' : true,
        'border-left-2': true,
        'border-left-2-red': true
      };
    }
    else if (row.status_color)
    {
      let obj = {};

      obj['font-'+row.status_color.name] = true;
      obj['border-left-2'] = true;
      obj['border-left-'+row.status_color.name] = true;

      return obj;
    }

  }





  // MODAL EXPORT
  isModalExport: boolean = false;

  showModalExport(): void {
    this.isModalExport = true;

    this.modalLoading.emit(this.isModalExport);
  }

  hideModalExport(): void {

    this.isModalExport = false;
    this.modalLoading.emit(this.isModalExport);
  }


  /*  Salvo i dati nel formato Excel
  */
 exportToExcel() {

  this.showModalExport();

  if (this.rows.length > 0)
  {
      this.exportParse(this.rows, () => {
        this.hideModalExport();
      });
  }

}




/*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
 exportParse (data: any, callback: any) {

  var exportedArray = [];

  //  preparo i dati per il csv bonificandoli
  data.forEach( (element) => {

    //  bonifico
    Object.keys(element).forEach(key => {
      if (element[key] == null) element[key] = '';
    })

    //  preparo
    exportedArray.push( {
      'Data' : element.date_formatted,
      'Stato': element.status_flag,
      'Enterprise' : element.enterprise_denomination,
      'Organizzatore': element.enterprise_organizer_denomination
    })

  });

  //  creo io worksheet con i dati
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

  //  personalizzo le colonne
  worksheet['!cols'] = [{width: 20}, {width:20}, {width:50}, {width:60}];

  //  creo il workbook con lo sheet attuale
  const workbook: XLSX.WorkBook = { Sheets: { 'Tour Booking': worksheet}, SheetNames: ['Tour Booking'] };

  //  scrivo il file
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

  //  Salvo il file tramite il browser
  FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "tour_booking_"+this.theatrinoService.currentTheatrinoInfo.denomination+".xlsx");

  callback();

}




  addNewTourDate()
  {
    this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/new/booking/", true]);
  }

  addNewDayOff()
  {
    this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/new/dayoff/", true]);
  }

  addNewGeneral()
  {
    this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/new/general/", true]);
  }

  addNewSwapDay()
  {
    this.router.navigate(["theatrino/"+ this.theatrinoService.currentTheatrinoId +"/tour/new/swapday/", true]);
  }

  editTourDate(key) {
    //   Vado al component per editare la corrente data dal tour
    this.router.navigate(["theatrino/" + this.theatrinoService.currentTheatrinoId + "/tour/edit/" + this.tourList[key].id]);
  }

  getTheatrinoService() {
    return this.theatrinoService
  }

}
