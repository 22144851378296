import { BackendService } from './../../_services/backend.service';
import { Component, OnInit } from '@angular/core';
import { PersonService, HelperService, ConfigService } from '../../_services/index';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-person-history',
  templateUrl: './person-history.component.html',
  styleUrls: ['./person-history.component.css']
})
export class PersonHistoryComponent implements OnInit {

  subscribe: any;
  model: any = {};
  history_url_old_id: any = "";
  history_html: any;

  gaston_history: any = {
    // staff: []
  };

  loading: boolean = false;

  constructor(private personService: PersonService, private helper: HelperService,
    private router: Router, private configService: ConfigService, private http: HttpClient,
    private backend: BackendService) { }

  ngOnInit() {

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.model = this.personService.currentPersonInfo;

      if (this.model == null) this.model = {};
      else {

        if (this.model.old_id) {
          //this.history_url_old_id = this.configService.blobBasePath + "history_old_id/"+ this.model.old_id + ".html";
          this.history_url_old_id = "https://gastonapp.blob.core.windows.net/history/" + this.model.old_id + ".html";
          this.http.get(this.history_url_old_id, {responseType: 'text'}).subscribe(
            (response) => {
              var text = response.replace(/<a\b[^>]*>/gi, "").replace(/<\/a>/gi, "")
              this.history_html = text;
            },
            (error) => {
              this.history_html = "There is no history from Gabriel for this person...";
            });
        }
      }

    });

  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }


  getHistory ()
  {
    if (this.model.id == null || typeof this.model.id == "undefined") return;

    this.loading = true;

    let params = new HttpParams();

    this.backend.get('person/'+this.model.id+'/history', params).subscribe (
      (response) => {

        var res : any = response;

        if (typeof res.subscriber == "undefined")
          res.subscriber = [];

        //  faccio il sort in base alle date
        res.subscriber.sort(function(a, b) {
          return a.start < b.start ? -1 : a.start > b.start ? 1 : 0;
        });

        //   setto le date
        res.subscriber.forEach(element => {
          if (element.start)
            element.start_formatted = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.end)
            element.end_formatted = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        });

        if (typeof res.staff == "undefined")
          res.staff = [];

        //  faccio il sort in base alle date
        res.staff.sort(function(a, b) {
          return a.start > b.start ? -1 : a.start < b.start ? 1 : 0;
        });

        //   setto le date
        res.staff.forEach(element => {
          if (element.start)
            element.start_formatted = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.end)
            element.end_formatted = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        });


        //  controllo i corsi di formazione
        if (typeof res.course_schedule == "undefined")
          res.course_schedule = [];

        //  faccio il sort in base alle date
        res.course_schedule.sort(function(a, b) {
          return a.start < b.start ? -1 : a.start > b.start ? 1 : 0;
        });

        //   setto le date
        res.course_schedule.forEach(element => {
          if (element.start)
            element.start_formatted = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.end)
            element.end_formatted = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        });


        //  controllo i gruppi del theatrino
        if (typeof res.group_member_theatrino == "undefined")
          res.group_member_theatrino = [];

        //  faccio il sort in base alle date
        res.group_member_theatrino.sort(function (a, b) {
          return a.start < b.start ? -1: a.start > b.start ? 1 : 0;
        });

        //  setto le date
        res.group_member_theatrino.forEach(element => {
          if (element.start)
          element.start_formatted = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.end)
            element.end_formatted = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        });

        //  controllo i gruppi del theatrino
        if (typeof res.accountable_theatrino == "undefined")
          res.accountable_theatrino = [];

        //  faccio il sort in base alle date
        res.accountable_theatrino.sort(function (a, b) {
          return a.start < b.start ? -1: a.start > b.start ? 1 : 0;
        });

        //  setto le date
        res.accountable_theatrino.forEach(element => {
          if (element.start)
          element.start_formatted = parseISO(element.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.end)
            element.end_formatted = parseISO(element.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

          if (element.date)
            element.date_formatted = parseISO(element.date).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        });


        this.gaston_history = res;
        this.loading = false;
      },
      (error) => {
        this.backend.showErrors(error);
        this.loading = false;
      });
  }

  getConfigService() {
    return this.configService
  }
}
