<div class="datatable-loader text-center" *ngIf="ongoingRequest">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>

<ngx-datatable *ngIf="!ongoingRequest" class="striped" [rows]="rows" [columnMode]="'force'" [headerHeight]="50"
  [footerHeight]="50" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'"
  (activate)="onActivate($event)" (select)='onSelect($event)'>

  <!-- <ngx-datatable-column name="Type" prop="type_name" [resizeable]="false" [draggable]="false">
  </ngx-datatable-column> -->
  <ngx-datatable-column name="Camp" prop="camp_code" [resizeable]="false" [draggable]="false">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        {{row.camp_code}}<br>
        <small>{{row.type_name}}</small>
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Director" [flexGrow]="3" prop="camp_director_surname" [resizeable]="false" [draggable]="false">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        {{row.camp_director_surname}} {{row.camp_director_first_name}}
      </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Locality" [flexGrow]="3" prop="administrative_area_level_3_long_version"
    [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <span *ngIf="row['administrative_area_level_3_long_version'] == null" tooltip="The address is not googlized or not primary address"
        placement="auto"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
      <span *ngIf="row['administrative_area_level_3_long_version'] != null">
        {{row?.administrative_area_level_3_long_version}}</span> <span *ngIf="row['administrative_area_level_2_short_version'] != null">
            ({{row?.administrative_area_level_2_short_version}})</span>
    </ng-template>
  </ngx-datatable-column>
  <!-- <ngx-datatable-column name="PR" prop="administrative_area_level_2_short_version" [flexGrow]="1" [resizeable]="false"
    [draggable]="false">
    <ng-template>
      <span *ngIf="row['administrative_area_level_2_short_version'] != null">
        {{row?.administrative_area_level_2_short_version}}</span>
    </ng-template>
  </ngx-datatable-column> -->
  <ngx-datatable-column name="Region" prop="administrative_area_level_1_long_version" [flexGrow]="2"
    [resizeable]="false" [draggable]="false">
    <ng-template>
      <span *ngIf="row['administrative_area_level_1_long_version'] != null">
        {{row?.administrative_area_level_1_long_version}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Date" prop="start" [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row"><small>{{row?.start_formatted}}<br>{{row?.end_formatted}}</small></ng-template>
  </ngx-datatable-column>
  <!-- <ngx-datatable-column name="End" prop="end" [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row"><small>{{row?.end_formatted}}</small></ng-template>
  </ngx-datatable-column> -->
  <!-- <ngx-datatable-column name="Weeks" prop="num_of_weeks" [flexGrow]="1" [resizeable]="false" [draggable]="false">
  </ngx-datatable-column> -->
  <ngx-datatable-column name="R/S/P" [flexGrow]="1" [resizeable]="false" [draggable]="false">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <i *ngIf="row.requests_count > 0" class="fa fa-exclamation" style="color:red"></i>
      <span tooltip="Signup request:{{row.requests_count}} Subscribers:{{row.subscription_count}} Presences:{{row.presences}}">{{row.requests_count}}/{{row.subscription_count}}/{{row.presences}}</span>
      <i *ngIf="row.buyable == true" class="fa fa-toggle-on" style="color:green"></i>
      <i *ngIf="row.buyable == false" class="fa fa-toggle-off" style="color:red"></i>
      <br>
      <small>{{row.num_of_weeks}} <i class="fa fa-calendar" aria-hidden="true"></i></small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="" [flexGrow]="2" [resizeable]="false" [draggable]="false" prop="">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <button class="blue btn default btn-sm" (click)="openSelected(row)"><i class="fa fa-edit"
          aria-hidden="true"></i></button>
      <a class="blue pointer btn btn-outline default btn-sm" target="_blank"
        routerLink="/camp/edit/{{row.camp_id}}"><i class="fa fa-external-link"
          aria-hidden="true"></i></a>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
