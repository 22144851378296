import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { environment } from '../../environment'
import { FacilitatorApplicationService, UserService } from 'app/_services';

@Component({
  selector: 'app-facilitators-applications-home',
  templateUrl: './facilitators-applications-home.component.html',
  styleUrls: ['./facilitators-applications-home.component.css']
})
export class FacilitatorsApplicationsHomeComponent implements OnInit {

  @ViewChildren('facilitatorTabs') staticTabs: QueryList<TabsetComponent>;
  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;

  public staticTab = {
    tabs: [
      {active: true},
      {active: false},
      {active: false},
      {active: false},
      {active: false}
    ]
  }

  public listEditions: any = null;
  public editionArray: any = [];
  public selectedValue: any = {};

  public lastActive: any = {};
  public searchModel: any = {};

  constructor(private userService: UserService, private facilitatorService: FacilitatorApplicationService) {}

  ngOnInit() {

    this.init();
  }

  init()
  {
    this.facilitatorService.getAllEditions((editions) => {

      this.listEditions = editions;

      let eA = editions.map(function(el) {return el.title});
      eA.unshift ('All Editions');
      this.editionArray = eA;

      this.selectedValue = this.editionArray[this.editionArray.length -1];

      this.setSearchModel();

      this.staticTab = this.staticTabs.first;
      // Rimosso e cambiato il valore iniziale dell'oggetto this.staticTab[0].active in true, sembra funzionare
      // this.staticTab.tabs[0].active = true;
    });

    this.facilitatorService.getLastActive((lastActive) => {
      this.lastActive = lastActive;
    })
  }

  setSearchModel()
  {
    let userSelected = this.userService.getUserPreferences("facilitator_edition_selected");
    if (userSelected)
      this.selectedValue = userSelected;

    let edition = this.listEditions.find(x => x.title == this.selectedValue);
    if (typeof edition != "undefined")
      this.searchModel.edition = edition.id;
    else
      delete this.searchModel.edition;

    this.facilitatorService.sendUpdateEvent();
  }

  selectedChanged(event)
  {
    this.selectedValue = event;

    this.userService.saveUserPreferences("facilitator_edition_selected", this.selectedValue);

    this.setSearchModel();
  }

  search ()
  {
    this.facilitatorService.sendUpdateEvent();
  }

  pressed ()
  {
    this.showModal();
  }

  editionChanged()
  {
    this.init();

    if (this.isModalShown)
      this.hideModal();
  }

  // MODAL
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }



  get facilitatorApplicationFormUrl(){
	  return environment.facilitatorApplicationFormUrl;
  }
}
