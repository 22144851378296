

<div class="portlet light bordered">
  <div class="portlet-title" *ngIf="addNewEdition">
      <div class="caption">
          <span class="caption-subject bold uppercase">New Edition</span>
      </div>
  </div>
  <div class="portlet-body form">
    <button *ngIf="!addNewEdition" class="btn default blue btn-block" (click)="addEdition()"><i class="fa fa-plus"aria-hidden="true"></i> Add New Edition</button>

    <form *ngIf="addNewEdition" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
      <div class="form-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || titleNew.dirty || titleNew.touched) && !titleNew.valid && !titleNew.disabled }">
              <label class="control-label">Title <span class="required">*</span></label>
              <input [disabled]="getTutorService().isOngoingRequest()" type="text" name="titleNew" [(ngModel)]="modelNewEdition.title" #titleNew="ngModel" required class="form-control">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Active</label>
              <div class="mt-checkbox-list">
                <label class="mt-checkbox">
                  <input type="checkbox" name="activeNew" [(ngModel)]="modelNewEdition.active" #activeNew="ngModel">
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [appCheckPermission]="'person_edit'" class="form-actions right">
        <img *ngIf="getTutorService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
        <button type="button" class="btn btn-outline blue" [disabled]="getTutorService().isOngoingRequest()" (click)="cancelEdition()"><i class="fa fa-times" aria-hidden="true"></i>&nbsp;Cancel</button>&nbsp;
        <button type="submit" class="btn green-jungle" [disabled]="getTutorService().isOngoingRequest()"><i class="fa fa-save" aria-hidden="true"></i>&nbsp;Save</button>
      </div>
    </form>
  </div>


<ngx-datatable *ngIf="!addNewEdition"
  class="striped hidden-empty-datatable"
  [rows]="rows"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [rowHeight]="'auto'"
>
<ngx-datatable-column name="Edition Name" [flexGrow]="8">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="selectedRow?.id == row.id; then thenBlock else elseBlock"></div>
      <ng-template #thenBlock>
        <div class="form-group" [ngClass]="{ 'has-error': (title.dirty || title.touched) && !title.valid && !title.disabled }">
          <input type="text" name="title" [(ngModel)]="selectedRow.title" #title="ngModel" required class="form-control">
        </div>
      </ng-template>
    <ng-template #elseBlock>
      <span [ngClass]="{'text-success': row.active == true}">{{row.title}}</span>
    </ng-template>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Active" [flexGrow]="8">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="selectedRow?.id == row.id; then thenBlock else elseBlock"></div>
      <ng-template #thenBlock>
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox">
              <input type="checkbox" id="inlineCheckbox1" [(ngModel)]="selectedRow.active"> Active
              <span></span>
            </label>
          </div>
        </div>
      </ng-template>
    <ng-template #elseBlock>
      <i *ngIf="row.active" class="fa fa-check text-success"></i>
      <i *ngIf="!row.active" class="fa fa-times"></i>
    </ng-template>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name=""  prop="id" [flexGrow]="2">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="selectedRow?.id == row.id; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
      <button class="green-jungle btn btn-block default btn-sm" (click)="updateRow(row)"><i class="fa fa-save"aria-hidden="true"></i> Update</button>
    </ng-template>
    <ng-template #elseBlock>
      <button class="blue btn btn-block default btn-sm" (click)="selectRow(row)"><i class="fa fa-edit"aria-hidden="true"></i> Edit</button>
    </ng-template>
  </ng-template>
</ngx-datatable-column>
</ngx-datatable>

</div>
