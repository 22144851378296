import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { BackendService } from './../../_services/index';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-actor-group-info-show',
  templateUrl: './actor-group-info-show.component.html',
  styleUrls: ['./actor-group-info-show.component.css']
})
export class ActorGroupInfoShowComponent implements OnInit, OnChanges {

  @Input() idGroup: number;
  groupInfo: any;
  currentId: number;

  constructor(private backend: BackendService, private router: Router) { }

  ngOnInit() {

    this.groupInfo = {};
  }

  ngOnChanges() {

    this.groupInfo = {};

    if (this.idGroup != this.currentId)
      this.getGroupInfo();
  }


  loading: boolean = false;

  getGroupInfo(){

    if (typeof this.idGroup == "undefined" ||  this.idGroup <= 0){
      this.groupInfo = {};
      this.currentId = null;
      return;
    }

    this.currentId = this.idGroup;

    let params = new HttpParams();

    this.loading = true;

    this.backend.get('actorgroup/' + this.currentId, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.groupInfo = resJson;

        this.loading = false;
      },
      (error) => {
        this.loading = false;

        this.backend.showErrors(error);
      });
  }

}
