import { WarehouseService, HelperService, ConfigService } from 'app/_services';
import { ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shipment-material-search-form',
  templateUrl: './shipment-material-search-form.component.html',
  styleUrls: ['./shipment-material-search-form.component.css']
})
export class ShipmentMaterialSearchFormComponent implements OnInit {

  model: any = { searchName: "", tags: ""};

  @ViewChild('searchNameText', {static: true, read: ElementRef}) private searchInput: ElementRef;

  @Input() defaultTags: any = "";
  @Output() searchResult = new EventEmitter<any> ();

  constructor(private warehouseService: WarehouseService, private configService: ConfigService, private helper: HelperService) { }


  ngOnInit() {

    if (this.defaultTags.length > 0)
      this.model.tags = this.defaultTags;

    //  inizializzo sempre i tag
    this.model.tags_formatted = this.helper.stringToArrayOfProductTags(this.model.tags, this.configService.warehouseProductTagsSelect);
  }

  //   quando la vista si è inizializzata setto il focus sul campo di ricerca
  public setFocus() {

    setTimeout(() => {
      if (typeof this.searchInput != "undefined")
        this.searchInput.nativeElement.focus();
    }, 600);
  }


  tagChanged () {
    //  trasform tag products into string
    this.model.tags = this.helper.arrayOfProductTagsToString(this.model.tags_formatted);
  }


  submitSearch() {

    this.warehouseService.searchProduct(this.model.searchName, this.model.tags,
      (result) => {
        this.searchResult.emit(result);
    });

  }


  getWarehouseService():WarehouseService {
    return this.warehouseService;
  }


}
