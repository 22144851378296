<!-- <alert type="warning" *ngIf="ongoingRequest">
  <strong>Loading</strong> ...
</alert> -->
<div class="datatable-loader text-center" *ngIf="ongoingRequest">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>

<ngx-datatable *ngIf="!ongoingRequest"
  class="striped"
  [rows]="rows"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowClass]="getRowClass"
  [rowHeight]="'auto'"
  [externalPaging]="true"
  [externalSorting]="true"
  [count]="pageTotalElements"
  [offset]="pageNumber"
  [limit]="pageSize"
  [selected]="selected"
  [selectionType]="'single'"
  (activate)="onActivate($event)"
  (select)='onSelect($event)'
  (page)='setPage($event)'
  (sort)='onSort($event)'
>
  <ngx-datatable-column name="Surname" [flexGrow]="1" prop="surname" [resizeable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="row['role'] == null" tooltip="There is no role assigned to this person" placement="auto"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{{value}}</span>
        <span *ngIf="row['role'] != null">{{value}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Name" [flexGrow]="1" prop="first_name" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Contact" [flexGrow]="1" prop="contact" [resizeable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Address" [flexGrow]="3" prop="address" [resizeable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span *ngIf="row['country_long_version'] == null" tooltip="The address is not googlized or not primary address" placement="auto"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{{value}}</span>
          <span *ngIf="row['country_long_version'] != null">{{value}}</span>
      </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
