<img *ngIf="loading" src="./assets/global/img/input-spinner.gif" />

<div *ngIf="this.courseInfo.deleted_at == null">
  <a *ngIf="currentCourseId && !hideSummaryCardModal" class="pointer" (click)="clickCourse()">{{courseInfo.title}}&nbsp;</a>
  <span *ngIf="currentCourseId && hideSummaryCardModal"><a *ngIf="currentCourseId" class="blue pointer" target="_blank" routerLink="/course/edit/{{ currentCourseId }}">{{courseInfo.title}}</a>&nbsp;</span>
  <a *ngIf="currentCourseId" class="blue pointer" target="_blank" routerLink="/course/edit/{{ currentCourseId }}"><i class="fa fa-external-link" aria-hidden="true"></i></a>
</div>

<div *ngIf="this.courseInfo.deleted_at != null">
  <span class="text-danger"> Course Deleted </span>
</div>
