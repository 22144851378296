import { NotificationsService } from 'angular2-notifications';
import { VehicleService } from './../../_services/index';
import { VehicleDiaryFormComponent } from './../vehicle-diary-form/vehicle-diary-form.component';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-vehicle-diary-list',
  templateUrl: './vehicle-diary-list.component.html',
  styleUrls: ['./vehicle-diary-list.component.css']
})
export class VehicleDiaryListComponent implements OnInit {

  @ViewChildren('vehicleDiaryFormList') public vehicleDiaryFormList: QueryList<VehicleDiaryFormComponent>;

  vehicleDiaryList: any;
  vehicleDiaryListKey: any[];

  allFormValid = true;
  multisave = true;

  subscribe: any;

  constructor(private vehicleService: VehicleService, private notify: NotificationsService) { }

  ngOnInit() {

    this.vehicleDiaryList = this.vehicleService.currentVehicleDiaryList;

    if(this.vehicleDiaryList == null)
      this.addNew();

    this.subscribe = this.vehicleService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      if (this.vehicleService.currentVehicleDiaryList != null) {

        let newFormModel = null;
        if (typeof this.vehicleDiaryFormList.first.model.id == "undefined"
          && this.vehicleDiaryFormList.first.currentForm.form.dirty
          && this.vehicleDiaryFormList.first.currentForm.form.pending){
            newFormModel = this.vehicleDiaryFormList.first.model;
        }

        this.vehicleDiaryList = this.vehicleService.currentVehicleDiaryList;
        this.vehicleDiaryListKey = Object.keys(this.vehicleService.currentVehicleDiaryList).reverse();

        //  Ordino le chiavi dell'array in base al valore di aggiornamento del model
        this.vehicleDiaryListKey = this.OrderByUpdate(this.vehicleDiaryListKey, this.vehicleDiaryList);

        //  Controllo che non ci sia un elemento attualmente in modifica
        let vehicleDiaryListOld = this.vehicleDiaryList;
        this.vehicleDiaryListKey.forEach(k => {

          if (typeof vehicleDiaryListOld[k] != "undefined" && this.vehicleDiaryList[k]["updated_at"] == vehicleDiaryListOld[k]["updated_at"])
            this.vehicleDiaryList[k] = vehicleDiaryListOld[k];

        });

        if (newFormModel != null) {
          this.vehicleDiaryList[0] = newFormModel;
          this.vehicleDiaryListKey.unshift(0);
        }

      }

      if (this.vehicleService.currentVehicleDiaryList != null && this.vehicleService.currentVehicleDiaryList.length == 0) {
        this.addNew();
      }

    });

  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }


  addNew() {

    if (this.vehicleDiaryList == null) {
      this.vehicleDiaryList = {};
      this.vehicleDiaryListKey = new Array();
    }
    this.vehicleDiaryList[0] = {};
    this.vehicleDiaryListKey.unshift(0);
  }


  saveAll () {

    this.allFormValid = true;

    //  Controllo se tutti i form sono validi
    this.vehicleDiaryFormList.forEach(element => {

      if (!element.currentForm.form.valid) {

        //  Controllo se non è valido
        for (var i in element.currentForm.form.controls) {
          element.currentForm.controls[i].markAsTouched();
        }

        this.allFormValid = false;
        this.notify.error("There is a form with error");
      }

    });

    if (!this.allFormValid)
      return;

      this.vehicleDiaryFormList.forEach(element => {
        element.submit();
      });
  }

  formDelete (event) {
    if(!event.model.id && this.vehicleDiaryListKey.length > 1) {
      this.vehicleDiaryListKey.splice(0, 1);
      delete this.vehicleDiaryList[0];
    }
  }

  formSaved (event) {

  }


  OrderByUpdate( listKey: any[], listValue: any) : any[]
  {

    let arrayTmp = new Array();

    listKey.forEach(key => { arrayTmp.push(listValue[key]) });

    arrayTmp.sort(function(a, b){
      return a.updated_at > b.updated_at ? -1 : a.updated_at < b.updated_at ? 1 : 0 }
    );

    let array = new Array();

    arrayTmp.forEach(element => {
      array.push(element.id);
    });

    return array;
  }

}
