<div class="portlet light bordered">

    <!-- <div class="portlet-title">
    </div> -->

  <div class="datatable-loader text-center" *ngIf="getWarehouseService().isOngoingRequest()">
    <span></span><span></span><span></span><span></span><span></span>
    <strong class="text-center">Loading</strong>
  </div>

  <div class="portlet-body">

    <ngx-datatable *ngIf="!getWarehouseService().isOngoingRequest()"
    class="striped"
        [rows]="rows"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="0"
        [rowHeight]="'auto'"
    >
      <ngx-datatable-column name="Type" [flexGrow]="2" prop="product_info.type" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
      <ngx-datatable-column name="Name" [flexGrow]="4" prop="product_info.name" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
      <ngx-datatable-column name="Description" [flexGrow]="6" prop="product_info.description" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
      <ngx-datatable-column name="Request" [flexGrow]="2" prop="quantity" [sortable]="true" [resizeable]="false"></ngx-datatable-column>
      <ngx-datatable-column name="Available" [flexGrow]="2" [sortable]="false" [resizeable]="false" *ngIf="!currentShipment?.closed_shipment">
        <ng-template ngx-datatable-cell-template let-row="row">
          <span [ngClass]="{'text-danger': row?.product_info?.quantity < row?.quantity}">{{row?.product_info?.quantity}}</span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

  </div>
</div>


