import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CampService, HelperService, ConfigService, WarehouseService, UserService } from './../../_services/index';
import { NotificationsService } from 'angular2-notifications';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-camp-groups',
  templateUrl: './camp-groups.component.html',
  styleUrls: ['./camp-groups.component.css']
})
export class CampGroupsComponent implements OnInit {

  @ViewChild('groupModal', {static: false}) public groupModal: ModalDirective;
  @ViewChild('campGroupOrganizerTab', {static: true}) campGroupOrganizerTab: TabsetComponent;

  campGroupList: any[] = [];
  campWeeksList: any[] = [];

  subscribe: any;

  selected = [] = [];

  campWeeks: number = 0;

  libri: any;

  constructor(private campService: CampService, private helper: HelperService, private userService: UserService,
              private configService: ConfigService, private notify: NotificationsService, private warehouseService: WarehouseService) { }

  ngOnInit() {

    this.subscribe = this.campService.getsubjectToUpdateObservable().subscribe((update: boolean) =>  {

      this.getGroupsInfo();
    });

    if (this.campService.currentCampGroups != null && this.campService.currentCampSubscriberList != null)
      this.getGroupsInfo();

    if (this.campService.currentCampInfo){
      this.campWeeks = this.campService.numOfCampWeeks;
    }

    this.warehouseService.getAllProductsByType("libro", (res) => {
      this.libri = res;
    });
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  getGroupsInfo () {

    if (this.campService.currentCampGroups != null && this.campService.currentCampSubscriberList != null) {

      this.campGroupList = this.campService.currentCampGroups;

      this.prepareWeekSubscribers();
    }
  }

  prepareWeekSubscribers ()
  {
    this.campWeeksList = [];

    //  per ogni settimana del campo setto i0 subscriber che ne sono iscritti
    for (var i = 0; i < this.campService.numOfCampWeeks; i++) {
      var subs = JSON.parse(JSON.stringify(this.campService.currentCampSubscriberList.filter(x => !x.deleted_at).filter(x => x.camp_week.includes(i+1))));

      //  per ogni iscritto della settimana prendo solo la settimana corrispondente
      subs.forEach (subscriber => {
        var subIdx = subscriber.groups.findIndex(x => x.num_of_week == (i+1));

        //  se ho trovato una settimana la assegno a questo iscritto
        if (subIdx >= 0) {
          subscriber.group = subscriber.groups[subIdx];
          var group = this.campGroupList.find(x => x.id == subscriber.groups[subIdx].camp_group_id)
            if (typeof group != "undefined")
              subscriber.group.name = group.group_name;
        }

        //  se il gruppo non c'è lo inizializzo comunque
        if (typeof subscriber.group == "undefined") { subscriber.group = {}; subscriber.group.name = "";}

      })

      //  inserisco la settimana nell'array
      this.campWeeksList.push(subs);

    }

  }

  checkSubscriberInWeek(week: number)
  {
    for (var i = 0; i < this.campWeeksList[week-1].length; i++)
    {
      if (typeof this.campWeeksList[week-1][i].groups.find(x=> x.num_of_week == week && x.camp_group_id == this.model_group.id) != "undefined")
      return true;
    }
  }

  checkUpdatePermission()
  {
    if (this.model_group.week_01 == false)
      return !this.checkSubscriberInWeek(1);

    if (this.model_group.week_02 == false)
      return !this.checkSubscriberInWeek(2);

    if (this.model_group.week_03 == false)
      return !this.checkSubscriberInWeek(3);

    if (this.model_group.week_04 == false)
      return !this.checkSubscriberInWeek(4);

      return true;
  }


  // GROUPS & MODAL

  newGroup () {
    this.showModalGroup();
  }

  onGroupSelect({ selected }) {

    if (!this.userService.checkPermission("camp_edit_group"))
      return;

    Object.assign(this.model_group, selected[0]);
    this.showModalGroup();
  }

  model_group: any = {};

  isModalGroup: boolean = false;

  showModalGroup(): void {
    this.isModalGroup = true;
  }

  hideModalGroup(): void {
    this.groupModal.hide();
  }

  onHiddenGroup(): void {
    this.isModalGroup = false;
    this.model_group = {};
  }

  submit() {

    if (this.model_group.id) {

      if (this.checkUpdatePermission())
      {
        //  aggiorno il gruppo
        this.campService.updateCampGroup(this.model_group, () => {
          this.hideModalGroup();
        })
      }
      else
      {
        this.notify.error("Group Info", "There are one or more children in a not selected week");
        return;
      }
    } else {
      this.campService.saveCampGroup(this.model_group, () => {
        this.hideModalGroup();
      })
    }
  }

  destroyGroup() {

    //  controllo se a questo gruppo appartiene almeno un bambino
    for (var k in this.campWeeksList) {
      if (this.campWeeksList[k].filter(x => x.group.camp_group_id == this.model_group.id).length > 0)
      {
        this.notify.error("Group Info", "There are one or more children in this group");
        break;
      }
      else {
        // cancello il gruppo
        this.campService.deleteCampGroup(this.model_group, () => {
          this.hideModalGroup();
        })
        break;
      }
    };

  }

  isOngoingRequest() {
    return this.campService.isOngoingRequest()
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

  getWarehouseService() {
    return this.warehouseService
  }

}
