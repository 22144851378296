import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprise-home',
  templateUrl: './enterprise-home.component.html',
  styleUrls: ['./enterprise-home.component.css']
})
export class EnterpriseHomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  searchKeywordsSubmit(data){
    this.router.navigate(['enterprise/search', data], { skipLocationChange: true });
  }

}