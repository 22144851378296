import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { PersonAddressFormComponent } from '../index';
import { PersonService } from '../../_services/index';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-person-address-list',
  templateUrl: './person-address-list.component.html',
  styleUrls: ['./person-address-list.component.css']
})
export class PersonAddressListComponent implements OnInit {

  @ViewChildren('personAddressFormList') public personAddressFormList: QueryList<PersonAddressFormComponent>;

  personAddressList: any;
  personAddressListKeys: any[];

  allFormValid = true;
  multiSaveForm = true;

  subscribe: any;

  constructor(private personService: PersonService, private notify: NotificationsService) { }

  ngOnInit() {

    this.personAddressList = this.personService.currentPersonAddressList;

    if (this.personAddressList == null)
      this.addNew();

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      if (this.personService.currentPersonAddressList != null) {

        let newFormModel = null;
        if (typeof this.personAddressFormList.first.model.id == "undefined"
          && this.personAddressFormList.first.currentForm.form.dirty
          && this.personAddressFormList.first.currentForm.form.pending) {
          newFormModel = this.personAddressFormList.first.model;
        }

        this.personAddressListKeys = Object.keys(this.personService.currentPersonAddressList).reverse();

        let personAddressListOld = this.personAddressList;
        this.personAddressList = this.personService.currentPersonAddressList;

        this.personAddressListKeys.forEach(k => {

          if (typeof personAddressListOld[k] != "undefined" && this.personAddressList[k]["updated_at"] == personAddressListOld[k]["updated_at"])
            this.personAddressList[k] = personAddressListOld[k];
        });

        if (newFormModel != null) {
          this.personAddressList[0] = newFormModel;
          this.personAddressListKeys.unshift(0);
        }

      }

      if (this.personService.currentPersonAddressList != null && this.personService.currentPersonAddressList.length == 0)
        this.addNew();

    });
  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }


  addNew() {
    if (this.personAddressList == null) {
      this.personAddressList = {};
      this.personAddressListKeys = new Array();
    }

    this.personAddressList[0] = {};
    this.personAddressListKeys.unshift(0);

    //  Assegno l'indirizzo come primary perchè è il primo della lista
    if (this.personAddressListKeys.length == 1)
      this.personAddressList[0].primary = true;
  }


  saveAll() {

    this.allFormValid = true;

    // Controllo se tutti i form sono validi
    this.personAddressFormList.forEach(element => {

      if (!element.currentForm.form.valid) {

        //  Controllo se non è valido

        for (var i in element.currentForm.controls) {
          element.currentForm.controls[i].markAsTouched();
        }

        this.allFormValid = false;
        this.notify.error("There is a form with error");
      }

    });

    if (!this.allFormValid)
      return;

    if (!this.checkPrimary())
      return;

    this.personAddressFormList.forEach(element => {
      element.submit();
    });
  }

  checkPrimary() {

    // controllo se esiste almeno un contatto primary
    let primaryFound = false;

    this.personAddressFormList.forEach(element => {
      if (element.model.primary)
        primaryFound = true;
    });

    if (!primaryFound) {
      this.notify.error("You need to set a primary contact");
      return false;
    }

    return true;
  }


  primaryUpdate(event) {
    //  Cambio il valore di tutti i campi primary
    if (event.primary == true) {
      this.personAddressListKeys.forEach(element => {
        if (this.personAddressList[element].id != event.id) {
          this.personAddressList[element].primary_backup = this.personAddressList[element].primary;
          this.personAddressList[element].primary = 0;
        }
      });
    }
    else {
      this.personAddressListKeys.forEach(element => {
        if (this.personAddressList[element].primary_backup && this.personAddressList[element].id != event.id) {
          this.personAddressList[element].primary = this.personAddressList[element].primary_backup;
        }
      });
    }
  }


  formDelete(event) {
    if (!event.model.id && this.personAddressListKeys.length > 1) {
      this.personAddressListKeys.splice(0, 1);
      delete this.personAddressList[0];
    }
  }

  formSaved(event) {

    // controllo se questo form ha il primary impostato, perché potrebbe aver cambiato anche gli altri
    if (event.primary == true) {

      this.personAddressFormList.forEach(element => {

        if (event.id != element.model.id) {

          if (typeof element.model.primary_backup != "undefined" && element.model.primary_backup != element.model.primary) {
            element.currentForm.form.markAsDirty();
            element.submit();
          }
        }
      });
    }
  }

  getPersonService() {
    return this.personService
  }
}
