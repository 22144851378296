<!-- BEGIN SIDEBAR -->
<div class="page-sidebar-wrapper">
  <!-- BEGIN SIDEBAR -->
  <!-- DOC: Set data-auto-scroll="false" to disable the sidebar from auto scrolling/focusing -->
  <!-- DOC: Change data-auto-speed="200" to adjust the sub menu slide up/down speed -->
  <div class="page-sidebar navbar-collapse collapse" *ngIf="currentUser">
    <!-- BEGIN SIDEBAR MENU -->
    <!-- DOC: Apply "page-sidebar-menu-light" class right after "page-sidebar-menu" to enable light sidebar menu style(without borders) -->
    <!-- DOC: Apply "page-sidebar-menu-hover-submenu" class right after "page-sidebar-menu" to enable hoverable(hover vs accordion) sub menu mode -->
    <!-- DOC: Apply "page-sidebar-menu-closed" class right after "page-sidebar-menu" to collapse("page-sidebar-closed" class must be applied to the body element) the sidebar sub menu mode -->
    <!-- DOC: Set data-auto-scroll="false" to disable the sidebar from auto scrolling/focusing -->
    <!-- DOC: Set data-keep-expand="true" to keep the submenues expanded -->
    <!-- DOC: Set data-auto-speed="200" to adjust the sub menu slide up/down speed -->
    <ul style="overflow:scroll; height:100vmin;" class="page-sidebar-menu  page-header-fixed "
      data-keep-expanded="false" data-auto-scroll="false" data-slide-speed="200">
      <!-- DOC: To remove the sidebar toggler from the sidebar you just need to completely remove the below "sidebar-toggler-wrapper" LI element -->
      <!-- BEGIN SIDEBAR TOGGLER BUTTON -->
      <li class="sidebar-toggler-wrapper hide">
        <div class="sidebar-toggler">
          <span></span>
        </div>
      </li>
      <!-- END SIDEBAR TOGGLER BUTTON -->

      <li class="nav-item start">
        <!--<li class="nav-item start active">-->
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/home']">
          <i class="icon-home"></i>
          <span class="title">Dashboard</span>
          <!--<span class="selected"></span>-->
        </a>
      </li>

      <li *ngIf="getUserService().checkPermission('agenda_read')" class="nav-item">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/agenda']">
          <i class="fa fa-calendar-check-o"></i>
          <span class="title">Agenda</span>
        </a>
      </li>


      <!-- Solo se sei un ATTORE -->
      <li *ngIf="getUserService().isActor() && getUserService().checkPermission('actorgroup_cashflow_edit')"
        class="heading">
        <h3 class="uppercase">Actor Group</h3>
      </li>
      <li *ngIf="getUserService().isActor() && getUserService().checkPermission('actorgroup_cashflow_edit')"
        class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/actorgroup/cashflow']">
          <i class="fa fa-line-chart" aria-hidden="true"></i>
          <span class="title">Cash Flow</span>
        </a>
      </li>

      <!-- Solo se sei un OFFICE FINANCIAL STAFF -->
      <li *ngIf="getUserService().checkPermission('admin_reports_read')" class="heading">
        <h3 class="uppercase">Admin Dashboard</h3>
      </li>
      <li *ngIf="getUserService().checkPermission('admin_reports_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/cashflow/search']">
          <i class="fa fa-money" aria-hidden="true"></i>
          <span class="title">Cash Flow</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('admin_reports_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/payments/search']">
          <i class="fa fa-credit-card" aria-hidden="true"></i>
          <span class="title">Payment Requests</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('admin_reports_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/admin/reports']">
          <i aria-hidden="true" class="fa fa-bar-chart"></i>
          <span class="title">Reports</span>
        </a>
      </li>



      <!-- Solo se sei STAFF -->
      <li
        *ngIf="getUserService().checkPermission('person_read') || getUserService().checkPermission('enterprise_read') || getUserService().checkPermission('vehicle_read')"
        class="heading">
        <h3 class="uppercase">Anagrafica</h3>
      </li>
      <li *ngIf="getUserService().checkPermission('person_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/person/home']">
          <i class="fa fa-user"></i>
          <span class="title">People</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('enterprise_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['enterprise/home']">
          <i class="fa fa-industry"></i>
          <span class="title">Enterprises</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('vehicle_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['vehicle/home']">
          <i class="fa fa-car"></i>
          <span class="title">Vehicles</span>
        </a>
      </li>

      <li
        *ngIf="getUserService().checkPermission('theatrino_read') || getUserService().checkPermission('actorgroup_read') || getUserService().checkPermission('show_read') || getUserService().checkPermission('theatrino_reports_read')"
        class="heading">
        <h3 class="uppercase">Theatrino</h3>
      </li>
      <li *ngIf="getUserService().checkPermission('theatrino_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/theatrino/home']">
          <i class="fa fa-calendar"></i>
          <span class="title">Tours</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('actorgroup_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/actorgroup/home']">
          <i class="fa fa-group"></i>
          <span class="title">Gruppi</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('show_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/show/home']">
          <i class="fa fa-university"></i>
          <span class="title">Spettacoli</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('theatrino_reports_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/theatrino/reports']">
          <i class="fa fa-bar-chart" aria-hidden="true"></i>
          <span class="title">Reports</span>
        </a>
      </li>


      <li
        *ngIf="getUserService().checkPermission('camp_reports_read') || getUserService().checkPermission('camp_documents_read') || getUserService().checkPermission('camp_transfer_details_read') || getUserService().checkPermission('camp_read') || getUserService().checkPermission('camp_calendar_read')"
        class="heading">
        <h3 class="uppercase">Camps</h3>
      </li>
      <li *ngIf="getUserService().checkPermission('camp_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/camp/home']">
          <i class="fa fa-free-code-camp" aria-hidden="true"></i>
          <span class="title">Camps</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('camp_calendar_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/camp/calendar']">
          <i class="fa fa-calendar" aria-hidden="true"></i>
          <span class="title">Camps Calendar</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('camp_transfer_details_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/camp/transfer-details']">
          <i class="fa fa-train" aria-hidden="true"></i>
          <span class="title">Transfer details</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('camp_documents_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/camp/documents']">
          <i class="fa fa-copy" aria-hidden="true"></i>
          <span class="title">Camps Documents</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('camp_reports_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/camp/reports']">
          <i class="fa fa-bar-chart" aria-hidden="true"></i>
          <span class="title">Reports</span>
        </a>
      </li>




      <li
        *ngIf="getUserService().checkPermission('warehouse_shipments_read') || getUserService().checkPermission('warehouse_read')"
        class="heading">
        <h3 class="uppercase">Warehouse</h3>
      </li>
      <li *ngIf="getUserService().checkPermission('warehouse_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/warehouse/home']">
          <i class="fa fa-truck" aria-hidden="true"></i>
          <span class="title">Products</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('warehouse_shipments_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/warehouse/shipments']">
          <i class="fa fa-list-ul" aria-hidden="true"></i>
          <span class="title">Shipment Requests</span>
        </a>
      </li>




      <li
        *ngIf="getUserService().checkPermission('application_tutors_read') || getUserService().checkPermission('application_actors_read') || getUserService().checkPermission('application_facilitators_read')"
        class="heading">
        <h3 class="uppercase">Applications</h3>
      </li>
      <li *ngIf="getUserService().checkPermission('application_tutors_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/tutorsapplications/home']">
          <i class="fa fa-id-card-o" aria-hidden="true"></i>
          <span class="title">Tutors applications</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('application_actors_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/actorsapplications/home']">
          <i class="fa fa-id-card-o" aria-hidden="true"></i>
          <span class="title">Actors applications</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('application_facilitators_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/facilitatorsapplications/home']">
          <i class="fa fa-id-card-o" aria-hidden="true"></i>
          <span class="title">Facilitators applications</span>
        </a>
      </li>



      <li
        *ngIf="getUserService().checkPermission('course_schedule_read') || getUserService().checkPermission('course_read')"
        class="heading">
        <h3 class="uppercase">Training courses</h3>
      </li>
      <li *ngIf="getUserService().checkPermission('course_schedule_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/courseschedule/home']">
          <i class="fa fa-calendar" aria-hidden="true"></i>
          <span class="title">Course Schedules</span>
        </a>
      </li>
      <li *ngIf="getUserService().checkPermission('course_read')" class="nav-item  ">
        <a href="javascript:void(0);" onclick="$('.navbar-collapse').toggleClass('in');" class="nav-link "
          [routerLink]="['/course/home']">
          <i class="fa fa-university" aria-hidden="true"></i>
          <span class="title">Course database</span>
        </a>
      </li>

      <div style="height: 100px;"></div>

    </ul>
    <!-- END SIDEBAR MENU -->
    <!-- END SIDEBAR MENU -->
  </div>
  <!-- END SIDEBAR -->
</div>
<!-- END SIDEBAR -->
