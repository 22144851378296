<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <h3 class="form-section">Transfer Detail</h3>

    <div class="row">
      <div class="col-md-6">
        <h4>From</h4>
      </div>
      <div class="col-md-6">
        <h4>To</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group">
          <label>Orientation or Returns</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input (change)="onTypeChange($event)" type="checkbox" name="orientation_returns" [(ngModel)]="model.orientation_returns" value="1"> Yes, this person does not depart from a camp
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div *ngIf="editMode && model.orientation_returns != 1" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || from_camp_id.dirty || from_camp_id.touched) && !from_camp_id.valid }">
          <label class="control-label">Departure Camp <span class="required">*</span></label>
          <select class="form-control" name="from_camp_id" [(ngModel)]="model.from_camp_id" #from_camp_id="ngModel"
            (change)="onTypeChange($event)" required>
            <option *ngFor="let c of camp_staff_member" [value]="c.camp_id">{{c.camp_code}}</option>
          </select>
          <span class="help-block">Select the camp, required</span>
        </div>
        <div *ngIf="from_camp_info" class="form-group editMode">
          <p class="line-breaker">
            <b>{{from_camp_info.camp_code}}</b>
            <small><i class="fa fa-train mx-1" aria-hidden="true"></i> {{from_camp_info.railway}}
            <i class="fa fa-road mx-1" aria-hidden="true"></i> {{from_camp_info.transfer_details}}</small>
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || to_camp_id.dirty || to_camp_id.touched) && !to_camp_id.valid }">
          <label class="control-label">Arrival Camp <span class="required">*</span></label>
          <select class="form-control" name="to_camp_id" [(ngModel)]="model.to_camp_id" #to_camp_id="ngModel"
            (change)="onTypeChange($event)" required>
            <option *ngFor="let c of camp_staff_member" [value]="c.camp_id">{{c.camp_code}}</option>
          </select>
          <span class="help-block">Select the camp, required</span>
        </div>
        <div *ngIf="to_camp_info" class="form-group editMode">
          <p class="line-breaker">
            <b>{{to_camp_info.camp_code}}</b>
            <small><i class="fa fa-train mx-1" aria-hidden="true"></i> {{to_camp_info.railway}}
            <i class="fa fa-road mx-1" aria-hidden="true"></i> {{to_camp_info.transfer_details}}</small>
          </p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="model.transport || editMode">
      <div class="col-md-12">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Transport (Bus/Train/Plane)</label>
          <input type="text" class="form-control" name="transport" #transport="ngModel" placeholder=""
          [(ngModel)]="model.transport">
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Transport (Bus/Train/Plane)</label>
          <p>{{model.transport}}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Departure</label>
          <input type="text" class="form-control" name="from_place" #from_place="ngModel" placeholder=""
            [(ngModel)]="model.from_place">
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Departure</label>
          <p>{{model.from_place}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Arrival</label>
          <input type="text" class="form-control" name="to_place" #to_place="ngModel" placeholder=""
            [(ngModel)]="model.to_place">
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Arrival</label>
          <p>{{model.to_place}}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Date</label>
            <div class="input-group">
            <!-- input box -->
            <input angular-mydatepicker #dp_from_date="angular-mydatepicker" name="from_date" class="form-control"
              [locale]="getHelper().locale"
              [disabled]="getPersonService().isOngoingRequest()"
              [options]="getDatePickerOptions()"
              [ngModel]="from_date"
              (dateChanged)="onFromDateChanged($event)"
            />
            <!-- toggle calendar button -->
            <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_from_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
            </span>
          </div>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Date</label>
          <p>{{model.from_date | date:'d MMMM, y':'':'it'}}</p>
        </div>
      </div>

      <div class="col-md-3">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Time</label>
          <input type="time" name="from_time" [(ngModel)]="model.from_time" class="form-control" placeholder="08:30" autocomplete="off">
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Time</label>
          <p>{{model.from_time}}</p>
        </div>
      </div>

      <div class="col-md-3">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Date</label>
            <div class="input-group">
            <!-- input box -->
            <input angular-mydatepicker #dp_to_date="angular-mydatepicker" name="to_date" class="form-control"
              [locale]="getHelper().locale"
              [disabled]="getPersonService().isOngoingRequest()"
              [options]="getDatePickerOptions()"
              [ngModel]="to_date"
              (dateChanged)="onToDateChanged($event)"
            />
            <!-- toggle calendar button -->
            <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_to_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
            </span>
          </div>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Date</label>
          <p>{{model.to_date | date:'d MMMM, y':'':'it'}}</p>
        </div>
      </div>

      <div class="col-md-3">
          <div *ngIf="editMode" class="form-group">
            <label class="control-label">Time</label>
            <input type="time" name="to_time" [(ngModel)]="model.to_time" class="form-control" placeholder="08:30" autocomplete="off">
          </div>
          <div *ngIf="!editMode" class="form-group editMode">
            <label class="control-label">Time</label>
            <p>{{model.to_time}}</p>
          </div>
        </div>
    </div>

    <div class="row" *ngIf="model.id">
        <div class="col-md-6">
          <div *ngIf="editMode" class="form-group">
            <label class="control-label">Attachment file</label>
            <a *ngIf="model.file_path && !getPersonService().isOngoingRequest()" [href]="getConfigService().blobBasePath + model.file_path" target="_self" class="btn green-jungle btn-block">
              <i class="fa fa-file"></i> Download Attachment
            </a>
            <div *ngIf="model.file_path" class="clearfix">
              <button type="button" [disabled]="getPersonService().isOngoingRequest()" class="btn red btn-block" (click)="destroyFile();">Remove Document</button>
            </div>
            <div *ngIf="!model.file_path">
              <div *ngIf="model.file_path == undefined || model.file_path == ''">
                <input *ngIf="!documentUploader.isUploading" type="file" id="documentUploader"
                  ng2FileSelect [uploader]="documentUploader" />
                <label *ngIf="!documentUploader.isUploading" for="documentUploader"
                  class="btn btn-block green-jungle text-uppercase label-file"><i class="fa fa-upload"></i>&nbsp; Upload Document!</label>
              </div>
              <div *ngIf="documentUploader.isUploading || getPersonService().isOngoingRequest()">
                <img src="./assets/global/img/input-spinner.gif" />
                please wait...
              </div>
            </div>
          </div>
          <div *ngIf="!editMode" class="form-group editMode">
            <label class="control-label">Attachment file</label>
            <a *ngIf="model.file_path" [href]="getConfigService().blobBasePath + model.file_path" target="_self" class="btn green-jungle btn-block">
              <i class="fa fa-file"></i> Download Attachment
            </a>
            <p *ngIf="!model.file_path">none</p>
          </div>
        </div>
      </div>

    <div class="row" *ngIf="model.annotation || editMode">
      <div class="col-md-12">
        <div *ngIf="editMode" class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" name="annotation" placeholder="Some notes..."
            [(ngModel)]="model.annotation"></textarea>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Notes</label>
          <p class="line-breaker">{{model.annotation}}</p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="model.id && !editMode">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label"><i class="fa fa-refresh" aria-hidden="true"></i> Ultimo aggiornamento</label>
          <p class="line-breaker">{{model.updated_at | date:'d MMMM, y - HH:mm':'':'it'}}</p>
        </div>
      </div>
    </div>
  </div>


  <div [appCheckPermission]="'person_edit'" class="form-actions right">
    <img *ngIf="getPersonService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getPersonService().isOngoingRequest()"
      *ngIf="this.model.id" [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i
        class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline red" [disabled]="getPersonService().isOngoingRequest()"
      *ngIf="!this.model.id" (click)="delete()"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline blue" [disabled]="getPersonService().isOngoingRequest()"
      *ngIf="this.model.id && !editMode" (click)="edit()"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      Edit</button>

    <button *ngIf="multiSave == false || editMode" type="submit" class="btn blue"
      [disabled]="getPersonService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>
