<div class="row">
  <div class="col-md-12">
    <app-show-search-form (searchKeywordsSubmit)="searchKeywordsSubmit($event)" [ongoingRequest]="datatable.ongoingRequest"></app-show-search-form>
  </div>
</div>

<br><br>

<!-- begin: prima riga -->
<div class="row">
  <div class="col-md-12">

    <!--begin: Portlet -->
    <div class="portlet light bordered">
      <!--end: Portlet-Body -->
      <div class="portlet-body">
         <app-show-datatable (showSelected)="showSelected($event)"></app-show-datatable> 
      </div>
      <!--end: Portlet-Body -->
    </div>
    <!--end: Portlet -->
  </div>
</div>
<!--end: prima riga -->