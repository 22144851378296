import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GroupService } from '../../_services/index';
import { ReminderShowComponent } from '../../reminder/index';

@Component({
  selector: 'app-actor-group-reminder',
  templateUrl: './actor-group-reminder.component.html',
  styleUrls: ['./actor-group-reminder.component.css']
})
export class ActorGroupReminderComponent implements OnInit {

  @ViewChild('reminderShowComponent', {static: false}) public reminderShowComponent: ReminderShowComponent;

  subscribe: any;
  model: any = {};
  holderName: string;
  holderId: any;
  holderType: string;

  constructor(private groupService: GroupService) { }

  ngOnInit() {

    this.subscribe = this.groupService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      if (!this.groupService.currentGroupInfo)
        return;

      this.holderName = this.groupService.currentGroupInfo["group_name"];
      this.holderId = this.groupService.currentGroupId;
      this.holderType = 'App\\ActorGroup';
    });
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  modelUpdated(event){
    this.reminderShowComponent.modelUpdated(event);
  }

}
