import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserService } from './user.service';

declare var saveAs: any;

@Injectable()
export class CourseScheduleService {

  currentCourseScheduleId;
  currentCourseScheduleInfo: any;
  currentCourseTimetable: any[];
  currentCourseStaffList: any[];
  currentCourseDiaryList: any;
  currentCourseSubscriberList: any[];
  currentCourseRooms: any = [];
  currentCourseGroups: any = [];
  // currentDocumentList: any[];
  // currentMaterialList: any[];

  ongoingRequest: boolean = false;

  //	Memorizza l'ultima query di ricerca fatta
  lastSearchQueries: any = null;


  private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

  constructor(private backend: BackendService, private notify: NotificationsService, private userService: UserService, private http: HttpClient) { }

  getsubjectToUpdateObservable(): Observable<boolean> {
    return this.subjectToUpdate.asObservable();
  }

  resetService() {
    this.currentCourseScheduleId = null;
    this.currentCourseScheduleInfo = null;
    this.currentCourseTimetable = null;
    this.currentCourseStaffList = null;
    this.currentCourseDiaryList = null;
    this.currentCourseSubscriberList = null;
    this.currentCourseRooms = null;
    this.currentCourseGroups = null;
    // this.currentDocumentList = null;
    // this.currentMaterialList = null;
  }

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  isOngoingRequest() {
    return this.ongoingRequest;
  }

  public canEditStaff() {

    if (this.userService.checkPermission('course_schedule_edit'))
      return true;

    // let u = this.userService.getCurrentUser();

    // if (!u)
    //   return false;

    // let p = u.person_info;

    // if (this.isPersonCampDirector(p.id)){
    //   return true;
    // }

    return false;
  }

  public canEditDiscount() {

    if (this.userService.checkPermission('course_schedule_add_discount'))
      return true;

    // let p = this.userService.getCurrentUser().person_info;

    // if (this.isPersonCampDirector(p.id)){
    //   return true;
    // }

    return false;
  }

  public canEditCourseScheduleTravelInfo() {

    if (this.userService.checkPermission('course_schedule_edit'))
      return true;

    // let p = this.userService.getCurrentUser().person_info;

    // if (this.isPersonCampDirector(p.id)){
    //   return true;
    // }

    return false;
  }

  public checkSubscribers()
  {
    let max_num_earned = false;
    if (this.currentCourseScheduleInfo != null)
    if (this.currentCourseScheduleInfo.max_num_subscribers != null &&
      typeof this.currentCourseScheduleInfo.max_num_subscribers != "undefined")
    {
      let counter = this.currentCourseSubscriberList.filter(x => x.deleted_at == null);
      if (counter && counter.length >= this.currentCourseScheduleInfo.max_num_subscribers)
        return max_num_earned = true;
    }

    return max_num_earned;
  }

  /* API di esportazione di tutti i campi */
  exportAll(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.post('courseschedule/export', model).subscribe(
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      });
  }




  getAllCourseSchedulesSearch(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.post('courseschedule/search', model).subscribe(
      (response) => {

        let resJson : any = response;

        if (callbackSuccess)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );

  }


  //  recupero le info base di tutti i corsi di formazione attivi
  // getAllCourseScheduleInfoLight(callbackSuccess: any = null, callbackError: any = null) {

  //   let params = new HttpParams();

  //   this.backend.get('courseschedule/light/all', params).subscribe(
  //     (response) => {

  //       let resJson : any = response;

  //       if (callbackSuccess != null)
  //         callbackSuccess(resJson);
  //     },
  //     (error) => {

  //       this.backend.showErrors(error);

  //       if (callbackError != null)
  //         callbackError(error)
  //     }
  //   );
  // }

  getCourseScheduleInfoAll(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('courseschedule/' + this.currentCourseScheduleId + '/all', params).subscribe(
      (response) => {

        let resJson : any = response;

        this.currentCourseScheduleInfo = resJson;
        this.currentCourseScheduleId = resJson.id;
        this.currentCourseStaffList = resJson.staff;
        this.currentCourseDiaryList = resJson.diary;
        this.currentCourseSubscriberList = resJson.subscribers;
        this.currentCourseRooms = resJson.rooms;
        this.currentCourseGroups = resJson.groups;
        this.currentCourseTimetable = resJson.timesheet;
        // this.currentDocumentList = resJson.documents;
        // this.currentMaterialList = resJson.materials;

        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });
  }

  saveCourseScheduleInfo(model: any[], callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('courseschedule', model).subscribe(
      (response) => {

        let resJson : any = response;
        this.currentCourseScheduleInfo = resJson;
        this.currentCourseScheduleId = resJson.id;
        this.subjectToUpdate.next(true);

        this.notify.success("Course Schedule info", "successfully saved");

        this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess(resJson);
			},
			(error) => {
				this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );
  }

  updateCourseScheduleInfo(model: any[], callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('courseschedule/' + this.currentCourseScheduleId, model).subscribe(
      (response) => {

        this.currentCourseScheduleInfo = response;
        this.currentCourseScheduleId = this.currentCourseScheduleInfo.id;
        this.subjectToUpdate.next(true);

        this.notify.success("Course Schedule info", "successfully updated");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        console.log("Errore");
        console.log(error);

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );
  }

  deleteCourseScheduleInfo(model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId).subscribe(
      (response) => {

        this.resetService();
        this.subjectToUpdate.next(true);

        this.notify.success("Course Schedule info", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }




  saveCourseDiary(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/diary', model).subscribe(
      (response) => {

        var diary : any = response;

        if (this.currentCourseDiaryList == null)
          this.currentCourseDiaryList = {};

        this.currentCourseDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updateCourseDiary(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('courseschedule/' + this.currentCourseScheduleId + '/diary/' + model.id, model).subscribe(
      (response) => {

        var diary : any = response;

        if (this.currentCourseDiaryList == null)
          this.currentCourseDiaryList = {};

        this.currentCourseDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteCourseDiary(model: any) {

    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/diary/' + model.id).subscribe(
      (response) => {

        var diary : any = response;

        if (this.currentCourseDiaryList == null)
          this.currentCourseDiaryList = {};

        this.currentCourseDiaryList = diary;
        this.subjectToUpdate.next(true);

        this.notify.success("Diary", "successfully deleted");

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }







  saveCourseStaffMember(model: any, callbackSuccess: any = null, callbackError: any = null, externalSave: boolean = false) {
    this.startRequest();

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/staff', model).subscribe(
      (response) => {

        let resJson : any = response;

        if (!externalSave)
        {
          this.currentCourseStaffList.push(resJson);

          this.subjectToUpdate.next(true);

          this.notify.success("Course Staff Member", "successfully saved");
        }

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );

  }



  updateCourseStaffMember(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('courseschedule/' + this.currentCourseScheduleId + '/staff/' + model.id, model).subscribe(
      (response) => {

        let resJson : any = response;

        var found = false;
        for (var i = 0; i < this.currentCourseStaffList.length && !found; i++) {
          if (this.currentCourseStaffList[i].id == model.id) {
            this.currentCourseStaffList[i] = resJson;
            found = true;
          }
        }

        this.subjectToUpdate.next(true);

        this.notify.success("Course Staff Member", "successfully updated");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        console.log("Errore");
        console.log(error);

        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );
  }



  deleteCourseStaffMember(model: any, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/staff/' + model.id).subscribe(
      (response) => {

        var found = false;
        for (var i = 0; i < this.currentCourseStaffList.length && !found; i++) {
          if (this.currentCourseStaffList[i].id == model.id) {
            found = true;
            this.currentCourseStaffList.splice(i, 1);
          }
        }

        this.subjectToUpdate.next(true);

        this.notify.success("Course Staff Member", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }
    );
  }



  getFileUploadHeader() {
    return this.backend.setupAuthHttpHeaders().headers;
  }

  public uploadedFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
      this.notify.success("Document File", 'successfully uploaded');
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Document File', JSON.parse(response)['message']);
      else
        this.notify.error('Document File', response);

      this.backend.showErrors(response);
    }
  }




  getStaffDocumentFileUploadURL(model: any, type: any) {
    return (environment.apiUrl + 'courseschedule/' + this.currentCourseScheduleId + '/staff/' + model.id + '/document/upload/' + type);
  }


  getAllDocumentForStaffMember(model: any, callbackSuccess: any = null, callbackError: any = null) {
    let params = new HttpParams();

    this.backend.get('courseschedule/' + this.currentCourseScheduleId + '/staff/' + model.id + '/document/all', params).subscribe(
      (response) => {

        let res : any = response;

        if (callbackSuccess != null)
          callbackSuccess(res);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      }
    );
  }



  deleteDocumentForStaffMember(model: any, idDocument: any, callbackSuccess: any = null) {

    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/staff/' + model.id + '/document/' + idDocument).subscribe(
      (response) => {

        this.finishRequest();

        this.notify.success("Staff Document", "successfully deleted");

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {

        this.finishRequest();
      }
    );

  }




  //	recupero le informazioni che arrivano dal form esterno degli iscritti al campo
  getSignupFormsInfo(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('courseschedule/' + this.currentCourseScheduleId + '/signupforms', params).subscribe(
      (response) => {

        let resJson : any = response;

        //	Ordino l'array in base a se le richieste sono stati già inserite o meno come utenti
        resJson.sort(function (a, b) {
          return a.registered > b.registered ? 1 : a.registered < b.registered ? -1 : 0
        }
        );

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      });

  }



  //  recupero le informazioni delle richieste di pagamento
  getPaymentRequests(callbackSuccess:any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('courseschedule/' + this.currentCourseScheduleId + '/paymentrequests', params).subscribe (
      (response) => {

        let resJson : any = response;

        //  Ordino l'array in base alle richieste se sono attive o meno

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );

  }


  getLeafletFileUploadURL(model: any) {
    return (environment.apiUrl + 'courseschedule/' + this.currentCourseScheduleId + '/document/leaflet/upload');
  }
  getConditionFileUploadURL(model: any) {
    return (environment.apiUrl + 'courseschedule/' + this.currentCourseScheduleId + '/document/condition/upload');
  }
  getVrequestFileUploadURL(model: any) {
    return (environment.apiUrl + 'courseschedule/' + this.currentCourseScheduleId + '/document/vrequest/upload');
  }
  deleteLeaflet(model: any, callbackSuccess = null, callbackError = null) {

    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/leaflet').subscribe(
      (response) => {

        this.notify.success("Leaflet", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );

  }
  deleteVrequest(model: any, callbackSuccess = null, callbackError = null) {
    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/vrequest').subscribe(
      (response) => {

        this.notify.success("Venue Request", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );
  }
  deleteCondition(model: any, callbackSuccess = null, callbackError = null) {
    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/condition').subscribe(
      (response) => {

        this.notify.success("Conditions & Agreements", "successfully deleted");

        this.finishRequest();

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    );
  }




  /* API di esportazione di tutti i campi */
  exportSubscribers(model: any, callbackSuccess: any = null, callbackError: any = null) {

    let params = new HttpParams();

    this.backend.get('courseschedule/' + this.currentCourseScheduleId + '/subscriber/export', params).subscribe(
      (response) => {

        if (callbackSuccess != null)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      });
  }



  getCourseSubscriber(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('courseschedule/' + this.currentCourseScheduleId + '/subscriber', params).subscribe(
      (response) => {

        var subscriber : any = response;

        this.currentCourseSubscriberList = subscriber;

        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }


  getCourseStaff(callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    let params = new HttpParams();

    this.backend.get('courseschedule/' + this.currentCourseScheduleId + '/staff', params).subscribe(
      (response) => {

        var subscriber : any = response;

        this.currentCourseStaffList = subscriber;

        this.subjectToUpdate.next(true);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }


  //  salvo tutti i subscribers in una sola volta
  saveMassiveCourseSubscriber(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.post('courseschedule/' + model.course_schedule_id + '/subscriber/copy', model).subscribe(
      (response) => {

        this.notify.success("Copy", "successfully saved");

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }


  //  cancello tutti i subscribers in una sola volta
  deleteMassiveSubscriber(model: any, callbackSuccess: any = null, callbackError: any = null){

    this.backend.post('courseschedule/' + model.course_schedule_id + '/subscriber/massivedelete', model).subscribe(
      (response) => {

        this.notify.success("Deleted", "successfully deleted");

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {

        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }


  saveCourseSubscriber(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/subscriber', model).subscribe(
      (response) => {

        var subscriber : any = response;

        if (this.currentCourseSubscriberList == null)
          this.currentCourseSubscriberList = [];

        this.currentCourseSubscriberList = subscriber;

        this.subjectToUpdate.next(true);

        this.notify.success("Subscriber", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  updateCourseSubscriber(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('courseschedule/' + this.currentCourseScheduleId + '/subscriber/' + model.id, model).subscribe(
      (response) => {

        var subscriber : any = response;

        if (this.currentCourseSubscriberList == null)
          this.currentCourseSubscriberList = [];

        this.currentCourseSubscriberList = subscriber;
        this.subjectToUpdate.next(true);

        this.notify.success("Subscriber", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteCourseSubscriber(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/subscriber/' + model.id).subscribe(
      (response) => {

        var subscriber : any = response;

        if (this.currentCourseSubscriberList == null)
          this.currentCourseSubscriberList = [];

        this.currentCourseSubscriberList = subscriber;
        this.subjectToUpdate.next(true);

        this.notify.success("Subscriber", "successfully deleted");

        if (callbackSuccess)
          callbackSuccess();

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  restoreCourseSubscriber(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/subscriber/' + model.id + "/restore").subscribe(
      (response) => {

        var subscriber : any = response;

        if (this.currentCourseSubscriberList == null)
          this.currentCourseSubscriberList = [];

        this.currentCourseSubscriberList = subscriber;
        this.subjectToUpdate.next(true);

        this.notify.success("Subscriber", "successfully restored");

        if (callbackSuccess)
          callbackSuccess();

        this.finishRequest();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }






  findPerson(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/signupforms/find', model).subscribe(
      (response) => {

        var results : any = response;

        if (callbackSuccess)
          callbackSuccess(results);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }


  ignoreCourseSignupForm(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/signupforms/' + model.signup_form_id + '/ignore', model).subscribe(
      (response) => {

        var results : any = response;

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess(results);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }


  saveCourseRoom(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/room', model).subscribe(
      (response) => {

        this.currentCourseRooms = response;

        this.subjectToUpdate.next(true);

        this.notify.success("Room", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }


  updateCourseRoom(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('courseschedule/' + this.currentCourseScheduleId + '/room/' + model.id, model).subscribe(
      (response) => {

        this.currentCourseRooms = response;

        this.getCourseSubscriber(() => {

          this.subjectToUpdate.next(true);

          this.notify.success("Room", "successfully updated");

          this.finishRequest();

          if (callbackSuccess)
            callbackSuccess();
        })

      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteCourseRoom(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/room/' + model.id).subscribe(
      (response) => {

        this.currentCourseRooms = response;

        this.getCourseSubscriber(() => {

          this.subjectToUpdate.next(true);

          this.notify.success("Room", "successfully deleted");

          if (callbackSuccess)
            callbackSuccess();

          this.finishRequest();
        })

      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }



  saveCourseSubscriberRoom(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/subscriber/room', model).subscribe(
      (response) => {

        let room : any = response;

        this.currentCourseSubscriberList.forEach((subscriber: any) => {
          if (subscriber.id == room.subscriber_id)
            subscriber.rooms.push(room);
        });

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }


  updateCourseSubscriberRoom(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.put('courseschedule/' + this.currentCourseScheduleId + '/subscriber/room/' + model.id, model).subscribe(
      (response) => {

        let room : any = response;

        this.currentCourseSubscriberList.forEach((subscriber: any) => {
          if (subscriber.id == room.subscriber_id)
          {
            subscriber.rooms.splice(0,subscriber.rooms.length);
            subscriber.rooms.push(room);
          }
        });

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteCourseSubscriberRoom(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/subscriber/room/' + model.id).subscribe(
      (response) => {

        this.currentCourseSubscriberList.forEach((subscriber: any) => {

          var index = subscriber.rooms.findIndex(v => v.id === model.id);
          if (index >= 0)
            subscriber.rooms.splice(index, 1);
        });

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }



  saveCourseStaffMemberRoom(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/staff/room', model).subscribe(
      (response) => {

        let room : any = response;

        this.currentCourseStaffList.forEach((staff_member: any) => {
          if (staff_member.id == room.staff_member_id)
            staff_member.rooms.push(room);
        });

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }


  updateCourseStaffMemberRoom(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.put('courseschedule/' + this.currentCourseScheduleId + '/staff/room/' + model.id, model).subscribe(
      (response) => {

        let room : any = response;

        this.currentCourseStaffList.forEach((staff_member: any) => {
          if (staff_member.id == room.staff_member_id)
          {
            staff_member.rooms.splice(0,staff_member.rooms.length);
            staff_member.rooms.push(room);
          }
        });

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteCourseStaffMemberRoom(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/staff/room/' + model.id).subscribe(
      (response) => {

        this.currentCourseStaffList.forEach((staff_member: any) => {

          var index = staff_member.rooms.findIndex(v => v.id === model.id);
          if (index >= 0)
            staff_member.rooms.splice(index, 1);
        });

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }



  public downloadCourseStaff() {

    this.backend.download('courseschedule/' + this.currentCourseScheduleId + '/coursestaff/pdf', this.currentCourseScheduleInfo['course_schedule_id'] + '.pdf');
  }

  public getLinkCertificato(id_subscriber){

    return environment.apiUrl + "courseschedule/"+this.currentCourseScheduleId+"/subscriber/"+id_subscriber+"/pdf";
  }

  sendEmailCertificatoTo (id_subscriber: any, email: string, callbackSuccess: any = null, callbackError: any = null)
	{
    // this.startRequest();

		let params = new HttpParams();

		this.backend.get('courseschedule/' + this.currentCourseScheduleId + '/subscriber/' + id_subscriber + '/mailto/' + email, params).subscribe (
			(response) => {

				// var resJson : any = response;

				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}



  saveTimesheet (model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/timesheet', model).subscribe (
			(response) => {

        var resJson : any = response;
        this.currentCourseTimetable = resJson;

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess(resJson);
			},
			(error) => {

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
  }

  updateTimesheet (model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('courseschedule/' + this.currentCourseScheduleId + '/timesheet/' + model.id, model).subscribe (
			(response) => {

        var resJson : any = response;
        this.currentCourseTimetable = resJson;

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess(resJson);
			},
			(error) => {

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
  }

  deleteTimesheet (timeDateId: number, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/timesheet/' + timeDateId ).subscribe (
			(response) => {

        var resJson : any = response;
        this.currentCourseTimetable = resJson;

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess(resJson);
			},
			(error) => {

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
  }





  saveCourseGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/group', model).subscribe(
      (response) => {

        this.currentCourseGroups = response;

        this.subjectToUpdate.next(true);

        this.notify.success("Group", "successfully saved");

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }


  updateCourseGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.put('courseschedule/' + this.currentCourseScheduleId + '/group/' + model.id, model).subscribe(
      (response) => {

        this.currentCourseGroups = response;

        this.getCourseSubscriber(() => {

          this.subjectToUpdate.next(true);

          this.notify.success("Group", "successfully updated");

          this.finishRequest();

          if (callbackSuccess)
            callbackSuccess();
        })

      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }


  deleteCourseGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/group/' + model.id).subscribe(
      (response) => {

        this.currentCourseGroups = response;

        this.getCourseSubscriber(() => {

          this.subjectToUpdate.next(true);

          this.notify.success("Group", "successfully deleted");

          if (callbackSuccess)
            callbackSuccess();

          this.finishRequest();
        })

      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError)
          callbackError();
      }
    );
  }




  saveCourseSubscriberGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/subscriber/group', model).subscribe(
      (response:any) => {

        let subscriberIndex = this.currentCourseSubscriberList.findIndex(x => x.id == response.subscriber_id);
        if (subscriberIndex >= 0)
          this.currentCourseSubscriberList[subscriberIndex].groups = JSON.parse(JSON.stringify(response));

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }


  updateCourseSubscriberGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.put('courseschedule/' + this.currentCourseScheduleId + '/subscriber/group/' + model.group_id, model).subscribe(
      (response: any) => {

      let subscriberIndex = this.currentCourseSubscriberList.findIndex(x => x.id == response.subscriber_id)
      if (subscriberIndex >= 0)
        this.currentCourseSubscriberList[subscriberIndex].groups = JSON.parse(JSON.stringify(response));

      this.subjectToUpdate.next(true);

      if (callbackSuccess)
        callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteCourseSubscriberGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/subscriber/group/' + model.group_id).subscribe(
      (response) => {

      let subscriberIndex = this.currentCourseSubscriberList.findIndex(x => x.id == model.subscriber_id);
      if (subscriberIndex >= 0)
        this.currentCourseSubscriberList[subscriberIndex].groups = null;

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }





  saveCourseStaffGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.post('courseschedule/' + this.currentCourseScheduleId + '/staff/group', model).subscribe(
      (response:any) => {

        let memberStaffIndex = this.currentCourseStaffList.findIndex(x => x.id == response.staff_member_id);
        if (memberStaffIndex >= 0)
          this.currentCourseStaffList[memberStaffIndex].groups = JSON.parse(JSON.stringify(response));

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }


  updateCourseStaffGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.put('courseschedule/' + this.currentCourseScheduleId + '/staff/group/' + model.group_id, model).subscribe(
      (response: any) => {

        let memberStaffIndex = this.currentCourseStaffList.findIndex(x => x.id == response.staff_member_id);
        if (memberStaffIndex >= 0)
          this.currentCourseStaffList[memberStaffIndex].groups = JSON.parse(JSON.stringify(response));

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess(response);
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }

  deleteCourseStaffGroup(model: any, callbackSuccess: any = null, callbackError: any = null) {

    this.backend.destroy('courseschedule/' + this.currentCourseScheduleId + '/staff/group/' + model.group_id).subscribe(
      (response) => {

        let memberStaffIndex = this.currentCourseStaffList.findIndex(x => x.id == model.staff_member_id);
        if (memberStaffIndex >= 0)
          this.currentCourseStaffList[memberStaffIndex].groups = null;

        this.subjectToUpdate.next(true);

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError)
          callbackError();
      }
    );
  }




}
