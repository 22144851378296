<button class="btn btn-primary btn-block" [disabled]="loading" (click)="getHistory()">Get History</button>

<span *ngIf="!loading && gaston_history?.length > 0">
  <h4>Theatrino Group Member</h4>
  <ngx-datatable *ngIf="!loading" class="striped" [rows]="gaston_history" [columnMode]="'flex'"
    [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
    <ngx-datatable-column name="Data" [flexGrow]="2" prop="theatrino_start" [resizeable]="false" [draggable]="false" [sortable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{row.start_formatted}} <br> {{row.end_formatted}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Theatrino" [flexGrow]="6" prop="theatrino_denomination" [resizeable]="false"
      [draggable]="false" [sortable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <a *ngIf="row.theatrino_id" class="pointer blue" target="_blank" [routerLink]="['/theatrino/edit/', row.theatrino_id]">
          {{value}}&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i></a>
        <span *ngIf="!row.theatrino_id">{{value}}</span>
      </ng-template></ngx-datatable-column>
  </ngx-datatable>
</span>