<!-- BEGIN HEADER -->
<div class="page-header navbar navbar-fixed-top">

  <!-- BEGIN HEADER INNER -->
  <div class="page-header-inner ">
    <!-- BEGIN LOGO -->
    <a [routerLink]="['/home']">
      <div class="page-logo">
        <span>GASTON
          <small>{{currentVersion}}</small>
        </span>
      </div>
    </a>
    <!-- END LOGO -->
    <!-- BEGIN RESPONSIVE MENU TOGGLER -->
    <a href="javascript:;" class="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse">
      <span></span>
    </a>

    <!-- END RESPONSIVE MENU TOGGLER -->
    <!-- BEGIN TOP NAVIGATION MENU -->
    <div *ngIf="currentUser" class="top-menu custom">
      <ul class="nav navbar-nav pull-right">

          <li class="dropdown dropdown-extended dropdown-notification" id="header_notification_bar">
              <a [routerLink]="['/agenda']" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="false">
                  <i class="fa fa-bell" aria-hidden="true"></i>
                  <span *ngIf="reminderNotification > 0" class="badge badge-danger"> {{ reminderNotification }} </span>
              </a>
          </li>

        <!-- BEGIN USER LOGIN DROPDOWN -->
        <!-- DOC: Apply "dropdown-dark" class after below "dropdown-extended" to change the dropdown styte -->
        <li class="dropdown dropdown-user">
          <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
            <img *ngIf="currentUser.avatarURL" class="img-circle" src="{{currentUser.avatarURL}}?{{currentUser.updated_at}}" />
            <img *ngIf=!currentUser.avatarURL alt="" class="img-circle" src="../assets/placeholder/avatar-placeholder.jpg" />
            <span class="username username-hide-on-mobile"> {{currentUser.email}} </span>
            <i class="fa fa-angle-down"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-default">
            <li>
              <a [routerLink]="['/login']">
                <i class="icon-logout"></i> Log Out </a>
            </li>
            <li>
              <a (click)="updateCurrentUser()">Aggiorna dati utente </a>
            </li>
          </ul>
        </li>
        <!-- END USER LOGIN DROPDOWN -->

        <!-- BEGIN LANGUAGE DROPDOWN -->
        <!-- DOC: Apply "dropdown-dark" class after below "dropdown-extended" to change the dropdown styte -->
        <li class="dropdown dropdown-dark dropdown-language">
          <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
            <img src="../assets/global/img/flags/{{currentLanguage}}.png" />
            <span class="current-lang"> {{currentLanguage}} </span>
          </a>
          <ul class="dropdown-menu dropdown-menu-default">
            <li>
              <a href="#" (click)="setLanguage('it');">
                <img src="../assets/global/img/flags/it.png" />&nbsp;&nbsp;Italian </a>
            </li>
            <li>
              <a href="#" (click)="setLanguage('en');">
                <img src="../assets/global/img/flags/en.png" />&nbsp;&nbsp;English </a>
            </li>
          </ul>
        </li>
        <!-- END LANGUAGE DROPDOWN -->
      </ul>
    </div>
    <!-- END TOP NAVIGATION MENU -->
  </div>
  <!-- END HEADER INNER -->
</div>
<!-- END HEADER -->
<!-- BEGIN HEADER & CONTENT DIVIDER -->
<div class="clearfix"> </div>
<!-- END HEADER & CONTENT DIVIDER -->
