<div class="row" *ngIf="weekName">
  <div class="col-md-12">

    <div class="row">
      <!-- BEGIN: Portlet Gruppi -->
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6" *ngFor="let group of currentGroupList">
            <div class="portlet solid default">
              <div class="portlet-title">
                <div class="caption"><span class="caption-subject font-dark sbold uppercase">{{group.group_name}}</span>
                </div>
                <div class="actions" *ngIf="group.subscribers">
                  <label class="btn btn-circle btn-transparent default btn-sm active">
                    {{ group.subscribers.length }}
                  </label>
                </div>
              </div>
              <div class="portlet-body">
                <div class="row">
                  <div class="col-md-12">
                    <div dndDropzone class="dndDropZone" (dndDrop)="onDrop($event, group)"
                      dndDragoverClass="dndDragOver">
                      <p class="text-center" style="margin: auto;">Trascina qui per assegnare!</p>
                    </div>

                    <br>

                    <button class="btn btn-block btn-success" *ngIf="group.subscribers && group.subscribers.length > 0 && !group.show_group_subscribers" (click)="group.show_group_subscribers = true"> Mostra {{ group.subscribers.length }} subscribers</button>
                    <button class="btn btn-block btn-primary" *ngIf="group.subscribers && group.subscribers.length > 0 && group.show_group_subscribers" (click)="group.show_group_subscribers = false"> Nascondi subscribers</button>

                    <br>

                    <div class="mt-element-list" *ngIf="group.subscribers && group.subscribers.length > 0 && group.show_group_subscribers">
                      <div class="mt-list-container list-simple">
                        <ul>
                          <li *ngFor="let subscriber of group.subscribers" class="mt-list-item">
                            <b>{{subscriber.person_info.surname}} {{subscriber.person_info.first_name}}</b><br>
                            <small>{{getConfigService().getGenderPersonValueByKey(subscriber.person_info.gender)}},
                              {{getHelper().calculateAge(subscriber.person_info.birthday)}} year, {{getConfigService().getDiaryCampSubscriberSchoolTypeValueByKey(subscriber.school_type)}}
                              - classe:
                              {{getConfigService().getDiaryCampSubscriberSchoolTypeSubValueByKey(subscriber.school_type, subscriber.class_type)}}</small>
                            <br>
                            <button class="btn btn-block btn-danger btn-sm" (click)="deleteSubscriberGroup(subscriber)">Rimuovi</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Portlet Gruppi -->
      <!-- BEGIN: Portlet Subscribers -->
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <select class="form-control" [(ngModel)]="modelFiltering.gender" (change)="filteringChange($event.target)">
              <option [value]=""></option>
              <option *ngFor="let g of getConfigService().genderPersonSelect" [value]="g.key">{{g.value}}</option>
            </select>
          </div>
          <div class="col-md-3">
            <select class="form-control" [(ngModel)]="modelFiltering.school" (change)="filteringChange($event.target)">
              <option [value]=""></option>
              <option *ngFor="let t of getConfigService().diaryCampSubscriberSchoolTypeSelect" [value]="t.key">{{t.value}}</option>
            </select>
          </div>
          <div class="col-md-3">
            <select *ngIf="modelFiltering.school" class="form-control" [(ngModel)]="modelFiltering.class_type" (change)="filteringChange($event.target)">
              <option [value]=""></option>
              <option *ngFor="let st of getConfigService().diaryCampSubscriberSchoolTypeSubSelect[modelFiltering.school]" [value]="st.key">{{st.value}}</option>
            </select>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div> -->
        <div class="row" >
          <div class="col-md-12">
            <div class="mt-element-list">
              <!-- <div class="mt-list-head list-simple font-white bg-blue">
                  <div class="list-head-title-container">
                    <h4 class="list-title">Subscribers</h4>
                  </div>
                </div> -->
              <div class="mt-list-head bg-white"></div>
              <div class="mt-list-container list-simple">
                <ul>
                  <li *ngFor="let subscriber of filteredWeekList" [dndDraggable]="subscriber" [dndType]="subscriber"
                    class="mt-list-item">
                    <b>{{subscriber.person_info.surname}} {{subscriber.person_info.first_name}}</b><br>
                    <small>{{getConfigService().getGenderPersonValueByKey(subscriber.person_info.gender)}},
                      {{getHelper().calculateAge(subscriber.person_info.birthday)}} year, {{getConfigService().getDiaryCampSubscriberSchoolTypeValueByKey(subscriber.school_type)}} -
                      classe:
                      {{getConfigService().getDiaryCampSubscriberSchoolTypeSubValueByKey(subscriber.school_type, subscriber.class_type)}}</small>
                    <br>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Portlet Subscribers -->
    </div>

  </div>
</div>
