import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { parseISO, differenceInWeeks, addWeeks, format, isWithinInterval, differenceInDays, subDays } from 'date-fns';
import { ConfigService, CampService, UserService, HelperService } from '../../_services';
import { Router } from '@angular/router';
import { it } from 'date-fns/locale'

//  salvataggio per excel
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-camp-calendar',
  templateUrl: './camp-calendar.component.html',
  styleUrls: ['./camp-calendar.component.css']
})
export class CampCalendarComponent implements OnInit {

  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;
  @ViewChild('affixheader', {static: true}) public affixHeader: ElementRef;

  columns: any[] = [];
  allCamps: any = null;
  rows: any = [];
  fakeRows: any[] = [{ title: 'Iscritti' }, { title: 'Tutor Previsti' }, { title: 'Tutor Disponibili' }];
  rowTutorPrevisisonal: any = [];
  rowTutorAvailable: any = [];
  rowChildrenWeeks: any = [];
  rowTutor: any = [];
  selected: any[] = [];

  searchModel: any = {};

  // risoluzione dello schermo
  public isSmartPortrait: any = false;

  constructor(private campService: CampService, private configService: ConfigService,
    private router: Router, private userService: UserService, private helper: HelperService) { }

  ngOnInit() {

    //  recupero le info della risoluzione dello schermo
    this.isSmartPortrait = window.innerWidth <= 450;
  }

  //  uso questo listener per visualizzare le info di rotazione dello smartphone
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmartPortrait = window.innerWidth <= 450;
  }
  //  uso questo listener per visualizzare le info di scroll dell'header della tabella
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.affixHeader.nativeElement.parentElement.getBoundingClientRect().top < 49)
      this.affixHeader.nativeElement.classList.add('affix');

    if (this.affixHeader.nativeElement.parentElement.getBoundingClientRect().top > 51)
      this.affixHeader.nativeElement.classList.remove('affix');
  }

  submitSearch(searchModel: any) {

    //  ad ogni ricerca resetto la tabella
    this.resetTable();

    this.campService.getAllCampsCalendar(searchModel, (campResults) => {

      if (typeof campResults == "undefined" || campResults == null || campResults.length == 0)
        this.resetTable();
      else {
        this.allCamps = JSON.parse(JSON.stringify(campResults));
        this.prepareTable();
      }
    });
  }

  //  resetto completamente la tabella
  resetTable() {
    this.columns = [];
    this.allCamps = [];
    this.rows = [];
    this.rowTutorPrevisisonal = [];
    this.rowTutorAvailable = [];
    this.rowTutor = [];
    this.rowChildrenWeeks = [];
  }

  //  prepara la tabella ad essere visualizzata
  prepareTable() {
    //  organizzo l'array in base alle date di inizio dei campi
    this.allCamps.sort(function (a, b) {
      return a.start < b.start ? -1 : a.start > b.start ? 1 : 0
    }
    );

    //  creo le colonne con le date
    this.columns = this.createColumns();

    //  aggiungo le settimane di riferimento della tabella a tutti i camp
    this.allCamps.forEach(camp => {
      camp['table_weeks'] = JSON.parse(JSON.stringify(this.columns));

      if (camp.status) {
        camp.status_name = this.configService.getCampStatusValueByKey(camp.status);
        camp.status_color = this.configService.getCampStatusColorByKey(camp.status);
      }

    });

    for (var index = 0; index < this.columns.length; index++) {

      this.allCamps.forEach(camp => {

        var differenceWeek = Math.ceil(differenceInDays(parseISO(camp.end), parseISO(camp.start)) / 7);

        for (var weekCounter = 0; weekCounter < differenceWeek; weekCounter++)
          if (isWithinInterval(addWeeks(parseISO(camp.start), weekCounter), { start: this.columns[index].date, end: addWeeks(subDays(this.columns[index].date, 1), 1)}))
            {
              camp['table_weeks'][index]['in_this_date'] = true;
              camp['table_weeks'][index]['tutors'] = camp.provisionals[weekCounter];
              camp['table_weeks'][index]['subscribers'] = camp.subscribers[weekCounter];
              camp['table_weeks'][index]['weekCounter'] = weekCounter;
            }

      });
    }

    //  setto le row
    this.rows = this.allCamps;

    //  creo le colonne con il numero di tutor previsti per le date
    this.rowTutorPrevisisonal = this.createTutorProvisions();
    this.rowTutorAvailable = this.createTutorAvailable();
    this.rowChildrenWeeks = this.createChildrenSubscribedWeek();
  }

  //  permette di creare tutte le colonne a partire da una data
  createColumns() {
    var firstDay = parseISO(this.allCamps[0].start);
    var lastDay = parseISO(this.allCamps[this.allCamps.length - 1].end);
    var totalWeeks = differenceInWeeks(lastDay, firstDay) + 1;

    //  array con tutte le settimane
    var arrayofweeks = [];

    //  riempio l'array delle settimane
    for (var i = 0; i <= totalWeeks; i++)
      arrayofweeks.push(
        {
          name: format(addWeeks(firstDay, i), 'dd MMM', { locale: it }),
          date: addWeeks(firstDay, i)
        });

    return arrayofweeks;
  }




  createTutorProvisions() {
    //  creo un array della lunghezza delle colonne
    let colTutorProv: any = [];

    //  aggiungo le entry all'array per il numero totale di colonne
    for (let entry of this.columns)
      colTutorProv.push({ entry: 0 });

    this.allCamps.forEach(camp => {

      for (var index = 0; index < camp.table_weeks.length; index++) {

        if (camp.table_weeks[index].in_this_date == true)
          if (typeof camp.table_weeks[index].tutors != "undefined")
            colTutorProv[index].entry += camp.table_weeks[index].tutors;
      }

    });

    return colTutorProv;
  }



  createChildrenSubscribedWeek() {
    //  creo un array della lunghezza delle colonne
    let colChildWeek: any = [];

    //  aggiungo le entry all'array per il numero totale di colonne
    for (let entry of this.columns)
      colChildWeek.push({ entry: 0 });

    this.allCamps.forEach(camp => {

      for (var index = 0; index < camp.table_weeks.length; index++) {

        if (camp.table_weeks[index].in_this_date == true)
          if (typeof camp.table_weeks[index].subscribers != "undefined")
            colChildWeek[index].entry += camp.table_weeks[index].subscribers;
      }

    });

    return colChildWeek;
  }





  //  crea i tutor disponibili in base all'application dei singoli tutor
  createTutorAvailable() {
    //  creo un array della lunghezza delle colonne
    let colTutorAva: any = [];

    //  aggiungo le entry all'array per il numero totale di colonne
    for (let entry of this.columns)
      colTutorAva.push({ entry: 0 });

    return colTutorAva;
  }

  //  Restituisce il valore di iscritti / tutor previsti calcolati in base alla settimana di riferimento
  getProvAndSubsForDate(row, index) {
    //  recupero il camp
    var camp = this.allCamps.find(x => x.camp_id == row.camp_id);

    //  valore restituito
    var text = "0/0";

    if (camp != null || typeof camp != "undefined") {
      //  iscritti in quella settimana + tutor previsti
      var iscritti = camp.table_weeks[index].subscribers;
      if (typeof iscritti == "undefined" || iscritti == null) iscritti = "0";

      var provisionals = camp.table_weeks[index].tutors;
      if (typeof provisionals == "undefined" || provisionals == null) provisionals = "0";

      text = "" + iscritti + "/" + provisionals;
    }

    return text;
  }



  //  Restituisce il valore di iscritti calcolati in base alla settimana di riferimento
  getProvForDate(row, index) {
    //  recupero il camp
    var camp = this.allCamps.find(x => x.camp_id == row.camp_id);

    //  valore restituito
    var text = "";

    if (camp != null || typeof camp != "undefined") {
      //  tutor in quella settimana + tutor previsti
      var provisionals = camp.table_weeks[index].tutors;
      if (typeof provisionals == "undefined" || provisionals == null) provisionals = "";

      text = "" + provisionals;
    }

    return text;
  }


  //  Restituisce il valore di tutor calcolati in base alla settimana di riferimento
  getSubsForDate(row, index) {
    //  recupero il camp
    var camp = this.allCamps.find(x => x.camp_id == row.camp_id);

    //  valore restituito
    var text = "";

    if (camp != null || typeof camp != "undefined") {
      //  iscritti in quella settimana
      var iscritti = camp.table_weeks[index].subscribers;
      if (typeof iscritti == "undefined" || iscritti == null) iscritti = "";

      text = "" + iscritti;
    }

    return text;
  }


  getCellClass({ row, column, value }) {
    //   I day Off sono rappresentati con il colore rosso
    if (row.status_color) {
      let obj = {};

      obj['font-' + row.status_color.name] = true;
      obj['border-left-2'] = true;
      obj['border-left-' + row.status_color.name] = true;

      return obj;
    }

  }


  //  resittuisce il numero totale di iscritti per tutti i campi correntemente visualizzati
  get totalSubscribersWeek() {
    var tot = 0;
    this.rowChildrenWeeks.forEach((subscriber: any) => {
      tot += subscriber.entry;
    });

    return tot;
  }

  onSelect(event) {
    // console.log('Event: select', event, this.selected);
    if (this.isTutorsModalShown) {
      this.selected = [];
    } else {

      if (typeof this.selected[0].camp_id != "undefined" && !this.isTutorsModalShown)
        this.router.navigate(['camp/edit/' + this.selected[0].camp_id]);
    }
  }

  //  mostra in grigio i campi che hanno lo status di cancellati
  getRowClass(row) {
    return { 'camp-calendar-deleted': row.status === 2 };
  }









  // MODAL EXPORT
  showModalExport(): void {
    this.staticModal.show();
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    this.exportParse(this.rows, () => {
      this.hideModalExport();
    });

  }


  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse(data: any, callback: any) {

    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach((element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      //  inserisco le prime informazioni
      var obj = {};
      obj['Seniority'] = this.configService.getCampSeniorityValueByKey(element.seniority);
      obj['Tipo'] = this.configService.getCampTypeValueByKey(element.type);
      obj['Company'] = this.configService.getGroupCompanyValueByKey(element.company);
      obj['Camp of'] = element.administrative_area_level_3_long_version;
      obj['Prov'] = element.administrative_area_level_2_short_version;
      obj['Regione'] = element.administrative_area_level_1_long_version;

      //  inserisco le settimane e di fianco inserisco il numero dei tutor
      Object.keys(element.table_weeks).forEach(indexW => {
        obj[element.table_weeks[indexW].name] = isNaN(parseInt(this.getSubsForDate(element, indexW))) ? '' : parseInt(this.getSubsForDate(element, indexW));
        obj[element.table_weeks[indexW].name + ' T'] = isNaN(parseInt(this.getProvForDate(element, indexW))) ? '' : parseInt(this.getProvForDate(element, indexW));
      });

      //  inserisco le ultime informazioni
      obj['Weeks'] = differenceInWeeks(element.end, element.start) + 1;
      obj['Stato'] = this.configService.getCampStatusValueByKey(element.status);
      obj['Coordinatore'] = this.configService.getAreaExpertiseValueByKey(element.area);

      //  preparo
      exportedArray.push(obj);

    });

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{ width: 8 }, { width: 18 }, { width: 14 }, { width: 30 }, { width: 5 }, { width: 14 }];
    this.columns.forEach(col => {
      worksheet['!cols'].push({ width: 6 });
      worksheet['!cols'].push({ width: 8 });
    });
    worksheet['!cols'].push({ width: 8 }, { width: 15 }, { width: 20 });

    //  personalizzo l'header
    worksheet['!rows'] = [{ hpx: 30 }];

    //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'CampsCalendar': worksheet }, SheetNames: ['CampsCalendar'] };

    //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });

    //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], { type: EXCEL_TYPE }), "camps_calendar.xlsx");

    callback();

  }

  // MODAL
  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;

  isTutorsModalShown: boolean = false;

  currentCalendarWeekIndex: number;
  currentWeekIndex: number;
  currentWeekNum: number;
  currentWeekStart: string = '';
  currentWeekEnd: string = '';
  currentCamp: any;

  showTutorsModal(row, index): void {

    this.currentCalendarWeekIndex = index;

    var camp = this.allCamps.find(x => x.camp_id == row.camp_id);

    var weekIndex = camp.table_weeks[index].weekCounter;

    this.currentCamp = camp;
    this.currentWeekIndex = weekIndex;

    this.currentWeekNum = weekIndex + 1;

    this.currentWeekStart = camp.camp_weeks[weekIndex].start_date;
    this.currentWeekStart = parseISO(this.currentWeekStart).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });

    this.currentWeekEnd = camp.camp_weeks[weekIndex].end_date;
    this.currentWeekEnd = parseISO(this.currentWeekEnd).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });


    this.isTutorsModalShown = true;
  }

  hideTutorsModal(): void {
    this.autoShownModal.hide();
  }

  onTutorsHidden(): void {
    this.isTutorsModalShown = false;
    //this.submitSearch({});
  }

  getProvsForDate(row, index) {
    //  recupero il camp
    var camp = this.allCamps.find(x => x.camp_id == row.camp_id);

    //  valore restituito
    var text = "0";

    if (camp != null || typeof camp != "undefined") {
      //tutor previsti
      var provisionals = camp.table_weeks[index].tutors;
      if (typeof provisionals == "undefined" || provisionals == null) provisionals = "0";

      text = provisionals;
    }

    return text;
  }

  getTutorsClass(row, index) {
    //  recupero il camp
    var camp = this.allCamps.find(x => x.camp_id == row.camp_id);

    var cssClass = "red";

    if ((camp != null || typeof camp != "undefined") &&
      camp.camp_weeks != null) {

      var provisionals = camp.table_weeks[index].tutors;
      if (typeof provisionals == "undefined" || provisionals == null) {
        provisionals = 0;
      }

      let weekIndex = camp.table_weeks[index].weekCounter;

      var campWeek = camp.camp_weeks[weekIndex];

      var assignedTutors = 0;

      if (campWeek != null && campWeek.camp_week_tutors != null) {
        assignedTutors = campWeek.camp_week_tutors.length;
      }

      if (assignedTutors >= provisionals) {
        cssClass = "green";
      }
    }

    return cssClass;
  }

  getUSerService() {
    return this.userService
  }

  getCampService() {
    return this.campService
  }

}
