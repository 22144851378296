import { PersonService, ConfigService, HelperService } from './../../_services/index';
import { NgForm } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { parseISO } from 'date-fns';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-person-camp-transfer-detail-form',
  templateUrl: './person-camp-transfer-detail-form.component.html',
  styleUrls: ['./person-camp-transfer-detail-form.component.css']
})
export class PersonCampTransferDetailFormComponent implements OnInit {

  @Input() model: any;
  @Input() multiSave: boolean;
  @Input() set campStaffMember(campStaffMember: any) {
    this.camp_staff_member = campStaffMember;
    this.updateCampInfo();
  };

  @Output() formDelete = new EventEmitter();
  @Output() formSaved = new EventEmitter();

  private datePickerOptions: IAngularMyDpOptions = {};

  editMode: boolean = false;
  from_camp_info: any;
  to_camp_info: any;
  from_date: IMyDateModel;
  to_date: IMyDateModel;
  camp_staff_member: any;

  //  file uploaders
  public documentUploader: FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf', 'application/msword'],
      url: 'serverUrl'
    }
  );

  @ViewChild('f', { static: true }) currentForm: NgForm;

  constructor(private personService: PersonService, private configService: ConfigService,
    private helper: HelperService, private notify: NotificationsService) {

    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    if (!this.model || !this.model.id)
      this.editMode = true;

    if (this.model && this.model.id) {

      //  Setto l'url per il caricamento delle foto e gli header
      this.documentUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
      this.documentUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        this.imageUploaded(item, response, status);
      };
      this.documentUploader.options.url = this.personService.getPersonCampTransferDetailFileUploadURL(this.model);

      if (this.model.from_camp_id == null) {
        this.model.orientation_returns = 1;
      }
      else {
        this.model.orientation_returns = 0;
      }
    }

    this.updateCampInfo();
  }

  submit() {

    if (!this.currentForm.form.valid)
      return;

    if (!this.currentForm.form.dirty) {
      this.editMode = false;
      return;
    }

    if (this.model.id) {
      this.personService.updatePersonCampTransferDetail(this.model, () => { this.editMode = false; });
    }
    else {

      this.model.person_id = this.personService.currentPersonId;

      this.personService.savePersonCampTransferDetail(this.model, () => { this.editMode = false; });
    }

    this.formSaved.emit(this.model);
  }

  destroy() {
    this.personService.deletePersonCampTransferDetail(this.model);
  }

  delete() {
    this.formDelete.emit(this);
  }

  edit() {
    this.editMode = true;
  }

  onTypeChange($event) {
    this.updateCampInfo();
  }


  //  Image Uploader
  public imageUploaded(item: any, response: any, status: any) {
    if (item != null) {
      if (status === 200) {
        this.model.file_path = response;
      }
      this.personService.personCampTransferDetailFileResponse(item, response, status, this.model.id);
    }
  }


  //  Remove image
  destroyFile() {
    this.personService.deletePersonCampTransferDetailFile(this.model);
  }

  public overwriteModel(model) {
    this.model = model;
    this.updateCampInfo();
    this.currentForm.form.markAsDirty();
    this.currentForm.form.markAsTouched();
  }

  public updateCampInfo() {

    if (!this.model)
      return;

    if (this.model && this.model.from_date) {
      this.from_date = this.helper.initMyDataInputSingle(this.model.from_date);
    }

    if (this.model && this.model.to_date) {
      this.to_date = this.helper.initMyDataInputSingle(this.model.to_date);
    }

    if (this.model.id && !this.model.from_camp_id && typeof this.model.orientation_returns == "undefined") {
      this.model.orientation_returns = 1;
    }

    if (this.model.orientation_returns == 1) {
      this.model.from_camp_id = null;
      this.from_camp_info = null;
    }
    else {
      this.model.orientation_returns = 0;
    }

    if (this.model.from_camp_id && this.camp_staff_member) {
      this.camp_staff_member.forEach(camp => {
        if (camp.camp_id == this.model.from_camp_id) {
          this.from_camp_info = camp;
        }
      });

      // se non ho trovato le info del camp significa che non sono abilitato in quel camp e resetto l'id
      // capita quando sovracrivo il model da un altro trasfer ma i camp sono sbagliati
      if (!this.from_camp_info && this.camp_staff_member && this.camp_staff_member.length > 0) {
        this.model.from_camp_id = null;
      }
    }

    if (this.model.to_camp_id && this.camp_staff_member) {
      this.camp_staff_member.forEach(camp => {
        if (camp.camp_id == this.model.to_camp_id) {
          this.to_camp_info = camp;
        }
      });

      // se non ho trovato le info del camp significa che non sono abilitato in quel camp e resetto l'id
      // capita quando sovracrivo il model da un altro trasfer ma i camp sono sbagliati
      if (!this.to_camp_info && this.camp_staff_member && this.camp_staff_member.length > 0) {
        this.model.to_camp_id = null;
      }
    }
  }

  onFromDateChanged(event: IMyDateModel) {

    this.from_date = event;

    this.model.from_date = null;

    if (event.singleDate.jsDate)
      this.model.from_date = this.helper.formatISODate(event);
    else
      this.model.from_date = null;
  }

  onToDateChanged(event: IMyDateModel) {

    this.to_date = event;

    this.model.to_date = null;

    if (event.singleDate.jsDate)
      this.model.to_date = this.helper.formatISODate(event);
    else
      this.model.to_date = null;
  }

  getConfigService() {
    return this.configService
  }

  getPersonService() {
    return this.personService
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

  getHelper() {
    return this.helper
  }

}
