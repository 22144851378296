<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/camp/home']">Camps</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span *ngIf="!getCampService().currentCampId">New</span>
            <span *ngIf="getCampService().currentCampId">Edit</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>

      <app-reminder-widget-alertbox [holderType]="'App\\Camp'" [holderId]="getCampService().currentCampId"
      [holderName]="getCampService().currentCampInfo?.camp_code"></app-reminder-widget-alertbox>

      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title" *ngIf="!getCampService().currentCampId">New Camp</h1>
      <h1 class="page-title" *ngIf="getCampService().currentCampId">{{getConfigService().getCampTypeValueByKey(getCampService().currentCampInfo?.type)}} {{getCampService().currentCampInfo?.camp_code}}</h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">

            <!--end: Portlet-Body -->
            <div class="portlet-body form">

              <alert type="warning" *ngIf="loadingData">
                <strong>Loading</strong> ...
              </alert>

              <tabset #campTabs class="align-right portlet-title tabbable-line" [hidden]="loadingData">
                <tab heading="Info"><app-camp-info-form *ngIf="staticTabs?.tabs[0]?.active" [campComponent]="this"></app-camp-info-form></tab>
                <tab heading="Quote"><app-camp-quote *ngIf="staticTabs?.tabs[1]?.active"></app-camp-quote></tab>
                <tab>
                  <ng-template tabHeading>
                    Staff <span *ngIf="campDirectorError"><i class="fa fa-exclamation-triangle"></i></span>
                  </ng-template>
                  <app-camp-staff *ngIf="staticTabs?.tabs[2]?.active"></app-camp-staff>
                </tab>
                <tab heading="Requests"><app-camp-signup-list *ngIf="staticTabs?.tabs[3]?.active"></app-camp-signup-list></tab>
                <tab heading="Subscribers"><app-camp-subscriber-list *ngIf="staticTabs?.tabs[4]?.active"></app-camp-subscriber-list></tab>
                <tab heading="Groups"><app-camp-groups *ngIf="staticTabs?.tabs[5]?.active"></app-camp-groups></tab>
                <tab heading="Shipments"><app-camp-shipments-list *ngIf="staticTabs?.tabs[6]?.active"></app-camp-shipments-list></tab>
                <tab heading="Documents"><app-camp-document-list *ngIf="staticTabs?.tabs[7]?.active"></app-camp-document-list></tab>
                <tab heading="Payment Requests"><app-camp-payment-requests-list *ngIf="staticTabs?.tabs[8]?.active && getUserService().checkPermission('camp_edit')"></app-camp-payment-requests-list></tab>
                <tab heading="Cash flow"><app-camp-cashflow *ngIf="staticTabs?.tabs[9]?.active"></app-camp-cashflow></tab>
                <tab heading="Reminder"><app-camp-reminder *ngIf="getUserService().checkPermission('camp_edit')"></app-camp-reminder></tab>
                <tab heading="Diary"><app-camp-diary-list *ngIf="staticTabs?.tabs[11]?.active && getUserService().checkPermission('camp_edit')"></app-camp-diary-list></tab>
                <tab heading="Revision"><app-camp-revision *ngIf="staticTabs?.tabs[12]?.active && getUserService().checkPermission('camp_edit')"></app-camp-revision></tab>
              </tabset>

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
