<!-- BEGIN: Sezione dedicata a tutti gli shows della singola data di un tour -->
<div>
  <div *ngIf="!lockedMode">
    <button type="button" class="btn green btn-block btn-outline" (click)="AddShowClicked()"><i class="fa fa-plus"></i>&nbsp; Add Show</button>
  </div>

  <!-- BUTTON SAVE ALL -->
  <div *ngIf="!lockedMode && showsArray?.length > 1" class="form-actions right">
      <img *ngIf="getTheatrinoService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
      <button type="button" (click)="saveAll();" class="btn green-jungle" [disabled]="getTheatrinoService().isOngoingRequest()">
        <span class="ladda-label"> <i class="fa fa-save"></i> Save All</span>
      </button>
    </div>

    <div class="row" *ngIf="model?.agreement_id">
      <div class="col-md-12">
        <div class="form-group">
            <button type="button" class="btn green btn-block btn-outline" (click)="GoToAgreement()"><i class="fa fa-external-link"></i>&nbsp; Existing Agreeement</button>
        </div>
      </div>
    </div>

    <alert type="warning"  *ngIf="model?.agreement_id">
        <strong>Warning!</strong> La modifica degli show è bloccata perché esiste già una convenzione creata, se si vuole modificare queste informazioni bisogna cancellare la convenzione
      </alert>

  <div *ngIf=showsArray>
    <div *ngFor="let show of showsArray">
      <app-theatrino-booking-show-info-form #showsForm [model]="show" [lockedMode]="lockedMode" (showDestroyed)="ShowDestroyed($event)"></app-theatrino-booking-show-info-form>
    </div>
  </div>
</div>
<!-- END: Sezione dedicata agli show della singola data di un tour -->

<!-- BUTTON SAVE ALL -->
<div *ngIf="!lockedMode && showsArray?.length > 1" class="form-actions right">
  <img *ngIf="getTheatrinoService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
  <button type="button" (click)="saveAll();" class="btn green-jungle" [disabled]="getTheatrinoService().isOngoingRequest()">
    <span class="ladda-label"> <i class="fa fa-save"></i> Save All</span>
  </button>
</div>
