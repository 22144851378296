import { ConfigService } from './../../_services/config.service';
import { Component, OnInit, Input, OnChanges, ViewChild, ViewChildren } from '@angular/core';
import { EnterpriseService, BackendService } from "../../_services/index";
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { EnterpriseInfoSummaryComponent } from '../enterprise-info-summary/enterprise-info-summary.component';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-enterprise-info-show',
  templateUrl: './enterprise-info-show.component.html',
  styleUrls: ['./enterprise-info-show.component.css']
})
export class EnterpriseInfoShowComponent implements OnInit, OnChanges {

  @Input() idEnterprise: number;
  enterpriseInfo: any;
  currentEnterpriseId: number;

  @Input() hideSummaryCardModal: boolean = false;
  @Input() showAddress: boolean = false;
  @Input() showContact: boolean = false;
  @Input() showRole: boolean = false;

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChildren('enterpriseInfoSummaryComponent') enterpriseShow: EnterpriseInfoSummaryComponent;

  constructor(private enterpriseService: EnterpriseService, private backend: BackendService,
              private configService: ConfigService, private router: Router) { }

  ngOnInit() {

    this.enterpriseInfo = {};
  }

  ngOnChanges() {

    this.enterpriseInfo = {};

    if (this.idEnterprise != this.currentEnterpriseId)
      this.getEnterpriseInfo();
  }

  loading: boolean = false;

  getEnterpriseInfo() {

    if (typeof this.idEnterprise == "undefined" || this.idEnterprise <= 0) {
      this.enterpriseInfo = {};
      this.currentEnterpriseId = null;
      return;
    }

    this.currentEnterpriseId = this.idEnterprise;

    let params = new HttpParams();
    if (this.showContact)
      params = params.set("contact", this.showContact.toString());

    if (this.showAddress)
      params = params.set("address", this.showAddress.toString());

    this.loading = true;

    this.backend.get('enterprise/' + this.currentEnterpriseId, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.enterpriseInfo = resJson;

        this.loading = false;
      },
      (error) => {
        this.loading = false;

        this.backend.showErrors(error);
      });
  }

  clickEnterprise() {
    //this.router.navigate(['enterprise/edit/' + this.idEnterprise]);
    this.showModal();
  }

  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  getConfigService() {
    return this.configService
  }

  getEnterpriseService() {
    return this.enterpriseService
  }
}
