<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <input type="hidden" name="id" [(ngModel)]="model.id">

  <div class="form-body">
    <div class="form-section">
      <h3>Role</h3>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div *ngIf="editMode" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || type.dirty || type.touched) && !type.valid }">
          <label class="control-label">Role Type <span class="required">*</span></label>
          <select class="form-control" name="type" [(ngModel)]="model.type" #type="ngModel" required>
            <option *ngFor="let r of getConfigService().rolePersonSelect" [value]="r.key">{{r.value}}</option>
          </select>
          <span class="help-block">Select role type, is required</span>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Role Type</label>
          <p>{{getConfigService().getRolePersonValueByKey(model.type)}}</p>
        </div>

        <div *ngIf="editMode" class="form-group">
          <label>Primary</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="primary" [(ngModel)]="model.primary" value="1"
                (ngModelChange)="valueChange($event)"> Yes, show this data in the search results and in the summary
              screen of this person
              <span></span>
            </label>
          </div>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Primary</label>
          <p *ngIf="model.primary">Yes, show this data in the search results and in the summary screen of this person
          </p>
          <p *ngIf="!model.primary">No, hide this data in the search results and in the summary screen of this person
          </p>
        </div>

      </div>
      <div class="col-md-6">

        <div *ngIf="editMode" class="portlet light">
          <div class="portlet-title">
            <div class="caption">
              <i class="icon-bar-chart font-dark hide"></i>
              <span class="caption-subject font-dark bold uppercase">Enterprise</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" class="btn btn-circle btn-outline green"
                  [disabled]="getPersonService().isOngoingRequest()" (click)="showModal();"><i
                    class="fa fa-edit"></i>&nbsp;Edit</button>
                <ng-template #confirmDelete01><button type="button" class="btn btn-outline red"
                    (click)="removeAutority()">Yes Remove!</button></ng-template>
                <button *ngIf="this.model.enterprise_id" type="button"
                  [disabled]="getPersonService().isOngoingRequest()" class="btn btn-circle red btn-outline"
                  [outsideClick]="true" [popover]="confirmDelete01" popoverTitle="Are you sure?"><i
                    class="fa fa-trash-o"></i>&nbsp;Remove</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-enterprise-info-show #enterpriseInfoShow [idEnterprise]="model.enterprise_id">
            </app-enterprise-info-show>
          </div>
        </div>
        <div *ngIf="!editMode" class="form-group editMode">
          <label class="control-label">Enterprise</label>
          <p>
            <app-enterprise-info-show #enterpriseInfoShow [idEnterprise]="model.enterprise_id">
            </app-enterprise-info-show>
          </p>
        </div>

      </div>
    </div>

    <div [appCheckPermission]="'person_role_edit_permission'" class="form-section" *ngIf="editMode && permissions.length > 0">
      <h3>Permissions</h3>
    </div>

    <div [appCheckPermission]="'person_role_edit_permission'" class="row" *ngIf="editMode && permissions.length > 0">
      <div class="col-md-12">

        <div class="form-group">
          <label>Agenda</label>
          <div class="mt-checkbox-inline">
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('agenda_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('reminder_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
          </div>
        </div>

        <div class="form-group">
          <label>Admin</label>
          <div class="mt-checkbox-inline">
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('admin_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
          </div>
        </div>

        <div class="form-group">
          <label>Prima nota</label>
          <div class="mt-checkbox-inline">
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('primanota_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
          </div>
        </div>

        <div class="form-group">
          <label>People</label>
          <div class="mt-checkbox-inline">
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('person_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
          </div>
        </div>

        <div class="form-group">
          <label>Enterprise</label>
          <div class="mt-checkbox-inline">
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('enterprise_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
          </div>
        </div>

        <div class="form-group">
          <label>Vehicle</label>
          <div class="mt-checkbox-inline">
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('vehicle_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
          </div>
        </div>

        <div class="form-group">
          <label>Theatrino</label>
          <div class="mt-checkbox-inline">
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('theatrino_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('actorgroup_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('show_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
          </div>
        </div>

        <div class="form-group">
          <label>Camp</label>
          <div class="mt-checkbox-inline">
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('camp_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
          </div>
        </div>

        <div class="form-group">
          <label>Warehouse</label>
          <div class="mt-checkbox-inline">
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('warehouse_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
          </div>
        </div>

        <div class="form-group">
          <label>Application</label>
          <div class="mt-checkbox-inline">
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('application_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
          </div>
        </div>

        <div class="form-group">
          <label>Course</label>
          <div class="mt-checkbox-inline">
            <label style="margin-right: 10px;" class="mt-checkbox" *ngFor="let perm of getFilteredPermissionBy('course_')" class="form-group">
              <input type="checkbox" name="perm_{{ perm.index }}" [(ngModel)]="perm.value" (ngModelChange)="valuePermissionChange($event)"> {{ perm.label }}
              <span></span>
            </label>
          </div>
        </div>

      </div>
    </div>

  </div>


  <div [appCheckPermission]="'person_role_edit'" class="form-actions right">
    <img *ngIf="getPersonService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getPersonService().isOngoingRequest()"
      *ngIf="this.model.id" [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i
        class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="button" class="btn btn-outline red" [disabled]="getPersonService().isOngoingRequest()"
      *ngIf="!this.model.id" (click)="delete()"><i class="fa fa-trash-o"></i> Delete</button>
    <button type="button" class="btn btn-outline blue" [disabled]="getPersonService().isOngoingRequest()"
      *ngIf="this.model.id && !editMode" (click)="edit()"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      Edit</button>

    <button *ngIf="multiSave == false || editMode" type="submit" class="btn blue"
      [disabled]="getPersonService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
      <span class="ladda-label" *ngIf="!this.model.id"> <i class="fa fa-save"></i> Save</span>
      <span class="ladda-label" *ngIf="this.model.id">Update</span>
    </button>
  </div>

</form>

<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Enterprise</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-enterprise-search-form #enterpriseSearchForm (searchKeywordsSubmit)="searchKeywordsSubmit($event)"
              [ongoingRequest]="enterpriseDatatable.ongoingRequest"></app-enterprise-search-form>
          </div>
        </div>

        <br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-enterprise-datatable #enterpriseDatatable [query]="enterpriseSearchForm.searchKeyword"
                  (enterpriseSelected)="resultSelected($event)"></app-enterprise-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
