<alert type="warning" *ngIf="loading">
  <strong>Loading</strong> ...
</alert>

<div *ngIf="!loading">

  <form action="#" class="horizontal-form" novalidate>
    <div class="form-body">
      <div>
        <alert *ngIf="tutorApplicationInfo.imported" type="warning">
          <strong>Alert!</strong> Tutor already imported: this tutor is present in Person Database.
        </alert>

        <alert *ngIf="tutorApplicationInfo.person_id" type="info">
          <button class="btn btn-block btn-outline btn-success"
            routerLink="/person/edit/{{ tutorApplicationInfo.person_id }}">Vai alla scheda della persona creata</button>
        </alert>
      </div>

      <br>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Created At</label>
            <p>{{ tutorApplicationInfo.created_at | date:'d MMMM, y - HH:mm':'':'it' }}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Last Update</label>
            <p>{{ tutorApplicationInfo.updated_at | date:'d MMMM, y - HH:mm':'':'it' }}</p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Annotation and Preferences</h4>
      <div class="row" *ngIf="tutorApplicationInfo.phase >= 1">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Preference Phase 1</label>
            <p>
              {{ getConfig().getapplicationPhasePreferenceSelectValueByKey(tutorApplicationInfo.status_01_promote_preference) }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group read-only">
            <label class="control-label">Annotation</label>
            <p class="line-breaker">{{tutorApplicationInfo.status_01_promote_note}}</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="tutorApplicationInfo.phase >= 2">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Preference Phase 2</label>
            <p>
              {{ getConfig().getapplicationPhasePreferenceSelectValueByKey(tutorApplicationInfo.status_02_promote_preference) }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group read-only">
            <label class="control-label">Annotation</label>
            <p class="line-breaker">{{tutorApplicationInfo.status_02_promote_note}}</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="tutorApplicationInfo.phase >= 3">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Preference Phase 3</label>
            <p>
              {{ getConfig().getapplicationPhasePreferenceSelectValueByKey(tutorApplicationInfo.status_03_promote_preference) }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group read-only">
            <label class="control-label">Annotation</label>
            <p class="line-breaker">{{tutorApplicationInfo.status_03_promote_note}}</p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Personal info</h4>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Name</label>
            <p>{{tutorApplicationInfo.surname}} {{tutorApplicationInfo.firstname}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Date of birth</label>
            <p>{{tutorApplicationInfo.date_of_birth}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Nationalities</label>
            <p>{{getTutorService().getNationalitiesLabel(tutorApplicationInfo.nationality)}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Criminal Record</label>
            <p *ngIf="tutorApplicationInfo.criminal_record == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="tutorApplicationInfo.criminal_record == 'no'"><i class="fa fa-remove"></i></p>
          </div>
          <div class="form-group read-only" *ngIf="tutorApplicationInfo.tshirt_size">
            <label class="control-label">T-Shirt Size</label>
            <p>{{tutorApplicationInfo.tshirt_size}}&nbsp;</p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Gender</label>
            <p>{{tutorApplicationInfo.gender}}</p>
            <!-- <p>{{tutorApplicationInfo.custom_gender}}</p> -->
          </div>
          <div class="form-group read-only" *ngIf="tutorApplicationInfo.native_english_speaker">
            <label class="control-label">Native English speaker</label>
            <p *ngIf="tutorApplicationInfo.native_english_speaker == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="tutorApplicationInfo.native_english_speaker == 'no'"><i class="fa fa-remove"></i></p>
          </div>
          <div class="form-group read-only" *ngIf="tutorApplicationInfo.proficient_english_speaker">
            <label class="control-label">Proficient English speaker</label>
            <p *ngIf="tutorApplicationInfo.proficient_english_speaker == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="tutorApplicationInfo.proficient_english_speaker == 'no'"><i class="fa fa-remove"></i></p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Passport Type</label>
            <p>{{tutorApplicationInfo.passport_type}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Criminal record detail</label>
            <p>{{tutorApplicationInfo.criminal_record_detail}}&nbsp;</p>
          </div>
        </div>

        <div class="col-md-4 mt-element-card">
          <div class="mt-card-item">
            <div *ngIf="tutorApplicationInfo.avatarUrl" class="mt-overlay-1" aria-hidden="true">
              <img *ngIf="tutorApplicationInfo.avatarUrl"
                src="{{tutorApplicationInfo.avatarUrl}}?{{tutorApplicationInfo.updated_at}}"
                class="img-responsive pic-bordered" alt="">
            </div>
            <div *ngIf="!tutorApplicationInfo.avatarUrl" class="mt-overlay-1" aria-hidden="true">
              <img src="../assets/placeholder/avatar-placeholder.jpg" width="100%">
            </div>
          </div>
          <div class="form-group" *ngIf="tutorApplicationInfo.cvUrl">
            <a class="btn btn-outline btn-block blue" [href]="tutorApplicationInfo.cvUrl" target="_blank">
              <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Download CV
            </a>
          </div>
          <div class="form-group" *ngIf="tutorApplicationInfo.coverUrl">
            <a class="btn btn-outline btn-block blue" [href]="tutorApplicationInfo.coverUrl" target="_blank">
              <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Download Cover Letter
            </a>
          </div>
          <div class="form-group" *ngIf="tutorApplicationInfo.youtube_preteaching_link">
            <a class="btn btn-outline btn-block green-jungle" [href]="tutorApplicationInfo.youtube_preteaching_link"
              target="_blank">
              <i class="fa fa-youtube-play" aria-hidden="true"></i>&nbsp;Pre-Teaching video
            </a>
          </div>
        </div>
      </div>

      <h4 class="form-section">Address &amp; Contacts</h4>
      <div class="row">
        <div class="col-md-4" *ngIf="tutorApplicationInfo.address">
          <div class="form-group read-only">
            <label class="control-label">Address</label>
            <p>{{tutorApplicationInfo.address}}, {{tutorApplicationInfo.postal_code}} - {{tutorApplicationInfo.city}}
              ({{tutorApplicationInfo.province}}) -
              {{getTutorService().getNationalityLabel(tutorApplicationInfo.country)}}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Email</label>
            <p>{{tutorApplicationInfo.email}}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Mobile telephone</label>
            <p>{{tutorApplicationInfo.mobile_telephone}}</p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Orientation Preference</h4>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Orientation</label>
            <p>{{getConfig().getTutorApplicationTourType(tutorApplicationInfo.tour_type)}}&nbsp;</p>
          </div>
        </div>
        <!-- <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Workshop Date Accepted</label>
            <p *ngIf="tutorApplicationInfo.workshop_agree == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="tutorApplicationInfo.workshop_agree == 'no'"><i class="fa fa-remove"></i></p>
          </div>
        </div> -->
      </div>
      <br>

      <div *ngIf="tutorApplicationInfo.phase >= 3">
        <h4 class="form-section">Proposal</h4>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" *ngIf="tutorApplicationInfo.passportUrl">
              <a class="btn btn-outline btn-block blue" [href]="tutorApplicationInfo.passportUrl" target="_self">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Passport
              </a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" *ngIf="tutorApplicationInfo.healthInsuranceUrl">
              <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                [href]="tutorApplicationInfo.healthInsuranceUrl" target="_blank">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Health Insurance
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">IBAN / Paypal Address</label>
              <p>{{tutorApplicationInfo.payment_number}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Emergency Contact Name</label>
              <p>{{tutorApplicationInfo.emergency_contact_name}}</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Emergency Contact Relationship</label>
              <p>{{tutorApplicationInfo.emergency_contact_relationship}}</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Emergency Contact Phone</label>
              <p>{{tutorApplicationInfo.emergency_contact_phone}}</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Emergency Contact Email</label>
              <p>{{tutorApplicationInfo.emergency_contact_email}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Dietary Requirements
                <i class="fa fa-check-circle text-success"
                  *ngIf="tutorApplicationInfo?.dietary_requirements_yes_or_not == 'yes'"></i>
                <i class="fa fa-close text-danger"
                  *ngIf="tutorApplicationInfo?.dietary_requirements_yes_or_not == 'no'"></i>
              </label>
              <p>{{tutorApplicationInfo.dietary_requirements}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Allergies
                <i class="fa fa-check-circle text-success"
                  *ngIf="tutorApplicationInfo?.allergic_yes_or_not == 'yes'"></i>
                <i class="fa fa-close text-danger" *ngIf="tutorApplicationInfo?.allergic_yes_or_not == 'no'"></i>
              </label>
              <p>{{tutorApplicationInfo.allergic_to}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Medical Conditions
                <i class="fa fa-check-circle text-success"
                  *ngIf="tutorApplicationInfo?.medical_conditions === 'yes'"></i>
                <i class="fa fa-close text-danger" *ngIf="tutorApplicationInfo?.medical_conditions === 'no'"></i>
              </label>
              <p>{{tutorApplicationInfo.medical_condition_details}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Availability</label>
              <p>{{tutorApplicationInfo.availability}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Flight Details</label>
              <p>{{tutorApplicationInfo.flight_details}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" *ngIf="tutorApplicationInfo.flightReceiptUrl">
              <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                [href]="tutorApplicationInfo.flightReceiptUrl" target="_blank">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Flight Receipt
              </a>
            </div>
          </div>
        </div>
      </div>

      <span *ngIf="tutorApplicationInfo.phase >= 3">
        <h4 class="form-section">Additional Proposal Info</h4>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Professional reference 1</label>
              <p>{{tutorApplicationInfo.professional_reference1}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Professional reference 2</label>
              <p>{{tutorApplicationInfo.professional_reference2}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">University</label>
              <p>{{tutorApplicationInfo.university}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Relevant qualifications</label>
              <p>{{tutorApplicationInfo.qualifications}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Working Experience with children</label>
              <p>{{tutorApplicationInfo.children_working_experience	}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Musical Instruments</label>
              <p>{{tutorApplicationInfo.musical_instruments}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Teach any Sports</label>
              <p>{{tutorApplicationInfo.sports}}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Can you speak Italian</label>
              <p>{{tutorApplicationInfo.italian_speak_rate}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Do you smoke</label>
              <p>{{tutorApplicationInfo.smoker}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">First Aid Certificate</label>
              <p>{{tutorApplicationInfo.first_aid_year_certificate}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">TEFL Certificate</label>
              <p>{{tutorApplicationInfo.tefl_certificate}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Confident swimmer</label>
              <p *ngIf="tutorApplicationInfo.confident_swimmer == true"><i class="fa fa-check"></i></p>
              <p *ngIf="tutorApplicationInfo.confident_swimmer == false"><i class="fa fa-remove"></i></p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Previous Teaching Experience in Drama</label>
              <p>{{tutorApplicationInfo.drama_experience}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Different Skills or experience</label>
              <p>{{tutorApplicationInfo.skills}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Stay in a Catholic environment</label>
              <p *ngIf="tutorApplicationInfo.catholic == true"><i class="fa fa-check"></i></p>
              <p *ngIf="tutorApplicationInfo.catholic == false"><i class="fa fa-remove"></i></p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Shall be living with host families</label>
              <p *ngIf="tutorApplicationInfo.host_families == true"><i class="fa fa-check"></i></p>
              <p *ngIf="tutorApplicationInfo.host_families == false"><i class="fa fa-remove"></i></p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Hear About</label>
              <p>{{tutorApplicationInfo.how_hear_about}}</p>
            </div>
          </div>
        </div>
      </span>
    </div>
    <br>

    <div class="form-actions right">
      <button type="button" class="btn blue btn-outline" (click)="EditTutor()"
        [appCheckPermission]="'application_tutors_edit'">Edit</button>
      <button type="button" class="btn btn-outline red" (click)="RejectTutor()" *ngIf="!tutorApplicationInfo.rejected">
        <i class="fa fa-times" aria-hidden="true"></i>&nbsp;Reject
      </button>

      <button type="button" class="btn btn-outline red" (click)="CancelRejectTutor()"
        *ngIf="tutorApplicationInfo.rejected">
        <i class="fa fa-times" aria-hidden="true"></i>&nbsp;Cancel rejected status
      </button>

      <ng-template #confirmPromote>
        <button type="button" class="btn btn-outline green-jungle" (click)="PromoteTutor()">Yes, I'm sure!</button>
      </ng-template>
      <button type="button"
        *ngIf="!tutorApplicationInfo.rejected && !tutorApplicationInfo.imported && tutorApplicationInfo.status == 'SUBMITTED' && tutorApplicationInfo.phase < 4"
        [outsideClick]="true" [popover]="confirmPromote" popoverTitle="Are you sure?" class="btn btn-outline blue"><i
          class="fa fa-check" aria-hidden="true"></i>&nbsp;Promote</button>

      <ng-template #confirmPromoteToHire>
        <button type="button" class="btn btn-outline green-jungle" (click)="HireTutor()">Yes, I'm sure!</button>
      </ng-template>
      <button type="button"
        *ngIf="!tutorApplicationInfo.rejected && !tutorApplicationInfo.imported && tutorApplicationInfo.status == 'EDITING' && tutorApplicationInfo.phase >= 4"
        [outsideClick]="true" [popover]="confirmPromoteToHire" popoverTitle="Are you sure?"
        class="btn btn-outline blue"><i class="fa fa-check" aria-hidden="true"></i>&nbsp;Hire!</button>
    </div>

  </form>
</div>
