import { ActivatedRoute } from '@angular/router';
import { GroupService } from './../_services/index';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-actor-group',
  templateUrl: './actor-group.component.html',
  styleUrls: ['./actor-group.component.css']
})
export class ActorGroupComponent implements OnInit {

  @ViewChild('actorTabs', {static: true}) staticTabs: TabsetComponent;

  private subject: any;

  loadingData: boolean = true;
  holderType: string;
  holderId: any;
  holderName: string;

  constructor(private groupService: GroupService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {

      this.groupService.resetService();

      if (typeof params['idCurrentGroup'] != "undefined") {

        this.groupService.currentGroupId = +params['idCurrentGroup'];
        this.groupService.getActorGroupInfoAll(() => {

          this.holderType = "App\\ActorGroup";
          this.holderName = this.groupService.currentGroupInfo["group_name"];
          this.holderId = this.groupService.currentGroupId;

          this.loadingData = false;
        }, () => {
          this.loadingData = false;
      });

    }
    else {

      this.loadingData = false;
    }

    });

  }


  getGroupService() {
    return this.groupService
  }

}
