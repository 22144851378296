<div class="row">
  <div class="col-md-12">
    <app-camp-search-form #CampSearchFormComponent (searchKeywordsSubmit)="searchKeywordsSubmit($event)" [ongoingRequest]="datatable.ongoingRequest"></app-camp-search-form>
  </div>
</div>

<br>
<br>

<!-- begin: prima riga -->
<div class="row">
  <div class="col-md-12">

    <!--begin: Portlet -->
    <div class="portlet light bordered">
      <!--end: Portlet-Body -->
      <div class="portlet-body">
        <app-camp-datatable #CampDatatableComponent (campSelected)="campSelected($event)"></app-camp-datatable>
        <div class="row">
          <div class="pull-right">
            <button [appCheckPermission]="'camp_export'" [disabled]="getCampService().isOngoingRequest() || datatable.rows?.length <= 0" type="button" class="btn green" (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button>
          </div>
        </div>
      </div>
      <!--end: Portlet-Body -->
    </div>
    <!--end: Portlet -->
  </div>
</div>
<!--end: prima riga -->


<!-- BEGIN: MODAL LOADING EXPORT-->
<div *ngIf="isModalExport" [config]="{ show: true, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal #staticModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
