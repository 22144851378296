import { differenceInCalendarWeeks } from 'date-fns';
import { PersonInfoSummaryComponent } from './../../person/person-info-summary/person-info-summary.component';
import { NotificationsService } from 'angular2-notifications';
import { IAngularMyDpOptions, IMyDateModel, IMyInputFieldChanged } from 'angular-mydatepicker';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { ConfigService, HelperService, CampService, PrimanotaService, EnterpriseService, TutorApplicationService, UserService } from './../../_services/index';
import { parseISO, isBefore } from 'date-fns';

@Component({
  selector: 'app-camp-subscriber-form',
  templateUrl: './camp-subscriber-form.component.html',
  styleUrls: ['./camp-subscriber-form.component.css']
})
export class CampSubscriberFormComponent implements OnInit {

  @ViewChild('f', {static: true}) currentForm: NgForm;

  @Input() parentComponent: any = null;

  @Input() model: any = null;
  @Input() modelFromSignup: any = null;
  @Input() viewSubscriber: boolean = false;

  @Output() formDelete = new EventEmitter();
  @Output() formSaved = new EventEmitter();

  //  numero di settimane del camp che si sta utilizzando
  campWeeks: number = 0;

  editMode: boolean = false;

  //  indica che una persona è stata cancellata dal DB
  personDeleted: boolean = false;
  parentDeleted: boolean = false;

  public localPersonData: IMyDateModel;
  public localParentData: IMyDateModel;
  public localRegistrationDate: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  ongoingRequestParent = false;
  ongoingRequestPerson = false;

  firstSearchParent = false;
  firstSearchPerson = false;

  parentsFound: any[] = [];
  parentSelected: any;

  personFound: any[] = [];
  personSelected: any;

  addNewParent: boolean = false;
  addNewPerson: boolean = false;

  addInAlertType: string = "warning";
  standardCourseFee: number = 0;
  totalDiscount: number = 0;

  canEditDiscount: boolean = false;
  quote_discount_early_bird_date_parsed: any;

  constructor(private campService: CampService, private helper: HelperService, private userService: UserService,
    private configService: ConfigService, private notify: NotificationsService,
    private primanotaService: PrimanotaService, private enterpriseService: EnterpriseService) {

      //  Inizializzo le info sulle date
      this.datePickerOptions = helper.datePickerOptions;
    }


  ngOnInit() {

    this.canEditDiscount = this.campService.canEditDiscount();

    // calcola il numero di settimane per questo campo, ! fondamentale
    this.campWeeks = differenceInCalendarWeeks(HelperService.parseDate(this.campService.currentCampInfo.end), HelperService.parseDate(this.campService.currentCampInfo.start), {weekStartsOn: 1}) + 1;

    //  arriva un model esterno che contiene l'iscrizione
    if (this.modelFromSignup != null)
    {
      //  faccio una copia esatta in modo da non aggiornare il vecchio model
      this.model = this.modelFromSignup;
    }
    else if (!this.model || !this.model.id)
    {
      this.configureModel();
      this.editMode = true;
    }

    if (this.model)
    {

      if (this.campService.currentCampInfo && this.campService.currentCampInfo.quote_discount_early_bird_date){
        this.quote_discount_early_bird_date_parsed = HelperService.parseDate(this.campService.currentCampInfo.quote_discount_early_bird_date);
      }

      if (this.model.person_info != null && typeof this.model.person_info != "undefined")
      {
        //  setto che qualcosa è stato cancellato da questo subscriber
        if (this.model.person_info.deleted_at != null)
          this.personDeleted = true;

        if (this.model.person_info.birthday != null)
        {
          this.localPersonData = this.helper.initMyDataInputSingle(this.model.person_info.birthday);
          this.model.person_info.birthday_parsed = HelperService.parseDate(this.model.person_info.birthday);
        }

        //  faccio partire automaticamente le chiamate per ricercare il figlio
        if (this.modelFromSignup != null)
          setTimeout(() => { this.findChild(); }, 1000);
      }

      if (this.model.parent_info != null && typeof this.model.parent_info != "undefined")
      {
        //  setto che qualcosa è stato cancellato da questo subscriber
        if (this.model.parent_info.deleted_at != null)
          this.parentDeleted = true;

        if (this.model.parent_info.birthday != null)
        {
          this.localParentData = this.helper.initMyDataInputSingle(this.model.parent_info.birthday);
          this.model.parent_info.birthday_parsed = HelperService.parseDate(this.model.parent_info.birthday);
        }

        //  faccio partire automaticamente le chiamate per ricercare il padre
        if (this.modelFromSignup != null)
          setTimeout(() => { this.findParent(); }, 1000);
      }

      //  setto a not valid quando la data di iscrizione al campo non esiste
      if (this.model.registration_date != null && typeof this.model.registration_date)
      {
        this.localRegistrationDate = this.helper.initMyDataInputSingle(this.model.registration_date);
        this.model.registration_date_parsed = HelperService.parseDate(this.model.registration_date);
      }

      //  forzo l'aggiornamento della validazione di model autorizzation
      if (this.model.authorization_personal_data == true)
        this.model.authorization_personal_data = true;

      // aggiungo l'id del camp corrente
      this.model.camp_id = this.campService.currentCampId;

      //  controllo le settimane di iscrizione
      this.checkSubscriptionWeeks();

      //  controllo le settimane dei tutor
      this.checkTutorWeeks();

      // aggiungo le informazioni per il CashFlow
      this.model.addin_enrollment_fee = false;
      this.model.addin_course_fee = false;

      //  controllo se esiste un pagamento già effettuato
      if (typeof this.model.payment_requests != "undefined" && this.model.payment_requests != null && this.modelFromSignup != null)
      {
        //  se la causale è quella dell'enrollment fee allora lo inserisco
        if (this.model.payment_requests.causal == 5 && this.model.payment_requests.status == "success")
        {
          this.model.addin_enrollment_fee = true;
          this.model.addin_enrollment_fee_value = this.model.payment_requests.amount;
          this.model.addin_enrollment_fee_registered_via = this.model.payment_requests.registered_via;
          this.model.payment_request = this.model.payment_requests;
        }

        // controllo il numero di persone iscritte contemporaneamente per capire se e quale sconto applicare
        if (this.model.payment_requests.prima_nota_rows != null )
        {
          if (this.model.payment_requests.prima_nota_rows.length == 2)
          {
            this.model.quote_discount_3 = true;
          }
          else if (this.model.payment_requests.prima_nota_rows.length >= 3)
          {
            this.model.quote_discount_4 = true;
          }
        }
      }

      if (this.model.price_quota_associativa > 0){
        this.model.addin_enrollment_fee_value = this.model.price_quota_associativa;
        this.model.addin_enrollment_fee_registered_via = 7
      }
      else {
        if (this.model.quote_enrollment_fee > 0)
          this.model.addin_enrollment_fee_value = this.model.quote_enrollment_fee;
        else
          this.model.addin_enrollment_fee_value = 50;
      }

      this.updateCourseFeeWithDiscount();

      //  se non è un utente precedentemente registrato allora abilito l'edit mode
      if (!this.model.registered)
        this.editMode = true;

      // se è un utente cancellato, disabilito l'edito mode
      if (this.model.deleted_at)
        this.editMode = false;

      console.log(this.model);
    }
  }

  configureModel ()
  {
    this.model = {};
    this.model.parent_info = {};
    this.model.person_info = {};
    this.model.camp_week = [];
    this.model.host_week = [];
    this.model.parent_info.contacts = [
      {type: 5, value: ""}, {type: 1, value: ""}, {type: 4, value: ""}, {type: 7, value: ""}
    ];
    this.model.person_info.contacts = [
      {type: 5, value: ""}, {type: 1, value: ""}, {type: 4, value: ""}
    ];
    this.model.person_info.addresses = [
      {type: 1}
    ]
  }

  //  Controlla se le settimane d'iscizione sono correttamente inserite
  checkSubscriptionWeeks ()
  {
    if (this.model.camp_week == null || typeof this.model.camp_week == "undefined")
      this.model.camp_week = [];

    for (var index in this.model.camp_week)
    {
      switch (this.model.camp_week[index]) {
        case 1:
          this.model.week_01 = true;
          break;
        case 2:
          this.model.week_02 = true;
          break;
        case 3:
          this.model.week_03 = true;
          break;
        case 4:
          this.model.week_04 = true;
          break;
      }
    }
  }


  //  Controlla se le settimane dei tutor sono correttamente inserite
  checkTutorWeeks ()
  {
    if (this.model.host_week == null || typeof this.model.host_week == "undefined")
      this.model.host_week = [];

    for (var index in this.model.host_week)
    {
      switch (this.model.host_week[index]) {
        case 1:
          this.model.tutor_week_01 = true;
          break;
        case 2:
          this.model.tutor_week_02 = true;
          break;
        case 3:
          this.model.tutor_week_03 = true;
          break;
        case 4:
          this.model.tutor_week_04 = true;
          break;
      }
    }
  }


  weekChanged(numOfWeek, value)
  {

    //   se lo sto aggiungendo
    if (value == true) {
      if (!this.model.camp_week.includes(numOfWeek)) {
        this.model.camp_week.push(numOfWeek);
        this.model.camp_week.sort();
      }
    }
    //  se lo sto rimuovendo
    else
    {
      var index = this.model.camp_week.indexOf(numOfWeek);
      if (index > -1)
        this.model.camp_week.splice(index, 1);
    }

    this.updateCourseFeeWithDiscount();
  }

  weekTutorChanged(numOfWeek, value)
  {
    //   se lo sto aggiungendo
    if (value == true) {
      this.model.host_week.push(numOfWeek);
      this.model.host_week.sort();
    }
    //  se lo sto rimuovendo
    else
    {
      var index = this.model.host_week.indexOf(numOfWeek);
      if (index > -1)
        this.model.host_week.splice(index, 1);
    }

    this.updateCourseFeeWithDiscount();
  }


  //  controlla se è stato selezionato almeno una settimana per partecipare al campo
  get weekValid () {

    if (this.model.camp_week.length == 0)
      return false;

    return true;
  }

  submit()
  {
    if (!this.currentForm.form.valid) return;

    if (!this.weekValid) return;

    if (this.model.addin_course_fee_value > 0) {
      if (this.model.addin_course_fee_value + this.model.course_fee_amount > this.model.addin_course_fee_value_total){
        this.notify.error("Course fee", "La quota che si sta versando è più elevata di quella prevista, controllare i dati inseriti");
        return;
      }
    }

    //  registration form
    if (this.modelFromSignup)
    {
      if (this.checkOnSubmit){

        this.campService.saveCampSubscriber(this.model, () => {
          this.formSaved.emit(this.model);
          this.primanotaService.getPrimaNota('App\\Camp', this.campService.currentCampId);
        });
      }
      return;
    }

    //  manual registration
    if (this.model.id)
    {
      this.campService.updateCampSubscriber(this.model, () => {
        this.formSaved.emit(this.model);
        this.primanotaService.getPrimaNota('App\\Camp', this.campService.currentCampId);
      });
    }
    else
    {
      if (this.checkOnSubmit)
      {
        this.campService.saveCampSubscriber(this.model, () => {
          this.formSaved.emit(this.model);
          this.primanotaService.getPrimaNota('App\\Camp', this.campService.currentCampId);
        });
      }
    }


  }

  get checkOnSubmit()
  {
    if (!this.helper.isMyDateValid(this.localPersonData) && !this.personSelected) return false;

    if (!this.helper.isMyDateValid(this.localParentData) && !this.parentSelected) return false;

    //   se non è stata mai settata una data di registrazione valida
    if (this.model.registration_date == null || this.model.registration_date == "undefined") return;


    if (this.parentSelected == null && !this.addNewParent)
    {
      this.notify.error("Parent Info", "Selezionare un genitore dalla tabella, oppure spuntare 'aggiungi nuova persona'");
      return false;
    }

    if (this.personSelected == null && !this.addNewPerson)
    {
      this.notify.error("Child Info", "Selezionare una bambino dalla tabella, oppure spuntare 'aggiungi nuova persona'");
      return false;
    }

    if (this.parentSelected)
      this.prepareParentModel();

    if (this.personSelected)
      this.preparePersonModel();

    if (this.addNewParent)
      this.prepareNewParentModel();

    if(this.addNewPerson)
      this.prepareNewPersonModel();

    //  elimino dal model tutti gli elementi che contengono valori vuoti
    this.sanitizeModel();

    return true;
  }


  sanitizeModel () {

  }


  destroy ()
  {
    this.campService.deleteCampSubscriber(this.model, () => {this.formSaved.emit(this.model);});
  }

  restore ()
  {
    this.campService.restoreCampSubscriber(this.model, () => {this.formSaved.emit(this.model);});
  }

  ignore(){
    this.campService.ignoreCampSignupForm(this.model, () => {
      this.formSaved.emit(this.model);
    });
  }


  //  Cerca la persona con i dati correnti e restituisce un elenco di possibili persone
  findParent()
  {
    this.ongoingRequestParent = true;
    this.parentSelected = null;

    var searchModel: any = {};
    searchModel.first_name = this.model.parent_info.first_name;
    searchModel.surname = this.model.parent_info.surname;
    searchModel.tax_id = this.model.parent_info.tax_id;

    this.campService.findPerson(searchModel, (parentsFound)=> {
      this.parentsFound = parentsFound;

      //  setto l'indirizzo da mostrare nel datatable
      this.parentsFound.forEach(parent => {
        if (Object.keys(parent.address).length > 0) {
          parent.address_primary = parent.address[Object.keys(parent.address)[0]]
          Object.keys(parent.address).forEach(addressKey => {
            if (parent.address[addressKey].primary == true)
              parent.address_primary = parent.address[addressKey];
          });
        }
      })

      this.ongoingRequestParent = false;
      this.firstSearchParent = true;
    }, () => {this.ongoingRequestParent = false;});
  }

  findChild()
  {
    this.ongoingRequestPerson = true;
    this.personSelected = null;

    var searchModel: any = {};
    searchModel.first_name = this.model.person_info.first_name;
    searchModel.surname = this.model.person_info.surname;
    searchModel.tax_id = this.model.person_info.tax_id;


    this.campService.findPerson(searchModel, (personFound)=> {
      this.personFound = personFound;

      //  setto l'indirizzo da mostrare nel datatable
      this.personFound.forEach(person => {
        if (Object.keys(person.address).length > 0) {
          person.address_primary = person.address[Object.keys(person.address)[0]]
          Object.keys(person.address).forEach(addressKey => {
            if (person.address[addressKey].primary == true)
              person.address_primary = person.address[addressKey];
          });
        }
      })

      this.personFound = personFound;
      this.ongoingRequestPerson = false;
      this.firstSearchPerson = true;
    }, () => {this.ongoingRequestPerson = false;});
  }

  prepareParentModel ()
  {
    //  aggiungo dei valori che aiutino la memorizzazione della persona
    this.model.parent_info.id = this.parentSelected.id;
    if (this.model.parent_info.kinship == "father")
      this.model.parent_info.gender = 'm';
    else
      this.model.parent_info.gender = 'f';

    //  controllo che ci siano nuovi contatti
    this.model.parent_new_contact = new Array;

    this.model.parent_info.contacts.forEach(contact => {
      var found = false;

      if (contact.value.trim().length == 0)
        found = true;

      Object.keys(this.parentSelected.contact).forEach(key => {
        if (this.parentSelected.contact[key].type == contact.type && this.parentSelected.contact[key].value == contact.value)
            found = true;
      });

      if (!found)
        this.model.parent_new_contact.push(contact);
    });

    //  controllo che ci siano i ruoli corretti
    this.model.parent_new_role = new Array;

    var found = false;
    Object.keys(this.parentSelected.role).forEach(key => {
      if (this.parentSelected.role[key].type == 2)
        found = true;
    });

    if (!found) {
      var role: any = {type: 2};
      this.model.parent_new_role.push(role);
    }

  }

  preparePersonModel ()
  {
    //  aggiungo dei valori che aiutino la memorizzazione della persona
    this.model.person_info.id = this.personSelected.id;

    //  controllo che ci siano nuovi contatti
    this.model.person_new_contact = new Array;

    this.model.person_info.contacts.forEach(contact => {
      var found = false;

      if (contact.value.trim().length == 0)
        found = true;

      Object.keys(this.personSelected.contact).forEach(key => {
        if ((this.personSelected.contact[key].type == contact.type
          && this.personSelected.contact[key].value == contact.value))
            found = true;
      });

      if (!found)
        this.model.person_new_contact.push(contact);
    });

    //  controllo che ci siano nuovi indirizzi
    this.model.person_new_address = new Array;

    this.model.person_info.addresses.forEach(address => {
      var found = false;
      Object.keys(this.personSelected.address).forEach(key => {
        if (this.personSelected.address[key].type == address.type &&
          address.route.indexOf(this.personSelected.address[key].route) >= 0)
            found = true;
      });

      if (!found) {
        this.model.person_new_address.push(address);
      }
    });

    //  conteollo che ci siano i ruoli corretti
    this.model.person_new_role = new Array;

    var found = false;
    Object.keys(this.personSelected.role).forEach(key => {
      if (this.personSelected.role[key].type == 3)
        found = true;
    });

    if (!found) {
      var role: any = {type: 3};
      this.model.person_new_role.push(role);
    }

  }


  prepareNewParentModel ()
  {
    //  aggiungo dei valori che aiutino la memorizzazione della persona
    if (this.model.parent_info.kinship == "father")
      this.model.parent_info.gender = 'm';
    else
      this.model.parent_info.gender = 'f';

    //  aggiungo i contatti
    this.model.parent_new_contact = new Array;
    this.model.parent_info.contacts.forEach(element => {
      if (element.value.trim().length > 0)
        this.model.parent_new_contact.push(element);
    });

    //  aggiungo il ruolo
    var role: any = {type: 2};
    this.model.parent_new_role = new Array (role);
  }

  prepareNewPersonModel ()
  {
    //  aggiungo i contatti
    this.model.person_new_contact = new Array;
    this.model.person_info.contacts.forEach(element => {
      if (element.value.trim().length > 0)
        this.model.person_new_contact.push(element);
    });

    //  aggiungo gli indirizzi
    this.model.person_new_address = new Array;
    this.model.person_info.addresses.forEach(element => {
      if (element.route.trim().length > 0)
        this.model.person_new_address.push(element);
    });

    //  aggiungo il ruolo
    var role: any = {type: 3};
    this.model.person_new_role = new Array (role);
  }


  onSelectParent({ selected }) {
    this.parentSelected = selected[0];
  }
  onSelectPerson({ selected }) {
    this.personSelected = selected[0];
  }

  onDatePersonChanged(event: IMyDateModel) {

    this.localPersonData = event;

    if (event.singleDate.jsDate != null)
      this.model.person_info.birthday = this.helper.formatISODate(event);
    else
      this.model.person_info.birthday = null;
  }



  onDateParentChanged(event: IMyDateModel) {

    this.localParentData = event;

    if (event.singleDate.jsDate != null)
      this.model.parent_info.birthday = this.helper.formatISODate(event);
    else
      this.model.parent_info.birthday = null;
  }



  onDateRegistrationChanged(event: IMyDateModel) {

    this.localRegistrationDate = event;

    if (event.singleDate.jsDate != null)
      this.model.registration_date = this.helper.formatISODate(event);
    else
    this.model.registration_date = null;
  }

  schoolOnChange: boolean = false;
  /** Evento di cambio del tipo di classe */
  onChangeSchoolType() {

    this.schoolOnChange = true;
    let typeofclass = this.configService.diaryCampSubscriberSchoolTypeSubSelect[this.model.school_type];
    let classfinded = typeofclass.find(x => x.key == this.model.class_type);
    if (classfinded)
      this.model.class_type = classfinded.key;
    else
      this.model.class_type = "1";

    setTimeout(() => {
      this.schoolOnChange = false;
    }, 50);
  }


  suggestEarlyBirdDiscount: boolean = false;

  updateCourseFeeWithDiscount() {

    if (this.campService.currentCampInfo == null)
      return;

    if (this.model == null || this.model.camp_week == null)
      return;

    let num_weeks = this.model.camp_week.length;
    let camp = this.campService.currentCampInfo;

    this.standardCourseFee = camp['quote_course_fee_w'+num_weeks];

    // if (!this.model.enrollment_fee_amount)
    //   return;

    let course_fee = camp['quote_course_fee_w'+num_weeks];

    if (this.model.enrollment_fee_amount)
      course_fee -= this.model.enrollment_fee_amount;

    if (this.model.quote_discount_1){
      course_fee -= camp.quote_discount_1
    }

    if (this.model.quote_discount_2){
      course_fee -= (camp.quote_discount_2 * this.model.host_week.length)
    }

    if (this.model.quote_discount_3){
      course_fee -= (camp.quote_discount_3 * this.model.camp_week.length)
    }

    if (this.model.quote_discount_4){
      course_fee -= (camp.quote_discount_4 * this.model.camp_week.length)
    }

    if (this.model.quote_discount_early_bird){
      course_fee -= camp.quote_discount_early_bird
      // this.suggestEarlyBirdDiscount = false;
    }
    else {
      if (this.campService.currentCampInfo.quote_discount_early_bird_date && isBefore(HelperService.parseDate(this.model.registration_date), HelperService.parseDate(this.campService.currentCampInfo.quote_discount_early_bird_date))){
        this.suggestEarlyBirdDiscount = true;
      }
      else {
        this.suggestEarlyBirdDiscount = false;
      }
    }

    if (this.model.quote_discount_free == true){
      course_fee = 0
    }

    if (this.model.quote_discount_custom > 0){

      if (this.model.quote_discount_custom > course_fee)
        this.model.quote_discount_custom = course_fee;

      course_fee -= parseFloat(this.model.quote_discount_custom);
    }

    this.model.addin_course_fee_value_total = course_fee;

    // sottraggo un eventuale valore già pagato
    if (this.model.course_fee_amount > 0){
      course_fee -= this.model.course_fee_amount;
    }

    // aggiorno il prezzo del corso
    this.model.addin_course_fee_value = course_fee;

    // calcolo lo sconto totale dalla quota standard
    this.totalDiscount = this.standardCourseFee - course_fee - this.model.enrollment_fee_amount - this.model.course_fee_amount;
  }


  //  mostra la modale con la richiesta di pagamento
  createPaymentRequest (typeOfPayment: any = null)
  {
    //  recupero la camp director
    var campDirector = this.campService.getCampDirector();
    //  recupero la venue
    if (this.campService.currentCampInfo.venue_id != null && typeof this.campService.currentCampInfo.venue_id != "undefined")
    {
      this.enterpriseService.getEnterpriseInfoAllSummary(this.campService.currentCampInfo.venue_id, (res) => {

        var address = this.getVenueAddress(res);

        //  creo il modulo da inviare e mostro la modale
        if (this.parentComponent != null && campDirector != null && address != null)
        {
          let modelRequest: any = {};

          modelRequest.item_id = this.campService.currentCampId;
          modelRequest.item_type = 'App\\Camp';
          modelRequest.item_quantity = 1;
          modelRequest.item_name = address.locality + " (" + address.administrative_area_level_2_short_version + ") " +  campDirector.person_info.surname.toUpperCase();
          // modelRequest.item_image = this.courseScheduleService.currentCourseScheduleInfo.course_info.image_url;
          modelRequest.item_currency = 'eur';
          modelRequest.item_description = this.configService.getAreaExpertiseValueByKey(this.campService.currentCampInfo.area);

          Object.keys(this.model.person_info.contact).forEach(contactKey => {
            if (this.model.person_info.contact[contactKey].type == 1)
              modelRequest.sendto_mail_address = this.model.person_info.contact[contactKey].value;
          });

          /*
          * CAMPI PER LA PRIMA NOTA
          */

          //  data della prima nota
          // modelRequest.date_payment = null;
          //  id dell'utente che genera la richiesta
          // modelRequest.registered_by_user_id = 1;
          //  valore predefinito per il pagamento con stripe
          modelRequest.registered_via = 8;
          if (typeOfPayment == 'coursefee')
          {
            modelRequest.amount = this.model.addin_course_fee_value;
            modelRequest.causal = 6;
          } else
          if (typeOfPayment == 'enrollmentfee')
          {
            modelRequest.amount = this.model.addin_enrollment_fee_value;
            modelRequest.causal = 5
          }
          modelRequest.in_holder_type = 'App\\Camp';
          modelRequest.in_holder_id = this.campService.currentCampInfo.id;
          modelRequest.out_holder_type = 'App\\Person';
          modelRequest.out_holder_id = this.model.person_info.id;

          this.parentComponent.showModalRequestPayment(modelRequest);
        }
      })
    }

  }

  inviaMailTo(event: any) {

    event.target.disabled = true;

    // TODO: recuperare l'email del genitore o delegare al server l'operazione
    let email = "";

    this.campService.sendEmailSubscriberInfo(this.model.id, email, () => {
      event.target.innerHTML = '<i class="fa fa-check-square-o aria-hidden="true"></i>&nbsp;Email inviata correttamente';
      this.notify.success("Email", "The email has been sent correctly");
    }, () => {
      event.target.innerHTML = '<i class="fa fa-exclamation-triangle aria-hidden="true"></i>&nbsp;Errore'
    });
  }

  /**
   * Restituisce l'indirizzo di una venue del camp
   */
  getVenueAddress (venue)
  {
    var address = null;
    if (Object.keys(venue.address).length > 0)
      address = venue.address[0];

    Object.keys(venue.address).forEach(element => {

      if (venue.address[element].primary == true)
        return address = venue.address[element];
    });

    return address;
  }

  getCampService():CampService {
    return this.campService
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

  getUserService() {
    return this.userService
  }

}
