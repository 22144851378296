import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { CampService, HelperService, ConfigService, PrimanotaService, UserService, TutorApplicationService } from 'app/_services';
import { IAngularMyDpOptions, IMyDateModel, AngularMyDatePickerModule } from 'angular-mydatepicker';
import { parseISO, isBefore, differenceInCalendarWeeks, format } from 'date-fns';
import { IMyInputFieldChanged } from 'angular-mydatepicker';
import { NgForm } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import CodiceFiscale from 'codice-fiscale-js';

@Component({
  selector: 'app-camp-subscriber-form-new',
  templateUrl: './camp-subscriber-form-new.component.html',
  styleUrls: ['./camp-subscriber-form-new.component.css']
})
export class CampSubscriberFormNewComponent implements OnInit {

  @ViewChild('f', {static: true}) currentForm: NgForm;
  @ViewChild('datePickerParent', {static: true}) datePickerParent: AngularMyDatePickerModule;
  @ViewChild('datePickerPerson', {static: true}) datePickerPerson: AngularMyDatePickerModule;

  @Output() formSaved = new EventEmitter();

  //  numero di settimane del camp che si sta utilizzando
  campWeeks: number = 0;

  model: any = null;

  //  search
  searchParentModel: any = {};
  searchPersonModel: any = {};

  //  date
  localParentData: IMyDateModel;
  localPersonData: IMyDateModel;
  localRegistrationDate: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  datePickerOptions: IAngularMyDpOptions = {};

  ongoingRequestParent = false;
  ongoingRequestPerson = false;
  firstSearchParent = false;
  firstSearchPerson = false;

  parentsFound: any[] = [];
  tempParents = [];
  parentSelected: any;
  addNewParent: boolean = false;

  personFound: any[] = [];
  tempPersons = [];
  personSelected: any;
  addNewPerson: boolean = false;

  addInAlertType: string = "warning";
  canEditDiscount: boolean = false;
  quote_discount_early_bird_date_parsed: any;
  standardCourseFee: number = 0;
  totalDiscount: number = 0;

  constructor(private campService: CampService, private helper:HelperService,
              private configService: ConfigService, private notify: NotificationsService,
              private primanotaService: PrimanotaService, private userService: UserService) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    this.canEditDiscount = this.campService.canEditDiscount();

    this.campWeeks = differenceInCalendarWeeks(HelperService.parseDate(this.campService.currentCampInfo.end), HelperService.parseDate(this.campService.currentCampInfo.start), {weekStartsOn: 1}) + 1;

    if (!this.model || !this.model.id)
      this.configureModel();

    if (this.model)
    {
      if (this.campService.currentCampInfo && this.campService.currentCampInfo.quote_discount_early_bird_date){
        this.quote_discount_early_bird_date_parsed = parseISO(this.campService.currentCampInfo.quote_discount_early_bird_date);
      }

      // aggiungo l'id del camp corrente
      this.model.camp_id = this.campService.currentCampId;

      //  controllo le settimane di iscrizione
      this.checkSubscriptionWeeks();

      //  controllo le settimane dei tutor
      this.checkTutorWeeks();

      // aggiungo le informazioni per il CashFlow
      this.model.addin_enrollment_fee = false;
      this.model.addin_course_fee = false;

      if (this.model.price_quota_associativa > 0){
        this.model.addin_enrollment_fee_value = this.model.price_quota_associativa;
        this.model.addin_enrollment_fee_registered_via = 7
      }
      else {
        if (this.model.quote_enrollment_fee > 0)
          this.model.addin_enrollment_fee_value = this.model.quote_enrollment_fee;
        else
          this.model.addin_enrollment_fee_value = 50;
      }

      this.updateCourseFeeWithDiscount();
    }
  }


  configureModel ()
  {
    this.model = {};
    this.model.camp_week = [];
    this.model.host_week = [];
    this.configurePersonModel();
    this.configureParentModel();
  }

  //  Controlla se le settimane d'iscizione sono correttamente inserite
  checkSubscriptionWeeks ()
  {
    if (this.model.camp_week == null || typeof this.model.camp_week == "undefined")
      this.model.camp_week = [];

    for (var index in this.model.camp_week)
    {
      switch (this.model.camp_week[index]) {
        case 1:
          this.model.week_01 = true;
          break;
        case 2:
          this.model.week_02 = true;
          break;
        case 3:
          this.model.week_03 = true;
          break;
      }
    }
  }

  //  Controlla se le settimane dei tutor sono correttamente inserite
  checkTutorWeeks ()
  {
    if (this.model.host_week == null || typeof this.model.host_week == "undefined")
      this.model.host_week = [];

    for (var index in this.model.host_week)
    {
      switch (this.model.host_week[index]) {
        case 1:
          this.model.tutor_week_01 = true;
          break;
        case 2:
          this.model.tutor_week_02 = true;
          break;
        case 3:
          this.model.tutor_week_03 = true;
          break;
      }
    }
  }


  /*
  _____        _____  ______ _   _ _______    _____ ______ _____ _______ _____ ____  _   _
 |  __ \ /\   |  __ \|  ____| \ | |__   __|  / ____|  ____/ ____|__   __|_   _/ __ \| \ | |
 | |__) /  \  | |__) | |__  |  \| |  | |    | (___ | |__ | |       | |    | || |  | |  \| |
 |  ___/ /\ \ |  _  /|  __| | . ` |  | |     \___ \|  __|| |       | |    | || |  | | . ` |
 | |  / ____ \| | \ \| |____| |\  |  | |     ____) | |___| |____   | |   _| || |__| | |\  |
 |_| /_/    \_\_|  \_\______|_| \_|  |_|    |_____/|______\_____|  |_|  |_____\____/|_| \_|

  */




  configureParentModel ()
  {
    this.model.parent_info = {};
    this.model.parent_info.contacts = [
      {type: 5, value: "", updated_at: "0"}, {type: 1, value: "", updated_at: "0"}, {type: 4, value: "", updated_at: "0"}, {type: 7, value: "", updated_at: "0"}
    ];
  }


  //  condizione di disattivazione del pulsante di ricerca di un genitore
  get searchParentDisabled()
  {
    var condition = true;

    condition = condition && !this.model.parent_info.tax_id;
    condition = condition && (!this.model.parent_info.surname || !this.model.parent_info.first_name);

    if (this.ongoingRequestParent || this.campService.ongoingRequest)
      return true;

    return condition;
  }




  onDateParentChanged(event: IMyDateModel) {

    this.localParentData = event;

    if (event.singleDate.jsDate != null)
      this.model.parent_info.birthday = this.helper.formatISODate(event);
    else
      this.model.parent_info.birthday = null;
  }


  onSelectParent({ selected }) {

    //  resetto il parent info
    this.configureParentModel();

    if (selected.length != 0)
    {
      this.parentSelected = selected[0];

      //  preparo il model del parent inserendo tutte le info che servono
      this.prepareParentModelView();

      //  filtro i risultati per mostrare solo quello selezionato
      this.parentsFound = this.tempParents.filter (x => x.id == selected[0].id);
    }
  }


  removeSelectedParent() {

    if (this.parentSelected)
    {
      this.configureParentModel();

      this.parentSelected = null;
      this.parentsFound = [...this.tempParents];

      this.model.parent_info.first_name = this.searchParentModel.first_name;
      this.model.parent_info.surname = this.searchParentModel.surname;
      this.model.parent_info.tax_id = this.searchParentModel.tax_id;

      this.localParentData = null;

      this.currentForm.form.markAsPristine();
      this.currentForm.form.markAsUntouched();
    }
  }


  //  seleziono un genitore dalla famiglia del bambino
  parentSelectedFromFamily (memberSelected) {

    //  faccio la stessa ricerca ma questa volta verrà ricercato per id della persona
    this.campService.findPerson(memberSelected, (person) => {

      if (person.length != 0)
      {
              //  resetto il parent info
        this.configureParentModel();

        this.parentSelected = person[0];

        //  preparo il model del parent inserendo tutte le info che servono
        this.prepareParentModelView();

        //  conservo anche un array temporaneo della ricerca
        this.tempParents = [...person];

        // inseriamo l'intera lista arrivata dalla ricerca
        this.parentsFound = person;

        //  forzo la visione della tabella
        this.firstSearchParent = true;
      }
    });

  }


  //  Questo metodo inserisce tutti i valori dopo la ricerca di una persona
  //  nell'html per evitare l'inserimento multiplo di tutti i valori
  prepareParentModelView ()
  {

    this.model.parent_info.surname = this.parentSelected.surname;
    this.model.parent_info.first_name = this.parentSelected.first_name;

    if (this.parentSelected.gender)
    {
      if (this.parentSelected.gender.toLowerCase() == 'm')
        this.model.parent_info.kinship = "father";
      else
        this.model.parent_info.kinship = "mother";
    }

    if (this.parentSelected.tax_id)
      this.model.parent_info.tax_id = this.parentSelected.tax_id;

    /* inseriti solo se sono membri dello staff */
    if (this.userService.isOfficeSalesStaff())
    {
      if (this.parentSelected.birthday)
      {
        this.model.parent_info.birthday = this.parentSelected.birthday
        this.localParentData = this.helper.initMyDataInputSingle(this.model.parent_info.birthday);
      }
      else  {
        this.localParentData = null;
      }

      if (this.parentSelected.birthplace)
        this.model.parent_info.birthplace = this.parentSelected.birthplace;

      Object.keys(this.parentSelected.contact).forEach(key => {
        for(var i=0; i < this.model.parent_info.contacts.length; i++) {
          if (this.model.parent_info.contacts[i].type == this.parentSelected.contact[key].type)
          {
            //  se la data attuale è precedente o la nuova data è un primary allora la setto
            if (isBefore(parseISO(this.model.parent_info.contacts[i].updated_at), parseISO(this.parentSelected.contact[key].updated_at)))
              this.model.parent_info.contacts[i] = this.parentSelected.contact[key]
          }
        };
      });
    }
    else
    {
      this.localParentData = null;
    }

  }

  //  preparo il model del parent quando viene da una selezione della tabella
  prepareParentModel ()
  {
    //  aggiungo dei valori che aiutino la memorizzazione della persona
    this.model.parent_info.id = this.parentSelected.id;
    if (this.model.parent_info.kinship == "father")
      this.model.parent_info.gender = 'm';
    else
      this.model.parent_info.gender = 'f';

    //  controllo che ci siano nuovi contatti
    this.model.parent_new_contact = new Array;

    this.model.parent_info.contacts.forEach(contact => {
      var found = false;

      if (contact.value.trim().length == 0)
        found = true;

      Object.keys(this.parentSelected.contact).forEach(key => {
        if (this.parentSelected.contact[key].type == contact.type && this.parentSelected.contact[key].value == contact.value)
            found = true;
      });

      if (!found)
        this.model.parent_new_contact.push(contact);
    });

    //  conteollo che ci siano i ruoli corretti
    this.model.parent_new_role = new Array;

    var found = false;
    Object.keys(this.parentSelected.role).forEach(key => {
      if (this.parentSelected.role[key].type == 2)
        found = true;
    });

    if (!found) {
      var role: any = {type: 2};
      this.model.parent_new_role.push(role);
    }

  }

  //  preparo il model del parent quando è un nuovo inserimento
  prepareNewParentModel ()
  {
    //  aggiungo dei valori che aiutino la memorizzazione della persona
    if (this.model.parent_info.kinship == "father")
      this.model.parent_info.gender = 'm';
    else
      this.model.parent_info.gender = 'f';

    //  aggiungo i contatti
    this.model.parent_new_contact = new Array;
    this.model.parent_info.contacts.forEach(element => {
      if (element.value.trim().length > 0)
        this.model.parent_new_contact.push(element);
    });

    //  aggiungo il ruolo
    var role: any = {type: 2};
    this.model.parent_new_role = new Array (role);
  }

  //  Cerca la persona con i dati correnti e restituisce un elenco di possibili persone
  findParent()
  {
    this.ongoingRequestParent = true;
    this.parentSelected = null;

    this.searchParentModel = {};
    this.searchParentModel.first_name = this.model.parent_info.first_name;
    this.searchParentModel.surname = this.model.parent_info.surname;
    this.searchParentModel.tax_id = this.model.parent_info.tax_id;

    this.campService.findPerson(this.searchParentModel, (parentsFound)=> {

      //  setto l'indirizzo da mostrare nel datatable
      parentsFound.forEach(parent => {
        if (Object.keys(parent.address).length > 0) {
          parent.address_primary = parent.address[Object.keys(parent.address)[0]]
          Object.keys(parent.address).forEach(addressKey => {
            if (parent.address[addressKey].primary == true)
              parent.address_primary = parent.address[addressKey];
          });
        }
      })

      //  conservo anche un array temporaneo della ricerca
      this.tempParents = [...parentsFound];

      // inseriamo l'intera lista arrivata dalla ricerca
      this.parentsFound = parentsFound;

      this.ongoingRequestParent = false;
      this.firstSearchParent = true;
    }, () => {this.ongoingRequestParent = false;});
  }




  onParentTaxidChange (valueField)
  {
    if (typeof valueField != "undefined")
      this.model.parent_info.tax_id = valueField.toUpperCase();

    try {
      var cf = new CodiceFiscale(this.model.parent_info.tax_id).toJSON();

      this.model.parent_info.gender = cf.gender.toLowerCase();

      if (typeof cf.birthplace != "undefined")
      {
        if (this.model.parent_info.birthplace != cf.birthplace)
          this.model.parent_info.birthplace = cf.birthplace;

        if (this.model.parent_info.birthplace_province != cf.birthplaceProvincia)
          this.model.parent_info.birthplace_province = cf.birthplaceProvincia;
        }

      //  assegno e formatto la data nel formato del datepicker corrente
      var localData = format(
        new Date(parseISO(cf.year + "-" + cf.month + "-" + cf.day)),
        this.getDateFormatParent().toUpperCase().replace('GG', "dd"));

      if (this.helper.formatISODate(this.localParentData) != localData)
      {
        this.model.parent_info.birthday = cf.year + '-' + cf.month + '-' + cf.day;
        this.localParentData = this.helper.initMyDataInputSingle(this.model.parent_info.birthday);
      }
    }
    catch(err) {
        return;
    }

  }





  /*
  _____  ______ _____   _____  ____  _   _    _____ ______ _____ _______ _____ ____  _   _
 |  __ \|  ____|  __ \ / ____|/ __ \| \ | |  / ____|  ____/ ____|__   __|_   _/ __ \| \ | |
 | |__) | |__  | |__) | (___ | |  | |  \| | | (___ | |__ | |       | |    | || |  | |  \| |
 |  ___/|  __| |  _  / \___ \| |  | | . ` |  \___ \|  __|| |       | |    | || |  | | . ` |
 | |    | |____| | \ \ ____) | |__| | |\  |  ____) | |___| |____   | |   _| || |__| | |\  |
 |_|    |______|_|  \_\_____/ \____/|_| \_| |_____/|______\_____|  |_|  |_____\____/|_| \_|

*/





  configurePersonModel()
  {
    this.model.person_info = {};
    this.model.person_info.contacts = [
      {type: 5, value: "", updated_at: "0"}, {type: 1, value: "", updated_at: "0"}, {type: 4, value: "", updated_at: "0"}
    ];
    this.model.person_info.addresses = [
      {type: 1, updated_at: "0"}
    ]
  }


  //  condizione di disattivazione del pulsante di ricerca di un iscritto
  get searchPersonDisabled()
  {
    var condition = true;

    condition = condition && !this.model.person_info.tax_id;
    condition = condition && (!this.model.person_info.surname || !this.model.person_info.first_name);

    if (this.ongoingRequestParent || this.campService.ongoingRequest)
      return true;

    return condition;
  }


  onDatePersonChanged(event: IMyDateModel) {

    this.localPersonData = event;

    if (event.singleDate.jsDate != null)
      this.model.person_info.birthday = this.helper.formatISODate(event);
    else
      this.model.person_info.birthday = null;
  }


  onSelectPerson({ selected }) {

    //  resetto il parent info
    this.configurePersonModel();

    if (selected.length != 0)
    {
      this.personSelected = selected[0];

      //  preparo il model del bambino inserendo tutte le info che servono
      this.preparePersonModelView();

      //  filtro i risultati per mostrare solo quello selezionato
      this.personFound = this.tempPersons.filter (x => x.id == selected[0].id);

      //  mostro la modale della famiglia se un bambino viene selezionato
      // if (typeof this.personSelected.family != "undefined" && this.personSelected.family != null)
    }
  }


  removeSelectedPerson() {

    if (this.personSelected)
    {
      this.configurePersonModel();

      this.personSelected = null;
      this.personFound = [...this.tempPersons];

      this.model.person_info.first_name = this.searchPersonModel.first_name;
      this.model.person_info.surname = this.searchPersonModel.surname;
      this.model.person_info.tax_id = this.searchPersonModel.tax_id;

      this.localPersonData = null;

      this.currentForm.form.markAsPristine();
      this.currentForm.form.markAsUntouched();
    }
  }


  //  Questo metodo inserisce tutti i valori dopo la ricerca di una persona
  //  nell'html per evitare l'inserimento multiplo di tutti i valori
  preparePersonModelView ()
  {
    this.model.person_info.surname = this.personSelected.surname;
    this.model.person_info.first_name = this.personSelected.first_name;

    if (this.personSelected.gender)
      this.model.person_info.gender = this.personSelected.gender;

    /* inseriti solo se sono membri dello staff */
    if (this.userService.isOfficeSalesStaff())
    {
      if (this.personSelected.tax_id)
        this.model.person_info.tax_id = this.personSelected.tax_id;

      if (this.personSelected.birthday)
      {
        this.model.person_info.birthday = this.personSelected.birthday
        this.localPersonData = this.helper.initMyDataInputSingle(this.model.person_info.birthday);
      }
      else  {
        this.localPersonData = null;
      }

      if (this.personSelected.tshirt_size)
        this.model.person_info.tshirt_size = this.personSelected.tshirt_size;

      if (this.personSelected.birthplace)
        this.model.person_info.birthplace = this.personSelected.birthplace;

      Object.keys(this.personSelected.contact).forEach(key => {
        for(var i=0; i < this.model.person_info.contacts.length; i++) {
          if (this.model.person_info.contacts[i].type == this.personSelected.contact[key].type)
          {
            //  se la data attuale è precedente la nuova data allora la setto
            if (isBefore(parseISO(this.model.person_info.contacts[i].updated_at), parseISO(this.personSelected.contact[key].updated_at)))
              this.model.person_info.contacts[i] = this.personSelected.contact[key]
          }
        };
      });

      Object.keys(this.personSelected.address).forEach(key => {
        for (var i=0; i < this.model.person_info.addresses.length; i++) {
          if (this.model.person_info.addresses[i].type == this.personSelected.address[key].type)
          {
            //  se la data attuale è precedente la nuova data allora la setto
            if (isBefore(parseISO(this.model.person_info.addresses[i].updated_at), parseISO(this.personSelected.address[key].updated_at)))
              this.model.person_info.addresses[i] = this.personSelected.address[key]
          }
        }
      });

    }
    else
    {
      this.localPersonData = null;
    }
  }

  preparePersonModel ()
  {
    //  aggiungo dei valori che aiutino la memorizzazione della persona
    this.model.person_info.id = this.personSelected.id;

    //  controllo che ci siano nuovi contatti
    this.model.person_new_contact = new Array;

    this.model.person_info.contacts.forEach(contact => {
      var found = false;

      if (contact.value.trim().length == 0)
        found = true;

      Object.keys(this.personSelected.contact).forEach(key => {
        if ((this.personSelected.contact[key].type == contact.type
          && this.personSelected.contact[key].value == contact.value))
            found = true;
      });

      if (!found)
        this.model.person_new_contact.push(contact);
    });

    //  controllo che ci siano nuovi indirizzi
    this.model.person_new_address = new Array;

    this.model.person_info.addresses.forEach(address => {
      var found = false;
      Object.keys(this.personSelected.address).forEach(key => {
        if (this.personSelected.address[key].type == address.type &&
          address.route.indexOf(this.personSelected.address[key].route) >= 0)
            found = true;
      });

      if (!found) {
        this.model.person_new_address.push(address);
      }
    });

    //  conteollo che ci siano i ruoli corretti
    this.model.person_new_role = new Array;

    var found = false;
    Object.keys(this.personSelected.role).forEach(key => {
      if (this.personSelected.role[key].type == 3)
        found = true;
    });

    if (!found) {
      var role: any = {type: 3};
      this.model.person_new_role.push(role);
    }

  }


  prepareNewPersonModel ()
  {
    //  aggiungo i contatti
    this.model.person_new_contact = new Array;
    this.model.person_info.contacts.forEach(element => {
      if (element.value.trim().length > 0)
        this.model.person_new_contact.push(element);
    });

    //  aggiungo gli indirizzi
    this.model.person_new_address = new Array;
    this.model.person_info.addresses.forEach(element => {
      if (element.route.trim().length > 0)
        this.model.person_new_address.push(element);
    });

    //  aggiungo il ruolo
    var role: any = {type: 3};
    this.model.person_new_role = new Array (role);
  }




  findChild()
  {
    this.ongoingRequestPerson = true;
    this.personSelected = null;

    this.searchPersonModel = {};
    this.searchPersonModel.first_name = this.model.person_info.first_name;
    this.searchPersonModel.surname = this.model.person_info.surname;
    this.searchPersonModel.tax_id = this.model.person_info.tax_id;

    this.campService.findPerson(this.searchPersonModel, (personFound)=> {
      this.personFound = personFound;

      //  setto l'indirizzo da mostrare nel datatable
      personFound.forEach(person => {
        if (Object.keys(person.address).length > 0) {
          person.address_primary = person.address[Object.keys(person.address)[0]]
          Object.keys(person.address).forEach(addressKey => {
            if (person.address[addressKey].primary == true)
              person.address_primary = person.address[addressKey];
          });
        }
      })

      //  conservo anche un array temporaneo della ricerca
      this.tempPersons = [...personFound];

      // inseriamo l'intera lista arrivata dalla ricerca
      this.personFound = personFound;

      this.ongoingRequestPerson = false;
      this.firstSearchPerson = true;
    }, () => {this.ongoingRequestPerson = false;});
  }


  onPersonTaxidChange (valueField)
  {
    if (typeof valueField != "undefined")
      this.model.person_info.tax_id = valueField.toUpperCase();

    try {
      var cf = new CodiceFiscale(this.model.person_info.tax_id).toJSON();

      this.model.person_info.gender = cf.gender.toLowerCase();

      if (typeof cf.birthplace != "undefined")
      {
        if (this.model.person_info.birthplace != cf.birthplace)
          this.model.person_info.birthplace = cf.birthplace;

        if (this.model.person_info.birthplace_province != cf.birthplaceProvincia)
          this.model.person_info.birthplace_province = cf.birthplaceProvincia;
        }

      //  assegno e formatto la data nel formato del datepicker corrente
      var localData = format(
        new Date(parseISO(cf.year + "-" + cf.month + "-" + cf.day)),
        this.getDateFormatPerson().toUpperCase().replace('GG', "dd"));

      if (this.helper.formatISODate(this.localPersonData) != localData)
      {
        this.model.person_info.birthday = cf.year + '-' + cf.month + '-' + cf.day;
        this.localPersonData = this.helper.initMyDataInputSingle(localData);
      }
    }
    catch(err) {
        return;
    }

  }





  /*
  ______      __  __ _____ _  __     __  _____        _____ _______
 |  ____/\   |  \/  |_   _| | \ \   / / |  __ \ /\   |  __ \__   __|
 | |__ /  \  | \  / | | | | |  \ \_/ /  | |__) /  \  | |__) | | |
 |  __/ /\ \ | |\/| | | | | |   \   /   |  ___/ /\ \ |  _  /  | |
 | | / ____ \| |  | |_| |_| |____| |    | |  / ____ \| | \ \  | |
 |_|/_/    \_\_|  |_|_____|______|_|    |_| /_/    \_\_|  \_\ |_|

  */










  /*
   _____ ____  __  __ __  __  ____  _   _   _____        _____ _______ _____
  / ____/ __ \|  \/  |  \/  |/ __ \| \ | | |  __ \ /\   |  __ \__   __/ ____|
 | |   | |  | | \  / | \  / | |  | |  \| | | |__) /  \  | |__) | | | | (___
 | |   | |  | | |\/| | |\/| | |  | | . ` | |  ___/ /\ \ |  _  /  | |  \___ \
 | |___| |__| | |  | | |  | | |__| | |\  | | |  / ____ \| | \ \  | |  ____) |
  \_____\____/|_|  |_|_|  |_|\____/|_| \_| |_| /_/    \_\_|  \_\ |_| |_____/

*/





onDateRegistrationChanged(event: IMyDateModel) {

  this.localRegistrationDate = event;

  if (event.singleDate.jsDate != null)
    this.model.registration_date = this.helper.formatISODate(event);
  else
    this.model.registration_date = null;
}



weekChanged(numOfWeek, value)
  {

    //   se lo sto aggiungendo
    if (value == true) {
      if (!this.model.camp_week.includes(numOfWeek)) {
        this.model.camp_week.push(numOfWeek);
        this.model.camp_week.sort();
      }
    }
    //  se lo sto rimuovendo
    else
    {
      var index = this.model.camp_week.indexOf(numOfWeek);
      if (index > -1)
        this.model.camp_week.splice(index, 1);
    }

    this.updateCourseFeeWithDiscount();
  }

  weekTutorChanged(numOfWeek, value)
  {
    //   se lo sto aggiungendo
    if (value == true) {
      this.model.host_week.push(numOfWeek);
      this.model.host_week.sort();
    }
    //  se lo sto rimuovendo
    else
    {
      var index = this.model.host_week.indexOf(numOfWeek);
      if (index > -1)
        this.model.host_week.splice(index, 1);
    }

    this.updateCourseFeeWithDiscount();
  }


  getDateFormatParent ()
  {
    var localeFormat = "";

    if (typeof this.datePickerParent['localeService'].locales[this.datePickerParent['locale']] != "undefined")
      localeFormat = this.datePickerParent['localeService'].locales[this.datePickerParent['locale']].localeFormat;

    if (typeof localeFormat == "undefined" || localeFormat.length == 0)
      localeFormat = this.datePickerOptions.dateFormat;

    if (typeof localeFormat == "undefined" || localeFormat.length == 0)
      localeFormat = "gg/mm/yyyy";

    return localeFormat
  }

  getDateFormatPerson ()
  {
    var localeFormat = "";

    if (typeof this.datePickerPerson['localeService'].locales[this.datePickerPerson['locale']] != "undefined")
      localeFormat = this.datePickerPerson['localeService'].locales[this.datePickerPerson['locale']].localeFormat;

    if (typeof localeFormat == "undefined" || localeFormat.length == 0)
      localeFormat = this.datePickerOptions.dateFormat;

    if (typeof localeFormat == "undefined" || localeFormat.length == 0)
      localeFormat = "gg/mm/yyyy";

    return localeFormat
  }


//  controlla se è stato selezionato almeno una settimana per partecipare al campo
get weekValid () {

  if (this.model.camp_week.length == 0)
    return false;

  return true;
}


submit ()
{
  if (!this.currentForm.form.valid) return;

  if (!this.weekValid) return;

  if (this.model.addin_course_fee_value > 0) {
    if (this.model.addin_course_fee_value + this.model.course_fee_amount > this.model.addin_course_fee_value_total){
      this.notify.error("Course fee", "La quota che si sta versando è più elevata di quella prevista, controllare i dati inseriti");
      return;
    }
  }

  //  manual registration
  if (this.checkOnSubmit)
  {
    this.campService.saveCampSubscriber(this.model, () => {
      this.formSaved.emit(this.model);
      this.primanotaService.getPrimaNota('App\\Camp', this.campService.currentCampId);
    });
  }
}


  get checkOnSubmit()
  {

    if (!this.helper.isMyDateValid(this.localPersonData) && !this.personSelected) return false;

    if (!this.helper.isMyDateValid(this.localParentData) && !this.parentSelected) return false;

    //   se non è stata mai settata una data di registrazione valida
    if (this.model.registration_date == null || this.model.registration_date == "undefined") return;

    if (this.parentSelected == null && !this.addNewParent)
    {
      this.notify.error("Parent Info", "Selezionare un genitore dalla tabella, oppure spuntare 'aggiungi nuova persona'");
      return false;
    }

    if (this.personSelected == null && !this.addNewPerson)
    {
      this.notify.error("Child Info", "Selezionare una bambino dalla tabella, oppure spuntare 'aggiungi nuova persona'");
      return false;
    }

    if (this.parentSelected)
      this.prepareParentModel();

    if (this.personSelected)
      this.preparePersonModel();

    if (this.addNewParent)
      this.prepareNewParentModel();

    if(this.addNewPerson)
      this.prepareNewPersonModel();

    //  elimino dal model tutti gli elementi che contengono valori vuoti
    this.sanitizeModel();

    return true;
  }

  sanitizeModel () {

  }


  suggestEarlyBirdDiscount: boolean = false;

  updateCourseFeeWithDiscount() {

    if (this.campService.currentCampInfo == null)
      return;

    if (this.model == null || this.model.camp_week == null)
      return;

    let num_weeks = this.model.camp_week.length;
    let camp = this.campService.currentCampInfo;

    this.standardCourseFee = camp['quote_course_fee_w'+num_weeks];

    // if (!this.model.enrollment_fee_amount)
    //   return;

    let course_fee = camp['quote_course_fee_w'+num_weeks];

    if (this.model.enrollment_fee_amount)
      course_fee -= this.model.enrollment_fee_amount;

    if (this.model.quote_discount_1){
      course_fee -= camp.quote_discount_1
    }

    if (this.model.quote_discount_2){
      course_fee -= (camp.quote_discount_2 * this.model.host_week.length)
    }

    if (this.model.quote_discount_3){
      course_fee -= (camp.quote_discount_3 * this.model.camp_week.length)
    }

    if (this.model.quote_discount_4){
      course_fee -= (camp.quote_discount_4 * this.model.camp_week.length)
    }

    if (this.model.quote_discount_early_bird){
      course_fee -= camp.quote_discount_early_bird
      // this.suggestEarlyBirdDiscount = false;
    }
    else {
      if ((this.campService.currentCampInfo.quote_discount_early_bird_date == null || typeof this.campService.currentCampInfo.quote_discount_early_bird_date == "undefined") ||
        (this.model.registration_date == null || typeof this.model.registration_date == "undefined"))
        this.suggestEarlyBirdDiscount = false;
      else
      {
        if (isBefore(this.model.registration_date, this.campService.currentCampInfo.quote_discount_early_bird_date)) {
          this.suggestEarlyBirdDiscount = true;
        }
        else {
          this.suggestEarlyBirdDiscount = false;
        }
      }
    }

    if (this.model.quote_discount_free == true){
      course_fee = 0
    }

    if (this.model.quote_discount_custom > 0){

      if (this.model.quote_discount_custom > course_fee)
        this.model.quote_discount_custom = course_fee;

      course_fee -= parseFloat(this.model.quote_discount_custom);
    }

    this.model.addin_course_fee_value_total = course_fee;

    // sottraggo un eventuale valore già pagato
    if (this.model.course_fee_amount > 0){
      course_fee -= this.model.course_fee_amount;
    }

    // aggiorno il prezzo del corso
    this.model.addin_course_fee_value = course_fee;

    // calcolo lo sconto totale dalla quota standard
    this.totalDiscount = this.standardCourseFee - course_fee;

  }

  getUserService(): UserService {
    return this.userService;
  }

  getCampService():CampService {
    return this.campService
  }

  getHelper() {
    return this.helper
  }

  getConfigService() {
    return this.configService
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

}
