<img *ngIf="loading" src="./assets/global/img/input-spinner.gif" />

<div *ngIf="this.personInfo.deleted_at == null">
  <a *ngIf="currentPersonId && !hideSummaryCardModal" class="pointer" (click)="clickPerson()">{{personInfo.first_name}} {{personInfo.surname}}&nbsp;</a>
  <span *ngIf="currentPersonId && hideSummaryCardModal"><a *ngIf="currentPersonId" class="blue pointer" target="_blank" routerLink="/person/edit/{{ currentPersonId }}">{{personInfo.first_name}} {{personInfo.surname}}</a>&nbsp;</span>
  <a *ngIf="currentPersonId" class="blue pointer" target="_blank" routerLink="/person/edit/{{ currentPersonId }}"><i class="fa fa-external-link" aria-hidden="true"></i></a>
  <!-- <a *ngIf="currentPersonId && !hideSummaryCardModal" class="blue pointer" (click)="clickPerson()"><i class="fa fa-id-card-o" aria-hidden="true"></i></a> -->

  <div *ngIf="showContact || showAddress || showRole">&nbsp;</div>

  <div *ngIf="showContact" class="row">
    <div *ngFor="let cont of personInfo.contact | mapFromKeyValue" class="col-md-6">
      <div class="form-group read-only">
        <label class="control-label"><i *ngIf="cont.type != 1" class="fa fa-phone-square" aria-hidden="true"></i>
          <i *ngIf="cont.type == 1" class="fa fa-envelope" aria-hidden="true"></i> {{ getConfigService().getContactPersonValueByKey(cont.type)}}</label>
        <p>{{cont.value}}&nbsp;<a *ngIf="cont.type != 1 && showWhatsapp" href="https://wa.me/39{{cont.value}}?text={{whatsappMessage}}" tooltip="Al numero di telefono viene aggiunto il prefisso internazionale +39, nel caso fosse già presente, rimuoverlo dal link o inserire quello corretto in caso di numero estero" target="_blank" class="btn btn-link btn-xs"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></p>

      </div>
    </div>
  </div>

  <div *ngIf="showAddress" class="row">
    <div *ngFor="let add of personInfo.address | mapFromKeyValue" class="col-md-6">
        <alert *ngIf="!add.country_long_version" type="warning">
            <strong>Alert!</strong> address is not googlized
          </alert>
      <div class="form-group read-only">
        <label class="control-label">{{getConfigService().getAddressPersonValueByKey(add.type)}}</label>
        <p>
          {{ getConfigService().convertAddressAsStringForTable(add) }}
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="showRole" class="row">
    <!-- <alert *ngIf="noRoleAlert" type="warning">
      <strong>Alert!</strong> no roles found
    </alert> -->
    <div *ngFor="let role of personInfo.role | mapFromKeyValue" class="col-md-6">
      <div class="form-group read-only">
        <p>{{getConfigService().getRolePersonValueByKey(role.type)}}</p>
        <p>
          <app-enterprise-info-show [idEnterprise]="role.enterprise_id"></app-enterprise-info-show>
        </p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="this.personInfo.deleted_at != null">
  <span class="text-danger"> Person Deleted </span>
</div>

<!-- BEGIN: MODAL CONTENT PERSON INFO -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
tabindex="-1" role="dialog" aria-hidden="true">
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Person Info Summary</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body form">
      <div class="row">
        <div class="col-md-12">
          <app-person-info-summary #personInfoSummaryComponent [idPerson]="currentPersonId" (closeEvent)="hideModal()" ></app-person-info-summary>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!-- END: MODAL CONTENT PERSON INFO -->
