import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { UserService } from '../../_services/user.service';

@Directive({
  selector: '[appCheckPermission]'
})
export class CheckPermissionDirective {

  @Input('appCheckPermission') appCheckPermission: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private userService: UserService) {  }

  ngOnInit(){

    if (!this.userService.checkPermission(this.appCheckPermission)){
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    }
  }

}
