<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <h1 class="page-title">Cash Flow</h1>

      <div class="row">
        <div class="col-md-12">

          <form action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
            <div class="row">

              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">Anno</label>
                  <select class="form-control" name="year" [(ngModel)]="query.year" (change)="fieldChange($event);">
                    <option value=""></option>
                    <option *ngFor="let y of getConfigService().yearSelect(-10)" [value]="y">{{y}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">Mese</label>
                  <select class="form-control" name="month" [(ngModel)]="query.month" (change)="fieldChange($event);">
                    <option value=""></option>
                    <option *ngFor="let m of getConfigService().monthSelect()" [value]="m">{{m}}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label><i class="fa fa-file-text" aria-hidden="true"></i> Fiscal Document</label>
                  <div class="mt-checkbox-inline">
                    <label class="mt-checkbox mt-checkbox-outline">
                      <input type="checkbox" name="fiscal_document" [(ngModel)]="query.fiscal_document" value="1"
                        (change)="fieldChange($event);"> It's available
                      <span></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3" [appCheckPermission]="'primanota_edit'">
                <div class="form-group">
                  <label><i class="fa fa-check-circle" aria-hidden="true"></i> Verified</label>
                  <div class="mt-checkbox-inline">
                    <label class="mt-checkbox mt-checkbox-outline">
                      <input type="checkbox" name="verified_transaction" [(ngModel)]="query.verified_transaction" value="1"
                        (change)="fieldChange($event);"> Transaction verified
                      <span></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">

              <div class="col-md-3">
                <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDateFrom, false)) }">
                  <label class="control-label">From Date</label>
                    <div class="input-group">
                      <!-- input box -->
                      <input angular-mydatepicker #dp_from_date="angular-mydatepicker" name="from_date" class="form-control"
                        [locale]="getHelper().locale"
                        [options]="datePickerOptionsBegin"
                        [ngModel]="localDateFrom"
                        (dateChanged)="onDateFromChanged($event)"
                      />
                      <!-- clear date button -->
                      <span class="input-group-btn">
                        <button type="button" *ngIf="query['from_date']" class="btn default" (click)="dp_from_date.clearDate()"><i class="fa fa-close"></i></button>
                      </span>
                      <!-- toggle calendar button -->
                      <span class="input-group-btn">
                          <button type="button" class="btn default" (click)="dp_from_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                      </span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localDateTo, false)) }">
                  <label class="control-label">To Date</label>
                    <div class="input-group">
                      <!-- input box -->
                      <input angular-mydatepicker #dp_to_date="angular-mydatepicker" name="to_date" class="form-control"
                        [locale]="getHelper().locale"
                        [options]="datePickerOptionsEnd"
                        [ngModel]="localDateTo"
                        (dateChanged)="onDateToChanged($event)"
                      />
                      <!-- clear date button -->
                      <span class="input-group-btn">
                        <button type="button" *ngIf="query['to_date']" class="btn default" (click)="dp_to_date.clearDate()"><i class="fa fa-close"></i></button>
                      </span>
                      <!-- toggle calendar button -->
                      <span class="input-group-btn">
                          <button type="button" class="btn default" (click)="dp_to_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                      </span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">Type From/To</label>
                  <select class="form-control" name="type_fromto" [(ngModel)]="query.type_fromto" (change)="fieldChange($event);">
                    <option value="App\Person">People</option>
                    <option value="App\Enterprise">Enterprise</option>
                    <option value="App\Camp">Camp</option>
                    <option value="App\ActorGroup">Actor Group</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">Registered via</label>
                  <select class="form-control" name="registered_via" [(ngModel)]="query.registered_via" #registeredvia="ngModel"
                    (change)="fieldChange($event);">
                    <option *ngFor="let c of getConfigService().primanotaRegisteredViaSelect" [value]="c.key">{{c.value}}</option>
                  </select>
                </div>
              </div>

            </div>

            <div class="form-actions right">
              <button type="button" class="btn default btn-sm" (click)="clearSearch()">Clear
                <i class="fa fa-eraser" aria-hidden="true"></i>
              </button>
              <button class="btn default btn-sm green" [disabled]="ongoingRequest" type="submit">Search</button>
            </div>

          </form>
        </div>
      </div>

      <hr>

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">

            <div class="portlet-body form">

              <alert type="warning" *ngIf="loadingData">
                <strong>Loading</strong> ...
              </alert>

              <ngx-datatable *ngIf="!loadingData" class="striped" [rows]="rows" [columnMode]="'force'" [headerHeight]="50"
                [footerHeight]="50" [rowClass]="getRowClass" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'"
                (activate)="onActivate($event)" (select)='onSelect($event)' [scrollbarH]="true" (page)='setPage($event)'
                (sort)='onSort($event)' [externalPaging]="true" [externalSorting]="true" [count]="pageTotalElements"
                [offset]="pageNumber" [limit]="pageSize">
                <ngx-datatable-column name="Date" prop="date_payment" [resizeable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span *ngIf="value">{{ value }}</span>
                    <br>{{pageNumber}}
                    <small>
                      <i *ngIf="row.verified_transaction" class="fa fa-check-circle" aria-hidden="true" style="color:green"></i>
                      {{ row.created_at_parsed | date:'d MMMM, y':'':'it' }}
                    </small>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="From" prop="out_holder_name" [resizeable]="false" [sortable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span *ngIf="value">
                      <i class="fa fa-{{row.out_holder_icon}}"></i> {{ value }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Out" prop="out_amount" [resizeable]="false" [sortable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span class="font-red-haze" *ngIf="value">{{ (value | currency:'EUR':'symbol':'1.2-2':'it') || "-"}}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="In" prop="in_amount" [resizeable]="false" [sortable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span class="font-green-jungle" *ngIf="value">{{ (value | currency:'EUR':'symbol':'1.2-2':'it') ||"-" }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="To" prop="in_holder_name" [resizeable]="false" [sortable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span *ngIf="value">
                      <i class="fa fa-{{row.in_holder_icon}}"></i> {{ value }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Registered via" prop="registered_via_description" [resizeable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <i *ngIf="row.fiscal_document" class="fa fa-file-text" aria-hidden="true"></i>
                    <span *ngIf="value">{{ value }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Causal" prop="causal_description" [resizeable]="false"></ngx-datatable-column>
              </ngx-datatable>



              <div class="row">
                <div class="pull-right">
                  <button [appCheckPermission]="'prima_nota_export'" [disabled]="getPrimanotaService().isOngoingRequest() || rows?.length <= 0"
                    type="button" class="btn green" (click)="exportToExcel()">
                    <i class="fa fa-share"></i> Export to Excel</button>
                </div>
              </div>


              <!-- BEGIN: MODAL LOADING EXPORT-->
              <div [config]="{ backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal #staticModal="bs-modal" class="modal fade"
                tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title text-center">
                        Attendere il caricamento senza chiudere o cambiare pagina
                      </h4>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12">
                          <h4 class="text-center">Se l'esportazione fallisce, provare a ridurre il numero di righe totali filtrando per anno o per mese.</h4>
                          <div class="datatable-loader text-center">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <strong class="text-center">Loading</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END: MODAL LOADING EXPORT-->






              <div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
                class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12">
                          <app-primanota-add-form [showCloseButton]="false" [primaNotaModel]="selectedRow" [showAddInOutButton]="false"
                            (modelUpdated)="modelUpdatedInModal($event)"></app-primanota-add-form>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>



            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
