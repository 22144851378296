import { Router } from '@angular/router';
import { EnterpriseService, HelperService, PersonService, ConfigService } from './../../_services/index';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { parseISO } from 'date-fns';
import { NotificationsService } from 'angular2-notifications';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-agreement-camp-info-form',
  templateUrl: './agreement-camp-info-form.component.html',
  styleUrls: ['./agreement-camp-info-form.component.css']
})
export class AgreementCampInfoFormComponent implements OnInit {

  constructor(private enterpriseService: EnterpriseService, private personService: PersonService,
    private helper: HelperService, private configService: ConfigService,
    private router: Router, private notify: NotificationsService) { }

  currentEnterprise: any;
  currentVenue: any;
  currentLegal: any;
  currentCD: any;
  currentData: any;
  currentAgreement: any;
  currentCamp: any;
  currentProjectName: any = "";
  audienceModel: null;

  errorMessages: any[] = [];
  warningMessages: any[] = [];

  requestCount: number;

  totalCost: number = 0;

  //  file uploaders
  public imageUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf'],
      url: 'serverUrl'
    }
  );


  @Output() onrequest:EventEmitter<boolean> = new EventEmitter<boolean>();


  ngOnInit() {

    //  quando si attiva il component ho già tutte le info di cui necessito
    this.requestCount = 0;

    this.currentEnterprise = this.enterpriseService.currentEnterpriseInfo;

    if (this.currentEnterprise == null) this.currentEnterprise = {};

    if (this.enterpriseService.currentCampAgreement == null) {
      this.errorMessages.push("Mancano le informazioni sull'organizzatore");
      return;
    }

    //  info agreement
    this.currentAgreement = this.enterpriseService.currentCampAgreement;

    if (this.currentAgreement.camp_info == null) {
      this.errorMessages.push("Mancano le informazioni sull'organizzatore");
      return;
    }

    //  venue dove si tiene il campo
    if (this.currentAgreement.camp_info.venue == null) {
      this.errorMessages.push("Mancano le informazioni per la venue")
      return;
    }
    this.currentVenue = this.currentAgreement.camp_info.venue
    this.setEnterpriseVenueAddress();

    //  info del camp
    this.currentCamp = this.currentAgreement.camp_info;
    this.currentCamp.start_date_parsed = parseISO(this.currentCamp.start).toLocaleDateString(this.helper.locale),
    this.currentCamp.end_date_parsed = parseISO(this.currentCamp.end).toLocaleDateString(this.helper.locale),
    this.currentCamp.lesson = this.currentCamp.start_lesson + " - " + this.currentCamp.end_lesson

    //  set audience model
    if (this.currentCamp.audience != null)
      this.audienceModel = JSON.parse(this.currentCamp.audience);

    //  setto il nome del progetto o il tipo di camp
    this.currentProjectName = this.configService.getCampTypeValueByKey(this.currentCamp.type);

    this.imageUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.imageUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.imageUploaded(item, response, status);
    };
    //  Se è una convenzione approvata allora setto alcune informazioni
    if (this.currentAgreement.id)
      this.imageUploader.options.url = this.enterpriseService.getCampAgreementDocumentFileUploadURL(this.currentAgreement);

    this.setEnterpriseAddress();

    this.setEnterpriseContact();

    this.setEnterpriseBankAccount();

    this.setEnterpriseLegal();

    this.setCampDirector();

  }

  ngOnDestroy() {
  }


  setEnterpriseVenueAddress ()
  {
    //  Assegno il primo indirizzo della lista se presente
    if (Object.keys(this.currentVenue.addresses).length > 0)
    this.currentVenue.primaryAddress = this.currentVenue.addresses[Object.keys(this.currentVenue.addresses)[0]];
    else
    this.errorMessages.push("Mancano l'indirizzo per la venue");
    //  Cerco l'indirizzo primary
    Object.keys(this.currentVenue.addresses).forEach(key => {
    if (this.currentVenue.addresses[key].primay)
      this.currentVenue.primaryAddress = this.currentVenue.addresses[key];
    });
  }

  setEnterpriseAddress()
  {
    //  Assegno il primo indirizzo della lista se presente
    if (Object.keys(this.enterpriseService.currentEnterpriseAddressList).length > 0)
      this.currentEnterprise.primaryAddress = this.enterpriseService.currentEnterpriseAddressList[Object.keys(this.enterpriseService.currentEnterpriseAddressList)[0]];
    else
      this.errorMessages.push("Mancano l'indirizzo per l'enterprise organizzatrice");
    //  Cerco l'indirizzo primary
    Object.keys(this.enterpriseService.currentEnterpriseAddressList).forEach(key => {
      if (this.enterpriseService.currentEnterpriseAddressList[key].primay)
        this.currentEnterprise.primaryAddress = this.enterpriseService.currentEnterpriseAddressList[key];
    });
  }


  setEnterpriseContact()
  {
    //  Assegno il contatto della lista se presente
    if (Object.keys(this.enterpriseService.currentEnterpriseContactList).length > 0)
      this.currentEnterprise.primaryContact = this.enterpriseService.currentEnterpriseContactList[Object.keys(this.enterpriseService.currentEnterpriseContactList)[0]];
    else
      this.errorMessages.push("Mancano il contatto per l'enterprise organizzatrice");
    //  Cerco l'indirizzo primary
    Object.keys(this.enterpriseService.currentEnterpriseContactList).forEach(key => {
      if (this.enterpriseService.currentEnterpriseContactList[key].primay)
        this.currentEnterprise.primaryContact = this.enterpriseService.currentEnterpriseContactList[key];
    });
  }


  setEnterpriseBankAccount()
  {
    //  Assegno il bankaccount della lista se presente
    if (Object.keys(this.enterpriseService.currentEnterpriseBankAccountList).length > 0)
      this.currentEnterprise.primaryBankaccount = this.enterpriseService.currentEnterpriseBankAccountList[Object.keys(this.enterpriseService.currentEnterpriseBankAccountList)[0]];
    else
      this.warningMessages.push("Mancano i dati bancari per l'enterprise organizzatrice");
    //  Cerco l'indirizzo primary
    Object.keys(this.enterpriseService.currentEnterpriseBankAccountList).forEach(key => {
      if (this.enterpriseService.currentEnterpriseBankAccountList[key].primay)
        this.currentEnterprise.primaryBankaccount = this.enterpriseService.currentEnterpriseBankAccountList[key];
    });

    if (this.currentEnterprise.primaryBankaccount != null)
    {
      if (this.currentEnterprise.primaryBankaccount.type != 1)
        this.warningMessages.push("L'enterprise non ha un bank account corretto");
    }

  }

  setEnterpriseLegal()
  {
    //  Inserisco il legale rappresentante della enterprise
    var legalFound = false;
    Object.keys(this.enterpriseService.currentEnterpriseInfo.representative).forEach(key => {
      if (this.enterpriseService.currentEnterpriseInfo.representative[key].type == 7) {

        //  Faccio una chiamata per recuperare i dati di questa persona
        this.getLegal(this.enterpriseService.currentEnterpriseInfo.representative[key], () => {

          if (this.currentLegal)
            legalFound = true;

          // Se non ci sono informazioni vado subito in errore
          if (!legalFound)
            this.warningMessages.push("Manca il rappresentante legale, aggiungi una persona con questo ruolo collegandolo alla scuola " + this.currentEnterprise.denomination);
        });
      }
    });
  }


  setCampDirector()
  {
    //  controllo se ci sono camp director
    if (this.currentCamp.camp_directors.length == 0)
      this.errorMessages.push("Mancano le info della Camp Director");
    else
    {
      if (this.currentCamp.camp_directors[0].person_info)
        this.currentCD = this.currentCamp.camp_directors[0].person_info;
    }
  }


  //  Creo la convenzione con le informazioni attuali
  createAgreement() {

    this.enterpriseService.confirmCampAgreementCreation(this.enterpriseService.currentEnterpriseInfo.id, () => {

      this.router.navigate(['enterprise/' + this.enterpriseService.currentEnterpriseId + '/agreementcamp/edit/' + this.enterpriseService.currentCampAgreement.id]);
    });
  }

  //  Cancello la convenzione e mando l'utente alla schermata del enterprise
  deleteAgreement() {
    this.enterpriseService.deleteCampAgreement(() => {

      this.router.navigate(['enterprise/edit/' + this.enterpriseService.currentEnterpriseId]);
    })
  }

  downloadPdfAgreement() {
    this.enterpriseService.createPdfCampAgreement(() => {

      // this.router.navigate(['enterprise/edit/' + this.enterpriseService.currentEnterpriseId]);
    })
  }

  inviaMailTo(event: any, email) {

    event.target.disabled = true;

    this.enterpriseService.sendEmailCampAgreementTo(email, () => {
      event.target.innerHTML = '<i class="fa fa-check-square-o aria-hidden="true"></i>&nbsp;Email inviata correttamente';
      this.notify.success("Email", "The email has been sent correctly");
    }, () => {
      event.target.innerHTML = '<i class="fa fa-exclamation-triangle aria-hidden="true"></i>&nbsp;Errore'
    });
  }


  getLegal(legal: any, callback = null) {

    this.requestCount++;
    this.onrequest.emit(true);

    if (this.personService.currentPersonInfo == null)
      this.currentLegal = {};

    this.personService.currentPersonId = legal.person_id;
    this.personService.getPersonInfoAll();

    var service = this.personService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      if (this.personService.currentPersonInfo) {
        this.currentLegal = this.personService.currentPersonInfo;
        service.unsubscribe();

        //  Trasformo la data in quella locale
        if (this.currentLegal.birthday != null)
          this.currentLegal.birthday = parseISO(this.currentLegal.birthday).toLocaleDateString(this.helper.locale, { year: "numeric", month: "2-digit", day: "2-digit" });

        //  Resetto le indo delle persone dal service
        this.personService.resetService();

        //  Controllo la possibilità di errori nelle infomazioni del rappresentante legale
        if (this.currentLegal.id) {
          //  Setto i messaggi di warning
          if (!this.currentLegal.birthday)
            this.warningMessages.push("Manca la data di nascita del rappresentante legale");
          if (!this.currentLegal.birthplace)
            this.warningMessages.push("Manca il luogo di nascita del rappresentante legale");
          if (!this.currentLegal.tax_id)
            this.warningMessages.push("Manca il codice fiscale del rappresentante legale");
        }
        else
          //  Setto i messaggi di errore
          this.errorMessages.push("Mancano le informazioni sul rappresentante legale");
      }

      this.requestCount--;
      if (this.requestCount <= 0)
        this.onrequest.emit(false);

        if (callback != null)
          callback();
    });

  }


  //  Document Uploader
  public imageUploaded(item:any, response:any, status:any)
  {

    if (item != null) {
      if (status === 200) {
        this.currentAgreement.url_document = response;
        this.currentAgreement.status = this.enterpriseService.currentCampAgreement.status;
      }
      this.enterpriseService.campAgreementDocumentFileResponse(item, response, status,  this.currentAgreement.id);
    }

  }

  //  Remove Document
  destroyFile() {
    this.enterpriseService.deleteCampAgreementDocumentFile(this.currentAgreement, () => {

      this.currentAgreement = this.enterpriseService.currentCampAgreement;
    });
  }


  submit() {

  }

  getEnterpriseService() {
    return this.enterpriseService
  }

  getConfigService() {
    return this.configService;
  }

}
