<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <a href="javascript:;" [routerLink]="['/courseschedule/home']">Course Schedule</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span *ngIf="!getCourseScheduleService().currentCourseScheduleId">New</span>
            <span *ngIf="getCourseScheduleService().currentCourseScheduleId">Edit</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>

      <app-reminder-widget-alertbox *ngIf="holderId" [holderType]="holderType" [holderId]="holderId" [holderName]="holderName"></app-reminder-widget-alertbox>


      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title" *ngIf="!getCourseScheduleService().currentCourseScheduleId">New Course Schedule</h1>
      <!-- <h1 class="page-title" *ngIf="getCourseScheduleService().currentCourseScheduleId">{{getCourseScheduleService().currentCourseScheduleInfo.title}}</h1> -->
      <h1 class="page-title" *ngIf="getCourseScheduleService().currentCourseScheduleId">Edit Course Schedule</h1>
      <!-- END PAGE TITLE-->
      <alert type="warning" *ngIf="">
          <strong>Loading</strong> ...
        </alert>
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">

            <!--end: Portlet-Body -->
            <div class="portlet-body form">

              <alert type="warning" *ngIf="maxNumOfSubscribersEarned">
                <strong>E' stato raggiunto il numero massimo di subscribers consentiti per questo corso</strong>
              </alert>

              <tabset #courseScheduleTabs class="align-right portlet-title tabbable-line" [hidden]="loadingData">
                <tab heading="Info"><app-course-schedule-info-form *ngIf="staticTabs?.tabs[0]?.active" [courseScheduleComponent]="this"></app-course-schedule-info-form></tab>
                <tab heading="Timetable"><app-course-schedule-table-date *ngIf="staticTabs?.tabs[1]?.active"></app-course-schedule-table-date></tab>
                <tab heading="Quote"><app-course-schedule-quote *ngIf="staticTabs?.tabs[2]?.active"></app-course-schedule-quote></tab>
                <tab heading="Staff"><app-course-schedule-staff *ngIf="staticTabs?.tabs[3]?.active"></app-course-schedule-staff></tab>
                <tab heading="Requests"><app-course-schedule-signup-list *ngIf="staticTabs?.tabs[4]?.active"></app-course-schedule-signup-list></tab>
                <tab heading="Subscribers"><app-course-schedule-subscriber-list *ngIf="staticTabs?.tabs[5]?.active"></app-course-schedule-subscriber-list></tab>
                <tab heading="Groups"><app-course-schedule-groups *ngIf="staticTabs?.tabs[6]?.active"></app-course-schedule-groups></tab>
                <tab heading="Rooms"><app-course-schedule-rooms *ngIf="staticTabs?.tabs[7]?.active"></app-course-schedule-rooms></tab>
                <!-- <tab heading="Documents"><app-camp-document-list *ngIf="staticTabs?.tabs[8]?.active"></app-camp-document-list></tab> -->
                <tab heading="Payment Requests"><app-course-schedule-payment-requests-list *ngIf="staticTabs?.tabs[8]?.active && getUserService().checkPermission('course_schedule_edit')"></app-course-schedule-payment-requests-list></tab>
                <tab heading="Cash flow"><app-course-schedule-cashflow *ngIf="staticTabs?.tabs[9]?.active"></app-course-schedule-cashflow></tab>
                <tab heading="Reminder"><app-course-schedule-reminder></app-course-schedule-reminder></tab>
                <tab heading="Diary"><app-course-schedule-diary-list *ngIf="staticTabs?.tabs[11]?.active && getUserService().checkPermission('course_schedule_edit')"></app-course-schedule-diary-list></tab>
                <tab heading="Revision"><app-course-schedule-revision *ngIf="staticTabs?.tabs[12]?.active && getUserService().checkPermission('course_schedule_edit')"></app-course-schedule-revision></tab>
              </tabset>

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
