import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { differenceInWeeks } from 'date-fns';
import { UserService } from './user.service';

declare var saveAs: any;

@Injectable()
export class ShipmentService {

  ongoingRequest: boolean = false;

  //	Memorizza l'ultima query di ricerca fatta
	lastSearchQueries: any = null;


	private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

	constructor(private backend: BackendService, private notify: NotificationsService, private userService: UserService, private http: HttpClient) { }

	getsubjectToUpdateObservable(): Observable<boolean> {
		return this.subjectToUpdate.asObservable();
	}

	resetService() {
	}

	startRequest() {
		this.ongoingRequest = true;
	}

	finishRequest() {
		this.ongoingRequest = false;
	}

	isOngoingRequest() {
		return this.ongoingRequest;
	}



	getAllMaterialFromShipment(shipment: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.get('shipment/materials/'+shipment.id+'/all', params).subscribe(
      (response) => {

				let res : any = response;

				if (callbackSuccess != null)
					callbackSuccess(res);

				this.finishRequest();
      },
      (error) => {
				this.backend.showErrors(error);

				if (callbackError != null)
					callbackError();

				this.finishRequest();
      }
		);

	}




	saveShipment(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.post('shipment', model).subscribe(
			(response) => {

			var shipment : any = response;

			this.subjectToUpdate.next(true);

			this.notify.success("Shipment", "successfully saved");

			this.finishRequest();

			if (callbackSuccess)
				callbackSuccess(shipment);
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError(error);
			}
		);
	}

	updateShipment(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.put('shipment/' + model.id, model).subscribe(
			(response) => {

				var shipment : any = response;

				this.subjectToUpdate.next(true);

				this.notify.success("Shipment", "successfully saved");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess(shipment);
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError(error);
			}
		);
	}



	deleteShipment(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.destroy('shipment/' + model.id).subscribe(
			(response) => {

				this.subjectToUpdate.next(true);

				this.notify.success("Shipment", "successfully deleted");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError(error);
			}
		);
  }





	saveShipmentMaterial(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.post('shipment/material', model).subscribe(
			(response) => {

				var material : any = response;

				this.subjectToUpdate.next(true);

				this.notify.success("Material", "successfully saved");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}


	updateShipmentMaterial(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.put('shipment/material/' + model.id, model).subscribe(
			(response) => {

				var material : any = response;

				this.subjectToUpdate.next(true);

				this.notify.success("Material", "successfully saved");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}



	deleteShipmentMaterial(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.destroy('shipment/material/' + model.id).subscribe(
			(response) => {

				this.subjectToUpdate.next(true);

				this.notify.success("Material", "successfully deleted");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}



	getAllShipmentWithClosedOrder (callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.get('shipment/allproducts/request', params).subscribe (
		  (response) => {

			let resJson : any = response;

			this.finishRequest();

			if (callbackSuccess != null)
			  callbackSuccess(resJson);
		  },
		  (error) => {
			this.backend.showErrors(error);

			this.finishRequest();

			if (callbackError != null)
			  callbackError();
		  }
		);
	  }




}
