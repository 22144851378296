import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {TutorApplicationService, ConfigService, HelperService} from "../../_services/index";

@Component({
  selector: 'app-tutors-applications-datatable',
  templateUrl: './tutors-applications-datatable.component.html',
  styleUrls: ['./tutors-applications-datatable.component.css']
})
export class TutorsApplicationsDatatableComponent implements OnInit {

  @Input() query: any;

  @Output() tutorApplicationSelected = new EventEmitter();

  pageTotalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotalPages: number = 0;
  pageSort: any = null;
  rows: any[] = [];

  selected = [];

  ongoingRequest: boolean = false;

  currentQuery: any;

  constructor(private tutorApplicationService: TutorApplicationService, private configService: ConfigService, private helperService: HelperService) {}

  ngOnInit() {
    // this.setPage({ offset: 0 });
  }

  update(data: any) {
  }

  setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.getResults();
  }

  onSort(sortInfo) {

    this.pageSort = sortInfo.sorts[0];
    this.getResults();
  }

  onSelect({selected}) {
    this.tutorApplicationSelected.emit(selected[0]);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  getResults() {
    if (typeof this.query == "undefined")
      return;

    if (JSON.stringify(this.currentQuery) != JSON.stringify(this.query)) {
      //  Salvo il valore della query attuale e resetto le variabili
      this.currentQuery = Object.assign(this.query);
      this.pageTotalElements = 0;
      this.pageNumber = 0;
      this.pageSize = 0;

      this.pageSort = null;
    }

    this.rows = [];
    this.pageTotalElements = 0;
    this.pageTotalPages = 0;

    if (typeof this.currentQuery.advancedSearchOn == "undefined")
      return;

    let simpleSearchKeyword = this.helperService.transformSearchString(this.currentQuery.simpleSearchKeyword);

    let params = new HttpParams();
    params = params.set('page', (this.pageNumber + 1).toString());

    if (this.pageSort != null) {
      params = params.set('sortDir', this.pageSort.dir);
      params = params.set('sortBy', this.pageSort.prop);
    }

    this.ongoingRequest = true;

    if (this.currentQuery.advancedSearchOn == "false" || !this.currentQuery.advancedSearchOn) {

      this.tutorApplicationService.searchTutorsApplication(simpleSearchKeyword, params, (jsonResponse) => {

        this.pageNumber = jsonResponse.current_page - 1;
        this.pageSize = jsonResponse.per_page;
        this.pageTotalElements = jsonResponse.total;
        this.pageTotalPages = jsonResponse.last_page;
        this.rows = this.getTableData(jsonResponse.data);
        this.ongoingRequest = false;
      }, (error) => {

        this.ongoingRequest = false;
      });
    }
    else {

    }
  }

  getTableData(data) {

    for (let key in data) {

      data[key].address = this.configService.convertAddressAsStringForTable(data[key]);
    }

    return data;
  }

}
