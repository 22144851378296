<div class="col-md-12" *ngIf="isOngoingRequest()">
  <div class="datatable-loader text-center" >
    <span></span><span></span><span></span><span></span><span></span>
    <strong class="text-center">Loading</strong>
  </div>
</div>


<div class="col-md-12">
  <div class="portlet light bordered">
    <div class="portlet-title">
      <div class="caption"><span class="caption-subject font-dark sbold uppercase"></span></div>
      <div class="actions">
        <select *ngIf="multiSelection" class="form-control portlet-inline" [(ngModel)]="multiSelectionCourseScheduleGroupId">
          <option [value]="-1"></option>
          <option *ngFor="let g of courseScheduleGroupList" [value]="g.id">{{g.group_name}}</option>
        </select>
        <br>
        <span><img *ngIf="loading" src="./assets/global/img/input-spinner.gif" /></span>
        <button [appCheckPermission]="'course_schedule_edit_group'" *ngIf="!multiSelection" class="btn btn-primary" [disabled]="loading"
          (click)="enableMultipleSelection()"><i class="fa fa-edit"></i>&nbsp;Multiple Selection</button>
        <button [appCheckPermission]="'course_schedule_edit_group'" *ngIf="multiSelection" class="btn green" [disabled]="loading"
          (click)="saveMultipleSelection()"><i class="fa fa-save"></i>&nbsp;Save</button>
        <button [appCheckPermission]="'course_schedule_edit_group'" *ngIf="multiSelection" class="btn btn-danger" [disabled]="loading"
          (click)="cancelMultipleSelection()"><i class="fa fa-times"></i>&nbsp;Clear Multiple Selection</button>
      </div>
    </div>
    <div class="portlet-body">

      <div class="datatable-loader text-center" *ngIf="isOngoingRequest()">
        <span></span><span></span><span></span><span></span><span></span>
        <strong class="text-center">Loading</strong>
      </div>

      <!-- BEGIN: Tabella con la settiman corrente -->
      <ngx-datatable *ngIf="!isOngoingRequest()" class="striped" [columnMode]="'flex'" [rowHeight]="'auto'"
        [rows]="subscriberGroupList" [headerHeight]="'auto'" [footerHeight]="0" [selected]="selected" [selectionType]="'checkbox'"
        [rowClass]="getRowClass" (select)='onSelect($event)' [sorts]="[{prop: 'person_info.surname', dir: 'asc'}]">

        <ngx-datatable-column [sortable]="false" *ngIf="multiSelection" [sortable]="false"
          [draggable]="false" [resizeable]="false" [headerCheckboxable]="false" [checkboxable]="true">
        </ngx-datatable-column>
        <ngx-datatable-column name="Nome" [flexGrow]="4" prop="person_info.surname" [sortable]="true"
          [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{value}} {{row.person_info.first_name}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Età" [flexGrow]="1" prop="person_info.birthday" [sortable]="true"
          [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{getHelper().calculateAge(row.person_info.birthday)}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Sesso" [flexGrow]="2" prop="person_info.gender" [sortable]="true"
          [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{getConfigService().getGenderPersonValueByKey(row.person_info.gender)}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Gruppo" [flexGrow]="3" prop="group.name" [sortable]="true" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span
              *ngIf="(currentModify?.subscriber?.person_id == row.person_id); else elsegroup">
              <select class="form-control" [(ngModel)]="row.group.course_schedule_group_id" (change)="changeGroup(row)">
                <option [value]="-1"></option>
                <option *ngFor="let g of courseScheduleGroupList" [value]="g.id">{{g.group_name}}</option>
              </select>
            </span>
            <ng-template #elsegroup>
              <span *ngIf="value">
                {{value}}
              </span>
            </ng-template>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="" [flexGrow]="2" [sortable]="false" [resizeable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span
              *ngIf="currentModify?.subscriber?.person_id != row.person_id; else elsebutton">
              <button [appCheckPermission]="'course_schedule_edit_group'" *ngIf="!multiSelection" class="btn btn-xs btn-outline btn-primary" [disabled]="loading"
                (click)="modifyGroup(row)"><i class="fa fa-edit"></i>&nbsp;Edit</button>
            </span>
            <ng-template #elsebutton>
              <span><img *ngIf="loading" src="./assets/global/img/input-spinner.gif" /></span>
              <button *ngIf="!loading" class="btn btn-xs btn-outline btn-danger" (click)="closeGroup()"><i
                  class="fa fa-times"></i>&nbsp;Close</button>
            </ng-template>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>

      <!-- END: Tabella con la settiman corrente -->
    </div>
    <hr>
    <div class="row">
      <div class="pull-right" style="padding:0 14px 0">
        <button *ngIf="getUserService().checkPermission('course_schedule_export')"
          [disabled]="getCourseScheduleService().isOngoingRequest()" type="button" class="btn green"
          (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button>
      </div>
    </div>
  </div>

</div>




<!-- BEGIN: MODAL LOADING EXPORT-->
<div [hidden]="!isModalExport" [config]="{ show: false, backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal
  #staticModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->
