import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BackendService, PersonService, ConfigService, HelperService } from "../../_services/index";

@Component({
  selector: 'app-person-datatable',
  templateUrl: './person-datatable.component.html',
  styleUrls: ['./person-datatable.component.css']
})
export class PersonDatatableComponent implements OnInit {

  @Input() query: any;

  @Output() personSelected = new EventEmitter();

  pageTotalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotalPages: number = 0;
  pageSort: any = null;
  rows: any[] = [];

  selected = [];

  ongoingRequest: boolean = false;

  currentQuery: any;

  constructor(private backend: BackendService, private personService: PersonService, private configService: ConfigService, private helperService: HelperService) { }

  ngOnInit() {
    // this.setPage({ offset: 0 });
  }

  update(data: any) {
  }

  setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.getResults();
  }

  onSort(sortInfo) {

    this.pageSort = sortInfo.sorts[0];
    this.getResults();
  }

  onSelect({ selected }) {
    this.personSelected.emit(selected[0]);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  getRowClass(row) {

    if (!row.role){
      return {'no-role-row': true};
    }
  }

  getResults()
  {
    if (typeof this.query == "undefined")
      return;

    if (JSON.stringify(this.currentQuery) != JSON.stringify(this.query))
    {
      //  Salvo il valore della query attuale e resetto le variabili
      this.currentQuery = Object.assign(this.query);
      this.pageTotalElements = 0;
      this.pageNumber = 0;
      this.pageSize = 0;

      this.pageSort = null;
    }

    this.rows = [];
    this.pageTotalElements = 0;
    this.pageTotalPages = 0;

    if (typeof this.currentQuery.advancedSearchOn == "undefined")
      return;


    let simpleSearchKeyword = this.helperService.transformSearchString(this.currentQuery.simpleSearchKeyword);

    let params = new HttpParams();
    params = params.set('page', (this.pageNumber + 1).toString());

    if (this.pageSort != null)
    {
      params = params.set('sortDir', this.pageSort.dir);
      params = params.set('sortBy', this.pageSort.prop);
    }

    this.ongoingRequest = true;

    if (this.currentQuery.advancedSearchOn == "false" || !this.currentQuery.advancedSearchOn) {

      this.backend.get('person/search/' + simpleSearchKeyword, params).subscribe(
        (response) => {
          let res : any = response;
          this.pageNumber = res.current_page - 1;
          this.pageSize = res.per_page;
          this.pageTotalElements = res.total;
          this.pageTotalPages = res.last_page;
          this.rows = this.getTableData(res.data);
          this.ongoingRequest = false;
        },
        (error) => {
          this.backend.showErrors(error);
          this.ongoingRequest = false;
        }
      );

    }
    else {

      this.backend.post('person/search/advanced', this.currentQuery, params).subscribe(
        (response) => {
          let res : any = response;
          this.pageNumber = res.current_page - 1;
          this.pageSize = res.per_page;
          this.pageTotalElements = res.total;
          this.pageTotalPages = res.last_page;
          this.rows = this.getTableData(res.data);
          this.ongoingRequest = false;
        },
        (error) => {
          this.backend.showErrors(error);

          this.ongoingRequest = false;
        }
      );
    }
  }

  getTableData(data){

    for (let key in data){

      data[key].address = this.configService.convertAddressAsStringForTable(data[key]);
    }

    return data;
  }


}
