import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CourseScheduleService, ConfigService, UserService, PrimanotaService } from 'app/_services';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-course-schedule',
  templateUrl: './course-schedule.component.html',
  styleUrls: ['./course-schedule.component.css']
})
export class CourseScheduleComponent implements OnInit {

  @ViewChild('courseScheduleTabs', {static: true}) staticTabs: TabsetComponent;

  private subject: any;
  private courseScheduleSubject: any;
  // private campDirectorError = false;

  loadingData: boolean = true;
  subscribePrimanota: any;
  holderType: string;
  holderId: any;
  holderName: string;

  //  massimo numero di subscribers raggiunto
  maxNumOfSubscribersEarned = false;

  constructor(private titleService: Title, private route: ActivatedRoute,
    private courseScheduleService: CourseScheduleService, private configService: ConfigService, private userService: UserService, private primanotaService: PrimanotaService) { }

  ngOnInit() {

    this.titleService.setTitle("Course Schedule");
    this.disableAllTab();

    //   mi registro ai cambiamenti delle info del campo per controllare se ci sono errori con la CM
    this.courseScheduleSubject = this.courseScheduleService.getsubjectToUpdateObservable().subscribe(() => {

      this.loadingData = false;
      // this.campDirectorError = this.courseScheduleService.errorCampDirector;
      this.checkSubscribers();
    });

    this.subject = this.route.params.subscribe(params => {

      this.courseScheduleService.resetService();

      if (typeof params['idCurrentCourseSchedule'] != "undefined") {

        this.courseScheduleService.currentCourseScheduleId = +params['idCurrentCourseSchedule'];

        this.getCourseScheduleInfo();
      }
      else {
        this.loadingData = false;
      }
    });



    // this.subscribePrimanota = this.primanotaService.getsubjectToUpdatePrimaNotaObservable().subscribe((res: any) => {

    // });

  }

  ngOnDestroy() {
    // this.subscribePrimanota.unsubscribe();
    this.courseScheduleSubject.unsubscribe();
  }

  getCourseScheduleInfo() {

    this.loadingData = true;

    this.courseScheduleService.getCourseScheduleInfoAll(() => {

      this.titleService.setTitle("Course Schedule > " + "Edit");

      this.holderType = "App\\ActorGroup";
      this.holderName = this.courseScheduleService.currentCourseScheduleInfo["title"];
      this.holderId = this.courseScheduleService.currentCourseScheduleId;

      this.checkSubscribers();

      this.loadingData = false;
      this.activeAllTab();
      this.selectTab(0);
    }, () => {

      this.loadingData = false;
      this.disableAllTab();
    });
  }

  checkSubscribers()
  {
    this.maxNumOfSubscribersEarned = this.courseScheduleService.checkSubscribers();
  }

  selectTab(num) {

    if (this.staticTabs.tabs[num])
      this.staticTabs.tabs[num].active = true;
  }

  activeAllTab() {

    this.staticTabs.tabs.forEach(element => {
      element.disabled = false;
    });
  }

  disableAllTab() {

    this.staticTabs.tabs.forEach(element => {
      element.disabled = true;
    });
  }

  getUserService() {
    return this.userService
  }

  getCourseScheduleService() {
    return this.courseScheduleService
  }

  getConfigService() {
    return this.configService
  }
}
