import { Component, OnInit, ViewChild } from '@angular/core';
import { ActorApplicationService, ConfigService, HelperService, UserService } from 'app/_services';
import { ActivatedRoute } from '@angular/router';
import { parseISO } from 'date-fns';
import { IMyDateModel, IMyInputFieldChanged, IAngularMyDpOptions } from 'angular-mydatepicker';
import * as moment from 'moment-timezone';
import { Nationalities } from 'app/_services/nationalities';
import { NotificationsService } from 'angular2-notifications';
import { NgForm } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-actors-application-edit',
  templateUrl: './actors-application-edit.component.html',
  styleUrls: ['./actors-application-edit.component.css']
})
export class ActorsApplicationEditComponent implements OnInit {

  private subject: any;

  @ViewChild('f', {static: false}) public form: NgForm;

  //  file uploaders
  public photoUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png'],
      url: 'serverUrl'
    }
  );
  public cvUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlCV'
    }
  );
  public coverUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlCover'
    }
  );
  public passportUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlPassport'
    }
  );
  public driverLicenceUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlDrivingLicence'
    }
  );
  public intChildProtectionLicenceUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlIntChildProtectCertificate'
    }
  );
  public intDriverLicenceUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlIntDriverLicence'
    }
  );
  public healthInsuranceUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlHealthInsurance'
    }
  );
  public flightReceiptUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      url: 'serverUrlFlightReceipt'
    }
  );

  //  impostazione delle data
  private datePickerOptions: IAngularMyDpOptions = {};
  localBirthdayData: any;

  private localDataInterview: any;

  headers: any;

  timezones: any[];
  timePreferences: any[];
  public timeValid: boolean = false;

  public loading = false;
  actorApplicationInfo: any = {};

  constructor(public actorApplicationService: ActorApplicationService, private route: ActivatedRoute,
    private configService: ConfigService, private helper: HelperService, private userService: UserService, private notify: NotificationsService) {
      //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
    this.datePickerOptions.disableSince = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }
  }

  ngOnInit() {

    // prima configurazione per l'upload delle foto
    this.photoUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.photoUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.photoUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload del CV
    this.cvUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.cvUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.cvUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload del cover letter
    this.coverUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.coverUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.coverUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload del passaporto
    this.passportUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.passportUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.passportUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload della patente di guida
    this.driverLicenceUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.driverLicenceUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.drivingLicenceUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload del certificato di protezione minori
    this.intChildProtectionLicenceUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.intChildProtectionLicenceUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.intChildProtectionCertificateUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload della patente di guida internazionale
    this.intDriverLicenceUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.intDriverLicenceUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.intDriverLicenceUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload dell'assicurazione sanitaria
    this.healthInsuranceUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.healthInsuranceUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.healthInsuranceUploadFinished(item, response, status);
    };
    //  prima configurazione per l'upload del biglietto arereo
    this.flightReceiptUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.flightReceiptUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      this.flightReceiptUploadFinished(item, response, status);
    };

    this.addTimeZones();
    this.addCheckbox();

    this.subject = this.route.params.subscribe(params => {

      this.actorApplicationInfo = {};

      if (typeof params['idCurrentActor'] != "undefined") {
        this.getActorApplicationInfo(params['idCurrentActor']);
      }
    });
  }


  ngOnDestroy ()
  {
    this.subject.unsubscribe();
  }

  addTimeZones()
  {
    this.timezones = [];
    moment.tz.names().forEach(zonename => {
      this.timezones.push(
        {
          name: zonename,
          gmt: moment.tz(zonename).format('Z'),
          text: zonename + ' - (GMT:' + moment.tz(zonename).format('Z') + ')',
        }
      );
    });
  }

  addCheckbox ()
  {
    this.timePreferences = [
      {id:100, name:'09:00 - 12:00', checked: false},
      {id:200, name:'12:00 - 15:00', checked: false},
      {id:300, name:'15:00 - 18:00', checked: false}
    ];
  }
  checkboxChanged (index)
  {
    this.timePreferences[index].checked = !this.timePreferences[index].checked;

    if (this.timePreferences.filter(x => x.checked == true).length >= 1)
      this.timeValid = true;
    else
      this.timeValid = false;
  }

  submit()
  {
    if (!this.helper.isMyDateValid(this.localBirthdayData) || !this.helper.isMyDateValid(this.localDataInterview, false))
        return;

    if (!this.form.form.valid)
      return;

    if (this.actorApplicationInfo.dietary_requirements_yes_or_not == 'yes' && !this.actorApplicationInfo.dietary_requirements)
    {
      this.notify.error("Dietary Requirements", "is Required");
      return;
    }
    if (this.actorApplicationInfo.allergic_yes_or_not == 'yes' && !this.actorApplicationInfo.allergic_to)
    {
      this.notify.error("Allergies", "is Required");
      return;
    }

    var modelUpdate = JSON.parse(JSON.stringify(this.actorApplicationInfo));

    modelUpdate.preferred_interview_time = JSON.stringify(this.timePreferences);
    modelUpdate.nationality = this.createNationalities(this.actorApplicationInfo.nationality);

    delete modelUpdate.photoUrl;
    delete modelUpdate.cvUrl;
    delete modelUpdate.coverUrl;
    delete modelUpdate.passportUrl;
    delete modelUpdate.drivingLicenceUrl;
    delete modelUpdate.medical_condition_select;
    delete modelUpdate.intChildCertificateUrl;
    delete modelUpdate.intDriverLicenceUrl;
    delete modelUpdate.healthInsuranceUrl;
    delete modelUpdate.flightReceiptUrl;

    if (this.actorApplicationInfo.dietary_requirements_yes_or_not == 'no')
      modelUpdate.dietary_requirements = null;

    if (this.actorApplicationInfo.allergic_yes_or_not == 'no')
      modelUpdate.allergic_to = null;

    if (this.actorApplicationInfo.medical_condition_select == 'no')
      modelUpdate.medical_conditions = null;

    this.actorApplicationService.updateAllActorApplicationInfo(modelUpdate, () => {

      this.getActorApplicationInfo(modelUpdate.id);
    });
  }

  getActorApplicationInfo(actorApplicationId) {

    this.loading = true;

    this.actorApplicationService.currentActorApplicationId = actorApplicationId;

    this.actorApplicationService.getActorApplicationInfo(() => {

      this.actorApplicationInfo = this.actorApplicationService.currentActorApplicationInfo;

      //Setto l'url per il caricamento delle foto e gli header
      this.photoUploader.options.url = this.actorApplicationService.getFileUploadURL(this.actorApplicationInfo.uid);
      this.cvUploader.options.url = this.actorApplicationService.getCVUploadURL(this.actorApplicationInfo.uid);
      this.coverUploader.options.url = this.actorApplicationService.getCoverUploadURL(this.actorApplicationInfo.uid);
      this.driverLicenceUploader.options.url = this.actorApplicationService.getDrivingLicenceUploadURL(this.actorApplicationInfo.uid);
      this.passportUploader.options.url = this.actorApplicationService.getPassportUploadURL(this.actorApplicationInfo.uid);
      this.intChildProtectionLicenceUploader.options.url = this.actorApplicationService.getIntChildProtectCertificateURL(this.actorApplicationInfo.uid);
      this.intDriverLicenceUploader.options.url = this.actorApplicationService.getIntDriverLicenceURL(this.actorApplicationInfo.uid);
      this.healthInsuranceUploader.options.url = this.actorApplicationService.getHealthInsuranceURL(this.actorApplicationInfo.uid);
      this.flightReceiptUploader.options.url = this.actorApplicationService.getFlightReceiptURL(this.actorApplicationInfo.uid);

      this.headers = this.actorApplicationService.getFileUlopadHeader();

      if (this.actorApplicationInfo.date_of_birth != null)
        this.localBirthdayData = this.helper.initMyDataInputSingle(this.actorApplicationInfo.date_of_birth);

      //  Se esiste il path per i file aggiuntivi costruisco gli url
      if (this.actorApplicationInfo.photo_url)
        this.actorApplicationInfo.photoUrl = this.configService.blobBasePath + this.actorApplicationInfo.photo_url;

      if (this.actorApplicationInfo.cv_url)
        this.actorApplicationInfo.cvUrl = this.configService.blobBasePath + this.actorApplicationInfo.cv_url;

      if (this.actorApplicationInfo.cover_url)
        this.actorApplicationInfo.coverUrl = this.configService.blobBasePath + this.actorApplicationInfo.cover_url;

      if (this.actorApplicationInfo.passport_url)
        this.actorApplicationInfo.passportUrl = this.configService.blobBasePath + this.actorApplicationInfo.passport_url;

      if (this.actorApplicationInfo.driving_licence_url)
        this.actorApplicationInfo.drivingLicenceUrl = this.configService.blobBasePath + this.actorApplicationInfo.driving_licence_url;

      if (this.actorApplicationInfo.int_child_protection_certificate_url)
        this.actorApplicationInfo.intChildCertificateUrl = this.configService.blobBasePath + this.actorApplicationInfo.int_child_protection_certificate_url;

      if (this.actorApplicationInfo.int_driver_licence_url)
        this.actorApplicationInfo.intDriverLicenceUrl = this.configService.blobBasePath + this.actorApplicationInfo.int_driver_licence_url;

      if (this.actorApplicationInfo.health_insurance_url)
        this.actorApplicationInfo.healthInsuranceUrl = this.configService.blobBasePath + this.actorApplicationInfo.health_insurance_url;

      if (this.actorApplicationInfo.flight_receipt_url)
        this.actorApplicationInfo.flightReceiptUrl = this.configService.blobBasePath + this.actorApplicationInfo.flight_receipt_url;

      if (this.actorApplicationInfo.preferred_interview_time != null &&
          typeof this.actorApplicationInfo.preferred_interview_time != "undefined")
        this.timePreferences = JSON.parse(this.actorApplicationInfo.preferred_interview_time);

      if (this.actorApplicationInfo.interview_date != null)
        this.localDataInterview = this.helper.initMyDataInputSingle(this.actorApplicationInfo.interview_date);

      if (this.actorApplicationInfo.medical_conditions != null)
        this.actorApplicationInfo.medical_condition_select =  'yes';
        else
        this.actorApplicationInfo.medical_condition_select = 'no';

      this.loading = false;

    }, () => { this.loading = false; });

  }




  getActorApplicationRevision ()
  {
    this.loading = true;

      //  recupera le info della review
      this.actorApplicationService.getActorApplicationRevision(()=> {
        this.loading = false;
      }, () => { this.loading = false;});

  }


  private createNationalities (nationality)
  {
    if (!nationality)
      return undefined;

    if (Array.isArray(nationality))
      return nationality;

    var nationalArray = [];

    let values = nationality.split(',');
    values.forEach(n => {

      var value = Nationalities.find(x => x.value == n);
      if (typeof value != "undefined")
        nationalArray.push(value);
    });

    return nationalArray
  }


  onDateBirthdayChanged(event: IMyDateModel) {

    this.localBirthdayData = event;

    if (event.singleDate.jsDate != null)
      this.actorApplicationInfo.date_of_birth = this.helper.formatISODate(event);
    else
      this.actorApplicationInfo.date_of_birth = null;
  }



  onDateInterviewChanged(event: IMyDateModel) {

    this.localDataInterview = event;

    if (event.singleDate.jsDate != null)
      this.actorApplicationInfo.interview_date = this.helper.formatISODate(event);
    else
      this.actorApplicationInfo.interview_date = null;
  }



  public photoUploadFinished(item:any, response:any, status:any) {

    if (item != null) {
      if (status === 200) {
        this.actorApplicationInfo.photoUrl = this.configService.blobBasePath + response;
        this.actorApplicationInfo.photo_url = response;
      }
      this.actorApplicationService.photoFileResponse(item, response, status, this.actorApplicationInfo.uid);
    }

  }

  public photoRemoved() {

    this.photoUploader.isUploading = true;
    this.actorApplicationService.deleteFile(this.actorApplicationInfo.uid, () => {

      this.actorApplicationInfo.photoUrl = undefined;
      this.actorApplicationInfo.photo_url = null;

      this.photoUploader.isUploading = false;
    }, () => { this.photoUploader.isUploading = false;})

  }

  public cvUploadFinished(item:any, response:any, status:any) {

    if (item != null) {
      if (status === 200) {
        this.actorApplicationInfo.cvUrl = this.configService.blobBasePath + response;
        this.actorApplicationInfo.cv_url = response;
      }
      this.actorApplicationService.cvFileResponse(item, response, status, this.actorApplicationInfo.uid);
    }

  }

  public cvRemoved() {

    this.cvUploader.isUploading = true;
    this.actorApplicationService.deleteCv(this.actorApplicationInfo.uid, () => {

      this.actorApplicationInfo.cvUrl = undefined;
      this.actorApplicationInfo.cv_url = null;

      this.cvUploader.isUploading = false;
    }, () => { this.cvUploader.isUploading = false;})
  }

  public coverUploadFinished(item:any, response:any, status:any) {

    if (item != null) {
      if (status === 200) {
        this.actorApplicationInfo.coverUrl = this.configService.blobBasePath + response;
        this.actorApplicationInfo.cover_url = response;
      }
      this.actorApplicationService.coverFileResponse(item, response, status, this.actorApplicationInfo.uid);
    }

  }

  public coverRemoved() {

    this.coverUploader.isUploading = true;

    this.actorApplicationService.deleteCover(this.actorApplicationInfo.uid, () => {

      this.actorApplicationInfo.coverUrl = undefined;
      this.actorApplicationInfo.cover_url = null;
      this.coverUploader.isUploading = false;

    }, () => { this.coverUploader.isUploading = false;})
  }

  public passportUploadFinished(item:any, response:any, status:any) {

    if (item != null) {
      if (status === 200) {
        this.actorApplicationInfo.passportUrl = this.configService.blobBasePath + response;
        this.actorApplicationInfo.passport_url = response;
      }
      this.actorApplicationService.passportFileResponse(item, response, status, this.actorApplicationInfo.uid);
    }

  }

  public passportRemoved() {

    this.passportUploader.isUploading = true;

    this.actorApplicationService.deletePassport(this.actorApplicationInfo.uid, () => {

      this.actorApplicationInfo.passportUrl = undefined;
      this.actorApplicationInfo.passport_url = null;
      this.passportUploader.isUploading = false;

    }, () => { this.passportUploader.isUploading = false;})
  }


  public drivingLicenceUploadFinished(item:any, response:any, status:any) {

    if (item != null) {
      if (status === 200) {
        this.actorApplicationInfo.drivingLicenceUrl = this.configService.blobBasePath + response;
        this.actorApplicationInfo.driving_licence_url = response;
      }
      this.actorApplicationService.drivingLicenceFileResponse(item, response, status, this.actorApplicationInfo.uid);
    }

  }


  public drivingLicenceRemoved() {

    this.driverLicenceUploader.isUploading = true;

    this.actorApplicationService.deleteDrivingLicence(this.actorApplicationInfo.uid, () => {

      this.actorApplicationInfo.drivingLicenceUrl = undefined;
      this.actorApplicationInfo.driving_licence_url = null;
      this.driverLicenceUploader.isUploading = false;

    }, () => { this.driverLicenceUploader.isUploading = false;})
  }



  public intChildProtectionCertificateUploadFinished(item:any, response:any, status:any) {

    if (item != null) {
      if (status === 200) {
        this.actorApplicationInfo.intChildCertificateUrl = this.configService.blobBasePath + response;
        this.actorApplicationInfo.int_child_protection_certificate_url = response;
      }
      this.actorApplicationService.intChildProtectCertificateFileResponse(item, response, status, this.actorApplicationInfo.uid);
    }

  }

  intChildProtectCertificateRemove() {

    this.intChildProtectionLicenceUploader.isUploading = true;

    this.actorApplicationService.deleteChildProtectCertificate(this.actorApplicationInfo.uid, () => {

      this.actorApplicationInfo.intChildCertificateUrl = undefined;
      this.actorApplicationInfo.int_child_protection_certificate_url = null;
      this.intChildProtectionLicenceUploader.isUploading = false;

    }, () => { this.intChildProtectionLicenceUploader.isUploading = false;})
  }


  public intDriverLicenceUploadFinished(item:any, response:any, status:any) {

    if (item != null) {
      if (status === 200) {
        this.actorApplicationInfo.intDriverLicenceUrl = this.configService.blobBasePath + response;
        this.actorApplicationInfo.int_driver_licence_url = response;
      }
      this.actorApplicationService.intDriverLicenceFileResponse(item, response, status, this.actorApplicationInfo.uid);
    }

  }

  intDriverLicenceRemove() {

    this.intDriverLicenceUploader.isUploading = true;

    this.actorApplicationService.deleteIntDriverLicence(this.actorApplicationInfo.uid, () => {

      this.actorApplicationInfo.intDriverLicenceUrl = undefined;
      this.actorApplicationInfo.int_driver_licence_url = null;
      this.intDriverLicenceUploader.isUploading = false;

    }, () => { this.intDriverLicenceUploader.isUploading = false;})
  }



  public healthInsuranceUploadFinished(item:any, response:any, status:any) {

    if (item != null) {
      if (status === 200) {
        this.actorApplicationInfo.healthInsuranceUrl = this.configService.blobBasePath + response;
        this.actorApplicationInfo.health_insurance_url = response;
      }
      this.actorApplicationService.healthInsuranceFileResponse(item, response, status, this.actorApplicationInfo.uid);
    }

  }

  healthInsuranceRemove() {

    this.healthInsuranceUploader.isUploading = true;

    this.actorApplicationService.deleteHealthInsurance(this.actorApplicationInfo.uid, () => {

      this.actorApplicationInfo.healthInsuranceUrl = undefined;
      this.actorApplicationInfo.health_insurance_url = null;
      this.healthInsuranceUploader.isUploading = false;

    }, () => { this.healthInsuranceUploader.isUploading = false;})
  }



  public flightReceiptUploadFinished(item:any, response:any, status:any) {

    if (item != null) {
      if (status === 200) {
        this.actorApplicationInfo.flightReceiptUrl = this.configService.blobBasePath + response;
        this.actorApplicationInfo.flight_receipt_url = response;
      }
      this.actorApplicationService.flightReceiptFileResponse(item, response, status, this.actorApplicationInfo.uid);
    }

  }

  flightReceiptRemove () {

    this.flightReceiptUploader.isUploading = true;

    this.actorApplicationService.deleteFlightReceipt(this.actorApplicationInfo.uid, () => {

      this.actorApplicationInfo.flightReceiptUrl = undefined;
      this.actorApplicationInfo.flight_receipt_url = null;
      this.flightReceiptUploader.isUploading = false;

    }, () => { this.flightReceiptUploader.isUploading = false;})
  }



  public deleteAuditionPreferences() {

    this.actorApplicationInfo.audition_accepted = null;
    this.actorApplicationInfo.audition_rejected = null;

    this.submit();
  }

  public deleteInterviewPreferences() {

    this.actorApplicationInfo.interview_accepted = null;
    this.actorApplicationInfo.interview_rejected = null;

    this.submit();
  }

  public deleteSelftapePreferences()
  {
    this.actorApplicationInfo.self_tape_accepted = null;
    this.actorApplicationInfo.self_tape_rejected = null;

    this.submit();
  }

  public deleteProposalPreferences()
  {
    this.actorApplicationInfo.proposal_accepted = null;
    this.actorApplicationInfo.proposal_rejected = null;

    this.submit();
  }


  inviaMailTo(event: any, type) {

    event.target.disabled = true;

    this.actorApplicationService.sendEmailType(this.actorApplicationService.currentActorApplicationId.toString(), type, () => {
      event.target.innerHTML = '<i class="fa fa-check-square-o aria-hidden="true"></i>&nbsp;Email inviata correttamente';
      this.notify.success("Email", "The email has been sent correctly");
    }, () => {
      event.target.innerHTML = '<i class="fa fa-exclamation-triangle aria-hidden="true"></i>&nbsp;Errore'
    });
  }

  getActorApplicationService() {
    return this.actorApplicationService;
  }

  getUserService () {
    return this.userService;
  }

  getConfig () {
    return this.configService;
  }

  getHelper () {
    return this.helper;
  }

  getLocalData () {
    return this.localDataInterview
  }

  getBirthdayLocalData() {
    return this.localBirthdayData;
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

  // getEmbedService(){
  //   return this.embedService;
  // }

}
