<header-app></header-app>
<!-- BEGIN CONTAINER -->
<div class="page-container">
  <sidebar-app></sidebar-app>
  <!-- BEGIN CONTENT -->
  <div class="page-content-wrapper">

    <app-check-platform-compatibility></app-check-platform-compatibility>

    <router-outlet></router-outlet>

  </div>
  <!-- END CONTENT -->
</div>
<!-- END CONTAINER -->

<simple-notifications [options]="notificationOptions"></simple-notifications>

<!-- <hotjar [tracking-id]="hotjarTrackingId" version="6"></hotjar> -->
