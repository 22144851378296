<alert type="warning" *ngIf="loadingData">
  <strong>Loading</strong> ...
</alert>

<ngx-datatable *ngIf="!loadingData"
class="striped"
[rows]="rows"
[columnMode]="'force'"
[headerHeight]="50"
[footerHeight]="50"
[rowClass]="getRowClass"
[rowHeight]="'auto'"
[selected]="selected"
[selectionType]="'single'"
(activate)="onActivate($event)"
(select)='onSelect($event)'
[rowClass]="getRowClass"
[scrollbarH]="true"
[sorts]="[{prop: 'date_payment', dir: 'desc'}]"
>
<ngx-datatable-column name="Date"  prop="date_payment" [resizeable]="false">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value">{{ value }}</span><br>
    <small><i *ngIf="row.verified_transaction" class="fa fa-check-circle" aria-hidden="true" style="color:green"></i> {{ row.created_at_parsed | date }}</small>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="From"  prop="out_holder_name" [resizeable]="false">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value"><i class="fa fa-{{row.out_holder_icon}}"></i> {{ value }} <i *ngIf="row.out_holder_deleted" class="fa fa-trash font-red-haze" aria-hidden="true"></i></span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Out"  prop="out_amount" [resizeable]="false">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span class="font-red-haze" *ngIf="value">{{ (value | currency:'EUR':'symbol':'1.2-2':'it') || "-" }}</span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="In"  prop="in_amount" [resizeable]="false">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span class="font-green-jungle" *ngIf="value">{{ (value | currency:'EUR':'symbol':'1.2-2':'it') || "-" }}</span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="To"  prop="in_holder_name" [resizeable]="false">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value"><i class="fa fa-{{row.in_holder_icon}}"></i> {{ value }} <i *ngIf="row.in_holder_deleted" class="fa fa-trash font-red-haze" aria-hidden="true"></i></span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Registered via"  prop="registered_via_description" [resizeable]="false">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <i *ngIf="row.fiscal_document" class="fa fa-file-text" aria-hidden="true"></i>
    <span *ngIf="value">{{ value }}</span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Causal"  prop="causal_description" [resizeable]="false"></ngx-datatable-column>
</ngx-datatable>



<div class="row">
  <div class="pull-right">
    <button [appCheckPermission]="'prima_nota_export'" [disabled]="getPrimanotaService().isOngoingRequest() || rows?.length <= 0" type="button" class="btn green" (click)="exportToExcel()"><i class="fa fa-share"></i> Export to Excel</button>
  </div>
</div>


<!-- BEGIN: MODAL LOADING EXPORT-->
<div [config]="{ backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal #staticModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->






<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
              <app-primanota-add-form [showCloseButton]="false" [holderType]="holderType" [holderId]="holderId" [holderName]="holderName" [primaNotaModel]="selectedRow" [showAddInOutButton]="false" (modelUpdated)="modelUpdatedInModal($event)"></app-primanota-add-form>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
