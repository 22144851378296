<ngx-datatable class="striped" [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0"
  [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'" (select)='onSelect($event)'
  [sorts]="[{prop: 'start_date', dir: 'asc'}]">
  <ngx-datatable-column name="Vehicle" prop="vehicle_data" [flexGrow]="2" [resizeable]="false" [draggable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value"> {{value.brand_model}} {{value.brand_name}} {{value.plate_number}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Date Start" prop="start_date" [flexGrow]="2" [resizeable]="false" [draggable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ row.start_date_parsed | date }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Date End" prop="end_date" [flexGrow]="2" [resizeable]="false" [draggable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ row.end_date_parsed | date }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Material" prop="material_type" [flexGrow]="3" [resizeable]="false" [draggable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      {{getConfigService().getVehicleMaterialAssignmentTypeSelect(row.material_type)}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Notes" prop="annotation" [flexGrow]="4" [resizeable]="false" [draggable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <p class="line-breaker">{{row.annotation}}</p>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
