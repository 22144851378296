import { Injectable } from '@angular/core';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { ConfigService } from './config.service';
import { parseISO, startOfDay, endOfDay, subDays, addDays, endOfMonth, addWeeks, addMonths, subWeeks, subMonths, startOfWeek, startOfMonth, endOfWeek, isEqual, differenceInYears, toDate, format, isValid } from 'date-fns';
import { UserService } from './user.service';
import CodiceFiscale from 'codice-fiscale-js';

type CalendarPeriod = 'day' | 'week' | 'month';

@Injectable()
export class HelperService {


  constructor(private configService: ConfigService, private userService: UserService) { }


  /*
    ____    _  _____ _____   _____ _   _ _   _  ____ _____ ___ ___  _   _ ____
   |  _ \  / \|_   _| ____| |  ___| | | | \ | |/ ___|_   _|_ _/ _ \| \ | / ___|
   | | | |/ _ \ | | |  _|   | |_  | | | |  \| | |     | |  | | | | |  \| \___ \
   | |_| / ___ \| | | |___  |  _| | |_| | |\  | |___  | |  | | |_| | |\  |___) |
   |____/_/   \_\_| |_____| |_|    \___/|_| \_|\____| |_| |___\___/|_| \_|____/
  */

  //	Lingua del browser
  // public locale: string = navigator.language.split('-')[0];
  public locale: string = navigator.language.replace("-IT", '');
  // public locale: string = 'it';

  //	Array con le opzioni del datepicker
  private _datePickerOptions: IAngularMyDpOptions = {
    disableSince: { year: 0, month: 0, day: 0 },
  };
  //	Restituisce un instanza diversa per ogni richiesta dell'array del datepicker
  public get datePickerOptions(): IAngularMyDpOptions {

    let copydatepickerOption = JSON.parse(JSON.stringify(this._datePickerOptions));
    if (this.locale.toLowerCase() == 'en-gb')
      copydatepickerOption.dateFormat = 'dd/mm/yyyy';
    else if (this.locale.toLowerCase() == 'en-us')
      copydatepickerOption.dateFormat = 'mm/dd/yyyy';

    return copydatepickerOption;
  }

  //  Setta una data in IMyDateModel per inizializzare il componente date input picker
  public initMyDataInputSingle(date: any): IMyDateModel {
    return { isRange: false, singleDate: { jsDate: new Date(date) } };
  }

  //  valida le date di un input picker
  //    con parametro di validazione required se deve per forza essere valido
  public isMyDateValid(dateObj: IMyDateModel, required = true): boolean {

    //   se la data è required
    if (required) {
      if ((dateObj == null || typeof dateObj == "undefined") && required)
        return false;

      if (dateObj != null && typeof dateObj != "undefined") {
        if (dateObj.isRange == false) {
          if (dateObj.singleDate.jsDate == null || isNaN(dateObj.singleDate.jsDate.getDate()))
            return false;

          return true;
        }
        else {
          if (dateObj.dateRange.beginJsDate == null || dateObj.dateRange.endJsDate == null)
            return false;
        }
      }
    }

    //   se la data non è required
    if (dateObj != null && typeof dateObj != "undefined") {
      //  se invece la date è prensente
      if (dateObj.isRange == false) {
        if (dateObj.singleDate.jsDate == null)
          return true;

        if (isNaN(dateObj.singleDate.jsDate.getDate()))
          return false;

        return true;
      }
      else {
        if (dateObj.dateRange.beginJsDate == null || dateObj.dateRange.endJsDate == null)
          return false;
      }
    }

    return true;
  }

  public formatISODate(dateObj: IMyDateModel): string {
    if (dateObj.isRange == false) {
      return format(dateObj.singleDate.jsDate, "yyyy-MM-dd");
    }

    return '';
  }



  public static parseDate(date: any) {

    if (date == null) {
      console.error("Date parse error: NULL value");
      return new Date();
    }

    if (typeof date == "string") {
      return parseISO(date);
    }
    else if (typeof date == "number") {
      return toDate(date);
    }
    else if (typeof date == "object" && date instanceof Date) {
      return toDate(date);
    }
    else {
      console.error("Date parse error: " + date.toString());
    }
  }



  //	Età calcolata a partire da una data di nascita
  public calculateAge(birthday: any): string {
    if (birthday) {
      var dataToday = HelperService.parseDate(new Date());
      var dataBirthday = HelperService.parseDate(new Date(birthday));
      var diff = differenceInYears(dataToday, dataBirthday);

      return diff.toString();
    }

    return '';
  }

  //  Calcolo l'età a partire dalla data della nascita
  public calculateExpiration(expirationData): boolean {
    if (expirationData) {
      var dataToday = new Date().valueOf();
      var expiration_date = parseISO(expirationData).valueOf();

      if ((expiration_date - dataToday) > 0)
        return true;
      else
        return false;
    }

    return false;
  }



  public addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
    return {
      day: addDays,
      week: addWeeks,
      month: addMonths
    }[period](date, amount);
  }

  public subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
    return {
      day: subDays,
      week: subWeeks,
      month: subMonths
    }[period](date, amount);
  }

  public startOfPeriod(period: CalendarPeriod, date: Date): Date {
    return {
      day: startOfDay,
      week: startOfWeek,
      month: startOfMonth
    }[period](date);
  }

  public endOfPeriod(period: CalendarPeriod, date: Date): Date {
    return {
      day: endOfDay,
      week: endOfWeek,
      month: endOfMonth
    }[period](date);
  }


  //	tagUsed sono i tag che vengono salvati
  //	tagConfig sono i tag dalla configurazione del sistema
  public stringToArrayOfProductTags(tagUsed: any, tagConfig: any) {

    var tags: any[] = [];

    tagConfig.forEach(tag => {

      var objTag: any = {};
      objTag.key = tag.key;
      objTag.value = tag.value;

      if (tagUsed.includes(tag.key))
        objTag.enabled = true;
      else
        objTag.enabled = false;

      tags.push(objTag);
    });

    return tags;
  }



  public arrayOfProductTagsToString(arrayTags: any) {

    var objTag: any[] = [];

    arrayTags.forEach(tag => {

      if (tag.enabled)
        objTag.push(tag.key);
    });

    return objTag.toString();
  }


  //  Calcolo l'età a partire dalla data della nascita
  public calculateTourValidDate(dateStart, dateEnd): boolean {
    if (dateStart && dateEnd) {
      var date_end = parseISO(dateEnd).valueOf();

      if ((date_end - dateStart) > 0)
        return true;
      else
        return false;
    }

    return false;
  }


  public isADateTourDayOff(tourType): boolean {
    return tourType === 1;
  }

  public isADateTourSwap(tourType): boolean {
    return tourType === 3;
  }

  public isADateTourStandard(tourType): boolean {
    return tourType === 2;
  }
  public isAnAccomodation(tourType): boolean {
    return tourType === 5;
  }


  /*
   __      __     _      _____ _____       _______ ______   ______ _    _ _   _  _____ _______ _____ ____  _   _  _____
   \ \    / /\   | |    |_   _|  __ \   /\|__   __|  ____| |  ____| |  | | \ | |/ ____|__   __|_   _/ __ \| \ | |/ ____|
    \ \  / /  \  | |      | | | |  | | /  \  | |  | |__    | |__  | |  | |  \| | |       | |    | || |  | |  \| | (___
     \ \/ / /\ \ | |      | | | |  | |/ /\ \ | |  |  __|   |  __| | |  | | . ` | |       | |    | || |  | | . ` |\___ \
    \  / ____ \| |____ _| |_| |__| / ____ \| |  | |____  | |    | |__| | |\  | |____   | |   _| || |__| | |\  |____) |
     \/_/    \_\______|_____|_____/_/    \_\_|  |______| |_|     \____/|_| \_|\_____|  |_|  |_____\____/|_| \_|_____/
  */

  public validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  public validateUrl(url) {
    var re = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})$/;
    return re.test(url);
  }

  public validatePhone(phone) {
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,6}$/im;
    return re.test(phone);
  }

  public validTaxId(person) {

    if (person.tax_id == null || typeof person.tax_id == "undefined")
      return true;

    if (!CodiceFiscale.check(person.tax_id))
      return false;

    //  controllo con le info che possiedo della persona
    var checkValid = true;
    var cf = new CodiceFiscale(person.tax_id).toJSON();
    if (person.birthday != null && typeof person.birthday != "undefined") {
      checkValid = isEqual(parseISO(person.birthday), parseISO(cf.year + '-' + cf.month + '-' + cf.day));
      return checkValid;
    }

    if (person.gender != null && typeof person.gender != "undefined") {
      checkValid = person.gender.toUpperCase() == cf.gender;
      return checkValid;
    }

    if (person.birthplace != null && typeof person.birthplace != "undefined")
      if (person.birthplace.length > 0) {
        checkValid = person.birthplace.toUpperCase() == cf.birthplace;
        return checkValid;
      }

    return checkValid;
  }


  // parsa una stringa per essere cercata
  public transformSearchString(s) {

    if (s == null || typeof s == "undefined")
      return "";

    s = s.replace(/["']/g, " ");
    return encodeURI(s.trim());
  }





  getHolderNameFromType(holderType, holderData) {

    if (holderData) {

      if (holderData.holder_description) {
        return holderData.holder_description;
      }

      if (holderType.indexOf("Person") > 0) {
        return holderData.surname + " " + holderData.first_name;
      }

      if (holderType.indexOf("Enterprise") > 0) {
        return holderData.denomination;
      }

      if (holderType.indexOf("ActorGroup") > 0) {
        return holderData.group_name;
      }

      if (holderType.indexOf("TheatrinoTourDate") > 0) {
        return "Tour date " + holderData.date;
      }

      if (holderType.indexOf("Camp") > 0) {
        return this.configService.getCampTypeValueByKey(holderData.type) + " " + holderData.camp_code;
      }

      if (holderType.indexOf("CourseSchedule") > 0) {
        return holderData.title;
      }

      if (holderType.indexOf("Vehicle") > 0) {
        return holderData.vehicle_name + " " + holderData.plate_number;
      }

      if (holderType.indexOf("CourseSignup") > 0) {
        return holderData.partecipante_cognome + " " + holderData.partecipante_nome;
      }

    }

    return null;
  }

  getHolderFirstNameFromType(holderType, holderData) {

    if (holderData) {

      if (holderType.indexOf("Person") > 0) {
        return holderData.first_name;
      }

      if (holderType.indexOf("Enterprise") > 0) {
        return holderData.denomination;
      }

      if (holderType.indexOf("ActorGroup") > 0) {
        return holderData.group_name;
      }

      if (holderType.indexOf("TheatrinoTourDate") > 0) {
        return holderData.date;
      }

      if (holderType.indexOf("Camp") > 0) {
        return this.configService.getCampTypeValueByKey(holderData.type);
      }

      if (holderType.indexOf("CourseSchedule") > 0) {
        return holderData.title;
      }

      if (holderType.indexOf("Vehicle") > 0) {
        return holderData.vehicle_name;
      }

      if (holderType.indexOf("CourseSignup") > 0) {
        return holderData.partecipante_nome;
      }

    }

    return null;
  }

  getHolderLastNameFromType(holderType, holderData) {

    if (holderData) {

      if (holderType.indexOf("Person") > 0) {
        return holderData.surname;
      }

      if (holderType.indexOf("Camp") > 0) {
        return holderData.camp_code;
      }

      if (holderType.indexOf("Vehicle") > 0) {
        return holderData.plate_number;
      }

      if (holderType.indexOf("CourseSignup") > 0) {
        return holderData.partecipante_cognome;
      }

    }

    return null;
  }

  getHolderIconFromType(holderType) {

    if (holderType.indexOf("Person") > 0) {
      return "user"
    }

    if (holderType.indexOf("Enterprise") > 0) {
      return "industry"
    }

    if (holderType.indexOf("ActorGroup") > 0) {
      return "group"
    }

    if (holderType.indexOf("TheatrinoTourDate") > 0) {
      return "calendar"
    }

    if (holderType.indexOf("Camp") > 0) {
      return "free-code-camp"
    }

    if (holderType.indexOf("CourseSchedule") > 0) {
      return "university"
    }

    if (holderType.indexOf("Vehicle") > 0) {
      return "car"
    }

    if (holderType.indexOf("CourseSignup") > 0) {
      return "user"
    }

    return "";
  }

  getHolderUrlPathFromType(holderType, holderData) {

    if (holderType.indexOf("Person") > 0) {
      return "person"
    }

    if (holderType.indexOf("Enterprise") > 0) {
      return "enterprise"
    }

    if (holderType.indexOf("ActorGroup") > 0) {
      return "actorgroup"
    }

    if (holderType.indexOf("TheatrinoTourDate") > 0) {
      return "theatrino/" + holderData.theatrino_id + "/tour"
    }

    if (holderType.indexOf("Camp") > 0) {
      return "camp"
    }

    if (holderType.indexOf("CourseSchedule") > 0) {
      return "courseschedule"
    }

    if (holderType.indexOf("Vehicle") > 0) {
      return "vehicle"
    }

    if (holderType.indexOf("CourseSignup") > 0) {
      return "courseschedule/edit/" + holderData.holdercourse_schedule_id;
    }

    return "";
  }

  color_palette = [
    {
      primary: '#ffb147',
      secondary: '#ffe3bc',
      name: 'orange'
    },
    {
      primary: '#26C281',
      secondary: '#92e0c0',
      name: 'green-jungle'
    },
    {
      primary: '#F4D03F',
      secondary: '#F8E28B',
      name: 'yellow-saffron'
    },
    {
      primary: '#0da500',
      secondary: '#b7f4b2',
      name: 'blue-soft'
    },
    {
      primary: '#f44336',
      secondary: '#f9a19a',
      name: 'red'
    },
    {
      primary: '#8E44AD',
      secondary: '#bb8ecd',
      name: 'purple'
    },
    {
      primary: '#E87E04',
      secondary: '#f1b168',
      name: 'yellow-gold'
    },
    {
      primary: '#32C5D2',
      secondary: '#84dce4',
      name: 'green'
    },
    {
      primary: '#C8D046',
      secondary: '#e3e7a2',
      name: 'yellow-soft'
    },
    {
      primary: '#8877A9',
      secondary: '#c3bbd4',
      name: 'purple-soft'
    },
  ]

  color_palette_assigned_in_session = {};

  getRandomColorForCalendarEvent(seed) {

    let indexColor = this.color_palette_assigned_in_session["calendar_event_color_seed_" + seed];

    if (typeof indexColor == "undefined") {

      let index = this.color_palette_assigned_in_session["calendar_event_color_last_index"];

      if (typeof index == "undefined") {
        index = -1;
      }

      index = (index + 1) % this.color_palette.length;
      indexColor = index;
      this.color_palette_assigned_in_session["calendar_event_color_last_index"] = index;
      this.color_palette_assigned_in_session["calendar_event_color_seed_" + seed] = indexColor;
    }

    return this.color_palette[indexColor];
  }

}
