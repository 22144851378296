<h3 class="">Search</h3>
<hr>
<form *ngIf="!advancedSearchShow" action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="input-group input-group-lg input-icon right" [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }">
        <i class="fa fa-times-circle" aria-hidden="true" (click)="model.simpleSearchKeyword = '';"></i>
        <input type="text" name="simpleSearchKeywordText" #simpleSearchKeywordText="ngModel" [(ngModel)]="model.simpleSearchKeyword"
          (keydown)="fieldChange($event);" required minlength="4" class="form-control" placeholder="Search for vehicle name...">
        <span class="input-group-btn">
          <button class="btn green" [disabled]="ongoingRequest" type="submit">Search</button>
        </span>
      </div>
      <small [ngClass]="{ 'has-error': f.submitted && !simpleSearchKeywordText.valid }">
        <span class="help-block">Scrivi una parola per cercare (min 3 caratteri), cerca nel Name, Brand, Plate number</span>
      </small>
    </div>
    <div class="col-md-6">
      <button type="button" [disabled]="ongoingRequest" (click)="showAdvancedSearch()" class="btn default btn-lg">Advanced Search
        <i class="fa fa-search"></i>
      </button>
      <button type="button" [disabled]="ongoingRequest" (click)="getAllResources(f);" class="btn btn-lg default">Get All <i class="fa fa-search"></i></button>
    </div>
  </div>
</form>


<form *ngIf="advancedSearchShow" action="#" name="form2" (ngSubmit)="f2.form.valid && submitSearch()" #f2="ngForm" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">Name </label>
        <input type="text" name="vehicle_name" [(ngModel)]="model.vehicle_name" #vehicle_name="ngModel" class="form-control">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label class="control-label">Vehicle Active</label>
        <div class="mt-checkbox-inline">
          <label class="mt-checkbox mt-checkbox-outline">
            <input type="checkbox" name="vehicle_active" [(ngModel)]="model.vehicle_active" [checked]="1" (ngModelChange)="fieldChange($event)"> &nbsp;
            <span></span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="control-label">Vehicle type</label>
        <select class="form-control" name="vehicle_type" [(ngModel)]="model.vehicle_type" #vehicle_type="ngModel">
          <option *ngFor="let v of getConfigService().vehicleTypeSelect" [value]="v.key">{{v.value}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label class="control-label">Plate Number </label>
        <input type="text" name="plate_number" [(ngModel)]="model.plate_number" #plate_number="ngModel" class="form-control" placeholder="AB123AA">
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="control-label">Brand name</label>
        <input class="form-control" type="text" name="brand_name" [(ngModel)]="model.brand_name" placeholder="Fiat">
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="control-label">Brand Model</label>
        <input class="form-control" type="text" name="brand_model" [(ngModel)]="model.brand_model" placeholder="Punto">
      </div>
    </div>
  </div>

  <div class="form-actions right">
    <button type="button" class="btn" (click)="hideAdvancedSearch()">Close</button>
    <button type="button" class="btn default" (click)="clearAdvancedSearch()">Clear
        <i class="fa fa-eraser" aria-hidden="true"></i>
    </button>
    <button type="submit" [disabled]="ongoingRequest" class="btn green">Search
      <i class="fa fa-search"></i>
    </button>
  </div>
</form>
