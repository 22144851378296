<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">
    <h3 class="form-section">Info Subscriber</h3>

    <div class="row">
      <div class="col-md-3">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localRegistrationDate)) }">
          <label class="control-label">Data di Registrazione
            <span class="required">*</span>
          </label>
          <div class="input-group">
            <!-- input box -->
            <input angular-mydatepicker #dp_registration_date="angular-mydatepicker" name="registration_date"
              class="form-control" [locale]="getHelper().locale" [options]="getDatePickerOptions()"
              [ngModel]="localRegistrationDate" (dateChanged)="onDateRegistrationChanged($event)" required />
            <!-- clear date button -->
            <span class="input-group-btn">
              <button type="button" *ngIf="model?.registration_date" class="btn default"
                (click)="dp_registration_date.clearDate()"><i class="fa fa-close"></i></button>
            </span>
            <!-- toggle calendar button -->
            <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_registration_date.toggleCalendar()"><i
                  class="fa fa-calendar-o"></i></button>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-3 read-only">
        <label class="control-label">Pagato online</label>
        <p>
          <i *ngIf="model.paid_online" class="fa fa-check" aria-hidden="true"></i>
          <i *ngIf="!model.paid_online" class="fa fa-times" aria-hidden="true"></i>
        </p>
      </div>
      <div class="col-md-3">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || school_type.dirty || school_type.touched) && !school_type.valid }">
          <label class="control-label">School Type
            <span class="required">*</span>
          </label>
          <select class="form-control" name="school_type" [(ngModel)]="model.school_type" #school_type="ngModel"
            required>
            <option *ngFor="let t of getConfigService().diaryCampSubscriberSchoolTypeSelect" [value]="t.key">{{t.value}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || class_type.dirty || class_type.touched) && !class_type.valid }">
          <label class="control-label">Class Type
            <span class="required">*</span>
          </label>
          <select class="form-control" name="class_type" [(ngModel)]="model.class_type" #class_type="ngModel" required>
            <option *ngFor="let st of getConfigService().diaryCampSubscriberSchoolTypeSubSelect[model.school_type]"
              [value]="st.key">{{st.value}}</option>
          </select>
        </div>
      </div>
    </div>

    <!-- Sezione dedicata alle settimane -->
    <div class="row">
      <div class="col-md-6 form-group" [ngClass]="{ 'has-error': f.submitted && !weekValid}">
        <label class="control-label">Weeks <span class="required">*</span></label>
        <div class="row">
          <div class="col-md-4">
            <div *ngIf="campWeeks >= 1 || model.camp_week.length > 0" class="mt-checkbox-inline">
              <label class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="week_01" #week_01 [(ngModel)]="model.week_01"
                  (click)="weekChanged(1, week_01.checked)">Prima settimana
                <span></span>
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mt-checkbox-inline">
              <label *ngIf="campWeeks >= 2 || model.camp_week.length > 1" class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="week_02" #week_02 [(ngModel)]="model.week_02"
                  (click)="weekChanged(2, week_02.checked)">Seconda settimana
                <span></span>
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mt-checkbox-inline">
              <label *ngIf="campWeeks >= 3 || model.camp_week.length > 2" class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="week_03" #week_03 [(ngModel)]="model.week_03"
                  (click)="weekChanged(3, week_03.checked)">Terza settimana
                <span></span>
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mt-checkbox-inline">
              <label *ngIf="campWeeks >= 4 || model.camp_week.length > 3" class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="week_04" #week_04 [(ngModel)]="model.week_04"
                  (click)="weekChanged(4, week_04.checked)">Quarta settimana
                <span></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Sezione dedicata alle settimane dei tutor -->
      <div class="col-md-6 form-group">
        <label class="control-label">Host weeks </label>
        <div class="row">
          <div class="col-md-4">
            <div *ngIf="campWeeks >= 1" class="mt-checkbox-inline">
              <label class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="tutor_week_01" #tutor_week_01 [(ngModel)]="model.tutor_week_01"
                  (click)="weekTutorChanged(1, tutor_week_01.checked)">Prima settimana
                <span></span>
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div *ngIf="campWeeks >= 2" class="mt-checkbox-inline">
              <label class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="tutor_week_02" #tutor_week_02 [(ngModel)]="model.tutor_week_02"
                  (click)="weekTutorChanged(2, tutor_week_02.checked)">Seconda settimana
                <span></span>
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div *ngIf="campWeeks >= 3" class="mt-checkbox-inline">
              <label class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="tutor_week_03" #tutor_week_03 [(ngModel)]="model.tutor_week_03"
                  (click)="weekTutorChanged(3, tutor_week_03.checked)">Terza settimana
                <span></span>
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mt-checkbox-inline">
              <label *ngIf="campWeeks >= 4" class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="tutor_week_04" #tutor_week_04 [(ngModel)]="model.tutor_week_04"
                  (click)="weekTutorChanged(4, tutor_week_04.checked)">Quarta settimana
                <span></span>
              </label>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-md-6">
        <div class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || authorization_personal_data.dirty || authorization_personal_data.touched) && !authorization_personal_data.valid }">
          <label class="control-label">Il sottoscritto dichiara di accettare quanto previsto dal D.Lgs196/03 Codice in
            materia di protezione dei dati
            personali. <span class="required">*</span>
          </label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="authorization_personal_data" #authorization_personal_data="ngModel"
                [(ngModel)]="model.authorization_personal_data" value="1" required> Yes
              <span></span>
            </label>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>Il sottoscritto autorizza EDUCO a pubblicare e realizzare, ai sensi dell'art. 96 legge n.
            633/1941</label>
          <span>&nbsp;</span>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="authorization_educo" [(ngModel)]="model.authorization_educo" value="1"> Yes
              <span></span>
            </label>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group edit-mode">
          <label class="control-label">Notes</label>
          <textarea rows="3" class="form-control" name="annotation" placeholder="Some notes..."
            [(ngModel)]="model.annotation" #note="ngModel"></textarea>
        </div>
      </div>
    </div>

    <h3 class="form-section">Child Subscriber</h3>

    <div class="row">
      <div class="col-md-3">
        <div *ngIf="!personSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || person_fist_name.dirty || person_fist_name.touched) && (!person_fist_name.valid && !personSelected) }">
          <label class="control-label">Figlio Nome
            <span class="required">*</span>
          </label>
          <input name="person_fist_name" [(ngModel)]="model.person_info.first_name" #person_fist_name="ngModel"
            class="form-control" nospace>
        </div>
        <div *ngIf="personSelected" class="form-group">
          <label class="control-label">Figlio Nome
            <span class="required">*</span>
          </label>
          <input name="person_fist_name" [(ngModel)]="model.person_info.first_name" #person_fist_name="ngModel"
            class="form-control">
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="!personSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || person_surname.dirty || person_surname.touched) && (!person_surname.valid && !personSelected) }">
          <label class="control-label">Figlio Cognome
            <span class="required">*</span>
          </label>
          <input name="person_surname" [(ngModel)]="model.person_info.surname" #person_surname="ngModel"
            class="form-control" nospace>
        </div>
        <div *ngIf="personSelected" class="form-group">
          <label class="control-label">Figlio Cognome
            <span class="required">*</span>
          </label>
          <input name="person_surname" [(ngModel)]="model.person_info.surname" #person_surname="ngModel"
            class="form-control">
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="!personSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || person_tax_id.dirty || person_tax_id.touched) && (!person_tax_id.valid && !personSelected) }">
          <label class="control-label">Figlio Codice fiscale &nbsp;
            <check-person-taxid [personInfo]="model.person_info"></check-person-taxid>
            <span class="required">*</span>
          </label>
          <input name="person_tax_id" [(ngModel)]="model.person_info.tax_id" #person_tax_id="ngModel"
            class="form-control" (change)="onPersonTaxidChange($event.target.value)" nospace>
        </div>
        <div *ngIf="personSelected" class="form-group">
          <label class="control-label">Figlio Codice fiscale &nbsp;
            <check-person-taxid [personInfo]="model.person_info"></check-person-taxid>
            <span class="required">*</span>
          </label>
          <input name="person_tax_id" [(ngModel)]="model.person_info.tax_id" #person_tax_id="ngModel"
            class="form-control">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">&nbsp;</label>
          <button type="button" class="form-control btn btn-primary" (click)="findChild()"
            [disabled]="searchPersonDisabled" tooltip="Verrano ricercate solo le persone con ruolo Genitore o Studente">
            Search Child
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="personSelected || addNewPerson" class="panel"
      [ngClass]="{'panel-warning': !getUserService().checkPermission('camp_edit_subscriber')}">
      <div class="panel-heading" *ngIf="!getUserService().checkPermission('camp_edit_subscriber')">
        <span class="panel-title">
          <i class="fa fa-warning"></i>&nbsp;
          All Fields are required
        </span>
      </div>
      <div class="panel-body">
        <div class="row" *ngIf="personSelected || addNewPerson">
          <div class="col-md-3">
            <div class="form-group"
              [ngClass]="{ 'has-error': (f.submitted && (!getHelper().isMyDateValid(localPersonData) && !personSelected)) }">
              <label class="control-label">Figlio Data di Nascita
                <span class="required">*</span>
              </label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_person_birthday="angular-mydatepicker" name="person_birthday"
                  class="form-control" [locale]="getHelper().locale" [options]="getDatePickerOptions()"
                  [ngModel]="localPersonData" (dateChanged)="onDatePersonChanged($event)" required />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model?.person_info?.birthday" class="btn default"
                    (click)="dp_person_birthday.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_person_birthday.toggleCalendar()"><i
                      class="fa fa-calendar-o"></i></button>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div *ngIf="!personSelected" class="form-group"
              [ngClass]="{ 'has-error': (f.submitted || person_birthplace.dirty || person_birthplace.touched) && (!person_birthplace.valid && !personSelected) }">
              <label class="control-label">Figlio Città di Nascita
                <span class="required">*</span>
              </label>
              <input name="person_birthplace" [(ngModel)]="model.person_info.birthplace" #person_birthplace="ngModel"
                class="form-control" nospace>
            </div>
            <div *ngIf="personSelected">
              <label class="control-label">Figlio Città di Nascita
              </label>
              <input name="person_birthplace" [(ngModel)]="model.person_info.birthplace" #person_birthplace="ngModel"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div *ngIf="!personSelected" class="form-group"
              [ngClass]="{ 'has-error': (f.submitted || person_birthplace_province.dirty || person_birthplace_province.touched) && (!person_birthplace_province.valid && !personSelected) }">
              <label class="control-label">Figlio Prov Nascita
                <span class="required">*</span>
              </label>
              <input name="person_birthplace_province" [(ngModel)]="model.person_info.birthplace_province"
                #person_birthplace_province="ngModel" class="form-control" nospace>
            </div>
            <div *ngIf="personSelected" class="form-group">
              <label class="control-label">Figlio Prov Nascita
              </label>
              <input name="person_birthplace_province" [(ngModel)]="model.person_info.birthplace_province"
                #person_birthplace_province="ngModel" class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="control-label">T-shirt size</label>
              <select class="form-control" name="person_tshirt_size" [(ngModel)]="model.person_info.tshirt_size">
                <option *ngFor="let sS of getConfigService().shirtSizePersonSelect" [value]="sS.key">{{sS.value}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div *ngIf="!personSelected" class="form-group"
              [ngClass]="{ 'has-error': (f.submitted || person_gender.dirty || person_gender.touched) && (!person_gender.valid && !personSelected) }">
              <label class="control-label">Figlio Sesso
                <span class="required">*</span>
              </label>
              <select class="form-control" name="person_gender" [(ngModel)]="model.person_info.gender"
                #person_gender="ngModel" required>
                <option *ngFor="let g of getConfigService().genderPersonSelect" [value]="g.key">{{g.value}}</option>
              </select>
            </div>
            <div *ngIf="personSelected" class="form-group">
              <label class="control-label">Figlio Sesso
              </label>
              <select class="form-control" name="person_gender" [(ngModel)]="model.person_info.gender"
                #person_gender="ngModel">
                <option *ngFor="let g of getConfigService().genderPersonSelect" [value]="g.key">{{g.value}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="personSelected || addNewPerson">
          <div *ngFor="let address of model.person_info.addresses">
            <div class="col-md-3 form-group" *ngIf="address.type == 1"
              [ngClass]="{ 'has-error': (f.submitted || person_address.dirty || person_address.touched) && !person_address.valid }">
              <label class="control-label">Figlio Indirizzo
                <span class="required">*</span>
              </label>
              <input name="person_address" [(ngModel)]="address.route" #person_address="ngModel" class="form-control"
                nospace>
            </div>
            <div class="col-md-3 form-group" *ngIf="address.type == 1"
              [ngClass]="{ 'has-error': (f.submitted || person_city.dirty || person_city.touched) && !person_city.valid }">
              <label class="control-label">Figlio Città
                <span class="required">*</span>
              </label>
              <input name="person_city" [(ngModel)]="address.administrative_area_level_3_long_version"
                #person_city="ngModel" class="form-control" nospace>
            </div>
            <div class="col-md-3 form-group" *ngIf="address.type == 1"
              [ngClass]="{ 'has-error': (f.submitted || person_province.dirty || person_province.touched) && !person_province.valid }">
              <label class="control-label">Figlio Provincia
                <span class="required">*</span>
              </label>
              <input name="person_province" [(ngModel)]="address.administrative_area_level_2_short_version"
                #person_province="ngModel" class="form-control" nospace>
            </div>
            <div class="col-md-3 form-group" *ngIf="address.type == 1"
              [ngClass]="{ 'has-error': (f.submitted || person_cap.dirty || person_cap.touched) && !person_cap.valid }">
              <label class="control-label">Figlio CAP
                <span class="required">*</span>
              </label>
              <input name="person_cap" [(ngModel)]="address.postal_code" #person_cap="ngModel" class="form-control"
                nospace>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="personSelected || addNewPerson">
          <div class="col-md-3" *ngFor="let contact of model.person_info.contacts">
            <div class="form-group" *ngIf="contact.type == 5">
              <label class="control-label">Figlio Cellulare</label>
              <input name="person_contact_phone" [(ngModel)]="contact.value" #person_contact_phone="ngModel"
                class="form-control">
            </div>
            <div class="form-group" *ngIf="contact.type == 1">
              <label class="control-label">Figlio Email</label>
              <input name="person_contact_email" [(ngModel)]="contact.value" #person_contact_email="ngModel"
                class="form-control">
            </div>
            <div class="form-group" *ngIf="contact.type == 4">
              <label class="control-label">Figlio telefono Casa</label>
              <input name="person_contact_home" [(ngModel)]="contact.value" #person_contact_home="ngModel"
                class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="firstSearchPerson">
      <div class="col-md-12">
        <div class="datatable-loader text-center" *ngIf="ongoingRequestPerson">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <strong class="text-center">Loading</strong>
        </div>
        <ngx-datatable *ngIf="!ongoingRequestPerson && !addNewPerson" class="material" [rows]="personFound"
          [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [selectionType]="'single'"
          (select)='onSelectPerson($event)'>
          <ngx-datatable-column name="Surname" [flexGrow]="2" prop="surname" [resizeable]="false">
          </ngx-datatable-column>
          <ngx-datatable-column name="Name" [flexGrow]="2" prop="first_name" [resizeable]="false">
          </ngx-datatable-column>
          <ngx-datatable-column name="Tax ID" [flexGrow]="2" prop="tax_id" [resizeable]="false"></ngx-datatable-column>
          <ngx-datatable-column name="Locality" [flexGrow]="2" prop="address_primary" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span
                *ngIf="value?.administrative_area_level_3_long_version == null">{{value?.administrative_area_level_2_short_version}}</span>
              <span
                *ngIf="value?.administrative_area_level_3_long_version != null">{{value?.administrative_area_level_3_long_version}}
                ({{value?.administrative_area_level_2_short_version}})</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Roles" [flexGrow]="1" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span><button class="no-button" [popover]="popTemplate" placement="left" triggers="mouseenter:mouseleave">
                  <i class="fa fa-exclamation-circle"></i></button>
              </span>
              <ng-template #popTemplate>
                <div class="row" *ngFor="let role of row?.role | mapFromKeyValue">
                  <div class="col-md-12">
                    {{this.getConfigService().getRolePersonValueByKey(role.type)}}
                  </div>
                </div>
              </ng-template>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
    <div class="row" *ngIf="personSelected">
      <div class="col-md-12">
        <div class="form-actions right">
          <button class="btn red" (click)="removeSelectedPerson()">Remove selected</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!personSelected && firstSearchPerson">
      <div class="col-md-12">
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="new_person" [(ngModel)]="addNewPerson">Nessun bambino trovato nel sistema.
              Aggiungilo come nuova persona!
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="panel panel-success"
      *ngIf="personSelected?.family && getUserService().checkPermission('camp_edit_subscriber')">
      <div class="panel-heading">
        <h3 class="panel-title">Family</h3>
      </div>
      <div class="panel-body">
        <p> Seleziona il padre o la madre che hanno iscritto il bambino al campo, oppure ignora traquillamente questo
          passaggio. </p>
      </div>
      <table class="table">
        <tbody>
          <tr *ngIf="personSelected?.family?.father_id">
            <td> Father </td>
            <td>
              <app-person-info-show #familyFather [idPerson]="personSelected?.family?.father_id"></app-person-info-show>
            </td>
            <td> <button class="btn blue btn-block" (click)="parentSelectedFromFamily(familyFather.personInfo)"> Select
              </button> </td>
          </tr>
          <tr *ngIf="personSelected?.family?.mother_id">
            <td> Mother </td>
            <td>
              <app-person-info-show #familyMother [idPerson]="personSelected?.family?.mother_id"></app-person-info-show>
            </td>
            <td> <button class="btn blue btn-block" (click)="parentSelectedFromFamily(familyMother.personInfo)"> Select
              </button> </td>
          </tr>
        </tbody>
      </table>
    </div>


    <h3 class="form-section">Parent Subscriber</h3>

    <div class="row">
      <div class="col-md-3">
        <div *ngIf="!parentSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || parent_fist_name.dirty || parent_fist_name.touched) && (!parent_fist_name.valid && !parentSelected) }">
          <label class="control-label">Genitore Nome
            <span class="required">*</span>
          </label>
          <input name="parent_fist_name" [(ngModel)]="model.parent_info.first_name" #parent_fist_name="ngModel"
            class="form-control" nospace>
        </div>
        <div *ngIf="parentSelected" class="form-group">
          <label class="control-label">Genitore Nome
            <span class="required">*</span>
          </label>
          <input name="parent_fist_name" [(ngModel)]="model.parent_info.first_name" #parent_fist_name="ngModel"
            class="form-control">
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="!parentSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || parent_surname.dirty || parent_surname.touched) && (!parent_surname.valid && !parentSelected) }">
          <label class="control-label">Genitore Cognome
            <span class="required">*</span>
          </label>
          <input name="parent_surname" [(ngModel)]="model.parent_info.surname" #parent_surname="ngModel"
            class="form-control" nospace>
        </div>
        <div *ngIf="parentSelected" class="form-group">
          <label class="control-label">Genitore Cognome
            <span class="required">*</span>
          </label>
          <input name="parent_surname" [(ngModel)]="model.parent_info.surname" #parent_surname="ngModel"
            class="form-control">
        </div>
      </div>
      <div class="col-md-3">
        <div *ngIf="!parentSelected" class="form-group"
          [ngClass]="{ 'has-error': (f.submitted || parent_tax_id.dirty || parent_tax_id.touched) && (!parent_tax_id.valid && !parentSelected) }">
          <label class="control-label">Genitore Codice fiscale &nbsp;
            <check-person-taxid [personInfo]="model.parent_info"></check-person-taxid>
            <span class="required">*</span>
          </label>
          <input name="parent_tax_id" [(ngModel)]="model.parent_info.tax_id" #parent_tax_id="ngModel"
            class="form-control" (change)="onParentTaxidChange($event.target.value)" nospace>
        </div>
        <div *ngIf="parentSelected" class="form-group">
          <label class="control-label">Genitore Codice fiscale &nbsp;
            <check-person-taxid [personInfo]="model.parent_info"></check-person-taxid>
            <span class="required">*</span>
          </label>
          <input name="parent_tax_id" [(ngModel)]="model.parent_info.tax_id" #parent_tax_id="ngModel"
            class="form-control">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">&nbsp;</label>
          <button type="button" class="form-control btn btn-primary" (click)="findParent()"
            [disabled]="searchParentDisabled" tooltip="Verrano ricercate solo le persone con ruolo Genitore o Studente">
            Search Parent
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="parentSelected || addNewParent" class="panel"
      [ngClass]="{'panel-warning': !getUserService().checkPermission('camp_edit_subscriber')}">
      <div class="panel-heading" *ngIf="!getUserService().checkPermission('camp_edit_subscriber')">
        <span class="panel-title">
          <i class="fa fa-warning"></i>&nbsp;
          All Fields are required
        </span>
      </div>
      <div class="panel-body">
        <div class="row" *ngIf="parentSelected || addNewParent">
          <div class="col-md-3">
            <div class="form-group"
              [ngClass]="{ 'has-error': (f.submitted && (!getHelper().isMyDateValid(localParentData) && !parentSelected)) }">
              <label class="control-label">Genitore Data di Nascita
                <span class="required">*</span>
              </label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_parent_birthday="angular-mydatepicker" name="parent_birthday"
                  class="form-control" [locale]="getHelper().locale" [options]="getDatePickerOptions()"
                  [ngModel]="localParentData" (dateChanged)="onDateParentChanged($event)" required />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model?.parent_info?.birthday" class="btn default"
                    (click)="dp_parent_birthday.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_parent_birthday.toggleCalendar()"><i
                      class="fa fa-calendar-o"></i></button>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div *ngIf="!parentSelected" class="form-group"
              [ngClass]="{ 'has-error': (f.submitted || parent_birthplace.dirty || parent_birthplace.touched) && (!parent_birthplace.valid && !parentSelected) }">
              <label class="control-label">Genitore Città di Nascita
                <span class="required">*</span>
              </label>
              <input name="parent_birthplace" [(ngModel)]="model.parent_info.birthplace" #parent_birthplace="ngModel"
                class="form-control" nospace>
            </div>
            <div *ngIf="parentSelected" class="form-group">
              <label class="control-label">Genitore Città di Nascita
              </label>
              <input name="parent_birthplace" [(ngModel)]="model.parent_info.birthplace" #parent_birthplace="ngModel"
                class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div *ngIf="!parentSelected" class="form-group"
              [ngClass]="{ 'has-error': (f.submitted || parent_birthplace_province.dirty || parent_birthplace_province.touched) && (!parent_birthplace_province.valid && !parentSelected) }">
              <label class="control-label">Genitore Prov Nascita
                <span class="required">*</span>
              </label>
              <input name="parent_birthplace_province" [(ngModel)]="model.parent_info.birthplace_province"
                #parent_birthplace_province="ngModel" class="form-control" nospace>
            </div>
            <div *ngIf="parentSelected" class="form-group">
              <label class="control-label">Genitore Prov Nascita
              </label>
              <input name="parent_birthplace_province" [(ngModel)]="model.parent_info.birthplace_province"
                #parent_birthplace_province="ngModel" class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Grado di parentela</label>
              <select class="form-control" name="parent_kinship" [(ngModel)]="model.parent_info.kinship"
                #parent_kinship="ngModel">
                <option value="mother">Madre</option>
                <option value="father">Padre</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="parentSelected || addNewParent">
          <div class="col-md-3" *ngFor="let contact of model.parent_info.contacts">
            <div *ngIf="contact.type == 5" class="form-group"
              [ngClass]="{ 'has-error': (f.submitted || parent_contact_phone.dirty || parent_contact_phone.touched) && !parent_contact_phone.valid}">
              <label class="control-label">Genitore Cellulare
                <span class="required">*</span>
              </label>
              <input name="parent_contact_phone" [(ngModel)]="contact.value" #parent_contact_phone="ngModel"
                class="form-control" nospace>
            </div>
            <div *ngIf="contact.type == 1" class="form-group"
              [ngClass]="{ 'has-error': (f.submitted || parent_contact_email.dirty || parent_contact_email.touched) && !parent_contact_email.valid}">
              <label class="control-label">Genitore Email
                <span class="required">*</span>
              </label>
              <input name="parent_contact_email" [(ngModel)]="contact.value" #parent_contact_email="ngModel"
                class="form-control" nospace>
            </div>
            <div class="form-group" *ngIf="contact.type == 4">
              <label class="control-label">Genitore telefono Casa</label>
              <input name="parent_contact_home" [(ngModel)]="contact.value" #parent_contact_home="ngModel"
                class="form-control">
            </div>
            <div class="form-group" *ngIf="contact.type == 7">
              <label class="control-label">Genitore telefono Altro</label>
              <input name="parent_contact_other" [(ngModel)]="contact.value" #parent_contact_other="ngModel"
                class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="firstSearchParent">
      <div class="col-md-12">
        <div class="datatable-loader text-center" *ngIf="ongoingRequestParent">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <strong class="text-center">Loading</strong>
        </div>
        <ngx-datatable *ngIf="!ongoingRequestParent && !addNewParent" class="material" [rows]="parentsFound"
          [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [selectionType]="'single'"
          (select)='onSelectParent($event)'>
          <ngx-datatable-column name="Surname" [flexGrow]="2" prop="surname" [resizeable]="false">
          </ngx-datatable-column>
          <ngx-datatable-column name="Name" [flexGrow]="2" prop="first_name" [resizeable]="false">
          </ngx-datatable-column>
          <ngx-datatable-column name="Tax ID" [flexGrow]="2" prop="tax_id" [resizeable]="false"></ngx-datatable-column>
          <ngx-datatable-column name="Locality" [flexGrow]="2" prop="address_primary" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span
                *ngIf="value?.administrative_area_level_3_long_version == null">{{value?.administrative_area_level_2_short_version}}</span>
              <span
                *ngIf="value?.administrative_area_level_3_long_version != null">{{value?.administrative_area_level_3_long_version}}
                ({{value?.administrative_area_level_2_short_version}})</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Roles" [flexGrow]="1" [resizeable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span><button class="no-button" [popover]="popTemplate" placement="left" triggers="mouseenter:mouseleave">
                  <i class="fa fa-exclamation-circle"></i></button>
              </span>
              <ng-template #popTemplate>
                <div class="row" *ngFor="let role of row?.role | mapFromKeyValue">
                  <div class="col-md-12">
                    {{this.getConfigService().getRolePersonValueByKey(role.type)}}
                  </div>
                </div>
              </ng-template>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
    <div class="row" *ngIf="parentSelected">
      <div class="col-md-12">
        <div class="form-actions right">
          <button class="btn red" (click)="removeSelectedParent()">Remove selected</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!parentSelected && firstSearchParent">
      <div class="col-md-12">
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="new_parent" [(ngModel)]="addNewParent">Nessun genitore trovato nel sistema.
              Aggiungilo come nuova persona!
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <h3 class="form-section">Total Course Fee <small style="padding-top: 6px;" class="pull-right">&nbsp;(senza
        sconti)</small><span
        class="pull-right">{{this.standardCourseFee | currency:'EUR':'symbol':'1.2-2':'it' }}</span></h3>

    <h3 class="form-section">Add In Enrollment Fee</h3>

    <div *ngIf="model.enrollment_fee" class="row">
      <div class="col-md-12">
        <alert type="success">
          <strong>Pagata:</strong> {{ model.enrollment_fee_amount | currency:'EUR':'symbol':'1.2-2':'it' }}<br>
        </alert>
      </div>
    </div>


    <div *ngIf="!model.enrollment_fee" class="row">
      <div class="col-md-12">
        <alert *ngIf="model.price_quota_associativa > 0" type="{{addInAlertType}}">
          <strong>Paypal:</strong> {{model.status}} - Transaction ID {{model.paypal_transaction_id}}<br>
          <strong>Payer:</strong> {{model.payer_first_name}} {{model.payer_last_name}} ({{model.payer_email}})<br>
          <strong>Address:</strong> {{model.payer_address_recipient_name}}, {{model.payer_address_city}}
          ({{model.payer_address_state}}) {{model.payer_address_postal_code}} {{model.payer_address_country_code}},
          {{model.payer_address_line1}} {{model.payer_address_line2}}<br>
          <strong>Enrollment fee:</strong> {{model.price_quota_associativa}} (+{{model.price_spese_gestione}})<br>
        </alert>
        <alert *ngIf="model.price_quota_associativa <= 0" type="warning">
          <strong>Online payment not received</strong>
        </alert>
      </div>
    </div>

    <div class="row" *ngIf="!model.enrollment_fee">
      <div class="col-md-6">
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="add_in_check" [(ngModel)]="model.addin_enrollment_fee"
                (change)="model.addin_enrollment_fee ? addInAlertType = 'success' : addInAlertType = 'warning'" />Confermo
              che l'iscrizione è stata pagata
              <span></span>
            </label>
            <p>Verrà aggiunta una voce Add In nel CashFlow del camp</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group" *ngIf="model.addin_enrollment_fee">
          <label class="control-label">Quota</label>
          <input type="number" min="0" name="add_in_value" [(ngModel)]="model.addin_enrollment_fee_value"
            #add_in_value="ngModel" class="form-control" required>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" *ngIf="model.addin_enrollment_fee"
          [ngClass]="{ 'has-error': (f.submitted || registeredvia.dirty || registeredvia.touched) && !registeredvia.valid }">
          <label class="control-label">Registered via
            <span class="required">*</span>
          </label>
          <select class="form-control" name="registered_via" [(ngModel)]="model.addin_enrollment_fee_registered_via"
            #registeredvia="ngModel" required>
            <option *ngFor="let c of getConfigService().primanotaRegisteredViaSelect" [value]="c.key">{{c.value}}
            </option>
          </select>
          <span class="help-block">Field required</span>
        </div>
      </div>
      <div class="col-md-12" *ngIf="model.addin_enrollment_fee">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="2" class="form-control" placeholder="Some notes..." name="addin_enrollment_fee_annotation"
            [(ngModel)]="model.addin_enrollment_fee_annotation"></textarea>
        </div>
      </div>
    </div>

    <h3 class="form-section">Discount <span *ngIf="this.totalDiscount"
        class="pull-right">{{this.totalDiscount | currency:'EUR':'symbol':'1.2-2':'it' }}</span><span
        *ngIf="model.quote_discount_free" class="pull-right">&nbsp;FREE</span></h3>

    <div class="row" *ngIf="canEditDiscount">
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_1" #quote_discount_1 [(ngModel)]="model.quote_discount_1"
            (change)="updateCourseFeeWithDiscount()">Iscrizione durante l'incontro genitori:
          {{ getCampService().currentCampInfo.quote_discount_1 | currency:'EUR':'symbol':'1.2-2':'it' }}
          <span></span>
        </label>
      </div>
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_2" #quote_discount_2 [(ngModel)]="model.quote_discount_2"
            (change)="updateCourseFeeWithDiscount()">
          <i *ngIf="model.host_week != null && model.host_week.length > 0" class="fa fa-check-circle" aria-hidden="true"
            style="color:green"></i>
          Host Families (per host week):
          {{ getCampService().currentCampInfo.quote_discount_2 | currency:'EUR':'symbol':'1.2-2':'it' }}
          (x{{model.host_week.length}})
          <span></span>
        </label>
      </div>
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_3" #quote_discount_3 [(ngModel)]="model.quote_discount_3"
            (change)="updateCourseFeeWithDiscount()">
          Sconto a fratello (per settimana):
          {{ getCampService().currentCampInfo.quote_discount_3 | currency:'EUR':'symbol':'1.2-2':'it' }}
          (x{{model.camp_week.length}})
          <span></span>
        </label>
      </div>
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_4" #quote_discount_4 [(ngModel)]="model.quote_discount_4"
            (change)="updateCourseFeeWithDiscount()">
          dal 3° fratello in poi:
          {{ getCampService().currentCampInfo.quote_discount_4 | currency:'EUR':'symbol':'1.2-2':'it' }}
          (x{{model.camp_week.length}})
          <span></span>
        </label>
      </div>
    </div>

    <div class="row" *ngIf="canEditDiscount">
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_early_bird" #quote_discount_early_bird
            [(ngModel)]="model.quote_discount_early_bird" (change)="updateCourseFeeWithDiscount()">
          <i *ngIf="suggestEarlyBirdDiscount" class="fa fa-check-circle" aria-hidden="true" style="color:green"></i>
          Early bird discount:
          {{ getCampService().currentCampInfo.quote_discount_early_bird | currency:'EUR':'symbol':'1.2-2':'it' }} from
          {{ quote_discount_early_bird_date_parsed | date:'d MMMM, y':'':'it'}}
          <span></span>
        </label>
      </div>
      <div class="col-md-3">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="quote_discount_free" #quote_discount_free [(ngModel)]="model.quote_discount_free"
            (change)="updateCourseFeeWithDiscount()"> Free
          <span></span>
        </label>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Personalized discount</label>
          <input type="number" name="quote_discount_custom" [(ngModel)]="model.quote_discount_custom"
            #quote_discount_custom="ngModel" (change)="updateCourseFeeWithDiscount()" class="form-control">
        </div>
      </div>
    </div>

    <div class="row" *ngIf="canEditDiscount">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Discount notes</label>
          <textarea rows="2" class="form-control" placeholder="Some notes..." name="quote_discount_annotation"
            [(ngModel)]="model.quote_discount_annotation"></textarea>
        </div>
      </div>
    </div>


    <h3 *ngIf="model.enrollment_fee" class="form-section">Add In Course Fee <span
        [ngClass]="{'font-red': model.addin_course_fee_value_total < 0}"
        *ngIf="model.addin_course_fee_value_total && !model.quote_discount_free"
        class="pull-right">{{model.addin_course_fee_value_total | currency:'EUR':'symbol':'1.2-2':'it' }}</span><span
        *ngIf="model.quote_discount_free" class="pull-right">FREE</span></h3>

    <div *ngIf="model.course_fee_amount >= model.addin_course_fee_value_total && model.enrollment_fee" class="row">
      <div class="col-md-12">
        <alert type="success">
          <span *ngIf="!model.quote_discount_free"><strong>Pagata:</strong>
            {{ model.course_fee_amount | currency:'EUR':'symbol':'1.2-2':'it' }}<br></span>
          <span *ngIf="model.quote_discount_free"><strong>FREE</strong>
            <label class="mt-checkbox mt-checkbox-outline pull-right">
              <input type="checkbox" name="quote_discount_free" #quote_discount_free
                [(ngModel)]="model.quote_discount_free" (change)="updateCourseFeeWithDiscount()"> <small>Uncheck and
                Update to remove FREE discount</small>
              <span></span>
            </label>
          </span><br>
          <span
            *ngIf="(model.addin_course_fee_value_total + model.enrollment_fee_amount)  != standardCourseFee && !model.quote_discount_free">
            <strong>Discount:</strong>
            <ul>
              <li *ngIf="model.quote_discount_1">Iscrizione durante l'incontro genitori:
                {{ getCampService().currentCampInfo.quote_discount_1 | currency:'EUR':'symbol':'1.2-2':'it' }}</li>
              <li *ngIf="model.quote_discount_2">Host Families (per host week):
                {{ (getCampService().currentCampInfo.quote_discount_2 * this.model.host_week.length) | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_discount_3">Sconto a fratello (per settimana):
                {{ (getCampService().currentCampInfo.quote_discount_3 * this.model.camp_week.length) | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_discount_4">dal 3° fratello in poi:
                {{ (getCampService().currentCampInfo.quote_discount_4 * this.model.camp_week.length) | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_discount_early_bird">Early bird discount:
                {{ campService.currentCampInfo.quote_discount_early_bird | currency:'EUR':'symbol':'1.2-2':'it' }}
                before {{ quote_discount_early_bird_date_parsed | date:'d MMMM, y':'':'it'}}</li>
              <li *ngIf="model.quote_discount_custom > 0">Personalized discount:
                {{ model.quote_discount_custom | currency:'EUR':'symbol':'1.2-2':'it' }}</li>
            </ul>
          </span>
        </alert>
      </div>
    </div>

    <div
      *ngIf="model.course_fee_amount > 0 && model.course_fee_amount < model.addin_course_fee_value_total && model.enrollment_fee"
      class="row">
      <div class="col-md-12">
        <alert type="warning">
          <span *ngIf="!model.quote_discount_free"><strong>Pagata parzialmente:</strong>
            {{ model.course_fee_amount | currency:'EUR':'symbol':'1.2-2':'it' }}<br></span>
          <br>
          <span
            *ngIf="(model.addin_course_fee_value_total + model.enrollment_fee_amount)  != standardCourseFee && !model.quote_discount_free">
            <strong>Discount:</strong>
            <ul>
              <li *ngIf="model.quote_discount_1">Iscrizione durante l'incontro genitori:
                {{ getCampService().currentCampInfo.quote_discount_1 | currency:'EUR':'symbol':'1.2-2':'it' }}</li>
              <li *ngIf="model.quote_discount_2">Host Families (per host week):
                {{ (getCampService().currentCampInfo.quote_discount_2 * this.model.host_week.length) | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_discount_3">Sconto a fratello (per settimana):
                {{ (getCampService().currentCampInfo.quote_discount_3 * this.model.camp_week.length) | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_discount_4">dal 3° fratello in poi:
                {{ (getCampService().currentCampInfo.quote_discount_4 * this.model.camp_week.length) | currency:'EUR':'symbol':'1.2-2':'it' }}
              </li>
              <li *ngIf="model.quote_discount_early_bird">Early bird discount:
                {{ getCampService().currentCampInfo.quote_discount_early_bird | currency:'EUR':'symbol':'1.2-2':'it' }}
                before {{ quote_discount_early_bird_date_parsed | date:'d MMMM, y':'':'it'}}</li>
              <li *ngIf="model.quote_discount_custom > 0">Personalized discount:
                {{ model.quote_discount_custom | currency:'EUR':'symbol':'1.2-2':'it' }}</li>
            </ul>
          </span>
        </alert>
      </div>
    </div>

    <div class="row" *ngIf="model.course_fee_amount < model.addin_course_fee_value_total && model.enrollment_fee">
      <div class="col-md-6">
        <div class="form-group">
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="add_in_check" [(ngModel)]="model.addin_course_fee" />Confermo che la quota
              del corso è stata pagata
              <span></span>
            </label>
            <p>Verrà aggiunta una voce Add In nel CashFlow del camp</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group" *ngIf="model.addin_course_fee">
          <label class="control-label">Quota</label>
          <input type="number" name="add_in_value" [(ngModel)]="model.addin_course_fee_value" #add_in_value="ngModel"
            class="form-control">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" *ngIf="model.addin_course_fee"
          [ngClass]="{ 'has-error': (f.submitted || registeredvia.dirty || registeredvia.touched) && !registeredvia.valid }">
          <label class="control-label">Registered via
            <span class="required">*</span>
          </label>
          <select class="form-control" name="registered_via" [(ngModel)]="model.addin_course_fee_registered_via"
            #registeredvia="ngModel" required>
            <option *ngFor="let c of getConfigService().primanotaRegisteredViaSelect" [value]="c.key">{{c.value}}
            </option>
          </select>
          <span class="help-block">Field required</span>
        </div>
      </div>
      <div class="col-md-12" *ngIf="model.addin_course_fee">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea rows="2" class="form-control" placeholder="Some notes..." name="addin_course_fee_annotation"
            [(ngModel)]="model.addin_course_fee_annotation"></textarea>
        </div>
      </div>
    </div>

  </div>

  <div class="form-actions right" [appCheckPermission]="'camp_edit_subscriber'">
    <img *ngIf="getCampService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />

    <button type="submit" class="btn blue" [disabled]="getCampService().isOngoingRequest()">
      <span class="ladda-label">
        <i class="fa fa-save"></i> Save</span>
    </button>
  </div>

</form>
