import { Component, OnInit, ViewChild, Input} from '@angular/core';
import { PrimanotaService, ConfigService, UserService, HelperService } from './../../_services/index';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpParams } from '@angular/common/http';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-primanota-search',
  templateUrl: './primanota-search.component.html',
  styleUrls: ['./primanota-search.component.css']
})
export class PrimanotaSearchComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;

  @Input() readOnlyMode: boolean = false;

  selectedRowModel: any;
  subscribe: any;
  loadingData: boolean = false;

  pageTotalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotalPages: number = 0;
  pageSort: any = null;
  rows: any[] = [];
  selected = [];
  selectedRow: any;
  selectedHolderType: string;
  selectedHolderId: number;
  selectedHolderName: string;
  ongoingRequest = false;
  currentQuery: any;
  query: any = {
    month: ''
  };

  constructor(private primanotaService: PrimanotaService, private configService: ConfigService, private userService: UserService, private helper: HelperService) {

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;
  }

  ngOnInit() {

    this.loadingData = true;

    this.subscribe = this.primanotaService.getsubjectToUpdatePrimaNotaObservable().subscribe((res: any) => {

      this.pageNumber = res.current_page - 1;
      this.pageSize = res.per_page;
      this.pageTotalElements = res.total;
      this.pageTotalPages = res.last_page;

      this.rows = this.primanotaService.getTableData(res.data);

      this.loadingData = false;
    });

    this.searchPrimaNota();
  }

  ngOnDestroy(){
    this.subscribe.unsubscribe();
  }

  submitSearch() {
    this.searchPrimaNota();
  }

  searchPrimaNota(): void {

    if (JSON.stringify(this.currentQuery) != JSON.stringify(this.query))
    {

      //  Salvo il valore della query attuale e resetto le variabili
      this.currentQuery = Object.assign({}, this.query);
      this.pageTotalElements = 0;
      this.pageNumber = 0;
      this.pageSize = 0;

      this.pageSort = null;
    }

    this.rows = [];
    this.pageTotalElements = 0;
    this.pageTotalPages = 0;

    let params = new HttpParams();
    params = params.set('page', (this.pageNumber + 1).toString());

    if (this.pageSort != null)
    {
      params = params.set('sortDir', this.pageSort.dir);
      params = params.set('sortBy', this.pageSort.prop);
    }

    this.primanotaService.searchPrimaNotaRow(params, this.currentQuery);
  }

  fieldChange(event) {

    this.searchPrimaNota();
  }

  clearSearch() {
    this.query = {}
    this.searchPrimaNota();
  }


  // DATE PICKER
  localDateFrom: IMyDateModel;
  localDateTo: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  datePickerOptionsBegin: IAngularMyDpOptions = {};
  datePickerOptionsEnd: IAngularMyDpOptions = {};

  //  Operazioni sulla data di start
  onDateFromChanged(event: IMyDateModel)
  {
    this.localDateFrom = event;

    if (event.singleDate.jsDate != null)
    {
      this.query['from_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;

      this.datePickerOptionsEnd = datePickerOpt;
    }
    else
    {
      this.query['from_date'] = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }
  }



  //  Operazioni sulla data di end
  onDateToChanged(event: IMyDateModel)
  {
    this.localDateTo = event;

    if (event.singleDate.date != null)
    {
      this.query['to_date'] = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableSince = event.singleDate.date;

      this.datePickerOptionsBegin = datePickerOpt;
    }
    else
    {
      this.query['to_date'] = null;
      this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }




  // MODAL

  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.searchPrimaNota();
  }

  onSort(sortInfo) {

    this.pageSort = sortInfo.sorts[0];
    this.searchPrimaNota();
  }

  onSelect({ selected }) {

    if (this.readOnlyMode)
      return;

    if (!this.userService.checkPermission('primanota_edit'))
      return;

    this.selectedRow = Object.assign({}, selected[0]);
    this.showModal();
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  getRowClass(row) {

    // if (!row.role){
    //   return {'no-role-row': true};
    // }
  }

  modelUpdatedInModal(event) {
    this.hideModal();
    this.loadingData = true;
    this.searchPrimaNota();
  }

  modelUpdated(event) {
    this.loadingData = true;
    this.searchPrimaNota();
  }


  // MODAL EXPORT

  showModalExport(): void {
    this.staticModal.show();
  }

  hideModalExport(): void {
    this.staticModal.hide();
  }

  onHiddenExport(): void {
    // call back alla chiusura della modale
  }


  /*  Salvo i dati nel formato Excel
  */
  exportToExcel() {

    this.showModalExport();

    let params = new HttpParams();
    params = params.set('page_size', (this.pageTotalElements + 1).toString());

    if (this.pageSort != null)
    {
      params = params.set('sortDir', this.pageSort.dir);
      params = params.set('sortBy', this.pageSort.prop);
    }

    this.primanotaService.exportPrimaNotaRow(params, this.currentQuery, (resJson) => {

      let rowsToExport = this.primanotaService.getTableData(resJson.data);

      this.exportParse(rowsToExport, () => {
        this.hideModalExport();
      });

    });
  }



  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse (data: any, callback: any) {

    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach( (element) => {

      // console.log(element);

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      //  preparo
      exportedArray.push( {
        'Date payment': element.date_payment,
        'Date created': element.created_at,
        'From': element.out_holder_name,
        'From first name': element.out_holder_firstname,
        'From last name': element.out_holder_lastname,
        'In amount': element.in_amount,
        'Out amount': element.out_amount,
        'To': element.in_holder_name,
        'To first name': element.in_holder_firstname,
        'To last name': element.in_holder_lastname,
        'Registered via': element.registered_via_description,
        'Causal': element.causal_description,
        'Fiscal document': element.fiscal_document,
        'Verified': element.verified_transaction,
        'Annotation': element.annotation
      })
    })

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{width: 20}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 15}, {width: 15}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 30}, {width: 20}, {width: 10}, {width: 100}];

    // //  personalizzo l'header
    worksheet['!rows'] = [{hpx: 30}];

    // //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'CashFlow': worksheet}, SheetNames: ['CashFlow'] };

    // //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

    // data corrente
    let d = new Date();

    // //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "cashflow-"+d.toString()+".xlsx");

    callback();

  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

  getUserService() {
    return this.userService
  }

  getPrimanotaService() {
    return this.primanotaService;
  }
}
