<div class="row">
  <div class="col-md-12">
    <button type="button" class="btn green-jungle btn-outline btn-block" (click)="showAddForm();"><i class="fa fa-plus"></i>&nbsp; New Expiration</button>
  </div>
</div>


<ngx-datatable
class="striped"
[rows]="rows"
[columnMode]="'flex'"
[headerHeight]="50"
[footerHeight]="0"
[rowHeight]="'auto'"
[selectionType]="'single'"
[sorts]="[{prop: 'expiration_date', dir: 'asc'}]"
>
<ngx-datatable-column name="Scadenza"  prop="expired" [flexGrow]="1" [resizeable]="false" [draggable]="false">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <div style="text-align:center;">
      <span *ngIf="value"><i class="fa fa-exclamation-triangle fa-lg" style="color:red" aria-hidden="true"></i></span>
      <span *ngIf="!value"><i class="fa fa-check fa-lg" style="color:green" aria-hidden="true"></i></span>
    </div>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Type"  prop="type" [flexGrow]="6" [resizeable]="false" [draggable]="false">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value">{{getConfigService().getVehicleExpirationTypeValueByKey(row.type)}}</span>
    <br>
    <small><span *ngIf="value">{{row.annotation}}</span></small>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Date"  prop="expiration_date" [flexGrow]="2" [resizeable]="false"[draggable]="false">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value">{{ row.expiration_date | date }}</span>
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Reminder"  prop="expiration_date" [flexGrow]="3" [resizeable]="false"[draggable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">
        <app-reminder-smart-button
            [holderName]=""
            [holderType]="'App\\Vehicle'"
            [holderId]="getVehicleService().currentVehicleId"
            [reminderDate]="row.expiration_date"
            [reminderTitle]="'Scadenza '+ getConfigService().getVehicleExpirationTypeValueByKey(row.type) + ' ' + getVehicleService().currentVehicleInfo['vehicle_name']"
            [reminderAnnotation]="row.annotation"
            [reminderTag]="'vehicle_' + getVehicleService().currentVehicleId + '_expiration_' + row.id"></app-reminder-smart-button>
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Action" prop="" [resizeable]="false" [flexGrow]="1">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <button class="blue btn default btn-sm" (click)="edit(row)"><i class="fa fa-edit" aria-hidden="true"></i></button>
      </ng-template>
    </ngx-datatable-column>
</ngx-datatable>





<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add expiration</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-vehicle-expiration-add-form [model]="currentSelected" (modelUpdated)="hideModal()" (modelDeleted)="hideModal()"></app-vehicle-expiration-add-form>
      </div>
    </div>
  </div>
</div>
