import { Component, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ShowService, ConfigService } from '../../_services/index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-show-info-form',
  templateUrl: './show-info-form.component.html',
  styleUrls: ['./show-info-form.component.css']
})
export class ShowInfoFormComponent implements OnInit {

  model: any = {};

  audienceModel:any = null;

  subscribe: any;

  constructor(private showService: ShowService, private router: Router, private configService: ConfigService) { }

  ngOnInit() {

    this.subscribe = this.showService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.model = this.showService.currentShowInfo;

      if (this.model != null)
        this.audienceModel = JSON.parse(this.model.audience);

    });

      //  Se è un nuovo gruppo setto di defaul il gruppo attivo
      if (typeof this.model.id == 'undefined') {
        this.model.active = true;

        //  Inizializzo le audience
        this.audienceModel = {};
        this.configService.showAudienceSelect.forEach(element => {
          this.audienceModel[element.key] = false;
        });
      }
  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }

  submit() {

    if (this.audienceModel != null)
        this.model.audience = JSON.stringify(this.audienceModel);

    if (this.model.id != null)
      this.showService.updateShowInfo(this.model);
    else
      this.showService.saveShowInfo(this.model);
  }

  destroy() {

    this.showService.deleteShowInfo(this.model, () => {
      this.router.navigate(["show/home"]);
    });

  }


  onChangeAudience(event) {
  }

  getShowService() {
    return this.showService
  }

  getConfigService() {
    return this.configService
  }

}
