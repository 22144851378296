import { ShowService } from './../../_services/show.service';
import { NotificationsService } from 'angular2-notifications';
import { TheatrinoBookingShowInfoFormComponent } from './../theatrino-booking-show-info-form/theatrino-booking-show-info-form.component';
import { TheatrinoBookingInfoFormComponent } from './../theatrino-booking-info-form/theatrino-booking-info-form.component';
import { TheatrinoService } from './../../_services/index';
import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { parseISO, addMinutes, format } from "date-fns";
import { Router } from '@angular/router';

@Component({
  selector: 'app-theatrino-booking-show-list',
  templateUrl: './theatrino-booking-show-list.component.html',
  styleUrls: ['./theatrino-booking-show-list.component.css']
})
export class TheatrinoBookingShowListComponent implements OnInit {

  @ViewChildren("showsForm")
  public showsForms: QueryList<TheatrinoBookingShowInfoFormComponent>;

  model: any = {};
  @Input() lockedMode: boolean = false;
  @Input() theatrinoInfo: TheatrinoBookingInfoFormComponent;

  subscribe: any;

  //  Array degli spettacoli
  showsArray: any[];
  showsArrayKey: any[];

  allFormValid = false;

  constructor (
    private theatrinoService: TheatrinoService,
    private notify: NotificationsService,
    private showService: ShowService,
    private router: Router) { }

  ngOnInit() {

      this.subscribe = this.theatrinoService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

        //  Setto il modello del tour dal service
        this.model = this.theatrinoService.currentTourInfo;

        if (this.model == null)
          this.model = {};

        if (typeof this.model.shows == "undefined") this.model.shows = [];

        //  Memorizzo gli show associati alla data
        this.showsArray = [];
        // this.showsArrayKey = Object.keys(this.model.shows).reverse();
        this.showsArrayKey = Object.keys(this.model.shows);

        this.showsArrayKey.forEach(key => {
          this.showsArray.push(this.model.shows[key]);
        });

        //  Ordino in base al tempo l'array
        this.showsArray.sort(function(a, b){
          return a.time < b.time ? -1 : a.time > b.time ? 1 : 0 }
        );

        //  Solo nel caso in cui non ci soon show aggiugo un form vuoto
        if (this.showsArray.length == 0)
          this.showsArray.push({});

      });

  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }


  saveAll() {

    this.allFormValid = true;

    this.showsForms.forEach(element => {
      if (!element.valid) return (this.allFormValid = false);
    });

    if (!this.allFormValid) {
      this.notify.error("Some Form are not valid on Tour");
      return;
    }

    this.showsForms.forEach(element => {
      element.submit();
    });

  }

  //  Inoltro il click sull'aggiunta di un nuovo show
  AddShowClicked ()
  {
    if (this.showsArray == null || typeof this.showsArray == "undefined")
    this.showsArray = [];

  this.showsArray.unshift(this.setInfoIntoShowModel());
  }

  //  Inoltro il click sulla cancellazione di uno show
  ShowDestroyed (event)
  {

    //  cerco e cancello l'oggetto
    var idx = this.showsArray.indexOf(event.model);
    if (idx != -1) {
      this.showsArray.splice(idx, 1);
    }
  }

  setInfoIntoShowModel() {
    var model: any = {};

    if (this.showsArray.length == 0) model.time = this.model.time;
    else {

      //  Recupero l'ultimo show inserito
      var lastShow = this.showsArray[this.showsArray.length-1];

      //  Copio le informazioni dello show e del responsabile dall'ultimo show inserito
      model.show_id = lastShow.show_id;
      model.workshop = lastShow.workshop;
      model.accountable_id = lastShow.accountable_id;
      model.pause = lastShow.pause;
      model.workshop = lastShow.workshop;

      //  Resetto le informazioni dello show che vanno inserite perchè diverse rispoetto a tutti gli altri show
      model.total_subscribers = "";
      model.number_gratuities = "";
      model.class_level = "";

      //  Setto momentanemante il time a niente
      model.time = "";

      //  trasformo il tempo in una data per poterla manipolare
      if (lastShow.time) {
        //  Trasformo il tempo in una data per poter utilizzare le funzioni sulle date
        var datetime = parseISO("1970-01-01T" + lastShow.time);

        //  Devo cercare le infromazioni sugli show e aggiungere la durata
        //    -> Qui lo Show Service ha già le informazioni
        this.showService.currentAllShows.forEach(element => {
          if (element.show_id == model.show_id)
            datetime = addMinutes(datetime, element.duration);
        });

        if (model.pause != null) datetime = addMinutes(datetime, model.pause);

        if (model.workshop != null)
          datetime = addMinutes(datetime, model.workshop);

        //  assegno anche il nuovo time
        model.time = format(datetime, "HH:mm");
      }
    }

    return model;
  }

  GoToAgreement() {

    this.router.navigate([
      "/enterprise/" +
        this.model.enterprise_organizer_id +
        "/agreement/edit/" +
        this.model.agreement_id
    ]);
  }

  getTheatrinoService() {
    return this.theatrinoService
  }


}
