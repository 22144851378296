import { Component, OnInit, Input } from '@angular/core';
import { HelperService, ConfigService, CampService } from './../../_services/index';
import { DndDropEvent } from 'ngx-drag-drop'
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-camp-groups-dnd',
  templateUrl: './camp-groups-dnd.component.html',
  styleUrls: ['./camp-groups-dnd.component.css']
})
export class CampGroupsDndComponent implements OnInit {

  campGroupList: any[] = [];
  @Input() weekName = null;

  currentWeekList: any[] = [];
  filteredWeekList: any[] = [];
  currentGroupList: any[] = [];
  weekNumber = 0;
  loading: boolean = false;

  modelFiltering: any = { gender: '', school: '', class_type: '' };
  subscribeGroup: any;
  subscribeSubscriberList: any;
  campWeeks: number = 0;

  constructor(private helper: HelperService, private configService: ConfigService,
    private campService: CampService, private notify: NotificationsService) { }

  ngOnInit() {

    this.subscribeGroup = this.campService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      this.getGroupsInfo();
      this.getSubscriberInfo();
    });

    this.subscribeSubscriberList = this.campService.getsubjectToUpdateCampSubscriberListObservable().subscribe((update: boolean) => {

      this.getSubscriberInfo();
    });

    if (this.campService.currentCampInfo) {
      this.campWeeks = this.campService.numOfCampWeeks;
    }

    //  se non è stato impostato un nome della settimana non posso attivare il componente
    if (this.weekName == null)
      return;

    //  setto il numero della settimana di questo componente
    this.weekNumber = parseInt(this.weekName.replace(new RegExp('week_0', 'g'), ''));

    this.getGroupsInfo();
    this.campService.getCampSubscriber();


  }

  ngOnDestroy() {
    this.subscribeGroup.unsubscribe();
    this.subscribeSubscriberList.unsubscribe();
  }

  getGroupsInfo() {

    if (this.campService.currentCampGroups != null) {

      this.campGroupList = this.campService.currentCampGroups;

      this.prepareGroupList();
    }
  }

  getSubscriberInfo() {

    if (this.campService.currentCampSubscriberList != null) {

      this.prepareWeekSubscribers();
    }
  }

  prepareWeekSubscribers(useCurrentList = false) {

    var subs = this.currentWeekList;

    if (!useCurrentList)
      subs = JSON.parse(JSON.stringify(this.campService.currentCampSubscriberList.filter(x => !x.deleted_at)));

    // resetto la lista degli iscritti
    this.currentWeekList = [];
    this.filteredWeekList = [];

    // svuoto le liste dei gruppi
    this.campGroupList.forEach(group => {
      group.subscribers = [];

      if (typeof group.show_group_subscribers == "undefined")
        group.show_group_subscribers = false;
    })

    //  per ogni iscritto della settimana prendo solo la settimana corrispondente
    subs.forEach((subscriber: any) => {

      if (subscriber.camp_week.includes(this.weekNumber)) {
        //  se il gruppo non c'è lo inizializzo comunque
        if (typeof subscriber.group == "undefined") { subscriber.group = {}; subscriber.group.name = ""; }

        var subIdx = subscriber.groups.findIndex(x => x.num_of_week == (this.weekNumber));

        //  se ho trovato una settimana la assegno a questo iscritto
        if (subIdx >= 0) {

          subscriber.group = subscriber.groups[subIdx];

          var group = this.campGroupList.find(x => x.id == subscriber.groups[subIdx].camp_group_id)

          if (typeof group != "undefined") {

            subscriber.group.name = group.group_name;

            group.subscribers.push(subscriber);

          }
        }

        // mettiamo tutti i subscriber che hanno questa settimana, poi filtriamo quelli con gruppo di filteringChange
        // in modo da mantenere anche altri filtri già attivi
        this.currentWeekList.push(subscriber);
      }
    });

    this.prepareGroupList();
    this.filteringChange(null);
  }


  prepareGroupList() {

    if (this.campGroupList != null && typeof this.campGroupList != "undefined") {
      //  resetto la lista dei gruppi locali
      this.currentGroupList = [];

      //  filtro tutti i gruppi per la settimana che occorre
      this.campGroupList.forEach(group => {

        Object.keys(group).forEach(groupKey => {
          if (groupKey == this.weekName)
            //  se trovo la settimana la aggiungo al mio array di riferimento
            if (group[groupKey] != null && typeof group[groupKey] != "undefined" && group[groupKey] == true)
              this.currentGroupList.push(group);
        });

      });
    }

  }





  onDrop(event: DndDropEvent, group: any) {

    if (event.data && group != null)
      this.AddGroupForSubscriber(event.data, group);
  }


  //  Aggiungo un iscritto ad un gruppo
  AddGroupForSubscriber(subscriber, group) {

    //  controllo se non è stato selezionato un gruppo valido
    if (typeof subscriber.group.camp_group_id == "undefined" && typeof subscriber.group.id == "undefined") {
      if (this.weekNumber > 0) {

        let model = { subscriber_id: subscriber.id, camp_group_id: group.id, num_of_week: this.weekNumber };

        // aggiorno manualmente le info prima di scaricare i dati
        subscriber.group = group;
        subscriber.group.camp_group_id = group.id
        var gr = this.currentGroupList.find(x => x.id == group.id);
        subscriber.group.name = gr.group_name;
        subscriber.groups.push(model);
        let idx = this.currentWeekList.findIndex(x => x.id == subscriber.id)
        this.currentWeekList[idx] = subscriber;
        this.prepareWeekSubscribers(true);

        this.loading = true;
        this.campService.saveCampSubscriberGroup(model, (newGroup) => {
          this.notify.success("Subscriber Group", "successfully saved");
          this.campService.getCampSubscriber(); // devo per forza scaricare la lista altrimenti non ho l'id dell'assegnazione del gruppo per fare il delete
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      }
    }
    else
      this.notify.error("Subscriber Group", "This Subscriber already have a group");
  }




  //  applico i filtri all'array degli iscritti
  filteringChange(event) {

    this.filteredWeekList = JSON.parse(JSON.stringify(this.currentWeekList));

    // filtro in base al gruppo
    this.filteredWeekList = this.filteredWeekList.filter(x => x.groups && x.groups.findIndex(x => x.num_of_week == (this.weekNumber)) < 0 );
// console.log(this.modelFiltering)
    // filtro in base al genere
    if (this.modelFiltering.gender.length > 0) {
      this.filteredWeekList = this.filteredWeekList.filter(
        x => x.person_info.gender.toLowerCase().indexOf(this.modelFiltering.gender.toLowerCase()) !== -1 || !this.modelFiltering.gender);
    }

    //  filtro in base al tipo della scuola
    if (this.modelFiltering.school.length > 0) {

      this.filteredWeekList = this.filteredWeekList.filter(
        x => x.school_type == this.modelFiltering.school
      );
    }

    //  filtro in base al tipo alla classe
    if (this.modelFiltering.class_type.length > 0) {

      this.filteredWeekList = this.filteredWeekList.filter(
        x => x.class_type == this.modelFiltering.class_type
      );
    }
  }

  deleteSubscriberGroup(row) {

    row.group.camp_group_id = -1;

    this.loading = true;

    this.campService.deleteCampSubscriberGroup(row.group, () => {

      row.group = {}; row.group.name = "";

      this.loading = false;

      this.notify.success("Subscriber Group", "successfully deleted");

      this.campService.getCampSubscriber();

    }, () => { this.loading = false; });
  }


  isOngoingRequest() {
    return this.campService.isOngoingRequest()
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

}
