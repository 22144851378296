import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GroupService, UserService } from '../../_services/index';
import { PrimanotaShowComponent } from '../../primanota/index';

@Component({
  selector: 'app-actor-group-cashflow-page',
  templateUrl: './actor-group-cashflow-page.component.html',
  styleUrls: ['./actor-group-cashflow-page.component.css']
})
export class ActorGroupCashflowPageComponent implements OnInit {

  @ViewChild('primaNotaShowComponent', {static: false}) public primaNotaShowComponent: PrimanotaShowComponent;

  subscribe: any;
  model: any = {};
  holderName: string;
  loadingData: boolean = true;

  constructor(private groupService: GroupService, private userService: UserService) { }

  ngOnInit() {

    this.subscribe = this.groupService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {
      this.model = this.groupService.currentGroupInfo;
      this.holderName = this.model.group_name;
      this.loadingData = false;
    });

    this.groupService.getCurrentUserActorGroupInfoAll();
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  modelUpdated(event){
    this.loadingData = true;
    this.primaNotaShowComponent.modelUpdated(event);
  }

}
