<div [appCheckPermission]="'person_edit'" *ngIf="!this.personDocumentList[0]">
  <button type="button" class="btn green btn-block btn-outline" (click)="addNew();"><i class="fa fa-plus"></i>&nbsp; New Document</button>
</div>

<!-- <div class="form-actions right">
  <img *ngIf="getPersonService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
  <button type="button" (click)="saveAll();" class="btn blue" [disabled]="getPersonService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.personDocumentList[0]}">
      <span class="ladda-label"> <i class="fa fa-save"></i> Save All</span>
    </button>
</div> -->

<div *ngFor="let key of personDocumentListKey">
  <app-person-document-form #personDocumentFormList [model]="this.personDocumentList[key]" [multiSave]="multiSaveForm" (formDelete)="formDelete($event)" (formSaved)="formSaved($event)"></app-person-document-form>
</div>

<!-- <div class="form-actions right">
  <img *ngIf="getPersonService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
  <button type="button" (click)="saveAll();" class="btn blue" [disabled]="getPersonService().isOngoingRequest()" [ngClass]="{'green-jungle': !this.personDocumentList[0]}">
      <span class="ladda-label"> <i class="fa fa-save"></i> Save All</span>
    </button>
</div> -->
