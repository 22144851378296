<div *ngIf="memberInfo.person_id && !memberInfo.person?.deleted_at" class="row form-group">
  <div class="col-md-3">
    <div class="form-group">
      <label class="control-label">{{memberInfo.person.surname}} {{memberInfo.person.first_name}}
        <a *ngIf="memberInfo.person_id" class="blue pointer" target="_blank" routerLink="/person/edit/{{ memberInfo.person_id }}">
          <i class="fa fa-external-link" aria-hidden="true"></i></a>
        <a *ngIf="memberInfo.person_id" class="blue pointer" (click)="clickPerson()">
          <i class="fa fa-id-card-o" aria-hidden="true"></i></a></label>
    </div>
    <small *ngIf="memberInfo.other_groups_array">
      <b>Giorni:</b> {{ memberInfo.days }} <br><b>DayOff: </b> {{ memberInfo.day_off}}<br><b>Accomodations:</b> {{ memberInfo.day_accomodations }}<br>
      <b>Other groups:</b> <span *ngFor="let g of memberInfo.other_groups_array" style="margin-right: 5px;">{{g.group_name}}</span>
    </small>
  </div>
  <div class="col-md-1">
    <div class="form-group">
      <label class="control-label"></label>
      <div class="mt-checkbox-inline">
        <label class="mt-checkbox mt-checkbox-outline">
          <input type="checkbox" name="driver" [disabled]="getGroupService().isOngoingRequest()" [(ngModel)]="memberInfo.driver"
            value="1" (change)="dChange()"> Driver
          <span></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group" [ngClass]="{ 'has-error': (!getHelper().isMyDateValid(localDataStart)) }">
      <label class="control-label">Start Date<span class="required">*</span></label>
        <div class="input-group">
          <!-- input box -->
          <input angular-mydatepicker #dp_start_date="angular-mydatepicker" name="start_date" class="form-control"
            [locale]="getHelper().locale"
            [options]="datePickerOptionsBegin"
            [ngModel]="localDataStart"
            (dateChanged)="onDateStartChanged($event)"
            required
          />
          <!-- clear date button -->
          <span class="input-group-btn">
            <button type="button" *ngIf="memberInfo?.start_date" class="btn default" (click)="dp_start_date.clearDate()"><i class="fa fa-close"></i></button>
          </span>
          <!-- toggle calendar button -->
          <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_start_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
          </span>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group" [ngClass]="{ 'has-error': (!getHelper().isMyDateValid(localDataEnd)) }">
      <label class="control-label">End Date<span class="required">*</span></label>
        <div class="input-group">
          <!-- input box -->
          <input angular-mydatepicker #dp_end_date="angular-mydatepicker" name="end_date" class="form-control"
            [locale]="getHelper().locale"
            [options]="datePickerOptionsEnd"
            [ngModel]="localDataEnd"
            (dateChanged)="onDateEndChanged($event)"
            required
          />
          <!-- clear date button -->
          <span class="input-group-btn">
            <button type="button" *ngIf="memberInfo?.end_date" class="btn default" (click)="dp_end_date.clearDate()"><i class="fa fa-close"></i></button>
          </span>
          <!-- toggle calendar button -->
          <span class="input-group-btn">
              <button type="button" class="btn default" (click)="dp_end_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
          </span>
      </div>
    </div>
  </div>
  <div class="col-md-2">
    <div class="form-group">
      <label class="control-label">Actions</label><br>
      <ng-template #confirmDelete>
        <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
      </ng-template>
      <button type="button" class="btn red btn-outline" [disabled]="getGroupService().isOngoingRequest()"
        [outsideClick]="true" [popover]="confirmDelete" popovertitle="Are you sure?">
        <i class="fa fa-trash-o"></i>&nbsp;Remove
      </button>
    </div>
  </div>
</div>

<!-- BEGIN: MODAL CONTENT PERSON INFO -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Person Info Summary</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body form">
        <div class="row">
          <div class="col-md-12">
            <app-person-info-summary #personInfoSummaryComponent [idPerson]="memberInfo.person_id" (closeEvent)="hideModal()"></app-person-info-summary>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL CONTENT PERSON INFO -->




<div *ngIf="memberInfo.person?.deleted_at && !loading" class="row form-group">
  <div class="col-md-8">
    <alert type="warning">
      <i class="fa fa-exclamation-triangle"></i>&nbsp;<strong>{{memberInfo.person.surname}}
        {{memberInfo.person.first_name}} (ID: {{memberInfo.person_id}})</strong> was DETELED, remove this person grom
      this group or contact the administration for recover this person
    </alert>
  </div>
  <div class="col-md-4">
    <ng-template #confirmDelete>
      <button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button>
    </ng-template>
    <button type="button" class="btn btn-circle red btn-outline" [disabled]="getGroupService().isOngoingRequest()"
      [outsideClick]="true" [popover]="confirmDelete" popovertitle="Are you sure?">
      <i class="fa fa-trash-o"></i>&nbsp;Remove
    </button>
  </div>
</div>
<!-- END: MODAL CONTENT PERSON INFO -->
