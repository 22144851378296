import { Component, OnInit, Input } from '@angular/core';
import { TheatrinoService, HelperService, ConfigService } from '../../_services/index';
import { IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-theatrino-tour-info-form',
  templateUrl: './theatrino-tour-info-form.component.html',
  styleUrls: ['./theatrino-tour-info-form.component.css']
})
export class TheatrinoTourInfoFormComponent implements OnInit {

  model: any = {};

  public localDataStart: IMyDateModel;
  public localDataEnd: IMyDateModel;

  editMode: boolean = false;

  //  Impostazione della data che vengono passate dall'helper
  public datePickerOptionsBegin: IAngularMyDpOptions = {};
  public datePickerOptionsEnd: IAngularMyDpOptions = {};

  constructor(private theatrinoService: TheatrinoService, private helper: HelperService,
              private router: Router, private configService: ConfigService) {

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;
  }

  subscribe: any;

  ngOnInit() {

    this.subscribe = this.theatrinoService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      this.model = this.theatrinoService.currentTheatrinoInfo;

      if (this.model == null) this.model = {};
      else
      {
        if (this.model.start != null)
        {
          this.localDataStart = this.helper.initMyDataInputSingle(this.model.start);
          this.validateStartDate();
        }

        if (this.model.end != null)
        {
          this.localDataEnd = this.helper.initMyDataInputSingle(this.model.end);
          this.validateEndDate();
        }
      }

    });

    //  Se è un nuovo nuovo spettacolo lo setto di defaul in attivo
    if (typeof this.model.id == 'undefined') this.model.active = true;
  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }


  submit()
  {
    console.log(this.helper.isMyDateValid(this.localDataStart));
    console.log(this.helper.isMyDateValid(this.localDataEnd));

    if (!this.helper.isMyDateValid(this.localDataStart) || !this.helper.isMyDateValid(this.localDataEnd)) return;

    if (this.model.id != null)
      this.theatrinoService.updateTheatrinoTourInfo(this.model, ()=>{this.editMode = false;});
    else
      this.theatrinoService.saveTheatrinoTourInfo(this.model, () => {
        this.editMode = false;
        this.router.navigate(["theatrino/edit/"+ this.theatrinoService.currentTheatrinoId]);
      });
  }

  destroy()
  {
    this.theatrinoService.deleteTheatrinoTourInfo(this.model, () => {
      this.router.navigate(['theatrino/home/']);
    });
  }

  edit() {
    this.editMode = true;
  }



  onDateStartChanged(event: IMyDateModel)
  {
    this.localDataStart = event;

    if (event.singleDate.jsDate != null)
    {
      this.model.start = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;

      this.datePickerOptionsEnd = datePickerOpt;
    }
    else
    {
      this.model.start = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }



  validateStartDate()
  {
    if (this.model.start)
    {
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      let dateS = parseISO(this.model.start);
      datePickerOpt.disableUntil = { year: dateS.getFullYear(), month: dateS.getMonth() + 1, day: dateS.getDate() + 1 }

      this.datePickerOptionsEnd = datePickerOpt;
    }

  }





  onDateEndChanged(event: IMyDateModel)
  {
    this.localDataEnd = event;

    if (event.singleDate.jsDate != null)
    {
      this.model.end = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableSince = event.singleDate.date;

      this.datePickerOptionsBegin = datePickerOpt;
    }
    else
    {
      this.model.end = null;
      this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }



  validateEndDate()
  {
    let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    let dateE = parseISO(this.model.end);
    datePickerOpt.disableSince = { year: dateE.getFullYear(), month: dateE.getMonth() + 1, day: dateE.getDate() + 1 }

    this.datePickerOptionsBegin = datePickerOpt;
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

  getTheatrinoService() {
    return this.theatrinoService;
  }


}
