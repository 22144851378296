import { ConfigService, ShipmentService } from 'app/_services';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-shipment-material-form',
  templateUrl: './shipment-material-form.component.html',
  styleUrls: ['./shipment-material-form.component.css']
})
export class ShipmentMaterialFormComponent implements OnInit {

  @Input() model: any = {};
  @Output() cancelEvent = new EventEmitter ();
  @Output() materialSaved = new EventEmitter ();

  constructor(private configService:ConfigService, private shipmentService: ShipmentService) { }

  ngOnInit() {  
    if (this.model == null) this.model = {};
  }

  submit() {
    
    if (this.model.id != null)
    {
      this.shipmentService.updateShipmentMaterial(this.model, () => {
        this.materialSaved.emit();
      });
    }
    else
    {
      this.shipmentService.saveShipmentMaterial(this.model, () => {
        this.materialSaved.emit();
      });
    }
  }

  destroyMaterial () {

    this.shipmentService.deleteShipmentMaterial(this.model, () => {
      this.materialSaved.emit();
    });
  }

  cancel() {
    this.cancelEvent.emit();
  }


  getConfigService() {
    return this.configService
  }

  getShipmentService(): ShipmentService {
    return this.shipmentService;
  }

}
