<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

	<div class="form-body">
    	<h3 class="form-section">Tutor</h3>

	  	<div class="row">

	  		<div class="col-md-6">
		   		<div class="form-group" [ngClass]="{ 'has-error': (f.submitted || statusInput.dirty || statusInput.touched) && !statusInput.valid }">
		        	<label class="control-label">Status<span class="required">*</span></label>
		          	<select [disabled]="isOngoingRequest()" class="form-control" id="statusInput" name="statusInput" [(ngModel)]="model.status" #statusInput="ngModel" required>
		            	<option *ngFor="let s of getTutorService().status" [value]="s.value">{{s.label}}</option>
		          	</select>
		          	<span class="help-block">Select the tutor status, required</span>
		    	</div>
	      	</div>

	  	</div>

	  	<div class="row">

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Can you speak Italian ?</label>
		          	<select [disabled]="isOngoingRequest()" class="form-control" id="italianSpeakRateInput" name="italianSpeakRateInput" [(ngModel)]="model.italian_speak_rate" #italianSpeakRateInput="ngModel">
		            	<option *ngFor="let rate of getTutorService().rates" [value]="rate">{{rate}}</option>
		          	</select>
		    		<span class="help-block">Rate between 0 and 10</span>
		    	</div>
	      	</div>

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Do you smoke ?</label>
		          	<select [disabled]="isOngoingRequest()" class="form-control" id="smokerInput" name="smokerInput" [(ngModel)]="model.smoker" #smokerInput="ngModel">
		            	<option value="yes">Yes</option>
						<option value="socially">Socially</option>
						<option value="no">No</option>
		          	</select>
		    		<span class="help-block"></span>
		    	</div>
	      	</div>

	  	</div>

	  	<div class="row">

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Do you hold a TEFL Certificate ?</label>
		          	<input [disabled]="isOngoingRequest()" type="text" class="form-control" id="teflCertificateInput" name="teflCertificateInput" [(ngModel)]="model.tefl_certificate" />
		    		<span class="help-block">Accredited by</span>
		    	</div>
	      	</div>

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Do you hold a First Aid Certificate ?</label>
		          	<input [disabled]="isOngoingRequest()" type="text" class="form-control" id="firstAidYearCertificateInput" name="firstAidYearCertificateInput" [(ngModel)]="model.first_aid_year_certificate" />
		          	<span class="help-block">Year in which obtained</span>
		    	</div>
	      	</div>

	  	</div>

	  	<div class="row">

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Current occupation</label>
		          	<input [disabled]="isOngoingRequest()" type="text" class="form-control" id="currentOccupationInput" name="currentOccupationInput" [(ngModel)]="model.current_occupation" />
		          	<span class="help-block"></span>
		    	</div>
	      	</div>

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Professional reference 1 (email)</label>
		          	<input [disabled]="isOngoingRequest()" type="text" class="form-control" id="professionalReference1Input" name="professionalReference1Input" [(ngModel)]="model.professional_reference1" />
		          	<span class="help-block"></span>
		    	</div>
	      	</div>

	  	</div>

	  	<div class="row">

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Professional reference 2 (email)</label>
		          	<input [disabled]="isOngoingRequest()" type="text" class="form-control" id="professionalReference2Input" name="professionalReference2Input" [(ngModel)]="model.professional_reference2" />
		          	<span class="help-block"></span>
		    	</div>
	      	</div>

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Which university do you attend?</label>
		          	<input [disabled]="isOngoingRequest()" type="text" class="form-control" id="universityInput" name="universityInput" [(ngModel)]="model.university" />
		          	<span class="help-block"></span>
		    	</div>
	      	</div>

	  	</div>

	  	<div class="row">

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Do you have any previous teaching experience or experience in the field of drama ?</label>
		          	<textarea [disabled]="isOngoingRequest()" rows="6" class="form-control" id="dramaExperienceInput" name="dramaExperienceInput" [(ngModel)]="model.drama_experience" ></textarea>
		          	<span class="help-block"></span>
		    	</div>
	      	</div>

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Do you have any other different skills or experience that could contribute to this role ?</label>
		          	<textarea [disabled]="isOngoingRequest()" rows="6" class="form-control" id="skillsInput" name="skillsInput" [(ngModel)]="model.skills" ></textarea>
		          	<span class="help-block"></span>
		    	</div>
	      	</div>

	  	</div>

	  	<div class="row">

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Can you play any musical instruments ?</label>
		          	<input [disabled]="isOngoingRequest()" class="form-control" id="musicalInstrumentsInput" name="musicalInstrumentsInput" [(ngModel)]="model.musical_instruments" />
		          	<span class="help-block"></span>
		    	</div>
	      	</div>

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Can you teach any sports ?</label>
		          	<input [disabled]="isOngoingRequest()" class="form-control" id="sportsInput" name="sportsInput" [(ngModel)]="model.sports" />
		          	<span class="help-block"></span>
		    	</div>
	      	</div>

	  	</div>

	  	<div class="row">

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Relevant qualifications, certificates and College/University studies</label>
		          	<textarea [disabled]="isOngoingRequest()" rows="6" class="form-control" id="qualificationsInput" name="qualificationsInput" [(ngModel)]="model.qualifications" ></textarea>
		          	<span class="help-block"></span>
		    	</div>
	      	</div>

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">List your working experience with children</label>
		          	<textarea [disabled]="isOngoingRequest()" rows="6" class="form-control" id="childrenWorkingExperienceInput" name="childrenWorkingExperienceInput" [(ngModel)]="model.children_working_experience" ></textarea>
		          	<span class="help-block"></span>
		    	</div>
	      	</div>

	  	</div>

  		<div class="row">

        <div class="col-md-6">
		   		<div class="form-group">
		   			<br>
		        	<label class="control-label">Allergic to</label>
		          	<textarea [disabled]="isOngoingRequest()" rows="6" class="form-control" id="allergicToInput" name="allergicToInput" [(ngModel)]="model.allergic_to" ></textarea>
		          	<span class="help-block"></span>
		    	</div>
        </div>

        <div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Do you have any medical/health conditions (including asthma, diabetes, depression or any form of anxiety) or require certain medications ?</label>
		          	<textarea [disabled]="isOngoingRequest()" rows="6" class="form-control" id="medicalConditionsInput" name="medicalConditionsInput" [(ngModel)]="model.medical_conditions" ></textarea>
		          	<span class="help-block"></span>
		    	</div>
        </div>

      </div>

      <div class="row">

        <div class="col-md-6">
		   		<div class="form-group">
		   			<br>
		        	<label class="control-label">Dietary requirements</label>
		          	<textarea [disabled]="isOngoingRequest()" rows="6" class="form-control" id="dietatyRequirementsInput" name="dietatyRequirementsInput" [(ngModel)]="model.dietary_requirements" ></textarea>
		          	<span class="help-block"></span>
		    	</div>
        </div>

	  	</div>

	  	<div class="row">

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Do you hold a Lifeguard Certificate ?</label>
		          	<input [disabled]="isOngoingRequest()" type="text" class="form-control" id="lifeguardYearCertificateInput" name="lifeguardYearCertificateInput" [(ngModel)]="model.first_aid_year_certificate" />
		          	<span class="help-block">Year in which obtained</span>
		    	</div>
	      	</div>

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Hear About</label>
		          	<input [disabled]="isOngoingRequest()" type="text" class="form-control" id="howHearAboutInput" name="howHearAboutInput" [(ngModel)]="model.how_hear_about" />
		          	<span class="help-block">Year in which obtained</span>
		    	</div>
	      	</div>

      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
              <label>Are you a confident swimmer ?</label>
              <div class="mt-checkbox-inline">
                <label class="mt-checkbox mt-checkbox-outline">
                    <input [disabled]="isOngoingRequest()" type="checkbox" id="confidentSwimmereInput" name="confidentSwimmerInput" [(ngModel)]="model.confident_swimmer"> Yes
                    <span></span>
                </label>
              </div>
          </div>
        </div>
      </div>

	  	<div class="row">

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Are you happy to stay with a host family ?</label>
		          	<div class="mt-checkbox-inline">
            			<label class="mt-checkbox mt-checkbox-outline">
              				<input [disabled]="isOngoingRequest()" type="checkbox" id="happyToStayWithHostFamilyInput" name="happyToStayWithHostFamilyInput" [(ngModel)]="model.happy_to_stay_with_host_family"> Yes
              				<span></span>
            			</label>
          			</div>
		    	</div>
	      	</div>

	      	<div class="col-md-6">
		   		<div class="form-group">
		        	<label class="control-label">Do you object to working or staying in a catholic environment ?</label>
		          	<div class="mt-checkbox-inline">
            			<label class="mt-checkbox mt-checkbox-outline">
              				<input [disabled]="isOngoingRequest()" type="checkbox" id="objectToCatholicEnvironmentInput" name="objectToCatholicEnvironmentInput" [(ngModel)]="model.object_to_catholic_environment"> Yes
              				<span></span>
            			</label>
          			</div>
		    	</div>
	      	</div>

	  	</div>

  	</div>

	<div class="form-actions right">
	  	<button type="submit" class="btn blue" [disabled]="isOngoingRequest()" [ngClass]="{'green-jungle': !this.model.id}">
	    	<span class="ladda-label" *ngIf="!this.model.currentPersonTutor"> <i class="fa fa-save"></i> Save</span>
	      	<span class="ladda-label" *ngIf="this.model.currentPersonTutor">Update</span>
	    </button>
  	</div>

</form>
