<alert type="warning" *ngIf="loading">
  <strong>Loading</strong> ...
</alert>

<div *ngIf="!loading">

  <form action="#" class="horizontal-form" novalidate>
    <div class="form-body">
      <div>
        <alert *ngIf="actorApplicationInfo.imported" type="warning">
          <strong>Alert!</strong> Actor already imported: this actor is present in Person Database.
        </alert>

        <alert *ngIf="actorApplicationInfo.person_id" type="info">
          <button class="btn btn-block btn-outline btn-success"
            routerLink="/person/edit/{{ actorApplicationInfo.person_id }}">Vai alla scheda della persona creata</button>
        </alert>
      </div>

      <br>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Created At</label>
            <p>{{ actorApplicationInfo.created_at | date:'d MMMM, y - HH:mm':'':'it' }}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Last Update</label>
            <p>{{ actorApplicationInfo.updated_at | date:'d MMMM, y - HH:mm':'':'it' }}</p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Annotation and Preferences</h4>
      <div class="row" *ngIf="actorApplicationInfo.phase >= 1">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Preference Phase 1</label>
            <p>
              {{ getConfig().getapplicationPhasePreferenceSelectValueByKey(actorApplicationInfo.status_01_promote_preference) }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group read-only">
            <label class="control-label">Annotation</label>
            <p class="line-breaker">{{actorApplicationInfo.status_01_promote_note}}</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="actorApplicationInfo.phase >= 2">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Preference Phase 2</label>
            <p>
              {{ getConfig().getapplicationPhasePreferenceSelectValueByKey(actorApplicationInfo.status_02_promote_preference) }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group read-only">
            <label class="control-label">Annotation</label>
            <p class="line-breaker">{{actorApplicationInfo.status_02_promote_note}}</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="actorApplicationInfo.phase >= 3">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Preference Phase 3</label>
            <p>
              {{ getConfig().getapplicationPhasePreferenceSelectValueByKey(actorApplicationInfo.status_03_promote_preference) }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group read-only">
            <label class="control-label">Annotation</label>
            <p class="line-breaker">{{actorApplicationInfo.status_03_promote_note}}</p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Personal info</h4>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Name</label>
            <p>{{actorApplicationInfo.surname}} {{actorApplicationInfo.firstname}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Date of birth</label>
            <p>{{actorApplicationInfo.date_of_birth}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Nationalities</label>
            <p>{{actorApplicationService.getNationalitiesLabel(actorApplicationInfo.nationality)}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Criminal Record</label>
            <p *ngIf="actorApplicationInfo.criminal_record == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="actorApplicationInfo.criminal_record == 'no'"><i class="fa fa-remove"></i></p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Gender</label>
            <!-- <p>{{actorApplicationInfo.gender}}</p> -->
            <p>{{actorApplicationInfo.custom_gender}}</p>
          </div>
          <div class="form-group read-only" *ngIf="actorApplicationInfo.native_english_speaker">
            <label class="control-label">Native English speaker</label>
            <p *ngIf="actorApplicationInfo.native_english_speaker == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="actorApplicationInfo.native_english_speaker == 'no'"><i class="fa fa-remove"></i></p>
          </div>
          <div class="form-group read-only" *ngIf="actorApplicationInfo.proficient_english_speaker">
            <label class="control-label">Proficient English speaker</label>
            <p *ngIf="actorApplicationInfo.proficient_english_speaker == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="actorApplicationInfo.proficient_english_speaker == 'no'"><i class="fa fa-remove"></i></p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Passport Type</label>
            <p>{{actorApplicationInfo.passport_type}}</p>
          </div>
          <div class="form-group read-only">
            <label class="control-label">Criminal record detail</label>
            <p>{{actorApplicationInfo.criminal_record_detail}}&nbsp;</p>
          </div>
        </div>

        <div class="col-md-4 mt-element-card">
          <div class="mt-card-item">
            <div *ngIf="actorApplicationInfo.avatarUrl" class="mt-overlay-1" aria-hidden="true">
              <img *ngIf="actorApplicationInfo.avatarUrl"
                src="{{actorApplicationInfo.avatarUrl}}?{{actorApplicationInfo.updated_at}}"
                class="img-responsive pic-bordered" alt="">
            </div>
            <div *ngIf="!actorApplicationInfo.avatarUrl" class="mt-overlay-1" aria-hidden="true">
              <img src="../assets/placeholder/avatar-placeholder.jpg" width="100%">
            </div>
          </div>
          <div class="form-group" *ngIf="actorApplicationInfo.cvUrl">
            <a class="btn btn-outline btn-block blue" [href]="actorApplicationInfo.cvUrl" target="_self">
              <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Download CV
            </a>
          </div>
          <div class="form-group" *ngIf="actorApplicationInfo.coverUrl">
            <a class="btn btn-outline btn-block blue" [href]="actorApplicationInfo.coverUrl" target="_self">
              <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Download Cover Letter
            </a>
          </div>
          <div class="form-group" *ngIf="actorApplicationInfo.youtube_selftape_link">
            <a class="btn btn-outline btn-block green-jungle" [href]="actorApplicationInfo.youtube_selftape_link"
              target="_blank">
              <i class="fa fa-youtube-play" aria-hidden="true"></i>&nbsp;Self-Tape video
            </a>
          </div>
        </div>

      </div>

      <h4 class="form-section">Address &amp; Contacts</h4>
      <div class="row">

        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Address</label>
            <p>{{actorApplicationInfo.address}}, {{actorApplicationInfo.postal_code}} - {{actorApplicationInfo.city}}
              ({{actorApplicationInfo.province}}) -
              {{actorApplicationService.getNationalityLabel(actorApplicationInfo.country)}}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Email</label>
            <p>{{actorApplicationInfo.email}}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Mobile telephone</label>
            <p>{{actorApplicationInfo.mobile_telephone}}</p>
          </div>
        </div>
      </div>

      <h4 class="form-section">Driving Information</h4>
      <div class="row">

        <div class="col-md-3">
          <div class="form-group read-only">
            <label class="control-label">Full driving licence</label>
            <p *ngIf="actorApplicationInfo.driver_licence == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="actorApplicationInfo.driver_licence == 'no'"><i class="fa fa-remove"></i></p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group read-only">
            <label class="control-label">Clean driver licence</label>
            <p *ngIf="actorApplicationInfo.clean_driver_licence  == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="actorApplicationInfo.clean_driver_licence  == 'no'"><i class="fa fa-remove"></i></p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group read-only">
            <label class="control-label">Happy to drive in Italy</label>
            <p *ngIf="actorApplicationInfo.like_drive_italy  == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="actorApplicationInfo.like_drive_italy  == 'no'"><i class="fa fa-remove"></i></p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group read-only">
            <label class="control-label">Shift Type</label>
            <p>{{actorApplicationInfo.shift_type}}&nbsp;</p>
          </div>
        </div>

      </div>

      <h4 class="form-section">Tour Preference</h4>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Tour</label>
            <p>{{getConfig().getActorApplicationTourType(actorApplicationInfo.tour_type)}}&nbsp;</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group read-only">
            <label class="control-label">Attend Audition in UK</label>
            <p *ngIf="actorApplicationInfo.audition_agree == 'yes'"><i class="fa fa-check"></i></p>
            <p *ngIf="actorApplicationInfo.audition_agree == 'no'"><i class="fa fa-remove"></i></p>
          </div>
        </div>
      </div>

      <br>

      <div *ngIf="actorApplicationInfo.phase >= 2">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Self-Tape</label>
              <div class="mt-checkbox-inline">
                <label class="mt-checkbox mt-checkbox-outline">
                  <input [disabled]="getActorService().isOngoingRequest()" type="checkbox" name="selftape"
                    (change)="changeSelftape($event)" [(ngModel)]="actorApplicationInfo.selftape">
                  <span></span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Audition</label>
              <div class="mt-checkbox-inline">
                <label class="mt-checkbox mt-checkbox-outline">
                  <input [disabled]="getActorService().isOngoingRequest()" type="checkbox" name="audition"
                    (change)="changeAudition($event)" [(ngModel)]="actorApplicationInfo.audition">
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="row" *ngIf="actorApplicationInfo.selftape">
              <div style="margin:15px 15px;">

                <ng-template #confirmMail1>
                  <button type="button" class="btn btn-outline green-jungle"
                    (click)="inviaMailTo($event, 'phase2_selftape_actor')">Yes, send it!</button>
                </ng-template>
                <button type="button" [outsideClick]="true" [popover]="confirmMail1" popoverTitle="Are you sure?"
                  class="btn btn-outline blue"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Actors</button>


                <ng-template #confirmMail2>
                  <button type="button" class="btn btn-outline green-jungle"
                    (click)="inviaMailTo($event, 'phase2_selftape_storytellers')">Yes, send it!</button>
                </ng-template>
                <button type="button" [outsideClick]="true" [popover]="confirmMail2" popoverTitle="Are you sure?"
                  class="btn btn-outline blue"><i class="fa fa-envelope-o"
                    aria-hidden="true"></i>&nbsp;Storytellers</button>


                <ng-template #confirmMail3>
                  <button type="button" class="btn btn-outline green-jungle"
                    (click)="inviaMailTo($event, 'phase2_selftape_pop')">Yes, send it!</button>
                </ng-template>
                <button type="button" [outsideClick]="true" [popover]="confirmMail3" popoverTitle="Are you sure?"
                  class="btn btn-outline blue"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Pop Music &
                  Culture</button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row" *ngIf="actorApplicationInfo.audition">
              <div style="margin:15px 15px;">
                <ng-template #confirmMail4>
                  <button type="button" class="btn btn-outline green-jungle"
                    (click)="inviaMailTo($event, 'phase2_audition_actors_storytellers')">Yes, send it!</button>
                </ng-template>
                <button type="button" [outsideClick]="true" [popover]="confirmMail4" popoverTitle="Are you sure?"
                  class="btn btn-outline blue"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Actors &
                  Storytellers</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Self-Tape accepted/rejected</label>
              <p><b>Accepted:</b> {{actorApplicationInfo.self_tape_accepted | date:'d MMMM, y HH:mm':'':'it'}}<br>
                <b>Rejected:</b> {{actorApplicationInfo.self_tape_rejected | date:'d MMMM, y HH:mm':'':'it'}}<br></p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Audition accepted/rejected</label>
              <p><b>Accepted:</b> {{actorApplicationInfo.audition_accepted | date:'d MMMM, y HH:mm':'':'it'}}<br>
                <b>Rejected:</b> {{actorApplicationInfo.audition_rejected | date:'d MMMM, y HH:mm':'':'it'}}<br></p>
            </div>
          </div>
        </div>

        <h4 class="form-section" *ngIf="actorApplicationInfo.timezone">Interview</h4>
        <div class="row">
          <div class="col-md-6" *ngIf="actorApplicationInfo.timezone">
            <label class="control-label">Preferred interview Time</label>
            <p>
              {{actorApplicationInfo.timezone_formatted}} {{actorApplicationInfo.preferred_interview_time_formatted}}
            </p>
          </div>
          <div class="col-md-6"></div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Date of interview <span class="required">*</span></label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_interview_date="angular-mydatepicker" name="interview_date"
                  class="form-control" [locale]="getHelper().locale" [options]="getDatePickerOptions()"
                  [ngModel]="getLocalData()" (dateChanged)="onDateChanged($event)" />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="actorApplicationInfo?.interview_date" class="btn default"
                    (click)="dp_interview_date.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_interview_date.toggleCalendar()"><i
                      class="fa fa-calendar-o"></i></button>
                </span>
              </div>
              <span class="help-block">Set the date and time in
                {{actorApplicationInfo.timezone_formatted}}timezone</span>
            </div>

          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Time of interview <span class="required">*</span></label>
              <input type="text" name="interview_time" [(ngModel)]="actorApplicationInfo.interview_time"
                #interview_time="ngModel" class="form-control" required>
              <span *ngIf="actorApplicationInfo.italian_interview_time_formatted" class="help-block">Italian time:
                {{actorApplicationInfo.italian_interview_time_formatted}}</span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="control-label">&nbsp;</label>
              <button *ngIf="actorApplicationInfo.interview_date && actorApplicationInfo.interview_time" type="button"
                class="btn blue form-control" (click)="saveInterviewInfo()"><i class="fa fa-save"
                  aria-hidden="true"></i>&nbsp;Save</button>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="control-label">&nbsp;</label>
              <ng-template #confirmMailInterview>
                <button type="button" class="btn btn-outline green-jungle"
                  (click)="inviaMailTo($event, 'phase2_interview')">Yes, send it!</button>
              </ng-template>
              <button type="button" *ngIf="interviewReady" [outsideClick]="true" [popover]="confirmMailInterview"
                popoverTitle="Are you sure?" class="btn btn-outline blue form-control"><i class="fa fa-envelope-o"
                  aria-hidden="true"></i>&nbsp;interview</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Skype interview accepted/rejected</label>
              <p><b>Accepted:</b> {{actorApplicationInfo.interview_accepted | date:'d MMMM, y HH:mm':'':'it'}}<br>
                <b>Rejected:</b> {{actorApplicationInfo.interview_rejected | date:'d MMMM, y HH:mm':'':'it'}}<br></p>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="actorApplicationInfo.skype_address">
          <div class="col-md-4">
            <div class="form-group" *ngIf="actorApplicationInfo.passportUrl">
              <a class="btn btn-outline btn-block blue" [href]="actorApplicationInfo.passportUrl" target="_self">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Passport
              </a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" *ngIf="actorApplicationInfo.driverLicenceUrl">
              <a class="btn btn-outline btn-block blue" [href]="actorApplicationInfo.driverLicenceUrl" target="_self">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp;Driving Licence
              </a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Skype Address</label>
              <p>{{actorApplicationInfo.skype_address}}</p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="actorApplicationInfo.phase >= 3">
        <h4 class="form-section">Proposal</h4>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <ng-template #confirmProposalMail4>
                <button type="button" class="btn btn-outline green-jungle"
                  (click)="inviaMailTo($event, 'phase3_proposal_tour_type')">Yes, send it!</button>
              </ng-template>
              <button type="button" [outsideClick]="true" [popover]="confirmProposalMail4" popoverTitle="Are you sure?"
                class="btn btn-outline blue"><i class="fa fa-envelope-o"
                  aria-hidden="true"></i>&nbsp;{{getConfig().getActorApplicationTourType(actorApplicationInfo.tour_type)}}</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Proposal accepted/rejected</label>
              <p><b>Accepted:</b> {{actorApplicationInfo.proposal_accepted | date:'d MMMM, y HH:mm':'':'it'}}<br>
                <b>Rejected:</b> {{actorApplicationInfo.proposal_rejected | date:'d MMMM, y HH:mm':'':'it'}}<br>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">IBAN / Paypal Address</label>
              <p>{{actorApplicationInfo.payment_number}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Insurance number</label>
              <p>{{actorApplicationInfo.insurance_number}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Emergency Contact Name</label>
              <p>{{actorApplicationInfo.emergency_contact_name}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Emergency Contact Phone</label>
              <p>{{actorApplicationInfo.emergency_contact_phone}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Emergency Contact Email</label>
              <p>{{actorApplicationInfo.emergency_contact_email}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Dietary Requirements
                <i class="fa fa-check-circle text-success"
                  *ngIf="actorApplicationInfo?.dietary_requirements_yes_or_not == 'yes'"></i>
                <i class="fa fa-close text-danger"
                  *ngIf="actorApplicationInfo?.dietary_requirements_yes_or_not == 'no'"></i>
              </label>
              <p>{{actorApplicationInfo.dietary_requirements}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Allergies
                <i class="fa fa-check-circle text-success"
                  *ngIf="actorApplicationInfo?.allergic_yes_or_not == 'yes'"></i>
                <i class="fa fa-close text-danger" *ngIf="actorApplicationInfo?.allergic_yes_or_not == 'no'"></i>
              </label>
              <!-- <p>{{actorApplicationInfo.allergic_to}}</p> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group" *ngIf="actorApplicationInfo.intChildCertificateUrl">
              <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                [href]="actorApplicationInfo.intChildCertificateUrl" target="_blank">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp; International Child Protection Certificate
              </a>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" *ngIf="actorApplicationInfo.intDriverLicenceUrl">
              <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                [href]="actorApplicationInfo.intDriverLicenceUrl" target="_blank">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp; International Driver Licence
              </a>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" *ngIf="actorApplicationInfo.healthInsuranceUrl">
              <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                [href]="actorApplicationInfo.healthInsuranceUrl" target="_blank">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Health Insurance
              </a>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" *ngIf="actorApplicationInfo.flightReceiptUrl">
              <a class="btn btn-outline btn-block blue" style="white-space:normal;"
                [href]="actorApplicationInfo.flightReceiptUrl" target="_blank">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Flight Receipt
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <br>

    <div class="form-actions right">
      <button type="button" class="btn blue btn-outline" (click)="EditActor()"
        [appCheckPermission]="'application_actors_edit'">Edit</button>
      <button type="button" class="btn btn-outline red" (click)="RejectActor()" *ngIf="!actorApplicationInfo.rejected">
        <i class="fa fa-times" aria-hidden="true"></i>&nbsp;Reject
      </button>

      <button type="button" class="btn btn-outline red" (click)="CancelRejectActor()"
        *ngIf="actorApplicationInfo.rejected">
        <i class="fa fa-times" aria-hidden="true"></i>&nbsp;Cancel rejected status
      </button>

      <ng-template #confirmPromote>
        <button type="button" class="btn btn-outline green-jungle" (click)="PromoteActor()">Yes, I'm sure!</button>
      </ng-template>
      <button type="button"
        *ngIf="!actorApplicationInfo.rejected && !actorApplicationInfo.imported && actorApplicationInfo.status == 'SUBMITTED' && actorApplicationInfo.phase < 4"
        [outsideClick]="true" [popover]="confirmPromote" popoverTitle="Are you sure?" class="btn btn-outline blue"><i
          class="fa fa-check" aria-hidden="true"></i>&nbsp;Promote</button>

      <ng-template #confirmPromoteToHire>
        <button type="button" class="btn btn-outline green-jungle" (click)="HireActor()">Yes, I'm sure!</button>
      </ng-template>
      <button type="button"
        *ngIf="!actorApplicationInfo.rejected && !actorApplicationInfo.imported && actorApplicationInfo.status == 'EDITING' && actorApplicationInfo.phase >= 4"
        [outsideClick]="true" [popover]="confirmPromoteToHire" popoverTitle="Are you sure?"
        class="btn btn-outline blue"><i class="fa fa-check" aria-hidden="true"></i>&nbsp;Hire!</button>
    </div>

  </form>
</div>
