import {BackendService} from "./backend.service";
import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NotificationsService} from 'angular2-notifications';

@Injectable()
export class TutorService {

  ongoingRequest: boolean = false;

  constructor(private backend: BackendService) {}

  public status = [
    {value: "NOT_CONFIRMED", label: "NOT CONFIRMED"},
    {value: "AP_NOT_ACCEPTED", label: "AP NOT ACCEPTED"},
    {value: "NEED_DL", label: "NEED DL"},
    {value: "RECEIVED_DL", label: "RECEIVED DL"},
    {value: "REPEAT_DL", label: "REPEAT DL"},
    {value: "NOT_SUCCESSFUL_DL", label: "NOT SUCCESSFUL DL"},
    {value: "DL_ON_HOLD", label: "DL ON HOLD"},
    {value: "JOB_OFFERED", label: "JOB OFFERED"},
    {value: "COE_ACCEPTED", label: "C.O.E. ACCEPTED"},
    {value: "COE_REFUSED", label: "C.O.E. REFUSED"},
    {value: "DOCUMENTS_OK", label: "DOCUMENTS OK"},
    {value: "FLIGHT_DETAIL_OK", label: "FLIGHT DETAIL OK"},
    {value: "ACTIVE", label: "ACTIVE"},
    {value: "ACTIVE_ON_HOLD", label: "ACTIVE ON HOLD"},
    {value: "DROP_OUT", label: "DROP OUT"}
  ];

  get rates() {return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];}

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  isOngoingRequest() {
    return this.ongoingRequest;
  }

  resetService(){

  }

  public getAvailableTutors(params: HttpParams, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.get('person/tutor/available', params).subscribe(
      (response) => {

        let resJson : any = response;

        this.finishRequest();

        if (callbackSuccess != null) {
          callbackSuccess(resJson);
        }
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null) {
          callbackError();
        }
      });
  }

  public getAssignedTutors(campId, campWeekId, params: HttpParams, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.get('camp/' + campId + '/campweek/' + campWeekId + '/tutor', params).subscribe(
      (response) => {

        let resJson : any = response;

        this.finishRequest();

        if (callbackSuccess != null) {
          callbackSuccess(resJson);
        }
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null) {
          callbackError();
        }
      });
  }

  public getAssignedTutorsToOtherCamps(params: HttpParams, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.get('person/tutor/assigned', params).subscribe(
      (response) => {

        let resJson : any = response;

        this.finishRequest();

        if (callbackSuccess != null) {
          callbackSuccess(resJson);
        }
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null) {
          callbackError();
        }
      });
  }

  public addTutor(campId, campWeekId, tutorId, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.post('camp/' + campId + '/campweek/' + campWeekId + '/tutor/' + tutorId , null).subscribe(
      (response) => {

        let resJson : any = response;

        this.finishRequest();

        if (callbackSuccess != null) {
          callbackSuccess(resJson);
        }
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null) {
          callbackError();
        }
      });
  }

 public removeTutor(campId, campWeekId, tutorId, callbackSuccess: any = null, callbackError: any = null) {

    this.startRequest();

    this.backend.destroy('camp/' + campId + '/campweek/' + campWeekId + '/tutor/' + tutorId).subscribe(
      (response) => {

        this.finishRequest();

        if (callbackSuccess != null) {
          callbackSuccess();
        }
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null) {
          callbackError();
        }
      });
  }

}
