import {Injectable} from '@angular/core';
import {environment} from './../environment';
import {BackendService} from './backend.service';
import {Subject, Observable} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {HttpParams, HttpHeaders} from '@angular/common/http';

@Injectable()
export class PaymentsService {

  ongoingRequest: boolean = false;

  //  Memorizza l'ultima query di ricerca fatta
  lastSearchQueries: any = {};

  private subjectToUpdate: Subject<boolean> = new Subject<boolean>();
  getsubjectToUpdateObservable(): Observable<boolean> {
    return this.subjectToUpdate.asObservable();
  }

  constructor(private backend: BackendService, private notify: NotificationsService) {}

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  isOngoingRequest() {
    return this.ongoingRequest;
  }

  resetService(){

  }


  searchPaymentRequest(params: HttpParams, model: any[], callbackSuccess: any = null) {

    this.startRequest();

    this.backend.post('payments/search', model, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.subjectToUpdate.next(resJson);

        this.finishRequest();

        if (callbackSuccess)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();
      }

    );
  }

  //  Crea una richiesta di pagamento
  public savePaymentRequest (model: any, callbackSuccess:any = null, callbackError: any = null)
  {
    this.startRequest();

    this.backend.post('payments/checkout/request', model).subscribe(
      (response) => {

        let resJson : any = response;

        this.finishRequest();

        this.notify.success('Payment Request', 'successfully created');

        if (callbackSuccess != null)
          callbackSuccess(resJson);
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    )
  }


  //  Invia un sollecito di pagamento per una request ancora in sospeso
  public sendReminder (model: any, callbackSuccess:any = null, callbackError: any = null)
  {
    this.startRequest();

    this.backend.get('payments/checkout/request/' + model.id + '/sendreminder', new HttpParams()).subscribe(
      (response) => {

        this.finishRequest();

        this.notify.success('Payment Reminder', 'successfully sent');

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    )
  }


  //  Cancella una richiesta di pagamento
  public deleteRequest (model: any, callbackSuccess:any = null, callbackError: any = null)
  {
    this.startRequest();

    this.backend.destroy('payments/checkout/request/' + model.id + '/delete').subscribe(
      (response) => {

        this.finishRequest();

        this.notify.success('Payment Reminder', 'successfully deleted');

        if (callbackSuccess != null)
          callbackSuccess();
      },
      (error) => {
        this.backend.showErrors(error);

        this.finishRequest();

        if (callbackError != null)
          callbackError();
      }
    )
  }


  // public searchTutorsApplication(simpleSearchKeyword: String, params: HttpParams, callbackSuccess: any = null, callbackError: any = null) {

  //   this.startRequest();

  //   let url = this.resourceUrl;

  //   if (simpleSearchKeyword) {
  //     url = url + '/search/' + simpleSearchKeyword;
  //   }

  //   this.backend.get(url, params).subscribe(
  //     (response) => {

  //       let resJson : any = response;

  //       this.finishRequest();

  //       if (callbackSuccess != null) {
  //         callbackSuccess(resJson);
  //       }

  //     },
  //     (error) => {
  //       this.backend.showErrors(error);

  //       this.finishRequest();

  //       if (callbackError != null) {
  //         callbackError(error);
  //       }
  //     }
  //   );
  // }



  // getTutorApplicationInfo(callbackSuccess: any = null, callbackError: any = null) {

  //   let params = new HttpParams();

  //   this.backend.get(this.resourceUrl + '/' + this.currentTutorApplicationId, params).subscribe(
  //     (response) => {

  //       let resJson : any = response;
  //       this.currentTutorApplicationInfo = resJson;
  //       this.currentTutorApplicationId = resJson.id;

  //       this.finishRequest();

  //       if (callbackSuccess != null)
  //         callbackSuccess();
  //     },
  //     (error) => {
  //       this.backend.showErrors(error);

  //       this.finishRequest();

  //       if (callbackError != null)
  //         callbackError();
  //     });
  // }



}
