import { Component, OnInit, ViewChild } from '@angular/core';
import { VehicleService } from 'app/_services';
import { VehicleMaterialAssignmentDatatableComponent } from '../vehicle-material-assignment-datatable/vehicle-material-assignment-datatable.component';
import { VehicleMaterialAssignmentAddFormComponent } from '../vehicle-material-assignment-add-form/vehicle-material-assignment-add-form.component';

@Component({
  selector: 'app-vehicle-material-assignment',
  templateUrl: './vehicle-material-assignment.component.html',
  styleUrls: ['./vehicle-material-assignment.component.css']
})
export class VehicleMaterialAssignmentComponent implements OnInit {

  @ViewChild('materialAssignmentDatatable', {static: true}) assignmentDatatable: VehicleMaterialAssignmentDatatableComponent;
  @ViewChild('materialAssignmentAddForm', {static: true}) assignmentAddForm: VehicleMaterialAssignmentAddFormComponent;

  loadingData: boolean = false;
  subject: any;

  assignments: any[] = [];

  constructor(private vehicleService: VehicleService) { }

  ngOnInit() {

    this.prepareView();

    this.subject = this.vehicleService.getsubjectToUpdateObservable().subscribe(() => {

      this.prepareView();
    });

  }

  prepareView()
  {
    if (this.vehicleService.currentVehicleMaterialAssignments == null || typeof this.vehicleService.currentVehicleMaterialAssignments == "undefined")
      this.assignments = [];
    else
      this.updateData(this.vehicleService.currentVehicleMaterialAssignments);
  }

  updateData (assignments: any[])
  {
    this.assignments = JSON.parse(JSON.stringify(assignments));
    this.assignments.forEach(assignment => {
      assignment.vehicle_data = JSON.parse(JSON.stringify(this.vehicleService.currentVehicleInfo));
    });

    this.assignmentDatatable.update(this.assignments);
  }

  ngOnDestroy() {

    this.subject.unsubscribe();
  }


  onSelect(selected) {
    var selectedRow:any = JSON.parse(JSON.stringify(selected));
    selectedRow.vehicle_data = JSON.parse(JSON.stringify(this.vehicleService.currentVehicleInfo));
    this.assignmentAddForm.update(selectedRow);
    this.assignmentAddForm.showMaster();
  }

  showAddForm () {
    var selectedRow:any = {};
    selectedRow.vehicle_data = JSON.parse(JSON.stringify(this.vehicleService.currentVehicleInfo));
    this.assignmentAddForm.update(selectedRow);
    this.assignmentAddForm.showMaster();
  }

  modelUpdatedInModal ()
  {
    this.loadingData = true;

    this.vehicleService.getAllMaterialAssignment(this.vehicleService.currentVehicleId, () => {

      //  ridisegno la tabella
      this.updateData(this.vehicleService.currentVehicleMaterialAssignments);

      this.loadingData = false;
    });
  }



  getVehicleService (){
    return this.vehicleService;
  }
}
