import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-home',
  templateUrl: './show-home.component.html',
  styleUrls: ['./show-home.component.css']
})
export class ShowHomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

}