import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { EnterpriseContactFormComponent } from '../index';
import { EnterpriseService } from '../../_services/index';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-enterprise-contact-list',
  templateUrl: './enterprise-contact-list.component.html',
  styleUrls: ['./enterprise-contact-list.component.css']
})
export class EnterpriseContactListComponent implements OnInit {

  @ViewChildren('enterpriseContactFormList') public enterpriseContactFormList: QueryList<EnterpriseContactFormComponent>;

  enterpriseContactList: any;
  enterpriseContactListKeys: any[];

  allFormValid = true;
  multiSaveForm = true;

  subscribe: any;

  constructor(private enterpriseService: EnterpriseService, private notify: NotificationsService) { }

  ngOnInit() {

    this.enterpriseContactList = this.enterpriseService.currentEnterpriseContactList;

    if (this.enterpriseContactList == null)
      this.addNew();

    this.subscribe = this.enterpriseService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      if (this.enterpriseService.currentEnterpriseContactList != null) {

        let newFormModel = null;
        if (typeof this.enterpriseContactFormList.first.model.id == "undefined"
          && this.enterpriseContactFormList.first.currentForm.form.dirty
          && this.enterpriseContactFormList.first.currentForm.form.pending) {
          newFormModel = this.enterpriseContactFormList.first.model;
        }

        this.enterpriseContactListKeys = Object.keys(this.enterpriseService.currentEnterpriseContactList).reverse();

        let enterpriseContactListOld = this.enterpriseContactList;
        this.enterpriseContactList = this.enterpriseService.currentEnterpriseContactList;

        this.enterpriseContactListKeys.forEach(k => {

          if (typeof enterpriseContactListOld[k] != "undefined" && this.enterpriseContactList[k]["updated_at"] == enterpriseContactListOld[k]["updated_at"])
            this.enterpriseContactList[k] = enterpriseContactListOld[k];

        });

        if (newFormModel != null) {
          this.enterpriseContactList[0] = newFormModel;
          this.enterpriseContactListKeys.unshift(0);
        }

      }

      if (this.enterpriseService.currentEnterpriseContactList != null && this.enterpriseService.currentEnterpriseContactList.length == 0)
        this.addNew();
    });
  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }

  addNew() {

    if (this.enterpriseContactList == null) {
      this.enterpriseContactList = {};
      this.enterpriseContactListKeys = new Array();
    }

    this.enterpriseContactList[0] = {};
    this.enterpriseContactListKeys.unshift(0);

    //  Assegno il contatto come primary perchè è il primo della lista
    if (this.enterpriseContactListKeys.length == 1)
      this.enterpriseContactList[0].primary = true;
  }



  saveAll() {

    this.allFormValid = true;

    // Controllo se tutti i form sono validi
    this.enterpriseContactFormList.forEach(element => {

      if (!element.currentForm.form.valid) {

        for (var i in element.currentForm.controls) {
          element.currentForm.controls[i].markAsTouched();
        }

        this.allFormValid = false;
        this.notify.error("There is a form with error");
      }

    });

    if (!this.allFormValid)
      return;

    if (!this.checkPrimary())
      return;

    this.enterpriseContactFormList.forEach(element => {
      element.submit();
    });
  }

  checkPrimary() {

    // controllo se esiste almeno un contatto primary
    let primaryFound = false;

    this.enterpriseContactFormList.forEach(element => {
      if (element.model.primary)
        primaryFound = true;
    });

    if (!primaryFound) {
      this.notify.error("You need to set a primary address");
      return false;
    }

    return true;
  }


  primaryUpdate(event) {
    //  Cambio il valore di tutti i campi primary
    if (event.primary == true) {
      this.enterpriseContactListKeys.forEach(element => {
        if (this.enterpriseContactList[element].id != event.id) {
          this.enterpriseContactList[element].primary_backup = this.enterpriseContactList[element].primary;
          this.enterpriseContactList[element].primary = 0;
        }
      });
    }
    else {
      this.enterpriseContactListKeys.forEach(element => {
        if (this.enterpriseContactList[element].primary_backup && this.enterpriseContactList[element].id != event.id) {
          this.enterpriseContactList[element].primary = this.enterpriseContactList[element].primary_backup;
        }
      });
    }
  }


  formDelete(event) {
    if (!event.model.id && this.enterpriseContactListKeys.length > 1) {
      this.enterpriseContactListKeys.splice(0, 1);
      delete this.enterpriseContactList[0];
    }
  }

  formSaved(event) {

    // controllo se questo form ha il primary impostato, perché potrebbe aver cambiato anche gli altri
    if (event.primary == true) {

      this.enterpriseContactFormList.forEach(element => {

        if (event.id != element.model.id) {

          if (typeof element.model.primary_backup != "undefined" && element.model.primary_backup != element.model.primary) {
            element.currentForm.form.markAsDirty();
            element.submit();
          }
        }
      });
    }
  }

  getEnterpriseService() {
    return this.enterpriseService
  }
}
