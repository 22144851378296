import { Router } from '@angular/router';
import { EnterpriseService, ConfigService } from './../../_services/index';
import { Component, OnInit, OnChanges, Input, enableProdMode, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-enterprise-info-summary',
  templateUrl: './enterprise-info-summary.component.html',
  styleUrls: ['./enterprise-info-summary.component.css']
})
export class EnterpriseInfoSummaryComponent implements OnInit, OnChanges {

  @Input() idEnterprise: number;
  @Output() closeEvent = new EventEmitter();
  enterpriseInfo: any;
  currentId: number;

  loading: boolean = false;

  constructor(private router: Router, private enterpriseService: EnterpriseService,
    private configService: ConfigService) { }

  ngOnInit() {
    this.enterpriseInfo = {};
  }

  editEnterprise() {
    this.router.navigate(['enterprise/edit/' + this.currentId]);
  }

  close() {
    this.closeEvent.emit();
  }

  ngOnChanges() {

    if (this.idEnterprise != this.currentId)
      this.getEnterpriseInfo();
  }

  getEnterpriseInfo() {

    if (typeof this.idEnterprise == "undefined" || this.idEnterprise <= 0) {
      this.enterpriseInfo = {};
      this.currentId = null;
      return;
    }

    this.currentId = this.idEnterprise;

    this.loading = true;

    this.enterpriseService.getEnterpriseInfoAllSummary(this.currentId, (enterpriseInfo) => {

      this.enterpriseInfo = enterpriseInfo;

      this.loading = false;

    }, () => {
      this.loading = false;
    }
  );

  }

  getConfigService() {
    return this.configService
  }

  getEnterpriseService() {
    return this.enterpriseService
  }
}
