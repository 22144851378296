<h4><i class="fa fa-line-chart" aria-hidden="true"></i> Statistics</h4>
<app-primanota-stats [holderType]="'App\\Camp'" [holderId]="getCampService().currentCampId" [holderName]="holderName"></app-primanota-stats>
<h4><i class="fa fa-line-chart" aria-hidden="true"></i> Camp Director expense</h4>
<div class="row">
  <div class="col-md-6">
      <div class="dashboard-stat2 ">
          <div class="display">
              <div class="number">
                  <h3 class="font-red-haze">
                      <span>{{ (max_total_quote_subscribers | currency:'EUR':'symbol':'1.2-2':'it') || "0,00" }}</span>
                      <!-- <small class="font-red-haze">€</small> -->
                  </h3>
                  <small>Max expense (add out) for all the subscribers</small>
              </div>
              <div class="icon">
                  <i class="icon-minus"></i>
              </div>
          </div>
      </div>
  </div>
  <div class="col-md-6">
      <div class="dashboard-stat2 ">
          <div class="display">
            <div class="number">
                <h3 class="font-red-haze">
                    <span>{{ (total_quote_subscribers | currency:'EUR':'symbol':'1.2-2':'it') || "0,00" }}</span>
                    <!-- <small class="font-red-haze">€</small> -->
                </h3>
                <small>Spesa attuale</small>
            </div>
            <div class="icon">
                <i class="icon-minus"></i>
            </div>
          </div>
          <div class="progress-info">
              <div class="progress">
                  <span [style.width]="total_quote_subscribers_perc" class="progress-bar progress-bar-success red-haze">
                      <span class="sr-only">{{total_quote_subscribers_perc}} progress</span>
                  </span>
              </div>
              <div class="status">
                  <div class="status-title"> % OF THE MAX EXPENSE</div>
                  <div class="status-number"> {{total_quote_subscribers_perc }} </div>
              </div>
          </div>
      </div>
  </div>
</div>

<app-primanota-add-form [holderType]="'App\\Camp'" [holderId]="getCampService().currentCampId" [holderName]="holderName" (modelUpdated)="modelUpdated($event)"></app-primanota-add-form>
<br>
<app-primanota-show #primaNotaShowComponent [holderType]="'App\\Camp'" [holderId]="getCampService().currentCampId" [holderName]="holderName"></app-primanota-show>

