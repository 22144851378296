import { Component, OnInit, ViewChild } from '@angular/core';
import { TheatrinoService, UserService } from "../_services/index";
import { ActivatedRoute } from '@angular/router';
import { TheatrinoTourInfoFormComponent } from './theatrino-tour-info-form/theatrino-tour-info-form.component';
import { TheatrinoAccomodationMultiFormModalComponent } from './../theatrino/theatrino-accomodation-multi-form-modal/theatrino-accomodation-multi-form-modal.component';
import { Title }     from '@angular/platform-browser';
import { TheatrinoTourBookingCalendarDayComponent } from 'app/theatrino/theatrino-tour-booking-calendar-day/theatrino-tour-booking-calendar-day.component';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-theatrino',
  templateUrl: './theatrino.component.html',
  styleUrls: ['./theatrino.component.css']
})
export class TheatrinoComponent implements OnInit {

  @ViewChild('theatrinoTourInfoForm', {static: true}) public theatrinoTourInfoForm: TheatrinoTourInfoFormComponent;

  @ViewChild('theatrinoAccomodationModal', {static: true}) public theatrinoAccomodationMultiFormModal: TheatrinoAccomodationMultiFormModalComponent
  @ViewChild('theatrinoDayModal', {static: true}) public theatrinoDayModal: TheatrinoTourBookingCalendarDayComponent;
  @ViewChild('exportLoading', {static: false}) public exportLoading: ModalDirective;

  private subject: any;

  loadingData: boolean = true;

  // MODAL EXPORT
  isModalExport: boolean = false;

  constructor(private theatrinoService: TheatrinoService, private userService: UserService, private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit() {

    this.titleService.setTitle("Theatrino");

    this.subject = this.route.params.subscribe(params => {

      this.theatrinoService.resetService();

      if (typeof params['idCurrentTheatrino'] != "undefined") {

        this.theatrinoService.currentTheatrinoId = +params['idCurrentTheatrino'];
        this.theatrinoService.getTheatrinoTourInfoAll( () => {

          this.titleService.setTitle("Theatrino > "+this.theatrinoService.currentTheatrinoInfo.denomination);

          this.loadingData = false;
        }, () => {
          this.loadingData = false;
        });
      }
      else
      {
        this.loadingData = false;
        this.theatrinoTourInfoForm.editMode = true;
      }

    });

  }

  editForm() {
    this.theatrinoTourInfoForm.editMode = !this.theatrinoTourInfoForm.editMode;
  }


  ModalLoading (event)
  {
    this.isModalExport = event;

    if (this.isModalExport)
    {
      this.exportLoading.show();
    }
    else
    {
      this.exportLoading.hide();
    }
  }

  getTheatrinoService() {
    return this.theatrinoService
  }

  getUserService(){
    return this.userService;
  }
}
