import { ConfigService } from './config.service';
import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpParams } from '@angular/common/http';

declare var saveAs: any;

@Injectable()
export class EnterpriseService {

	currentEnterpriseId;
	currentEnterpriseInfo: any;

	currentEnterpriseContactList: any;
	currentEnterpriseAddressList: any;
	currentEnterpriseBankAccountList: any;
	currentEnterpriseDocumentList: any;
	currentEnterpriseAccomodation:any[];
  	currentEnterpriseBookingList:any;
  	currentEnterpriseCampList:any;
	currentAgreement:any;
	currentCampAgreement:any;

	idDefaultType: any;

	ongoingRequest: boolean = false;

	//	Memorizza l'ultima query di ricerca fatta
	lastSearchQueries: any = null;

	private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

	constructor(private backend: BackendService, private notify: NotificationsService,
				private configService: ConfigService) { }

	getsubjectToUpdateObservable(): Observable<boolean> {
		return this.subjectToUpdate.asObservable();
	}

	resetService() {
		this.currentEnterpriseId = null;
		this.currentEnterpriseInfo = null;
		this.currentEnterpriseContactList = null;
		this.currentEnterpriseAddressList = null;
		this.currentEnterpriseBankAccountList = null;
		this.currentEnterpriseDocumentList = null;
		this.currentEnterpriseAccomodation = null;
    this.currentEnterpriseBookingList = null;
    this.currentEnterpriseCampList = null;
		this.currentAgreement = null;
		this.currentCampAgreement = null;
		this.idDefaultType = null;
	}

	startRequest() {
    this.ongoingRequest = true;
	}

	finishRequest() {
		this.ongoingRequest = false;
	}

	isOngoingRequest() {
		return this.ongoingRequest;
	}

	public MAX_NUM_OF_EXPORT = 150000;

	checkPrimary(model: any, modelList: any[]) {

		if (!model.primary || model.primary == 0) {

			let primaryFound = false;

			for (let k in modelList) {
				let entry = modelList[k];

				if (entry.primary == true || entry.primary == 1) {
					primaryFound = true;
				}
			}

			if (!primaryFound)
				return false;
		}

		return true;
	}


	checkEnterpriseAddress()
	{
		let check = false;
		if (this.currentEnterpriseAddressList != null && typeof this.currentEnterpriseAddressList != "undefined")
		{
			(Object.keys(this.currentEnterpriseAddressList)).forEach(element => {

				if (this.currentEnterpriseAddressList[element].primary == true)
					check = true;
			})
		}

		return check;
	}


	/* API di esportazione di tutti i campi */
	exportAll(model: any, callbackSuccess: any = null, callbackError: any = null) {

	let params = new HttpParams();
	params = params.set('page_size', '-1');

	this.backend.get('enterprise/export/' + model.simpleSearchKeyword, params).subscribe(
		(response) => {

		if (callbackSuccess != null)
			callbackSuccess(response);
		},
		(error) => {
		this.backend.showErrors(error);

		if (callbackError != null)
			callbackError();
		}
	);

	}

	exportAllAdvanced(model: any, callbackSuccess: any = null, callbackError: any = null) {

	let params = new HttpParams();
	params = params.set('page_size', '-1');

		this.backend.post('enterprise/export/advanced', model, params).subscribe(
		(response) => {

		if (callbackSuccess != null)
			callbackSuccess(response);
		},
		(error) => {
		this.backend.showErrors(error);

		if (callbackError != null)
			callbackError();
		}
	);
	}



	getEnterpriseInfoAll(callbackSuccess: any = null, callbackError: any = null) {

    // this.startRequest();

		let params = new HttpParams();

		this.backend.get('enterprise/' + this.currentEnterpriseId + '/all', params).subscribe(
			(response) => {

				let resJson : any = response;
        this.currentEnterpriseInfo = resJson;
        if (this.currentEnterpriseInfo) {
          this.currentEnterpriseId = resJson.id;
          this.currentEnterpriseContactList = resJson.contact;
          this.currentEnterpriseAddressList = resJson.address;
          this.currentEnterpriseBankAccountList = resJson.bankaccount;
          this.currentEnterpriseDocumentList = resJson.document;
          this.currentEnterpriseAccomodation = resJson.accomodation;
          this.currentEnterpriseBookingList = resJson.booking;
          this.currentEnterpriseCampList = resJson.camps;
        }

				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			});
	}


	//	restituisce tutte le informazioni dell'enterprise senza passare per il service
	getEnterpriseInfoAllSummary(enterpriseId, callbackSuccess: any = null, callbackError: any = null) {

		let params = new HttpParams();

		this.backend.get('enterprise/' + enterpriseId + '/all', params).subscribe(
			(response) => {

				let resJson : any = response;

				if (callbackSuccess)
					callbackSuccess(resJson);
			},
			(error) => {
				this.backend.showErrors(error);

				if (callbackError)
					callbackError();
			});
	}

	saveEnterpriseInfo(model: any[], callbackSuccess: any) {

		this.startRequest();

		this.backend.post('enterprise', model).subscribe(
			(response) => {

				let resJson : any = response;
				this.currentEnterpriseInfo = resJson;
				this.currentEnterpriseId = resJson.id;
				// this.currentEnterpriseContactList = resJson.contact;
				// this.currentEnterpriseAddressList = resJson.address;
				// this.currentEnterpriseBankAccountList = resJson.bankaccount;
				// this.currentEnterpriseDocumentList = resJson.document;
				// this.currentEnterpriseAccomodation = resJson.accomodation;
				this.subjectToUpdate.next(true);

				this.notify.success("Enterpriseal Info", "successfully saved");

				this.finishRequest();

				callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}

	updateEnterpriseInfo(model: any[]) {

		this.startRequest();

		this.backend.put('enterprise/' + this.currentEnterpriseId, model).subscribe(
			(response: any) => {

				// this.currentEnterpriseInfo : any = response;
				this.currentEnterpriseId = response.id;

				this.getEnterpriseInfoAll( () => {
					this.notify.success("Enterprise Info", "successfully updated");
				});

				// this.subjectToUpdate.next(true);

				// this.finishRequest();
			},
			(error) => {

				this.backend.showErrors(error);

				console.log("Errore");
				console.log(error);

				this.finishRequest();
			});
	}

	deleteEnterpriseInfo(model: any, callbackSuccess: any = null) {

		this.startRequest();

		this.backend.destroy('enterprise/' + this.currentEnterpriseId).subscribe(
			(response) => {

				this.resetService();

				this.notify.success("Enterprise Info", "successfully deleted");

				this.finishRequest();

				this.subjectToUpdate.next(true);

				if (callbackSuccess)
				callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}












	saveEnterpriseContact(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.post('enterprise/' + this.currentEnterpriseId + '/contact', model).subscribe(
			(response) => {

				var contactList : any = response;

				if (this.currentEnterpriseContactList == null)
					this.currentEnterpriseContactList = {};

				this.currentEnterpriseContactList = contactList;
				this.subjectToUpdate.next(true);

				this.notify.success("Contact", "successfully saved");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}

	updateEnterpriseContact(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.put('enterprise/' + this.currentEnterpriseId + '/contact/' + model.id, model).subscribe(
			(response) => {

				var contactList : any = response;

				if (this.currentEnterpriseContactList == null)
					this.currentEnterpriseContactList = {};

				this.currentEnterpriseContactList = contactList;
				this.subjectToUpdate.next(true);

				this.notify.success("Contact", "successfully updated");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}

	deleteEnterpriseContact(model: any) {

		this.startRequest();

		this.backend.destroy('enterprise/' + this.currentEnterpriseId + '/contact/' + model.id).subscribe(
			(response) => {

				var contactList : any = response;

				if (this.currentEnterpriseContactList == null)
					this.currentEnterpriseContactList = {};

				this.currentEnterpriseContactList = contactList;
				this.subjectToUpdate.next(true);

				this.notify.success("Contact", "successfully deleted");

				this.finishRequest();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}










	saveEnterpriseBankAccount(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.post('enterprise/' + this.currentEnterpriseId + '/bankaccount', model).subscribe(
			(response) => {

				var bankAccountList : any = response;

				if (this.currentEnterpriseBankAccountList == null)
					this.currentEnterpriseBankAccountList = {};

				this.currentEnterpriseBankAccountList = bankAccountList;
				this.subjectToUpdate.next(true);

				this.notify.success("Bank Account", "successfully saved");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}

	updateEnterpriseBankAccount(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.put('enterprise/' + this.currentEnterpriseId + '/bankaccount/' + model.id, model).subscribe(
			(response) => {

				var bankAccountList : any = response;

				if (this.currentEnterpriseBankAccountList == null)
					this.currentEnterpriseBankAccountList = {};

				this.currentEnterpriseBankAccountList = bankAccountList;
				this.subjectToUpdate.next(true);

				this.notify.success("Bank Account", "successfully updated");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}

	deleteEnterpriseBankAccount(model: any) {

		this.startRequest();

		this.backend.destroy('enterprise/' + this.currentEnterpriseId + '/bankaccount/' + model.id).subscribe(
			(response) => {

				var bankAccountList : any = response;

				if (this.currentEnterpriseBankAccountList == null)
					this.currentEnterpriseBankAccountList = {};

				this.currentEnterpriseBankAccountList = bankAccountList;
				this.subjectToUpdate.next(true);

				this.notify.success("Bank Account", "successfully deleted");

				this.finishRequest();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}









	saveEnterpriseAddress(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.post('enterprise/' + this.currentEnterpriseId + '/address', model).subscribe(
			(response) => {

				var addressList : any = response;

				if (this.currentEnterpriseAddressList == null)
					this.currentEnterpriseAddressList = {};

				this.currentEnterpriseAddressList = addressList;
				this.subjectToUpdate.next(true);

				this.notify.success("Address", "successfully saved");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}

	updateEnterpriseAddress(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.put('enterprise/' + this.currentEnterpriseId + '/address/' + model.id, model).subscribe(
			(response) => {

				var addressList : any = response;

				if (this.currentEnterpriseAddressList == null)
					this.currentEnterpriseAddressList = {};

				this.currentEnterpriseAddressList = addressList;
				this.subjectToUpdate.next(true);

				this.notify.success("Address", "successfully updated");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}

	deleteEnterpriseAddress(model: any) {

		this.startRequest();

		this.backend.destroy('enterprise/' + this.currentEnterpriseId + '/address/' + model.id).subscribe(
			(response) => {

				var addressList : any = response;

				if (this.currentEnterpriseAddressList == null)
					this.currentEnterpriseAddressList = {};

				this.currentEnterpriseAddressList = addressList;
				this.subjectToUpdate.next(true);

				this.notify.success("Address", "successfully deleted");

				this.finishRequest();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}










	saveEnterpriseDocument(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.post('enterprise/' + this.currentEnterpriseId + '/document', model).subscribe(
			(response) => {

				var documents : any = response;

				if (this.currentEnterpriseDocumentList == null)
					this.currentEnterpriseDocumentList = {};

				this.currentEnterpriseDocumentList = documents;
				this.subjectToUpdate.next(true);

				this.notify.success("Document", "successfully saved");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}

	updateEnterpriseDocument(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.put('enterprise/' + this.currentEnterpriseId + '/document/' + model.id, model).subscribe(
			(response) => {

				var documents : any = response;

				if (this.currentEnterpriseDocumentList == null)
					this.currentEnterpriseDocumentList = {};

				this.currentEnterpriseDocumentList = documents;
				this.subjectToUpdate.next(true);

				this.notify.success("Document", "successfully saved");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}

	deleteEnterpriseDocument(model: any) {

		this.startRequest();

		this.backend.destroy('enterprise/' + this.currentEnterpriseId + '/document/' + model.id).subscribe(
			(response) => {

				var document : any = response;

				if (this.currentEnterpriseDocumentList == null)
					this.currentEnterpriseDocumentList = {};

				this.currentEnterpriseDocumentList = document;
				this.subjectToUpdate.next(true);

				this.notify.success("Document", "successfully deleted");

				this.finishRequest();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}


	getEnterpriseDocumentFileUploadURL(model: any) {
		return (environment.apiUrl + 'enterprise/' + this.currentEnterpriseId + '/document/' + model.id + '/upload');
	}

  public enterpriseDocumentFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
      this.notify.success("Document File", 'successfully uploaded');
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Document File', JSON.parse(response)['message']);
      else
        this.notify.error('Document File', response);

      this.backend.showErrors(response);
    }
  }

	deleteEnterpriseDocumentFile(model: any) {

		this.startRequest();

		this.backend.destroy('enterprise/' + this.currentEnterpriseId + '/document/' + model.id + '/destroyFile').subscribe(
			(response) => {

				var document : any = response;

				if (this.currentEnterpriseDocumentList == null)
					this.currentEnterpriseDocumentList = {};

				this.currentEnterpriseDocumentList = document;
				this.subjectToUpdate.next(true);

				this.notify.success("Document", "successfully deleted image");

				this.finishRequest();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}





	saveEnterpriseAccomodation(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.post('enterprise/' + this.currentEnterpriseId + '/accomodation', model).subscribe(
			(response) => {

				var accomodation : any = response;

				if (this.currentEnterpriseAccomodation == null)
					this.currentEnterpriseAccomodation = [];

				this.currentEnterpriseAccomodation = accomodation;
				this.subjectToUpdate.next(true);

				this.notify.success("Accomodation", "successfully saved");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}

	updateEnterpriseAccomodation(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.put('enterprise/' + this.currentEnterpriseId + '/accomodation/' + model.id, model).subscribe(
			(response) => {

				var accomodation : any = response;

				if (this.currentEnterpriseAccomodation == null)
					this.currentEnterpriseAccomodation = [];

				this.currentEnterpriseAccomodation = accomodation;
				this.subjectToUpdate.next(true);

				this.notify.success("Accomodation", "successfully updated");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}




	getAgreement (agreementIds: any[], callbackSuccess: any = null, callbackError: any = null)
	{
		this.startRequest();

		let params = new HttpParams();

		this.backend.get('enterprise/' + this.currentEnterpriseId + '/agreement/' + agreementIds[0] + '/all', params).subscribe (
			(response) => {

				var resJson : any = response;

				this.currentAgreement = resJson;

				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}


	createAgreement (dateIds:any[], callbackSuccess: any = null, callbackError: any = null)
	{
		this.startRequest();

		let request: any = {tour_ids: dateIds};

		this.backend.post('enterprise/' + this.currentEnterpriseId + '/agreement/create', request).subscribe(

			(response) => {

			  var resJson : any = response;

			  if (this.currentAgreement == null)
				this.currentAgreement = {};

			  this.currentAgreement = resJson;

			  this.subjectToUpdate.next(true);

			  this.finishRequest();

			  if (callbackSuccess)
				callbackSuccess();
			},
			(error) => {
			  this.backend.showErrors(error);

			  this.finishRequest();

			  if (callbackError)
				callbackError();
			});
  }

  createPdfAgreement (callbackSuccess: any = null, callbackError: any = null)
	{
		this.backend.download('enterprise/' + this.currentEnterpriseId + '/agreement/' + this.currentAgreement['id'] + '/pdf', this.currentAgreement['number'] + '.pdf');
  }

  sendEmailAgreementTo (email: string, callbackSuccess: any = null, callbackError: any = null)
	{
    this.startRequest();

		let params = new HttpParams();

		this.backend.get('enterprise/' + this.currentEnterpriseId + '/agreement/' + this.currentAgreement['id'] + '/mailto/' + email, params).subscribe (
			(response) => {

				// var resJson : any = response;

				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}



	confirmAgreementCreation (dateIds:any[], callbackSuccess: any = null, callbackError: any = null)
	{
		this.startRequest();

		let request: any = {tour_ids: dateIds};

		this.backend.post('enterprise/' + this.currentEnterpriseId + '/agreement/confirmcreation', request).subscribe(

			(response) => {

				var resJson : any = response;

				this.currentAgreement = resJson;

				// this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}




	deleteAgreement(callbackSuccess: any = null, callbackError: any = null)
	{
		this.startRequest();

			this.backend.destroy('enterprise/' + this.currentEnterpriseId + '/agreement/' + this.currentAgreement.id).subscribe(
				(response) => {

					this.notify.success("Agreement", "successfully deleted");

					this.finishRequest();

					this.subjectToUpdate.next(true);

					if (callbackSuccess)
						callbackSuccess();
				},
				(error) => {
					this.backend.showErrors(error);

					this.finishRequest();

					if (callbackError)
						callbackError();
				}
			);
	}



	getAgreementDocumentFileUploadURL (model: any) {
		return (environment.apiUrl + 'enterprise/' + this.currentEnterpriseId + '/agreement/' + this.currentAgreement.id + '/document/upload');
	}


  public agreementDocumentFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
      this.currentAgreement.url_document = response;
      this.currentAgreement.status = 2;
			this.subjectToUpdate.next(true);
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Agreement', JSON.parse(response)['message']);
      else
        this.notify.error('Agreement', response);

      this.backend.showErrors(response);
    }
  }


	deleteAgreementDocumentFile(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.destroy('enterprise/' + this.currentEnterpriseId + '/agreement/' + this.currentAgreement.id + '/document/destroyFile').subscribe(
			(response) => {

				var resJson : any = response;

				this.currentAgreement = resJson;

				this.subjectToUpdate.next(true);

				this.notify.success("Agreement", "successfully deleted document");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}






	getCampAgreement (agreementIds: any[], callbackSuccess: any = null, callbackError: any = null)
	{
		this.startRequest();

		let params = new HttpParams();

		this.backend.get('enterprise/' + this.currentEnterpriseId + '/agreementcamp/' + agreementIds[0] + '/all', params).subscribe (
			(response) => {

				var resJson : any = response;

				this.currentCampAgreement = resJson;

				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}


	createCampAgreement (campId, callbackSuccess: any = null, callbackError: any = null)
	{
		this.startRequest();

    let request: any = {camp_id: campId};

		this.backend.post('enterprise/' + this.currentEnterpriseId + '/agreementcamp/create', request).subscribe(

			(response) => {

			  var resJson : any = response;

			  if (this.currentCampAgreement == null)
				this.currentCampAgreement = {};

			  this.currentCampAgreement = resJson;

			  this.subjectToUpdate.next(true);

			  this.finishRequest();

			  if (callbackSuccess)
				callbackSuccess();
			},
			(error) => {
			  this.backend.showErrors(error);

			  this.finishRequest();

			  if (callbackError)
				callbackError();
			});
  }

  createPdfCampAgreement (callbackSuccess: any = null, callbackError: any = null)
	{
		this.backend.download('enterprise/' + this.currentEnterpriseId + '/agreementcamp/' + this.currentCampAgreement['id'] + '/pdf', this.currentCampAgreement['number'] + '.pdf');
  }

  sendEmailCampAgreementTo (email: string, callbackSuccess: any = null, callbackError: any = null)
	{
    this.startRequest();

		let params = new HttpParams();

		this.backend.get('enterprise/' + this.currentEnterpriseId + '/agreementcamp/' + this.currentCampAgreement['id'] + '/mailto/' + email, params).subscribe (
			(response) => {

				// var resJson : any = response;

				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}



	confirmCampAgreementCreation (campId, callbackSuccess: any = null, callbackError: any = null)
	{
		this.startRequest();

		let request: any = {camp_id: campId};

		this.backend.post('enterprise/' + this.currentEnterpriseId + '/agreementcamp/confirmcreation', request).subscribe(

			(response) => {

				var resJson : any = response;

				this.currentCampAgreement = resJson;

				// this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {

				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}




	deleteCampAgreement(callbackSuccess: any = null, callbackError: any = null)
	{
		this.startRequest();

			this.backend.destroy('enterprise/' + this.currentEnterpriseId + '/agreementcamp/' + this.currentCampAgreement.id).subscribe(
				(response) => {

					this.notify.success("Agreement", "successfully deleted");

					this.finishRequest();

					this.subjectToUpdate.next(true);

					if (callbackSuccess)
						callbackSuccess();
				},
				(error) => {
					this.backend.showErrors(error);

					this.finishRequest();

					if (callbackError)
						callbackError();
				}
			);
	}



	getCampAgreementDocumentFileUploadURL (model: any) {
		return (environment.apiUrl + 'enterprise/' + this.currentEnterpriseId + '/agreementcamp/' + this.currentCampAgreement.id + '/document/upload');
	}


  public campAgreementDocumentFileResponse(item:any, response:any, status:any, id: String) {

    if (status === 200) {
      this.currentCampAgreement.url_document = response;
      this.currentCampAgreement.status = 2;
			this.subjectToUpdate.next(true);
    }
    else {
      if (response != null && typeof response != "undefined")
        this.notify.error('Agreement File', JSON.parse(response)['message']);
      else
        this.notify.error('Agreement File', response);

      this.backend.showErrors(response);
    }
  }



	deleteCampAgreementDocumentFile(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		this.backend.destroy('enterprise/' + this.currentEnterpriseId + '/agreementcamp/' + this.currentCampAgreement.id + '/document/destroyFile').subscribe(
			(response) => {

				var resJson : any = response;

				this.currentCampAgreement = resJson;

				this.subjectToUpdate.next(true);

				this.notify.success("Agreement", "successfully deleted document");

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);
	}



}
