import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PersonDatatableComponent, PersonSearchFormComponent, PersonInfoShowComponent } from "../../person/index";
import { GroupService } from '../../_services/index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-actor-group-info-form',
  templateUrl: './actor-group-info-form.component.html',
  styleUrls: ['./actor-group-info-form.component.css']
})
export class ActorGroupInfoFormComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('personDatatable', {static: false}) public personDatatable: PersonDatatableComponent;
  @ViewChild('personSearchForm', {static: false}) public personSearchForm: PersonSearchFormComponent;
  @ViewChildren('personInfoShow') public personInfoShow: QueryList<PersonInfoShowComponent>;

  @ViewChildren('group_name') public groupName: any;
  @ViewChildren('form') public form: any;

  model: any = {};
  selected: any;
  subscribe: any;

  constructor(private groupService: GroupService, private router: Router) { }

  ngOnInit() {

    this.subscribe = this.groupService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {
      this.model = this.groupService.currentGroupInfo;

      //   se ho una persona memorizzata la passo al metodo che la salverà
      if (this.selected != null)
        this.personSelected(this.selected);

      //  Setto il modello vuoto
      if (this.model == null) this.model = {};

    });

      //  Se è un nuovo gruppo setto di defaul il gruppo attivo
      if (typeof this.model.id == 'undefined') this.model.active = true;
  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }

  submit() {

    if (this.model.id != null)
      this.groupService.updateActorGroupInfo(this.model);
    else
      this.groupService.saveActorGroupInfo(this.model);
  }

  destroy() {

    this.groupService.deleteActorGroupInfo(this.model, () => {
      this.router.navigate(["actorgroup/home"]);
    });

  }


  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    if (this.autoShownModal)
      this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }


  searchKeywordsSubmit(params) {
    this.personDatatable.query = params;
    this.personDatatable.getResults();
  }

   personSelected(selected) {

    this.hideModal();

    if (!this.model.id)
    {
      this.selected = selected;
      this.groupName.first.control.markAsDirty()

      this.submit();
    }
    else
    {
      //  Setto il membro appartentente a questo gruppo
      this.groupService.addGroupMember(selected, () => {
        this.groupService.getActorGroupInfoAll();
      });

      this.selected = null;
    }


  }

  updateMemberInfo(memberInfo) {
    //  Aggiorno il membro senza ricaricare le info del gruppo
    this.groupService.updateGroupMember(memberInfo);
  }

  destroyMember(idMember) {
    //  Cancello il membro appartenente a questo gruppo
    this.groupService.removeGroupMember(idMember, ()=> {
      this.groupService.getActorGroupInfoAll();
    });
  }


  getGroupService() {
    return this.groupService
  }

}
