<!-- BEGIN FORM -->
<form (keydown.enter)="checkSubmitKeyEnter($event)" action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">
    <h3 class="form-section">Personal Info</h3>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || surname.dirty || surname.touched) && !surname.valid && !surname.disabled }">
          <label class="control-label">Surname <span class="required">*</span></label>
          <input [disabled]="getPersonService().isOngoingRequest()" type="text" name="surname" [(ngModel)]="model.surname" #surname="ngModel" required class="form-control" placeholder="Chee Kin">
          <span class="help-block">Surname is required </span>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || first_name.dirty || first_name.touched) && !first_name.valid  && !first_name.disabled}">
          <label class="control-label">First Name <span class="required">*</span></label>
          <input [disabled]="getPersonService().isOngoingRequest()" type="text" name="first_name" [(ngModel)]="model.first_name" #first_name="ngModel" required class="form-control" placeholder="Lim">
          <span class="help-block">First name is required</span>
        </div>
        <div class="form-group">
          <label>Privacy Agreement</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input [disabled]="getPersonService().isOngoingRequest()" type="checkbox" name="privacy" [(ngModel)]="model.privacy" value="1"> Yes
              <span></span>
            </label>
          </div>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || tax_id.dirty || tax_id.touched) && !tax_id.valid && !tax_id.disabled }">
          <label class="control-label">Codice Fiscale/Tax ID &nbsp;
            <check-person-taxid [personInfo]="model"></check-person-taxid>
          </label>
          <input [disabled]="getPersonService().isOngoingRequest()" type="text" name="tax_id" [(ngModel)]="model.tax_id" #tax_id="ngModel" maxlength="16" class="form-control" placeholder="CF/Tax ID">
          <span class="help-block">Tax ID or Italian CF (max length 16 chars)</span>
        </div>
      </div>

      <div class="col-md-3 col-md-offset-3 mt-element-card">
        <alert type="warning" *ngIf="model.id == undefined">
          <i class="fa fa-warning"></i> First save the form
        </alert>
        <div class="mt-card-item">
          <div *ngIf="model.avatarUrl" class="mt-overlay-1" aria-hidden="true">
            <img src="{{model.avatarUrl}}?{{this.model.updated_at}}" style="width: 100%;">
          </div>
          <div *ngIf="!model.avatarUrl" class="mt-overlay-1" aria-hidden="true">
            <img src="../assets/placeholder/avatar-placeholder.jpg" width="100%">
          </div>
          <div [appCheckPermission]="'person_edit'" *ngIf="model.id != undefined">
            <!-- mikone upload format -->
            <div *ngIf="model.avatarUrl == undefined || model.avatarUrl == ''">
              <input *ngIf="!photoUploader.isUploading" type="file" id="photoUploader"
                ng2FileSelect [uploader]="photoUploader" />
              <label *ngIf="!photoUploader.isUploading" for="photoUploader"
                class="btn btn-lg btn-block label-file green-jungle text-uppercase"><i class="fa fa-upload"></i>&nbsp; Upload picture!</label>
            </div>
            <div *ngIf="photoUploader.isUploading || getPersonService().isOngoingRequest()">
              <img src="./assets/global/img/input-spinner.gif" />
              please wait...
            </div>
            <div *ngIf="model.avatarUrl != undefined && model.avatarUrl != ''" style="padding: 10px;">
              <button *ngIf="!photoUploader.isUploading" type="button"
                class="btn btn-danger btn-block text-uppercase label-file" (click)="destroyFile();"><i
                  class="fa fa-trash"></i>&nbsp; Remove Picture</button>
            </div>
            <!-- END -->
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || gender.dirty || gender.touched) && !gender.valid && !gender.disabled }">
          <label class="control-label">Gender <span class="required">*</span></label>
          <select [disabled]="getPersonService().isOngoingRequest()" class="form-control" name="gender" [(ngModel)]="model.gender" #gender="ngModel" required>
            <option *ngFor="let g of getConfigService().genderPersonSelect" [value]="g.key">{{g.value}}</option>
          </select>
          <span class="help-block"> Select your gender, gender is required</span>
        </div>
      </div>
      <div class="col-md-3 col-md-offset-3">
        <div class="form-group">
          <div [appCheckPermission]="'person_edit'" *ngIf="model.id">
            <div *ngIf="model.signaturePath == undefined || model.signaturePath == ''">
              <input *ngIf="!signutureUploader.isUploading" type="file" id="signutureUploader"
                ng2FileSelect [uploader]="signutureUploader" />
              <label *ngIf="!signutureUploader.isUploading" for="signutureUploader"
                class="btn btn-lg green-jungle btn-block text-uppercase label-file"><i class="fa fa-upload"></i>&nbsp; Upload Signature!</label>
            </div>
            <div *ngIf="signutureUploader.isUploading || getPersonService().isOngoingRequest()">
              <img src="./assets/global/img/input-spinner.gif" /> please wait...
            </div>
            <div *ngIf="model.id && model.signaturePath">
              <div *ngIf="model.signaturePath" aria-hidden="true">
                <img src="{{model.signaturePath}}?{{this.model.updated_at}}" style="width: 100%;">
              </div>
            </div>
            <div *ngIf="model.signaturePath != undefined && model.signaturePath != ''" style="padding: 10px;">
              <button *ngIf="!signutureUploader.isUploading" type="button"
                class="btn btn-danger btn-block text-uppercase label-file" (click)="destroySignature();"><i
                  class="fa fa-trash"></i>&nbsp; Remove Signature</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" *ngIf="model.gender == 'c'">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || custom_gender.dirty || custom_gender.touched) && !custom_gender.valid && !custom_gender.disabled }">
          <label class="control-label">Custom Gender <span class="required">*</span></label>
          <input [disabled]="getPersonService().isOngoingRequest()" class="form-control" name="custom_gender" [(ngModel)]="model.custom_gender" #custom_gender="ngModel" required>
          <span class="help-block"> Custom gender is required</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(localData, false)) }">
          <label class="control-label">Date of Birth</label>
            <div class="input-group">
              <!-- input box -->
              <input angular-mydatepicker #dp_birthday="angular-mydatepicker" name="birthday" class="form-control"
                [locale]="getHelper().locale"
                [disabled]="getPersonService().isOngoingRequest()"
                [options]="getDatePickerOptions()"
                [ngModel]="getLocalData()"
                (dateChanged)="onDateChanged($event)"
              />
              <!-- clear date button -->
              <span class="input-group-btn">
                <button type="button" *ngIf="model?.birthday" class="btn default" (click)="dp_birthday.clearDate()"><i class="fa fa-close"></i></button>
              </span>
              <!-- toggle calendar button -->
              <span class="input-group-btn">
                  <button type="button" class="btn default" (click)="dp_birthday.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
              </span>
          </div>
          <span class="help-block" *ngIf="model.birthday else noAge">Età calcolata: <b>{{calculateAge}} anni</b> </span>
          <ng-template #noAge>
            <span class="help-block"></span>
          </ng-template>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Place of Birth</label>
          <input [disabled]="getPersonService().isOngoingRequest()" type="text" class="form-control" class="form-control" placeholder="City" name="birthplace" [(ngModel)]="model.birthplace" #birthplace="ngModel" (setAddress)="getAddress($event)"
            googleplace/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">Nationality</label>
          <app-country-picker [disabled]="getPersonService().isOngoingRequest()" name="nationality" #nationality="ngModel" (euro)="european = $event" [(ngModel)]="model.nationality"></app-country-picker>
          <div *ngIf="european != undefined">
            <span class="help-block" *ngIf="european"> Cittadino appartenenete alla comunità europea: <b>SI</b> </span>
            <span class="help-block" *ngIf="!european"> Cittadino appartenenete alla comunità europea: <b>NO</b> </span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Native/highly proficient English speaker</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input [disabled]="getPersonService().isOngoingRequest()" type="checkbox" name="native_english_speaker" [(ngModel)]="model.native_english_speaker" value="1"> Yes
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">T-Shirt size</label>
          <select [disabled]="getPersonService().isOngoingRequest()" class="form-control" name="tshirt_size" [(ngModel)]="model.tshirt_size">
            <option *ngFor="let sS of getConfigService().shirtSizePersonSelect" [value]="sS.key">{{sS.value}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Driver's license</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input [disabled]="getPersonService().isOngoingRequest()" type="checkbox" name="driver_license" [(ngModel)]="model.driver_license" value="1"> Yes
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Work type</label>
          <select [disabled]="getPersonService().isOngoingRequest()" class="form-control" name="work_type" [(ngModel)]="model.work_type">
            <option *ngFor="let wT of getConfigService().personWorkSelect" [value]="wT.key">{{wT.value}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3" *ngIf="model.work_type == 1">
        <div class="form-group">
          <label class="control-label">Class type</label>
          <select [disabled]="getPersonService().isOngoingRequest()" class="form-control" name="class_type" [(ngModel)]="model.class_type">
            <option *ngFor="let pwT of getConfigService().personWorkSchoolTypeSelect" [value]="pwT.key">{{pwT.value}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea [disabled]="getPersonService().isOngoingRequest()" name="annotation" [(ngModel)]="model.annotation" rows="3" class="form-control" placeholder="Some notes..."></textarea>
        </div>
      </div>
    </div>

  </div>

  <div  [appCheckPermission]="'person_edit'" class="form-actions right">
    <img *ngIf="getPersonService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getPersonService().isOngoingRequest()" *ngIf="getPersonService().currentPersonId"
       [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="submit" class="btn blue" [disabled]="getPersonService().isOngoingRequest()" *ngIf="getPersonService().currentPersonId">Update</button>
    <button type="submit" class="btn green-jungle" [disabled]="getPersonService().isOngoingRequest()" *ngIf="!getPersonService().currentPersonId">Continue</button>
    <button type="button" class="btn btn-outline blue" [disabled]="getPersonService().isOngoingRequest()" *ngIf="getPersonService().currentPersonId && getPersonService().currentPersonHasRole('9')" (click)="getPersonService().downloadTutorForm()"><i class="fa fa-print" aria-hidden="true"></i>&nbsp;PDF</button>
  </div>

</form>
