import { Component, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfigService, CourseService } from '../../_services/index';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-course-info-form',
  templateUrl: './course-info-form.component.html',
  styleUrls: ['./course-info-form.component.css']
})
export class CourseInfoFormComponent implements OnInit {

  model: any = {};

  subscribe: any;

  private headers: any;
  //  file uploaders
  public coverUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp'],
      url: 'serverUrl'
    }
  );

  constructor(private courseService: CourseService, private router: Router, private configService: ConfigService) { }

  ngOnInit() {

    this.subscribe = this.courseService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.model = this.courseService.currentInfo;

      if (this.model == null) this.model = {};
      else
      {
        //  setto l'url per il caricamento dei file
        this.coverUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
        this.coverUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
          this.coverUploaded(item, response, status);
        };
        this.coverUploader.options.url = this.courseService.getCourseCoverFileUploadURL(this.model);


        //  Se esiste il path per l'immagine costruisco l'url completo
      if (this.model.image_url)
        this.model.coverPath = this.configService.blobBasePath+this.model.image_url;
      }

    });

      //  Se è un nuovo gruppo setto di defaul il gruppo attivo
      if (typeof this.model.id == 'undefined') {
        this.model.active = true;
      }
  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }

  submit() {

    if (this.model.id != null)
      this.courseService.updateCourseInfo(this.model);
    else
      this.courseService.saveCourseInfo(this.model);
  }

  destroy() {

    this.courseService.deleteCourseInfo(this.model, () => {
      this.router.navigate(["course/home"]);
    });

  }


  //  Cover Uploader
  public coverUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.model.coverPath = this.configService.blobBasePath + response;
        this.model.image_url = response;
      }
      this.courseService.courseCoverFileResponse(item, response, status,  this.model.id);
    }
  }

  //  Remove
  destroyCover() {
    this.courseService.deleteCourseCoverFile(this.model);
  }

  getCourseService() {
    return this.courseService
  }

  getConfigService() {
    return this.configService
  }

}
