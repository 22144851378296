import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { PersonDocumentFormComponent } from '../index';
import { PersonService, ConfigService } from '../../_services/index';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-person-document-list',
  templateUrl: './person-document-list.component.html',
  styleUrls: ['./person-document-list.component.css']
})
export class PersonDocumentListComponent implements OnInit {

  @ViewChildren('personDocumentFormList') public personDocumentFormList: QueryList<PersonDocumentFormComponent>;

  personDocumentList: any;
  personDocumentListKey: any[];

  allFormValid = true;
  multiSave = true;

  subscribe: any;

  constructor(private personService: PersonService, private notify: NotificationsService, private configService: ConfigService) { }

  ngOnInit() {

    this.personDocumentList = this.personService.currentPersonDocumentList;

    if (this.personDocumentList == null)
      this.addNew();

    this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      if (this.personService.currentPersonDocumentList != null) {

        let newFormModel = null;
        if (typeof this.personDocumentFormList.first.model.id == "undefined"
          && this.personDocumentFormList.first.currentForm.form.dirty
          && this.personDocumentFormList.first.currentForm.form.pending) {
          newFormModel = this.personDocumentFormList.first.model;
        }

        this.personDocumentListKey = Object.keys(this.personService.currentPersonDocumentList).reverse();

        let personDocumentListOld = this.personDocumentList;
        this.personDocumentList = this.personService.currentPersonDocumentList;

        this.personDocumentListKey.forEach(k => {

          if (typeof personDocumentListOld[k] != "undefined" && this.personDocumentList[k]["updated_at"] == personDocumentListOld[k]["updated_at"]) {
            this.personDocumentList[k] = personDocumentListOld[k];
          }
          else {

            // devo aggiornare manualmente questo campo perché il model non si aggiorna
            //  Se esiste il path per l'immagine costruisco l'url completo
            if (this.personDocumentList[k].file_path)
              this.personDocumentList[k].documentUrl = this.configService.blobBasePath + this.personDocumentList[k].file_path;
          }
        });

        if (newFormModel != null) {
          this.personDocumentList[0] = newFormModel;
          this.personDocumentListKey.unshift(0);
        }

      }

      if (this.personService.currentPersonDocumentList != null && this.personService.currentPersonDocumentList.length == 0) {
        this.addNew();
      }

    });
  }

  ngOnDestroy() {

    this.subscribe.unsubscribe();
  }

  addNew() {

    if (this.personDocumentList == null) {
      this.personDocumentList = {};
      this.personDocumentListKey = new Array();
    }
    this.personDocumentList[0] = {};
    this.personDocumentListKey.unshift(0);
  }


  saveAll() {

    this.allFormValid = true;

    // Controllo se tutti i form sono validi
    this.personDocumentFormList.forEach(element => {

      if (!element.currentForm.form.valid) {

        //  Controllo se non è valido

        for (var i in element.currentForm.controls) {
          element.currentForm.controls[i].markAsTouched();
        }

        this.allFormValid = false;
        this.notify.error("There is a form with error");
      }

    });

    if (!this.allFormValid)
      return;

    this.personDocumentFormList.forEach(element => {
      element.submit();
    });
  }


  formDelete(event) {
    if (!event.model.id && this.personDocumentListKey.length > 1) {
      this.personDocumentListKey.splice(0, 1);
      delete this.personDocumentList[0];
    }
  }

  formSaved(event) {

  }

  getPersonService() {
    return this.personService
  }

}
