<div class="datatable-loader text-center" *ngIf="ongoingRequest || getTutorService().ongoingRequest">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>


<div class="portlet">
    <div class="portlet-body">

      <h3 class="font-green-jungle">Completed</h3>
      <ngx-datatable *ngIf="!ongoingRequest || getTutorService().ongoingRequest" class="striped hidden-empty-datatable" [rows]="tutorsCompleted" [columnMode]="'flex'" [headerHeight]="50"
        [footerHeight]="10" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'">

        <ngx-datatable-column name="Name" prop="surname" [flexGrow]="4" [resizeable]="false" [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            {{row.surname}} {{row.firstname}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Created at" [flexGrow]="4" prop="created_at" [resizeable]="false" [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            {{ value | date:'d MMMM, y - HH:mm':'':'it' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Email" [flexGrow]="5" prop="email" [resizeable]="false" [draggable]="false">
        </ngx-datatable-column>
        <ngx-datatable-column name="Gender" [flexGrow]="2" prop="gender" [resizeable]="false" [draggable]="false">
        </ngx-datatable-column>
        <ngx-datatable-column name="Status" [flexGrow]="3" prop="status" [resizeable]="false" [draggable]="false">
        </ngx-datatable-column>
        <ngx-datatable-column name="" [flexGrow]="2" [resizeable]="false" [draggable]="false" prop="">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <button class="blue btn default btn-sm" (click)="openSelected(row)"><i class="fa fa-edit"
                aria-hidden="true"></i></button>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
          <ng-template  ngx-datatable-footer-template let-rowCount="rowCount">
            <b>Total</b> : {{rowCount}} Tutors
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>

    </div>
  </div>


<div class="portlet">
  <div class="portlet-body">

    <h3>Not Completed</h3>
    <ngx-datatable *ngIf="!ongoingRequest || getTutorService().ongoingRequest" class="striped hidden-empty-datatable" [rows]="tutors" [columnMode]="'flex'" [headerHeight]="50"
      [footerHeight]="10" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'">

      <ngx-datatable-column name="Name" prop="surname" [flexGrow]="4" [resizeable]="false" [draggable]="false">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          {{row.surname}} {{row.firstname}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Created at" [flexGrow]="4" prop="created_at" [resizeable]="false" [draggable]="false">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          {{ value | date:'d MMMM, y - HH:mm':'':'it' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Email" [flexGrow]="5" prop="email" [resizeable]="false" [draggable]="false">
      </ngx-datatable-column>
      <ngx-datatable-column name="Gender" [flexGrow]="2" prop="gender" [resizeable]="false" [draggable]="false">
      </ngx-datatable-column>
      <ngx-datatable-column name="Status" [flexGrow]="3" prop="status" [resizeable]="false" [draggable]="false">
      </ngx-datatable-column>
      <ngx-datatable-column name="" [flexGrow]="2" [resizeable]="false" [draggable]="false" prop="">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <button class="blue btn default btn-sm" (click)="openSelected(row)"><i class="fa fa-edit"
              aria-hidden="true"></i></button>
        </ng-template>
        <ngx-datatable-footer>
          <ng-template  ngx-datatable-footer-template let-rowCount="rowCount">
            <b>Total</b> : {{rowCount}} Tutors
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable-column>
    </ngx-datatable>

  </div>
</div>

<div class="portlet">
  <div class="portlet-body">

    <h3 class="font-red">Rejected</h3>
    <ngx-datatable *ngIf="!ongoingRequest || getTutorService().ongoingRequest" class="striped hidden-empty-datatable" [rows]="tutorsRejected" [columnMode]="'flex'" [headerHeight]="50"
      [footerHeight]="10" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'">

      <ngx-datatable-column name="Name" prop="surname" [flexGrow]="4" [resizeable]="false" [draggable]="false">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          {{row.surname}} {{row.firstname}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Created at" [flexGrow]="4" prop="created_at" [resizeable]="false" [draggable]="false">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          {{ value | date:'d MMMM, y - HH:mm':'':'it' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Email" [flexGrow]="5" prop="email" [resizeable]="false" [draggable]="false">
      </ngx-datatable-column>
      <ngx-datatable-column name="Gender" [flexGrow]="2" prop="gender" [resizeable]="false" [draggable]="false">
      </ngx-datatable-column>
      <ngx-datatable-column name="Status" [flexGrow]="3" prop="status" [resizeable]="false" [draggable]="false">
      </ngx-datatable-column>
      <ngx-datatable-column name="" [flexGrow]="2" [resizeable]="false" [draggable]="false" prop="">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <button class="blue btn default btn-sm" (click)="openSelected(row)"><i class="fa fa-edit"
              aria-hidden="true"></i></button>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-footer>
        <ng-template  ngx-datatable-footer-template let-rowCount="rowCount">
          <b>Total</b> : {{rowCount}} Tutors
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>

  </div>
</div>

<div class="portlet" *ngIf="phase == '4'">
  <div class="portlet-body">

    <h3 class="font-blue">Hired (Imported)</h3>
    <ngx-datatable *ngIf="!ongoingRequest || getTutorService().ongoingRequest" class="striped hidden-empty-datatable" [rows]="tutorsImported" [columnMode]="'flex'" [headerHeight]="50"
      [footerHeight]="10" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'">

      <ngx-datatable-column name="Name" prop="surname" [flexGrow]="4" [resizeable]="false" [draggable]="false">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          {{row.surname}} {{row.firstname}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Created at" [flexGrow]="4" prop="created_at" [resizeable]="false" [draggable]="false">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          {{ value | date:'d MMMM, y - HH:mm':'':'it' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Email" [flexGrow]="5" prop="email" [resizeable]="false" [draggable]="false">
      </ngx-datatable-column>
      <ngx-datatable-column name="Gender" [flexGrow]="2" prop="gender" [resizeable]="false" [draggable]="false">
      </ngx-datatable-column>
      <ngx-datatable-column name="Status" [flexGrow]="3" prop="status" [resizeable]="false" [draggable]="false">
      </ngx-datatable-column>
      <ngx-datatable-column name="" [flexGrow]="2" [resizeable]="false" [draggable]="false" prop="">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <button class="blue btn default btn-sm" (click)="openSelected(row)"><i class="fa fa-edit"
              aria-hidden="true"></i></button>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-footer>
        <ng-template  ngx-datatable-footer-template let-rowCount="rowCount">
          <b>Total</b> : {{rowCount}} Tutors
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>

  </div>
</div>


<!-- BEGIN: MODAL CONTENT TUTORS INFO -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
          <h4 class="modal-title pull-left">{{currentTutorInfo.firstname}} {{currentTutorInfo.surname}} Info Summary</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body form">
        <div class="row">
          <div class="col-md-12">
              <app-tutors-applications-info-summary #tutorsApplicationsInfoSummaryComponent [tutorApplicationId]="currentTutorInfo.id" (closeEvent)="hideModal()"></app-tutors-applications-info-summary>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL CONTENT TUTORS INFO -->
