<alert type="danger" *ngIf="tableData && tableData.length == 0">
  <strong>There is nothing to show on this date !</strong>
</alert>

<!-- BEGIN: risultati della ricerca -->

<div *ngFor="let tdataKey of tableDataKeys">

  <div *ngFor="let tdata of tableData[tdataKey]">

    <!-- Note -->
    <div *ngIf="tdata.type == 2">
      <h2 class="font-red sbold uppercase">{{tdata.date | date:'longDate'}}</h2>
      <div class="row">
        <div class="col-md-12">
          <label class="control-label bold">Annotation:</label>
          <p> {{tdata.annotation}} </p>
        </div>
      </div>
    </div>

    <!-- BEGIN: prenotazione -->
    <div *ngIf="tdata.type == 2">
      <div *ngIf="tdata.shows">
        <div *ngFor="let show of tdata.shows; let i = index">
          <h2 class="font-red sbold uppercase">{{i+1}}° Show</h2>
          <div class="row">
            <div class="col-md-12">
              <label class="control-label bold">School Name:</label>
              <p> {{ tdata.enterprise_info?.denomination }} </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="control-label bold">Address:</label>
              <p> {{ tdata.enterprise_info?.address?.route }} {{ tdata.enterprise_info?.address?.street_number }} - {{ tdata.enterprise_info?.address?.administrative_area_level_3_long_version
                }} - {{ tdata.enterprise_info?.address?.postal_code }} - {{ tdata.enterprise_info?.address?.administrative_area_level_2_short_version
                }}
              </p>
            </div>
            <div class="col-md-6">
              <label class="control-label bold">Contact:</label>
              <p> {{ getConfigService().getContactEnterpriseValueByKey(tdata.enterprise_info?.contact?.type) }} : {{ tdata.enterprise_info?.contact?.value
                }} </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="control-label bold">Teacher Name:</label>
              <p> {{ show.accountable_info?.first_name }} {{ show.accountable_info?.surname }}
              </p>
            </div>
            <div class="col-md-6">
              <label class="control-label bold">Contact:</label>
              <p> {{ getConfigService().getContactPersonValueByKey(show.accountable_info?.contact?.type) }} : {{ show.accountable_info?.contact?.value
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: prenotazione -->

    <!-- BEGIN: singoli spettacoli -->
    <div *ngIf="tdata.shows">
      <h4 class="font-red sbold uppercase">Work detail</h4>

      <!-- BEGIN: header degli spettacoli -->
      <div class="row" style="background-color: rgb(238, 242, 255);">
        <div class="col-xs-2">
          <label class="control-label sbold">Time</label>
        </div>
        <div class="col-xs-4">
          <label class="control-label sbold">Activity</label>
        </div>
        <div class="col-xs-3">
          <label class="control-label sbold">Pupils</label>
        </div>
        <div class="col-xs-3">
          <label class="control-label sbold">Class</label>
        </div>
      </div>
      <!-- END: header degli spettacoli -->


      <div *ngFor="let show of tdata.shows" class="striped">
        <div class="row" *ngIf="show?.show_info">
          <div class="col-xs-2">
            {{ show?.time }}-{{ show?.endTime }}
          </div>
          <div class="col-xs-4">
            {{ show?.show_info?.denomination }}
          </div>
          <div class="col-xs-3">
            {{ this.totalPulips(show) }}
          </div>
          <div class="col-xs-3">
            {{ show?.class_level }}
          </div>
        </div>
        <div class="row" *ngIf="show?.workshop_info">
          <div class="col-xs-2">
            {{ show?.workshop_info?.time }}-{{ show?.workshop_info?.endTime }}
          </div>
          <div class="col-xs-4">
            {{ show?.workshop_info?.denomination }}
          </div>
          <div class="col-xs-3">
          </div>
          <div class="col-xs-3">
          </div>
        </div>
      </div>

    </div>
    <!-- END: singoli spettacoli -->

    <!-- BEGIN: payment -->
    <!-- <div *ngIf="tdata.type == 2">
      <div class="row">
        <div class="col-md-12">
          <h2 class="font-red sbold uppercase">Payment</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label sbold">School name: </label>
            <p> {{ tdata.enterprise_organizer_info?.denomination }} </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label sbold">Payment: </label>
            <p> {{getConfigService().getPaymentTourValueByKey(tdata.payment_type)}} </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label sbold">Price: </label>
            <p> {{ tdata.cost | currency:'EUR':'symbol':'1.2-2':'it'}} </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label sbold">Note:</label>
            <p> {{ tdata.annotation }} </p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- END: payment -->

    <!-- BEGIN: accomodation -->
    <div *ngIf="tdata.type == 5">
      <h2 class="font-red sbold uppercase">Accomodation</h2>
      <div class="row">
        <div class="col-md-12">
          <label class="control-label sbold">Name:</label>
          <p> {{ tdata.enterprise_info?.denomination }} </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="control-label sbold">Address:</label>
          <p> {{ tdata.enterprise_info?.address?.route }} {{ tdata.enterprise_info?.address?.street_number }} - {{ tdata.enterprise_info?.address?.administrative_area_level_3_long_version
            }} - {{ tdata.enterprise_info?.address?.postal_code }} - {{ tdata.enterprise_info?.address?.administrative_area_level_2_short_version
            }}
          </p>
        </div>
        <div class="col-md-6">
          <label class="control-label sbold">Contact:</label>
          <p> {{ getConfigService().getContactEnterpriseValueByKey(tdata.enterprise_info?.contact?.type) }} : {{ tdata.enterprise_info?.contact?.value
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="control-label sbold">Annotation:</label>
          <p> {{ tdata.accomodation_annotation }} </p>
        </div>
      </div>
    </div>
    <!-- END: accomodation -->
  </div>

  <hr>

</div>
