<!-- BEGIN CONTENT BODY -->
<div class="page-content">
  <div class="row">
    <div class="col-md-12">

      <!-- BEGIN PAGE HEADER-->
      <!-- BEGIN PAGE BAR -->
      <div class="page-bar">
        <ul class="page-breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/home']">Home</a>
            <i class="fa fa-circle"></i>
          </li>
          <li>
            <span>Person</span>
          </li>
        </ul>
        <div class="page-toolbar">

        </div>
      </div>
      <!-- BEGIN PAGE TITLE-->
      <h1 class="page-title"> Person Database
        <small></small>
      </h1>
      <!-- END PAGE TITLE-->
      <!-- END PAGE HEADER-->

      <!-- begin: prima riga -->
      <div class="row">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">
            <!--begin: Portlet-Body -->
            <div class="portlet-body">
              <app-person-search-form (searchKeywordsSubmit)="searchKeywordsSubmit($event)"></app-person-search-form>
            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->



      <!-- begin: prima riga -->
      <div class="row" *ngIf="getUserService().checkPermission('person_create')">
        <div class="col-md-12">

          <!--begin: Portlet -->
          <div class="portlet light bordered">
            <!--begin: Portlet-Body -->
            <div class="portlet-body">

              <!-- begin: Inserimento persona -->
              <h3 [appCheckPermission]="'person_create'" class="">Add new</h3>
              <hr>
              <div [appCheckPermission]="'person_create'" class="grid grid-flex grid-responsive-md">
                <div class="row">
                  <div class="col-xs-4 col-sm-2 col-lg-2 margin-top">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/person/new', 1]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Docente</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-xs-4 col-sm-2 col-lg-2 margin-top">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/person/new', 2]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Genitore</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-xs-4 col-sm-2 col-lg-2 margin-top">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/person/new', 3]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Studente</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-xs-4 col-sm-2 col-lg-2 margin-top">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/person/new', 4]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Staff</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-xs-4 col-sm-2 col-lg-2 margin-top">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/person/new', 5]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Dirigente</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-xs-4 col-sm-2 col-lg-2 margin-top">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/person/new', 6]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Attore</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-xs-4 col-sm-2 col-lg-2 margin-top">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/person/new', 9]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Tutor</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-xs-4 col-sm-2 col-lg-2 margin-top">
                    <a class="dashboard-stat dashboard-stat-v2 green" href="javascript:;" [routerLink]="['/person/new', 10]">
                      <div class="visual">
                        <i class="fa fa-plus"></i>
                      </div>
                      <div class="details">
                        <div class="number">
                          <span>Office Staff</span>
                        </div>
                        <div class="desc"> Create new </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <!--end: inserimento persona -->

            </div>
            <!--end: Portlet-Body -->
          </div>
          <!--end: Portlet -->
        </div>
      </div>
      <!--end: prima riga -->
    </div>
  </div>
</div>
<!-- END CONTENT BODY -->
