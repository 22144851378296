import { BackendService, GroupService, HelperService } from './../../_services/index';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ViewChildren } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PersonInfoSummaryComponent } from "./../../person/person-info-summary/person-info-summary.component";
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-group-member-info-show',
  templateUrl: './group-member-info-show.component.html',
  styleUrls: ['./group-member-info-show.component.css']
})
export class GroupMemberInfoShowComponent implements OnInit, OnChanges {

  @Input() idMember: number;
  @Output() destroyMember = new EventEmitter();
  @Output() driverChange = new EventEmitter();

  memberInfo: any;
  currentId: number;
  loading: boolean;

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChildren('personInfoSummaryComponent') personShow: PersonInfoSummaryComponent;

  public localDataStart: IMyDateModel;
  public localDataEnd: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  datePickerOptionsBegin: IAngularMyDpOptions = {};
  datePickerOptionsEnd: IAngularMyDpOptions = {};

  constructor(private backend: BackendService, private groupService: GroupService, private helper: HelperService) {

    //  Inizializzo le info sulle date
    this.datePickerOptionsBegin = helper.datePickerOptions;
    this.datePickerOptionsEnd = helper.datePickerOptions;
  }

  ngOnInit() {

    this.memberInfo = {};
  }

  ngOnChanges() {

    if (this.idMember != this.currentId)
      this.getMemberInfo();
  }

  getMemberInfo() {

    this.loading = true;

    if (typeof this.idMember == "undefined" || this.idMember <= 0) {
      this.memberInfo = {};
      this.currentId = null;
      return;
    }

    this.currentId = this.idMember;

    let params = new HttpParams();

    this.backend.get('groupmember/' + this.currentId, params).subscribe(
      (response) => {

        let resJson : any = response;
        this.memberInfo = resJson;
        this.loading = false;

        if (this.memberInfo == null) this.memberInfo = {};
        else {
          if (this.memberInfo.start_date != null) {
            this.localDataStart = this.helper.initMyDataInputSingle(this.memberInfo.start_date);
            this.validateStartDate();
          }

          if (this.memberInfo.end_date != null) {
            this.localDataEnd = this.helper.initMyDataInputSingle(this.memberInfo.end_date);
            this.validateEndDate();
          }

          this.memberInfo.other_groups_array = new Array();

          if (this.memberInfo.other_groups){

            for (let g in this.memberInfo.other_groups) {
              this.memberInfo.other_groups_array.push(this.memberInfo.other_groups[g]);
            }
          }
        }


      },
      (error) => {
        this.backend.showErrors(error);
        this.loading = false;
      });
  }


  destroy() {
    this.destroyMember.emit(this.idMember);
  }

  dChange() {
    this.driverChange.emit(this.memberInfo);
  }

  clickPerson() {
    //this.router.navigate(['person/edit/' + this.idPerson]);
    this.backend.showErrors("test");
    this.showModal();
  }

  onDateStartChanged(event: IMyDateModel) {

    this.localDataStart = event;

    if (event.singleDate.jsDate != null) {
      this.memberInfo.start_date = this.helper.formatISODate(event);

      this.driverChange.emit(this.memberInfo);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil = event.singleDate.date;

      this.datePickerOptionsEnd = datePickerOpt;
    }
    else {
      this.memberInfo.start_date = null;
      this.datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }



  validateStartDate() {

    if (this.memberInfo.start_date) {
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      let dateS = parseISO(this.memberInfo.start_date);
      datePickerOpt.disableUntil = { year: dateS.getFullYear(), month: dateS.getMonth() + 1, day: dateS.getDate() + 1 }

      this.datePickerOptionsEnd = datePickerOpt;
    }

  }





  onDateEndChanged(event: IMyDateModel) {

    this.localDataEnd = event;

    if (event.singleDate.jsDate != null) {
      this.memberInfo.end_date = this.helper.formatISODate(event);

      this.driverChange.emit(this.memberInfo);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableSince = event.singleDate.date;

      this.datePickerOptionsBegin = datePickerOpt;
    }
    else {
      this.memberInfo.end_date = null;
      this.datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }


  validateEndDate() {
    let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    let dateE = parseISO(this.memberInfo.end_date);
    datePickerOpt.disableSince = { year: dateE.getFullYear(), month: dateE.getMonth() + 1, day: dateE.getDate() + 1 }

    this.datePickerOptionsBegin = datePickerOpt;
  }

  // MODAL
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }


  getGroupService() {
    return this.groupService
  }

  getHelper() {
    return this.helper
  }

}
