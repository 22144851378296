import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild, Input, ViewChildren, QueryList } from '@angular/core';
import { EnterpriseComponent } from '../enterprise.component';
import { EnterpriseDatatableComponent } from '../enterprise-datatable/enterprise-datatable.component';
import { EnterpriseSearchFormComponent } from '../enterprise-search-form/enterprise-search-form.component';
import { EnterpriseInfoShowComponent } from '../enterprise-info-show/enterprise-info-show.component';
import { EnterpriseService, ConfigService } from '../../_services/index';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-enterprise-info-form',
  templateUrl: './enterprise-info-form.component.html',
  styleUrls: ['./enterprise-info-form.component.css']
})
export class EnterpriseInfoFormComponent implements OnInit {

  model: any = {};

  subscribe: any;

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;
  @ViewChild('enterpriseDatatable', {static: false}) public enterpriseDatatable: EnterpriseDatatableComponent;
  @ViewChild('enterpriseSearchForm', {static: false}) public enterpriseSearchForm: EnterpriseSearchFormComponent;
  @ViewChildren('enterpriseInfoShow') public enterpriseInfoShow: EnterpriseInfoShowComponent;

  @Input() enterpriseComponent: EnterpriseComponent;

  constructor(private enterpriseService: EnterpriseService, private router: Router,
    private configService: ConfigService,private notify: NotificationsService) { }

  ngOnInit() {

    //  Setto il type di default se settato
    if (this.enterpriseService.idDefaultType != null)
      this.model.type = this.enterpriseService.idDefaultType;

    this.subscribe = this.enterpriseService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      this.model = this.enterpriseService.currentEnterpriseInfo;

      if (this.model == null) this.model = {};

    });

  }

  ngOnDestroy(){

    this.subscribe.unsubscribe();
  }


  submit() {

    if (this.model.id != null)
      this.enterpriseService.updateEnterpriseInfo(this.model);
    else {
      this.enterpriseService.saveEnterpriseInfo(this.model, () => {

        //  Devo abilitare tutte le tab
        this.enterpriseComponent.activeAllTab();
        this.enterpriseComponent.selectTab(1);

        if (this.model.type != 5)
          this.enterpriseComponent.disableTab(5);
      });
    }
  }

  destroy() {

    this.enterpriseService.deleteEnterpriseInfo(this.model, () => {
      this.router.navigate(["enterprise/home"]);
    });
  }

  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  //  controlla che non esistano più di 1 legale rappresentante
  get checkLegalRappresentativeError (){

    var legalCount = 0;
    if (typeof this.model.representative != "undefined" && this.model.representative != null)
    {
      this.model.representative.forEach(element => {

        if (element.type == 7)
          legalCount++;
      });
    }

    return legalCount >1
  }

//  Quando viene cambiato il tipo di impresa
  onChangeType($event)
  {
    if (typeof this.model.id == "undefined")
      return;

    if (this.model.type == 5)
      this.enterpriseComponent.enableTab(5);
    else
      this.enterpriseComponent.disableTab(5);
  }

  searchKeywordsSubmit(params) {
    this.enterpriseDatatable.query = params;
    this.enterpriseDatatable.getResults();
  }

  resultSelected(selected) {

    if (selected.enterprise_id == this.model.id){
      this.notify.warn("Autority", "the selected venture cannot be in relationship with himself");
      this.hideModal();
      return;
    }

    this.model.superior_id = selected.enterprise_id;
    this.hideModal();

    // if (this.model.id != null)
    //   this.submit();
  }

  removeAutority(){
    this.model.superior_id = null;
    // this.submit();
  }

  getConfigService() {
    return this.configService
  }

  getEnterpriseService() {
    return this.enterpriseService
  }
}
