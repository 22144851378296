<alert type="warning" *ngIf="loadingData">
  <strong>Loading</strong> ...
</alert>



<ngx-datatable *ngIf="!loadingData" class="striped" [rows]="rowsNotCompleted" [columnMode]="'flex'" [headerHeight]="50"
  [footerHeight]="0" [rowClass]="getRowClass" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'"
  (activate)="onActivate($event)" (select)='onSelect($event)' [rowClass]="getRowClass" [scrollbarH]="true" [sorts]="[{prop: 'start_date_reminder', dir: 'desc'}]">
  <ngx-datatable-column name="Status" prop="id" [resizeable]="false" [flexGrow]="1">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <h4><i class="fa {{row.status_icon_class}}" aria-hidden="true"></i></h4>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Date" prop="start_date_reminder" [resizeable]="false" [flexGrow]="2">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ value | date : 'shortDate' }}</span><br>
      <!-- <small>{{ row.created_at_parsed | date }}</small> -->
        <small><span *ngIf="!row.all_the_day && row.start_time_reminder"><i
          class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;{{ row.start_time_reminder }}</span><span *ngIf="row.all_the_day"><i
          class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;All day</span></small>
    </ng-template>
  </ngx-datatable-column>
  <!-- <ngx-datatable-column name="Holder"  prop="holder_name" [resizeable]="false" [flexGrow]="3">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value"><i class="fa fa-{{row.holder_icon}}"></i> {{ value }} <i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze" aria-hidden="true"></i></span>
  </ng-template>
</ngx-datatable-column> -->
  <ngx-datatable-column name="Reminder" prop="title_reminder" [resizeable]="false" [flexGrow]="6">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ value }}</span>
      <p class="line-breaker"><small *ngIf="row.annotation_reminder">{{ row.annotation_reminder }}</small></p>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Notification" prop="notification_date_time" [resizeable]="false" [flexGrow]="2">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ row.notification_date_time_parsed | date : 'short' }}</span><br>
      <small *ngIf="row.notification_destination">{{ row.notification_destination }}</small>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<h4 *ngIf="!loadingData">Completed</h4>

<ngx-datatable *ngIf="!loadingData" class="striped" [rows]="rowsCompleted" [columnMode]="'flex'" [headerHeight]="0"
  [footerHeight]="0" [rowClass]="getRowClass" [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'"
  (activate)="onActivate($event)" (select)='onSelect($event)' [rowClass]="getRowClass" [scrollbarH]="true" [sorts]="[{prop: 'start_date_reminder', dir: 'desc'}]">
  <ngx-datatable-column name="Status" prop="id" [resizeable]="false" [flexGrow]="1">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <h4><i class="fa {{row.status_icon_class}}" aria-hidden="true"></i></h4>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Date" prop="start_date_reminder" [resizeable]="false" [flexGrow]="2">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ value | date : 'shortDate' }}</span><br>
      <!-- <small>{{ row.created_at_parsed | date }}</small> -->
        <small><span *ngIf="!row.all_the_day && row.start_time_reminder"><i
          class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;{{ row.start_time_reminder }}</span><span *ngIf="row.all_the_day"><i
          class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;All day</span></small>
    </ng-template>
  </ngx-datatable-column>
  <!-- <ngx-datatable-column name="Holder"  prop="holder_name" [resizeable]="false" [flexGrow]="3">
  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value"><i class="fa fa-{{row.holder_icon}}"></i> {{ value }} <i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze" aria-hidden="true"></i></span>
  </ng-template>
</ngx-datatable-column> -->
  <ngx-datatable-column name="Reminder" prop="title_reminder" [resizeable]="false" [flexGrow]="6">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ value }}</span>
      <p class="line-breaker"><small *ngIf="row.annotation_reminder">{{ row.annotation_reminder }}</small></p>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Notification" prop="notification_date_time" [resizeable]="false" [flexGrow]="2">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ row.notification_date_time_parsed | date : 'short' }}</span><br>
      <small *ngIf="row.notification_destination">{{ row.notification_destination }}</small>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<div class="row" *ngIf="!loadingData">
  <div class="col-md-12">
    <button type="button" class="btn btn-default btn-block btn-outline" (click)="updateReminderTable();"><i class="fa fa-refresh"></i>&nbsp;
      Refresh reminder table</button>
  </div>
</div>


<!-- BEGIN: MODAL LOADING EXPORT-->
<div [config]="{ backdrop: 'static'}" (onHidden)="onHiddenExport()" bsModal #staticModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">
          Attendere il caricamento senza chiudere o cambiare pagina
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="datatable-loader text-center">
              <span></span><span></span><span></span><span></span><span></span>
              <strong class="text-center">Loading</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL LOADING EXPORT-->






<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-reminder-add-form [showCloseButton]="false" [holderType]="holderType" [holderId]="holderId"
              [holderName]="holderName" [reminderModel]="selectedRow" (modelUpdated)="modelUpdatedInModal($event)"></app-reminder-add-form>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
