import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel, AngularMyDatePickerModule } from 'angular-mydatepicker';
import { CourseScheduleService, HelperService, ConfigService, PrimanotaService, UserService } from 'app/_services';
import { NotificationsService } from 'angular2-notifications';
import { parseISO, isBefore, format } from 'date-fns';
import CodiceFiscale from 'codice-fiscale-js';

@Component({
  selector: 'app-course-schedule-subscriber-form-new',
  templateUrl: './course-schedule-subscriber-form-new.component.html',
  styleUrls: ['./course-schedule-subscriber-form-new.component.css']
})
export class CourseScheduleSubscriberFormNewComponent implements OnInit {

  @ViewChild('f', {static: true}) currentForm: NgForm;
  @ViewChild('datePicker', {static: true}) datePicker: AngularMyDatePickerModule;

  model: any = null;
  @Output() formSaved = new EventEmitter();

  localPersonData: IMyDateModel;
  localRegistrationDate: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  ongoingRequestPerson = false;

  firstSearchPerson = false;

  //  search
  searchPersonModel: any = {};

  personFound: any[] = [];
  tempPersons = [];
  personSelected: any;

  addNewPerson: boolean = false;

  addInAlertType: string = "warning";
  standardCourseFee: number = 0;
  totalDiscount: number = 0;

  canEditDiscount: boolean = false;
  quote_discount_early_bird_date_parsed: any;

  constructor(private courseScheduleService: CourseScheduleService, private helper: HelperService,
    private configService: ConfigService, private notify: NotificationsService, private primanotaService: PrimanotaService,
    private userService: UserService) {

      //  Inizializzo le info sulle date
      this.datePickerOptions = helper.datePickerOptions;
    }

  ngOnInit() {

    this.canEditDiscount = this.courseScheduleService.canEditDiscount();

    if (!this.model || !this.model.id)
      this.configureModel();

    if (this.model)
    {
      if (this.courseScheduleService.currentCourseScheduleInfo && this.courseScheduleService.currentCourseScheduleInfo.quote_discount_early_bird_date){
        this.quote_discount_early_bird_date_parsed = parseISO(this.courseScheduleService.currentCourseScheduleInfo.quote_discount_early_bird_date);
      }

      if (this.model.person_info != null && typeof this.model.person_info != "undefined")
      {

        if (this.model.person_info.birthday != null)
        {
          this.localPersonData = this.helper.initMyDataInputSingle(this.model.person_info.birthday);
          this.model.person_info.birthday_parsed = parseISO(this.model.person_info.birthday);
        }else
        this.localPersonData = null;
      }

      //  se non ho un ruolo di default preimpostato nel corso allora prendo quello di sisitema
      this.model.person_default_course_role = this.courseScheduleService.currentCourseScheduleInfo.default_subscribers_role;
      if (!this.model.person_default_course_role)
        this.model.person_default_course_role = this.configService.courseScheduleRoleDefaultForSubscribers();

      //  setto a not valid quando la data di iscrizione al campo non esiste
      if (!this.model.registration_date)
        this.localRegistrationDate = null;

      // aggiungo l'id del corso schedule corrente
      this.model.course_schedule_id = this.courseScheduleService.currentCourseScheduleId;

      // aggiungo le informazioni per il CashFlow
      this.model.addin_enrollment_fee = false;
      this.model.addin_course_fee = false;

      if (this.model.price_quota_associativa > 0){
        this.model.addin_enrollment_fee_value = this.model.price_quota_associativa;
        this.model.addin_enrollment_fee_registered_via = 7
      }
      else {
        if (this.model.quote_enrollment_fee > 0)
          this.model.addin_enrollment_fee_value = this.model.quote_enrollment_fee;
        else
          this.model.addin_enrollment_fee_value = 50;
      }

      this.updateCourseFeeWithDiscount();
    }

  }

  configureModel ()
  {
    this.model = {};
    this.configurePersonModel();
  }

  configurePersonModel() {

    this.model.person_info = {};
    this.model.person_info.birthplace_province = "";
    this.model.person_info.contacts = [
      {type: 5, value: "", updated_at: "0"}, {type: 1, value: "", updated_at: "0"}, {type: 4, value: "", updated_at: "0"}
    ];
    this.model.person_info.addresses = [
      {type: 1, updated_at: "0"}
    ]
  }


  //  condizione di disattivazione del pulsante di ricerca di un iscritto
  get searchPersonDisabled()
  {
    var condition = true;

    condition = condition && !this.model.person_info.tax_id;
    condition = condition && (!this.model.person_info.surname || !this.model.person_info.first_name);

    if (this.ongoingRequestPerson || this.courseScheduleService.ongoingRequest)
      return true;

    return condition;
  }


  findPerson()
  {
    this.ongoingRequestPerson = true;
    this.personSelected = null;

    this.searchPersonModel = {};
    this.searchPersonModel.first_name = this.model.person_info.first_name;
    this.searchPersonModel.surname = this.model.person_info.surname;
    this.searchPersonModel.tax_id = this.model.person_info.tax_id;

    this.courseScheduleService.findPerson(this.searchPersonModel, (personFound) => {
      this.personFound = personFound;

      //  setto l'indirizzo da mostrare nel datatable
      this.personFound.forEach(person => {
        if (Object.keys(person.address).length > 0) {
          person.address_primary = person.address[Object.keys(person.address)[0]]
          Object.keys(person.address).forEach(addressKey => {
            if (person.address[addressKey].primary == true)
              person.address_primary = person.address[addressKey];
          });
        }
    })

    //  conservo anche un array temporaneo della ricerca
      this.tempPersons = [...personFound];

      // inseriamo l'intera lista arrivata dalla ricerca
      this.personFound = personFound;

      this.ongoingRequestPerson = false;
      this.firstSearchPerson = true;
    }, () => {this.ongoingRequestPerson = false;});
  }


  //  Questo metodo inserisce tutti i valori dopo la ricerca di una persona
  //  nell'html per evitare l'inserimento multiplo di tutti i valori
  preparePersonModelView ()
  {
    this.model.person_info.surname = this.personSelected.surname;
    this.model.person_info.first_name = this.personSelected.first_name;

    if (this.personSelected.gender)
      this.model.person_info.gender = this.personSelected.gender;

    if (this.personSelected.work_type)
      this.model.person_info.work_type = this.personSelected.work_type;

    /* inseriti solo se sono membri dello staff */
    if (this.userService.isOfficeSalesStaff())
    {
      if (this.personSelected.tax_id)
        this.model.person_info.tax_id = this.personSelected.tax_id;

      if (this.personSelected.birthday)
      {
        this.model.person_info.birthday = this.personSelected.birthday
        this.localPersonData = this.helper.initMyDataInputSingle(this.model.person_info.birthday);
      }
      else  {
        this.localPersonData = null;
      }

      if (this.personSelected.tshirt_size)
        this.model.person_info.tshirt_size = this.personSelected.tshirt_size;

      if (this.personSelected.birthplace)
        this.model.person_info.birthplace = this.personSelected.birthplace;

      Object.keys(this.personSelected.contact).forEach(key => {
        for(var i=0; i < this.model.person_info.contacts.length; i++) {
          if (this.model.person_info.contacts[i].type == this.personSelected.contact[key].type)
          {
            //  se la data attuale è precedente la nuova data allora la setto
            if (isBefore(parseISO(this.model.person_info.contacts[i].updated_at), parseISO(this.personSelected.contact[key].updated_at)))
              this.model.person_info.contacts[i] = this.personSelected.contact[key]
          }
        };
      });

      Object.keys(this.personSelected.address).forEach(key => {
        for (var i=0; i < this.model.person_info.addresses.length; i++) {
          if (this.model.person_info.addresses[i].type == this.personSelected.address[key].type)
          {
            //  se la data attuale è precedente la nuova data allora la setto
            if (isBefore(parseISO(this.model.person_info.addresses[i].updated_at), parseISO(this.personSelected.address[key].updated_at)))
              this.model.person_info.addresses[i] = this.personSelected.address[key]
          }
        }
      });

    }
    else
    {
      this.localPersonData = null;
    }
  }


  onSelectPerson({ selected }) {

    //  resetto il parent info
    this.configurePersonModel();

    if (selected.length != 0)
    {
      this.personSelected = selected[0];

      //  preparo il model del bambino inserendo tutte le info che servono
      this.preparePersonModelView();

      //  filtro i risultati per mostrare solo quello selezionato
      this.personFound = this.tempPersons.filter (x => x.id == selected[0].id);
    }
  }

  removeSelectedPerson() {

    if (this.personSelected)
    {
      this.configurePersonModel();

      this.personSelected = null;
      this.personFound = [...this.tempPersons];

      this.model.person_info.first_name = this.searchPersonModel.first_name;
      this.model.person_info.surname = this.searchPersonModel.surname;
      this.model.person_info.tax_id = this.searchPersonModel.tax_id;

      this.localPersonData = null;

      this.currentForm.form.markAsPristine();
      this.currentForm.form.markAsUntouched();
    }
  }



  submit()
  {
    if (!this.currentForm.form.valid) return;

    if (this.model.addin_course_fee_value > 0) {
      if (this.model.addin_course_fee_value + this.model.course_fee_amount > this.model.addin_course_fee_value_total){
        this.notify.error("Course fee", "La quota che si sta versando è più elevata di quella prevista, controllare i dati inseriti");
        return;
      }
    }

    if (this.checkOnSubmit)
    {
      this.courseScheduleService.saveCourseSubscriber(this.model, () => {
        this.formSaved.emit(this.model);
        this.primanotaService.getPrimaNota('App\\CourseSchedule', this.courseScheduleService.currentCourseScheduleId);
      });
    }

  }

  get checkOnSubmit()
  {
    if (!this.helper.isMyDateValid(this.localPersonData) && !this.personSelected) return false;

    //   se non è stata mai settata una data di registrazione valida
    if (this.model.registration_date == null || this.model.registration_date == "undefined") return;

    if (this.personSelected == null && !this.addNewPerson)
    {
      this.notify.error("Subscriber Info", "Ricercare e selezionare una persona dalla tabella, oppure spuntare 'aggiungi nuova persona'");
      return false;
    }

    if (this.personSelected)
      this.preparePersonModel();

    if(this.addNewPerson)
      this.prepareNewPersonModel();

    for (let contact of this.model.person_info.contacts)
      if (contact.type == 1) {
        if (!this.helper.validateEmail(contact.value))
          return false;
      }

    return true;
  }


  onTaxidChange (valueField)
  {
    if (typeof valueField != "undefined")
      this.model.person_info.tax_id = valueField.toUpperCase();

    try {
      var cf = new CodiceFiscale(this.model.person_info.tax_id).toJSON();

      this.model.person_info.gender = cf.gender.toLowerCase();

      if (typeof cf.birthplace != "undefined")
      {
        if (this.model.person_info.birthplace != cf.birthplace)
          this.model.person_info.birthplace = cf.birthplace;

        if (this.model.person_info.birthplace_province != cf.birthplaceProvincia)
          this.model.person_info.birthplace_province = cf.birthplaceProvincia;
        }

      //  assegno e formatto la data nel formato del datepicker corrente
      var localData = format(
        new Date(parseISO(cf.year + "-" + cf.month + "-" + cf.day)),
        this.getDateFormat().toUpperCase().replace('GG', "dd"));

      if (this.helper.formatISODate(this.localPersonData) != localData)
      {
        this.model.person_info.birthday = cf.year + '-' + cf.month + '-' + cf.day;
        this.localPersonData = this.helper.initMyDataInputSingle(localData);
      }
    }
    catch(err) {
        return;
    }

  }


  preparePersonModel ()
  {
    //  aggiungo dei valori che aiutino la memorizzazione della persona
    this.model.person_info.id = this.personSelected.id;

    //  controllo che ci siano nuovi contatti
    this.model.person_new_contact = new Array;

    this.model.person_info.contacts.forEach(contact => {
      var found = false;

      if (contact.value.trim().length == 0)
        found = true;

      Object.keys(this.personSelected.contact).forEach(key => {
        if ((this.personSelected.contact[key].type == contact.type
          && this.personSelected.contact[key].value == contact.value))
            found = true;
      });

      if (!found)
        this.model.person_new_contact.push(contact);
    });

    //  controllo che ci siano nuovi indirizzi
    this.model.person_new_address = new Array;

    this.model.person_info.addresses.forEach(address => {
      var found = false;
      Object.keys(this.personSelected.address).forEach(key => {
        if (this.personSelected.address[key].type == address.type &&
          address.route.indexOf(this.personSelected.address[key].route) >= 0)
            found = true;
      });

      if (!found) {
        this.model.person_new_address.push(address);
      }
    });

    //  controllo che ci siano i ruoli corretti
    this.model.person_new_role = new Array;

    //  cerco sia il ruolo di default del corso che quello basato sul lavoro dell'iscritto
    var foundCourseRole = false;
    var foundWorkRole = false;

    //  il ruolo da trovare è quello di default del corso
    var rolesToFind = [this.model.person_default_course_role];

    //  se però ho selezionato docente come lavoro allora aggiungo docente come ruolo
    if (this.model.person_info.work_type == 1)
      rolesToFind.push(1);

    Object.keys(this.personSelected.role).forEach(key => {
      //  cerco prima il ruolo di default del corso
      if (this.personSelected.role[key].type == rolesToFind[0])
        foundCourseRole = true;

      //  e dopo solo se presente anche il ruolo basato sul lavoro
      if (rolesToFind.length > 1)
        if (this.personSelected.role[key].type == rolesToFind[1])
          foundWorkRole = true;
    });

    if (!foundCourseRole) {
      var role: any = {type: rolesToFind[0]};
      this.model.person_new_role.push(role);
    }

    if (!foundWorkRole && rolesToFind.length > 1) {
      var role: any = {type: rolesToFind[1]};
      this.model.person_new_role.push(role);
    }

  }


  prepareNewPersonModel ()
  {
    //  aggiungo i contatti
    this.model.person_new_contact = new Array;
    this.model.person_info.contacts.forEach(element => {
      if (element.value.trim().length > 0)
        this.model.person_new_contact.push(element);
    });

    //  aggiungo gli indirizzi
    this.model.person_new_address = new Array;
    this.model.person_info.addresses.forEach(element => {
      if (element.route.trim().length > 0)
        this.model.person_new_address.push(element);
    });

    //  il ruolo da trovare è quello di default del corso
    var rolesToAdd = [this.model.person_default_course_role];

    //  se però ho selezionato docente come lavoro allora aggiungo docente come ruolo
    if (this.model.person_info.work_type == 1)
      rolesToAdd.push(1);

    rolesToAdd.forEach(roleToAdd => {
      var role: any = {type: roleToAdd};
      this.model.person_new_role = new Array (role);
    });
  }

  //  quando viene cambiato il lavoro devono essere azzerate le altre variabili
  changedWorkType (event) {

    //  se tiverso da docente azzero le altre scelte
    if (event != 1)
    {
      this.model.person_info.class_type = null;
      this.model.school_name = null;
      this.model.school_prov = null;
    }

  }



  onDatePersonChanged(event: IMyDateModel) {

    this.localPersonData = event;

    if (event.singleDate.jsDate != null)
      this.model.person_info.birthday = this.helper.formatISODate(event);
    else
      this.model.person_info.birthday;
  }



  getDateFormat ()
  {
    var localeFormat = "";

    if (typeof this.datePicker['localeService'].locales[this.datePicker['locale']] != "undefined")
      localeFormat = this.datePicker['localeService'].locales[this.datePicker['locale']].localeFormat;

    if (typeof localeFormat == "undefined" || localeFormat.length == 0)
      localeFormat = this.datePickerOptions.dateFormat;

    if (typeof localeFormat == "undefined" || localeFormat.length == 0)
      localeFormat = "gg/mm/yyyy";

    return localeFormat
  }

  onDateRegistrationChanged(event: IMyDateModel) {

    this.localRegistrationDate = event;
    if (event.singleDate.jsDate != null)
      this.model.registration_date = this.helper.formatISODate(event);
    else
      this.model.registration_date = null;
  }




  suggestEarlyBirdDiscount: boolean = false;

  updateCourseFeeWithDiscount() {

    if (this.courseScheduleService.currentCourseScheduleInfo == null)
      return;

    if (this.model == null)
      return;

    let courseSchedule = this.courseScheduleService.currentCourseScheduleInfo;

    this.standardCourseFee = courseSchedule['quote_course_fee'];

    let course_fee = courseSchedule['quote_course_fee'];

    if (this.model.enrollment_fee_amount)
      course_fee -= this.model.enrollment_fee_amount;

    if (this.model.quote_extra_1){
      course_fee += courseSchedule.quote_extra_1
    }

    if (this.model.quote_extra_custom > 0){
      course_fee += parseFloat(this.model.quote_extra_custom);
    }

    if (this.model.quote_discount_1){
      course_fee -= courseSchedule.quote_discount_1
    }

    if (this.model.quote_discount_2){
      course_fee -= courseSchedule.quote_discount_2
    }

    if (this.model.quote_discount_early_bird){
      course_fee -= courseSchedule.quote_discount_early_bird
      // this.suggestEarlyBirdDiscount = false;
    }
    else {
      if (isBefore(this.model.registration_date, this.courseScheduleService.currentCourseScheduleInfo.quote_discount_early_bird_date)){
        this.suggestEarlyBirdDiscount = true;
      }
      else {
        this.suggestEarlyBirdDiscount = false;
      }
    }

    if (this.model.quote_discount_custom > 0){

      if (this.model.quote_discount_custom > course_fee)
        this.model.quote_discount_custom = course_fee;

      course_fee -= parseFloat(this.model.quote_discount_custom);
    }

    if (this.model.quote_discount_free == true){
      course_fee = 0
    }

    this.model.addin_course_fee_value_total = course_fee;

    // sottraggo un eventuale valore già pagato
    if (this.model.course_fee_amount > 0){
      course_fee -= this.model.course_fee_amount;
    }

    // aggiorno il prezzo del corso
    this.model.addin_course_fee_value = course_fee;

    // calcolo lo sconto totale dalla quota standard
    this.totalDiscount = this.standardCourseFee - course_fee - this.model.enrollment_fee_amount - this.model.course_fee_amount;
  }




  getCourseScheduleService():CourseScheduleService {
    return this.courseScheduleService
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

  getUserService() {
    return this.userService
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

}
