import { HttpClient } from '@angular/common/http';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { NgForm } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfigService, HelperService, CourseScheduleService, UserService } from './../../_services/index';
import { CourseScheduleComponent } from './../course-schedule.component';
import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList, ElementRef, DoCheck } from '@angular/core';
import { EnterpriseInfoShowComponent, EnterpriseSearchFormComponent, EnterpriseDatatableComponent } from './../../enterprise/index';
import { Router } from '@angular/router';
import { parseISO } from 'date-fns';
import { NotificationsService } from 'angular2-notifications';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { PersonSearchFormComponent, PersonDatatableComponent } from '../../person';
import { CourseDatatableComponent } from '../../course/course-datatable/course-datatable.component';
import { CourseSearchFormComponent } from '../../course/course-search-form/course-search-form.component';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-course-schedule-info-form',
  templateUrl: './course-schedule-info-form.component.html',
  styleUrls: ['./course-schedule-info-form.component.css']
})
export class CourseScheduleInfoFormComponent implements OnInit, DoCheck {

  //  componenti per la ricerca della stazione ferroviaria
  //  dati scaricati dal sito datiopen.it
  private dataUrl = '../assets/railway-stations.json';
  asyncSelected: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  stationComplex: any = [];

  onlineErrorArr: any[] = [];
  onlineWarnArr: any[] = [];

  provisionalWeekValid: boolean = true;

  @ViewChild('modalCopy', {static: false}) public modalCopy: ModalDirective;
  @ViewChild('enterpriseModal', {static: false}) public enterpriseModal: ModalDirective;
  @ViewChild('personModal', {static: false}) public personModal: ModalDirective;
  @ViewChild('courseModal', {static: false}) public courseModal: ModalDirective;

  @ViewChild('enterpriseDatatable', {static: false}) public enterpriseDatatable: EnterpriseDatatableComponent;
  @ViewChild('enterpriseSearchForm', {static: false}) public enterpriseSearchForm: EnterpriseSearchFormComponent;
  @ViewChild('personDatatable', {static: false}) public personDatatable: PersonDatatableComponent;
  @ViewChild('personSearchForm', {static: false}) public personSearchForm: PersonSearchFormComponent;
  @ViewChild('courseDatatable', {static: false}) public courseDatatable: CourseDatatableComponent;
  @ViewChild('courseSearchForm', {static: false}) public courseSearchForm: CourseSearchFormComponent;

  @ViewChild('confirmModal', {static: false}) confirmModal: ModalDirective;
  @ViewChildren('enterpriseInfoShowVenue') public enterpriseInfoShow: QueryList<EnterpriseInfoShowComponent>;
  @ViewChildren('provisionalInput') public provisionalInputs: QueryList<ElementRef>;

  @ViewChild('f', {static: true}) currentForm: NgForm;

  public localDataStart: IMyDateModel;
  public localDataEnd: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  private _datePickerOptionsBegin: IAngularMyDpOptions = {};
  public get datePickerOptionsBegin () {
    return this._datePickerOptionsBegin;
  }
  private _datePickerOptionsEnd: IAngularMyDpOptions = {};
  public get datePickerOptionsEnd () {
    return this._datePickerOptionsEnd;
  }

  editMode = false;
  // editModeTravelInfo = false;
  editModeTravelInfo = true;

  model: any = null;

  //  Array con le info dell'odience da selezionare
  audienceModel:any = null;

  @Input() courseScheduleComponent: CourseScheduleComponent;

  //  file uploaders
  public inUpload: boolean = false;
  private headers: any;
  public leafletUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf', 'application/msword'],
      url: 'serverUrl'
    }
  );

  constructor(private configService: ConfigService, private helper: HelperService,
              private router: Router, private courseScheduleService: CourseScheduleService,
              private notify: NotificationsService, private http: HttpClient, private userService: UserService) {

    //  Inizializzo le info sulle date
    this._datePickerOptionsBegin = helper.datePickerOptions;
    this._datePickerOptionsEnd = helper.datePickerOptions;

    //  carico l'array dalle risorse
    this.loadStations();
   }

  subscribe: any;



  ngOnInit() {

    //  Inizializzo le audience
    this.audienceModel = {};
    this.configService.courseScheduleAudienceSelect.forEach(element => {
      this.audienceModel[element.key] = false;
    });

    //  La prima volta che entro controllo che non sia nullo il model
    if (this.model == null)
    {
        this.model = {};
        this.editMode = true;
    }

    this.subscribe = this.courseScheduleService.getsubjectToUpdateObservable().subscribe((update:boolean) => {
        this.getCourseScheduleInfo();
    });

    if (this.courseScheduleService.currentCourseScheduleInfo != null){
      this.getCourseScheduleInfo();
    }

  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  check = true;
  ngDoCheck ()
  {
    this.onlineWarnArr = new Array;

    if (this.model.buyable_online == false)
      this.onlineWarnArr.push("non è stato spuntato 'acquistabile'");

    if (this.model.venue_id)
      this.courseScheduleBuyableOnlineCheck;
  }

  getCourseScheduleInfo() {

    this.model =  JSON.parse(JSON.stringify(this.courseScheduleService.currentCourseScheduleInfo));

      if (this.model == null)
      {
        this.model = {};
        this.editMode = true;
      }
      else
      {
        this.editMode = false;
        this.editModeTravelInfo = false;

        //   Se esiste nel modello la data di start
        if (this.model.start != null)
        {
          this.model.start_parsed = parseISO(this.model.start);
          this.localDataStart = this.helper.initMyDataInputSingle(this.model.start);
          this.validateStartDate();
        }

        //   Se esiste nel modello la data di end
        if (this.model.end != null)
        {
          this.model.end_parsed = parseISO(this.model.end);
          this.localDataEnd = this.helper.initMyDataInputSingle(this.model.end);
          this.validateEndDate();
        }

        //  preparo gli url per l'upload dei file
        this.headers = this.courseScheduleService.getFileUploadHeader();
        this.leafletUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
        this.leafletUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
          this.leafletUploaded(item, response, status);
        };
        this.leafletUploader.options.url = this.courseScheduleService.getLeafletFileUploadURL(this.model);

        //  Se il model ha informazioni da mostrare faccio il parser della audience
        if (this.model.audience != null)
          this.audienceModel = JSON.parse(this.model.audience);
      }

      //  assegno un ruolo di default per gli iscritti online
    if (this.model.default_subscribers_role == null || typeof this.model.default_subscribers_role == "undefined") {
      this.model.default_subscribers_role = this.configService.courseScheduleRoleDefaultForSubscribers();
    }
  }

  edit() {
    //controllo se non ho i permessi di edit
    if (this.userService.checkPermission('course_schedule_edit')){
      this.editModeTravelInfo = true;
      this.editMode = true;
      return;
    }

    // let p = this.userService.getCurrentUser().person_info;

    // if (this.courseScheduleService.isPersonCampDirector(p.id)){
    //   this.editModeTravelInfo = true;
    //   return;
    // }
  }

  submit() {

    //  Controllo che le date siano valide
    if (!this.helper.isMyDateValid(this.localDataStart) || !this.helper.isMyDateValid(this.localDataEnd)) return;

    if (this.audienceModel != null)
      this.model.audience = JSON.stringify(this.audienceModel);

    //  forzo la validazione dei time schedule
    this.onTimeStartInput(null);
    this.onTimeEndInput(null);

    if (!this.timeStartvalid || !this.timeEndvalid)
    {
      this.notify.error("Course Schedule", "The Lesson plan time are Required");
      return;
    }

    if (!this.model.course_id)
    {
      this.notify.error("Course Schedule", "The Course is Required");
      return;
    }

    //  Invio un messaggio di warning tramite modale se non è acquistabile online
    if(!this.courseScheduleBuyableOnlineCheck || !this.checkBuyable)
      this.confirmModal.show();
    else

    this.confirmSubmit();
  }

  confirmSubmit()
  {
    this.confirmModal.hide();

    if(!this.courseScheduleBuyableOnlineCheck || !this.checkBuyable)
      this.model.buyable_online = false;

    console.log(this.model);

    if (this.model.id != null)
      this.courseScheduleService.updateCourseScheduleInfo(this.model, () => { this.editMode = false; this.editModeTravelInfo = false; });
    else
    {
      //  provo a cambiare il routing al salvataggio che mi sembra più giusto
      this.courseScheduleService.saveCourseScheduleInfo(this.model, () => {

        this.router.navigate(["courseschedule/edit/"+ this.courseScheduleService.currentCourseScheduleId]);
      });
    }
  }


  destroy()
  {
    this.courseScheduleService.deleteCourseScheduleInfo(this.model, () => {
      this.router.navigate(['courseschedule/home/']);
    });
  }

  //  Controllo che il corso sia disponibile sulla piattaforma delle iscrizioni
  get courseScheduleBuyableOnlineCheck ()
  {
    this.onlineErrorArr = new Array;

    if (this.model != null && typeof this.model != "undefined")
    {
      if (this.model.status != 1 && this.model.status != 3)
        this.onlineErrorArr.push("lo status del corso non è corretto");
      if (!this.checkAddressVenue())
        this.onlineErrorArr.push('L\'indirizzo della Venue non è Googlizzato o è mancante');


      if (this.onlineErrorArr.length == 0)
        return true;
    }

    return false;
  }

  get checkBuyable()
  {
    if (this.model.buyable_online != null && typeof this.model.buyable_online != "undefined")
      return this.model.buyable_online;

    return false;
  }

  checkAddressVenue ()
  {
    var found = false;
    if (this.enterpriseInfoShow != null && typeof this.enterpriseInfoShow != "undefined" && this.enterpriseInfoShow.length > 0)
    {
      if (this.enterpriseInfoShow.first.enterpriseInfo != null && typeof this.enterpriseInfoShow.first.enterpriseInfo != "undefined")
        if (this.enterpriseInfoShow.first.enterpriseInfo.address != null && typeof this.enterpriseInfoShow.first.enterpriseInfo.address != "undefined")
        Object.keys(this.enterpriseInfoShow.first.enterpriseInfo.address).forEach(key => {
          var element = this.enterpriseInfoShow.first.enterpriseInfo.address[key];
          if (element.type == 1)
            // if (typeof element.route != "undefined" && element.route != null && element.route.length > 0) // La Route non deve essere obligatoria, a volte gli indirizzi non hanno una route
              if(element.administrative_area_level_3_long_version != null && typeof element.administrative_area_level_3_long_version != "undefined" && element.administrative_area_level_3_long_version.length > 0)
                if (typeof element.administrative_area_level_2_short_version != "undefined" && element.administrative_area_level_2_short_version != null && element.administrative_area_level_2_short_version.length > 0)
                  if (typeof element.postal_code != "undefined" && element.postal_code != null && element.postal_code.length > 0)
                    return found = true;
        });
    }

    return found;
  }


  toggleClicked ()
  {
    // se il corso ha errori e non è acquistabile
    if (!this.courseScheduleBuyableOnlineCheck)
    {
      this.model.buyable_online = false;
      return false;
    }
    //   se il corso è acquistabile ma è spuntato no
    if (this.model.buyable_online == false)
    {
      this.model.buyable_online = true;
      return true;
    }
    //   se il corso è acquistabile ma è spuntato si
    else
    {
      this.model.buyable_online = false;
      return false;
    }
  }


  onlineClicked()
  {
    this.model.online = !this.model.online;
  }

  // courseCodeClicked ()
  // {
  //   if (!this.courseScheduleBuyableOnlineCheck)
  //     this.model.buyable_online = false;
  // }

  //  Quando viene cambiata la audience
  onChangeAudience(event) {
  }



  //  Operazioni sulla data di start
  onDateStartChanged(event: IMyDateModel)
  {
    this.localDataStart = event;

    if (event.singleDate.jsDate != null)
    {
      this.model.start = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableUntil =  {year: event.singleDate.date.year, month: event.singleDate.date.month, day: event.singleDate.date.day -1};

      this._datePickerOptionsEnd = datePickerOpt;
    }
    else
    {
      this.model.start = null;
      this._datePickerOptionsEnd = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }


  validateStartDate()
  {
    if (this.model.start)
    {
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      let dateS = parseISO(this.model.start);
      datePickerOpt.disableUntil = { year: dateS.getFullYear(), month: dateS.getMonth() + 1, day: dateS.getDate() -1 }

      this._datePickerOptionsEnd = datePickerOpt;
    }

  }


  //  Operazioni sulla data di end
  onDateEndChanged(event: IMyDateModel)
  {
    this.localDataEnd = event;

    if (event.singleDate.jsDate != null)
    {
      this.model.end = this.helper.formatISODate(event);

      //  Disabilito la data della fine del tour a seconda della data iniziale
      let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
      datePickerOpt.disableSince = {year: event.singleDate.date.year, month: event.singleDate.date.month, day: event.singleDate.date.day +1};

      this._datePickerOptionsBegin = datePickerOpt;
    }
    else
    {
      this.model.end = null;
      this._datePickerOptionsBegin = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    }

  }


  validateEndDate()
  {
    let datePickerOpt = JSON.parse(JSON.stringify(this.helper.datePickerOptions));
    let dateE = parseISO(this.model.end);
    datePickerOpt.disableSince = { year: dateE.getFullYear(), month: dateE.getMonth() + 1, day: dateE.getDate() + 1 }

    this._datePickerOptionsBegin = datePickerOpt;
  }




  //  Valido l'orario di start
  timeStartvalid: boolean = false;
  onTimeStartInput (event)
  {
    this.timeStartvalid = false;

    //  controllo se è valido il time
    this.configService.timeCourseSchedulePlanSelect.forEach(element => {

      if (element.value == this.currentForm.form.controls.start_time.value)
          return this.timeStartvalid = true;
    });
  }
  typeTimeStartOnSelect (event) {
    //  Se arriva questo evento al 100% è una data valida
    this.timeStartvalid = true;
  }



  //  Valido l'orario di end
  timeEndvalid: boolean = false;
  onTimeEndInput (event)
  {
    this.timeEndvalid = false;

    //  controllo se è valido il time
    this.configService.timeCourseSchedulePlanSelect.forEach(element => {
      if (element.value == this.currentForm.form.controls.end_time.value)
        return this.timeEndvalid = true;
    });
  }
  typeTimeEndOnSelect (event) {
    //  Se arriva questo evento al 100% è una data valida
    this.timeEndvalid = true;
  }


  //  tutte le modali
  currentModalProp: string = null;

  // Modale Enterprise
  isEnterpriseModalShown: boolean = false;
  showEnterpriseModal(typeEvent:string = null): void {

    //  se non ci sono parametri per i quali posso far aprire la modal non facico nulla
    if (typeEvent == null)
      return;

    //  memorizzo da dove viene la richiesta di apertura della modale
    this.currentModalProp = typeEvent;

    this.isEnterpriseModalShown = true;
  }

  hideEnterpriseModal(): void {
    this.enterpriseModal.hide();
  }

  onEnterpriseHidden(): void {
    this.isEnterpriseModalShown = false;
  }

  searchEnterpriseKeywordsSubmit(params) {
    this.enterpriseDatatable.query = params;
    this.enterpriseDatatable.getResults();
  }


  //  modale persone
  isPersonModalShown: boolean = false;
  showPersonModal(typeEvent:string = null): void {

    //  se non ci sono parametri per i quali posso far aprire la modal non facico nulla
    if (typeEvent == null)
      return;

    //  memorizzo da dove viene la richiesta di apertura della modale
    this.currentModalProp = typeEvent;

    this.isPersonModalShown = true;
  }

  hidePersonModal(): void {
    this.personModal.hide();
  }

  onPersonHidden(): void {
    this.isPersonModalShown = false;
  }

  searchPersonKeywordsSubmit(params) {
    this.personDatatable.query = params;
    this.personDatatable.getResults();
  }



  //  modale corsi
  isCourseModalShown: boolean = false;
  showCourseModal(typeEvent:string = null): void {

    //  se non ci sono parametri per i quali posso far aprire la modal non facico nulla
    if (typeEvent == null)
      return;

    //  memorizzo da dove viene la richiesta di apertura della modale
    this.currentModalProp = typeEvent;

    this.isCourseModalShown = true;
  }

  hideCourseModal(): void {
    this.courseModal.hide();
  }

  onCourseHidden(): void {
    this.isCourseModalShown = false;
  }


  isModalCopyCourse: boolean = false;
  copy(): void {
    this.isModalCopyCourse = true;
  }

  showModalCopy(): void {
    this.isModalCopyCourse = true;
  }

  hideModalCopy(): void {
    this.modalCopy.hide();
  }

  onHiddenCopy(): void {
    this.isModalCopyCourse = false;
  }

  closeModalEvent(event)
  {
    this.hideModalCopy();
  }




  searchCourseKeywordsSubmit(params) {

    if (typeof params == "undefined")
      params = "all";

    this.courseDatatable.query = params;
    this.courseDatatable.getResult();
  }


  resultSelected(selected) {

    switch (this.currentModalProp)
    {
      case 'venue':
      {
        this.model.venue_id = selected.enterprise_id;
        // this.toggleClicked();
        this.hideEnterpriseModal();
        break;
      }
      case 'accomodation_venue':
      {
        this.model.accomodation_venue_id = selected.enterprise_id;
        this.hideEnterpriseModal();
        break;
      }
      case 'supervisor':
      {
        this.model.supervisor_id = selected.person_id;
        this.onPersonHidden();
        break;
      }
      case 'co_supervisor_01':
      {
        this.model.co_supervisor_01_id = selected.person_id;
        this.onPersonHidden();
        break;
      }
      case 'co_supervisor_02':
      {
        this.model.co_supervisor_02_id = selected.person_id;
        this.onPersonHidden();
        break;
      }
      case 'coursename':
      {
        this.model.course_id = selected.course_id;
        this.onCourseHidden();
        break;
      }
    }

    this.currentForm.form.markAsDirty();

  }

  removeAutority (typeEvent:string = null): void {

    switch (typeEvent)
    {
      case 'venue':
      {
        this.model.venue_id = null;
        this.model.buyable_online = false;
        break;
      }
      case 'accomodation_venue':
      {
        this.model.accomodation_venue_id = null;
        break;
      }
      case 'coursename':
      {
        this.model.course_id = null;
        this.model.buyable_online = false;
        break;
      }
      case 'supervisor':
      {
        this.model.supervisor_id = null;
        break;
      }
      case 'co_supervisor_01':
      {
        this.model.co_supervisor_01_id = null;
        break;
      }
      case 'co_supervisor_02':
      {
        this.model.co_supervisor_02_id = null;
        break;
      }
    }

  }



    //   metodi necessari alla ricerca della stazione ferroviaria
  private loadStations() {

    this.http.get(this.dataUrl).subscribe(
    (response) => {
      this.stationComplex = response;
    },
    (error) => {
      console.log(error);
    });
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.model.railway = e.value;
  }




  deleteVrequest(){
    this.inUpload = true;
    this.courseScheduleService.deleteVrequest(this.model, ()=>{
      this.model.venue_request_file_path = null;
      this.inUpload = false}, ()=>{this.inUpload = false});
  }
  deleteLeaflet ()
  {
    this.inUpload = true;
    this.courseScheduleService.deleteLeaflet(this.model, ()=>{
      this.model.leaflet_file_path = null;
      this.inUpload = false}, ()=>{this.inUpload = false});
  }
  deleteCondition () {
    this.inUpload = true;
    this.courseScheduleService.deleteCondition(this.model, ()=>{
      this.model.conditions_file_path = null;
      this.inUpload = false}, ()=>{this.inUpload = false});
  }


  //  metodi per il caricamento dei documenti
  //  Documento Caricato
  public leafletUploaded(item:any, response:any, status:any)
  {
    if (item != null) {
      if (status === 200) {
        this.model.leaflet_file_path = response;
      }
      this.courseScheduleService.uploadedFileResponse(item, response, status,  this.model.id);
    }
  }
  getCourseScheduleService() {
    return this.courseScheduleService
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }
}
