import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { CampService, ConfigService, HelperService, UserService } from 'app/_services';
import { differenceInYears } from 'date-fns';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-camp-signup-list',
  templateUrl: './camp-signup-list.component.html',
  styleUrls: ['./camp-signup-list.component.css']
})
export class CampSignupListComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) public autoShownModal: ModalDirective;

  rows: any[] = [];
  selected = [];
  ongoingRequest: boolean = false;
  selectedSignupInfo = null;

  constructor(private campService: CampService, private configService: ConfigService, private helper: HelperService, private userService: UserService) { }

  ngOnInit() {

    if (this.campService.currentCampId)
      this.getSignups();
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  onSelect({ selected }) {

    if (!this.userService.checkPermission('camp_edit_signup'))
      return;

    this.selectedSignupInfo = JSON.parse(JSON.stringify(this.selected[0]));
    this.selectedSignupInfo.registration_date_parsed = parseISO(this.selectedSignupInfo.registration_date);
    this.showModal();
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  calculateYear (birthday: any)
  {
    return differenceInYears(new Date(), parseISO(birthday));
  }

  getSignups() {

    this.ongoingRequest = true;
    this.rows = [];

    this.campService.getSignupFormsInfo((list) => {

      this.rows = list;

      this.ongoingRequest = false;

    });

  }

  getRowClass(row) {
    return {
      'ignore-request': row.ignore_request === 1
    };
  }

  formNewSubscriberSaved(event) {
    this.getSignups();
    this.hideModal();
  }

  formNewSubscriberDelete(event) {
    this.getSignups();
    this.hideModal();
  }

  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

}
