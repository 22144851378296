import { ModalDirective } from 'ngx-bootstrap/modal';
import { ViewChild } from '@angular/core';
import { WarehouseService, ShipmentService, UserService } from './../../_services/index';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warehouse-product-datatable',
  templateUrl: './warehouse-product-datatable.component.html',
  styleUrls: ['./warehouse-product-datatable.component.css']
})
export class WarehouseProductDatatableComponent implements OnInit {

  @ViewChild('productModal', {static: false}) public productModal: ModalDirective;

  rows = [];
  selected = [];

  subscribe: any;

  constructor(private warehouseService:WarehouseService, private shipmentService:ShipmentService, private userService: UserService) { }

  ngOnInit() {

    this.subscribe = this.warehouseService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

      var products = JSON.parse(JSON.stringify(this.warehouseService.currentProducts));

      this.getRequestedProducts (products);

      this.hideModalProduct();
    });
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }


  getRequestedProducts (products: any) {

    this.shipmentService.getAllShipmentWithClosedOrder( (allProducts)=> {

      //  setto a zeto tutti i prodotti richiesti per mostrarli nella tabella
      products.forEach(product => {
        product.quantity_requested = 0;
      });

      //  per ogni prodotto richiesto nelle spedizioni cerco il corrispondente nel magazzino e memorizzo la sua quantità richiesta
      allProducts.forEach(camp => {

        camp.material_list.forEach(material => {

          //  faccio in confronto per sommare
          products.forEach(product => {

            if (product.id == material.product_id)
              product.quantity_requested += material.quantity;
          });

        });

      });

      this.rows = JSON.parse(JSON.stringify(products));

    });

  }


  addNew () {
    this.currentProductSelected = {};
    this.showModalProduct();
  }


  currentProductSelected: any = null;
  onSelect({ selected }) {

    if (!this.userService.checkPermission("warehouse_edit"))
      return;

    this.currentProductSelected =  JSON.parse(JSON.stringify(selected[0]));
    this.showModalProduct();
  }


  isModalProductionShow: boolean = false;
  showModalProduct() {
    this.isModalProductionShow = true;
    this.productModal.show();
  }

  hideModalProduct() {
    this.productModal.hide();
  }

  onHiddenProductModal () {
    this.currentProductSelected = null;
    this.car = false;
    this.scar = false;
    this.isModalProductionShow = false;
  }


  scar:boolean = false;
  RemoveForProduct() {
    this.scar = true;
  }

  car: boolean = false;
  AddForProduct() {
    this.car = true;
  }

  getWarehouseService () {
    return this.warehouseService;
  }

  getShipmentService () {
    return this.shipmentService;
  }
}
