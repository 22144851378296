import { Component, OnInit, ViewChild } from '@angular/core';
import { VehicleService } from 'app/_services';
import { VehicleAssignmentDatatableComponent } from '../vehicle-assignment-datatable/vehicle-assignment-datatable.component';
import { VehicleAssignmentAddFormComponent } from '../vehicle-assignment-add-form/vehicle-assignment-add-form.component';

@Component({
  selector: 'app-vehicle-assignment',
  templateUrl: './vehicle-assignment.component.html',
  styleUrls: ['./vehicle-assignment.component.css']
})
export class VehicleAssignmentComponent implements OnInit {

  @ViewChild('assignmentDatatable', {static: true}) assignmentDatatable: VehicleAssignmentDatatableComponent;
  @ViewChild('assignmentAddForm', {static: true}) assignmentAddForm: VehicleAssignmentAddFormComponent;

  loadingData: boolean = false;
  subject: any;

  assignments: any[] = [];

  constructor(private vehicleService: VehicleService) { }

  ngOnInit() {

    this.prepareView();

    this.subject = this.vehicleService.getsubjectToUpdateObservable().subscribe(() => {

      this.prepareView();
    });

  }

  prepareView()
  {
    if (this.vehicleService.currentVehicleAssignments == null || typeof this.vehicleService.currentVehicleAssignments == "undefined")
      this.assignments = [];
    else
      this.updateData(this.vehicleService.currentVehicleAssignments);
  }

  updateData (assignments: any[])
  {
    this.assignments = JSON.parse(JSON.stringify(assignments));
    this.assignments.forEach(assignment => {
      assignment.vehicle_data = JSON.parse(JSON.stringify(this.vehicleService.currentVehicleInfo));
    });

    this.assignmentDatatable.update(this.assignments);
  }

  ngOnDestroy() {

    this.subject.unsubscribe();
  }


  onSelect(selected) {
    var selectedRow:any = JSON.parse(JSON.stringify(selected));
    selectedRow.vehicle_data = JSON.parse(JSON.stringify(this.vehicleService.currentVehicleInfo));
    this.assignmentAddForm.update(selectedRow);
    this.assignmentAddForm.showMaster();
  }

  showAddForm () {
    var selectedRow:any = {};
    selectedRow.vehicle_data = JSON.parse(JSON.stringify(this.vehicleService.currentVehicleInfo));
    this.assignmentAddForm.update(selectedRow);
    this.assignmentAddForm.showMaster();
  }

  modelUpdatedInModal ()
  {
    this.loadingData = true;

    this.vehicleService.getAllAssignment(this.vehicleService.currentVehicleId, () => {

      //  ridisegno la tabella
      this.updateData(this.vehicleService.currentVehicleAssignments);

      this.loadingData = false;
    });
  }



  getVehicleService (){
    return this.vehicleService;
  }
}
