import { Component, OnInit, Output, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EventEmitter } from '@angular/core';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { HelperService, CourseScheduleService } from 'app/_services';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications'
import { parseISO, isBefore } from 'date-fns';

@Component({
  selector: 'app-course-schedule-copy',
  templateUrl: './course-schedule-copy.component.html',
  styleUrls: ['./course-schedule-copy.component.css']
})
export class CourseScheduleCopyComponent implements OnInit {

  @Input() model: any;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  public staffcopy: boolean = true;

  @ViewChild('confirmModal', {static: false}) confirmModal: ModalDirective;

  private localDataStart: IMyDateModel;
  private localDataEnd: IMyDateModel;

  loadingData: boolean = false;

  //  Impostazione della data che vengono passate dall'helper
  public datePickerOptions: IAngularMyDpOptions = {};

  constructor(private helper: HelperService, private courseScheduleService: CourseScheduleService,
              private router:Router, private notify: NotificationsService) { }

  ngOnInit() {

    //  Devo copiare il model passato ricreando un nuovo oggetto
    this.model =  JSON.parse(JSON.stringify(this.model));
  }

  submit()
  {
    //  rimuovo l'external id per rimuovere la connessione con gli iscritti
    delete this.model['id'];
    delete this.model['course_external_id'];

    //  Controllo se la data non è valida
    if (!this.helper.isMyDateValid(this.localDataStart) || !this.helper.isMyDateValid(this.localDataEnd)) return;

    //  controllo che la data finale sia superiore a quella iniziale
    if (isBefore(parseISO(this.model.end), parseISO(this.model.start)))
    {
      this.localDataEnd = null;
      return;
    }

    this.courseScheduleService.saveCourseScheduleInfo(this.model, (resJson) => {

      var courseCopy = resJson;

      if (this.staffcopy == true)
      {
        if (this.model.staff != null && this.model.staff != "undefined")
        {
            this.model.staff.forEach(staffMember => {

              staffMember.course_schedule_id = courseCopy.id;
              delete staffMember['id'];

              //  salvo lo stafff
              this.courseScheduleService.saveCourseStaffMember(staffMember, ()=>{}, ()=>{}, true);
          });

          this.notify.success("Course Staff", "Successfully Saved");
          this.router.navigate(["courseschedule/edit/"+courseCopy.id]);
          this.closeModal.emit(true);
          return;
        }
      }

      this.router.navigate(["courseschedule/edit/"+courseCopy.id]);
      this.closeModal.emit(true);
    });

  }


  //  Metodi per la data
  onDateStartChanged(event: IMyDateModel) {

    this.localDataStart = event;

    if (event.singleDate.date != null)
      this.model.start = this.helper.formatISODate(event);
    else
      this.model.start = null;

  }



  //  Metodi per la data
  onDateEndChanged(event: IMyDateModel) {

    this.localDataEnd = event;

    if (event.singleDate.date != null)
      this.model.end = this.helper.formatISODate(event);
    else
      this.model.end = null;
  }



  getLocalDataStart() {
    return this.localDataStart
  }
  getLocalDataEnd() {
    return this.localDataEnd
  }

  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }

  getCourseScheduleService() {
    return this.courseScheduleService
  }


}
