import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CampService, PrimanotaService } from '../../_services/index';
import { PrimanotaShowComponent } from '../../primanota/index';

@Component({
  selector: 'app-camp-cashflow',
  templateUrl: './camp-cashflow.component.html',
  styleUrls: ['./camp-cashflow.component.css']
})
export class CampCashflowComponent implements OnInit {

  @ViewChild('primaNotaShowComponent', {static: true}) public primaNotaShowComponent: PrimanotaShowComponent;

  subscribe: any;
  subscribePrimaNota: any;
  model: any = {};
  holderName: string;
  rows: any[] = [];

  max_total_quote_subscribers: number = 0;
  total_quote_subscribers: number = 0;
  total_quote_subscribers_perc: string = "0%";

  constructor(private campService: CampService, private primanotaService: PrimanotaService) { }

  ngOnInit() {

    this.subscribe = this.campService.getsubjectToUpdateObservable().subscribe((updated: boolean) => {

      this.getCampInfo();
    });

    this.subscribePrimaNota = this.primanotaService.getsubjectToUpdatePrimaNotaObservable().subscribe((res: any) => {

      this.rows = this.primanotaService.getTableData(res, this.campService.currentCampId, "App\\Camp");

      this.calculateCurrentExpence();
      this.calculateMaxExpenseSubscribers();

    });

    if (this.campService.currentCampInfo != null)
      this.getCampInfo();

  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();

    if (this.subscribePrimaNota)
      this.subscribePrimaNota.unsubscribe();
  }

  getCampInfo() {

    this.model = this.campService.currentCampInfo;

      if (this.model == null) this.model = {};
      else
      {
        this.holderName = this.campService.currentCampInfo["camp_code"];
        this.calculateCurrentExpence();
        this.calculateMaxExpenseSubscribers();
      }

  }

  modelUpdated(event){
    this.primaNotaShowComponent.modelUpdated(event);
  }

  calculateMaxExpenseSubscribers() {

    let list = this.campService.currentCampSubscriberList;
    let quote = this.campService.currentCampInfo;
    let total = 0;

    if (list == null)
      return;

    list.forEach(function(obj){
      if (!obj.deleted_at)
        total += quote['quote_max_out_per_subscriber_w'+obj.camp_week.length];
    });

    // if (this.total_quote_subscribers && this.total_quote_subscribers > 0){
    //   total -= this.total_quote_subscribers;
    // }

    if (this.rows == null)
      return;

    // escursione, food, foto, materiale, other, parking, petrol, ricarica tel, spese post, telephone
    let causalOut = [10, 4, 12, 5, 9, 8, 2, 13, 11, 6];

    // sottraggo gli addout che fanno parte della spesa
    this.rows.forEach(function(obj){
      if (obj.out_amount > 0){
        if (causalOut.indexOf(obj.causal) != -1){
          total -= obj.out_amount;
        }
      }
    });

    this.max_total_quote_subscribers = total;
  }

  calculateCurrentExpence(){

    if (this.rows == null || this.max_total_quote_subscribers <= 0)
      return;

    let _total_addout = 0;

    for (let key in this.rows){

      let r = this.rows[key];

      if (r.out_amount > 0){
        _total_addout += r.out_amount;
      }

    }

    let _total_addout_perc = _total_addout * 100 / this.max_total_quote_subscribers;
    this.total_quote_subscribers = _total_addout;
    this.total_quote_subscribers_perc = ""+(Math.round(_total_addout_perc * 100) / 100)+"%";

  }

  getCampService() {
    return this.campService
  }

}
