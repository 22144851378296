<!-- BEGIN CONTENT BODY -->
<div class="page-content">
    <div class="row">
        <div class="col-md-12">

            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                    <!-- BEGIN SAMPLE FORM PORTLET-->
                    <div class="portlet light bordered">
                        <div class="portlet-title">
                            <div class="caption font-red-sunglo">
                                <i class="icon-settings font-red-sunglo"></i>
                                <span class="caption-subject bold uppercase"> Login Form</span>
                            </div>
                        </div>
                        <div *ngIf="loginError" class="alert alert-danger display-hide" style="display: block;">
                            <span> {{loginError.statusText}} </span>
                        </div>
                        <div class="portlet-body form">
                            <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                                <div class="form-body">
                                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                                        <label>Email Address or Username</label>
                                        <div class="input-group">
                                            <span class="input-group-addon">
                                                <i class="fa fa-envelope fa-fw"></i>
                                            </span>
                                            <input type="text" class="form-control" placeholder="Email Address or Username" name="email" [(ngModel)]="model.email" #email="ngModel"
                                                required (ngModelChange)="valuechange($event)"> </div>
                                        <div *ngIf="f.submitted && !email.valid" class="help-block">email is required</div>
                                    </div>
                                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                                        <label>Password</label>
                                        <div class="input-group">
                                            <span class="input-group-addon">
                                                <i class="fa fa-lock fa-fw"></i>
                                            </span>
                                            <input type="password" class="form-control" placeholder="Password" name="password" [(ngModel)]="model.password" #password="ngModel"
                                                required (ngModelChange)="valuechange($event)"> </div>
                                        <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <button [disabled]="loading" class="btn btn-primary">Login</button>
                                    <img *ngIf="loading" src="./assets/global/img/input-spinner.gif" />
                                    <a style="margin-left: 20px;" target="_blank" href="{{resetPasswordLink}}">Forgot your password?</a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- END SAMPLE FORM PORTLET-->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END CONTENT BODY -->
