import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { PersonBankAccountFormComponent } from '../index';
import { PersonService } from '../../_services/index';
import { NotificationsService } from 'angular2-notifications';

@Component({
	selector: 'app-person-bank-account-list',
	templateUrl: './person-bank-account-list.component.html',
	styleUrls: ['./person-bank-account-list.component.css']
})
export class PersonBankAccountListComponent implements OnInit {

	@ViewChildren('personBankAccountFormList') public personBankAccountFormList: QueryList<PersonBankAccountFormComponent>;

	personBankAccountList: any;
	personBankAccountListKeys: any[];

	allFormValid = true;
	multiSaveForm = true;

	subscribe: any;

	constructor(private personService: PersonService, private notify: NotificationsService) { }

	ngOnInit() {

		this.personBankAccountList = this.personService.currentPersonBankAccountList;

		if (this.personBankAccountList == null)
			this.addNew();

		this.subscribe = this.personService.getsubjectToUpdateObservable().subscribe((update: boolean) => {

			if (this.personService.currentPersonBankAccountList != null) {

				let newFormModel = null;
				if (typeof this.personBankAccountFormList.first.model.id == "undefined"
					&& this.personBankAccountFormList.first.currentForm.form.dirty
					&& this.personBankAccountFormList.first.currentForm.form.pending) {
					newFormModel = this.personBankAccountFormList.first.model;
				}

				this.personBankAccountListKeys = Object.keys(this.personService.currentPersonBankAccountList)

				let personBankAccountListOld = this.personBankAccountList;
				this.personBankAccountList = this.personService.currentPersonBankAccountList;

				this.personBankAccountListKeys.forEach(k => {

					if (typeof personBankAccountListOld[k] != "undefined" && this.personBankAccountList[k]["updated_at"] == personBankAccountListOld[k]["updated_at"])
						this.personBankAccountList[k] = personBankAccountListOld[k];
				});

				if (newFormModel != null) {
					this.personBankAccountList[0] = newFormModel;
					this.personBankAccountListKeys.unshift(0);
				}

			}

			if (this.personService.currentPersonBankAccountList != null && this.personService.currentPersonBankAccountList.length == 0) {
				this.addNew();
			}
		});
	}

	ngOnDestroy(){

    this.subscribe.unsubscribe();
  }


	addNew() {

		//	cancello sempre la lista
		if (this.personBankAccountList == null) {
			this.personBankAccountList = {};
			this.personBankAccountListKeys = new Array();
		}

		this.personBankAccountList[0] = {};
		this.personBankAccountListKeys.unshift(0);

		//  Assegno il bank account come primary perchè è il primo della lista
		if (this.personBankAccountListKeys.length == 1)
			this.personBankAccountList[0].primary = true;
	}



	saveAll() {

		this.allFormValid = true;

		// Controllo se tutti i form sono validi
		this.personBankAccountFormList.forEach(element => {

			if (!element.currentForm.form.valid) {

				//  Controllo se non è valido

				for (var i in element.currentForm.controls) {
					element.currentForm.controls[i].markAsTouched();
				}

				this.allFormValid = false;
				this.notify.error("There is a form with error");
			}

		});

		if (!this.allFormValid)
			return;

		if (!this.checkPrimary())
			return;

		this.personBankAccountFormList.forEach(element => {
			element.submit();
		});
	}

	checkPrimary() {

		// controllo se esiste almeno un contatto primary
		let primaryFound = false;

		this.personBankAccountFormList.forEach(element => {
			if (element.model.primary)
				primaryFound = true;
		});

		if (!primaryFound) {
			this.notify.error("You need to set a primary payments");
			return false;
		}

		return true;
	}

	primaryUpdate(event) {
		//  Cambio il valore di tutti i campi primary
		if (event.primary == true) {
			this.personBankAccountListKeys.forEach(element => {
				if (this.personBankAccountList[element].id != event.id) {
					this.personBankAccountList[element].primary_backup = this.personBankAccountList[element].primary;
					this.personBankAccountList[element].primary = 0;
				}
			});
		}
		else {
			this.personBankAccountListKeys.forEach(element => {
				if (this.personBankAccountList[element].primary_backup && this.personBankAccountList[element].id != event.id) {
					this.personBankAccountList[element].primary = this.personBankAccountList[element].primary_backup;
				}
			});
		}
	}



	formDelete(event) {
		if (!event.model.id && this.personBankAccountListKeys.length > 1) {
			this.personBankAccountListKeys.splice(0, 1);
			delete this.personBankAccountList[0];
		}
	}

	formSaved(event) {

		// controllo se questo form ha il primary impostato, perché potrebbe aver cambiato anche gli altri
		if (event.primary == true) {

			this.personBankAccountFormList.forEach(element => {

				if (event.id != element.model.id) {

					if (typeof element.model.primary_backup != "undefined" && element.model.primary_backup != element.model.primary) {
						element.currentForm.form.markAsDirty();
						element.submit();
					}
				}
			});
		}
  }

  getPersonService() {
    return this.personService
  }

}
