<div class="portlet light bordered">
  <div class="portlet-title">
    <div class="caption"><b>Active Filters:</b>
      <small *ngIf="model.year">Anno:<b>{{model.year}}</b></small>
      <small *ngIf="model.type"> - Tipo camp:<b>{{getCampTypeValueByKey(model.type)}}</b></small>
      <small *ngIf="model.region"> - Regione:<b>{{model.region}}</b></small>
      <small *ngIf="model.area"> - Area:<b>{{getAreaExpertiseValueByKey(model.area)}}</b></small>
      <small *ngIf="model.status?.length > 0 "> - Stato camp :<b>"<small *ngFor="let id of model.status"> {{getCampStatusValueByKey(id)}}</small>"</b></small>
      <small *ngIf="model.company"> - Company:<b>{{getGroupCompanyValueByKey(model.company)}}</b></small>
      <small *ngIf="model.week_start"> - Inizio camp:<b>{{getDateStart()}}</b></small>
      <small *ngIf="model.week_end"> - Fine camp:<b>{{getDateEnd()}}</b></small>
    </div>
    <div class="actions">
      <div class="btn-group btn-group-devided" data-toggle="buttons">
        <button *ngIf="isHidden" type="button" class="btn btn-circle btn-outline green" [disabled]="isOngoingRequest()"
          (click)="isHidden = !isHidden">
          <i class="fa fa-search"></i>&nbsp;Filter</button>
        <button *ngIf="!isHidden" type="button" class="btn btn-circle btn-outline red" [disabled]="isOngoingRequest()"
        (click)="isHidden = !isHidden">
          <i class="fa fa-times"></i>&nbsp;Hide</button>
      </div>
    </div>
  </div>

  <form [hidden]="isHidden" class=" portlet-body form" action="#" name="form" (ngSubmit)="f.form.valid && submitSearch()" #f="ngForm" novalidate>
    <div class="form-body">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Anno</label>
            <select class="form-control" name="year" [(ngModel)]="model.year" (change)="fieldChange($event);">
              <option *ngFor="let y of yearSelect(-10)" [value]="y">{{y}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Tipo</label>
            <select class="form-control" name="type" [(ngModel)]="model.type" (change)="fieldChange($event);">
              <option value=""></option>
              <option *ngFor="let t of campTypeSelect()" [value]="t.key">{{t.value}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Region</label>
            <input type="text" class="form-control" placeholder="" name="region" [(ngModel)]="model.region" (change)="fieldChange($event);">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Company</label>
            <select class="form-control" name="company" [(ngModel)]="model.company" (change)="fieldChange($event);">
              <option value=""></option>
              <option *ngFor="let c of groupCompaniesSelect()" [value]="c.key">{{c.value}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Area</label>
            <select class="form-control" name="area" [(ngModel)]="model.area" (change)="fieldChange($event);">
              <option value=""></option>
              <option *ngFor="let z of areaExpertiseSelect()" [value]="z.key">{{z.value}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Date From</label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_from_date="angular-mydatepicker" name="from_date" class="form-control"
                  [locale]="getHelper().locale"
                  [options]="getDatePickerOptions()"
                  [ngModel]="getDateStart()"
                  (dateChanged)="onDateStartChanged($event)"
                />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model?.week_start" class="btn default" (click)="dp_from_date.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                    <button type="button" class="btn default" (click)="dp_from_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                </span>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Date To</label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_to_date="angular-mydatepicker" name="to_date" class="form-control"
                  [locale]="getHelper().locale"
                  [options]="getDatePickerOptions()"
                  [ngModel]="getDateEnd()"
                  (dateChanged)="onDateEndChanged($event)"
                />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model?.week_end" class="btn default" (click)="dp_to_date.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                    <button type="button" class="btn default" (click)="dp_to_date.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="">
            <label class="control-label">Camp Status (multiple selection)</label>
            <div class="form-group">
              <div class="mt-checkbox-inline">
                <label class="mt-checkbox" *ngFor="let status of multipleSelect">
                  <input type="checkbox" [(ngModel)]="status.value" [ngModelOptions]="{standalone: true}"> {{getConfigService().getCampStatusValueByKey(status.key)}}
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      &nbsp;
    </div>

    <div class="form-actions right">
      <button type="button" class="btn default" (click)="clearAdvancedSearch()">Clear
          <i class="fa fa-eraser" aria-hidden="true"></i>
      </button>
      <button type="submit" [disabled]="isOngoingRequest()" class="btn green">Search
        <i class="fa fa-search"></i>
      </button>
    </div>

  </form>

</div>
