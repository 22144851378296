<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
    <div class="form-body">

      <div class="row">
        <div class="col-md-6">
          <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalDataStart())) }">
            <label class="control-label">Start Date
              <span class="required">*</span>
            </label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_start="angular-mydatepicker" name="start" class="form-control"
                  [locale]="getHelper().locale"
                  [disabled]="loadingData || getCourseScheduleService().isOngoingRequest()"
                  [options]="getDatePickerOptions()"
                  [ngModel]="getLocalDataStart()"
                  (dateChanged)="onDateStartChanged($event)"
                />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model?.start" class="btn default" (click)="dp_start.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                    <button type="button" class="btn default" (click)="dp_start.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                </span>
            </div>
            <span class="help-block"> Date is required</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group" [ngClass]="{ 'has-error': (f.submitted && !getHelper().isMyDateValid(getLocalDataEnd())) }">
            <label class="control-label">End Date
              <span class="required">*</span>
            </label>
              <div class="input-group">
                <!-- input box -->
                <input angular-mydatepicker #dp_end="angular-mydatepicker" name="end" class="form-control"
                  [locale]="getHelper().locale"
                  [disabled]="loadingData || getCourseScheduleService().isOngoingRequest()"
                  [options]="getDatePickerOptions()"
                  [ngModel]="getLocalDataEnd()"
                  (dateChanged)="onDateEndChanged($event)"
                />
                <!-- clear date button -->
                <span class="input-group-btn">
                  <button type="button" *ngIf="model?.end" class="btn default" (click)="dp_end.clearDate()"><i class="fa fa-close"></i></button>
                </span>
                <!-- toggle calendar button -->
                <span class="input-group-btn">
                    <button type="button" class="btn default" (click)="dp_end.toggleCalendar()"><i class="fa fa-calendar-o"></i></button>
                </span>
            </div>
            <span class="help-block"> Date is required</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Also copy the Staff</label>
            <div class="mt-checkbox-inline">
              <label class="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="staffcopy" [disabled]="loadingData || getCourseScheduleService().isOngoingRequest()" [(ngModel)]="staffcopy"
                  value="1"> Yes
                <span></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions right">
      <img *ngIf="getCourseScheduleService().isOngoingRequest() || this.loadingData" src="./assets/global/img/input-spinner.gif" />
      <button type="submit" class="btn blue" [disabled]=" loadingData || getCourseScheduleService().isOngoingRequest()">
        <span class="ladda-label">
          <i class="fa fa-clone" aria-hidden="true"></i> Copy</span>
      </button>

    </div>

  </form>
