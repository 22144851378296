import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroCurrency'
})
export class ZeroCurrencyPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (typeof value != "undefined" && value != null)
    {
      if (value != 0)
        return value;
    }

    return '-';
  }

}