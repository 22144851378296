import { Component, OnInit, Input, DoCheck, KeyValueDiffers } from '@angular/core';
import { HelperService } from 'app/_services';
import CodiceFiscale from 'codice-fiscale-js';
import { isEqual, parseISO, toDate } from 'date-fns';

@Component({
  selector: 'check-person-taxid',
  templateUrl: './check-person-taxid.component.html',
  styleUrls: ['./check-person-taxid.component.css']
})
export class CheckPersonTaxidComponent implements OnInit {

  @Input() personInfo: any;
  differ: any;
  validID = true;
  errorMessage: any;

  constructor(private helper: HelperService, private differs: KeyValueDiffers) {
    this.differ = differs.find({}).create();
   }

  ngOnInit() {
  }

  ngDoCheck (){

    if (this.differ.diff(this.personInfo))
      this.checkTaxID();
  }


  private checkTaxID() {

    this.validID = true;

    if (this.personInfo.tax_id == null || typeof this.personInfo.tax_id == "undefined")
      return;

    if (!CodiceFiscale.check(this.personInfo.tax_id))
    {
      this.validID = false;
      this.errorMessage = "The Tax Code is not correnct";
      return;
    }

    //  controllo con le info che possiedo della persona
    var cf = new CodiceFiscale(this.personInfo.tax_id).toJSON();
    if (this.personInfo.birthday != null && typeof this.personInfo.birthday != "undefined")
    {
      if (!isEqual(parseISO(this.personInfo.birthday), toDate(new Date(cf.year, cf.month-1, cf.day))))
      {
        this.validID = false;
        this.errorMessage = "<p>The Birthday is not correct " +
                            "<br>current CF value:<b>" + cf.day+"/"+cf.month+"/"+cf.year +"</b></p>";
        return;
      }
    }

    if (this.personInfo.gender != null && typeof this.personInfo.gender != "undefined")
    {
      if (this.personInfo.gender.toUpperCase() != cf.gender)
      {
        this.validID = false
        this.errorMessage = "<p>The Gender is not correct " +
                            "<br>current CF value:<b>" + cf.gender +"</b></p>";
        return;
      }
    }

    if (this.personInfo.birthplace != null && typeof this.personInfo.birthplace != "undefined")
    {
      if (this.personInfo.birthplace.length > 0)
      {
        if (this.personInfo.birthplace.toUpperCase() != cf.birthplace)
        {
          this.validID = false;
          this.errorMessage = "<p>The Birthplace is not correct " +
                            "<br>current CF value:<b>" + cf.birthplace +"</b>." +
                            "<br><br>Care, the birthplace must be contain only city name </p>";
          return;
        }
      } else {
        this.validID = false;
        this.errorMessage = "The Birthplace is not present";
        return;
      }
    } else {
      this.validID = false;
      this.errorMessage = "The Birthplace is not present";
      return;
    }
  }

}
