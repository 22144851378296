import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../environment';

import * as Sentry from "@sentry/browser";
import { BackendService } from './backend.service';

@Injectable()
export class AuthenticationService {

  private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

  getLoggeginObservable(): Observable<boolean> {
    return this.subjectToUpdate.asObservable();
  }

  constructor(private http: HttpClient, private backend: BackendService) { }

  login(email: string, password: string, callbackSuccess: any = null, callbackError: any = null) {

    this.http.post(environment.apiUrl + "auth/login", JSON.stringify({ email: email, password: password })).subscribe(
      (response: Response) => {
        // login successful if there's a jwt token in the response
        let data: any = response;
        let user = data.user;
        //let session = data.session;
        if (data && data.user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          //localStorage.setItem('currentSession', JSON.stringify(session));
          localStorage.setItem('Api-Token', user.api_token);

          Sentry.configureScope(scope => {
            scope.setUser({
              email: user.email,
              id: user.id,
              username: user.name
            });
          });

          this.subjectToUpdate.next(true);

          if (callbackSuccess != null)
            callbackSuccess(user);
        }
      },
      (error) => {
        this.backend.showErrors(error);

        if (callbackError != null)
          callbackError();
      });
  }

  checkAndUpdateCurrentUserData(frequency = 1000*60*60) {

    // Aggiorno il profilo della persona
    // 2 secondi dopo l'avvio (per problemi di accavallamento di chiamate)
    // ogni 60 minuti per verificare eventuali scadenze di sessione e cambio di permessi
    let self = this;
    setTimeout(() => {

      self.updateCurrentUserData();

      setInterval(() => {
        self.updateCurrentUserData();
      }, frequency);

    }, 2000);
  }

  updateCurrentUserData() {

    let params = new HttpParams();

    if (localStorage.getItem('currentUser')) {

      this.backend.get('user/current', params).subscribe(
        (response: any) => {
          let user = response.user;

          if (user) {
            localStorage.setItem('currentUser', JSON.stringify(user));
          }

        },
        (error) => {
          this.backend.showErrors(error);
          // localStorage.removeItem('currentUser');
        });
    }
  }

  update(currentUserString: any) {

    localStorage.setItem('currentUser', JSON.stringify(currentUserString));
  }


  logout() {

    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    //localStorage.removeItem('currentSession');
    //localStorage.removeItem('API-Token');

    this.subjectToUpdate.next(false);
  }
}
