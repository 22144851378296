import { Component, OnInit } from '@angular/core';
import { CampService, ConfigService, HelperService, RevisionService } from './../../_services/index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-camp-revision',
  templateUrl: './camp-revision.component.html',
  styleUrls: ['./camp-revision.component.css']
})
export class CampRevisionComponent implements OnInit {


  rows: any[] = [];

  selected = [];

  ongoingRequest: boolean = false;

  constructor(
    private revisionService: RevisionService,
    private router: Router,
    private campService: CampService,
    private configService: ConfigService,
    private helper: HelperService) { }

  ngOnInit() {

    //this.getRevision();
  }

  ngOnChanges() {

    //this.getRevision();
  }

  onSelect({ selected }) {

  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  getSelectedIx() {
    return this.selected[0]['$$index'];
  }

  getRowClass(row) {

  }

  getRevision() {

    this.ongoingRequest = true;
    this.rows = [];

    this.revisionService.getCampRevision(this.campService.currentCampId, (list) => {

      list.sort(function(a,b){
        if (a.revision.created_at < b.revision.created_at)
          return 1;
        if (a.revision.created_at > b.revision.created_at)
          return -1;
        return 0;
      });

      this.rows = list;

      this.ongoingRequest = false;
    });

  }

}
