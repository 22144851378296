import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CourseScheduleService, HelperService } from 'app/_services';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-course-schedule-subscriber-copy',
  templateUrl: './course-schedule-subscriber-copy.component.html',
  styleUrls: ['./course-schedule-subscriber-copy.component.css']
})
export class CourseScheduleSubscriberCopyComponent implements OnInit {

  @Input() subscribers: any;
  @Output() subscriberCopied = new EventEmitter();

  courseScheduleList: any[] = null;

  private localData: IMyDateModel;

  model: any = {};
  modelSearch: any = {};
  loadingData: boolean = false;

  //  Impostazione della data che vengono passate dall'helper
  public datePickerOptions: IAngularMyDpOptions = {};

  constructor(private courseScheduleService: CourseScheduleService, private helper: HelperService) { }

  ngOnInit() {

    //  Copio il model passato ricreando un nuovo oggetto
    this.subscribers =  JSON.parse(JSON.stringify(this.subscribers));
    this.model = {};
  }


  submit ()
  {

    //  se non è stato selezionato lo schedule id allora non vado avanti
    if (this.model.course_schedule_id == null) return;

    this.loadingData = true;

    //  setto i subscribers prima di copiarli
    this.model.subscribers = this.subscribers;

    this.courseScheduleService.saveMassiveCourseSubscriber(this.model, () => {

      this.loadingData = false;
      this.subscriberCopied.emit(true);
    }, () => {

      this.loadingData = false;
    });

  }

  onSelectChange (event)
  {
    this.model.course_schedule_id = event;
  }

  searchCourse ()
  {
    //  se la data è valida faccio la ricerca
    if (this.helper.isMyDateValid(this.localData))
    {
      this.courseScheduleService.getAllCourseSchedulesSearch(this.modelSearch, (searchResult) => {

        //  sort by date
        searchResult.sort(function(a,b){
          return parseISO(a.start).getTime() - parseISO(b.start).getTime();
        });

        searchResult.forEach(course => {

          course.description = "" + course.title + " - ";

          if (course.administrative_area_level_3_long_version != null)
            course.description += course.administrative_area_level_3_long_version;

          if (course.administrative_area_level_2_short_version != null)
            course.description += " (" + course.administrative_area_level_2_short_version + ") ";

          if (course.administrative_area_level_1_long_version != null)
            course.description += " " +course.administrative_area_level_1_long_version;

            course.description += " - " + parseISO(course.start).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
            course.description += " -> " + parseISO(course.end).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
        });

        this.courseScheduleList = searchResult;
      })
    }
  }


  //  Metodi per la data
  onDateChanged(event: IMyDateModel) {

    this.localData = event;

    if (event.singleDate.jsDate != null)
    {
      this.modelSearch.from_date = this.helper.formatISODate(event);
      this.modelSearch.to_date = this.modelSearch.from_date;

      this.courseScheduleList = null;
    }

  }


  getLocalData() {
    return this.localData
  }

  getCourseScheduleService() {
    return this.courseScheduleService;
  }

  getHelper() {
    return this.helper
  }

  getDatePickerOptions() {
    return this.datePickerOptions
  }
}
