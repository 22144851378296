import { environment } from './../environment';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Subject ,  Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { HttpParams } from '@angular/common/http';
import { UserService } from 'app/_services/user.service';
import { ShipmentService } from './shipment.service';

@Injectable()
export class GroupService {

	currentGroupId;
	currentGroupInfo: any;
	currentShipmentList: any[];
	currentAssignments: any[] = [];

	ongoingRequest: boolean = false;


	private subjectToUpdate: Subject<boolean> = new Subject<boolean>();

	constructor(private backend: BackendService, private notify: NotificationsService, private userService: UserService, private shipmentService: ShipmentService) { }

	getsubjectToUpdateObservable(): Observable<boolean> {
		return this.subjectToUpdate.asObservable();
	}

	resetService() {
		this.currentGroupId = null;
		this.currentGroupInfo = null;
		this.currentShipmentList = null;
		this.currentAssignments = null;
	}

	startRequest() {
		this.ongoingRequest = true;
	}

	finishRequest() {
		this.ongoingRequest = false;
	}

	isOngoingRequest() {
		return this.ongoingRequest;
	}

	getActorGroupInfoAll(callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		let params = new HttpParams();

		this.backend.get('actorgroup/' + this.currentGroupId + '/all', params).subscribe(
			(response) => {

				let resJson : any = response;
				this.currentGroupInfo = resJson;
				this.currentShipmentList = resJson.shipments;
				this.currentAssignments = resJson.assignments;
				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			});
  }

  getCurrentUserActorGroupInfoAll(callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

    let params = new HttpParams();

    let u = this.userService.getCurrentUser();

		this.backend.get('actorgroup/'+u.person_info.id+'/current', params).subscribe(
			(response) => {

				let resJson : any = response;
        this.currentGroupInfo = resJson;
        this.currentGroupId = resJson.id;
				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();

				if (callbackError != null)
					callbackError();
			});
	}

	saveActorGroupInfo(model: any[], callbackSuccess: any = null) {

		this.startRequest();

		this.backend.post('actorgroup', model).subscribe(
			(response) => {

				let resJson : any = response;
				this.currentGroupInfo = resJson;
				this.currentGroupId = resJson.id;
				this.subjectToUpdate.next(true);

				this.notify.success("Actor Group Info", "successfully saved");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}

	updateActorGroupInfo(model: any[]) {

		this.startRequest();

		this.backend.put('actorgroup/' + this.currentGroupId, model).subscribe(
			(response) => {

				this.currentGroupInfo = response;
				this.currentGroupId = this.currentGroupInfo.id;
				this.subjectToUpdate.next(true);

				this.notify.success("Actor Group Info", "successfully updated");

				this.finishRequest();
			},
			(error) => {
				console.log("Errore");
				console.log(error);

				this.finishRequest();
			});
	}

	deleteActorGroupInfo(model: any, callbackSuccess: any = null) {

		this.startRequest();

		this.backend.destroy('actorgroup/' + this.currentGroupId).subscribe(
			(response) => {

				this.resetService();
				this.subjectToUpdate.next(true);

				this.notify.success("Actor Group Info", "successfully deleted");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}


	addGroupMember(model: any[], callbackSuccess: any = null) {

		this.startRequest();

		let member :any = {};
		member.person_id = model['person_id'];
    	member.group_id = this.currentGroupId;

		this.backend.post('groupmember', member).subscribe(
			(response) => {

				// let resJson : any = response;

				this.notify.success("Group Member", "successfully added");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);

				this.finishRequest();
			}
		);
	}


	updateGroupMember (model: any)
	{
		this.startRequest();

		this.backend.put('groupmember/' + model.id, model).subscribe(
			(response) => {

				this.notify.success("Group Member", "successfully updated");

				this.finishRequest();
			},
			(error) => {
				this.backend.showErrors(error);
			}

		);
	}


	removeGroupMember(idMember: any, callbackSuccess: any = null) {

		this.startRequest();

		this.backend.destroy('groupmember/' + idMember).subscribe(
			(response) => {

				this.notify.success("Group Member", "successfully removed");

				this.finishRequest();

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				this.backend.showErrors(error);
			}
		);

	}




	getGroupShipment (callbackSuccess: any = null, callbackError: any = null)
	{
		let params = new HttpParams();

		this.backend.get('actorgroup/'+this.currentGroupId+'/shipment', params).subscribe(
			(response) => {

				var resJson : any = response;

				this.finishRequest();

				this.currentShipmentList = resJson;

				if (callbackSuccess != null)
					callbackSuccess();
			},
			(error) => {
				console.log("Errore");

				this.finishRequest();

				if (callbackError)
					callbackError();
			}
		);

	}




	saveGroupShipment(model: any, callbackSuccess: any = null, callbackError: any = null) {

		this.startRequest();

		model.holder_type = "App\\ActorGroup";
		model.holder_id = this.currentGroupId;

		this.shipmentService.saveShipment(model, (shipment) => {

			if (this.currentShipmentList == null)
				this.currentShipmentList = [];

				this.currentShipmentList.push(shipment);
				this.subjectToUpdate.next(true);

				this.finishRequest();

				if (callbackSuccess)
					callbackSuccess(shipment);
		}, (error) => {
				this.finishRequest();

				if (callbackError)
					callbackError();
		});

	}




	updateGroupShipment(model:any, callbackSuccess:any = null, callbackError:any = null)
	{
		this.startRequest();

		this.shipmentService.updateShipment(model, (shipment) => {

			for (var key in this.currentShipmentList)
				if (this.currentShipmentList[key].id == shipment.id)
					this.currentShipmentList[key] = shipment;

			this.subjectToUpdate.next(true);

			this.finishRequest();

			if (callbackSuccess)
				callbackSuccess(shipment);
		}, (error) => {

			this.finishRequest();

			if (callbackError)
				callbackError();
		})
	}




	deleteGroupShipment(model:any, callbackSuccess:any = null, callbackError:any = null)
	{
		this.startRequest();

		this.shipmentService.deleteShipment(model, (shipment) => {

			for (var key in this.currentShipmentList)
				if (this.currentShipmentList[key].id == model.id)
				{
					this.currentShipmentList.splice(parseInt(key), 1);
					break;
				}

			this.subjectToUpdate.next(true);

			this.finishRequest();

			if (callbackSuccess)
				callbackSuccess();
		}, (error) => {

			this.finishRequest();

			if (callbackError)
				callbackError();
		})
	}


}
