<h4><i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>&nbsp;Reminder scaduti</h4>
<alert type="warning" *ngIf="loadingData">
  <strong>Loading</strong> ...
</alert>

<ngx-datatable *ngIf="!loadingData" class="striped" [rows]="rowsPast" [columnMode]="'flex'" [headerHeight]="0"
  [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true" [sorts]="[{prop: 'start_date_reminder', dir: 'desc'}, {prop: 'start_time_reminder', dir: 'asc'}]">
  <ngx-datatable-column name="Date" prop="start_date_reminder" [resizeable]="false" [flexGrow]="2">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <i *ngIf="row.complete_id" class="fa fa-check-square-o text-success" aria-hidden="true"></i>
      <span *ngIf="value">{{ value | date : 'shortDate' }}</span><br>
      <!-- <small>{{ row.created_at_parsed | date }}</small> -->
      <small><span *ngIf="!row.all_the_day && row.start_time_reminder"><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;{{
          row.start_time_reminder }}</span><span *ngIf="row.all_the_day"><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;All
          day</span></small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Reminder" prop="title_reminder" [resizeable]="false" [flexGrow]="4">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ value }}</span><br>
      <span class="line-breaker"><small *ngIf="row.annotation_reminder">{{ row.annotation_reminder }}</small></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Notification" prop="notification_date_time" [resizeable]="false" [flexGrow]="3">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <i *ngIf="isPastNotification(row.notification_date_time)" class="fa fa-bell text-danger" aria-hidden="true"></i>
      <span *ngIf="value">{{ row.notification_date_time_parsed | date : 'shortDate' }} <small>{{
          row.notification_date_time_parsed | date : 'H:mm' }}</small></span><br>
      <small *ngIf="row.notification_destination">{{ row.notification_destination }}</small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Holder" prop="holder_name" [resizeable]="false" [flexGrow]="5">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <a *ngIf="row.holder_url_path" class="blue pointer btn btn-outline default btn-sm" routerLink="/{{row.holder_url_path}}/edit/{{ row.holder_data.id }}"><i
          class="fa fa-{{row.holder_icon}}"></i> {{ value }}<i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze"
          aria-hidden="true"></i></a>
      <span *ngIf="!row.holder_url_path"><i class="fa fa-{{row.holder_icon}}"></i> {{ value }}<i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze"
          aria-hidden="true"></i></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Action" *ngIf="!readOnlyMode" prop="" [resizeable]="false" [flexGrow]="3">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button *ngIf="!row.complete_id" class="btn default btn-sm green-jungle" [disabled]="loadingData" type="button"
        (click)="markAsCompleted(row.id)"><i class="fa fa-check-square-o" aria-hidden="true"></i></button>
      <button class="blue btn default btn-sm" (click)="viewReminder(row)"><i class="fa fa-edit" aria-hidden="true"></i></button>
      <a *ngIf="row.holder_url_path" class="blue pointer btn btn-outline default btn-sm" target="_blank"
        routerLink="/{{row.holder_url_path}}/edit/{{ row.holder_data.id }}"><i class="fa fa-external-link" aria-hidden="true"></i></a>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>


<h4><i class="fa fa-calendar-check-o" aria-hidden="true"></i>&nbsp;Oggi</h4>
<alert type="warning" *ngIf="loadingData">
  <strong>Loading</strong> ...
</alert>

<ngx-datatable *ngIf="!loadingData" class="striped" [rows]="rowsToday" [columnMode]="'flex'" [headerHeight]="0"
  [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true" [sorts]="[{prop: 'start_date_reminder', dir: 'desc'}, {prop: 'start_time_reminder', dir: 'asc'}]">
  <ngx-datatable-column name="Date" prop="start_date_reminder" [resizeable]="false" [flexGrow]="2">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <i *ngIf="row.complete_id" class="fa fa-check-square-o text-success" aria-hidden="true"></i>
      <span *ngIf="value">{{ value | date : 'shortDate' }}</span><br>
      <!-- <small>{{ row.created_at_parsed | date }}</small> -->
      <small><span *ngIf="!row.all_the_day && row.start_time_reminder"><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;{{
          row.start_time_reminder }}</span><span *ngIf="row.all_the_day"><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;All
          day</span></small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Reminder" prop="title_reminder" [resizeable]="false" [flexGrow]="4">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ value }}</span><br>
      <span class="line-breaker"><small *ngIf="row.annotation_reminder">{{ row.annotation_reminder }}</small></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Notification" prop="notification_date_time" [resizeable]="false" [flexGrow]="3">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <i *ngIf="isPastNotification(row.notification_date_time)" class="fa fa-bell text-danger" aria-hidden="true"></i>
      <span *ngIf="value">{{ row.notification_date_time_parsed | date : 'shortDate' }} <small>{{
          row.notification_date_time_parsed | date : 'H:mm' }}</small></span><br>
      <small *ngIf="row.notification_destination">{{ row.notification_destination }}</small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Holder" prop="holder_name" [resizeable]="false" [flexGrow]="5">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <a *ngIf="row.holder_url_path" class="blue pointer btn btn-outline default btn-sm" routerLink="/{{row.holder_url_path}}/edit/{{ row.holder_data.id }}"><i
          class="fa fa-{{row.holder_icon}}"></i> {{ value }}<i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze"
          aria-hidden="true"></i></a>
      <span *ngIf="!row.holder_url_path"><i class="fa fa-{{row.holder_icon}}"></i> {{ value }}<i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze"
          aria-hidden="true"></i></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Action" *ngIf="!readOnlyMode" prop="" [resizeable]="false" [flexGrow]="3">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button *ngIf="!row.complete_id" class="btn default btn-sm green-jungle" [disabled]="loadingData" type="button"
        (click)="markAsCompleted(row.id)"><i class="fa fa-check-square-o" aria-hidden="true"></i></button>
      <button class="blue btn default btn-sm" (click)="viewReminder(row)"><i class="fa fa-edit" aria-hidden="true"></i></button>
      <a *ngIf="row.holder_url_path" class="blue pointer btn btn-outline default btn-sm" target="_blank"
        routerLink="/{{row.holder_url_path}}/edit/{{ row.holder_data.id }}"><i class="fa fa-external-link" aria-hidden="true"></i></a>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>


<h4><i class="fa fa-calendar-plus-o" aria-hidden="true"></i>&nbsp;Domani</h4>
<alert type="warning" *ngIf="loadingData">
  <strong>Loading</strong> ...
</alert>

<ngx-datatable *ngIf="!loadingData" class="striped" [rows]="rowsTomorrow" [columnMode]="'flex'" [headerHeight]="0"
  [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true" [sorts]="[{prop: 'start_date_reminder', dir: 'desc'}, {prop: 'start_time_reminder', dir: 'asc'}]">
  <ngx-datatable-column name="Date" prop="start_date_reminder" [resizeable]="false" [flexGrow]="2">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <i *ngIf="row.complete_id" class="fa fa-check-square-o text-success" aria-hidden="true"></i>
      <span *ngIf="value">{{ value | date : 'shortDate' }}</span><br>
      <!-- <small>{{ row.created_at_parsed | date }}</small> -->
      <small><span *ngIf="!row.all_the_day && row.start_time_reminder"><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;{{
          row.start_time_reminder }}</span><span *ngIf="row.all_the_day"><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;All
          day</span></small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Reminder" prop="title_reminder" [resizeable]="false" [flexGrow]="4">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ value }}</span><br>
      <span class="line-breaker"><small *ngIf="row.annotation_reminder">{{ row.annotation_reminder }}</small></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Notification" prop="notification_date_time" [resizeable]="false" [flexGrow]="3">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <i *ngIf="isPastNotification(row.notification_date_time)" class="fa fa-bell text-danger" aria-hidden="true"></i>
      <span *ngIf="value">{{ row.notification_date_time_parsed | date : 'shortDate' }} <small>{{
          row.notification_date_time_parsed | date : 'H:mm' }}</small></span><br>
      <small *ngIf="row.notification_destination">{{ row.notification_destination }}</small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Holder" prop="holder_name" [resizeable]="false" [flexGrow]="5">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <a *ngIf="row.holder_url_path" class="blue pointer btn btn-outline default btn-sm" routerLink="/{{row.holder_url_path}}/edit/{{ row.holder_data.id }}"><i
          class="fa fa-{{row.holder_icon}}"></i> {{ value }}<i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze"
          aria-hidden="true"></i></a>
      <span *ngIf="!row.holder_url_path"><i class="fa fa-{{row.holder_icon}}"></i> {{ value }}<i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze"
          aria-hidden="true"></i></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Action" *ngIf="!readOnlyMode" prop="" [resizeable]="false" [flexGrow]="3">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button *ngIf="!row.complete_id" class="btn default btn-sm green-jungle" [disabled]="loadingData" type="button"
        (click)="markAsCompleted(row.id)"><i class="fa fa-check-square-o" aria-hidden="true"></i></button>
      <button class="blue btn default btn-sm" (click)="viewReminder(row)"><i class="fa fa-edit" aria-hidden="true"></i></button>
      <a *ngIf="row.holder_url_path" class="blue pointer btn btn-outline default btn-sm" target="_blank"
        routerLink="/{{row.holder_url_path}}/edit/{{ row.holder_data.id }}"><i class="fa fa-external-link" aria-hidden="true"></i></a>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>


<h4 *ngIf="showFutureReminder"><i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;Altri</h4>
<alert type="warning" *ngIf="showFutureReminder && loadingData">
  <strong>Loading</strong> ...
</alert>

<ngx-datatable *ngIf="showFutureReminder && !loadingData" class="striped" [rows]="rowsFuture" [columnMode]="'flex'"
  [headerHeight]="0" [footerHeight]="0" [rowHeight]="'auto'"
  [scrollbarH]="true" [sorts]="[{prop: 'start_date_reminder', dir: 'desc'}, {prop: 'start_time_reminder', dir: 'asc'}]">
  <ngx-datatable-column name="Date" prop="start_date_reminder" [resizeable]="false" [flexGrow]="2">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <i *ngIf="row.complete_id" class="fa fa-check-square-o text-success" aria-hidden="true"></i>
      <span *ngIf="value">{{ value | date : 'shortDate' }}</span><br>
      <small><span *ngIf="!row.all_the_day && row.start_time_reminder"><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;{{
          row.start_time_reminder }}</span><span *ngIf="row.all_the_day"><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;All
          day</span></small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Reminder" prop="title_reminder" [resizeable]="false" [flexGrow]="4">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ value }}</span><br>
      <span class="line-breaker"><small *ngIf="row.annotation_reminder">{{ row.annotation_reminder }}</small></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Notification" prop="notification_date_time" [resizeable]="false" [flexGrow]="3">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <i *ngIf="isPastNotification(row.notification_date_time)" class="fa fa-bell text-danger" aria-hidden="true"></i>
      <span *ngIf="value">{{ row.notification_date_time_parsed | date : 'shortDate' }} <small>{{
          row.notification_date_time_parsed | date : 'H:mm' }}</small></span><br>
      <small *ngIf="row.notification_destination">{{ row.notification_destination }}</small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Holder" prop="holder_name" [resizeable]="false" [flexGrow]="5">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <a *ngIf="row.holder_url_path" class="blue pointer btn btn-outline default btn-sm" routerLink="/{{row.holder_url_path}}/edit/{{ row.holder_data.id }}"><i
        class="fa fa-{{row.holder_icon}}"></i> {{ value }}<i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze"
        aria-hidden="true"></i></a>
    <span *ngIf="!row.holder_url_path"><i class="fa fa-{{row.holder_icon}}"></i> {{ value }}<i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze"
        aria-hidden="true"></i></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Action" *ngIf="!readOnlyMode" prop="" [resizeable]="false" [flexGrow]="3">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button *ngIf="!row.complete_id" class="btn default btn-sm green-jungle" [disabled]="loadingData" type="button"
        (click)="markAsCompleted(row.id)"><i class="fa fa-check-square-o" aria-hidden="true"></i></button>
      <button class="blue btn default btn-sm" (click)="viewReminder(row)"><i class="fa fa-edit" aria-hidden="true"></i></button>
      <a *ngIf="row.holder_url_path" class="blue pointer btn btn-outline default btn-sm" target="_blank"
        routerLink="/{{row.holder_url_path}}/edit/{{ row.holder_data.id }}"><i class="fa fa-external-link" aria-hidden="true"></i></a>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<br><br>

<h4 *ngIf="showCompletedReminder"><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Completed</h4>
<alert type="warning" *ngIf="showCompletedReminder && loadingReminderCompleted">
  <strong>Loading</strong> ...
</alert>

<ngx-datatable *ngIf="showCompletedReminder && !loadingReminderCompleted" class="striped" [rows]="rowsCompleted" [columnMode]="'flex'"
  [headerHeight]="0" [footerHeight]="50" [rowHeight]="'auto'"
  [scrollbarH]="true" [sorts]="[{prop: 'complete_date', dir: 'desc'}, {prop: 'start_time_reminder', dir: 'asc'}]"
  [externalPaging]="true"
  [count]="pageTotalElements"
  [offset]="pageNumber"
  [limit]="pageSize"
  (page)='setPage($event)'>
  <ngx-datatable-column name="Date" prop="start_date_reminder" [resizeable]="false" [flexGrow]="2">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <!-- <i *ngIf="row.complete_id" class="fa fa-check-square-o text-success" aria-hidden="true"></i> -->
      <span *ngIf="value">{{ value | date : 'shortDate' }}</span><br>
      <!-- <small>{{ row.created_at_parsed | date }}</small> -->
      <small><span *ngIf="!row.all_the_day && row.start_time_reminder"><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;{{
          row.start_time_reminder }}</span><span *ngIf="row.all_the_day"><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;All
          day</span></small>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Reminder" prop="title_reminder" [resizeable]="false" [flexGrow]="4">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ value }}</span><br>
      <span class="line-breaker"><small *ngIf="row.annotation_reminder">{{ row.annotation_reminder }}</small></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Completed" prop="complete_date" [resizeable]="false" [flexGrow]="3">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <i *ngIf="row.complete_id" class="fa fa-check-square-o text-success" aria-hidden="true"></i>
      <span *ngIf="value">{{ row.complete_date | date : 'shortDate' }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Holder" prop="holder_name" [resizeable]="false" [flexGrow]="5">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <a *ngIf="row.holder_url_path" class="blue pointer btn btn-outline default btn-sm" routerLink="/{{row.holder_url_path}}/edit/{{ row.holder_data.id }}"><i
        class="fa fa-{{row.holder_icon}}"></i> {{ value }}<i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze"
        aria-hidden="true"></i></a>
    <span *ngIf="!row.holder_url_path"><i class="fa fa-{{row.holder_icon}}"></i> {{ value }}<i *ngIf="row.holder_deleted" class="fa fa-trash font-red-haze"
        aria-hidden="true"></i></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Action" *ngIf="!readOnlyMode" prop="" [resizeable]="false" [flexGrow]="3">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button *ngIf="!row.complete_id" class="btn default btn-sm green-jungle" [disabled]="loadingData" type="button"
        (click)="markAsCompleted(row.id)"><i class="fa fa-check-square-o" aria-hidden="true"></i></button>
      <button class="blue btn default btn-sm" (click)="viewReminder(row)"><i class="fa fa-edit" aria-hidden="true"></i></button>
      <a *ngIf="row.holder_url_path" class="blue pointer btn btn-outline default btn-sm" target="_blank"
        routerLink="/{{row.holder_url_path}}/edit/{{ row.holder_data.id }}"><i class="fa fa-external-link" aria-hidden="true"></i></a>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>


<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-reminder-add-form [showCloseButton]="false" [reminderModel]="selectedRow" (modelUpdated)="modelUpdatedInModal($event)"></app-reminder-add-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
