import { ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotificationsService } from 'angular2-notifications';
import { BackendService, ConfigService, HelperService } from './../../_services/index';
import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { parseISO, getYear } from 'date-fns';
import { environment } from '../../environment';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-camp-documents',
  templateUrl: './camp-documents.component.html',
  styleUrls: ['./camp-documents.component.css']
})
export class CampDocumentsComponent implements OnInit {

  @ViewChild('confirmModal', {static: false}) public confirmModal: ModalDirective;

  loadingData: boolean = false;
  yearAccordions: any [] = [];

  //  file uploaders
  public documentUploader:FileUploader = new FileUploader(
    {
      autoUpload: true,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf', 'application/msword'],
      url: 'serverUrl'
    }
  );

  constructor(private backend: BackendService, private notify: NotificationsService,
            private configService: ConfigService, private helper: HelperService) { }

  ngOnInit() {

    //  carico tutti documenti all'avvio
    this.loadAllDocuments();

  }

  loadAllDocuments() {

    this.loadingData = true;

    let params = new HttpParams();

    this.backend.get('camp/documents/all', params).subscribe(
      (response) => {

        //  recupero i documenti dalla risposta
        var documents : any = response;

        //  memorizzo l'anno corrente del utente
        var yearNow = getYear(new Date());

        //  resetto l'array corrente
        this.yearAccordions = [];

        //  faccio il sort per data dei documenti
        documents.sort(function (a,b) {
          return b.date > a.date;
        });

        //  creo un nuovo array dove i documenti saranno ordinati per data decrescente
        var arrayOrdered = [];

        //  inserisco la data più alta che mi è arrivata e scendo
        if (documents.length > 0)
          var currentYear = getYear(parseISO(documents[0].date));

        while (documents.length > 0) {

          //  creo un array con i documenti dell'anno corrente
          var arrayTmp = documents.filter(x => getYear(parseISO(x.date)) == currentYear);

          //  rimuovo tutti gli elementi inseriti nell'array
          arrayTmp.forEach(element => {
            var index = documents.findIndex(x => x.id == element.id);
              if (index >= 0)
                documents.splice(index, 1);
          });

          //  aggiungo gli elementi all'array finale
          arrayOrdered.push ({
            data: currentYear,
            title: currentYear,
            documents: arrayTmp
          });

          //  vado avanti con gli anni
          if (documents.length > 0)
            currentYear = getYear(parseISO(documents[0].date));
        }


        //  controllo che sia presente l'anno corrente nell'array altrimenti lo aggiungo prima di salvarlo
        if (typeof arrayOrdered.find(x => x.data == yearNow) == "undefined")
          arrayOrdered.unshift({
            data: yearNow,
            title: yearNow,
            documents: []
          });


        //  ordino l'array in base alle date
        arrayOrdered.sort(function compare (a,b) {

          const dateA = a.data;
          const dateB = b.data;

          let comparison = 0;

          if (dateA > dateB)
            comparison = -1;
          else if (dateA < dateB)
            comparison = 1;

            return comparison;
        });


        //  assegno il risultato di tutto il lavoro
        this.yearAccordions = arrayOrdered;

        this.loadingData = false;
      },
      (error) => {
        this.backend.showErrors(error);
        this.loadingData = false;
      }
    );
  }



  //  contiene le info dell'upload corrente
  currentUpload: any = {};

  addNewDocument(year) {

    if (typeof this.currentUpload.data != "undefined") {

      this.currentUpload = {};
    } else {

      this.currentUpload.data = year.data;
      this.currentUpload.serverUrl =  environment.apiUrl + "camp/documents/upload/"+year.data;
      this.currentUpload.headers = this.backend.setupAuthHttpHeaders().headers;

      this.documentUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
      this.documentUploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
        this.documentUploaded(item, response, status);
      };
      this.documentUploader.options.url = this.currentUpload.serverUrl;
    }
  }


  //  fa partire una richiesta per la cancellazione di un documento
  destroyDocument ()
  {

    if (this.currentDocumentSelected == null) return;

    //  nascondo la modale di conferma
    this.confirmModal.hide();

    this.loadingData = true;

    this.backend.destroy('camp/documents/'+this.currentDocumentSelected.id).subscribe(
      (response) => {

        this.loadingData = false;
        this.notify.success("Document File", 'successfully deleted');

        //  rimuovo il documento selezionato
        this.currentDocumentSelected = null;

        this.loadAllDocuments();
      },
      (error) => {
        this.loadingData = false;
        this.backend.showErrors(error);
      }
    );

  }

  //  Documento Caricato
  documentUploaded (item:any, response:any, status:any)
  {
      if (item != null) {
        if (status == 200) {
          this.notify.success("Document File", 'successfully uploaded');
          this.loadAllDocuments();
        }
        //	messaggio di errore dal server
        else if (status == 422 || status == 400) {
          var res = JSON.parse(response);
          this.notify.error("File Error", res.message);
        }
        //	errore generico
        else
        {
          console.log(response);
          this.backend.showErrors(response);
        }
      }

    this.currentUpload = {};
  }


  //  la tabella non avrà selezione all'hover
  getRowClass(row) {
      return {'no-selection-row': true};
  }


  //  MODAL CONFIRM
  currentDocumentSelected: any;
  showModalConfirm(document): void {
    this.currentDocumentSelected = document;
    this.confirmModal.show();
  }
  hideModalConfirm(): void {
    this.confirmModal.hide();
  }

  getConfigService() {
    return this.configService
  }

  getHelper() {
    return this.helper
  }

}
