<div [appCheckPermission]="'person_edit'" *ngIf="!this.personCampTransferDetailList[0]">
  <button type="button" class="btn green btn-block btn-outline" (click)="addNew();"><i class="fa fa-plus"></i>&nbsp; New
    Transfer detail</button>
</div>

<div class="mt-3" [appCheckPermission]="'person_edit'" *ngIf="!this.personCampTransferDetailList[0]">
  <button type="button" class="btn green btn-block btn-outline" (click)="openModalCopyAndPaste();"><i
      class="fa fa-clipboard"></i>&nbsp;Copy & Paste Transfer detail</button>
</div>

<div [appCheckPermission]="'person_edit'" class="form-actions right">
  <img *ngIf="getPersonService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
  <button type="button" (click)="saveAll();" class="btn blue" [disabled]="getPersonService().isOngoingRequest()"
    [ngClass]="{'green-jungle': !this.personCampTransferDetailList[0]}">
    <span class="ladda-label"> <i class="fa fa-save"></i> Save All</span>
  </button>
</div>

<div *ngFor="let key of personCampTransferDetailListKeys">
  <app-person-camp-transfer-detail-form #personCampTransferDetailFormList
    [model]="this.personCampTransferDetailList[key]" [multiSave]="multiSaveForm" [campStaffMember]="camp_staff_member"
    (formDelete)="formDelete($event)" (formSaved)="formSaved($event)"></app-person-camp-transfer-detail-form>
</div>

<div [appCheckPermission]="'person_edit'" class="form-actions right">
  <img *ngIf="getPersonService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif" />
  <button type="button" (click)="saveAll();" class="btn blue" [disabled]="getPersonService().isOngoingRequest()"
    [ngClass]="{'green-jungle': !this.personCampTransferDetailList[0]}">
    <span class="ladda-label"> <i class="fa fa-save"></i> Save All</span>
  </button>
</div>

<!-- BEGIN: MODAL COPY PASTE -->
<div [config]="{ show: false }" bsModal (onHidden)="onHiddenCopyPasteModal()" #copyPasteModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-clipboard"></i>&nbsp;Copia i dati dagli ultimi inserimenti che
          sono stati fatti</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalCopyPaste()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngFor="let model of campTransferDetailsCopyPaste">
          <h3 *ngIf="model.person">{{ model.person.first_name }} {{ model.person.surname }}</h3>
          <div class="row">
            <div class="col-md-6" *ngIf="model.from_camp">
              <h4>From</h4>
              <p class="line-breaker">
                <b>{{model.from_camp.camp_code}}</b>
                <small><i class="fa fa-train mx-1" aria-hidden="true"></i> {{model.from_camp.railway}}
                  <i class="fa fa-road mx-1" aria-hidden="true"></i> {{model.from_camp.transfer_details}}</small>
              </p>
            </div>
            <div class="col-md-6" *ngIf="model.to_camp">
              <h4>To</h4>
              <p class="line-breaker">
                <b>{{model.to_camp.camp_code}}</b>
                <small><i class="fa fa-train mx-1" aria-hidden="true"></i> {{model.to_camp.railway}}
                  <i class="fa fa-road mx-1" aria-hidden="true"></i> {{model.to_camp.transfer_details}}</small>
              </p>
            </div>
          </div>
          <div class="row" *ngIf="model.transport">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label">Transport (Bus/Train/Plane)</label>
                <p>{{model.transport}}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Departure</label>
                <p class="mb-0">{{model.from_place}}</p>
                <p class="mb-0">{{model.from_date | date:'d MMMM, y':'':'it'}}</p>
                <p class="mb-0">{{model.from_time}}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Arrival</label>
                <p class="mb-0">{{model.to_place}}</p>
                <p class="mb-0">{{model.to_date | date:'d MMMM, y':'':'it'}}</p>
                <p class="mb-0">{{model.to_time}}</p>
              </div>
            </div>
          </div>
          <button class="btn btn-primary btn-block" (click)="copyAndPaste(model)"><i
              class="fa fa-clipboard"></i>&nbsp;Incolla i dati come nuovo transfer details</button>
          <hr>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: MODAL COPY PASTE -->
