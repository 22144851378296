import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EnterpriseService, ConfigService, HelperService } from '../../_services/index';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-enterprise-bank-account-form',
  templateUrl: './enterprise-bank-account-form.component.html',
  styleUrls: ['./enterprise-bank-account-form.component.css']
})
export class EnterpriseBankAccountFormComponent implements OnInit {

  @Input() model: any = {};
  @Input() multiSave: boolean;

  @Output() primaryUpdate = new EventEmitter();
  @Output() formDelete = new EventEmitter();
  @Output() formSaved = new EventEmitter();

    editMode: boolean = false;

  @ViewChild('f', {static: true}) currentForm: NgForm;

  constructor(private enterpriseService: EnterpriseService, private configService: ConfigService,
    private helper: HelperService, private notify: NotificationsService) { }

  ngOnInit() {

    if (!this.model || !this.model.id)
      this.editMode = true;
  }

  submit() {

    if (!this.currentForm.form.valid)
      return;

      if (!this.currentForm.form.dirty) {
        this.editMode = false;
        return;
      }

    if (!this.enterpriseService.checkPrimary(this.model, this.enterpriseService.currentEnterpriseBankAccountList)) {
      this.notify.error("You need to set this or other payment as primary");
      return;
    }

    //  Valido il campo
    if (!this.validateValue)
      return;

    if (this.model.id)
      this.enterpriseService.updateEnterpriseBankAccount(this.model, ()=> {this.editMode = false;});
    else
      this.enterpriseService.saveEnterpriseBankAccount(this.model, ()=> {this.editMode = false;});

    this.formSaved.emit(this.model);
  }

  destroy() {
    this.enterpriseService.deleteEnterpriseBankAccount(this.model);
  }

  delete() {
    this.formDelete.emit(this);
  }

  edit() {
    this.editMode = true;
  }

  onTypeChange($event) {
    if (this.model.type == 2)
      this.model.bank_name = "Paypal";
    else
      this.model.bank_name = "";

    //  In ogni caso resetto gli altri
    this.model.swift = "";
    this.model.iban = "";
    this.model.accountholder = "";
  }

    //  E' cambiato il valore di un primary
  valueChange(value) {
    this.primaryUpdate.emit(this.model);
  }

  get validateValue(): boolean {
    //  Controllo la validità dei campi prima di inviarli
    if (!this.model.type != null && this.model.iban != null) {
      switch (parseInt(this.model.type)) {
        case 1:
          return true;
        case 2:
          return this.helper.validateEmail(this.model.iban);
      }
    }

    return true;
  }

  getConfigService() {
    return this.configService
  }

  getEnterpriseService() {
    return this.enterpriseService
  }
}
