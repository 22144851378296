import { Router } from '@angular/router';
import { TutorApplicationService, ConfigService, HelperService, UserService } from '../../_services/index';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment-timezone';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-tutors-applications-info-summary',
  templateUrl: './tutors-applications-info-summary.component.html',
  styleUrls: ['./tutors-applications-info-summary.component.css']
})
export class TutorsApplicationsInfoSummaryComponent implements OnInit {

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};

  constructor(public tutorApplicationService: TutorApplicationService, private router: Router,
    private configService: ConfigService, private helper: HelperService, private userService: UserService, private notify: NotificationsService) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
    this.datePickerOptions.disableUntil = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() - 1 }
  }

  @Input() tutorApplicationId: number;
  @Output() closeEvent = new EventEmitter();

  public loading: boolean = false;

  tutorApplicationInfo: any = {};
  currentId: number;

  dataFormatted: any;
  private localData: IMyDateModel;

  noRoleAlert: boolean = false;

  ngOnInit() {

    this.tutorApplicationInfo = {};
  }

  close() {
    this.closeEvent.emit();
  }

  editPerson() {
    this.router.navigate(['person/edit/' + this.tutorApplicationInfo.id]);
  }

  ngOnChanges() {

    if (this.tutorApplicationId != this.currentId)
      this.tutororApplicationInfo();
  }

  tutororApplicationInfo() {

    if (typeof this.tutorApplicationId == "undefined" || this.tutorApplicationId <= 0) {
      this.tutorApplicationInfo = {};
      this.currentId = null;
      return;
    }

    this.loading = true;

    this.currentId = this.tutorApplicationId;
    this.tutorApplicationService.currentTutorApplicationId = this.currentId;

    this.tutorApplicationService.getTutorApplicationInfo(() => {

      this.tutorApplicationInfo = this.tutorApplicationService.currentTutorApplicationInfo;

      if (this.tutorApplicationInfo.birthday != null)
        this.dataFormatted = this.helper.initMyDataInputSingle(this.tutorApplicationInfo.birthday);

      //  Se esiste il path per i file aggiuntivi costruisco gli url
      if (this.tutorApplicationInfo.photo_url)
        this.tutorApplicationInfo.avatarUrl = this.configService.blobBasePath + this.tutorApplicationInfo.photo_url;

      if (this.tutorApplicationInfo.cv_url)
        this.tutorApplicationInfo.cvUrl = this.configService.blobBasePath + this.tutorApplicationInfo.cv_url;

      if (this.tutorApplicationInfo.cover_url)
        this.tutorApplicationInfo.coverUrl = this.configService.blobBasePath + this.tutorApplicationInfo.cover_url;

      if (this.tutorApplicationInfo.driving_licence_url)
        this.tutorApplicationInfo.driverLicenceUrl = this.configService.blobBasePath + this.tutorApplicationInfo.driving_licence_url;

      if (this.tutorApplicationInfo.passport_url)
        this.tutorApplicationInfo.passportUrl = this.configService.blobBasePath + this.tutorApplicationInfo.passport_url;

      if (this.tutorApplicationInfo.int_child_protection_certificate_url)
        this.tutorApplicationInfo.intChildCertificateUrl = this.configService.blobBasePath + this.tutorApplicationInfo.int_child_protection_certificate_url;

      if (this.tutorApplicationInfo.int_driver_licence_url)
        this.tutorApplicationInfo.intDriverLicenceUrl = this.configService.blobBasePath + this.tutorApplicationInfo.int_driver_licence_url;

      if (this.tutorApplicationInfo.health_insurance_url)
        this.tutorApplicationInfo.healthInsuranceUrl = this.configService.blobBasePath + this.tutorApplicationInfo.health_insurance_url;

      if (this.tutorApplicationInfo.flight_receipt_url)
        this.tutorApplicationInfo.flightReceiptUrl = this.configService.blobBasePath + this.tutorApplicationInfo.flight_receipt_url;

      if (this.tutorApplicationInfo.timezone) {
        this.tutorApplicationInfo.timezone_formatted = this.tutorApplicationInfo.timezone + ' (GMT:' + moment.tz(this.tutorApplicationInfo.timezone).format('Z') + ')';
      }

      this.loading = false;

    }, () => { this.loading = false; });

  }

  PromoteTutor() {

    this.tutorApplicationService.promoteTutor('' + this.currentId, this.tutorApplicationInfo, () => {
      this.close();
    })
  }

  HireTutor() {

    this.tutorApplicationService.hireTutor('' + this.currentId, this.tutorApplicationInfo, (jsonResponse) => {

      this.tutorApplicationInfo = jsonResponse;
      this.close();
      this.editPerson();
    })
  }

  RejectTutor() {

    this.tutorApplicationService.rejectTutor('' + this.currentId, this.tutorApplicationInfo, () => {
      this.close();
    })
  }

  CancelRejectTutor() {

    this.tutorApplicationService.cancelRejectTutor('' + this.currentId, this.tutorApplicationInfo, () => {
      this.close();
    })
  }

  EditTutor() {

    this.router.navigate(['tutorsapplications/edit/' + this.currentId]);
  }



  public getConfig() {
    return this.configService;
  }

  public getHelper() {
    return this.helper;
  }

  public getDatePickerOptions() {
    return this.datePickerOptions
  }

  public getLocalData() {
    return this.localData
  }

  public getUserService() {
    return this.userService
  }

  public getTutorService() {
    return this.tutorApplicationService;
  }

}
