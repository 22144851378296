import { IAngularMyDpOptions, IMyDateModel, IMyInputFieldChanged } from 'angular-mydatepicker';
import { CampService, ConfigService, HelperService } from './../../../_services/index';
import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-camp-calendar-filter',
  templateUrl: './camp-calendar-filter.component.html',
  styleUrls: ['./camp-calendar-filter.component.css']
})
export class CampCalendarFilterComponent implements OnInit {

  model: any = {};
  isHidden = true;
  public multipleSelect = [];

  //  campi data
  private dateStart: IMyDateModel;
  private dateEnd: IMyDateModel;

  //  Impostazione della data che vengono passate dall'helper
  private datePickerOptions: IAngularMyDpOptions = {};


  @Output() search = new EventEmitter;

  constructor(private campService: CampService, private configService: ConfigService,
              private helper: HelperService) {

    //  Inizializzo le info sulle date
    this.datePickerOptions = helper.datePickerOptions;
  }

  ngOnInit() {

    // la prima volta imposto sempre l'anno corrente per fare le ricerche
    this.clearAdvancedSearch();
    this.submitSearch();
  }

  submitSearch() {

    if (this.model.status != null && typeof this.model.status != "undefined")
    {
      this.model.status = [];
      this.multipleSelect.forEach(elementStatus => {
        if (elementStatus.value == true)
          this.model.status.push(elementStatus.key);
      })
    }

    this.search.emit(this.model);
    this.isHidden = true;
  }


  clearAdvancedSearch () {
    this.model = {};
    this.model.status = ["1", "3", "4"];
    this.model.year = (new Date()).getFullYear();
    this.dateStart = null;
    this.dateEnd = null;

    //  configuro il multiselect
    this.multipleSelect = [
      {key:"1", value:false}, {key:"2", value:false}, {key:"3", value:false}, {key:"4", value:false}, {key:"5", value:false}, {key:"6", value:false}
    ];

    this.model.status.forEach(elementStatus => {
      (this.multipleSelect.find( x => x.key == elementStatus)).value = true;
    });
  }

  cleanSearch() {

    Object.keys(this.model).forEach(key => {
        if(this.model[key].length == 0 && key != 'status')
          delete this.model[key];
    });
  }

  fieldChange(event) {
    this.cleanSearch();
  }


  onDateStartChanged(event: IMyDateModel)
  {
    this.dateStart = event;

    if (event.singleDate.jsDate != null)
      this.model.week_start = this.helper.formatISODate(event);
    else
      delete this.model.week_start;

  }

  onDateEndChanged(event: IMyDateModel)
  {

    this.dateEnd = event;

    if (event.singleDate.jsDate != null)
      this.model.week_end = this.helper.formatISODate(event);
    else
      delete this.model.week_end;
  }



    //  selezionato il valore attuale dai filtri della select
    public selectedItem(value:any):void {

      this.model.status.push(value.id);
    }

    //  rimuovo il valore attuale dai filtri della select
    public removedItem(value:any):void {

      var index = this.model.status.findIndex(x => x == value.id);

      if (index >= 0)
        this.model.status.splice(index, 1);
    }

    isOngoingRequest() {
      return this.campService.isOngoingRequest()
    }

    getCampTypeValueByKey(type) {
      return this.configService.getCampTypeValueByKey(type)
    }

    getAreaExpertiseValueByKey(area) {
      return this.configService.getAreaExpertiseValueByKey(area)
    }

    getCampStatusValueByKey(key) {
      return this.configService.getCampStatusValueByKey(key)
    }

    getGroupCompanyValueByKey(key) {
      return this.configService.getGroupCompanyValueByKey(key)
    }

    yearSelect(number) {
      return this.configService.yearSelect(number)
    }

    campTypeSelect() {
      return this.configService.campTypeSelect
    }

    groupCompaniesSelect() {
      return this.configService.groupCompaniesSelect
    }

    areaExpertiseSelect() {
      return this.configService.areaExpertiseSelect
    }


    getDateStart() {
      return this.dateStart
    }

    getDateEnd() {
      return this.dateEnd
    }

    getHelper() {
      return this.helper
    }

    getDatePickerOptions() {
      return this.datePickerOptions
    }

    getConfigService() {
      return this.configService
    }


}
