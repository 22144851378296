import { Component, OnInit, Input} from '@angular/core';
import { ActorApplicationService, ConfigService } from 'app/_services';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-actors-applications-phase',
  templateUrl: './actors-applications-phase.component.html',
  styleUrls: ['./actors-applications-phase.component.css']
})
export class ActorsApplicationsPhaseComponent implements OnInit {

  @Input('phase') phase: string;
  @Input('filter') filter: string = "";
  @Input('searchParams') search: any;

  ongoingRequest: boolean = false;
  subscribe: any;

  selected = [];

  actorsCompleted: any[] = [];
  actors: any[] = [];
  actorsRejected: any[] = [];
  actorsImported: any[] = [];

  constructor(private actorService: ActorApplicationService, private configService: ConfigService) { }

  ngOnInit() {

    this.init();
    this.subscribe = this.actorService.getsubjectToUpdateObservable().subscribe(() => {
      this.init();
    })
  }

  ngOnDestroy(){

    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

  init()
  {
    if (typeof this.phase != "undefined" && this.phase != null)
    {
      this.actorService.getActorApplicationPhase(this.phase, this.search, (response) => {

        this.updateTable(response);
      });
    }
  }

  updateTable (data)
  {
    data.forEach(actorApplicationInfo => {

      if (actorApplicationInfo.timezone){
        actorApplicationInfo.timezone_formatted = actorApplicationInfo.timezone + ' (GMT:' + moment.tz(actorApplicationInfo.timezone).format('Z') + ')';

        if (actorApplicationInfo.interview_time && actorApplicationInfo.interview_date){
          let m = moment.tz(actorApplicationInfo.interview_date + " "+actorApplicationInfo.interview_time, actorApplicationInfo.timezone);
          actorApplicationInfo.italian_interview_time_formatted = m.tz("Europe/Rome");
        }
      }

      if (actorApplicationInfo.preferred_interview_time) {
        actorApplicationInfo.preferred_interview_time_formatted = [];
        JSON.parse(actorApplicationInfo.preferred_interview_time).forEach(time => {
          if (time.checked == true)
            actorApplicationInfo.preferred_interview_time_formatted.push(
              " • " + time.name
            );
        });
      }
    });

    this.actors = data.filter(x => x.rejected == false && x.status != "SUBMITTED" && x.status != "COMPLETED" && !x.imported == true);
    this.actorsCompleted = data.filter(x => x.rejected == false && (x.status == "SUBMITTED" || x.status == "COMPLETED" ));
    this.actorsRejected = data.filter(x => x.rejected == true);
    this.actorsImported = data.filter(x => x.imported == true);

    // applico eventuali filtri
    if (this.filter == "selftape"){
      this.actors = this.actors.filter(x => x.selftape == true);
      this.actorsCompleted = this.actorsCompleted.filter(x => x.selftape == true);
      this.actorsRejected = this.actorsRejected.filter(x => x.selftape == true);
    }

    if (this.filter == "audition"){
      this.actors = this.actors.filter(x => x.audition == true);
      this.actorsCompleted = this.actorsCompleted.filter(x => x.audition == true);
      this.actorsRejected = this.actorsRejected.filter(x => x.audition == true);
    }
  }


  public getActorService() {
    return this.actorService;
  }

  public getConfigService(){
    return this.configService
  }

}
