import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BackendService, CampService, ConfigService, HelperService } from 'app/_services';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-camp-tutor-recap',
  templateUrl: './camp-tutor-recap.component.html',
  styleUrls: ['./camp-tutor-recap.component.css']
})
export class CampTutorRecapComponent implements OnInit {

  rows: any[] = [];
  query: any;

  ongoingRequest: boolean = false;

  constructor(
    private campService: CampService,
    private backend: BackendService,
    private helper: HelperService,
    private configService: ConfigService
  ) { }

  ngOnInit(): void {

    this.query = {
      current_user_staff_member: true,
      current_user_staff_member_add_details: true,
      year: new Date().getFullYear(),
      status: ["1"], // al rilascio rimuovere lo stato 4 - completato, usato solo per test
      sortBy: "camps.start",
      sortDir: "DESC"
    };

    // this.query.year = "2021"

    this.update();
  }

  update() {

    let params = new HttpParams();

    this.ongoingRequest = true;

    this.backend.post('camp/search', this.query, params).subscribe(
      (response) => {

        let res: any = response;

        // faccio il parse delle info del camp
        res.forEach(element => {
          if (element.type)
            element.type_name = this.configService.getCampTypeValueByKey(element.type);
        });

        this.rows = res;

        this.ongoingRequest = false;
      },
      (error) => {

        this.backend.showErrors(error);
        this.ongoingRequest = false;
      }
    );
  }

  downloadCampPDF(camp_id, camp_code) {
    this.campService.downloadCampStaff(camp_id, camp_code);
  }

  public getConfigService(){
    return this.configService
  }

}
