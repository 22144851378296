<div class="datatable-loader text-center" *ngIf="isOngoingRequest()">
  <span></span><span></span><span></span><span></span><span></span>
  <strong class="text-center">Loading</strong>
</div>

<div class="portlet">

  <div class="portlet-body">
    <div *ngIf="!isOngoingRequest()">
      <ngx-datatable class="striped"
        class="material"
        [rows]="documentsRows"
        [columnMode]="'flex'"
        [headerHeight]="0"
        [footerHeight]="0"
        [rowHeight]="'auto'"
        [rowClass]="getRowClass"
      >
        <ngx-datatable-column prop="date_parsed" [flexGrow]="2">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value | date:'d MMMM, y':'':'it' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="file_name" [flexGrow]="6">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="" [flexGrow]="2">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a  class="btn btn-primary" href="{{getConfigService().blobBasePath+row.file_path}}"><i class="fa fa-save"></i>&nbsp;Download</a>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>
