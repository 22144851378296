<div class="row">
    <div class="col-md-12">
      <button type="button" class="btn green-jungle btn-outline btn-block" (click)="addPenalty();"><i class="fa fa-plus"></i>&nbsp; New Penalty</button>
    </div>
  </div>
  <ngx-datatable
  class="striped"
  [rows]="rows"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="0"
  [rowHeight]="'auto'"
  [selected]="selectedRow"
  [selectionType]="'single'"
  (select)='onSelect($event)'
  (activate)="onActivate($event)"
  >
  <ngx-datatable-column name=""  prop="paid" [flexGrow]="1" [resizeable]="false" [draggable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value == true"><i class="fa fa-check fa-lg" style="color:green" aria-hidden="true"></i></span>
      <span *ngIf="value != true"><i class="fa fa-times fa-lg" style="color:red" aria-hidden="true"></i></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Date Penalty"  prop="date_penalty" [flexGrow]="2" [resizeable]="false" [draggable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ row.date_penalty | date }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Locality"  prop="locality" [flexGrow]="6" [resizeable]="false" [draggable]="false"></ngx-datatable-column>
  <ngx-datatable-column name="Amount" prop="amount" [flexGrow]="2" [resizeable]="false" [draggable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span><b>{{ (value | currency:'EUR':'symbol':'1.2-2':'it') || "€ 0,00" }}</b></span>
    </ng-template>
  </ngx-datatable-column>
  </ngx-datatable>
  
  
  
  <!-- Modale per la visualizzazione o inserimento di un nuovo membro -->
  <div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Penalty</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <app-vehicle-penalty-info-form [model]="selected" (penaltyDeleted)="hideModal()" (penaltyUpdated)="hideModal()"></app-vehicle-penalty-info-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>