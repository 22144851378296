<!-- BEGIN FORM -->
<form action="#" class="horizontal-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

  <div class="form-body">
    <h3 class="form-section">Actor Group Info</h3>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || group_name.dirty || group_name.touched) && !group_name.valid }">
          <label class="control-label">Nome Gruppo <span class="required">*</span></label>
          <input type="text" name="group_name" [(ngModel)]="model.group_name" #group_name="ngModel" required class="form-control" placeholder="TH 01 2017">
          <span class="help-block"> Group name is required </span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Attivo</label>
          <div class="mt-checkbox-inline">
            <label class="mt-checkbox mt-checkbox-outline">
              <input type="checkbox" name="active" [(ngModel)]="model.active" value="1"> Yes
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="control-label"></label>
        <div class="portlet light bordered" >
          <div class="portlet-title">
            <div class="caption">
              <span class="caption-subject font-dark bold uppercase">Attori del gruppo</span>
              <span class="caption-helper"></span>
            </div>
            <div class="actions">
              <div class="btn-group btn-group-devided" data-toggle="buttons">
                <button type="button" [disabled] = "getGroupService().ongoingRequest" class="btn btn-circle btn-outline green" (click)="showModal();"><i class="fa fa-add"></i>&nbsp;Aggiungi attore</button>
              </div>
            </div>
          </div>
          <div class="portlet-body">
            <app-group-member-info-show *ngFor="let member of model.members | mapFromKeyValue" [idMember]="member.id" (destroyMember)="destroyMember($event)" (driverChange)="updateMemberInfo($event)"></app-group-member-info-show>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Notes</label>
          <textarea name="annotation" [(ngModel)]="model.annotation" rows="10" class="form-control" placeholder="Some notes..."></textarea>
        </div>
      </div>
    </div>

  </div>

  <div class="form-actions right">
    <img *ngIf="getGroupService().isOngoingRequest()" src="./assets/global/img/input-spinner.gif"
    />

    <ng-template #confirmDelete><button type="button" class="btn btn-outline red" (click)="destroy();">Yes Delete!</button></ng-template>
    <button type="button" class="btn btn-outline red" [disabled]="getGroupService().isOngoingRequest()" *ngIf="getGroupService().currentGroupId"
       [outsideClick]="true" [popover]="confirmDelete" popoverTitle="Are you sure?"><i class="fa fa-trash-o"></i> Delete</button>&nbsp;
    <button type="submit" class="btn blue" [disabled]="getGroupService().isOngoingRequest()" *ngIf="getGroupService().currentGroupId">Update</button>
    <button type="submit" class="btn green-jungle" [disabled]="getGroupService().isOngoingRequest()" *ngIf="!getGroupService().currentGroupId">Continue</button>
  </div>

</form>


<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add actor</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <app-person-search-form #personSearchForm (searchKeywordsSubmit)="searchKeywordsSubmit($event)" [ongoingRequest]="personDatatable.ongoingRequest"></app-person-search-form>
          </div>
        </div>

        <br><br>

        <!-- begin: prima riga -->
        <div class="row">
          <div class="col-md-12">

            <!--begin: Portlet -->
            <div class="portlet light bordered">
              <!--end: Portlet-Body -->
              <div class="portlet-body">
                <app-person-datatable #personDatatable [query]="personSearchForm.searchKeyword" (personSelected)="personSelected($event)"></app-person-datatable>
              </div>
              <!--end: Portlet-Body -->
            </div>
            <!--end: Portlet -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
