import { NotificationsService } from 'angular2-notifications';
import { HttpParams } from '@angular/common/http';
import { BackendService, PersonService, HelperService } from './../../_services/';
import { PersonSearchFormComponent } from './../person-search-form/person-search-form.component';
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonDatatableComponent } from "../person-datatable/person-datatable.component";
import { PersonInfoSummaryComponent } from "../person-info-summary/person-info-summary.component";
import { parseISO } from 'date-fns';

//  salvataggio per excel
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {  } from '../../_services';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-person-search-results',
  templateUrl: './person-search-results.component.html',
  styleUrls: ['./person-search-results.component.css']
})
export class PersonSearchResultsComponent implements OnInit {

  @ViewChild('autoShownModal', {static: false}) autoShownModal: ModalDirective;
  @ViewChild('staticModal', {static: false}) public staticModal: ModalDirective;

  @ViewChild(PersonDatatableComponent, {static: true}) datatable: PersonDatatableComponent;
  @ViewChildren('personInfoSummaryComponent') personShow: PersonInfoSummaryComponent;

  private subject: any;
  private searchForm: any;

  constructor(private route: ActivatedRoute, private router: Router, private backend: BackendService,
            private personService: PersonService, private notify: NotificationsService, private helper: HelperService) { }

  ngOnInit() {

    this.subject = this.route.params.subscribe(params => {
      this.updateResults(params);
    });
  }

  ngOnDestroy()
  {
    this.subject.unsubscribe();
  }

  currentPersonId: number;
  personSelected(selected) {

    this.currentPersonId = selected.person_id;
    this.showModal();
  }

  updateResults(params){
    this.searchForm = params;

    this.datatable.query = params;
    this.datatable.getResults();
  }

  searchKeywordsSubmit(params){
    this.updateResults(params);
  }


  // MODAL
  isModalShown: boolean = false;

  showModal(): void {
    this.isModalShown = true;
    this.autoShownModal.show();
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }



    // MODAL EXPORT
    isModalExport: boolean = false;

    showModalExport(): void {
      this.isModalExport = true;
    }

    hideModalExport(): void {
      this.staticModal.hide();
    }

    onHiddenExport(): void {
      this.isModalExport = false;
    }


  /*  Salvo i dati nel formato Excel
  */
 exportToExcel() {

    if (this.datatable.pageTotalElements != null && typeof this.datatable.pageTotalElements != "undefined")
      if (this.datatable.pageTotalElements > this.personService.MAX_NUM_OF_EXPORT) {
        this.notify.warn("Export", "Too many results, improve your search");
        return;
      }

    this.showModalExport();

    if (this.searchForm.advancedSearchOn == "false" || !this.searchForm.advancedSearchOn) {

      this.personService.exportAll(this.searchForm, (res) => {
        this.exportParse(res, () => {
          this.hideModalExport();
        });
      }, () => {
        this.hideModalExport();
      });

    }
    else {

      this.personService.exportAllAdvanced(this.searchForm, (res) => {
        this.exportParse(res, () => {
          this.hideModalExport();
        })
      }, () => {
        this.hideModalExport();
      });
    }

  }

  /*
  * Questo metodo prepara l'esportazione dei dati
  * da inviare poi ad un file excel o csv
  */
  exportParse (data: any, callback: any) {

    var exportedArray = [];

    //  preparo i dati per il csv bonificandoli
    data.forEach( (element) => {

      //  bonifico
      Object.keys(element).forEach(key => {
        if (element[key] == null) element[key] = '';
      })

      if (typeof element.birthday != "undefined" && element.birthday != null && element.birthday.length > 0)
        element.data_parsed = parseISO(element.birthday).toLocaleDateString(this.helper.locale, { year: "numeric", month: "short", day: "2-digit" });
      else
        element.data_parsed = "";

      //  preparo
      exportedArray.push( {
        'Nome': element.first_name,
        'Cognome': element.surname,
        'Cellulare': element.mobile_contact,
        'Email': element.email_contact,
        'Indirizzo': element.route +" "+element.street_number,
        "Città": element.administrative_area_level_3_short_version,
        "Provincia": element.administrative_area_level_2_short_version,
        "Regione": element.administrative_area_level_1_long_version,
        "Codice Postale": element.postal_code,
        "Codice Fiscale": element.tax_id,
        "Data di Nascita": element.data_parsed,
        "Luogo di Nascita": element.birthplace,
        "Dati bancari": element.bank_account_iban
      })
    });

    //  creo io worksheet con i dati
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedArray);

    //  personalizzo le colonne
    worksheet['!cols'] = [{width: 20}, {width:20}, {width:20}, {width:30}, {width:40}, {width:20}, {width:12}, {width:20}, {width:14}, {width:20}, {width:20}, {width:36}, {width:30}];

    //  creo il workbook con lo sheet attuale
    const workbook: XLSX.WorkBook = { Sheets: { 'People': worksheet}, SheetNames: ['People'] };

    //  scrivo il file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', bookSST:false, type: 'array' });

    //  Salvo il file tramite il browser
    FileSaver.saveAs(new Blob([excelBuffer], {type: EXCEL_TYPE}), "people.xlsx");

    callback();

  }

}
